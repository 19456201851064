import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { Loading } from '../Common/Loading';
import { formatPrice } from '../../js/Utils.js';
import { getPaymentType, getPaymentTypeIconName } from '../../js/Utils.js';
import configData from "../../appsettings.json";
import { M3Icon } from '../Common/M3Icon';

export const CartPayment = ({ addictionalProducts, selectedAddProdIds, handleSelectedPaymentType, handleHaveAllProductPaymentMap, addedMkpFee, modifiedMkpFee, selectedBuoniScontoIds, welfareInfoCredit, onUpdateTotalPriceToPay }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    // debug
    const [searchParams, setSearchParams] = useSearchParams();
    let debug = searchParams.get("debug");

    const [isLoading, setIsLoading] = useState(true);
    const [cartPaymentMap, setCartPaymentMap] = useState();


    useEffect(() => {
        const getCartPaymentMap = async (inputData) => {
            const response = callGetCartPaymentMap(inputData);
        }

        setIsLoading(true);
        getCartPaymentMap({ 
            AddictionalProducts: addictionalProducts,
            AddictionalProductSelected: selectedAddProdIds,
            AddedMkpFee: addedMkpFee,
            SelectedBuoniScontoIds: selectedBuoniScontoIds
        });
    }, [selectedAddProdIds, addedMkpFee, modifiedMkpFee, selectedBuoniScontoIds]);


    async function callGetCartPaymentMap(inputData) {
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/GetPaymentMap', requestOption);
        const response = await fetchedRes.json();

        if (response.success && response.cartPaymentMap) {
            try {
                if (response.cartPaymentMap.globalPaymentTypes.includes(5) && welfareInfoCredit) // 5 = welfare points
                {
                    let totalAmount = response.cartPaymentMap.globalPaymentMaps.payments[0].amount;
                    if (totalAmount > welfareInfoCredit.displayPrice) {
                        response.cartPaymentMap.globalPaymentTypes = response.cartPaymentMap.globalPaymentTypes.filter(x => x !== 5);
                        onUpdateTotalPriceToPay(totalAmount);
                        if (response.cartPaymentMap.globalPaymentTypes.length === 0) {
                            return;
                        }

                        for (let i = 0; i < response.cartPaymentMap.productPaymentMaps.length; i++) {
                            response.cartPaymentMap.productPaymentMaps[i].payments = response.cartPaymentMap.productPaymentMaps[i].payments.filter(x => x.paymentType !== 5);
                            for (let j = 0; j < response.cartPaymentMap.productPaymentMaps[i].payments.length; j++) {
                                response.cartPaymentMap.productPaymentMaps[i].bestMatch = response.cartPaymentMap.globalPaymentTypes[0];
                            }
                        }

                        for (let i = 0; i < response.cartPaymentMap.globalPaymentMaps.payments.length; i++) {
                            response.cartPaymentMap.globalPaymentMaps.payments = response.cartPaymentMap.globalPaymentMaps.payments.filter(x => x.paymentType !== 5);                            
                        }
                        response.cartPaymentMap.globalPaymentMaps.bestMatch = response.cartPaymentMap.globalPaymentTypes[0];
                    }

                    setCartPaymentMap(response.cartPaymentMap);
                    handleHaveAllProductPaymentMap(response.haveAllProductPaymentMap);
                    setIsLoading(false);
                    return;
                }
            }
            catch (ex) {

            }

            setCartPaymentMap(response.cartPaymentMap);
            handleHaveAllProductPaymentMap(response.haveAllProductPaymentMap);
        }

        setIsLoading(false);
    }


    const onPaymentSelect = (e, payment) => {
        // tolgo class selected a tutti
        let ePayments = document.getElementsByClassName("paymentType");
        for (let i = 0; i < ePayments.length; i++) ePayments[i].classList.remove("paymentTypeSelected");

        // aggiungo classe selected a quello cliccato
        e.currentTarget.classList.add("paymentTypeSelected");

        // imposto sul cart tipo di pagamento selezionato
        handleSelectedPaymentType(payment.paymentType);
    }


    const renderPaymentMethods = () => {
        return (
            <>
                {cartPaymentMap && cartPaymentMap.globalPaymentMaps && cartPaymentMap.globalPaymentMaps.payments && cartPaymentMap.globalPaymentMaps.payments.length > 0
                    ? (
                        <>
                            <p>{t("MyBook:ClickPayment")}</p>
                            {cartPaymentMap.globalPaymentMaps.payments.map(payment =>
                                <div key={payment.paymentType} className="border rounded cursor-pointer paymentType" onClick={e => onPaymentSelect(e, payment)}>
                                    <div className="p-2">
                                        <div className="titlecard">
                                            <div className="d-inline-block"><span>{payment.customLabel ? payment.customLabel : t(`paymentTypes:${getPaymentType(payment.paymentType)}`)}</span></div>
                                            &nbsp;&nbsp;<img src={`${configData.Settings.CDN_Common}/images/ico/payment_type/${getPaymentTypeIconName(payment.paymentType)}.png`} alt={payment.customLabel ? payment.customLabel : t(`paymentTypes:${getPaymentType(payment.paymentType)}`)} style={{ width: '20px' }} />
                                            <div className="d-inline-block float-end">
                                                {formatPrice(payment.amount, payment.currency, cultureName)}
                                            </div>
                                            <div className="d-inline-block float-end mr-5">
                                                <span className="h6">{t(`Button:Select`)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )
                    : (<></>)
                }

                {debug === "1" && (<><br /><br />{JSON.stringify(cartPaymentMap)}</>)}
            </>
        );
    }


    return (
        <>
            <div>
                <M3Icon iconName="CreditCard" externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                <span className="basket titlecard">{t("MyBook:Payment")}</span>
            </div>

            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : cartPaymentMap && renderPaymentMethods()
            }
        </>
    )
}
