import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { ProductDataImages } from './common/ProductDataImages';
import { ProductDataDescriptions } from './common/ProductDataDescriptions';
import { AddServiceNote } from '../../../ManualProduct/components/AddServiceNote';
import { AddNoteXml } from '../../../ManualProduct/components/AddNoteXml';
import { Loading } from '../../../Common/Loading';

export const UpdateActivityData = ({ modalId, resetValue, quotationItemId, wsBookingId, saveProductData }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    const [productData, setProductData] = useState(false);
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setVisible(resetValue > 1);
        setProductData(null);
        setIsLoading(true);
        setIsError(false);

        let inputData = {
            quotationItemId: quotationItemId,
            wsBookingId: wsBookingId,
            cultureName: cultureName
        }

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchData = async () => {
            const response = await fetch(`${configData.Settings.CommonApi_BaseUrl}quotationto/getUpdateStaticData`, requestOption)
            const data = await response.json();

            if (data.success) {
                setProductData(data.activityData);
                setIsError(false);
            }

            setIsLoading(false);
        }

        fetchData()
            .catch(console.error);
    }, [resetValue]);

    function onSave() {
        let inputData = {
            activityData: productData,
            quotationItemId: quotationItemId,
            wsBookingId: wsBookingId
        };

        setVisible(false);
        saveProductData(inputData);
    }

    function onUpdateDataDesc(desc) {
        let pd = { ...productData };
        pd.descriptions = desc;
        setProductData(pd);
    }

    function onUpdateDataFullCategories(desc) {
        let pd = { ...productData };
        pd.fullCategories = desc;
        setProductData(pd);
    }

    function onUpdateDataImages(img) {
        let pd = { ...productData };
        pd.images = img;
        setProductData(pd);
    }

    function onUpdateDataVideos(img) {
        let pd = { ...productData };
        pd.videos = img;
        setProductData(pd);
    }

    function onBlurUpdatePublicNote(event) {
        let pd = { ...productData };
        pd.internalNote = event.target.value;
        setProductData(pd);
    }

    function onBlurEventServiceNote(roomIndex, propertyName, event) {
        let pd = { ...productData };
        pd.serviceNote = event;
        setProductData(pd);
    }

    function onBlurEventRoomNote(event) {
        let pd = { ...productData };
        pd.roomsNoteXML = event;
        setProductData(pd);
    }

    const footerContent = (
        <div className='modal-footer'>
            <button type="button" className="btn bt-sm tp-btn-delete" onClick={_ => setVisible(false)}>{t('Button:Close')}</button>
            <button type="button" className="btn bt-sm tp-btn-add" data-bs-dismiss="modal" onClick={_ => onSave()}>{t('Button:Save')}</button>
        </div>
    );

    return (
        <>
            <Dialog
                header="Scheda"
                footer={footerContent}
                visible={visible}
                style={{ width: '75vw' }}
                onHide={() => { if (!visible) return; setVisible(false); }}>
                <p className="m-0">
                    {
                        isLoading && <Loading textMsg="Caricamento in corso..." />
                    }
                    {
                        productData && <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="form-floating col-6">
                                        <InputText id="updName" type="text" value={productData.name} className="form-control" onChange={(e) => setProductData({ ...productData, name: e.target.value })} />
                                        <label htmlFor="updName" class="active">Nome esperienza</label>
                                    </div>
                                    <div className="form-floating col-6">
                                        <InputText id="updThumb" type="text" value={productData.thumb} className="form-control" onChange={(e) => setProductData({ ...productData, thumb: e.target.value })} />
                                        <label htmlFor="updThumb" class="active">Thumb</label>
                                    </div>
                                    <div className="form-floating col-2">
                                        <InputText id="updDuration" type="text" value={productData.duration} className="form-control" onChange={(e) => setProductData({ ...productData, duration: e.target.value })} />
                                        <label htmlFor="updDuration" class="active">Durata (numero giorni)</label>
                                    </div>
                                    <div className="form-floating col-3">
                                        <InputText id="updCity" type="text" value={productData.city} className="form-control" onChange={(e) => setProductData({ ...productData, city: e.target.value })} />
                                        <label htmlFor="updCity" class="active">Città</label>
                                    </div>
                                    <div className="form-floating col-2">
                                        <InputText id="updZipCode" type="text" value={productData.zipCode} className="form-control" onChange={(e) => setProductData({ ...productData, zipCode: e.target.value })} />
                                        <label htmlFor="updZipCode" class="active">Zip Code</label>
                                    </div>
                                    <div className="form-floating col-2">
                                        <InputText id="updProvince" type="text" value={productData.stateProvince} className="form-control" onChange={(e) => setProductData({ ...productData, stateProvince: e.target.value })} />
                                        <label htmlFor="updProvince" class="active">Provincia</label>
                                    </div>
                                    <div className="form-floating col-3">
                                        <InputText id="updCountry" type="text" value={productData.country} className="form-control" onChange={(e) => setProductData({ ...productData, country: e.target.value })} />
                                        <label htmlFor="updCountry" class="active">Stato</label>
                                    </div>
                                    {
                                        productData && <div className="col-12">
                                            <TabView>
                                                <TabPanel header="Categorie">
                                                    <ProductDataDescriptions
                                                        allCategoryDescriptions={productData.allCategoryDescriptions}
                                                        descriptions={productData.fullCategories}
                                                        descriptionIsHTML={false}
                                                        onUpdateData={onUpdateDataFullCategories} />
                                                </TabPanel>
                                                <TabPanel header="Descrizioni">
                                                    <ProductDataDescriptions
                                                        descriptions={productData.descriptions}
                                                        descriptionIsHTML={true}
                                                        onUpdateData={onUpdateDataDesc} />
                                                </TabPanel>
                                                <TabPanel header="Immagini">
                                                    <ProductDataImages
                                                        images={productData.images}
                                                        onUpdateData={onUpdateDataImages} />
                                                </TabPanel>
                                                <TabPanel header="Note Servizio">
                                                    <div className="pb-2">
                                                        <AddServiceNote
                                                            serviceNote={productData.serviceNote}
                                                            roomIndex={0}
                                                            onBlurEvent={onBlurEventServiceNote} />
                                                    </div>

                                                    <div class="mt-2 pt-2 border-light border-top">
                                                        <div className="row">
                                                            <div className="col-12 form-floating ">
                                                                <textarea className="form-control"
                                                                    placeholder="Note Interne"
                                                                    value={productData.internalNote}
                                                                    onChange={(e) => { onBlurUpdatePublicNote(e); }}
                                                                    id="floatingTextareaIntNote"
                                                                    style={{ height: '100px' }} >
                                                                </textarea>
                                                                <label htmlFor="floatingTextareaIntNote">Note Interne</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel header="Note XML Fornitore">
                                                    <AddNoteXml
                                                        roomNotes={productData.roomsNoteXML}
                                                        onBlurEvent={onBlurEventRoomNote} />
                                                </TabPanel>
                                                <TabPanel header="Video">
                                                    <ProductDataImages
                                                        images={productData.videos}
                                                        onUpdateData={onUpdateDataVideos} />
                                                </TabPanel>
                                            </TabView>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </p>
            </Dialog>
        </>
    );
}
