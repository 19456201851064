import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useAuth } from '../../../Auth/AuthProvider';
import { getAuthToken, getCurrentUserFromJwtToken } from '../../../../js/Utils.js';
import { HeaderLogo } from '../Header/HeaderLogo';
import { HeaderUserInfo } from '../Header/HeaderUserInfo';
import { HeaderMyBook } from '../Header/HeaderMyBook';
import { HeaderCart } from '../Header/HeaderCart';
import { HeaderGiftCards } from '../Header/HeaderGiftCards';
import { HeaderMenu } from '../Header/HeaderMenu';
import { HeaderLogin } from '../Header/HeaderLogin';
import { HeaderLogout } from '../Header/HeaderLogout';
import { HeaderMenuMobile } from '../Header/HeaderMenuMobile'
import { HeaderWorkingMode } from '../Header/HeaderWorkingMode';
import { HeaderProductLink } from '../Header/HeaderProductLink';
import { HeaderChangeLanguage } from '../Header/HeaderChangeLanguage';
import { HeaderRequestQuotation } from '../Header/HeaderRequestQuotation';
import { FooterVersion } from '../Header/FooterVersion';
import { FooterVersionRight } from '../Header/FooterVersionRight';
import { scrollTo } from '../../../../js/Utils.js';
import { WebMenuVoid } from './WebMenuVoid';

export const RenderByPlaceholder = ({ item, rules, modal }) => {
    let token = getAuthToken();
    const location = useLocation();
    const { onChangeModuleSelected, getModuleSelected, getDefaultModule } = useAuth();

    const [moduleSelected, setModuleSelect] = useState('NOMODULE');

    useEffect(() => {
        setModuleSelect(getModuleSelected);
    }, [getModuleSelected])

    function isOnlyBookMode() {

        let isOnlyBook = false;

        try {

            let currentUser = getCurrentUserFromJwtToken(token);
            if (currentUser && (currentUser.roles.includes("W_OnlyBook") || currentUser.roles.includes("W_ConfirmAndBuy")))
                isOnlyBook = true;

        } catch (ex) {

        }

        return isOnlyBook;
    }

    function isAnonymous() {

        let isAnonymous = false;

        try {
            let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
            if (jCurrentUser && jCurrentUser.isanonymous)
                isAnonymous = true;

        } catch (ex) {

        }

        return isAnonymous;
    }

    function isPlaceholder(myHtml) {

        if (myHtml.includes("#PRODUCT_"))
            return true;

        switch (myHtml) {
            case "#LOGO#":
            case "#USERNAME#":
            case "#MYBOOK#":
            case "#CART#":
            case "#GIFTCARDS#":
            case "#LOGOUT#":
            case "#LOGIN#":
            case "#WORKINGMODE#":
            case "#CHANGELANGUAGE#":
            case "#MENULATERAL#":
            case "#MENUMOBILE#":
            case "#REQUESTQUOTATION#":
            case "#VERSION#":
            case "#VERSIONRIGHT#":
                return true;

            default:
                return false;
        }
    }

    function isVisibleProduct(myHtml) {
        if (!rules)
            return false;

        let isVisibleTrippie = false;
        try {
            let currentUser = getCurrentUserFromJwtToken(token);
            if (currentUser && currentUser.roles.includes("W_EngineTrippie"))
                isVisibleTrippie = true;
        } catch (ex) { }

        try {

            switch (myHtml) {
                case '#PRODUCT_ACTIVITY#':
                    {
                        return rules["ACTIVITY"];
                        break;
                    }
                case '#PRODUCT_RENTCAR#':
                    {
                        return rules["CARRENTAL"];
                        break;
                    }
                case '#PRODUCT_CRUISE#':
                    {
                        return rules["CRUISE"];
                        break;
                    }
                case '#PRODUCT_STRUCTURE#':
                    {
                        return rules["STRUCTURE"];
                        break;
                    }
                case '#PRODUCT_TRANSFER#':
                    {
                        return rules["TRANSFER"];
                        break;
                    }
                case '#PRODUCT_TOUR#':
                    {
                        return rules["TOUR"];
                        break;
                    }
                case '#PRODUCT_FLIGHT#':
                    {
                        return rules["FLIGHT"];
                        break;
                    }
                case '#PRODUCT_TRIPPIE#':
                    {
                        return isVisibleTrippie;
                        break;
                    }
                default: {
                    return true;
                }
            }


        } catch (ex) {
            return false;
        }
    }

    function onChangePage(pageName) {
        if (!token && pageName !== 'HOME') {
            const button = document.querySelector('.open-login-modal')
            button.click()
            return
        }

        if (pageName === 'HOME') {
            onChangeModuleSelected(getDefaultModule);
        }
        else {
            onChangeModuleSelected(pageName);
        }

        if (!location.pathname.includes('home'))
            window.location.href = 'home';
        else {
            scrollTo(null, 'childrenContainer');
        }
    }

    function canRenderIsLoggedOrNot() {
        if (item.name.toUpperCase().includes('NOTLOGGED'))
            return !token || isAnonymous();

        if (item.name.toUpperCase().includes('LOGGED'))
            return token && !isAnonymous();

        return true;
    }

    return (
        <>
            {
                !isPlaceholder(item.html) && canRenderIsLoggedOrNot() && <WebMenuVoid modal={modal} item={item} rules={rules} />
            }
            {item.html === "#LOGO#" && <HeaderLogo item={item} modal={modal} />}


            {
                item.html === "#MENUMOBILE#" && <HeaderMenuMobile modal={modal} />
            }
            {
                !isAnonymous() && <>
                    {token && item.html === "#USERNAME#" && <HeaderUserInfo item={item} />}
                    {token && !isOnlyBookMode() && item.html === "#MYBOOK#" && <HeaderMyBook item={item} modal={modal} />}
                    {token && item.html === "#LOGOUT#" && <><HeaderLogout modal={modal} item={item} />

                    </>}
                </>
            }
            {token && !isOnlyBookMode() && item.html === "#CART#" && <HeaderCart item={item} modal={modal} />}
            {token && !isOnlyBookMode() && item.html === "#GIFTCARDS#" && <HeaderGiftCards item={item} modal={modal} />}
            {(!token || isAnonymous()) && item.html === "#LOGIN#" && <> <HeaderLogin item={item} modal={modal} />

            </>}
            {
                 item.html === "#WORKINGMODE#"&& canRenderIsLoggedOrNot() && <HeaderWorkingMode item={item} />
            }
            {
                item.html === "#CHANGELANGUAGE#" && <HeaderChangeLanguage item={item} />
            }
            {
                item.html === "#REQUESTQUOTATION#" && canRenderIsLoggedOrNot() && <HeaderRequestQuotation item={item} modal={modal} />
            }
            {
                item.html === "#MENULATERAL#" && <HeaderMenu item={item} />
            }
            {
                item.html === "#VERSION#" && <FooterVersion item={item} />
            }
            {
                item.html === "#VERSIONRIGHT#" && <FooterVersionRight item={item} />
            }
            {
                isVisibleProduct(item.html) && item.html.includes("#PRODUCT_") && <HeaderProductLink item={item} modal={modal} moduleSelected={moduleSelected} onChangePage={onChangePage} />
            }
        </>
    );
}