import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useAuth } from '../../Auth/AuthProvider';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../Common/Loading';
import { handleChangePax, renderOptionBookParameters, renderPaxBookParameters, productNotAvailableClass_Opacity, productNotAvailableClass_Border } from './SharedFunctions';
import { formatDateTime, getStructureStars } from '../../../js/Utils.js';
import { registerSessionQuotation } from '../../../js/QuotationUtils';
import { BookParameterMandatoryCssClass } from '../../../js/Constant';
import configData from "../../../appsettings.json";
import { AvailableTour } from "../Available/AvailableTour";
import { M3Icon } from '../../Common/M3Icon';
import { M3IconProductType } from '../../Common/M3IconProductType';
import { M3Pax } from "../../Common/M3Pax";
import { CancelPolicyModal } from '../../Product/Common/CancelPolicyModal';

export const CartTour = ({ cartItemId, isCartQuotation, userPermissions, cartPaxesNames, staticBookParams, productBookParams, handleBookParameters, handleReservationData, userLockBook, callTermsFinished, onOpenModalConfirmDelete }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const cultureName = cookies.get("CultureName");
    const { setQuotationInfoSession } = useAuth();

    // debug
    const [searchParams] = useSearchParams();
    let debug = searchParams.get("debug");

    const [isLoading, setIsLoading] = useState(true);
    const [cartItem, setCartItem] = useState([]);

    useEffect(() => {
        const getCartItem = async () => {
            callGetCartItem();
        }

        setIsLoading(true);
        getCartItem();
    }, []);

    useEffect(() => {
        callGetCartItem();
    }, [callTermsFinished]);

    useEffect(() => {

        if (userPermissions && userPermissions.cartOnlyOption) {
            let rbOptionBook = document.getElementById(`rbStructureOption_${cartItemId}`);
            if (rbOptionBook)
                rbOptionBook.click();
        }

    }, [userPermissions, callTermsFinished])

    useEffect(() => {
        if (cartItem && cartItem.rooms && cartPaxesNames && cartPaxesNames.length > 0) {
            for (let i = 0; i < cartItem.rooms.length; i++) {
                let room = cartItem.rooms[i];
                for (let j = 0; j < room.passengers.length; j++) {
                    let pax = room.passengers[j];
                    let e = { target: { value: pax.index }, Index: pax.index };

                    let ddlPaxId = `ci_${cartItem.cartItemId}_room_${room.sequence}_pax_${pax.index}_ddlPax`;
                    let ddlPax = document.getElementById(ddlPaxId);
                    if (ddlPax) {
                        ddlPax.value = pax.index;
                        handleChangePax(cartItem, cartPaxesNames, handleBookParameters, room.sequence, pax.index, ddlPaxId, e);
                    }
                }
            }
        }
    }, [cartPaxesNames]);


    async function callGetCartItem() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/GetCartItem/' + cartItemId, requestOption);
        const response = await fetchedRes.json();

        setCartItem(response);
        setIsLoading(false);
    }

    async function callUpdateQuotationItemStatus(quotationId, quotationItemId) {
        let inputData =
        {
            QuotationId: quotationId,
            QuotationItemId: quotationItemId,
            Status: 70,
            LogNote: "Status changed from CartQuotation due to BookingTerm error",
            QuotationSettings: { BaseUrl: "" }
        };

        const requestOption =
        {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/UpdateQuotationItemStatus', requestOption);
        const response = await fetchedRes.json();

        let responseQuotation = await registerSessionQuotation(quotationId, quotationItemId, true, cartItemId);
        if (responseQuotation) {
            setQuotationInfoSession(responseQuotation.quotationInfo);
            window.location.href = responseQuotation.url;
        }
    }

    function onClickChangeProduct(url, quotationId, quotationItemId) {
        if (quotationId !== null && quotationId !== undefined && quotationItemId !== null && quotationItemId !== undefined) {
            callUpdateQuotationItemStatus(quotationId, quotationItemId);
        }
        else {
            window.location.href = url;
        }
    }


    const structureBookOperationOnChange = (e) => {
        let id = e.target.id;
        let val = e.target.value;

        // --- set label class ---
        var selectedLabelId = id === `rbStructureBook_${cartItemId}` ? `lblStructureBook_${cartItemId}` : `lblStructureOption_${cartItemId}`;
        var notSelectedLabelId = selectedLabelId === `lblStructureBook_${cartItemId}` ? `lblStructureOption_${cartItemId}` : `lblStructureBook_${cartItemId}`;
        let lblSelected = document.getElementById(selectedLabelId);
        let lblNotSelected = document.getElementById(notSelectedLabelId);

        if (lblSelected) {
            lblSelected.classList.remove("btn-flight-operation");
            lblSelected.classList.add("btn-flight-operation-selected");
        }

        if (lblNotSelected) {
            lblNotSelected.classList.remove("btn-flight-operation-selected");
            lblNotSelected.classList.add("btn-flight-operation");
        }

        // --- set label class ---

        if (val === "on") {
            let data = id === "rbStructureBook_" + cartItemId ? 3 : 2;
            handleReservationData(cartItem.cartItemId, "ProductAction", data);
        }
    }

    const renderAction = () => {
        if (isCartQuotation) {
            return (<></>);
        }
        else {
            return (
                <>
                    {
                        (!userPermissions || !userPermissions.cartOnlyOption) && <>
                            <input id={`rbStructureBook_${cartItemId}`} type="radio" className="btn-check" name={`rbStructureBookOperation_${cartItemId}`} autoComplete="off" onChange={e => structureBookOperationOnChange(e)} defaultChecked={true} />
                            <label id={`lblStructureBook_${cartItemId}`} className="btn btn-sm btn-flight-operation-selected mr-2" htmlFor={`rbStructureBook_${cartItemId}`}>{t("Button:Book")}</label>
                        </>
                    }
                    {
                        (cartItem.canOptionBook || (userPermissions && userPermissions.cartOnlyOption)) && <>
                            <input id={`rbStructureOption_${cartItemId}`} type="radio" className="btn-check btn-sm" name={`rbStructureBookOperation_${cartItemId}`} autoComplete="off" onChange={e => structureBookOperationOnChange(e)} defaultChecked={false} />
                            <label id={`lblStructureOption_${cartItemId}`} className="btn btn-sm btn-flight-operation" htmlFor={`rbStructureOption_${cartItemId}`}>{t("MyBook:Option")}</label>
                        </>
                    }
                </>
            );
        }
    }

    function onClickDeleteNotAvailable() {
        onOpenModalConfirmDelete(cartItemId);
    }

    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : (
                    <>
                        <div className={"card mt-3 p-3 " + productNotAvailableClass_Border(productBookParams)}>
                            <div className="card p-3">
                                <div className={productNotAvailableClass_Opacity(productBookParams)}>

                                    <div className="row">
                                        <div className="col-7">
                                            <M3IconProductType productType={cartItem.productType} externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                                            <span className="titlecard">{cartItem.name}</span>
                                            {getStructureStars(cartItem.category)}
                                        </div>
                                        <div className="col-2">
                                            <div class="w-100">
                                                <M3Pax adt={cartItem.adults}
                                                    chd={cartItem.children}
                                                    chdAges={cartItem.childrenAges}
                                                    inf={cartItem.infants}
                                                    infAges={cartItem.infantAges}
                                                    extraOptions={{ showInfant: false, iconClass: "icon-15" }} />
                                            </div>
                                        </div>
                                        <div className="col-3 text-right">
                                            {cartItem.status && cartItem.status === 'OnRequest' && <span className="text-warning h6 mb-0 small d-block"><var>{cartItem.status}</var></span>}
                                            {cartItem.supplier && <span className="text-info small d-block"><var>{cartItem.supplier.supplierName}</var></span>}
                                        </div>
                                    </div>

                                    <div><span>{cartItem.address}</span></div>
                                    <div className="btn-group ml-4" role="group" aria-label="Basic radio toggle button group">
                                        {/*<input type="radio" className="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked="" />*/}
                                        {/*<label className="btn btn-outline-success btn-sm" for="btnradio1">Prenota</label>*/}
                                        {/*<input type="radio" className="btn-check" name="btnradio" id="btnradio2" autocomplete="off" />*/}
                                        {/*<label className="btn btn-outline-success btn-sm" for="btnradio2">opziona</label>*/}
                                    </div>

                                    {callTermsFinished &&
                                        <React.Fragment>
                                            <div className="d-inline float-end">
                                                <button className="btn btn-sm btn-danger" onClick={() => onOpenModalConfirmDelete(cartItemId)}>{t("Button:Delete")}</button>
                                            </div>

                                            <div className="d-inline float-end mr-4">
                                                {renderAction()}
                                            </div>
                                        </React.Fragment>
                                    }

                                    <p><label>{formatDateTime(cartItem.dateFrom, cultureName)} - {formatDateTime(cartItem.dateTo, cultureName)}</label></p>
                                </div>
                                {cartItem.rooms.map(room =>
                                    <div key={room.sequence} className={productNotAvailableClass_Opacity(productBookParams)}>
                                        <div class="w-100 mb-1">
                                            <label>Room <var>{room.sequence}: {room.roomName}</var></label>
                                            {
                                                (
                                                    (room.notes !== null && room.notes !== undefined && room.notes.length > 0) ||
                                                    (room.resortFee !== null && room.resortFee !== undefined && room.resortFee.length > 0)
                                                ) && <label className="ml-1 cursor-pointer text-info" data-bs-toggle="modal" data-bs-target={"#modal_CartItem_" + cartItemId + "_" + room.sequence}>
                                                    <M3Icon iconName="Info" externalClass="icon-10 text-info" />
                                                </label>
                                            }
                                        </div>
                                        <div className="row">
                                            {
                                                room.cancelPolicy && <div className="col-12">
                                                    <label className="penalty">
                                                        <span className={room.cancelPolicy.isFree ? "penalty free" : "penalty"}>
                                                            <M3Icon iconName="CancelPolicy" externalClass="icon-10 mr-1" />
                                                            {room.cancelPolicy.isFree ? t("CancelPolicy:FreeCancellation") + " " + formatDateTime(room.cancelPolicy.expirationDate, { twoDigits: true }) : t("CancelPolicy:PenaltyCancellation")}
                                                        </span>
                                                        <CancelPolicyModal cancellationPolicy={room.cancelPolicy} />
                                                    </label>
                                                </div>

                                            }
                                        </div>

                                        {/*Passengers*/}
                                        {!callTermsFinished
                                            ? (<><Loading textMsg={t(`Cart:LoadingBookingTerms`)} isSmall={true} /></>)
                                            : (
                                                <>
                                                    {productBookParams !== null && productBookParams !== undefined && productBookParams.IsAvailable
                                                        ? (
                                                            <>
                                                                {room.passengers.map(pax =>
                                                                    <div key={pax.index} className="row mt-2">
                                                                        <div className="form-floating col-md-3">
                                                                            <select id={`ci_${cartItem.cartItemId}_room_${room.sequence}_pax_${pax.index}_ddlPax`} className="form-select border-0 border-bottom border-dark" aria-label="Fornitore" onChange={(e) => handleChangePax(cartItem, cartPaxesNames, handleBookParameters, room.sequence, pax.index, `ci_${cartItem.cartItemId}_room_${room.sequence}_pax_${pax.index}_ddlPax`, e)}>
                                                                                <option value="-1">{t("Button:Select")}&nbsp; pax</option>
                                                                                {cartPaxesNames !== null && cartPaxesNames !== undefined && cartPaxesNames.length > 0
                                                                                    ? (
                                                                                        cartPaxesNames.map(paxName =>
                                                                                            <option key={paxName.Index} value={paxName.Index}>{paxName.BpFirstName[0].Value} {paxName.BpLastName[0].Value}</option>
                                                                                        )
                                                                                    )
                                                                                    : (<></>)
                                                                                }
                                                                            </select>
                                                                            <label htmlFor={`ci_${cartItem.cartItemId}_room_${room.sequence}_pax_${pax.index}`}>
                                                                                <span className={BookParameterMandatoryCssClass.Mandatory}>{t("MyBook:Passenger")}&nbsp; {pax.paxType} {pax.index}:</span>
                                                                            </label>
                                                                        </div>

                                                                      {renderPaxBookParameters(cartItem, productBookParams, staticBookParams, handleBookParameters, room.sequence, pax.index)}
                                                                    </div>
                                                                )}

                                                               {renderOptionBookParameters(cartItem, productBookParams, handleBookParameters, room.sequence)}
                                                            </>
                                                        )
                                                        : (<></>)
                                                    }
                                                </>
                                            )
                                        }


                                        <div className="modal fade" id={"modal_CartItem_" + cartItemId + "_" + room.sequence} tabIndex="-1" aria-labelledby={"modal_CartItem_Label_" + cartItemId + "_" + room.sequence} aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id={"modal_CartItem_Label_" + cartItemId + "_" + room.sequence}>Note camera</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        {/*Resort Fee*/}
                                                        {room.resortFee !== null && room.resortFee !== undefined && room.resortFee.length > 0 && <div className="row">
                                                            <div className="col-12">
                                                                <h5>Resort Fee</h5>
                                                            </div>
                                                            <div className="col-12">
                                                                {
                                                                    room.resortFee.map((txt, index) => {
                                                                        return <React.Fragment key={index}>{txt}<br /></React.Fragment>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        }

                                                        {/*Note Camera*/}
                                                        {room.notes !== null && room.notes !== undefined && room.notes.length > 0 && <div className="row">
                                                            <div className="col-12">
                                                                <h5>Note Camera</h5>
                                                            </div>
                                                            <div className="col-12">
                                                                {
                                                                    room.notes.map((txt, index) => {
                                                                        return <React.Fragment key={index}>{txt}<br /></React.Fragment>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className='modal-footer'>
                                                        <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t('Button:Close')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )}

                                {/*Product Not Available*/}
                                {productBookParams !== null && productBookParams !== undefined && !productBookParams.IsAvailable
                                    ? (
                                        <AvailableTour
                                            detailUrl={productBookParams.TermsError_DetailUrl}
                                            listUrl={productBookParams.TermsError_ListUrl}
                                            quotationId={productBookParams.QuotationId}
                                            quotationItemId={productBookParams.QuotationItemId}
                                            deleteFn={onClickDeleteNotAvailable}
                                            changeProductFn={onClickChangeProduct}
                                            mode="CART"
                                        />
                                    )
                                    : (<></>)
                                }
                            </div>

                            {/* --- DEBUG --- */}
                            {debug === "1"
                                ? (<><br /><br />{JSON.stringify(productBookParams)}</>)
                                : (<></>)
                            }
                            {/* --- DEBUG --- */}
                        </div>
                    </>
                )
            }
        </>
    )
}