import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatPrice, formatDateTime, getStatusClass } from '../../../js/Utils.js';
import { M3IconProductType } from '../../Common/M3IconProductType.js';

export const OrderLight = ({ order, showNetPrices, pricingB2C }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const renderProducts = (pratiche) => {
        return (
            <>
                {pratiche.map(pratica =>
                    <tr key={pratica.idPratica} data-numpratica={pratica.wsBookingId}>
                        <td scope="row">
                            <M3IconProductType productType={pratica.tipoPratica} externalClass="h2" />
                        </td>
                        <td>
                            <div className="d-flex align-items-lg-start">
                                {pratica.productName}
                            </div>
                        </td>
                        <td>{formatDateTime(pratica.startDate, cultureName, { noYear: true })}</td>
                        <td>{formatDateTime(pratica.endDate, cultureName, { noYear: true })}</td>
                        <td>{formatPrice((!pricingB2C ? pratica.invoicePrice : pratica.prezzoCliente), pratica.currency, cultureName)}</td>
                        <td className={`d-flex align-items-end status ${getStatusClass(pratica.status)}`}>{t(`statiPrenotazione:` + pratica.status)}</td>
                        <td>{pratica.wsBookingId}</td>
                    </tr>
                )}
            </>    
        );
    }

    return (
        <div className="card mb-4">
            <div className="row no-gutters">
                <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
                    {/*Order Header*/}
                    <div>
                        <span className="h5 m-4 pt-5"><label>{t('MyBook:NOrder')}:</label> {order.numeroOrdine} <label>del</label> {formatDateTime(order.bookDate, cultureName, { twoDigits: true })}</span>
                    </div>

                    {/*Pratiche*/}
                    <div className="p-0 p-md-4 mb-4">
                        <table className="mybook-table table-hover p-0">
                            <thead>
                                <tr>
                                    <th scope="col">{t("MyBook:Type")}</th>
                                    <th scope="col">{t("MyBook:Product")}</th>
                                    <th scope="col">{t("MyBook:Start")}</th>
                                    <th scope="col">{t("MyBook:End")}</th>
                                    <th scope="col">{t("MyBook:Value")}</th>
                                    <th scope="col">{t("MyBook:State")}</th>
                                    <th scope="col">{t("MyBook:NPractice")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderProducts(order.pratiche)}
                            </tbody>
                        </table>
                    </div>
                    {/*<div className="myBook-card-body"></div>*/}
                </div>

                {/*Price Bar*/}
                <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 mybook-priceBar status unset bg-gray-300">
                    <div className="d-table w-100 h-100">
                        <div className="d-table-row h-50">
                            <div className="d-table-cell align-top">
                                <span className="text-muted font-small d-block">{t("MyBook:OrderTotal")}</span>
                                <h3>{formatPrice((!pricingB2C ? order.totalInvoicePrice : order.totalPrezzoCliente), order.currency, cookies.get("CultureName"))}</h3>
                                {showNetPrices === '1'
                                    ? (
                                        <div>
                                            <p><span><label>{t("MyBook:Net")}:</label> {formatPrice(order.totalNetPrice, order.currency, cultureName)}</span></p>
                                            {order.pratiche[0].isCommissionable
                                                ? (<p><span><label>{t("MyBook:Commission")}:</label> {formatPrice(order.totalCommission, order.currency, cultureName)}</span></p>)
                                                : (<></>)
                                            }
                                            <p><span><label>{t("MyBook:Markup")}:</label> {formatPrice(order.totalMarkup, order.currency, cultureName)}</span></p>
                                            <p><span><label>{t("MyBook:Fee")}:</label> {formatPrice(order.totalFee, order.currency, cultureName)}</span></p>
                                        </div>
                                    )
                                    : (<></>)
                                }
                                <div><span><label>Rif.Pax.:</label> {order.refPax}</span></div>
                                <div>
                                    <p><span><label>{t("MyBook:StarDate")}:</label>{formatDateTime(order.startDate, cultureName, { twoDigits: true })}</span></p>
                                    <p><span><label>{t("MyBook:EndDate")}:</label>{formatDateTime(order.endDate, cultureName, { twoDigits: true })}</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="d-table-row h-50">
                            <div className="d-table-cell align-bottom">
                                <div className="btn btn-sm tp-btn-select mt-2">
                                    <a href={`mybook/orders/detail/${order.numeroOrdine}`} className="badge text-decoration-none mt-2">{t("MyBook:Detail")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
