import React from 'react';
import { Modal } from '../../Common/Modal.js';
import configData from "../../../appsettings.json";

export const OptionConfirmModal = ({ pnr, productType, productName }) => {

    async function handleOnConfirm() {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ PNR: pnr, ProductType: productType })
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/optionconfirm', requestOption);
        const response = await fetchedRes.json();

        let outObj = {
            success: response.success,
            bodyHtml: response.success
                ? "<p><span class='penalty free'>Prodotto confermato con successo</span></p>"
                : "<p><span class='penalty'>Si &egrave; verificato un errore durante la conferma dell'opzione.</span></p>"
        };

        return outObj;
    }

    async function handleOnOpen() {
        let outObj = {
            success: true,
            bodyHtml: ""
        };

        outObj.bodyHtml = "<span>Prodotto: <label>" + productName + "</label></span>";
        outObj.bodyHtml += "<p><span>Clicca su 'Conferma Opzione' per procedere con la conferma.<span></p>";

        return outObj;
    }

    const handleOnClose = () => {
        window.location.reload(true);
    }

    const modalSettings = {
        id: 'OptionConfirm_' + pnr,
        title: 'Conferma opzione N. ' + pnr,
        body: null,
        lblClose: 'Chiudi',
        lblConfirm: 'Conferma opzione',
        isLoading: true,
        loadingMsg: 'Caricamento in corso...',
        showBtnConfirm: true,
        showBtnClose: true,
        fnOnConfirm: handleOnConfirm,
        fnOnOpen: handleOnOpen,
        fnOnClose: handleOnClose
    };

    return (
        <Modal {...modalSettings} />
    );
}
