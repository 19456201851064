import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { getTitleClass, getTitleLabel } from "../TemplateUtils";

export const TemplateInclusions = ({ inclusions }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    return (
        <>
            {inclusions && inclusions.length > 0 &&
                inclusions.filter(x => x.inclusions.length > 0).map((inclusion, idx) => {
                    return (
                        <div key={idx} className="col-12 mt-2">
                            <div className="card shadow p-2">
                            <h5 className={getTitleClass(inclusion.inclusionType)}>{getTitleLabel(inclusion.inclusionType, inclusion.title, t)}</h5>
                            <ul className="list-group border-0">
                                {inclusion.inclusions && inclusion.inclusions.length > 0 && inclusion.inclusions.map((inc, idx) => {
                                    return (<li key={idx} className="list-group-item">{inc.description}</li>)
                                })}
                                </ul>
                            </div>
                        </div>
                    )
                })
            }
        </>
    );
}