import React from 'react';
import { M3StructureStars } from '../../../Common/M3StructureStars';
import { imageOnLoad } from '../../../../js/CDNUtility';
import configData from "../../../../appsettings.json";

export const StructureBoxStatic = ({ structure, codiceProdotto, index, modalOpenStructureId }) => {
    let imageOnLeft = index % 2 === 0;

    function onModalOpenStructureId(productId) {
        modalOpenStructureId(productId);
    }

    return (
        <>
            <div id={`structureDetail_${structure.structureId}`} className="w-100">
                <div className="card border-gray-600 my-4 animate-up-5 shadow">
                    <div className="row no-gutters">
                        {/*thumb left*/}
                        {imageOnLeft && <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div className="rounded" style={{ backgroundImage: "url(" + (structure.thumbUrl && structure.thumbUrl.length > 0 ? encodeURI(structure.thumbUrl[0]) : imageOnLoad()) + "), url(" + imageOnLoad() +")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100%" }}>
                            </div>
                        </div>}

                        {/*body*/}
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            <div className="myBook-card-body">
                                <div>
                                    <div className="h5">
                                        <var>{structure.name}</var>
                                        <span className="float-end">
                                            <label className="btn-neutral text-decoration-underline px-2" onClick={e => onModalOpenStructureId(codiceProdotto)}>Maggiori Dettagli</label>
                                        </span>
                                    </div>
                                    <span>
                                        <var>
                                            <M3StructureStars category={structure.category} />
                                        </var>
                                    </span>
                                    <div className="w-100" style={{ maxHeight: '200px', height: '200px', overflow: 'hidden' }}>
                                        <span className="text-justify">
                                            {structure.descriptions && structure.descriptions.length > 0
                                                ? (<><p dangerouslySetInnerHTML={{ __html: structure.descriptions[0].description }}></p></>)
                                                : (<></>)
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*thumb right*/}
                        {!imageOnLeft && <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div className="rounded" style={{ backgroundImage: "url(" + (structure.thumbUrl.length > 0 ? encodeURI(structure.thumbUrl[0]) : imageOnLoad(configData.Settings.Products.Structure.IdTipoProdotto)) + "), url(" + imageOnLoad(configData.Settings.Products.Structure.IdTipoProdotto) + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100%" }}>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    );
}
