import React, { useState, useEffect } from 'react';
import { M3Icon } from "../../../Common/M3Icon";

export const CruiseSummary = ({ cruise, isAlternative }) => {
    const [fontSize, setFontSize] = useState('');

    useEffect(() => {
        if (isAlternative)
            setFontSize('h075');
    }, [cruise]);

    return (
        <>
            <div>
                <data m3ico="Cruise mr-2 icon-15">
                    <M3Icon iconName="Cruise" externalClass="mr-2 icon-15 text-custom" hasLabel={false} />
                </data>

                <span className={fontSize}>{cruise.name}</span>
            </div>
        </>
    );
}
