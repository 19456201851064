import React, { Component } from 'react';

export const LayoutDocument = ({ children }) => {
    return (
        <div>
                {children}
        </div>
    );
}

