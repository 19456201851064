import React from 'react';
import { formatPrice, formatDateTime } from '../../../../../js/Utils.js';
import { useTranslation } from 'react-i18next';


export const PrintAccountBalanceScadenziario = ({ orderNr, visualizationType, userType, grouped, cultureName, timetable }) => {
    const { t } = useTranslation();

    return (
        <>
            <span style={{ borderBottom: '1px solid #edf0f7'}}>
                <h3>{t("scadenziario:item")}:
                    {timetable.numDeadlines === 0 ? (<span>  {t("MyBook:NoPaymentsExpected")}</span>) : ''}
                </h3>
            </span>

            <table className="table mb-0 document-invoice">
                <tbody className="document-invoice">
                {
                    timetable.deadlineGroups?.map(
                        (payment) => (
                            <>
                                <tr key={payment.index}>
                                    <td style={{ fontSize: '14px', textAlign: 'left', borderBottom: '1px solid #edf0f7', width: '5%' }}>
                                        <span>{t("MyBook:Expiring")} {formatDateTime(payment.deadlineDate, cultureName, { twoDigits: true })}: </span>
                                        {formatPrice(payment.amount, payment.currency, cultureName)}
                                    </td>
                                </tr>
                                
                                {grouped === 'false' &&
                                    payment.deadlineProducts?.map(
                                        (dp) => (
                                            <tr key={dp.index} >
                                                <td style={{ fontSize: '12px', textAlign: 'left', borderBottom: '1px solid #edf0f7' }}>
                                                </td>
                                                <td style={{ fontSize: '12px', textAlign: 'left', borderBottom: '1px solid #edf0f7' }}>
                                                    {dp.productName}
                                                </td>
                                                <td style={{ fontSize: '12px', textAlign: 'left', borderBottom: '1px solid #edf0f7' }}>
                                                    {formatPrice(dp.amount, payment.currency, cultureName)}
                                                </td>
                                                <td style={{ fontSize: '12px', textAlign: 'left', borderBottom: '1px solid #edf0f7' }}>
                                                    {userType === 'A' ? dp.commissionPerc + "%" : ''}
                                                </td>
                                                <td style={{ fontSize: '12px', textAlign: 'left', borderBottom: '1px solid #edf0f7' }}>
                                                    {userType === 'A' ? formatPrice(dp.commission, payment.currency, cultureName) : ''}
                                                </td>
                                            </tr>
                                        )
                                    )
                                }
                            </>
                        )
                    )
                }
                </tbody>
            </table>
        </>
    );
}