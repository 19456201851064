import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import configData from "../../../appsettings.json";
import { DebounceInput } from 'react-debounce-input';
import { handleError } from "../../../js/Utils";
import { useTranslation } from 'react-i18next';

export const QuotationSelectAgency = (props) => {
    const { t } = useTranslation();

    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [selectCustomer, setSelectCustomer] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [filterByName, setFilterByName] = useState('');
    const [loadingCustomerName, setLoadingCustomerName] = useState(false);

    const [users, setUsers] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);

    useEffect(() => {
        setUsers([{
            username: props.items.username,
            id: props.items.userId,
            identity: ''
        }]);

        setCustomers([{
            id: props.items.agencyId,
            ragioneSociale: props.items.agencyName
        }]);

        setSelectCustomer(true);

        setFilterByName(props.items.agencyName);

    }, [props]);

    function updateCustomerValue(customerName) {
        setFilterByName(customerName);
        setSelectCustomer(false);
        getCustomers(customerName);
    }

    function getCustomers(customerName) {
        setUsers([]);
        setLoadingCustomerName(true);

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: customerName
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}customer/getallcustomers`, requestOption)
            .then((res) => handleError(res))
            .then((items) => {
                setLoadingCustomerName(false);
                setCustomers(items);
            })
            .catch((err) => {
                setLoadingCustomerName(false);
                console.error(err);
                setCustomers([]);
            });
    }

    function onSelectCustomer(customer) {
        setCustomers([customer]);
        setSelectCustomer(true);
        getUsers(customer.id);
    }

    function getUsers(customerId) {
        setUsers([]);
        setLoadingUsers(true);

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}user/getusersbystakeholderid/${customerId}`, requestOption)
            .then((res) => handleError(res))
            .then((items) => {
                setLoadingUsers(false);
                setUsers(items);
            })
            .catch((err) => {
                setLoadingUsers(false);
                console.error(err);
                setUsers([]);
            });
    }

    function onSelectUser(item) {
        props.onCallSelectAgency(item);
    }

    return (
        <>
            <DebounceInput
                className="form-control"
                minLength={3}
                debounceTimeout={1000}
                placeholder="Customer name"
                value={filterByName}
                onChange={event => updateCustomerValue(event.target.value)} />
            <div>
                {loadingCustomerName && <div className="spinner-border spinner-border-sm text-dark" role="status" style={{ 'fontSize': '9px' }}>
                    <span className="sr-only"></span>
                </div>
                }
                {
                    !loadingCustomerName && customers && customers.length > 0 && <>
                        <div className="row">
                            <div className="col-4"><b>{t("MyBook:Company")}</b></div>
                            <div className="col-3"><b>{t("MyBook:Address")}</b></div>
                            <div className="col-3"><b>{t("MyBook:City")}</b></div>
                            <div className="col-2"></div>
                        </div>
                        {<div className="row" style={{ "maxHeight": "140px", "overflowY": "auto" }}>
                            {
                                customers.map((item, key) => {
                                    return <React.Fragment key={key}>
                                        <div className="col-4 my-1">{item.ragioneSociale}</div>
                                        <div className="col-3 my-1">{item.address}</div>
                                        <div className="col-3 my-1">{item.city}</div>
                                        <div className="col-2 my-1">
                                            <button className="btn-primary-outline bg-transparent border-0 text-primary text-decoration-underline" onClick={e => onSelectCustomer(item)}>
                                                {t("MyBook:Select")}
                                            </button>
                                        </div>
                                    </React.Fragment>
                                })
                            }
                        </div>
                        }
                    </>
                }
                {
                    filterByName && !loadingCustomerName && (!customers || customers.length === 0) && <> {t("MyBook:NothingCustomerFound")}</>
                }
                {
                    loadingUsers && <div className="spinner-border spinner-border-sm text-dark" role="status" style={{ 'fontSize': '9px' }}>
                        <span className="sr-only"></span>
                    </div>
                }
                {
                    !loadingUsers && (users && users.length > 0) && <>
                        <div className="row  my-2">
                            <div className="col-4"><b>{t('General:User')}</b></div>
                            <div className="col-2"></div>
                        </div>
                        {
                            <div className="row" style={{ "maxHeight": "140px", "overflowY": "auto" }}>
                                {
                                    users.map((item, key) => {
                                        return <React.Fragment key={key}>
                                            <div className="col-5 my-1">{item.username}</div>
                                            <div className="col-2 my-1">
                                                <button className="btn-primary-outline bg-transparent border-0 text-primary text-decoration-underline" onClick={e => onSelectUser(item)}>
                                                    {t("MyBook:Select")}
                                                </button>
                                            </div>
                                            <div className="col-5 my-1"></div>
                                        </React.Fragment>
                                    })
                                }
                            </div>
                        }
                    </>
                }
                {
                    selectCustomer && !loadingUsers && (!users || users.length === 0) && <> {t("MyBook:NothingUserFound")}</>
                }
            </div>
        </>
    );
}