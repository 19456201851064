import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Cookies from 'universal-cookie';
import configData from "../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { handleError } from "../../js/Utils";
import { WebContent } from "../WebContent/WebContent";
import { Loading } from '../Common/Loading';
import { Error } from '../Common/Error';
import { BlogListItem } from './components/BlogListItem';

export const BlogList = () => {
    const { t } = useTranslation();

    const cookies = new Cookies();

    const [cultureName, setCultureName] = useState(cookies.get("CultureName"));
    const [articles, setArticles] = useState([]);
    const [totalArticles, setTotalArticles] = useState(0);
    const [toSkip, setToSkip] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    // paging
    const itemsPerPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(0);

    useEffect(() => {
        let cultureCode = cultureName;
        getBlogArticles(itemsPerPage, toSkip, cultureCode);
    }, [])

    function getBlogArticles(itemToSee, skip, cultureCode) {
        if (!cultureCode) {
            cultureCode = 'it-IT';
            setCultureName(cultureCode);
        }

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                cultureCode: cultureCode,
                blogId: 1,
                blogArticleId: null,
                topResult: null,
                toTake: itemToSee,
                toSkip: skip
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}blog/getblogarticles`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setArticles(item);

                setIsLoading(false);
                setIsError(false);

                if (totalArticles === 0 && item.length > 0) {
                    renderPaginator(item[0].totalArticles, pageSelected);
                    paginationBlogArticles(pageSelected, false);
                }

            })
            .catch((err) => {
                console.error(err);
                setIsLoading(false);
                setIsError(true);
            });
    }

    const handlePageClick = async e => {
        paginationBlogArticles(e.selected, true);
    };

    function renderPaginator(totalItems, pageSelected) {
        setTotalArticles(totalItems);
        setPageCount(Math.ceil(totalItems / itemsPerPage));
        setPageSelected(pageSelected);
    }

    function paginationBlogArticles(pagePosition, callApi) {
        var startFrom = 0;
        if (pagePosition > 0)
            startFrom = itemsPerPage + pagePosition - 1;

        setToSkip(startFrom);
        setPageSelected(pagePosition);

        if (callApi)
            getBlogArticles(itemsPerPage, startFrom, cultureName);
    }

    return (
        <>
            <div className="mt-6">
                <WebContent extraParams={[{ key: 'PAGE', value: 'BLOGLIST' }]} noShowLoading={true} />
            </div>
            <div className="container custom_container mt-6">
                {
                    isError && <div className="text-center"><Error textMsg={t('Error:Blog')} /></div>
                }
                {
                    articles && articles.length > 0 ? articles.map((article, key) => {
                        return <div key={key} className="row">
                            <BlogListItem article={article} cultureCode={cultureName} />
                        </div>
                    })
                        : isLoading ? <div className="text-center"><Loading textMsg={t('Loading:BlogList')} /></div>
                            : !isError ? <div className="text-center"><Error textMsg={t('Error:Blog')} /></div> : <></>
                }
                {
                    totalArticles > itemsPerPage && <div className="row">
                        <div className="col-12">
                            <nav aria-label="Page navigation comments" className="mt-4">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel={t('pagination:nextLabel')}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel={t('pagination:previousLabel')}
                                    renderOnZeroPageCount={null}
                                    forcePage={pageSelected}

                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    marginPagesDisplayed={2}
                                    containerClassName="pagination justify-content-center"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                />
                            </nav>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}