import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

export const ProductDataImages = ({ images, onUpdateData }) => {
    const { t } = useTranslation();

    const dtRef = useRef(null);
    const [normalizeImages, setNormalizeImages] = useState([]);

    useEffect(() => {
        if (images)
            setNormalizeImages(images);
    }, [images])

    const countryBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img src={rowData.path} className="w-50" />
            </div>
        );
    };

    const bodyDelete = (rowData) => {
        return (
            <div className="cursor-pointer" onClick={_ => onRemove(rowData)}>
                <span class="material-icons-outlined text-danger">delete</span>
            </div>
        );
    };


    const onRowEditComplete = (e) => {
        let updImages = [...normalizeImages];
        let { newData, index } = e;
        updImages[index] = newData;

        onUpdateData(updImages);
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    function onRemove(rowData) {
        let updImages = normalizeImages.filter(x => x.id !== rowData.id);
        onUpdateData(updImages);
    }

    function onCallAddRow() {
        let lastNumber = 1;
        let updImages = [...normalizeImages]
        if (!updImages) {
            updImages = [];
        } else {
            lastNumber = 1;

            if (updImages && updImages.length > 0)
                lastNumber = updImages[updImages.length - 1].number + 1;
        }

        let fakeId = Math.floor(Math.random() * 100000);

        updImages.push({
            id: fakeId,
            number: lastNumber,
            url: ''
        });

        setNormalizeImages(updImages);
    }

    return (
        <>
            <div className="row">
                <DataTable value={normalizeImages}
                    ref={dtRef}
                    dataKey="id"
                    editMode="row"
                    onRowEditComplete={onRowEditComplete}
                    tableStyle={{ minWidth: '50rem' }}>
                    <Column field="number" header="Position"></Column>
                    <Column header="Image" body={countryBodyTemplate}></Column>
                    <Column field="path" header="Url" editor={(options) => textEditor(options)}></Column>
                    <Column rowEditor={true}></Column>
                    <Column body={bodyDelete}></Column>
                </DataTable>
                <div className="col-12 text-right">
                    <button type="button"
                        className="btn bt-sm tp-btn-confirm"
                        onClick={_ => onCallAddRow()}>
                        {t("MyBook:Add")}
                    </button>
                </div>
            </div>
        </>
    );
}
