import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'universal-cookie';

import * as resources from './translations';

const cookies = new Cookies();

i18n.use(initReactI18next)
    .init({
        partialBundledLanguages: true,
        ns: [],
        defaultNS: 'common',
        resources,
        lng: cookies.get('CultureName'),
        fallbackLng: 'it',

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

export default i18n;
