import React from 'react';

export const FooterPDFPrint = ({ footerElements }) => {

    return (<>
        {footerElements && footerElements.map(item => {
            return <div dangerouslySetInnerHTML={{ __html: item.html }}>
            </div>
        })}
    </>
    )
}
