import React, { useState, useEffect } from 'react';

export const BlogListItem = ({ article, cultureCode }) => {
    const [detailUrl, setDetailUrl] = useState('');

    function getDay() {
        let myDate = new Date(article.createDate);
        return Intl.DateTimeFormat(cultureCode, { day: "2-digit" }).format(myDate);
    }

    function getMonth() {
        let myDate = new Date(article.createDate);
        return Intl.DateTimeFormat(cultureCode, { month: "short" }).format(myDate);
    }

    function getYear() {
        let myDate = new Date(article.createDate);
        return Intl.DateTimeFormat(cultureCode, { year: "numeric" }).format(myDate);
    }

    useEffect(() => {
        if (article.urlOverride)
            setDetailUrl(article.urlOverride);
        else
            setDetailUrl('blog\\' + article.code);
    }, [])

    return (
        <div className="col-lg-12">
            <div className="blog_main">
                <article className="blog_post_item zoom_in_effect">
                    <div className="post_media">
                        <a href={detailUrl}>
                            <img className="zoom_in_img" src={article.imageUrl} alt="" />
                        </a>
                        {
                            article.createDate && <div className="post_date">
                                {getDay()}
                                <span className="text-capitalize month-text">{getMonth()}</span>
                                <span className="text-capitalize year-text">{getYear()}</span>
                            </div>
                        }
                    </div>
                    <div className="media_blog_content">
                        <a href={detailUrl}>
                            <h2 className="blog_title">{article.title}</h2>
                        </a>
                        {article.summary && <p dangerouslySetInnerHTML={{ __html: article.summary }}></p>}
                        <div className="post_bottom d-block w-100 text-right">
                            <a href={detailUrl} className="min_btn">Leggi</a>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    );
}