import React, { useState, useEffect } from 'react';
import { MenuBarVertical } from '../MenuBarVertical';
import { Loading } from '../../Common/Loading';
import { getModuleIcon, createRenderHtml } from '../../../js/Utils.js';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { M3IconProductType } from '../../Common/M3IconProductType';

export const Markup = () => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [markup, setMarkup] = useState([]);
    const [newMarkup, setNewMarkup] = useState([]);

    const [saveLoading, setSaveLoading] = useState(false);
    const [saveCalled, setSaveCalled] = useState(false);
    const [saveResMessage, setSaveResMessage] = useState();


    useEffect(() => {
        const getMarkup = async (inputData) => { const response = callGetMarkup(inputData); }
        getMarkup();
    }, []);


    async function callGetMarkup() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'customer/GetCustomerMarkupFee', requestOption);
        const response = await fetchedRes.json();

        if (response !== null && response !== undefined) {
            setMarkup(response);
            setNewMarkup(response);
        }

        setIsLoading(false);
    }

    async function callSetMarkup(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'customer/SetCustomerMarkupFee', requestOption);
        const response = await fetchedRes.json();

        let resMsg = response.success
            ? "<p className='penalty free'>"+ t("MyBook:SuccessfulData") +"</p>"
            : "<p className='penalty'>" + t("MyBook:SuccessfulData") +"</p>";

        setSaveLoading(false);
        setSaveCalled(true);
        setSaveResMessage(resMsg);
    }


    const onValChange = (target) => {
        let type = target.id.split('_')[0];
        let idModulo = Number(target.id.split('_')[1]);
        let value = target.value;

        let mkpFeeModule = newMarkup.markupFeeModuli.find(x => x.idModulo === idModulo);

        switch (type) {
            case "mkp": // Markup
                {
                    mkpFeeModule.markup = Number(value);
                }
                break;

            case "fee": // Booking Fee
                {
                    let bookingScope = Number(target.id.split('_')[2]);
                    let feeType = target.id.split('_')[3];

                    // questo mi serve per settare il valore della Fee
                    let bookingFee = mkpFeeModule.bookingFees.find(x => x.bookingScope === bookingScope);

                    // mentre queste tre mi servono per spalmare il valore di "mandatory" e "application" su tutte e tre le tipologie (nazionale, internazionale, intercontinentale)
                    let bookingFee1 = mkpFeeModule.bookingFees.find(x => x.bookingScope === 1);
                    let bookingFee2 = mkpFeeModule.bookingFees.find(x => x.bookingScope === 2);
                    let bookingFee3 = mkpFeeModule.bookingFees.find(x => x.bookingScope === 3);

                    switch (feeType)
                    {
                        case "m": // mandatory
                            {
                                bookingFee1.mandatory = target.checked;
                                bookingFee2.mandatory = target.checked;
                                bookingFee3.mandatory = target.checked;
                            }
                            break;

                        case "a": // application
                            {
                                bookingFee1.application = value;
                                bookingFee2.application = value;
                                bookingFee3.application = value;
                            }
                            break;

                        case "v": // amount
                            {
                                bookingFee.amount = Number(value);
                            }
                            break;
                    }
                }
                break;
        }

        setNewMarkup(newMarkup);
    }

    const saveMarkup = () => {
        setSaveLoading(true);
        callSetMarkup(newMarkup);
    }


    const renderMarkupFee = () => {
        if (markup === null || markup === undefined || markup.markupFeeModuli === null || markup.markupFeeModuli === undefined || markup.markupFeeModuli.length === 0)
            return (<></>);

        return (
            <>
                {markup.markupFeeModuli.map(module =>
                    <div key={module.idModulo} className="mt-3 mb-3">
                        <div className="card">
                            <span>
                                <M3IconProductType productSubtype={module.idModulo} externalClass="h4 p-3" />
                                {module.commission !== 0 ? (<><label className="px-2"> {t("MyBook:Commission")}:</label>{module.commission}%</>) : (<></>)}
                            </span>

                            <div className="row px-3 pb-3">
                                <div className="form-floating col-md-3">
                                    <input id={`mkp_${module.idModulo}`} type="number" className="form-control" defaultValue={module.markup} onChange={e => onValChange(e.target)} disabled={module.canEditMarkup ? "" : "disabled"} />
                                    <label htmlFor={`mkp_${module.idModulo}`} className="active pl-3">MarkUp</label>
                                </div>


                                {module.canEditFee
                                    ? (
                                        <>
                                            {renderBookingFees(module.idModulo, module.bookingFees)}
                                        </>
                                    )
                                    : (<></>)
                                }

                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }

    const renderBookingFees = (idModulo, fees) => {
        if (fees === null || fees === undefined || fees.length === 0)
            return;

        let nzFee = fees.find(x => x.bookingScope === 1);
        let inFee = fees.find(x => x.bookingScope === 2);
        let icFee = fees.find(x => x.bookingScope === 3);

        let myFees = [];
        myFees.push(nzFee);
        myFees.push(inFee);
        myFees.push(icFee);

        return (
            <>
                {myFees.map((x, index) =>
                    <React.Fragment key={index}>
                        <div className="form-floating col-md-3">
                            <input id={`fee_${idModulo}_${x.bookingScope}_v`} type="number" className="form-control" defaultValue={x.amount} onChange={e => onValChange(e.target)} />
                            <label htmlFor={`fee_${idModulo}_${x.bookingScope}_v`} className="active pl-3">
                                {x.bookingScope === 1 ? "Fee Nazionale" : x.bookingScope === 2 ? "Fee Internazionale" : "Fee Intercontinentale"}
                            </label>
                        </div>
                        {/*NOTA: uso Index 2 perchè è l'ultima Fee e quindi renderizzo i div correttamente nella riga successiva*/}
                        {index === 2
                            ? (
                                <>
                                    <div className="form-floating col-md-3 mt-4">
                                        <select id={`fee_${idModulo}_${x.bookingScope}_a`} className="form-select border-0 border-bottom border-dark" defaultValue={x.application} onChange={e => onValChange(e.target)}>
                                            <option value="B">Book</option>
                                            <option value="I">Item</option>
                                            <option value="P">Pax</option>
                                        </select>
                                        <label htmlFor={`fee_${idModulo}_${x.bookingScope}_a`} className="active pl-3">
                                            {t("MyBook:FeeApplication")}
                                        </label>
                                    </div>
                                    <div className="form-floating col-md-3 text-center pt-4">
                                        <br />
                                        {t("MyBook:FeeMandatory")}&nbsp;&nbsp;
                                        <input id={`fee_${idModulo}_${x.bookingScope}_m`} type="checkbox" defaultChecked={x.mandatory} onChange={e => onValChange(e.target)} />
                                    </div>
                                </>
                            )
                            : (<></>)
                        }

                    </React.Fragment>
                )}
            </>
        );
    }


    return (
        <>
            <MenuBarVertical activeAnchorId="aMarkup" />

            <div className="section section-lg bg-gray-200 page-content" id="content">
                <div className="container">
                    <h2>Markup</h2>
                    <p className="text-gray-700">
                        {t("MyBook:MarkupText")}
                    </p>

                    {isLoading
                        ? (<Loading textMsg={t('MyBook:Loading')} />)
                        : (
                            <>
                                {markup !== null && markup !== undefined
                                    ? (
                                        <>
                                            {renderMarkupFee()}

                                            <div className="text-right mb-4">
                                                <div className="card">
                                                    <div className="row p-3">
                                                        <div className="col-12">
                                                            <a id="aSaveMarkup" className="tp-btn-confirm btn-sm" onClick={saveMarkup}> {t("Button:Save")}</a>
                                                        </div>
                                                        <div className="col-12">
                                                            {saveLoading && <img src="https://cdn.travelplace.ch/common/images/general/ajax-loader.gif" alt="Loading..." />}
                                                        </div>
                                                        {saveCalled && <div className="col-12" dangerouslySetInnerHTML={createRenderHtml(saveResMessage)}></div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                    : (<h6> {t("MyBook:NothingData")}</h6>)
                                }
                            </>
                        )
                    }
                </div>
            </div>
        </>
    );
}
