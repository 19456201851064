import React, { useState, useEffect } from 'react';
import { Loading } from '../Common/Loading';
import { Passenger } from './Passenger';
import { PriceBar } from './PriceBar';
import configData from "../../appsettings.json";
import { useTranslation } from 'react-i18next';


export const GenericDetail = ({ id, type, showNetPrices, showCost, prodottoCorrelatoInfo, customFunctions }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [genericDetail, setGenericDetail] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        switch (type) {
            case 'MyBook':
                const getGenericDetail = async (inputData) => {
                    const response = callGetGenericDetail(inputData);
                }
                getGenericDetail({ PNR: id, ProductType: configData.Settings.Products.Generic.IdTipoProdotto, Options: { WebsiteBaseUrl: configData.Settings.OldWebSite_BaseUrl, GroupedIdsProdotto: prodottoCorrelatoInfo.groupedIdsProdotto } });
                break;
            case 'QuotationTO':
            default:
                setIsLoading(false);
        }
    }, []);

    async function callGetGenericDetail(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetReservationDetail', requestOption);
        const response = await fetchedRes.json();

        if (type === 'QuotationTO_MyBook' || type === 'MyBook')
            customFunctions.onCallSaveProductInfo(response, type, id);

        setGenericDetail(response);
        setIsLoading(false);
    }

    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : (
                    <>
                        <div id={'aDetail_' + id}></div>

                        {/*Vertical Left Icon Bar*/}
                        <div className="col-12 col-sm-10 col-md-6 col-lg-1 mb-4">
                            <div className="timeline timeline-one timeline-mybook h-100">
                                <div className="timeline-item h-100">
                                    <span className="icon icon-success"><i className="ph-file-text-bold h2"></i></span>
                                </div>
                            </div>
                        </div>

                        {/*Generic*/}
                        <div className="col-12 col-sm-10 col-md-6 col-lg-11 mb-4">
                            <div className={type === 'MyBook' ? "card border-light animate-up-5" : "card border-light mb-4 animate-up-5"}>
                                <div className="row no-gutters">
                                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                                        <div className="row no-gutters">
                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                <i className="ph-file-text h2" style={{ fontSize: '8rem' }}></i>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                <div className="myBook-card-body">
                                                    <div className="mybook-activity">
                                                        <h5>{genericDetail.name}</h5>
                                                        <p className="my-2">
                                                            <span>{t("MyBook:ProductType")}: <label>{genericDetail.tipoProdottoDescrizione}</label></span>
                                                        </p>
                                                        {genericDetail.pdfUrl !== null && genericDetail.pdfUrl !== undefined && genericDetail.pdfUrl !== ""
                                                            ? (
                                                                <>
                                                                    <p className="my-2">
                                                                        <a href={genericDetail.pdfUrl} target="_blank">
                                                                            <i className="ph-file-pdf" style={{ fontSize: '24px', position: 'relative', top: '7px' }}></i> Download Pdf
                                                                        </a>
                                                                    </p>
                                                                </>
                                                            )
                                                            : (<></>)
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mybook-activity-option">
                                            <div className="row">
                                                {/*Passengers*/}
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <div className="pt-3">
                                                        <div>{t("MyBook:Passengers")}:</div>
                                                        {
                                                            genericDetail.passengers.map(passenger =>
                                                                <Passenger key={passenger.index} passenger={passenger} />
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Price Bar*/}
                                    <PriceBar oPriceBar={genericDetail.priceBar}
                                        showNetPrices={showNetPrices}
                                        showCost={showCost}
                                        reservationDetail={genericDetail.reservationDetail}
                                        noStatus={type === "QuotationTO"} />
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
}
