import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import configData from "../../../appsettings.json";
import { Loading } from '../../Common/Loading';
import { formatPrice } from "../../../js/Utils";
import { getTitleClass, getTitleLabel } from "../../Template/TemplateUtils";
import { IconChoosePrint } from '../components/IconChoosePrint';


export const TemplatePrintInclusion = ({ inclusion, onHandleIsPrint }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");


    return (
        <>
        {inclusion && 
            <>
                <table>
                    <thead>
                        <tr>
                            <td style={{ display: "flex" }} >
                                <span className="mr-2">
                                    <IconChoosePrint handleIsPrint={onHandleIsPrint} />
                                </span>
                                <h5 className={getTitleClass(inclusion.inclusionType)}>{getTitleLabel(inclusion.inclusionType, inclusion.title, t)}</h5>
                            </td>
                        </tr>
                    </thead>
                </table>
                <div className="rounded-top rounded-bottom border">
                    {inclusion.inclusions && inclusion.inclusions.length > 0 && inclusion.inclusions.map((inclus, key) =>
                        <div key={key} className={(key < inclusion.inclusions.length-1 ? "border-bottom text-left" : "text-left")} style={{padding: "5px"}}>
                            <span>{inclus.description}</span>
                        </div>
                    )}
                </div>
            </>
        }
        </>
    );
}