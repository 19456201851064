import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

export const TravelDoc = () => {
    let { orderNumber } = useParams();
    let { cultureName } = useParams()
    let { authcode } = useParams()

    return (
        <>
            <div>TRAVEL DOC WORKING</div>
        </> 
    );
}
