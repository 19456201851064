import React from 'react';
import { CartBuoniScontoModal } from './CartBuoniScontoModal';

export const CartBuoniScontoSelectButton = ({ type, buoniSconto, msg, onSelectBuoniSconto }) => {

    function onClickOpenModal() {
        let myBtn = document.getElementById("open_SelectDiscount_" + type);
        myBtn.click();
    }

    return (
        <>
            {
                buoniSconto && buoniSconto.length > 0 && <div className="row mb-2">
                    <div className="col-1">
                        <div className="d-table w-100 h-100">
                            <div className="d-table-row">
                                <div className="d-table-cell align-middle text-center">
                                    <span className="material-icons-outlined">sell</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="d-table w-100 h-100">
                            <div className="d-table-row">
                                <div className="d-table-cell align-middle">
                                    {msg}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <button className="btn btn-sm btn-outline-success" onClick={(e) => onClickOpenModal()}>
                            Seleziona
                        </button>
                    </div>
                </div>

            }
            {
                buoniSconto && buoniSconto.length > 0 && <CartBuoniScontoModal
                    type={type}
                    buoniSconto={buoniSconto}
                    onSelectBuoniSconto={onSelectBuoniSconto}
                    msg={msg} />
            }
        </>
    )
}
