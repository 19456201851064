import React, { useState, useEffect } from 'react';
import configData from "../../../appsettings.json";
import { M3IconProductType } from '../../Common/M3IconProductType.js';
import { formatDateTime } from '../../../js/Utils.js';
import { FlightItemList } from '../../Product/Flight/components/FlightItemList.js';
import { FlightModal } from '../../Product/Flight/components/FlightModal.js';
import { FlightModalFareDetail } from '../../Product/Flight/components/FlightModalFareDetail.js';
import { ActivityItemList } from '../../Product/Activity/components/ActivityItemList.js';
import { ActivityModalStatic } from '../../Product/Activity/components/ActivityModalStatic.js';
import { CarItemList } from '../../Product/Car/components/CarItemList.js';
import { HotelItemList } from '../../Product/Hotel/components/HotelItemList.js';
import { HotelModalStatic } from '../../Product/Hotel/components/HotelModalStatic.js';
import { TourModalStatic } from '../../Product/Tour/components/TourModalStatic.js';
import { TourItemList } from '../../Product/Tour/components/TourItemList.js';
import { TransferItemList } from '../../Product/Transfer/components/TransferItemList.js';
import { TrainModal } from '../../Product/Train/components/TrainModal.js';
import { TrainSelectedItinerary } from '../../Product/Train/components/TrainSelectedItinerary.js';
import { Loading } from '../../Common/Loading.js';
import { Error } from '../../Common/Error.js';

export const TrippieStepItem = ({ stepIndex, itemIndex, cartItemId, refFlightCartItem, refLegIndex, item, cultureName, showPriceBar, showCost, updateItemAfterSearch, removeItemAfterSearch, showPriceTrippie }) => {

    const [isLoadingFromCartItem, setIsLoadingFromCartItem] = useState(false);
    const [isErrorFromCartItem, setIsErrorFromCartItem] = useState(false);

    const [renderInfo, setRenderInfo] = useState(null);
    const [searchParam, setSearchParam] = useState(null);
    const [productType, setProductType] = useState(null);

    async function callGetCartItem(cartItemIdParam) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'trippie/GetCartItem/' + cartItemIdParam, requestOption);
        const response = await fetchedRes.json();
        if (response.success) {
            setRenderInfo(response.renderInfo);
            setSearchParam(response.searchParam);
        } else {
            setIsErrorFromCartItem(true);
        }

        setIsLoadingFromCartItem(false);
    }

    async function callGetFlightCartItem(refFlightCartItemParam, refLegIndexParam) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'trippie/GetCartFlightItem/' + refFlightCartItemParam + '/' + refLegIndexParam, requestOption);
        const response = await fetchedRes.json();
        if (response.success) {
            setRenderInfo(response.renderInfo);
            setSearchParam(response.searchParam);
        } else {
            setIsErrorFromCartItem(true);
        }

        setIsLoadingFromCartItem(false);
    }

    useEffect(() => {
        if (item)
            setProductType(item.productType);

        if (refFlightCartItem) {
            setIsLoadingFromCartItem(true);
            setIsErrorFromCartItem(false);
            setProductType(configData.Settings.Products.Flight.IdTipoProdotto);

            const getFlightCartItem = async (refFlightCartItemParam, refLegIndexParam) => {
                const response = callGetFlightCartItem(refFlightCartItemParam, refLegIndexParam);
            }
            getFlightCartItem(refFlightCartItem, refLegIndex);
        }
        else if (cartItemId) {
            setIsLoadingFromCartItem(true);
            setIsErrorFromCartItem(false);

            const getCartItem = async (cartItemIdParam) => {
                const response = callGetCartItem(cartItemIdParam);
            }
            getCartItem(cartItemId);
        }
        else {
            setRenderInfo(null);
        }

    }, [cartItemId, refFlightCartItem, item])

    const [modalOpenProductId, setModalOpenProductId] = useState(null);

    const [modalOpenFlightFare, setModalOpenFlightFare] = useState(null);
    const [modalOpenFareDetail, setModalOpenFareDetail] = useState(null);

    const [modalOpenTrainFare, setModalOpenTrainFare] = useState(null);

    function updateTrippie() {
        let cartItemIdParam = cartItemId;
        if (!cartItemIdParam)
            cartItemIdParam = refFlightCartItem;

        updateItemAfterSearch(stepIndex, cartItemIdParam, productType, searchParam);
    }

    function removeTrippie() {
        let cartItemIdParam = cartItemId;
        if (!cartItemIdParam)
            cartItemIdParam = refFlightCartItem;

        removeItemAfterSearch(stepIndex, cartItemIdParam);
    }

    return (
        <>
            {
                refFlightCartItem && !renderInfo ? <>
                    {
                        isLoadingFromCartItem && <Loading textMsg="Caricamento in corso" />
                    }
                    {
                        isErrorFromCartItem && <Error textMsg="Si è verificato un errore" />
                    }
                </> : <></>
            }
            {
                !refFlightCartItem && !renderInfo ? <div key={itemIndex} className="row py-4 border-bottom">
                    <div className="col-2 px-1 text-center">
                        <M3IconProductType productType={productType} externalClass="text-gray-400" />
                        <div>
                            <small>
                                {formatDateTime(item.dateFrom, cultureName, { noYear: true })}
                                {item.dateTo && <> - {formatDateTime(item.dateTo, cultureName, { noYear: true })}</>}
                            </small>
                        </div>
                    </div>
                    <div className="col-4 px-1">
                        <div>{item.productFrom}</div>
                    </div>
                    <div className="col-4 px-1">
                        <div>{item.productTo}</div>
                    </div>
                    {
                        isLoadingFromCartItem && <Loading textMsg="Caricamento in corso" />
                    }
                    {
                        isErrorFromCartItem && <Error textMsg="Si è verificato un errore" />
                    }
                </div>
                    : renderInfo
                        ? <>
                            {
                                productType === configData.Settings.Products.Activity.IdTipoProdotto && <>
                                    <ActivityItemList
                                        item={renderInfo}
                                        showPriceBar={showPriceBar}
                                        showCost={showCost}
                                        extraParam={{ isTrippie: true, showPriceTrippie: showPriceTrippie }}
                                        updateTrippie={updateTrippie}
                                        removeTrippie={removeTrippie}
                                        modalOpenProductId={setModalOpenProductId} />
                                    {
                                        modalOpenProductId && <ActivityModalStatic activityId={modalOpenProductId} modalCloseProductId={setModalOpenProductId} />
                                    }
                                </>
                            }
                            {
                                productType === configData.Settings.Products.CarRental.IdTipoProdotto && <>
                                    <CarItemList
                                        item={renderInfo}
                                        showPriceBar={showPriceBar}
                                        showCost={showCost}
                                        extraParam={{ isTrippie: true, showPriceTrippie: showPriceTrippie }}
                                        updateTrippie={updateTrippie}
                                        removeTrippie={removeTrippie}
                                        modalOpenProductId={setModalOpenProductId} />
                                </>
                            }
                            {
                                productType === configData.Settings.Products.Structure.IdTipoProdotto && <>
                                    <HotelItemList
                                        item={renderInfo}
                                        showPriceBar={showPriceBar}
                                        showCost={showCost}
                                        extraParam={{ isTrippie: true, showPriceTrippie: showPriceTrippie }}
                                        updateTrippie={updateTrippie}
                                        removeTrippie={removeTrippie}
                                        modalOpenProductId={setModalOpenProductId} />
                                    {
                                        modalOpenProductId && <HotelModalStatic structureId={modalOpenProductId} modalCloseProductId={setModalOpenProductId} isNotEngine={true} />
                                    }
                                </>
                            }
                            {
                                productType === configData.Settings.Products.Flight.IdTipoProdotto && <>
                                    <FlightItemList
                                        item={renderInfo}
                                        showPriceBar={showPriceBar}
                                        showCost={showCost}
                                        extraParam={{ isTrippie: true, showPriceTrippie: showPriceTrippie }}
                                        updateTrippie={updateTrippie}
                                        removeTrippie={removeTrippie}
                                        modalOpenProductId={setModalOpenFlightFare}
                                        modalOpenFareDetail={setModalOpenFareDetail} />

                                    {
                                        modalOpenFlightFare && <FlightModal flightFare={modalOpenFlightFare} modalCloseProductId={setModalOpenFlightFare} />
                                    }
                                    {
                                        modalOpenFareDetail && <FlightModalFareDetail flightFare={modalOpenFareDetail} modalCloseProductId={setModalOpenFareDetail} />
                                    }
                                </>
                            }
                            {
                                productType === configData.Settings.Products.Transfer.IdTipoProdotto && <>
                                    <TransferItemList
                                        item={renderInfo}
                                        showPriceBar={showPriceBar}
                                        showCost={showCost}
                                        extraParam={{ isTrippie: true, showPriceTrippie: showPriceTrippie }}
                                        updateTrippie={updateTrippie}
                                        removeTrippie={removeTrippie}
                                        modalOpenProductId={setModalOpenProductId}
                                    />
                                </>
                            }
                            {
                                productType === configData.Settings.Products.Tour.IdTipoProdotto && <>
                                    <TourItemList
                                        item={renderInfo}
                                        showPriceBar={showPriceBar}
                                        showCost={showCost}
                                        extraParam={{ isTrippie: true, showPriceTrippie: showPriceTrippie }}
                                        updateTrippie={updateTrippie}
                                        removeTrippie={removeTrippie}
                                        modalOpenProductId={setModalOpenProductId} />
                                    {
                                        modalOpenProductId && <TourModalStatic tourId={modalOpenProductId} modalCloseProductId={setModalOpenProductId} />
                                    }
                                </>
                            }
                            {
                                productType === configData.Settings.Products.Train.IdTipoProdotto && <>
                                    <TrainSelectedItinerary
                                        itinerary={renderInfo}
                                        showPriceBar={showPriceBar}
                                        onModalOpenProductId={setModalOpenTrainFare}
                                        updateTrippie={updateTrippie}
                                        removeTrippie={removeTrippie}
                                        extraParam={{ isTrippie: true, showPriceTrippie: showPriceTrippie }} />
                                    {
                                        modalOpenTrainFare && <TrainModal trainLeg={modalOpenTrainFare}
                                            carpetId={item.carpetId}
                                            modalCloseProductId={setModalOpenTrainFare} />
                                    }
                                </>
                            }
                        </>
                        : <></>
            }
        </>
    );
}
