export const quotationTOStates = [
    { key: -1, text: 'Seleziona' },
    { key: 0, text: 'quotationto:status:draft' },
    { key: 1, text: 'quotationto:status:send' },
    { key: 2, text: 'quotationto:status:update' },
    { key: 3, text: 'quotationto:status:cancel' },
    { key: 4, text: 'quotationto:status:confirmed' },
    { key: 5, text: 'quotationto:status:confirmwithbook' },
    { key: 6, text: 'quotationto:status:working' },
]

export const translateQuotationCommissionableGroupType = (item, hasIVACee = true) => {
    switch (item.type) {
        case 'None': return item.productName;

        case 'CommissionableFlight': return `Volo Commissionabile (${item.percentageCommissionable}%)`;
        case 'Commissionable': return `Servizi al ${item.percentageCommissionable}%`;
        case 'CommissionableProdottiCorrelati': return `${item.productName} ${item.percentageCommissionable}%`;

        case 'TotaleMargineLordoCompresaIVA': return `Totale Margine Lordo (IVA Compresa) (${item.percentageCommissionable}%)`;
        case 'TotaleMargineLordo': return hasIVACee ? `Totale Margine Lordo (Netto dell'IVA) (${item.percentageCommissionable}%)` : `Totale Margine Lordo (${item.percentageCommissionable}%)`;
        case 'RicavoTM': return `Ricavo TM`;
        case 'MargineSede': return `Margine Sede (${item.percentageCommissionable}%)`;

        case 'NotCommissionableTaxesFlightAndFixCost': return "Tasse volo e costi fissi";
        case 'NotCommissionableTaxesCruise': return "Tasse crociere";
        case 'NotCommissionable': return item.productName;
        case 'NotCommissionableProdottiCorrelati': return item.productName;
        case 'NotCommissionableInterventoCommerciale': return "Intervento Commerciale";
        case 'NotCommissionableSconto': return "Buono sconto";

        case 'TotaleAgencyFee': return "Totale Agency Fee";
        case 'TotaleAgencyMarkup': return "Totale Agency Markup";

        default: return item.productName;
    }
}

export const translateQuotationDiscountStatus = (item) => {
    switch (item +"") {
        case "0":
        case "Pending":
            return "In attesa di approvazione";
        case "1":
        case "Accepted":
            return "Accettato";
        case "2":
        case "Reject":
            return "Rifiutato";
        case "3":
        case "Deleted":
            return "Cancellato";
    }
}