import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';


export const ProductCalendar = ({ productCalendar, defaultDate, imageUrl, onSelectDate }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    const [selectedDate, setSelectedDate] = useState(null);
    const [renderDates, setRenderDates] = useState([]);

    useEffect(() => {

        if (defaultDate) {
            const defaultDatePart = defaultDate.split('-');
            let newSeletedDate = new Date(defaultDatePart[0], defaultDatePart[1] - 1, defaultDatePart[2]);
            setSelectedDateFormat(newSeletedDate);
        }

    }, [defaultDate])

    useEffect(() => {

        if (productCalendar) {

            // la data arriva in formato yyyy-MM-dd
            const fromDatePart = productCalendar.dateFrom.split('-');
            let fromDate = new Date(fromDatePart[0], fromDatePart[1] - 1, fromDatePart[2]);

            // mostro sempre un mese
            let toDate = new Date(fromDatePart[0], fromDatePart[1] - 1, fromDatePart[2]);
            toDate.setMonth(toDate.getMonth() + 1);

            let allDateAvailable = [];
            if (productCalendar.availableDates) {
                for (let i = 0; i < productCalendar.availableDates.length; i++) {
                    const avlDatePart = productCalendar.availableDates[i].split('-');
                    let avlDate = new Date(avlDatePart[0], avlDatePart[1] - 1, avlDatePart[2]);
                    allDateAvailable.push(avlDate);
                }
            }

            // creo l'oggetto che rappresenta le date
            let datesInfo = [];
            for (let i = 0; i < 31; i++) {
                let nextDate = new Date(fromDatePart[0], fromDatePart[1] - 1, fromDatePart[2]);
                nextDate.setDate(fromDate.getDate() + i);

                if (nextDate >= toDate)
                    break;

                if (i === 0 && !selectedDate && !defaultDate)
                    setSelectedDateFormat(nextDate);

                let dateInfo = {
                    date: nextDate,
                    day: nextDate.getDate(),
                    available: allDateAvailable.some(d => d.getTime() === nextDate.getTime())
                };

                datesInfo.push(dateInfo);
            }

            // creo l'oggetto che rappresenta le linee del calendario
            let renderDatesInfo = [];
            let indexRowDateRender = 0;
            let rowDateRenderDefault = {
                monthName: '',
                mon: { isNoSet: true },
                tue: { isNoSet: true },
                wed: { isNoSet: true },
                thu: { isNoSet: true },
                fri: { isNoSet: true },
                sat: { isNoSet: true },
                sun: { isNoSet: true }
            };

            let rowDateRender = { ...rowDateRenderDefault };
            for (let i = 0; i < datesInfo.length; i++) {
                let dateInfo = datesInfo[i];
                let myDate = dateInfo.date;
                let weekDay = myDate.getDay();// Sunday - Saturday : 0 - 6

                rowDateRender.monthName = myDate.toLocaleString((cultureName === "it-IT" ? "it-IT" : "en-EN"), { month: 'long' });
                let dayRender = {
                    day: myDate.getDate(),
                    date: myDate,
                    available: dateInfo.available
                };

                switch (weekDay) {
                    case 1: {
                        rowDateRender.mon = dayRender;
                        break;
                    }
                    case 2: {
                        rowDateRender.tue = dayRender;
                        break;
                    }
                    case 3: {
                        rowDateRender.wed = dayRender;
                        break;
                    }
                    case 4: {
                        rowDateRender.thu = dayRender;
                        break;
                    }
                    case 5: {
                        rowDateRender.fri = dayRender;
                        break;
                    }
                    case 6: {
                        rowDateRender.sat = dayRender;
                        break;
                    }
                    case 0: {
                        rowDateRender.sun = dayRender;
                        break;
                    }
                }

                // se sono a domenica vuol dire che devo creare una nuova riga
                // sono l'ultimo elemento del ciclo
                if (weekDay === 0 || (i + 1) === datesInfo.length) {
                    indexRowDateRender = indexRowDateRender + 1;
                    renderDatesInfo.push(rowDateRender);
                    rowDateRender = { ...rowDateRenderDefault };
                }

            }

            setRenderDates(renderDatesInfo);
        }

    }, [productCalendar])

    function setSelectedDateFormat(newSeletedDate) {
        setSelectedDate({
            day: newSeletedDate.getDate(),
            month: newSeletedDate.getMonth(),
            year: newSeletedDate.getFullYear(),
            dateStr: getDateToString(newSeletedDate),
            monthName: newSeletedDate.toLocaleString((cultureName === "it-IT" ? "it-IT": "en-EN"), { month: 'long' })
        });
    }

    function getClassNameItemDay(itemDay) {
        if (!itemDay || itemDay.isNoSet)
            return "";

        if (!itemDay.available)
            return "bg-gray-500 px-2 rounded";

        if (getDateToString(itemDay.date) !== selectedDate.dateStr)
            return " cursor-pointer";

        return "";
    };

    function getSelectedClassName(itemDay) {
        if (!itemDay || itemDay.isNoSet)
            return "";

        if (getDateToString(itemDay.date) === selectedDate.dateStr)
            return "bg-blue text-white px-2 rounded";

        return ""
    }

    function getDateToString(myDate) {
        let monthStr = myDate.getMonth() + 1;
        let dayStr = myDate.getDate();
        if (monthStr < 10)
            monthStr = "0" + monthStr;

        if (dayStr < 10)
            dayStr = "0" + dayStr;

        return myDate.getFullYear() + "-" + (monthStr) + "-" + dayStr;
    }

    function onClickSelectDate(itemDay, item) {
        if (!item || item.isNoSet || !itemDay.available || getDateToString(itemDay.date) === selectedDate.dateStr)
            return;

        setSelectedDateFormat(itemDay.date);
        let isoDate = getDateToString(itemDay.date);
        onSelectDate(isoDate);
    }

    return (
        <>
            <h5 className=" border-bottom p-0 mb-0">
                <data m3Lab="Calendar.SelectAvailableDate">
                {t('Calendar:SelectAvailableDate')}
                </data>
            </h5>
            <label>
                <data m3Lab="Calendar.Month">
                    {t('Calendar:SelectAvailableDateSubtitle')}
                </data>
            </label>
            {
                productCalendar && <div className="d-md-flex rounded">
                    <div className="wrap-img-calendar d-flex align-items-center img w-25 rounded" style={{ backgroundImage: `url("${imageUrl}")` }}>
                        <div className="p-2 w-100">
                            <div className="W-100 p-0">
                                <div className="h6 font-weight-light text-white w-100 text-center text-capitalize">{selectedDate && selectedDate.monthName}</div>
                                <div className="h1 text-white w-100 text-center">{selectedDate && selectedDate.day}</div>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 px-4">
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <th><data m3Lab="Calendar.Month">{t('Calendar:MonthName')}</data></th>
                                    <th className="text-gray-500"><data m3Lab="Calendar.Month">{t("Calendar:DayShort:Monday")}</data></th>
                                    <th className="text-gray-500"><data m3Lab="Calendar.Month">{t("Calendar:DayShort:Tuesday")}</data></th>
                                    <th className="text-gray-500"><data m3Lab="Calendar.Month">{t("Calendar:DayShort:Wedsnday")}</data></th>
                                    <th className="text-gray-500"><data m3Lab="Calendar.Month">{t("Calendar:DayShort:Thursday")}</data></th>
                                    <th className="text-gray-500"><data m3Lab="Calendar.Month">{t("Calendar:DayShort:Friday")}</data></th>
                                    <th className="text-gray-500"><data m3Lab="Calendar.Month">{t("Calendar:DayShort:Saturday")}</data></th>
                                    <th className="text-gray-500"><data m3Lab="Calendar.Month">{t("Calendar:DayShort:Sunday")}</data></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    renderDates && renderDates.map((item, key) => {
                                        return <tr key={key}>
                                            <td className=" text-gray-500 small text-capitalize">{item.monthName}</td>
                                            <td onClick={_ => onClickSelectDate(item.mon, item)}>
                                                <div className={getClassNameItemDay(item.mon) + " " + getSelectedClassName(item.mon) + " w-75 text-center"}>
                                                    {item.mon.day}
                                                </div>
                                            </td>
                                            <td onClick={_ => onClickSelectDate(item.tue, item)}>
                                                <div className={getClassNameItemDay(item.tue) + " " + getSelectedClassName(item.tue) + " w-75 text-center"}>
                                                    {item.tue.day}
                                                </div>
                                            </td>
                                            <td onClick={_ => onClickSelectDate(item.wed, item)}>
                                                <div className={getClassNameItemDay(item.wed) + " " + getSelectedClassName(item.wed) + " w-75 text-center"}>
                                                    {item.wed.day}
                                                </div>
                                            </td>
                                            <td onClick={_ => onClickSelectDate(item.thu, item)}>
                                                <div className={getClassNameItemDay(item.thu) + " " + getSelectedClassName(item.thu) + " w-75 text-center"}>
                                                    {item.thu.day}
                                                </div>
                                            </td>
                                            <td onClick={_ => onClickSelectDate(item.fri, item)}>
                                                <div className={getClassNameItemDay(item.fri) + " " + getSelectedClassName(item.fri) + " w-75 text-center"}>
                                                    {item.fri.day}
                                                </div>
                                            </td>
                                            <td onClick={_ => onClickSelectDate(item.sat, item)}>
                                                <div className={getClassNameItemDay(item.sat) + " " + getSelectedClassName(item.sat) + " w-75 text-center"}>
                                                    {item.sat.day}
                                                </div>
                                            </td>
                                            <td onClick={_ => onClickSelectDate(item.sun, item)}>
                                                <div className={getClassNameItemDay(item.sun) + " " + getSelectedClassName(item.sun) + " w-75 text-center"}>
                                                    {item.sun.day}
                                                </div>
                                            </td>
                                        </tr>;
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </>
    );
}