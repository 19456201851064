import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../Common/M3Icon";
import { InputSearchDestinationContainer } from '../../Common/Destination/InputSearchDestinationContainer';
import { InputPaxesCompositionContainer } from '../../Common/Paxes/InputPaxesCompositionContainer';
import { InputCalendarContainer } from '../../Common/Calendar/InputCalendarContainer';
import { SelectCitizenship } from '../../Common/SelectCitizenship';
import { generateClientFlowId, callGetLastItemsSearch, callGetGetProductRules, getTransferListLink, getTransferCarpetId } from '../../../js/ProductService.js';
import configData from "../../../appsettings.json";

export const TransferSearchEngine = ({ searchParameters, modeView, resetValues, extraParams, isFake, isTrippie, onSearchTrippie }) => {
    const { t } = useTranslation();

    const [lastSearchItems, setLastSearchItems] = useState([]);
    const [defaultCitizenshipId, setDefaultCitizenshipId] = useState(null);
    const [isStartSearch, setIsStartSearch] = useState(false);
    const [isValidComposition, setIsValidComposition] = useState(true);

    const [selectCitizenship, setSelectCitizenship] = useState(null);
    const [selectDestinations, setSelectDestinations] = useState({});
    const [selectComposition, setSelectComposition] = useState(null);
    const [selectDates, setSelectDates] = useState(null);
    const [isValidForm, setIsValidForm] = useState({ isValidDestination: false, isValidDates: false, isValidComposition: false, isAllFieldValid: false });

    const [textColorClass, setTextColorClass] = useState('text-white');
    const [transferType, setTransferType] = useState(1);
    const [departureType, setDepartureType] = useState(0);

    // gestisco la visibilità
    const [showDestination, setShowDestination] = useState([{ dep: false, arr: false }]);
    const [showPaxes, setShowPaxes] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);


    useEffect(() => {
        const getLastSearchItems = async () => {
            const response = await callGetLastItemsSearch(41);
            setLastSearchItems(response);
        }

        const getRules = async () => {
            const response = await callGetGetProductRules(41);

            if (response) {
                try {
                    setIsValidComposition(response['ADDTOCART'].allowed);
                } catch (ex) {
                    console.error(ex);
                }
            }
        }

        getLastSearchItems();
        getRules();

        if (modeView === 'MODAL')
            setTextColorClass('text-dark');
        else
            setTextColorClass('text-white');
    }, []);

    useEffect(() => {
        setShowPaxes(false);
        setValuesHowDefault();
    }, [searchParameters, resetValues]);

    useEffect(() => {
        let isValidFormUpdate = {
            isValidDestination: false,
            isValidDates: false,
            isValidComposition: false,
            isAllFieldValid: false
        };

        // check destinations
        let isValidDestination = false;
        if (selectDestinations) {
            isValidDestination = selectDestinations.dep !== undefined && selectDestinations.arr !== undefined;
        }
        isValidFormUpdate.isValidDestination = isValidDestination;

        // check dates
        let isValidDates = false;
        if (selectDates) {
            isValidDates = selectDates.dateFrom !== undefined && (transferType === 0 || selectDates.dateTo !== undefined);
        }
        isValidFormUpdate.isValidDates = isValidDates;

        // check composition
        if (selectComposition) {
            isValidFormUpdate.isValidComposition = true;
        }

        // check all summary
        isValidFormUpdate.isAllFieldValid = isValidFormUpdate.isValidDates && isValidFormUpdate.isValidComposition && isValidFormUpdate.isValidDestination;
        setIsValidForm(isValidFormUpdate);
    }, [selectDestinations, selectDates, selectComposition]);


    function setValuesHowDefault() {
        if (searchParameters) {

            let updatePickupPoint = {
                Text: searchParameters.pickupPoint.name,
                IataCode: searchParameters.pickupPoint.type === 7 ? searchParameters.pickupPoint.code : null,
                Id: searchParameters.pickupPoint.type === 7 ? null : searchParameters.pickupPoint.code,
                Type: searchParameters.pickupPoint.type
            };

            let updateDropoffPoint = {
                Text: searchParameters.dropoffPoint.name,
                IataCode: searchParameters.dropoffPoint.type === 7 ? searchParameters.dropoffPoint.code : null,
                Id: searchParameters.dropoffPoint.type === 7 ? null : searchParameters.dropoffPoint.code,
                Type: searchParameters.dropoffPoint.type
            };

            let updateSelectDestinations = {};
            updateSelectDestinations.dep = updatePickupPoint;
            updateSelectDestinations.arr = updateDropoffPoint;
            setSelectDestinations(updateSelectDestinations);

            let updateSelectDates = { dateFrom: searchParameters.pickupDate, dateTo: searchParameters.returnPickUpDate };
            setSelectDates(updateSelectDates);

            let updateSelectComposition = [searchParameters.passengersComposition];
            setSelectComposition(updateSelectComposition);

            setDefaultCitizenshipId(searchParameters.citizenship);

            setTransferType(searchParameters.direction);

            if (searchParameters.pickupPoint.type === 7)
                setDepartureType(0);
            else
                setDepartureType(1);
        }
    }

    // function change types
    function onChangeTransferType(event, type) {
        setTransferType(type);
    }

    function onChangeDepartureType(event, type) {
        setDepartureType(type);
    }


    // funzioni destinazione
    function onOpenPanelDestination() {
        setShowDestination(true);
        setShowCalendar(false);
        setShowPaxes(false);
    }

    function onSelectSearchDestination(newSelectDestination, externalId) {
        let updateSelectDestinations = { ...selectDestinations };
        let isDeparture = externalId === 'departure';

        if (isDeparture) {
            updateSelectDestinations.dep = newSelectDestination;
        }
        else {
            updateSelectDestinations.arr = newSelectDestination;
        }

        setSelectDestinations(updateSelectDestinations);
    }


    // funzioni per il calendario
    function onOpenPanelCalendar() {
        setShowDestination(false);
        setShowCalendar(true);
        setShowPaxes(false);
    }

    function onClickSelectDates(values) {
        setShowCalendar(false);

        setSelectDates({
            dateFrom: values.dateFrom,
            dateTo: values.dateTo,
        });

        //checkIsValidForm(null, null, values);
    }

    // funzioni per la composizione
    function onClickSelectPaxes(selectComposition) {
        setSelectComposition(selectComposition);
        //checkIsValidForm(null, selectComposition, null);
    }

    function onOpenPanelPaxes() {
        setShowDestination(false);
        setShowCalendar(false);
        setShowPaxes(true);
    }

    // funzioni cittadinanza
    function onSelectCitizenship(item) {
        setSelectCitizenship(item);
    }


    // funzioni button
    async function onSearchButton() {
        setIsStartSearch(true);

        let newSP = {};

        if (searchParameters) {
            newSP = searchParameters;
        }

        let dep = {};
        let arr = {};

        let departureFromAirport = departureType === 0 ? true : false;
        if (departureFromAirport) {
            dep = { code: selectDestinations.dep.IataCode, type: 7, name: selectDestinations.dep.Text };
            arr = { code: selectDestinations.arr.Id, type: 4, name: selectDestinations.arr.Text };
        } else {
            dep = { code: selectDestinations.dep.Id, type: 4, name: selectDestinations.dep.Text };
            arr = { code: selectDestinations.arr.IataCode, type: 7, name: selectDestinations.arr.Text };
        }

        newSP.pickupPoint = dep;
        newSP.dropoffPoint = arr;
        newSP.pickupDate = selectDates.dateFrom;
        newSP.returnPickUpDate = selectDates.dateTo;
        newSP.direction = transferType;
        newSP.passengersComposition = selectComposition[0];
        newSP.citizenship = selectCitizenship.isoId;
        
        if (isTrippie) {
            let clientFlowId = generateClientFlowId();
            let carpetId = await getTransferCarpetId(newSP, clientFlowId);

            onSearchTrippie(extraParams.stepIndex, clientFlowId, carpetId, newSP, configData.Settings.Products.Transfer.IdTipoProdotto);
            setIsStartSearch(false);
            return;
        }

        let clientFlowId = generateClientFlowId();
        let url = await getTransferListLink(newSP, clientFlowId);

        //console.log(JSON.stringify(newSP));

        window.open(url, '_self');
    }


    return (
        <>
            {/* Roundtrip/OneWay */}
            <div className="btn-group btn-sm rounded-1" role="group" aria-label="Basic example">
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" id="Return" value="option0" checked={transferType === 1} onChange={(e) => onChangeTransferType(e, 1)} />

                    <data m3ico="RoundTrip text-gray-600 icon-10">
                        <M3Icon iconName="RoundTrip" externalClass={"icon-10 " + textColorClass} />
                    </data>
                    <label className={"form-check-label " + textColorClass} htmlFor="Return">
                        <data m3lab="SearchEngine.Roundtrip">
                            {t('SearchEngine:Roundtrip')}
                        </data>
                    </label>
                </div>
            </div>
            <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" id="OneWay" value="option1" checked={transferType === 0} onChange={(e) => onChangeTransferType(e, 0)} />

                <data m3ico="OneWay text-gray-600 icon-10">
                    <M3Icon iconName="OneWay" externalClass={"icon-10 " + textColorClass} />
                </data>
                <label className={"form-check-label  " + textColorClass} htmlFor="OneWay">
                    <data m3lab="SearchEngine.OneWay">
                        {t('SearchEngine:OneWay')}
                    </data>
                </label>
            </div>

            <div className="row mb-3">
                {/* Departure */}
                <div className="col-12 col-sm-3 my-1">
                    {departureType === 0
                        ? (
                            <InputSearchDestinationContainer
                                externalId="departure"
                                defaultSelectDestination={selectDestinations && selectDestinations.dep}
                                productType={configData.Settings.Products.Flight.IdTipoProdotto}
                                lastSearchItems={null}
                                onSelectSearchDestination={onSelectSearchDestination}
                                onOpenPanelDestination={onOpenPanelDestination}
                                closeExternal={showDestination.dep}
                                extraOptions={{ selectGroupTitle: false }}
                                modeView={modeView} />
                        )
                        : (
                            <InputSearchDestinationContainer
                                externalId="departure"
                                defaultSelectDestination={selectDestinations && selectDestinations.dep}
                                productType={configData.Settings.Products.Transfer.IdTipoProdotto}
                                lastSearchItems={null}
                                onSelectSearchDestination={onSelectSearchDestination}
                                onOpenPanelDestination={onOpenPanelDestination}
                                closeExternal={showDestination.dep}
                                extraOptions={{ selectGroupTitle: true, rules: { enableDestination: true } }}
                                modeView={modeView} />
                        )
                    }


                </div>

                {/* Arrival */}
                <div className="col-12 col-sm-3 my-1">
                    {departureType === 0
                        ? (
                            <InputSearchDestinationContainer
                                externalId="arrival"
                                defaultSelectDestination={selectDestinations && selectDestinations.arr}
                                productType={configData.Settings.Products.Transfer.IdTipoProdotto}
                                lastSearchItems={null}
                                onSelectSearchDestination={onSelectSearchDestination}
                                onOpenPanelDestination={onOpenPanelDestination}
                                closeExternal={showDestination.arr}
                                extraOptions={{ selectGroupTitle: true, rules: { enableDestination: true } }}
                                modeView={modeView} />
                        )
                        : (
                            <InputSearchDestinationContainer
                                externalId="arrival"
                                defaultSelectDestination={selectDestinations && selectDestinations.arr}
                                productType={configData.Settings.Products.Flight.IdTipoProdotto}
                                lastSearchItems={null}
                                onSelectSearchDestination={onSelectSearchDestination}
                                onOpenPanelDestination={onOpenPanelDestination}
                                closeExternal={showDestination.arr}
                                extraOptions={{ selectGroupTitle: false }}
                                modeView={modeView} />
                        )
                    }

                </div>

                {/* Calendar */}
                <div className="col-12 col-sm-3 my-1">

                    <InputCalendarContainer
                        productType={configData.Settings.Products.Transfer.IdTipoProdotto}
                        dateFrom={selectDates ? selectDates.dateFrom : null}
                        dateTo={selectDates ? selectDates.dateTo : null}
                        extraOptions={{ multiDate: transferType === 1 }}
                        onClickSelectDates={onClickSelectDates}
                        onOpenPanelCalendar={onOpenPanelCalendar}
                        closeExternal={showCalendar}
                        modeView={modeView}
                        validDateRule={extraParams ? extraParams.validDateRule : null}
                        type="xl" />

                </div>

                {/* Passengers */}
                <div className="col-12 col-sm-2 my-1">

                    <InputPaxesCompositionContainer
                        searchParameters={searchParameters}
                        productType={configData.Settings.Products.Activity.IdTipoProdotto}
                        callOnSelectComposition={onClickSelectPaxes}
                        onOpenPanelPaxes={onOpenPanelPaxes}
                        closeExternal={showPaxes}
                        modeView={modeView}
                        type="xl" />

                </div>

                {/* Button Search */}
                <div className="col-12 col-sm-1 my-1 p-0">
                    <button className="btn tp-btn-search w-100 py-3" onClick={_ => onSearchButton()} disabled={!isValidForm.isAllFieldValid && !isStartSearch}>
                        {isStartSearch ? <><div className="spinner-border spinner-border-sm text-primary" role="status"><span className="sr-only"></span></div></> : <>{t('Button:Search')}</>}
                    </button>
                </div>

                <div className={"col-12 col-sm-6 my-1 d-inline " + textColorClass}>
                    <span className="">Partenza da:</span>
                    {/* DepartureType */}
                    <div className={"col-12 col-sm-3 my-1 d-inline " + textColorClass}>
                        <div className="btn-group btn-sm rounded-1" role="group" aria-label="Basic example">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="FromAirport" value="depType0" checked={departureType === 0} onChange={(e) => onChangeDepartureType(e, 0)} />

                                <data m3ico="RoundTrip text-gray-600 icon-10">
                                    <M3Icon iconName="Airport" externalClass={"icon-10 " + textColorClass} />
                                </data>
                                <label className={"form-check-label " + textColorClass} htmlFor="FromAirport">
                                    <data m3lab="SearchEngine.Airport">
                                        {t('SearchEngine:Airport')}
                                    </data>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={"col-12 col-sm-3 my-1 d-inline " + textColorClass}>
                        <div className="btn-group btn-sm rounded-1" role="group" aria-label="Basic example">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="FromDestination" value="depType1" checked={departureType === 1} onChange={(e) => onChangeDepartureType(e, 1)} />

                                <data m3ico="RoundTrip text-gray-600 icon-10">
                                    <M3Icon iconName="Destination" externalClass={"icon-10 " + textColorClass} />
                                </data>
                                <label className={"form-check-label " + textColorClass} htmlFor="FromDestination">
                                    <data m3lab="SearchEngine.Destination">
                                        {t('SearchEngine:Destination')}
                                    </data>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Citizenship */}
                <div className={"col-12 col-sm-6 my-1 d-inline text-right " + textColorClass}>
                    <SelectCitizenship
                        defaultCitizenshipId={defaultCitizenshipId}
                        onSelectCitizenship={onSelectCitizenship}
                        modeView={modeView} />
                </div>
            </div>
        </>
    );
}