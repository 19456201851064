import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { M3IconProductType } from '../../Common/M3IconProductType';
import { Loading } from '../../Common/Loading';
import configData from "../../../appsettings.json";

export const FormAddMarkupFee = ({ addedMkpFee, showCalculatorMarkup, showCalculatorFee, handleAddMarkupFee }) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [isSavedOk, setIsSavedOk] = useState(false);
    const [isSavedError, setIsSavedError] = useState(false);
    const [markup, setMarkup] = useState(null);
    const [fee, setFee] = useState(null);

    useEffect(() => {

        if (addedMkpFee) {

            for (let i = 0; i < addedMkpFee.length; i++) {
                let myItem = addedMkpFee[i];
                if (myItem.percentValue && myItem.percentValue >= 0)
                    setMarkup(myItem.percentValue);
                else
                    setFee(myItem.totalPriceAmount);
            }
        }

    }, [])

    const paramOnChange = (type, id, val) => {
        if (type === "MKP") {
            setMarkup(val);

        } else if (type === "FEE") {
            setFee(val);
        }
    }

    const saveChanges = (e) => {
        if (e) e.preventDefault();

        setIsSavedOk(false);
        setIsSavedError(false);
        setIsLoading(true);

        callAddMarkupFee();
    }

    async function callAddMarkupFee() {
        let inputData = { Markup: markup, Fee: fee };
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/AddMarkupFee', requestOption);

        let saveResult = false;

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response && response.success) {
                saveResult = true;
                handleAddMarkupFee(response.addProds);
            }
        }

        setIsSavedOk(saveResult);
        setIsSavedError(!saveResult);
        setIsLoading(false);
    }


    return (
        <>
            <a id="open_AddMarkupFeeCart" data-bs-toggle="modal" data-bs-target="#modal_AddMarkupFeeCart"></a>
            <div className="modal fade" id="modal_AddMarkupFeeCart" tabIndex="-1" aria-labelledby="modal_SaveCartAndPrint" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal_AddMarkupFeeCartLabel">
                                {!showCalculatorFee && showCalculatorMarkup && <> {t("Button:AddMarkup")}</>}
                                {showCalculatorFee && !showCalculatorMarkup && <> {t("Button:AddFee")}</>}
                                {showCalculatorFee && showCalculatorMarkup && <> {t("Button:AddMarkupAndFee")}</>}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Inserisci i valori di Markup e/o Fee e clicca Salva per aggiungere il prodotto. Inserisci valore zero per rimuoverlo.</p>

                            {
                                showCalculatorMarkup && <div className="row">
                                    <div className="col-3 pt-2">
                                        Markup (%)
                                    </div>
                                    <div className="col-3">
                                        <input id="CartAddMarkup" type="number" className="form-control" placeholder="Markup" defaultValue={0} value={markup ? markup : 0} onChange={e => paramOnChange("MKP", e.target.id, e.target.value)} />
                                    </div>
                                    <div className="col-6 pt-2 text-center">
                                        <a id="CartResetMarkup" href className="cursor-pointer" onClick={e => paramOnChange("MKP", "CartAddMarkup", 0)}>Rimuovi</a>
                                    </div>
                                </div>
                            }
                            {
                                showCalculatorFee && <div className="row">
                                    <div className="col-3 pt-2">
                                        Fee
                                    </div>
                                    <div className="col-3">
                                        <input id="CartAddFee" type="number" className="form-control" placeholder="Fee" defaultValue={0} value={fee ? fee : 0} onChange={e => paramOnChange("FEE", e.target.id, e.target.value)} />
                                    </div>
                                    <div className="col-6 pt-2 text-center">
                                        <a id="CartResetFee" href className="cursor-pointer" onClick={e => paramOnChange("FEE", "CartAddFee", 0)}>Rimuovi</a>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='modal-footer'>
                            <div>
                                {!isLoading && isSavedOk && <div><h6>{t(`Cart:Calculator_MarkupFee_SaveOk`)}</h6></div>}
                                {!isLoading && isSavedError && <h6>{t(`Cart:Calculator_MarkupFee_SaveError`)}</h6>}
                            </div>
                            <div>
                                <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t(`Button:Close`)}</button>
                                {isLoading && <Loading textMsg={t(`Cart:Calculator_MarkupFee_Saving`)} isSmall={true} />}
                                {!isLoading && <button type="button" className="btn bt-sm tp-btn-confirm" onClick={(e) => saveChanges(e)}>{t(`Button:Save`)}</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
