import React from 'react';

export const TemplateGallery = ({ photoGallery }) => {

    return (
        <>
            {
                photoGallery && photoGallery.items && photoGallery.items.length > 0 &&
                (
                    <>
                        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-ride="false">
                            <div className="carousel-indicators">
                                {photoGallery.items.map((item, idx) => {
                                    return (<button key={idx} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={`${idx}`} className={idx === 0 ? "active" : ""} aria-current="true" aria-label={`Slide ${idx + 1}`}></button>)
                                })}
                            </div>
                            <div className="carousel-inner" pause="true">
                                {photoGallery.items.map((item, idx) => {
                                    return (
                                        <div key={idx} className={`carousel-item ${idx === 0 ? "active" : ""}`} style={{ maxHeight: '350px' }}>
                                            <img src={item.url} alt={item.description} className="d-block w-100" />
                                            <div className="carousel-caption d-none" style={{ marginBottom: '-3rem' }}>
                                                <div className=" bg-custom w-30 p-1 text-white text-left px-3 rounded-top">
                                                    <h2 className="text-white"><b>{item.description}</b></h2>
                                                    <p></p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </>
                )
            }
        </>
    );
}