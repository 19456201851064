import React from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { formatPrice } from '../../js/Utils.js';
import { BookParameterMandatoryCssClass } from '../../js/Constant';

export const BookParameter = ({ inputId, bookParameter, paramOnChange }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();

    const getLabelSpanClass = (isMandatory) => {
        return isMandatory
            ? BookParameterMandatoryCssClass.Mandatory
            : BookParameterMandatoryCssClass.NotMandatory;
    }

    const getLocalizedName = (id, description) => {
        let bpDesc = t(`bookParameters:${id}`);
        return bpDesc === id ? description : bpDesc;
    }

    const renderBookParameter = () => {
        if (bookParameter.hidden)
            return;

        switch (bookParameter.type) {
            case "Mindcube.Travel.StringBookParameter":
                if (bookParameter.validValues === null || bookParameter.validValues === undefined || bookParameter.validValues.length === 0) {
                    return (
                        <div className="form-floating col-md-3">
                            <input id={inputId} type="text" className="form-control" placeholder={bookParameter.description} required={bookParameter.mandatory} onChange={paramOnChange} value={bookParameter.value} />
                            <label htmlFor={inputId} className="active">
                                <span className={getLabelSpanClass(bookParameter.mandatory)}>{getLocalizedName(bookParameter.id, bookParameter.description)} {bookParameter.mandatory ? "(*)" : ""}</span>
                            </label>
                        </div>
                    );
                } else {
                    return (
                        <div className="form-floating col-md-3">
                            <select id={inputId} className="form-select border-0 border-bottom border-dark" aria-label="Fornitore" required={bookParameter.mandatory} onChange={paramOnChange}>
                                <option value="-1">Seleziona</option>
                                {bookParameter.validValues.map((vv, index) =>
                                    <option key={index} value={vv.key === null ? "-1" : vv.key}>{vv.value}</option>
                                )}
                            </select>
                            <label htmlFor={inputId} className="active">
                                <span className={getLabelSpanClass(bookParameter.mandatory)}>{getLocalizedName(bookParameter.id, bookParameter.description)} {bookParameter.mandatory ? "(*)" : ""}</span>
                            </label>
                        </div>
                    );
                }

            case "Mindcube.Travel.BooleanBookParameter":
                return (
                    <div className="col-md-3 mt-3">
                        <input id={inputId} type="checkbox" placeholder={bookParameter.description} onChange={paramOnChange} />
                        <label htmlFor={inputId} className="active labelBooleanBp">
                            <span className={getLabelSpanClass(bookParameter.mandatory)}>
                                {getLocalizedName(bookParameter.id, bookParameter.description)} {bookParameter.mandatory ? "(*)" : ""}
                                {bookParameter.amount && (<><br/>{formatPrice(bookParameter.amount, bookParameter.currency, cultureName)}</>)}
                            </span>
                        </label>
                    </div>
                )

            case "Mindcube.Travel.DateTimeBookParameter":
                return (
                    <div className="form-floating col-md-3">
                        <input id={inputId} type={bookParameter.dateTimeFormat !== "HH:mm" ? "date" : "text"} className="form-control" placeholder={bookParameter.description} dateTimeFormat={bookParameter.dateTimeFormat} required={bookParameter.mandatory} onChange={paramOnChange} value={bookParameter.value} />
                        <label htmlFor={inputId} className="active">
                            <span className={getLabelSpanClass(bookParameter.mandatory)}>{getLocalizedName(bookParameter.id, bookParameter.description)} {bookParameter.dateTimeFormat} {bookParameter.mandatory ? "(*)" : ""}</span>                            
                        </label>
                    </div>
                )

            case "Mindcube.Travel.StringArrayBookParameter":
                return (
                    <div className="form-floating col-md-3">
                        <input id={inputId} type="text" className="form-control" placeholder={bookParameter.description} required={bookParameter.mandatory} onChange={paramOnChange} value={bookParameter.value} />
                        <label htmlFor={inputId} className="active">
                            <span className={getLabelSpanClass(bookParameter.mandatory)}>{bookParameter.description} {bookParameter.mandatory ? "(*)" : ""}</span>
                        </label>
                    </div>
                );


            // TODO - other types

            default:
                return (<></>);
        }
    }

    return (
        <>
            {renderBookParameter()}
        </>
    )
}