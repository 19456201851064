import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatPrice, formatDateTime } from '../../../js/Utils.js';
import { imageOnError, imageOnLoadFlight } from '../../../js/CDNUtility';
import { getIconUrlToPrint, getDifferenceDayFlight } from '../../../js/PrintUtility';


export const FlightDetailPrint = ({ id, type, isNotStep, flightDetail, isPrintCliente }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const renderPassenger = () => {
        return (
            <>
                {flightDetail.passengers && flightDetail.passengers.length > 0 && flightDetail.passengers[0].fullName === null ?
                    <label>{flightDetail.passengers.length} &nbsp;{t("Print:Passengers")}</label>
                    :
                    flightDetail.passengers && flightDetail.passengers.length > 0 && flightDetail.passengers.map((pax, index) =>
                        <>
                            <label>{pax.fullName}</label>
                            {index < flightDetail.passengers.length - 1 ? ", " : ""}
                        </>
                    )
                }
            </>
        );
    }
 

    const renderPassengerCosts = (passengerFareComponents) => {
        return (
            <>
                {passengerFareComponents.map((paxFareCompo, index) =>
                    <tr key={index}>
                        <td colSpan="3">
                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <td className="w-30">
                                            <label>{paxFareCompo.passenger.fullName ? paxFareCompo.passenger.fullName : ("Pax " + (index + 1))}</label>
                                        </td>
                                        <td className="w-30">
                                            <label>{paxFareCompo.fareDetails[0].fareBasis}</label>
                                        </td>
                                        <td className="w-30">
                                            <label>{paxFareCompo.fareDetails[0].bookingClass !== null ? paxFareCompo.fareDetails[0].bookingClass.split('/')[0] : "--"}</label>
                                        </td>
                                        <td className="w-30">
                                            <label>{paxFareCompo.fareDetails[0].baggages !== null ? paxFareCompo.fareDetails[0].baggages : "--"}</label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                )}
            </>
        );
    }

    return (
        <>
            <div className="w-100 border shadow">
                <table>
                    {flightDetail && flightDetail.legs && flightDetail.legs.length > 0 && flightDetail.legs.map((leg, index) =>
                        leg.segments && leg.segments.length > 0 && leg.segments.map((segment, keySegments) =>
                            <>
                                {((leg.stops && leg.stops.stopsDurations && !leg.stops.stopsDurations[keySegments - 1]) || leg.stops === null) &&
                                    <tr className="text-center">
                                        <td className="w-15 h6 fontBold">
                                            {formatDateTime(segment.departureDate, cultureName, { noYear: true })}
                                        </td>
                                        <td className="w-50 fontBold">
                                            <label className="mr-1 h075">
                                                ({leg.departureAirportIATA})&nbsp;
                                            </label>
                                            <span className="mr-1 h6">
                                                {leg.departureAirportName}
                                            </span>
                                        </td>
                                        <td className="w-50 fontBold">
                                            <label className="mr-1 h075">
                                                ({leg.arrivalAirportIATA})&nbsp;
                                            </label>
                                            <span className="mr-1 h6">
                                                {leg.arrivalAirportName}
                                            </span>
                                        </td>
                                    </tr>
                                }
                                <tr>
                                    <td colSpan="3">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="w-10">
                                                        <img src={`data:image;base64, ` + leg.thumbUrlB} alt={leg.airlineName} className='w-50 p-2 imgFlight' onError={imageOnError} />
                                                        <label className="pl-2">{leg.airlineName}</label>
                                                    </td>
                                                    <td className="w-30">
                                                        <table className="w-100">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="text-left">
                                                                        <label>
                                                                            {t(`Product:Flight:Takeoff`)} ({segment.departureAirportIATA})
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">
                                                                        <label>{formatDateTime(segment.departureDate, cultureName, { onlyTime: true })}</label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-left">
                                                                        <label>{segment.flightNumber}</label>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td className="w-30 text-center">
                                                        <table className="w-100">
                                                            <tbody>
                                                                <tr className="">
                                                                    <td>
                                                                        <label>
                                                                            {t(`Product:Flight:Direct`)}
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td className="text-right">
                                                        <table className="w-100">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <label>
                                                                            {t(`Product:Flight:Landing`)} ({segment.arrivalAirportIATA})
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <label>{formatDateTime(segment.arrivalDate, cultureName, { onlyTime: true })}</label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <label>{getDifferenceDayFlight(segment.departureDate, segment.arrivalDate)}</label>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                {leg.stops && leg.stops.stopsDurations && leg.stops.stopsDurations[keySegments] &&
                                    <tr>
                                        <td className="widthFixed">
                                        </td>
                                        <td className="col-3 col-segment-print">
                                            <div className="w-100 bg-gray-100 rounded text-center">
                                                <label className="h075">
                                                    {t('Product:Flight:Stopover')}&nbsp;
                                                    {leg.stops.stopsDurations[keySegments].duration} h -&nbsp;
                                                    {t('Product:Flight:AirportOf')}&nbsp;
                                                    ({leg.stops.stopsDurations[keySegments].arrivalAirportIATA}) {leg.stops.stopsDurations[keySegments].arrivalAirportName}
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </>
                        )
                    )}
                    <tr>
                        <td className="w-imagePdf">
                            <img src={getIconUrlToPrint('person')} className="ico-image mr-1" alt="" />
                        </td>
                        <td>
                            {renderPassenger()}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="3" className="pt-2">
                            <table className="w-100">
                                <tbody>
                                    <tr className="bg-gray-100 rounded">
                                        <td className="w-30">
                                            <span style={{ fontSize: "14px" }}>
                                                {t("MyBook:Passenger")}
                                            </span>
                                        </td>
                                        <td className="w-30">
                                            <span style={{ fontSize: "14px" }}>
                                                {t("Product:Flight:FareBasis")}
                                            </span>
                                        </td>
                                        <td className="w-30">
                                            <span style={{ fontSize: "14px" }}>
                                                {t("Product:Flight:Class")}
                                            </span>
                                        </td>
                                        <td className="w-30">
                                            <span style={{ fontSize: "14px" }}>
                                                {t("Product:Flight:Bagages")}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    {renderPassengerCosts(flightDetail.passengerFareComponents)}
                    {
                        flightDetail.notes && <tr>
                            <td>
                                <label>{t('General:Note')}</label>
                            </td>
                            <td>
                                {flightDetail.notes}
                            </td>
                        </tr>
                    }
                </table>
            </div>
        </>
    );
}