import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Loading } from '../Common/Loading';
import configData from "../../appsettings.json";
import Cookies from 'universal-cookie';
import { getWebsiteId } from '../../js/Utils.js';

export const RegistrationConfirm = () => {
    const { t } = useTranslation();
    let { ConfirmToken } = useParams();
    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");
    const [username, setUsername] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [isLoadMessagge, setIsLoadMessage] = useState(false);
    const [message, setMessage] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (ConfirmToken) {
            callActivateEmail();
        }
    }, []);

    async function callActivateEmail() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'user/ValidateTokenAndSendEmail/' + ConfirmToken + "/" + parseInt(getWebsiteId()) + "/" + cultureCode , requestOption);
        const response = await fetchedRes.json();

        setIsLoadMessage(true);
        if (response.success && response.send) {
            setMessage("Registrazione avvenuta con successo");
            window.location.href = configData.Settings.Base_Url; // redirect to login
        }
        else if (response.success && response.NotValid) {
            setMessage("Il codice di verifica inserito non è valido.");
        }
        else {
            setMessage("Errore invio mail");
        }
    }

    return (
        <>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className="col-3 d-none d-flex"></div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-left">
                        {isLoadMessagge &&
                            message
                        }
                    </div>
                </div>
            </div>
        </>
    );
}