import React, { useState, useEffect } from 'react';
import { TourList } from './TourList';
import { TourDetail } from './TourDetail';
import { scrollTo } from '../../../../js/Utils.js';

export const TrippieTourProductResult = ({ productSearchParam, onAddTour }) => {
    const [extraParam, setExtraParam] = useState(null);
    const [availabilityParam, setAvailabilityParam] = useState(null);
    const [availabilityParamArr, setAvailabilityParamArr] = useState([]);
    const [forceClearIntervalList, setForceClearIntervalList] = useState([]);

    const [showPanel, setShowPanel] = useState(null);
    const [forceRender, setForceRender] = useState(null);

    useEffect(() => {

        if (productSearchParam) {
            setExtraParam({
                carpetId: productSearchParam.carpetId,
                clientFlowId: productSearchParam.clientFlowId,
                productId: 0
            });

            setShowPanel(null);
            setAvailabilityParamArr([]);
            setAvailabilityParam(null);
        }

    }, [productSearchParam])


    async function onClickSearch(tourId, tourName, filters, filterSelected, tourSearchParameters) {
        let param = {};

        param.clientFlowId = productSearchParam.clientFlowId;
        param.carpetId = productSearchParam.carpetId;
        param.tourId = tourId;
        param.name = tourName;
        param.cartItemId = productSearchParam.cartItemId;

        clearCarpetAllInterval();
        let updAvailabilityParam = [...availabilityParamArr];

        if (updAvailabilityParam.some(x => x.tourId === tourId)) {
            changePanel(tourId);
        } else {
            updAvailabilityParam.push(param);
            setAvailabilityParamArr(updAvailabilityParam);

            setAvailabilityParam(param);
            setShowPanel(tourId);
        }

        scrollTo(null, "trippieSearchListResult");
    }

    function changePanel(tourId) {
        setShowPanel(tourId);

        if (tourId) {
            let avlParam = availabilityParamArr.filter(x => x.tourId === tourId);
            if (avlParam.length > 0)
                setAvailabilityParam(avlParam[0]);

            setForceRender(forceRender + 1);
        } else {
            setAvailabilityParam(null);
        }
    }

    function closePanel(tourId, event) {
        event.stopPropagation();

        if (showPanel === tourId) {
            setShowPanel(null);
            setAvailabilityParam(null);
        }

        if (tourId) {
            let avlParam = availabilityParamArr.filter(x => x.tourId !== tourId);
            setAvailabilityParamArr(avlParam);
        } else {
            setShowPanel(null);
            setAvailabilityParam(null);
        }
    }

    function saveIntervalCarpetPing(intervalPing) {
        let upd = [...forceClearIntervalList];
        upd.push(intervalPing);
        setForceClearIntervalList(upd);
    }

    function clearCarpetAllInterval() {
        if (forceClearIntervalList) {
            for (let i = 0; i < forceClearIntervalList.length; i++) {
                try {
                    let intId = forceClearIntervalList[i];
                    clearInterval(intId);
                } catch (ex) { }

            }

            setForceClearIntervalList([]);
        }
    }

    function onAddProduct(tourRecap) {
        clearCarpetAllInterval();
        onAddTour(productSearchParam.stepIndex, productSearchParam.cartItemId, tourRecap, productSearchParam.carpetId, availabilityParam.carpetAvailabilityId);
    }

    return (
        <>
            {
                <ul class="nav nav-tabs">
                    <li class="nav-item" aria-current="page" onClick={_ => changePanel(null)}>
                        <div className={"nav-link " + (showPanel ? "" : "active")}>List</div>
                    </li>
                    {
                        availabilityParamArr && availabilityParamArr.map((ap, index) => {
                            return <li key={index} title={ap.name} class="nav-item" aria-current="page" onClick={_ => changePanel(ap.tourId)}>
                                <div className={"nav-link " + (showPanel && showPanel === ap.tourId ? "active" : "")}>
                                    {ap.name.substring(0, 10)}
                                    <button type="button" class="btn-close h-25 position-relative" style={{ 'top': '-12px', 'right': '-10px' }} aria-label="Close" onClick={e => closePanel(ap.tourId, e)}></button>
                                </div>
                            </li>
                        })
                    }
                </ul>
            }
            {
                productSearchParam.carpetId && !availabilityParam && <TourList
                    carpetId={productSearchParam.carpetId}
                    clientFlowId={productSearchParam.clientFlowId}
                    extraParam={extraParam}
                    saveIntervalCarpetPing={saveIntervalCarpetPing}
                    onClickSearch={onClickSearch} />
            }
            {
                availabilityParam && <TourDetail
                    forceRender={forceRender}
                    clientFlowId={availabilityParam.clientFlowId}
                    carpetSearchId={availabilityParam.carpetId}
                    cartItemId={productSearchParam.cartItemId}
                    tourId={availabilityParam.tourId}
                    step={productSearchParam.stepIndex}
                    saveIntervalCarpetPing={saveIntervalCarpetPing}
                    onAddProduct={onAddProduct} />
            }
        </>
    );
}