import React, { useState } from 'react';
import { RenderByPlaceholder } from './WebMenuRenderType/RenderByPlaceholder';
import { WebMenuRow } from './WebMenuRenderType/WebMenuRow';
import { WebMenuListVertical } from './WebMenuRenderType/WebMenuListVertical';
import { WebMenuListHorizontal } from './WebMenuRenderType/WebMenuListHorizontal';
import { WebMenuNavBar } from './WebMenuRenderType/WebMenuNavBar';

export const WebMenuVoices = ({ item, rules, modal }) => {

    function haveCustomRender(rt) {
        switch (rt) {
            case 0:
            case 1:
            case 2:
            case 3:
                return true;

            default:
                return false;
        }
    }

    return (
        <>
            {
                !haveCustomRender(item.renderType) && <RenderByPlaceholder modal={modal} item={item} rules={rules} />
            }

            {item.renderType === 0 && <WebMenuRow modal={modal} item={item} rules={rules} />}
            {item.renderType === 1 && <WebMenuListVertical modal={modal} item={item} rules={rules} />}
            {item.renderType === 2 && <WebMenuListHorizontal modal={modal} item={item} rules={rules} />}
            {item.renderType === 3 && <WebMenuNavBar modal={modal} item={item} rules={rules} />}
        </>
    );

}
