import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useSearchParams } from "react-router-dom";
import configData from "../../../appsettings.json";

export const IntesaSanPaoloSuccess = () => {
    const cookies = new Cookies();

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        let orderNumber = cookies.get("orderNumber");
        if (orderNumber) {
            let baseUrlConfirmCart = `${configData.Settings.Base_Url}cart-confirm/${orderNumber}`;
            let currentUserObj = JSON.parse(localStorage.getItem("CurrentUser"));
            if (currentUserObj.autoLoginToken)
                baseUrlConfirmCart = `${configData.Settings.Base_Url}?ual=${currentUserObj.autoLoginToken}&ru=${encodeURIComponent(baseUrlConfirmCart)}`;

            window.location.href = baseUrlConfirmCart;
        }
    }, []);

    return (
        <div className="row text-center">
            <div className="col-12">
                <p className="penalty free">Transazione completata con successo.</p>
                <p>Clicca 'Chiudi' per aggiornare la pagina</p>
            </div>
        </div>
    );
}
