import React, { useState, useEffect } from 'react';
import { M3Icon } from "../../../Common/M3Icon";
import { TrainFarePivotExtended } from "./TrainFarePivotExtended";
import { TrainFarePivotCompactView } from "./TrainFarePivotCompactView";

export const TrainFarePivot = ({ leg, fares, showCost, templateInfo, selectFares, onSelectFares }) => {

    const [departureSegments, setDepartureSegments] = useState([]);
    const [farePivotRowHeader, setFarePivotRowHeader] = useState([]);
    const [itemsListMode, setItemsListMode] = useState(0);

    useEffect(() => {
        if (fares && fares.tariffe) {
            let updHeaderPivot = [];

            for (let i = 0; i < fares.tariffe.length; i++) {
                let pivot = fares.tariffe[i].farePivot;

                let pivotHeader = [];

                if (pivot) {

                    for (let j = 0; j < pivot.length; j++) {
                        let rows = pivot[j].rows;

                        for (let k = 0; k < rows.length; k++) {
                            let fc = rows[k].fareComponents;

                            if (pivotHeader.length >= (k + 1)) {
                                if (fc && fc.serviceTypeId) {
                                    let oldValue = pivotHeader[k];
                                    if (!oldValue)
                                        pivotHeader[k] = fc.serviceTypeId;
                                }
                            } else {
                                if (fc && fc.serviceTypeId) {
                                    pivotHeader.push(fc.serviceTypeId);
                                } else {
                                    pivotHeader.push(null);
                                }
                            }

                        }

                        if (pivotHeader.every(x => x !== null))
                            break;
                    }
                }

                updHeaderPivot.push(pivotHeader);
            }

            setFarePivotRowHeader(updHeaderPivot);
        }

    }, [fares])

    useEffect(() => {
        let segments = [];

        if (leg) {
            for (let i = 0; i < leg.segments.length; i++) {
                segments.push(leg.segments[i]);
            }
        }

        setDepartureSegments(segments);
    }, [leg])

    function onChangeItemsListMode(mode) {
        setItemsListMode(mode);
    }

    function onChangeShowFares(mode) {
        setItemsListMode(mode);
    }

    return (
        <>
            {
                fares && fares.tariffe && fares.tariffe.map((fare, keyTariffe) => {
                    return <React.Fragment key={keyTariffe}>
                        <div className="row pt-4 d-none d-sm-block">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                {
                                    departureSegments && departureSegments.length >= (keyTariffe + 1) && <div className="row  pt-2 border-top">
                                        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 pt-1">
                                            <h5 className="  w-100  mb-1">
                                                {departureSegments[keyTariffe].vehicle && <>{departureSegments[keyTariffe].vehicle && <>{departureSegments[keyTariffe].vehicle.name}: {departureSegments[keyTariffe].vehicle.number}</>}</>}
                                            </h5>
                                            <h6>{departureSegments[keyTariffe].departure.stationName} - {departureSegments[keyTariffe].arrival.stationName} ({departureSegments[keyTariffe].duration}h)</h6>
                                        </div>
                                        <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-right">
                                            <button class={(itemsListMode === 0 ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 rounded border-0 shadow"} onClick={(e) => { onChangeItemsListMode(0) }}>
                                                <data m3ico="ResultList icon-25 "><M3Icon iconName="ResultList" externalClass="icon-25" /></data>
                                            </button>
                                            <button class={(itemsListMode === 1 ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 rounded border-0 shadow"} onClick={(e) => { onChangeItemsListMode(1) }}>
                                                <data m3ico="ResultBloc icon-25 "><M3Icon iconName="ResultBloc" externalClass="icon-25" /></data>
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                            {
                                itemsListMode === 0 && <TrainFarePivotExtended
                                    fare={fare}
                                    index={keyTariffe}
                                    farePivotRowHeader={farePivotRowHeader[keyTariffe]}
                                    selectFares={selectFares}
                                    showCost={showCost}
                                    templateInfo={templateInfo}
                                    onSelectFares={onSelectFares}
                                    onChangeShowFares={onChangeShowFares} />
                            }
                            {
                                itemsListMode === 1 && <TrainFarePivotCompactView
                                    fare={fare}
                                    index={keyTariffe}
                                    selectFares={selectFares}
                                    showCost={showCost}
                                    templateInfo={templateInfo}
                                    onSelectFares={onSelectFares} />
                            }

                        </div>
                    </React.Fragment>
                })
            }
            {
                fares && fares.tariffe && fares.tariffe.map((fare, keyTariffe) => {
                    return <React.Fragment key={keyTariffe}>
                        <div className="row pt-4 d-block d-sm-none">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                {
                                    departureSegments && departureSegments.length >= (keyTariffe + 1) && <div className="row  pt-2 border-top">
                                        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 pt-1">
                                            <h5 className="  w-100  mb-1">
                                                {departureSegments[keyTariffe].vehicle && <>{departureSegments[keyTariffe].vehicle && <>{departureSegments[keyTariffe].vehicle.name}: {departureSegments[keyTariffe].vehicle.number}</>}</>}
                                            </h5>
                                            <h6>{departureSegments[keyTariffe].departure.stationName} - {departureSegments[keyTariffe].arrival.stationName} ({departureSegments[keyTariffe].duration}h)</h6>
                                        </div>
                                    </div>
                                }
                            </div>

                            <TrainFarePivotCompactView
                                    fare={fare}
                                    index={keyTariffe}
                                    selectFares={selectFares}
                                    showCost={showCost}
                                    templateInfo={templateInfo}
                                    onSelectFares={onSelectFares} />


                        </div>
                    </React.Fragment>
                })
            }
        </>
    );
}