import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Loading } from '../../Common/Loading';
import configData from "../../../appsettings.json";
import { formatDateTime } from '../../../js/Utils.js';
import { Map } from '../../Common/Map';
import { DynamicNote } from '../DynamicNote';
import { BookingNote } from '../BookingNote';
import { useTranslation } from 'react-i18next';
import { replaceAllStyleElements, getPaxGenderVoucher } from '../../../js/PrintUtility';
import { BookParameterId } from '../../../js/Constant';


export const VoucherCarRentalDetail = ({ id, customProductDetail, customDynamicNote, customBookingNote }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [productDetail, setProductDetail] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        const getProductDetail = async (inputData) => {
            const response = callGetProductDetail(inputData);
        }
        getProductDetail({ PNR: id, ProductType: configData.Settings.Products.CarRental.IdTipoProdotto, Options: { WebsiteBaseUrl: configData.Settings.OldWebSite_BaseUrl } });

    }, []);

    function onCustomDynamicNote(dynamicNote) {
        
        customDynamicNote(dynamicNote);
    }
    function onCustomBookingNote(bookingNote) {
        
        customBookingNote(bookingNote);
    }

    async function callGetProductDetail(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetReservationDetail', requestOption);
        const response = await fetchedRes.json();
        debugger;
        setProductDetail(response);
        customProductDetail(response);
        setIsLoading(false);
    }

    const renderSplitText = (text) => {
        var splittedText = text.split(".");
        let ret = "";
        splittedText.map((t, index) => ret += t+".\r\n");
        return ret;
    }

    const renderInclusion = (inclusion) => {
        let arrInclusion = inclusion.split("-");
        if (arrInclusion.length > 1) {
            return (<span><label>{arrInclusion[0]}</label>
                {renderSplitText(arrInclusion[1])}
                    </span>)
            } else {
            return (<span>{renderSplitText(arrInclusion[0])}</span>)
            }
    }
    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : (
                    <>
                        <div className="stepHeader text-center mt-2 mb-2" style={{ borderRadius: "5px", padding: "7px" }}>
                            <h5 className="color-text-voucher">{t("Print:Voucher:CarRental")} &nbsp;- &nbsp; {t("Print:Voucher:BookingNumber")}: {productDetail.option.providerRecordLocatorId}</h5>
                        </div>

                        <div style={{ border: "1px solid grey", borderRadius: "5px" }}>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <span><label>{t("Print:Voucher:Service")}:</label> {productDetail.name}</span>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <span><label>Ref. Pax:</label> {productDetail.option.refPax}</span>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <span><label>{t("Print:Voucher:DatePickUp")}:</label> {formatDateTime(productDetail.pickUpInfo.pickupDateTime, cultureName, { withTime: true })}</span>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <span><label>{t("Print:Voucher:DateDropOff")}:</label> {formatDateTime(productDetail.dropOffInfo.dropOffDateTime, cultureName, { withTime: true })}</span>
                                </div>
                                
                                {productDetail.option.passenger && productDetail.option.passenger &&
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <span key={productDetail.option.passenger.index} ><label>{t("Print:Voucher:Pax")} {productDetail.option.passenger.index} :</label> {productDetail.option.passenger.fullName}</span> {getPaxGenderVoucher(productDetail.option.passenger, BookParameterId)} {productDetail.option.passenger.paxType !== "Adult" && productDetail.option.passenger.paxType}
                                    </div>
                                }
                                {productDetail.option.providerReservationId &&
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <span><label>{t("Print:Voucher:BookingNumber")}:</label> {productDetail.option.providerReservationId}</span>
                                    </div>
                                }
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <span><label>{t("Print:Voucher:Supplier")}:</label> {productDetail.priceBar.supplier}</span>
                                </div>
                            </div>
                        </div>

                        <div className="stepHeader text-center mt-2 mb-2" style={{ borderRadius: "5px", padding: "7px" }}>
                            <h5 className="color-text-voucher">{t("Print:Voucher:BookingDetailReservationNumber")}:&nbsp;{productDetail.priceBar.wsBookingId}</h5>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className=" card border-gray-500  mb-2 document-print-6">
                                        <div className="p-2">
                                            <label>{t("Print:Voucher:PickUpPoint")}</label>
                                            <div><span>{productDetail.pickUpInfo.officeName}</span></div>
                                            <div><span>{productDetail.pickUpInfo.address}</span></div>
                                            {productDetail.pickUpInfo.opening.openingTime && productDetail.pickUpInfo.opening.openingTime !== "-" && <div><span><label>{t("Print:Voucher:Opening")}:</label> {productDetail.pickUpInfo.opening.openingTime} / {productDetail.pickUpInfo.opening.closingTime}</span></div>}
                                            {productDetail.pickUpInfo.subSupplierName && <div><span><label>{t("Print:Voucher:Supplier")}:</label> {productDetail.pickUpInfo.subSupplierName}</span></div>}
                                            <div>
                                                <label><i className="ph-phone-bold mr-2"></i> {productDetail.pickUpInfo.phone}</label>
                                                <label><i className="ph-envelope-simple-bold"></i> {productDetail.pickUpInfo.email}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 document-print-6">
                                    <div className=" card border-gray-500  mb-2">
                                        <div className="p-2">
                                            <label>{t("Print:Voucher:DropOffPoint")}</label>
                                            <div><span>{productDetail.dropOffInfo.officeName}</span></div>
                                            <div><span>{productDetail.dropOffInfo.address}</span></div>
                                            {productDetail.dropOffInfo.opening.openingTime && productDetail.dropOffInfo.opening.openingTime !== "-" && <div><span><label>{t("Print:Voucher:Opening")}:</label> {productDetail.dropOffInfo.opening.openingTime} / {productDetail.dropOffInfo.opening.closingTime}</span></div>}
                                            {productDetail.dropOffInfo.subSupplierName && <div><span><label>{t("Print:Voucher:Supplier")}:</label> {productDetail.dropOffInfo.subSupplierName}</span></div>}
                                            <div>
                                                <label><i className="ph-phone-bold mr-2"></i> {productDetail.dropOffInfo.phone}</label>
                                                <label><i className="ph-envelope-simple-bold"></i> {productDetail.dropOffInfo.email}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mt-4 border-gray-500 mb-3">
                                    <div className="card">
                                        <div className="p-2">
                                            <h5>{t("Print:Voucher:Characteristics")}</h5>
                                            <div>
                                                <span><label>SIPP:</label> {productDetail.sippCode}</span> <span> <label>{t("Print:Voucher:Passengers")}:</label> {productDetail.passengers}</span>
                                                {productDetail.doors && <span> <label> {t("Print:Voucher:Doors")}:</label> {productDetail.doors}</span>}
                                                {productDetail.luggages !== null ? (<span> <label> {t("Print:Voucher:Bagagges")}:</label> <span>{productDetail.luggages}</span></span>) : (<></>)}
                                            </div>
                                            {productDetail.option.inclusions &&
                                            <>
                                                <h5 className="pt-2">{t("Print:Voucher:Inclusions")}</h5>
                                                <div className="document-txt-note text-justify ">
                                                    <ul>
                                                        {productDetail.option.inclusions.map((inclusion, index) =>
                                                            <li key={index}>
                                                                {renderInclusion(inclusion)}
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {<BookingNote idPratica={productDetail.idPratica} customBookingNote={onCustomBookingNote} />}
                                {<DynamicNote pageId="11" idTipoProdotto={configData.Settings.Products.CarRental.IdTipoProdotto} productDetail={productDetail} customDynamicNote={onCustomDynamicNote} />}

                                {/*<!--MAPPA-->*/}
                                <div className="py-0 mt-4">
                                    <div className="row">
                                        {productDetail.pickUpInfo.longitude && productDetail.pickUpInfo.latitude &&
                                            <div className="col-12">
                                                <label>PickUp</label>
                                                {<Map longitude={productDetail.pickUpInfo.longitude} latitude={productDetail.pickUpInfo.latitude} />}
                                            </div>
                                        }
                                        {productDetail.dropOffInfo.longitude && productDetail.dropOffInfo.latitude &&
                                            <div className="col-12">
                                                <label>DropOff</label>
                                                {<Map longitude={productDetail.dropOffInfo.longitude} latitude={productDetail.dropOffInfo.latitude} />}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );

}