import React, { useState, useEffect } from 'react';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';


export const SelectContact = ({ onSelectContact, selectContacts, titleForm, subTitleForm, mode, extraParams, defaultUserIdToSend, defaultContactIdToSend }) => {
    const { t } = useTranslation();
    const [contacts, setContacts] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [totalContacts, setTotalContacts] = useState(0);
    const [itemsPerPage] = useState(20);
    const [isLoadingFilter, setIsLoadingFilter] = useState(false);
    const [isSetDefaultFirstTime, setIsSetDefaultFirstTime] = useState(false);

    useEffect(() => {
        let inputData = {
            IdStakeholder: -1,
            filterName: filterName,
            PageNum: 1,
            PageSize: itemsPerPage
        };

        const getContacts = async () => {
            const response = callGetContacts(inputData);
        }

        getContacts();

    }, []);

    async function callGetContacts(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'contact/getcontactsrubrica', requestOption);
        var myItems = [];

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            myItems = response.contacts;
            setTotalContacts(response.totalContacts);

            if (myItems && myItems.length > 0)
                myItems = myItems.filter((x) => x.email && x.userId);

            setContacts(myItems);

            if (defaultContactIdToSend && !isSetDefaultFirstTime) {
                let contact = myItems.filter((x) => x.contactId === defaultContactIdToSend);
                if (contact !== null && contact.length > 0)
                    onSelectContact(contact[0]);
            }

        } else {
            setContacts(myItems);
        }

        setIsSetDefaultFirstTime(true);
        setIsLoadingFilter(false);
    }

    function handleKeyPressRubrica(event) {
        if (event.key === 'Enter') {
            filterContacts();
        }
    }

    const filterContacts = async e => {
        setIsLoadingFilter(true);

        let inputData = {
            IdStakeholder: -1,
            filterName: filterName,
            PageNum: 1,
            PageSize: itemsPerPage
        };

        const response = await callGetContacts(inputData);
    }

    function onselectUserId(contact) {
        let clickedContact = contacts.filter(c => c.contactId === contact.contactId);
        onSelectContact(clickedContact[0]);
    }

    return (
        <>
            <div className="row mt-4">
                <div className="col-12 font-weight-bold h6">{titleForm}</div>
                {subTitleForm && <div className="col-12 font-weight-bold h6">{subTitleForm}</div>}
                {mode === 'QUOTATION' && extraParams && selectContacts && selectContacts.length > 0 && <div className="col-12 font-weight-bold h6"><input type="checkbox" defaultValue="false" value={extraParams.canAutoLogin} onChange={(e) => extraParams.onCheckAutoLogin()} />Flagga qui se l'utente può procedere all'acquisto</div>}
                <div className="form-floating col-9">
                    <input className="form-control" placeholder="Cerca per nome cognome"
                        id="sendMail_Rubrica"
                        type="text"
                        onChange={e => setFilterName(e.target.value)}
                        onKeyPress={e => handleKeyPressRubrica(e)}
                    />
                    <label htmlFor="sendMail_Rubrica" className="active">{t("MyBook:FindForSurname")}</label>
                </div>
                <div className="col-3 form-floating">
                    <button className="btn btn-sm tp-btn-confirm postion-relative" style={{ "top": "18px" }} onClick={filterContacts}>
                        {
                            isLoadingFilter ? <div className="spinner-border text-primary" role="status"><span className="sr-only">{t("MyBook:Saving")}</span></div>
                                : <><span className="material-icons-outlined">search</span> {t("MyBook:Search")}</>
                        }
                    </button>
                </div>
                {
                    !isSetDefaultFirstTime && isLoadingFilter && <div className="spinner-border text-primary" role="status"><span className="sr-only">{t("MyBook:LoadContact")}</span></div>
                }
                {
                    contacts && <div className="form-floating col-12 mt-4" style={{ "maxHeight": "140px", "overflowY": "auto" }} >
                        <ul className="list-unstyled">
                            {contacts.map((contact, key) => {
                                return <li key={key} className={"px-2 py-1 my-1 " + (selectContacts && selectContacts.some((x) => x.contactId === contact.contactId) ? "bg-gradient-gray-400" : "")}>
                                    <div className="row mb-2">
                                        <div className="col-3">{contact.name}</div>
                                        <div className="col-3">{contact.surname}</div>
                                        <div className="col-3">{contact.email}</div>
                                        <div className="col-3">
                                            <button
                                                className={"btn btn-sm w-75 " + (selectContacts && selectContacts.some((x) => x.contactId === contact.contactId) ? "tp-btn-delete" : "tp-btn-add")}
                                                onClick={e => onselectUserId(contact)}>
                                                {selectContacts && selectContacts.some((x) => x.contactId === contact.contactId) ? "Deseleziona" : "Seleziona"}
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            })}
                            {
                                totalContacts > itemsPerPage && <li>{t("MyBook:FilterNameAndSurname")}</li>
                            }
                        </ul>
                    </div>
                }
            </div>
        </>
    );
}