import React, { useState, useEffect } from 'react';

export const ProductDataAmenities = ({ allAmentities, selectAmenties, onUpdateData }) => {

    const [normalizeAmenities, setNormalizeAmenities] = useState([]);

    useEffect(() => {
        if (allAmentities && selectAmenties) {
            let updAmenities = [...allAmentities];

            for (let i = 0; i < selectAmenties.length; i++) {
                let index = updAmenities.findIndex(x => x.id === selectAmenties[i].code);

                if (index >= 0)
                    updAmenities[index].isChecked = true;

            }

            setNormalizeAmenities(updAmenities);
        } else {
            setNormalizeAmenities(allAmentities);
        }

    }, [allAmentities])

    function handleChangeIsChecked(event, amenity) {
        let isChecked = event.currentTarget.checked;
        let updAmenities = [...normalizeAmenities];

        let index = updAmenities.findIndex(x => x.id === amenity.id);
        if (index >= 0)
            updAmenities[index].isChecked = isChecked;
        else
            updAmenities[index].isChecked = false;

        setNormalizeAmenities(updAmenities);
        onUpdateData(updAmenities.filter(x => x.isChecked).map(x => { return { code: x.id, name: x.name } }));
    }

    return (
        <>
            <div className="row">
                {
                    normalizeAmenities && normalizeAmenities.map((amenity, index) => {
                        return <div key={index} className="col-3 mb-2">
                            <input type="checkbox"
                                id={"cbAmenity_" + amenity.id}
                                className="position-relative mr-1"
                                style={{ top: '2px' }}
                                defaultChecked={amenity.isChecked}
                                checked={amenity.isChecked}
                                onChange={e => handleChangeIsChecked(e, amenity)} />
                            <label for={"cbAmenity_" + amenity.id} className="cursor-pointer">{amenity.name}</label>
                        </div>
                    })
                }
            </div>
        </>
    );
}
