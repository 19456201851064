import React, { useState, useEffect } from 'react';
import configData from "../../../../appsettings.json";

export const FooterVersion = ({ item, modal }) => {
    const [version, setVersion] = useState();

    const MyKeyVersion = "WebsiteVersion";

    useEffect(() => {

        let strMyVersion = sessionStorage.getItem(MyKeyVersion);
        if (!strMyVersion) {

            const getVersion = async () => { const response = callGetVersion(); }
            getVersion();

        } else {
            let jMyVersion = JSON.parse(strMyVersion);
            setVersion(jMyVersion);
        }

    }, [])

    async function callGetVersion() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Constant/GetVersion', requestOption);
        const response = await fetchedRes.json();
        if (response && response.length > 0) {
            const myVersion = response[0].version;
            setVersion(myVersion);
            sessionStorage.setItem(MyKeyVersion, JSON.stringify(myVersion));
        }
    }

    return (
        <div className={item.className ? item.className : ""}>
            <div className="font-weight-normal font-small mb-0">
                <span className="powered-by">Powered by <a href="https://www.mind3.ch" target="_blank">MIND3 SA</a></span>
            </div>
            <div className="text-right">
                {version}
            </div>
        </div>
    );
}
