import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { Loading } from '../Common/Loading';
import { BookParameterId, BookParameterMandatoryCssClass } from '../../js/Constant';
import { validateBookParam } from '../../js/Utils';
import configData from "../../appsettings.json";

export const CartPaxes = ({ inputData, handlePaxesBookParams, mandatoryBookParams, precompiledOrderPaxesBookParams, isCommissionable }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [countries, setCountries] = useState([]);
    const [dialCodes, setDialCodes] = useState([]);
    const [paxStraniero, setPaxStraniero] = useState(false);


    useEffect(() => {
        setIsLoading(true);

        if (!countries || countries.length === 0) {
            const getCountries = async () => { const response = callGetCountries(); }
            getCountries();
        }
          
        if (!dialCodes || dialCodes.length === 0) {
            const getDialCodes = async () => { const response = callGetDialCodes(); }
            getDialCodes();
        }

        setIsLoading(false);
    }, []);


    async function callGetCountries() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Constant/GetCountries/' + cultureName, requestOption);
        const response = await fetchedRes.json();
        setCountries(response);
    }

    async function callGetDialCodes() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Constant/GetDialCodes', requestOption);
        const response = await fetchedRes.json();
        setDialCodes(response);
    }

    async function callCheckFiscalCode(pax, id, val) {
        let isValid = val.length === 16;
        if (isValid) {
            const inputData = { FiscalCode: val };
            const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
            const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Utility/FiscalCodeCheck', requestOption);
            const response = await fetchedRes.json();
            isValid = response.success;
        }

        paramOnChange(pax, id, val, isValid);

        if (isValid) {
            // se e' valido, compilo automaticamente gender, data di nascita e citta di nascita se non sono gia' state compilate
            const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
            const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Utility/FiscalCodeGetData/' + val, requestOption);
            const response = await fetchedRes.json();
            if (response.success) {
                let aSplit = id.split('_');
                let paxIndex = aSplit[1];

                let inputDateOfBirth = document.getElementById(`BpPax_${paxIndex}_${BookParameterId.Personal.BirthDate}`);
                if (inputDateOfBirth.value === "") {
                    let dob = response.birthDate.substring(0, 10);
                    inputDateOfBirth.value = dob;
                    paramOnChange(pax, `BpPax_${paxIndex}_${BookParameterId.Personal.BirthDate}`, dob);
                }

                let inputBirthCity = document.getElementById(`BpPax_${paxIndex}_BirthCity`);
                if (inputBirthCity.value === "") {
                    inputBirthCity.value = response.birthCity;
                    paramOnChange(pax, `BpPax_${paxIndex}_BirthCity`, response.birthCity);
                }
            }
        }
    }


    const getPaxType = (paxType) => { return paxType === 0 ? t("SearchEngine:Adult") : t("SearchEngine:Child") }
    const getPaxAge = (pax) => { return pax.paxType === 0 ? "" : "(" + pax.age + " anni)"; }

    // imposta il pax straniero disabilitando e pulendi cod fiscale e citta di nascita
    const paxStranieroOnChange = (pax, id, checked) => {
        let aSplit = id.split('_');
        let paxIndex = aSplit[1];

        let inputFiscalCode = document.getElementById(`BpPax_${paxIndex}_${BookParameterId.Personal.FiscalCode}`);
        let inputBirthCity = document.getElementById(`BpPax_${paxIndex}_BirthCity`);

        if (checked) {
            inputBirthCity.value = "";
            inputBirthCity.required = false;
            paramOnChange(pax, `BpPax_${paxIndex}_BirthCity`, "");

            let fakeFiscalCode = "9999999999999999";
            inputFiscalCode.value = fakeFiscalCode;
            inputFiscalCode.required = false;
            paramOnChange(pax, `BpPax_${paxIndex}_${BookParameterId.Personal.FiscalCode}`, fakeFiscalCode, true);
        } else {
            inputFiscalCode.value = "";
            paramOnChange(pax, `BpPax_${paxIndex}_${BookParameterId.Personal.FiscalCode}`, "", true);
        }

        setPaxStraniero(checked);
    }

    // controlla se un book parameter è obbligatorio
    const checkMandatory = (paxIndex, bpId) => {
        if (isCommissionable) {
            switch (bpId) {
                case BookParameterId.Personal.FiscalCode:
                case "BirthCity":
                case BookParameterId.Address.Address1:
                case BookParameterId.Address.City:
                case BookParameterId.Address.Zip:
                    return true;

                default:
                    break;
            }
        }

        if (mandatoryBookParams === null || mandatoryBookParams === undefined)
            return false;

        let bpIdx = mandatoryBookParams.findIndex(x => x.Id === bpId);
        if (bpIdx < 0)
            return false;

        let bp = mandatoryBookParams[bpIdx];
        if (bp.PaxIndexes.length > 0 && !bp.PaxIndexes.includes(paxIndex))
            return false;

        return true;
    }

    const getLabelSpanClass = (paxIndex, bpId) => {
        let isMandatory = checkMandatory(paxIndex, bpId);
        return isMandatory
            ? BookParameterMandatoryCssClass.Mandatory
            : BookParameterMandatoryCssClass.NotMandatory;
    }

    const getLocalizedName = (id) => {
        let bpDesc = t(`bookParameters:${id}`);
        return bpDesc;
    }

    // gestisce l'evento di onChange sul valore del parametro, effettuando anche una validazione
    const paramOnChange = (pax, id, val, forceValidate) => {
        let inputTarget = document.getElementById(id);
        inputTarget.classList.remove("is-valid");
        inputTarget.classList.remove("is-invalid");

        let aSplit = id.split('_');
        let paxIndex = aSplit[1];
        let bpId = aSplit[2];

        // Validation - START
        let isMandatory = checkMandatory(paxIndex, bpId);
        let res = validateBookParam(bpId, val, forceValidate);
        if (val !== "" || isMandatory) { // se ho scritto qualcosa oppure è obbligatorio
            if (!res) {
                if (!inputTarget.className.includes("is-invalid"))
                    inputTarget.className += " is-invalid";

                return; // se non è valido non lo aggiungo neanche
            } else {
                if (!inputTarget.className.includes("is-valid"))
                    inputTarget.className += " is-valid";
            }
        }
        // Validation - END

        handlePaxesBookParams({ PaxIndex: paxIndex, PaxType: pax.paxType, BpId: bpId, BpValue: val });
    }

    const getDefaultPaxValue = (pax, bpId) => {
        // case standard => pax arrives from booking terms with book parameters valued
        if (pax.bookParameters && pax.bookParameters.length > 0) {
            let paxBookParameter = pax.bookParameters.find(x => x.id === bpId);
            if (paxBookParameter)
                return paxBookParameter.value;
        }

        // case add to order / quotation
        let paxExists = precompiledOrderPaxesBookParams && precompiledOrderPaxesBookParams.findIndex(x => x.PaxIndex === pax.index) > -1;
        if (!paxExists)
            return null;

        let bpPax = precompiledOrderPaxesBookParams.find(x => x.PaxIndex === pax.index);
        let bp = bpPax.BookParameters.find(x => x.Id === bpId);
        if (bp) {
            return bp.Value;
        }

        return null;
    }

    const existRefPax = () => {
        var result = inputData.cartPaxes.filter(x => x.isRefPax);

        return result.length > 0;
    }

    const renderPaxFields = (pax) => {
        let isRefPax = pax.isRefPax || (!existRefPax() && pax.index === 1);

        let defaultFirstName = getDefaultPaxValue(pax, BookParameterId.Personal.FirstName);
        let defaultMiddleName = getDefaultPaxValue(pax, BookParameterId.Personal.MiddleName);
        let defaultLastName = getDefaultPaxValue(pax, BookParameterId.Personal.LastName);
        let defaultBirthDate = getDefaultPaxValue(pax, BookParameterId.Personal.BirthDate);
        let defaultGender = getDefaultPaxValue(pax, BookParameterId.Personal.Gender);
        let defaultFiscalCode = getDefaultPaxValue(pax, BookParameterId.Personal.FiscalCode);
        let defaultPhone = getDefaultPaxValue(pax, BookParameterId.Contact.HomePhone);
        let defaultEmail = getDefaultPaxValue(pax, BookParameterId.Contact.Email);
        let defaultAddress = getDefaultPaxValue(pax, BookParameterId.Address.Address1);
        let defaultZipCode = getDefaultPaxValue(pax, BookParameterId.Address.Zip);
        let defaultCity = getDefaultPaxValue(pax, BookParameterId.Address.City);
        let defaultDocumentNumber = getDefaultPaxValue(pax, BookParameterId.Document.Number);
        let defaultBirthCity = getDefaultPaxValue(pax, "BirthCity");

        return (
            <>
                {/*Riga 1*/}
                <div className="row mt-2">
                    <div className="form-floating col-md-3">
                        <input id={`BpPax_${pax.index}_${BookParameterId.Personal.FirstName}`} type="text" className="form-control" placeholder="Nome" disabled={defaultFirstName ? "disabled" : ""} defaultValue={defaultFirstName} required={checkMandatory(pax.index, BookParameterId.Personal.FirstName)} onChange={e => paramOnChange(pax, e.target.id, e.target.value)} />
                        <label htmlFor={`BpPax_${pax.index}_${BookParameterId.Personal.FirstName}`} className="active">
                            <span className={getLabelSpanClass(pax.index, BookParameterId.Personal.FirstName)}>{getLocalizedName(BookParameterId.Personal.FirstName)}&nbsp; {checkMandatory(pax.index, BookParameterId.Personal.FirstName) ? "(*)" : ""}</span>
                        </label>
                    </div>
                    <div className="form-floating col-md-3">
                        <input id={`BpPax_${pax.index}_${BookParameterId.Personal.MiddleName}`} type="text" className="form-control" placeholder="Secondo nome" disabled={defaultMiddleName ? "disabled" : ""} defaultValue={defaultMiddleName} required={checkMandatory(pax.index, BookParameterId.Personal.MiddleName)} onChange={e => paramOnChange(pax, e.target.id, e.target.value)} />
                        <label htmlFor={`BpPax_${pax.index}_${BookParameterId.Personal.MiddleName}`} className="active">
                            <span className={getLabelSpanClass(pax.index, BookParameterId.Personal.MiddleName)}>{getLocalizedName(BookParameterId.Personal.MiddleName)}&nbsp; {checkMandatory(pax.index, BookParameterId.Personal.MiddleName) ? "(*)" : ""}</span>
                        </label>
                    </div>
                    <div className="form-floating col-md-3">
                        <input id={`BpPax_${pax.index}_${BookParameterId.Personal.LastName}`} type="text" className="form-control" placeholder="Cognome" disabled={defaultLastName ? "disabled" : ""} defaultValue={defaultLastName} required={checkMandatory(pax.index, BookParameterId.Personal.LastName)} onChange={e => paramOnChange(pax, e.target.id, e.target.value)} />
                        <label htmlFor={`BpPax_${pax.index}_${BookParameterId.Personal.LastName}`} className="active">
                            <span className={getLabelSpanClass(pax.index, BookParameterId.Personal.LastName)}>{getLocalizedName(BookParameterId.Personal.LastName)}&nbsp; {checkMandatory(pax.index, BookParameterId.Personal.LastName) ? "(*)" : ""}</span>
                        </label>
                    </div>
                    <div className="form-floating col-md-1">
                        <div className="mt-3">
                            <div>
                                <input id={`BpPax_${pax.index}_${BookParameterId.Personal.Gender}_M`} type="radio" name={`BpPax_${pax.index}_${BookParameterId.Personal.Gender}`} value="M" onChange={e => paramOnChange(pax, e.target.id, e.target.value)} defaultChecked={defaultGender ? (defaultGender === "M") : true} />
                                &nbsp;<label htmlFor={`BpPax_${pax.index}_${BookParameterId.Personal.Gender}_M`}>M</label>

                                <input id={`BpPax_${pax.index}_${BookParameterId.Personal.Gender}_F`} type="radio" name={`BpPax_${pax.index}_${BookParameterId.Personal.Gender}`} value="F" onChange={e => paramOnChange(pax, e.target.id, e.target.value)} defaultChecked={defaultGender ? (defaultGender === "F") : false} />
                                &nbsp;<label htmlFor={`BpPax_${pax.index}_${BookParameterId.Personal.Gender}_F`}>F</label>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Riga 2*/}
                <div className="row pt-3">
                    <div className="form-floating col-md-3">
                        <input id={`BpPax_${pax.index}_${BookParameterId.Personal.BirthDate}`} type="date" className="form-control" placeholder="Data di nascita" disabled={defaultBirthDate ? "disabled" : ""} defaultValue={defaultBirthDate} required={checkMandatory(pax.index, BookParameterId.Personal.BirthDate)} onChange={e => paramOnChange(pax, e.target.id, e.target.value)} />
                        <label htmlFor={`BpPax_${pax.index}_${BookParameterId.Personal.BirthDate}`} className="active">
                            <span className={getLabelSpanClass(pax.index, BookParameterId.Personal.BirthDate)}>{getLocalizedName(BookParameterId.Personal.BirthDate)}&nbsp; {checkMandatory(pax.index, BookParameterId.Personal.BirthDate) ? "(*)" : ""}</span>
                        </label>
                    </div>
                    <div className="form-floating col-md-2">
                        <div className="form-floating">
                            <select id={`BpPax_${pax.index}_${BookParameterId.Personal.Citizenship}`} className="form-select border-0 border-bottom border-dark" aria-label="Fornitore" required={checkMandatory(pax.index, BookParameterId.Personal.Citizenship)} onChange={e => paramOnChange(pax, e.target.id, e.target.value)}>
                                <option value="-1">Seleziona</option>
                                {countries.map(x =>
                                    <option key={x.iso2} value={x.iso2} selected={x.iso2 === inputData.defaultValues.country}>{x.name}</option>
                                )}
                            </select>
                            <label htmlFor={`BpPax_${pax.index}_${BookParameterId.Personal.Citizenship}`}>
                                <span className={getLabelSpanClass(pax.index, BookParameterId.Personal.Citizenship)}>{getLocalizedName(BookParameterId.Personal.Citizenship)}&nbsp; {checkMandatory(pax.index, BookParameterId.Personal.Citizenship) ? "(*)" : ""}</span>
                            </label>
                        </div>
                    </div>
                    <div className="form-floating col-2">
                        <div className="form-check mt-2">
                            <input id={`BpPax_${pax.index}_${BookParameterId.Document.Type}_C`} className="form-check-input" type="radio" name={`BpPax_${pax.index}_${BookParameterId.Document.Type}`} value="C" onChange={e => paramOnChange(pax, e.target.id, e.target.value)} />
                            <label htmlFor={`BpPax_${pax.index}_${BookParameterId.Document.Type}_C`} className="form-check-label">
                                <span className={getLabelSpanClass(pax.index, BookParameterId.Document.Type)}>{getLocalizedName(`${BookParameterId.Document.Type}_C`)} {checkMandatory(pax.index, BookParameterId.Document.Type) ? "(*)" : ""}</span>
                            </label>
                        </div>
                        <div className="form-check">
                            <input id={`BpPax_${pax.index}_${BookParameterId.Document.Type}_P`} className="form-check-input" type="radio" name={`BpPax_${pax.index}_${BookParameterId.Document.Type}`} value="P" onChange={e => paramOnChange(pax, e.target.id, e.target.value)} />
                            <label htmlFor={`BpPax_${pax.index}_${BookParameterId.Document.Type}_P`} className="form-check-label">
                                <span className={getLabelSpanClass(pax.index, BookParameterId.Document.Type)}>{getLocalizedName(`${BookParameterId.Document.Type}_P`)} {checkMandatory(pax.index, BookParameterId.Document.Type) ? "(*)" : ""}</span>
                            </label>
                        </div>
                    </div>
                    <div className="form-floating col-md-2">
                        <input id={`BpPax_${pax.index}_${BookParameterId.Document.Number}`} type="text" className="form-control" placeholder="N. Documento" disabled={defaultDocumentNumber ? "disabled" : ""} defaultValue={defaultDocumentNumber} required={checkMandatory(pax.index, BookParameterId.Document.Number)} onChange={e => paramOnChange(pax, e.target.id, e.target.value)} />
                        <label htmlFor={`BpPax_${pax.index}_${BookParameterId.Document.Number}`} className="active">
                            <span className={getLabelSpanClass(pax.index, BookParameterId.Document.Number)}>{getLocalizedName(BookParameterId.Document.Number)} {checkMandatory(pax.index, BookParameterId.Document.Number) ? "(*)" : ""}</span>
                        </label>
                    </div>
                </div>

                {/*Righe 3, 4 e 5 - Only RefPax*/}
                {!isRefPax
                    ? (<></>)
                    : (
                        <>
                            <div className="row">
                                <div className="form-floating col-md-3">
                                    <input id={`BpPax_${pax.index}_${BookParameterId.Personal.FiscalCode}`} type="text" className="form-control" placeholder="Codice Fiscale" disabled={paxStraniero || defaultBirthCity} defaultValue={defaultFiscalCode} required={checkMandatory(pax.index, BookParameterId.Personal.FiscalCode)} onChange={e => callCheckFiscalCode(pax, e.target.id, e.target.value)} />
                                    <label htmlFor={`BpPax_${pax.index}_${BookParameterId.Personal.FiscalCode}`} className="active">
                                        <span className={getLabelSpanClass(pax.index, BookParameterId.Personal.FiscalCode)}>{getLocalizedName(BookParameterId.Personal.FiscalCode)}&nbsp; {checkMandatory(pax.index, BookParameterId.Personal.FiscalCode) ? "(*)" : ""}</span>
                                    </label>
                                </div>
                                <div className="form-floating col-md-3">
                                    <input id={`BpPax_${pax.index}_BirthCity`} type="text" className="form-control" placeholder="Città natale" disabled={paxStraniero || defaultBirthCity} defaultValue={defaultBirthCity} required={checkMandatory(pax.index, "BirthCity")} onChange={e => paramOnChange(pax, e.target.id, e.target.value)} />
                                    <label htmlFor={`BpPax_${pax.index}_BirthCity`} className="active">
                                        <span className={getLabelSpanClass(pax.index, "BirthCity")}>{getLocalizedName("BirthCity")}&nbsp;  {checkMandatory(pax.index, "BirthCity") ? "(*)" : ""}</span>
                                    </label>
                                </div>
                                <div className="col-md-3 pt-3">
                                    <input id={`BpPax_${pax.index}_PaxEE`} className="form-check-input" type="checkbox" value="PaxEE" onChange={e => paxStranieroOnChange(pax, e.target.id, e.target.checked)} />
                                    <label htmlFor={`BpPax_${pax.index}_PaxEE`} className="active">&nbsp;&nbsp;{getLocalizedName("ForeignPassenger")}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-floating col-md-3">
                                    <input id={`BpPax_${pax.index}_${BookParameterId.Address.Address1}`} type="text" className="form-control" placeholder="Indirizzo" disabled={defaultAddress ? "disabled" : ""} defaultValue={defaultAddress} required={checkMandatory(pax.index, BookParameterId.Address.Address1)} onChange={e => paramOnChange(pax, e.target.id, e.target.value)} />
                                    <label htmlFor={`BpPax_${pax.index}_${BookParameterId.Address.Address1}`} className="active">
                                        <span className={getLabelSpanClass(pax.index, BookParameterId.Address.Address1)}>{getLocalizedName(BookParameterId.Address.Address1)}&nbsp; {checkMandatory(pax.index, BookParameterId.Address.Address1) ? "(*)" : ""}</span>
                                    </label>
                                </div>
                                <div className="form-floating col-md-3">
                                    <input id={`BpPax_${pax.index}_${BookParameterId.Address.City}`} type="text" className="form-control" placeholder="Città" disabled={defaultCity ? "disabled" : ""} defaultValue={defaultCity} required={checkMandatory(pax.index, BookParameterId.Address.City)} onChange={e => paramOnChange(pax, e.target.id, e.target.value)} />
                                    <label htmlFor={`BpPax_${pax.index}_${BookParameterId.Address.City}`} className="active">
                                        <span className={getLabelSpanClass(pax.index, BookParameterId.Address.City)}>{getLocalizedName(BookParameterId.Address.City)}&nbsp; {checkMandatory(pax.index, BookParameterId.Address.City) ? "(*)" : ""}</span>
                                    </label>
                                </div>
                                <div className="form-floating col-md-2">
                                    <input id={`BpPax_${pax.index}_${BookParameterId.Address.Zip}`} type="number" className="form-control" placeholder="CAP" disabled={defaultZipCode ? "disabled" : ""} defaultValue={defaultZipCode} required={checkMandatory(pax.index, BookParameterId.Address.Zip)} onChange={e => paramOnChange(pax, e.target.id, e.target.value)} />
                                    <label htmlFor={`BpPax_${pax.index}_${BookParameterId.Address.Zip}`} className="active">
                                        <span className={getLabelSpanClass(pax.index, BookParameterId.Address.Zip)}>{getLocalizedName(BookParameterId.Address.Zip)}&nbsp; {checkMandatory(pax.index, BookParameterId.Address.Zip) ? "(*)" : ""}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-floating col-md-3">
                                    <input id={`BpPax_${pax.index}_${BookParameterId.Contact.Email}`} type="email" className="form-control" placeholder="Email" disabled={defaultEmail ? "disabled" : ""} defaultValue={defaultEmail} required={checkMandatory(pax.index, BookParameterId.Contact.Email)} onChange={e => paramOnChange(pax, e.target.id, e.target.value)} />
                                    <label htmlFor={`BpPax_${pax.index}_${BookParameterId.Contact.Email}`} className="active">
                                        <span className={getLabelSpanClass(pax.index, BookParameterId.Contact.Email)}>{getLocalizedName(BookParameterId.Contact.Email)} {checkMandatory(pax.index, BookParameterId.Contact.Email) ? "(*)" : ""}</span>
                                    </label>
                                </div>
                                <div className="form-floating col-md-3">
                                    <div className="form-floating">
                                        <select id={`BpPax_${pax.index}_${BookParameterId.Contact.HomePhone}-Prefix`} className="form-select border-0 border-bottom border-dark" aria-label="Fornitore" onChange={e => paramOnChange(pax, e.target.id, e.target.value)}>
                                            <option value="-1">Seleziona</option>
                                            {dialCodes.map((x, index) =>
                                                <option key={index} value={x.codeDial} selected={x.codeDial === inputData.defaultValues.dialCode}>{x.isoTwoLetter} - {x.codeDial}</option>
                                            )}
                                        </select>
                                        <label htmlFor={`BpPax_${pax.index}_${BookParameterId.Contact.HomePhone}-Prefix`}>
                                            <span className={getLabelSpanClass(pax.index, BookParameterId.Contact.HomePhone)}>{getLocalizedName(`${BookParameterId.Contact.HomePhone}_PREFIX`)}&nbsp; {checkMandatory(pax.index, BookParameterId.Contact.HomePhone) ? "(*)" : ""}</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-floating col-md-3">
                                    <input id={`BpPax_${pax.index}_${BookParameterId.Contact.HomePhone}`} type="number" className="form-control" placeholder="Telefono" disabled={defaultPhone ? "disabled" : ""} defaultValue={defaultPhone} required={checkMandatory(pax.index, BookParameterId.Contact.HomePhone)} onChange={e => paramOnChange(pax, e.target.id, e.target.value)} />
                                    <label htmlFor={`BpPax_${pax.index}_${BookParameterId.Contact.HomePhone}`} className="active">
                                        <span className={getLabelSpanClass(pax.index, BookParameterId.Contact.HomePhone)}>{getLocalizedName(BookParameterId.Contact.HomePhone)}&nbsp; {checkMandatory(pax.index, BookParameterId.Contact.HomePhone) ? "(*)" : ""}</span>
                                    </label>
                                </div>
                            </div>
                        </>
                    )
                }
            </>
        );
    }


    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : inputData !== undefined && inputData.cartPaxes !== undefined
                    ? (
                        <>
                            {inputData.cartPaxes.map(pax =>
                                <React.Fragment key={pax.index}>
                                    <div className="mt-4">
                                        <b>{t("MyBook:Passenger")}&nbsp; {pax.index}:</b> {getPaxType(pax.paxType)} {(pax.isRefPax || (!existRefPax() && pax.index === 1)) && ", " + t("MyBook:PrincipalContact")} {getPaxAge(pax)}
                                    </div>
                                    {renderPaxFields(pax)}
                                </React.Fragment>
                            )}
                        </>
                    )
                    : <></>
            }
        </>
    )
}