import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Loading } from '../../Common/Loading';
import configData from "../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { TrainItemList } from '../../Product/Train/components/TrainItemList';
import { TrainSelectedItinerary } from '../../Product/Train/components/TrainSelectedItinerary';
import { TrainItinerySeatMap } from '../../Product/Train/components/TrainItinerySeatMap';
import { formatPrice, formatDateTime, scrollTo } from '../../../js/Utils';
import { TrainChangeSearchItinerary } from './TrainChangeSearchItinerary';

export const TrainChangeItinerary = ({ pnr, trainDetail, openChangeIteneraryPanel }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null)
    const [errorMsgSearch, setErrorMsgSearch] = useState(null)
    const [trains, setTrains] = useState([])
    const [selectedLeg, setSelectedLeg] = useState(null)
    const [evalutatedData, setEvalutatedData] = useState(null)
    const [seatSelections, setSeatSelections] = useState([])
    const [showAllTrains, setShowAllTrains] = useState(false)
    const [operationPostResponse, setOperationPostRepsponse] = useState(null)
    const [stationNames, setStationNames] = useState(null)
    const [selectedCode, setSelectedCode] = useState(null)
    const [evalutateError, setEvalutateError] = useState(false)
    const [solutionType, setSolutionType] = useState(1)
    const [changePaxError, setChangePaxError] = useState(null)
    const [changePaxSuccess, setChangePaxSuccess] = useState(null)
    const [showPanelSearch, setShowPanelSearch] = useState(null)

    const searchChangeItinerary = async (operations, code, solutionTypeParam, selectDestinations, selectDates, selectTimes) => {
        setSelectedCode(code);
        setSolutionType(solutionTypeParam);

        setShowPanelSearch(null);
        setSeatSelections(null);
        setEvalutatedData(null);
        setSelectedLeg(null);
        setEvalutateError(null);
        setErrorMsgSearch(null);

        setIsLoading(true);

        try {
            let timeFrom = selectTimes.timeFrom;
            if (timeFrom.length < 5)
                timeFrom = "0" + timeFrom;

            const searchChangeItineraryRequest = {
                PNR: pnr,
                Code: code,
                SolutionType: solutionTypeParam, // example value for Outbound or Return
                Departure: selectDestinations[0].dep.Id,
                Arrival: selectDestinations[0].arr.Id,
                Date: selectDates.dateFrom,
                Time: timeFrom,
                Itinerary: {},
                PostBookOperationResponse: {
                    ChangePossibilitiesOubound: operations.changeItineraryOutbound,
                    ChangePossibilitiesReturn: operations.changeItineraryReturn
                },
                Culture: 'en-US',
                AsyncState: operationPostResponse.asyncState,
                WsBookingId: pnr
            };
            const response = await fetch(`${configData.Settings.CommonApi_BaseUrl}Train/SearchChangeItinerary`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(searchChangeItineraryRequest)
            });
            const data = await response.json();
            if (response.ok && data.success) {
                setTrains(data.trains);
                setIsLoading(false);

                if (!data.trains || data.trains.length === 0)
                    setErrorMsgSearch(t('MyBook:operationPostError'))

                return data;
                // Update the state or handle the search results
            } else {
                setErrorMsgSearch(t('MyBook:operationPostError'))
                throw new Error(data.message || "Failed to fetch search results");
            }
        } catch (error) {
            setErrorMsgSearch(t('MyBook:operationPostError'))
            console.error("Error during search:", error);
            // Handle errors, e.g., show error message
        }
        setIsLoading(false);

    };

    async function getPostBookOperations() {
        setIsLoading(true);
        setErrorMsg(null)
        console.log(trainDetail)

        const requestBody = {
            PNR: pnr,
            OperationType: "ChangeItinerary",
        };

        try {
            const response = await fetch(`${configData.Settings.CommonApi_BaseUrl}Train/GetPostBookOperations`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setIsLoading(false);

            const data = await response.json();
            if (!data.success) {
                return setErrorMsg(`${t('MyBook:operationPostError')}`);
            }

            //await searchChangeItinerary({
            //     ChangePossibilitiesOubound: data.changeItineraryOutbound,
            //     ChangePossibilitiesReturn: data.changeItineraryReturn
            // }, )

            setOperationPostRepsponse(data)
            setStationNames(data.stationNames);

        } catch (error) {
            console.error('Error:', error);
            // Handle errors, e.g., show error message
        }
    }

    const confirmChangeItinerary = async () => {
        if (!selectedLeg || !pnr) {
            console.error("Required data for confirmation is missing.");
            return;
        }
        // Assuming selectedLeg contains the selected train info including InternalId
        const requestBody = {
            WsBookingId: pnr, // You already have `pnr` from props
            PNR: pnr,
            SelectFares: selectedLeg.selectedFares.map(x => x.fareComponents.uniqueId),
            code: selectedCode,
            SolutionType: solutionType,
            InternalId: selectedLeg.uniqueId,
            SeatSelectionRq: seatSelections
        };

        try {
            const response = await fetch(`${configData.Settings.CommonApi_BaseUrl}Train/ConfirmChangeItinerary`, {
                method: 'POST',
                credentials: 'include', // Assuming cookies are needed
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            if (data.success) {
                setChangePaxError(false);
                setChangePaxSuccess(true);
                console.log("Change confirmed successfully", data);
                // Handle successful confirmation (e.g., updating state, showing a success message)
            } else {
                setChangePaxError(true);
                setChangePaxSuccess(false);
                console.error("Failed to confirm changes", data);
                // Handle failure (e.g., showing an error message)
            }
        } catch (error) {
            setChangePaxError(true);
            setChangePaxSuccess(false);
            console.error("Error confirming changes", error);
            // Handle network errors or other exceptions
        }
    };


    async function onClickSearch(legUniqueId, selectFares) {
        let selectedLeg = trains.filter(l => l.leg.uniqueId === legUniqueId)[0];
        selectedLeg.selectedFares = selectFares;
        setSelectedLeg(selectedLeg)

        scrollTo(null, "trainChangeItineray_recap");

        evaluateChangeItinerary(selectedLeg.uniqueId, selectedLeg.selectedFares.map(x => x.fareComponents.uniqueId), solutionType);
    }

    const evaluateChangeItinerary = (internalId, selectedFares, solutionType) => {
        setEvalutateError(false)
        // Assuming you have wsBookingId and other required info available
        const requestBody = {
            WsBookingId: pnr, // You already have `pnr` from props
            PNR: pnr,
            SelectFares: selectedFares,
            code: selectedCode,
            SolutionType: solutionType,
            InternalId: internalId, // This should be passed to this function when it's called
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}Train/EvaluateChangeItinerary`, {
            method: 'POST',
            credentials: 'include', // Assuming you need cookies for session management
            headers: {
                'Content-Type': 'application/json',
                // Add any other headers like authorization tokens if needed
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                return response.json();
            })
            .then(data => {
                console.log('Evaluation success:', data);

                if (!data.success) {
                    setEvalutateError(true)
                }

                setEvalutatedData(data);
                // Handle successful evaluation here (update state, UI, etc.)
            })
            .catch(error => {
                console.error('Evaluation failed:', error);
                setEvalutateError(true)
                // Handle error condition here (show error message, etc.)
            });
    };


    function onSelectSeatOutbound(paxes) {
        setSeatSelections(paxes)
    }

    function getStationName(stationId) {
        try {
            if (stationNames) {
                return stationNames[stationId];
            }

        } catch (ex) {

        }

        return "N.D."
    }

    function changeShowPanelSearch(type) {
        if (showPanelSearch === type)
            setShowPanelSearch(null);
        else {
            setShowPanelSearch(type);
        }

    }

    useEffect(() => {

        setChangePaxError(false);
        setChangePaxSuccess(false);

        getPostBookOperations();

    }, []);

    return (
        <div className="" id="change_date" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="change_itinerary">{t('MyBook:ChangeItinerary')}</h5>
                        <button onClick={() => openChangeIteneraryPanel(false)} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body ml-4">
                        {
                            (changePaxError || changePaxSuccess) && <div>
                                {!changePaxError && changePaxSuccess && <p className="text-success">{t('MyBook:changeSuccessful')}</p>}
                                {changePaxError && <p className="text-danger">{t('MyBook:changeUnsuccessful')}</p>}
                            </div>
                        }
                        {
                            !(changePaxError || changePaxSuccess) && <>
                                {isLoading ? (
                                    <Loading textMsg={t('MyBook:Loading')} />
                                ) : errorMsg ? (
                                    <>
                                        <span className="text-danger">{errorMsg}</span>
                                    </>
                                ) : (
                                    <>
                                        <div className="d-flex flex-column gap-3">

                                            {operationPostResponse?.changeItineraryOutbound.length > 0 && <label className="fw-bold">{t('Product:Train:Going')}</label>}
                                            {operationPostResponse && operationPostResponse?.changeItineraryOutbound.map(item => {
                                                return <div>
                                                    <div className="row align-items-center">
                                                        {item.segments.map((segment) => {
                                                            return <>
                                                                <p className="text-center col-5">{`${getStationName(segment.departure.stationId)} > ${getStationName(segment.arrival.stationId)}`}</p>
                                                                <p className="text-center col-5 ">{`${formatDateTime(segment.departure.date)} ${segment.departure.time} > ${formatDateTime(segment.arrival.date)} ${segment.arrival.time}`}</p>
                                                            </>
                                                        })}
                                                        <div className="col-2">
                                                            <button onClick={() => changeShowPanelSearch("1")} className="btn btn-small tp-btn-search">{t('Button:Change')}</button>
                                                        </div>

                                                        {
                                                            showPanelSearch && showPanelSearch === "1" && <>
                                                                <TrainChangeSearchItinerary
                                                                    item={item}
                                                                    getStationName={getStationName}
                                                                    operationPostResponse={operationPostResponse}
                                                                    code={item.code}
                                                                    trainType="1"
                                                                    searchChangeItinerary={searchChangeItinerary} />
                                                            </>
                                                        }

                                                    </div>
                                                </div>
                                            })}
                                            {operationPostResponse?.changeItineraryReturn.length > 0 && <label className="fw-bold">{t('Product:Train:Return')}</label>}
                                            {operationPostResponse && operationPostResponse?.changeItineraryReturn.map(item => {
                                                return <div>
                                                    <div className="row align-items-center">
                                                        {item.segments.map((segment) => {
                                                            return <>
                                                                <p className="text-center col-3">{`${getStationName(segment.departure.stationId)} > ${getStationName(segment.arrival.stationId)}`}</p>
                                                                <p className="text-center col-6 ">{`${formatDateTime(segment.departure.date)} ${segment.departure.time} > ${formatDateTime(segment.arrival.date)} ${segment.arrival.time}`}</p>
                                                            </>
                                                        })}

                                                        <div className="col-3">
                                                            <button onClick={() => changeShowPanelSearch("2")} className="btn btn-small tp-btn-search">{t('Button:select')}</button>
                                                        </div>

                                                        {
                                                            showPanelSearch && showPanelSearch === "2" && <>
                                                                <TrainChangeSearchItinerary
                                                                    item={item}
                                                                    getStationName={getStationName}
                                                                    operationPostResponse={operationPostResponse}
                                                                    code={item.code}
                                                                    trainType="2"
                                                                    searchChangeItinerary={searchChangeItinerary} />
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <div id="trainChangeItineray_recap">
                                            {
                                                selectedLeg ? <>
                                                    <div class="h5">{t('Product:Train:SelectItinerary')}</div>

                                                    {

                                                        selectedLeg && <TrainSelectedItinerary
                                                            itinerary={selectedLeg}
                                                            type="SELECTSEAT"
                                                            showPriceBar={true}
                                                            onClickUpdate={() => { }} />

                                                    }

                                                    {
                                                        evalutatedData?.seatMaps && <div className="px-0 my-4">
                                                            <TrainItinerySeatMap
                                                                id="outbound"
                                                                haveErrorSeat={false}
                                                                seatMap={evalutatedData?.seatMaps}
                                                                onSelectSeat={onSelectSeatOutbound}
                                                            />
                                                        </div>
                                                    }

                                                    {
                                                        selectedLeg && <div className="d-flex justify-content-end mt-2">
                                                            {
                                                                evalutatedData
                                                                    ? <h6 className="h5 text-danger pt-3 mr-3">Prezzo cambiato: {formatPrice(evalutatedData.amount, evalutatedData.currency, cultureName)}</h6>
                                                                    : <></>
                                                            }

                                                            {evalutateError ? <div className="h5 text-danger mt-2">{t('MyBook:operationPostError')}</div> : <></>}

                                                            {!evalutateError && <button className="btn btn-small tp-btn-search p-1 mt-2" onClick={confirmChangeItinerary}>{t('Button:Confirm')}</button>}

                                                        </div>
                                                    }
                                                </> : <></>

                                            }pre
                                        </div>

                                        <div className="mx-2">
                                            {trains && trains.length > 0 ? <><div class="h5 fw-bold mb-2">{t('MyBook:Alternative')}:</div></> : <></>}
                                            {
                                                errorMsgSearch && <div className="text-danger">{t("Product:Train:NotFound")}</div>
                                            }
                                            {
                                                !showAllTrains ? trains.slice(0, 3).map((item, key) => {
                                                    return <TrainItemList key={key}
                                                        onClickSearch={onClickSearch}

                                                        item={item}
                                                        showPriceBar={true}
                                                        showCost={true}
                                                    />
                                                }) : <>
                                                    {trains.map((item, key) => {
                                                        return <TrainItemList key={key}
                                                            onClickSearch={onClickSearch}

                                                            item={item}
                                                            showPriceBar={true}
                                                            showCost={true}
                                                        />
                                                    })}
                                                </>
                                            }
                                            {trains.length > 0 && !showAllTrains && < div className="d-flex justify-content-center">
                                                <button onClick={() => setShowAllTrains(true)} className="btn border">{t('General:More')}</button>
                                            </div>}
                                        </div>
                                    </>
                                )}
                            </>
                        }
                    </div>
                </div>
            </div>
        </div >

    );
};
