import React, { useState, useEffect, useCallback } from 'react';
import { M3Icon } from '../../Common/M3Icon';
import { useTranslation } from 'react-i18next'

export const WebVerticalMenu = ({ item, isActive, handleModal }) => {
    const { t } = useTranslation();


    const handleInputClick = useCallback(event => {
        handleModal("");
    }, [handleModal]);

    return (
        <nav id="menu-vertical" className={"nav flex-column flex-nowrap vertical-menu open-left shadow-sm bg-white p-3 " + isActive}>
            <div class="d-flex justify-content-between align-items-center">
                <img src="" width="175" height="75" alt="" />
                <div class="cursor-pointer" onClick={e => handleInputClick(e)}>
                    <M3Icon iconName="OneWay" />
                </div>
            </div>
            <div class="d-flex flex-column h-100">

                <a id="ctl12_lnkHome" class="link main-link nav-link rounded active" href="/">
                    <div class="d-flex align-items-center">
                        <M3Icon iconName="Home" />
                        <span>Home </span>
                    </div>
                </a>

                <a id="ctl12_lnkCartMenu" class="link main-link nav-link rounded" href="/cart">
                    <div class="d-flex align-items-center">
                        <M3Icon iconName="Cart" />
                        <span>{t("MyBook:Cart")}</span>
                    </div>
                </a>

                <ul>

                </ul>

                <div class="container mt-3 d-none">
                    <div class="row">
                        <div class="col-12">
                            <ul class="menuhead">
                                <li>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );

}
