import React from 'react';
import Cookies from 'universal-cookie';
import { formatPrice } from '../../../../js/Utils';

export const CruiseDetailCabinCategoryServices = ({ services, title, onSelect, readonly }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    function onSelectCheckBox(service, isChecked) {
        onSelect(service.code, isChecked);
    }

    return (
        <>
            {
                title && services && services.length > 0 && <div className="col-12 col-lg-12 pt-2 px-0">
                    <var className="h6">{title}</var>
                </div>
            }
            {
                services && services.map((service, index) => {
                    { JSON.stringify(service) }
                    return <div className="row" key={index}>
                        <div className="col-2 col-lg-2 pt-2">
                            <var>{service.itemCode}</var>
                        </div>
                        <div className="col-6 col-lg-6 pt-2">
                            <var>{service.name}</var>
                        </div>
                        {
                            !readonly && <div className="col-4 col-lg-4 pt-2  text-right">
                                <var className="fw-700">
                                    {formatPrice(service.amount, service.currency, cultureName)}
                                </var>
                                <input type="checkbox"
                                    className="ml-2 position-relative"
                                    style={{ top: '1px' }}
                                    defaultValue={service.checked}
                                    checked={service.checked}
                                    onChange={(e) => onSelectCheckBox(service, e.currentTarget.checked)} />
                            </div>
                        }
                    </div>
                })
            }
        </>
    );
}