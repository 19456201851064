import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../Common/Loading';
import configData from "../../appsettings.json";
import { getWebsiteId } from '../../js/Utils.js';

export const ResetPassword = () => {
    const { t } = useTranslation();
    const [username, setUsername] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadMessagge, setIsLoadMessage] = useState(false);
    const [message, setMessage] = useState(false);
    const [isDisabledForm, setIsDisabledForm] = useState(true);
    const queryParameters = new URLSearchParams(window.location.search);
    const userId = queryParameters.get("userId");
    const code = queryParameters.get("code");

    const [inputData, setInputData] = useState({
        password: '',
        confirmPassword: '',
        websiteId: getWebsiteId(),
        userId: userId,
        code: code
    });

    const [validateInputData, setValidateInputData] = useState({
        isValidPassword: false,
        isTouchedPassword: false,

        isValidConfirmPassword: false,
        isTouchedConfirmPassword: false,

        isValidForm: false
    });
    function onClickSend() {
        if (userId && code)
            callResetPassword();
    }

    useEffect(() => {
        if (validateInputData.isValidForm) {
            setIsDisabledForm(false);
        }
        else {
            setIsDisabledForm(true);
        }
    }, [validateInputData.isValidForm]);

    function onBlurEvent(event, propertyName) {
        let updateInputData = { ...inputData };

        let newValue = propertyName === 'privacyPolicy' ? event.target.checked
            : event.target.value;

        updateInputData[propertyName] = newValue;

        let isValidForm = false;
        let prevValidateInputData = { ...validateInputData };
        delete prevValidateInputData.isValidForm;
        const isNotEmpty = (newValue !== '' && newValue !== undefined);
        
        switch (propertyName) {
            case 'password': {
                delete prevValidateInputData.isValidPassword;
                delete prevValidateInputData.isTouchedPassword;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                let isValidPassword = isNotEmpty;
                let isValidConfirmPassword = isNotEmpty;

                if (isNotEmpty) {
                    let isStrongPassword = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(newValue);
                    isValidPassword = isValidPassword && isStrongPassword;

                    if (isValidPassword)
                        isValidConfirmPassword = newValue === updateInputData.password;
                }

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidPassword: isValidPassword,
                    isTouchedPassword: true,
                    isValidConfirmPassword: isValidConfirmPassword
                });
                break;
            }

            case 'confirmPassword': {
                delete prevValidateInputData.isValidConfirmPassword;
                delete prevValidateInputData.isTouchedConfirmPassword;

                const isValidConfirmPassword = isNotEmpty && newValue === updateInputData.password;
                isValidForm = isValidConfirmPassword && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidConfirmPassword: isValidConfirmPassword,
                    isTouchedConfirmPassword: true
                });
                break;
            }
        }

        setInputData(updateInputData);
    }

    async function callResetPassword() {
        setIsLoading(true);

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'user/ResetPassword', requestOption);
        const response = await fetchedRes.json();

        setIsLoadMessage(true);
        if (response.success) {
            setMessage(t("Login:ResetPasswordSuccess"));
            setIsLoading(false);
        }
        else {
            setMessage("Errore");
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className="col-3 d-none d-flex"></div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-left">
                        <h3>{t("Login:ResetPassword")}</h3>
                    </div>
                    <div className="col-12 text-center">
                        <div className="row">
                            <div className="col-3 d-none d-flex"></div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                {!isLoadMessagge &&
                                    <div className="row">
                                        <div className={"col-md-12 mb-3 text-left" + (!validateInputData.isValidPassword ? " insert-danger" : "")}>
                                            Password *
                                            <input type="password"
                                                className="form-control"
                                                aria-label="Password"
                                                onChange={e => onBlurEvent(e, 'password')}
                                            />
                                        </div>
                                        <div className={"col-md-12 mb-3 text-left" + (!validateInputData.isValidConfirmPassword ? " insert-danger" : "")}>
                                            {t("Login:ConfirmPassword")} *
                                            <input type="password"
                                                className="form-control"
                                                aria-label="Conferma Password"
                                                onChange={e => onBlurEvent(e, 'confirmPassword')}
                                            />
                                        </div>
                                        {
                                            validateInputData.isTouchedPassword && !validateInputData.isValidPassword && <div className="col-md-12 mb-3 text-danger">
                                                {t("Login:PasswordText")}
                                            </div>
                                        }
                                        {
                                            validateInputData.isTouchedConfirmPassword && !validateInputData.isValidConfirmPassword && <div className="col-md-12 mb-3 text-danger">
                                                {t("Login:CoincidentPassword")} *
                                            </div>
                                        }
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                            <button disabled={isDisabledForm} className="btn btn-sm bg-custom w-100" onClick={() => onClickSend()}>
                                                {
                                                    !isLoading ? <>{t("Button:Send")}</>
                                                        : <div className="spinner-border spinner-border-sm text-light" role="status" style={{ 'fontSize': '9px' }}>
                                                            <span className="sr-only"></span>
                                                        </div>
                                                }
                                            </button>
                                         </div>
                                    </div>
                                }
                                {isLoadMessagge &&
                                    <div className="text-justify mt-4">
                                        {message}    
                                    </div>
                                }
                            </div>
                            <div className="col-3 d-none d-md-flex"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}