import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Loading } from '../Common/Loading';
import configData from "../../appsettings.json";
import Cookies from 'universal-cookie';
import { getWebsiteId } from '../../js/Utils.js';

export const ForgotPassword = () => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");
    const [username, setUsername] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [isLoadMessagge, setIsLoadMessage] = useState(false);
    const [message, setMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    function onClickSend() {
        setIsLoading(true);
        callForgotPassword();
    }

    const handleChange = (e) => {
        setUsername(e);

        if (e.trim() !== "")
            setDisabled(false);
        else
            setDisabled(true);
    };

    async function callForgotPassword() {
        var inputData = {
            username: username,
            websiteId: parseInt(getWebsiteId()),
            cultureCode: cultureCode
        }

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'user/ForgotPassword', requestOption);
        const response = await fetchedRes.json();

        setIsLoadMessage(true);
        if (response) {
            setIsLoading(false);
            setMessage(t("Login:EmailForgotPasswordText"));
        }
        else {
            setMessage("Errore invio mail");
        }
    }

    return (
        <>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className="col-3 d-none d-flex"></div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-left">
                        <h3>{t("Login:ForgotPassword")}</h3>
                    </div>
                    <div className="col-12 text-center">
                        <div className="row">
                            <div className="col-3 d-none d-flex"></div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                {!isLoadMessagge &&
                                    <div className="row">
                                        <div className="col-lg-8 col-md-8 col-sm-12 col-12 pl-2">
                                            <input className="input-text full-width txtUsername" style={{ height: "34px", width: "100%" }} placeholder="Username" value={username} onChange={e => handleChange(e.target.value)} />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                            <button disabled={disabled} className="btn btn-sm buttonCustom w-100" onClick={() => onClickSend()}>
                                                {
                                                    !isLoading ? <>{t("Button:Send")}</>
                                                        : <div className="spinner-border spinner-border-sm text-light" role="status" style={{ 'fontSize': '9px' }}>
                                                            <span className="sr-only"></span>
                                                        </div>
                                                }
                                            </button>
                                         </div>
                                    </div>
                                }
                                {isLoadMessagge &&
                                    <div className="text-justify mt-4">
                                        {message}    
                                    </div>
                                }
                                <div className="text-justify mt-4">
                                    {t("Login:ForgotPasswordText")}
                                </div>
                            </div>
                            <div className="col-3 d-none d-md-flex"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}