import React from 'react';
import { useTranslation } from 'react-i18next';

export const CruiseInfoLink = ({ infoProviderLinks }) => {
    const { t } = useTranslation();

    return (
        <>
            {
                infoProviderLinks && infoProviderLinks.length > 0 && <>
                    <div className="w-100 mt-2">
                        <div className="h6">{t('Product:Cruise:InfoLink')}</div>
                        <ul className="list-group pl-3">
                            {
                                infoProviderLinks.map((item, index) => {
                                    return <li key={index} class="list-group-item border-0 my-1 bg-blue rounded">
                                        <a href={item.url} target="_blank" className="text-decoration-none text-white">{item.name}</a>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </>
            }
        </>
    );
}