import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../M3Icon";
import configData from "../../../appsettings.json";

export const InputSearchDestinationOnlyRead = ({ defaultSelectDestination, onlyView, modeView, openPanelDestination, productType, extraOptions,clearSelection }) => {
    const { t } = useTranslation();

    const [iconWidth, setIconWidth] = useState('w-10');
    const [inputWidth, setInputWidth] = useState('w-90');

    useEffect(() => {
        if (productType === configData.Settings.Products.Flight.IdTipoProdotto) {
            setIconWidth('w-20');
            setInputWidth('w-80');

            if (extraOptions && extraOptions.clearSelection)
                setInputWidth('w-70');
        } else if (productType === configData.Settings.Products.Train.IdTipoProdotto) {
            setIconWidth('w-20');
            setInputWidth('w-80');

            if (extraOptions && extraOptions.clearSelection)
                setInputWidth('w-70');
        }
        else {
            if (extraOptions && extraOptions.clearSelection)
                setInputWidth('w-80');
        }

        if (modeView === 'COMPACTVIEW') {
            setIconWidth('w-20');
            setInputWidth('w-80');
        }

    }, [productType]);

    function onOpenPanelDestination(event) {
        let isClickClose = false;
        try {
            isClickClose = event.target.className.includes('btn-close');
        } catch (ex) { }
       
        if (!onlyView && !isClickClose)
            openPanelDestination();
    }

    const getLabel = () => {
        if (extraOptions && extraOptions.label)
            return t(extraOptions.label);

        switch (productType) {
            case configData.Settings.Products.Flight.IdTipoProdotto:
                return t('SearchEngine:DepartureAirport');

            default:
                return t('SearchEngine:Destination');
        }
    }

    const getDefaultInputValDescription = () => {
        if (extraOptions && extraOptions.inputText)
            return t(extraOptions.inputText);

        switch (productType) {
            case configData.Settings.Products.Flight.IdTipoProdotto:
                return t('SearchEngine:Airport');

            case configData.Settings.Products.Train.IdTipoProdotto:
                return t('SearchEngine:Station');

            case configData.Settings.Products.Cruise.IdTipoProdotto:
                return t('Product:Cruise:DeparturePort');

            default:
                return t('SearchEngine:WhereDoYouGo');
        }
    }

    return (<>
        <div className={"input-group rounded pr-1 " + (onlyView ? "bg-gray-300" : "bg-white")} onClick={e => onOpenPanelDestination(e)}>
            <span className={"input-group-text px-2 " + iconWidth + " " + (onlyView ? "bg-gray-300" : "bg-white")} id="basic-addon1">
                {extraOptions && extraOptions.iconName && <M3Icon iconName={extraOptions.iconName} />}
                {(!extraOptions || !extraOptions.iconName) && <M3Icon iconName="Place" />}
            </span>
            <div className={"pl-1 " + inputWidth}>
                <label htmlFor="Destination" className="form-label h065">
                    <data m3lab="SearchEngine.Destination">
                        {getLabel()}
                    </data>
                </label>

                <input className={"form-control h075 border-0 shadow-none w-100 " + (onlyView ? "bg-gray-300" : "")}
                    id="Destination"
                    value={defaultSelectDestination && defaultSelectDestination.Text
                        ? defaultSelectDestination.Text
                        : getDefaultInputValDescription()
                    }
                    readOnly />
            </div>
            {
                extraOptions && extraOptions.clearSelection && defaultSelectDestination && <button type="button" className="btn-close mr-1 m-auto" onClick={_ => clearSelection()}></button>
            }
        </div>
    </>
    );
}
