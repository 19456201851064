import React, { useState, useEffect } from 'react';
import { useAuth } from '../../Auth/AuthProvider';
import { HotelStaticContainer } from './components/HotelStaticContainer';


export const HotelDetailStatic = () => {
    let params = new URLSearchParams(window.location.search);
    let structureId = params.get('sId');

    const [searchParam, setSearchParam] = useState(null);
    let { getQuotationInfoSession } = useAuth();

    useEffect(() => {

        if (getQuotationInfoSession && getQuotationInfoSession.quotationInfoSearchParam) {
            setSearchParam(getQuotationInfoSession.quotationInfoSearchParam.hotelSearchParameters);
        }

    }, [getQuotationInfoSession])

    return (
        <>
            {
                structureId && <main className="bg-gray-300">
                    <div className="container mt-5">
                        <div className="card border-light bg-white p-1">
                            <HotelStaticContainer structureId={structureId} isStaticView={true} searchParameters={searchParam} />
                        </div>
                    </div>
                </main>
            }
        </>
    );
}