import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import configData from "../../appsettings.json";
import { Loading } from '../Common/Loading';
import { useTranslation } from 'react-i18next';
import { Error } from '../Common/Error';
import { CartScadenziario } from '../Cart/CartScadenziario';
import { QuotationHeader } from './components/QuotationHeader';
import { QuotationTableMargin } from './components/QuotationTableMargin';
import { QuotationRecapItemADV } from './components/QuotationRecapItemADV';
import { QuotationSteps } from './components/QuotationSteps';
import { QuotationChats } from './components/QuotationChats';
import { QuotationPaxes } from './components/QuotationPaxes';
import { QuotationPaxesForm } from './components/QuotationPaxesForm';
import { QuotationBaseButton } from './components/QuotationBaseButton';
import { handleError, getCurrentUserFromJwtToken } from "../../js/Utils";
import { isQuotationPaxesFilled } from "./service/QuotationService";

export const QuotationTOADV = () => {
    const { t } = useTranslation();

    let { quotationId } = useParams();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const pdfFileName = "Preventivo" + quotationId;

    const [userIdWorkNow, SetUserIdWorkNow] = useState(null);
    const [actualUserInfo, setActualUserInfo] = useState({});
    const [customerInfoQuotation, setCustomerInfoQuotation] = useState({});
    const [quotation, setQuotation] = useState(null);
    const [quotationHeader, setQuotationHeader] = useState(null);
    const [quotationTableMarginItems, setQuotationTableMarginItems] = useState([]);
    const [quotationRecapItems, setQuotationRecapItems] = useState([]);
    const [quotationItemStep, setQuotationItemStep] = useState([]);
    const [quotationItemStepPrint, setQuotationItemStepPrint] = useState([]);
    const [quotationMode, setQuotationMode] = useState([]);
    const [quotationAllowedOperation, setQuotationAllowedOperation] = useState(null);
    const [baseQuotationDetail, setBaseQuotationDetail] = useState(null);

    const [quotationChats, setQuotationChats] = useState([]);
    const [limitQuotationChats, setLimitQuotationChats] = useState(5);
    const [totalChats, setTotalChats] = useState(0);

    const [quotationPaxes, setQuotationPaxes] = useState([]);
    const [minProductDate, setMinProductDate] = useState(null);
    const [isLoadingSaveAllPaxes, setIsLoadingSaveAllPaxes] = useState(true);
    const [resultSaveAllPaxes, setResultSaveAllPaxes] = useState("");
    const [isAllQuotationPaxesFilled, setIsAllQuotationPaxesFilled] = useState("");
    const [saveAllPaxesOpt, setSaveAllPaxesOpt] = useState({ isConfirmAll: false, quotationItemId: null, status: null });

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadAfterPage, setIsLoadAfterPage] = useState(false);
    const [isError, setIsError] = useState(false);

    const [showHistory] = useState({ isDeleted: false, isReplaced: false });
    const [scadenziario, setScadenziario] = useState(null);
    const [dynamicNotes, setDynamicNotes] = useState([]);

    const [quotationSettings] = useState({
        baseUrl: configData.Settings.Base_Url,
        minPercTotaleMargineLordo: configData.Settings.QuotationTO.MinPercTotaleMargineLordo,
        maxPercTotaleMargineLordo: configData.Settings.QuotationTO.MaxPercTotaleMargineLordo,
        groupedAddictionalProductADV: configData.Settings.QuotationTO.GroupedAddictionalProductADV,
        excludeRicavoTMFromMargineSede: configData.Settings.QuotationTO.ExcludeRicavoTMFromMargineSede,
        calcoloTMType: configData.Settings.QuotationTO.CalculateMarginTM,
        isDeleted: false,
        isReplaced: false,
        isAdmin: false
    });

    const [tipoProdotto, setTipoProdotto] = useState([]);
    const [websiteId, setWebsiteId] = useState();

    let oldArrTipoProdotto = [...tipoProdotto];

    useEffect(() => {
        callGetQuotationDetail();
        let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
        let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);
        setWebsiteId(currentUser.websiteId);
    }, []);

    useEffect(() => {
        if (isLoadAfterPage) {
            callGetChats(limitQuotationChats);
            callGetScadenziario();
            callGetCustomerInfoQuotation();
            callGetPaxes();
        }

    }, [isLoadAfterPage]);

    function getProductType(idTipoProdotto) {
        if (oldArrTipoProdotto.indexOf(idTipoProdotto) === -1) {
            oldArrTipoProdotto.push(Number(idTipoProdotto));
            setTipoProdotto(oldArrTipoProdotto);
        }
    }

    async function callGetDynamicNote(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'dynamicnote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();

        if (response.dynamicNotes != null && response.dynamicNotes.length > 0) {
            setDynamicNotes(arr => [...arr, response.dynamicNotes]);
        }
    }

    function openPopupLoadUpdate() {
        var myBtn = document.getElementById("openQuotationTO_LoadingAfterUpdate");
        myBtn.click();
    }

    function closePopupLoadUpdate() {
        setTimeout(() => {
            let myBtn = document.getElementById("closeQuotationTO_LoadingAfterUpdate");
            myBtn.click();
        }, 1000);
    }

    function callGetQuotationDetail() {
        setIsLoading(true);

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: -1,
                quotationSettings: quotationSettings
            })

        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationdetailadv`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotation(item);
                setBaseQuotationDetail(item.baseQuotationDetail);

                SetUserIdWorkNow(item.userIdWorkNow);

                setActualUserInfo({
                    username: item.userQuotation,
                    userId: item.userQuotationId,
                    agencyName: item.agencyName,
                    agencyId: item.agencyId
                });

                setQuotationHeader(
                    {
                        name: item.name,
                        ticketId: item.ticketId,
                        quotationId: item.quotationId,
                        agencyName: item.agencyName,
                        createDate: item.createDate,
                        tablePricing: item.tablePricing,
                        commentsTotal: item.chatTotal,
                        commentsToReadTotal: item.chatTotalToRead,
                        internalNotesTotal: item.totalInternalNotes,
                        status: item.status,
                        numeroOrdine: item.numeroOrdine,
                        isCommissionable: item.tablePricing.isCommissionable
                    }
                );
                setQuotationMode(item.quotationMode);
                setQuotationAllowedOperation(item.allowedOperations);

                setTotalChats(item.chatTotal);

                setQuotationRecapItems(item.recapItems);
                setQuotationTableMarginItems({
                    hasIVACee: item.tablePricing.hasIVACee,
                    tableMargin: item.tablePricing.tableMargin,
                    totalCommissionAmount: item.tablePricing.totalCommission,
                    totalCommissionable: item.tablePricing.totalCommissionable,
                    totalCommissionCurrency: item.tablePricing.currency,
                    totalNotCommissionableAmount: item.tablePricing.totalNotCommissionable,
                    totalNotCommissionableCurrency: item.tablePricing.currency
                });

                setQuotationItemStep(item.quotationItemStep);

                if (item.recapItems && item.recapItems.length > 0) {
                    var myDates = item.recapItems.filter(r => r.productType !== 'Generic').map(function (myRecapItem) { return new Date(myRecapItem.dateFrom) });
                    var minDate = new Date(Math.min.apply(null, myDates))
                    setMinProductDate(minDate);
                }

                setIsError(false);
                setIsLoadAfterPage(true);

                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setQuotation(null);
                setIsError(true);
                setIsLoading(false);
            });

    }

    function callOnSaveSelectAlternative(item) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                oldSelectedAlternativeId: item.oldSelectedAlternativeId,
                selectedAlternativeId: item.selectedAlternativeId
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/selectalternative`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }

    function callOnChangeStatusQuotationItem(id, status) {
        let isAllPaxesFilled = true;

        if (status === 1) {
            isAllPaxesFilled = isQuotationPaxesFilled(quotationPaxes);
            setIsAllQuotationPaxesFilled(isAllPaxesFilled);

            if (!isAllPaxesFilled) {
                setSaveAllPaxesOpt({
                    isConfirmAll: false,
                    quotationItemId: id,
                    status: status
                });
                openPopupFillPaxes();
            }
        }

        if (isAllPaxesFilled) {
            openPopupLoadUpdate();

            const requestOption = {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    quotationId: quotationId,
                    userIdWorkNow: userIdWorkNow,
                    quotationSettings: quotationSettings,
                    quotationItemId: id,
                    status: status
                })
            };

            fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/updatequotationitemstatus`, requestOption)
                .then((res) => handleError(res))
                .then((item) => {
                    updateQuotationAfterAPI(item);
                })
                .catch((err) => {
                    console.error(err);
                    closePopupLoadUpdate();
                });
        }

    }

    function callOnCallSaveProductInfo(response, type, id) {
        let isFound = false;

        let quotationItemStepToPrint = [...quotationItemStepPrint];
        if (quotationItemStepPrint && quotationItemStepPrint.length === 0)
            quotationItemStepToPrint = [...quotationItemStep];

        for (let i = 0; i < quotationItemStepToPrint.length; i++) {
            let itemStep = quotationItemStepToPrint[i];
            if (itemStep) {
                for (let j = 0; j < itemStep.quotationItemInfo.length; j++) {
                    let qInfo = itemStep.quotationItemInfo[j];

                    if (qInfo.wsBookingId && type === 'QuotationTO_MyBook') {
                        if (qInfo.wsBookingId === id) {
                            qInfo.productInfo = response;
                            isFound = true;
                            callDynamicNotes(qInfo);
                            break;
                        }
                    }

                    if (qInfo.id && type === 'QuotationTO') {
                        if (qInfo.id === id) {
                            qInfo.productInfo = response;
                            isFound = true;
                            callDynamicNotes(qInfo);
                            break;
                        }
                    }
                }
            }

            if (isFound)
                break;
        }

        setQuotationItemStepPrint(quotationItemStepToPrint);
    }

    function callDynamicNotes(qInfo) {
        let idTipoProdotto = 99;
        if (qInfo.productType === "Activity") {
            //getProductType(configData.Settings.Products.Activity.IdTipoProdotto);
            idTipoProdotto = configData.Settings.Products.Activity.IdTipoProdotto;
        }
        if (qInfo.productType === "Flight") {
            //getProductType(configData.Settings.Products.Flight.IdTipoProdotto);
            idTipoProdotto = configData.Settings.Products.Flight.IdTipoProdotto;
        }
        if (qInfo.productType === "Structure") {
            //getProductType(configData.Settings.Products.Structure.IdTipoProdotto);
            idTipoProdotto = configData.Settings.Products.Structure.IdTipoProdotto;
        }
        if (qInfo.productType === "Tour") {
            //getProductType(configData.Settings.Products.Tour.IdTipoProdotto);
            idTipoProdotto = configData.Settings.Products.Tour.IdTipoProdotto;
        }
        if (qInfo.productType === "Transfer") {
            //getProductType(configData.Settings.Products.Transfer.IdTipoProdotto);
            idTipoProdotto = configData.Settings.Products.Transfer.IdTipoProdotto;
        }
        if (qInfo.productType === "CarRental") {
            //getProductType(configData.Settings.Products.CarRental.IdTipoProdotto);
            idTipoProdotto = configData.Settings.Products.CarRental.IdTipoProdotto;
        }

        const getDynamicNote = async (inputData) => {
            const response = callGetDynamicNote(inputData);
        }

        getDynamicNote({
            cultureCode: cultureName,
            websiteId: websiteId,
            pageId: 14,
            productTypes: [idTipoProdotto],
            productCode: qInfo.productInfo ? qInfo.productInfo.productId : null,
            checkInFrom: qInfo.productInfo.priceBar ? qInfo.productInfo.priceBar.startDate : null,
            checkInTo: qInfo.productInfo.priceBar ? qInfo.productInfo.priceBar.endDate : null,
            webDestinationIds: qInfo.productInfo.webDestinationIds ? qInfo.productInfo.webDestinationIds : null,
            supplierId: qInfo.productInfo.supplierId ? qInfo.productInfo.supplierId : null,
        });
    }

    function callConfirmAll() {

        const isAllPaxesFilled = isQuotationPaxesFilled(quotationPaxes);
        setIsAllQuotationPaxesFilled(isAllPaxesFilled);

        if (isAllPaxesFilled) {
            saveConfirmAll()
        } else {
            setSaveAllPaxesOpt({
                isConfirmAll: true,
                quotationItemId: null,
                status: null
            });
            openPopupFillPaxes();
        }
    }

    function saveConfirmAll() {

        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/confirmallquotation`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });

    }

    function callGetScadenziario() {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationscadenziario`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setScadenziario(item);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function callGetPaxes() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationpaxes/${quotationId}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationPaxes(item);
                setIsLoadingSaveAllPaxes(false);
            })
            .catch((err) => {
                console.error(err);
                setIsLoadingSaveAllPaxes(false);
            });
    }

    function onAddPaxBpValues(myPax) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                paxes: myPax
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/updatequotationpaxbpvalues`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                console.log(item.length);
                setQuotationPaxes(item);
                closePopupLoadUpdate();
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }

    function updateQuotationAfterAPI(item) {
        setQuotationHeader({
            ...quotationHeader,
            status: item.status,
            tablePricing: item.tablePricing,
            isCommissionable: item.tablePricing.isCommissionable
        });
        setQuotationRecapItems(item.recapItems);
        setQuotationTableMarginItems({
            hasIVACee: item.tablePricing.hasIVACee,
            tableMargin: item.tablePricing.tableMargin,
            totalCommissionAmount: item.tablePricing.totalCommission,
            totalCommissionable: item.tablePricing.totalCommissionable,
            totalCommissionCurrency: item.tablePricing.currency,
            totalNotCommissionableAmount: item.tablePricing.totalNotCommissionable,
            totalNotCommissionableCurrency: item.tablePricing.currency
        });

        setQuotationItemStep(item.quotationItemStep);

        if (item.scadenziario)
            setScadenziario(item.scadenziario);

        closePopupLoadUpdate();
    }

    function callGetChats(limit = -1) {
        setLimitQuotationChats(limit);

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationchat/${quotationId}/${limit}/${userIdWorkNow}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationChats(item);
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
            });
    }

    function callAddChat(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                text: inputData,
                limit: limitQuotationChats
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/AddQuotationChat`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationChats(item);

                let totalChats = quotationChats.length + 1;
                setTotalChats(totalChats);
                setQuotationHeader({ ...quotationHeader, commentsTotal: totalChats });
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
            });
    }

    function callAddChatAnswer(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                chatId: inputData.chatId,
                text: inputData.text,
                limit: limitQuotationChats
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/AddQuotationChatAnswer`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationChats(item);

                let totalChats = quotationChats.length + 1;
                setTotalChats(totalChats);
                setQuotationHeader({ ...quotationHeader, commentsTotal: totalChats });
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
            });
    }

    function callReadChat(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                chatId: inputData,
                limit: limitQuotationChats
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/UpdateQuotationChatRead`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationChats(item);

                let totalChats = quotationChats.length + 1;
                setTotalChats(totalChats);
                setQuotationHeader({ ...quotationHeader, commentsTotal: totalChats });
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
            });
    }

    function callGetCustomerInfoQuotation() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}customer/getcustomerinfo/${actualUserInfo.agencyId}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setCustomerInfoQuotation(item);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function openPopupFillPaxes() {
        setIsLoadingSaveAllPaxes(false);
        setResultSaveAllPaxes("");

        var myBtn = document.getElementById("openQuotationTO_ConfirmAllPaxes");
        myBtn.click();
    }

    function onSaveAllPaxBpValues(myPax) {
        setIsLoadingSaveAllPaxes(true);
        setResultSaveAllPaxes("");

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                paxes: myPax
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/updatequotationpaxbpvalues`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationPaxes(item);

                const isAllPaxesFilled = isQuotationPaxesFilled(item);
                let msg = ""
                if (!isAllPaxesFilled)
                    msg = t("MyBook:AttentionTextQuotation");

                setIsAllQuotationPaxesFilled(isAllPaxesFilled);
                setIsLoadingSaveAllPaxes(false);
                setResultSaveAllPaxes(t("MyBook:SaveSuccess") + msg);
            })
            .catch((err) => {
                console.error(err);

                setIsLoadingSaveAllPaxes(false);
                setResultSaveAllPaxes(t("MyBook:NotPossibleSaveData"));
            });
    }

    function onSaveAllPaxAndConfirm() {
        if (saveAllPaxesOpt.isConfirmAll) {
            callConfirmAll();
        } else if (saveAllPaxesOpt.quotationItemId) {
            callOnChangeStatusQuotationItem(saveAllPaxesOpt.quotationItemId, saveAllPaxesOpt.status);
        }
    }

    return (
        <>
            <div className="section section-lg bg-white" id="content">
                <div className="container ">
                    {
                        isLoading
                            ? (<Loading textMsg={t("MyBook:Loading")} />)
                            : !isError && quotation !== null ?
                                <>
                                    <QuotationBaseButton
                                        quotationId={quotationId}
                                        pdfFileName={pdfFileName}
                                        quotationMode={quotationMode}
                                        quotationHeader={quotationHeader}
                                        quotationPaxes={quotationPaxes}
                                        quotationTableMarginItems={quotationTableMarginItems}
                                        quotationRecapItems={quotationRecapItems}
                                        quotationItemStepPrint={quotationItemStepPrint}
                                        dynamicNotes={dynamicNotes}
                                        customerInfoQuotation={customerInfoQuotation}
                                        scadenziario={scadenziario}
                                        cultureName={cultureName}
                                        callOnSendMailAgency={null}
                                        sendMailMsg={null}
                                        quotationAllowedOperation={quotationAllowedOperation}
                                    />
                                    <div className="mybook-order mybook-order-main p-0">
                                        <div className="card border-light p-0 p-md-4">
                                            <QuotationHeader
                                                quotationHeader={quotationHeader}
                                                cultureName={cultureName}
                                                callConfirmAll={callConfirmAll}
                                                quotationMode={quotationMode}
                                                quotationAllowedOperation={quotationAllowedOperation}
                                            />
                                        </div>
                                        {
                                            baseQuotationDetail && baseQuotationDetail.showPriceQuotationTOADV && <QuotationTableMargin
                                                marginItems={quotationTableMarginItems}
                                                cultureName={cultureName}
                                                isCommissionable={quotationHeader.isCommissionable}
                                                quotationMode={quotationMode}
                                            />
                                        }
                                    </div>
                                    {
                                        quotationRecapItems && quotationRecapItems.length > 0
                                            ? <QuotationRecapItemADV
                                                recapItems={quotationRecapItems}
                                                cultureName={cultureName}
                                            />
                                            : <></>
                                    }
                                    {
                                        scadenziario && <div className="p-4"><CartScadenziario
                                            selectedAddProdIds={[]}
                                            orderNumber={-1}
                                            mode={"QuotationTO"}
                                            extScadenziario={scadenziario} /></div>
                                    }
                                    {
                                        !isLoadingSaveAllPaxes && <QuotationPaxes
                                            paxes={quotationPaxes}
                                            startDate={minProductDate}
                                            onAddPaxBpValues={onAddPaxBpValues}
                                            orderNumber={quotationHeader.numeroOrdine}
                                            isAgencyMode={true}
                                        />
                                    }
                                    {
                                        quotationItemStep && quotationItemStep.length > 0
                                            ? <QuotationSteps items={quotationItemStep}
                                                cultureName={cultureName}
                                                callOnChangeStatusQuotationItem={callOnChangeStatusQuotationItem}
                                                onSaveSelectAlternative={callOnSaveSelectAlternative}
                                                onCallSaveProductInfo={callOnCallSaveProductInfo}
                                                showHistory={showHistory}
                                                quotationMode={quotationMode}
                                            />
                                            : <></>
                                    }
                                    {
                                        configData.Settings.QuotationTO.ShowChat && <QuotationChats
                                            quotationChats={quotationChats}
                                            totalChats={totalChats}
                                            limitQuotationChats={limitQuotationChats}
                                            callAddChat={callAddChat}
                                            callAddChatAnswer={callAddChatAnswer}
                                            callReadChat={callReadChat}
                                            callGetAllChat={callGetChats}
                                            cultureName={cultureName}
                                            isAgencyMode={true} />
                                    }

                                </>
                                : <Error textMsg={t("MyBook:QuotationNotAvailable")} />
                    }
                </div >
            </div >

            <a id="openQuotationTO_LoadingAfterUpdate" data-bs-toggle="modal" data-bs-target="#quotationTO_LoadingPage"></a>
            <div className="modal fade" id="quotationTO_LoadingPage" tabIndex="-1" aria-labelledby="quotationTO_LoadingPageLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <a id="closeQuotationTO_LoadingAfterUpdate" data-bs-dismiss="modal" />
                            <Loading textMsg={t("MyBook:ReloadingQuotation")} />
                        </div>
                    </div>
                </div>
            </div>

            <a id="openQuotationTO_ConfirmAllPaxes" data-bs-toggle="modal" data-bs-target="#quotationTO_ConfirmAllPaxes"></a>
            <div className="modal fade" id="quotationTO_ConfirmAllPaxes" tabIndex="-1" aria-labelledby="quotationTO_ConfirmAllPaxesLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{t("MyBook:FillPassengersData")}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                {t("MyBook:QuotationFillMandatoryText")}
                            </p>
                            <QuotationPaxesForm
                                paxes={quotationPaxes}
                                startDate={minProductDate}
                                isAllQuotationPaxesFilled={isAllQuotationPaxesFilled}
                                isAgencyMode={true}
                                onAddPaxBpValues={onSaveAllPaxBpValues}
                                extraOptions={{ saveSinglePax: false, readonly: false }}
                            />
                        </div>
                        <div className="modal-footer">
                            <p>{resultSaveAllPaxes}</p>
                            {
                                isLoadingSaveAllPaxes && <li>Loading<div className="spinner-border spinner-border-sm text-light" role="status" style={{ 'fontSize': '9px' }}>
                                    <span className="sr-only"></span>
                                </div></li>
                            }
                            {
                                isAllQuotationPaxesFilled && <button className="tp-btn-confirm btn-sm" onClick={onSaveAllPaxAndConfirm} data-bs-dismiss="modal">{t("MyBook:Confirm")}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}