import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { M3IconProductType } from '../../Common/M3IconProductType';
import { formatDateTime, formatPrice } from '../../../js/Utils.js';

export const ChangePolicyPrice = ({ productChangePolicyPrice }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName, setCultureName] = useState(cookies.get("CultureName"));

    return (
        <>
            <a id={"open_ChangePolicyPrice"} data-bs-toggle="modal" data-bs-target={"#modal_ChangePolicyPrice"}></a>

            <div className="modal fade" id={"modal_ChangePolicyPrice"} tabIndex="-1" aria-labelledby="modal_ChangePolicyPriceLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal_ChangePolicyPriceLabel">{t(`Cart:ChangePolicyPriceTitle`)}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h6 className="mb-4">{t(`Cart:ChangePolicyPriceMessage`)}</h6>

                            {productChangePolicyPrice.map((productChange, idx) => {
                                return (
                                    <React.Fragment key={idx}>
                                        <div className="row">
                                            <div className="col-12">
                                                <M3IconProductType productType={productChange.productType} externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                                                {productChange.productName} 
                                            </div>
                                            {!productChange.isCancelPolicyEquals
                                                ? (
                                                    <div className="row mt-2">
                                                        <div className="col-6">
                                                            <label className="d-inline block">CancelPolicy Old</label><br/>
                                                            <label className="penalty">
                                                                <span className={productChange.cancelPolicyOld.isFree ? "penalty free" : "penalty"}>
                                                                    {productChange.cancelPolicyOld.isFree ? t("CancelPolicy:FreeCancellation") + " " + formatDateTime(productChange.cancelPolicyOld.expirationDate, { twoDigits: true }) : t("CancelPolicy:PenaltyCancellation")}
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="col-6">
                                                            <label className="d-inline block">CancelPolicy New</label><br />
                                                            <label className="penalty">
                                                                <span className={productChange.cancelPolicyNew.isFree ? "penalty free" : "penalty"}>
                                                                    {productChange.cancelPolicyNew.isFree ? t("CancelPolicy:FreeCancellation") + " " + formatDateTime(productChange.cancelPolicyNew.expirationDate, { twoDigits: true }) : t("CancelPolicy:PenaltyCancellation")}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                )
                                                : (<></>)
                                            }
                                        </div>

                                        {!productChange.isPricingEquals
                                            ? (
                                                <div className="row mt-2">
                                                    <div className="col-6">
                                                        <label className="d-inline block">Price Old</label><br />
                                                        {formatPrice(productChange.pricingOld.displayPrice, productChange.pricingOld.displayPriceCurrency, cultureName)}
                                                    </div>
                                                    <div className="col-6">
                                                        <label className="d-inline block">Price New</label><br />
                                                        {formatPrice(productChange.pricingNew.displayPrice, productChange.pricingNew.displayPriceCurrency, cultureName)}
                                                    </div>
                                                </div>
                                            )
                                            : (<></>)
                                        }
                                    </React.Fragment>
                                );
                            })}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">Chiudi</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}