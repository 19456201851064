import React, { useState, useEffect } from 'react';
import configData from "../../../appsettings.json";
import { M3IconProductType } from '../../Common/M3IconProductType.js';
import { ActivitySearchEngineTrippie } from '../searchEngine/ActivitySearchEngineTrippie';
import { CarSearchEngineTrippie } from '../searchEngine/CarSearchEngineTrippie';
import { StructureSearchEngineTrippie } from '../searchEngine/StructureSearchEngineTrippie';
import { TransferSearchEngineTrippie } from '../searchEngine/TransferSearchEngineTrippie';
import { TourSearchEngineTrippie } from '../searchEngine/TourSearchEngineTrippie';
import { FlightSearchEngineTrippie } from '../searchEngine/FlightSearchEngineTrippie';
import { TrainSearchEngineTrippie } from '../searchEngine/TrainSearchEngineTrippie';
import { getPassengerComposition } from '../../Template/TemplateUtils.js';

export const TrippieStepAddItem = ({ rules, stepIndex, changeDataStep, stepInfo, prevStep, nextStep, stepDestination, onStartSearch }) => {

    const [activitySearchParam, setActivitySearchParam] = useState(null);
    const [carSearchParam, setCarSearchParam] = useState(null);
    const [hotelSearchParam, setHotelSearchParam] = useState(null);
    const [tourSearchParam, setTourSearchParam] = useState(null);
    const [trainSearchParam, setTrainSearchParam] = useState(null);
    const [flightSearchParam, setFlightSearchParam] = useState(null);
    const [transferSearchParam, setTransferSearchParam] = useState(null);

    const [selectProductType, setSelectProductType] = useState(null);
    const [extraParam, setExtraParam] = useState({ stepIndex: stepIndex });

    function isVisibleProduct(productName) {
        if (!rules)
            return false;

        let isFlightStep = stepInfo.stepType === (configData.Settings.Products.Flight.IdTipoProdotto + "");

        try {

            switch (productName) {
                case 'ACTIVITY':
                    {
                        return !isFlightStep && rules["ACTIVITY"];
                        break;
                    }
                case 'RENTCAR':
                    {
                        return !isFlightStep && rules["CARRENTAL"];
                        break;
                    }
                //case 'CRUISE':
                //    {
                //        return !isFlightStep && rules["CRUISE"];
                //        break;
                //    }
                case 'HOTEL':
                    {
                        return !isFlightStep && rules["STRUCTURE"];
                        break;
                    }
                case 'TRANSFER':
                    {
                        return !isFlightStep && rules["TRANSFER"];
                        break;
                    }
                case 'TOUR':
                    {
                        return !isFlightStep && rules["TOUR"];
                        break;
                    }
                case 'FLIGHT':
                    {
                        return isFlightStep && rules["FLIGHT"];
                        break;
                    }
                //case 'TRAIN':
                //    {
                //        return !isFlightStep && rules["TRAIN"];
                //        break;
                //    }
                default: {
                    return false;
                }
            }


        } catch (ex) {
            return false;
        }
    }

    function showAddProductPanel(idTipoProdotto) {

        if (selectProductType === (idTipoProdotto))
            setSelectProductType(null);
        else
            setSelectProductType(idTipoProdotto);
    }

    function onStartSearchStep(stepIndexParam, clientFlowId, carpetId, searchParam, productType) {
        onStartSearch(stepIndexParam, clientFlowId, carpetId, searchParam, productType);

        try {
            let items = document.getElementsByClassName("tp-btn-trippie");
            for (let i = 0; i < items.length; i++) {
                try {
                    items[i].click();
                } catch (ex) { }
            }
        } catch (ex) { }

    }

    useEffect(() => {

        if (stepInfo && stepInfo.stepSearchParam)
            createDefaultSearchParam(
                stepInfo.stepFrom,
                stepInfo.stepTo,
                stepInfo.webDestinationId ? stepInfo.webDestinationId : stepDestination.WebId,
                stepInfo.webDestinationName ? stepInfo.webDestinationName : stepDestination.Text,
                stepInfo.webDestinationType ? stepInfo.webDestinationType : stepDestination.Type,
                { roomsComposition: stepInfo.stepSearchParam }
            );

        if (changeDataStep)
            setSelectProductType(null);

    }, [stepInfo, changeDataStep, stepDestination])

    function createDefaultSearchParam(stepFrom, stepTo, webDestinationId, webDestinationName, webDestinationType, stepSearchParam) {

        setExtraParam({
            ...extraParam,
            validDateRule: {
                minDate: stepFrom,
                maxDate: stepTo
            }
        });

        createActivitySearchParam(stepFrom, stepTo, webDestinationId, webDestinationName, webDestinationType, stepSearchParam);
        createCarSearchParam(stepFrom, stepTo, webDestinationId, webDestinationName, webDestinationType, stepSearchParam);
        createHotelSearchParam(stepFrom, stepTo, webDestinationId, webDestinationName, webDestinationType, stepSearchParam);
        createTourSearchParam(stepFrom, stepTo, webDestinationId, webDestinationName, webDestinationType, stepSearchParam);
        createTrainSearchParam(stepFrom, stepTo, webDestinationId, webDestinationName, webDestinationType, stepSearchParam);
        createFlightSearchParam(stepFrom, stepTo, webDestinationId, webDestinationName, webDestinationType, stepSearchParam);
        createTransferSearchParam(stepFrom, stepTo, webDestinationId, webDestinationName, webDestinationType, stepSearchParam);
    }

    function createActivitySearchParam(stepFrom, stepTo, webDestinationId, webDestinationName, webDestinationType, stepSearchParam) {
        let newSP = {
            activityId: null,
            searchByDestinationOnly: false,
            citizenship: 380, // Default italia se non dovrebbe arrivare
            productSubType: 11,
            showCancelPolicies: true,
            templateId: null,
            testRequest: false,
            destinationWebIds: null,
            isTour: false,
            agencyMarkup: null,
            agencyFee: null
        };

        newSP.destinationDesc = webDestinationName;
        newSP.destinationWebId = webDestinationId;
        newSP.destinationType = webDestinationType;

        newSP.activityDate = stepFrom;
        newSP.dateFrom = stepFrom;
        newSP.dateTo = stepTo;

        newSP.passengerComposition = getPassengerComposition(stepSearchParam.roomsComposition, configData.Settings.Products.Activity.IdTipoProdotto);

        setActivitySearchParam(newSP);
    }

    function createCarSearchParam(stepFrom, stepTo, webDestinationId, webDestinationName, webDestinationType, stepSearchParam) {
        let newSP = {
            pickupDestinationType: null,
            pickupDestinationName: null,
            pickupDestinationWebId: null,
            pickupDate: null,
            dropoffDate: null,
            dropOffSameAsPickup: true,
            driverAge: 25,
            citizenship: 380,
            agencyMarkup: null,
            agencyFe: null,
            dropoffDestinationType: null,
            dropoffDestinationName: null,
            dropoffDestinationWebId: null,
        };

        try {
            newSP.pickupDestinationName = prevStep.webDestinationName;
            newSP.pickupDestinationWebId = prevStep.webDestinationId;

            if (prevStep.stepType === (configData.Settings.Products.Flight.IdTipoProdotto + "")) {
                newSP.pickupDestinationType = 'Airport';
            } else {
                newSP.pickupDestinationType = 'Destination';
            }

            //newSP.dropoffDestinationName = null;
            //newSP.dropoffDestinationWebId = null;
            //newSP.dropoffDestinationType = null;
        } catch (ex) { }

        newSP.dropOffSameAsPickup = true;

        newSP.pickupDate = stepFrom + "T12:00:00";
        newSP.dropoffDate = stepTo + "T12:00:00";

        setCarSearchParam(newSP);
    }

    function createHotelSearchParam(stepFrom, stepTo, webDestinationId, webDestinationName, webDestinationType, stepSearchParam) {
        let newSP = {
            structureId: null,
            destinationDesc: null,
            destinationId: null,
            destinationWebId: null,
            destinationType: null,
            structureIds: null,
            latitude: 0,
            longitude: 0,
            ray: 10,
            checkIn: null,
            checkOut: null,
            roomsComposition: [],
            citizenship: 380,
            suppliersToExclude: null,
            refundableOnly: false,
            templateId: null,
            timeout: null,
            groupedByRoomName: false,
            testRequest: false,
            agencyMarkup: null,
            agencyFee: null
        };

        newSP.destinationDesc = webDestinationName;
        newSP.destinationWebId = webDestinationId;
        newSP.destinationType = webDestinationType;

        newSP.checkIn = stepFrom;
        newSP.checkOut = stepTo;

        newSP.roomsComposition = getPassengerComposition(stepSearchParam.roomsComposition, configData.Settings.Products.Structure.IdTipoProdotto);

        setHotelSearchParam(newSP);
    }

    function createTourSearchParam(stepFrom, stepTo, webDestinationId, webDestinationName, webDestinationType, stepSearchParam) {
        let newSP = {
            destinationWebIds: [],
            destinationDesc: [],
            dates: [],
            roomsComposition: [],
            citizenship: 380,
            suppliersToExclude: [],
            suppliersToInclude: [],
            refundableOnly: false,
            availableOnly: false,
            structureTypes: [],
            timeout: 0,
            testRequest: false,
            templateId: null,
            agencyMarkup: null,
            agencyFee: null
        };

        newSP.destinationWebIds = [];
        newSP.destinationDesc = [];

        newSP.roomsComposition = getPassengerComposition(stepSearchParam.roomsComposition, configData.Settings.Products.Tour.IdTipoProdotto);

        newSP.dates = [stepFrom];

        setTourSearchParam(newSP);
    }

    function createTrainSearchParam(stepFrom, stepTo, webDestinationId, webDestinationName, webDestinationType, stepSearchParam) {
        let newSP = {
            filteringOptions: [],
            outboundFareArgs: null,
            outboundLegCriteria: null,
            returnLegCriteria: null,
            citizenship: 380,
            travelers: []
        };

        newSP.directionData = 1;

        newSP.outboundLegCriteria = {
            arrivalLocation: {
                stationId: null,
                stationDesc: null,
                stationType: null
            },
            departureDate: stepFrom,
            departureLocation: {
                stationId: null,
                stationDesc: null,
                stationType: null
            },
            departureTimeSlot: {
                fromTime: '12:00',
                toTime: null
            }
        }

        newSP.travelers = getPassengerComposition(stepSearchParam.roomsComposition, configData.Settings.Products.Train.IdTipoProdotto);

        setTrainSearchParam(newSP);
    }

    function createFlightSearchParam(stepFrom, stepTo, webDestinationId, webDestinationName, webDestinationType, stepSearchParam) {
        let newSP = {
            "legsCriteria": [],
            "fareOptions": {
                "publicFares": true,
                "privateFares": true,
                "passengerTypesOptions": []
            },
            "filteringOptions": {},
            "travelers": []
        };

        let legsCriteria = [
            {
                "departureLocation": {},
                "arrivalLocation": {},
                "date": stepFrom,
                "cabinClass": 1
            },
            {
                "departureLocation": {},
                "arrivalLocation": {},
                "date": stepTo,
                "cabinClass": 1
            }
        ];

        newSP.legsCriteria = legsCriteria;

        if (stepSearchParam.roomsComposition.roomsComposition) {
            let trv = [];
            for (let i = 0; i < stepSearchParam.roomsComposition.roomsComposition.length; i++) {
                let roomCompo = stepSearchParam.roomsComposition.roomsComposition[i]

                for (let j = 0; j < roomCompo.adults; j++) {
                    trv.push({ passengerTypeCodes: ['ADT'] });
                }

                for (let j = 0; j < roomCompo.childAges; j++) {
                    if (roomCompo.childAges[j] > 1)
                        trv.push({ passengerTypeCodes: ['CHD'] });
                    else
                        trv.push({ passengerTypeCodes: ['INF'] });
                }
            }

            newSP.travelers = trv;
        }

        setFlightSearchParam(newSP);
    }

    function createTransferSearchParam(stepFrom, stepTo, webDestinationId, webDestinationName, webDestinationType, stepSearchParam) {
        let dep = {};
        let arr = {};

        let transferType = 0;
        let departureFromAirport = false;

        try {

            if (prevStep.stepType === (configData.Settings.Products.Flight.IdTipoProdotto + "")) {
                dep = { code: prevStep.IataCode, type: 7, name: prevStep.webDestinationName };
                departureFromAirport = true;
            } else {
                arr = { code: prevStep.webDestinationId, type: 4, name: prevStep.webDestinationName };
            }

        } catch (ex) { }

        try {

            if (departureFromAirport) {

                if (nextStep.stepType !== (configData.Settings.Products.Flight.IdTipoProdotto + ""))
                    dep = { code: nextStep.webDestinationId, type: 4, name: nextStep.webDestinationName };

            } else {

                if (nextStep.stepType === (configData.Settings.Products.Flight.IdTipoProdotto + ""))
                    arr = { code: nextStep.IataCode, type: 7, name: nextStep.webDestinationName };
            }

        } catch (ex) { }


        let newSP = {
            pickupPoint: dep,
            dropoffPoint: arr,
            pickupDate: stepFrom + "T12:00:00",
            returnPickUpDate: stepTo + "T12:00:00",
            direction: transferType,
            passengersComposition: getPassengerComposition(stepSearchParam.roomsComposition, configData.Settings.Products.Transfer.IdTipoProdotto),
            citizenship: 380
        };

        setTransferSearchParam(newSP);
    }

    return (
        <div>
            <div className="row p-4">
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 mt-1">Cosa vuoi aggiungere?</div>
                {
                    isVisibleProduct('ACTIVITY') && <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                        <button
                            onClick={_ => showAddProductPanel(configData.Settings.Products.Activity.IdTipoProdotto)} className="mybook-order-addproduct border-0 bg-transparent cursor-pointer">
                            <M3IconProductType externalClass={(selectProductType === configData.Settings.Products.Activity.IdTipoProdotto ? " text-primary" : "")} productType="Activity" />
                        </button>
                    </div>
                }
                {
                    isVisibleProduct('RENTCAR') && <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                        <button
                            onClick={_ => showAddProductPanel(configData.Settings.Products.CarRental.IdTipoProdotto)}
                            className="mybook-order-addproduct border-0 bg-transparent cursor-pointer">
                            <M3IconProductType externalClass={(selectProductType === configData.Settings.Products.CarRental.IdTipoProdotto ? " text-primary" : "")} productType="CarRental" />
                        </button>
                    </div>
                }
                {
                    isVisibleProduct('CRUISE') && <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                        <button onClick={_ => showAddProductPanel(configData.Settings.Products.Cruise.IdTipoProdotto)} className="mybook-order-addproduct border-0 bg-transparent cursor-pointer">
                            <M3IconProductType externalClass={(selectProductType === configData.Settings.Products.Cruise.IdTipoProdotto ? " text-primary" : "")} productType="Cruise" />
                        </button>
                    </div>
                }
                {
                    isVisibleProduct('HOTEL') && <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                        <button onClick={_ => showAddProductPanel(configData.Settings.Products.Structure.IdTipoProdotto)} className="mybook-order-addproduct border-0 bg-transparent cursor-pointer">
                            <M3IconProductType externalClass={(selectProductType === configData.Settings.Products.Structure.IdTipoProdotto ? " text-primary" : "")} productType="Structure" />
                        </button>
                    </div>
                }
                {
                    isVisibleProduct('TRANSFER') && <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                        <button onClick={_ => showAddProductPanel(configData.Settings.Products.Transfer.IdTipoProdotto)} className="mybook-order-addproduct border-0 bg-transparent cursor-pointer">
                            <M3IconProductType externalClass={(selectProductType === configData.Settings.Products.Transfer.IdTipoProdotto ? " text-primary" : "")} productType="Transfer" />
                        </button>
                    </div>
                }
                {
                    isVisibleProduct('TOUR') && <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                        <button onClick={_ => showAddProductPanel(configData.Settings.Products.Tour.IdTipoProdotto)} className="mybook-order-addproduct border-0 bg-transparent cursor-pointer">
                            <M3IconProductType externalClass={(selectProductType === configData.Settings.Products.Tour.IdTipoProdotto ? " text-primary" : "")} productType="Tour" />
                        </button>
                    </div>
                }
                {
                    isVisibleProduct('FLIGHT') && <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                        <button onClick={_ => showAddProductPanel(configData.Settings.Products.Flight.IdTipoProdotto)} className="mybook-order-addproduct border-0 bg-transparent cursor-pointer">
                            <M3IconProductType externalClass={(selectProductType === configData.Settings.Products.Flight.IdTipoProdotto ? " text-primary" : "")} productType="Flight" />
                        </button>
                    </div>
                }
                {
                    isVisibleProduct('TRAIN') && <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                        <button onClick={_ => showAddProductPanel(configData.Settings.Products.Train.IdTipoProdotto)} className="mybook-order-addproduct border-0 bg-transparent cursor-pointer">
                            <M3IconProductType externalClass={(selectProductType === configData.Settings.Products.Train.IdTipoProdotto ? " text-primary" : "")} productType="Train" />
                        </button>
                    </div>
                }
            </div>
            {
                selectProductType !== null && <> <div className="row">
                    {
                        selectProductType === configData.Settings.Products.Activity.IdTipoProdotto && <ActivitySearchEngineTrippie
                            stepIndex={stepIndex}
                            searchParameters={activitySearchParam}
                            extraParams={extraParam}
                            typeSearch="SRC"
                            onStartSearch={onStartSearchStep} />
                    }
                    {
                        selectProductType === configData.Settings.Products.CarRental.IdTipoProdotto && <CarSearchEngineTrippie
                            stepIndex={stepIndex}
                            searchParameters={carSearchParam}
                            extraParams={extraParam}
                            typeSearch="SRC"
                            onStartSearch={onStartSearchStep} />
                    }
                    {
                        selectProductType === configData.Settings.Products.Structure.IdTipoProdotto && <StructureSearchEngineTrippie
                            stepIndex={stepIndex}
                            searchParameters={hotelSearchParam}
                            extraParams={extraParam}
                            typeSearch="SRC"
                            onStartSearch={onStartSearchStep} />
                    }
                    {
                        selectProductType === configData.Settings.Products.Tour.IdTipoProdotto && <TourSearchEngineTrippie
                            stepIndex={stepIndex}
                            searchParameters={tourSearchParam}
                            extraParams={extraParam}
                            typeSearch="SRC"
                            onStartSearch={onStartSearchStep} />
                    }
                    {
                        selectProductType === configData.Settings.Products.Transfer.IdTipoProdotto && <TransferSearchEngineTrippie
                            stepIndex={stepIndex}
                            searchParameters={transferSearchParam}
                            extraParams={extraParam}
                            typeSearch="SRC"
                            onStartSearch={onStartSearchStep} />
                    }
                    {
                        selectProductType === configData.Settings.Products.Train.IdTipoProdotto && <TrainSearchEngineTrippie
                            stepIndex={stepIndex}
                            searchParameters={trainSearchParam}
                            extraParams={extraParam}
                            typeSearch="SRC"
                            onStartSearch={onStartSearchStep} />
                    }
                    {
                        selectProductType === configData.Settings.Products.Flight.IdTipoProdotto && <FlightSearchEngineTrippie
                            stepIndex={stepIndex}
                            searchParameters={flightSearchParam}
                            extraParams={extraParam}
                            typeSearch="SRC"
                            onStartSearch={onStartSearchStep} />
                    }
                </div>
                </>
            }

        </div>
    );
}
