import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../../js/Utils.js';

export const DMCPriceBreakDown = ({ priceBreakDownArr, idAccordion }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    return (
        <>
            {
                priceBreakDownArr && <>
                    <div className="accordion accordion-flush" id={"accordionPriceBreakDown"+ idAccordion }>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button collapsed p-1" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapseOne" + idAccordion} aria-expanded="false" aria-controls={"flush-collapseOne"+idAccordion }>
                                    <span className="accordion-header text-center small" id="flush-headingOne">
                                        <data m3lab="Product.Structure.Filter.PriceDet">{t("Product:Structure:Filter:PriceDet")}</data>
                                    </span>
                                </button>
                            </h2>
                            <div id={"flush-collapseOne" + idAccordion} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent={"#accordionPriceBreakDown"+idAccordion }>
                                <div className="accordion-body">
                                    <div className="text-small pr-1 small">
                                        {priceBreakDownArr.map((priceBreakDown, key) =>
                                            <div className="row">
                                                <div className="col-10">
                                                    <var>{priceBreakDown.description && <div dangerouslySetInnerHTML={{ __html: priceBreakDown.description }}></div>}</var>
                                                </div>
                                                <div className="col-2">
                                                    <var>
                                                        {formatPrice(priceBreakDown.priceAdt + priceBreakDown.priceChd + priceBreakDown.priceInf, priceBreakDown.currency, cultureName) }
                                                    </var>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               </>
            }
        </>
    );
}