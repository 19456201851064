import React, { useState, useEffect, useRef } from 'react';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { getFlightType, getFlightDates } from "../../../../js/ProductService";
import { getDateObj } from "../../../../js/Utils.js";

import { M3Icon } from '../../../Common/M3Icon';

export const ListSearchEngineCVMobile = ({ imageUrl, searchParameters, productType, typeSearch, extraParams,  isShowMap = false }) => {
    const { t } = useTranslation();

    const [normalizeSearchParam, setNormalizeSearchParam] = useState({});
    const [extraOptions, setExtraOptions] = useState({ multiDate: false });
    const [multiDestinationName, setMultiDestinationName] = useState(false);

    const [isOnlyDestination, setIsOnlyDestination] = useState(false);

    const [showFilterArea, setShowFilterArea] = useState(false);
    const [sizeInMeters, setSizeInMeters] = useState(5000);
    const [featureFiltered, setFeatureFiltered] = useState([]);

    const [isShowViaggiatori, setIsShowViaggiatori] = useState(true);
    const [isShowMapImage, setIsShowMapImage] = useState(true);


    // usato solo quando si apre la modal, e bisogna resettare i dati com'erano in origine
    const [resetValues, setResetValues] = useState(false);

    //map
    const geoJsonRef = useRef();
    const [mapArr, setMapArr] = useState(null);
    const [map, setMap] = useState(null);

    useEffect(() => {

        let normalizeSearchParam = {};
        console.log(searchParameters)
        if (searchParameters.travelers) {

            normalizeSearchParam["adults"] = searchParameters.travelers.filter((item) => {
                return !item.age
            }).length
            normalizeSearchParam["children"] = searchParameters.travelers.filter((item) => {
                return item.age
            }).length
        }

        if (searchParameters && productType === configData.Settings.Products.Structure.IdTipoProdotto) {
            normalizeSearchParam.destinationName = searchParameters.destinationDesc;

            setMultiDestinationName(false);
            setExtraOptions({ ...extraOptions, multiDate: true });
            normalizeSearchParam.fromPeriod = searchParameters.checkIn;
            normalizeSearchParam.toPeriod = searchParameters.checkOut;

            if (isShowMap && extraParams && extraParams.longitude && extraParams.latitude) {
                setMapArr({
                    type: "FeatureCollection",
                    features: [{
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [extraParams.longitude, extraParams.latitude]
                        },
                        properties: {
                            StructureId: extraParams && extraParams.structureId,
                            StructureName: extraParams && extraParams.productName,
                            StructureCategory: "&lt;i class=&#39;fas fa-star&#39;&gt;&lt;/i&gt;&lt;i class=&#39;fas fa-star&#39;&gt;&lt;/i&gt;",
                            StructureThumbnail: configData.Settings.CDN_Custom + "/images/noimage/no-image-hotel.jpg",
                            BestPriceAmount: null,
                            BestPriceCurrency: null,
                            BestMealplan: null
                        }
                    }]
                });
            }
        }

        if (searchParameters && productType === configData.Settings.Products.Activity.IdTipoProdotto) {
            normalizeSearchParam.destinationName = searchParameters.destinationDesc;

            setMultiDestinationName(false);
            setExtraOptions({ ...extraOptions, multiDate: true });
            normalizeSearchParam.fromPeriod = searchParameters.dateFrom;
            normalizeSearchParam.toPeriod = searchParameters.dateTo;

            if (isShowMap && extraParams && extraParams.longitude && extraParams.latitude) {
                setMapArr({
                    type: "FeatureCollection",
                    features: [{
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [extraParams.longitude, extraParams.latitude]
                        },
                        properties: {
                            StructureId: extraParams && extraParams.activityId,
                            StructureName: extraParams && extraParams.productName,
                            StructureCategory: "&lt;i class=&#39;fas fa-star&#39;&gt;&lt;/i&gt;&lt;i class=&#39;fas fa-star&#39;&gt;&lt;/i&gt;",
                            StructureThumbnail: configData.Settings.CDN_Custom + "/images/noimage/no-image-hotel.jpg",
                            BestPriceAmount: null,
                            BestPriceCurrency: null,
                            BestMealplan: null
                        }
                    }]
                });
            }
        }

        if (searchParameters && productType === configData.Settings.Products.Flight.IdTipoProdotto) {
            let flightType = getFlightType(searchParameters);
            let flightDates = getFlightDates(searchParameters);

            let departureLocation = searchParameters.legsCriteria[0].departureLocation;
            let arrivalLocation = searchParameters.legsCriteria[0].arrivalLocation;

            if (flightType === 3) {
                let lastIndex = searchParameters.legsCriteria.length;
                arrivalLocation = searchParameters.legsCriteria[lastIndex - 1].arrivalLocation;
            }

            if (!departureLocation.name) {
                let depName = `${departureLocation.code} > ${arrivalLocation.code}`;
                normalizeSearchParam.destinationName = [depName];
            } else {
                normalizeSearchParam.destinationName = [`${departureLocation.code} - ${departureLocation.name}`, `${arrivalLocation.code} - ${arrivalLocation.name}`];
            }

            if (flightDates) {
                normalizeSearchParam.fromPeriod = flightDates[0].dateFrom;
                if (flightType === 2)
                    normalizeSearchParam.toPeriod = flightDates[0].dateTo;

            } else {
                normalizeSearchParam.fromPeriod = null;
                normalizeSearchParam.toPeriod = null;
            }

            if (flightType === 2) {
                setExtraOptions({ ...extraOptions, multiDate: true });
            } else {
                setExtraOptions({ ...extraOptions, multiDate: false });
            }

            setMultiDestinationName(true);
        }

        if (searchParameters && productType === configData.Settings.Products.Train.IdTipoProdotto) {
            let outboundLegCriteria = searchParameters.outboundLegCriteria;
            let returnLegCriteria = searchParameters.returnLegCriteria;

            normalizeSearchParam.destinationName = [outboundLegCriteria.departureLocation.stationDesc, outboundLegCriteria.arrivalLocation.stationDesc];

            setMultiDestinationName(true);

            normalizeSearchParam.fromPeriod = outboundLegCriteria.departureDate;
            normalizeSearchParam.toPeriod = null;

            normalizeSearchParam.timeFrom = outboundLegCriteria.departureTimeSlot.fromTime;
            normalizeSearchParam.timeTo = null;

            if (returnLegCriteria) {
                normalizeSearchParam.toPeriod = returnLegCriteria.departureDate;
                normalizeSearchParam.timeTo = returnLegCriteria.departureTimeSlot.fromTime;

                setExtraOptions({ ...extraOptions, multiDate: true });
            } else {
                setExtraOptions({ ...extraOptions, multiDate: false });
            }
        }

        if (searchParameters && productType === configData.Settings.Products.CarRental.IdTipoProdotto) {
            setIsShowViaggiatori(false);
            setIsShowMapImage(false);
            setIsOnlyDestination(true);

            if (searchParameters.dropOffSameAsPickup) {
                normalizeSearchParam.destinationName = [searchParameters.pickupDestinationName, searchParameters.pickupDestinationName];
            }
            else {
                normalizeSearchParam.destinationName = [searchParameters.pickupDestinationName, searchParameters.dropoffDestinationName];
            }

            normalizeSearchParam.fromPeriod = searchParameters.pickupDate;
            normalizeSearchParam.toPeriod = searchParameters.dropoffDate;

            normalizeSearchParam.timeFrom = new Date(searchParameters.pickupDate).toTimeString().split(' ')[0].slice(0, -3);
            normalizeSearchParam.timeTo = new Date(searchParameters.dropoffDate).toTimeString().split(' ')[0].slice(0, -3);
        }

        if (searchParameters && productType === configData.Settings.Products.Transfer.IdTipoProdotto) {
            setIsShowViaggiatori(true);
            setIsShowMapImage(false);
            setIsOnlyDestination(true);

            normalizeSearchParam.destinationName = [searchParameters.pickupPoint.name, searchParameters.dropoffPoint.name];

            normalizeSearchParam.fromPeriod = searchParameters.pickupDate;
            normalizeSearchParam.toPeriod = searchParameters.returnPickUpDate;
        }

        if (searchParameters && productType === configData.Settings.Products.Tour.IdTipoProdotto) {
            normalizeSearchParam.destinationName = searchParameters.destinationDesc;

            setMultiDestinationName(false);
            setExtraOptions({ ...extraOptions, multiDate: true });
            let updateCalendar = searchParameters.dates.map(x => getDateObj(x))[0];
            normalizeSearchParam.selectMonths = updateCalendar;

            /*if (isShowMap) {
                setMapArr({
                    type: "FeatureCollection",
                    features: [{
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [extraParams.longitude, extraParams.latitude]
                        },
                        properties: {
                            StructureId: extraParams && extraParams.tourId,
                            StructureName: extraParams && extraParams.productName,
                            StructureCategory: "&lt;i class=&#39;fas fa-star&#39;&gt;&lt;/i&gt;&lt;i class=&#39;fas fa-star&#39;&gt;&lt;/i&gt;",
                            StructureThumbnail:  configData.Settings.CDN_Custom + "/images/noimage/no-image-hotel.jpg",
                            BestPriceAmount: null,
                            BestPriceCurrency: null,
                            BestMealplan: null
                        }
                    }]
                });
            }*/
        }

        if (searchParameters && productType === configData.Settings.Products.Cruise.IdTipoProdotto) {
            normalizeSearchParam.destinationName = searchParameters.destinationDesc;

            if (!normalizeSearchParam.destinationName) {
                try {
                    let portName = searchParameters.portNames && searchParameters.portNames.length > 0 ? searchParameters.portNames[0] : '';
                    normalizeSearchParam.destinationName = portName;
                } catch (ex) { }
            }

            setMultiDestinationName(false);
            setExtraOptions({ ...extraOptions, multiDate: true });

            normalizeSearchParam.fromPeriod = searchParameters.dateFrom;
            normalizeSearchParam.toPeriod = searchParameters.dateTo;

        }

        setNormalizeSearchParam(normalizeSearchParam);
        console.log(normalizeSearchParam)
    }, []);
    useEffect(() => {
        setTimeout(() => {

            console.log(normalizeSearchParam)
        }, 2000)

    }, [])
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    return (
        <>
            <div className="tp-btn-search bg-custom rounded shadow d-block d-sm-none" data-bs-toggle="modal" data-bs-target="#EngineModifyActivty">
                <div className=" row mb-3 mx-1">
                    <div className="col-12 col-sm-12 p-1">
                        <div className="text-left p-2">
                            <div className=''>

                                {!multiDestinationName && !(extraParams && extraParams.productName) && normalizeSearchParam.destinationName}
                                {
                                    multiDestinationName && !(extraParams && extraParams.productName) && normalizeSearchParam.destinationName && normalizeSearchParam.destinationName.map((destName, index) => {
                                        return <div className="d-flex align-items-center" style={{ gap: "0.4rem" }} key={index}><M3Icon iconName="Ray" />{destName}</div>;
                                    })
                                }
                                {(extraParams && extraParams.productName) && extraParams.productName}
                            </div>
                            <div>
                                {normalizeSearchParam.adults && <div className="d-flex mt-2 mb-2" style={{ gap: "1rem" }}>
                                    <div className="d-flex align-items-center" style={{ gap: "0.2rem" }}>
                                        <M3Icon iconName="PaxAdl" />
                                        <p style={{ marginBottom: 0 }}>
                                            {t('SearchEngine:Adults')}: {normalizeSearchParam.adults}
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center" style={{ gap: "0.2rem" }}>
                                        <M3Icon iconName="PaxChd" />
                                        <p style={{ marginBottom: 0 }}>
                                            {t('SearchEngine:Children')}: {normalizeSearchParam.children}
                                        </p>
                                    </div>

                                </div>}
                                {normalizeSearchParam.fromPeriod && <div className="d-flex" style={{ gap: "1rem" }}>
                                    <div className="d-flex align-items-center" style={{ gap: "0.2rem" }}>
                                        <M3Icon iconName="CalendarMonth" />
                                        <p style={{ marginBottom: 0 }}>
                                            {formatDate(normalizeSearchParam.fromPeriod)}
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center" style={{ gap: "0.2rem" }}>
                                        <M3Icon iconName="CalendarMonth" />
                                        <p style={{ marginBottom: 0 }}>
                                            {formatDate(normalizeSearchParam.toPeriod)}
                                        </p>
                                    </div>

                                </div>}
                                {normalizeSearchParam.selectMonths && <div className="d-flex" style={{ gap: "1rem" }}>
                                    <div className="d-flex align-items-center" style={{ gap: "0.2rem" }}>
                                        <M3Icon iconName="CalendarMonth" />
                                        <p style={{ marginBottom: 0 }}>
                                            {formatDate(normalizeSearchParam.selectMonths)}
                                        </p>
                                    </div>
                                </div>}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
