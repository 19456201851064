import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";
import { ProductFilterByTime } from "./ProductFilterByTime";

export const ProductFilter = ({ items, label, type, iconTitle, onApplyFilter, resetSelectedItem, subFilterOptions, defaultSelectIds, defaultShowFilter = true }) => {
    const { t } = useTranslation();
    const [selectedItems, setSelectedItems] = useState([]);
    const [showFilter, setShowFilter] = useState(defaultShowFilter);

    // subItems state
    const rangeTimeInfo = {
        min: '00:00',
        max: '23:59',
        selectMin: '00:00',
        selectMax: '23:59',
        step: 15
    };
    const [defaultRangeTimeInfo, setDefaultRangeTimeInfo] = useState([]);

    useEffect(() => {
        if (defaultSelectIds && defaultSelectIds.length > 0) {
            let arrTmp = [];

            defaultSelectIds.map(x => {
                arrTmp.push({ id: x })
            });

            setSelectedItems(arrTmp);
        }
    }, []);

    useEffect(() => {
        if ((type === 'FlightStops' || type === 'TrainStops') && subFilterOptions && subFilterOptions.subItems && defaultRangeTimeInfo.length === 0) {
            let updDefaultRangeTimeInfo = [];
            for (let i = 0; i < subFilterOptions.subItems.length; i++) {
                updDefaultRangeTimeInfo.push({
                    legCriteria: subFilterOptions.subItems[i],
                    stopDuration: rangeTimeInfo
                });
            }

            setDefaultRangeTimeInfo(updDefaultRangeTimeInfo);
        }
    }, [subFilterOptions])

    useEffect(() => {
        if (resetSelectedItem) {
            if ((type === 'FlightStops' || type === 'TrainStops') && subFilterOptions && subFilterOptions.subItems) {
                let updDefaultRangeTimeInfo = [];
                for (let i = 0; i < subFilterOptions.subItems.length; i++) {
                    updDefaultRangeTimeInfo.push({
                        legCriteria: subFilterOptions.subItems[i],
                        stopDuration: rangeTimeInfo
                    });
                }

                setDefaultRangeTimeInfo(updDefaultRangeTimeInfo);
            }

            setSelectedItems([]);
        }
    }, [resetSelectedItem]);

    function onCallApplyFilter(item) {
        let updateSelectedItems = [...selectedItems];

        try {
            delete item.$type;
        } catch (ex) { }

        let isSelect = updateSelectedItems.some(x => x.id === item.id);
        if (isSelect)
            updateSelectedItems = updateSelectedItems.filter(x => x.id !== item.id);
        else
            updateSelectedItems.push(item);

        setSelectedItems(updateSelectedItems);
        onApplyFilter(updateSelectedItems, type);
    }

    function onShowFilter(event) {
        event.preventDefault();
        setShowFilter(!showFilter);
    }

    function getLabel(name, id) {
        // structure 
        if (type === 'StructureStars')
            return parseInt(name) + " " + t('Product:Structure:Filter:Star:Title');

        //structure
        if (type === 'BestRoomTypes')
            return t(`Product:Structure:Filter:Generic:${name.replace(' ', '')}`);

        //flight
        if (type === 'BestFlightTypes')
            return t(`Product:Flight:Filter:Generic:${name.replace(' ', '')}`);

        // flight 
        if (type === 'FlightStops' && name === "FLIGHT-STOP-0") {
            return t('Product:Flight:Direct');
        }
        if (type === 'FlightStops' && name === "FLIGHT-STOP-1") {
            return "1 " + t('Product:Flight:Stopover');
        }
        if (type === 'FlightStops' && name === "FLIGHT-STOP-2") {
            return "2 " + t('Product:Flight:Stopovers');
        }

        // train 
        if (type === 'TrainStops' && name === "TRAIN-STOP-0") {
            return t('Product:Train:Direct');
        }
        if (type === 'TrainStops' && name === "TRAIN-STOP-1") {
            return "1 " + t('Product:Train:Stopover');
        }
        if (type === 'TrainStops' && name === "TRAIN-STOP-2") {
            return "2 " + t('Product:Train:Stopovers');
        }

        // car equipment 
        if (type === 'CarOnBoardEquipment' && id === "TX_M") {
            return t('Product:RentCar:Filter:ManualTrasmission');
        }
        if (type === 'CarOnBoardEquipment' && id === "TX_A") {
            return t('Product:RentCar:Filter:AutomaticTrasmission');
        }
        if (type === 'CarOnBoardEquipment' && id === "AC_Y" && name.toLowerCase() === "yes") {
            return t('Product:RentCar:Filter:AirConditionsYes');
        }
        if (type === 'CarOnBoardEquipment' && id === "AC_N" && name.toLowerCase() === "no") {
            return t('Product:RentCar:Filter:AirConditionsNo');
        }
        if (type === 'CarOnBoardEquipment' && id.includes("DO_")) {
            return t('Product:RentCar:Filter:Drive') + name;
        }
        if (type === 'CarOnBoardEquipment' && id.includes("FO_")) {
            return t('Product:RentCar:Filter:Fuel') + name;
        }

        // tour
        if (type === 'TourDuration') {
            return name + " " + t('Product:Tour:Filter:Nights');
        }

        return name;
    }

    // subItems methods
    function onApplyFilterTime(value, keyId) {
        let selectedItems = [];
        let updateRangeTimeInfo = [...defaultRangeTimeInfo];

        let keyParts = keyId.split('_');
        //let typeFlt = keyParts[0];
        let index = parseInt(keyParts[1]);

        for (let i = 0; i < updateRangeTimeInfo.length; i++) {
            let item = {};

            if (index !== i) {
                item.minStopDuration = updateRangeTimeInfo[i].stopDuration.selectMin;
                item.maxStopDuration = updateRangeTimeInfo[i].stopDuration.selectMax;
            } else {

                updateRangeTimeInfo[index].stopDuration.selectMin = value.min;
                updateRangeTimeInfo[index].stopDuration.selectMax = value.max;

                item.minStopDuration = value.min;
                item.maxStopDuration = value.max;
            }

            selectedItems.push(item);
        }

        setDefaultRangeTimeInfo(updateRangeTimeInfo);
        subFilterOptions.onApplyFilter(selectedItems, type);
    }

    return (
        <>
            {
                items && items.length > 0 && <div className="card bg-white rounded active my-1 text-primary h6 shadow">
                    <a href="#" className="text-decoration-none " onClick={(e) => onShowFilter(e)}>
                        <div className="row">
                            <div className="col-10 pl-4 pt-2">
                                <span className="p-2 text-left text-blue">
                                    {iconTitle && <span className="material-icons icon-10">{iconTitle}</span>}
                                    <data m3lab={label}>
                                        {t(label)}
                                        {
                                            selectedItems && selectedItems.length > 0 && <var>({selectedItems.length})</var>
                                        }
                                    </data>
                                </span>
                            </div>
                            <div className="col-1 text-left p-1">
                                <data m3ico="Remove text-blue">
                                    {showFilter && <M3Icon iconName="Remove" externalClass="text-blue" hasLabel={false} />}
                                    {!showFilter && <M3Icon iconName="Add" externalClass="text-blue" hasLabel={false} />}
                                </data>
                            </div>
                        </div>
                    </a>
                    {
                        showFilter && <ul className="list-group px-1">
                            {
                                items.map((item, key) =>
                                    key < 10 &&
                                    <li key={key} className="list-group-item p-0 m-1 border-0 ">
                                        <input id={`fltFilter_${type}_${item.id}`} type="checkbox"
                                            value={selectedItems.some(x => x.id === item.id)}
                                            checked={selectedItems.some(x => x.id === item.id)}
                                            onChange={e => onCallApplyFilter(item)} />
                                        <var><label htmlFor={`fltFilter_${type}_${item.id}`} className="px-2">{getLabel(item.name, item.id)}</label></var>
                                    </li>
                                )
                            }
                            <div className="collapse multi-collapse" id="inclusionCollapse">
                                {items.length > 10 &&
                                    items.map((item, key) =>
                                        key >= 10 &&
                                        <li key={key} className="list-group-item p-0 m-1 border-0 ">
                                            <input id={`fltFilter_${type}_${item.id}`} type="checkbox"
                                                value={selectedItems.some(x => x.id === item.id)}
                                                checked={selectedItems.some(x => x.id === item.id)}
                                                onChange={e => onCallApplyFilter(item)} />
                                            <var><label htmlFor={`fltFilter_${type}_${item.id}`} className="px-2">{getLabel(item.name, item.id)}</label></var>
                                        </li>
                                    )
                                }
                            </div>
                            {items.length > 10 &&
                                <div className="text-center mt-2 mb-2">
                                    <label data-bs-toggle="collapse" href="#inclusionCollapse" role="button" aria-expanded="false" aria-controls="inclusionCollapse">
                                        {t('General:More')}
                                    </label>
                                </div>
                            }

                            {
                                (type === 'FlightStops' || type === 'TrainStops') && defaultRangeTimeInfo && defaultRangeTimeInfo.map((subItem, key) => {
                                    return <li className="list-group-item p-0 m-1 border-0 ">
                                        <label className="mb-0 pl-2">
                                            {defaultRangeTimeInfo.length === 2 && key === 0 && <data m3lab='Product:Flight:Going'>{t('Product:Flight:Going')}</data>}
                                            {defaultRangeTimeInfo.length === 2 && key === 1 && <data m3lab='Product:Flight:Return'>{t('Product:Flight:Return')}</data>}
                                            {type === 'FlightStops' && defaultRangeTimeInfo.length !== 2 && <>{subItem.legCriteria.departureLocation.code} - {subItem.legCriteria.arrivalLocation.code}</>}
                                            :{t('Product:Flight:Filter:StopDuration')}
                                        </label>
                                        {
                                            <ProductFilterByTime
                                                rangeTimeInfo={defaultRangeTimeInfo[key].stopDuration}
                                                onApplyFilter={onApplyFilterTime}
                                                resetSelectedItem={resetSelectedItem}
                                                type={"D_" + key}
                                                extraOptions={{ isSubFilter: true }} />
                                        }
                                    </li>
                                })
                            }
                        </ul>
                    }
                </div>
            }
        </>
    );
}