import React from 'react';
import { useTranslation } from 'react-i18next';
import { M3Icon } from '../../../Common/M3Icon'
import { Login } from "../../../Auth/Login"
import configData from "../../../../appsettings.json";

export const HeaderLogin = ({ item, modal }) => {
    const { t } = useTranslation();

    function getClassName(className) {
        if (!className)
            return '';

        try {
            return className.split("#")[1];
        } catch (ex) { }

        return '';
    }
    function goTo(link) {
        window.location.href = configData.Settings.Base_Url + link;
    }

    return (
        <>
            <a data-bs-toggle="modal" data-bs-target="#loginModal" className={getClassName(item.className) + ' ' + 'd-none d-sm-block cursor-pointer open-login-modal'} aria-current="page">{t('Header:Login')}</a>
            <div className="modal fade" data-backdrop="false" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-5 pt-0">
                            <Login />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
