import React from 'react';
import { formatDateTime, createRenderHtml } from '../../../js/Utils.js';

export const QuotationLogs = (props) => {

    function onClickLoadAllLogs() {
        props.callGetAllLogs();
    }

    return (
        <div className="section section-md bg-white text-black pt-0 line-bottom-light" id="quotLog">

            <div className="container mt-3">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8">
                        <div>
                            <h4>Logs:</h4>
                            {
                                props.quotationLogs !== null & props.quotationLogs !== undefined & props.quotationLogs.length > 0
                                    ? props.quotationLogs.map((qLog) => {
                                        return <div key={`quot_log_${qLog.id}`} className="mt-5">
                                            <div className="card border-light rounded p-4 mb-4">
                                                <div className="d-flex justify-content-between mb-4">
                                                    <span className="font-small">
                                                        <span className="font-weight-bold">{qLog.name}</span>
                                                        <span className="ml-2">{formatDateTime(qLog.created, props.cultureName, { withTime: true, noYear: true })}</span>
                                                        {qLog.quotationItemId && <span className="font-weight-bold"> - Prodotto N. {qLog.quotationItemId}</span> }
                                                    </span>
                                                </div>
                                                <p className="m-0" dangerouslySetInnerHTML={createRenderHtml(qLog.note)}></p>
                                            </div>
                                        </div>;
                                    })
                                    : <p>Nessuna nota interna inserita</p>
                            }

                            <div className="mt-5 text-center">
                                {
                                    props.quotationLogs !== null & props.quotationLogs !== undefined & props.quotationLogs.length < props.totalLogs
                                        ? <button id="loadOnClickqLogs" className="btn btn-primary btn-loading-overlay mr-2 mb-2" onClick={onClickLoadAllLogs}>
                                            <span className="ml-1 btn-inner-text">Load more logs</span>
                                        </button>
                                        :
                                        <p id="allLoadedLogs">
                                            That's all!
                                        </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}