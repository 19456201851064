import React, { useState, useEffect } from 'react';
import { M3Icon } from './M3Icon';
import { useTranslation } from 'react-i18next';

export const M3Pax = ({ adt, chd, chdAges, inf, infAges, extraOptions }) => {
    const { t } = useTranslation();

    const [iconClass, setIconClass] = useState("icon-15");

    useEffect(() => {
        if (!extraOptions) {
            setIconClass("icon-15");
        } else if (extraOptions && extraOptions.iconClass) {
            setIconClass(extraOptions.iconClass);
        } else {
            setIconClass("");
        }

    }, [extraOptions])

    return (
        <>
            <div className="row no-gutters text-gray-600 small align-text-bottom">
                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 px-0 text-right">
                    <var>{adt}</var>
                </div>
                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-0">
                    <data m3ico="PaxAdl"> <M3Icon iconName="PaxAdl" externalClass={iconClass} /></data>
                </div>
                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 px-0 text-right">
                    <var>{chd}</var>

                </div>
                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-0">
                    <data m3ico="PaxChd"> <M3Icon iconName="PaxChd" externalClass={iconClass} /></data>
                </div>
                {
                    (extraOptions && extraOptions.showInfant) && <>
                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 px-0 text-right">
                            <var>{inf && inf.length > 0 ? inf[0] : 0}</var>
                        </div>
                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-0">
                            <data m3ico="PaxInf"> <M3Icon iconName="PaxInf" externalClass={iconClass} /></data>
                        </div>
                    </>
                }
                {
                    chd > 0 && chdAges && chdAges.length > 0 ? <>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            ({t('SearchEngine:Age')} CHD:
                            {
                                chdAges.map((age, index) => {
                                    return age + (index === (chdAges.length - 1) ? "" : ",");
                                })
                            }
                            )
                        </div>
                    </> : <></>
                }{
                    (extraOptions && extraOptions.showInfant) && <>
                        {
                            infAges && infAges.length > 0 ? <>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    ({t('SearchEngine:Age')} INF:
                                    {
                                        infAges.map((age, index) => {
                                            return age + (index === (infAges.length - 1) ? "" : ",");
                                        })
                                    }
                                    )
                                </div>
                            </> : <></>
                        }
                    </>
                }
            </div>
        </>
    );
}
