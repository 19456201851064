import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../js/Utils.js';
import { getADTTotal, getCHDTotal } from '../../../js/PrintUtility.js';
import { IconChoosePrint } from './IconChoosePrint.js';

export const PrintCommissionsDetailCompact = ({ marginItems, taxesFlightArr, isPrintCliente, paxes, onHandleIsPrint, isConfirmPrint = true, extraOptions }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    if (!extraOptions)
        extraOptions = {};

    if (!extraOptions.marginTop)
        extraOptions.marginTop = "mt-3"

    let totals = {
        nettoTotal: 0,
        quotaTotal: 0,
        commTotal: 0,
        currency: null
    }

    var genericArr = [];

    // commissionabili
    var groupedArrTmp = [];
    var taxesFlightQuota = 0;
    var taxesFlightCommission = 0;
    var taxesFlightNet = 0;
    var taxesFlightCurrency = "";

    function invoicePrice(item) {
        if (isConfirmPrint) {
            return item.pricing ? item.pricing.invoicePrice : item.invoicePrice;
        }
        else {
            return item.sellAmount;
        }
    }

    function commissioneAgenzia(item) {
        if (isConfirmPrint) {
            return item.pricing ? item.pricing.commission : item.commissioneAgenzia;
        }
        else {
            return item.commissionableAmount;
        }
    }

    function agencyFee(item) {
        if (isConfirmPrint) {
            return item.pricing ? item.pricing.agencyFee : item.agencyFee;
        }
        else {
            return 0;
        }
    }

    function currency(item) {
        if (isConfirmPrint) {
            return item.pricing ? item.pricing.valutaCliente : item.currency;
        }
        else {
            return item.sellCurrency;
        }
    }

    function checkIfExists() {
        if (marginItems.filter(x => ((x.isSelected || x.isActive) && x.productType === "Generic") || x.productType !== "Generic").length > 0)
            return true;
        return false;
    }

    function setTotals() {
        genericArr.forEach(function (item) {
            totals.nettoTotal = totals.nettoTotal + getNetto(item);
            totals.quotaTotal = totals.quotaTotal + getQuotaTotale(item);
            totals.commTotal = totals.commTotal + getCommissioneTotale(item);
            totals.currency = currency(item);
        });

        groupedArrTmp.forEach(function (item) {
            totals.nettoTotal += item.netto;
            totals.quotaTotal += item.quota;
            totals.commTotal += item.commissione;
            totals.currency = currency(item);
        });

        totals.quotaTotal += taxesFlightQuota;
        totals.nettoTotal += taxesFlightNet;

        marginItems.filter(x => (((x.isSelected || x.isActive) && x.productType === "Generic") || x.productType !== "Generic") && x.status === "PENALTY").map((item, index) => {
            totals.nettoTotal = totals.nettoTotal + getNetto(item);
            totals.quotaTotal = totals.quotaTotal + getQuotaTotale(item);
            totals.commTotal = totals.commTotal + getCommissioneTotale(item);
        });


        if (!totals.currency)
            totals.currency = "EUR";
    }

    function getNetto(item) {
        let price = 0;
        if (item.isCommissionable) {
            price = (invoicePrice(item) - commissioneAgenzia(item));
        }
        else {
            price = invoicePrice(item);
        }
        return price;
    }

    function getQuotaTotale(item) {
        let price = 0;
        price = invoicePrice(item);

        return price;
    }

    function getCommissioneTotale(item) {
        let price = 0;
        if (item.isCommissionable) {
            price = commissioneAgenzia(item) + agencyFee(item);
        }

        return price;
    }


    if (marginItems && marginItems.length > 0 && checkIfExists()) {
        marginItems.filter(x => (((x.isSelected || x.isActive) && x.productType === "Generic") || x.productType !== "Generic") && x.status !== "PENALTY").map((item, idx) => {
            if (item.productType !== "Generic") {
                let res = "";

                if (item.productType === "Flight") {
                    res = isNaN(Math.round(((commissioneAgenzia(item) + agencyFee(item)) * 100) / ((invoicePrice(item) - item.taxAmount)))) ? 0 : Math.round(((commissioneAgenzia(item) + agencyFee(item)) * 100) / (invoicePrice(item) - item.taxAmount));
                }
                else {
                    res = isNaN(Math.round(((commissioneAgenzia(item) + agencyFee(item)) * 100) / invoicePrice(item))) ? 0 : Math.round(((commissioneAgenzia(item) + agencyFee(item)) * 100) / invoicePrice(item));
                }

                let indexCommGroup = groupedArrTmp.findIndex(object => {
                    return object.id === res;
                });

                if (indexCommGroup !== -1) {
                    groupedArrTmp[indexCommGroup].quota += invoicePrice(item);
                    groupedArrTmp[indexCommGroup].netto += invoicePrice(item) - commissioneAgenzia(item);
                    groupedArrTmp[indexCommGroup].commissione += commissioneAgenzia(item) + agencyFee(item);
                }
                else {
                    groupedArrTmp.push({
                        id: res,
                        productName: (res === 0 ? t(`Template:QuoteNotCommissionable`) + " (" + res + "%)" : t(`Template:QuoteCommissionable`) + " " + res + "%"),
                        quota: invoicePrice(item),
                        netto: invoicePrice(item) - commissioneAgenzia(item),
                        commissione: commissioneAgenzia(item) + agencyFee(item),
                        isCommissionable: true,
                        currency: currency(item),
                        peso: res === 0 ? 999 : res,
                    });
                };
                if ((item.tipoPratica === "F" || item.productType === "Flight")) { // salva le tasse aeroportuali
                    if (item.taxAmount && item.taxAmount > 0) {
                        taxesFlightQuota += item.taxAmount;
                        taxesFlightCommission += 0;
                        taxesFlightNet += item.taxAmount;
                        taxesFlightCurrency = item.taxCurrency ? item.taxCurrency : item.currency;

                        if (indexCommGroup !== -1) {
                            groupedArrTmp[indexCommGroup].quota = groupedArrTmp[indexCommGroup].quota - item.taxAmount;
                            groupedArrTmp[indexCommGroup].netto = groupedArrTmp[indexCommGroup].netto - item.taxAmount;
                        }
                        else {
                            groupedArrTmp[groupedArrTmp.length - 1].quota = groupedArrTmp[groupedArrTmp.length - 1].quota - item.taxAmount;
                            groupedArrTmp[groupedArrTmp.length - 1].netto = groupedArrTmp[groupedArrTmp.length - 1].netto - item.taxAmount;
                        }
                    }
                    else {
                        if (taxesFlightArr && taxesFlightArr.length > 0) {
                            let taxesflight = taxesFlightArr.find(x => x.wsBookingId === item.wsBookingId);
                            taxesFlightQuota += taxesflight.taxesPrice;
                            taxesFlightCommission += 0;
                            taxesFlightNet += taxesflight.taxesPrice;
                            taxesFlightCurrency = taxesflight.currency;

                            if (indexCommGroup !== -1) {
                                groupedArrTmp[indexCommGroup].quota = groupedArrTmp[indexCommGroup].quota - taxesflight.taxesPrice;
                                groupedArrTmp[indexCommGroup].netto = groupedArrTmp[indexCommGroup].netto - taxesflight.taxesPrice;
                            }
                            else {
                                groupedArrTmp[groupedArrTmp.length - 1].quota = groupedArrTmp[groupedArrTmp.length - 1].quota - taxesflight.taxesPrice;
                                groupedArrTmp[groupedArrTmp.length - 1].netto = groupedArrTmp[groupedArrTmp.length - 1].netto - taxesflight.taxesPrice;
                            }
                        }
                    }
                }
            }
            else {
                genericArr.push(item);
            }
        });

        if (groupedArrTmp.length > 0) {
            groupedArrTmp.sort(function (a, b) {
                return new Date(a.peso) - new Date(b.peso);
            });
        }
    }

    if ((genericArr && genericArr.length > 0) || (groupedArrTmp && groupedArrTmp.length > 0)) {
        setTotals();
    }


    return (
        <>
            {(genericArr || groupedArrTmp) && !isPrintCliente &&
                <>
                    <div className={`rounded-top ${extraOptions.marginTop} text-white stepHeader px-1`}>
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <td className="pl-2" style={{ marginLeft: "5px" }}>
                                        <span className="h5 text-white float-start">
                                            {onHandleIsPrint && <IconChoosePrint handleIsPrint={onHandleIsPrint} />}
                                            {t(`Template:TablePrice`)}
                                        </span>
                                    </td>
                                    <td>
                                        <tr className="row mr-2 no-gutters text-white small align-text-bottom">
                                            <td className="col-2 col-sm-2 col-md-7 col-lg-7 col-xl-7 px-1 text-right">
                                                {getADTTotal(paxes, isConfirmPrint)}
                                            </td>
                                            <td className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-0 text-right">
                                                {t(`Template:Adults`)}
                                            </td>
                                            <td className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 px-1 text-right">
                                                {getCHDTotal(paxes, isConfirmPrint)}
                                            </td>
                                            <td className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-0 text-right">
                                                {t(`Template:Children`)}
                                            </td>
                                        </tr>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </div>

                    <div className="p-2 border shadow">
                        <table className=" w-100">
                            <thead className="h6 ">
                                <tr className="text-right h6 customBackground">
                                    <td className="text-left">{t(`Template:Service`)}</td>
                                    <td className="text-right w-20">{t(`Template:Quote`)}</td>
                                    <td className="text-right w-20">{t(`Template:Commission`)}</td>
                                    <td className="text-right w-20">{t(`Template:Tot\tNetToPay`)}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {groupedArrTmp && groupedArrTmp.length > 0 &&
                                    groupedArrTmp.map((item, index) =>
                                        <>
                                            <tr key={index} className="text-right border-bottom" style={{ borderBottom: '1px solid #d0cece' }}>
                                                <td className="text-left">
                                                    {item.productName}
                                                </td>
                                                <td className="text-right w-20">
                                                    {formatPrice(item.quota, item.currency, cultureName)}
                                                </td>
                                                <td className="text-right w-20">
                                                    {item.commissione === 0
                                                        ? 0
                                                        : "- " + formatPrice((item.commissione), item.currency, cultureName)
                                                    }
                                                </td>
                                                <td className="text-right w-20" >
                                                    {formatPrice(item.netto, item.currency, cultureName)}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }
                                {genericArr && genericArr.length > 0 &&
                                    genericArr.map((item, index) =>
                                        <>
                                            {item.isCommissionable &&
                                                <tr key={index} className="text-right border-bottom" style={{ borderBottom: '1px solid #d0cece' }}>
                                                    <td className="text-left">
                                                        {item.productName}
                                                    </td>
                                                    <td className="text-right w-20">
                                                        {formatPrice(invoicePrice(item), currency(item), cultureName)}
                                                    </td>
                                                    <td className="text-right w-20">
                                                        {commissioneAgenzia(item) + agencyFee(item) === 0
                                                            ? 0
                                                            : "- " + formatPrice((commissioneAgenzia(item) + agencyFee(item)), currency(item), cultureName)
                                                        }
                                                        {
                                                            parseFloat(item.totalIVACee) > 0 ? <>(* {item.totalIVACee})</> : ""
                                                        }
                                                    </td>
                                                    <td className="text-right w-20" >
                                                        {formatPrice(invoicePrice(item) - (commissioneAgenzia(item) + agencyFee(item)), currency(item), cultureName)}
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                    )
                                }
                                {parseInt(taxesFlightQuota) > 0 &&
                                    <>
                                        <tr className="text-right">
                                            <td className="text-left">
                                                {t(`Product:Flight:AirportTaxes`)}
                                            </td>
                                            <td className="text-right w-20">
                                                {formatPrice(taxesFlightQuota, taxesFlightCurrency, cultureName)}
                                            </td>
                                            <td className="text-right w-20">
                                                0
                                            </td>
                                            <td className="text-right w-20">
                                                {formatPrice(taxesFlightNet, taxesFlightCurrency, cultureName)}
                                            </td>
                                        </tr>
                                    </>
                                }
                                {genericArr && genericArr.map((item, index) =>
                                    <>
                                        {!item.isCommissionable &&
                                            <tr key={index} className="text-right">
                                                <td className="text-left">
                                                    {item.productName}
                                                </td>
                                                <td className="text-right w-20">
                                                    {formatPrice(invoicePrice(item), currency(item), cultureName)}
                                                </td>
                                                <td className="text-right w-20">
                                                    0
                                                </td>
                                                <td className="text-right w-20">
                                                    {formatPrice(invoicePrice(item), currency(item), cultureName)}
                                                </td>
                                            </tr>
                                        }
                                    </>
                                )}
                                {/* cancellate con penale */}
                                {marginItems && marginItems.filter(x => (((x.isSelected || x.isActive) && x.productType === "Generic") || x.productType !== "Generic") && x.status === "PENALTY").map((item, index) =>
                                    <>
                                        {index === 0 &&
                                            <tr className="border-top bg-gray-200">
                                                <td className="text-left text-danger fontBold">
                                                    Cancellati con Penale
                                                </td>
                                            </tr>
                                        }
                                        <tr key={index} className="text-right bg-gray-200">
                                            <td className="text-left">
                                                {item.productName}
                                            </td>
                                            <td className="text-right w-20">
                                                {formatPrice(invoicePrice(item), currency(item), cultureName)}
                                            </td>
                                            <td className="text-right w-20">
                                                0
                                            </td>
                                            <td className="text-right w-20">
                                                {formatPrice(invoicePrice(item), currency(item), cultureName)}
                                            </td>
                                        </tr>
                                    </>
                                )}

                                <tr className="text-right customBackground">
                                    <td className="h6 text-left">{t(`Template:Total`)}</td>
                                    <td className="h6 text-right w-20 ">{formatPrice(totals.quotaTotal, totals.currency, cultureName)}</td>
                                    <td className="h6 text-right w-20 "> {totals.commTotal === 0 ? 0 : "- " + formatPrice(totals.commTotal, totals.currency, cultureName)}</td>
                                    <td className="h6 text-right w-20 ">{formatPrice(totals.nettoTotal, totals.currency, cultureName)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            }
            {genericArr && isPrintCliente &&
                <>
                    <table className="w-100">
                        <tr>
                            <td></td>
                            <td className="w-20 text-white float-end p-3 w-30 stepHeader">
                                {t(`Template:Total`)}: <span class="h2 text-white">{formatPrice(totals.quotaTotal, totals.currency, cultureName)}</span>
                            </td>
                        </tr>
                    </table>
                </>
            }
        </>
    );
}
