import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../../js/Utils.js';

export const HotelMealPlan = ({ structureId, bestPricingMealplans, onClickSearch }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    var isFocus = false;

    function goToDetail(mealplanId) {
        onClickSearch(structureId, [
            { type: 'MEALPLANID', value: mealplanId }
        ]);
    }

    function setFocus(priceRoom) {
        if (priceRoom && !isFocus) {
            isFocus = true;
            return "focus";
        }

        return "";
    }

    return (
        <>
            {
                bestPricingMealplans && <table className=" w-100 text-center small">
                    <thead>
                        <tr className="small">
                            <td><data m3lab="Product.Structure.Filter.MealPlan.RoomsOB">{t('Product:Structure:Filter:MealPlan:RoomsOB')}</data></td>
                            <td><data m3lab="Product.Structure.Filter.MealPlan.RoomsBB">{t('Product:Structure:Filter:MealPlan:RoomsBB')}</data></td>
                            <td><data m3lab="Product.Structure.Filter.MealPlan.RoomsHB">{t('Product:Structure:Filter:MealPlan:RoomsHB')}</data></td>
                            <td><data m3lab="Product.Structure.Filter.MealPlan.RoomsFB">{t('Product:Structure:Filter:MealPlan:RoomsFB')}</data></td>
                            <td><data m3lab="Product.Structure.Filter.MealPlan.RoomsAI">{t('Product:Structure:Filter:MealPlan:RoomsAI')}</data></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {
                                <td>
                                    <button className={"btn btn-sm btn-neutral " + setFocus(bestPricingMealplans.bestPriceOB)} disabled={bestPricingMealplans.bestPriceOB ? false : true} onClick={(e) => goToDetail(1)}>
                                        {bestPricingMealplans.bestPriceOB ? formatPrice(bestPricingMealplans.bestPriceOB, bestPricingMealplans.currency, cultureName) : "-"}
                                    </button>
                                </td>
                            }
                            {
                                <td>
                                    <button className={"btn btn-sm btn-neutral " + setFocus(bestPricingMealplans.bestPriceBB)} disabled={bestPricingMealplans.bestPriceBB ? false : true} onClick={(e) =>  goToDetail(2)}>
                                        {bestPricingMealplans.bestPriceBB ? formatPrice(bestPricingMealplans.bestPriceBB, bestPricingMealplans.currency, cultureName) : "-"}
                                    </button>
                                </td>
                            }
                            {
                                <td>
                                    <button className={"btn btn-sm btn-neutral " + setFocus(bestPricingMealplans.bestPriceHB)} disabled={bestPricingMealplans.bestPriceHB ? false : true} onClick={(e) => goToDetail(3)}>
                                        {bestPricingMealplans.bestPriceHB ? formatPrice(bestPricingMealplans.bestPriceHB, bestPricingMealplans.currency, cultureName) : "-"}
                                    </button>
                                </td>
                            }
                            {
                                <td>
                                    <button className={"btn btn-sm btn-neutral " + setFocus(bestPricingMealplans.bestPriceFB)} disabled={bestPricingMealplans.bestPriceFB ? false : true} onClick={(e) => goToDetail(4)}>
                                        {bestPricingMealplans.bestPriceFB ? formatPrice(bestPricingMealplans.bestPriceFB, bestPricingMealplans.currency, cultureName) : "-"}
                                    </button>
                                </td>
                            }
                            {
                                <td>
                                    <button className={"btn btn-sm btn-neutral " + setFocus(bestPricingMealplans.bestPriceAI)} disabled={bestPricingMealplans.bestPriceAI ? false : true} onClick={(e) => goToDetail(5)}>
                                        {bestPricingMealplans.bestPriceAI ? formatPrice(bestPricingMealplans.bestPriceAI, bestPricingMealplans.currency, cultureName) : "-"}
                                    </button>
                                </td>
                            }
                        </tr>
                    </tbody>
                </table>
            }
        </>
    );
}