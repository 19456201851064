import { TravelPlaceMap } from "./TravelPlaceMap";
import { TileLayer } from 'react-leaflet';
import React, { useState } from 'react';
import Cookies from 'universal-cookie';

export const OsmTravelPlaceMap = ({ geoJsonRef, map, setMap, data, children, center, zoom, scrollWheelZoom, showFilterArea, sizeInMeters, showClusterPopup, updateFeatures, clusterMarkerPopup, markerPopup, showCluster, handleClickPointOnMap }) => {
    const cookies = new Cookies();
    const [cultureName] = useState(cookies.get("CultureName"));

    return (
        <TravelPlaceMap
            geoJsonRef={geoJsonRef}
            center={center}
            zoom={zoom}
            scrollWheelZoom={scrollWheelZoom}
            data={data}
            showFilterArea={showFilterArea}
            sizeInMeters={sizeInMeters}
            showClusterPopup={showClusterPopup}
            updateFeatures={updateFeatures}
            clusterMarkerPopup={clusterMarkerPopup}
            markerPopup={markerPopup}
            cultureCode={cultureName}
            showCluster={showCluster}
            map={map}
            setMap={setMap}
            handleClickPointOnMap={handleClickPointOnMap}
        >
            <TileLayer
                attribution='Travelplace &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                eventHandlers={{
                    add: (e) => {
                        console.log("Added Layer:", e.target);
                    },
                    remove: (e) => {
                        console.log("Removed layer:", e.target);
                    }
                }}
            />
            {children}
        </TravelPlaceMap>
    );
}
