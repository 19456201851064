import React, { useState } from 'react';
import { QuotationPaxesForm } from './QuotationPaxesForm';
import { BookParameterId } from '../../../js/Constant';
import { M3Icon } from '../../Common/M3Icon';
import { useTranslation } from 'react-i18next';

export const QuotationPaxes = (props) => {
    const { t } = useTranslation();
    const bookParamQuotationPaxType = 'PAX_TYPE_QUOT';
    const bookParamQuotationBirthCity = 'PAX_BIRTH_CITY';

    const defaultPaxValue = [
        { bpCode: bookParamQuotationPaxType, bpValue: 'ADT', isMandatory: true, defaultValue: '' },
        { bpCode: BookParameterId.Personal.FirstName, bpValue: '', isMandatory: true, defaultValue: '' },
        { bpCode: BookParameterId.Personal.LastName, bpValue: '', isMandatory: true, defaultValue: '' },
        { bpCode: BookParameterId.Personal.Gender, bpValue: '', isMandatory: true, defaultValue: '' },
        { bpCode: BookParameterId.Personal.BirthDate, bpValue: '', isMandatory: false, defaultValue: '' },
        { bpCode: bookParamQuotationBirthCity, bpValue: '', isMandatory: false, defaultValue: '' },
        { bpCode: BookParameterId.Personal.FiscalCode, bpValue: '', isMandatory: false, defaultValue: '' },
        { bpCode: BookParameterId.Document.Number, bpValue: '', isMandatory: false, defaultValue: '' },
        { bpCode: BookParameterId.Document.ExpirationDate, bpValue: '', isMandatory: false, defaultValue: '' },
        { bpCode: BookParameterId.Document.Type, bpValue: 'P', isMandatory: false, defaultValue: '' },
        { bpCode: BookParameterId.Contact.HomePhone, bpValue: '', isMandatory: false, defaultValue: '' },
        { bpCode: BookParameterId.Contact.Email, bpValue: '', isMandatory: false, defaultValue: '' }
    ];

    const [isValid, setIsValid] = useState(true);

    const [paxBpValues, setPaxBpValues] = useState(defaultPaxValue);
    const [validAge] = useState(new Map([
        ["ADT", { minAge: 18, maxAge: 150 }],
        ["CHD", { minAge: 2, maxAge: 17 }],
        ["INF", { minAge: 0, maxAge: 1 }]
    ]));

    function onBlurCheckIsMandatory(event, propertyName) {
        let updatePaxBpValues = [...paxBpValues];
        var isChecked = event.target.checked;

        switch (propertyName) {
            case 'birthDate': {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0];
                myItem.isMandatory = isChecked;
                break;
            }
            case 'email': {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Contact.Email)[0];
                myItem.isMandatory = isChecked;
                break;
            }
            case 'mobilePhone': {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Contact.HomePhone)[0];
                myItem.isMandatory = isChecked;
                break;
            }
            case 'expiryDatePassport': {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Document.ExpirationDate)[0];
                myItem.isMandatory = isChecked;
                break;
            }
            case 'numberPassport': {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Document.Number)[0];
                myItem.isMandatory = isChecked;
                break;
            }
            case 'fiscalCode': {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Personal.FiscalCode)[0];
                myItem.isMandatory = isChecked;
                break;
            }
            case 'birthCity': {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === bookParamQuotationBirthCity)[0];
                myItem.isMandatory = isChecked;
                break;
            }
        }

        setPaxBpValues(updatePaxBpValues);
    }

    function onChangePaxType(event, paxType) {
        let updatePaxBpValues = [...paxBpValues];

        let myItem = updatePaxBpValues.filter(x => x.bpCode === bookParamQuotationPaxType)[0];
        if (event.target.checked && myItem.bpValue !== paxType) {
            myItem.bpValue = paxType
            myItem.defaultValue = '';

            if (paxType !== 'ADT') {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0];
                myItem.isMandatory = true;
                myItem.defaultValue = '';
                setIsValid(false);
            } else {
                setIsValid(true);
                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0];
                myItem.isMandatory = false;
                myItem.defaultValue = '';
            }
        }

        setPaxBpValues(updatePaxBpValues);
    }

    function onBlurCheckDefaultValue(event, propertyName) {
        let updatePaxBpValues = [...paxBpValues];
        var defaultValue = event.target.value;

        switch (propertyName) {
            case 'birthDate': {
                const paxRules = validAge.get(paxBpValues.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue);

                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0];
                myItem.defaultValue = defaultValue;

                if ((parseInt(defaultValue) >= paxRules.minAge && parseInt(defaultValue) <= paxRules.maxAge)) {
                    setIsValid(true);
                } else {
                    setIsValid(false);
                }

                break;
            }
        }

        setPaxBpValues(updatePaxBpValues);
    }

    function onClickAddOrUpdate() {
        let myPax = {
            idPax: -1,
            isRefPax: false,
            bookParams: paxBpValues
        };

        props.onAddorUpdatePax(myPax);
    }

    function GetPaxesMessage(isAgencyMode, orderNumber, isAllQuotationPaxesFilled) {
        if (orderNumber)
            return t("MyBook:PassengersList");

        if (isAgencyMode || isAllQuotationPaxesFilled)
            return t("MyBook:PassengersQuotation");

        return t("MyBook:InsertPassengerText");
    }

    function GetRecapPaxes(paxes) {
        if (!paxes || paxes.length === 0)
            return " - ";

        let recapPaxes = "";

        const bookParamPaxType = paxes.map(x => x.bookParams).filter(x => x.bpCode === bookParamQuotationPaxType);

        if (bookParamPaxType && bookParamPaxType.length > 0) {
            const totalADT = bookParamPaxType.filter(bp => bp.bpValue === 'ADT');
            if (totalADT) {
                recapPaxes += totalADT.length + " ADT, ";
            } else {
                recapPaxes += "0 ADT, ";
            }

            const totalCHD = bookParamPaxType.filter(bp => bp.bpValue === 'CHD');
            if (totalCHD) {
                recapPaxes += totalCHD.length + " CHD, ";
            } else {
                recapPaxes += "0 CHD, ";
            }

            const totalINF = bookParamPaxType.filter(bp => bp.bpValue === 'INF');
            if (totalINF) {
                recapPaxes += totalADT.length + " INF";
            } else {
                recapPaxes += "0 INF";
            }
        }
        else {
            let strMyDate = props.startDate;
            if (props.startDate instanceof Date)
                strMyDate = props.startDate.toDateString();

            let myDate = new Date(strMyDate);

            let totalADT = 0;
            let totalCHD = 0;
            let totalINF = 0;
            for (let i = 0; i < paxes.length; i++) {
                let myPax = paxes[i];
                let dateBirthBp = myPax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.BirthDate);
                if (dateBirthBp && dateBirthBp.length > 0) {
                    try {
                        let strBirthDate = dateBirthBp[0].bpValue;
                        let myBirthDate = new Date(strBirthDate);

                        var diff = (myDate.getTime() - myBirthDate.getTime()) / 1000;
                        diff /= (60 * 60 * 24);
                        var age = Math.abs(Math.round(diff / 365.25));
                        if (!age && dateBirthBp[0].defaultValue)
                            age = parseInt(dateBirthBp[0].defaultValue);

                        if (age < 2) {
                            totalINF++;
                        } else if (age < 18) {
                            totalCHD++;
                        } else {
                            totalADT++;
                        }

                    } catch (ex) {
                        totalADT++;
                    }

                } else {
                    totalADT++
                }

            }

            recapPaxes = `${totalADT} ADT, ${totalCHD} CHD, ${totalINF} INF`;
        }

        return recapPaxes;
    }

    return (
        <div className="card border-gray-300 mb-4">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                <h2 className="accordion-header " id="FormQuotPaxes">
                    <a className="h3 text-decoration-none collapsed" data-bs-toggle="collapse" data-bs-target="#formRQPax" aria-expanded="false" aria-controls="formRQPax">
                        {
                            <label className="h100 text-gray-700">
                                {GetPaxesMessage(props.isAgencyMode, props.orderNumber, props.isAllQuotationPaxesFilled)}
                            </label>
                        }
                        {
                            props.paxes && props.paxes.length > 0 && <label className="h100 text-gray-700 d-block fw-bolder">{t("MyBook:PassengersComposition")}: {GetRecapPaxes(props.paxes)}</label>
                        }
                        <span className="material-icons icon-10 pr-2">add_box</span>
                    </a>
                </h2>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 border-0">
                <div id="formRQPax" className="accordion-collapse collapse" role="region" aria-labelledby="heading1c">
                    <div className="accordion-body">
                        {
                            !props.orderNumber && !props.isAgencyMode && <div className="row pt-2">
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label>ADL
                                                <input id="cbQuotationPaxADT"
                                                    type="checkbox"
                                                    className="formcheck mx-1"
                                                    checked={paxBpValues.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue === 'ADT'}
                                                    onChange={e => onChangePaxType(e, "ADT")} />
                                            </label>
                                            <label>CHD
                                                <input id="cbQuotationPaxCHD"
                                                    type="checkbox"
                                                    className="mx-1"
                                                    checked={paxBpValues.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue === 'CHD'}
                                                    onChange={e => onChangePaxType(e, "CHD")} />
                                            </label>
                                            <label>INF
                                                <input id="cbQuotationPaxINF"
                                                    type="checkbox"
                                                    className="mx-1"
                                                    checked={paxBpValues.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue === 'INF'}
                                                    onChange={e => onChangePaxType(e, "INF")} />
                                            </label>
                                        </div>
                                        <div className="col-1">
                                            <label>Nome</label>
                                        </div>
                                        <div className="col-md-2">
                                            <label>Cognome </label>
                                        </div>
                                        <div className="col-md-1">
                                            <label>M/F </label>
                                        </div>
                                        <div className={"col-md-3" + (!isValid ? " insert-danger" : "")}>
                                            <label>Data di Nascita
                                                <input id="checkbox2"
                                                    type="checkbox"
                                                    className="mx-1"
                                                    disabled={paxBpValues.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue !== 'ADT'}
                                                    checked={paxBpValues.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0].isMandatory}
                                                    onChange={e => onBlurCheckIsMandatory(e, 'birthDate')} />
                                            </label>
                                            {
                                                paxBpValues.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue !== 'ADT' && <div>
                                                    <label className="d-inline">Età *</label>
                                                    <input type="number"
                                                        className="form-control d-inline w-50 mx-1 mb-2"
                                                        value={paxBpValues.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0].defaultValue}
                                                        min={validAge.get(paxBpValues.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue).minAge}
                                                        max={validAge.get(paxBpValues.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue).maxAge}
                                                        onChange={e => onBlurCheckDefaultValue(e, 'birthDate')}
                                                        onBlur={e => onBlurCheckDefaultValue(e, 'birthDate')} />
                                                </div>
                                            }
                                            {
                                                !isValid && <div className={(!isValid ? " text-danger" : "")} >{`L'età
                                                    ${paxBpValues.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue === 'CHD'
                                                        ? "del bambino"
                                                        : "dell'infant"
                                                    }
                                                    deve essere compresa da
                                                    ${validAge.get(paxBpValues.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue).minAge} a
                                                    ${validAge.get(paxBpValues.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue).maxAge}`}</div>
                                            }
                                        </div>
                                        <div className="col-md-2">
                                            <label>{t("MyBook:BirthPlace")}
                                                <input id="checkbox2"
                                                    type="checkbox"
                                                    className="mx-1"
                                                    disabled={paxBpValues.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue !== 'ADT'}
                                                    checked={paxBpValues.filter(x => x.bpCode === bookParamQuotationBirthCity)[0].isMandatory}
                                                    onChange={e => onBlurCheckIsMandatory(e, 'birthCity')} />
                                            </label>
                                        </div>
                                        <div className="col-md-2">
                                            <label>{t("MyBook:idCode")}
                                                <input id="checkbox2"
                                                    type="checkbox"
                                                    className="mx-1"
                                                    checked={paxBpValues.filter(x => x.bpCode === BookParameterId.Personal.FiscalCode)[0].isMandatory}
                                                    onChange={e => onBlurCheckIsMandatory(e, 'fiscalCode')} />
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label>{t("MyBook:PassportNumber")}
                                                <input id="checkbox2"
                                                    type="checkbox"
                                                    className="mx-1"
                                                    checked={paxBpValues.filter(x => x.bpCode === BookParameterId.Document.Number)[0].isMandatory}
                                                    onChange={e => onBlurCheckIsMandatory(e, 'numberPassport')} />
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label>{t("MyBook:PassportExpiration")}
                                                <input id="checkbox2"
                                                    type="checkbox"
                                                    className="mx-1"
                                                    checked={paxBpValues.filter(x => x.bpCode === BookParameterId.Document.ExpirationDate)[0].isMandatory}
                                                    onChange={e => onBlurCheckIsMandatory(e, 'expiryDatePassport')} />
                                            </label>
                                        </div>
                                        <div className="col-md-2">
                                            <label>{t("MyBook:Phone")}
                                                <input id="checkbox2"
                                                    type="checkbox"
                                                    className="mx-1"
                                                    checked={paxBpValues.filter(x => x.bpCode === BookParameterId.Contact.HomePhone)[0].isMandatory}
                                                    onChange={e => onBlurCheckIsMandatory(e, 'mobilePhone')} />
                                            </label>
                                        </div>
                                        <div className="col-md-2">
                                            <label>Mail
                                                <input id="checkbox2"
                                                    type="checkbox"
                                                    className="mx-1"
                                                    checked={paxBpValues.filter(x => x.bpCode === BookParameterId.Contact.Email)[0].isMandatory}
                                                    onChange={e => onBlurCheckIsMandatory(e, 'email')} />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 text-end">
                                    <button
                                        className="btn btn-sm tp-btn-add h085 mb-2"
                                        disabled={!isValid}
                                        onClick={onClickAddOrUpdate}
                                    ><M3Icon iconName="Add" externalClass="h085" /> {t("MyBook:Passenger")}</button>
                                </div>
                                <div>{t("MyBook:CompilationPaxAgencyText")} </div>
                            </div>
                        }
                        <QuotationPaxesForm
                            paxes={props.paxes}
                            startDate={props.startDate}
                            isAgencyMode={props.isAgencyMode}
                            onAddPaxBpValues={props.onAddPaxBpValues}
                            onDeletePax={props.onDeletePax}
                            orderNumber={props.orderNumber}
                            extraOptions={{ saveSinglePax: true, readonly: props.isAgencyMode }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}