import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';
import { handleError, getCurrentUserFromJwtToken } from "../../../js/Utils";
import { M3Icon } from "../../Common/M3Icon";
import { M3Pax } from "../../Common/M3Pax";
import { imageOnLoadTemplateStep } from '../../../js/CDNUtility';

export const TransferTemplate = ({ templateData, searchParameters, isAlternative, isOptional }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const renderAlternatives = (alternativeTemplateDataIds) => {
        if (!alternativeTemplateDataIds || alternativeTemplateDataIds.length === 0)
            return (<></>);

        let alternatives = []; //allTemplateDatas.filter(x => alternativeTemplateDataIds.includes(x.templateDataId));
        if (!alternatives || alternatives.length === 0)
            return (<></>);

        return (
            <>
                {alternatives.map((alternative, index) =>
                    <div key={index} className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row rounded rounded-3 bg-gray-300 my-1 mx-0 p-1">
                            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                <div className="rounded" style={{ backgroundImage: "url(" + (alternative.product.thumbUrl && alternative.product.thumbUrl.length > 0 ? encodeURI(alternative.product.thumbUrl[0]) : imageOnLoadTemplateStep()) + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100%" }}></div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                <div className="w-100">
                                    <div className="h6 m-0">
                                        <var>
                                            {alternative.product.pickupDestinationName} {alternative.product.pickupStructureName}
                                            &gt;
                                            {alternative.product.dropoffDestinationName} {alternative.product.dropoffStructureName}
                                        </var>
                                    </div>
                                    {/*<label>*/}
                                    {/*    <var>Doppia matrimoniale con letti separati</var>*/}
                                    {/*</label>*/}
                                </div>
                            </div>
                            <div className="col-3 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-right float-end pr-0 align-middle h-100">
                                <button className="btn btn-sm tp-btn-outline-search py-0">
                                    <data m3lab="Template.Option">Alternativa</data>
                                    {/*<div>*/}
                                    {/*    +253 €*/}
                                    {/*</div>*/}
                                </button>
                            </div>
                        </div>

                    </div>
                )}
            </>
        );
    }


    return (
        <>
            {templateData && 
                <div className="card border-gray-600 my-4 animate-up-5 shadow">
                    <div className="row no-gutters">
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div className="rounded" style={{ backgroundImage: "url(" + (templateData.product.thumbUrl ? templateData.product.thumbUrl : imageOnLoadTemplateStep()) + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100%" }}></div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            <div className="myBook-card-body">
                                <div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <span><data m3lab="Product.Transfer.Item">{t(`Product:Transfer:Item`)}</data></span>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 px-4">
                                            <M3Pax adt={searchParameters.totalAdults} chd={searchParameters.totalChildren} inf={searchParameters.totalInfants} />
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <div className="btn-group text-right float-end pr-2">
                                                <button className="btn p-0 m-0" type="button" id="menuItem1" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                    <data m3ico="MenuFunctions">
                                                        <span className="material-icons">
                                                            list
                                                        </span>
                                                    </data>
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end" aria-labelled="menuItem2">
                                                    {/*<li><a className="dropdown-item" href="#"><data m3ico="Night icon-10 small rext-gray-600"><span className="material-icons icon-10 small text-gray-600">bed</span></data><label><data m3lab="ActionMenu.TransferChange">Cambio Transfer</data></label></a></li>*/}
                                                    {/*<li><a className="dropdown-item" href="#"><data m3ico="Info icon-10"><span className="material-icons icon-10">info_outline</span></data><label><data m3lab="ActionMenu.detail">Dettaglio (more)</data></label></a></li>*/}
                                                    {/*<li><a className="dropdown-item text-danger" href="#"><data m3ico="Delete icon-10"><span className="material-icons icon-10">delete_forever</span></data><label><data m3lab="ActionMenu.delete">Cancella</data></label></a></li>*/}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-100">
                                        <label>
                                            <data m3ico="Transfer">
                                                <span className="material-icons icon-10">airport_shuttle</span>
                                            </data>
                                            <var>
                                                {templateData.product.pickupDestinationName} {templateData.product.pickupStructureName}
                                                &gt;
                                                {templateData.product.dropoffDestinationName} {templateData.product.dropoffStructureName}
                                            </var>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Alternative */}
                        {renderAlternatives(templateData.alternativeTemplateDataIds)}
                    </div>
                </div>
            }
        </>
    );
}
