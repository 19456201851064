import configData from "../appsettings.json";

export function downloadDocumentPDF(filename, url) {
    const element = document.createElement('a');
    element.setAttribute('href', `${url}`);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export function generateComponentInBody(componentHtml) {
    return "<html><body>" + componentHtml + "</body></html>";
}

export function getIconUrlToPrint(icoName) {
    var iconUrl = "https://cdn.travelplace.ch/travelplace40/img/icons/print/";
    var iconImgName = "";

    switch (icoName) {
        case 'activity': iconImgName = "baseline_directions_walk_black_24dp.png"; break;
        case 'assurance': iconImgName = "outline_shield_black_24dp.png"; break;
        case 'carrental': iconImgName = "baseline_directions_car_black_24dp.png"; break;
        case 'cruise': iconImgName = "baseline_directions_boat_black_24dp.png"; break;
        case 'email': iconImgName = "outline_email_black_24dp.png"; break;
        case 'euro': iconImgName = "outline_euro_symbol_black_24dp.png"; break;
        case 'flight': iconImgName = "baseline_flight_black_24dp.png"; break;
        case 'hotel': iconImgName = "baseline_business_black_24dp.png"; break;
        case 'phone': iconImgName = "baseline_phone_black_24dp.png"; break;
        case 'product': iconImgName = "outline_shield_black_24dp.png"; break;
        case 'tour': iconImgName = "baseline_tour_black_24dp.png"; break;
        case 'train': iconImgName = "baseline_train_black_24dp.png"; break;
        case 'transfer': iconImgName = "baseline_directions_bus_filled_black_24dp.png"; break;
        case 'person': iconImgName = "baseline_person_black_24dp.png"; break;
        case 'child': iconImgName = "baseline_face_black_24dp.png"; break;
        case 'infant': iconImgName = "baseline_child_care_black_24dp.png"; break;
        case 'star': iconImgName = "baseline_star_rate_black_24dp.png"; break;
        case 'room': iconImgName = "baseline_hotel_black_24dp.png"; break;
        case 'treatment': iconImgName = "baseline_restaurant_black_24dp.png"; break;
        case 'gps': iconImgName = "baseline_share_location_black_24dp.png"; break;
        case 'penalty': iconImgName = "baseline_local_atm_black_24dp.png"; break;
        case 'radioButton': iconImgName = "baseline_radio_button_24dp.png"; break;
        case 'luggage': iconImgName = "baseline_luggage_black_24dp.png"; break;
        case 'announcement': iconImgName = "baseline_feedback_black_24.png"; break;
        case 'carRental': iconImgName = "baseline_car_rental_24dp.png"; break;
        case 'localAtm': iconImgName = "baseline_local_atm_24dp.png"; break;
        case 'hail': iconImgName = "baseline_hail_24dp.png"; break;
        case 'privacy': iconImgName = "baseline_privacy_tip_24dp.png"; break;
            
        default: return "";
    }

    return iconUrl + iconImgName;
}


export function getIconUrlToPrintCustom(icoName) {
    var iconUrl = "https://cdn.travelplace.ch/travelplace40/img/icons/print-custom/";
    var iconImgName = "";

    switch (icoName) {
        case 'voli': iconImgName = "i_vostri_voli.png"; break;
        case 'elenco_servizi': iconImgName = "elenco_servizi.png"; break;
        case 'copertina': iconImgName = "immagine_copertina.png"; break;
        case 'dettaglio_commissioni': iconImgName = "totale_viaggio_dettaglio_commissioni.png"; break;
        case 'uomo': iconImgName = "uomo.png"; break;
        case 'bambino': iconImgName = "bambino.png"; break;
        case 'neonato': iconImgName = "neonato.png"; break;
        case 'info_utili': iconImgName = "info_utili.png"; break;
        case 'puntini_oro_verticali': iconImgName = "puntini_oro_verticali.png"; break;
        case 'puntini_oro_orizzontali': iconImgName = "puntini_oro_orizzontali.png"; break;
        case 'linea_oro_oirzzonatale': iconImgName = "linea_oro_oirzzonatale.png"; break;
        case 'info_pax_background': iconImgName = "info_pax_background.png"; break;
        case 'cronologia': iconImgName = "cronologia.png"; break;
        case 'privacy_tip': iconImgName = "privacy_tip.png"; break;
        case 'info_hotel': iconImgName = "info_hotel.png"; break;
        case 'directions_walk': iconImgName = "directions_walk.png"; break;

        default: return "";
    }

    return iconUrl + iconImgName;
}

export function getProductIconToPrint(productType) {
    productType = productType + ""; // fix toString

    switch (productType) {
        case "S":
        case "Structure":
        case "0":
            return getIconUrlToPrint('hotel');
        case "A":
        case "Activity":
        case "1":
            return getIconUrlToPrint('activity');
        case "C":
        case "CarRental":
        case "2":
            return getIconUrlToPrint('carrental');
        case "N":
        case "Cruise":
        case "3":
            return getIconUrlToPrint('cruise');
        case "T":
        case "Transfer":
        case "4":
            return getIconUrlToPrint('transfer');
        case "F":
        case "Flight":
        case "5":
            return getIconUrlToPrint('flight');
        case "P":
        case "Package":
        case "6":
            return getIconUrlToPrint('package');
        case "K":
        case "Coupon":
        case "7":
            return getIconUrlToPrint('coupon');
        case "R":
        case "RentSellPr":
        case "8":
            return getIconUrlToPrint('rentsellpr');
        case "I":
        case "Train":
        case "9":
            return getIconUrlToPrint('train');
        case "O":
        case "Tour":
        case "10":
            return getIconUrlToPrint('tour');
        case "Generic":
        case "99":
            return getIconUrlToPrint('assurance');
        default: return getIconUrlToPrint('product');
    }
}

export const getStructureStarsToPrint = (categoryId) => {
    switch (categoryId) {
        case "1":
        case "2":
            return (
                <>
                    <img src={getIconUrlToPrint('star')} className="ico-image" />
                </>
            );

        case "3":
        case "4":
            return (
                <>
                    <img src={getIconUrlToPrint('star')} className="ico-image" /><img src={getIconUrlToPrint('star')} className="ico-image" />
                </>
            );

        case "5":
        case "6":
            return (
                <>
                    <img src={getIconUrlToPrint('star')} className="ico-image" /><img src={getIconUrlToPrint('star')} className="ico-image" /> <img src={getIconUrlToPrint('star')} className="ico-image" />
                </>
            );

        case "7":
        case "8":
            return (
                <>
                    <img src={getIconUrlToPrint('star')} className="ico-image" /> <img src={getIconUrlToPrint('star')} className="ico-image" /> <img src={getIconUrlToPrint('star')} className="ico-image" /> <img src={getIconUrlToPrint('star')} className="ico-image" />
                </>
            );

        case "9":
        case "10":
            return (
                <>
                    <img src={getIconUrlToPrint('star')} className="ico-image" /> <img src={getIconUrlToPrint('star')} className="ico-image" /> <img src={getIconUrlToPrint('star')} className="ico-image" /> <img src={getIconUrlToPrint('star')} className="ico-image" /> <img src={getIconUrlToPrint('star')} className="ico-image" />
                </>
            );

        case "11":
        case "12":
        case "13":
        case "14":
        case "15":
        case "99":
        default:
            return (<></>);
    }
}

/* classe usata per errori da file di testo database */
export function replaceAllStyleElements(styleText) {
    let styleReplaced = styleText;

    if (!styleReplaced)
        return styleReplaced;

    if (styleReplaced.includes("&quot;")) {
        styleReplaced = styleReplaced.replaceAll('&quot;', "");
    }
    if (styleReplaced.includes("&lt;")) {
        styleReplaced = styleReplaced.replaceAll('&lt;', "<");
    }
    if (styleReplaced.includes("&gt;")) {
        styleReplaced = styleReplaced.replaceAll('&gt;', ">");
    }
    if (styleReplaced.includes("color: inherit"))
        styleReplaced = styleReplaced.replaceAll("color: inherit", "text-align: justify");

    if (styleReplaced.includes("<div")) {
        styleReplaced = styleReplaced.replaceAll('<div', '<span');
    }
    if (styleReplaced.includes("</div>")) {
        styleReplaced = styleReplaced.replaceAll('</div>', '</span>');
    }
    if (styleReplaced.includes(">>")) {
        styleReplaced = styleReplaced.replaceAll('>>', '>');
    }
    if (styleReplaced.includes("Text")) {
        styleReplaced = styleReplaced.replaceAll('Text', '');
    }

    if (styleReplaced.includes("sans-serif")) {
        styleReplaced = styleReplaced.replaceAll("sans-serif", ";");
    }
    if (styleReplaced.includes("serif")) {
        styleReplaced = styleReplaced.replaceAll("serif", ";");
    }
    if (styleReplaced.includes("Serif")) {
        styleReplaced = styleReplaced.replaceAll("Serif", ";");
    }
    if (styleReplaced.includes("sans")) {
        styleReplaced = styleReplaced.replaceAll("sans", ";");
    }
    if (styleReplaced.includes("Courier New")) {
        styleReplaced = styleReplaced.replaceAll("Courier New", ";");
    }
    if (styleReplaced.includes("courier new")) {
        styleReplaced = styleReplaced.replaceAll("courier new", ";");
    }
    if (styleReplaced.includes("Courier")) {
        styleReplaced = styleReplaced.replaceAll("Courier", ";");
    }
    if (styleReplaced.includes("courier")) {
        styleReplaced = styleReplaced.replaceAll("courier", ";");
    }
    if (styleReplaced.includes("Comic Sans MS")) {
        styleReplaced = styleReplaced.replaceAll("Comic Sans MS", ";");
    }
    if (styleReplaced.includes("comic sans ms")) {
        styleReplaced = styleReplaced.replaceAll("comic sans ms", ";");
    }
    if (styleReplaced.includes("Impact")) {
        styleReplaced = styleReplaced.replaceAll("Impact", ";");
    }
    if (styleReplaced.includes("impact")) {
        styleReplaced = styleReplaced.replaceAll("impact", ";");
    }
    if (styleReplaced.includes("Lucida Grande")) {
        styleReplaced = styleReplaced.replaceAll("Lucida Grande", ";");
    }
    if (styleReplaced.includes("lucida grande")) {
        styleReplaced = styleReplaced.replaceAll("lucida grande", ";");
    }
    if (styleReplaced.includes("Lucida Sans")) {
        styleReplaced = styleReplaced.replaceAll("Lucida Sans", ";");
    }
    if (styleReplaced.includes("lucida sans")) {
        styleReplaced = styleReplaced.replaceAll("lucida sans", ";");
    }
    if (styleReplaced.includes("Sans")) {
        styleReplaced = styleReplaced.replaceAll("Sans", ";");
    }
    if (styleReplaced.includes("Verdana")) {
        styleReplaced = styleReplaced.replaceAll("Verdana", " Helvetica");
    }
    if (styleReplaced.includes("verdana")) {
        styleReplaced = styleReplaced.replaceAll("verdana", " Helvetica");
    }
    if (styleReplaced.includes("Tahoma")) {
        styleReplaced = styleReplaced.replaceAll("Tahoma", " Helvetica");
    }
    if (styleReplaced.includes("tahoma")) {
        styleReplaced = styleReplaced.replaceAll("tahoma", " Helvetica");
    }
    if (styleReplaced.includes("Tahoma")) {
        styleReplaced = styleReplaced.replaceAll("Tahoma", " Helvetica");
    }
    if (styleReplaced.includes("Helvetica Neue")) {
        styleReplaced = styleReplaced.replaceAll("Helvetica Neue", " Helvetica");
    }
    if (styleReplaced.includes("Calibri")) {
        styleReplaced = styleReplaced.replaceAll("Calibri", " Helvetica");
    }
    if (styleReplaced.includes("arial")) {
        styleReplaced = styleReplaced.replaceAll("arial", " Helvetica");
    }
    if (styleReplaced.includes("Arial")) {
        styleReplaced = styleReplaced.replaceAll("Arial", " Helvetica");
    }
    if (styleReplaced.includes("arial black")) {
        styleReplaced = styleReplaced.replaceAll("arial black", " Helvetica");
    }
    if (styleReplaced.includes("Arial Black")) {
        styleReplaced = styleReplaced.replaceAll("Arial Black", " Helvetica");
    }
    if (styleReplaced.includes("Times New Roman")) {
        styleReplaced = styleReplaced.replaceAll("Times New Roman", " Helvetica");
    }
    if (styleReplaced.includes("Aptos")) {
        styleReplaced = styleReplaced.replaceAll("Aptos", " Helvetica");
    }
    if (styleReplaced.includes("Aptos_EmbeddedFont")) {
        styleReplaced = styleReplaced.replaceAll("Aptos_EmbeddedFont", " Helvetica");
    }
    if (styleReplaced.includes("Aptos_MSFontService")) {
        styleReplaced = styleReplaced.replaceAll("Aptos_MSFontService", " Helvetica");
    }
    if (styleReplaced.includes("Lato")) {
        styleReplaced = styleReplaced.replaceAll("Lato", " Helvetica");
    }
    if (styleReplaced.includes("Arial Unicode MS")) {
        styleReplaced = styleReplaced.replaceAll("Arial Unicode MS", " Helvetica");
    }
    if (styleReplaced.includes("Ms Mincho")) {
        styleReplaced = styleReplaced.replaceAll("", " Helvetica");
    }

    return styleReplaced;
}


export function dynamicNotesDistincts(dynamicNotes) {
    let arrayNotesIdTmp = [];
    let arrayNotes = [];

    let arrNotes = [];

    // mette in un unico array le note
    if (dynamicNotes != null) {
        dynamicNotes.forEach((arrNote) => {
            arrNote.forEach((note) => {
                arrNotes.push(note);
            });
        });

        if (arrNotes != null) {
            // fa un distinct delle note
            arrNotes.forEach((note) => {
                if (arrayNotesIdTmp.indexOf(note.noteId) === -1) {
                    arrayNotesIdTmp.push(Number(note.noteId));
                    arrayNotes.push(note);
                }
            });
        }
    }

    return arrayNotes;
}

export function getDifferenceDay(departureDate, arrivalDate) {
    var d1 = new Date(departureDate);
    var d2 = new Date(arrivalDate);

    var diff = d2.getTime() - d1.getTime();

    var diffDays = Math.round(diff / (1000 * 60 * 60 * 24));

    if (diffDays)
        return diffDays;
    else
        return "";
}

export function getDifferenceDayFlight(departureDate, arrivalDate) {
    var date1 = new Date(departureDate);
    var date2 = new Date(arrivalDate);

    date1.setHours(0);
    date1.setMinutes(0, 0, 0);
    date2.setHours(0);
    date2.setMinutes(0, 0, 0);
    var datediff = Math.abs(date1.getTime() - date2.getTime()); // difference

    if (datediff > 0)
        return "+" + parseInt(datediff / (24 * 60 * 60 * 1000), 10)
    else
        return "";
}


export function getADTTotal(paxes, isConfirmPrint=true) {
    const bookParamQuotationPaxType = 'PAX_TYPE_QUOT';
    let adt = 0;
    if (isConfirmPrint) {
        paxes.forEach((pax) => {
            if (pax.bookParameters.filter(x => x.id === bookParamQuotationPaxType)[0]) {
                if (pax.bookParameters.filter(x => x.id === bookParamQuotationPaxType)[0].value === 'ADT')
                    adt++;
            }
            else
                adt++;
        });
    }
    else {
        paxes.forEach((pax) => {
            if (pax.bookParams.filter(x => x.bpCode === bookParamQuotationPaxType)[0]) {
                if (pax.bookParams.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue === 'ADT')
                    adt++;
            }
            else
                adt++;
        });
    }

    return adt;
}

export function getCHDTotal(paxes, isConfirmPrint=true) {
    const bookParamQuotationPaxType = 'PAX_TYPE_QUOT';
    let chd = 0;

    if (isConfirmPrint) {
        paxes.forEach((pax) => {
            if (pax.bookParameters.filter(x => x.id === bookParamQuotationPaxType)[0]) {
                if (pax.bookParameters.filter(x => x.id === bookParamQuotationPaxType)[0].value === 'CHD')
                    chd++;
            }
        });
    }
    else {
        paxes.forEach((pax) => {
            if (pax.bookParams.filter(x => x.bpCode === bookParamQuotationPaxType)[0]) {
                if (pax.bookParams.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue === 'CHD')
                    chd++;
            }
            else
                chd++;
        });
    }

    return chd;
}

export function getINFTotal(paxes, isConfirmPrint=true) {
    const bookParamQuotationPaxType = 'PAX_TYPE_QUOT';
    let inf = 0;

    if (isConfirmPrint) {
        paxes.forEach((pax) => {
            if (pax.bookParameters.filter(x => x.id === bookParamQuotationPaxType)[0].value === 'INF')
                inf++;
        });
    }
    else {
        paxes.forEach((pax) => {
            if (pax.bookParams.filter(x => x.bpCode === bookParamQuotationPaxType)[0]) {
                if (pax.bookParams.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue === 'INF')
                    inf++;
            }
            else
                inf++;
        });
    }

    return inf;
}

export function setCancelPolicy(qInfo, cancelPolicyArr, t) {
    let tmpArr = [];
    if (qInfo && qInfo.productInfo && qInfo.productType !== 'Flight' && qInfo.productType !== 'Structure' && qInfo.productType !== 'Tour') {
        if (qInfo.productInfo.option && qInfo.productInfo.option.cancelPolicy && qInfo.productInfo.option.cancelPolicy.penalties && qInfo.productInfo.option.cancelPolicy.penalties.length > 0) {
            // aggiunto perchè a volte non viene passato dai fornitori e l'ultima data deve essere sempre 100%
            qInfo.productInfo.option.cancelPolicy.penalties[qInfo.productInfo.option.cancelPolicy.penalties.length - 1].percent = "100";

            qInfo.productInfo.option.cancelPolicy.penalties.map(penalty =>
                tmpArr.push({
                    date: penalty.startDate,
                    percent: penalty.percent ? penalty.percent : Math.round(((penalty.amount * 100) / qInfo.productInfo.priceBar.netPrice)),
                    amount: penalty.amount,
                    currency: penalty.currency
                })
            );

            tmpArr.sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
            });

            cancelPolicyArr.push({
                name: qInfo.productInfo.name,
                cancelPolicy: tmpArr
            });
        }
    }
    else if (qInfo && qInfo.productInfo && qInfo.productInfo.rooms && qInfo.productInfo.rooms.length > 0 && (qInfo.productType === 'Structure' || qInfo.productType === 'Tour')) {
        qInfo.productInfo.rooms.map((room, index) => {
            tmpArr = [];
            // aggiunto perchè a volte non viene passato dai fornitori e l'ultima data deve essere sempre 100%
            room.cancelPolicy.penalties[room.cancelPolicy.penalties.length - 1].percent = "100";

            room.cancelPolicy.penalties.map(penalty =>
                tmpArr.push({
                    date: penalty.startDate,
                    percent: penalty.percent ? penalty.percent : Math.round(((penalty.amount * 100) / room.priceBar.netPrice)),
                    amount: penalty.amount,
                    currency: penalty.currency
                })
            );

            tmpArr.sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
            });

            cancelPolicyArr.push({
                name: qInfo.productInfo.name + " (" + t(`Product:Structure:Room`) + " " + room.sequence + ")",
                cancelPolicy: tmpArr
            });
        });
    }
}


// verifica se le note superano il massimo numero di righe
function checkIfNotesTooBig(noteArr) {
    let isTooBig = false;
    let numberChar = 0;
    
    if (noteArr === undefined || noteArr[0] === "" || noteArr[0] === null)
        return isTooBig;

    noteArr.forEach(function (note) {
        if ((numberChar + note.length) > 200) {
            isTooBig = true;
        }
        else {
            numberChar += note.length;
        }
    });
    
    return isTooBig;
}

export function isNotesTooBig(noteArr) {
    return checkIfNotesTooBig(noteArr);
}

function getProductsNotesArray(noteArr, name, roomName = "") {
    let arrNotesProdcuts = [];

    arrNotesProdcuts.push({
        name: name + roomName,
        notes: noteArr,
    });

    return arrNotesProdcuts;
}

export function setNoteProductBig(qInfo, arrNotesProdcuts) {
    let isNotesHide = false;
    let resProductArr = [];
    if (!qInfo)
        return;
   
    if ((qInfo.productSubType === 'Tour' || qInfo.productSubType === 'Activity' || qInfo.productSubType === 'CarRental' || qInfo.productSubType === 'Transfer') && qInfo.productInfo.option && qInfo.productInfo.option.notes && qInfo.productInfo.option.notes.length > 0) {
        if (qInfo.productInfo.option.notes)
            isNotesHide = checkIfNotesTooBig(qInfo.productInfo.option.notes);

        if (isNotesHide) {
            resProductArr = getProductsNotesArray(qInfo.productInfo.option.notes, qInfo.productInfo.name);
            if (resProductArr.length > 0) {
                arrNotesProdcuts.push(resProductArr);
            }
        }
    }
    else if (qInfo.productSubType === 'Hotel' && qInfo.productInfo) {
        if (qInfo.productInfo.rooms && qInfo.productInfo.rooms.length > 0) {
            let finalRooms = qInfo.productInfo.rooms.filter(x =>
                x.priceBar.status === "CONFIRMED" ||
                x.priceBar.status === "ONREQUEST" ||
                x.priceBar.status === "PRINTED" ||
                x.priceBar.status === "REFUND" ||
                x.priceBar.status === "PENALTY" ||
                x.priceBar.status === "UNDEFINED" || // PREVENTIVO
                x.priceBar.status === "MANAGE" // PREVENTIVO
            );
            /* controlla che tutte le note dell'hotel siano contenute nel massimo numero di righe */
            finalRooms.forEach(function (room) {
                if (room.notes.length > 0 && room.notes[0] != "") {
                    if (room.notes.length > 2)
                        isNotesHide = true;
                    else
                        isNotesHide = checkIfNotesTooBig(room.notes);
                }
            });

            /* se le note eccedono il max, allora le inserisce nell'array */
            if (isNotesHide) {
                finalRooms.forEach(function (room) {
                    if (room.notes.length > 0 && room.notes[0] != "") {
                        resProductArr = getProductsNotesArray(room.notes, qInfo.productInfo.name, " (Camera " + room.sequence + ")");
                        if (resProductArr.length > 0) {
                            arrNotesProdcuts.push(resProductArr);
                        }
                    }
                });
            }
        }
    }
    else if (qInfo.productSubType === 'Cruise') {
        if (qInfo.productInfo.note && qInfo.productInfo.note != "")
            isNotesHide = checkIfNotesTooBig([qInfo.productInfo.note]);

        /* se le note eccedono il max, allora le inserisce nell'array */
        if (isNotesHide) {
            if (qInfo.productInfo.note != "") {
                resProductArr = getProductsNotesArray([qInfo.productInfo.note], qInfo.productInfo.name);
                if (resProductArr.length > 0) {
                    arrNotesProdcuts.push(resProductArr);
                }
            }
        }
    }
}

export function setFlightTaxesArr(qInfo, arrTaxes) {
    var arrTmp = { taxesPrice: 0 }
    if (qInfo && qInfo.passengerFareComponents && qInfo.passengerFareComponents.length > 0) {
        qInfo.passengerFareComponents.forEach(function (fare) {
            arrTmp["taxesPrice"] = arrTmp["taxesPrice"] + fare.taxesPrice;
            arrTmp["currency"] = fare.currency;
            arrTmp["wsBookingId"] = qInfo.priceBar.wsBookingId;
        });
    }
    else
        return;

    arrTaxes.push(arrTmp);
}


export function getAllFirstUppercase(str) {
    let lower = str.toLowerCase();
    const arr = lower.trim().split(" ");

    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    
    return arr.join(" ");
}


export function getFirstUppercase(string) {
    if (!string)
        return string;

    return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export function getPaxGenderVoucher(pax, BookParameterId) {
    let res = "";
    if (pax && pax.infos && pax.infos.length > 0) {
        pax.infos.forEach((pax) => {
            if (pax.paramName === BookParameterId.Personal.Gender) {
                res = "(" + pax.paramValue + ")";
            }
        });
    }

    return res;
}

export function getNoteCut(notes) {
    let noteRes = "";
    let lengthNotes = configData.Settings.LengthNotesPrint ? configData.Settings.LengthNotesPrint : 200;

    for (var i = 0; i < notes.length; i++) {
        noteRes = noteRes + notes[i];
        if (noteRes.length > lengthNotes) {
            noteRes = noteRes.replaceAll("<br/>", "").replaceAll("<br />", "").slice(0, lengthNotes);
            break;
        }
    }

    return replaceAllStyleElements(noteRes);
}