import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { Loading } from '../../../Common/Loading';
import { Error } from '../../../Common/Error';
import { ActivityStaticContainer } from "./ActivityStaticContainer";


export const ActivityModalStatic = ({ activityId, modalCloseProductId }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const cultureCode = cookies.get("CultureName");

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [item, setItem] = useState(null);

    useEffect(() => {

        let myModal = document.getElementById("schedStaticActivity");
        myModal.addEventListener('hidden.bs.modal', function () {
            modalCloseProductId(null);
        });

    }, []);

    useEffect(() => {

        if (activityId) {
            getActivity();

            let myBtn = document.getElementById("btn_openModalActivity");
            myBtn.click();
        }

    }, [activityId]);

    async function getActivity() {
        setIsLoading(true);
        setIsError(false);

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}activity/GetActivityData/${activityId}/${cultureCode}`, requestOption);
        const response = await fetchedRes.json();

        if (response.success) {
            setItem(response.activity);

            setIsLoading(false);
        } else {
            setIsError(true);
            console.error(response.errorMsg);
        }
    }

    return (
        <>
            <a id="btn_openModalActivity" data-bs-toggle="modal" data-bs-target="#schedStaticActivity"></a>
            <div className="modal fade" id="schedStaticActivity" tabIndex="-1" aria-labelledby="schedStaticActivityLabel" aria-hidden="true">
                <div className="modal-dialog modal-xxl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="schedStaticActivityLabel">
                                <data m3lab="ActionMenu.Detail">{t(`ActionMenu:Detail`)}</data>
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {isLoading && !isError && <Loading textMsg={t('MyBook:Loading')} />}
                            {!isLoading && isError && <Error textMsg={t('Error:GenericBlog')} />}
                            {
                                !isLoading && !isError && activityId && item && <ActivityStaticContainer
                                    activityId={activityId}
                                    activityDetail={item}
                                    modeView="modal" />
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                <data m3lab="Button.Close">{t('Button:Close')}</data>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}