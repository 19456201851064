export const isValidGiftCard = (giftCard) => {
    if (giftCard.usedOn)
        return !isUsedGiftCard(giftCard);

    if (giftCard.expiresOn) {
        var expiresOnDate = new Date(giftCard.expiresOn);
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        return expiresOnDate.getTime() > today.getTime();
    }

    return true;
}

export const isUsedGiftCard = (giftCard) => {
    if (giftCard.usedOn)
        return true;

    return false;
}