import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../../js/Utils.js';

export const CruiseBaseInfo = ({ cruiseData }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const { t } = useTranslation();

    return (
        <>
            <div className="card bg-gray-100 table small text-muted w-100">
                <div className="row p-2">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-0">
                        <span className="px-2 small pt-">
                            <label className="h6"><data m3lab="Product.Activity.Duration">{t('Product:Cruise:Company')}: </data></label>
                            <var>{cruiseData.providerName}</var>
                        </span>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-0">
                        <span className="px-2 small pt-1">
                            <label className="h6"><data m3lab="Product.Activity.Code">{t('Product:Cruise:Ship')}: </data></label>
                            <var>{cruiseData.shipName}</var>
                        </span>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-0">
                        <span className="px-2 small pt-1">
                            <label className="h6"><data m3lab="Product.Activity.Code">{t('Product:Cruise:Duration')}: </data></label>
                            <var>
                                <span className="mr-1">{cruiseData.nights}</span>
                                {
                                    cruiseData.nights === 1
                                        ? t('Product:Cruise:Night')
                                        : t('Product:Cruise:Nights')
                                }
                            </var>
                        </span>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-0">
                        <span className="px-2 small pt-1">
                            <label className="h6"><data m3lab="Product.Activity.Code">{t('Product:Cruise:Boarding')}: </data></label>
                            <var>{cruiseData.departureSegment?.portName}</var>
                        </span>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-0">
                        <span className="px-2 small">
                            <label className="h6"><data m3lab="Product.Activity.Code">{t('Product:Cruise:Landing')}: </data></label>
                            <var>{cruiseData.arrivalSegment?.portName}</var>
                        </span>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-0">
                        <span className="px-2 small">
                            <label className="h6"><data m3lab="Product.Activity.Code">{t('Product:Cruise:Departure')}: </data></label>
                            <var>{formatDateTime(cruiseData.departureSegment?.date, cultureName)}</var>
                        </span>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-0">
                        <span className="px-2 small">
                            <label className="h6"><data m3lab="Product.Activity.Code">{t('Product:Cruise:Arrival')}: </data></label>
                            <var>{formatDateTime(cruiseData.arrivalSegment?.date, cultureName)}</var>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}