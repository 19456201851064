import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Loading } from '../../Common/Loading';
import configData from "../../../appsettings.json";
import { formatDateTime } from '../../../js/Utils.js';
import { DynamicNote } from '../DynamicNote';
import { BookingNote } from '../BookingNote';
import { replaceAllStyleElements, getPaxGenderVoucher } from '../../../js/PrintUtility';
import { useTranslation } from 'react-i18next';
import { BookParameterId } from '../../../js/Constant';

export const VoucherTransferDetail = ({ id, customProductDetail, customDynamicNote, customBookingNote }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [productDetail, setProductDetail] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        const getProductDetail = async (inputData) => {
            const response = callGetProductDetail(inputData);
        }
        getProductDetail({ PNR: id, ProductType: configData.Settings.Products.Transfer.IdTipoProdotto, Options: { WebsiteBaseUrl: configData.Settings.OldWebSite_BaseUrl } });

    }, []);

    function onCustomDynamicNote(dynamicNote) {
        customDynamicNote(dynamicNote);
    }
    function onCustomBookingNote(bookingNote) {
        customBookingNote(bookingNote);
    }

    async function callGetProductDetail(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetReservationDetail', requestOption);
        const response = await fetchedRes.json();
        setProductDetail(response);
        customProductDetail(response);
        setIsLoading(false);
    }

    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : (
                    <>
                        <div className="stepHeader text-center mt-2 mb-2" style={{ borderRadius: "5px", padding: "7px" }}>
                            <h5 className="color-text-voucher">{t("Print:Voucher:Transfer")} &nbsp;- &nbsp; {t("Print:Voucher:BookingNumber")}: {productDetail.providerRecordLocatorId}</h5>
                        </div>

                        <div style={{ border: "1px solid grey", borderRadius: "5px" }}>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <span><label>{t("Print:Voucher:Service")}:</label> {productDetail.name}</span>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <span><label>Ref Pax:</label> {productDetail.refPax}</span>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <span><label>{t("Print:Voucher:DataArrival")}: </label> {formatDateTime(productDetail.outbound.date, cultureName, { withTime: true })}</span>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    {productDetail.isRoundTrip &&
                                        <span><label>{t("Print:Voucher:DataReturn")}: </label> {formatDateTime(productDetail.return.date, cultureName, { withTime: true })}</span>
                                    }
                                </div>
                                {productDetail.passengers && productDetail.passengers.length > 0 && productDetail.passengers.map(pax =>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <span key={pax.index} ><label>{t("Print:Voucher:Pax")} {pax.index} :</label> {pax.fullName} {getPaxGenderVoucher(pax, BookParameterId)} {pax.paxType !== "Adult" && pax.paxType}</span>
                                    </div>
                                )}
                                {productDetail.providerReservationId &&
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <span><label>{t("Print:Voucher:BookingNumber")}: </label> {productDetail.providerReservationId}</span>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="stepHeader text-center mt-2" style={{ borderRadius: "5px", padding: "7px" }}>
                            <h5 className="color-text-voucher">{t("Print:Voucher:BookingDetailReservationNumber")}:&nbsp;{productDetail.priceBar.wsBookingId}</h5>
                        </div>


                        <div className="container">
                            <div className="row mt-2">
                                <div className={"col-12 col-sm-12 col-md-12 pl-0 " + (productDetail.isRoundTrip ? "col-lg-6" : "col-lg-12")}>
                                    <div className="card border-gray-500  mb-2 document-print-6">
                                        <div className="p-2">
                                            <label>{t("Print:Voucher:Arrival")}</label>
                                            <div><label>Pick up: </label><span> {productDetail.outbound.from}</span></div>
                                            <div><label>Drop off: </label><span> {productDetail.outbound.to}</span></div>
                                            {productDetail.flightInfo !== null && 
                                            <>
                                                {productDetail.flightInfo.outboundFlightNumber && <div> <label>{t("Print:Voucher:FlightNum")}:</label> <span> {productDetail.flightInfo.outboundFlightNumber}</span></div>}
                                                {productDetail.flightInfo.outboundFlightArrivalTime && <div> <label>{t("Print:Voucher:FlightTime")}:</label> <span> {productDetail.flightInfo.outboundFlightArrivalTime}</span></div>}
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {productDetail.isRoundTrip &&
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 document-print-6 pr-0">
                                            <div className="card border-gray-500  mb-2">
                                                <div className="p-2">
                                                    <label>{t("Print:Voucher:Return")}</label>
                                                    <div><span><label>Pick up: </label>{productDetail.return.from}</span></div>
                                                    <div><span><label>Drop off: </label>{productDetail.return.to}</span></div>
                                                    {productDetail.flightInfo !== null && productDetail.flightInfo.returnFlightNumber &&
                                                        <>
                                                        {productDetail.flightInfo.returnFlightNumber && <div><label>{t("Print:Voucher:FlightNum")}:</label> <span> {productDetail.flightInfo.returnFlightNumber}</span></div>}
                                                        {productDetail.flightInfo.returnFlightDepartureTime && <div><label>{t("Print:Voucher:FlightTime")}:</label> <span> {productDetail.flightInfo.returnFlightDepartureTime}</span></div>}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                    </div>
                                }
                                <div className="card mt-4 border-gray-500 mb-2">
                                    <div className="card">
                                        <div className="p-2">
                                            <div>{t("Print:Voucher:Characteristics")}</div>
                                            <div>
                                                <span><label>{t("Print:Voucher:Passengers")}:</label> {productDetail.adults + productDetail.children}</span>
                                                {productDetail.flightInfo !== null &&
                                                    <>
                                                    </>
                                                }
                                                {productDetail.vehicle !== null &&
                                                <>
                                                    <span> <label>{t("Print:Voucher:VehicleType")}:</label> <span>{productDetail.vehicle.type} </span></span>
                                                    {productDetail.vehicle.numberOfBags && <span><label>{t("Print:Voucher:BagsNumber")}:</label> <span> {productDetail.vehicle.numberOfBags} </span></span>}
                                                </>
                                                }
                                            </div>
                                            <div style={{ textAlign: "justify" }}>
                                                {productDetail.note &&
                                                    <span dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(productDetail.note) }}></span>
                                                }
                                            </div>
                                            <div className="mt-2" style={{ textAlign: "justify" }}>
                                                {productDetail.trxText &&
                                                    <span dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(productDetail.trxText) }}></span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {<BookingNote idPratica={productDetail.idPratica} customBookingNote={onCustomBookingNote} />}
                                {<DynamicNote pageId="11" idTipoProdotto={configData.Settings.Products.Transfer.IdTipoProdotto} productDetail={productDetail} customDynamicNote={onCustomDynamicNote} />}
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );

}