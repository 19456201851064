import L from "leaflet";
import configData from "../../appsettings.json";

L.Icon.Default.imagePath = configData.Settings.CDN_Common + '/lib/libsMap/assets/vendor/leaflet-color-markers/'

L.Icon.Default.mergeOptions({
    iconUrl: `${configData.Settings.CDN_Common}/lib/libsMap/assets/vendor/leaflet-color-markers/marker-icon-blue.png`,
    iconRetinaUrl: `${configData.Settings.CDN_Common}/lib/libsMap/assets/vendor/leaflet-color-markers/marker-icon-2x-blue.png`,
    shadowUrl: `${configData.Settings.CDN_Common}/lib/libsMap/assets/vendor/leaflet-color-markers/marker-shadow.png`,
});

const colors = [
    "blue",
    "gold",
    "red",
    "green",
    "orange",
    "yellow",
    "violet",
    "grey",
    "black",
    "white",
];

const options = {
    shadowUrl: `${configData.Settings.CDN_Common}/lib/libsMap/assets/vendor/leaflet-color-markers/marker-shadow.png`,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
};
export default colors.reduce((obj, color) => {
    return {
        ...obj,
        [color]: {
            origin: new L.Icon({
                iconUrl: `${configData.Settings.CDN_Common}/lib/libsMap/assets/vendor/leaflet-color-markers/marker-icon-${color}.png`,
                iconRetinaUrl: `${configData.Settings.CDN_Common}/lib/libsMap/assets/vendor/leaflet-color-markers/marker-icon-2x-${color}.png`,
                ...options,
            }),
            empty: new L.Icon({
                iconUrl: `${configData.Settings.CDN_Common}/lib/libsMap/assets/vendor/leaflet-color-markers/marker-icon-${color}-x.png`,
                iconRetinaUrl: `${configData.Settings.CDN_Common}/lib/libsMap/assets/vendor/leaflet-color-markers/marker-icon-2x-${color}-x.png`,
                ...options,
            }),
            numbers: [...Array(100).keys()].map(
                (num) =>
                    new L.Icon({
                        iconUrl: `${configData.Settings.CDN_Common}/lib/libsMap/assets/vendor/leaflet-color-markers/${color}/marker-icon-${color}-${num}.png`,
                        iconRetinaUrl: `${configData.Settings.CDN_Common}/lib/libsMap/assets/vendor/leaflet-color-markers/${color}/marker-icon-2x-${color}-${num}.png`,
                        ...options,
                    })
            ),
        },
    };
}, {});