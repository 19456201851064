import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import configData from "../../../../appsettings.json";
import { M3Icon } from "../../../Common/M3Icon";


export const HotelServices = ({ services, category }) => {
    const { t } = useTranslation();

    const [isGeneral, setIsGeneral] = useState(false);
    const [isRoom, setIsRoom] = useState(false);
    const [isHotel, setIsHotel] = useState(false);

    const amenities = [
        { id: 0, name: "Generico", iconName: "" },
        { id: 1, name: "Accesso a Internet", iconName: "Wifi" },
        { id: 2, name: "SPA-Benessere", iconName: "SPA" },
        { id: 3, name: "Fitness", iconName: "Fitness" },
        { id: 5, name: "Animali Ammessi", iconName: "Pet" },
        { id: 8, name: "Business Center", iconName: "BusinessCenter" },
        { id: 9, name: "Cassetta di Sicurezza", iconName: "SafeBox" },
        { id: 10, name: "Parcheggio Auto", iconName: "Parking" },
        { id: 16, name: "Navetta Aeroportuale", iconName: "AirportShuttle" },
        { id: 17, name: "Piscina", iconName: "Pool" },
        { id: 24, name: "Aria Condizionata", iconName: "AirCondiction" },
        { id: 65, name: "Mini Club", iconName: "MiniClub" },
        { id: 72, name: "Reception", iconName: "Reception" },
        { id: 73, name: "Ristorante", iconName: "Restourant" },
        { id: 84, name: "Carta di Credito", iconName: "BusinessCenter" },
        { id: 85, name: "Golf", iconName: "Golf" },
        { id: 87, name: "Accessibile ai Disabili", iconName: "Accessible" },
        { id: 88, name: "Internt to be payed", iconName: "" },
        { id: 89, name: "Clean & Safe", iconName: "" },
        { id: 90, name: "Safe", iconName: "" },
        { id: 91, name: "Covid Protected", iconName: "" }
    ];

    useEffect(() => {
        //setCategories();
    }, []);

    function setCategories() {
        if (category === "Generale") {
            setIsGeneral(true);
        }
        else if (category === "Camere") {
            setIsRoom(true);
        }
        else if (category === "Hotel") {
            setIsHotel(true);
        }
    }

    function getAmenitiesFromArr(code) {
        let res = null;
        amenities.forEach(function (amenity) {
            if (amenity.id === parseInt(code))
                res = amenity;
        });

        return res;
    }

    return (
        <>
            <p className="h6"><data m3lab="Product:Structure.Service">{t(`Product:Structure:Service`)}</data></p>
            <div className="card bg-gray-100 table small text-muted w-100">
                <div className="row ml-0">
                    {/*filter(x => (x.isGeneral === isGeneral && x.isHotel === isHotel && x.isRoom === isRoom))*/}
                    {services.map((service, key) =>
                        <div key={key} className="col-lg-6 col-sm-12 col-md-12 p-1">
                            <var>{getAmenitiesFromArr(service.code) ? <M3Icon iconName={getAmenitiesFromArr(service.code).iconName} externalClass="icon-10 label" /> : ""} {service.name}</var>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}