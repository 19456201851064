import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { Loading } from '../../Common/Loading';
import { formatPrice } from '../../../js/Utils.js';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";

export const OrderDetailScadenziarioPayCcModal = ({ orderNumber, rataIdx, paymentData }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [iframeUrl, setIframeUrl] = useState();

    const [showBtnConfirm, setShowBtnConfirm] = useState(true);
    const [showBtnClose, setShowBtnClose] = useState(true);

    const onClose = () => {
        window.location.reload(true);
    }

    async function onConfirm() {
        setIsLoading(true);

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ OrderNumber: orderNumber, RataIdx: rataIdx, WebsiteBaseUrl: configData.Settings.Base_Url })
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'PaymentGateway/GetPaymentGatewayUrlForCustomAmount', requestOption);
        const response = await fetchedRes.json();

        setIsLoading(false);
        setIsFinished(true);
        setShowErrorMsg(!response.success);

        if (response.success) {
            if (response.isRedirectOnly) {
                window.location.href = response.paymentUrl;
            } else {
                setIframeUrl(response.paymentUrl);
            }
        }
    }


    return (
        <div className="modal fade" id={"OrderDetailScadenziarioPayCc_" + orderNumber} tabIndex="-1" aria-labelledby={"OrderDetailScadenziarioPayCc_" + orderNumber} aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{t("MyBook:PayWithCreditCard")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {
                            isLoading
                                ? (<Loading textMsg={t('MyBook:Loading')} />)
                                : (!isFinished
                                    ? (
                                        <div className="row">
                                            <div className="col-12">
                                                <p><label>{t("MyBook:Product")}:</label> <span>{paymentData.productName}</span></p>
                                            </div>
                                            <div className="col-12">
                                                <p><label>{t("MyBook:TotalToPay")}:</label> <span>{formatPrice(paymentData.amount, paymentData.currency, cultureName)}</span></p>
                                            </div>
                                            <div className="col-12">
                                                <p>{t("MyBook:OnClickPay")}</p>
                                            </div>
                                        </div>
                                    )
                                    : (
                                        <div className="row">
                                            <div className="col-12" >
                                                <iframe src={iframeUrl} frameBorder="0" style={{ width: '100%', minHeight: '700px' }} />
                                            </div>
                                        </div>
                                    )
                                )
                        }
                    </div>
                    <div className={isLoading ? ('modal-footer d-none') : ('modal-footer')}>
                        <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal" onClick={onClose}>{t("MyBook:Close")} </button>
                        {!isFinished && paymentData.amount > 0 ? (<button type="button" className="btn bt-sm tp-btn-confirm" onClick={onConfirm}>{t("MyBook:Pay")}</button>) : (<></>)}
                    </div>
                </div>
            </div>
        </div>
    );
}
