import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CruiseDetailCabinCategory } from './CruiseDetailCabinCategory';
import { CancelPolicyModal } from '../../Common/CancelPolicyModal.js';

export const CruiseDetailCabinCategoryGroup = ({ cabinCategoryGroup, onClickBook, showPriceBar, showCost, enableCanAddToCart, quotationInfo, templateInfo }) => {

    const { t } = useTranslation();

    const [cancellationPolicy, setCancellationPolicy] = useState(null);

    function onOpenCancelPolicyDetail(cancellationPolicy) {
        setCancellationPolicy(cancellationPolicy);
    }

    return (
        <>
            <div className="card border-light bg-gray-200 small m-2 roomToSelect">
                <div className="row small py-1 cursor-pointer">
                    <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10 h6 px-2 m-0 pl-3 pt-2">
                        <var>{cabinCategoryGroup.name}</var>
                    </div>
                    <div className="col-6 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-md-0 pt-2">
                        <data m3lab="Product.Structure.Filter.CityTax">
                            {
                                cabinCategoryGroup.url && <a href={cabinCategoryGroup.url} target="_blank">{t("Product:Cruise:CabinDetail")}</a>
                            }
                        </data>
                    </div>
                </div>
                {
                    cabinCategoryGroup.cabinCategories && cabinCategoryGroup.cabinCategories.map((cabinCategory, index) => {
                        return <React.Fragment key={index}>
                            <CruiseDetailCabinCategory cabinCategory={cabinCategory}
                                enableCanAddToCart={enableCanAddToCart}
                                quotationInfo={quotationInfo}
                                templateInfo={templateInfo}
                                showPriceBar={showPriceBar}
                                onClickBook={onClickBook}
                                showCost={showCost}
                                onOpenCancelPolicyDetail={onOpenCancelPolicyDetail}
                            />
                        </React.Fragment>
                    })
                }
            </div>

            <CancelPolicyModal
                cancellationPolicy={cancellationPolicy}
                autoOpen={true}
            />
        </>
    );
}