import React, { useEffect } from 'react';
import { Carousel } from 'primereact/carousel';

export const WebContentCarousel = ({ item }) => {

    const idModal = "WebContentCarousel_" + getRandomInt();
    const idCustomHeader = "WebContentHeader_" + getRandomInt()
    function getRandomInt() {
        return Math.floor(Math.random() * 100000);
    }

    useEffect(() => {

        try {
            let myContainer = document.querySelector(`#${idModal}`);

            myContainer.querySelector('.p-carousel-next').click();
            myContainer.querySelector('.p-carousel-prev').click();

            const header = document.querySelector(`#${idCustomHeader}`)
            let lastHeaderElement = header.lastElementChild
            while (lastHeaderElement) {
                if (lastHeaderElement.lastElementChild) {
                    console.log(lastHeaderElement.lastElementChild)
                    lastHeaderElement = lastHeaderElement.lastElementChild
                } else {
                    break
                }
            }
            lastHeaderElement.appendChild(myContainer)

            console.log(header.lastElementChild)
        } catch (ex) {
            console.error(ex);
        }

    }, [])

    const productTemplate = (slide) => {
        return (
            <div className="border-1 surface-border border-round m-2 text-center px-3">
                <div dangerouslySetInnerHTML={{ __html: slide }}></div>
            </div>
        );
    };

    return <>

        <div id={idCustomHeader} dangerouslySetInnerHTML={{ __html: item.carousel.header }}></div>

        <div id={idModal}>
            <div className="d-none d-sm-block">

            <Carousel value={item.carousel.slides}
                itemTemplate={productTemplate}
                circular
                autoplayInterval={600000}
                numVisible={item.carousel.numberSlideToShow} />
            </div>
            <div className="d-sm-none">

                <Carousel value={item.carousel.slides}
                    itemTemplate={productTemplate}
                    circular
                    autoplayInterval={600000}
                    numVisible={1} />
            </div>
        </div>

    </>
}