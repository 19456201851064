import React from 'react';
import { formatDateTime } from '../../../../../js/Utils.js';
import { replaceAllStyleElements, getPaxGenderVoucher } from '../../../../../js/PrintUtility';
import { BookParameterId } from '../../../../../js/Constant';

export const PrintVoucherCar = ({ productDetail, cultureName, t}) => {
    const renderSplitText = (text) => {
        var splittedText = text.split(".");
        let ret = "";
        splittedText.map((t, index) => ret += t + ".\r\n");
        return ret;
    }

    const renderInclusion = (inclusion) => {
        let arrInclusion = inclusion.split("-");
        if (arrInclusion.length > 1) {
            return (<span><label>{arrInclusion[0]}</label>
                {renderSplitText(arrInclusion[1])}
            </span>)
        } else {
            return (<span>{renderSplitText(arrInclusion[0])}</span>)
        }
    }

    return (
        <>
            {productDetail &&
            <>
                <div className="stepHeader text-center mt-2" style={{ borderRadius: "5px", padding: "7px" }}>
                    <h5 className="color-text-voucher">{t("Print:Voucher:CarRental")} &nbsp;- &nbsp;{t("Print:Voucher:BookingNumber")}: {productDetail.option.providerRecordLocatorId}</h5>
                </div>
                {/*spazio*/}
                <div style={{ padding: "7px" }}></div>

                <div style={{ border: "1px solid grey", borderRadius: "5px" }}>
                    <table>
                        <tr>
                            <td style={{ textAlign: "left" }}>
                                <span><label>{t("Print:Voucher:Service")}:</label> {productDetail.name}</span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left" }}>
                                <span><label>Ref. Pax:</label> {productDetail.option.refPax}</span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                                <span><label>{t("Print:Voucher:DatePickUp")}:</label> {formatDateTime(productDetail.pickUpInfo.pickupDateTime, cultureName, { withTime: true })}</span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                                <span><label>{t("Print:Voucher:DateDropOff")}:</label> {formatDateTime(productDetail.dropOffInfo.dropOffDateTime, cultureName, { withTime: true })}</span>
                            </td>
                        </tr>
                        {productDetail.option.passenger && productDetail.option.passenger &&
                            <tr>
                                <td key={productDetail.option.passenger.index} style={{ textAlign: "left" }}>
                                    <span><label> {t("Print:Voucher:Pax")} {productDetail.option.passenger.index}: </label> {productDetail.option.passenger.fullName}</span> {getPaxGenderVoucher(productDetail.option.passenger, BookParameterId)} {productDetail.option.passenger.paxType !== "Adult" && productDetail.option.passenger.paxType}
                                </td>
                            </tr>
                        }
                        {productDetail.option.providerReservationId &&
                            <tr>
                                <td><span><label>{t("Print:Voucher:BookingNumber")}:</label> {productDetail.option.providerReservationId}</span></td>
                            </tr>
                        }
                        {productDetail.priceBar.supplier &&
                            <tr>
                                <td><span><label>{t("Print:Voucher:Supplier")}:</label> {productDetail.priceBar.supplier}</span></td>
                            </tr>
                        }
                    </table>
                </div>

                {/*spazio*/}
                <div style={{ padding: "7px" }}></div>

                <div className="stepHeader color-text-voucher" style={{ borderRadius: "5px", padding: "7px" }}>
                    <h5>{t("Print:Voucher:BookingDetailReservationNumber")}:&nbsp; {productDetail.priceBar.wsBookingId}</h5>
                </div>

                {/*spazio*/}
                <div style={{ padding: "7px" }}></div>

                <table>
                    <tr>
                        <td style={{ border: "1px solid grey", borderRadius: "5px", }}>
                            <tr>
                                <td style={{ textAlign: "left" }}>
                                    <label>{t("Print:Voucher:PickUpPoint")}</label>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left" }}>
                                    <span>{productDetail.pickUpInfo.officeName}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left" }}>
                                    <span>{productDetail.pickUpInfo.address}</span>
                                </td>
                            </tr>
                            {productDetail.pickUpInfo.opening.openingTime && productDetail.pickUpInfo.opening.openingTime !== "-" &&
                                <tr>
                                    <td style={{ textAlign: "left" }}>
                                        <span><label>{t("Print:Voucher:Opening")}:</label> {productDetail.pickUpInfo.opening.openingTime} / {productDetail.pickUpInfo.opening.closingTime}</span>
                                    </td>
                                </tr>
                            }
                            {productDetail.pickUpInfo.subSupplierName &&
                                <tr>
                                    <td style={{ textAlign: "left" }}>
                                        <span><label>{t("Print:Voucher:Supplier")}:</label> {productDetail.pickUpInfo.subSupplierName}</span>
                                    </td>
                                </tr>
                            }
                            <tr>
                                <td style={{ textAlign: "left" }}>
                                    <label><i class="ph-phone-bold mr-2"></i> {productDetail.pickUpInfo.phone}</label>
                                    <label><i class="ph-envelope-simple-bold"></i> {productDetail.pickUpInfo.email}</label>
                                </td>
                            </tr> 
                        </td>
                        <td style={{ border: "1px solid grey", borderRadius: "5px", marginLeft: "10px"}}>
                            <tr>
                                <td style={{ textAlign: "left" }}>
                                    <label>{t("Print:Voucher:DropOffPoint")}</label>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left" }}>
                                    <span>{productDetail.dropOffInfo.officeName}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left" }}>
                                    <span>{productDetail.dropOffInfo.address}</span>
                                </td>
                            </tr>
                            {productDetail.dropOffInfo.opening.openingTime && productDetail.dropOffInfo.opening.openingTime !== "-" &&
                                <tr>
                                    <td style={{ textAlign: "left" }}>
                                        <span><label>{t("Print:Voucher:Opening")}:</label> {productDetail.dropOffInfo.opening.openingTime} / {productDetail.dropOffInfo.opening.closingTime}</span>
                                    </td>
                                </tr>
                            }
                            {productDetail.dropOffInfo.subSupplierName &&
                                <tr>
                                    <td style={{ textAlign: "left" }}>
                                        <span><label>{t("Print:Voucher:Supplier")}:</label> {productDetail.dropOffInfo.subSupplierName}</span>
                                    </td>
                                </tr>
                            }
                            <tr>
                                <td style={{ textAlign: "left" }}>
                                <label><i class="ph-phone-bold mr-2"></i> {productDetail.dropOffInfo.phone}</label>
                                <label><i class="ph-envelope-simple-bold"></i> {productDetail.dropOffInfo.email}</label>
                                </td>
                            </tr>   
                        </td>
                    </tr>
                </table>


                {/*spazio*/}
                <div style={{ padding: "7px" }}></div>

                <div style={{ border: "1px solid grey", borderRadius: "5px", }}>
                    <table>
                        <tr>
                            <td style={{ textAlign: "left" }}>
                                <h5>{t("Print:Voucher:Characteristics")}</h5>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left" }}>
                                <span><label>SIPP: </label>{productDetail.sippCode} </span> <span><label>{t("Print:Voucher:Passengers")}: </label> {productDetail.passengers} </span>
                                {productDetail.doors && <span> <label> {t("Print:Voucher:Doors")}: </label>{productDetail.doors} </span>}
                                {productDetail.luggages !== null ? (<span> <label> {t("Print:Voucher:Bagagges")}: </label> <span> {productDetail.luggages}</span></span>) : (<></>)}
                            </td>
                        </tr>
                    </table>
                    {productDetail.option.inclusions &&
                        <div style={{ maxWidth: "90%" }}>
                            <ul>
                                {productDetail.option.inclusions.map((inclusion, index) =>
                                    <li key={index}>
                                        {renderInclusion(inclusion)}
                                    </li>
                                )}
                            </ul>
                        </div>
                    }
                </div>
            </>
            }
        </>
    );
}