import React, { useEffect } from 'react';
import { FlightItemList } from './FlightItemList';
import { useTranslation } from 'react-i18next';

export const FlightModal = ({ flightFare, modalCloseProductId }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (flightFare) {
            let myBtn = document.getElementById("btn_openModalFlight");
            myBtn.click();
        }
    }, [flightFare]);

    useEffect(() => {
        let myModal = document.getElementById("schedStaticaModalFlight");
        myModal.addEventListener('hidden.bs.modal', function () {
            modalCloseProductId(null);
        });
    }, []);


    return (
        <>
            <a id="btn_openModalFlight" data-bs-toggle="modal" data-bs-target="#schedStaticaModalFlight"></a>
            <div className="modal fade" id={"schedStaticaModalFlight"} tabIndex="-1" aria-labelledby={"exampleModalLabel"} aria-hidden="true">
                <div className="modal-dialog modal-xxl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id={"exampleModalLabel"}>
                                <data m3lab="ActionMenu.Detail">{t(`ActionMenu:Detail`)}</data>
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <FlightItemList
                                item={flightFare}
                                isViewDetail={true}
                                showPriceBar={false}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                <data m3lab="Button.Close">{t('Button:Close')}</data>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}