import React from 'react';
import configData from "../../../appsettings.json";
import { formatPrice } from '../../../js/Utils.js';
import { translateQuotationCommissionableGroupType } from '../enums/quotationTOEnums';
import { useTranslation } from 'react-i18next';


export const QuotationTableMargin = (props) => {
    const { t } = useTranslation();

    return (
        <div className="row p-3">
            <div className="card col-12 col-sm-12 col-md-12 col-lg-6 border-gray-400">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 text-center bg-recap-custom h-100 align-middle">
                        {props.isCommissionable && <div className="h6 fw-bold text-white">Non Commissionabile</div>}
                        {!props.isCommissionable && <div className="h6 fw-bold text-white">Vendita Netta</div>}
                        <div className="h5 text-white">{formatPrice(props.marginItems.totalNotCommissionableAmount, props.marginItems.totalNotCommissionableCurrency, props.cultureName)}</div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                        <table className="p-0 h065 table-hover w-100">
                            <thead>
                                <tr>
                                    <th scope="col">{t("MyBook:Voice")}</th>
                                    <th scope="col">Imp.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.marginItems.tableMargin.notCommissionableItems && props.marginItems.tableMargin.notCommissionableItems.length > 0 ?
                                        props.marginItems.tableMargin.notCommissionableItems.map((item, index) => {
                                            return <tr key={`table_tr_not_comm_item_${index}`} className="p-0">
                                                <td>{translateQuotationCommissionableGroupType(item)}</td>
                                                <td>{formatPrice(item.grossPriceAmount, item.grossPriceCurrency, props.cultureName)}</td>
                                            </tr>;
                                        })
                                        : <tr className="p-0"><td>-</td><td>-</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 border-gray-400">
                <div className="row border border-gray-400 rounded">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 text-center bg-recap-custom">
                        {(props.isCommissionable || props.isQuotationTO) && <div className="h6 fw-bold text-white">{t("MyBook:Commission")}</div>}
                        {!props.isCommissionable && !props.isQuotationTO && <div className="h6 fw-bold text-white">Fee e Markup</div>}
                        <div className="h5 text-white">{formatPrice(props.marginItems.totalCommissionAmount, props.marginItems.totalCommissionCurrency, props.cultureName)}</div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                        <table className="h065 table-hover p-0 w-100">
                            <thead>
                                <tr>
                                    <th scope="col">Voce</th>
                                    <th scope="col">Imp.</th>
                                    <th scope="col">Comm.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.marginItems.tableMargin.commissionableItems && props.marginItems.tableMargin.commissionableItems.length > 0 ?
                                        props.marginItems.tableMargin.commissionableItems.map((item, index) => {
                                            return <tr key={`table_tr_comm_item_${index}`} className="p-0">
                                                <td>{translateQuotationCommissionableGroupType(item)}</td>
                                                <td>{formatPrice(item.grossPriceAmount, item.grossPriceCurrency, props.cultureName)}</td>
                                                <td>
                                                    {
                                                        item.totalIVACee && parseFloat(item.totalIVACee) > 0 ?
                                                            <>* </>
                                                            : ""
                                                    }
                                                    {
                                                        (item.totalCommissioneAmountWithoutIVA && item.totalCommissioneAmountWithoutIVA > 0)
                                                            ? <>{formatPrice(item.totalCommissioneAmountWithoutIVA, item.totalCommissioneCurrency, props.cultureName)}</>
                                                            : <>{formatPrice(item.totalCommissioneAmount, item.totalCommissioneCurrency, props.cultureName)}</>
                                                    }
                                                    {
                                                        item.totalIVACee && parseFloat(item.totalIVACee) > 0 && configData.Settings.QuotationTO.ShowIVARecaptItem ?
                                                            <>
                                                                ({item.totalIVACee})
                                                            </>
                                                            : ""
                                                    }
                                                </td>
                                            </tr>;
                                        })
                                        : <tr className="p-0"><td>-</td><td>-</td><td>-</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                props.marginItems.tableMargin.hasIVACee ?
                    <div className="col-12">
                        * essendo una pratica CEE, questi importi sono al netto dell'I.V.A.
                    </div>
                    : ""
            }
        </div >
    );
}