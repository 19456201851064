import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../Common/Loading';
import configData from "../../../appsettings.json";

export const Documents = ({ orderNumber }) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [orderDocs, setOrderDocs] = useState();

    useEffect(() => {
        const getOrder = async (inputData) => {
            const response = callGetOrder(inputData);
        }

        setIsLoading(true);
        getOrder(orderNumber);
    }, []);

    async function callGetOrder(orderNumber) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}reservation/GetOrderDocuments/${orderNumber}/${configData.Settings.documentManagementType}`, requestOption);
        const response = await fetchedRes.json();

        setOrderDocs(response);

        setIsLoading(false);
    }

    const renderDocuments = (documents, type) => {
        return (
            <>
                {documents.map((document, index) =>
                    <li key={index} className="list-group-item border-bottom">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <i className="ph-file-doc-bold h2"></i>
                            </div>
                            <div className="col ml-n2">
                                <h6 className="text-sm mb-0">{t(`DocumentsType:` + document.label)}</h6>
                                <p className="card-text small text-muted status confirm">
                                    {type === "ORDER"
                                        ? (<>{t("MyBook:NOrder")}: {document.id}</>)
                                        : (<>{document.productName} ({t("MyBook:NPractice")}: {document.id})</>)
                                    }
                                </p>
                            </div>
                            <div className="col-auto">
                                <div className="row">
                                    {
                                        document.viewUrl && <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 pr-2">
                                            <a href={document.viewUrl} target="_blank"><span className="btn btn-md badge bg-primary text-white mr-2">{t("MyBook:Visualize")} </span></a>
                                        </div>
                                    }
                                    {
                                        document.pdfUrl && <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <a href={document.pdfUrl} target="_blank"><span className="btn btn-md badge bg-primary text-white">{t("MyBook:Download")}</span></a>
                                        </div>
                                    }
                                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                        <div className="form-check form-switch mt-2">
                                            <input type="checkbox" className="form-check-input" id="customSwitch4" style={{ display: 'none' }} />
                                            <label className="form-check-label" htmlFor="customSwitch4" style={{ display: 'none' }}></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                )}
            </>
        );
    }


    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : (
                    <div className="card border-light p-0 p-md-4 mb-4">
                        <div className="card-header">
                             <h5 className="mb-0"><i className="ph-file-doc-bold"></i>{t('MyBook:Documents')}</h5>
                            <p className="text-sm mb-0">{t('MyBook:DocumentsDownloadAndSee')}:</p>
                        </div>
                        <ul className="list-group list-group-flush">
                            {/*Order Documents*/}
                            {orderDocs.orderDocuments.length > 0 && renderDocuments(orderDocs.orderDocuments, "ORDER")}

                            {/*Pratiche Documents*/}
                            {orderDocs.praticheDocuments.length > 0 && renderDocuments(orderDocs.praticheDocuments, "RESERVATION")}
                        </ul>
                    </div>
                )
            }
        </>
    );
}