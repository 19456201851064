import React, { useEffect, useState } from 'react';
import configData from "../../../../appsettings.json";
import Cookies from 'universal-cookie';
import { M3Icon } from "../../../Common/M3Icon";
import { useTranslation } from 'react-i18next';
import { formatDateTime, formatPrice } from '../../../../js/Utils.js';


export const FlightPriceFareDetail = ({ fares, leg, legKey }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    return (
        <>
            <label className="h6 mb-0 mt-1">
                <data m3ico="NavigateNext bg-blue text-white icon-15">
                    <M3Icon iconName="NavigateNext" externalClass="bg-blue text-white icon-15" />
                </data>
                <span className="mx-1">{formatDateTime(leg.departureDate, cultureName, { noYear: true })}</span>
                <label className="mx-1">({leg.departureAirportIATA})</label>
                <span className="mx-1">{leg.departureAirportName}</span>
                <label className="mx-1">({leg.arrivalAirportIATA})</label>
                <span className="mx-1">{leg.arrivalAirportName}</span>
                <span className="mx-1">
                    <data m3lab="Product:Flight:Item`">
                        {t(`Product:Flight:Item`)}:
                    </data>
                </span>
                <label className="mx-1">{leg.mktAirlineIATA + leg.flightNumber}</label>
            </label>
            <table className="w-100">
                <thead>
                    <tr className="h075">
                        <th>
                            <data m3lab="Product:Flight:Item`">
                                {t(`Product:Flight:Item`)}
                            </data>
                        </th>
                        <th>
                            <data m3lab="Product:Flight:FareBasis`">
                                {t(`Product:Flight:FareBasis`)}
                            </data>
                        </th>
                        <th>
                            <data m3lab="Product:Flight:BookigClass`">
                                {t(`Product:Flight:BookigClass`)}
                            </data>
                        </th>
                        <th>
                            <data m3lab="Product:Flight:PTC`">
                                {t(`Product:Flight:PTC`)}
                            </data>
                        </th>
                        <th>
                            <data m3lab="Product:Flight:Baggage`">
                            {t(`Product:Flight:Baggage`)}
                            </data>
                        </th>
                    </tr>
                </thead>
                <tbody className="h075">
                    {fares && fares.length > 0 && fares[0].renderFaresDetails && fares[0].renderFaresDetails.map((fare, key) => 
                        fare && fare.renderFareDetails.length > 0 && fare.renderFareDetails.filter(x => x.legIndex === legKey).map((renderFare, key) =>
                            <tr key={key}>
                                <td>{renderFare.flightsInfo}</td>
                                <td>{fare.fareBasis}</td>
                                <td>{renderFare.bookingClass}</td>
                                <td>{renderFare.ptc}</td>
                                <td>{renderFare.baggage ? renderFare.baggage && renderFare.baggage.replace("Pcs", "") : t(`Product:Flight:NoBaggage`) }</td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>
        </>
    );
}