import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";

export const ActivitySummary = ({ activity, isAlternative }) => {
    const { t } = useTranslation();
    const [iconName, setIconName] = useState('Activity');
    const [fontSize, setFontSize] = useState('');

    useEffect(() => {
        if (activity.productSubType === 41) {
            setIconName('Transfer');
        }

        if (isAlternative)
            setFontSize('h075');

    }, [activity]);

    return (
        <>
            <div>
                <data m3ico="Activity mr-2 icon-15">
                    <M3Icon iconName={iconName} externalClass="mr-2 icon-15 text-custom" hasLabel={false} />
                </data>
                <span className={fontSize}>
                    {
                        activity.multiItineraryName && t(activity.multiItineraryName)
                    }
                    {
                        !activity.multiItineraryName && activity.name
                    }
                </span>
            </div>
        </>
    );
}
