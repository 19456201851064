import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";

export const ProductFilterByTime = ({ rangeTimeInfo, type, onApplyFilter, resetSelectedItem, extraOptions }) => {
    const { t } = useTranslation();

    const [showFilter, setShowFilter] = useState(true);

    // Slider Parametri
    const [minVal, setMinVal] = useState(0);
    const [maxVal, setMaxVal] = useState(5000);
    const minValRef = useRef(null);
    const maxValRef = useRef(null);
    const range = useRef(null);
    const [minRangePrice, setMinRangePrice] = useState(0);
    // 1440 is number of minutes in a day
    const [maxRangePrice, setMaxRangePrice] = useState(1440);

    useEffect(() => {
        setMinVal(rangeTimeInfo.min);
        setMinRangePrice(covertHourInMinutes(rangeTimeInfo.min));

        setMaxVal(rangeTimeInfo.max);
        setMaxRangePrice(covertHourInMinutes(rangeTimeInfo.max));
    }, [rangeTimeInfo]);

    useEffect(() => {
        setMinVal(rangeTimeInfo.min);

        setMaxVal(rangeTimeInfo.max);
    }, [resetSelectedItem]);

    // Convert to percentage
    function getPercent(value) {
        let min = minRangePrice;
        let max = maxRangePrice;
        let converValue = covertHourInMinutes(value);

        return Math.round(((converValue - min) / (max - min)) * 100)
    }

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minVal);
            const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

            if (range.current) {
                range.current.style.left = `${minPercent}%`;
                range.current.style.width = `${maxPercent - minPercent}%`;
            }

        }
    }, [minVal, showFilter]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(+minValRef.current.value);
            const maxPercent = getPercent(maxVal);

            if (range.current) {
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [maxVal, showFilter]);

    function covertHourInMinutes(value) {
        if (!value)
            return 0;

        try {

            let valuesParts = value.split(":");
            let hour = parseInt(valuesParts[0]) * 60;
            let minutes = parseInt(valuesParts[1]);

            return hour + minutes;

        } catch (ex) {
            return value;
        }
    }

    function convertMinutesInHour(value) {
        if (!value)
            return '00:00';

        let number = value / 60;
        let hours = Math.floor(number) + "";
        if (hours.length < 2)
            hours = '0' + hours;

        let minutes = Math.floor(((number - Math.floor(number)) * 60)) + "";
        if (minutes.length < 2)
            minutes = '0' + minutes;

        return hours + ':' + minutes;
    }

    function onBlurValue() {
        let rangeTime = {
            min: minVal,
            max: maxVal
        };

        onApplyFilter(rangeTime, type);
    }

    function onShowFilter(event) {
        event.preventDefault();
        setShowFilter(!showFilter);
    }

    return (
        <div className={"rounded active text-primary h6 " + ((!extraOptions || !extraOptions.isSubFilter) ? "card bg-white shadow my-2" : "")} aria-current="true">
            {
                (!extraOptions || !extraOptions.isSubFilter) && <a href="#" className="text-decoration-none text-blue" onClick={(e) => onShowFilter(e)}>
                    <div className="row">
                        <div className="col-10 pl-4 pt-2">
                            <span className="p-2 text-left">
                                <data m3lab="Product.Activity.Filter.Pricing">{t('Product:Activity:Filter:Pricing')}</data>
                            </span>
                        </div>
                        <div className="col-1 text-left p-1">
                            <data m3ico="Remove text-blue">
                                <span className="material-icons text-blue">
                                    {showFilter && <M3Icon iconName="Remove" externalClass="text-blue" hasLabel={false} />}
                                    {!showFilter && <M3Icon iconName="Add" externalClass="text-blue" hasLabel={false} />}
                                </span>
                            </data>
                        </div>
                    </div>
                </a>
            }
            {
                showFilter && <div className="tp-multirange-slider py-2" style={{ height: '50px' }}>
                    <input
                        type="range"
                        min={minRangePrice}
                        max={maxRangePrice}
                        value={covertHourInMinutes(minVal)}
                        ref={minValRef}
                        onChange={(event) => {
                            let value = Math.min(parseInt(event.target.value), covertHourInMinutes(maxVal));
                            if (value > maxRangePrice)
                                value = maxRangePrice;

                            let valueHours = convertMinutesInHour(value);

                            setMinVal(valueHours);
                            event.target.value = valueHours.toString();
                        }}
                        onMouseUp={(e) => onBlurValue()}
                        className={(minVal > maxRangePrice - 100) ? "thumb thumb--zindex-5" : "thumb thumb--zindex-3"}
                    />
                    <input
                        type="range"
                        min={minRangePrice}
                        max={maxRangePrice}
                        value={covertHourInMinutes(maxVal)}
                        ref={maxValRef}
                        onChange={(event) => {
                            let value = Math.max(parseInt(event.target.value), covertHourInMinutes(minVal));
                            if (value > maxRangePrice)
                                value = maxRangePrice;

                            let valueHours = convertMinutesInHour(value);
                            setMaxVal(valueHours);
                            event.target.value = valueHours.toString();
                        }}
                        onMouseUp={(e) => onBlurValue()}
                        className="thumb thumb--zindex-4"
                    />

                    <div className="slider">
                        <div className="slider__track" />
                        <div ref={range} className="slider__range" />
                        <div className="slider__left-value">{minVal} h</div>
                        <div className="slider__right-value">{maxVal} h</div>
                    </div>
                </div>
            }
        </div>
    );
}