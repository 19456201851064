import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { formatPrice, formatDateTime, scrollTo } from '../../../js/Utils.js';
import { quotationTOStates, translateQuotationCommissionableGroupType } from '../enums/quotationTOEnums';
import { M3Icon } from '../../Common/M3Icon.js';

export const QuotationHeader = (props) => {
    const { t } = useTranslation();

    const [quotationStatus, setQuotationStatus] = useState(props.quotationHeader.status);

    const [noteStatusToAdd, setNoteStatusToAdd] = useState("");
    const [isValidNoteStatusToAdd, setValidStatusNoteToAdd] = useState(false);

    const [loadingSendMailAuth, setLoadingSendMailAuth] = useState(false);
    const [sendMailAuthResult, setSendMailAuthResult] = useState("");

    const [quotationOnlyBook, setQuotationOnlyBook] = useState(false);
    const [quotationOnlyConfirmAndBuy, setQuotationOnlyConfirmAndBuy] = useState(false);

    useEffect(() => {

        if (props.quotationAllowedOperation) {
            setQuotationOnlyBook(props.quotationAllowedOperation.OnlyBook.enabled);
            setQuotationOnlyConfirmAndBuy(props.quotationAllowedOperation.ConfirmAndBuy.enabled);
        }

    }, []);

    useEffect(() => {
        setSendMailAuthResult(props.sendMailAuthMsg);
        setLoadingSendMailAuth(false);
    }, [props.sendMailAuthMsg]);

    function callOnChangeQuotationStatus(event) {
        var newStatus = event.target.value;
        setQuotationStatus(newStatus);
        setNoteStatusToAdd("");
        setValidStatusNoteToAdd(false);

        var myBtn = document.getElementById("openQuotationTO_ChangeStatus");
        myBtn.click();
    }

    function confirmChangeStatus() {
        props.callOnChangeQuotationStatus({ status: quotationStatus, noteStatusToAdd: noteStatusToAdd });
    }

    function onTextAreaStatusChange(event) {
        var note = event.target.value;
        setValidStatusNoteToAdd(note && note.length > 0);
        setNoteStatusToAdd(note);
    }

    function onClickConfirmAll() {
        props.callConfirmAll();
    }

    function handleChangeIsVisibleAgency() {
        props.callChangeIsVisibleAgency(!props.quotationHeader.isAgencyVisible);
    }

    function onSendRequestAuthorization() {
        setLoadingSendMailAuth(true);
        props.callSendRequestAuthorization();
    }

    function callOnAuthorizeSend(isAuth) {
        setLoadingSendMailAuth(true);
        props.callSendAuthorization(isAuth)
    }

    function getClassDangerMarginNotValid(item) {
        let classDanger = "";
        const existTotalItems = props.quotationHeader.tablePricing.tableMargin.totalInfoItems
            && props.quotationHeader.tablePricing.tableMargin.totalInfoItems.length > 0;

        if (existTotalItems) {
            const minPerc = configData.Settings.QuotationTO.MinPercTotaleMargineLordo;
            const maxPerc = configData.Settings.QuotationTO.MaxPercTotaleMargineLordo;

            const myType = props.quotationHeader.tablePricing.tableMargin.totalInfoItems.map((x) => x.type);
            const existTotalWithMargin = myType.includes('TotaleMargineLordoCompresaIVA');

            if (item.type === 'TotaleMargineLordoCompresaIVA') {
                if (maxPerc > item.percentageCommissionable || minPerc < item.percentageCommissionable)
                    classDanger = "text-danger";
            } else if (item.type === 'TotaleMargineLordo' && !existTotalWithMargin) {
                if (maxPerc > item.percentageCommissionable || minPerc < item.percentageCommissionable)
                    classDanger = "text-danger";
            }

        }

        return classDanger;
    }

    return (
        <>
            <div className="row">
                <div className="col-12 col-sm-9 col-md-1 col-lg-1 border text-lg-center p-0  mybook-order-card">
                    <div><label>{t("MyBook:NQuotation")}</label><h6 className="fw-bold">{props.quotationHeader.ticketId && `${props.quotationHeader.ticketId} - `}{props.quotationHeader.quotationId}</h6></div>
                    {props.quotationHeader.numeroOrdine && <div><label>{t("MyBook:NOrder")}</label><h6 className="fw-bold">{props.quotationHeader.numeroOrdine}</h6></div>}
                    <div><label>{t("MyBook:Date")}</label> <h6 className="fw-bold">{formatDateTime(props.quotationHeader.createDate, props.cultureName, { twoDigits: true })}</h6></div>
                </div>
                <div className="col-12 col-sm-9 col-md-9 col-lg-9  border">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-3  mybook-order-card">
                            <div><label>{t("MyBook:QuotationName")}:</label> <h6 className="fw-bold">{props.quotationHeader.name}</h6></div>
                            <div><label>{t("MyBook:TravelAgency")}</label><h6 className="fw-bold">{props.quotationHeader.agencyName}</h6></div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-9 mybook-order-card">
                            <div className="row">
                                {
                                    !props.quotationHeader.authorizationToSend && props.quotationMode === 'QuotationTOWorking' && <div className="col-12 col-sm-12 col-md-12 col-lg-3 my-1 x-small mybook-mt-15">
                                        <button
                                            className="btn btn-outline-gray"
                                            disabled={loadingSendMailAuth}
                                            onClick={onSendRequestAuthorization} >
                                            {
                                                !loadingSendMailAuth && <><M3Icon iconName="Send" externalClass="h075" /> {t("MyBook:AskAuthorization")}</>
                                            }
                                            {
                                                loadingSendMailAuth && <><div className="spinner-border spinner-border-sm text-dark h6 " role="status">
                                                    <span className="sr-only"></span>
                                                </div></>
                                            }
                                        </button>
                                    </div>
                                }
                                {
                                    props.quotationMode === 'QuotationTOWorking' && <div className="col-12 col-sm-12 col-md-12 col-lg-3 my-1 x-small mybook-mt-15">
                                        <button className="btn mb-2 mr-2 tp-btn-confirm" onClick={props.callOnSelectedAddToCart}>
                                            <M3Icon iconName="Cart" externalClass="h075 text-white" /> Book
                                        </button>
                                    </div>
                                }
                                {
                                    quotationOnlyBook && <div className="col-12 col-sm-12 col-md-12 col-lg-3 my-1 x-small mybook-mt-15">
                                        <button className="btn mb-2 mr-2 tp-btn-confirm" onClick={props.callOnBuyQuotation}>
                                            <M3Icon iconName="Cart" externalClass="h075 text-white" /> {t("MyBook:Buy")}
                                        </button>
                                    </div>
                                }
                                {
                                    quotationOnlyConfirmAndBuy && <div className="col-12 col-sm-12 col-md-12 col-lg-3 my-1 x-small mybook-mt-15">
                                        <button className="btn mb-2 mr-2 tp-btn-confirm" onClick={props.callOnBuyAndConfirm}>
                                            <M3Icon iconName="Cart" externalClass="h075 text-white" />  {t("MyBook:Buy")}
                                        </button>
                                    </div>
                                }
                            </div>
                            <div className="mt-3">
                                {
                                    (props.quotationMode === 'QuotationTOWorking' || props.quotationMode === 'QuotationTOEndUser') && <a href={void (0)} onClick={event => scrollTo(event, "chatStakeholder")}>
                                        <label className="h5 m-2">{t("MyBook:QuotationNotes")}:</label>
                                        <label className="h5 mb-4"><span className="badge rounded p-2 h075 bg-primary text-uppercase mr-2">{props.quotationHeader.commentsTotal}</span> Comments</label>
                                        <label className="h5 mb-4"><span className="badge rounded p-2 h075 bg-info text-uppercase mr-2">{props.quotationHeader.commentsToReadTotal}</span> {t("MyBook:ToRead")}</label>
                                        <label className="h5 mb-4"><span className="badge rounded p-2 h075 bg-info text-uppercase mr-2"> {t("MyBook:See")}</span></label>
                                    </a>
                                }
                                {
                                    props.quotationMode === 'QuotationTOWorking' && <a href={void (0)} onClick={event => scrollTo(event, "noteInterne")}>
                                        <label className="h5 mb-4"><span className="badge rounded p-2 h075 bg-gray-300 text-uppercase mr-2">{props.quotationHeader.internalNotesTotal}</span> {t("MyBook:InternalNotes")} </label>
                                    </a>
                                }
                            </div>
                            {
                                props.quotationMode === 'QuotationTOWorking' && <div className="mt-3">
                                    <div className="text-danger">
                                        {props.quotationHeader.authorizationStatus === 'InRequest' && <span> {t("MyBook:InRequestText")}</span>}
                                        {props.quotationHeader.authorizationStatus === 'Reject' && <span> {t("MyBook:RejectSendText")}</span>}
                                    </div>
                                </div>
                            }
                            {
                                props.quotationMode === 'QuotationTOWorking' && props.quotationHeader.authorizationStatus === 'InRequest' && props.quotationHeader.canAuthorization && !loadingSendMailAuth && <div className="mt-3">
                                    <button className="btn mb-2 mr-2 tp-btn-confirm" onClick={e => callOnAuthorizeSend(true)}>
                                        <M3Icon iconName="Check" externalClass="h075" /> {t("MyBook:SendAuthorize")}
                                    </button>
                                    <button className="btn btn-outline-gray mb-2 mr-2" onClick={e => callOnAuthorizeSend(false)}>
                                        <M3Icon iconName="Remove" externalClass="h075" />{t("MyBook:NotAuthorizeSend")}
                                    </button>
                                </div>
                            }
                            {
                                props.quotationMode === 'QuotationTOWorking' && props.quotationHeader.authorizationStatus === 'InRequest' && props.quotationHeader.canAuthorization && loadingSendMailAuth && <div className="mt-3">
                                    <><div className="spinner-border spinner-border-sm text-dark h6 " role="status">
                                        <span className="sr-only"></span>
                                    </div></>
                                </div>
                            }
                            {<div className="mt-3"><h6 className="mt-2 mb-2">{sendMailAuthResult}</h6></div>}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-9 col-md-6 col-lg-2 border text-lg-center mybook-order-card">
                    <h6 className="fw-bold">{t("MyBook:TotalQuotation")}</h6>
                    {props.quotationMode === 'QuotationTOWorking' && <h6 className="fw-bold">{props.quotationHeader.tablePricing.isCommissionable ? "Commissionabile" : ""}</h6>}
                    <h4>{formatPrice(props.quotationHeader.tablePricing.totalPrice, props.quotationHeader.tablePricing.currency, props.cultureName)}</h4>
                    {
                        props.quotationMode === 'QuotationTOWorking' && <div className="form-floating">
                            <select id="ddlQuotationTOStatus" className="form-select" value={quotationStatus} onChange={callOnChangeQuotationStatus} aria-label="quotationTOStatus">
                                {
                                    quotationTOStates.map((item) => {
                                        return <option key={item.key} value={item.key}>{t(item.text)}</option>
                                    })
                                }
                            </select>
                            <label htmlFor="floatingSelect">{t("MyBook:State")}</label>
                        </div>
                    }
                    <div className="mt-2 mb-2">
                        {(props.quotationMode === 'QuotationTOWorking' || props.quotationMode === 'QuotationTOEndUser') && <button className="tp-btn-confirm btn-sm" onClick={onClickConfirmAll} >{t(`quotationto:button:confirmall`)}</button>}
                    </div>
                    {
                        props.quotationMode === 'QuotationTOWorking' && <div className="mt-2 mb-2">
                            <input type="checkbox"
                                id="btnradio_visibleagency"
                                name="btnradio_visibleagency"
                                defaultChecked={props.quotationHeader.isAgencyVisible}
                                onChange={handleChangeIsVisibleAgency} />
                            <label htmlFor="btnradio_visibleagency">
                                {t("MyBook:AgencyVisible")}
                            </label>
                        </div>
                    }
                </div>
            </div>

            <div className="p-0 pt-2">
                <div className=" card border-gray-400">
                    <div className="row">
                        {
                            props.quotationMode === 'QuotationTOWorking' && props.quotationHeader.tablePricing.tableMargin.totalInfoItems && props.quotationHeader.tablePricing.tableMargin.totalInfoItems.length > 0 ?
                                props.quotationHeader.tablePricing.tableMargin.totalInfoItems.map((item, index) => {
                                    return <div key={`table_tr_recap_comm_item_${index}`} className="col-12 col-sm-12 col-md-12 col-lg-3">
                                        <span>
                                            <label className={getClassDangerMarginNotValid(item)}>
                                                {translateQuotationCommissionableGroupType(item, props.quotationHeader.tablePricing.tableMargin.hasIVACee)}
                                            </label>
                                            {formatPrice(item.grossPriceAmount, item.grossPriceCurrency, props.cultureName)}
                                        </span>
                                    </div>;
                                })
                                : <></>
                        }
                    </div>
                </div>
            </div>

            {
                props.quotationMode === 'QuotationTOWorking' &&
                <>
                    <a id="openQuotationTO_ChangeStatus" data-bs-toggle="modal" data-bs-target="#quotationTO_ChangeStatus"></a>
                    <div className="modal fade" id="quotationTO_ChangeStatus" tabIndex="-1" aria-labelledby="quotationTO_ChangeStatusLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="quotationTO_ChangeStatusLabel">
                                        {t("MyBook:AddNoteChangeState")}
                                    </h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    {t("MyBook:NoteTextInsertChangeState")}
                                    <textarea className="form-control border border-light-gray" id="txtInternalNote" value={noteStatusToAdd} placeholder="Inserisci nota" rows="6" maxLength="1000" style={{ minHeight: '150px' }} onChange={onTextAreaStatusChange}></textarea>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t("Button:Cancel")}</button>
                                    <button type="button" className="btn bt-sm tp-btn-confirm" data-bs-dismiss="modal" onClick={confirmChangeStatus}>{t("Button:Save")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>


    );
}