import React from 'react';
import { formatPrice } from '../../js/Utils.js';
import { M3IconProductType } from '../Common/M3IconProductType.js';
import { useTranslation } from 'react-i18next';

export const DocOrderDetail = ({ order, visualizationType, userType, grouped, cultureName}) => {
    const { t } = useTranslation();

    const renderProducts = (pratiche, visualizationType, userType, cultureName) => {
        return (
            <>
                {pratiche.map(pratica =>
                    <tr key={pratica.idPratica}>
                        <td scope="row" className="text-centre h6">
                            {<M3IconProductType productType={pratica.tipoPratica} externalClass="h6" />}
                        </td>
                        <td className="text-left" style={{ minWidth: '250px' }}>
                            <div className="d-flex align-items-lg-start">
                                {pratica.productName}
                            </div>
                        </td>
                        <td>{formatPrice(pratica.invoicePrice, pratica.currency, cultureName)}</td>
                        <td>
                            {userType === 'A' && grouped === 'false'
                                ? <>{pratica.commissioneAgenziaPerc}%</>
                            : ''}
                        </td>
                        <td>{userType === 'A' && grouped === 'false'
                            ? formatPrice(pratica.commissioneAgenzia, pratica.currency, cultureName)
                            : ''}
                        </td>
                    </tr>
                )}
            </>
        );
    }

    return (
        <table className="table mb-0 document-invoice">
            <thead className="bg-light border-top">
                <tr>
                    <th scope="row" className="border-0 m-0 text-left">
                        Item
                    </th>
                    <th scope="row">
                        {t("Template:Service")}
                    </th>
                    <th scope="row">
                        {t("MyBook:Value")}
                    </th>
                    <th scope="row">
                        {userType === 'A' && grouped === 'false' ? '% Comm.' : ''}
                    </th>
                    <th scope="row">
                        {userType === 'A' && grouped === 'false' ? 'Val.Comm.' : ''}
                    </th>
                </tr>
            </thead>
            <tbody className="document-invoice text-right">
                {renderProducts(order.pratiche, visualizationType, userType, cultureName)}
            </tbody>
        </table>
    );
}
