import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";
import configData from "../../../../appsettings.json";
import { Loading } from '../../../Common/Loading';
import { Error } from '../../../Common/Error';
import { imageOnError } from '../../../../js/CDNUtility';


export const CarStaticContainer = ({ carId = null, isStaticView = false, carData = null }) => {
    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");
    const { t } = useTranslation();

    let params = new URLSearchParams(window.location.search);

    const [extraParamSearch, setExtraParamSearch] = useState({
        carId: carId,
        productName: ''
    });

    return (
        <>
            {carData && <>
                <div className="row px-2">
                    <div className="col-9">
                        <label><var>{carData.pickUpInfo && carData.pickUpInfo.officeName}</var></label>
                    </div>
                    <div className="col-3 text-right">
                        <button className="btn m-0 p-0 h6" m3Role="w_Favorite">
                            <data m3lab="Button.Prefer">{t(`Button:Prefer`)}</data>
                            <data m3ico="FavoriteOutline icon-10">
                                <M3Icon iconName="FavoriteOutline" externalclassName="icon-10" />
                            </data>
                        </button>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="h4">
                        <var>{carData.name }</var>
                    </div>
                    <div className="mb-3">
                        <var>{carData.supplierName}</var>
                        <var> - {carData.supplier && carData.supplier.supplierName}</var>
                    </div>
                </div>
                <div className="row p-3">
                    <div className="card p-1 col-12 col-sm-12 col-md-12 col-lg-6">
                        <img src={carData.imageURL} className="img-fluid" alt="..." onError={(e) => imageOnError(e, configData.Settings.Products.CarRental.IdTipoProdotto)} />
                    </div>
                    <div className=" col-12 col-sm-12 col-md-12 col-lg-6">
                        <p className="h6">
                           Info:
                        </p>
                        <div className="card bg-gray-100 table small text-muted w-100">
                            <div className="row p-2">
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 py-1">
                                    <span className="p-0 small">
                                        <label className="h6">
                                            <data m3lab="Product.RentCar.Type">{t('Product:RentCar:Type')}:</data>
                                        </label>
                                        <var> {carData.typeName}</var>
                                    </span>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 py-1">
                                    <span className="p-0 small">
                                        <label className="h6">
                                            <data m3lab="Product.RentCar.Pax">{t('Product:RentCar:Pax')}: </data>
                                        </label>
                                        <var> {carData.passengers}</var>
                                    </span>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 py-1">
                                    <span className="p-0 small">
                                        <label className="h6">
                                            <data m3lab="Product.RentCar.Door">{t('Product:RentCar:Door')}: </data>
                                        </label>
                                        <var> {carData.doors}</var>
                                    </span>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 py-1">
                                    <span className="p-0 small">
                                        <label className="h6">
                                            <data m3lab="Product.RentCar.Baggages">{t('Product:RentCar:Baggages')}: </data>
                                        </label>
                                        <var> {carData.luggages}</var>
                                    </span>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 py-1">
                                    <span className="p-0 small">
                                        <label className="h6">
                                            <data m3lab="Product.RentCar.AC">{t('Product:RentCar:AC')}: </data>
                                        </label>
                                        <var> {carData.airCondition}</var>
                                    </span>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 py-1">
                                    <span className="p-0 small">
                                        <label className="h6">
                                            <data m3lab="Product.RentCar.Fuel">{t('Product:RentCar:Fuel')}: </data>
                                        </label>
                                        <var> {carData.fuelAirCondName}</var>
                                    </span>
                                </div>
                                <div className="col-12 py-1">
                                    <span className="p-0 small">
                                        <label className="h6">
                                            <data m3lab="Product.RentCar.Transmission">{t('Product:RentCar:TransmissionAndDrive')}: </data>
                                        </label>
                                        <var> {carData.transmissionDriveName}</var>
                                    </span>
                                </div>
                               
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-1">
                                    <div className="p-0 small h6 mb-0">
                                        <data m3lab="Product.RentCar.SippCode">{t('Product:RentCar:SippCode')}:</data>
                                        <span className="ml-2 fw-bold h7">
                                            <var>
                                                <div title={carData.categoryName} className="mr-1 sippCode">
                                                    {carData.categoryLetter}
                                                </div>
                                                <div title={carData.typeName} className="mr-1 sippCode">
                                                    {carData.typeLetter}
                                                </div>
                                                <div title={carData.transmissionDriveName} className="mr-1 sippCode">
                                                    {carData.transmissionDriveLetter}
                                                </div>
                                                <div title={carData.fuelAirCondName} className="mr-1 sippCode">
                                                    {carData.fuelAirCondLetter}
                                                </div>
                                            </var>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row p-3 mb-2">
                    <div className=" col-12">
                        <div className="row detailed-features">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-2 px-0">
                                <div className="h6 bg-gray-300 w-100 p-1 pl-3 py-3 m-0 fw-bold">
                                    <data m3lab="Product.RentCar.PickupDeatil">
                                        {t('Product:RentCar:PickupDeatil')}
                                        {carData.pickUpInfo && <label className="pl-2" style={{ cursor: 'auto' }}>(Code: {carData.pickUpInfo.carOfficeCode})</label>}
                                    </data>
                                </div>
                                <div className="small text-justify border border-top-0 border-gray-300 p-3">
                                    <div>
                                        <span className="h6 fw-bold">
                                            <data m3lab="General.Phone">{t('General:Phone')}</data>
                                        </span>
                                    </div>
                                    <div className="mb-1">
                                        <var> {carData.pickUpInfo && carData.pickUpInfo.officePhone}</var>
                                    </div>

                                    <div>
                                        <span className="h6 fw-bold">
                                            <data m3lab="Product.RentCar.Time">{t('Product:RentCar:Time')}</data>
                                        </span>
                                    </div>
                                    <div className="mb-1">
                                        <var> {carData.pickUpInfo && carData.pickUpInfo.pickupDateTime}</var>
                                        <data m3ico="Info ico-15" data-bs-toggle="modal" data-bs-target="#orariPickUp">
                                            <M3Icon iconName="Info" externalClass="icon-15" />
                                        </data>
                                        {t('Product:RentCar:SeeDetails')}
                                    </div>

                                    <div>
                                        <span className="h6 fw-bold">
                                            <data m3lab="Product.RentCar.Place">{t('Product:RentCar:Place')}</data>
                                        </span>
                                    </div>
                                    <div className="mb-1">
                                        <var>{carData.pickUpInfo && carData.pickUpInfo.address}</var>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-0">
                                <div className="h6 bg-gray-300 w-100 p-1 pl-3 py-3 m-0 fw-bold">
                                    <data m3lab="Product.RentCar.DropOffDeatil">
                                        {t('Product:RentCar:DropOffDeatil')}
                                        {carData.dropOffInfo && <label className="pl-2" style={{ cursor: 'auto' }}>(Code: {carData.dropOffInfo.carOfficeCode})</label>}
                                    </data>
                                </div>
                                <div className="small text-justify border border-top-0 border-gray-300 p-3">
                                    <div>
                                        <span className="h6 fw-bold">
                                            <data m3lab="General.Phone">{t('General:Phone')}</data>
                                        </span>
                                    </div>
                                    <div className="mb-1">
                                        <var> {carData.dropOffInfo && carData.dropOffInfo.officePhone}</var>
                                    </div>

                                    <div>
                                        <span className="h6 fw-bold">
                                            <data m3lab="Product.RentCar.Time">{t('Product:RentCar:Time')}</data>
                                        </span>
                                    </div>
                                    <div className="mb-1">
                                        <var> {carData.dropOffInfo && carData.dropOffInfo.pickupDateTime}</var> {/* da lasciare pickupDateTime perchè usa la stessa classe */}
                                        <data m3ico="Info ico-15" data-bs-toggle="modal" data-bs-target="#orariDropOff">
                                            <M3Icon iconName="Info" externalClass="icon-15" />
                                        </data>
                                        {t('Product:RentCar:SeeDetails')}
                                    </div>

                                    <div>
                                        <span className="h6 fw-bold">
                                            <data m3lab="Product.RentCar.Place">{t('Product:RentCar:Place')}</data>
                                        </span>
                                    </div>
                                    <div className="mb-1">
                                        <var>{carData.dropOffInfo && carData.dropOffInfo.address}</var>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                {/* orari pick up */}
                <div className="modal fade" id="orariPickUp" tabindex="-1" aria-labelledby="orariPickUpLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="orariPickUpLabel">{t('Product:RentCar:PickUpTimes')}</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <table className="table w-100" style={{ fontSize: "14px" }}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th className="w-18">{t('Calendar:Day:Sunday')}</th>
                                            <th className="w-18">{t('Calendar:Day:Monday')}</th>
                                            <th className="w-18">{t('Calendar:Day:Tuesday')}</th>
                                            <th className="w-18">{t('Calendar:Day:Wedsnday')}</th>
                                            <th className="w-18">{t('Calendar:Day:Thursday')}</th>
                                            <th className="w-18">{t('Calendar:Day:Friday')}</th>
                                            <th className="w-18">{t('Calendar:Day:Saturday')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">{t('Product:RentCar:Times')}</th>
                                            {carData.pickUpInfo && carData.pickUpInfo.opening && carData.pickUpInfo.opening.length > 0 && carData.pickUpInfo.opening.map((time, idx) =>
                                                <td className="w-18" key={idx}>{time.openingTime}</td>
                                            )}
                                         </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                {/* orari drop off */}
                <div className="modal fade" id="orariDropOff" tabindex="-1" aria-labelledby="orariDropOffLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="orariDropOffLabel">{t('Product:RentCar:DropOffTimes')}</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <table className="table w-100" style={{ fontSize: "14px" }}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th className="w-18">{t('Calendar:Day:Sunday')}</th>
                                            <th className="w-18">{t('Calendar:Day:Monday')}</th>
                                            <th className="w-18">{t('Calendar:Day:Tuesday')}</th>
                                            <th className="w-18">{t('Calendar:Day:Wedsnday')}</th>
                                            <th className="w-18">{t('Calendar:Day:Thursday')}</th>
                                            <th className="w-18">{t('Calendar:Day:Friday')}</th>
                                            <th className="w-18">{t('Calendar:Day:Saturday')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">{t('Product:RentCar:Times')}</th>
                                            {carData.dropOffInfo && carData.dropOffInfo.opening && carData.dropOffInfo.opening.length > 0 && carData.dropOffInfo.opening.map((time, idx) =>
                                                <td className="w-18" key={idx}>{time.openingTime}</td>
                                            )}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
    );
}