import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../js/Utils.js';
import { getADTTotal, getCHDTotal } from '../../../js/PrintUtility';
import { IconChoosePrint } from './IconChoosePrint';

export const PrintCommissionsDetail = ({ marginItems, isPrintCliente, paxes, onHandleIsPrint, isConfirmPrint = true, extraOptions }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [items, setItems] = useState(null);
    const [tableTotals, setTableTotals] = useState(null);

    if (!extraOptions)
        extraOptions = {};

    if (!extraOptions.marginTop)
        extraOptions.marginTop = "mt-3"

    useEffect(() => {
        setItems(marginItems);

        if (marginItems && marginItems.length > 0) {

            let totals = {
                nettoTotal: 0,
                quotaTotal: 0,
                commTotal: 0,
                currency: null
            }

            setTotals(marginItems, totals);
        }

    }, [marginItems])

    function getProductName(item) {
        return item.productName ? item.productName : item.name;
    }

    function invoicePrice(item) {
        let value = null;
        if (isConfirmPrint) {
            value = item.pricing ? item.pricing.invoicePrice : item.invoicePrice;
        }

        if (!value) {
            return item.sellAmount;
        } else {
            return value;
        }
    }

    function commissioneAgenzia(item) {
        let value = null;

        if (isConfirmPrint) {
            value = item.pricing ? item.pricing.commission : item.commissioneAgenzia;
        }

        if (!value) {
            return item.commissionableAmount;
        } else {
            return value;
        }
    }

    function agencyFee(item) {
        let value = null;
        if (isConfirmPrint) {
            value = item.pricing ? item.pricing.agencyFee : item.agencyFee;
        }

        if (!value) {
            return 0;
        } else {
            return value;
        }
    }

    function currency(item) {
        let value = null;

        if (isConfirmPrint) {
            value = item.pricing ? item.pricing.valutaCliente : item.currency;
        }

        if (!value) {
            return item.sellCurrency;
        } else {
            return value;
        }
    }

    function setTotals(marginItemsExt, totals) {
        marginItemsExt.filter(x => ((x.isSelected || x.isActive) && x.productType === "Generic") || x.productType !== "Generic").forEach(function (item) {
            totals.nettoTotal = totals.nettoTotal + getNetto(item);
            totals.quotaTotal = totals.quotaTotal + getQuotaTotale(item);
            totals.commTotal = totals.commTotal + getCommissioneTotale(item);
            totals.currency = currency(item);
        });

        setTableTotals(totals);
    }

    function getNetto(item) {
        let price = 0;
        if (item.isCommissionable) {
            price = (invoicePrice(item) - commissioneAgenzia(item));
        }
        else {
            price = invoicePrice(item);
        }

        return price;
    }

    function getQuotaTotale(item) {
        let price = 0;
        price = invoicePrice(item);

        return price;
    }

    function getCommissioneTotale(item) {
        let price = 0;
        if (item.isCommissionable) {
            price = commissioneAgenzia(item) + agencyFee(item);
        }

        return price;
    }

    return (
        <>
            {items && !isPrintCliente &&
                <>
                    <div className={`rounded-top ${extraOptions.marginTop} text-white stepHeader px-1`}>
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <td className="pl-2" style={{ marginLeft: "5px" }}>
                                        <span className="h5 text-white float-start">
                                            {onHandleIsPrint && <IconChoosePrint handleIsPrint={onHandleIsPrint} />}
                                            {t(`Template:TablePrice`)}
                                        </span>
                                    </td>
                                    <td>
                                        <tr className="row mr-2 no-gutters text-white small align-text-bottom">
                                            <td className="col-2 col-sm-2 col-md-7 col-lg-7 col-xl-7 px-1 text-right">
                                                {getADTTotal(paxes, isConfirmPrint)}
                                            </td>
                                            <td className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-0 text-right">
                                                {t(`Template:Adults`)}
                                            </td>
                                            <td className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 px-1 text-right">
                                                {getCHDTotal(paxes, isConfirmPrint)}
                                            </td>
                                            <td className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-0 text-right">
                                                {t(`Template:Children`)}
                                            </td>
                                        </tr>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="p-2 border shadow">
                        <table className=" w-100">
                            <thead className="h6 ">
                                <tr className="text-right h6 customBackground">
                                    <td className="text-left">{t(`Template:Service`)}</td>
                                    <td className="text-right w-20">{t(`Template:Quote`)}</td>
                                    <td className="text-right w-20">{t(`Template:Commission`)}</td>
                                    <td className="text-right w-20">{t(`Template:Tot\tNetToPay`)}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {items && items.length > 0 &&
                                    items.filter(x => (((x.isSelected || x.isActive) && x.productType === "Generic") || x.productType !== "Generic") && x.status !== "PENALTY").map((item, index) =>
                                        <>
                                            {item.isCommissionable &&
                                                <tr key={index} className="text-right border-bottom" style={{ borderBottom: '1px solid #d0cece' }}>
                                                    <td className="text-left">
                                                        {getProductName(item)}
                                                    </td>
                                                    <td className="text-right w-20">
                                                        {invoicePrice(item)
                                                            ? formatPrice(invoicePrice(item), currency(item), cultureName)
                                                            : formatPrice(item.sellAmount, item.sellCurrency, cultureName)
                                                        }
                                                    </td>
                                                    <td className="text-right w-20">
                                                        {commissioneAgenzia(item) + agencyFee(item) === 0
                                                            ? 0
                                                            : "- " + formatPrice((commissioneAgenzia(item) + agencyFee(item)), currency(item), cultureName)
                                                        }
                                                        {
                                                            parseFloat(item.totalIVACee) > 0
                                                                ? <>(* {item.totalIVACee})</>
                                                                : ""
                                                        }
                                                    </td>
                                                    <td className="text-right w-20" >
                                                        {formatPrice(invoicePrice(item) - (commissioneAgenzia(item) + agencyFee(item)), currency(item), cultureName)}
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                    )
                                }
                                {items && items.filter(x => (((x.isSelected || x.isActive) && x.productType === "Generic") || x.productType !== "Generic") && x.status !== "PENALTY").map((item, index) =>
                                    <>
                                        {!item.isCommissionable &&
                                            <tr key={index} className="text-right">
                                                <td className="text-left">
                                                    {getProductName(item)}
                                                </td>
                                                <td className="text-right w-20">
                                                    {formatPrice(invoicePrice(item), currency(item), cultureName)}
                                                </td>
                                                <td className="text-right w-20">
                                                    0
                                                </td>
                                                <td className="text-right w-20">
                                                    {formatPrice(invoicePrice(item), currency(item), cultureName)}
                                                </td>
                                            </tr>
                                        }
                                    </>
                                )}
                                {/* cancellate con penale */}
                                {items && items.filter(x => (((x.isSelected || x.isActive) && x.productType === "Generic") || x.productType !== "Generic") && x.status === "PENALTY").map((item, index) =>
                                    <>
                                        {index === 0 &&
                                            <tr className="border-top bg-gray-200">
                                                <td className="text-left text-danger fontBold">
                                                    Cancellati con Penale
                                                </td>
                                            </tr>
                                        }
                                        <tr key={index} className="text-right bg-gray-200">
                                            <td className="text-left">
                                                {getProductName(item)}
                                            </td>
                                            <td className="text-right w-20">
                                                {formatPrice(invoicePrice(item), currency(item), cultureName)}
                                            </td>
                                            <td className="text-right w-20">
                                                0
                                            </td>
                                            <td className="text-right w-20">
                                                {formatPrice(invoicePrice(item), currency(item), cultureName)}
                                            </td>
                                        </tr>
                                    </>
                                )}

                                <tr className="text-right customBackground">
                                    <td className="h6 text-left">{t(`Template:Total`)}</td>
                                    <td className="h6 text-right w-20">{formatPrice(tableTotals.quotaTotal, tableTotals.currency, cultureName)}</td>
                                    <td className="h6 text-right w-20"> {tableTotals.commTotal === 0 ? 0 : "- " + formatPrice(tableTotals.commTotal, tableTotals.currency, cultureName)}</td>
                                    <td className="h6 text-right w-20">{formatPrice(tableTotals.nettoTotal, tableTotals.currency, cultureName)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            }

            {items && isPrintCliente &&
                <>
                    <table className="w-100">
                        <tr>
                            <td></td>
                            <td className="w-20 text-white float-end p-3 w-30 stepHeader">
                                {t(`Template:Total`)}: <span class="h2 text-white">{formatPrice(tableTotals.quotaTotal, tableTotals.currency, cultureName)}</span>
                            </td>
                        </tr>
                    </table>
                </>
            }
        </>
    );
}
