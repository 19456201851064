import configData from "../appsettings.json";

export function createQuotationInfoSession(response, quotationId, quotationItemIdToChange, isFromCart) {
    let obj = {
        quotationId: quotationId,
        quotationItemIdToChange: quotationItemIdToChange,
        quotationInfoSearchParam: response.quotationInfoSearchParam,
        productType: null,
        username: response.username,
        isFromCart: isFromCart
    };

    if (response && response.productType)
        obj.productType = parseInt(response.productType);

    return obj;
}

export async function registerSessionQuotation(quotationId, quotationItemIdToChange, isFromCart = false, cartItemId = null) {
    const requestOption = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            quotationId: quotationId,
            quotationItemIdToChange: quotationItemIdToChange,
            cartItemId: cartItemId
        })
    };

    const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}auth/sessionregisterquotation`, requestOption);
    const response = await fetchedRes.json();
    if (response.success) {
        let url = '';

        switch (parseInt(response.productType)) {
            case configData.Settings.Products.Structure.IdTipoProdotto:
                url = `/Hotel/HotelDetailStatic/?sId=${response.idProdotto}`;
                break;

            case configData.Settings.Products.Activity.IdTipoProdotto:
                url = `/Activity/ActivityDetailStatic/?aId=${response.idProdotto}`;
                break;

            case configData.Settings.Products.CarRental.IdTipoProdotto:
                url = `/Carrental/CarrentalList/?cId=${response.carpetId}&cfId=${response.clientFlowId}`;
                break;

            case configData.Settings.Products.Transfer.IdTipoProdotto:
                url = `/Transfer/TransferList/?cId=${response.carpetId}&cfId=${response.clientFlowId}`;
                break;

            case configData.Settings.Products.Cruise.IdTipoProdotto:
                url = `/Cruise/CruiseList/?cId=${response.carpetId}&cfId=${response.clientFlowId}`;
                break;

            case configData.Settings.Products.Flight.IdTipoProdotto:
                url = `/Flight/FlightList/?cId=${response.carpetId}&cfId=${response.clientFlowId}`;
                break;

            case configData.Settings.Products.Train.IdTipoProdotto:
                url = `/Train/TrainList/?cId=${response.carpetId}&cfId=${response.clientFlowId}`;
                break;

            case configData.Settings.Products.Tour.IdTipoProdotto:
                url = `/Tour/TourList/?cId=${response.carpetId}&cfId=${response.clientFlowId}`;
                break;

            default:
                break;
        }

        return {
            quotationInfo: createQuotationInfoSession(response, quotationId, quotationItemIdToChange, isFromCart),
            url: url
        };

    } else {
        console.error("ERROR: register Quotation");
        return null;
    }
}

export async function logoutSessionQuotation() {
    const requestOption = {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' }
    };

    const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}auth/clearregisterquotation`, requestOption);
    const response = await fetchedRes.json();
    if (response.success) {
        return true;
    } else {
        console.error("ERROR: clear Quotation");
        return false;
    }
}