import ReactDOMServer from "react-dom/server";
import { generateComponentInBody } from "../../../../js/PrintUtility";
import { PrintAccountBalanceSubHeader } from "./components/PrintAccountBalanceSubHeader";
import { PrintAccountBalanceServizi } from "./components/PrintAccountBalanceServizi";
import { PrintAccountBalanceScadenziario } from "./components/PrintAccountBalanceScadenziario";

export function generateAccountBalanceHTML(titleData, order, visualizationType, userType, grouped, scadenziario, cultureName) {
    let myArr = [];
    let subHeaderComponent = ReactDOMServer.renderToString(<PrintAccountBalanceSubHeader
        titleData={titleData}
        cultureName={cultureName}
    />);

    myArr.push({ html: generateComponentInBody(subHeaderComponent), break: false, wrap: true });

    if (order && order.pratiche && order.pratiche.length > 0) {
        let praticheComponent = ReactDOMServer.renderToString(<PrintAccountBalanceServizi
            order={order}
            visualizationType={visualizationType}
            userType={userType}
            grouped={grouped}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(praticheComponent), break: false, wrap: true });
    }

    if (scadenziario) {
        let praticheComponent = ReactDOMServer.renderToString(<PrintAccountBalanceScadenziario
            orderNr={order}
            visualizationType={visualizationType}
            userType={userType}
            grouped={grouped}
            cultureName={cultureName}
            timetable={scadenziario }
        />);

        myArr.push({ html: generateComponentInBody(praticheComponent), break: false, wrap: true });
    }

    return myArr;
}