import React, { useEffect, useState, useCallback } from 'react';
import { Chips } from 'primereact/chips';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';

export const HotelRoomFilter = ({ handleRoom }) => {
    const { t } = useTranslation();
    const [valueArr, setValueArr] = useState([]);

    const css = `
    .p-chips-multiple-container {
        width: 100%;
    }`;

    const handleInput = useCallback(event => {
        setValueArr(event);

        handleRoom(event);
    }, [handleRoom]);

    useEffect(() => {
    }, []);

    return (
        <>
            <style>{css}</style>
            <div className="bg-gray-200 p-3 rounded shadow">
                <Chips placeholder={t(`Product:Structure:Filter:RoomFilter`)} style={{ width: "100%" }} value={valueArr} separator="," onChange={(e) => handleInput(e.value)} />
            </div>
        </>
    );
}
