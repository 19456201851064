import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../../js/Utils.js';

export const ActivityListRecapOptions = ({ activityId, bestPricingOptions, onClickSearch }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    function goToDetail(pricing) {
        if (pricing.date) {
            let myDate = getDateToString(new Date(pricing.date));

            onClickSearch(activityId, [
                { type: 'SELECTDATE', value: myDate }
            ]);
        } else {
            onClickSearch(activityId, [
                { type: 'SELECTLABEL', value: pricing.value ? pricing.value : pricing.label }
            ]);
        }
        
    }

    function getDateToString(myDate) {
        let monthStr = myDate.getMonth() + 1;
        let dayStr = myDate.getDate();
        if (monthStr < 10)
            monthStr = "0" + monthStr;

        if (dayStr < 10)
            dayStr = "0" + dayStr;

        return myDate.getFullYear() + "-" + (monthStr) + "-" + dayStr;
    }

    return (
        <>
            {
                bestPricingOptions && <table className=" w-100 text-center small">
                    <thead>
                        <tr className="small">
                            {
                                bestPricingOptions.map((pricing, key) => {
                                    return <td key={key}>
                                        {
                                            !pricing.date && !pricing.label && <data m3lab="Product.Activity.Other">{t('Product:Activity:Other')}</data>
                                        }
                                        {
                                            pricing.date && <data m3lab="Product.Activity.Other">{formatDateTime(pricing.date, cultureName, { noYear: true })}</data>
                                        }
                                        {
                                            !pricing.date && pricing.label && <data m3lab="Product.Activity.Other">{pricing.label}</data>
                                        }
                                    </td>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {
                                bestPricingOptions.map((pricing, key) => {
                                    return <td key={key}>
                                        <button className={"btn btn-sm btn-neutral" + (key === 0 ? " focus" : "")} onClick={(e) => goToDetail(pricing)}>
                                            <var>{parseInt(pricing.bestPrice)}</var>
                                        </button>
                                    </td>;
                                })
                            }
                        </tr>
                    </tbody>
                </table>
            }
        </>
    );
}