import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { AddServiceNote } from '../../../ManualProduct/components/AddServiceNote';
import { AddNoteXml } from '../../../ManualProduct/components/AddNoteXml';
import { Loading } from '../../../Common/Loading';

export const UpdateFlightData = ({ modalId, resetValue, quotationItemId, wsBookingId, saveProductData }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    const [productData, setProductData] = useState(false);
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setVisible(resetValue > 1);
        setProductData(null);
        setIsLoading(true);
        setIsError(false);

        let inputData = {
            quotationItemId: quotationItemId,
            wsBookingId: wsBookingId,
            cultureName: cultureName
        }

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchData = async () => {
            const response = await fetch(`${configData.Settings.CommonApi_BaseUrl}quotationto/getUpdateStaticData`, requestOption)
            const data = await response.json();

            if (data.success) {
                setProductData(data.flightData);
                setIsError(false);
            }

            setIsLoading(false);
        }

        fetchData()
            .catch(console.error);
    }, [resetValue]);

    function onSave() {
        let inputData = {
            flightData: productData,
            quotationItemId: quotationItemId,
            wsBookingId: wsBookingId
        };

        setVisible(false);
        saveProductData(inputData);
    }

    function onBlurUpdatePublicNote(event) {
        let pd = { ...productData };
        pd.internalNote = event.target.value;
        setProductData(pd);
    }

    function onBlurEventDescription(event, propertyName) {
        let pd = { ...productData };
        pd.serviceNoteDescription = event;
        setProductData(pd);
    }

    function onBlurEventServiceNote(roomIndex, propertyName, event) {
        let pd = { ...productData };
        pd.serviceNote = event;
        setProductData(pd);
    }

    function onBlurEventRoomNote(event) {
        let pd = { ...productData };
        pd.roomsNoteXML = event;
        setProductData(pd);
    }

    const footerContent = (
        <div className='modal-footer'>
            <button type="button" className="btn bt-sm tp-btn-delete" onClick={_ => setVisible(false)}>{t('Button:Close')}</button>
            <button type="button" className="btn bt-sm tp-btn-add" data-bs-dismiss="modal" onClick={_ => onSave()}>{t('Button:Save')}</button>
        </div>
    );

    return (
        <>
            <Dialog
                header="Scheda"
                footer={footerContent}
                visible={visible}
                style={{ width: '75vw' }}
                onHide={() => { if (!visible) return; setVisible(false); }}>
                <p className="m-0">
                    {
                        isLoading && <Loading textMsg="Caricamento in corso..." />
                    }
                    {
                        productData && <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    {
                                        productData && <div className="col-12">
                                            <TabView>
                                                <TabPanel header="Note Servizio">
                                                    <div className="pb-2">
                                                        <AddServiceNote
                                                            serviceNote={productData.description}
                                                            roomIndex={-1}
                                                            noteTypeToAdd={12}
                                                            onBlurEvent={onBlurEventDescription} />
                                                    </div>

                                                    <div className="pb-2">
                                                        <AddServiceNote
                                                            serviceNote={productData.serviceNote}
                                                            roomIndex={0}
                                                            onBlurEvent={onBlurEventServiceNote} />
                                                    </div>

                                                    <div class="mt-2 pt-2 border-light border-top">
                                                        <div className="row">
                                                            <div className="col-12 form-floating ">
                                                                <textarea className="form-control"
                                                                    placeholder="Note Interne"
                                                                    value={productData.internalNote}
                                                                    onChange={(e) => { onBlurUpdatePublicNote(e); }}
                                                                    id="floatingTextareaIntNote"
                                                                    style={{ height: '100px' }} >
                                                                </textarea>
                                                                <label htmlFor="floatingTextareaIntNote">Note Interne</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel header="Note XML Fornitore">
                                                    <AddNoteXml
                                                        roomNotes={productData.roomsNoteXML}
                                                        onBlurEvent={onBlurEventRoomNote} />
                                                </TabPanel>
                                            </TabView>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </p>
            </Dialog>
        </>
    );
}
