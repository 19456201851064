import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import configData from "../../../appsettings.json";

export const SaferPayBack = () => {
    const cookies = new Cookies();

    useEffect(() => {
        if (cookies.get("orderNumber")) {
            window.location.href = `${configData.Settings.Base_Url}cart`;
        }
    }, []);

    return (
        <div className="row text-center">
            <div className="col-12">
                <p><strong>Transazione annullata dall'utente.</strong></p>
                <p>Clicca 'Chiudi' per aggiornare la pagina</p>
            </div>            
        </div>
    );
}
