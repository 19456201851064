import React from 'react';
import { useTranslation } from 'react-i18next';
import { imageOnError, imageOnLoad } from '../../js/CDNUtility';

export const Gallery = ({ gallery, alternativeText, modeView, onlyOne, productType }) => {
    const { t } = useTranslation();

    function normalizeObjectPath(index) {
        if (gallery && gallery.length > index) {
            let myObj = gallery[index];

            if (myObj.path)
                return myObj.path;

            if (typeof myObj === 'string' || myObj instanceof String)
                return myObj;
        }

        return imageOnLoad(productType);
    }

    return (
        <>
            {
                onlyOne ? <div className="card p-1 col-12">
                    <img src={normalizeObjectPath(0)} className="img-fluid img-first-gallery" alt={alternativeText} onError={(e) => imageOnError(e, productType)} />
                </div>
                    : <>
                        <div className="card p-1 col-6 col-sm-6 col-md-6 col-lg-6">
                            <img src={normalizeObjectPath(0)} className="img-fluid img-first-gallery" alt={alternativeText} onError={(e) => imageOnError(e, productType)} />
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="row">
                                <div className="card p-1 col-6 col-sm-6 col-md-6 col-lg-6">
                                    <img src={normalizeObjectPath(1)} className="img-fluid img-other-gallery" alt={alternativeText} onError={(e) => imageOnError(e, productType)} />
                                </div>
                                <div className="card p-1 col-6 col-sm-6 col-md-6 col-lg-6">
                                    <img src={normalizeObjectPath(2)} className="img-fluid  img-other-gallery" alt={alternativeText} onError={(e) => imageOnError(e, productType)} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="card p-1 col-6 col-sm-6 col-md-6 col-lg-6">
                                    <img src={normalizeObjectPath(3)} className="img-fluid  img-other-gallery" alt={alternativeText} onError={(e) => imageOnError(e, productType)} />
                                </div>
                                <div className="card p-1 col-6 col-sm-6 col-md-6 col-lg-6 right">
                                    <img src={normalizeObjectPath(4)} className="img-fluid  img-other-gallery" alt={alternativeText} onError={(e) => imageOnError(e, productType)} />
                                    {
                                        modeView !== 'modal' && <div className="position-relative">
                                            <div className="position-absolute bottom-0 end-0">
                                                <button className="btn btn-sm btn-white p-1 m-1" data-bs-toggle="modal" data-bs-target="#galleryModal">
                                                    <data m3lab="Button.AllPhonto">{t('Button:AllPhonto')}</data>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div class="modal fade" id="galleryModal" tabindex="-1" aria-labelledby="galleryModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-xl">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5" id="galleryModalLabel">{t('Button:AllPhonto')}</h1>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div id="carouselGalleryIndicators" class="carousel slide" data-bs-ride="true">
                                            <div class="carousel-inner carousel-inner-custom text-center">
                                                {gallery && gallery.length > 0 && gallery.map((img, idx) =>
                                                    <div className={"carousel-item " + (idx === 0 ? "active" : "")}>
                                                        <img src={normalizeObjectPath(idx)} className="img-fluid" style={{ height: "500px" }} alt={alternativeText} onError={(e) => imageOnError(e, productType)} />
                                                    </div>
                                                )}
                                            </div>
                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselGalleryIndicators" data-bs-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Previous</span>
                                            </button>
                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselGalleryIndicators" data-bs-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Next</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    );
}