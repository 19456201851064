import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../M3Icon";
import configData from "../../../appsettings.json";
import { getDiffDays, getDateRangeLabel } from "../../../js/Utils.js";

export const InputTimeReadOnly = ({ time, extraOptions, productType, modeView, onClickOpen }) => {
    const { t } = useTranslation();

    const [iconWidth, setIconWidth] = useState('w-30');
    const [inputWidth, setInputWidth] = useState('w-70');

    useEffect(() => {
        if (productType === configData.Settings.Products.Train.IdTipoProdotto) {
            setIconWidth('w-20');
            setInputWidth('w-80');
        }
        else if (productType === configData.Settings.Products.CarRental.IdTipoProdotto) {
            setIconWidth('w-30');
            setInputWidth('w-70');
        }

        if (modeView === 'COMPACTVIEW') {
            setIconWidth('w-30');
            setInputWidth('w-70');
        }

    },[])

    function getDateRangeLabelWithTranslations() {
        if (time) {
            return time;
        }

        return t('SearchEngine:Time');
    }

    function onClickInput() {
        if (modeView !== 'PANEL' && modeView !== 'COMPACTVIEW')
            onClickOpen();
    }

    return (
        <div className={"input-group rounded pr-1 " + (modeView !== 'MODAL' ? "bg-gray-300" : "bg-white")} onClick={_ => onClickInput()}>
            <span className={"input-group-text px-2 " + iconWidth + (modeView !== 'MODAL' ? " bg-gray-300" : " bg-white")} id="basic-addon1">
                <data m3ico="Time">
                    <M3Icon iconName="Time" />
                </data>
            </span>
            <div className={"pl-1 " + inputWidth}>
                <label htmlFor="TimeInput" className="form-label h065">
                    {t("SearchEngine:Time")}
                </label>
                {
                    modeView !== 'MODAL' ? <p className="p-0 m-0 h075">{getDateRangeLabelWithTranslations()}</p>
                        : <input className="form-control h075 w-100 border-0 shadow-none"
                            id="Time"
                            readOnly
                            placeholder={getDateRangeLabelWithTranslations()}
                        />
                }
            </div>
        </div>
    );
}
