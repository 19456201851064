import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const FilterAreaMap = ({ onShowFilterArea, onSizeInMeters}) => {
    const { t } = useTranslation();

    const [isNoArea, setIsNoArea] = useState(true);
    const [isArea1, setIsArea1] = useState(false);
    const [isArea3, setIsArea3] = useState(false);
    const [isArea5, setIsArea5] = useState(false);

    function onChangeAreas(e) {
        if (e.currentTarget.value === "0") {
            setIsNoArea(true);
            setIsArea1(false);
            setIsArea3(false);
            setIsArea5(false);

            onShowFilterArea(false);
        }
        else if (e.currentTarget.value === "1") {
            setIsNoArea(false);
            setIsArea1(true);
            setIsArea3(false);
            setIsArea5(false);

            onShowFilterArea(true);
            onSizeInMeters(1000);
        }
        else if (e.currentTarget.value === "3") {
            setIsNoArea(false);
            setIsArea1(false);
            setIsArea3(true);
            setIsArea5(false);

            onShowFilterArea(true);
            onSizeInMeters(3000);
        }
        else if (e.currentTarget.value === "5") {
            setIsNoArea(false);
            setIsArea1(false);
            setIsArea3(false);
            setIsArea5(true);

            onShowFilterArea(true);
            onSizeInMeters(5000);
        }
    }

    return (
        <>
            <div id="ListAreas" className="panel style1 filter categories">
                <data m3lab="MyBook.FindInOtherArea">{t("MyBook:FindInOtherArea")}</data>

                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    <label id="lblNoArea" className="btn filter-area-fake-checkbox">
                        <div className="filterItem" title="No Area">
                            <input type="radio" id="rbNoArea" name="Areas" category="Areas" value="0" onClick={e => onChangeAreas(e)} checked={isNoArea} />
                            <label for="rbNoArea">No Area</label>
                        </div>
                    </label>
                    <label id="lblArea1Km" className="btn filter-area-fake-checkbox">
                        <div className="filterItem" title="Area 1km">
                            <input type="radio" id="rbArea1km" name="Areas" category="Areas" value="1" onClick={e => onChangeAreas(e)} checked={isArea1} />
                            <label for="rbArea1km">Area 1km</label>
                        </div>
                    </label>
                    <label id="lblArea3Km" className="btn filter-area-fake-checkbox">
                        <div className="filterItem" title="Area 3km">
                            <input type="radio" id="rbArea3km" name="Areas" category="Areas" value="3" onClick={e => onChangeAreas(e)} checked={isArea3} />
                            <label for="rbArea3km">Area 3km</label>
                        </div>
                    </label>
                    <label id="lblArea5Km" className="btn filter-area-fake-checkbox">
                        <div className="filterItem" title="Area 5km">
                            <input type="radio" id="rbArea5km" name="Areas" category="Areas" value="5" onClick={e => onChangeAreas(e)} checked={isArea5} />
                            <label for="rbArea5km">Area 5km</label>
                        </div>
                    </label>
                </div>
            </div>
        </>
    );
}