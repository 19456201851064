import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";
import configData from "../../../../appsettings.json";
import { Gallery } from '../../Gallery';
import { Loading } from '../../../Common/Loading';
import { Error } from '../../../Common/Error';
import { M3StructureStars } from '../../../Common/M3StructureStars';
import { HotelServices } from './HotelServices';
import { ListSearchEngineCV } from '../../SearchEngine/compactView/ListSearchEngineCV';
import { ProductDescription } from "../../Common/ProductDescription";
import { getIconFavoutire } from '../../../../js/Utils.js';

export const HotelStaticContainer = ({ structureId = null, isStaticView = false, _structureData = null, isNotEngine = false, searchParameters, favourite }) => {
    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");
    const { t } = useTranslation();
    const [structureData, setStructureData] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isFavourite, setIsFavourite] = useState(false);

    let params = new URLSearchParams(window.location.search);

    let onRequest = params.get('onReq');
    let carpetId = params.get('cId');
    let structure = params.get('sId');

    const [extraParamSearch, setExtraParamSearch] = useState({
        structureId: structureId,
        testRequest: params.get('testRequest'),
        onRequest: onRequest,
        productName: ''
    });

    useEffect(() => {
        if (_structureData) {
            setStructureData(_structureData);
            setIsLoading(true);
        }
    }, []);

    useEffect(() => {
        if (structureId) {
            getHotel();
        }
    }, [structureId]);


    useEffect(() => {
        if (favourite) {
            setIsFavourite(true);
        }
    }, [favourite]);

    async function getHotel() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}structure/GetStructureData/${structureId}/${cultureCode}`, requestOption);
        const response = await fetchedRes.json();

        if (response.success) {
            setStructureData(response.structure);
            setIsLoading(true);
            setExtraParamSearch({
                ...extraParamSearch,
                productName: response.structure.name
            });
        } else {
            setIsError(true);
            console.error(response.errorMsg);
        }
    }

    function cutDescritpion(string) {
        let res = string;
        let length = 300;

        if (string.length > length)
            res = string.substring(0, length);

        return res + "...";
    }

    function onClickFavourite() {
        let favourite = !isFavourite;
        setIsFavourite(favourite);
        
        let inputData = {
            productType: configData.Settings.Products.Structure.IdTipoProdotto,
            productId: structure ? structure : structureId,
            operation: favourite ? "A" : "R",
            carpetId: carpetId
        }

        saveFavourite(inputData);
    }

    async function saveFavourite(requestParam) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}user/saveFavourite`, requestOption);
        if (!fetchedRes.ok) {
        } else {
            const response = await fetchedRes.json();

            if (response.success) {
            } else {
            }
        }
    }

    return (
        <>
            {!isLoading && !isError && <Loading textMsg={t('MyBook:Loading')} />}
            {!isLoading && isError && <Error textMsg={t('Error:GenericBlog')} />}
            {isLoading && !isError && <>

                <div className="row px-2">
                    <div className="col-9">
                        <label><var>{structureData.city}</var></label>
                    </div>
                    <div className="col-3 text-right">
                        {getIconFavoutire(isFavourite, t, onClickFavourite, true)}
                    </div>
                </div>
                <div className="row p-3">
                    <Gallery gallery={structureData.images ? structureData.images.map((img) => { return img.path }) : null} modeView={isStaticView ? "modal" : ""} productType={configData.Settings.Products.Structure.IdTipoProdotto} />
                </div>
                <div className="row px-1">
                    <div className="col-12 col-sm-8 col-md-8 col-lg-8">
                        <div className="d-flex ">
                            <var>
                                <M3StructureStars category={structureData.category} />
                            </var>
                        </div>
                        <div className="h4"><var>{structureData.name}</var></div>
                        <div className="h6"><var>{structureData.address}</var></div>
                    </div>
                    <div className="col-12 col-sm-8 col-md-8 col-lg-8" >
                        {!isStaticView && structureData.descriptions && structureData.descriptions.map((description, key) =>
                            <React.Fragment key={key}>
                                {key < 1 &&
                                    <>
                                        <p className="h6"><var>{description.category}</var></p>
                                        <p className="small">
                                            <var dangerouslySetInnerHTML={{ __html: cutDescritpion(description.description).replaceAll("<br/>", "") }}></var>
                                        </p>
                                    </>
                                }
                            </React.Fragment>
                        )}
                        {!isStaticView && structureData.descriptions &&
                            <div className="col-12 text-center">
                                <button className="btn p-0" data-bs-toggle="modal" data-bs-target="#infoHotel">
                                    <data m3lab="General.More">{t(`General:More`)}</data>
                                </button>
                            </div>
                        }

                        {
                            isStaticView && structureData.descriptions && structureData.descriptions.map((description, key) => {
                                return <React.Fragment key={key}>
                                    <ProductDescription index={key}
                                        category={description.category}
                                        description={description.description}
                                        productType={configData.Settings.Products.Structure.IdTipoProdotto}
                                        gallery={structureData.images} />
                                </React.Fragment>;
                            })
                        }
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                        {
                            structureData.amenities && structureData.amenities.length > 0 && <HotelServices services={structureData.amenities} />
                        }
                        {
                            isStaticView && !isNotEngine && <ListSearchEngineCV
                                imageUrl="https://cdn.travelplace.ch/travelplace40/img/maps_list.jpg"
                                searchParameters={searchParameters}
                                productType={configData.Settings.Products.Structure.IdTipoProdotto}
                                extraParams={extraParamSearch}
                                typeSearch="AVL"
                            />
                        }
                    </div>
                </div>
            </>
            }
        </>
    );
}