import React from 'react';
import configData from "../../../../appsettings.json";
import { GalleryList } from "../../Common/Image/GalleryList";
import { M3Icon } from "../../../Common/M3Icon";
import { ProductActionMenu } from "../../Common/ProductActionMenu";
import { ProductPrice } from "../../Common/Pricing/ProductPrice";

export const TourItemList = ({ item, showPriceBar, showCost, onClickSearch, modalOpenProductId, extraParam, removeTrippie, updateTrippie }) => {

    function onModalOpenProductId(productId) {
        modalOpenProductId(productId);
    }

    function getColumnClass(typeCol) {
        let isTrippie = extraParam && extraParam.isTrippie;

        if (typeCol === 'DESC')
            return isTrippie ? "col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
                             : "col-12 col-sm-6 col-md-6 col-lg-8 col-xl-8";

        if (typeCol === 'PRICE')
            return isTrippie ? "col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 p-1 pr-3 h-auto"
                             : "col-12 col-sm-2 col-md-2 col-lg-12 col-xl-12 p-1 pr-3";
    }

    return (
        <>
            <div className="card shadow shadow-hover mb-4 animate-up-3 ">
                <div className="row no-gutters">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <GalleryList
                            productId={item.tourId}
                            defaultImage={item.thumbUrl}
                            gallery={item.images}
                            alternativeText={item.name}
                            onClickSearch={onClickSearch}
                        />
                    </div>
                    <div className={getColumnClass('DESC')}>
                        <div className="card-body pt-2 px-0">
                            <div>
                                <div className="w-100">
                                    <div className="row">
                                        <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                                            <label>
                                                <data m3ico="Place icon-10">
                                                    <M3Icon iconName="Place" externalClass="ico-10" hasLabel={false} />
                                                </data>
                                                <var>{item.touchPoints && item.touchPoints.length > 0 && item.touchPoints[0].webDestinationName}</var>
                                            </label>
                                            {
                                                item.isSuggestion && <data m3ico="Star text-facebook">
                                                    { /* Per le promozioni o i suggeriti */}
                                                    <M3Icon iconName="Star" externalClass="text-facebook" hasLabel={false} />
                                                </data>
                                            }
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <div className="btn-group text-right float-end pr-2">
                                                <button className="btn p-0 m-0" type="button" id="menuItem1" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                    <data m3ico="List">
                                                        <M3Icon iconName="MenuFunctions" />
                                                    </data>
                                                </button>
                                                <ProductActionMenu
                                                    productId={item.tourId}
                                                    productType={configData.Settings.Products.Tour.IdTipoProdotto}
                                                    handleModalOpenProductId={onModalOpenProductId}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h5">
                                        <var className="cursor-pointer" onClick={(e) => onClickSearch(item.tourId)}>{item.name}</var>
                                    </div>

                                    <div className="w-100 mb-2">
                                        <label className="lh-base">
                                            { /* Max 189 caratteri i 3 puntini aprono la scheda */}
                                            <var>
                                                {
                                                    item.description && item.description.length > 189 ? item.description.substring(0, 189)
                                                        : item.description
                                                }
                                                {
                                                    item.description && item.description.length > 189 && <a data-bs-toggle="modal" data-bs-target={"#schedStaticaModal" + item.tourId}>...</a>
                                                }
                                            </var>
                                        </label>
                                    </div>
                                    <div className="w-100">
                                        {
                                            item.bestRoomNames && item.bestRoomNames.map((bestRoomName, indexbestRoomName) => {
                                                return <label key={indexbestRoomName} >
                                                    <data m3ico="Night">
                                                        <M3Icon iconName="Night" externalClass="icon-15" />
                                                    </data>
                                                    <span className="ml-1 lh-sm">
                                                        <var>{bestRoomName}</var>
                                                    </span>
                                                </label>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {((!extraParam || !extraParam.isTrippie) && item.supplier) && <span className="text-warning text-right small d-block"><var>{item.supplier.supplierName}</var></span>}

                    </div>

                    <div className={getColumnClass('PRICE')}>
                        <ProductPrice
                            productId={item.tourId}
                            startDate={item.startDate}
                            endDate={item.endDate}
                            pricing={item.pricing}
                            showPriceBar={showPriceBar}
                            showCost={showCost}
                            otherParam={{ buttonEnabled: true }}
                            typePrice="TotPrice"
                            onClickSearch={onClickSearch}
                            isFromTour={true}
                            isTrippie={extraParam && extraParam.isTrippie}
                            showPriceTrippie={extraParam && extraParam.showPriceTrippie}
                            removeTrippie={removeTrippie}
                            updateTrippie={updateTrippie}
                            supplier={extraParam && extraParam.isTrippie ? item.supplier : null}
                        />
                    </div>
                </div>
            </div >
        </>
    );
}