import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

export const ServiceNote = ({ notes, noteTypeToShow }) => {
    const { t } = useTranslation();

    const [serviceNotes, setServiceNotes] = useState([]);

    useEffect(() => {
        if (notes && notes.length > 0) {

            if (noteTypeToShow && noteTypeToShow.length > 0) {
                let notesToShow = notes.filter(x => noteTypeToShow.some(y => y === x.idTypeNote));
                setServiceNotes(notesToShow);
            } else {
                setServiceNotes(notes);
            }
        } else {
            setServiceNotes([]);
        }

    }, [notes])

    function renderTitleServiceNote(noteTypes) {
        if (noteTypes && noteTypes.some(x => x === 12))
            return t('General:Description')+":"

        return "Note Servizio:"
    }

    return (
        <>
            {
                serviceNotes && serviceNotes.length > 0 && <>
                    <div className="pt-3">
                        {
                            renderTitleServiceNote(noteTypeToShow)
                        }
                        {
                            serviceNotes.map((item, key) => {
                                return <>
                                    <div className="mb-2" key={key} dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                </>
                            })
                        }
                    </div>
                </>
            }

        </>
    );
}
