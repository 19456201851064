import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { InputPaxesComposition } from './InputPaxesComposition';
import { InputPaxesCompositionReadOnly } from './InputPaxesCompositionReadOnly';
import { InputPaxesCompositionPanel } from './InputPaxesCompositionPanel';
import { closeTpSearchInput } from '../../../js/Utils';
import configData from "../../../appsettings.json";

export const InputPaxesCompositionContainer = ({ searchParameters, productType, callOnSelectComposition, onOpenPanelPaxes, closeExternal, modeView, type }) => {

    const { t } = useTranslation();

    const [selectComposition, setSelectComposition] = useState(null);
    const [showPaxesComposition, setShowPaxesComposition] = useState(false);

    const [idModal] = useState(getRandomInt());
    function getRandomInt() {
        return Math.floor(Math.random() * 100000);
    }
    const modalId = "Composition_" + idModal;


    useEffect(() => {

        if (modeView === 'SEARCHENGINE')
            closeTpSearchInput(modalId, closePanelCalendar);

    }, []);

    useEffect(() => {
        setShowPaxesComposition(closeExternal);
    }, [closeExternal]);

    function onClickOpenPaxesComposition() {
        setShowPaxesComposition(!showPaxesComposition);
        onOpenPanelPaxes();

        if (modeView === 'SEARCHENGINE' && !showPaxesComposition) {
            let btn = document.getElementById("btnModalComposition_" + idModal);
            btn.click();
        }

        if (modeView === 'SEARCHENGINE' && showPaxesComposition) {
            let btn = document.getElementById("btnCloseModalComposition_" + idModal);
            btn.click();
        }
    }

    function closePanelCalendar() {
        setShowPaxesComposition(false);

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnCloseModalComposition_" + idModal);
            btn.click();
        }
    }

    function onClickSelectPaxes(selectCompositionParam) {
        setSelectComposition(selectCompositionParam);
        callOnSelectComposition(selectCompositionParam);
        setShowPaxesComposition(false);

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnCloseModalComposition_" + idModal);
            btn.click();
        }
    }

    function isMobile(excludeModeView = true) {
        if (excludeModeView && modeView === 'MODAL')
            return false;

        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const isMobileWidth = window.innerWidth <= 991;

        // Controllo per dispositivi Android
        if (/android/i.test(userAgent)) {
            return true;
        }

        // Controllo per dispositivi iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return true;
        }

        return isMobileWidth;
    }

    const getPlaceholder = () => {
        if (productType === configData.Settings.Products.Structure.IdTipoProdotto)
            return t('SearchEngine:RoomList');

        if (productType === configData.Settings.Products.Tour.IdTipoProdotto)
            return t('SearchEngine:RoomList');

        return t('SearchEngine:Composition');
    }

    return (
        <>
            <div className="w-100">
                {
                    !showPaxesComposition && <InputPaxesCompositionReadOnly
                        searchParameters={searchParameters}
                        defaultSelectComposition={selectComposition}
                        productType={productType}
                        callOnShowPanel={onClickOpenPaxesComposition}
                        callOnSelectComposition={onClickSelectPaxes}
                        modeView={modeView}
                        type={type} />
                }
            </div>

            <a id={"btnModalComposition_" + idModal} data-bs-toggle="modal" data-bs-target={"#" + modalId}></a>
            <div className={"modal " + (isMobile() ? "" : " tp-search-input position-relative ") + (modeView === 'MODAL' ? "d-block" : "fade") + ((isMobile() || modeView === 'MODAL') ? "" : " wm-250")}
                style={{ overflow: 'unset', height: (!isMobile() ? (isMobile(false) && modeView === 'MODAL' && showPaxesComposition ? '' : '0px') : '100%'), zIndex: (!isMobile() ? '200' : '999'), pointerEvents: 'none' }}
                id={modalId}
                tabIndex="-1"
                aria-labelledby={"#CompositionLabel_" + idModal}
                aria-hidden="true">
                <div className={"modal-dialog " + (isMobile() ? "h-100 modal-dialog-scrollable" : "")}>
                    {
                        showPaxesComposition && <div className={"modal-content " + (isMobile() ? "h-100" : "")}>
                            {
                                isMobile()
                                    ? <div className="modal-header p-0">
                                        <div className="container py-3">
                                            <div className="row">
                                                <div className="col-1">
                                                    <button className="btn btn-link p-0 m-0 text-decoration-none" onClick={_ => closePanelCalendar()}>
                                                        <span class="material-icons-outlined">
                                                            arrow_back
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="col-11 text-center">
                                                    <h6 className="btn btn-link p-0 m-0 text-decoration-none fw-bold text-dark">
                                                        {getPlaceholder()}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <div className="modal-header p-0">
                                        <InputPaxesComposition
                                            searchParameters={searchParameters}
                                            defaultSelectComposition={selectComposition}
                                            productType={productType}
                                            closePanelCalendar={closePanelCalendar}
                                            modeView={modeView}
                                            type={type} />
                                    </div>
                            }
                            <div className="modal-body p-0">
                                <InputPaxesCompositionPanel
                                    searchParameters={searchParameters}
                                    defaultSelectComposition={selectComposition}
                                    productType={productType}
                                    callOnSelectComposition={onClickSelectPaxes}
                                    type={type} />
                            </div>
                        </div>
                    }

                    <a id={"btnCloseModalComposition_" + idModal} data-bs-dismiss="modal"></a>
                </div>

            </div>
        </>
    );
}
