import React, { useEffect, useState, useCallback } from 'react';
import { Chips } from 'primereact/chips';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";


export const HotelVisualize = ({ label, iconTitle, resetSelectedItem, defaultSelectIds, defaultShowFilter = true, onIsReccomended, onIsBestSeller, onIsFavourites  }) => {
    const { t } = useTranslation();

    const [showFilter, setShowFilter] = useState(defaultShowFilter);

    const [isReccomended, setIsReccomended] = useState(false);
    const [isBestSeller, setIsBestSeller] = useState(false);
    const [isFavourites, setIsFavourites] = useState(false);

    useEffect(() => {
        if (resetSelectedItem) {
            onChangeReccomended(false);
            onChangeBestSeller(false);
            onChangeFavourite(false);
        }
    }, [resetSelectedItem]);


    function onShowFilter(event) {
        event.preventDefault();
        setShowFilter(!showFilter);
    }

    function onChangeFavourite(value) {
        setIsFavourites(value);
        onIsFavourites(value);
    }
    function onChangeBestSeller(value) {
        setIsBestSeller(value);
        onIsBestSeller(value);
    }
    function onChangeReccomended(value) {
        setIsReccomended(value);
        onIsReccomended(value);
    }

    return (
        <>
            {
                <div className="card bg-white rounded active my-1 text-primary h6 shadow">
                    <a href="#" className="text-decoration-none " onClick={(e) => onShowFilter(e)}>
                        <div className="row">
                            <div className="col-10 pl-4 pt-2">
                                <span className="p-2 text-left text-blue">
                                    {iconTitle && <span className="material-icons icon-10">{iconTitle}</span>}
                                    <data m3lab={label}>
                                        {t(label)}
                                    </data>
                                </span>
                            </div>
                            <div className="col-1 text-left p-1">
                                <data m3ico="Remove text-blue">
                                    {showFilter && <M3Icon iconName="Remove" externalClass="text-blue" hasLabel={false} />}
                                    {!showFilter && <M3Icon iconName="Add" externalClass="text-blue" hasLabel={false} />}
                                </data>
                            </div>
                        </div>
                    </a>
                    {
                        showFilter && <ul className="list-group px-1">
                            <li className="list-group-item p-0 m-1 border-0 ">
                                <input type="checkbox"
                                    checked={isFavourites}
                                    onChange={e => onChangeFavourite(e.currentTarget.checked)} 
                            />
                                <label className="px-2">{t(`Product:Structure:Filter:Favourites`)}</label>
                            </li>
                            <li className="list-group-item p-0 m-1 border-0 ">
                                <input type="checkbox"
                                    checked={isBestSeller}
                                    onChange={e => onChangeBestSeller(e.currentTarget.checked)} 
                                />
                                <label className="px-2">{t(`Product:Structure:Filter:BestSeller`)}</label>
                            </li>
                            <li className="list-group-item p-0 m-1 border-0 ">
                                <input type="checkbox"
                                    checked={isReccomended}
                                    onChange={e => onChangeReccomended(e.currentTarget.checked)} 
                                />
                                <label className="px-2">{t(`Product:Structure:Filter:Recommended`)}</label>
                            </li>
                        </ul>
                    }
                </div>
            }
        </>
    );
}