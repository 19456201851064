import React from 'react';
import { WebMenuVoices } from '../WebMenuVoices';

export const WebMenuVoid = ({ item, rules, modal }) => {
    return (
        <div className={!modal ? (item.className ? item.className : "") : 'modal-menu w-100'}>
            {
                !modal && item.html && item.html != ' ' && <span className='d-none d-sm-block' dangerouslySetInnerHTML={{ __html: item.html }}></span>
            }
            {
                !modal && item.html && !item.html.match('account_circle') && item.html != ' ' && <span className='d-block d-sm-none' dangerouslySetInnerHTML={{ __html: item.html }}></span>
            }
            {
                item.children && item.children.length > 0 && item.children.map((chd, key) => {
                    return <WebMenuVoices modal={ modal} key={key} item={chd} rules={rules} />;
                })
            }
        </div>
    );
} 
