import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import configData from "../../../appsettings.json";
import { handleError, formatDateTime, getStructureStars, formatPrice, getQuotationItemStatus } from "../../../js/Utils";
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';
import { M3Icon } from '../../Common/M3Icon';

export const QuotationItemAlternative = ({ id, item, fatherId, onSaveSelectAlternative }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [quotationAlternative, setQuotationAlternative] = useState({});

    useEffect(() => {
        setIsLoading(true);
        setIsError(false);

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationitemalternative/${id}/${fatherId}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationAlternative(item);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
                setIsLoading(false);
            });
    }, [item]);

    const callOnSaveSelectAlternative = useCallback(() => {
        onSaveSelectAlternative({ selectedAlternativeId: id, oldSelectedAlternativeId: fatherId });
    }, [id, fatherId]);

    return (
        <>
            {isLoading && <Loading textMsg="" isSmall={true} />}
            {!isLoading && isError && <Error textMsg={t('Error:NotAlternative')} />}
            {!isLoading && !isError && <>
                <div className={"col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10" +  (item.isDisabled ? " opacity-3" : "")}>
                    <div className="row no-gutters">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="row">

                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="mybook-hotel pl-2">
                                        <h6>{quotationAlternative.name}</h6>
                                        <p className="my-2">
                                            {quotationAlternative.productType === 'Activity' && <span><M3Icon iconName="CityLocation" /> {quotationAlternative.address}</span>}
                                            {quotationAlternative.productType === 'Tour' && <span><M3Icon iconName="CityLocation" /> {quotationAlternative.address}</span>}
                                            {quotationAlternative.productType === 'CarRental' && <span>Company{quotationAlternative.address}</span>}
                                            {quotationAlternative.productType === 'Cruise' && <span>Ship Name: {quotationAlternative.address}</span>}
                                            {quotationAlternative.productType === 'Flight' && quotationAlternative.flightDateInfo.map((dateItem, key) => {
                                                return quotationAlternative.flightDateInfo.lenght < 3
                                                    ? <span key={key}><label> {key === 0 ? "A" : "R"}: {formatDateTime(dateItem.date)}</label>{dateItem.hour}</span>
                                                    : <span key={key}><label> Volo {key + 1}: {formatDateTime(dateItem.date)}</label>{dateItem.hour}</span>
                                            })}
                                            {quotationAlternative.productType === 'Structure' && <span><M3Icon iconName="CityLocation" /> {quotationAlternative.address}</span>}
                                            {quotationAlternative.productType === 'Transfer' && <span>Type {quotationAlternative.address}</span>}
                                            {quotationAlternative.productType === 'Train' && quotationAlternative.flightDateInfo.map((dateItem, key) => {
                                                return <span key={key}><label> {key === 0 ? "A" : "R"}: {formatDateTime(dateItem.date)}</label>{dateItem.hour}</span>
                                            })}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                    <div className="d-flex my-1 small">
                                        {quotationAlternative.productType === 'Activity' && quotationAlternative.productType !== 'Tour' && <span><M3Icon iconName="CityLocation" /> {quotationAlternative.extraInfo}</span>}
                                        {quotationAlternative.productType === 'Activity' && quotationAlternative.productType === 'Tour' && <span><M3Icon iconName="Clock" /><span>{t("MyBook:Duration")}:</span>{quotationAlternative.extraInfo}</span>}
                                        {quotationAlternative.productType === 'Tour' && <span><M3Icon iconName="Clock" /><span>{t("MyBook:Duration")}:</span>{quotationAlternative.extraInfo}</span>}
                                        {quotationAlternative.productType === 'CarRental' && <span><M3Icon iconName="Clock" /><span>{t("MyBook:Rent")}:</span>{quotationAlternative.extraInfo} {t("MyBook:Days")}</span>}
                                        {quotationAlternative.productType === 'Cruise' && <span><M3Icon iconName="Clock" /><span>{t("MyBook:Duration")}:</span>{quotationAlternative.extraInfo} {t("MyBook:Days")}</span>}
                                        {quotationAlternative.productType === 'Flight' && <span><M3Icon iconName="Clock" /><span>Pax:</span>{quotationAlternative.extraInfo}</span>}
                                        {quotationAlternative.productType === 'Structure' && <span>{getStructureStars(quotationAlternative.extraInfo)}</span>}
                                        {quotationAlternative.productType === 'Transfer' && <span><M3Icon iconName="Clock" /><span>Max Pax:</span>{quotationAlternative.extraInfo}</span>}
                                        {quotationAlternative.productType === 'Train' && <span><M3Icon iconName="Clock" /><span>Pax:</span>{quotationAlternative.extraInfo}</span>}
                                    </div>
                                    <div className="mybook-hotel-room penalty">
                                        {
                                            quotationAlternative.cancelPolicy && <span className={quotationAlternative.cancelPolicy.isFree ? "penalty free" : "penalty"}>
                                                <M3Icon iconName="CancelPolicy" />
                                                {quotationAlternative.cancelPolicy.isFree ? t("CancelPolicy:FreeCancellation") + formatDateTime(quotationAlternative.cancelPolicy.expirationDate, { twoDigits: true }) : t("CancelPolicy:PenaltyCancellation") }
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 mybook-priceBar status confirm bg" +  (item.isDisabled?" opacity-3": "")}>

                    <div className="d-table w-100 h-100">
                        <div className="d-table-row h-50">
                            <div className="d-table-cell align-top">
                                <h6>{formatPrice(quotationAlternative.diffPriceAmount, quotationAlternative.diffPriceCurrency, cultureName, { signDisplay: true })}</h6>
                                {
                                    !item.isDisabled && <button className="btn p-0" onClick={callOnSaveSelectAlternative}><span className="badge bg-primary">{t("MyBook:SelectAlternative")}</span></button>
                                }
                                {
                                    item.isDisabled && <small className="text-muted mb-1"><div>{t(getQuotationItemStatus(item.status))}</div><div>{formatDateTime(item.deletedDate)}</div></small>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
    );
}
