import React, { useState, useEffect } from 'react';
import { Loading } from '../Common/Loading';
import configData from "../../appsettings.json";
import { useTranslation } from 'react-i18next';

export const BookingNote = ({ idPratica, customBookingNote }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [notes, setNotes] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        const getNotes = async (inputData) => {
            const response = callGetNotes(inputData);
        }

        setIsLoading(true);
        getNotes(idPratica);
    }, []);

    async function callGetNotes(idPratica) {
        var data = [];
        data.push({ fieldName: "IdPratica", Value: idPratica.toString() });
        data.push({ fieldName: "IdTipoNota", Value: "7" });
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}Document/GetNotes`, requestOption);
        const response = await fetchedRes.json();
        
        setNotes(response);
        customBookingNote(response);
        setIsLoading(false);
    }
    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : (notes && notes.length > 0 &&
                    <div className="card mt-4 border-gray-500 content-block">
                        <div className="m-4">
                            <div className=" document-bg-std  x-100"><h6>Booking Note</h6></div>
                            {notes.map((n, index) =>
                                <div key={index} className="mb-0">
                                     <div className="document-txt-note text-justify" dangerouslySetInnerHTML={{ __html: n.nota }}></div>
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        </>
    );
}
