import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../M3Icon";
import configData from "../../../appsettings.json";
import { getDiffDays, getDateRangeLabel } from "../../../js/Utils.js";

export const InputCalendar = ({ dateFrom, dateTo, timeFrom, timeTo, productType, hadDuration, closePanelCalendar, extraOptions, modeView, type, isMobile }) => {
    const { t } = useTranslation();
    const [inputWidth, setInputWidth] = useState('w-75');

    useEffect(() => {
        if (type === 'l') {
            setInputWidth('w-65');
        }

        if (type === 'm') {
            setInputWidth('w-50');
        }

        if (modeView === 'MODAL') {
            setInputWidth('w-75');

            if (productType === configData.Settings.Products.Train.IdTipoProdotto) {
                setInputWidth('w-50');
            }
            else if (productType === configData.Settings.Products.CarRental.IdTipoProdotto) {
                setInputWidth('w-50');
            }
        }

    }, [type])

    function getDiffDaysWithTranslations() {
        let diffDays = getDiffDays(dateFrom, dateTo);

        if (diffDays)
            return diffDays + t('SearchEngine:NigthAbbr');


        return "";
    }

    function getDateRangeLabelWithTranslations() {
        let label = getDateRangeLabel(dateFrom, dateTo, timeFrom, timeTo);
        if (label)
            return label;

        if (extraOptions && extraOptions.label)
            return t(extraOptions.label);

        if (!extraOptions || !extraOptions.multiDate)
            return t('SearchEngine:Date');

        return t('SearchEngine:DateFromDateTo');
    }

    function onClosePanel() {
        closePanelCalendar();
    }

    return (
        <div className="input-group bg-white rounded pr-1">
            <span className="input-group-text px-2" id="basic-addon1">
                <data m3ico="CalendarMonth">
                    <M3Icon iconName="CalendarMonth" />
                </data>
            </span>
            <div className={"pl-1 pt-2 " + inputWidth}>
                <input className="form-control border-0 shadow-none w-100"
                    id="CalendarInput"
                    type="text"
                    readOnly
                    value=""
                    placeholder={getDateRangeLabelWithTranslations() + (hadDuration ? getDiffDaysWithTranslations() : "")} />
            </div>
            {
                !isMobile && <button type="button" className="btn-close mt-2 mr-1 m-auto" onClick={_ => onClosePanel()}></button>
            }
        </div>
    );
}
