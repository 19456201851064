/* Product Shared Functions */
import { BookParameter } from '../BookParameter';
import { BookParameterId } from '../../../js/Constant';

// gestisce il cambio di pax sulla dropdown
export const handleChangePax = (cartItem, cartPaxesNames, handleBookParameters, sequence, paxIndex, ddlPaxId, e) => {
    if (e.target.value !== "-1") {
        let ddlPax = document.getElementById(ddlPaxId);
        if (ddlPax) {
            ddlPax.classList.remove("is-invalid");
        }
    } else {
        return; // se è -1 non devo fare niente
    }

    let selectedPaxIndex = Number(e.target.value);
    let bpPax = cartPaxesNames.filter(e => e.Index === selectedPaxIndex);
    let bpFirstName = { Id: BookParameterId.Personal.FirstName, Value: bpPax[0].BpFirstName[0].Value };
    let bpLastName = { Id: BookParameterId.Personal.LastName, Value: bpPax[0].BpLastName[0].Value };

    let data = { Sequence: sequence, PaxIndex: Number(paxIndex), BookParameters: [bpFirstName, bpLastName] };

    handleBookParameters(cartItem.cartItemId, "PAX", data);
}

// gestisce il cambio valore degli input fields
export const paramOnChange = (cartItem, handleBookParameters, type, sequence, index, bp, e) => {
    let val = "";

    switch (bp.type) {
        case "Mindcube.Travel.BooleanBookParameter":
            val = (e.target.checked) ? "true" : "false";
            break;

        default:
            val = e.target.value;
            break;
    }

    switch (type) {
        case "PRODUCT":
            let pData = { BookParameters: [{ Id: bp.id, Value: val }] };
            handleBookParameters(cartItem.cartItemId, type, pData);
            break;

        case "OPTION":
            let oData = { Sequence: sequence, BookParameters: [{ Id: bp.id, Value: val }] };
            handleBookParameters(cartItem.cartItemId, type, oData);
            break;

        case "PAX":
            let paxData = { Sequence: sequence, PaxIndex: index, BookParameters: [{ Id: bp.id, Value: val }] };
            handleBookParameters(cartItem.cartItemId, type, paxData);
            break;

        default:
            break;
    }
}

// renderizza i book parameters a livello di product
export const renderProductBookParameters = (cartItem, productBookParams, handleBookParameters) => {
    if (productBookParams === null || productBookParams === undefined ||
        productBookParams.ProductBookParameters === null || productBookParams.ProductBookParameters === undefined || productBookParams.ProductBookParameters.length === 0
    )
        return;

    return (
        <>
            <div className="row">
                {productBookParams.ProductBookParameters.map((bp, index) =>
                    <BookParameter key={index} inputId={`ci_${cartItem.cartItemId}_${bp.id}`} bookParameter={bp} paramOnChange={e => paramOnChange(cartItem, handleBookParameters, "PRODUCT", null, null, bp, e)} />
                )}
            </div>
        </>
    );
}

// renderizza i book parameters a livello di option/room
export const renderOptionBookParameters = (cartItem, productBookParams, handleBookParameters, optionNumber) => {
    if (productBookParams === null || productBookParams === undefined ||
        productBookParams.OptionBookParameters === null || productBookParams.OptionBookParameters === undefined ||
        productBookParams.OptionBookParameters.length === 0
    )
        return;

    let roomBookParams = productBookParams.OptionBookParameters[optionNumber - 1].bookParameters; // optionNumber - 1 corrisponde all'indice della room
    return (
        <>
            {roomBookParams === null || roomBookParams === undefined || roomBookParams.length === 0
                ? (<></>)
                : (
                    <div className="row">
                        {roomBookParams.map((bp, index) =>
                            <BookParameter key={index} inputId={`ci_${cartItem.cartItemId}_option_${optionNumber}_${bp.id}`} bookParameter={bp} paramOnChange={e => paramOnChange(cartItem, handleBookParameters, "OPTION", optionNumber, null, bp, e)} />
                        )}
                    </div>
                )
            }
        </>
    );
}

// renderizza i book parameters a livello di pax (tra quelli non presenti nei default)
export const renderPaxBookParameters = (cartItem, productBookParams, staticBookParams, handleBookParameters, optionNumber, paxIndex) => {
    if (productBookParams === null || productBookParams === undefined ||
        productBookParams.OptionBookParameters === null || productBookParams.OptionBookParameters === undefined ||
        productBookParams.OptionBookParameters[optionNumber - 1] === null || productBookParams.OptionBookParameters[optionNumber - 1] === undefined ||
        productBookParams.OptionBookParameters[optionNumber - 1].paxes === null || productBookParams.OptionBookParameters[optionNumber - 1].paxes === undefined ||
        productBookParams.OptionBookParameters[optionNumber - 1].paxes[paxIndex - 1] === null || productBookParams.OptionBookParameters[optionNumber - 1].paxes[paxIndex - 1] === undefined
    )
        return;

    let originPaxBookParams = productBookParams.OptionBookParameters[optionNumber - 1].paxes[paxIndex - 1].bookParameters;

    let paxBookParams = [];
    for (let i = 0; i < originPaxBookParams.length; i++) {

        let addPrameter = true;
        for (let j = 0; j < staticBookParams.length; j++) {
            if (staticBookParams[j].Id === originPaxBookParams[i].id && staticBookParams[j].OnlyFirstPax && (paxIndex - 1 > 0)) {
                addPrameter = true; // se il book parameter è solo per il primo pax e siamo su un pax diverso dal primo, lo aggiungo
            }
            else if (staticBookParams[j].Id === originPaxBookParams[i].id) {
                addPrameter = false; // se è uno dei book parameter che ho tra quelli statici comuni, non devo aggiungerlo
                break;
            }
        }

        if (addPrameter) {
            paxBookParams.push(originPaxBookParams[i]);
        }
    }

    return (
        <>
            {paxBookParams.length === 0
                ? (<></>)
                : (
                    <>
                        {paxBookParams.map((bp, index) =>
                            <BookParameter key={index} inputId={`ci_${cartItem.cartItemId}_option_${optionNumber}_pax_${paxIndex}_${bp.id}`} bookParameter={bp} paramOnChange={e => paramOnChange(cartItem, handleBookParameters, "PAX", optionNumber, paxIndex, bp, e, handleBookParameters)} />
                        )}
                    </>
                )
            }
        </>
    );
}

// restituisce la classe da applicare al div in caso di prodotto non disponibile
export const productNotAvailableClass_Opacity = (productBookParams) => {
    if (productBookParams !== null && productBookParams !== undefined && !productBookParams.IsAvailable)
        return "opacity-3";

    return "";
}

// restituisce la classe da applicare al div in caso di prodotto non disponibile
export const productNotAvailableClass_Border = (productBookParams) => {
    if (productBookParams !== null && productBookParams !== undefined && !productBookParams.IsAvailable)
        return "border-secondary";

    return "";
}