import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../Auth/AuthProvider';
import { useTranslation } from 'react-i18next';
import { logoutSessionQuotation } from '../../../../js/QuotationUtils';

export const HeaderWorkingMode = ({ item }) => {
    const { t } = useTranslation();

    let { getQuotationInfoSession, setQuotationInfoSession, getAddToOrderSession } = useAuth();

    const [isVisible, setIsVisible] = useState(false);
    const [quotationInfo, setQuotationInfo] = useState(null);
    const [impersonate, setImpersonate] = useState(null);
    const [addToOrder, setAddToOrder] = useState(null);
    const [welfareInfo, setWelfareInfo] = useState(null);

    useEffect(() => {

        // gestione cross login
        try {

            const jCurrentUser = localStorage.getItem("CurrentUser");
            if (jCurrentUser !== null && jCurrentUser !== undefined) {
                let currentUser = JSON.parse(jCurrentUser);

                if (currentUser.impersonate !== null && currentUser.impersonate !== undefined) {
                    setImpersonate(currentUser.name);
                    setIsVisible(true);
                }
            }
        } catch (ex) { }

        // getstione quotation
        try {

            if (getQuotationInfoSession && getQuotationInfoSession.quotationId) {
                setQuotationInfo(getQuotationInfoSession);
                setIsVisible(true);
            }

        } catch (ex) { }

        // TODO gestione welfareInfo
        try {

            const jCurrentWelfareInfo = localStorage.getItem("WelfareInfo");
            if (jCurrentWelfareInfo !== null && jCurrentWelfareInfo !== undefined) {
                let currentWelfareInfo = JSON.parse(jCurrentWelfareInfo);
                setWelfareInfo(currentWelfareInfo);
                setIsVisible(true);
            }
        } catch (ex) { }


        // gestiona addToOrder
        try {
            const jAddToOrder = localStorage.getItem("AddToOrder");
            if (jAddToOrder !== null && jAddToOrder !== undefined) {
                let currentAddToOrder = JSON.parse(jAddToOrder);
                setAddToOrder(currentAddToOrder);
                setIsVisible(true);
            }
        } catch (ex) { }


    }, [item]);

    useEffect(() => {
        let existItem = false;

        const jCurrentUser = localStorage.getItem("CurrentUser");
        if (jCurrentUser !== null && jCurrentUser !== undefined) {
            let currentUser = JSON.parse(jCurrentUser);

            if (currentUser.impersonate !== null && currentUser.impersonate !== undefined) {
                existItem = true;
            }
        }

        if (getQuotationInfoSession && getQuotationInfoSession.quotationId) {
            setQuotationInfo(getQuotationInfoSession);
            setIsVisible(true);
            existItem = true;

        } else {
            setQuotationInfo(null);
        }

        if (getAddToOrderSession && getAddToOrderSession.OrderNumber) {
            setAddToOrder(getAddToOrderSession);
            setIsVisible(true);
        } else {
            setAddToOrder(null);
        }

        setIsVisible(existItem);

    }, [getQuotationInfoSession, getAddToOrderSession])

    async function exitQuotationMode() {
        let result = await logoutSessionQuotation();
        if (result) {
            setQuotationInfoSession(null);
            setQuotationInfo(null);
            setIsVisible(false);
        }

    }

    async function exitAddToOrderMode() {
        //let result = await logoutSessionAddToOrder();
        //if (result) {
        //    setAddToOrderSession(null);
        //    setAddToOrder(null);
        //    setIsVisible(false);
        //}

    }

    return (
        <>
            {
                isVisible && <div className={item.className ? item.className : ""}>
                    {impersonate && !quotationInfo && <div dangerouslySetInnerHTML={{ __html: t("Header:WorkingCrossLogin").replace("#USERNAME#", impersonate) }}></div>}

                    {
                        quotationInfo && <>
                            <div dangerouslySetInnerHTML={{ __html: t("Header:WorkingQuotation").replaceAll("#QUOTATIONID#", quotationInfo.quotationId).replaceAll("#USERNAME#", quotationInfo.username) }}></div>
                            <div>
                                <a className="cursor-pointer text-underline h075 h6" onClick={_ => exitQuotationMode()}>{t("Header:ExitQuotationMode")}</a>
                            </div>
                        </>
                    }
                    {welfareInfo && <div dangerouslySetInnerHTML={{ __html: t("Header:WorkingWelfareInfo") }}></div>}
                    {addToOrder && <div dangerouslySetInnerHTML={{ __html: t("Header:WorkingAddToOrder").replace("#ORDERNUMBER#", addToOrder.OrderNumber) }}></div>}
                </div>
            }
        </>
    );
}
