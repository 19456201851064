import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../../js/Utils.js';
import { M3Icon } from "../../../Common/M3Icon";

export const ProductFilterByPrice = ({ rangePriceInfo, onApplyFilter, resetSelectedItem, defaultShowFilter=true }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const [cultureName] = useState(cookies.get("CultureName"));

    const [showFilter, setShowFilter] = useState(defaultShowFilter);

    // Slider Parametri
    const [minVal, setMinVal] = useState(0);
    const [maxVal, setMaxVal] = useState(5000);
    const minValRef = useRef(null);
    const maxValRef = useRef(null);
    const range = useRef(null);
    const [minRangePrice, setMinRangePrice] = useState(0);
    const [maxRangePrice, setMaxRangePrice] = useState(5000);

    useEffect(() => {
        setMinVal(parseInt(rangePriceInfo.min));
        setMinRangePrice(parseInt(rangePriceInfo.min));

        setMaxVal(parseInt(rangePriceInfo.max));
        setMaxRangePrice(parseInt(rangePriceInfo.max));
    }, [rangePriceInfo]);

    useEffect(() => {
        setMinVal(parseInt(rangePriceInfo.min));
        setMinRangePrice(parseInt(rangePriceInfo.min));

        setMaxVal(parseInt(rangePriceInfo.max));
        setMaxRangePrice(parseInt(rangePriceInfo.max));
    }, [resetSelectedItem]);

    // Convert to percentage
    function getPercent(value) {
        let min = rangePriceInfo.min;
        let max = rangePriceInfo.max;

        return Math.round(((value - min) / (max - min)) * 100)
    }

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minVal);
            const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

            if (range.current) {
                range.current.style.left = `${minPercent}%`;
                range.current.style.width = `${maxPercent - minPercent}%`;
            }

        }
    }, [minVal, showFilter]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(+minValRef.current.value);
            const maxPercent = getPercent(maxVal);

            if (range.current) {
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [maxVal, showFilter]);

    function onBlurValue() {
        let rangePrice = {
            min: minVal,
            max: maxVal
        };

        onApplyFilter(rangePrice);
    }

    function onShowFilter(event) {
        event.preventDefault();
        setShowFilter(!showFilter);
    }

    return (
        <div className="card bg-white rounded active my-2 text-primary h6 shadow" aria-current="true">
            <a href="#" className="text-decoration-none text-blue" onClick={(e) => onShowFilter(e)}>
                <div className="row">
                    <div className="col-10 pl-4 pt-2">
                        <span className="p-2 text-left">
                            <data m3lab="Product.Activity.Filter.Pricing">{t('Product:Activity:Filter:Pricing')}</data>
                        </span>
                    </div>
                    <div className="col-1 text-left p-1">
                        <data m3ico="Remove text-blue">
                            <span className="material-icons text-blue">
                                {showFilter && <M3Icon iconName="Remove" externalClass="text-blue" hasLabel={false} />}
                                {!showFilter && <M3Icon iconName="Add" externalClass="text-blue" hasLabel={false} />}
                            </span>
                        </data>
                    </div>
                </div>
            </a>
            {
                showFilter && <div className="tp-multirange-slider py-2" style={{ height: '50px' }}>
                    <input
                        type="range"
                        min={minRangePrice}
                        max={maxRangePrice}
                        value={minVal}
                        ref={minValRef}
                        onChange={(event) => {
                            const value = Math.min(+event.target.value, maxVal - 1);
                            setMinVal(value);
                            event.target.value = value.toString();
                        }}
                        onMouseUp={(e) => onBlurValue()}
                        className={(minVal > maxRangePrice - 100) ? "thumb thumb--zindex-5" : "thumb thumb--zindex-3"}
                    />
                    <input
                        type="range"
                        min={minRangePrice}
                        max={maxRangePrice}
                        value={maxVal}
                        ref={maxValRef}
                        onChange={(event) => {
                            const value = Math.max(+event.target.value, minVal + 1);
                            setMaxVal(value);
                            event.target.value = value.toString();
                        }}
                        onMouseUp={(e) => onBlurValue()}
                        className="thumb thumb--zindex-4"
                    />

                    <div className="slider">
                        <div className="slider__track" />
                        <div ref={range} className="slider__range" />
                        <div className="slider__left-value">{formatPrice(minVal, rangePriceInfo.currency, cultureName, { noDecimal: true })}</div>
                        <div className="slider__right-value">{formatPrice(maxVal, rangePriceInfo.currency, cultureName, { noDecimal: true })}</div>
                    </div>
                </div>
            }
        </div>
    );
}