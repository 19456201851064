import React from 'react';
import { formatPrice, formatDateTime } from '../../../js/Utils.js';
import { IconChoosePrint } from './IconChoosePrint.js';
import { useTranslation } from 'react-i18next';
import { replaceAllStyleElements } from "../../../js/PrintUtility";
import { PrintStepHeader } from './PrintStepHeader.js';
import Cookies from 'universal-cookie';

export const PrintProductsNotes = ({ arrNotesProdcuts, onHandleIsPrint }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    return (
        <>
            {arrNotesProdcuts != null && arrNotesProdcuts.length > 0 &&
            <>
                <PrintStepHeader
                    title={ t(`Template:InformationDetailProduct`) }
                    PNR=''
                    totaleViaggio=''
                    iconName=''
                    cultureName={cultureName}
                    onHandleIsPrint={onHandleIsPrint }
                />
                {arrNotesProdcuts.map((product, index) =>
                    <>
                        <div className="border" style={{ display: 'grid' }}>
                            <table className="w-100">
                                <tbody>
                                    <tr className="customBackground">
                                        <td className="pl-2">
                                            <span className="h6 float-start" style={{ color: "#780704" }}>{product[0].name}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {product[0].notes.map((note) =>
                                <div style={{ padding: '5px', textAlign: "justify" }}>
                                    <span style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(note) }} />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </>
            }
        </>
    );
}