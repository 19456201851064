import React from 'react';
import Cookies from 'universal-cookie';
import { formatDateTime, formatPrice } from '../../../js/Utils.js';
import { getProductIconToPrint } from '../../../js/PrintUtility.js';
import { PrintStepHeader } from './PrintStepHeader.js';
import { useTranslation } from 'react-i18next';


export const PrintAncillaries = ({ recapItems, isConfirmPrint, isPrintCliente, onHandleIsPrint}) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    return (
        <>
            {recapItems &&
            <>
                 <PrintStepHeader
                    title={t("Print:AncillariesTitle")}
                    PNR=''
                    totaleViaggio=''
                    iconName=''
                    cultureName={cultureName}
                    onHandleIsPrint={onHandleIsPrint}
                />
                <div className="border shadow">
                    <table className="w-100">
                        <thead>
                            <tr className="customBackground">
                                <td className="text-left px-2 h6">{t(`Template:Service`)}</td>
                                <td className="px-2 text-right h6">{t("Print:Ancillary")}</td>
                            </tr>
                        </thead>
                        <tbody className=" p-2">
                            {recapItems &&
                                recapItems.filter((item) => item.productType === "Generic" && item.IdTipoProdotto !== "3" && item.IdTipoProdotto !== "6" &&
                                    ((!isConfirmPrint && item.isSelected && item.step === -1) || isConfirmPrint))
                                    .map((item, key) =>
                                        <tr key={key}>
                                            <td className="px-2" style={{ textAlign: 'left' }}>
                                                {item.productInfo ? item.productInfo.name : item.productName}
                                            </td>
                                            {!isPrintCliente &&
                                                <td className="text-right px-2" style={{ width: '30%', maxWidth: '30%' }}>
                                                    {item.productInfo ?
                                                        formatPrice(item.productInfo.priceBar.invoicePrice, item.productInfo.priceBar.currency, cultureName)
                                                    :
                                                        formatPrice(item.sellAmount, item.sellCurrency, cultureName) // preventivo
                                                    }
                                                </td>
                                            }
                                        </tr>
                                    )
                            }
                        </tbody>
                    </table>
                </div>
            </>
            }
        </>
    );
}