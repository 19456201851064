import React, { useState, useCallback } from 'react';
import configData from "../../../../appsettings.json";
import { getAuthToken } from '../../../../js/Utils.js';
import { FormRichiestaInterventoBooking } from '../../../Cart/Form/FormRichiestaInterventoBooking';

export const HeaderRequestQuotation = ({ item, modal}) => {
    let token = getAuthToken();

    const [richiestaBookOnSendRes, setRichiestaBookOnSendRes] = useState(null);

    // open popup "RichiestaInterventoBooking"
    const openModalRichiestaInterventoBooking = useCallback(() => {
        let myBtn = document.getElementById("open_Header_RichiestaInterventoBooking");
        myBtn.click();
    }, []);

    // Funzionalita richiesta intervento booking
    function onSendRichiestaInterventoBooking(inputData) {
        if (!token) {
            const button = document.querySelector('.open-login-modal');
            button.click();
            return;
        }

        inputData.addictionalProductSelected = [];
        saveRichiestaInterventoBooking(inputData);
    }

    async function saveRichiestaInterventoBooking(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'webcontent/SaveRichiestaInterventoBooking', requestOption);
        const response = await fetchedRes.json();

        if (response) {
            setRichiestaBookOnSendRes(response);
        } else {
            setRichiestaBookOnSendRes({ success: false, message: "Generic Error" });
        }
    }

    function getClassName(className) {
        if (!className)
            return '';

        try {
            return className.split("#")[1];
        } catch (ex) { }

        return '';
    }

    return (
        <>
            <a className={getClassName(item.className) + ` ${modal ? 'd-block' : 'd-none'} d-sm-block`} style={{
                fontSize: modal ? '1.5rem' : ''
            }} aria-current="page" href="javascript:void(0)" onClick={_ => openModalRichiestaInterventoBooking()} >
                Richiedi Preventivo
            </a>
            <FormRichiestaInterventoBooking
                customId="Header_RichiestaInterventoBooking"
                onSend={onSendRichiestaInterventoBooking}
                onSendResponse={richiestaBookOnSendRes}
                minDate={null}
                maxDate={null}
                isFromCart={false}
            />
        </>
    );
}
