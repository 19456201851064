import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useAuth } from '../../Auth/AuthProvider';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../Common/Loading';
import { handleChangePax, renderOptionBookParameters, renderPaxBookParameters, productNotAvailableClass_Opacity, productNotAvailableClass_Border } from './SharedFunctions';
import { formatDateTime } from '../../../js/Utils.js';
import { registerSessionQuotation } from '../../../js/QuotationUtils';
import { BookParameterMandatoryCssClass } from '../../../js/Constant';
import configData from "../../../appsettings.json";
import { AvailableCruise } from "../Available/AvailableCruise";
import { M3IconProductType } from '../../Common/M3IconProductType';
import { M3Pax } from "../../Common/M3Pax";
import { CancelPolicyModal } from '../../Product/Common/CancelPolicyModal';

export const CartCruise = ({ cartItemId, isCartQuotation, userPermissions, cartPaxesNames, staticBookParams, productBookParams, handleBookParameters, handleReservationData, userLockBook, callTermsFinished, onOpenModalConfirmDelete }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const cultureName = cookies.get("CultureName");
    const { setQuotationInfoSession } = useAuth();

    // debug
    const [searchParams] = useSearchParams();
    let debug = searchParams.get("debug");

    const [isLoading, setIsLoading] = useState(true);
    const [cartItem, setCartItem] = useState([]);
    const [cabins, setCabins] = useState([]);


    useEffect(() => {
        const getCartItem = async () => {
            callGetCartItem();
        }

        setIsLoading(true);
        getCartItem();
    }, []);

    useEffect(() => {
        callGetCartItem();
    }, [callTermsFinished]);

    useEffect(() => {

        if (userPermissions && userPermissions.cartOnlyOption) {
            let rbOptionBook = document.getElementById(`rbCruiseOption_${cartItemId}`);
            if (rbOptionBook)
                rbOptionBook.click();
        }

    }, [userPermissions, callTermsFinished])

    useEffect(() => {
        if (cartItem && cartItem.passengers && cartPaxesNames && cartPaxesNames.length > 0) {
            for (let i = 0; i < cartItem.passengers.length; i++) {
                let pax = cartItem.passengers[i];
                let e = { target: { value: pax.index }, Index: pax.index };

                let ddlPaxId = `ci_${cartItem.cartItemId}_option_1_pax_${pax.index}_ddlPax`;
                let ddlPax = document.getElementById(ddlPaxId);
                if (ddlPax) {
                    ddlPax.value = pax.index;
                    handleChangePax(cartItem, cartPaxesNames, handleBookParameters, 1, pax.index, ddlPaxId, e);
                }
            }
        }
    }, [cartPaxesNames]);


    async function callGetCartItem() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/GetCartItem/' + cartItemId, requestOption);
        const response = await fetchedRes.json();

        setCartItem(response);
        setIsLoading(false);
    }

    const changeCabin = (e) => {
        let bpCabinIdx = productBookParams.OptionBookParameters[0].bookParameters.findIndex(x => x.id.includes('-CAB'));
        let cabinBp = productBookParams.OptionBookParameters[0].bookParameters[bpCabinIdx];

        let data = { Sequence: 1, BookParameters: [{ Id: cabinBp.id, Value: e.target.value }] };
        handleBookParameters(cartItemId, "OPTION", data);
    }

    const renderDecks = () => {
        if (productBookParams === null || productBookParams === undefined ||
            productBookParams.OptionBookParameters === null || productBookParams.OptionBookParameters === undefined || productBookParams.OptionBookParameters.length === 0 ||
            productBookParams.OptionBookParameters[0].bookParameters === null || productBookParams.OptionBookParameters[0].bookParameters === undefined || productBookParams.OptionBookParameters[0].bookParameters.length === 0)
            return;

        let bpCabinIdx = productBookParams.OptionBookParameters[0].bookParameters.findIndex(x => x.id.includes('-CAB'));
        if (bpCabinIdx < 0) {
            return (<></>);
        } else {
            let cabinBp = productBookParams.OptionBookParameters[0].bookParameters[bpCabinIdx];

            // generate decks
            let decks = [];
            for (let i = 0; i < cabinBp.validValues.length; i++) {
                let deckCode = cabinBp.validValues[i].value.split(' - ')[1];
                let deckName = cabinBp.validValues[i].value.split(' - ')[2];

                if (deckName === "")
                    deckName = "N/A";

                let deckIdx = decks.findIndex(x => x.deckCode === deckCode);
                if (deckIdx < 0) {
                    decks.push({ deckCode: deckCode, deckName: deckName });
                }
            }

            return (
                <>
                    <div className="form-floating col-md-3">
                        <select id={`CartItemId_${cartItemId}_CruiseDecks`} className="form-select border-0 border-bottom border-dark" aria-label="Fornitore" onChange={e => setDeckCabins(e.target.value)}>
                            <option value="-1">Seleziona</option>
                            {decks.map((deck, index) =>
                                <option key={index} value={deck.deckCode}>{deck.deckName}</option>
                            )}
                        </select>
                        <label htmlFor={`CartItemId_${cartItemId}_CruiseDecks`} className="active">{t("MyBook:SelectBridge")}&nbsp; (*)</label>
                    </div>
                </>
            );
        }
    }
    const setDeckCabins = (selectedDeckCode) => {
        let bpCabinIdx = productBookParams.OptionBookParameters[0].bookParameters.findIndex(x => x.id.includes('-CAB'));
        let cabinBp = productBookParams.OptionBookParameters[0].bookParameters[bpCabinIdx];

        // generate cabins
        let myCabins = [];
        for (let i = 0; i < cabinBp.validValues.length; i++) {
            let key = cabinBp.validValues[i].key;
            let deckCode = cabinBp.validValues[i].value.split(' - ')[1];
            if (deckCode !== selectedDeckCode)
                continue;

            let cabinIdx = myCabins.findIndex(x => x.key === key);
            if (cabinIdx < 0) {
                myCabins.push({ key: key, val: key });
            }
        }

        setCabins(myCabins);
    }

    async function callUpdateQuotationItemStatus(quotationId, quotationItemId) {
        let inputData =
        {
            QuotationId: quotationId,
            QuotationItemId: quotationItemId,
            Status: 70,
            LogNote: "Status changed from CartQuotation due to BookingTerm error",
            QuotationSettings: { BaseUrl: "" }
        };

        const requestOption =
        {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/UpdateQuotationItemStatus', requestOption);
        const response = await fetchedRes.json();

        let responseQuotation = await registerSessionQuotation(quotationId, quotationItemId, true, cartItemId);
        if (responseQuotation) {
            setQuotationInfoSession(responseQuotation.quotationInfo);
            window.location.href = responseQuotation.url;
        }
    }

    function onClickChangeProduct(url, quotationId, quotationItemId) {
        if (quotationId !== null && quotationId !== undefined && quotationItemId !== null && quotationItemId !== undefined) {
            callUpdateQuotationItemStatus(quotationId, quotationItemId);
        }
        else {
            window.location.href = url;
        }
    }

    async function callUpdateCruiseBookType(ciId, data) {
        let bookType = data.BookParameters[0].Value;

        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/UpdateCruiseBookType/' + ciId + '/' + bookType, requestOption);
        const response = await fetchedRes.json();

        if (response && response.updated) {
            handleBookParameters(ciId, "OPTION", data);
        }
    }

    const cruiseBookTypeOnChange = (e) => {
        let id = e.target.id;
        let val = e.target.value;

        // --- set label class ---
        var selectedLabelId = id === `rbCruiseBook_${cartItemId}` ? `lblCruiseBook_${cartItemId}` : `lblCruiseQuote_${cartItemId}`;
        var notSelectedLabelId = selectedLabelId === `lblCruiseBook_${cartItemId}` ? `lblCruiseQuote_${cartItemId}` : `lblCruiseBook_${cartItemId}`;
        let lblSelected = document.getElementById(selectedLabelId);
        let lblNotSelected = document.getElementById(notSelectedLabelId);

        lblSelected.classList.remove("btn-flight-operation");
        lblSelected.classList.add("btn-flight-operation-selected");

        lblNotSelected.classList.remove("btn-flight-operation-selected");
        lblNotSelected.classList.add("btn-flight-operation");
        // --- set label class ---

        if (val === "on") {
            let data = { BookParameters: [{ Id: "CRS-BOOKTYPE", Value: id === "rbCruiseBook_" + cartItemId ? "B" : "Q" }] };
            callUpdateCruiseBookType(cartItem.cartItemId, data);
        }
    }

    const renderAction = (pBookParmas) => {
        if (pBookParmas === null || pBookParmas === undefined ||
            pBookParmas.OptionBookParameters === null || pBookParmas.OptionBookParameters === undefined || pBookParmas.OptionBookParameters.length === 0 ||
            !pBookParmas.OptionBookParameters[0].bookParameters || pBookParmas.OptionBookParameters[0].bookParameters.length === 0
        ) {
            return (<></>);
        } else if (cartItem && cartItem.isDmc) {
            return (<></>);
        }
        else {
            let bpBookOperationIdx = pBookParmas.OptionBookParameters[0].bookParameters.findIndex(x => x.id === "CRS-BOOKTYPE");
            let bpBookOperation = productBookParams.OptionBookParameters[0].bookParameters[bpBookOperationIdx];

            let canQuote = bpBookOperation.validValues.findIndex(x => x.key === "Q") > -1 && !userLockBook;
            let canBook = bpBookOperation.validValues.findIndex(x => x.key === "B") > -1 && !userLockBook;

            return (
                <>
                    {canBook
                        ? (
                            <>
                                <input id={`rbCruiseBook_${cartItemId}`} type="radio" className="btn-check btn-sm" name={`rbCruiseBookType_${cartItemId}`} autoComplete="off" onChange={e => cruiseBookTypeOnChange(e)} defaultChecked={canBook} />
                                <label id={`lblCruiseBook_${cartItemId}`} className={`btn btn-sm ${canBook ? "btn-flight-operation-selected" : "btn-flight-operation"}`} htmlFor={`rbCruiseBook_${cartItemId}`}>{t("Button:Book")}</label>
                            </>
                        )
                        : (<></>)
                    }

                    {canQuote
                        ? (
                            <>
                                <input id={`rbCruiseQuote_${cartItemId}`} type="radio" className="btn-check" name={`rbCruiseBookType_${cartItemId}`} autoComplete="off" onChange={e => cruiseBookTypeOnChange(e)} defaultChecked={canQuote && !canBook} />
                                <label id={`lblCruiseQuote_${cartItemId}`} className={`btn btn-sm ${canQuote && !canBook ? "btn-flight-operation-selected" : "btn-flight-operation"}`} htmlFor={`rbCruiseQuote_${cartItemId}`}>{t("MyBook:Option")}</label>
                            </>
                        )
                        : (<></>)
                    }
                </>
            );
        }
    }

    function onClickDeleteNotAvailable() {
        onOpenModalConfirmDelete(cartItemId);
    }

    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : (
                    <>
                        <div className={"card mt-3 p-3 " + productNotAvailableClass_Border(productBookParams)}>
                            <div className="card p-3">
                                <div className={productNotAvailableClass_Opacity(productBookParams)}>

                                    <div className="row">
                                        <div className="col-7">
                                            <M3IconProductType productType={cartItem.productType} externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                                            <span className="titlecard">{cartItem.name}</span>
                                        </div>
                                        <div className="col-2">
                                            <div class="w-100">
                                                <M3Pax adt={cartItem.adults} chd={cartItem.children} chdAges={cartItem.childrenAges} extraOptions={{ showInfant: false, iconClass: "icon-15" }} />
                                            </div>
                                        </div>
                                        <div className="col-3 text-right">
                                            {cartItem.status && cartItem.status === 'OnRequest' && <span className="text-warning h6 mb-0 small d-block"><var>{cartItem.status}</var></span>}
                                            {cartItem.supplier && <span className="text-info small d-block mt-2"><var>{cartItem.supplier.supplierName}</var></span>}
                                        </div>
                                    </div>

                                    {callTermsFinished &&
                                        <React.Fragment>
                                            <div className="d-inline float-end">
                                                <button className="btn btn-sm btn-danger" onClick={() => onOpenModalConfirmDelete(cartItemId)}>{t('Button:Delete')}</button>
                                            </div>

                                            <div className="btn-group float-end ml-4 mr-2">
                                                {renderAction(productBookParams)}
                                            </div>
                                        </React.Fragment>
                                    }

                                    <p><label>{formatDateTime(cartItem.dateFrom, cultureName)} - {formatDateTime(cartItem.dateTo, cultureName)}</label></p>
                                </div>
                                <div className={productNotAvailableClass_Opacity(productBookParams)}>
                                    <span><b>Cabina</b> {cartItem.cabinName}</span>
                                    <br />
                                    {
                                        cartItem.cancelPolicy && <><span className={cartItem.cancelPolicy.isFree ? "penalty free" : "penalty"}>
                                            {cartItem.cancelPolicy.isFree ? t("CancelPolicy:FreeCancellation") + " " + formatDateTime(cartItem.cancelPolicy.expirationDate, { twoDigits: true }) : t("CancelPolicy:PenaltyCancellation")}
                                        </span>
                                            <CancelPolicyModal cancellationPolicy={cartItem.cancelPolicy} />
                                        </>
                                    }


                                    {!callTermsFinished
                                        ? (<><Loading textMsg={t(`Cart:LoadingBookingTerms`)} isSmall={true} /></>)
                                        : (
                                            <>
                                                {productBookParams !== null && productBookParams !== undefined && productBookParams.IsAvailable
                                                    ? (
                                                        <>
                                                            {/*Deck Cabin selection*/}
                                                            <div className="row mb-4">
                                                                {renderDecks()}

                                                                {(cabins && cabins.length > 0) &&
                                                                    <div className="form-floating col-md-3">
                                                                        <select id={`CartItemId_${cartItemId}_CruiseDeckCabins`} className="form-select border-0 border-bottom border-dark" aria-label="Fornitore" onChange={e => changeCabin(e)}>
                                                                            <option value="">{t("Button:Select")}</option>
                                                                            {cabins && cabins.length > 0
                                                                                ? (cabins.map((cabin, index) =>
                                                                                    <option key={index} value={cabin.key}>{cabin.key}</option>
                                                                                ))
                                                                                : (<></>)
                                                                            }
                                                                        </select>
                                                                        <label htmlFor={`CartItemId_${cartItemId}_CruiseDeckCabins`} className="active">
                                                                            <span className={BookParameterMandatoryCssClass.Mandatory}>{t("Button:Select")}&nbsp; Cabin (*)</span>
                                                                        </label>
                                                                    </div>
                                                                }
                                                            </div>

                                                            {/*Passengers*/}
                                                            {cartItem.passengers.map(pax =>
                                                                <div key={pax.index} className="row">
                                                                    <div className="form-floating col-md-3">
                                                                        <select id={`ci_${cartItem.cartItemId}_option_1_pax_${pax.index}_ddlPax`} className="form-select border-0 border-bottom border-dark" aria-label="Fornitore" onChange={(e) => handleChangePax(cartItem, cartPaxesNames, handleBookParameters, 1, pax.index, `ci_${cartItem.cartItemId}_option_1_pax_${pax.index}_ddlPax`, e)}>
                                                                            <option value="-1">{t("Button:Select")}&nbsp; pax</option>
                                                                            {cartPaxesNames !== null && cartPaxesNames !== undefined && cartPaxesNames.length > 0
                                                                                ? (
                                                                                    cartPaxesNames.map(paxName =>
                                                                                        <option key={paxName.Index} value={paxName.Index}>{paxName.BpFirstName[0].Value} {paxName.BpLastName[0].Value}</option>
                                                                                    )
                                                                                )
                                                                                : (<></>)
                                                                            }
                                                                        </select>
                                                                        <label htmlFor={`ci_${cartItem.cartItemId}_option_1_pax_${pax.index}`}>
                                                                            <span className={BookParameterMandatoryCssClass.Mandatory}>{t("MyBook:Traveler")}&nbsp; {pax.paxType} {pax.index}:</span>
                                                                        </label>
                                                                    </div>

                                                                    {renderPaxBookParameters(cartItem, productBookParams, staticBookParams, handleBookParameters, 1, pax.index)}
                                                                </div>
                                                            )}

                                                            {renderOptionBookParameters(cartItem, productBookParams, handleBookParameters, 1)}
                                                        </>
                                                    )
                                                    : (<></>)
                                                }
                                            </>
                                        )
                                    }
                                </div>

                                {/*Product Not Available*/}
                                {productBookParams !== null && productBookParams !== undefined && !productBookParams.IsAvailable
                                    ? (
                                        <AvailableCruise
                                            listUrl={productBookParams.TermsError_ListUrl}
                                            quotationId={productBookParams.QuotationId}
                                            quotationItemId={productBookParams.QuotationItemId}
                                            deleteFn={onClickDeleteNotAvailable}
                                            changeProductFn={onClickChangeProduct}
                                            mode="CART"
                                        />
                                    )
                                    : (<></>)
                                }
                            </div>

                            {/* --- DEBUG --- */}
                            {debug === "1"
                                ? (<><br /><br />{JSON.stringify(productBookParams)}</>)
                                : (<></>)
                            }
                            {/* --- DEBUG --- */}
                        </div>
                    </>
                )
            }
        </>
    )
}