export function isQuotationPaxesFilled(myPaxes) {
    let isValid = true;

    if (myPaxes && myPaxes.length > 0) {
        for (var i = 0; i < myPaxes.length; i++) {

            let myBookParam = myPaxes[i].bookParams;
            for (var j = 0; j < myBookParam.length; j++) {
                if (myBookParam[j].isMandatory && !myBookParam[j].bpValue)
                    isValid = false;
            }
        }
    }

    return isValid;
}