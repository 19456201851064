import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { getDisplayPrice } from '../../../../js/Utils.js';
import { M3Icon } from '../../../Common/M3Icon';
import { openNewWindowTrain } from '../../../../js/ProductService.js';

export const TrainFarePivotCompactView = ({ fare, index, selectFares, showCost, templateInfo, onSelectFares }) => {
    const { t } = useTranslation();

    const [openOffertId, setOpenOffertId] = useState(null);
    const [offertLink, setOffertLink] = useState(null);

    const cookies = new Cookies();
    const [cultureName] = useState(cookies.get("CultureName"));

    useEffect(() => {
        if (!selectFares && fare && fare.farePivot && !openOffertId)
            setOpenOffertId(fare.farePivot[0].fareComponents.offertId);
        else if (selectFares)
            setOpenOffertId(selectFares[index].fareComponents.offerTypeId);

    }, [fare, selectFares]);

    function handleOnSelectFares(service, key) {
        onSelectFares(service, key, fare.fareArgs);
    }

    function isSelectedFare(rowComponent, key, selectFares) {
        let className = '';

        if (!rowComponent)
            return '';

        if (selectFares && selectFares.length >= (key + 1)) {
            if (selectFares[key].fareComponents.uniqueId === rowComponent.uniqueId)
                className = 'bg-blue text-white';
        }

        return className;
    }

    function renderPivotOffertId(fare, offertId) {
        let offerName = offertId;

        if (fare.offerList) {
            let offer = fare.offerList.filter(x => x.id === offertId);
            if (offer && offer.length > 0) {
                offerName = offer[0].name;
            }

        }

        return offerName;
    }

    function getBestPriceOffert(pivot) {
        let bestPriceOffert = '';
        if (pivot && pivot.rows) {
            let rowsAvailable = pivot.rows
                .filter(x => x.fareComponents && x.fareComponents.pricing)
                .map(x => x.fareComponents.pricing)
                .sort(compareNumbers);

            if (rowsAvailable) {
                bestPriceOffert = getDisplayPrice(rowsAvailable[0]);
            }
        }

        return bestPriceOffert;
    }

    function compareNumbers(pricing1, pricing2) {
        if (showCost)
            return pricing1.costoFornitore - pricing2.costoFornitore

        return pricing1.displayPrice - pricing2.displayPrice;
    }

    function renderPivotServiceId(fare, serviceId) {
        let serviceName = serviceId;
        let serviceLink = '';
        if (fare.serviceList) {
            let service = fare.serviceList.filter(x => x.id === serviceId);
            if (service && service.length > 0) {
                serviceName = service[0].name;
                serviceLink = service[0].descriptionLink;
            }
        }

        return <>
            {serviceName}
            <span className="d-none">{serviceId}</span>
            {
                serviceLink && <button className="btn p-0" onClick={_ => openNewWindowTrain(serviceLink)}>
                    <data m3ico="info text-gray-500 icon-10">
                        <M3Icon iconName="Info" externalClass="text-gray-500 icon-10" />
                    </data>
                </button>
            }
        </>;
    }

    function changeTab(offertId) {

        setOpenOffertId(offertId);

        let offertLink = '';
        if (fare.offerList) {
            let offer = fare.offerList.filter(x => x.id === offertId);
            if (offer && offer.length > 0) {
                offertLink = offer[0].descriptionLink;
            }

        }

        setOffertLink(offertLink);
    }

    return (
        <>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <ul className="nav nav-tabs">
                    {
                        fare.farePivot && fare.farePivot.map((pivot, keyPivot) => {
                            return <React.Fragment key={keyPivot}>
                                <li className="nav-item cursor-pointer">
                                    <a class={"nav-link h5 " + (openOffertId === pivot.offertId ? "active" : "")} aria-current="page" onClick={_ => changeTab(pivot.offertId)}>
                                        {renderPivotOffertId(fare, pivot.offertId)}
                                        <span className="d-none">{pivot.offertId}</span>
                                        {
                                            openOffertId === pivot.offertId && <p className="m-0">
                                                <label>{t('General:From')} {getBestPriceOffert(pivot)}</label>
                                            </p>
                                        }
                                    </a>
                                </li>
                            </React.Fragment>
                        })
                    }
                </ul>
                {
                    fare.farePivot && fare.farePivot.filter(x => x.offertId === openOffertId).map((pivot, keyPivot) => {
                        return <React.Fragment key={keyPivot}>
                            <div className="p-1 ">
                                {
                                    pivot.rows && pivot.rows.filter(x => x.fareComponents).map((row, keyRow) => {
                                        return <div key={keyRow} className={"rounded border shadow p-2 mt-2 animate-up-5 cursor-pointer " + isSelectedFare(row.fareComponents, index, selectFares)} onClick={_ => handleOnSelectFares(row.fareComponents, index)}>
                                            <div className="row">
                                                <div className="col-12 col-md-9 col-xl-9 ">
                                                    {renderPivotServiceId(fare, row.fareComponents.serviceTypeId)}
                                                </div>
                                                <div className="col-12 col-md-3 col-xl-3">
                                                    {getDisplayPrice(row.fareComponents.pricing, cultureName, showCost, templateInfo)}
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </React.Fragment>
                    })
                }
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                {
                    offertLink && <>
                        <h6 className="mt-2">
                            {t('Product:Train:ShowOffertDetail')}
                            <button className="btn p-0" onClick={_ => openNewWindowTrain(offertLink)}>
                                <data m3ico="info text-gray-500 icon-10">
                                    <M3Icon iconName="Info" externalClass="text-gray-500 icon-10" />
                                </data>
                            </button>
                        </h6>
                    </>
                }
            </div>
        </>
    );
}