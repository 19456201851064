import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { M3Icon } from '../../../Common/M3Icon'


export const HeaderMenuMobile = ({ item, moduleSelected, onChangePage, modal }) => {

    return (
        <>
            <div className={`d-flex d-sm-none align-items-center`} onClick={() => {
                document.querySelector(".modal-backdrop").style.zIndex = 0
            }}>
                <div className={modal && 'd-none'} style={{ color: 'black', cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#hamburgerMenuModal">
                    <M3Icon iconName="MenuBurgher" />
                </div>



            </div>
        </>
    );
}
