import React, { useState, useEffect } from 'react';
import configData from "../appsettings.json";
import { getAuthToken } from '../js/Utils.js';
import Cookies from 'universal-cookie';
import { Engine } from './Engine';
import { Login } from './Auth/Login';

export const Home = () => {
    let token = getAuthToken();
    const cookies = new Cookies();

    let params = new URLSearchParams(window.location.search);
    let mySSOToken = params.get('ssoToken');
    let mySTkn = params.get('sTkn');
    let addToOrder = params.get('ato');
    let userAutoLogin = params.get('ual');

    const [isFake, setIsFake] = useState(true);

    useEffect(() => {
        cookies.remove("CultureName", { path: '/' });
        cookies.set('CultureName', "it-IT", { path: '/' });
    }, []);

    useEffect(() => {

        if (!token || (mySSOToken !== null || mySTkn !== null || addToOrder !== null || userAutoLogin !== null))
            setIsFake(true);
        else
            setIsFake(false);

    }, [token])

    return (
        <>

            {
                isFake && configData.Settings.Website.Home === 'WITHLOGIN'
                    ? (
                        <div className="container">
                            <div className='d-flex justify-content-center py-5 border'>
                                <div className='w-30 border rounded p-4 bg-white'>
                                    <Login />
                                </div>
                            </div>
                        </div>
                    )
                    : <Engine isFake={isFake} startModule="HOTEL" />
            }
        </>
    );
}
