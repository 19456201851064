import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { M3Icon } from '../../Common/M3Icon.js';
import { formatDateTime } from '../../../js/Utils.js';

export const TrippieHeader = ({ searchParameters, onUpdateSearchParam }) => {
    const { t } = useTranslation();

    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [updateTravelName, setUpdateTravelName] = useState(false);
    const [paxesLabel, setPaxesLabel] = useState('');
    const [travelName, setTravelName] = useState('');
    const [selectComposition, setSelectComposition] = useState([{
        adults: 2,
        children: 0,
        childAges: []
    }]);
    const [selectDates, setSelectDates] = useState(null);

    useEffect(() => {
        if (searchParameters) {

            setTravelName(searchParameters.text);

            setSelectDates({
                dateFrom: searchParameters.dateFrom,
                dateTo: searchParameters.dateTo
            });

            if (searchParameters.roomsComposition && searchParameters.roomsComposition.length > 0)
                setSelectComposition(searchParameters.roomsComposition);
        }

    }, [searchParameters])

    useEffect(() => {

        setPaxesLabel(getLabelName());

    }, [searchParameters])

    function getLabelName() {
        const totalRoom = searchParameters.roomsComposition.length;
        const totalAdt = searchParameters.roomsComposition.reduce((accumulator, object) => {
            return accumulator + object.adults;
        }, 0);
        const totalChd = searchParameters.roomsComposition.reduce((accumulator, object) => {
            return accumulator + object.children;
        }, 0);

        let label = "";

        label = totalAdt + " ";
        if (totalAdt === 1) {
            label += t('SearchEngine:Adult');
        } else {
            label += t('SearchEngine:Adults');
        }

        label += " + " + totalChd + " ";
        if (totalChd === 1) {
            label += t('SearchEngine:Child');
        } else {
            label += t('SearchEngine:Children');
        }

        label += " + " + totalRoom + " ";

        if (totalRoom === 1) {
            label += t('SearchEngine:Room');
        } else {
            label += t('SearchEngine:Rooms');
        }

        return label;
    }

    const onChangeTravelName = function (event) {
        setTravelName(event.target.value);
    }

    function onEditTravelName() {

        setUpdateTravelName(!updateTravelName);

        if (updateTravelName && selectDates)
            onUpdateSearchParam(travelName, selectDates.dateFrom, selectDates.dateTo, selectComposition);

        try {
            document.getElementById("trippie_travelname").focus();
        } catch (ex) { }
    }

    function handleKeyDown(event) {
        if (event.key === 'Enter')
            onEditTravelName();
    }

    return (
        <>
            <div className="row m-0">
                <div className="trippie-card px-4 py-2 rounded" style={{ backgroundColor: 'rgba(255, 255, 255, .8)' }}>
                    <div className="text-center text-muted h6 fw-normal">
                        {t('Trippie:SearchEngine:Placeholder')}
                    </div>
                    <div className="text-center">
                        <div className="">
                            <div className="h3">

                                {
                                    updateTravelName && <input
                                        id="trippie_travelname"
                                        type="text"
                                        className="w-90 small"
                                        value={travelName}
                                        ref={(inp) => {
                                            try {
                                                inp.focus();
                                            } catch (ex) { }
                                        }}
                                        onKeyDown={handleKeyDown}
                                        placeholder={t('Trippie:SearchEngine:TravelTitle')}
                                        onChange={e => onChangeTravelName(e)}
                                        onBlur={e => onEditTravelName()} />
                                }

                                {
                                    !updateTravelName && <>
                                        {
                                            travelName ? travelName : t('Trippie:SearchEngine:TravelTitle')
                                        }
                                    </>
                                }

                                <button className="btn btn-sm text-success bg-transparent ml-1 p-1" onClick={_ => onEditTravelName()}>
                                    <M3Icon iconName="Edit" />
                                </button>
                            </div>
                            <div className="cursor-pointer" data-bs-toggle="modal" data-bs-target="#modal_trippie_manage_step">
                                {
                                    searchParameters && searchParameters.dateFrom && <div className="float-start px-3">
                                        <span className="mr-1">{t('MyBook:From')}</span>
                                        <span className="text-capitalize">{formatDateTime(searchParameters.dateFrom, cultureName, { noYear: true, monthLabel: true })}</span>
                                        <span className="ml-1 mr-1">{t('MyBook:To')}</span>
                                        <span className="text-capitalize">{formatDateTime(searchParameters.dateTo, cultureName, { noYear: true, monthLabel: true })}</span>
                                    </div>
                                }
                                {
                                    (!searchParameters || !searchParameters.dateFrom) && <div className="float-start px-3">{t('Calendar:SelectAvailableDate')}</div>
                                }
                                {
                                    searchParameters && searchParameters.roomsComposition && <div className="float-end px-3">
                                        {paxesLabel}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
