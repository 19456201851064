import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { formatPrice } from '../../../../js/Utils.js';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";

export const TrainItinerySeatMap = ({ id, seatMap, haveErrorSeat, onSelectSeat }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();

    const [seatMapping, setSeatMapping] = useState(null);
    const [seatSelected, setSeatSelected] = useState([]);
    const [maxSelectSeat, setMaxSelectSeat] = useState(1);
    const [totalPrice, setTotalPrice] = useState({ amount: 0, currency: 'EUR' });

    useEffect(() => {
        let updSeatMapping = [];
        let updSeatSelected = [];

        if (!haveErrorSeat && seatMap) {
            for (let i = 0; i < seatMap.length; i++) {
                let sm = seatMap[i];

                updSeatMapping.push({
                    uniqueId: sm.segment.uniqueId,
                    wagonIds: sm.layouts.map(x => x.wagonId),
                    wagons: sm.layouts,
                    segmentIndex: (i + 1),
                    segment: sm.segment,
                    seatSelectionCosts: sm.seatSelectionCosts
                });

                updSeatSelected.push({
                    wagonId: sm.layouts[0].wagonId,
                    selectPaxIndexToAddSeat: 1,
                    selectSeatNumber: 0,
                    selectPaxHaveSeat: '',
                    segmentIndex: (i + 1),
                    seatIds: sm.seatSelectionCosts.map(ssc => {
                        return {
                            travelerIndex: ssc.travelerIndex,
                            seatId: null,
                            price: ssc.price
                        };
                    })
                });

                try {
                    setMaxSelectSeat(sm.seatSelectionCosts.length);
                } catch (ex) {
                    console.log("TRAIN SELECT SEAT: " + ex);
                }

            }
        }

        setSeatMapping(updSeatMapping);
        setSeatSelected(updSeatSelected);

    }, [seatMap, haveErrorSeat])

    function onOpenAccordionId(id, key) {
        let myId = id + "_Seat_" + key;
        let myItems = document.querySelectorAll(".train-seat-collapse");
        for (let i = 0; i < myItems.length; i++) {
            let myItem = myItems[i];
            if (myItem.id !== myId) {

                try {
                    let pnlId = myItem.dataset.bsTarget.replaceAll('#', '');
                    let pnlCollpsed = document.getElementById(pnlId);
                    pnlCollpsed.classList.remove("show");
                } catch (ex) {
                }

            }
        }
    }

    function onChangeSelectWagonId(wagonId, key) {
        let updSeatSelected = [...seatSelected];
        updSeatSelected[key].wagonId = wagonId;


        updSeatSelected[key].selectPaxHaveSeat = '';
        updSeatSelected[key].selectSeatNumber = 0;
        for (let i = 0; i < updSeatSelected[key].seatIds.length; i++) {
            updSeatSelected[key].seatIds[i].seatId = null;
            updSeatSelected[key].seatIds[i].valToShow = null;
        }

        setTotalPrice({
            ...totalPrice,
            amount: 0
        });

        setSeatSelected(updSeatSelected);
    }

    function onSelectPaxesSeat(travelerIndex, key, id) {
        let updSeatSelected = [...seatSelected];
        updSeatSelected[key].selectPaxIndexToAddSeat = travelerIndex;
        setSeatSelected(updSeatSelected);

        try {
            let pnlAccordionId = id + "_Seat_" + key;
            let pnlCollpsed = document.getElementById(pnlAccordionId);

            if (!pnlCollpsed.classList.contains("show")) {
                onOpenAccordionId(id, key);
                pnlCollpsed.classList.add("show");
            }

        } catch (ex) {

        }
    }

    function showMessage() {
        alert('I posti sono già stati assegnati. Se vuoi cambiare la tua scelta, deseleziona un posto per sceglierne un altro.');
    }

    function confirmSeat(key, segmentIndex) {
        let updSeatSelected = [...seatSelected];
        let confirmSeatValue = document.getElementById("callConfirmSeat").value;
        if (!confirmSeatValue) {
            showMessage();
            return;
        }

        let confirmSeatValueParts = confirmSeatValue.split('_');
        let seatId = confirmSeatValueParts[0];
        let operation = confirmSeatValueParts[1];
        let idWagon = updSeatSelected[key].wagonId;
        let travelerIndex = updSeatSelected[key].selectPaxIndexToAddSeat;
        let trvIndexId = ";" + travelerIndex + ";";

        if (operation === 'ADD') {
            if (updSeatSelected[key].wagonId !== idWagon) {
                updSeatSelected[key].selectPaxHaveSeat = '';
                updSeatSelected[key].selectSeatNumber = 0;

                for (let i = 0; i < updSeatSelected[key].seatIds.length; i++) {
                    updSeatSelected[key].seatIds[i].seatId = null;
                    updSeatSelected[key].seatIds[i].valToShow = null;
                    updSeatSelected[key].seatIds[i].wagonId = idWagon;
                }
            }

            let valToShow = confirmSeatValueParts[2];
            updSeatSelected[key].wagonId = idWagon;

            for (let i = 0; i < updSeatSelected[key].seatIds.length; i++) {
                if (updSeatSelected[key].seatIds[i].travelerIndex === travelerIndex) {
                    updSeatSelected[key].seatIds[i].seatId = seatId;
                    updSeatSelected[key].seatIds[i].valToShow = valToShow;
                    updSeatSelected[key].seatIds[i].wagonId = idWagon;

                    if (!updSeatSelected[key].selectPaxHaveSeat.includes(trvIndexId)) {
                        updSeatSelected[key].selectPaxHaveSeat += trvIndexId;
                        updSeatSelected[key].selectSeatNumber += 1;
                    }
                }
            }
        } else {
            updSeatSelected[key].selectSeatNumber -= 1;

            for (let i = 0; i < updSeatSelected[key].seatIds.length; i++) {
                if (updSeatSelected[key].seatIds[i].travelerIndex === travelerIndex) {
                    updSeatSelected[key].seatIds[i].seatId = null;
                    updSeatSelected[key].seatIds[i].valToShow = null;

                    updSeatSelected[key].selectPaxHaveSeat = updSeatSelected[key].selectPaxHaveSeat.replaceAll(trvIndexId);
                    updSeatSelected[key].selectSeatNumber -= 1;
                }
            }
        }

        document.getElementById("callConfirmSeat").value = '';

        let updTotalPriceAmount = 0;
        for (let i = 0; i < updSeatSelected.length; i++) {
            let ss = updSeatSelected[i].seatIds;

            for (let j = 0; j < ss.length; j++) {
                let sIds = ss[j];
                if (sIds.seatId) {
                    updTotalPriceAmount += sIds.price.amount;
                }
            }
        }

        setTotalPrice({
            ...totalPrice,
            amount: updTotalPriceAmount
        });

        setSeatSelected(updSeatSelected);

        let seatSelection = [];

        for (let i = 0; i < updSeatSelected.length; i++) {
            let ss = updSeatSelected[i];
            let seatIds = ss.seatIds;

            for (let j = 0; j < seatIds.length; j++) {
                let sIds = seatIds[j];

                if (sIds.seatId) {
                    let paxSelection = {
                        paxIndex: sIds.travelerIndex,
                        legIndex: id === 'outbound' ? 1 : 2,
                        segmentIndex: ss.segmentIndex,
                        seatDetail: {
                            seatCode: sIds.valToShow,
                            seatNo: sIds.seatId,
                            wagon: ss.wagonId
                        }
                    };

                    seatSelection.push(paxSelection);
                }
            }
        }

        onSelectSeat(seatSelection);
    }

    return (
        <>
            {
                seatMap && <div className="card border-light p-1 shadow">
                    <div className="row no-gutters">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-none d-sm-block">
                            {
                                !haveErrorSeat && seatMapping && seatMapping.length > 0 && <div className="w-100 mt-1">
                                    <div className="w-100 mt-1">
                                        <div className=" text-center float-start w-90"><label>{t("Product:Train:SeatMapSelectMsg")}</label></div>
                                        <div className="h6 float-end">
                                            {formatPrice(totalPrice.amount, totalPrice.currency, cultureName)}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-block d-sm-none">
                            {
                                !haveErrorSeat && seatMapping && seatMapping.length > 0 && <div className="w-100 mt-1">
                                    <div className="w-100 mt-1 d-flex align-items-center">
                                        <div className=" text-center float-start w-90"><label>{t("Product:Train:SeatMapSelectMsg")}</label></div>
                                        <div className="">
                                            {formatPrice(totalPrice.amount, totalPrice.currency, cultureName)}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <input type="hidden" ID="maxSel" value={maxSelectSeat} />
                            <input type="hidden" id="callConfirmSeat" value="" />
                            {
                                !haveErrorSeat && seatMapping && seatMapping.length > 0 && seatMapping.map((sm, key) => {
                                    return <React.Fragment key={key}>
                                        <div className="row">
                                            <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 d-none d-sm-block">
                                                <div className="text-center">
                                                    <h1 className="">
                                                        <M3Icon iconName="FlightSeat" externalClass="mr-2 h5r" />
                                                    </h1>
                                                </div>
                                                <div className="h5 text-center">{t("Product:Train:Seat")}</div>
                                            </div>
                                            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 d-none d-sm-block">
                                                <p className="text-left mt-3">
                                                    <a id={id + "_SeatFather_" + key}
                                                        className="text-decoration-none h075 pl-2 collapsed train-seat-collapse"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={"#" + id + "_Seat_" + key}
                                                        role="button"
                                                        aria-expanded="false"
                                                        aria-controls={id + "_Seat_" + key}
                                                        onClick={_ => onOpenAccordionId(id, key)}>
                                                        <label className="h6 mr-2">
                                                            <span className="material-icons icon-10 pr-2">add_box</span>
                                                            {sm.segment.departure.time} {sm.segment.departure.stationName} - {sm.segment.arrival.stationName}
                                                        </label>
                                                        {t('Product:Train:Item')}: <label>N.{sm.segment.vehicle && sm.segment.vehicle.number}</label>
                                                    </a>
                                                    <div className="row d-none d-sm-flex">
                                                        {
                                                            seatSelected && seatSelected[key].seatIds.map((pax, keyPax) => {
                                                                return <div key={keyPax} className="col-2 cursor-pointer">
                                                                    <div
                                                                        className={"w-100 px-1 border rounded " + (pax.travelerIndex === seatSelected[key].selectPaxIndexToAddSeat ? " bg-gray-100" : "bg-white")}
                                                                        onClick={_ => onSelectPaxesSeat(pax.travelerIndex, key, id)}>
                                                                        <label>
                                                                            {t('Product:Train:Traveler')} {pax.travelerIndex}
                                                                        </label>
                                                                        <div className="h085">
                                                                            {pax.seatId && <span className="mr-2"><M3Icon iconName="TrainWagon" externalClass="h075" /> {pax.wagonId}</span>}
                                                                            {pax.seatId && <span className="mr-2"><M3Icon iconName="TrainSeat" externalClass="h075" /> {pax.valToShow}</span>}
                                                                            <span>{pax.seatId && formatPrice(pax.price.amount, 'EUR', cultureName)}</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                    <div className=" d-flex gap-4 d-sm-none">
                                                        {
                                                            seatSelected && seatSelected[key].seatIds.map((pax, keyPax) => {
                                                                return <div key={keyPax} className="cursor-pointer">
                                                                    <div
                                                                        className={"w-100 px-1 border rounded " + (pax.travelerIndex === seatSelected[key].selectPaxIndexToAddSeat ? " bg-gray-100" : "bg-white")}
                                                                        onClick={_ => onSelectPaxesSeat(pax.travelerIndex, key, id)}>
                                                                        <label>
                                                                            {t('Product:Train:Traveler')} {pax.travelerIndex}
                                                                        </label>
                                                                        <div className="h085">
                                                                            {pax.seatId && <span className="mr-2"><M3Icon iconName="TrainWagon" externalClass="h075" /> {pax.wagonId}</span>}
                                                                            {pax.seatId && <span className="mr-2"><M3Icon iconName="TrainSeat" externalClass="h075" /> {pax.valToShow}</span>}
                                                                            <span>{pax.seatId && formatPrice(pax.price.amount, 'EUR', cultureName)}</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </p>
                                                <div id={id + "_Seat_" + key} className="collapse">
                                                    <div className="w-50 mt-2">
                                                        <select className="form-select border-0 border-bottom border-dark"
                                                            onChange={e => onChangeSelectWagonId(e.target.value, key)}
                                                            defaultChecked={seatSelected[key].wagonId}>
                                                            {
                                                                sm.wagonIds.map((wagon, keyWagon) => {
                                                                    return <option key={keyWagon} value={wagon}>
                                                                        {t('Product:Train:Wagon')} {wagon}
                                                                    </option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <input id={id + "_" + key + "_SelNum"} type="hidden" value={seatSelected[key].selectSeatNumber} />
                                                    <input id={id + "_" + key + "_PaxSelected"} type="hidden" value={seatSelected[key].selectPaxIndexToAddSeat} />
                                                    <input id={id + "_" + key + "_PaxHaveSeat"} type="hidden" value={seatSelected[key].selectPaxHaveSeat} />
                                                    <div id={id + "_" + key} onClick={_ => confirmSeat(key, sm.segmentIndex)}>
                                                        {
                                                            sm.wagons.filter(x => x.wagonId === seatSelected[key].wagonId).length > 0 && <div dangerouslySetInnerHTML={{ __html: sm.wagons.filter(x => x.wagonId === seatSelected[key].wagonId)[0].layout }}></div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-block d-sm-none">
                                                <p className="text-left mt-3">
                                                    <a id={id + "_SeatFather_" + key}
                                                        className="text-decoration-none h075 pl-2 collapsed train-seat-collapse"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={"#" + id + "_Seat_" + key}
                                                        role="button"
                                                        aria-expanded="false"
                                                        aria-controls={id + "_Seat_" + key}
                                                        onClick={_ => onOpenAccordionId(id, key)}>
                                                        <label className="h6 mr-2">
                                                            <span className="material-icons icon-10 pr-2">add_box</span>
                                                            {sm.segment.departure.time} {sm.segment.departure.stationName} - {sm.segment.arrival.stationName}
                                                        </label>
                                                        {t('Product:Train:Item')}: <label>N.{sm.segment.vehicle && sm.segment.vehicle.number}</label>
                                                    </a>
                                                    <div className="row d-none d-sm-flex">
                                                        {
                                                            seatSelected && seatSelected[key].seatIds.map((pax, keyPax) => {
                                                                return <div key={keyPax} className="col-2 cursor-pointer">
                                                                    <div
                                                                        className={"w-100 px-1 border rounded " + (pax.travelerIndex === seatSelected[key].selectPaxIndexToAddSeat ? " bg-gray-100" : "bg-white")}
                                                                        onClick={_ => onSelectPaxesSeat(pax.travelerIndex, key, id)}>
                                                                        <label>
                                                                            {t('Product:Train:Traveler')} {pax.travelerIndex}
                                                                        </label>
                                                                        <div className="h085">
                                                                            {pax.seatId && <span className="mr-2"><M3Icon iconName="TrainWagon" externalClass="h075" /> {pax.wagonId}</span>}
                                                                            {pax.seatId && <span className="mr-2"><M3Icon iconName="TrainSeat" externalClass="h075" /> {pax.valToShow}</span>}
                                                                            <span>{pax.seatId && formatPrice(pax.price.amount, 'EUR', cultureName)}</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                    <div className=" d-flex gap-4 d-sm-none">
                                                        {
                                                            seatSelected && seatSelected[key].seatIds.map((pax, keyPax) => {
                                                                return <div key={keyPax} className="cursor-pointer">
                                                                    <div
                                                                        className={"w-100 px-1 border rounded " + (pax.travelerIndex === seatSelected[key].selectPaxIndexToAddSeat ? " bg-gray-100" : "bg-white")}
                                                                        onClick={_ => onSelectPaxesSeat(pax.travelerIndex, key, id)}>
                                                                        <label>
                                                                            {t('Product:Train:Traveler')} {pax.travelerIndex}
                                                                        </label>
                                                                        <div className="h085">
                                                                            {pax.seatId && <span className="mr-2"><M3Icon iconName="TrainWagon" externalClass="h075" /> {pax.wagonId}</span>}
                                                                            {pax.seatId && <span className="mr-2"><M3Icon iconName="TrainSeat" externalClass="h075" /> {pax.valToShow}</span>}
                                                                            <span>{pax.seatId && formatPrice(pax.price.amount, 'EUR', cultureName)}</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </p>
                                                <div id={id + "_Seat_" + key} className="collapse">
                                                    <div className="w-50 mt-2">
                                                        <select className="form-select border-0 border-bottom border-dark"
                                                            onChange={e => onChangeSelectWagonId(e.target.value, key)}
                                                            defaultChecked={seatSelected[key].wagonId}>
                                                            {
                                                                sm.wagonIds.map((wagon, keyWagon) => {
                                                                    return <option key={keyWagon} value={wagon}>
                                                                        {t('Product:Train:Wagon')} {wagon}
                                                                    </option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <input id={id + "_" + key + "_SelNum"} type="hidden" value={seatSelected[key].selectSeatNumber} />
                                                    <input id={id + "_" + key + "_PaxSelected"} type="hidden" value={seatSelected[key].selectPaxIndexToAddSeat} />
                                                    <input id={id + "_" + key + "_PaxHaveSeat"} type="hidden" value={seatSelected[key].selectPaxHaveSeat} />
                                                    <div id={id + "_" + key} onClick={_ => confirmSeat(key)}>
                                                        {
                                                            sm.wagons.filter(x => x.wagonId === seatSelected[key].wagonId).length > 0 && <div className="seat-mobile" dangerouslySetInnerHTML={{ __html: sm.wagons.filter(x => x.wagonId === seatSelected[key].wagonId)[0].layout }}></div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                })
                            }
                        </div>
                    </div>
                </div>
            }
            {
                haveErrorSeat && <div className="card border-light p-1 shadow">
                    <div className="row no-gutters">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="w-100 mt-1">
                                <div className="w-100 mt-1">
                                    <div className=" text-center float-start w-90"><label>{t("Product:Train:SelectSeatError")}</label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            }
        </>
    );
}