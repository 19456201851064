import React, { useEffect, useState } from 'react';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { GalleryList } from "../../Common/Image/GalleryList";
import { M3Icon } from "../../../Common/M3Icon";
import { ProductActionMenu } from "../../Common/ProductActionMenu";
import { ProductPrice } from "../../Common/Pricing/ProductPrice";
import { M3StructureStars } from '../../../Common/M3StructureStars';
import { HotelMealPlan } from "./HotelMealPlan";
import { getBasicDetailUrl, getIconFavoutire } from '../../../../js/Utils.js';


export const HotelItemList = ({ item, showPriceBar, showCost, extraParam, modalOpenProductId, onClickSearch, onFavourite, removeTrippie, updateTrippie }) => {
    const { t } = useTranslation();
    const [detailLink, setDetailLink] = useState('');
    const [isFavourite, setIsFavourite] = useState(false);

    useEffect(() => {
        if (!extraParam)
            extraParam = {};

        extraParam.productId = item.structureId;
        setDetailLink(getBasicDetailUrl(configData.Settings.Products.Structure.IdTipoProdotto, extraParam));

        setIsFavourite(item.favourite);
    }, [item]);

    function onModalOpenProductId(productId) {
        modalOpenProductId(productId);
        onFavourite(item.favourite);
    }

    function onClickFavourite() {
    }

    function _onClickSearch(id, filters) {
        let filtersParams = {};

        if (filters) {
            filters["favourite"] = item.favourite;
            filtersParams = filters;
        }
        else {
            filtersParams["favourite"] = item.favourite;
        }

        onClickSearch(id, filtersParams);
    }

    return (
        <>
            <div className="card shadow shadow-hover mb-4 animate-up-3 ">
                <div className="row no-gutters">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <GalleryList
                            productId={item.structureId}
                            defaultImage={item.thumbUrl}
                            gallery={item.images}
                            alternativeText={item.name}
                            onClickSearch={_onClickSearch}
                        />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <div className="card-body pt-2 px-0">
                            <div>
                                <div className="w-100">
                                    <div className="row">
                                        <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                                            <var>
                                                <M3StructureStars category={item.category} />
                                            </var>
                                            {item.isSuggestion && <data m3ico="Star text-facebook">
                                                <var className="material-icons text-facebook">
                                                    stars
                                                </var>
                                            </data>}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <div className="btn-group text-right float-end pr-2">
                                                <button className="btn p-0 m-0" type="button" id="menuItem1" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                    <data m3ico="List">
                                                        <M3Icon iconName="MenuFunctions" />
                                                    </data>
                                                </button>
                                                <ProductActionMenu
                                                    productId={item.structureId}
                                                    productType={configData.Settings.Products.Structure.IdTipoProdotto}
                                                    handleModalOpenProductId={onModalOpenProductId}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h5">
                                        <var className="cursor-pointer" onClick={(e) => _onClickSearch(item.structureId)}>{item.name}</var>
                                        {getIconFavoutire(isFavourite, t, onClickFavourite, false)}
                                    </div>
                                    <div className="w-100">
                                        <label>
                                            <data m3ico="Place icon-10">
                                                <M3Icon iconName="Place" externalClass="icon-10" />
                                            </data>
                                            <span className="ml-1 lh-sm">
                                                <var>{item.address}</var>
                                            </span>
                                            <span className="ml-1 lh-sm">
                                                {item.distance &&
                                                    <var>({(item.distance / 1000).toString().replace(".", ",")} Km)</var>
                                                }
                                            </span>
                                        </label>
                                    </div>
                                    <div className="w-100">
                                        {
                                            item.bestRoomNames && item.bestRoomNames.map((bestRoomName, indexbestRoomName) => {
                                                return <div key={indexbestRoomName}>
                                                    <label>
                                                        <data m3ico="Night">
                                                            <M3Icon iconName="Night" externalClass="icon-15" />
                                                        </data>
                                                        <span className="ml-1 lh-sm">
                                                            <var>{bestRoomName}</var>
                                                        </span>
                                                    </label>
                                                </div>
                                            })
                                        }
                                    </div>

                                    {
                                        (!extraParam || !extraParam.isTrippie) && <div className="position-innerit h-100 ">
                                            <div className="w-100  mt-3">
                                                <label>
                                                    <data m3lab="Product.Structure.Filter.NumenrSolutions">{t(`Product:Structure:Filter:NumenrSolutions`)} </data>
                                                    <var>{item.totalRooms}</var>
                                                </label>
                                            </div>
                                            <div className="position-absolute d-none d-sm-block bottom-0 w-100 pb-1">
                                                <HotelMealPlan
                                                    structureId={item.structureId}
                                                    bestPricingMealplans={item.bestPricingMealplans}
                                                    onClickSearch={_onClickSearch}
                                                />
                                            </div>
                                            <div className="d-block d-sm-none bottom-0 w-100 pb-1">
                                                <HotelMealPlan
                                                    structureId={item.structureId}
                                                    bestPricingMealplans={item.bestPricingMealplans}
                                                    onClickSearch={_onClickSearch}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-1 px-3">
                        <ProductPrice
                            productId={item.structureId}
                            startDate={item.startDate}
                            endDate={item.endDate}
                            pricing={item.pricing}
                            supplier={item.supplier}
                            isPackage={item.isPackage}
                            isPromotion={item.isPromotion}
                            showPriceBar={showPriceBar}
                            showCost={showCost}
                            detailLink={detailLink}
                            typePrice="NTPax"
                            otherParam={{ mealPlanId: item.bestMealPlanId, buttonEnabled: true }}
                            isTrippie={extraParam && extraParam.isTrippie}
                            showPriceTrippie={extraParam && extraParam.showPriceTrippie}
                            onClickSearch={_onClickSearch}
                            removeTrippie={removeTrippie}
                            updateTrippie={updateTrippie}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}