import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../M3Icon";

export const InputListDestination = ({ options, lastSearchItems, isLoading, isError, showRecent, extraOptions, callOnSelectDestination }) => {
    const { t } = useTranslation();

    const [lastSearchItemsMapping, setLastSearchItemsMapping] = useState([]);
    const [showRecentMode, setShowRecentMode] = useState(false);

    useEffect(() => {
        setShowRecentMode(showRecent);
    }, [showRecent])

    useEffect(() => {
        if (lastSearchItems) {
            let mapping = lastSearchItems.map(x => {
                let title = x.text;
                let subtitle = '';

                try {
                    let textParts = x.text.split(',');
                    title = textParts[0];
                    
                    for (let i = 1; i < textParts.length; i++) {
                        subtitle += textParts[i] + ', ';
                    }

                    subtitle = subtitle.slice(0, -2);

                } catch (es) { }

                return {
                    Id: x.webId,
                    Text: x.text,
                    GeoId: x.geoId,
                    Latitude: x.Latitude,
                    Longitude: x.Longitude,
                    Title: title,
                    Subtitle: subtitle,
                    Type: x.type,
                    Provider: '',
                    IsGroupTitle: false
                };
            });

            setLastSearchItemsMapping(mapping);
        } else {
            setLastSearchItemsMapping([]);
        }

    }, [lastSearchItems])

    const [selectDestination, setSelectDestination] = useState({});

    const getLocationIcon = (type) => {
        let icon = "";
        switch (type) {
            case "Airport":
                icon = "Flight";
                break;
            case "Destination":
                icon = "Place";
                break;
            case "Port":
                icon = "Port";
                break;
            case "POI":
                icon = "GPSPoint";
                break;
            case "Zone":
                icon = "CityLocation";
                break;
            case "CityLocation":
                icon = "CityLocation";
                break;
            case "City":
                icon = "Place";
                break;
            case "Structure":
                icon = "Structure";
                break;
            case "TrainStation":
                icon = "TrainStation";
                break;
            case "Loading":
                icon = "IcoLoading";
                break;
            case "Recent":
                icon = "Recent";
                break;
            case "NoResults":
            default:
                icon = "";
                break;
        }

        return icon;
    }

    function onSelectDestination(item) {
        if (item.IsGroupTitle && (extraOptions && !extraOptions.selectGroupTitle))
            return;

        setSelectDestination(item);

        let cleanSelectDestination = { ...selectDestination };
        delete cleanSelectDestination.IsGroupTitle;

        callOnSelectDestination(item);
    }

    return (
        <>
            <ul className="list-group overflow-300">
                {
                    !isLoading && showRecentMode && lastSearchItemsMapping && lastSearchItemsMapping.map((value, key) => {
                        return <li key={key}
                            id={value.Id}
                            className="list-group-item border-0 p-0 cursor-pointer"
                            onClick={_ => onSelectDestination(value)}>

                            <div className="d-table-cell h-100 align-middle">
                                <M3Icon iconName="Recent" externalClass="" />
                            </div>
                            <div className="d-table-cell p-2">
                                <p className="p-0 m-0 h075">{value.Title}</p>
                                <label className="h065">
                                    {value.Provider === 'IATA' && <>{value.Id} - </>}{value.Subtitle}
                                </label>
                            </div>
                        </li>;
                    })
                }
                {
                    isLoading && <li className="list-group-item border-0 p-0 ">
                        <div className="d-table-cell h-100 align-middle"></div>
                        <div className="d-table-cell p-2">
                            <p className="p-0 m-0 h075">
                                <div className="spinner-border spinner-border-sm text-primary" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </p>
                            <label className="h065"></label>
                        </div>
                    </li>
                }
                {
                    !isLoading && !showRecent && (isError || options.length === 0) && <li className="list-group-item border-0 p-0 ">
                        <div className="d-table-cell h-100 align-middle"></div>
                        <div className="d-table-cell p-2">
                            <p className="p-0 m-0 h075">
                                <data m3lab="SearchEngine:DestinationNotFound">
                                    {t('SearchEngine:DestinationNotFound')}
                                </data>
                            </p>
                            <label className="h065"></label>
                        </div>
                    </li>
                }
                {
                    !isLoading && !isError && options.length > 0 && options.map((value, key) => (
                        <li key={key}
                            id={value.Id}
                            className={"list-group-item border-0 p-0 " + (value.IsChild ? " pl-4" : "") + ((!value.IsChild && (value.IsGroupTitle && (extraOptions && !extraOptions.selectGroupTitle))) ? " " : " cursor-pointer")}
                            onClick={_ => onSelectDestination(value)}>

                            <div className="d-table-cell h-100 align-middle">
                                <M3Icon iconName={getLocationIcon(value.Type)} externalClass="" />
                            </div>
                            <div className="d-table-cell p-2">
                                <p className="p-0 m-0 h075">{value.Title}</p>
                                <label className="h065">
                                    {value.Provider === 'IATA' && <>{value.Id} - </>}{value.Subtitle}
                                </label>
                            </div>
                        </li>
                    ))
                }
            </ul>
        </>
    );
}
