import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../../js/Utils';

export const CruiseDetailCabinCategoryPriceTable = ({ services, title, isIncludedService, readonly }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const [obsDescriptions, setObsDescriptions] = useState("");

    const { t } = useTranslation();

    useEffect(() => {

        if (services && services.length > 0 && !isIncludedService) {
            try {
                let desc = services[0].obsDescription;
                setObsDescriptions(desc[0]);
            } catch (ex) { }

        }

    }, [services]);

    function renderName(service) {
        let value = '';

        if (service.name === 'ADT')
            value = t('SearchEngine:Adult');
        else if (service.name === 'CHD')
            value = t('SearchEngine:Child');
        else if (service.name === 'INF')
            value = t('SearchEngine:Infant');
        else
            return service.name;

        return value + " " + service.paxIndex;
    }

    return (
        <>

            {
                title && <div className="col-12 col-lg-12 pt-2 px-0">
                    <var className="h6">{title}</var>
                </div>
            }
            {
                !isIncludedService && <div className="row">
                    <div className="col-3 col-lg-3 pt-2">
                        <var>{t('General:Pax')}</var>
                    </div>
                    <div className="col-3 col-lg-3 pt-2 text-center">
                        <var>{t('Product:Flight:Taxes')}/{t('General:Code')}</var>
                    </div>
                    <div className="col-3 col-lg-3 pt-2 text-center">
                        {
                            !isIncludedService ? <var>
                                QDS
                                {
                                    obsDescriptions && <span class="material-icons-outlined ml-2 icon-10 position-relative" title={obsDescriptions} style={{ top: '2px' }}>info</span>
                                }
                            </var> : <></>
                        }
                    </div>
                    <div className="col-3 col-lg-3 pt-2 text-right">
                         <var>{t('Product:Cruise:Item')}</var>
                    </div>
                </div>
            }
            {
                services && services.map((service, index) => {
                    return <div className="row" key={index}>
                        <div className="col-3 col-lg-3 pt-2">
                            <var>
                                {renderName(service)}
                            </var>
                        </div>
                        <div className="col-3 col-lg-3 pt-2 text-center">
                            <var>
                                {
                                    isIncludedService ? service.itemCode
                                        : formatPrice(service.taxPrice, service.taxCurrency, cultureName)
                                }
                            </var>
                        </div>
                        <div className="col-3 col-lg-3 pt-2 text-center">
                            <var>
                                {
                                    isIncludedService ? <></>
                                        : formatPrice(service.obsPrice, service.obsCurrency, cultureName)
                                }
                            </var>
                        </div>
                        {
                            !readonly && <div className="col-3 col-lg-3 pt-2 text-right">
                                <var>
                                    {
                                        isIncludedService ? formatPrice(service.amount, service.currency, cultureName)
                                            : formatPrice(service.cabPrice, service.cabCurrency, cultureName)
                                    }
                                </var>
                            </div>
                        }
                    </div>
                })
            }
        </>
    );
}