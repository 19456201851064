import React, { useState, useEffect } from 'react';
import { M3Icon } from "../../../Common/M3Icon";

export const StructureSummary = ({ structure, isAlternative }) => {
    const [fontSize, setFontSize] = useState('');

    useEffect(() => {
        if (isAlternative)
            setFontSize('h075');
    }, [structure]);

    return (
        <>
            <div>
                <data m3ico="Structure mr-2 icon-15">
                    <M3Icon iconName="Structure" externalClass="mr-2 icon-15 text-custom" hasLabel={false} />
                </data>
                <span className={fontSize}>
                    {structure.name}
                </span>
            </div>
        </>
    );
}
