import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import configData from "../../../appsettings.json";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { handleError } from "../../../js/Utils";
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';

export const JointlyPayResult = () => {
    const cookies = new Cookies();

    const { t } = useTranslation();

    const [searchParams] = useSearchParams();
    let externalCode = searchParams.get("token");
    let voucherCode = searchParams.get("jointly_voucher_code");
    let externalStatus = searchParams.get("status");

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(true);

    useEffect(() => {
        callJointlyPayResult();
    }, []);

    function callJointlyPayResult() {
        let inputData = {
            externalCode: externalCode,
            voucherCode: voucherCode,
            externalStatus: externalStatus
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}paymentgateway/JointlyPayResult`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {

                if (item.success) {
                    let orderNumber = cookies.get("orderNumber");

                    let baseUrlConfirmCart = `${configData.Settings.Base_Url}cart-confirm/${orderNumber}`;
                    let currentUserObj = JSON.parse(localStorage.getItem("CurrentUser"));
                    if (currentUserObj.autoLoginToken)
                        baseUrlConfirmCart = `${configData.Settings.Base_Url}?ual=${currentUserObj.autoLoginToken}&ru=${encodeURIComponent(baseUrlConfirmCart)}`;

                    window.location.href = baseUrlConfirmCart

                } else {
                    window.location.href = `${configData.Settings.Base_Url}cart`;
                }

                setIsLoading(false);
                setIsError(false);
            })
            .catch((err) => {
                window.location.href = `${configData.Settings.Base_Url}cart`;

                setIsLoading(false);
                setIsError(true);
                console.log(err);
            });

    }

    return (
        <div className="row text-center">
            {
                isLoading && !isError && <Loading textMsg={t('Loading:PaymentSuccess')} />
            }
            {
                !isLoading && isError && <Error textMsg={t('Loading:PaymentError')} />
            }
            {
                !isLoading && !isError && <Loading textMsg={t('Loading:PaymentSuccess')} />
            }
        </div>
    );
}
