import React from 'react';
import { useTranslation } from 'react-i18next';

export const TourDetailTouchPoints = ({ tourId, tourData }) => {
    const { t } = useTranslation();

    return (
        <>
            {tourData && tourData.touchPoints && tourData.touchPoints.length > 0 &&
            <>
                <h5 className=" border-bottom p-0 mb-0 mt-3">
                    <data m3lab="Product.Tour.Locality">{t("Product:Tour:Locality")}</data>
                </h5>
                
                {tourData.touchPoints.map(touchPoint =>
                    <div className="card my-4 animate-up-5 shadow">
                        <div className="row no-gutters">
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                <div className="myBook-card-body pb-2">
                                    <div>
                                        <div className="h5">
                                            <var> {touchPoint.webDestinationName}</var>
                                        </div>
                                        <div className="w-100">
                                            <div className="text-justify">
                                                {touchPoint.descriptions.length > 0 && touchPoint.descriptions.map(desc =>
                                                    <var>{desc}</var>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                {/*immagine*/}
                            </div>
                        </div>
                    </div>
                )}
            </>
            }
        </>
    );
}