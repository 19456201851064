import React, { useEffect, useState } from 'react';
import { formatDateTime, formatPrice, groupBy } from '../../../js/Utils.js';
import { StructureDetail } from '../../Reservation/StructureDetail';
import { FlightDetail } from '../../Reservation/FlightDetail';
import { ActivityDetail } from '../../Reservation/ActivityDetail';
import { TransferDetail } from '../../Reservation/TransferDetail';
import { CarRentalDetail } from '../../Reservation/CarRentalDetail';
import { CruiseDetail } from '../../Reservation/CruiseDetail';
import { TrainDetail } from '../../Reservation/TrainDetail';
import { TourDetail } from '../../Reservation/TourDetail';
import { QuotationItemAlternative } from './QuotationItemAlternative';
import { AvailableStructure } from '../../Cart/Available/AvailableStructure';
import { AvailableFlight } from '../../Cart/Available/AvailableFlight';
import { AvailableActivity } from '../../Cart/Available/AvailableActivity';
import { AvailableTour } from '../../Cart/Available/AvailableTour';
import { AvailableTransfer } from '../../Cart/Available/AvailableTransfer';
import { AvailableCarRental } from '../../Cart/Available/AvailableCarRental';
import { AvailableCruise } from '../../Cart/Available/AvailableCruise';
import { AvailableTrain } from '../../Cart/Available/AvailableTrain';
import { M3IconProductType } from '../../Common/M3IconProductType.js';
import { useTranslation } from 'react-i18next';
import { M3Icon } from '../../Common/M3Icon.js';
import { InputSearchDestinationContainer } from '../../Common/Destination/InputSearchDestinationContainer.js';
import configData from "../../../appsettings.json";


export const QuotationSteps = (props) => {
    const { t } = useTranslation();

    const [quotationItems, setQuotationItems] = useState([]);
    const [updateItemStep, setUpdateItemStep] = useState([]);
    const [updateStepInfo, setUpdateStepInfo] = useState([]);
    const [updateAlternative, setUpdateAlternative] = useState([]);
    const [showHistoryQuotationItem, setShowHistoryQuotationItem] = useState([]);
    const [customFunctionSingleProduct, setCustomFunctionSingleProduct] = useState({});

    // step destination function
    const [selectStepOpenDestination, setSelectStepOpenDestination] = useState(null);
    const [selectStepDestination, setSelectStepDestination] = useState({});
    const [stepDestinationOpen, setStepDestinationOpen] = useState(false);

    useEffect(() => {
        let mapItems = [];
        props.items.forEach((item) => {
            let mapItem = {
                step: item.step,
                stepName: item.stepName,
                stepDestinationName: item.stepDestinationName,
                stepWebDestinationId: item.stepWebDestinationId,
                dateFrom: item.dateFrom,
                dateTo: item.dateTo,
                quotationItemInfo: []
            };

            let mapQuotationItemInfo = [];
            let qItemIdsAlternative = [];

            // prima aggiungo i prodotti selezionati
            for (let i = 0; i < item.quotationItemInfo.length; i++) {
                let qInfo = item.quotationItemInfo[i];

                if (qInfo.isSelected) {
                    let myItem = {
                        id: qInfo.id,
                        productType: qInfo.productType,
                        hadAlternative: qInfo.isAlternative,
                        isDisabled: qInfo.isDisabled,
                        wsBookingId: qInfo.wsBookingId,
                        alternatives: [],
                        forceRender: qInfo.forceRender,
                        isAvailable: qInfo.isAvailable
                    };

                    mapQuotationItemInfo.push(myItem);
                } else {
                    qItemIdsAlternative.push(
                        {
                            id: qInfo.id,
                            productType: qInfo.productType,
                            name: qInfo.name,
                            priceAmount: qInfo.priceAmount,
                            priceCurrency: qInfo.priceCurrency,
                            isDisabled: qInfo.isDisabled,
                            deletedDate: qInfo.deletedDate,
                            status: qInfo.status
                        });
                }
            }

            // aggiungo i prodotto in alternativa
            for (let i = 0; i < qItemIdsAlternative.length; i++) {
                let alternative = qItemIdsAlternative[i];

                let myArr = mapQuotationItemInfo.filter(x => x.productType === alternative.productType && x.hadAlternative);
                if (myArr && myArr.length > 0 && (myArr[0].wsBookingId === undefined || myArr[0].wsBookingId === '' || myArr[0].wsBookingId === null)) {
                    myArr[0].alternatives.push(alternative);
                }
            }

            mapItem.quotationItemInfo = mapQuotationItemInfo;

            mapItems.push(mapItem);
        });

        setQuotationItems(mapItems);
        if (props.quotationMode === 'QuotationTOWorking') {
            let modalStep = document.getElementById("ModalStep");
            modalStep.addEventListener('show.bs.modal', function (e) {
                let items = [];

                props.items.forEach((item) => {
                    item.quotationItemInfo.forEach((subitem) => {
                        items.push({
                            quotationItemId: subitem.id,
                            step: item.step,
                            order: subitem.order,
                            productType: subitem.productType,
                            name: subitem.name,
                            dateFrom: subitem.dateFrom,
                            dateTo: subitem.dateTo
                        });
                    });

                });

                setUpdateItemStep(items);
            });

            let modalNameStep = document.getElementById("ModalNameStep");
            modalNameStep.addEventListener('show.bs.modal', function (e) {
                let items = [];

                props.items.forEach((item) => {
                    items.push({
                        step: item.step,
                        name: item.stepName,
                        webDestinationName: item.stepDestinationName,
                        webDestinationId: item.stepWebDestinationId,
                        dateFrom: item.dateFrom,
                        dateTo: item.dateTo
                    });
                });

                setUpdateStepInfo(items);
            });

            let modalAlternative = document.getElementById("ModalAlternative");
            modalAlternative.addEventListener('show.bs.modal', function (e) {
                let items = [];
                props.items.forEach((item) => {
                    let stepAlternative = { step: item.step, products: [] };

                    let grouped = groupBy(item.quotationItemInfo, 'productType');
                    for (const property in grouped) {
                        let productTypeItems = grouped[property];

                        if (productTypeItems.length > 1)
                            stepAlternative.products.push({ productType: property, qInfos: productTypeItems.map(pti => ({ ...pti })) });
                    }

                    if (stepAlternative.products.length > 0)
                        items.push(stepAlternative);
                });

                setUpdateAlternative(items);
            });
        }

        setCustomFunctionSingleProduct({
            callOnChangeStatusQuotationItem: props.callOnChangeStatusQuotationItem,
            onCallRequoteQuotationItem: props.onCallRequoteQuotationItem,
            onCallUpdateQuotationItem: props.onCallUpdateQuotationItem,
            onCallSaveProductInfo: onCallSaveProductInfoStep,
            quotationMode: props.quotationMode
        });
    }, [props.items]);

    function onCallSaveProductInfoStep(item, type, id) {
        props.onCallSaveProductInfo(item, type, id);
    }

    function onChangeStep(event, index, propertyName) {
        let updateItems = [...updateItemStep];

        updateItems[index][propertyName] = event.target.value;

        setUpdateItemStep(updateItems)
    }

    function onSaveChangeStep() {
        let updateItems = [];

        updateItemStep.forEach((item) => {
            delete item.productType;
            delete item.name;
            delete item.dateFrom;
            delete item.dateTo;

            updateItems.push(item);
        });

        props.onSaveChangeStep(updateItems);
    }

    function onChangeStepName(event, index, propertyName) {
        let updateItems = [...updateStepInfo];

        updateItems[index][propertyName] = event.target.value;

        setUpdateStepInfo(updateItems)
    }

    function onSaveStepInfo() {
        let updateItems = [];

        updateStepInfo.forEach((item) => {
            delete item.dateFrom;
            delete item.dateTo;

            updateItems.push(item);
        });

        props.onSaveStepInfo(updateItems);
    }

    function onAutoGenerateStep() {
        props.onAutoGenerateStep();
    }

    function onChangeIsAlternative(event, key, keyP, keyI) {
        let newUpdateAlternative = [...updateAlternative];

        let isSelected = event.target.checked;

        newUpdateAlternative[key].products[keyP].qInfos[keyI].isAlternative = isSelected;
        newUpdateAlternative[key].products[keyP].qInfos[keyI].hadChange = true;

        if (!isSelected)
            newUpdateAlternative[key].products[keyP].qInfos[keyI].isDefault = false;

        let defaultId = -1;
        let alternativeId = -1;
        for (let i = 0; i < newUpdateAlternative[key].products[keyP].qInfos.length; i++) {
            let item = newUpdateAlternative[key].products[keyP].qInfos[i];
            if (i !== keyI) {
                if (item.isAlternative && alternativeId === -1)
                    alternativeId = i;

                if (item.isDefault)
                    defaultId = i;
            }
        }

        if (defaultId === -1 && isSelected)
            newUpdateAlternative[key].products[keyP].qInfos[keyI].isDefault = true;

        if (defaultId === -1 && alternativeId !== -1 && !isSelected) {
            newUpdateAlternative[key].products[keyP].qInfos[alternativeId].isDefault = true;
            newUpdateAlternative[key].products[keyP].qInfos[alternativeId].hadChange = true;
        }

        setUpdateAlternative(newUpdateAlternative);
    }

    function onChangeIsDefault(event, key, keyP, keyI) {
        let newUpdateAlternative = [...updateAlternative];
        let isDefault = event.target.checked;

        newUpdateAlternative[key].products[keyP].qInfos[keyI].hadChange = true;

        if (newUpdateAlternative[key].products[keyP].qInfos[keyI].isAlternative) {
            newUpdateAlternative[key].products[keyP].qInfos[keyI].isDefault = isDefault;

            for (let i = 0; i < newUpdateAlternative[key].products[keyP].qInfos.length; i++) {
                let item = newUpdateAlternative[key].products[keyP].qInfos[i];
                if (i !== keyI && isDefault) {
                    item.isDefault = false;
                    item.hadChange = true;
                }
            }
        }

        setUpdateAlternative(newUpdateAlternative);
    }

    function onSaveChangeAlternative() {
        let updateItems = [];

        updateAlternative.forEach((item) => {

            item.products.forEach((prod) => {

                prod.qInfos.forEach((qInfo) => {
                    if (qInfo.hadChange) {
                        let newItem = {
                            id: qInfo.id,
                            isAlternative: qInfo.isAlternative,
                            isDefault: qInfo.isDefault
                        };

                        updateItems.push(newItem);
                    }
                })
            })
        });

        props.onSaveChangeAlternative(updateItems);
    }

    function onChangeShowDeleted(event) {
        props.onCallChangeShowHistory(event.target.checked, 'D');
    }

    function onChangeShowReplaced(event) {
        props.onCallChangeShowHistory(event.target.checked, 'R');
    }

    function onShowQuotationItemHistory(event, qiId) {
        let newShowHistory = [...showHistoryQuotationItem];
        let isSelected = event.target.checked;

        if (isSelected) {
            newShowHistory.push(qiId);
        } else {
            newShowHistory = newShowHistory.filter(function (value, index, arr) {
                return value !== qiId;
            });
        }

        setShowHistoryQuotationItem(newShowHistory);
    }

    function onOpenModalStepDestination(item) {

        setStepDestinationOpen(false);
        setSelectStepOpenDestination(item);
        setSelectStepDestination({
            WebId: item.stepWebDestinationId,
            Text: item.stepDestinationName
        });

        var myBtn = document.getElementById("openQuotationTO_UpdateStepDestination");
        myBtn.click();
    }

    function onSelectSearchDestination(item) {
        setSelectStepDestination({
            WebId: item.Id,
            Text: item.Text
        });
    }

    function onOpenPanelDestination() {
        setStepDestinationOpen(!stepDestinationOpen);
    }

    function callOnSaveStepDestination() {

        let updateItems = [];
        props.items.forEach((item) => {

            let webDestinationId = item.stepWebDestinationId;
            let webDestinationName = item.stepDestinationName;
            if (item.step === selectStepOpenDestination.step) {
                webDestinationId = selectStepDestination.WebId;
                webDestinationName = selectStepDestination.Text;
            }

            updateItems.push({
                step: item.step,
                name: item.stepName,
                webDestinationName: webDestinationName,
                webDestinationId: webDestinationId,
                dateFrom: item.dateFrom,
                dateTo: item.dateTo
            });
        });

        props.onSaveStepInfo(updateItems);
    }

    return (
        <>
            {
                (props.quotationMode === 'QuotationTOWorking') && <div className="card">
                    <div className="text-end">
                        <div className="form-check form-switch mt-1 mr-1 text-left">
                            <input
                                type="checkbox"
                                checked={props.showHistory.isDeleted}
                                className="form-check-input h100"
                                id="qto_show_deleted"
                                onChange={e => onChangeShowDeleted(e)}
                            />
                            <label className="form-check-label h100 fw-bold" htmlFor="qto_show_deleted">{t("MyBook:SeeDeleted")}</label>
                        </div>
                        <div className="form-check form-switch  mt-1 mr-1 text-left">
                            <input
                                type="checkbox"
                                checked={props.showHistory.isReplaced}
                                className="form-check-input h100"
                                id="qto_show_replaced"
                                onChange={e => onChangeShowReplaced(e)}
                            />
                            <label className="form-check-label h100 fw-bold" htmlFor="qto_show_replaced">{t("MyBook:SeeSubtitutes")}</label>
                        </div>

                        <button type="button" className="btn btn-outline-gray mr-1" onClick={props.onCallRequoteAll}>
                            {t("MyBook:RequoteEverything")}
                        </button>
                        <button type="button" className="btn btn-outline-gray mr-1" data-bs-toggle="modal" data-bs-target="#ModalAlternative">
                            {t("MyBook:SetAlternatives")}
                        </button>
                        <button type="button" className="btn btn-outline-gray mr-1" data-bs-toggle="modal" data-bs-target="#ModalAutoGenerateStep">
                            {t("MyBook:AutoGenerateStep")}
                        </button>
                        <button type="button" className="btn btn-outline-gray mr-1" data-bs-toggle="modal" data-bs-target="#ModalStep">
                            {t("MyBook:ManageStep")}
                        </button>
                        <button type="button" className="btn btn-outline-gray" data-bs-toggle="modal" data-bs-target="#ModalNameStep">
                            {t("MyBook:StepNameManagement")}
                        </button>
                    </div>
                </div>
            }
            {
                quotationItems.map((item, key) => {
                    return <div key={key} className="col-12 col-lg-12">
                        <div className="row">
                            <div className="col-12 col-sm-10 col-md-6 col-lg-1 mb-4">
                                <div className="timeline timeline-one timeline-mybook h-100">
                                    <div className=" h-100">
                                        <span className="badge bg-quotation-step text-dark">step {item.step}</span>
                                    </div>
                                </div>
                            </div>
                                
                            <div className="col-12 col-sm-10 col-md-6 col-lg-11 mb-2 mt-3">
                                <div className="col-12 col-sm-10 col-md-6 col-lg-12 mb-4 p-0">
                                    <div className="card bg-quotation-step p-3">
                                        <span className="h5 m-0">
                                            <label className="pr-3">{t("MyBook:From")}:</label>{formatDateTime(item.dateFrom, props.cultureName)}
                                            <label className="pr-3"> {t("MyBook:To")}:</label>{formatDateTime(item.dateTo, props.cultureName)}
                                            {item.stepName && <span> - {item.stepName}</span>}
                                            <span className="float-end h085">
                                                {
                                                    props.quotationMode === 'QuotationTOWorking' && <>
                                                        {
                                                            item.stepDestinationName ? item.stepDestinationName
                                                                : <>{t("MyBook:AddDestination")}</>
                                                        }
                                                        {
                                                            <button className="border-0 bg-transparent" onClick={_ => onOpenModalStepDestination(item)}>
                                                                {
                                                                    item.stepDestinationName ? <M3Icon iconName="Edit" externalClass="h085" />
                                                                        : <M3Icon iconName="AddCircle" externalClass="h085" />
                                                                }
                                                            </button>
                                                        }
                                                    </>
                                                }
                                                {
                                                    props.quotationMode !== 'QuotationTOWorking' && item.stepDestinationName && <span className="small">{item.stepDestinationName}</span>
                                                }
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                {
                                    item.quotationItemInfo && item.quotationItemInfo.length > 0
                                        ? item.quotationItemInfo.map((qInfo, index) => {
                                            return <React.Fragment key={index}>
                                                {
                                                    qInfo.wsBookingId && <div className="row no-gutters bg-gradient-gray-400 mb-1">
                                                        <div className="form-check form-switch  mt-1 mr-1 text-left">
                                                            <input
                                                                type="checkbox"
                                                                checked={showHistoryQuotationItem.includes(qInfo.id)}
                                                                className="form-check-input h100"
                                                                id={"qto_show_history_" + key + "_" + index}
                                                                onChange={e => onShowQuotationItemHistory(e, qInfo.id)}
                                                            />
                                                            <label className="form-check-label h100 fw-bold" htmlFor={"qto_show_history_" + key + "_" + index}>{t("MyBook:SeeOriginal")} {qInfo.id}</label>
                                                        </div>
                                                    </div>
                                                }
                                                <div className={"row" + ((qInfo.isDisabled && !(qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id))) ? " quotation-opacity-3" : "")}>
                                                    {
                                                        qInfo.productType === 'Structure' && <StructureDetail
                                                            key={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? qInfo.wsBookingId : qInfo.id}
                                                            id={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? qInfo.wsBookingId : qInfo.id}
                                                            type={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? "QuotationTO_MyBook" : "QuotationTO"}
                                                            showNetPrices={false}
                                                            customFunctions={customFunctionSingleProduct}
                                                            forceRender={qInfo.forceRender}
                                                            saveProductData={props.saveProductData}
                                                        />
                                                    }
                                                    {
                                                        qInfo.productType === 'Flight' && <FlightDetail
                                                            key={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? qInfo.wsBookingId : qInfo.id}
                                                            id={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? qInfo.wsBookingId : qInfo.id}
                                                            type={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? "QuotationTO_MyBook" : "QuotationTO"}
                                                            showNetPrices={false}
                                                            customFunctions={customFunctionSingleProduct}
                                                            forceRender={qInfo.forceRender}
                                                            saveProductData={props.saveProductData}
                                                        />
                                                    }
                                                    {
                                                        qInfo.productType === 'Tour' && <TourDetail
                                                            key={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? qInfo.wsBookingId : qInfo.id}
                                                            id={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? qInfo.wsBookingId : qInfo.id}
                                                            type={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? "QuotationTO_MyBook" : "QuotationTO"}
                                                            showNetPrices={false}
                                                            customFunctions={customFunctionSingleProduct}
                                                            forceRender={qInfo.forceRender}
                                                            saveProductData={props.saveProductData}
                                                        />
                                                    }
                                                    {
                                                        qInfo.productType === 'Activity' && <ActivityDetail
                                                            key={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? qInfo.wsBookingId : qInfo.id}
                                                            id={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? qInfo.wsBookingId : qInfo.id}
                                                            type={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? "QuotationTO_MyBook" : "QuotationTO"}
                                                            showNetPrices={false}
                                                            customFunctions={customFunctionSingleProduct}
                                                            forceRender={qInfo.forceRender}
                                                            saveProductData={props.saveProductData}
                                                        />
                                                    }
                                                    {
                                                        qInfo.productType === 'Transfer' && <TransferDetail
                                                            key={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? qInfo.wsBookingId : qInfo.id}
                                                            id={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? qInfo.wsBookingId : qInfo.id}
                                                            type={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? "QuotationTO_MyBook" : "QuotationTO"}
                                                            showNetPrices={false}
                                                            customFunctions={customFunctionSingleProduct}
                                                            forceRender={qInfo.forceRender}
                                                            saveProductData={props.saveProductData}
                                                        />
                                                    }
                                                    {
                                                        qInfo.productType === 'CarRental' && <CarRentalDetail
                                                            key={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? qInfo.wsBookingId : qInfo.id}
                                                            id={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? qInfo.wsBookingId : qInfo.id}
                                                            type={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? "QuotationTO_MyBook" : "QuotationTO"}
                                                            showNetPrices={false}
                                                            customFunctions={customFunctionSingleProduct}
                                                            forceRender={qInfo.forceRender}
                                                            saveProductData={props.saveProductData}
                                                        />
                                                    }
                                                    {
                                                        qInfo.productType === 'Cruise' && <CruiseDetail
                                                            key={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? qInfo.wsBookingId : qInfo.id}
                                                            id={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? qInfo.wsBookingId : qInfo.id}
                                                            type={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? "QuotationTO_MyBook" : "QuotationTO"}
                                                            showNetPrices={false}
                                                            customFunctions={customFunctionSingleProduct}
                                                            forceRender={qInfo.forceRender}
                                                            saveProductData={props.saveProductData}
                                                        />
                                                    }
                                                    {
                                                        qInfo.productType === 'Train' && <TrainDetail
                                                            key={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? qInfo.wsBookingId : qInfo.id}
                                                            id={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? qInfo.wsBookingId : qInfo.id}
                                                            type={qInfo.wsBookingId && !showHistoryQuotationItem.includes(qInfo.id) ? "QuotationTO_MyBook" : "QuotationTO"}
                                                            showNetPrices={false}
                                                            customFunctions={customFunctionSingleProduct}
                                                            forceRender={qInfo.forceRender}
                                                            saveProductData={props.saveProductData} />
                                                    }
                                                </div>
                                                {
                                                    (props.quotationMode === 'QuotationTOWorking') && !qInfo.isAvailable && !qInfo.wsBookingId && <div className="row basket">
                                                        <div>
                                                            <div className="col-12 col-sm-10 col-md-6 col-lg-12 px-0">
                                                                <div className="card allertError mb-4 mt-n3">
                                                                    {
                                                                        qInfo.productType === 'Structure' && <AvailableStructure
                                                                            quotationItemId={qInfo.id}
                                                                            mode="QuotationTO"
                                                                            changeProductFn={customFunctionSingleProduct.onCallUpdateQuotationItem}
                                                                        />
                                                                    }
                                                                    {
                                                                        qInfo.productType === 'Flight' && <AvailableFlight
                                                                            quotationItemId={qInfo.id}
                                                                            mode="QuotationTO"
                                                                            changeProductFn={customFunctionSingleProduct.onCallUpdateQuotationItem}
                                                                        />
                                                                    }
                                                                    {
                                                                        qInfo.productType === 'Tour' && <AvailableTour
                                                                            quotationItemId={qInfo.id}
                                                                            mode="QuotationTO"
                                                                            changeProductFn={customFunctionSingleProduct.onCallUpdateQuotationItem}
                                                                        />
                                                                    }
                                                                    {
                                                                        qInfo.productType === 'Activity' && <AvailableActivity
                                                                            quotationItemId={qInfo.id}
                                                                            mode="QuotationTO"
                                                                            changeProductFn={customFunctionSingleProduct.onCallUpdateQuotationItem}
                                                                        />
                                                                    }
                                                                    {
                                                                        qInfo.productType === 'Transfer' && <AvailableTransfer
                                                                            quotationItemId={qInfo.id}
                                                                            mode="QuotationTO"
                                                                            changeProductFn={customFunctionSingleProduct.onCallUpdateQuotationItem}
                                                                        />
                                                                    }
                                                                    {
                                                                        qInfo.productType === 'CarRental' && <AvailableCarRental
                                                                            quotationItemId={qInfo.id}
                                                                            mode="QuotationTO"
                                                                            changeProductFn={customFunctionSingleProduct.onCallUpdateQuotationItem}
                                                                        />
                                                                    }
                                                                    {
                                                                        qInfo.productType === 'Cruise' && <AvailableCruise
                                                                            quotationItemId={qInfo.id}
                                                                            mode="QuotationTO"
                                                                            changeProductFn={customFunctionSingleProduct.onCallUpdateQuotationItem}
                                                                        />
                                                                    }
                                                                    {
                                                                        qInfo.productType === 'Train' && <AvailableTrain
                                                                            quotationItemId={qInfo.id}
                                                                            mode="QuotationTO"
                                                                            changeProductFn={customFunctionSingleProduct.onCallUpdateQuotationItem}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row no-gutters bg-gradient-gray-400 mb-4">
                                                    {
                                                        qInfo.alternatives.length > 0 && qInfo.alternatives.map((alt, keyAlt) => {
                                                            return <QuotationItemAlternative
                                                                key={keyAlt}
                                                                id={alt.id}
                                                                item={alt}
                                                                fatherId={qInfo.id}
                                                                onSaveSelectAlternative={props.onSaveSelectAlternative}
                                                            />
                                                        })
                                                    }
                                                </div>

                                            </React.Fragment>
                                        })
                                        : <></>
                                }
                            </div>
                        </div>
                    </div>
                })
            }

            {
                props.quotationMode === 'QuotationTOWorking' && <div className="modal fade" id="ModalStep" tabIndex="-1" aria-labelledby="ModalStepLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{t("MyBook:ModStepTitle")}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <table className="mybook-table table-hover p-0 xx-small ">
                                    <thead>
                                        <tr>
                                            <th scope="col">{t("MyBook:Type")}</th>
                                            <th scope="col">{t("MyBook:Product")}</th>
                                            <th scope="col">{t("MyBook:From")}-{t("MyBook:To")}</th>
                                            <th scope="col">Step</th>
                                            <th scope="col">{t("MyBook:Sequence")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            updateItemStep.map((item, key) => {
                                                return <tr key={key} className="p-0">
                                                    <th scope="row">
                                                        <M3IconProductType productType={item.productType} productSubtype={item.productSubType} />
                                                    </th>
                                                    <td>
                                                        <div className="d-flex align-items-lg-start">
                                                            {item.name}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-lg-start">
                                                            {formatDateTime(item.dateFrom, props.cultureName, { noYear: true })}
                                                            &nbsp;-&nbsp;
                                                            {formatDateTime(item.dateTo, props.cultureName, { noYear: true })}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <input type="number"
                                                                placeholder="1"
                                                                value={item.step}
                                                                onChange={(e) => onChangeStep(e, key, 'step')} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <input type="number"
                                                                placeholder="1"
                                                                value={item.order}
                                                                onChange={(e) => onChangeStep(e, key, 'order')} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <div className="w-100 text-right">{t("MyBook:SaveModifiedQuotationText")}.</div>
                                <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t("MyBook:CloseWithoutSave")}</button>
                                <button type="button" className="btn bt-sm tp-btn-confirm" data-bs-dismiss="modal" onClick={onSaveChangeStep}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                props.quotationMode === 'QuotationTOWorking' && <div className="modal fade" id="ModalNameStep" tabIndex="-1" aria-labelledby="ModalNameStepLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{t("MyBook:AssignDescriptionToTitle")}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <table className="mybook-table table-hover p-0 xx-small ">
                                    <thead>
                                        <tr>
                                            <th scope="col">Step</th>
                                            <th scope="col">{t("MyBook:From")}-{t("MyBook:To")}</th>
                                            <th scope="col">{t("MyBook:Name")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            updateStepInfo.map((item, key) => {
                                                return <tr key={key} className="p-0">
                                                    <th scope="row"><label>Step {item.step}</label></th>
                                                    <td className="w-25">
                                                        {formatDateTime(item.dateFrom, props.cultureName)}
                                                        &nbsp;-&nbsp;
                                                        {formatDateTime(item.dateTo, props.cultureName)}
                                                    </td>
                                                    <td>
                                                        <input type="text"
                                                            className=""
                                                            value={item.name}
                                                            onChange={(e) => onChangeStepName(e, key, 'name')} />
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <div className="w-100 text-right">{t("MyBook:SaveModifiedQuotationText")}.</div>
                                <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t("MyBook:CloseWithoutSave")}</button>
                                <button type="button" className="btn bt-sm tp-btn-confirm" data-bs-dismiss="modal" onClick={onSaveStepInfo}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                props.quotationMode === 'QuotationTOWorking' && <div className="modal fade" id="ModalAlternative" tabIndex="-1" aria-labelledby="ModalAlternativeStepLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="ModalAlternativeStepLabel">{t("MyBook:SetAlternatives")}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {
                                    updateAlternative && updateAlternative.length > 0
                                        ? <table className="mybook-table table-hover p-0 xx-small ">
                                            <thead>
                                            </thead>
                                            <tbody>
                                                {
                                                    updateAlternative.map((item, key) => {
                                                        return <React.Fragment key={key}>
                                                            <tr><td><b>Step {item.step}</b></td></tr>
                                                            {
                                                                item.products.map((product, keyP) => {
                                                                    return <React.Fragment key={keyP}>
                                                                        <tr><td><M3IconProductType productType={product.productType} productSubtype={product.productSubType} /></td></tr>
                                                                        <tr>
                                                                            <td className="text-center">{t("MyBook:Product")}</td>
                                                                            <td className="text-center">{t("MyBook:Price")}</td>
                                                                            <td className="text-center">{t("MyBook:Alternative")}</td>
                                                                            <td className="text-center">Default</td>
                                                                        </tr>
                                                                        {
                                                                            product.qInfos.map((qInfoItem, keyI) => {
                                                                                return <tr key={keyI}>
                                                                                    <td className="text-center">{qInfoItem.name}</td>
                                                                                    <td className="text-center">{formatPrice(qInfoItem.priceAmount, qInfoItem.priceCurrency, props.cultureName)}</td>
                                                                                    <td className="text-center">
                                                                                        <input type="checkbox"
                                                                                            className=""
                                                                                            name={`btnRadio_Alternative_step${key}_prod${keyP}_item${keyI}`}
                                                                                            checked={qInfoItem.isAlternative}
                                                                                            onChange={e => onChangeIsAlternative(e, key, keyP, keyI)} />
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        <input type="radio"
                                                                                            className=""
                                                                                            name={`btnRadio_Default_step${key}_prod${keyP}_item${keyI}`}
                                                                                            checked={qInfoItem.isDefault}
                                                                                            onChange={e => onChangeIsDefault(e, key, keyP, keyI)} />
                                                                                    </td>
                                                                                </tr>
                                                                            })
                                                                        }
                                                                    </React.Fragment>
                                                                })
                                                            }
                                                        </React.Fragment>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        : <div>Nessuna alternativa disponibile</div>
                                }
                            </div>
                            <div className="modal-footer">
                                <div className="w-100 text-right">{t("MyBook:SaveModifiedQuotationText")}.</div>
                                <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t("MyBook:CloseWithoutSave")}</button>
                                <button type="button" className="btn bt-sm tp-btn-confirm" data-bs-dismiss="modal" onClick={onSaveChangeAlternative}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                props.quotationMode === 'QuotationTOWorking' && <div className="modal fade" id="ModalAutoGenerateStep" tabIndex="-1" aria-labelledby="ModalAutoGenerateStepLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{t("MyBook:AutoGenerateStep")}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {t("MyBook:AutoGenerateStepMessage")}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t("MyBook:CloseWithoutSave")}</button>
                                <button type="button" className="btn bt-sm tp-btn-confirm" data-bs-dismiss="modal" onClick={onAutoGenerateStep}>{t("Button:Save")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                props.quotationMode === 'QuotationTOWorking' && <>

                    <a id="openQuotationTO_UpdateStepDestination" data-bs-toggle="modal" data-bs-target="#quotationTO_UpdateStepDestination"></a>
                    <div className="modal fade" id="quotationTO_UpdateStepDestination" tabIndex="-1" aria-labelledby="quotationTO_UpdateStepDestinationLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="quotationTO_UpdateStepDestinationLabel">{t("MyBook:AddDestination")}</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <InputSearchDestinationContainer
                                        defaultSelectDestination={selectStepDestination}
                                        productType={configData.Settings.Products.Structure.IdTipoProdotto}
                                        onSelectSearchDestination={onSelectSearchDestination}
                                        onOpenPanelDestination={onOpenPanelDestination}
                                        closeExternal={stepDestinationOpen}
                                        clearSearch={stepDestinationOpen}
                                        extraOptions={{ rules: { enableDestination: false, enableCity: true } }}
                                        modeView="MODAL" />
                                </div>
                                <div className='modal-footer'>
                                    <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t("Button:Close")}</button>
                                    <button type="button"
                                        className="btn bt-sm tp-btn-confirm"
                                        onClick={callOnSaveStepDestination}>
                                        {t("Button:Save")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            }
        </>


    );
}