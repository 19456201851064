import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import configData from "../../appsettings.json";

export const M3DiscountCodes = ({ discountCodes, onSelectDiscounts, avlCarpetId }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName, setCultureName] = useState(cookies.get("CultureName"));

    const idCheckBox = "cbM3DiscountCode_";
    const [selectInputIndex, setSelectInputIndex] = useState();
    const [selectDiscount, setSelectDiscount] = useState([]);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [intervalIds, setIntervalIds] = useState([]);

    const [memberCardLoading, setMemberCardLoading] = useState(false);
    const [memberCardDiscount, setMemberCardDiscount] = useState(null);
    const [memberCardFirstName, setMemberCardFirstName] = useState();
    const [memberCardLastName, setMemberCardLastName] = useState();
    const [memberCardNumber, setMemberCardNumber] = useState();
    const [memberCardIsValid, setMemberCardIsValid] = useState(null);


    useEffect(() => {
        if (!isFirstRender) {
            let intervalId = setTimeout(() => { onSelectDiscounts(selectDiscount); }, 600);

            for (let i = 0; i < intervalIds.length; i++) {
                let intId = intervalIds[i];
                clearInterval(intId);
            }

            setIntervalIds([intervalId])
        }

        setIsFirstRender(false);
    }, [selectDiscount])


    function onSelectDiscount(discount, index, event) {
        setMemberCardDiscount(null);
        setMemberCardFirstName(null);
        setMemberCardLastName(null);
        setMemberCardNumber(null);
        setMemberCardIsValid(null);

        let isChecked = event.currentTarget.checked;
        if (discount.isClub && isChecked) {
            setSelectInputIndex(index);
            setMemberCardDiscount(discount);

            let iFirstName = document.getElementById("iFirstName");
            iFirstName.value = "";

            let iLastName = document.getElementById("iLastName");
            iLastName.value = "";

            let iMemberCard = document.getElementById("iMemberCard");
            iMemberCard.value = "";

            let myBtn = document.getElementById("open_CheckMemberCard");
            myBtn.click();            
        } else {
            SaveMemberCard();
            onSelectCheckBox(discount, event);
        }
    }

    function onSelectCheckBox(discount, event) {
        let code = discount.value;
        let isChecked = event.currentTarget.checked;
        let updDiscount = [];

        if (selectDiscount && selectDiscount.length > 0)
            updDiscount = [...selectDiscount]

        if (isChecked) {
            updDiscount.push(code);
        } else {
            updDiscount = updDiscount.filter(x => x !== code);
        }

        setSelectDiscount(updDiscount);
    }


    async function onConfirmMemberCard() {
        setMemberCardLoading(true);

        const inputData =
        {
            carpetId: avlCarpetId,
            cultureName: cultureName,
            firstName: memberCardFirstName,
            lastName: memberCardLastName,
            memberCardNumber: memberCardNumber,
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Cruise/GetCruiseMemberCard', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response && response.success && response.isValid) {
                setMemberCardIsValid(true);
                const resSave = await SaveMemberCard();
                if (resSave) {
                    onSelectCheckBox(memberCardDiscount, { currentTarget: { checked: true } });
                }
            }
            else {
                setMemberCardIsValid(false);
                onSelectCheckBox(memberCardDiscount, { currentTarget: { checked: false } });
                let checkbox = document.getElementById(`${idCheckBox}${selectInputIndex}`);
                checkbox.checked = false;
            }
        }
        else {
            setMemberCardIsValid(false);
        }

        setMemberCardLoading(false);
    }

    async function SaveMemberCard() {
        const inputData =
        {
            carpetId: avlCarpetId,
            cultureName: cultureName,
            firstName: memberCardFirstName,
            lastName: memberCardLastName,
            memberCardNumber: memberCardNumber,
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Cruise/SaveMemberCard', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response && response.success) {
                return true;
            }
        }

        return false;
    }


    return (
        <>
            {discountCodes && discountCodes.length > 0
                ? <div className="row">
                    <div>{t('Product:Cruise:DiscountPaxes')}</div>
                    {
                        discountCodes.map((discount, index) => {
                            return <div key={index} className="col-12 col-sm-4 mb-1">
                                <input type="checkbox"
                                    id={idCheckBox + index}
                                    className="mr-1 position-relative"
                                    value={selectDiscount && selectDiscount.length > 0 ? selectDiscount.some(sdn => sdn === discount.value) : false}
                                    defaultValue="false"
                                    style={{ 'top': '2px' }}
                                    onClick={e => onSelectDiscount(discount, index, e)} />
                                <label className="p-0 m-0 h065" htmlFor={idCheckBox + index}>{discount.label}</label>
                            </div>
                        })
                    }
                </div>
                : <></>
            }

            <a id={"open_CheckMemberCard"} data-bs-toggle="modal" data-bs-target={"#modal_CheckMemberCard"}></a>

            <div className="modal fade" id={"modal_CheckMemberCard"} tabIndex="-1" aria-labelledby="modal_CheckMemberCardLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal_CheckMemberCardLabel">Member Card</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Inserisci i tuoi dati per verificare la carta fedeltà.</p>
                            <div className="input-group bg-white rounded pr-1">
                                <div className="pl-1 pt-2 pb-2">
                                    <input id="iFirstName" type="text" placeholder="Nome" value={memberCardFirstName} onChange={e => setMemberCardFirstName(e.target.value)} />
                                </div>
                            </div>
                            <div className="input-group bg-white rounded pr-1">
                                <div className="pl-1 pt-2 pb-2">
                                    <input id="iLastName" type="text" placeholder="Cognome" value={memberCardLastName} onChange={e => setMemberCardLastName(e.target.value)} />
                                </div>
                            </div>
                            <div className="input-group bg-white rounded pr-1">
                                <div className="pl-1 pt-2 pb-2">
                                    <input id="iMemberCard" type="text" placeholder="Numero Carta Fedeltà" value={memberCardNumber} onChange={e => setMemberCardNumber(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t(`Button:Close`)}</button>
                            {!memberCardLoading && !memberCardIsValid && <button type="button" className="btn bt-sm btn-success" onClick={e => onConfirmMemberCard()}>{t(`Button:Confirm`)}</button>}
                            {memberCardLoading && <>
                                <div className="spinner-border spinner-border-sm text-dark mt-4" role="status"></div>
                                <span className="sr-only pl-2">{t(`Product:Cruise:MemberCard_VerifyingInProgress`)}</span>
                            </>}
                        </div>
                        {!memberCardLoading && memberCardIsValid &&
                            <div className="pt-2 pr-3 pb-2 text-right text-success">
                                {t(`Product:Cruise:MemberCard_VerifyOK`)}
                            </div>
                        }
                        {!memberCardLoading && memberCardIsValid !== null && memberCardIsValid === false &&
                            <div className="pt-2 pr-3 pb-2 text-right text-danger">
                                {t(`Product:Cruise:MemberCard_VerifyKO`)}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
