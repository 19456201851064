import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const ProductNotAllowedAddToCart = ({ enableCanAddToCart }) => {
    const { t } = useTranslation();

    const [addToCartObj, setAddToCartObj] = useState(null);
    useEffect(() => {

        setAddToCartObj({ ...enableCanAddToCart });

    }, [enableCanAddToCart])

    return (
        <>
            {
                addToCartObj && !addToCartObj.allowed && <div className="text-danger">
                    {
                        addToCartObj.label === 'TEMPLATEEXIST' && <>{t('Error:AddToCart:TemplateExist')}</>
                    }
                    {
                        addToCartObj.label === 'DISABLEDCARTCOMPOSITION' && <>{t('Error:AddToCart:CartComposition')}</>
                    }
                    {
                        addToCartObj.label === 'DISABLEDADDTOORDER' && <>{t('Error:AddToCart:AddToOrder')}</>
                    }
                    {
                        addToCartObj.label === 'PRODUCTNOTAVAILABLE' && <>{t('Error:AddToCart:ProductNotAvailable')}</>
                    }
                    {
                        addToCartObj.label === 'FLIGHTRESTRICTION' && <>{t('Error:AddToCart:FlightRestriction')}</>
                    }
                </div>
            }
        </>
    );
}