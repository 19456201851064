import React, { useEffect } from 'react';
import configData from "../../../appsettings.json";
import { useSearchParams } from "react-router-dom";
import { handleError } from "../../../js/Utils";

export const JointlyPayValidate = () => {
    const [searchParams] = useSearchParams();

    let extToken = searchParams.get("token");
    let extAmount = searchParams.get("value");

    useEffect(() => {
        callJointlyPayValidate();
    }, []);

    function callJointlyPayValidate() {

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}paymentgateway/jointlypayvalidate/${extToken}/${extAmount}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
            })
            .catch((err) => {
                console.log(err);
            });

    }

    return (
        <div className="row text-center">
            <div className="col-12 penalty">
            </div>
        </div>
    );
}
