import React, { useState, useEffect } from 'react';
import { HotelList } from './HotelList';
import { HotelDetail } from './HotelDetail';
import { getStructureCarpetAvailabilityId } from '../../../../js/ProductService';
import { scrollTo } from '../../../../js/Utils.js';

export const TrippieHotelProductResult = ({ productSearchParam, onAddHotel }) => {
    const [extraParam, setExtraParam] = useState(null);
    const [availabilityParam, setAvailabilityParam] = useState(null);
    const [availabilityParamArr, setAvailabilityParamArr] = useState([]);
    const [forceClearIntervalList, setForceClearIntervalList] = useState([]);

    const [showPanel, setShowPanel] = useState(null);
    const [forceRender, setForceRender] = useState(null);

    useEffect(() => {
        if (productSearchParam) {
            setExtraParam({
                carpetId: productSearchParam.carpetId,
                clientFlowId: productSearchParam.clientFlowId,
                productId: 0
            });

            setShowPanel(null);
            setAvailabilityParamArr([]);
            setAvailabilityParam(null);
        }

    }, [productSearchParam])

    async function onClickSearch(structureId, structureName, filters, filterSelected, hotelSearchParameters) {
        let param = {};

        let defaultMealplanId = ''
        if (filters && filters.length > 0) {
            for (let i = 0; i < filters.length; i++) {
                if (filters[0].type === 'MEALPLANID' && filters[0].value)
                    defaultMealplanId += filters[0].value
            }
        }

        let sFavourite = '';
        if (filters && filters.favourite) {
            sFavourite += filters.favourite
        }

        let suppliersIds = '';
        if (filterSelected.suppliers && filterSelected.suppliers.length > 0) {
            let arrTmp = [];
            filterSelected.suppliers.forEach((supplier) => {
                arrTmp.push(supplier.id);
            });

            suppliersIds += arrTmp.join();
        }

        let onlyPack = '';
        if (filterSelected && filterSelected.bestRoomTypes && filterSelected.bestRoomTypes.length > 0) {
            for (let i = 0; i < filterSelected.bestRoomTypes.length; i++) {
                if (filterSelected.bestRoomTypes[0].id === 'PackageRate')
                    onlyPack += '1';
            }
        }

        let carpetAvailabilityId = await getStructureCarpetAvailabilityId(hotelSearchParameters, productSearchParam.clientFlowId, structureId);

        param.clientFlowId = productSearchParam.clientFlowId;
        param.carpetId = productSearchParam.carpetId;
        param.carpetAvailabilityId = carpetAvailabilityId;
        param.structureId = structureId;
        param.name = structureName;
        param.defaultMealplanId = defaultMealplanId;
        param.sFavourite = false;
        param.suppliersIds = suppliersIds;
        param.onlyPack = onlyPack;
        param.cartItemId = productSearchParam.cartItemId;

        clearCarpetAllInterval();
        let updAvailabilityParam = [...availabilityParamArr];

        if (updAvailabilityParam.some(x => x.structureId === structureId)) {
            changePanel(structureId);
        } else {
            updAvailabilityParam.push(param);
            setAvailabilityParamArr(updAvailabilityParam);

            setAvailabilityParam(param);
            setShowPanel(structureId);
        }

        scrollTo(null, "trippieSearchListResult");
    }

    function changePanel(structureId) {
        setShowPanel(structureId);

        if (structureId) {
            let avlParam = availabilityParamArr.filter(x => x.structureId === structureId);
            if (avlParam.length > 0)
                setAvailabilityParam(avlParam[0]);

            setForceRender(forceRender + 1);
        } else {
            setAvailabilityParam(null);
        }
    }

    function closePanel(structureId, event) {
        event.stopPropagation();

        if (showPanel === structureId) {
            setShowPanel(null);
            setAvailabilityParam(null);
        }

        if (structureId) {
            let avlParam = availabilityParamArr.filter(x => x.structureId !== structureId);
            setAvailabilityParamArr(avlParam);
        } else {
            setShowPanel(null);
            setAvailabilityParam(null);
        }
    }

    function saveIntervalCarpetPing(intervalPing) {
        let upd = [...forceClearIntervalList];
        upd.push(intervalPing);
        setForceClearIntervalList(upd);
    }

    function clearCarpetAllInterval() {
        if (forceClearIntervalList) {
            for (let i = 0; i < forceClearIntervalList.length; i++) {
                try {
                    let intId = forceClearIntervalList[i];
                    clearInterval(intId);
                } catch (ex) { }

            }

            setForceClearIntervalList([]);
        }
    }

    function onAddProduct(hotelRecap) {
        clearCarpetAllInterval();
        onAddHotel(productSearchParam.stepIndex, productSearchParam.cartItemId, hotelRecap, productSearchParam.carpetId, availabilityParam.carpetAvailabilityId);
    }

    return (
        <>
            {
                <ul class="nav nav-tabs">
                    <li class="nav-item" aria-current="page" onClick={_ => changePanel(null)}>
                        <div className={"nav-link " + (showPanel ? "" : "active")}>List</div>
                    </li>
                    {
                        availabilityParamArr && availabilityParamArr.map((ap, index) => {
                            return <li key={index} title={ap.name} class="nav-item" aria-current="page" onClick={_ => changePanel(ap.structureId)}>
                                <div className={"nav-link " + (showPanel && showPanel === ap.structureId ? "active" : "")}>
                                    {ap.name.substring(0, 10)}
                                    <button type="button" class="btn-close h-25 position-relative" style={{ 'top': '-12px', 'right': '-10px' }} aria-label="Close" onClick={e => closePanel(ap.structureId, e)}></button>
                                </div>
                            </li>
                        })
                    }
                </ul>
            }
            {
                productSearchParam.carpetId && !availabilityParam && <HotelList carpetId={productSearchParam.carpetId}
                    clientFlowId={productSearchParam.clientFlowId}
                    extraParam={extraParam}
                    saveIntervalCarpetPing={saveIntervalCarpetPing}
                    onClickSearch={onClickSearch} />
            }
            {
                availabilityParam && <HotelDetail
                    forceRender={forceRender}
                    structureId={availabilityParam.structureId}
                    clientFlowId={availabilityParam.clientFlowId}
                    caId={availabilityParam.carpetAvailabilityId}
                    carpetSearchId={availabilityParam.carpetId}
                    defaultMealplanId={availabilityParam.defaultMealplanId}
                    onlyPack={availabilityParam.onlyPack}
                    favourite={availabilityParam.sFavourite}
                    defualtSuppliersIds={availabilityParam.suppliersIds}
                    step={productSearchParam.stepIndex}
                    cartItemId={productSearchParam.cartItemId}
                    saveIntervalCarpetPing={saveIntervalCarpetPing}
                    onAddProduct={onAddProduct} />
            }
        </>
    );
}