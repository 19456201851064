import configData from "../../../../appsettings.json";

export async function BeginTransactionJointlyPay(jointlyInfo) {
    let jointlyRedirectUrl = "";

    if (!jointlyInfo)
        return jointlyRedirectUrl;

    let inputData = {
        'product_name': jointlyInfo.productName,
        'price': jointlyInfo.amount,
        'value': jointlyInfo.amount,
        'venue': jointlyInfo.productName,
        'product_description': jointlyInfo.productName,
        'info': jointlyInfo.productName,
        'payment_group_id': 2,//4,
        'callback_url': jointlyInfo.callbackUrlEncode,
        'beneficiary_accept': 1,
        'beneficiary_name': jointlyInfo.bfName,
        'beneficiary_surname': jointlyInfo.bfSurname,
        'beneficiary_relation': "me",
        'beneficiary_cf': jointlyInfo.bfCf,
        'freeze_for': 0
    };

    try {

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}PaymentGateway/LogStartTransactionJointly`, requestOption);

    } catch (ex) {

    }

    try {
        debugger;
        let response = await window.JointlyWallet.beginTransaction(inputData);
        debugger;
        if (response.errors) {
            // salvo Errore con il nostro transactionCode
            errorLogJointly(response.errors);
            console.log('response.errors', response.errors);
            alert("La piattaforma Jointly non è al momento raggiungibile");

            SaveJointlyBeginTransactionError(jointlyInfo.customerId, jointlyInfo.transactionCode, 7, response.errors);
        } else {
            //Eventuali implementazioni
            console.log(response);
            jointlyRedirectUrl = response.checkout_url;
            SaveJointlyBeginTransactionSuccess(jointlyInfo.customerId, jointlyInfo.transactionCode, 7, response.token, jointlyRedirectUrl);
        }

    } catch (ex) {

        try {

            alert('La piattaforma Jointly non è al momento raggiungibile');
            SaveJointlyBeginTransactionError(jointlyInfo.customerId, jointlyInfo.transactionCode, 7, ex);

        } catch (ex1) {

        }

        jointlyRedirectUrl = "";
        errorLogJointly(ex);
    }

    return jointlyRedirectUrl;
}

async function SaveJointlyBeginTransactionError(cId, transactionCode, payId, err) {

    var errNormalize = encodeURIComponent(err);
    const requestOption = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            customerId: cId,
            transactionCode: transactionCode,
            paymentId: payId,
            err: errNormalize
        })
    };

    const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}PaymentGateway/BeginTransactionErrorJointly`, requestOption);
}

async function SaveJointlyBeginTransactionSuccess(cId, transactionCode, payId, extTCode, jointlyRedirectUrl) {

    const requestOption = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            customerId: cId,
            transactionCode: transactionCode,
            paymentId: payId,
            extTransactionCode: extTCode
        })
    };

    const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}PaymentGateway/BeginTransactionSuccessJointly`, requestOption);
    const response = await fetchedRes.json();

    if (response && response.success) {
        window.location.href = jointlyRedirectUrl;
    } else {
        errorLogJointly(response.msg);
        console.log("SaveJointlyBeginTransactionSuccess [error: " + response.msg + "]");
        SaveJointlyBeginTransactionError(cId, transactionCode, payId, response.msg);
    }

}

async function errorLogJointly(errorMsg) {
    try {

        try {

            const requestOption = {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ error: errorMsg })
            };

            const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}PaymentGateway/LogStartTransactionJointly`, requestOption);

        } catch (ex) {

        }

    } catch (ex) {

    }

}