import React, { useState, useEffect } from "react";
import { InputCalendar } from './InputCalendar';
import { InputCalendarReadOnly } from './InputCalendarReadOnly';
import { M3Calendar } from './M3Calendar';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { closeTpSearchInput } from '../../../js/Utils';

export const InputCalendarContainer = ({ externalId, dateFrom, dateTo, timeFrom, timeTo, productType, onClickSelectDates, onOpenPanelCalendar, closeExternal, extraOptions, validDateRule, modeView, type, minDate, isForceOpenLeft }) => {
    const { t } = useTranslation();

    const [selectDates, setSelectDates] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);

    const [idModal] = useState(getRandomInt());
    function getRandomInt() {
        return Math.floor(Math.random() * 100000);
    }
    const modalId = "Calendar_" + idModal;

    useEffect(() => {

        if (modeView === 'SEARCHENGINE')
            closeTpSearchInput(modalId, closePanelCalendar);

    }, []);

    const [inputWidth, setInputWidth] = useState('w-75');

    useEffect(() => {
        if (type === 'l') {
            setInputWidth('w-65');
        }
    }, [type])

    useEffect(() => {
        let updateSelectDates = null;

        if (dateFrom) {
            updateSelectDates = {};
            updateSelectDates.dateFrom = dateFrom;
        }

        if (updateSelectDates) {
            if (dateTo) {
                updateSelectDates.dateTo = dateTo;
            }

            if (timeFrom)
                updateSelectDates.timeFrom = timeFrom;

            if (timeTo)
                updateSelectDates.timeTo = timeTo;

        }

        setSelectDates(updateSelectDates);
    }, [dateFrom, dateTo]);

    useEffect(() => {
        setShowCalendar(closeExternal);
    }, [closeExternal]);

    function onClickOpenCalendar() {
        setShowCalendar(!showCalendar);

        if (!externalId)
            onOpenPanelCalendar();
        else
            onOpenPanelCalendar(externalId);

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnModalCalendar_" + idModal);
            btn.click();
        }
    }

    function closePanelCalendar() {
        setShowCalendar(false);

        try {

            let myModal = document.getElementById(idModal);
            myModal.style.setProperty("height", "0px");

        } catch (ex) { }

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnCloseModalCalendar_" + idModal);
            btn.click();
        }
    }

    function onSelectDate(values) {
        setShowCalendar(false);
        let myDates = {
            dateFrom: values.dateFrom,
            dateTo: values.dateTo,
            timeFrom: values.timeFrom,
            timeTo: values.timeTo,
        };

        setSelectDates(myDates);
        if (!externalId)
            onClickSelectDates(myDates);
        else
            onClickSelectDates(myDates, externalId);

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnCloseModalCalendar_" + idModal);
            btn.click();
        }

    }

    function isMobile(excludeModeView = true) {
        if (excludeModeView && modeView === 'MODAL')
            return false;

        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const isMobileWidth = window.innerWidth <= 991;

        // Controllo per dispositivi Android
        if (/android/i.test(userAgent)) {
            return true;
        }

        // Controllo per dispositivi iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return true;
        }

        return isMobileWidth;
    }

    const getPlaceholder = () => {
        if (extraOptions && extraOptions.label)
            return t(extraOptions.label);

        if (!extraOptions || !extraOptions.multiDate)
            return t('SearchEngine:SelectMonth');

        return t('SearchEngine:SelectMonths');
    }

    return (
        <>
            <div className="w-100">
                {
                    !showCalendar && <InputCalendarReadOnly
                        dateFrom={selectDates && selectDates.dateFrom ? selectDates.dateFrom : null}
                        dateTo={selectDates && selectDates.dateTo ? selectDates.dateTo : null}
                        timeFrom={selectDates && selectDates.timeFrom ? selectDates.timeFrom : null}
                        timeTo={selectDates && selectDates.timeTo ? selectDates.timeTo : null}
                        hadDuration={false}
                        extraOptions={extraOptions}
                        productType={productType}
                        modeView="MODAL"
                        type={type}
                        onClickOpen={onClickOpenCalendar} />
                }
            </div>

            <a id={"btnModalCalendar_" + idModal} data-bs-toggle="modal" data-bs-target={"#" + modalId}></a>
            <div
                className={"modal " + (isMobile() ? "" : " tp-search-input position-relative ") + (modeView === 'MODAL' ? "d-block" : "fade")}
                style={{ overflow: 'unset', height: (!isMobile() ? (isMobile(false) && modeView === 'MODAL' && showCalendar ? '' : '0px') : '100%'), zIndex: (!isMobile() ? '200' : '999') }}
                id={modalId}
                tabIndex="-1"
                aria-labelledby={"CalendarLabel_" + idModal}
                aria-hidden="true">
                <div className={"modal-dialog " + (isMobile() ? "h-100 modal-dialog-scrollable" : "")}>

                    {
                        showCalendar && <div className={"modal-content " + (isMobile() ? "h-100" : "")}>
                            {
                                isMobile()
                                    ? <div className="modal-header p-0">
                                        <div className="container py-3">
                                            <div className="row">
                                                <div className="col-1">
                                                    <button className="btn btn-link p-0 m-0 text-decoration-none" onClick={_ => closePanelCalendar()}>
                                                        <span class="material-icons-outlined">
                                                            arrow_back
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="col-11 text-center">
                                                    <h6 className="btn btn-link p-0 m-0 text-decoration-none fw-bold text-dark">
                                                        {getPlaceholder()}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                            }
                            <div className="modal-header p-0">
                                <InputCalendar
                                    dateFrom={selectDates && selectDates.dateFrom ? selectDates.dateFrom : null}
                                    dateTo={selectDates && selectDates.dateTo ? selectDates.dateTo : null}
                                    timeFrom={selectDates && selectDates.timeFrom ? selectDates.timeFrom : null}
                                    timeTo={selectDates && selectDates.timeTo ? selectDates.timeTo : null}
                                    productType={productType}
                                    hadDuration={false}
                                    extraOptions={extraOptions}
                                    modeView={isMobile() ? "MODAL" : modeView}
                                    type={type}
                                    closePanelCalendar={closePanelCalendar}
                                    isMobile={isMobile()} />
                            </div>
                            <div className={"modal-body " + (isForceOpenLeft ? " force-open-left " : "") + (isMobile() ? "" : "p-0 wm-992 modal-calendar-responsive")}>
                                <M3Calendar
                                    minDateValue={minDate}
                                    dateFrom={selectDates && selectDates.dateFrom ? selectDates.dateFrom : null}
                                    dateTo={selectDates && selectDates.dateTo ? selectDates.dateTo : null}
                                    timeFrom={selectDates && selectDates.timeFrom ? selectDates.timeFrom : null}
                                    timeTo={selectDates && selectDates.timeTo ? selectDates.timeTo : null}
                                    extraOptions={extraOptions}
                                    validDateRule={validDateRule}
                                    onSelectDate={onSelectDate}
                                    productType={productType} />
                                {productType !== configData.Settings.Products.Structure.IdTipoProdotto &&
                                    <label className="ml-2">{t('Calendar:ClickSingleDay')}</label>
                                }
                            </div>
                        </div>
                    }

                    <a id={"btnCloseModalCalendar_" + idModal} data-bs-dismiss="modal"></a>
                </div>
            </div>
        </>
    );
}
