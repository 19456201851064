import { Profile } from "./components/MyBook/Profile";
import { Orders } from "./components/MyBook/Orders";
import { OrderDetail } from "./components/MyBook/Orders/OrderDetail";
import { GiftCards } from "./components/MyBook/GiftCards/GiftCards";
import { Markup } from "./components/MyBook/Markup/Markup";
import { Rubrica } from "./components/MyBook/Rubrica/Rubrica";
import { Quotations } from "./components/MyBook/Quotations/Quotations";
import { QuotationsTO } from "./components/MyBook/QuotationsTO/QuotationsTO";
import { HotelFavourite } from "./components/MyBook/HotelFavourite/HotelFavourite";

const AppRoutes_MyBookProtected = [
    {
        path: '/mybook/profile',
        element: <Profile />,
        visibleOnlyBook: false
    }, {
        path: '/mybook/giftcards',
        element: <GiftCards />,
        visibleOnlyBook: false
    }, {
        path: '/mybook/orders',
        element: <Orders />,
        visibleOnlyBook: false
    }, {
        path: '/mybook/orders/detail/:orderNumber',
        element: <OrderDetail />,
        visibleOnlyBook: false
    }, {
        path: '/mybook/markup',
        element: <Markup />,
        visibleOnlyBook: false
    }, {
        path: '/mybook/rubrica',
        element: <Rubrica />,
        visibleOnlyBook: false
    }, {
        path: '/mybook/quotations',
        element: <Quotations />,
        visibleOnlyBook: false
    }, {
        path: '/mybook/quotationsTO',
        element: <QuotationsTO />,
        visibleOnlyBook: false
    },
    {
        path: '/mybook/hotelfavourite',
        element: <HotelFavourite />,
        visibleOnlyBook: false
    }
];

export default AppRoutes_MyBookProtected;