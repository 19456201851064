import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import configData from "../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { InputSearchDestinationOnlyRead } from '../../Common/Destination/InputSearchDestinationOnlyRead';
import { InputSearchDestinationWithModalContainer } from '../../Common/Destination/InputSearchDestinationWithModalContainer';
import { InputPaxesCompositionContainer } from '../../Common/Paxes/InputPaxesCompositionContainer';
import { InputMonthCalendarContainer } from '../../Common/MonthPicker/InputMonthCalendarContainer';
import { SelectCitizenship } from '../../Common/SelectCitizenship';
import { getTourDetailLink, getTourListLink, generateClientFlowId, callGetLastItemsSearch, callGetGetProductRules, getTourCarpetForDetail, getTourCarpetId } from '../../../js/ProductService.js';
import { getDateObj } from '../../../js/Utils.js';

export const TourSearchEngine = ({ searchParameters, typeSearch, extraParams, modeView, resetValues, isFake, isTrippie, onSearchTrippie }) => {
    const { t } = useTranslation();

    const cookies = new Cookies();
    const [cultureName, setCultureName] = useState(cookies.get("CultureName"));

    const [isLoading, setIsLoading] = useState(true);

    const [destinations, setDestinations] = useState([]);
    const [tourPointAvailability, setTourPointAvailability] = useState([]);
    const [validDates, setValidDates] = useState([]);
    const [lastSearchItems, setLastSearchItems] = useState([]);
    const [defaultCitizenshipId, setDefaultCitizenshipId] = useState(null);
    const [isStartSearch, setIsStartSearch] = useState(false);
    const [isValidComposition, setIsValidComposition] = useState(true);
    const [isClickDate, setIsClickDate] = useState(false);

    const [selectCitizenship, setSelectCitizenship] = useState(null);
    const [selectDestination, setSelectDestination] = useState(null);
    const [selectDates, setSelectDates] = useState(null);
    const [selectComposition, setSelectComposition] = useState(null);
    const [isValidForm, setIsValidForm] = useState({
        isValidDestination: false,
        isValidDates: false,
        isAllFieldValid: false
    });

    const [textColorClass, setTextColorClass] = useState('text-white');

    // gestisco la visibilità
    const [showDestination, setShowDestination] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showPaxes, setShowPaxes] = useState(false);


    useEffect(() => {
        if (!isFake) {
            const getTourWebDestinations = async () => {
                const response = await callGetTourWebDestionation();
            }
            getTourWebDestinations();

            const getTourPointWithAvailability = async () => {
                const response = await callGetTourPointWithAvailability();
            }
            getTourPointWithAvailability();

            const getLastSearchItems = async () => {
                const response = await callGetLastItemsSearch(11);
                setLastSearchItems(response);
            }
            getLastSearchItems();

            const getRules = async () => {
                const response = await callGetGetProductRules(12);

                if (response) {
                    try {
                        setIsValidComposition(response['ADDTOCART'].allowed);
                    } catch (ex) {
                        console.error(ex);
                    }
                }
            }
            getRules();

        }

        if (modeView === 'MODAL')
            setTextColorClass('text-dark');
        else
            setTextColorClass('text-white');

    }, []);

    useEffect(() => {
        if (resetValues) {
            setShowDestination(false);
            setShowCalendar(false);

            setValuesHowDefault();
        }

    }, [resetValues]);

    useEffect(() => {
        setValuesHowDefault();
    }, [searchParameters]);


    async function callGetTourWebDestionation() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        let cultureCode = cultureName;
        if (!cultureCode) {
            cultureCode = 'it-IT';
            setCultureName(cultureCode);
        }

        setIsLoading(true);

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Tour/GetTourWebDestionation/' + cultureCode, requestOption);
        const response = await fetchedRes.json();

        if (response && response.destinationsParent && response.destinationsParent.length > 0) {
            setDestinations(response.destinationsParent);
        } else {
            setDestinations([]);
        }

        setIsLoading(false);
    }

    async function callGetTourPointWithAvailability() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };

        setIsLoading(true);

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Tour/GetTourPointWithAvailability', requestOption);
        const response = await fetchedRes.json();

        if (response && response.success && response.tourPointAvl && response.tourPointAvl.length > 0) {

            if (extraParams && extraParams.validDateRule) {
                let fltTourPointAvl = [];

                if (fltTourPointAvl) {

                    for (let i = 0; i < response.tourPointAvl.length; i++) {

                        let item = response.tourPointAvl[i];
                        if (extraParams.validDateRule.minDate) {
                            let dates = response.tourPointAvl[i].dates.filter(x => extraParams.validDateRule.minDate.includes(x));
                            item.dates.push(dates);
                        }

                        if (extraParams.validDateRule.maxDate) {
                            let dates = response.tourPointAvl[i].dates.filter(x => extraParams.validDateRule.maxDate.includes(x));
                            item.dates.push(dates);
                        }

                        fltTourPointAvl.push(item);
                    }
                }
                
                setTourPointAvailability(fltTourPointAvl);

            } else {
                setTourPointAvailability(response.tourPointAvl);
            }

           
        } else {
            setTourPointAvailability([]);
        }

        setIsLoading(false);
    }

    function setValuesHowDefault() {
        if (searchParameters) {
            let itemTmp = [];
            searchParameters.destinationWebIds.forEach(function (destination) {
                itemTmp.push({ webDestinationId: parseInt(destination) })
            });

            let updateDestination = { Text: searchParameters.destinationDesc[0], items: itemTmp };

            setSelectDestination(updateDestination);

            let updateCalendar = searchParameters.dates.map(x => getDateObj(x))[0];
            setSelectDates(updateCalendar);

            let updateTravelers = searchParameters.roomsComposition;
            setSelectComposition(updateTravelers);
            checkIsValidForm(updateDestination, updateTravelers, updateCalendar);

            setDefaultCitizenshipId(searchParameters.citizenship);

        } else {
            checkIsValidForm(null, null, null);
        }

        if (extraParams && extraParams.validDateRule && extraParams.validDateRule.minDate) {
            setValidDates([extraParams.validDateRule.minDate]);
            setValidDates([extraParams.validDateRule.maxDate]);
        }

    }

    async function onSearchButton() {
        setIsStartSearch(true);

        try {
            let newSP = {};
            let testRequest = false;
            let onRequest = false;

            if (extraParams && extraParams.testRequest)
                testRequest = true;

            if (extraParams && extraParams.onRequest)
                onRequest = true;

            if (searchParameters) {
                newSP = searchParameters;
                newSP.testRequest = testRequest;
            } else {
                newSP = {
                    destinationWebIds: [],
                    destinationDesc: [],
                    dates: [],
                    roomsComposition: [],
                    citizenship: 380,
                    suppliersToExclude: [],
                    suppliersToInclude: [],
                    refundableOnly: false,
                    availableOnly: false,
                    structureTypes: [],
                    timeout: 0,
                    testRequest: testRequest,
                    templateId: null,
                    agencyMarkup: null,
                    agencyFee: null
                };
            }

            if (typeSearch === 'SRC' || (typeSearch === 'AVL' && searchParameters)) {
                newSP.destinationWebIds = selectDestination.items.map(x => x.webDestinationId);
                newSP.destinationDesc = [selectDestination.Text];

            }
            newSP.roomsComposition = selectComposition;

            let day = selectDates.getDate();
            let month = (selectDates.getMonth() + 1);
            if (day > 1 && month > (new Date().getMonth() + 1))
                day = 1;
            else if (month <= (new Date().getMonth() + 1))
                day = new Date().getDate();

            let dateStr = selectDates.getFullYear() + "-" + month + "-" + day;
            newSP.dates = [dateStr];

            if (!selectDates) {
                return;
            }

            if (selectCitizenship)
                newSP.citizenship = selectCitizenship.isoId;

            if (isTrippie) {
                let clientFlowId = generateClientFlowId();
                let carpetId = await getTourCarpetId(newSP, clientFlowId);

                onSearchTrippie(extraParams.stepIndex, clientFlowId, carpetId, newSP, configData.Settings.Products.Tour.IdTipoProdotto);
                setIsStartSearch(false);
                return;
            }

            if (typeSearch === 'SRC') {
                let clientFlowId = generateClientFlowId();
                let url = await getTourListLink(newSP, clientFlowId);

                window.open(url, '_self');
            }

            if (typeSearch === 'AVL') {
                let clientFlowId = generateClientFlowId();
                let carpetId = await getTourCarpetForDetail(newSP, clientFlowId);

                let url = await getTourDetailLink(newSP, extraParams.tourId, carpetId, clientFlowId, false, null, extraParams.supplier);
                window.open(url, '_self');

                /*let extraParamUrl = {
                    testRequest: testRequest,
                    onRequest: onRequest
                };

                if (searchParameters) {
                    // ricerca sul dettaglio

                    let cId = null;
                    if (extraParams)
                        cId = extraParams.carpetId;

                    let cfId = '';
                    if (!extraParams || !extraParams.clientFlowId)
                        cfId = generateClientFlowId();
                    else
                        cfId = extraParams.clientFlowId;

                    let url = await getTourDetailLink(newSP, extraParams.tourId, cId, cfId, true);
                    window.open(url, '_self');
                }
                else {
                    // ricerca sul dettaglio statico

                    newSP.destinationDesc = extraParams.city;
                    newSP.activityId = extraParams.activityId;

                    let clientFlowId = generateClientFlowId();
                    let url = await getTourDetailLink(newSP, extraParams.tourId, null, clientFlowId, true);
                    window.open(url, '_self');
                }*/
            }
        } catch (ex) {
            console.error(ex);
        }

        setIsStartSearch(false);
    }

    function checkIsValidForm(destinationUpdate, travelersUpdate, calendarUpdate) {
        let isValidFormUpdate = {
            isValidDestination: typeSearch === 'AVL',
            isValidDates: false,
            isAllFieldValid: false
        };

        if (typeSearch !== 'AVL')
            if (destinationUpdate) {
                if (destinationUpdate.items && destinationUpdate.items.length > 0 && destinationUpdate.Text)
                    isValidFormUpdate.isValidDestination = true;
            } else {
                if (selectDestination && selectDestination.Text && selectDestination.items && selectDestination.items.length > 0)
                    isValidFormUpdate.isValidDestination = true;
            }

        if (travelersUpdate)
            isValidFormUpdate.isValidComposition = true;
        else if (selectComposition)
            isValidFormUpdate.isValidComposition = true;

        if (calendarUpdate)
            isValidFormUpdate.isValidDates = true;
        else if (selectDates)
            isValidFormUpdate.isValidDates = true;

        isValidFormUpdate.isAllFieldValid = isValidFormUpdate.isValidDates && isValidFormUpdate.isValidComposition && isValidFormUpdate.isValidDestination;

        setIsValidForm(isValidFormUpdate);
    }

    // funzioni destinazione
    function onSelectSearchDestination(selectDestination) {
        // save valid dates for selected destinations - start
        let tmpValidDates = [];
        for (let i = 0; i < selectDestination.items.length; i++) {
            let wdId = selectDestination.items[i].webDestinationId;
            let tourPointAvl = tourPointAvailability.filter(x => x.webDestinationId === wdId)[0];
            for (let j = 0; j < tourPointAvl.dates.length; j++) {
                tmpValidDates.push(tourPointAvl.dates[j]);
            }
        }

        // distinct
        var validDates = tmpValidDates.filter((value, index, array) => array.indexOf(value) === index);

        // sort
        validDates.sort(function (a, b) {
            if (a < b) { return -1; }
            if (a > b) { return 1; }
            return 0;
        })

        setValidDates(validDates);
        // save valid dates for selected destinations - end

        setSelectDestination(selectDestination);
        checkIsValidForm(selectDestination, selectComposition, selectDates);
    }

    function onOpenPanelDestination() {
        setShowCalendar(false);
        setShowDestination(true);
        setShowPaxes(false);
    }

    // funzioni per la composizione
    function onClickSelectPaxes(selectComposition) {
        setSelectComposition(selectComposition);
        checkIsValidForm(null, selectComposition, null);
    }

    function onOpenPanelPaxes() {
        setShowDestination(false);
        setShowCalendar(false);
        setShowPaxes(true);
    }

    // funzioni per il calendario
    function onOpenPanelCalendar() {
        setShowCalendar(true);
        setShowDestination(false);
        setShowPaxes(false);
    }

    function onClickSelectDates(values) {
        setShowCalendar(false);
        setSelectDates(values);

        setIsClickDate(true);

        checkIsValidForm(null, null, values);
    }

    function getDestinationLabel() {
        if (extraParams && extraParams.productName)
            return { Text: extraParams.productName };

        return selectDestination;
    }

    // funzioni cittadinanza
    function onSelectCitizenship(item) {
        setSelectCitizenship(item);
    }


    return (
        <>
            <div className="row mb-3">
                {
                    <div className="col-12 col-sm-4 my-1">
                        {
                            typeSearch === 'AVL' && <InputSearchDestinationOnlyRead
                                defaultSelectDestination={getDestinationLabel()}
                                onlyView={true} />
                        }
                        {
                            typeSearch !== 'AVL' && <InputSearchDestinationWithModalContainer
                                destinations={destinations}
                                validDestinations={tourPointAvailability}
                                defaultSelectDestination={selectDestination}
                                isLoading={isLoading}
                                productType={configData.Settings.Products.Tour.IdTipoProdotto}
                                lastSearchItems={lastSearchItems}
                                onSelectSearchDestination={onSelectSearchDestination}
                                onOpenPanelDestination={onOpenPanelDestination}
                                closeExternal={showDestination}
                                modeView={modeView} />
                        }
                    </div>
                }
                <div className="col-12 col-sm-4 my-1">
                    <InputMonthCalendarContainer
                        selectMonths={selectDates}
                        extraOptions={{ multiDate: false, validDates: validDates }}
                        onClickSelectDates={onClickSelectDates}
                        onOpenPanelCalendar={onOpenPanelCalendar}
                        closeExternal={showCalendar}
                        modeView={modeView}
                        type="xl" />
                </div>
                <div className="col-12 col-sm-3 my-1">
                    <InputPaxesCompositionContainer
                        searchParameters={searchParameters}
                        productType={configData.Settings.Products.Tour.IdTipoProdotto}
                        callOnSelectComposition={onClickSelectPaxes}
                        onOpenPanelPaxes={onOpenPanelPaxes}
                        closeExternal={showPaxes}
                        modeView={modeView}
                        type="xl" />
                </div>
                <div className="col-12 col-sm-1 my-1 p-0">
                    <button
                        className="btn tp-btn-search w-100 py-3"
                        onClick={_ => onSearchButton()}
                        disabled={!isValidForm.isAllFieldValid && !isStartSearch}
                    >
                        {isStartSearch ? <><div className="spinner-border spinner-border-sm text-primary" role="status"><span className="sr-only"></span></div></> : <>{t('Button:Search')}</>}
                    </button>
                </div>

                <div className="col-12 col-sm-9 my-1"></div>
                <div className="col-12 col-sm-3 my-1 d-inline text-right">
                    <SelectCitizenship
                        defaultCitizenshipId={defaultCitizenshipId}
                        onSelectCitizenship={onSelectCitizenship}
                        modeView={modeView} />
                </div>

                {
                    !isValidComposition && <div className={"col-12 col-sm-12 my-1 " + textColorClass}>
                        {t('SearchEngine:WarningCartComposition')}
                    </div>
                }
            </div>
        </>
    );
}