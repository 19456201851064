import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../Auth/AuthProvider';
import { M3Icon } from '../../../Common/M3Icon';
import configData from "../../../../appsettings.json";

export const HeaderCart = ({ item, modal }) => {
    const [totalCartItem, setTotalCartItem] = useState(0);
    const [redirectUrl, setRedirectUrl] = useState('cart');

    let { getQuotationInfoSession } = useAuth();


    useEffect(() => {
        const getCartSummaryInfo = async () => {
            callGetCartSummaryInfo();
        }
        getCartSummaryInfo();
    }, [item]);

    useEffect(() => {
        if (getQuotationInfoSession && getQuotationInfoSession.quotationId) {
            setRedirectUrl("cart-quotation");
        } else {
            setRedirectUrl("cart");
        }
    }, [getQuotationInfoSession]);


    async function callGetCartSummaryInfo() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}ShoppingCart/GetCartSummaryInfo`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setTotalCartItem(response.totalItems);
        } else {
            setTotalCartItem(0);
        }
    }

    function getClassName(className) {
        if (!className)
            return "";

        try {
            return className.split("#")[1];
        } catch (ex) { }

        return "";
    }


    return (
        <>
            <a className={getClassName(item.className) + ' d-none d-sm-flex'} href={redirectUrl}>
                <M3Icon iconName="Cart" />
                {totalCartItem > 0 && <span className="h065 custom-total-cart-items">{totalCartItem}</span>}
            </a>
            <a className={getClassName(item.className) + ' d-flex d-sm-none p-0'}  href={redirectUrl}>
                <M3Icon iconName="Cart" />
                {totalCartItem > 0 && <span className="h065 custom-total-cart-items">{totalCartItem}</span>}
            </a>
        </>
    );
}
