import { WebContent } from "./components/WebContent/WebContent";
import { BlogList } from "./components/Blog/BlogList";
import { BlogDetail } from "./components/Blog/BlogDetail";

export function GenerateDynamicRoutes(items) {
    let appRouteDynamic = [...AppRoutes_Dynamic];

    for (let i = 0; i < items.length; i++) {
        let item = items[i];

        if (item.componentRenderType === 1) {
            let myUrlRewriting = {
                path: item.path,
                websiteId: item.websiteId,
                websiteInfo: item.websiteInfo,
                element: <WebContent extraParams={item.params} />
            };

            appRouteDynamic.push(myUrlRewriting);
        }
        else if (item.componentRenderType === 2) {
            let myUrlRewriting = {
                path: item.path,
                websiteId: item.websiteId,
                element: <BlogDetail extraParams={item.params} />
            };

            appRouteDynamic.push(myUrlRewriting);
        }
    }

    //appRouteDynamic.push({
    //    path: '*',
    //    element: <Page404 />
    //});

    return appRouteDynamic;
}

const AppRoutes_Dynamic = [
    {
        path: 'webcontent/:pageCode',
        element: <WebContent />,
        visibleOnlyBook: false
    },
    {
        path: 'blog',
        element: <BlogList />,
        visibleOnlyBook: false
    },
    {
        path: 'blog/:articleCode',
        element: <BlogDetail />,
        visibleOnlyBook: false
    }
];

export default AppRoutes_Dynamic;
