import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../Common/Loading';
import { M3Icon } from '../Common/M3Icon';
import { Passenger } from './Passenger';
import { PriceBar } from './PriceBar';
import { CancelReservationModal } from './OperationModals/CancelReservationModal';
import { OptionConfirmModal } from './OperationModals/OptionConfirmModal';
import { PayWithCreditCardModal } from './OperationModals/PayWithCreditCardModal';
import { OperationButtons } from './OperationButtons';
import { QuotationOperationButtons } from '../QuotationTO/components/QuotationOperationButtons';
import { formatDateTime, getStatusClass } from '../../js/Utils.js';
import configData from "../../appsettings.json";
import { imageOnError, imageOnLoad } from '../../js/CDNUtility';
import { renderWebsite } from '../../js/Constant';
import { Error } from '../Common/Error';
import { DMCExtras } from '../Product/Common/DMC/DMCExtras';
import { ServiceNote } from './ServiceNote';
import { UpdateTourData } from '../MyBook/ProductData/components/UpdateTourData';

export const TourDetail = ({ id, type, showNetPrices, showCost, customFunctions, forceRender, saveProductData }) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [tourDetail, setTourDetail] = useState([]);

    const [modalId, setModalId] = useState("");
    const [resetValue, setResetValue] = useState(1);
    const [isEnabledUpdateStaticData, setIsEnabledUpdateStaticData] = useState(false);

    useEffect(() => {
        setResetValue(1);
        loadItem();

        setModalId("modalUpdateStaticDAta_" + id)
    }, []);

    useEffect(() => {
        if (forceRender && forceRender > 0) {
            loadItem();
            setResetValue(1);
        }
    }, [forceRender]);

    function loadItem() {
        setIsLoading(true);

        switch (type) {
            case 'MyBook':
            case 'QuotationTO_MyBook':
                const getTourDetail = async (inputData) => {
                    const response = callGetTourDetail(inputData);
                }
                getTourDetail({ PNR: id, ProductType: configData.Settings.Products.Tour.IdTipoProdotto, Options: { WebsiteBaseUrl: configData.Settings.OldWebSite_BaseUrl } });
                break;

            case 'QuotationTO':
                const getQuotationItem = async (inputData) => {
                    const response = callGetQuotationItem(inputData);
                }
                getQuotationItem(id);
                break;

            default:
                setIsLoading(false);
        }
    }

    async function callGetTourDetail(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetReservationDetail', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (type === 'QuotationTO_MyBook' || type === 'MyBook')
                customFunctions.onCallSaveProductInfo(response, type, id);

            setTourDetail(response);
            setIsError(false);

        } else {
            setErrorMsg(t("MyBook:PracticeNotAvailableMsg").replace("##ID##", id));
            setIsError(true);
        }

        setIsLoading(false);
    }

    async function callGetQuotationItem(id) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationitem/${id}`, requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            customFunctions.onCallSaveProductInfo(response, type, id);

            setTourDetail(response);
            setIsError(false);

            if (response.allowedOperations && response.allowedOperations.EnabledUpdateStaticData)
                setIsEnabledUpdateStaticData(response.allowedOperations.EnabledUpdateStaticData.enabled && (type !== 'QuotationTOEndUser' && type !== 'QuotationEndUser'));
        } else {
            setErrorMsg(t("MyBook:QuotaionProductNotLoadMsg").replace("##ID##", id));
            setIsError(true);
        }

        setIsLoading(false);
    }

    function onClickOpenUpdateStaticData() {
        try {
            setResetValue((resetValue + 1));
        } catch (ex) { }
    }

    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : isError ? <Error textMsg={errorMsg} />
                    : (
                        <>
                            <div id={'aDetail_' + id}></div>

                            {/*Vertical Left Icon Bar*/}
                            {type === 'MyBook'
                                ? (
                                    <div className="col-12 col-sm-10 col-md-6 col-lg-1 mb-4">
                                        <div className="timeline timeline-one timeline-mybook h-100">
                                            <div className="timeline-item h-100">
                                                <span className="icon icon-success"><i className="ph-buildings-bold h2"></i></span>
                                            </div>
                                        </div>
                                    </div>)
                                : (<></>)
                            }

                            {/*Hotel*/}
                            <div className={type === 'MyBook' ? "col-12 col-sm-10 col-md-6 col-lg-11 mb-4" : ""}>
                                <div className={type === 'MyBook' ? "card border-light animate-up-5" : "card border-light mb-4 animate-up-5"}>
                                    <div className="row no-gutters">
                                        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                                            {/*Testata Hotel*/}
                                            <div className="row no-gutters ">
                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                    <img src={tourDetail.thumbUrl ? tourDetail.thumbUrl : imageOnLoad(configData.Settings.Products.Tour.IdTipoProdotto)} alt="Thumb" className="card-img p-2 rounded-xl" onError={e => imageOnError(e, configData.Settings.Products.Tour.IdTipoProdotto)} />
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                    <div className="myBook-card-body">
                                                        <div className="mybook-hotel">
                                                            <h5>
                                                                {
                                                                    (type === 'QuotationTO' || type === 'QuotationTO_MyBook')
                                                                    && (customFunctions && !customFunctions.isAgencyMode)
                                                                    && (tourDetail.quotationDetail && tourDetail.priceBar.isManualLoading)
                                                                    && <span className="material-icons-outlined mx-1" title="Prodotto manuale" style={{ fontSize: '16px' }}>front_hand</span>
                                                                }
                                                                {
                                                                    type === 'QuotationTO' && <span className="badge bg-gray-300 text-dark mr-2">{id}</span>
                                                                }
                                                                {tourDetail.name} <a className="ico info" style={{ display: 'none' }}><i className="fas fa-info-circle"></i></a></h5>
                                                            <p className="my-2">
                                                                <span><i className="ph-map-pin-fill"></i>{tourDetail.address}</span>
                                                            </p>

                                                            {tourDetail.optionExpireDate && tourDetail.priceBar.status === "OPTION"
                                                                ? (
                                                                    <>
                                                                        <div className="mybook-hotel mb-2">
                                                                            <span className="penalty">
                                                                                {t("MyBook:ExpirationOption")}: {formatDateTime(tourDetail.optionExpireDate, { twoDigits: true })}
                                                                            </span>
                                                                        </div>
                                                                    </>
                                                                )
                                                                : (<></>)
                                                            }
                                                        </div>
                                                        <div className="float-left">
                                                            {type === 'MyBook' && <OperationButtons pnr={id} productType={configData.Settings.Products.Tour.IdTipoProdotto} status={tourDetail.priceBar.status} allowedOps={tourDetail.allowedOperations} />}
                                                            {
                                                                type === 'QuotationTO' && <QuotationOperationButtons
                                                                    id={id}
                                                                    callOnChangeStatusQuotationItem={customFunctions.callOnChangeStatusQuotationItem}
                                                                    onCallRequoteQuotationItem={customFunctions.onCallRequoteQuotationItem}
                                                                    onCallUpdateQuotationItem={customFunctions.onCallUpdateQuotationItem}
                                                                    isCartQuotation={customFunctions.isCartQuotation}
                                                                    quotationMode={customFunctions.quotationMode}
                                                                    reservationDetail={tourDetail.reservationDetail}
                                                                    quotationDetail={tourDetail.quotationDetail} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                tourDetail.notesService && tourDetail.notesService.length > 0 && <div className="mybook-hotel-room">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 right">
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 right">
                                                            <h2 className="accordion-header " id="heading1c">
                                                                <a href="#" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={'#pannelRoom' + '_' + id} aria-expanded="true" aria-controls={'pannelRoom' + '_' + id}>
                                                                    <span className="icon-title h6 mb-0 font-weight-bold text-right w-100">Note Tour</span>
                                                                </a>
                                                            </h2>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                            <div id={'pannelRoom' + '_' + id} className="accordion-collapse collapse" role="region" aria-labelledby="heading1c">
                                                                <div className="accordion-body">
                                                                    {
                                                                        <ServiceNote notes={tourDetail.notesService} noteTypeToShow={[12]} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {/*Rooms*/}
                                            {
                                                tourDetail.rooms && tourDetail.rooms.map(room =>
                                                    <div key={room.sequence} className="mybook-hotel-room">
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                                                <h6>
                                                                    {t("Product:Structure:Room")} &nbsp;{room.sequence}:&nbsp;&nbsp;
                                                                    {room.adults} <M3Icon iconName="PaxAdl" />
                                                                    {room.children} <M3Icon iconName="PaxChd" />

                                                                    {
                                                                        room.children && room.children > 0 && room.childrenAges && room.childrenAges.length > 0 && <>
                                                                            ({t('SearchEngine:Age')}:
                                                                            {
                                                                                room.childrenAges.map((age, index) => {
                                                                                    return age + (index === (room.childrenAges.length - 1) ? "" : ",");
                                                                                })
                                                                            }
                                                                            )
                                                                        </>
                                                                    }
                                                                    {tourDetail.rooms.length === 1
                                                                        ? (<></>)
                                                                        : (<>&nbsp;&nbsp; <span className={`status ${getStatusClass(room.priceBar.status)}`}>{t(`statiPrenotazione:` + room.priceBar.status)}</span></>)
                                                                    }
                                                                </h6>
                                                            </div>

                                                            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                                                {
                                                                    room.cancelPolicy && <span className={room.cancelPolicy.isFree ? "penalty free" : "penalty"}>
                                                                        <i className="ph-money-light"></i>
                                                                        {room.cancelPolicy.isFree ? t("CancelPolicy:FreeCancellation") + formatDateTime(room.cancelPolicy.expirationDate, { twoDigits: true }) + " " + renderWebsite.cancelPolicy.time : "Cancellazione con penale"}
                                                                    </span>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <span> <i className="ph-bed-light"></i><label>{t("Product:Structure:RoomType")}:</label> {room.roomName}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                {
                                                                    (type === 'MyBook' || type === 'QuotationTO_MyBook')
                                                                        ? <span><i className="ph-user-fill"></i><label>Ref.Pax:</label> {room.refPax}</span>
                                                                        : <></>
                                                                }
                                                            </div>

                                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 right">
                                                                <h2 className="accordion-header " id="heading1c">
                                                                    <a href="#" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={'#pannelRoom' + '_' + id + '_' + room.sequence} aria-expanded="true" aria-controls={'pannelRoom' + '_' + id + '_' + room.sequence}>
                                                                        <span className="icon-title h6 mb-0 font-weight-bold text-right w-100">Note - &nbsp; {t("MyBook:PassengerList")}&nbsp; - Resort fee</span>
                                                                    </a>
                                                                </h2>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <div id={'pannelRoom' + '_' + id + '_' + room.sequence} className="accordion-collapse collapse" role="region" aria-labelledby="heading1c">
                                                                    <div className="accordion-body">
                                                                        {/*Notes*/}
                                                                        <div className="pt-3">
                                                                            Note:
                                                                            {room.publicNotes && room.publicNotes.map((note, index) =>
                                                                                <p key={index} dangerouslySetInnerHTML={{ __html: note }}></p>
                                                                            )}
                                                                        </div>

                                                                        {/*Passengers*/}
                                                                        {
                                                                            (type === 'MyBook' || type === 'QuotationTO_MyBook')
                                                                                ? <div className="pt-3">
                                                                                    <div>{t("MyBook:Passengers")}:</div>
                                                                                    {room.passengers && room.passengers.map(passenger =>
                                                                                        <Passenger key={passenger.index} passenger={passenger} />
                                                                                    )}
                                                                                </div>
                                                                                : <></>
                                                                        }

                                                                        {/*Extras*/}
                                                                        {
                                                                            room.extras && room.extras.length > 0 && <div className="row pt-3">
                                                                                <div className="mb-2"><data m3lab="Product.Activity.AddictionalOptions">{t('Product:Activity:AddictionalOptions')}:</data></div>
                                                                                {
                                                                                    room.extras.map((extra) => {
                                                                                        return <DMCExtras extra={extra} readOnly={true} />;
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        }


                                                                        {/*ResertFees*/}
                                                                        <div className="pt-3">
                                                                            Resort Fee:
                                                                            {room.resortFees && room.resortFees.map((resortFee, index) =>
                                                                                <p key={index}>{resortFee}</p>
                                                                            )}
                                                                        </div>

                                                                        {/*Notes*/}
                                                                        <div className="pt-3">
                                                                            Note:
                                                                            {room.notes && room.notes.map((note, index) =>
                                                                                <p key={index} dangerouslySetInnerHTML={{ __html: note }}></p>
                                                                            )}
                                                                        </div>

                                                                        {
                                                                            <ServiceNote notes={room.notesService} noteTypeToShow={[13]} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        {/*Price Bar*/}
                                        <PriceBar
                                            oPriceBar={tourDetail.priceBar}
                                            showNetPrices={showNetPrices}
                                            showCost={showCost}
                                            reservationDetail={tourDetail.reservationDetail}
                                            quotationDetail={tourDetail.quotationDetail}
                                            quotationMode={customFunctions.quotationMode}
                                            customView={{ notShowPaymentAvailable: (type === 'QuotationTO' || type === 'QuotationTO_MyBook') }}
                                            isEnabledUpdateStaticData={isEnabledUpdateStaticData}
                                            onClickOpenUpdateStaticData={onClickOpenUpdateStaticData} />
                                    </div>
                                </div>
                            </div>

                            {/*Modals*/}
                            {type === 'MyBook' && <CancelReservationModal pnr={id} productType={configData.Settings.Products.Tour.IdTipoProdotto} />}
                            {type === 'MyBook' && <OptionConfirmModal pnr={id} productType={configData.Settings.Products.Tour.IdTipoProdotto} productName={tourDetail.name} />}
                            {type === 'MyBook' && tourDetail.priceBar.paymentData !== null && <PayWithCreditCardModal pnr={id} productType={configData.Settings.Products.Tour.IdTipoProdotto} paymentData={tourDetail.priceBar.paymentData} />}
                            {isEnabledUpdateStaticData && <UpdateTourData
                                modalId={modalId}
                                quotationItemId={id}
                                saveProductData={saveProductData}
                                resetValue={resetValue} />}
                        </>
                    )
            }
        </>
    );
}
