import React, { useState, useEffect } from 'react';
import { M3Icon } from "../../../Common/M3Icon";

export const CarRentalSummary = ({ carRental, isAlternative }) => {
    const [fontSize, setFontSize] = useState('');

    useEffect(() => {
        if (isAlternative)
            setFontSize('h075');
    }, [carRental]);


    return (
        <>
            <div>
                <data m3ico="RentCar mr-2 icon-15">
                    <M3Icon iconName="RentCar" externalClass="mr-2 icon-15 text-custom" hasLabel={false} />
                </data>

                <span className={fontSize}>{carRental.pickUpInfo.officeName}</span>
            </div>
        </>
    );
}
