import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { AddServiceNote } from '../../../ManualProduct/components/AddServiceNote';
import { AddNoteXml } from '../../../ManualProduct/components/AddNoteXml';
import { ProductDataImages } from './common/ProductDataImages';
import { Loading } from '../../../Common/Loading';

export const UpdateCruiseData = ({ modalId, resetValue, quotationItemId, wsBookingId, saveProductData }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    const [productData, setProductData] = useState(false);
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setVisible(resetValue > 1);
        setProductData(null);
        setIsLoading(true);
        setIsError(false);

        let inputData = {
            quotationItemId: quotationItemId,
            wsBookingId: wsBookingId,
            cultureName: cultureName
        }

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchData = async () => {
            const response = await fetch(`${configData.Settings.CommonApi_BaseUrl}quotationto/getUpdateStaticData`, requestOption)
            const data = await response.json();

            if (data.success) {
                setProductData(data.cruiseData);
                setIsError(false);
            }

            setIsLoading(false);
        }

        fetchData()
            .catch(console.error);
    }, [resetValue]);

    function onSave() {
        let inputData = {
            cruiseData: productData,
            quotationItemId: quotationItemId,
            wsBookingId: wsBookingId
        };

        setVisible(false);
        saveProductData(inputData);
    }

    function onBlurUpdatePublicNote(event) {
        let pd = { ...productData };
        pd.internalNote = event.target.value;
        setProductData(pd);
    }

    function onBlurEventDescription(event, propertyName) {
        let pd = { ...productData };
        pd.serviceNoteDescription = event;
        setProductData(pd);
    }

    function onBlurEventServiceNote(roomIndex, propertyName, event) {
        let pd = { ...productData };
        pd.serviceNote = event;
        setProductData(pd);
    }

    function onBlurEventRoomNote(event) {
        let pd = { ...productData };
        pd.roomsNoteXML = event;
        setProductData(pd);
    }

    function onUpdateDataImages(img) {
        let pd = { ...productData };
        pd.images = img;
        setProductData(pd);
    }

    function onUpdateDataVideos(img) {
        let pd = { ...productData };
        pd.videos = img;
        setProductData(pd);
    }

    function onUpdateDataDocuments(img) {
        let pd = { ...productData };
        pd.documents = img;
        setProductData(pd);
    }

    const footerContent = (
        <div className='modal-footer'>
            <button type="button" className="btn bt-sm tp-btn-delete" onClick={_ => setVisible(false)}>{t('Button:Close')}</button>
            <button type="button" className="btn bt-sm tp-btn-add" data-bs-dismiss="modal" onClick={_ => onSave()}>{t('Button:Save')}</button>
        </div>
    );

    return (
        <>
            <Dialog
                header="Scheda"
                footer={footerContent}
                visible={visible}
                style={{ width: '75vw' }}
                onHide={() => { if (!visible) return; setVisible(false); }}>
                <p className="m-0">
                    {
                        isLoading && <Loading textMsg="Caricamento in corso..." />
                    }
                    {
                        productData && <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="form-floating col-6">
                                        <InputText id="updShipName" type="text" value={productData.shipName} className="form-control" onChange={(e) => setProductData({ ...productData, shipName: e.target.value })} />
                                        <label htmlFor="updShipName" class="active">Nome Nave</label>
                                    </div>
                                    <div className="form-floating col-6">
                                        <InputText id="updShipThumb" type="text" value={productData.shipThumb} className="form-control" onChange={(e) => setProductData({ ...productData, shipThumb: e.target.value })} />
                                        <label htmlFor="updShipThumb" class="active">Thumb Nave</label>
                                    </div>
                                    <div className="form-floating col-6">
                                        <InputText id="updcabinName" type="text" value={productData.cabinName} className="form-control" onChange={(e) => setProductData({ ...productData, cabinName: e.target.value })} />
                                        <label htmlFor="updcabinName" class="active">Nome Cabina</label>
                                    </div>
                                    <div className="form-floating col-6">
                                        <InputText id="updcabinExperienceName" type="text" value={productData.cabinExperienceName} className="form-control" onChange={(e) => setProductData({ ...productData, cabinExperienceName: e.target.value })} />
                                        <label htmlFor="updcabinExperienceName" class="active">Nome Experienza</label>
                                    </div>
                                    <div className="form-floating col-6">
                                        <InputText id="upddescriptions" type="text" value={productData.descriptions} className="form-control" onChange={(e) => setProductData({ ...productData, descriptions: e.target.value })} />
                                        <label htmlFor="updcabindescriptions" class="active">Descrizione cabina</label>
                                    </div>

                                    {
                                        productData && <div className="col-12">
                                            <TabView>
                                                <TabPanel header="Documenti">
                                                    <ProductDataImages
                                                        images={productData.documents}
                                                        onUpdateData={onUpdateDataDocuments} />
                                                </TabPanel>
                                                <TabPanel header="Immagini">
                                                    <ProductDataImages
                                                        images={productData.images}
                                                        onUpdateData={onUpdateDataImages} />
                                                </TabPanel>
                                                <TabPanel header="Note Servizio">
                                                    <div className="pb-2">
                                                        <AddServiceNote
                                                            serviceNote={productData.description}
                                                            roomIndex={-1}
                                                            noteTypeToAdd={12}
                                                            onBlurEvent={onBlurEventDescription} />
                                                    </div>

                                                    <div className="pb-2">
                                                        <AddServiceNote
                                                            serviceNote={productData.serviceNote}
                                                            roomIndex={0}
                                                            onBlurEvent={onBlurEventServiceNote} />
                                                    </div>

                                                    <div class="mt-2 pt-2 border-light border-top">
                                                        <div className="row">
                                                            <div className="col-12 form-floating ">
                                                                <textarea className="form-control"
                                                                    placeholder="Note Interne"
                                                                    value={productData.internalNote}
                                                                    onChange={(e) => { onBlurUpdatePublicNote(e); }}
                                                                    id="floatingTextareaIntNote"
                                                                    style={{ height: '100px' }} >
                                                                </textarea>
                                                                <label htmlFor="floatingTextareaIntNote">Note Interne</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel header="Note XML Fornitore">
                                                    <AddNoteXml
                                                        roomNotes={productData.roomsNoteXML}
                                                        onBlurEvent={onBlurEventRoomNote} />
                                                </TabPanel>
                                                <TabPanel header="Video">
                                                    <ProductDataImages
                                                        images={productData.videos}
                                                        onUpdateData={onUpdateDataVideos} />
                                                </TabPanel>
                                            </TabView>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </p>
            </Dialog>
        </>
    );
}
