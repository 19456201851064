import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import configData from "../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { StructureSearchEngine } from "../../Product/SearchEngine/StructureSearchEngine";
import { formatDateTime, getTotalPaxesFromSearchParameters } from '../../../js/Utils';
import { generateClientFlowId, getStructureCarpetId } from '../../../js/ProductService.js';

export const StructureSearchEngineTrippie = ({ searchParameters, typeSearch, extraParams, onStartSearch }) => {
    const { t } = useTranslation();

    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [paxLabel, setPaxLabel] = useState(null);

    useEffect(() => {

        if (searchParameters && searchParameters.roomsComposition) {
            const paxNorm = getTotalPaxesFromSearchParameters(searchParameters.roomsComposition, configData.Settings.Products.Structure.IdTipoProdotto, false);

            let label = "";
            label += paxNorm.totalAdtPC + " ";
            if (paxNorm.totalAdtPC === 1) {
                label += t('SearchEngine:Adult');
            } else {
                label += t('SearchEngine:Adults');
            }

            label += " + " + paxNorm.totalChdPC + " ";
            if (paxNorm.totalChdPC === 1) {
                label += t('SearchEngine:Child');
            } else {
                label += t('SearchEngine:Children');
            }

            label += " + " + paxNorm.totalRM + " ";

            if (paxNorm.totalRM === 1) {
                label += t('SearchEngine:Room');
            } else {
                label += t('SearchEngine:Rooms');
            }

            setPaxLabel(label);
        }


    }, [searchParameters])

    async function onStartQuote() {
        let clientFlowId = generateClientFlowId();
        let carpetId = await getStructureCarpetId(searchParameters, clientFlowId);

        onStartSearch(extraParams.stepIndex, clientFlowId, carpetId, searchParameters, configData.Settings.Products.Structure.IdTipoProdotto);
    }

    return (
        <>
            <div className="row">
                <div className="col-8 mb-2">
                    {
                        searchParameters && <div className="me-2">{searchParameters.destinationDesc}.</div>
                    }
                    {
                        searchParameters && <div className="me-2">{t('MyBook:From')} {formatDateTime(searchParameters.checkIn, cultureName, { monthLabel: true, noYear: true })} {t('MyBook:To')} {formatDateTime(searchParameters.checkOut, cultureName, { monthLabel: true, noYear: true })}, </div>
                    }
                    {
                        paxLabel && <div className="me-2">{paxLabel}</div>
                    }
                </div>
                <div className="col-4 mb-2 text-end">
                    <span className="cursor-pointer btn tp-btn-search" onClick={onStartQuote}>Quota</span>
                </div>
                <div className="col-6">
                    <div className="h6 fw-bold d-none">Hotel suggeriti</div>
                </div>
                <div className="col-6 h6 text-end text-decoration-none">
                    <span className="cursor-pointer btn tp-btn-search" data-bs-toggle="modal" data-bs-target="#EngineModifyHotel">Modifica Ricerca</span>
                </div>
            </div>

            <div className="modal tp-search-input fade" id="EngineModifyHotel" tabIndex="-1" aria-labelledby="EngineModifyHotel" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen-xxl-down">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <div className="h4 text-center w-100 mt-2">
                                {t('SearchEngine:UpdateSearch')} - {t('Product:Structure:Item')}
                            </div>
                            <button type="button" className="btn-close mt-2 pr-4 tp-btn-trippie" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body bg-gray-300">
                            <StructureSearchEngine
                                searchParameters={searchParameters}
                                typeSearch={typeSearch}
                                extraParams={extraParams}
                                resetValues={false}
                                isTrippie={true}
                                onSearchTrippie={onStartSearch}
                                modeView="MODAL" />
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
}