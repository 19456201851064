import React, { useState, useEffect } from 'react';
import configData from "../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { InputSearchDestinationContainer } from '../../Common/Destination/InputSearchDestinationContainer';
import { InputCalendarContainer } from '../../Common/Calendar/InputCalendarContainer';

export const TrainChangeSearchItinerary = ({ item, getStationName, trainType, operationPostResponse, code, searchChangeItinerary }) => {
    const { t } = useTranslation();

    useEffect(() => {
        let departureSegment = item.segments[0]
        let arrivalSegment = item.segments[item.segments.length - 1]

        let updateDepartureStation = {
            Text: getStationName(departureSegment.departure.stationId),
            Id: departureSegment.departure.stationId,
            Type: 'Station'
        };
        let updateArrivalStation = {
            Text: getStationName(arrivalSegment.arrival.stationId),
            Id: arrivalSegment.arrival.stationId,
            Type: 'Station'
        }

        let updateCalendar = {
            dateFrom: departureSegment.departure.date,
            dateTo: null
        };

        let updateTimes = {
            timeFrom: departureSegment.departure.time,
            timeTo: null
        };

        setSelectDestinations([{ dep: updateDepartureStation, arr: updateArrivalStation }]);
        setSelectDates(updateCalendar);
        setSelectTimes(updateTimes);
    }, [item])

    // Search Engine
    const modeView = "MODAL";
    const [stylePanelSearch, setStylePanelSearch] = useState({});
    const [extraOptionsCalendarOutbound, setExtraOptionsCalendarOutbound] = useState({
        multiDate: false,
        numberOfMonths: 1,
        showTime: true,
        label: 'SearchEngine:Date',
        labelPlaceHolder: 'SearchEngine:Date'
    });

    const [validDateRule, setValidDateRule] = useState({});
    const [selectDestinations, setSelectDestinations] = useState([{}]);
    const [selectDates, setSelectDates] = useState(null);
    const [selectTimes, setSelectTimes] = useState(null);
    const [showDestination, setShowDestination] = useState([{ dep: false, arr: false }]);
    const [showCalendarOutbound, setShowCalendarOutbound] = useState(false);
    // funzioni destinazione
    function onSelectSearchDestination(newSelectDestination, externalId) {
        let externalIdParts = externalId.split('_');
        let indexToUpdate = parseInt(externalIdParts[1]);
        let updateSelectDestinations = [...selectDestinations];
        let isDeparture = externalIdParts[0].includes('departure');

        if (isDeparture) {
            updateSelectDestinations[indexToUpdate].dep = newSelectDestination;
        }
        else {
            updateSelectDestinations[indexToUpdate].arr = newSelectDestination;
        }

        setSelectDestinations(updateSelectDestinations);
    }

    function onOpenPanelDestination(externalId) {
        let externalIdParts = externalId.split('_');
        let isDeparture = externalIdParts[0].includes('departure');
        let indexToUpdate = parseInt(externalIdParts[1]);

        let showDestinationMap = getShowDestinationLegs(false);
        if (isDeparture) {
            showDestinationMap[indexToUpdate].dep = true;
        }
        else {
            showDestinationMap[indexToUpdate].arr = true;
        }

        setShowDestination(showDestinationMap);

        setShowCalendarOutbound(false);
        setStylePanelSearch(null);
    }

    function getShowDestinationLegs(value) {
        let result = [];

        if (showDestination && showDestination.length > 0) {
            for (let i = 0; i < showDestination.length; i++) {
                result.push({ dep: value, arr: value });
            }
        }

        return result;
    }

    // funzioni per il calendario
    function onOpenPanelCalendarOutbound() {
        let showDestinationMap = getShowDestinationLegs(false);
        setShowDestination(showDestinationMap);

        setShowCalendarOutbound(true);
        setStylePanelSearch({ "minHeight": '450px' });
    }

    function onClickSelectDatesOutbound(values) {
        setShowCalendarOutbound(false);
        setStylePanelSearch(null);

        let updSelectDates = {
            ...selectDates,
            dateFrom: values.dateFrom
        };

        let updValidDateRule = {
            ...validDateRule
        };
        updValidDateRule.minDate = values.dateFrom;

        setSelectDates(updSelectDates);
        setValidDateRule(updValidDateRule);

        setSelectTimes({
            ...selectTimes,
            timeFrom: values.timeFrom
        })
    }

    function onSearch() {
        searchChangeItinerary(operationPostResponse, code, trainType, selectDestinations, selectDates, selectTimes);
    }

    return (
        <>
            <div className="col-12 p-0">
                <div className="row mb-3 mt-3 " style={stylePanelSearch}>
                    <div className="col-12 col-lg-3 my-1">
                        <InputSearchDestinationContainer
                            externalId="departureSegment_0"
                            defaultSelectDestination={selectDestinations[0].dep}
                            productType={configData.Settings.Products.Train.IdTipoProdotto}
                            onSelectSearchDestination={onSelectSearchDestination}
                            onOpenPanelDestination={onOpenPanelDestination}
                            extraOptions={{ label: 'SearchEngine:DepartureStation', iconName: 'TrainStation', selectGroupTitle: true }}
                            closeExternal={showDestination[0].dep}
                            modeView={modeView} />
                    </div>
                    <div className="col-12 col-lg-3 my-1">
                        <InputSearchDestinationContainer
                            externalId="arrivalSegment_0"
                            defaultSelectDestination={selectDestinations[0].arr}
                            productType={configData.Settings.Products.Train.IdTipoProdotto}
                            onSelectSearchDestination={onSelectSearchDestination}
                            onOpenPanelDestination={onOpenPanelDestination}
                            extraOptions={{ label: 'SearchEngine:ArrivalStation', iconName: 'TrainStation', selectGroupTitle: true }}
                            closeExternal={showDestination[0].arr}
                            modeView={modeView} />
                    </div>
                    <div className="col-12 col-lg-4 mb-2">
                        <InputCalendarContainer
                            dateFrom={selectDates ? selectDates.dateFrom : null}
                            timeFrom={selectTimes ? selectTimes.timeFrom : null}
                            extraOptions={extraOptionsCalendarOutbound}
                            productType={configData.Settings.Products.Train.IdTipoProdotto}
                            onClickSelectDates={onClickSelectDatesOutbound}
                            onOpenPanelCalendar={onOpenPanelCalendarOutbound}
                            closeExternal={showCalendarOutbound}
                            modeView={modeView}
                            type="m" />
                    </div>
                    <div className="col-2">
                        <button onClick={_ => onSearch()} className="btn btn-small tp-btn-search">{t('Button:Search')}</button>
                    </div>

                </div>
            </div>
        </>
    );
};
