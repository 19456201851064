import { useEffect, useRef, useState } from 'react'
import { GeoJSON, useMapEvents } from 'react-leaflet';
import L from "leaflet";
import icons from './leaflet-marker-color';
import { formatPrice } from '../../js/Utils';

const GeoJSONLayer = ({ data, geoJsonRef, markerPopup, markerFilter, cultureCode, handleClickPointOnMap }) => {
    const map = useMapEvents({
        layeradd(e) {
            //console.log("Added Layer:", e.target);
        },
        layerremove(e) {
            //console.log("Removed layer:", e.target);
        },
        click(e) {
        
        },
        dragend(e) {
        }
    });


    const zoomToFeature = (e) => {
        if (e.target.getLatLng) {
            const latLngs = [e.target.getLatLng()];
            const markerBounds = L.latLngBounds(latLngs);
            map.fitBounds(markerBounds);
        } else if (e.target.getBounds) {
            const markerBounds = e.target.getBounds();
            map.fitBounds(markerBounds);
        }

        handleClickPointOnMap(e);
    }

    const blueIcon = icons.blue.origin;
    const orangeIcon = icons.orange.origin;
    const redIcon = icons.red.origin;
    const greenIcon = icons.green.origin;

    const markerOptions = { radius: 5, weight: 1, opacity: 1, fillOpacity: 0.8, }

    const markerStyles = function (feature) {
        return { color: '#4575b4' };
        //switch (feature.properties.type) {
        //    case 'Sticker': return { color: '#a50026' };
        //    case 'Mural': return { color: '#d73027' };
        //    case 'Marker': return { color: '#f46d43' };
        //    case 'Characters': return { color: '#fdae61' };
        //    case 'Letters': return { color: '#fee090' };
        //    case 'Tippex': return { color: '#ffffbf' };
        //    case 'Spray': return { color: '#e0f3f8' };
        //    case 'Chalk': return { color: '#abd9e9' };
        //    case 'Label maker sticker': return { color: '#74add1' };
        //    case 'Poster': return { color: '#4575b4' };
        //}
    }

    // Map Events
    const onMouseOut = (e) => {
        //    var layer = e.target;
        //    geoJsonRef.current.setStyle(markerOptions);
    }

    const onMouseOver = (e) => {
        // var layer = e.target;

        //layer.setStyle({
        //    weight: 5,
        //    color: '#666',
        //    dashArray: '',
        //    fillOpacity: 0.7,
        //    radius: 3
        //});

        //if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        //    layer.bringToFront();
        //}
    }

    function onEachFeature(feature, layer) {
        if (feature.properties) {
            if (feature.geometry.type === "Point") {
                if (markerPopup === undefined) {
                    markerPopup = defaultMarkerPopup;
                }

                var htmlContainer = markerPopup(feature);

                layer.bindPopup(htmlContainer);
            } else if (feature.geometry.type === "LineString") {
                layer.bindTooltip(feature.properties.ItineraryName)
            }
        }
        layer.on({
            mouseover: onMouseOver,
            mouseout: onMouseOut,
            click: zoomToFeature
        });
    }

    function pointToLayer(feature, latLng) {
        var marker;

        // Hotel
        if (feature.properties.StructureId) {
            switch (feature.properties.BestStatus) {
                case "Available":
                    marker = L.marker(latLng, { id: feature.properties.StructureId, icon: greenIcon });
                    break;
                case "OnRequest":
                    marker = L.marker(latLng, { id: feature.properties.StructureId, icon: orangeIcon });
                    break;
                case "Price":
                    marker = L.marker(latLng, {
                        id: feature.properties.StructureId,
                        icon: new L.DivIcon({
                            className: 'my-div-icon',
                            html: '<div class="mapMarkerPrice" id=' + feature.properties.StructureId +'>' + formatPrice(feature.properties.BestPriceAmount, feature.properties.BestPriceCurrency, cultureCode) +'</div>'
                        }),
                    });
                    break;
                default:
                    marker = L.marker(latLng, { id: feature.properties.StructureId, icon: blueIcon });
                    break;
            }
            if (feature.properties?.StructureName) {
                marker.bindTooltip(feature.properties.StructureName)
            }
        } else if (feature.properties.ItineraryId) {
            marker = L.marker(latLng, { icon: icons.grey.numbers[feature.properties.ItineraryPointId] });
            if (feature.properties?.ItineraryName) {
                marker.bindTooltip(feature.properties.ItineraryName)
            }
        }

        return marker;
        //return L.circleMarker(latLng, markerOptions)
    }

    const defaultMarkerPopup = (feature) => {
        var htmlContainer = '<div style="display:block; max-height: 400px; width: 250px; overflow-x: hidden;" />';
        // Hotel
        if (feature.properties?.StructureId) {
            htmlContainer += "<div><label>Id: " + feature.properties.StructureId + " </label></div>"
        }
        if (feature.properties?.StructureName) {
            htmlContainer += "<div><label>Name: " + feature.properties.StructureName + " </label></div>"
        }

        htmlContainer += "</div>";
        return htmlContainer;
    }

    return (
        <GeoJSON
            data={data}
            pointToLayer={pointToLayer}
            pathOptions={markerStyles}
            onEachFeature={onEachFeature}
            filter={markerFilter}
            ref={geoJsonRef} />
    )

}

export default GeoJSONLayer