import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatDateTime, formatPrice } from '../../../../js/Utils.js';


export const FlightAncillaryOption = ({ ancillariesMap, flightFare, onChangeAncillary }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    const [ancillariesInfo, setAncillariesInfo] = useState([]);


    useEffect(() => {
        if (ancillariesMap) {
            let updAncillariesInfo = [];

            if (ancillariesMap.itinerary && ancillariesMap.itinerary.length > 0) {
                let keyRefsInserted = [];

                for (let i = 0; i < ancillariesMap.itinerary.length; i++) {
                    let ancillaries = ancillariesMap.itinerary[i];
                    let randomId = Math.floor(Math.random() * 1000);
                    let res = normalizeAncillaries(ancillaries, keyRefsInserted);

                    updAncillariesInfo.push({
                        id: 'ancillaries_Itinerary_' + randomId,
                        type: 'ITINERARY',
                        index: null,
                        segments: res.segments,
                        isSameSegmnet: res.isSameSegmnet
                    });
                }
            }

            if (ancillariesMap.leg && ancillariesMap.leg.length > 0) {
                let keyRefsInserted = [];

                for (let i = 0; i < ancillariesMap.leg.length; i++) {
                    let leg = ancillariesMap.leg[i];
                    let randomId = Math.floor(Math.random() * 1000);
                    let res = normalizeAncillaries(leg.ancillaries, keyRefsInserted);

                    updAncillariesInfo.push({
                        id: 'ancillaries_Leg_' + leg.legIndex + '_' + randomId,
                        type: 'LEG',
                        index: i,
                        segments: res.segments,
                        isSameSegmnet: res.isSameSegmnet
                    });
                }
            }

            if (ancillariesMap.traveler && ancillariesMap.traveler.length > 0) {
                let keyRefsInserted = [];

                for (let i = 0; i < ancillariesMap.traveler.length; i++) {
                    let traveler = ancillariesMap.traveler[i];
                    let randomId = Math.floor(Math.random() * 1000);
                    let res = normalizeAncillaries(traveler.ancillaries, keyRefsInserted);

                    updAncillariesInfo.push({
                        id: 'ancillaries_Traveler_' + traveler.index + '_' + randomId,
                        type: 'PAX',
                        index: traveler.index,
                        segments: res.segments,
                        isSameSegmnet: res.isSameSegmnet
                    });
                }

            }


            setAncillariesInfo(updAncillariesInfo);
        }

    }, [ancillariesMap, flightFare])

    function normalizeAncillaries(ancillaries, keyRefsInserted) {

        let segments = [];
        let isSameSegmnet = false;

        for (let j = 0; j < ancillaries.length; j++) {
            let anc = ancillaries[j];
            anc.isSelected = false;

            if (anc.keyRefs && anc.keyRefs.length > 0) {
                for (let k = 0; k < anc.keyRefs.length; k++) {
                    let keyRef = anc.keyRefs[k];
                    if (keyRefsInserted.indexOf(anc.id) > -1)
                        continue;

                    let segment = flightFare.legs[keyRef.legIndex - 1].segments[keyRef.segmentIndex - 1];
                    anc.description = anc.description.replaceAll(segment.departureAirportIATA + ' -> ' + segment.arrivalAirportIATA, '').trimStart();

                    // in alcuni casi ho città e aereoporto quindi provo a fare uno split (la cosa messa prima non funziona)
                    if (anc.description.includes('->')) {
                        anc.description = anc.description.substring(10).trimStart();
                    }

                    let segmentIndex = segments.findIndex(x => x.legIndex === keyRef.legIndex && x.segmentIndex === keyRef.segmentIndex);
                    if (segmentIndex > -1) {
                        segments[segmentIndex].ancillaries.push(anc);
                        segments[segmentIndex].ancillaries.sort(ancillariesCompare);
                    } else {

                        segments.push({
                            id: keyRef.legIndex + '_' + keyRef.segmentIndex,
                            legIndex: keyRef.legIndex,
                            segmentIndex: keyRef.segmentIndex,
                            segment: segment,
                            ancillaries: [anc]
                        });
                    }

                    keyRefsInserted.push(anc.id);
                }
            } else {
                isSameSegmnet = true;
                let legIndex = 0;
                let segmentIndex = 0;

                let segment = flightFare.legs[legIndex].segments[segmentIndex];

                if (anc.validValues)

                    anc.validValues.sort(validValuesCompare);

                segments.push({
                    id: legIndex + '_' + segmentIndex + '_' + anc.id,
                    legIndex: legIndex,
                    segmentIndex: segmentIndex,
                    segment: segment,
                    ancillaries: [anc]
                });

            }

        }

        return {
            segments: segments,
            isSameSegmnet: isSameSegmnet
        };
    }

    function changeSelectedAncillary(ancillarySelected, segmentSelected, ancillaryInfoSelected) {
        let updAncillariesInfo = [...ancillariesInfo];
        let isFound = false;
        let value = false;
        let index = -1;

        for (let i = 0; i < updAncillariesInfo.length; i++) {
            let segments = updAncillariesInfo[i].segments;
            let isRadioButton = updAncillariesInfo[i].id === ancillaryInfoSelected.id;

            for (let j = 0; j < segments.length; j++) {
                let ancillaries = segments[j].ancillaries;
                isRadioButton = isRadioButton && segments[j].id === segmentSelected.id;


                for (let k = 0; k < ancillaries.length; k++) {
                    let anc = ancillaries[k];

                    if (anc.id === ancillarySelected.id && !isFound) {
                        isFound = true;
                        value = !anc.isSelected
                        anc.isSelected = value;
                    }
                    else if (anc.id === ancillarySelected.id && isFound) {
                        anc.isSelected = value;
                    }
                    else if (isRadioButton)
                        anc.isSelected = false;
                }
            }

        }
     
        setAncillariesInfo(updAncillariesInfo);
        onChangeAncillary(ancillarySelected, null, index, ancillaryInfoSelected.index)
    }

    function onSelectValidValues(valueSelected, ancillarySelected, segmentSelected, ancillaryInfoSelected) {
        let updAncillariesInfo = [...ancillariesInfo];
        let isFound = false;
        let value = false;
        let index = -1;

        for (let i = 0; i < updAncillariesInfo.length; i++) {
            let segments = updAncillariesInfo[i].segments;

            if (updAncillariesInfo[i].id !== ancillaryInfoSelected.id)
                continue;

            let isRadioButton = updAncillariesInfo[i].id === ancillaryInfoSelected.id;

            for (let j = 0; j < segments.length; j++) {
                let ancillaries = segments[j].ancillaries;

                if (segments[j].id !== segmentSelected.id)
                    continue;

                isRadioButton = isRadioButton && segments[j].id === segmentSelected.id;

                for (let k = 0; k < ancillaries.length; k++) {
                    let anc = ancillaries[k];

                    if (anc.id === ancillarySelected.id) {
                        for (let z = 0; z < anc.validValues.length; z++) {
                            let vv = anc.validValues[z];

                            if (valueSelected.key === vv.key && !isFound) {
                                isFound = true;
                                value = !vv.isSelected;
                                vv.isSelected = value;
                            } else if (vv.key === valueSelected.key && isFound) {
                                vv.isSelected = value;
                            } else if (isRadioButton) {
                                vv.isSelected = false;
                            }
                        }

                    }

                }
            }

        }
        
        setAncillariesInfo(updAncillariesInfo);
        onChangeAncillary(ancillarySelected, valueSelected, index, ancillaryInfoSelected.index);
    }

    function ancillariesCompare(a, b) {

        if (a.description < b.description) {
            return -1;
        }

        if (a.description > b.description) {
            return 1;
        }

        return a.amount.amount - b.amount.amount;
    }

    function validValuesCompare(a, b) {

        if (a.description < b.description) {
            return -1;
        }

        if (a.description > b.description) {
            return 1;
        }

        return a.value.amount - b.value.amount;
    }

    function getLegName() {
        let depSegment = flightFare.legs[0].segments[0];

        let arrLeg = flightFare.legs[flightFare.legs.length - 1];
        let arrSegment = arrLeg.segments[arrLeg.segments.length - 1];

        let itineraryName = depSegment.departureAirportIATA + ' > ' + arrSegment.arrivalAirportIATA
        let depDate = formatDateTime(depSegment.departureDate, cultureName, { noYear: true });
        let flightCode = depSegment.mktCarrier + ' ' + depSegment.flightNumber;
        return depDate + ' ' + itineraryName + ' ' + t('Product:Flight:FlightCode') + ': ' + flightCode;
    }

    function getItineraryName() {
        return flightFare.itineraryName;
    }

    return (
        <>
            {
                ancillariesInfo && ancillariesInfo.length > 0 && ancillariesInfo.map((ancillaryInfo, keyAncInfo) => {
                    return <React.Fragment key={keyAncInfo}>
                        <p className="text-left mt-3">
                            <a className="text-decoration-none h075 pl-2 collapsed" data-bs-toggle="collapse" href={"#" + ancillaryInfo.id} role="button" aria-expanded="false" aria-controls={ancillaryInfo.id} >
                                <label className="h6">
                                    <span className="material-icons icon-10 pr-2"> add_box</span>
                                    {ancillaryInfo.type === 'PAX' && <>{t('General:Pax')} {ancillaryInfo.index}</>}
                                    {ancillaryInfo.type === 'LEG' && getLegName()}
                                    {ancillaryInfo.type === 'ITINERARY' && getItineraryName()}
                                </label>
                            </a>
                        </p>
                        <div className="collapse" id={ancillaryInfo.id}>
                            <div className="card card-body py-0">
                                <div id={ancillaryInfo.id} className="accordion-collapse collapse" role="region" aria-labelledby="heading1c" >
                                    <div className="accordion-body">
                                        {
                                            ancillaryInfo.segments && ancillaryInfo.segments.map((segment, keySeg) => {
                                                return <React.Fragment key={keySeg}>
                                                    {
                                                        (!ancillaryInfo.isSameSegmnet || keySeg === 0) && <div className="font-weight-bold h075 mt-3">
                                                            <span className="mr-1">{formatDateTime(segment.segment.departureDate, cultureName, { noYear: true })}</span>
                                                            <span className="mr-1">{segment.segment.departureAirportIATA} > {segment.segment.arrivalAirportIATA}</span>
                                                            <span className="mr-1">{t('Product:Flight:FlightCode')}: {segment.segment.mktCarrier} {segment.segment.flightNumber}</span>
                                                        </div>
                                                    }

                                                    {
                                                        segment.ancillaries && segment.ancillaries.map((ancillary, key) => {
                                                            return <React.Fragment key={key}>
                                                                {
                                                                    (!ancillary.validValues || ancillary.validValues.length == 0) && < div key={key} className="form-check mt-1" >
                                                                        <div className="w-80 d-inline-block">
                                                                            <input className="form-check-input"
                                                                                type="checkbox"
                                                                                id={"anc_" + ancillary.id}
                                                                                checked={ancillary.isSelected}
                                                                                value={ancillary.id}
                                                                                onChange={_ => changeSelectedAncillary(ancillary, segment, ancillaryInfo)}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={"anc_" + ancillary.id}>
                                                                                <span className="small h075">
                                                                                    {ancillary.description}
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                        <div className="w-20 d-inline-block text-right">
                                                                            <span className="small">{ancillary.amount && formatPrice(ancillary.amount.amount, 'EUR', cultureName)}</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    ancillary.validValues && ancillary.validValues.length > 0 && <div key={key} className="form-check mt-1">
                                                                        <span className="small h075 mt-2">{ancillary.description}</span>
                                                                        {
                                                                            ancillary.validValues.map((value, keyVv) => {
                                                                                return <React.Fragment key={keyVv}>
                                                                                    <div className="w-80 d-inline-block">
                                                                                        <input className="form-check-input"
                                                                                            type="checkbox"
                                                                                            id={"anc_vv_" + value.key}
                                                                                            checked={value.isSelected}
                                                                                            value={value.key}
                                                                                            onChange={_ => onSelectValidValues(value, ancillary, segment, ancillaryInfo)}
                                                                                        />
                                                                                        <label className="form-check-label" htmlFor={"anc_" + value.key}>
                                                                                            <span className="small h075">
                                                                                                {value.description}
                                                                                            </span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="w-20 d-inline-block text-right">
                                                                                        <span className="small">{value.value && formatPrice(value.value.amount, 'EUR', cultureName)}</span>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            })
                                                                        }
                                                                    </div>
                                                                }
                                                            </React.Fragment>
                                                        })
                                                    }
                                                </React.Fragment>;
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                })
            }
        </>
    );
}