import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';


export const PrintConfigButtons = ({ printParam, onClickPrintConfigButtons }) => {
    const [items, setItems] = useState({});

    const [checkboxes, setCheckboxes] = useState({
        B2BB2C: false,
        ShowCover: true,
        ShowPaxes: true,
        ShowPricing: true,
        ShowProducts: true,
        ShowDynamicNote: true,
        ShowGroupingPricing: true,
        ShowStaticScheda: true
    });

    useEffect(() => {
        setItems(printParam);
        if (printParam) {
            Object.keys(checkboxes).forEach((item) => {
                if (item === 'B2BB2C') {
                    checkboxes[item] = printParam[item] === 2;
                } else {
                    checkboxes[item] = printParam[item];
                }

            });
        }

    }, [printParam]);

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
        setCheckboxes((prevState) => ({
            ...prevState,
            [id]: checked
        }));

        const updItems = { ...items };
        if (id === 'B2BB2C') {
            updItems[id] = checked ? 2 : 1;
        } else {
            updItems[id] = checked;
        }

        onClickPrintConfigButtons(updItems);
    };

    function getLabel(key) {
        switch (key) {
            case 'B2BB2C': return 'Stampa Cliente';
            case 'ShowCover': return 'Mostra Cover';
            case 'ShowPaxes': return 'Mostra Lista Passeggeri';
            case 'ShowPricing': return 'Mostra Tabella Prezzi';
            case 'ShowGroupingPricing': return 'Mostra Tabella Prezzi Ragruppata';
            case 'ShowProducts': return 'Mostra Dettaglio Prodotti';
            case 'ShowDynamicNote': return 'Mostra Note Dinamiche Pagina';
            case 'ShowStaticScheda': return 'Mostra Schede Statiche';
            default: return key;
        }
    }


    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h5 className="mb-4">Configura la tua stampa</h5>
                </div>

                {Object.keys(checkboxes).map((key) => (
                    <div className="col-6 col-sm-6 col-md-3" key={key}>
                        <div>
                            <input
                                type="checkbox"
                                className="form-check-input ml-0 mb-2"
                                id={key}
                                checked={checkboxes[key]}
                                onChange={handleCheckboxChange}
                            />
                            <label
                                className="form-check-label"
                                style={{ marginLeft: '20px' }}
                                htmlFor={key}>
                                {getLabel(key)}
                            </label>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
