import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { M3Icon } from '../../Common/M3Icon';
import { formatDateTime } from '../../../js/Utils.js';
import { isValidNights } from '../service/TrippieService';

export const TrippieStepHeader = ({ stepInfo, cultureName, searchParameters }) => {

    const { t } = useTranslation();

    const [isValidNewNightsValue, setIsValidNewNightsValue] = useState(false);
    const [maxNightsValue, setMaxNightsValue] = useState(0);

    useEffect(() => {
        let res = isValidNights(stepInfo.nights, stepInfo, searchParameters);

        setIsValidNewNightsValue(res.isValid);
        setMaxNightsValue(res.maxNightsValue);

    }, [stepInfo, searchParameters])

    function onShowUpdateNight() {
        try {
            let btn = document.getElementById("open_modal_trippie_manage_step");
            btn.click();
        } catch (ex) { }
    }

    function openAddWebDestination() {
        try {
            let btn = document.getElementById("open_modal_trippie_manage_step");
            btn.click();
        } catch (ex) { }
    }

    return (
        <>
            <div className="h5 pt-3">
                <span className="float-start cursor-pointer" onClick={_ => openAddWebDestination()}>
                    <b>
                        {
                            stepInfo.stepType === (configData.Settings.Products.Flight.IdTipoProdotto + "")
                                ? <>{stepInfo.stepName ? stepInfo.stepName : <>Volo</>}</>
                                : <>{stepInfo.stepName ? stepInfo.stepName : <>Step {stepInfo.step}</>}</>
                        }
                    </b>
                </span>
                <span className="float-end h085" onClick={_ => onShowUpdateNight()}>
                    {
                        stepInfo.stepType === (configData.Settings.Products.Flight.IdTipoProdotto + "") ? <></>
                            : <div className="cursor-pointer text-end">
                                {
                                    stepInfo.nights
                                }
                                <span className="ml-1">{t('Product:Structure:Nights')}</span>
                                <button className="border-0 bg-transparent">
                                    <M3Icon iconName="Edit" externalClass="h085" />
                                </button>
                            </div>
                    }
                    {
                        !isValidNewNightsValue && <div className="text-danger">Il valore notti non è valido (massimo: {maxNightsValue})</div>
                    }
                </span>
            </div>
            {
                stepInfo.stepType === (configData.Settings.Products.Flight.IdTipoProdotto + "") ? <></>
                    : <div className="pb-4 border-bottom cursor-pointer" onClick={_ => openAddWebDestination()}>
                        <span className="float-start">
                            <small>Dal</small>
                            <span className="mx-1">{formatDateTime(stepInfo.stepFrom, cultureName, { monthLabel: true, noYear: true })}</span>
                            <small>al</small>
                            <span className="mx-1">{formatDateTime(stepInfo.stepTo, cultureName, { monthLabel: true, noYear: true })}</span>
                        </span>
                        <span className="float-end h085 cursor-pointer">
                            {
                                stepInfo.webDestinationName ? stepInfo.webDestinationName
                                    : <span>{t('Trippie:SearchEngine:SelectDestination')}</span>
                            }
                            <button className="border-0 bg-transparent">
                                <M3Icon iconName="Add" externalClass="h085" />
                            </button>
                        </span>
                    </div>
            }
        </>
    );
}
