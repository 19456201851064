import { QuotationTO } from "./components/QuotationTO/QuotationTO";
import { QuotationTOADV } from "./components/QuotationTO/QuotationTOADV";
import { Quotation } from "./components/QuotationTO/Quotation";
import { Cart } from "./components/Cart/Cart";
import { CartQuotation } from "./components/Cart/CartQuotation";
import { CartConfirm } from "./components/Cart/CartConfirm";
import { LibsVersion } from "./components/_Interno/LibsVersion";
import { ClearCache } from "./components/_Interno/ClearCache";

const AppRoutesProtected = [
    {
        path: '/quotationto/:quotationId',
        element: <QuotationTO />,
        visibleOnlyBook: false
    },
    {
        path: '/quotationtoadv/:quotationId',
        element: <QuotationTOADV />,
        visibleOnlyBook: false
    },
    {
        path: '/quotation/:quotationId',
        element: <Quotation />,
        visibleOnlyBook: true
    },
    {
        path: '/cart',
        element: <Cart />,
        visibleOnlyBook: false
    },
    {
        path: '/cart-quotation',
        element: <CartQuotation />,
        visibleOnlyBook: true
    },
    {
        path: '/cart-confirm/:orderNumber',
        element: <CartConfirm />,
        visibleOnlyBook: true
    },
    {
        path: '/libsversion',
        element: <LibsVersion />,
        visibleOnlyBook: false
    },
    {
        path: '/clearcache',
        element: <ClearCache />,
        visibleOnlyBook: false
    }
];

export default AppRoutesProtected;
