import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { getDisplayPrice } from '../../../../js/Utils.js';

export const TrainBestFareGroupByService = ({ fares, selectFares, showCost, templateInfo, onSelectFares }) => {
    const { t } = useTranslation();

    const cookies = new Cookies();
    const [cultureName] = useState(cookies.get("CultureName"));

    const maxFareItemForRow = 6;
    const [faresMapping, setFaresMapping] = useState([]);
    const [showAllFareServices, setShowAllFareServices] = useState(false);

    useEffect(() => {
        let updFares = [];
        if (fares.tariffe) {
            for (let i = 0; i < fares.tariffe.length; i++) {
                let fare = fares.tariffe[i];

                if (fare.bestService) {
                    let serviceNames = [];
                    let bestServices = [];

                    for (let j = 0; j < fare.bestService.length; j++) {
                        let bs = fare.bestService[j];
                        let serviceId = bs.serviceTypeId;
                        let serviceName = serviceId;

                        if (fare.serviceList) {
                            let serviceNameMatched = fare.serviceList.filter(x => x.id === serviceId);

                            if (serviceNameMatched.length > 0)
                                serviceName = serviceNameMatched[0].name;
                        }

                        bestServices.push(bs);
                        serviceNames.push(serviceName);
                    }

                    updFares.push({
                        index: i,
                        itineraryName: fare.fromStation + ' - ' + fare.toStation,
                        serviceNames: serviceNames,
                        bestServices: bestServices
                    });
                }

            }

        }

        setFaresMapping(updFares);
    }, [fares, selectFares])

    function isSelectedFare(service, key) {
        let className = '';

        if (selectFares && selectFares.length >= (key + 1)) {
            if (selectFares[key].fareComponents.uniqueId === service.uniqueId)
                className = 'focus';
        }

        return className;
    }

    function handleOnSelectFares(service, key) {
        onSelectFares(service, key, fares.fareArgs);
    }

    function onShowAllFareServices() {
        setShowAllFareServices(!showAllFareServices);
    }

    return (
        <>
            <div className="row pt-1">
                <div className="col-0 col-md-12 col-lg-12 col-xl-12 d-none d-sm-block">
                    <div className="">
                        {
                            faresMapping && faresMapping.map((fare, key) => {
                                return <React.Fragment key={key}>
                                    {key === 0 && <div>{t('Product:Train:BestService')}</div>}
                                    {faresMapping.length > 1 && <small>{fare.itineraryName}</small>}

                                    <table key={key} className="w-100 text-center small mt-2 mb-4">
                                        <thead>
                                            <tr className="small">
                                                {
                                                    fare.serviceNames && fare.serviceNames.slice(0, maxFareItemForRow).map((serviceName, keySN) => {
                                                        return <td key={keySN} className="pr-3 pb-2">
                                                            <var>{serviceName}</var>
                                                        </td>;
                                                    })
                                                }
                                                {
                                                    !showAllFareServices && fare.serviceNames && fare.serviceNames.length > maxFareItemForRow && <>
                                                        <td className="pr-3 pb-2"></td>
                                                    </>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                {
                                                    fare.bestServices && fare.bestServices.slice(0, maxFareItemForRow).map((service, keyS) => {
                                                        return <td key={keyS} className="pr-3">
                                                            <button className={"btn btn-sm btn-neutral " + isSelectedFare(service, key)} onClick={_ => handleOnSelectFares(service, key)}>
                                                                {getDisplayPrice(service.pricing, cultureName, showCost, templateInfo)}
                                                            </button>
                                                        </td>;
                                                    })
                                                }
                                                {
                                                    !showAllFareServices && fare.bestServices && fare.bestServices.length > maxFareItemForRow && <>
                                                        <td className="pr-3">
                                                            <button className="btn btn-sm btn-neutral " onClick={_ => onShowAllFareServices()}>
                                                                Show More
                                                            </button>
                                                        </td>
                                                    </>
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            })
                        }
                        {
                            showAllFareServices && faresMapping.map((fare, key) => {
                                return <React.Fragment key={key}>
                                    <table key={key} className="w-100 text-center small mt-2 mb-4">
                                        <thead>
                                            <tr className="small">
                                                {
                                                    fare.serviceNames && fare.serviceNames.slice(maxFareItemForRow).map((serviceName, keySN) => {
                                                        return <td key={keySN} className="pr-3 pb-2">
                                                            <var>{serviceName}</var>
                                                        </td>;
                                                    })
                                                }
                                                {
                                                    showAllFareServices && fare.serviceNames && fare.serviceNames.length > maxFareItemForRow && <>
                                                        <td className="pr-3 pb-2"></td>
                                                    </>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                {
                                                    fare.bestServices && fare.bestServices.slice(maxFareItemForRow).map((service, keyS) => {
                                                        return <td key={keyS} className="pr-3">
                                                            <button className={"btn btn-sm btn-neutral " + isSelectedFare(service, key)} onClick={_ => handleOnSelectFares(service, key)}>
                                                                {getDisplayPrice(service.pricing, cultureName, showCost, templateInfo)}
                                                            </button>
                                                        </td>;
                                                    })
                                                }
                                                {
                                                    showAllFareServices && fare.bestServices && fare.bestServices.length > maxFareItemForRow && <>
                                                        <td className="pr-3">
                                                            <button className="btn btn-sm btn-neutral " onClick={_ => onShowAllFareServices()}>
                                                                Show Less
                                                            </button>
                                                        </td>
                                                    </>
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            })
                        }
                    </div>
                </div>
                <div className="col-0 col-md-12 col-lg-12 col-xl-12 d-block d-sm-none">
                    <div className="">
                        {
                            faresMapping && faresMapping.map((fare, key) => {
                                return <React.Fragment key={key}>
                                    {key === 0 && <div>{t('Product:Train:BestService')}</div>}
                                    {faresMapping.length > 1 && <small>{fare.itineraryName}</small>}

                                    <table key={key} className="w-100 text-center small mt-2 mb-4">
                                        <tbody>
                                            <tr className='d-grid' style={{ gridTemplateColumns: '1fr 1fr' }}>
                                                {
                                                    fare.bestServices && fare.bestServices.map((service, keyS) => {
                                                        return <td key={keyS} className="pr-3">
                                                            <p>{fare.serviceNames[keyS]}</p>
                                                            <button className={"btn btn-sm btn-neutral " + isSelectedFare(service, key)} onClick={_ => handleOnSelectFares(service, key)}>
                                                                {getDisplayPrice(service.pricing, cultureName, showCost, templateInfo)}
                                                            </button>
                                                        </td>;
                                                    })
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            })
                        }
                    </div>
                </div>
            </div >
        </>
    );
}