import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useAuth } from '../../Auth/AuthProvider';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../Common/Loading';
import { handleChangePax, renderOptionBookParameters, renderPaxBookParameters, productNotAvailableClass_Opacity, productNotAvailableClass_Border } from './SharedFunctions';
import { formatDateTime } from '../../../js/Utils.js';
import { registerSessionQuotation } from '../../../js/QuotationUtils';
import { BookParameterMandatoryCssClass } from '../../../js/Constant';
import configData from "../../../appsettings.json";
import { AvailableActivity } from "../Available/AvailableActivity";
import { M3IconProductType } from '../../Common/M3IconProductType';
import { M3Pax } from "../../Common/M3Pax";

export const CartActivity = ({ cartItemId, isCartQuotation, userPermissions, cartPaxesNames, staticBookParams, productBookParams, handleBookParameters, handleReservationData, userLockBook, callTermsFinished, onOpenModalConfirmDelete }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const cultureName = cookies.get("CultureName");
    const { setQuotationInfoSession } = useAuth();

    // debug
    const [searchParams, setSearchParams] = useSearchParams();
    let debug = searchParams.get("debug");

    const [isLoading, setIsLoading] = useState(true);
    const [cartItem, setCartItem] = useState([]);


    useEffect(() => {
        const getCartItem = async () => {
            const response = callGetCartItem();
        }

        setIsLoading(true);
        getCartItem();
    }, []);

    useEffect(() => {
        callGetCartItem();
    }, [callTermsFinished]);

    useEffect(() => {

        if (userPermissions && userPermissions.cartOnlyOption) {
            let rbOptionBook = document.getElementById(`rbActivityOption_${cartItemId}`);
            if (rbOptionBook)
                rbOptionBook.click();
        }

    }, [userPermissions, callTermsFinished])

    useEffect(() => {
        if (cartItem && cartItem.option && cartItem.option.passengers && cartPaxesNames && cartPaxesNames.length > 0) {
            for (let i = 0; i < cartItem.option.passengers.length; i++) {
                let pax = cartItem.option.passengers[i];
                let e = { target: { value: pax.index }, Index: pax.index };

                let ddlPaxId = `ci_${cartItem.cartItemId}_option_1_pax_${pax.index}_ddlPax`;
                let ddlPax = document.getElementById(ddlPaxId);
                if (ddlPax) {
                    ddlPax.value = pax.index;
                    handleChangePax(cartItem, cartPaxesNames, handleBookParameters, 1, pax.index, ddlPaxId, e);
                }
            }
        }
    }, [cartPaxesNames]);


    async function callGetCartItem() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/GetCartItem/' + cartItemId, requestOption);
        const response = await fetchedRes.json();

        setCartItem(response);
        setIsLoading(false);
    }

    async function callUpdateQuotationItemStatus(quotationId, quotationItemId) {
        let inputData =
        {
            QuotationId: quotationId,
            QuotationItemId: quotationItemId,
            Status: 70,
            LogNote: "Status changed from CartQuotation due to BookingTerm error",
            QuotationSettings: { BaseUrl: "" }
        };

        const requestOption =
        {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/UpdateQuotationItemStatus', requestOption);
        const response = await fetchedRes.json();

        let responseQuotation = await registerSessionQuotation(quotationId, quotationItemId, true, cartItemId);
        if (responseQuotation) {
            setQuotationInfoSession(responseQuotation.quotationInfo);
            window.location.href = responseQuotation.url;
        }
    }

    function onClickChangeProduct(url, quotationId, quotationItemId) {
        if (quotationId !== null && quotationId !== undefined && quotationItemId !== null && quotationItemId !== undefined) {
            callUpdateQuotationItemStatus(quotationId, quotationItemId);
        }
        else {
            window.location.href = url;
        }
    }

    function onClickDeleteNotAvailable() {
        onOpenModalConfirmDelete(cartItemId);
    }

    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : (
                    <>
                        <div className={"card mt-3 p-3 " + productNotAvailableClass_Border(productBookParams)}>
                            <div className="card p-3">
                                <div className={productNotAvailableClass_Opacity(productBookParams)}>

                                    <div className="row">
                                        <div className="col-8">
                                            <M3IconProductType productType={cartItem.productType} productSubtype={cartItem.moduleId} externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                                            <span className="titlecard">{cartItem.name}</span>
                                            <div><span>{cartItem.category}</span></div>
                                        </div>
                                        <div className="col-4 text-right">
                                            {cartItem.status && cartItem.status === 'OnRequest' && <span className="text-warning h6 mb-0 small d-block"><var>{cartItem.status}</var></span>}
                                            {cartItem.supplier && <span className="text-info small d-block mt-2"><var>{cartItem.supplier.supplierName}</var></span>}
                                        </div>
                                    </div>

                                    {callTermsFinished &&
                                        <div className="d-inline float-end">
                                            <button className="btn btn-sm btn-danger" onClick={() => onOpenModalConfirmDelete(cartItemId)}>{t("Button:Delete")}</button>
                                        </div>
                                    }

                                    <p><label>{formatDateTime(cartItem.dateFrom, cultureName)} - {formatDateTime(cartItem.dateTo, cultureName)}</label></p>
                                </div>
                                <div className={productNotAvailableClass_Opacity(productBookParams)}>
                                    <M3Pax adt={cartItem.adults} chd={cartItem.children} chdAges={cartItem.childrenAges} extraOptions={{ showInfant: false, iconClass: "icon-15" }} />
                                    <br />
                                    {cartItem.option.cancelPolicy &&
                                        <span className={cartItem.option.cancelPolicy.isFree ? "penalty free" : "penalty"}>
                                            {cartItem.option.cancelPolicy.isFree ? t("CancelPolicy:FreeCancellation") + " " + formatDateTime(cartItem.option.cancelPolicy.expirationDate, { twoDigits: true }) : t("CancelPolicy:PenaltyCancellation")}
                                        </span>
                                    }

                                    {!callTermsFinished
                                        ? (<><Loading textMsg={t(`Cart:LoadingBookingTerms`)} isSmall={true} /></>)
                                        : (
                                            <>
                                                {productBookParams !== null && productBookParams !== undefined && productBookParams.IsAvailable
                                                    ? (
                                                        <>
                                                            {/*Passengers*/}
                                                            {cartItem.option.passengers.map(pax =>
                                                                <div key={pax.index} className="row">
                                                                    <div className="form-floating col-md-3">
                                                                        <select id={`ci_${cartItem.cartItemId}_option_1_pax_${pax.index}_ddlPax`} className="form-select border-0 border-bottom border-dark" aria-label="Fornitore" onChange={(e) => handleChangePax(cartItem, cartPaxesNames, handleBookParameters, 1, pax.index, `ci_${cartItem.cartItemId}_option_1_pax_${pax.index}_ddlPax`, e)}>
                                                                            <option value="-1">{t("Button:Select")}&nbsp; pax</option>
                                                                            {cartPaxesNames !== null && cartPaxesNames !== undefined && cartPaxesNames.length > 0
                                                                                ? (
                                                                                    cartPaxesNames.map(paxName =>
                                                                                        <option key={paxName.Index} value={paxName.Index}>{paxName.BpFirstName[0].Value} {paxName.BpLastName[0].Value}</option>
                                                                                    )
                                                                                )
                                                                                : (<></>)
                                                                            }
                                                                        </select>
                                                                        <label htmlFor={`ci_${cartItem.cartItemId}_option_1_pax_${pax.index}`}>
                                                                            <span className={BookParameterMandatoryCssClass.Mandatory}>{t("MyBook:Traveler")}&nbsp; {pax.paxType} {pax.index}:</span>
                                                                        </label>
                                                                    </div>

                                                                    {renderPaxBookParameters(cartItem, productBookParams, staticBookParams, handleBookParameters, 1, pax.index)}
                                                                </div>
                                                            )}

                                                            {renderOptionBookParameters(cartItem, productBookParams, handleBookParameters, 1)}
                                                        </>
                                                    )
                                                    : (<></>)
                                                }
                                            </>
                                        )
                                    }
                                </div>

                                {/*Product Not Available*/}
                                {productBookParams !== null && productBookParams !== undefined && !productBookParams.IsAvailable
                                    ? (
                                        <AvailableActivity
                                            detailUrl={productBookParams.TermsError_DetailUrl}
                                            listUrl={productBookParams.TermsError_ListUrl}
                                            quotationId={productBookParams.QuotationId}
                                            quotationItemId={productBookParams.QuotationItemId}
                                            deleteFn={onClickDeleteNotAvailable}
                                            changeProductFn={onClickChangeProduct}
                                            mode="CART"
                                        />
                                    )
                                    : (<></>)
                                }
                            </div>

                            {/* --- DEBUG --- */}
                            {debug === "1"
                                ? (<><br /><br />{JSON.stringify(productBookParams)}</>)
                                : (<></>)
                            }
                            {/* --- DEBUG --- */}
                        </div>
                    </>
                )
            }
        </>
    )
}