import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../Common/Loading';
import { BookParameterId } from '../../../js/Constant';
import configData from "../../../appsettings.json";

export const ChangeStructurePaxesModal = ({ pnr, productType, structureDetail }) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);

    const [showBtnConfirm, setShowBtnConfirm] = useState(true);
    const [showBtnClose, setShowBtnClose] = useState(true);

    const [paxesData, setPaxesData] = useState([]);

    useEffect(() => {
        let myPaxesData = [];
        for (let i = 0; i < structureDetail.rooms.length; i++) {
            let room = structureDetail.rooms[i];
            for (let j = 0; j < structureDetail.rooms[i].passengers.length; j++) {
                let pax = structureDetail.rooms[i].passengers[j];

                let objPax = {
                    IdPrenotazione: room.sequence,
                    IdPax: Number(pax.index),
                    NewName: getPaxName(pax, true),
                    NewSurname: getPaxName(pax, false),
                    NewGender: getPaxGender(pax),
                    NewPaxType: pax.paxType === 'Adult' ? 'A' : 'C',
                    NewBirthDate: getPaxBirthDate(pax),
                    NewEmail: getPaxEmail(pax)
                };

                myPaxesData.push(objPax);
            }
        }
        setPaxesData(myPaxesData);
    }, []);

    const getPaxName = (pax, isFirstName) => {
        if (pax && pax.fullName) {
            let names = pax.fullName.split(' ');
            return isFirstName ? names[0] : names[1];
        } else {
            return ''
        }
    }

    const getPaxGender = (pax) => {
        var bp = pax.infos.find(x => x.paramName === BookParameterId.Personal.Gender);
        return bp !== null && bp !== undefined ? bp.paramValue : "";
    }

    const getPaxBirthDate = (pax) => {
        var bp = pax.infos.find(x => x.paramName === BookParameterId.Personal.BirthDate);
        return bp !== null && bp !== undefined ? bp.paramValue : null;
    }

    const getPaxEmail = (pax) => {
        var bp = pax.infos.find(x => x.paramName === BookParameterId.Contact.Email);
        return bp !== null && bp !== undefined ? bp.paramValue : null;
    }

    const onChangeProperties = (roomSeq, paxIdx, propertyName, propertyValue) => {
        let myPaxesData = paxesData;
        let objIndex = myPaxesData.findIndex((x => x.IdPrenotazione === roomSeq && x.IdPax === Number(paxIdx)));
        if (objIndex < 0)
            return;

        if (propertyName === "firstName") myPaxesData[objIndex].NewName = propertyValue;
        if (propertyName === "lastName") myPaxesData[objIndex].NewSurname = propertyValue;
        if (propertyName === "type") myPaxesData[objIndex].NewPaxType = propertyValue;
        if (propertyName === "gender") myPaxesData[objIndex].NewGender = propertyValue;
        if (propertyName === "birthDate") myPaxesData[objIndex].NewBirthDate = propertyValue;
        if (propertyName === "email") myPaxesData[objIndex].NewEmail = propertyValue;
        setPaxesData(myPaxesData);
    }


    const onClose = () => {
        window.location.reload(true);
    }

    async function onConfirm() {
        setIsLoading(true);

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ PNR: pnr, ProductType: productType, NewPaxes: paxesData })
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/ChangePaxes', requestOption);
        const response = await fetchedRes.json();

        setIsLoading(false);
        setIsFinished(true);
        setShowSuccessMsg(true);
    }


    return (
        <div className="modal fade" id={"ChangeStructurePaxes_" + pnr} tabIndex="-1" aria-labelledby={"ChangeStructurePaxesLabel_" + pnr} aria-hidden="true">
            <div className="modal-dialog modal-lg" style={{ width: '65%', maxWidth: 'none' }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Cambio Passeggeri</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {
                            isLoading
                                ? (<Loading textMsg={t('MyBook:Loading')} />)
                                : (!isFinished
                                    ? (
                                        <>
                                            {structureDetail.rooms.map(room =>
                                                <div key={room.sequence} className="row">
                                                    <div className="col-12">
                                                        <strong>Camera {room.sequence}</strong>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-1">N.</div>
                                                            <div className="col-2">Nome</div>
                                                            <div className="col-2">Cognome</div>
                                                            <div className="col-1">Tipo</div>
                                                            <div className="col-1">Genere</div>
                                                            <div className="col-3">Data di nascita</div>
                                                            <div className="col-2">Email</div>
                                                        </div>
                                                        {room.passengers.map((pax, index) =>
                                                            <div key={index} className="row">
                                                                <div className="col-1">
                                                                    {pax.index}
                                                                </div>
                                                                <div className="col-2">
                                                                    <input type="text" className="form-control" placeholder={getPaxName(pax, true)} onChange={e => onChangeProperties(room.sequence, pax.index, 'firstName', e.target.value)} />
                                                                </div>
                                                                <div className="col-2">
                                                                    <input type="text" className="form-control" placeholder={getPaxName(pax, false)} onChange={e => onChangeProperties(room.sequence, pax.index, 'lastName', e.target.value)} />
                                                                </div>
                                                                <div className="col-1">
                                                                    <select className="form-control" defaultValue={pax.paxType === 'Adult' ? 'ADT' : 'CHD'} onChange={e => onChangeProperties(room.sequence, pax.index, 'type', e.target.value)}>
                                                                        <option value="A">ADT</option>
                                                                        <option value="C">CHD</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-1">
                                                                    <select className="form-control" defaultValue={getPaxGender(pax) === 'M' ? 'M' : 'F'}  onChange={e => onChangeProperties(room.sequence, pax.index, 'gender', e.target.value)}>
                                                                        <option value="M">M</option>
                                                                        <option value="F">F</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-3">
                                                                    <input type="date" className="form-control datepicker" placeholder={getPaxBirthDate(pax)} onChange={e => onChangeProperties(room.sequence, pax.index, 'birthDate', e.target.value)} />
                                                                </div>
                                                                <div className="col-2">
                                                                    <input type="text" className="form-control" placeholder={getPaxEmail(pax)} onChange={e => onChangeProperties(room.sequence, pax.index, 'email', e.target.value)} />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )
                                    : (
                                        <>
                                            {showSuccessMsg
                                                ? (<h6 className="penalty free">Richiesta inviata con successo</h6>)
                                                : (<h6 className="penalty">Si &egrave; verificato un errore</h6>)}
                                        </>
                                    )
                                )
                        }
                    </div>
                    <div className={isLoading ? ('modal-footer d-none') : ('modal-footer')}>
                        <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal" onClick={onClose}>{t('Button:Close')}</button>
                        {!isFinished ? (<button type="button" className="btn bt-sm tp-btn-confirm" onClick={onConfirm}>Cambia Passeggeri</button>) : (<></>)}
                    </div>
                </div>
            </div>
        </div>
    );
}
