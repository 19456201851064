import React, { useEffect, useState } from "react";
import { M3Icon } from "../M3Icon";
import configData from "../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { searchRangeParameter } from '../../../js/Constant.js';
import { getDefaultRoom } from '../../../js/Utils.js';

export const InputPaxesCompositionPanel = ({ searchParameters, productType, defaultSelectComposition, callOnSelectComposition, type }) => {
    const { t } = useTranslation();

    // usati nel pannello che si aprirà sotto
    const [composition, setComposition] = useState([]);
    const [validChildAges, setValidChildAges] = useState([]);

    // usato per accedere alla configurazione presente nel searchRangeParameter
    const [typeRangeParameter, setTypeRangeParameter] = useState(0);

    // impostazioni di pagina
    const [haveRooms, setHaveRooms] = useState(false);
    const [inputPaxTypeWidth, setInputPaxTypeWidth] = useState('w-35');

    useEffect(() => {
        if (type === 'l') {
            setInputPaxTypeWidth('w-35');
        }
    }, [])

    useEffect(() => {
        if (productType === configData.Settings.Products.Structure.IdTipoProdotto) {
            setHaveRooms(true);

            if (!defaultSelectComposition && searchParameters && searchParameters.roomsComposition) {

                searchParameters.roomsComposition.forEach(function (roomComp) {
                    setComposition(arr => [...arr, {
                        adults: roomComp.adults,
                        children: roomComp.children,
                        childAges: roomComp.childAges
                    }
                    ]);
                });

            } else if (defaultSelectComposition) {
                setComposition(defaultSelectComposition);
            }
            else {
                setDefaultComposition('hotel');
            }

            setDefaultValidChildAges('hotel');

            setTypeRangeParameter('hotel');
        }

        if (productType === configData.Settings.Products.Activity.IdTipoProdotto) {
            if (!defaultSelectComposition && searchParameters && searchParameters.passengerComposition) {
                setComposition([
                    {
                        adults: searchParameters.passengerComposition.adults,
                        children: searchParameters.passengerComposition.children,
                        childAges: searchParameters.passengerComposition.childAges
                    }
                ]);

            } else if (defaultSelectComposition) {
                setComposition(defaultSelectComposition);
            }
            else {
                setDefaultComposition('activity');
            }

            setDefaultValidChildAges('activity');

            setTypeRangeParameter('activity');
        }

        if (productType === configData.Settings.Products.Flight.IdTipoProdotto) {
            if (!defaultSelectComposition && searchParameters && searchParameters.travelers) {
                let compositionObj = { adults: 0, children: 0, childAges: [] };
                for (let i = 0; i < searchParameters.travelers.length; i++) {
                    let pc = searchParameters.travelers[i];

                    if ((pc.age || pc.age === 0) && pc.age <= searchRangeParameter.flight.maxInfantAge) {
                        compositionObj.children += 1
                        compositionObj.childAges.push(pc.age);

                    } else if (pc.age && pc.age <= searchRangeParameter.flight.maxChildAge) {
                        compositionObj.children += 1
                        compositionObj.childAges.push(pc.age);

                    } else {
                        compositionObj.adults += 1;
                    }
                }

                setComposition([compositionObj]);

            } else if (defaultSelectComposition) {
                setComposition(defaultSelectComposition);
            }
            else {
                setDefaultComposition('flight');
            }

            setDefaultValidChildAges('flight');

            setTypeRangeParameter('flight');
        }

        if (productType === configData.Settings.Products.Train.IdTipoProdotto) {
            if (!defaultSelectComposition && searchParameters && searchParameters.passengerComposition) {
                setComposition([
                    {
                        adults: searchParameters.passengerComposition.adults,
                        children: searchParameters.passengerComposition.children,
                        childAges: searchParameters.passengerComposition.childAges
                    }
                ]);

            } else if (defaultSelectComposition) {
                setComposition(defaultSelectComposition);
            }
            else {
                setDefaultComposition('train');
            }

            setDefaultValidChildAges('train');

            setTypeRangeParameter('train');
        }

        if (productType === configData.Settings.Products.Tour.IdTipoProdotto) {
            setHaveRooms(true);

            if (!defaultSelectComposition && searchParameters && searchParameters.roomsComposition) {

                searchParameters.roomsComposition.forEach(function (roomComp) {
                    setComposition(arr => [...arr, {
                        adults: roomComp.adults,
                        children: roomComp.children,
                        childAges: roomComp.childAges
                    }
                    ]);
                });

            } else if (defaultSelectComposition) {
                setComposition(defaultSelectComposition);
            }
            else {
                setDefaultComposition('tour');
            }

            setDefaultValidChildAges('tour');

            setTypeRangeParameter('tour');
        }

        if (productType === configData.Settings.Products.Cruise.IdTipoProdotto) {
            if (!defaultSelectComposition && searchParameters && searchParameters.passengersComposition) {
                setComposition([
                    {
                        adults: searchParameters.passengersComposition.adults,
                        children: searchParameters.passengersComposition.children,
                        childAges: searchParameters.passengersComposition.childAges
                    }
                ]);

            } else if (defaultSelectComposition) {
                setComposition(defaultSelectComposition);
            }
            else {
                setDefaultComposition('cruise');
            }

            setDefaultValidChildAges('cruise');

            setTypeRangeParameter('cruise');
        }

    }, [searchParameters, defaultSelectComposition]);

    // settaggi iniziali
    function setDefaultComposition(type) {
        let defRoom = getDefaultRoom(type);
        setComposition([defRoom]);

        callOnSelectComposition([defRoom]);
    }

    function setDefaultValidChildAges(type) {
        let childAgesRP = [];
        let minAge = searchRangeParameter[type].minChildAge;
        if (productType === configData.Settings.Products.Flight.IdTipoProdotto)
            minAge = searchRangeParameter[type].minInfantAge;

        for (let i = minAge; i <= searchRangeParameter[type].maxChildAge; i++)
            childAgesRP.push(i);

        setValidChildAges(childAgesRP);
    }

    function getTotalPaxesToAdd() {
        const sum = composition.reduce((accumulator, object) => {
            return accumulator + object.adults + object.children;
        }, 0);

        return sum;
    }

    // funzioni per gestire la composizione
    function onAddPax(type, index) {
        let updateComposition = [...composition];

        let totalPaxes = getTotalPaxesToAdd();
        if (totalPaxes >= searchRangeParameter[typeRangeParameter].totalPaxes)
            return;

        if (type === 'ADT') {
            if (updateComposition[index].adults >= searchRangeParameter[typeRangeParameter].maxAdults)
                return;

            updateComposition[index].adults++;
        }

        if (type === 'CHD') {
            if (updateComposition[index].children >= searchRangeParameter[typeRangeParameter].maxChildren)
                return;

            updateComposition[index].children++;
            updateComposition[index].childAges.push(searchRangeParameter[typeRangeParameter].defaultChildAge);
        }


        setComposition(updateComposition);
    }

    function onRemovePax(type, index) {
        let updateComposition = [...composition];

        let totalPaxes = getTotalPaxesToAdd();
        if (totalPaxes === 1)
            return;

        if (type === 'ADT') {
            if (updateComposition[index].adults === searchRangeParameter[typeRangeParameter].minAdults)
                return;

            updateComposition[index].adults--;
        }

        if (type === 'CHD') {
            if (updateComposition[index].children === searchRangeParameter[typeRangeParameter].minChildren)
                return;

            updateComposition[index].children--;
            updateComposition[index].childAges.pop();
        }

        setComposition(updateComposition);
    }

    function onSelectAge(age, indexRoomComposition, indexChildAge) {
        let updateComposition = [...composition];

        try {
            updateComposition[indexRoomComposition].childAges[indexChildAge] = age;
        } catch (ex) { }


        setComposition(updateComposition);
    }

    function onAddRoom() {
        let updateComposition = [...composition];
        let defComp = getDefaultRoom(typeRangeParameter);

        updateComposition.push(defComp);

        setComposition(updateComposition);
    }

    function onRemoveRoom(removeIndex) {
        let updateComposition = [...composition];

        updateComposition.splice(removeIndex, 1);

        setComposition(updateComposition);
    }

    // funzioni esterne
    function confirmComposition() {
        callOnSelectComposition(composition);
    }

    return (
        <>
            <ul className="list-group list-unstyled">
                {
                    composition.map((item, key) => {
                        return <React.Fragment key={key}>
                            <li className="list-group-item pb-2">
                                <div className=" w-100 h-100 align-middle d-inline">
                                    {
                                        haveRooms && <label className="d-inline-block w-85 mb-2 pb-1 h6">
                                            <data m3lab="Product.Structure.Room">{t('Product:Structure:Room')}</data> {key + 1}:
                                        </label>
                                    }
                                </div>
                                <div className="d-table">

                                    <div className="d-table-row">
                                        <div className="d-table-cell h075 pr-4">
                                            <data m3lab="SearchEngine.Adults">{t('SearchEngine:Adults')}</data>
                                        </div>
                                        <div className="d-inline">
                                            <button className="d-inline-block btn btn-sm tp-btn-outline-select rounded-circle p-1" onClick={_ => onRemovePax('ADT', key)}>
                                                <data m3ico="Remove">
                                                    <M3Icon iconName="Remove" externalClass="icon-15" />
                                                </data>
                                            </button>
                                            <input className={"d-inline-block bg-gray-200 rounded mx-2 text-center " + inputPaxTypeWidth}
                                                type="number"
                                                value={item.adults} />
                                            <button className="d-inline-block btn btn-sm tp-btn-outline-select rounded-circle p-1" onClick={_ => onAddPax('ADT', key)}>
                                                <data m3ico="Add">
                                                    <M3Icon iconName="Add" externalClass="icon-15" />
                                                </data>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="d-table-row">
                                        <div className="d-table-cell h075 pr-4">
                                            <data m3lab="SearchEngine.Children">{t('SearchEngine:Children')}</data>
                                        </div>
                                        <div className="d-inline">
                                            <button className="d-inline-block btn btn-sm tp-btn-outline-select rounded-circle p-1" onClick={_ => onRemovePax('CHD', key)}>
                                                <data m3ico="Remove">
                                                    <M3Icon iconName="Remove" externalClass="icon-15" />
                                                </data>
                                            </button>
                                            <input className={"d-inline-block bg-gray-200 rounded mx-2 text-center " + inputPaxTypeWidth}
                                                type="number"
                                                value={item.children} />
                                            <button className="d-inline-block btn btn-sm tp-btn-outline-select rounded-circle p-1" onClick={_ => onAddPax('CHD', key)}>
                                                <data m3ico="Add">
                                                    <M3Icon iconName="Add" externalClass="icon-15" />
                                                </data>
                                            </button>
                                        </div>
                                    </div>
                                    {
                                        item.children > 0 && item.childAges && item.childAges.map((age, keyChd) => {
                                            return <div key={keyChd} className="d-table-row">
                                                <div className="d-table-cell h075 pt-2">
                                                    <data m3lab="SearchEngine.Child">{t('SearchEngine:Child')}</data> {keyChd + 1}
                                                </div>
                                                <div className="d-inline">
                                                    <div className="dropdown w-100 h075 bg-gray-200 rounded mx-2 ">
                                                        <div className="form-control btn btn-sm dropdown-toggle shadow-none h075" type="button" id={"EtaBambino" + key} data-bs-toggle="dropdown" aria-expanded="false">
                                                            <data m3lab="SearchEngine.Age">{t('SearchEngine:Age')}</data>: {age}
                                                        </div>
                                                        <ul className="dropdown-menu bg-gray-200 text-center" aria-labelledby={"EtaBambino" + key}>
                                                            {
                                                                validChildAges.map((vca, keyVca) => {
                                                                    return <li key={keyVca}
                                                                        className={vca === age ? "dropdown-item active" : ""}
                                                                        onClick={_ => onSelectAge(vca, key, keyChd)}>
                                                                        {vca}
                                                                    </li>;
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                                {
                                    haveRooms && key !== 0 && <div className="text-right mt-1">
                                        <button className="btn p-1" onClick={_ => onRemoveRoom(key)}>
                                            <span className="h065">
                                                <data m3lab="SearchEngine.RemoveRoom">
                                                    {t('SearchEngine:RemoveRoom')}
                                                </data>
                                                <data m3ico="Remove">
                                                    <M3Icon iconName="Remove" externalClass="icon-10" />
                                                </data>
                                            </span>
                                        </button>
                                    </div>
                                }
                            </li>
                        </React.Fragment>
                    })
                }
                {
                    haveRooms && <li className="bg-white">
                        <div className="text-right mt-1">
                            <button className="btn p-1" onClick={_ => onAddRoom()}>
                                <span className="h065">
                                    <data m3lab="SearchEngine.AddRoom">
                                        {t('SearchEngine:AddRoom')}
                                    </data>
                                    <data m3ico="Add">
                                        <M3Icon iconName="Add" externalClass="icon-10" />
                                    </data>
                                </span>
                            </button>
                        </div>
                    </li>
                }
                <li className="bg-white py-2">
                    <div className=" text-right mt-1 mr-2">
                        <button type="button" className="btn btn-sm tp-btn-fatto" onClick={_ => confirmComposition()}>
                            <span className="h065">
                                <data m3lab="Button.Confirm">
                                    {t('Button:Confirm')}
                                </data>
                            </span>
                        </button>
                    </div>
                </li>
            </ul>
        </>
    );
}
