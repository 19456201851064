import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";
import { TourRoomMealPlan } from "./TourRoomMealPlan";
import { ProductNotAllowedAddToCart } from '../../Common/ProductNotAllowedAddToCart';
import { CancelPolicyModal } from '../../Common/CancelPolicyModal.js';

export const TourRoom = ({ room, showPriceBar, showCost, enableShowPriceBreakDown, enableCanAddToCart, roomIndex, totalAccomodation, handleMealPlanIdAndSequence, multiRoomSelected, handleFinalBook, templateInfo, quotationInfo, handleRoomExtras, supplier }) => {
    const { t } = useTranslation();
    const [mealPlanId, setMealPlanId] = useState(null);
    const [cityTax, setCityTax] = useState(null);
    const [notes, setNote] = useState(null);
    const [cancellationPolicy, setCancellationPolicy] = useState(null);

    useEffect(() => {
        if (mealPlanId) {
            getInfoByMealPlanIdSelected();
        }
    }, [mealPlanId]);

    function onBookClick(mealPlanID) {
        let mealplanSelected = room.rooms.find(x => x.id === mealPlanID);
        handleFinalBook(mealplanSelected, totalAccomodation);
    };

    function getInfoByMealPlanIdSelected() {
        const mealplan = room.rooms.find((el) => el.id === mealPlanId);
        if (!mealplan)
            return;

        setCityTax(mealplan.resortFees ? getCityTaxFromHtml(mealplan.resortFees[0]) : null);

        let noteArr = [];
        if (mealplan.descriptions && mealplan.descriptions.length > 0) {
            noteArr = noteArr.concat(mealplan.descriptions);
        }

        if (mealplan.notes && mealplan.notes.length > 0) {
            noteArr = noteArr.concat(mealplan.notes);
        }

        setNote(noteArr);
    }

    function getCityTaxFromHtml(string) {
        const regex = /(<([^>]+)>)/ig;
        return string.replace(regex, '');
    }

    function onHandleMealPlanId(mealplanId) {
        handleMealPlanIdAndSequence({ id: mealplanId, sequence: room.sequence });
        setMealPlanId(mealplanId);
    }

    function onHandleSelectedExtras(extras) {
        handleRoomExtras({roomId: room.sequence, extras: extras, mealPlanId: extras.mealPlanId });
    }

    function onClickOpenCancelPolicyDetail(cancellationPolicy) {
        setCancellationPolicy(cancellationPolicy);
    }

    return (
        <>
            <div className="card border-light bg-gray-200 small m-2 roomToSelect">
                <div className="row small ">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 h6 px-2 m-0 pl-3 pt-2">
                        <var>{room.roomName}</var>
                    </div>

                    {!templateInfo &&
                        <div className="col-6 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-md-0 pt-2">
                            <data m3lab="Product.Structure.Filter.CityTax">{t(`Product:Structure:Filter:CityTax`)} </data>
                            {cityTax && cityTax.length < 40 ?
                                <span><var>{cityTax}</var></span>
                                :
                                <button className="btn p-0" data-bs-toggle="modal" data-bs-target="#infoCityTax">
                                    <data m3ico="info text-gray-500 icon-10"> <M3Icon iconName="Info" externalClass=" text-gray-500 icon-10" /></data>
                                </button>
                            }
                        </div>
                    }

                    {!templateInfo &&
                        <div className="col-6 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-md-0 pt-2">
                            <data m3lab="Structure.Note">{t(`Structure:Note`)} </data>
                            {notes && notes.length > 0 ?
                                <button className="btn p-0" data-bs-toggle="modal" data-bs-target="#infoCamera">
                                    <data m3ico="info text-gray-500 icon-10"> <M3Icon iconName="Info" externalClass=" text-gray-500 icon-10" /></data>
                                </button>
                                :
                                "X"
                            }
                        </div>
                    }
                    <div className={`col-12 ${!templateInfo ? "col-sm-2 col-md-2 col-lg-2 col-xl-2" : "col-sm-6 col-md-6 col-lg-6 col-xl-6"} pl-0 text-right m-0`}>
                        {
                            (templateInfo || quotationInfo || (enableCanAddToCart && enableCanAddToCart.allowed)) && <button className="btn-sm tp-btn-outline-book float-end m-0 px-2 py-1 mb-1 btnBookRoom" onClick={_ => onBookClick(mealPlanId)}>
                                <data m3lab="Button.Book">
                                    {!templateInfo && !quotationInfo && <span dangerouslySetInnerHTML={{ __html: t(`Button:AddToCart`) }}></span>}
                                    {templateInfo && t(`Button:Select`)}
                                    {quotationInfo && !quotationInfo.quotationItemIdToChange && t(`Button:AddQuotation`)}
                                    {quotationInfo && quotationInfo.quotationItemIdToChange && t(`Button:ReplaceQuotation`)}
                                </data>
                            </button>
                        }
                        <ProductNotAllowedAddToCart enableCanAddToCart={enableCanAddToCart} />
                    </div>
                </div>
                {
                    room.rooms && room.rooms.map((mealplan, key) =>
                        <TourRoomMealPlan key={mealplan.id}
                            mealplan={mealplan}
                            defaultMealPlanId={mealPlanId}
                            showPriceBar={showPriceBar}
                            showCost={showCost}
                            enableShowPriceBreakDown={enableShowPriceBreakDown}
                            mealPlanIndex={key}
                            handleMealPlanId={onHandleMealPlanId}
                            roomIndex={roomIndex}
                            totalAccomodation={totalAccomodation}
                            multiRoomSelected={multiRoomSelected}
                            totalMealplan={room.rooms.length}
                            templateInfo={templateInfo}
                            handleOpenCancelPolicyDetail={onClickOpenCancelPolicyDetail}
                            handleSelectedExtras={onHandleSelectedExtras}
                            supplier={supplier }
                        />
                    )
                }
            </div>

            <CancelPolicyModal
                cancellationPolicy={cancellationPolicy}
                autoOpen={true}
            />

            {/* Note modale */}
            <div className="modal fade" id="infoCamera" tabIndex="-1" aria-labelledby="infoCamera" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="infoCamera">
                                <data m3lab="Product.Structure.InfoCamera">
                                    {t("Product:Structure:InfoCamera")}
                                </data>
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {notes && notes.map((note) =>
                                <p style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: note }} />
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-gray-300" data-bs-dismiss="modal">
                                <data m3lab="Button.Chiudi">
                                    {t("Button:Chiudi")}
                                </data>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* City tax modale */}
            <div className="modal fade" id="infoCityTax" tabIndex="-1" aria-labelledby="infoCityTax" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="infoCityTax">
                                <data m3lab="Product.Structure.InfoCamera">
                                    {t("Product:Structure:InfoCamera")}
                                </data>
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {cityTax ?
                                cityTax
                                :
                                <data m3lab="Product.Structure.InfoTaxes">
                                    {t("Product:Structure:InfoTaxes")}
                                </data>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-gray-300" data-bs-dismiss="modal">
                                <data m3lab="Button.Chiudi">
                                    {t("Button:Chiudi")}
                                </data>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}