import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { QuotationItemChat } from './QuotationItemChat';

export const QuotationChats = (props) => {
    const { t } = useTranslation();

    const noteToAddMaxLength = 1000;

    const [chatToAdd, setChatToAdd] = useState("");
    const [isLoadingChatToAdd, setIsLoadingChatToAdd] = useState(false);
    const [isValidNoteToChat, setValidNoteToChat] = useState(false);

    function onTextAreaChange(event) {
        var note = event.target.value;
        setValidNoteToChat(note && note.length > 0);
        setChatToAdd(note);
    }

    function onClickAddChat() {
        setIsLoadingChatToAdd(true);
        props.callAddChat(chatToAdd);
    }

    function onClickAddChatAnswer(inputData) {
        setIsLoadingChatToAdd(true);

        props.callAddChatAnswer(inputData);
    }

    function onClickReadChat(chatId) {
        props.callReadChat(chatId);
    }

    function onClickLoadAllChats() {
        props.callGetAllChat();
    }

    useEffect(() => {
        setIsLoadingChatToAdd(false);
        setChatToAdd("");
        setValidNoteToChat(false);
    }, [props]);

    return (
        <div className="section section-md bg-white text-black pt-0 line-bottom-light" id="chatStakeholder">

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8">
                        <div>
                            <h4>Chat:</h4>
                            <textarea className="form-control border border-light-gray" id="txtChat" value={chatToAdd} placeholder="Inserisci messaggio" rows="6" maxLength={noteToAddMaxLength} onChange={onTextAreaChange}></textarea>
                            <div className="d-flex justify-content-between mt-3">
                                <small className="font-weight-light text-dark">
                                    <span id="charactersRemaining">
                                       {t('quotationto:WarningMaxLength').replaceAll('#MAXLENGTH#', noteToAddMaxLength) }
                                    </span>
                                </small>
                                <button className="btn tp-btn-confirm animate-up-2" onClick={onClickAddChat} disabled={!isValidNoteToChat || isLoadingChatToAdd}>
                                    {t('Button:Send')}
                                </button>
                            </div>
                            {
                                props.quotationChats !== null & props.quotationChats !== undefined & props.quotationChats.length > 0
                                    ? props.quotationChats.map((chat, key) => {
                                        return <div key={key} className="mt-5">
                                            <div className="card bg-white border-light rounded p-4 mb-4">
                                                <QuotationItemChat
                                                    chat={chat}
                                                    isReplyPossible={!(chat.childs && chat.childs.length > 0)}
                                                    callReadChat={onClickReadChat}
                                                    callAddChatAnswer={onClickAddChatAnswer}
                                                    cultureName={props.cultureName}
                                                />
                                            </div>
                                            {
                                                chat.childs && chat.childs.length > 0 && chat.childs.map((chatChild, keyChild) => {
                                                    return <div key={keyChild} className="card bg-gray-400 border-light rounded p-4 ml-5 mb-4">
                                                        <QuotationItemChat
                                                            chat={chatChild}
                                                            isReplyPossible={keyChild === (chat.childs.length - 1)}
                                                            callReadChat={onClickReadChat}
                                                            callAddChatAnswer={onClickAddChatAnswer}
                                                            cultureName={props.cultureName}
                                                            isAgencyMode={props.isAgencyMode}
                                                        />
                                                    </div>
                                                })
                                            }
                                        </div>;
                                    })
                                    : <p>Nessuna chat</p>
                            }

                            <div className="mt-5 text-center">
                                {
                                    props.quotationChats && props.limitQuotationChats > -1
                                ? <button id="loadOnClickChats" className="btn btn-primary btn-loading-overlay mr-2 mb-2" onClick={onClickLoadAllChats}>
                                    <span className="ml-1 btn-inner-text">Load more chat</span>
                                </button>
                                :
                                <p id="allLoadedchats">
                                    That's all!
                                </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}