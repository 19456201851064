import React, { useState, useCallback } from 'react';
import { formatPrice } from '../../../../js/Utils.js';
import Cookies from 'universal-cookie';

export const CarSingleExtra = ({ extra, handleClickExtra, isReadOnly }) => {
    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");
    const [quantity, setQuantity] = useState("0");
    const [disabledQuantity, setDisabledQuantity] = useState(false);

    const onClickExtra = useCallback(event => {

        if (!isReadOnly) {

            let isSelect = false;
            if (event > 0) {
                isSelect = true;
                //setDisabledQuantity(true);
            }
            else {
                isSelect = false;
                //setDisabledQuantity(false);
            }
            setQuantity(event);
            handleClickExtra({ extra: extra, isSelected: isSelect, quantity: event });

        }

    }, [handleClickExtra, quantity]);


    return (
        <>
            {extra &&
                <>
                    {/*<div className="col-1 p-2 pl-1">
                    <input className="form-check-input cursor-pointer" type="checkbox" onClick={(e) => onClickExtra(e)} />
                    </div>*/}
                    <div className="col-8 p-2">
                        <span className="fw-bold">
                            <var>{extra.description}</var>
                        </span>
                        <label>
                            <span className="small h075">
                            </span>
                        </label>
                    </div>
                    <div className="col-2 text-center p-2">
                        {
                            !isReadOnly && <select id="quantity-options" onChange={(e) => onClickExtra(e.currentTarget.value)} disabled={disabledQuantity}>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        }
                    </div>
                    <div className="col-2 d-none d-sm-block text-right p-2">
                        <span className="h5">
                            {formatPrice((extra.amount ? extra.amount : extra.costAmount), (extra.currency ? extra.currency : extra.costCurrency), cultureCode)}
                        </span>
                    </div>
                    <div className="col-12 d-sm-none text-left p-2">
                        <span className="h5">
                            {formatPrice((extra.amount ? extra.amount : extra.costAmount), (extra.currency ? extra.currency : extra.costCurrency), cultureCode)}
                        </span>
                    </div>
                </>
            }
        </>
    );
}