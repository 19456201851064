import React from 'react';


export const HeaderPDFPrint = ({  headerElements }) => {

    return (<>
        {headerElements && headerElements.map(item => {
            return <div dangerouslySetInnerHTML={{ __html: item.html }}>
            </div>
        })}
    </>
    )
}
