import React, { useEffect, useState } from 'react';
import configData from "../../../../appsettings.json";
import { handleError } from "../../../../js/Utils";
import { useTranslation } from 'react-i18next';


export const AddContact = ({ contact, onSaveSuccess, onClosePanel }) => {
    const { t } = useTranslation();

    const defaultNewContact = {
        contactId: -1,
        name: '',
        surname: '',
        email: '',
        mobile: ''
    };
    const defaultErrorMsg = 'Errore in fase di inserimento, controllare i dati';
    const defaultValidateNewContact = {
        isValidName: false,
        isTouchedName: false,

        isValidSurname: false,
        isTouchedSurname: false,

        isValidEmail: false,
        isTouchedEmail: false,

        isValidMobile: true,

        isValidForm: false
    };

    const [newContact, setNewContact] = useState(defaultNewContact);
    const [validateNewContact, setValidateNewContact] = useState(defaultValidateNewContact);

    const [isLoadingSaveContact, setIsLoadingSaveContact] = useState(false);
    const [isErrorSaveContact, setIsErrorSaveContact] = useState(false);
    const [errorMsg, setErrorMsg] = useState(defaultErrorMsg);

    useEffect(() => {

        if (contact && contact.contactId > 0) {
            let newvalidateNewContact = { ...defaultValidateNewContact };

            Object.keys(newvalidateNewContact).forEach(v => newvalidateNewContact[v] = true);

            setNewContact(contact);
            setValidateNewContact(newvalidateNewContact);
        } else {
            setNewContact(defaultNewContact);
            setValidateNewContact(defaultValidateNewContact);
        }

        setIsLoadingSaveContact(false);
        setIsErrorSaveContact(false);

    }, [contact]);

    function onBlurEvent(event, propertyName) {
        let updateNewContact = { ...newContact };
        let newValue = event.target.value;

        let prevValidateNewContact = { ...validateNewContact };
        const isNotEmpty = (newValue !== '' && newValue !== undefined);
        let isValidForm = true;

        switch (propertyName) {
            case "name":
                {
                    delete prevValidateNewContact.isValidName;
                    delete prevValidateNewContact.isTouchedName;
                    delete prevValidateNewContact.isValidForm;

                    isValidForm = isNotEmpty && Object.values(prevValidateNewContact).every(item => item);

                    setValidateNewContact({ ...validateNewContact, isValidForm: isValidForm, isValidName: isNotEmpty, isTouchedName: true });
                }
                break;

            case "surname":
                {
                    delete prevValidateNewContact.isValidSurname;
                    delete prevValidateNewContact.isTouchedSurname;
                    delete prevValidateNewContact.isValidForm;

                    isValidForm = isNotEmpty && Object.values(prevValidateNewContact).every(item => item);

                    setValidateNewContact({ ...validateNewContact, isValidForm: isValidForm, isValidSurname: isNotEmpty, isTouchedSurname: true });
                }
                break;

            case "email":
                {
                    delete prevValidateNewContact.isValidEmail;
                    delete prevValidateNewContact.isTouchedEmail;
                    delete prevValidateNewContact.isValidForm;

                    const isValidMailFormat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(newValue);
                    isValidForm = isNotEmpty && isValidMailFormat && Object.values(prevValidateNewContact).every(item => item);

                    setValidateNewContact({ ...validateNewContact, isValidForm: isValidForm, isValidEmail: isNotEmpty && isValidMailFormat, isTouchedEmail: true });
                }
                break;

            case "mobile":
                {
                    delete prevValidateNewContact.isValidMobile;
                    delete prevValidateNewContact.isValidForm;

                    let isValidMobileFormat = !isNotEmpty;
                    if (isNotEmpty) {

                        // verifico se il numero inizia con +393478945678
                        let startWithPrefix = newValue.startsWith('+');
                        newValue = newValue.trim();
                        let phoneWithoutPrefix = newValue.trim();

                        if (startWithPrefix) {
                            // rimuovo il più
                            phoneWithoutPrefix = phoneWithoutPrefix.slice(1);
                        }

                        // verifico che sia un intero
                        const intRegex = /[0-9 -()+]+$/;
                        isValidMobileFormat = intRegex.test(phoneWithoutPrefix);
                    }

                    isValidForm = isValidMobileFormat && Object.values(prevValidateNewContact).every(item => item);

                    setValidateNewContact({ ...validateNewContact, isValidForm: isValidForm, isValidMobile: isValidMobileFormat });
                }
                break;
        }

        updateNewContact[propertyName] = newValue;
        setNewContact(updateNewContact);
    }

    function onSaveContact() {
        setIsLoadingSaveContact(true);
        setIsErrorSaveContact(false);

        newContact.profileId = configData.Settings.QuotationTO.ProfileId;

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newContact)
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}contact/savecustomercontact`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                if (item.success) {
                    onSaveSuccess();
                } else {

                    if (item.errorMsg)
                        setErrorMsg(item.errorMsg);
                    else
                        setErrorMsg(defaultErrorMsg);

                    setIsErrorSaveContact(true);
                }

                setIsLoadingSaveContact(false);
            })
            .catch((err) => {
                console.error(err);
                setErrorMsg(defaultErrorMsg);
                setIsErrorSaveContact(true);
                setIsLoadingSaveContact(false);
            });

    }

    return (
        <>
            <li className="list-group-item border-bottom">
                <div className="row align-items-center">
                    <div className={"col-2 font-weight-bold" + (validateNewContact.isTouchedName && !validateNewContact.isValidName ? " insert-danger" : "")}>
                        <input type="text"
                            className="form-control"
                            id="floatingInput_contact_name"
                            placeholder={t("Login:Name") + " *"}
                            value={newContact.name}
                            onChange={(e) => { onBlurEvent(e, 'name'); }}
                        />
                        {
                            validateNewContact.isTouchedName && !validateNewContact.isValidName && <div className="text-danger">{t("Login:FieldIsMandatory")}</div>
                        }
                    </div>
                    <div className={"col-3 font-weight-bold" + (validateNewContact.isTouchedSurname && !validateNewContact.isValidSurname ? " insert-danger" : "")}>
                        <input type="text"
                            className="form-control"
                            id="floatingInput_contact_surname"
                            placeholder={t("Login:Surname") + " *"}
                            value={newContact.surname}
                            onChange={(e) => { onBlurEvent(e, 'surname'); }} />
                        {
                            validateNewContact.isTouchedSurname && !validateNewContact.isValidSurname && <div className="text-danger">{t("Login:FieldIsMandatory")}</div>
                        }
                    </div>
                    <div className={"col-3 font-weight-bold" + (validateNewContact.isTouchedEmail && !validateNewContact.isValidEmail ? " insert-danger" : "")}>
                        <input type="text"
                            className="form-control"
                            id="floatingInput_contact_email"
                            placeholder="Email *"
                            value={newContact.email}
                            onChange={(e) => { onBlurEvent(e, 'email'); }} />
                        {
                            validateNewContact.isTouchedEmail && !validateNewContact.isValidEmail && <div className="text-danger">{t("Login:FieldNotValid")}</div>
                        }
                    </div>
                    <div className={"col-2 font-weight-bold" + (!validateNewContact.isValidMobile ? " insert-danger" : "")}>
                        <input type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder={t("Login:Phone")}
                            value={newContact.mobile}
                            onChange={(e) => { onBlurEvent(e, 'mobile'); }} />
                        {
                            !validateNewContact.isValidMobile && <div className="text-danger"> {t("Login:FieldNotValid")}</div>
                        }
                    </div>
                    <div className="col-2 font-weight-bold text-right">
                        <div>
                            <button id="addcontact_save" className="btn tp-btn-add btn-sm w-75"
                                onClick={onSaveContact}
                                disabled={!validateNewContact.isValidEmail || validateNewContact.isLoadingSaveContact}
                            >
                                {
                                    !isLoadingSaveContact
                                        ? newContact.contactId > 0 ? <><span className="material-icons-outlined">save</span> {t("MyBook:Modify")}</>
                                            : <><span className="material-icons-outlined">save</span> Save</>
                                        : <div className="spinner-border text-primary" role="status"><span className="sr-only">{t("MyBook:Saving")}</span></div>
                                }
                            </button>
                        </div>
                        {
                            !isLoadingSaveContact && <div className="mt-1">
                                <button className="btn btn-danger btn-sm w-75" onClick={onClosePanel}><span className="material-icons-outlined">close</span>{t("MyBook:Cancel")}</button>
                            </div>
                        }
                    </div>
                    {
                        isErrorSaveContact && !isLoadingSaveContact && <div className="col-12 text-right">
                            <span className="text-danger">{errorMsg}</span>
                        </div>
                    }
                </div>
            </li>
        </>
    );
}