import configData from "../../../appsettings.json";
import { getWebsiteId } from '../../../js/Utils.js';

export async function getHeaderPrint(type, cultureCode) {
    const result = await getWebsiteMenuPrint(type, cultureCode)
    return result
}


async function getWebsiteMenuPrint(type, cultureCode) {

    const requestOption = {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    };
    // GetWebsiteMenu/{type}/{websiteId}/{cultureCode}
    try {
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + `website/GetWebsiteMenu/${type}/${getWebsiteId()}/${cultureCode}`, requestOption);
        if (fetchedRes.ok) {
            const result = await fetchedRes.json();
            return result
        }
    } catch(e) {
        console.error('Error:' + e)
        return null
    }

}