import React from 'react';
import configData from "../../../../appsettings.json";
import { GalleryList } from "../../Common/Image/GalleryList";
import { M3Icon } from "../../../Common/M3Icon";
import { ProductActionMenu } from "../../Common/ProductActionMenu";
import { ProductPrice } from "../../Common/Pricing/ProductPrice";
import { ActivityListRecapOptions } from "./ActivityListRecapOptions";

export const ActivityItemList = ({ item, showPriceBar, showCost, onClickSearch, modalOpenProductId, extraParam, removeTrippie, updateTrippie }) => {

    function onModalOpenProductId(productId) {
        modalOpenProductId(productId);
    }

    return (
        <>
            <div className="card shadow shadow-hover mb-4 animate-up-3 ">
                <div className="row no-gutters">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <GalleryList
                            productId={item.activityId}
                            defaultImage={item.thumbUrl}
                            gallery={item.images}
                            alternativeText={item.name}
                            onClickSearch={onClickSearch}
                        />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <div className="card-body pt-2 px-0">
                            <div>
                                <div className="w-100">
                                    <div className="row">
                                        <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                                            <label>
                                                <data m3ico="Place icon-10">
                                                    <M3Icon iconName="Place" externalClass="ico-10" hasLabel={false} />
                                                </data>
                                                <var>{item.city}</var>
                                            </label>
                                            {
                                                item.isSuggestion && <data m3ico="Star text-facebook">
                                                    { /* Per le promozioni o i suggeriti */}
                                                    <M3Icon iconName="Star" externalClass="text-facebook" hasLabel={false} />
                                                </data>
                                            }
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <div className="btn-group text-right float-end pr-2">
                                                <button className="btn p-0 m-0" type="button" id="menuItem1" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                    <data m3ico="List">
                                                        <M3Icon iconName="MenuFunctions" />
                                                    </data>
                                                </button>
                                                <ProductActionMenu
                                                    productId={item.activityId}
                                                    productType={configData.Settings.Products.Activity.IdTipoProdotto}
                                                    handleModalOpenProductId={onModalOpenProductId}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="h5">
                                        <var className="cursor-pointer" onClick={(e) => onClickSearch(item.activityId)}>{item.name}</var>
                                    </div>

                                    <div className="w-100 mb-2">
                                        <label className="lh-base">
                                            { /* Max 189 caratteri i 3 puntini aprono la scheda */}
                                            <var>
                                                {
                                                    item.description && item.description.length > 189 ? item.description.substring(0, 189)
                                                        : item.description
                                                }
                                                {
                                                    item.description && item.description.length > 189 && <a data-bs-toggle="modal" data-bs-target={"#schedStaticaModal" + item.activityId}>...</a>
                                                }
                                            </var>
                                        </label>
                                    </div>
                                    <div className="position-innerit h-100 ">
                                        <div className="position-absolute bottom-0 w-100 pb-1">
                                            {/*<ActivityListRecapOptions*/}
                                            {/*    activityId={item.activityId}*/}
                                            {/*    bestPricingOptions={item.bestPricingOptions}*/}
                                            {/*    onClickSearch={onClickSearch} />*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-1 pr-3">
                        <ProductPrice
                            productId={item.activityId}
                            startDate={item.startDate}
                            endDate={item.endDate}
                            pricing={item.pricing}
                            supplier={item.supplier}
                            showPriceBar={showPriceBar}
                            showCost={showCost}
                            otherParam={{ buttonEnabled: true }}
                            typePrice="XPax"
                            isTrippie={extraParam && extraParam.isTrippie}
                            showPriceTrippie={extraParam && extraParam.showPriceTrippie}
                            removeTrippie={removeTrippie}
                            updateTrippie={updateTrippie}
                            onClickSearch={onClickSearch} />
                    </div>
                </div>
            </div >
        </>
    );
}