import React, { useState, useEffect } from 'react';
import { M3Icon } from "../../../Common/M3Icon";

export const TourSummary = ({ tour, isAlternative }) => {
    const [fontSize, setFontSize] = useState('');

    useEffect(() => {
        if (isAlternative)
            setFontSize('h075');
    }, [tour]);

    return (
        <>
            <span m3ico="Tour mr-2 icon-15">
                <M3Icon iconName="Tour" externalClass="mr-2 icon-15 text-custom" hasLabel={false} />
            </span>

            {tour.name}
        </>
    );
}
