import React from 'react';
import { Error } from '../../Common/Error';


export const PrintHeader = ({ isLoadingPrint, isErrorPrint, title, onPrint  }) => {


    return (
        <>
            <div className="row py-2">
                <div className="col-6">
                    <div className="d-flex align-items-center h-100">
                        <h4>{title}</h4>
                    </div>
                </div>
                <div className="col-6 text-right pr-6">
                    {
                        isLoadingPrint && <div class="spinner-border spinner-sm" role="status">
                            <span class="visually-hidden"></span>
                        </div>
                    }
                    {
                        !isLoadingPrint && <button className="btn btn-primary text-white ml-3" onClick={_ => onPrint()}><span class="far fa-file-pdf mr-2"></span>Stampa</button>
                    }
                    {
                        !isLoadingPrint && isErrorPrint && <Error textMsg="Errore in fase di stampa" />
                    }
                </div>
            </div>
        </>
    );
}
