import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ProductCalendarTime = ({ productTimes, imageUrl, onSelectTime }) => {
    const { t } = useTranslation();

    const [selectTime, setSelectTime] = useState(null);
    const [timesMorning, setTimesMorning] = useState([]);
    const [timesAfternoon, setTimesAfternoon] = useState([]);
    const [timesEvening, setTimesEvening] = useState([]);

    useEffect(() => {
        let newTimeMorning = []; // dalle 6 alle 12
        let newTimeAfternoon = []; // dalle 12 alle 18
        let newTimeEvening = []; // dalle 18 alle 23

        // capisco che frazione oraria sono le ore
        let minutes = [];
        for (let i = 0; i < productTimes.length; i++) {
            let myTime = productTimes[i];
            if (myTime.isSelected)
                setSelectTime(myTime.label);

            let myTimePart = myTime.label.split(':');
            minutes.push(myTimePart[1]);

            // CASO RARO in caso un'orario non sia nel range viene aggiunto in morning o evening
            if (parseInt(myTimePart[0]) < 7)
                newTimeMorning.push(myTime);

            if (parseInt(myTimePart[0]) >= 23)
                newTimeEvening.push(myTime);
        }

        // Distinct
        minutes = [...new Set(minutes)];

        for (let i = 6; i <= 23; i++) {
            let timeStr = i + "";
            if (i < 10)
                timeStr = "0" + i;

            let allTimeMinutes = [];
            for (let j = 0; j < minutes.length; j++) {
                let timeInfo = {
                    value: '',
                    label: timeStr + ":" + minutes[j],
                    className: ''
                };

                let exist = productTimes.some(pt => pt.label === timeInfo.label);
                if (!exist) {
                    timeInfo.className = 'tp-btn-select disabled';
                } else {
                    timeInfo.isAvailable = true;
                    timeInfo.value = productTimes.filter(pt => pt.label === timeInfo.label)[0].value;
                }

                allTimeMinutes.push(timeInfo);
            }

            let existAtLeastOne = allTimeMinutes.some(x => x.isAvailable);
            if (existAtLeastOne)
                allTimeMinutes = allTimeMinutes.sort(compare).filter(x => x.isAvailable);
            else
                allTimeMinutes = [allTimeMinutes[0]];

            if (i < 12)
                newTimeMorning.push(...allTimeMinutes);

            if (i >= 12 && i < 18)
                newTimeAfternoon.push(...allTimeMinutes);

            if (i >= 18)
                newTimeEvening.push(...allTimeMinutes);

        }

        // Normalize
        newTimeMorning = newTimeMorning.sort(compare);
        newTimeAfternoon = newTimeAfternoon.sort(compare);
        newTimeEvening = newTimeEvening.sort(compare);

        setTimesMorning(newTimeMorning);
        setTimesAfternoon(newTimeAfternoon);
        setTimesEvening(newTimeEvening);

    }, [productTimes])

    function compare(a, b) {
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
        return 0;
    }

    function getTimeClassName(time) {
        if (!time)
            return "";

        if (time.label === selectTime)
            return time.className + " tp-btn-select";

        return time.className;
    }

    function onClickSelectTime(time) {
        if (!time.isAvailable)
            return;

        setSelectTime(time.label);
        let selectTimeOption = productTimes.filter(x => x.value === time.value);
        onSelectTime(selectTimeOption[0]);
    }

    return (
        <>
            {
                productTimes && productTimes.length > 0 && <div className="col-12">
                    <div className="row row-hover py-1 border-bottom mx-0 my-2 bg-white rounded">
                        <div className="d-md-flex rounded">
                            <div className="wrap-img-calendar d-flex align-items-center img w-25 rounded" style={{ backgroundImage: `url("${imageUrl}")` }}>
                                <div className="p-2 w-100">
                                    <div className="W-100 p-0">
                                        <div className="h6 font-weight-light text-white w-100 text-center">Turno delle</div>
                                        <div className="h1 text-white w-100 text-center">{selectTime}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 px-4 w-100 text-center small">
                                <div className="row">
                                    <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                        <div>{t('Calendar:Morning')}</div>
                                        <div className="d-table text-center w-100 h-100">
                                            <div className="d-table-row">
                                                {
                                                    timesMorning && timesMorning.map((time, key) => {
                                                        return <button key={key}
                                                            className={"btn btn-sm px-0 w-30 " + getTimeClassName(time)}
                                                            onClick={_ => onClickSelectTime(time)}>
                                                            {time ? time.label : ""}
                                                        </button>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                        <div>{t('Calendar:Afternoon')}</div>
                                        <div className="d-table text-center w-100 h-100">
                                            <div className="d-table-row">
                                                {
                                                    timesAfternoon && timesAfternoon.map((time, key) => {
                                                        return <button key={key}
                                                            className={"btn btn-sm px-0 w-30 " + getTimeClassName(time)}
                                                            onClick={_ => onClickSelectTime(time)}>
                                                            {time ? time.label : ""}
                                                        </button>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                        <div>{t('Calendar:Evening')}</div>
                                        <div className="d-table text-center h-100">
                                            <div className="d-table-cell">
                                                {
                                                    timesEvening && timesEvening.map((time, key) => {
                                                        return <button key={key}
                                                            className={"btn btn-sm px-0 w-30 " + getTimeClassName(time)}
                                                            onClick={_ => onClickSelectTime(time)}>
                                                            {time ? time.label : ""}
                                                        </button>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            }
        </>
    );
}