import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../Common/M3Icon";
import { M3Pax } from "../../Common/M3Pax";
import { getSearchParameterDate, getPassengerComposition, TemplateInclusion, canStartSearch } from '../TemplateUtils';
import { CruiseItemList } from '../../Product/Cruise/components/CruiseItemList';
import { CruiseDetailGroupCabinFilter } from '../../Product/Cruise/components/CruiseDetailGroupCabinFilter';
import { CruiseDetailCabinCategoryGroup } from '../../Product/Cruise/components/CruiseDetailCabinCategoryGroup';
import { imageOnLoadCruise } from '../../../js/CDNUtility';
import { scrollTo, formatPrice, getWebsiteId } from '../../../js/Utils.js';
import configData from "../../../appsettings.json";

export const CruiseTemplate = ({ templateData, products, selectedProducts, selectedProductData, searchParameters, isAlternative, isOptional, onSelectAlternative, onUpdateSelectedInfoData, onUnselectOptional, onFinishSearch }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    // debug
    const [searchParams, setSearchParams] = useSearchParams();
    let debug = searchParams.get("debug");

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchStarted, setSearchStarted] = useState(false);

    // src
    const [srcCarpetId, setSrcCarpetId] = useState(null);
    const [avlCarpetId, setAvlCarpetId] = useState(null);

    // avl
    const [showCruiseCabinCategoriesChoose, setShowCruiseCabinCategoriesChoose] = useState(false);
    const [cruiseCabinCategory, setCruiseCabinCategory] = useState(null);
    const [selectedDiscountPaxCodes, setSelectedDiscountPaxCodes] = useState(null);

    // discount pax
    const [discountPaxCodes, setDiscountPaxCodes] = useState(null);


    useEffect(() => {
        setIsLoading(true);
        onFinishSearch(templateData.templateDataId, true);

        if (searchStarted) {
            callTemplateStartSearch();
        }
    }, [templateData]);

    useEffect(() => {
        if (searchStarted || !canStartSearch(templateData.templateDataId, products, selectedProducts))
            return;

        setSearchStarted(true);
        callTemplateStartSearch();
    }, [selectedProducts]);


    // start search
    async function callTemplateStartSearch(selectedDiscountCodes) {
        let sCarpetId = selectedProductData
            ? !isAlternative && !isOptional ? selectedProductData.SelectedInfo.SrcCarpetId : selectedProductData.AlternativeInfo.SrcCarpetId
            : srcCarpetId;

        let requestParam = {
            TemplateId: templateData.templateId,
            TemplateDataId: templateData.templateDataId,
            DepartureDate: getSearchParameterDate(searchParameters, templateData, configData.Settings.Products.Cruise.IdTipoProdotto, "CHECKIN"),
            PaxComposition: getPassengerComposition(searchParameters, configData.Settings.Products.Cruise.IdTipoProdotto),
            Citizenship: searchParameters.citizenship,
            WebsiteId: getWebsiteId(),
            SessionToken: cookies.get("SessionToken"),
            CultureCode: cultureName,
            SrcCarpetId: sCarpetId,
            DiscountPaxesCodes: selectedDiscountCodes
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestParam) };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}Cruise/TemplateStartSearch`, requestOption);

        let isError = true;

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response && response.success && response.cruise) {
                isError = false;

                setSrcCarpetId(response.cruise.srcCarpetId);
                setAvlCarpetId(response.cruise.avlCarpetId);
                setCruiseCabinCategory(response.cruise);

                let setDefaultOption = selectedProductData && (
                    (selectedProductData.SelectedInfo && !selectedProductData.SelectedInfo.ProductSelection) ||
                    (templateData.inclusion === TemplateInclusion.Optional && selectedProductData.AlternativeInfo && !selectedProductData.AlternativeInfo.ProductSelection)
                );

                if (setDefaultOption || (selectedDiscountCodes && selectedDiscountCodes.length > 0)) { // set default Cruise
                    // setto la Option di default
                    let productSelection = `${response.cruise.cabinCategoryGroupInfo[0].cabinCategories[0].productCode}`;
                    let productPriceDetail = {
                        DisplayPrice: response.cruise.cabinCategoryGroupInfo[0].cabinCategories[0].pricing.displayPrice,
                        NetPrice: response.cruise.cabinCategoryGroupInfo[0].cabinCategories[0].pricing.networkPrice,
                        Markup: response.cruise.cabinCategoryGroupInfo[0].cabinCategories[0].pricing.agencyMarkupVal,
                        Fee: response.cruise.cabinCategoryGroupInfo[0].cabinCategories[0].pricing.agencyFee,
                        Commission: response.cruise.cabinCategoryGroupInfo[0].cabinCategories[0].pricing.commission
                    };
                    onUpdateSelectedInfoData(templateData.templateDataId, response.cruise.avlCarpetId, response.cruise.srcCarpetId, productSelection, productPriceDetail, true, false);
                }

                // setto DefaultAlternativePrice
                let setDefaultAlternativePrice = selectedProductData && selectedProductData.AlternativeInfo && !selectedProductData.AlternativeInfo.SelectedTotalPrice;
                if (setDefaultAlternativePrice) {
                    let productSelection = `${response.cruise.cabinCategoryGroupInfo[0].cabinCategories[0].productCode}`;
                    let productPriceDetail = {
                        DisplayPrice: response.cruise.cabinCategoryGroupInfo.cabinCategories[0].pricing.displayPrice,
                        NetPrice: response.cruise.cabinCategoryGroupInfo.cabinCategories[0].pricing.displayNet,
                        Markup: response.cruise.cabinCategoryGroupInfo.cabinCategories[0].pricing.agencyMarkupVal,
                        Fee: response.cruise.cabinCategoryGroupInfo.cabinCategories[0].pricing.agencyFee,
                        Commission: response.cruise.cabinCategoryGroupInfo.cabinCategories[0].pricing.commission
                    };
                    onUpdateSelectedInfoData(templateData.templateDataId, response.cruise.avlCarpetId, response.cruise.srcCarpetId, productSelection, productPriceDetail, true, false);
                }

                // call discount pax
                callGetCruiseDiscountPax(response.cruise.avlCarpetId, response.cruise.cruiseCode, response.cruise.bestPriceCabinCategoryCode);
            }
        }

        setIsError(isError);
        if (isError) {
            onUpdateSelectedInfoData(templateData.templateDataId, null, null, null, null, true, true);
        }

        setIsLoading(false);
        onFinishSearch(templateData.templateDataId, false);
    }

    // cabin categories
    function openCruiseCabinCategories(e) {
        if (e) e.preventDefault();

        if (showCruiseCabinCategoriesChoose) {
            setShowCruiseCabinCategoriesChoose(false);
            return;
        }

        setShowCruiseCabinCategoriesChoose(true);
    }
    function onSelectCabinCategory(selectedCabinCategory) {
        let productSelection = selectedCabinCategory.cabinCategoryProductCode;
        let productPriceDetail = "";

        for (let i = 0; i < cruiseCabinCategory.cabinCategoryGroupInfo.length; i++) {
            let matchingCabinCategory = cruiseCabinCategory.cabinCategoryGroupInfo[i].cabinCategories.filter(x => x.productCode === productSelection);
            if (matchingCabinCategory && matchingCabinCategory.length > 0) {
                productPriceDetail = {
                    DisplayPrice: matchingCabinCategory[0].pricing.displayPrice,
                    NetPrice: matchingCabinCategory[0].pricing.netPrice,
                    Markup: matchingCabinCategory[0].pricing.agencyMarkupVal,
                    Fee: matchingCabinCategory[0].pricing.agencyFee,
                    Commission: matchingCabinCategory[0].pricing.commission
                };
                break;
            }
        }

        onUpdateSelectedInfoData(templateData.templateDataId, cruiseCabinCategory.avlCarpetId, cruiseCabinCategory.srcCarpetId, productSelection, productPriceDetail, true, false);
        setShowCruiseCabinCategoriesChoose(false);
    }

    // discount pax
    async function callGetCruiseDiscountPax(carpetId, cruiseCode, cabinCategoryCode) {
        let requestParam = {
            CultureCode: cultureName,
            CarpetId: carpetId,
            CruiseCode: cruiseCode,
            CabinCategoryCode: cabinCategoryCode
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestParam) };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}Cruise/GetCruiseDiscountPax`, requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response && response.success) {
                setDiscountPaxCodes(response.discountPaxesCodes);
            }
        }
    }

    function onSelectDiscounts(codes) {
        if (codes && codes.length > 0) {
            setSelectedDiscountPaxCodes(codes);
        } else {
            setSelectedDiscountPaxCodes(null);
        }

        setIsLoading(true);
        setShowCruiseCabinCategoriesChoose(false);
        callTemplateStartSearch(codes);
    }

    // render
    const renderSelectedOption = () => {
        if (isError)
            return (<h6 className="red">Prodotto non disponibile.</h6>);

        if (!cruiseCabinCategory || !cruiseCabinCategory.cabinCategoryGroupInfo || cruiseCabinCategory.cabinCategoryGroupInfo.length === 0)
            return (<></>);

        if ((!isAlternative && !isOptional) || isOptional) {
            return (
                <React.Fragment>
                    <CruiseItemList
                        item={cruiseCabinCategory}
                        showPriceBar={false}
                        showCost={false}
                        extraParam={{
                            templateInfo:
                            {
                                templateId: templateData.templateId,
                                selectedProductPrice: (selectedProductData && selectedProductData.SelectedInfo && selectedProductData.SelectedInfo.ProductPriceDetail) ? selectedProductData.SelectedInfo.ProductPriceDetail.DisplayPrice : null,
                                selectedProductCode: (selectedProductData && selectedProductData.SelectedInfo && selectedProductData.SelectedInfo.ProductSelection) ? selectedProductData.SelectedInfo.ProductSelection : null,
                                showSelectButton: false,
                                isPrimary: true
                            }
                        }}
                    />
                </React.Fragment>
            );
        } else {
            return (<></>);
        }
    }


    return (
        <>
            {templateData &&
                <React.Fragment>
                    {debug &&
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <p style={{ width: '500px' }}>
                                {selectedProductData && JSON.stringify(selectedProductData)}
                            </p>
                        </div>
                    }

                    {!isAlternative && !isOptional &&
                        <React.Fragment>
                            {isLoading && <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                <div className="rounded" style={{ backgroundImage: "url(" + (templateData.product.thumbUrl ? encodeURI(templateData.product.thumbUrl) : imageOnLoadCruise()) + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100%" }}>
                                </div>
                            </div>}

                            <div className={isLoading ? "col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9" : "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"}>
                                <div className="myBook-card-body">
                                    {isLoading
                                        ? (
                                            <div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <span><data m3lab="Product.Cruise.Item">{t(`Product:Cruise:Item`)}</data></span>
                                                    </div>

                                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 px-4">
                                                        <M3Pax adt={searchParameters.totalAdults} chd={searchParameters.totalChildren} inf={searchParameters.totalInfants} />
                                                    </div>
                                                </div>
                                                <div className="h5">
                                                    <var>{templateData.product.name}</var>
                                                </div>
                                                <div>
                                                    <div className="spinner-border spinner-border-sm text-primary" role="status" style={{ 'fontSize': '12px' }}></div> {t(`Template:Loading`)}
                                                </div>
                                            </div>
                                        )
                                        : (
                                            <>
                                                {/* Menu Functions */}
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div className="btn-group text-right float-end pr-2">
                                                            <button id={templateData.templateDataId} className="btn p-0 m-0" type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                                <data m3ico="MenuFunctions">
                                                                    <M3Icon iconName="MenuFunctions" />
                                                                </data>
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="menuItem1">
                                                                {cruiseCabinCategory &&
                                                                    <li>
                                                                        <a id={`aShowCruiseCabins_${templateData.templateDataId}`} href className="dropdown-item" onClick={e => openCruiseCabinCategories(e)}>
                                                                            <data m3ico="Cruise icon-10"><M3Icon iconName="Cruise" externalClass="icon-10 small text-gray-600" /></data>
                                                                            <label><data m3lab="ActionMenu.CruiseCabinChange">{t(`ActionMenu:CruiseChangeCabin`)}</data></label>
                                                                        </a>
                                                                    </li>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        {renderSelectedOption()}
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                    }

                    {/*Cruise Cabin*/}
                    {showCruiseCabinCategoriesChoose &&
                        <>
                            {discountPaxCodes && discountPaxCodes.length > 0 &&
                                <CruiseDetailGroupCabinFilter
                                    promotionsFilter={null}
                                    onSelectPromoFilter={null}
                                    discountPaxesCodes={discountPaxCodes}
                                    onSelectDiscounts={onSelectDiscounts}
                                    resetFilter={null}
                                    avlCarpetId={avlCarpetId}
                                />
                            }

                            {cruiseCabinCategory && cruiseCabinCategory.cabinCategoryGroupInfo && cruiseCabinCategory.cabinCategoryGroupInfo.length > 0 &&
                                cruiseCabinCategory.cabinCategoryGroupInfo.map((ccGroup, key) => {
                                    let cabins = ccGroup.cabinCategories.filter(x => x.productCode !== selectedProductData.SelectedInfo.ProductSelection);
                                    ccGroup.cabinCategories = cabins;

                                    return (
                                        <CruiseDetailCabinCategoryGroup
                                            key={key}
                                            cabinCategoryGroup={ccGroup}
                                            showPriceBar={false}
                                            showCost={false}
                                            templateInfo={{
                                                templateInfo:
                                                {
                                                    templateId: templateData.templateId,
                                                    selectedProductPrice: (selectedProductData && selectedProductData.SelectedInfo && selectedProductData.SelectedInfo.ProductPriceDetail) ? selectedProductData.SelectedInfo.ProductPriceDetail.DisplayPrice : null,
                                                    selectedProductCode: (selectedProductData && selectedProductData.SelectedInfo && selectedProductData.SelectedInfo.ProductSelection) ? selectedProductData.SelectedInfo.ProductSelection : null
                                                }
                                            }}
                                            onClickBook={onSelectCabinCategory}
                                        />
                                    )
                                })
                            }
                        </>
                    }
                </React.Fragment>
            }
        </>
    );
}