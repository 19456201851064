import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Cookies from 'universal-cookie';
import { MenuBarVertical } from '../MenuBarVertical';
import { M3Icon } from '../../Common/M3Icon';
import { GiftCard } from './components/GiftCard';
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { isValidGiftCard, isUsedGiftCard } from './utils/giftCardUtil';

export const GiftCards = () => {
    const { t } = useTranslation();

    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [giftCards, setGiftCards] = useState([]);
    const [giftCardsFiltered, setGiftCardsFiltered] = useState([]);
    const [giftCardsToShow, setGiftCardsToShow] = useState([]);

    const [giftCardCode, setGiftCardCode] = useState(null);
    const [giftCardRegisterLoading, setGiftCardRegisterLoading] = useState(false);
    const [giftCardRegisterRes, setGiftCardRegisterRes] = useState(null);

    // paging
    const itemsPerPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(0);

    // filtering
    const [filterActive, setFilterActive] = useState({ toUse: false, isUsed: false });


    useEffect(() => {
        const getGiftCards = async () => {
            const response = callGetGiftCards();
        }

        setIsLoading(true);
        getGiftCards();
    }, []);


    async function callGetGiftCards() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'discount/getgiftcards', requestOption);

        var myItems = [];

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            myItems = response;

            setGiftCards(myItems);
            setGiftCardsFiltered(myItems);
            setIsError(false);
        } else {
            setGiftCards(myItems);
            setGiftCardsFiltered(myItems);
            setIsError(true);
        }

        renderPaginator(myItems, pageSelected);
        paginationGiftCards(myItems, 0);

        setIsLoading(false);
    }

    const handlePageClick = async e => {
        paginationGiftCards(giftCardsFiltered, e.selected);
    };

    function renderPaginator(items, pageSelected) {
        setPageCount(Math.ceil(items.length / itemsPerPage));
        setPageSelected(pageSelected);
    }

    const handleChangeToUse = event => {
        var myFilter = filterActive;
        myFilter.toUse = event.target.checked;

        setFilterActive(myFilter);
        filterGiftCards();
    };

    const handleChangeIsUsed = event => {
        var myFilter = filterActive;
        myFilter.isUsed = event.target.checked;

        setFilterActive(myFilter);
        filterGiftCards();
    };

    function filterGiftCards() {
        var myItemsFiltered = giftCards.filter(gc => {
            if (filterActive.toUse) {
                if (isValidGiftCard(gc))
                    return true;
            }

            if (filterActive.isUsed) {
                if (isUsedGiftCard(gc))
                    return true;
            }

            return !filterActive.isUsed && !filterActive.toUse;
        });

        setGiftCardsFiltered(myItemsFiltered);

        renderPaginator(myItemsFiltered, 0);
        paginationGiftCards(myItemsFiltered, 0);
    }

    function paginationGiftCards(myItems, pagePosition) {
        var startFrom = 0;
        var endTo = itemsPerPage;
        if (pagePosition > 0) {
            startFrom = itemsPerPage + pagePosition - 1;
            endTo = itemsPerPage + itemsPerPage + pagePosition;
        }

        setGiftCardsToShow(myItems.slice(startFrom, endTo));
        setPageSelected(pagePosition);

        window.scrollTo(0, 0);
    }

    const registerGiftCard = (e) => {
        if (e) e.preventDefault();
        setGiftCardRegisterLoading(true);
        callRegisterGiftCard();
    }
    async function callRegisterGiftCard() {
        const inputData = { GiftCardCode: giftCardCode };
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'discount/registergiftcard', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            let registerSuccess = response.success;
            setGiftCardRegisterRes(registerSuccess);

            if (registerSuccess === true) {
                callGetGiftCards();
            }
        }

        setGiftCardRegisterLoading(false);
    }

    return (
        <>
            <MenuBarVertical activeAnchorId="aBuoniSconto" />

            <div className="section section-lg bg-gray-200 page-content" id="content">
                <div className="container">
                    {
                        isLoading
                            ? (<Loading textMsg={t(`giftCard:GiftCardLoading`)} />)
                            : (
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card border-light p-0 p-md-4 mb-4">
                                            <div className="card-header">

                                                <h5><M3Icon iconName="CreditCard" /> {t(`giftCard:GiftCardList`)}</h5>

                                                <div className="mt-5">
                                                    <h6>{t(`giftCard:GiftCardRegister`)}</h6>
                                                    <p>{t(`giftCard:GiftCardRegisterMsg`)}</p>
                                                    <div className="row mt-1">
                                                        <div className="col-12 col-md-6 mt-1">
                                                            <input id="giftCardCode" type="text" className="form-control" placeholder="Gift Card" onChange={e => setGiftCardCode(e.target.value)} />
                                                        </div>
                                                        <div className="col-12 col-md-6 mt-1">
                                                            {!giftCardRegisterLoading && <button type="submit" className="btn btn-dark" onClick={e => registerGiftCard(e)}>{t(`Button:Save`)}</button>}
                                                            {giftCardRegisterLoading && (
                                                                <>
                                                                    <div className="spinner-border spinner-border-sm text-dark mt-4" role="status"></div>
                                                                    <span className="sr-only pl-2">{t(`MyBook:Loading`)}</span>
                                                                </>
                                                            )}
                                                        </div>
                                                        {giftCardRegisterRes !== null && (
                                                            <div className="col-12 col-md-6 mt-1">
                                                                {giftCardRegisterRes
                                                                    ? (<span className="penalty free">{t(`giftCard:GiftCardRegisterOK`)}</span>)
                                                                    : (<span className="penalty">{t(`giftCard:GiftCardRegisterKO`)}</span>)
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="mt-5">
                                                    <p className="text-sm mb-0">{t(`giftCard:GiftCardFilter`)}</p>
                                                    <div className="mt-1">
                                                        <div className="btn-group" aria-label="">
                                                            <input type="checkbox" className="btn-check" id="btnradio_touse" name="btnradio_touse" defaultChecked={false} onChange={handleChangeToUse} />
                                                            <label className="btn btn-outline-success btn-sm" htmlFor="btnradio_touse">{t("MyBook:FromUsing")} </label>

                                                            <input type="checkbox" className="btn-check" id="btnradio_isused" name="btnradio_isused" defaultChecked={false} onChange={handleChangeIsUsed} />
                                                            <label className="btn btn-outline-success btn-sm" htmlFor="btnradio_isused">{t("MyBook:Used")} </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isError
                                                    ? <Error textMsg={t(`giftCard:GiftCardError`)} />
                                                    : giftCardsToShow && giftCardsToShow.length > 0
                                                        ? (
                                                            <>
                                                                <ul className="list-group list-group-flush">
                                                                    {
                                                                        giftCardsToShow.map((giftCard, index) => {
                                                                            return <GiftCard key={index} giftCard={giftCard} cultureName={cultureName} />;
                                                                        })
                                                                    }
                                                                </ul>

                                                                <nav aria-label="Page navigation comments" className="mt-4">
                                                                    <ReactPaginate
                                                                        breakLabel="..."
                                                                        nextLabel={t('pagination:nextLabel')}
                                                                        onPageChange={handlePageClick}
                                                                        pageRangeDisplayed={5}
                                                                        pageCount={pageCount}
                                                                        previousLabel={t('pagination:previousLabel')}
                                                                        renderOnZeroPageCount={null}
                                                                        forcePage={pageSelected}

                                                                        breakClassName="page-item"
                                                                        breakLinkClassName="page-link"
                                                                        marginPagesDisplayed={2}
                                                                        containerClassName="pagination justify-content-center"
                                                                        pageClassName="page-item"
                                                                        pageLinkClassName="page-link"
                                                                        previousClassName="page-item"
                                                                        previousLinkClassName="page-link"
                                                                        nextClassName="page-item"
                                                                        nextLinkClassName="page-link"
                                                                        activeClassName="active"
                                                                    />
                                                                </nav>
                                                            </>
                                                        ) : (
                                                            <ul className="list-group list-group-flush">
                                                                <li className="list-group-item border-bottom">
                                                                    <h6>{t(`giftCard:GiftCardNotFound`)}</h6>
                                                                </li>
                                                            </ul>
                                                        )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>
            </div>
        </>
    );
}