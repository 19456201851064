import React from 'react';
import { useTranslation } from 'react-i18next';
import configData from "../../../../appsettings.json";
import { Gallery } from '../../Gallery';
import { ListSearchEngineCV } from '../../SearchEngine/compactView/ListSearchEngineCV';
import { ActivityDetailFavourite } from './../../Activity/components/ActivityDetailFavourite';
import { ActivityDetailHeader } from './../../Activity/components/ActivityDetailHeader';
import { TourDetailCategoryInfo } from './TourDetailCategoryInfo';
import { TourDetailDescriptions } from './TourDetailDescriptions';
import { ActivityDetailDescriptions } from '../../Activity/components/ActivityDetailDescriptions';

export const TourStaticContainer = ({ tourId, tourDetail, modeView, extraParamSearch, searchParameters, isNotEngine }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="card border-light bg-white p-1">
                {
                    modeView !== 'modal' && <div className="row px-2">
                        <ActivityDetailFavourite activityDetail={tourDetail} />
                    </div>
                }
                {
                    <div className="row p-3">
                        <Gallery gallery={tourDetail.images} alternativeText={tourDetail.name} modeView={modeView} productType={configData.Settings.Products.Tour.IdTipoProdotto } />
                    </div>
                }
                <div className="row px-1">
                    <ActivityDetailHeader activityDetail={tourDetail} />

                    <ActivityDetailDescriptions
                        activityDetail={tourDetail}
                        showOnlyGeneral="true" />
                </div>
                <div className="row">
                    <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                        <TourDetailDescriptions tourDetail={tourDetail} />
                    </div>
                    <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                        <TourDetailCategoryInfo
                            tourId={tourId}
                            tourDetail={tourDetail}
                        />
                        {
                            modeView !== 'modal' && !isNotEngine && <>
                                <ListSearchEngineCV
                                    imageUrl="https://cdn.travelplace.ch/travelplace40/img/maps_list.jpg"
                                    searchParameters={searchParameters}
                                    productType={configData.Settings.Products.Tour.IdTipoProdotto}
                                    extraParams={extraParamSearch}
                                    typeSearch="AVL" />
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}