import React, { useState, useEffect } from 'react';
import configData from "../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { getCDNLogo } from "../../js/CDNUtility";
import { M3Icon } from "../Common/M3Icon";

export const MenuBarVertical = ({ activeAnchorId }) => {
    const { t } = useTranslation();

    const [currentUser, setCurrentUser] = useState(() => {
        const jCurrentUser = localStorage.getItem("CurrentUser");
        if (jCurrentUser !== null && jCurrentUser !== undefined) {
            let currentUser = JSON.parse(jCurrentUser);
            return currentUser;
        }

        return null;
    });

    useEffect(() => {
        let selectedMenuAnchor = document.getElementById(activeAnchorId)
        if (!selectedMenuAnchor.className.includes("active"))
            selectedMenuAnchor.className += " active";

        let contentDiv = document.getElementById("content");
        let sidebarDiv = document.getElementById("sidebar");
        let minHeight = '400px';
        if (sidebarDiv) {
            let sidebarHeight = document.getElementById('sidebar').offsetHeight - 150;
            minHeight = sidebarHeight + 'px';
        }

        if (contentDiv) {
            contentDiv.style.minHeight = minHeight;
        }

    }, [activeAnchorId]);

    const getUsername = () => {
        return currentUser !== null ? currentUser.username : "";
    }

    const showMenuEntry = (name) => {
        if (currentUser === null || currentUser.roles === null || currentUser.roles.length === 0)
            return false;
        var settIdStakeholdersWithoutGiftMenu = configData.Settings.MyBook.IdStakeholdersWithoutGiftMenu;
        var IdStakeholdersWithoutGiftMenu = [];
        var enabledGiftCardMenu = true;
        if (settIdStakeholdersWithoutGiftMenu) {
            IdStakeholdersWithoutGiftMenu = settIdStakeholdersWithoutGiftMenu.split(",");
            if (IdStakeholdersWithoutGiftMenu.includes(currentUser.stakeholderId)) {
                enabledGiftCardMenu = false;
            }
        }
        switch (name) {
            case "orders": return currentUser.roles.indexOf("US_TravelAgent") > -1 || currentUser.roles.indexOf("US_TravelAgentManager") > -1;
            case "markup": return currentUser.roles.indexOf("US_ShowMenu_Markup") > -1;
            case "quotations": return currentUser.roles.indexOf("US_ShowMenu_Preventivi") > -1;
            case "quotationsto": return currentUser.roles.indexOf("US_ShowMenu_PreventiviTO") > -1;
            case "giftcards": return enabledGiftCardMenu && currentUser.roles.indexOf("US_ShowMenu_BuoniSconto") > -1;
            case "rubrica": return currentUser.roles.indexOf("US_Rubrica") > -1;
        }
    }

    return (
        <div className="section section-lg vertical-nav bg-white mt-6" id="sidebar">
            <div className="py-4 px-3 mb-4 ">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 d-none d-lg-block">
                            <div className="nav text-center p-2">
                                <div className="card-body p-2">
                                    <h2 className="h5 font-weight-normal text-center mt-3 mb-0" style={{ overflow: 'hidden', maxWidth: '170px' }}>{getUsername()}</h2>
                                    <div className="list-group dashboard-menu list-group-sm mt-4 text-left">
                                        {
                                            showMenuEntry("orders") && <div className="border-0 mb-2">
                                                <a href="mybook/orders" id="aOrdini" className="d-inline-block list-group-item list-group-item-action border-0 text-decoration-none text-dark">
                                                    {t("MyBook:Orders")}
                                                    <M3Icon iconName="ChevronRight" externalClass="d-inline-block float-end text-dark" />
                                                </a>
                                            </div>
                                        }
                                        {
                                            showMenuEntry("markup") && <div className="border-0 mb-2">
                                                <a href="mybook/markup" id="aMarkup" className="d-inline-block list-group-item list-group-item-action border-0 text-decoration-none text-dark">
                                                    {t("MyBook:Markup")}
                                                    <M3Icon iconName="ChevronRight" externalClass="d-inline-block float-end text-dark" />
                                                </a>
                                            </div>
                                        }
                                        {
                                            showMenuEntry("quotations") && <div className="border-0 mb-2">
                                                <a href="mybook/quotations" id="aQuotations" className="d-inline-block list-group-item list-group-item-action border-0 text-decoration-none text-dark">
                                                    {t("MyBook:Quotations")}
                                                    <M3Icon iconName="ChevronRight" externalClass="d-inline-block float-end text-dark" />
                                                </a>
                                            </div>
                                        }
                                        {
                                            showMenuEntry("quotationsto") && <div className="border-0 mb-2">
                                                <a href="mybook/quotationsto" id="aQuotationsTO" className="d-inline-block list-group-item list-group-item-action border-0 text-decoration-none text-dark">
                                                    {t("MyBook:QuotationsTO")}
                                                    <M3Icon iconName="ChevronRight" externalClass="d-inline-block float-end text-dark" />
                                                </a>
                                            </div>

                                        }
                                        {
                                            showMenuEntry("giftcards") && <div className="border-0 mb-2">
                                                <a href="mybook/giftcards" id="aBuoniSconto" className="d-inline-block list-group-item list-group-item-action border-0 text-decoration-none text-dark">
                                                    {t("MyBook:GiftCards")}
                                                    <M3Icon iconName="ChevronRight" externalClass="d-inline-block float-end text-dark" />
                                                </a>
                                            </div>
                                        }
                                        <div className="border-0 mb-2">
                                            <a href="mybook/profile" id="aProfilo" className="d-inline-block list-group-item list-group-item-action border-0 text-decoration-none text-dark">
                                                {t("MyBook:Profile")}
                                                <M3Icon iconName="ChevronRight" externalClass="d-inline-block float-end text-dark" />
                                            </a>
                                        </div>
                                        {
                                            showMenuEntry("rubrica") && <div className="border-0 mb-2">
                                                <a href="mybook/rubrica" id="aRubrica" className="d-inline-block list-group-item list-group-item-action border-0 text-decoration-none text-dark">
                                                    {t("MyBook:AddressBook")}
                                                    <M3Icon iconName="ChevronRight" externalClass="d-inline-block float-end text-dark" />
                                                </a>
                                            </div>
                                        }
                                        <div className="border-0 mb-2">
                                            <a href={configData.Settings.OldWebSite_BaseUrl} id="aWebsite" className="d-inline-block list-group-item list-group-item-action border-0 text-decoration-none text-dark">
                                                {t("MyBook:Website")}
                                                <M3Icon iconName="ChevronRight" externalClass="d-inline-block float-end text-dark" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}