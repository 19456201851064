import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { OsmTravelPlaceMap } from '../../TravelPlaceMap/OsmTravelPlaceMap';
import { formatPrice } from '../../../js/Utils';
import { searchCarpetParameter } from '../../../js/Constant';
import configData from "../../../appsettings.json";
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';
import { ProductFilterByPrice } from '../Common/Filter/ProductFilterByPrice';
import { M3StructureStars } from '../../Common/M3StructureStars';
import { ProductFilter } from '../Common/Filter/ProductFilter';
import { ProductFilterByName } from '../Common/Filter/ProductFilterByName';
import { M3Icon } from "../../Common/M3Icon";
import { FilterAreaMap } from '../../TravelPlaceMap/FilterAreaMap';
import { StructureSearchEngine } from "../SearchEngine/StructureSearchEngine";
import L from "leaflet";
import { getStructureDetailLink } from '../../../js/ProductService.js';

export const HotelMap = () => {
    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");
    const { t } = useTranslation();
    const [featureFiltered, setFeatureFiltered] = useState([]);
    const [mapArr, setMapArr] = useState(null);

    const [showFilterArea, setShowFilterArea] = useState(false);
    const [sizeInMeters, setSizeInMeters] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMap, setIsLoadingMap] = useState(true);
    const [isError, setIsError] = useState(false);
    const [items, setItems] = useState({});

    // mappe
    const geoJsonRef = useRef();
    const [map, setMap] = useState(null);

    //const productList = JSON.parse(localStorage.getItem("ProductList"));
    let params = new URLSearchParams(window.location.search);
    let carpetId = params.get('cId');
    let clientFlowId = params.get('cfId');

    const [filterSelected, setFilterSelected] = useState({
        structureName: null,
        categories: [],
        mealplans: [],
        amenities: [],
        suppliers: [],
        rangePrice: null,
        pageNumber: 0,
        orderBy: searchCarpetParameter.hotel.orderBy
    });

    const [totalProduct, setTotalProduct] = useState(0);

    // filtri
    const [isResetSelectedItem, setIsResetSelectedItem] = useState(false);
    const [isOrderAZ, setIsOrderAZ] = useState(true);
    const [isChangeAzDisabled, setIsChangeAzDisabled] = useState(true);
    const [filterValues, setFilterValues] = useState(null);
    const [rangePriceInfo, setRangePriceInfo] = useState({
        min: "0",
        max: "5000",
        step: "50",
        currency: "EUR"
    });

    useEffect(() => {
        getStructureList(filterSelected, 0);
        getStructureListFilters();
    }, []);

    useEffect(() => {
        if (items && items.length > 0) {
            setIsLoading(false);

            setTotalProduct(items.length);

            let featureArr = [];
            items.forEach(function (product, index) {
                featureArr.push({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [product.longitude, product.latitude]
                    },
                    properties: {
                        StructureId: product.structureId,
                        StructureName: product.name,
                        StructureCategory: product.category,
                        StructureThumbnail: product.thumbUrl && product.thumbUrl.length > 0 && product.thumbUrl[0],
                        BestPriceAmount: product.pricing.displayPrice,
                        BestPriceCurrency: product.pricing.valutaCliente,
                        BestMealplan: null,
                        BestStatus: "Price",
                        Address: product.address
                    }
                });
            });

            setMapArr({
                type: "FeatureCollection",
                features: featureArr
            });
            setIsLoadingMap(true);
        }
        else {
            setMapArr(null);
        }
    }, [items]);


    const clusterMarkerPopup = (childMarkers) => {
        var htmlContainer = "<div>";
        htmlContainer += '<div style="display:block; max-height: 400px; width: 250px; overflow-x: hidden;" />';
        childMarkers.forEach(function (m) {
            if (m.feature?.properties?.StructureName) {
                htmlContainer += "<div><label>Name: " + m.feature.properties.StructureName + " </label></div>"
            }
            if (m.feature?.properties?.htmlDescriptionBox) {

                htmlContainer += m.feature.properties.htmlDescriptionBox;
            }
            htmlContainer += "<hr/>"
        });
        htmlContainer += "</div>";
        htmlContainer += "</div>";
        return htmlContainer;
    }

    const markerPopup = (feature) => {
        var htmlContainer = '<div data-id="'+ feature.properties.StructureId +'" class="divHotel cursor-pointer" style="display: block; max-height: 400px; width: 250px; overflow-x: hidden;" />';
        
        if (feature.properties.StructureThumbnail) {
            htmlContainer += "<div><img src='" + feature.properties.StructureThumbnail + "' alt='' height='250px'> </div>"
        }
        if (feature.properties?.StructureName) {
            htmlContainer += "<div class='mt-2'><h6>" + feature.properties.StructureName + " </h6></div>"
        }
        if (feature.properties?.Address) {
            htmlContainer += "<div class='mt-2'><span>" + feature.properties.Address + " </span></div>"
        }
        if (feature.properties?.BestPriceAmount) {
            htmlContainer += "<div class='mt-3 text-right'><h6>" + formatPrice(feature.properties.BestPriceAmount, feature.properties.BestPriceCurrency, cultureCode) + " </h6></div>"
        }

        if (feature.properties?.ItineraryId) {
            htmlContainer += "<div><label>Id: " + feature.properties.ItineraryId + " </label></div>"
        }

        if (feature.properties?.ItineraryName) {
            htmlContainer += "<div><label>Name: " + feature.properties.ItineraryName + " </label></div>"
        }

        if (feature.properties?.ItineraryPointId) {
            htmlContainer += "<div><label>Punto: " + feature.properties.ItineraryPointId + " </label></div>"
        }

        htmlContainer += "</div>";
        return htmlContainer;
    }


    const updateFeatures = (features) => {
        setFeatureFiltered(features);
    }

    async function getStructureList(filterSelectedUpdate, pageNumber) {
        let requestParam = {
            cultureCode: cultureCode,
            carpetId: carpetId,
            pageNumber: pageNumber,
            pageSize: 1000,
            showCost: true,
            structureName: filterSelectedUpdate.structureName,
            categories: filterSelectedUpdate.categories,
            mealplans: filterSelectedUpdate.mealplans,
            services: filterSelectedUpdate.services,
            suppliers: filterSelectedUpdate.suppliers,
            rangePrice: filterSelectedUpdate.rangePrice,
            cityLocations: filterSelectedUpdate.cityLocations,
            orderBy: filterSelectedUpdate.orderBy
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };
        
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}structure/getstructurelist`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setItems(response.structures);
        }
        else {
            setIsError(true);
        }
    }

    async function getStructureListFilters() {
        let requestParam = {
            cultureCode: cultureCode,
            carpetId: carpetId
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}structure/getstructurelistfilters`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setFilterValues(response);

            if (response.rangePricesInfo) {
                setRangePriceInfo({
                    ...rangePriceInfo,
                    min: response.rangePricesInfo.min,
                    max: response.rangePricesInfo.max,
                    currency: response.rangePricesInfo.name
                });
            }

            if (response.hotelSearchParameter && response.hotelSearchParameter.destinationType !== "Destination") {
                filterSelected.orderBy = "8"; // distanza
                setIsChangeAzDisabled(false);
                setFilterSelected(filterSelected);
            }
            getStructureList(filterSelected, 0);
        }
    }

    function onApplyFilterPrice(rangePrice) {
        let filterSelectedUpdate = {
            ...filterSelected,
            rangePrice: rangePrice
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    // metodi filtro
    function callFilterValues(filterSelectedUpdate) {
        setIsLoadingMap(false);
        getStructureList(filterSelectedUpdate, 0);
    }
    function onApplyFilter(selectedItems, type) {
        let filterSelectedUpdate = { ...filterSelected };

        switch (type) {
            case 'StructureStars': {
                filterSelectedUpdate.categories = selectedItems;
                break;
            }
            case 'StructureMealplans': {
                filterSelectedUpdate.mealplans = selectedItems;
                break;
            }
            case 'StructureAmenities': {
                filterSelectedUpdate.services = selectedItems;
                break;
            }
            case 'StructureSuppliers': {
                filterSelectedUpdate.suppliers = selectedItems;
                break;
            }
            case 'StructureCityLocations': {
                filterSelectedUpdate.cityLocations = selectedItems;
                break;
            }
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }
    function onApplyFilterName(name) {
        let filterSelectedUpdate = {
            ...filterSelected,
            structureName: name
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function getFilterOrderByValue(orderValue) {
        let res = null;

        searchCarpetParameter.hotel.orderByValues.forEach(function (order) {
            if (order.descendingValue === parseInt(orderValue)) {
                res = order.value;
            }
        });

        if (res == null) {
            res = orderValue;
        }

        return res;
    }

    function onChangeSortBy(event) {
        let orderByValue = event.target.selectedOptions[0].value;

        let filterSelectedUpdate = {
            ...filterSelected,
            orderBy: orderByValue
        };

        if (orderByValue === "0" || orderByValue === "1") {
            setIsChangeAzDisabled(true);
        }
        else {
            setIsChangeAzDisabled(false);
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    const onChangeAZ = (e) => {
        setIsOrderAZ(!isOrderAZ);

        if (filterSelected.orderBy === "3") { // nome az
            let filterSelectedUpdate = {
                ...filterSelected,
                orderBy: "2"
            };

            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
        else if (filterSelected.orderBy === "2") { // nome za
            let filterSelectedUpdate = {
                ...filterSelected,
                orderBy: "3"
            };

            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
        else if (filterSelected.orderBy === "4") { // distanza az
            let filterSelectedUpdate = {
                ...filterSelected,
                orderBy: "8"
            };

            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
        else if (filterSelected.orderBy === "8") { // distanza za
            let filterSelectedUpdate = {
                ...filterSelected,
                orderBy: "4"
            };

            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
    }

    function handleClickPointOnMap() {
        setTimeout(function () {
            let elements = document.getElementsByClassName("divHotel");
            for (let i = 0; i < elements.length; i++) {
                elements[i].addEventListener("click", function (e) {
                    if (e.currentTarget.dataset.id)
                        clickSearch(parseInt(e.currentTarget.dataset.id));
                });
            }
        }, 200);
    }

    function handleClick(item) {
        const markerBounds = L.latLngBounds([item.latLng]);
        map.fitBounds(markerBounds);

        var marker = geoJsonRef.current.getLayers().find(x => x.options.id === item.id);
        if (!marker)
            return;
                
        if (marker.getLatLng) {
            const latLngs = [marker.getLatLng()];
            const markerBounds = L.latLngBounds(latLngs);
            map.fitBounds(markerBounds);
        } else if (marker.getBounds) {
            const markerBounds = marker.getBounds();
            map.fitBounds(markerBounds);
        }

        marker.openPopup();

        handleClickPointOnMap();
    }

    const renderProduct = (product) => {
        return (
            <>
                <div className="border rounded mb-2 p-1 cursor-pointer" data-id={product.id} onClick={() => handleClick(product)} >
                    <span style={{ fontSize: "13px" }}>{product.name}</span>
                    <div className="d-flex justify-content-sm-between">
                        <div>
                            <M3StructureStars category={product.category} />
                        </div>
                        <span style={{ fontSize: "13px", "fontWeight": "700" }}>{formatPrice(product.price, product.currency, cultureCode)}</span>
                    </div>
                </div>
            </>
        );
    }

    async function clickSearch(structureId, filters = null) {
        let extraParam = '';
        if (filters && filters.length > 0) {
            for (let i = 0; i < filters.length; i++) {
                if (filters[0].type === 'MEALPLANID' && filters[0].value)
                    extraParam += '&sMealplanId=' + filters[0].value

            }
        }

        let url = await getStructureDetailLink(filterValues.hotelSearchParameter, structureId, carpetId, clientFlowId, false);
        if (extraParam)
            url += extraParam;

        if (filterSelected.suppliers && filterSelected.suppliers.length > 0) {
            let arrTmp = [];
            filterSelected.suppliers.forEach((supplier) => {
                arrTmp.push(supplier.id);
            });

            url += "&suppliersIds=" + arrTmp.join();
        }
        if (url)
            window.open(url, '_blank');
    }


    return (
        <>
            {isLoading && <div className="col-12"><Loading textMsg={t('Product:Structure:LoadingList')} /></div>}
            {!isLoading && isError && <div className="col-12"><Error textMsg={t('Product:Structure:Error')} /></div>}
            {!isLoading && !isError &&
                <div className="container mw-100" >
                    <div className="row">
                        <div className="col-12">
                            <StructureSearchEngine
                                searchParameters={filterValues.hotelSearchParameter}
                                typeSearch="SRC"
                                modeView="MODAL"
                                isFromMap={true}
                            />
                        </div>
                    </div>
                    <div className="row p-2">
                        <div className="col-3">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            <b><data m3lab="General.Filters">{t(`General:Filters`)}</data></b>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body" style={{ overflowY: "scroll", maxHeight: "500px" }}>

                                        { /* Area */}
                                        <FilterAreaMap
                                            onShowFilterArea={setShowFilterArea}
                                            onSizeInMeters={setSizeInMeters }
                                        />

                                        { /* Price */}
                                        <ProductFilterByPrice
                                            rangePriceInfo={rangePriceInfo}
                                            onApplyFilter={onApplyFilterPrice}
                                            resetSelectedItem={isResetSelectedItem}
                                            defaultShowFilter={false}
                                        />

                                        { /* Stelle */}
                                        <ProductFilter
                                            items={filterValues.categories}
                                            label="Product:Structure:Filter:Star:Title"
                                            type="StructureStars"
                                            onApplyFilter={onApplyFilter}
                                            resetSelectedItem={isResetSelectedItem}
                                            defaultShowFilter={false}
                                        />

                                        { /* Trattamento */}
                                        <ProductFilter
                                            items={filterValues.mealplans}
                                            label="Product:Structure:Filter:MealPlan:Title"
                                            type="StructureMealplans"
                                            onApplyFilter={onApplyFilter}
                                            resetSelectedItem={isResetSelectedItem}
                                            defaultShowFilter={false}
                                        />

                                        { /* Amenities */}
                                        <ProductFilter
                                            items={filterValues.amenities}
                                            label="Product:Structure:Filter:Amenities"
                                            type="StructureAmenities"
                                            onApplyFilter={onApplyFilter}
                                            resetSelectedItem={isResetSelectedItem}
                                            defaultShowFilter={false}
                                        />

                                        { /* Zone */}
                                        <ProductFilter
                                            items={filterValues.cityLocations}
                                            label="Product:Structure:Filter:CityLocation"
                                            type="StructureCityLocations"
                                            onApplyFilter={onApplyFilter}
                                            resetSelectedItem={isResetSelectedItem}
                                            defaultShowFilter={false}
                                        />

                                        { /* Provider */}
                                        <ProductFilter
                                            items={filterValues.suppliers}
                                            label="Product:Structure:Filter:Provider"
                                            type="StructureSuppliers"
                                            onApplyFilter={onApplyFilter}
                                            resetSelectedItem={isResetSelectedItem}
                                            defaultShowFilter={false}
                                        />
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                            <b><data m3lab="MyBook.ProductList">{t(`MyBook:ProductList`)}</data></b>
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body" style={{ overflowY: "scroll", height: "600px" }}>
                                            <div className="text-right">
                                                <label>{t("Product:Structure:NStructure")}: {totalProduct}</label>
                                            </div>
                                            <span className="input-group mb-3 text-right">
                                                <select className="form-select border-0" aria-label="Disabled select example" value={getFilterOrderByValue(filterSelected.orderBy)} onChange={(e) => { onChangeSortBy(e); }}>
                                                    <option value="">
                                                        <data m3lab="General.Order.OrderBy">{t(`General:Order:OrderBy`)}</data>
                                                    </option>
                                                    {filterValues &&
                                                        searchCarpetParameter.hotel.orderByValues.filter(x => (filterValues.hotelSearchParameter.ray === 0 ? x.value !== 8 : x)).map((item, key) => {
                                                            return <option key={key} value={item.value}>
                                                                <data m3lab={item.label} >{t(item.label)}</data>
                                                            </option>
                                                        })
                                                    }
                                                </select>
                                                <button disabled={isChangeAzDisabled} className="btn-neutral btn m-0 p-0 pr-1 d-inline" type="button" id="button-addon2" onClick={(e) => { onChangeAZ(e); }}>
                                                    <div className={(!isOrderAZ ? "" : "d-none")}>
                                                        <data m3ico="SortBy icon-15 text-bluesoft">
                                                            <data m3ico="ResultList icon-25">
                                                                <M3Icon iconName="OrderByAsc" externalClass="icon-15 text-bluesoft p-1" hasLabel={false} />
                                                            </data>
                                                        </data>
                                                    </div>
                                                    <div className={(isOrderAZ ? "" : "d-none")}>
                                                        <data m3ico="SortBy icon-15 text-bluesoft">
                                                            <data m3ico="ResultList icon-25">
                                                                <M3Icon iconName="OrderByDesc" externalClass="icon-15 text-bluesoft p-1" hasLabel={false} />
                                                            </data>
                                                        </data>
                                                    </div>
                                                </button>
                                            </span>

                                            {/* Nome */}
                                            <ProductFilterByName
                                                items={filterValues.hotelNames}
                                                label={t("SearchEngine:FindHotel")}
                                                type="StructureName"
                                                onApplyFilter={onApplyFilterName}
                                                resetSelectedItem={isResetSelectedItem}
                                                noPredictions={true}
                                            />

                                            {featureFiltered && featureFiltered.length > 0 && featureFiltered.map((product, index) =>
                                                <>
                                                    {index < 10 &&
                                                        renderProduct(product)
                                                    }
                                                </>
                                            )}
                                            {featureFiltered && featureFiltered.length > 10 &&
                                                <>
                                                    <a className="cursor-pointer pl-1" data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1" style={{ textDecoration: "none" }}>
                                                        <data m3lab="General.More">{t("General:More")}</data>
                                                    </a>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="collapse multi-collapse" id="multiCollapseExample1">
                                                                {featureFiltered.map((product, index) =>
                                                                    <>
                                                                        {index >= 10 &&
                                                                            renderProduct(product)
                                                                        }
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-9 generalMapContainer" style={{zIndex: "0"}}>
                        {isLoadingMap && mapArr &&
                            <OsmTravelPlaceMap
                                geoJsonRef={geoJsonRef}
                                data={mapArr}
                                center={[45.6479389724, 8.7796935685]}
                                zoom={8}
                                scrollWheelZoom={true}
                                showFilterArea={showFilterArea}
                                sizeInMeters={sizeInMeters}
                                showClusterPopup={false}
                                updateFeatures={updateFeatures}
                                markerPopup={markerPopup}
                                showCluster={false}
                                map={map}
                                setMap={setMap}
                                clusterMarkerPopup={clusterMarkerPopup}
                                handleClickPointOnMap={handleClickPointOnMap}
                            />
                        }
                        {!isLoadingMap && mapArr &&
                            <Loading textMsg={t('Product:Structure:LoadingList')} />
                        }
                        {!isLoadingMap && !mapArr &&
                            <div className="text-center">
                                {t('MyBook:NothingData')}
                            </div>
                        }
                        </div>
                    </div>
                </div>
            }


        </>
    );
}