import React, { useEffect, useState } from 'react';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

export const CarTermsConditions = ({ termsAndConditions }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");
    
    return (
        <>
            <div className="mt-5 p-0">
                <div className="h5 bg-gray-300 w-100 p-1 py-3 pl-3 rounded-top m-0 ">
                    <data m3lab="Product.RentCar.TermsAndCondition">{t('Product:RentCar:TermsAndCondition')}</data>
                </div>
                <div className="small text-justify border border-top-0 border-gray-300 rounded-bottom p-3">
                    <ul className="pl-0">
                        {termsAndConditions && termsAndConditions.length > 0 && termsAndConditions.map((cond, idx) =>
                            <li id={"CondizioniCar" + idx} className="list-group-item border-0 ml-3 pt-0 pb-0 collapse show">
                                <a className="text-decoration-none h075 pl-2 collapsed" data-bs-toggle="collapse" href={"#Condizioni" + idx} role="button" aria-expanded="false" aria-controls={"Condizioni" + idx}>
                                    <h6 className="mb-0">
                                        <var>{cond.name }</var>
                                    </h6>
                                </a>
                                <div className="collapse" id={"Condizioni" + idx} >
                                    <div className="card card-body">
                                        <div id={"Condizioni" + idx} class="accordion-collapse collapse" role="region" aria-labelledby="heading1c">
                                            <div className="accordion-body">
                                                {cond && cond.informations && cond.informations.length > 0 && cond.informations.map(x =>
                                                    <>
                                                    <span dangerouslySetInnerHTML={{ __html: x.description }} />
                                                        {x.url &&
                                                            <a href={x.url} target="_blank">{x.url }</a>
                                                        }
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </>
    );
}