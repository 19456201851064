import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import configData from "../../../../appsettings.json";
import { GalleryList } from "../../Common/Image/GalleryList";
import { M3Icon } from "../../../Common/M3Icon";
import { ProductActionMenu } from "../../Common/ProductActionMenu";
import { ProductPrice } from "../../Common/Pricing/ProductPrice";
import { ActivityListRecapOptions } from "../../Activity/components/ActivityListRecapOptions";
import { formatDateTime } from '../../../../js/Utils.js';


export const CruiseItemList = ({ item, isViewDetail = false, showPriceBar, showCost, extraParam, onModalOpenProductId, onClickSearch, removeTrippie, updateTrippie }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();

    useEffect(() => {
        if (!extraParam)
            extraParam = {};

        extraParam.productId = item.uniqueId;
    }, [item]);

    function handleOnModalOpenProductId() {
        onModalOpenProductId(item);
    }

    function handleClickSearch(cruiseCode, extSelectedFlt) {
        let cabinCategoryCode = item.bestPriceCabinCategoryCode;
        if (extSelectedFlt && extSelectedFlt.length > 0) {
            let selectCC = extSelectedFlt.filter(x => x.type === 'SELECTLABEL');
            if (selectCC.length > 0)
                cabinCategoryCode = selectCC[0].value;
        }

        let ids = {
            cruiseCode: item.cruiseCode,
            cabinCategoryCode: cabinCategoryCode
        };

        onClickSearch(ids);
    }

    function getTemplateCabinSelected() {
        if (!item || !item.cabinCategoryGroupInfo || item.cabinCategoryGroupInfo.length === 0 || !extraParam || !extraParam.templateInfo)
            return "";

        for (let i = 0; i < item.cabinCategoryGroupInfo.length; i++) {
            let matchingCabin = item.cabinCategoryGroupInfo[i].cabinCategories.filter(x => x.productCode === extraParam.templateInfo.selectedProductCode);
            if (matchingCabin.length > 0) {
                return matchingCabin[0].name;
            }

        }
    }


    return (
        <>
            <>
                <div className="card shadow shadow-hover mb-4 animate-up-3 ">
                    <div className="row no-gutters">
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <GalleryList
                                productId={item.cruiseCode}
                                defaultImage={item.thumbUrl}
                                gallery={item.images}
                                alternativeText={item.name}
                                onClickSearch={handleClickSearch}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <div className="card-body pt-2 px-0">
                                <div>
                                    <div className="w-100">
                                        <div className="row">
                                            <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                                                <label>
                                                    <img src={item.providerLogoUrl} />
                                                </label>
                                                {
                                                    item.isSuggestion && <data m3ico="Star text-facebook">
                                                        <M3Icon iconName="Star" externalClass="text-facebook" hasLabel={false} />
                                                    </data>
                                                }
                                            </div>
                                            {extraParam && extraParam.templateInfo
                                                ? (<></>)
                                                : (<>
                                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <div className="btn-group text-right float-end pr-2">
                                                            <button className="btn p-0 m-0" type="button" id="menuItem1" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                                <data m3ico="List">
                                                                    <M3Icon iconName="MenuFunctions" />
                                                                </data>
                                                            </button>
                                                            <ProductActionMenu
                                                                productId={item.activityId}
                                                                productType={configData.Settings.Products.Cruise.IdTipoProdotto}
                                                                handleModalOpenProductId={handleOnModalOpenProductId}
                                                            />
                                                        </div>
                                                    </div>
                                                </>)
                                            }
                                        </div>
                                        <div className="h5">
                                            <var className="cursor-pointer" onClick={(e) => handleClickSearch(item.cruiseCode)}>{item.name}</var>
                                        </div>

                                        <div className="w-100 small mb-1">
                                            <span className="fw-bold text-gray-400"><data m3lab="Product.Activity.Code">{t('Product:Cruise:Departure')}: </data></span>
                                            <var>{formatDateTime(item.departureSegment?.date, cultureName)}</var>
                                        </div>
                                        <div className="w-100 small mb-1">
                                            <span className="fw-bold text-gray-400"><data m3lab="Product.Activity.Code">{t('Product:Cruise:Boarding')}: </data></span>
                                            <var>{item.departureSegment?.portName}</var>
                                        </div>
                                        <div className="w-100 small mb-1">
                                            <span className="fw-bold text-gray-400"><data m3lab="Product.Activity.Code">{t('Product:Cruise:Landing')}: </data></span>
                                            <var>{item.arrivalSegment?.portName}</var>
                                        </div>
                                        <div className="w-100 small mb-1">
                                            <span className="fw-bold text-gray-400"><data m3lab="Product.Activity.Code">{t('Product:Cruise:Duration')}: </data></span>
                                            <var>
                                                <span className="mr-1">{item.nights}</span>
                                                {
                                                    item.nights === 1
                                                        ? t('Product:Cruise:Night')
                                                        : t('Product:Cruise:Nights')
                                                }
                                            </var>
                                        </div>
                                        <div className={extraParam && extraParam.templateInfo ? "w-100 small mb-1" : "w-100 small mb-5"}>
                                            <span className="fw-bold text-gray-400"><data m3lab="Product.Activity.Code">{t('Product:Cruise:Ship')}: </data></span>
                                            <var>{item.shipName}</var>
                                        </div>

                                        {extraParam && extraParam.templateInfo
                                            ? (<>
                                                <div className="w-100 small mb-1">
                                                    <span className="fw-bold text-gray-400"><data m3lab="Product.Cruise.CabinName">{t('Product:Cruise:Cabin')}: </data></span>
                                                    <var>{getTemplateCabinSelected()}</var>
                                                </div>
                                            </>)
                                            : (<></>)
                                        }

                                        <div className="position-innerit h-100 ">
                                            <div className="position-absolute bottom-0 w-100 pb-1">
                                                <ActivityListRecapOptions
                                                    activityId={item.cruiseCode}
                                                    bestPricingOptions={item.bestPricingCabinCategories}
                                                    onClickSearch={handleClickSearch} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-1 pr-3">
                            <ProductPrice
                                productId={item.cruiseCode}
                                pricing={item.pricing}
                                supplier={item.supplier}
                                showPriceBar={showPriceBar}
                                showCost={showCost}
                                otherParam={{ buttonEnabled: true }}
                                typePrice="XCabin"
                                isTrippie={extraParam && extraParam.isTrippie}
                                showPriceTrippie={extraParam && extraParam.showPriceTrippie}
                                removeTrippie={removeTrippie}
                                updateTrippie={updateTrippie}
                                onClickSearch={handleClickSearch}
                                templateInfo={extraParam ? extraParam.templateInfo : null}
                            />
                        </div>
                    </div>
                </div >
            </>
        </>
    );
}