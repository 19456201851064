import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { M3Pax } from '../../../Common/M3Pax';
import { CruiseDetailCabinCategoryGroup } from './CruiseDetailCabinCategoryGroup';

export const CruiseDetailGroupCabinCategory = ({ cruiseDetail, cruiseSearchParameters, selectFilterPromo, resetFilter, onClickBook, onChangeShowPriceBar, showPriceBar, showCost, enableShowNetPrices, enableCanAddToCart, quotationInfo, templateInfo }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const { t } = useTranslation();
    const [originalCabinCategoryGroupInfo, setOriginalCabinCategoryGroupInfo] = useState([]);
    const [filterCabinCategoryGroupInfo, setFilterCabinCategoryGroupInfo] = useState([]);

    useEffect(() => {
        if (cruiseDetail && cruiseDetail.cabinCategoryGroupInfo) {
            setOriginalCabinCategoryGroupInfo(cruiseDetail.cabinCategoryGroupInfo);
            setFilterCabinCategoryGroupInfo(cruiseDetail.cabinCategoryGroupInfo);
        }

    }, [cruiseDetail, resetFilter])

    useEffect(() => {

        let updFilter = [...selectFilterPromo];
        if (updFilter && updFilter.length > 0) {

            let items = [];
            for (let i = 0; i < originalCabinCategoryGroupInfo.length; i++) {
                let cabinCategory = { ...originalCabinCategoryGroupInfo[i] };

                if (cabinCategory.cabinCategories.length > 0) {
                    cabinCategory.cabinCategories = cabinCategory.cabinCategories.filter(x => updFilter.some(f => f.id === x.promotion.id));
                }

                if (cabinCategory.cabinCategories.length > 0)
                    items.push(cabinCategory);
            }

            setFilterCabinCategoryGroupInfo(items);

        } else {
            setFilterCabinCategoryGroupInfo(cruiseDetail.cabinCategoryGroupInfo);
        }

    }, [selectFilterPromo])

    return (
        <>
            <div className="card border-light bg-gray-300 pt-2 mt-4">
                <div class="row p-1 ">
                    <div class="h5 col-12 col-lg-5 ">
                        <data m3lab="Product.structure.Accomodation">
                            {t('Product:Cruise:Cabin')}
                        </data>
                    </div>
                    <div class="h5 col-12 col-lg-2 ">
                        {
                            cruiseSearchParameters && cruiseSearchParameters.passengersComposition &&
                            <M3Pax
                                adt={cruiseSearchParameters.passengersComposition.adults}
                                chd={cruiseSearchParameters.passengersComposition.children}
                                inf={cruiseSearchParameters.passengersComposition.childAges}
                            />
                        }
                    </div>
                    <div class="col-12 col-lg-5 text-right">
                        {
                            enableShowNetPrices && <button className={(showPriceBar ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow"} m3rool="f_ShowPriceBar" onClick={(e) => { onChangeShowPriceBar() }}>
                                <data m3lab="Button.Net">
                                    {t("Button:Net")}
                                </data>
                            </button>
                        }
                    </div>
                    {
                        filterCabinCategoryGroupInfo && filterCabinCategoryGroupInfo.map((cabinCategoryGroup, index) => {
                            return <React.Fragment key={index}>
                                <div class="col-12 ">
                                    <CruiseDetailCabinCategoryGroup
                                        cabinCategoryGroup={cabinCategoryGroup}
                                        enableCanAddToCart={enableCanAddToCart}
                                        quotationInfo={quotationInfo}
                                        templateInfo={templateInfo}
                                        showCost={showCost}
                                        showPriceBar={showPriceBar}
                                        onClickBook={onClickBook}
                                    />
                                </div>
                            </React.Fragment>
                        })
                    }
                </div>
            </div>
        </>
    );
}