import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import configData from "../../../appsettings.json";
import { M3Icon } from '../../Common/M3Icon';
import { GetModulesRules, formatDateTime } from "../../../js/Utils";
import { TrippieStepHeader } from './TrippieStepHeader';
import { TrippieStepItem } from './TrippieStepItem';
import { TrippieStepAddItem } from './TrippieStepAddItem';
import { isPreviousVoidStep, canAddAnotherStep } from '../service/TrippieService.js';

export const TrippieSteps = ({ steps, searchParameters, changeDataStep, onAddStep, onAddMissingStep, onRemoveItem, onStartSearch, updateItemAfterSearch, removeItemAfterSearch, onDeleteStep }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const { t } = useTranslation();

    const [rules, setRules] = useState(null);

    useEffect(() => {
        const callGetModuleRules = async () => {
            const moduleRules = await GetModulesRules();
            setRules(moduleRules);
        };
        callGetModuleRules();
    }, []);

    function canAddItemStep(stepInfo) {
        if (stepInfo.stepType === (configData.Settings.Products.Flight.IdTipoProdotto + ""))
            return (!stepInfo.items || stepInfo.items.length === 0) && !stepInfo.refFlightCartItem;

        if (stepInfo.webDestinationId)
            return true;

        return false;
    }

    function showPriceTrippie(stepInfo) {
        if (stepInfo.refFlightCartItem) {
            return stepInfo.refLegIndex === 1;
        }

        return true; // TODO roles trippie show price
    }

    function getPrevStep(step) {

        try {
            let prevStep = steps.filter(x => x.step === (step - 1))[0];
            return prevStep;
        } catch (ex) {

        }

        return null;
    }

    function getNextStep(step) {

        try {
            let prevStep = steps.filter(x => x.step === (step + 1))[0];
            return prevStep;
        } catch (ex) {

        }

        return null;
    }

    return (
        <>
            {
                (!steps || steps.length === 0) && <>
                    <div className="row">
                        <div className="col-12 text-right mb-4">
                            <button className="h075 btn btn-outline-success shadow" onClick={_ => onAddStep(0)} style={{ "borderRadius": "100px" }}>
                                <M3Icon iconName="Add" externalClass="h085" /> Aggiungi Step
                            </button>
                        </div>
                    </div>
                </>
            }
            {
                steps && steps.map((stepInfo, index) => {
                    return <React.Fragment key={index} >
                        {
                            index === 0 && isPreviousVoidStep(stepInfo, searchParameters.dateFrom) && <div className="row mt-4 mb-4">
                                <div className="col-1"></div>
                                <div className="col-12 col-sm-11 col-md-11 col-lg-11">
                                    <div className="card bg-light px-3 py-2">
                                        <div>
                                            <span className="text-danger h085 float-start">
                                                Soggiorno Libero dal {formatDateTime(searchParameters.dateFrom, cultureName, { monthLabel: true, noYear: true })} al {formatDateTime(stepInfo.stepFrom, cultureName, { monthLabel: true, noYear: true })}
                                            </span>
                                            <span className="float-end">
                                                <button className="h075 btn btn-outline-success shadow" onClick={_ => onAddMissingStep(-1)} style={{ "borderRadius": "100px" }}>
                                                    <M3Icon iconName="Add" externalClass="h085" /> Aggiungi Step
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div id={"stepInfo_" + stepInfo.step} className="row mb-4">
                            <div className="col-12 col-sm-1 col-md-1 col-lg-1">
                                <div className="timeline timeline-one timeline-mybook">
                                    <div className="h-100">
                                        <div className={"trippie-badge badge text-dark px-2 " + (stepInfo.stepType === (configData.Settings.Products.Flight.IdTipoProdotto + "") ? "bg-trippie-flight" : "bg-white")}>
                                            {
                                                stepInfo.stepType === (configData.Settings.Products.Flight.IdTipoProdotto + "") ? <M3Icon iconName="Flight" />
                                                    : <M3Icon iconName="Destination" />
                                            }
                                            <div className="mt-2">Step {index + 1}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-11 col-md-11 col-lg-11">

                                <div className={"trippie-card card px-3 pt-0 pb-3 " + (stepInfo.stepType === (configData.Settings.Products.Flight.IdTipoProdotto + "") ? "bg-trippie-flight" : "bg-white")}>
                                    <TrippieStepHeader
                                        stepInfo={stepInfo}
                                        searchParameters={searchParameters}
                                        cultureName={cultureName} />
                                    {
                                        stepInfo && stepInfo.items && stepInfo.items.map((item, indexItem) => {
                                            return <React.Fragment key={indexItem}>
                                                <TrippieStepItem
                                                    stepIndex={index}
                                                    itemIndex={indexItem}
                                                    cartItemId={item.cartItemId}
                                                    showPriceTrippie={showPriceTrippie(stepInfo)}
                                                    refFlightCartItem={stepInfo.refFlightCartItem}
                                                    refLegIndex={stepInfo.refLegIndex}
                                                    cultureName={cultureName}
                                                    item={item}
                                                    updateItemAfterSearch={updateItemAfterSearch}
                                                    removeItemAfterSearch={removeItemAfterSearch}
                                                    onDeleteStep={onDeleteStep}
                                                    onRemoveItem={onRemoveItem}
                                                />
                                            </React.Fragment>
                                        })
                                    }
                                    {
                                        stepInfo.refFlightCartItem && (!stepInfo.items || stepInfo.items.length === 0) && <TrippieStepItem
                                            stepIndex={index}
                                            refFlightCartItem={stepInfo.refFlightCartItem}
                                            refLegIndex={stepInfo.refLegIndex}
                                            cultureName={cultureName}
                                            showPriceTrippie={showPriceTrippie(stepInfo)}
                                            updateItemAfterSearch={updateItemAfterSearch}
                                            removeItemAfterSearch={removeItemAfterSearch}
                                            onDeleteStep={onDeleteStep}
                                            onRemoveItem={onRemoveItem} />
                                    }
                                    {
                                        canAddItemStep(stepInfo) && <TrippieStepAddItem rules={rules}
                                            stepIndex={stepInfo.step}
                                            stepInfo={stepInfo}
                                            prevStep={getPrevStep(stepInfo.step)}
                                            nextStep={getNextStep(stepInfo.step)}
                                            stepDestination={{
                                                WebId: stepInfo.webDestinationId,
                                                Text: stepInfo.webDestinationName,
                                                Type: stepInfo.webDestinationType
                                            }}
                                            changeDataStep={changeDataStep}
                                            onStartSearch={onStartSearch} />
                                    }
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            stepInfo.missingDateToNextStep && <div className="row mt-4 mb-4">
                                <div className="col-1"></div>
                                <div className="col-12 col-sm-11 col-md-11 col-lg-11">
                                    <div className="trippie-card card bg-light px-3 py-2">
                                        <div>
                                            <span className="text-danger h085 float-start">
                                                Soggiorno Libero dal {formatDateTime(stepInfo.missingStepFrom, cultureName, { monthLabel: true, noYear: true })} al {formatDateTime(stepInfo.missingStepTo, cultureName, { monthLabel: true, noYear: true })}
                                            </span>
                                            <span className="float-end">
                                                <button className="h075 btn btn-outline-success shadow" onClick={_ => onAddMissingStep(index)} style={{ "borderRadius": "100px" }}>
                                                    <M3Icon iconName="Add" externalClass="h085" /> Aggiungi Step
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            !stepInfo.missingDateToNextStep && canAddAnotherStep(steps, index, searchParameters.dateTo) && <div className="row">
                                <div className="col-12 text-right mb-4">
                                    <button className="h075 btn btn-outline-success shadow" onClick={_ => onAddStep(index)} style={{ "borderRadius": "100px" }}>
                                        <M3Icon iconName="Add" externalClass="h085" /> Aggiungi Step
                                    </button>
                                </div>
                            </div>
                        }

                    </React.Fragment>
                })
            }
        </>
    );
}
