import React, { useEffect, useState } from "react";
import { M3Icon } from "../M3Icon";
import configData from "../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { getDefaultRoom, getTotalPaxesFromSearchParameters } from '../../../js/Utils.js';

export const InputPaxesCompositionReadOnly = ({ searchParameters, productType, defaultSelectComposition, callOnSelectComposition, callOnShowPanel, modeView, type }) => {
    const { t } = useTranslation();

    const [iconWidth, setIconWidth] = useState('w-20');
    const [inputWidth, setInputWidth] = useState('w-80');

    // usati solo in fase di visualizzazione nella input
    const [totalAdt, setTotalAdt] = useState(0);
    const [totalChd, setTotalChd] = useState(0);
    const [totalInf, setTotalInf] = useState(0);
    const [totalRoom, setTotalRoom] = useState(0);

    // impostazioni di pagina
    const [haveRooms, setHaveRooms] = useState(false);

    useEffect(() => {
        if (type === 'l') {
            setIconWidth('');
            setInputWidth('w-60');
        }

    }, []);

    useEffect(() => {
        if (productType === configData.Settings.Products.Structure.IdTipoProdotto) {
            setHaveRooms(true);

            if (!defaultSelectComposition && searchParameters && searchParameters.roomsComposition) {
                setInputLabelFromSearchParameters(searchParameters.roomsComposition);
                setTotalRoom(searchParameters.roomsComposition.length);

            } else if (defaultSelectComposition) {
                setInputLabel(defaultSelectComposition);
            }
            else {
                setDefaultComposition('hotel');
            }

        }

        if (productType === configData.Settings.Products.Activity.IdTipoProdotto) {
            if (!defaultSelectComposition && searchParameters && searchParameters.passengerComposition) {
                setInputLabelFromSearchParameters([searchParameters.passengerComposition]);
            } else if (defaultSelectComposition) {
                setInputLabel(defaultSelectComposition);
            }
            else {
                setDefaultComposition('activity');
            }

        }

        if (productType === configData.Settings.Products.Flight.IdTipoProdotto) {
            if (!defaultSelectComposition && searchParameters && searchParameters.travelers) {
                setInputLabelFromSearchParameters(searchParameters.travelers);
            } else if (defaultSelectComposition) {
                setInputLabel(defaultSelectComposition);
            }
            else {
                setDefaultComposition('flight');
            }

        }

        if (productType === configData.Settings.Products.Train.IdTipoProdotto) {
            if (!defaultSelectComposition && searchParameters && searchParameters.travelers) {
                setInputLabelFromSearchParameters(searchParameters.travelers);
            } else if (defaultSelectComposition) {
                setInputLabel(defaultSelectComposition);
            }
            else {
                setDefaultComposition('train');
            }

        }

        if (productType === configData.Settings.Products.Transfer.IdTipoProdotto) {
            if (!defaultSelectComposition && searchParameters && searchParameters.passengersComposition) {
                setInputLabelFromSearchParameters([searchParameters.passengersComposition]);
            } else if (defaultSelectComposition) {
                setInputLabel(defaultSelectComposition);
            }
            else {
                setDefaultComposition('transfer');
            }
        }

        if (productType === configData.Settings.Products.Tour.IdTipoProdotto) {
            setHaveRooms(true);

            if (!defaultSelectComposition && searchParameters && searchParameters.roomsComposition) {
                setInputLabelFromSearchParameters(searchParameters.roomsComposition);
                setTotalRoom(searchParameters.roomsComposition.length);

            } else if (defaultSelectComposition) {
                setInputLabel(defaultSelectComposition);
            }
            else {
                setDefaultComposition('tour');
            }

        }

        if (productType === configData.Settings.Products.Cruise.IdTipoProdotto) {
            if (!defaultSelectComposition && searchParameters && searchParameters.passengersComposition) {
                setInputLabelFromSearchParameters([searchParameters.passengersComposition]);
            } else if (defaultSelectComposition) {
                setInputLabel(defaultSelectComposition);
            }
            else {
                setDefaultComposition('cruise');
            }

        }

    }, [searchParameters, defaultSelectComposition]);

    // settaggi iniziali
    function setDefaultComposition(type) {
        let defRoom = getDefaultRoom(type);

        setTotalAdt(defRoom.adults);
        setTotalChd(defRoom.children);
        setTotalRoom(1);

        if (callOnSelectComposition)
            callOnSelectComposition([defRoom]);
    }

    function setInputLabelFromSearchParameters(passengerCompositions) {
        let totalPaxesCompositionNormalize = getTotalPaxesFromSearchParameters(passengerCompositions, productType);

        setTotalAdt(totalPaxesCompositionNormalize.totalAdtPC);
        setTotalChd(totalPaxesCompositionNormalize.totalChdPC);
        setTotalInf(totalPaxesCompositionNormalize.totalInfPC);
        setTotalRoom(totalPaxesCompositionNormalize.totalRM);
    }

    function setInputLabel(passengerCompositions) {
        let totalPaxesCompositionNormalize = getTotalPaxesFromSearchParameters(passengerCompositions, productType, true);

        setTotalAdt(totalPaxesCompositionNormalize.totalAdtPC);
        setTotalChd(totalPaxesCompositionNormalize.totalChdPC);
        setTotalInf(totalPaxesCompositionNormalize.totalInfPC);
        setTotalRoom(totalPaxesCompositionNormalize.totalRM);
    }

    function getLabelName() {
        let label = "";

        label = totalAdt + " ";
        if (totalAdt === 1) {
            label += t('SearchEngine:Adult');
        } else {
            label += t('SearchEngine:Adults');
        }

        label += " + " + totalChd + " ";
        if (totalChd === 1) {
            label += t('SearchEngine:Child');
        } else {
            label += t('SearchEngine:Children');
        }

        if (haveRooms) {
            label += " + " + totalRoom + " ";

            if (totalRoom === 1) {
                label += t('SearchEngine:Room');
            } else {
                label += t('SearchEngine:Rooms');
            }
        }

        if (productType === configData.Settings.Products.Flight.IdTipoProdotto) {
            label += " + " + totalInf + " ";

            if (totalInf === 1) {
                label += t('SearchEngine:Infant');
            } else {
                label += t('SearchEngine:Infants');
            }
        }

        return label;
    }

    function onClickShowPanel() {
        if (modeView !== 'PANEL' && modeView !== 'COMPACTVIEW')
            callOnShowPanel();
    }

    return (
        <>
            <div className={"input-group rounded border pr-1 " + ((modeView === 'PANEL' || modeView === 'COMPACTVIEW') ? "bg-gray-300" : "bg-white")} onClick={_ => onClickShowPanel()}>
                <span className={"input-group-text px-2 " + iconWidth + " " + ((modeView === 'PANEL' || modeView === 'COMPACTVIEW') ? "bg-gray-300" : "bg-white")} id="basic-addon1">
                    <data m3ico="Groups">
                        <M3Icon iconName="Groups" />
                    </data>
                </span>
                <div className={"pl-1 " + inputWidth} >
                    <label className="form-label h065">
                        {
                            haveRooms ? <data m3lab="SearchEngine.Rooms">
                                <data m3lab="SearchEngine.Rooms">
                                    {t("SearchEngine:Rooms")}
                                </data>
                            </data>
                                : <data m3lab="SearchEngine.Travelers">
                                    <data m3lab="SearchEngine.Travelers">
                                        {t("SearchEngine:Travelers")}
                                    </data>
                                </data>
                        }
                    </label>
                    {
                        (modeView === 'PANEL' || modeView === 'COMPACTVIEW') && <p className="p-0 m-0 h075">
                            {getLabelName()}
                        </p>
                    }
                    {
                        (modeView !== 'PANEL' && modeView !== 'COMPACTVIEW') && <input className="form-control h075 w-100 border-0 shadow-none w-100"
                            id="NRoom"
                            value={getLabelName()}
                            readOnly />
                    }
                </div>
            </div>
        </>
    );
}
