import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { M3Icon } from '../../Common/M3Icon';
import { FlightSearchEngine } from '../searchEngine/FlightSearchEngine';

export const TrippieSelectTransport = ({ searchParameters, onUpdateFlightParam }) => {
    const { t } = useTranslation();

    const [selectTransport, setSelectTransport] = useState(configData.Settings.Products.Flight.IdTipoProdotto);
    const [paxComposition, setPaxComposition] = useState(null);

    useEffect(() => {
        if (searchParameters && searchParameters.roomsComposition && searchParameters.roomsComposition.length > 0) {
            let updPaxComposition = {
                adults: 0,
                children: 0,
                childAges: []
            };

            for (let i = 0; i < searchParameters.roomsComposition.length; i++) {
                updPaxComposition.adults += searchParameters.roomsComposition[i].adults;
                updPaxComposition.children += searchParameters.roomsComposition[i].children;
                updPaxComposition.childAges.concat(searchParameters.roomsComposition[i].childAges);
            }

            setPaxComposition([updPaxComposition]);
        }
    }, [searchParameters])

    const changeTransport = (type) => {
        setSelectTransport(type);

        onUpdateFlightParam(type);
    }

    const onSearchButtonFlight = (selectDestinations, selectDates, searchParam) => {
        onUpdateFlightParam(selectTransport, selectDestinations, selectDates, searchParam);
    }

    return (
        <>
            <div className="row m-0">
                <div className="col-12">
                    <h6 className="mt-3 fw-bold">{t('Trippie:SearchEngine:SelectTransportTitle')}</h6>
                </div>
                <div className="col-12 col-sm-1 col-md-1 col-lg-1">
                    <div className="timeline timeline-one timeline-mybook w-100 cursor-pointer" onClick={_ => changeTransport(configData.Settings.Products.Flight.IdTipoProdotto)}>
                        <div className="h-100 w-100">
                            <div className={"trippie-badge badge px-2 " + (selectTransport === configData.Settings.Products.Flight.IdTipoProdotto ? "bg-primary text-white" : "bg-white text-dark")}>
                                <M3Icon iconName="Flight" />
                            </div>
                        </div>
                    </div>

                    <div className="timeline timeline-one timeline-mybook w-100 mt-2 cursor-pointer" onClick={_ => changeTransport("NO")}>
                        <div className="h-100 w-100">
                            <div className={"trippie-badge badge px-2 " + (selectTransport === 'NO' ? "bg-primary text-white" : "bg-white text-dark")}>
                                <M3Icon iconName="NoFlight" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-11 col-md-11 col-lg-11 p-0 rounded">
                    {
                        selectTransport === 'NO' && <div className="position-absolute top-0 left-0 w-100 h-100 rounded" style={{ backgroundColor: 'rgba(12,11,14,.7)', zIndex: 1000 }}></div>
                    }
                    <FlightSearchEngine
                        searchParameters={searchParameters}
                        onSearchButtonFlight={onSearchButtonFlight}
                        paxComposition={paxComposition} />
                </div>
            </div>
        </>
    );
}
