import React from 'react';
import { M3Icon } from "../../../Common/M3Icon";
import { useTranslation } from 'react-i18next';

export const ActivityDetailFavourite = ({ activityDetail }) => {
    const { t } = useTranslation();

    function renderCity(item) {
        let label = "";
        if (item.country)
            label += item.country;

        if (item.region)
            label += (!label ? "" : "> ") + item.region;

        if (item.city)
            label += (!label ? "" : "> ") + item.city;

        if (item.touchPoints && item.touchPoints.length > 0)
            label += (!label ? "" : "> ") + item.touchPoints[0].webDestinationName;

        return label;
    }

    return (
        <>
            <div className="col-9">
                <label><var>{renderCity(activityDetail)}</var></label>
            </div>
            <div className="col-3 text-right">
                <button className="btn m-0 p-0 h6" m3Role="w_Favorite">
                    <data m3lab="Button.Prefer">{t('Button:Prefer')}</data>
                    <data m3ico="FavoriteOutline icon-10">
                        <M3Icon iconName="FavoriteOutline" externalClass="icon-10" />
                    </data>
                </button>
            </div>
        </>
    );
}