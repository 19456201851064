import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { useParams } from "react-router-dom";
import { Loading } from '../Common/Loading';
import configData from "../../appsettings.json";
import { RegistrationEndUserForm } from "./components/RegistrationEndUserForm";

export const RegistrationUserAgency = () => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");
    const [dynamicNotes, setDynamicNotes] = useState([]);
    const [isDisabledFormUser, setIsDisabledFormUser] = useState(true);
    const [isDisabledFormClient, setIsDisabledFormClient] = useState(true);
    const [countries, setCountries] = useState([]);
    const [networks, setNetworks] = useState([]);
    const [inputDataFromEndUser, setInputDataFromEndUser] = useState({});
    const [codeVerify, setCodeVerify] = useState(null);
    const [isloadingInputVerifyCode, setIsloadingInputVerifyCode] = useState(false);
    const [isLoadingButtonToken, setIsLoadingButtonToken] = useState(false);
    const [isLoadingButtonRegistration, setIsLoadingButtonRegistration] = useState(false);
    const [showTokenPanel, setShowTokenPanel] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoadingMessage, setIsLoadingMessage] = useState(false);
    const [messagge, setMessagge] = useState('');

    const [inputData, setInputData] = useState({
        companyName: '',
        vat: '',
        licenseNumber: '',
        addictionalCompany: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        zipCode: '',
        region: '',
        province: ''
    });

    const [validateInputData, setValidateInputData] = useState({
        isValidCompanyName: false,
        isTouchedCompanyName: false,

        isValidEmail: false,
        isTouchedEmail: false,

        isValidPhone: false,
        isTouchedPhone: false,

        isValidAddress: false,
        isTouchedAddress: false,

        isValidCity: false,
        isTouchedCity: false,

        isValidForm: false
    });

    useEffect(() => {
        callGetCountries();
        callGetNetworks();
    }, []);

    useEffect(() => {
        if (validateInputData.isValidForm) {
            setIsDisabledFormClient(false);
        }
        else {
            setIsDisabledFormClient(true);
        }
    }, [validateInputData.isValidForm]);


    var inputRes = {};
    function sendVerifyCode() {
        setIsLoadingButtonToken(true);
        setIsError(false);
        setShowTokenPanel(false);
        setErrorMsg('');

        inputRes = {
            ragioneSociale: inputData.companyName,
            partitaIva: inputData.vat,
            email: inputData.email,
            ragioneSocialeAgg: inputData.addictionalCompany,
            address: inputData.address,
            phone: inputData.phone,
            city: inputData.city,
            zipCode: inputData.zipCode,
            country: inputData.country ? inputData.country : "IT",
            region: inputData.region,
            userEmail: inputDataFromEndUser.email,
            userPassword: inputDataFromEndUser.password,
            userFirstName: inputDataFromEndUser.name,
            userLastName: inputDataFromEndUser.surname,
            websiteId: inputDataFromEndUser.websiteId,
            cultureCode: cultureCode
        }

        const callSendCode = async (inputRes) => {
            const response = sendCode(inputRes);
        }

        callSendCode(inputRes);
    }

    function saveUser() {
        setIsLoadingButtonRegistration(true);

        const callRegisterAgency = async (inputRes) => {
            const response = registerAgency(inputRes);
        }

        inputRes = {
            ragioneSociale: inputData.companyName,
            partitaIva: inputData.vat,
            email: inputData.email,
            address: inputData.address,
            city: inputData.city,
            zipCode: inputData.zipCode,
            country: inputData.country ? inputData.country : "IT",
            state: inputData.state,
            region: inputData.region,
            userEmail: inputDataFromEndUser.email,
            userPassword: inputDataFromEndUser.password,
            userFirstName: inputDataFromEndUser.name,
            userLastName: inputDataFromEndUser.surname,
            websiteId: inputDataFromEndUser.websiteId,
            token: codeVerify,
            licenseNumber: inputData.licenseNumber,
            ragioneSocialeAgg: inputData.addictionalCompany,
            phone: inputData.phone,
            province: inputData.province,
            cultureCode: cultureCode,
        }
        
         if (configData.Settings.Login.HasNetworkId)
            inputRes["networkId"] = inputData.network;

        callRegisterAgency(inputRes);
    }

    async function sendCode() {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputRes)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'user/GenerateAndSendToken', requestOption);
        const response = await fetchedRes.json();

        if (response.success && response.send) {
            setIsLoadingButtonToken(false)

            setIsloadingInputVerifyCode(true);
        }
        else if (response.success && response.exist) {
            setIsLoadingButtonToken(false)
            setIsError(true);
            setErrorMsg(t("Login:MailAlreadyExists"));
        }
        else {
            setIsError(true);
            setErrorMsg(t("Login:RegistrationErrorMessage"));
        }
    }

    async function registerAgency(inputRes) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputRes)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'user/RegistrationAgency', requestOption);
        const response = await fetchedRes.json();

        if (response.success && response.send) {
            setIsLoadingMessage(true);
            setMessagge(t("Login:RegistrationSuccess"));
        }
        else if (response.success && response.exist) {
            setIsError(true);
            setErrorMsg(t("Login:MailAlreadyExists"));
        }
        else {
            setIsError(true);
            setErrorMsg(t("Login:RegistrationErrorMessage"));
        }
    }


    async function callGetCountries() {

        if (!cultureCode)
            cultureCode = 'it-IT';

        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'constant/GetCountries/' + cultureCode, requestOption);
        const response = await fetchedRes.json();
        if (response) {
            setCountries(response);
        }
    }

    async function callGetNetworks() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'constant/GetNetworks', requestOption);
        const response = await fetchedRes.json();
        if (response) {
            setNetworks(response);
        }
    }

    function onChangeDropdown(event, id) {
        const selectedValue = event.target.selectedOptions[0].value;
        const isSelected = selectedValue !== '-1';
        //saveSelectedBuoniSconto(id, isSelected, selectedValue);
    }

    function onBlurEvent(event, propertyName) {
        let updateInputData = { ...inputData };

        let newValue = propertyName === 'privacyPolicy' ? event.target.checked
            : event.target.value;

        updateInputData[propertyName] = newValue;

        let isValidForm = false;
        let prevValidateInputData = { ...validateInputData };
        delete prevValidateInputData.isValidForm;
        const isNotEmpty = (newValue !== '' && newValue !== undefined);

        switch (propertyName) {
            case 'companyName': {
                delete prevValidateInputData.isValidCompanyName;
                delete prevValidateInputData.isTouchedCompanyName;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidCompanyName: isNotEmpty,
                    isTouchedCompanyName: true
                });
                break;
            }

            case 'address': {
                delete prevValidateInputData.isValidAddress;
                delete prevValidateInputData.isTouchedAddress;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidAddress: isNotEmpty,
                    isTouchedAddress: true
                });
                break;
            }

            case 'city': {
                delete prevValidateInputData.isValidCity;
                delete prevValidateInputData.isTouchedCity;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidCity: isNotEmpty,
                    isTouchedCity: true
                });
                break;
            }

            case 'phone': {
                delete prevValidateInputData.isValidPhone;
                delete prevValidateInputData.isTouchedPhone;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidPhone: isNotEmpty,
                    isTouchedPhone: true
                });
                break;
            }

            case 'email': {
                delete prevValidateInputData.isValidEmail;
                delete prevValidateInputData.isTouchedEmail;
                let isValidEmailFormat = isNotEmpty;
                if (isNotEmpty) {
                    const isValidMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/.test(newValue);
                    isValidEmailFormat = isValidEmailFormat && isValidMail;
                }

                isValidForm = isValidEmailFormat && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidEmail: isValidEmailFormat,
                    isTouchedEmail: true
                });
                break;
            }
        }

        setInputData(updateInputData);
    }

    return (
        <>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className="col-12 text-left">
                        <h3>{t("Login:Registration")}</h3>
                        <h4>{t("Login:RegistrationSubTitle")}</h4>
                        <div className="row">
                            <div className={"col-md-12 mb-3" + (!validateInputData.isValidCompanyName ? " insert-danger" : "")}>
                                {t("Login:CompanyName")} *
                                <input type="text"
                                    className="form-control"
                                    //placeholder={t("Login:CompanyName") + " *"}
                                    aria-label="Nome"
                                    onChange={e => onBlurEvent(e, 'companyName')}
                                />
                                {
                                    validateInputData.isTouchedCompanyName && !validateInputData.isValidCompanyName && <div className="text-danger mt-1">Il campo è obbligatorio</div>
                                }
                            </div>
                            <div className={"col-md-6 mb-3"}>
                                {t("Login:Vat")}
                                <input type="text"
                                    className="form-control"
                                    //placeholder={t("Login:Vat")}
                                    aria-label="Nome"
                                    onChange={e => onBlurEvent(e, 'vat')}
                                />
                            </div>
                            <div className={"col-md-6 mb-3"}>
                                {t("Login:LicenseNumber")}
                                <input type="text"
                                    className="form-control"
                                    //placeholder={t("Login:LicenseNumber")}
                                    aria-label="Nome"
                                    onChange={e => onBlurEvent(e, 'licenseNumber')}
                                />
                            </div>
                            <div className={"col-md-12 mb-3"}>
                                {t("Login:AddictionalCompany")}
                                <input type="text"
                                    className="form-control"
                                    //placeholder={t("Login:AddictionalCompany")}
                                    aria-label="Nome"
                                    onChange={e => onBlurEvent(e, 'addictionalCompany')}
                                />
                            </div>
                            <div className={"col-md-12 mb-3" + (!validateInputData.isValidEmail ? " insert-danger" : "")}>
                                Email *
                                <input type="text"
                                    className="form-control"
                                    //placeholder="Email *"
                                    aria-label="Nome"
                                    onChange={e => onBlurEvent(e, 'email')}
                                />
                            </div>
                            {
                                validateInputData.isTouchedEmail && !validateInputData.isValidEmail && <div className="text-danger mt-1">Il campo è obbligatorio</div>
                            }
                            <div className={"col-md-12 mb-3" + (!validateInputData.isValidPhone ? " insert-danger" : "")}>
                                {t("Login:Phone")} *
                                <input type="number"
                                    className="form-control"
                                    //placeholder={t("Login:Phone") + " *"}
                                    aria-label="Nome"
                                    onChange={e => onBlurEvent(e, 'phone')}
                                />
                            </div>
                            {
                                validateInputData.isTouchedPhone && !validateInputData.isValidPhone && <div className="text-danger mt-1">Il campo è obbligatorio</div>
                            }
                            <div className={"col-md-12 mb-3" + (!validateInputData.isValidAddress ? " insert-danger" : "")}>
                                {t("Login:Address")} *
                                <input type="text"
                                    className="form-control"
                                    //placeholder={t("Login:Address") + " *"}
                                    aria-label="Nome"
                                    onChange={e => onBlurEvent(e, 'address')}
                                />
                            </div>
                            {
                                validateInputData.isTouchedAddress && !validateInputData.isValidAddress && <div className="text-danger mt-1">Il campo è obbligatorio</div>
                            }
                            <div className={"col-md-5 mb-3" + (!validateInputData.isValidCity ? " insert-danger" : "")}>
                                {t("Login:City")} *
                                <input type="text"
                                    className="form-control"
                                    //placeholder={t("Login:City") +" *"}
                                    aria-label="Nome"
                                    onChange={e => onBlurEvent(e, 'city')}
                                />
                            </div>
                            {
                                validateInputData.isTouchedCity && !validateInputData.isValidCity && <div className="text-danger mt-1">Il campo è obbligatorio</div>
                            }
                            <div className={"col-md-2 mb-3"}>
                                {t("Login:ZipCode")}
                                <input type="text"
                                    className="form-control"
                                    //placeholder={t("Login:ZipCode")}
                                    aria-label="Nome"
                                    onChange={e => onBlurEvent(e, 'zipCode')}
                                />
                            </div>
                            <div className={"col-md-3 mb-3"}>
                                {t("Login:Region")}
                                <input type="text"
                                    className="form-control"
                                    //placeholder={t("Login:Region")}
                                    aria-label="Nome"
                                    onChange={e => onBlurEvent(e, 'region')}
                                />
                            </div>
                            <div className={"col-md-2 mb-3"}>
                                {t("Login:Province")}
                                <input type="text"
                                    className="form-control"
                                    //placeholder={t("Login:Province")}
                                    aria-label="Nome"
                                    onChange={e => onBlurEvent(e, 'province')}
                                />
                            </div>
                            <div className={"col-md-12 mb-3"}>
                                {t("Login:State")} *
                                <select className="form-select p-2" aria-label="" onChange={(e) => { onBlurEvent(e, 'country') }}>
                                    {countries && countries.length > 0 && countries.map((cc, key) =>
                                        <option value={cc.iso2} selected={cc.iso2 === "IT" ? true : false }>{cc.name}</option>
                                    )}
                                </select>
                            </div>
                            {configData.Settings.Login.HasNetworkId &&
                                <div className={"col-md-12 mb-3"}>
                                    Network
                                    <select className="form-select p-2" aria-label="" onChange={(e) => { onBlurEvent(e, 'network') }}>
                                        {networks && networks.length > 0 && networks.map((net, key) =>
                                            key === 0 ?
                                                <option value={null}></option>
                                                :
                                                <option value={net.value}>{net.text}</option>

                                        )}
                                    </select>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-12 text-left mt-3">
                        <h4>{t("Login:RegistrationSubTitleUser")}</h4>
                        <RegistrationEndUserForm handeIsValidForm={setIsDisabledFormUser} handleInputData={setInputDataFromEndUser} cultureCode={cultureCode} isCallDynamicNotesAgency={true} />

                        <div className="col-12 mb-3 text-center">
                            {!isLoadingMessage && !isloadingInputVerifyCode ?
                                <button className="btn btn-sm buttonCustom" disabled={(isDisabledFormUser || isDisabledFormClient)} onClick={sendVerifyCode}>
                                    {
                                        !isLoadingButtonToken ? <>{t("Button:RegistrationToken")}</>
                                            : <div className="spinner-border spinner-border-sm text-light" role="status" style={{ 'fontSize': '9px' }}>
                                                <span className="sr-only"></span>
                                            </div>
                                    }
                                </button>
                                :
                                <>
                                    {t("General:Code")}
                                    <input type="text"
                                        className="form-control"
                                        aria-label="Nome"
                                        onChange={e => setCodeVerify(e.target.value)}
                                    />
                                    {!isLoadingMessage &&
                                        <button className="btn btn-sm buttonCustom mt-3" onClick={saveUser}>
                                            {
                                                !isLoadingButtonRegistration ?
                                                    <>{t("Button:Registration")}</>
                                                    :
                                                    <div className="spinner-border spinner-border-sm text-light" role="status" style={{ 'fontSize': '9px' }}>
                                                        <span className="sr-only"></span>
                                                    </div>
                                            }
                                        </button>
                                    }
                                </>
                            }
                            {isLoadingMessage &&
                                messagge
                            }
                            {
                                isError && <div className="text-danger mt-1">
                                    <div dangerouslySetInnerHTML={{ __html: errorMsg }}></div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}