import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useSearchParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useAuth } from '../../Auth/AuthProvider';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../Common/Loading';
import { handleChangePax, renderOptionBookParameters, renderPaxBookParameters, productNotAvailableClass_Opacity, productNotAvailableClass_Border } from './SharedFunctions';
import { generateClientFlowId } from '../../../js/ProductService.js';
import { formatDateTime, getStructureStars, scrollTo } from '../../../js/Utils.js';
import { registerSessionQuotation } from '../../../js/QuotationUtils';
import { BookParameterMandatoryCssClass, searchCarpetParameter } from '../../../js/Constant';
import configData from "../../../appsettings.json";
import { AvailableStructure } from "../Available/AvailableStructure";
import { M3Icon } from '../../Common/M3Icon';
import { M3IconProductType } from '../../Common/M3IconProductType';
import { M3Pax } from "../../Common/M3Pax";
import { HotelRoom } from '../../Product/Hotel/components/HotelRoom';
import { HotelItemList } from '../../Product/Hotel/components/HotelItemList';
import { ProductFilter } from '../../Product/Common/Filter/ProductFilter';
import { ProductFilterByName } from '../../Product/Common/Filter/ProductFilterByName';
import { ProductFilterByPrice } from '../../Product/Common/Filter/ProductFilterByPrice';
import { CancelPolicyModal } from '../../Product/Common/CancelPolicyModal';

export const CartStructure = ({ cartItemId, isCartQuotation, userPermissions, cartPaxesNames, staticBookParams, productBookParams, handleBookParameters, handleReservationData, userLockBook, callTermsFinished, onOpenModalConfirmDelete }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const cultureName = cookies.get("CultureName");
    const { setQuotationInfoSession } = useAuth();

    // debug
    const [searchParams] = useSearchParams();
    let debug = searchParams.get("debug");

    const [isLoading, setIsLoading] = useState(true);
    const [cartItem, setCartItem] = useState([]);

    // src
    const [srcCarpetId, setSrcCarpetId] = useState(false);
    const [isLoadingSrc, setIsLoadingSrc] = useState(false);
    const [isSrcError, setIsSrcError] = useState(false);
    const [structureList, setStructureList] = useState(null);

    // src paging
    const itemsPerPage = searchCarpetParameter.hotel.listMaxItem;
    const [pageCount, setPageCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(0);

    // src filters
    const [srcFilterShow, setSrcFilterShow] = useState(false);
    const [isResetSelectedItem, setIsResetSelectedItem] = useState(false);
    const [isOrderAZ, setIsOrderAZ] = useState(true);
    const [isChangeAzDisabled, setIsChangeAzDisabled] = useState(true);
    const [filterValues, setFilterValues] = useState(null);
    const [rangePriceInfo, setRangePriceInfo] = useState({ min: "0", max: "5000", step: "50", currency: "EUR" });
    const [filterSelected, setFilterSelected] = useState({
        structureName: null,
        categories: [],
        mealplans: [],
        amenities: [],
        suppliers: [],
        bestRoomTypes: [],
        rangePrice: null,
        pageNumber: 0,
        orderBy: searchCarpetParameter.hotel.orderBy,
        onlyFavourite: false,
        onlyBestSeller: false,
        onlyRecomended: false
    });

    // avl
    const [isLoadingAvl, setIsLoadingAvl] = useState(false);
    const [isAvlError, setIsAvlError] = useState(false);
    const [structureAvailability, setStructureAvailability] = useState(null);
    const [availRoomsReq, setAvailRoomsReq] = useState([]);


    useEffect(() => {
        const getCartItem = async () => {
            callGetCartItem();
        }

        setIsLoading(true);
        getCartItem();
    }, []);

    useEffect(() => {
        callGetCartItem();
    }, [callTermsFinished]);

    useEffect(() => {

        if (userPermissions && userPermissions.cartOnlyOption) {
            let rbOptionBook = document.getElementById(`rbStructureOption_${cartItemId}`);
            if (rbOptionBook)
                rbOptionBook.click();
        }

    }, [userPermissions, callTermsFinished])

    useEffect(() => {
        if (cartItem && cartItem.rooms && cartPaxesNames && cartPaxesNames.length > 0) {
            for (let i = 0; i < cartItem.rooms.length; i++) {
                let room = cartItem.rooms[i];
                for (let j = 0; j < room.passengers.length; j++) {
                    let pax = room.passengers[j];
                    let e = { target: { value: pax.index }, Index: pax.index };

                    let ddlPaxId = `ci_${cartItem.cartItemId}_room_${room.sequence}_pax_${pax.index}_ddlPax`;
                    let ddlPax = document.getElementById(ddlPaxId);
                    if (ddlPax) {
                        ddlPax.value = pax.index;
                        handleChangePax(cartItem, cartPaxesNames, handleBookParameters, room.sequence, pax.index, ddlPaxId, e);
                    }
                }
            }
        }
    }, [cartPaxesNames]);


    async function callGetCartItem() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/GetCartItem/' + cartItemId, requestOption);
        const response = await fetchedRes.json();

        setCartItem(response);
        setIsLoading(false);
    }

    async function callUpdateQuotationItemStatus(quotationId, quotationItemId) {
        let inputData =
        {
            QuotationId: quotationId,
            QuotationItemId: quotationItemId,
            Status: 70,
            LogNote: "Status changed from CartQuotation due to BookingTerm error",
            QuotationSettings: { BaseUrl: "" }
        };

        const requestOption =
        {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/UpdateQuotationItemStatus', requestOption);
        const response = await fetchedRes.json();

        let responseQuotation = await registerSessionQuotation(quotationId, quotationItemId, true, cartItemId);
        if (responseQuotation) {
            setQuotationInfoSession(responseQuotation.quotationInfo);
            window.location.href = responseQuotation.url;
        }
    }

    async function callStartSearchFromCart() {
        setIsLoadingSrc(true);

        let inputData = { CartItemId: cartItemId, carpetOperation: "SRC", clientFlowId: generateClientFlowId() };
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Structure/StartSearchFromCart', requestOption);

        let isError = true;

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response && response.success) {
                isError = false;
                setSrcCarpetId(response.carpetId);
                callGetStructureList(response.carpetId, 0, filterSelected);
            }
        }

        if (isError) {
            setIsLoadingAvl(false);
        }
    }
    async function callStartAvailabilityFromCart(structureId) {
        setIsLoadingAvl(true);

        let inputData =
        {
            CartItemId: cartItemId,
            carpetOperation: "AVL",
            clientFlowId: generateClientFlowId(),
            structureId: structureId,
            searchCarpetId: srcCarpetId
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Structure/StartAvailabilityFromCart', requestOption);

        let isError = true;

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response && response.success) {
                isError = false;
                callGetStructureAvailability(response.carpetId, response.structureId);
            }
        }

        if (isError) {
            setIsLoadingAvl(false);
        }
    }

    async function callGetStructureList(carpetId, myPageSelected, filterSelectedUpdate) {
        // structure filters list
        let requestParamFilters = { cultureCode: cultureName, carpetId: carpetId };
        const requestOptionFilters = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestParamFilters) };
        const fetchedResFilters = await fetch(`${configData.Settings.CommonApi_BaseUrl}structure/getstructurelistfilters`, requestOptionFilters);

        if (fetchedResFilters.ok) {
            const response = await fetchedResFilters.json();
            setFilterValues(response);
        }

        // structure list
        let inputData = {
            cultureCode: cultureName,
            carpetId: carpetId,
            pageNumber: myPageSelected,
            pageSize: 10,
            structureName: filterSelectedUpdate.structureName,
            categories: filterSelectedUpdate.categories,
            mealplans: filterSelectedUpdate.mealplans,
            services: filterSelectedUpdate.services,
            suppliers: filterSelectedUpdate.suppliers,
            bestRoomTypes: filterSelectedUpdate.bestRoomTypes,
            rangePrice: filterSelectedUpdate.rangePrice,
            cityLocations: filterSelectedUpdate.cityLocations,
            orderBy: filterSelectedUpdate.orderBy,
            onlyFavourite: filterSelectedUpdate.onlyFavourite,
            onlyBestSeller: filterSelectedUpdate.onlyBestSeller,
            onlyRecomended: filterSelectedUpdate.onlyRecomended
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Structure/GetStructureList', requestOption);

        let isError = true;

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response && response.success && response.structures) {
                isError = false;
                setStructureList(response.structures);
                setPageCount(Math.ceil(response.totalStructures / itemsPerPage));
            }
        }

        setIsLoadingSrc(false);
        setIsSrcError(isError);
    }
    async function callGetStructureAvailability(carpetId, structureId, selectedRooms) {
        let inputData = {
            cultureCode: cultureName,
            carpetId: carpetId,
            structureId: structureId,
            retrieveStaticData: true,
            numRoomsToView: 10,
            availRoomsReq: selectedRooms ? selectedRooms : null
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Structure/GetStructureAvailability', requestOption);

        let isError = true;

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response && response.success && response.structure) {
                isError = false;
                setStructureAvailability(response.structure);
            }
        }

        setIsLoadingAvl(false);
        setIsAvlError(isError);
    }

    async function callReplaceItemInCart(selectedRoomIds) {
        let inputData = {
            CultureCode: cultureName,
            CartItemIdToReplace: cartItemId,
            CarpetId: structureAvailability.carpetId,
            SelectedRoomIds: selectedRoomIds
        };
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Structure/ReplaceItemInCart', requestOption);

        let isError = true;

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response && response.success) {
                isError = false;
                window.location.reload();
            }
        }

        setIsAvlError(isError);
    }

    function onClickChangeProduct(type, quotationId, quotationItemId) {
        if (quotationId && quotationItemId) {
            callUpdateQuotationItemStatus(quotationId, quotationItemId);
        }
        else {
            if (type === "L") {
                callStartSearchFromCart();
            } else {
                callStartAvailabilityFromCart(null);
            }
        }
    }


    const structureBookOperationOnChange = (e) => {
        let id = e.target.id;
        let val = e.target.value;

        // --- set label class ---
        var selectedLabelId = id === `rbStructureBook_${cartItemId}` ? `lblStructureBook_${cartItemId}` : `lblStructureOption_${cartItemId}`;
        var notSelectedLabelId = selectedLabelId === `lblStructureBook_${cartItemId}` ? `lblStructureOption_${cartItemId}` : `lblStructureBook_${cartItemId}`;
        let lblSelected = document.getElementById(selectedLabelId);
        let lblNotSelected = document.getElementById(notSelectedLabelId);

        if (lblSelected) {
            lblSelected.classList.remove("btn-flight-operation");
            lblSelected.classList.add("btn-flight-operation-selected");
        }

        if (lblNotSelected) {
            lblNotSelected.classList.remove("btn-flight-operation-selected");
            lblNotSelected.classList.add("btn-flight-operation");
        }

        // --- set label class ---

        if (val === "on") {
            let data = id === "rbStructureBook_" + cartItemId ? 3 : 2;
            handleReservationData(cartItem.cartItemId, "ProductAction", data);
        }
    }

    const renderAction = () => {
        if (isCartQuotation || !cartItem.canOptionBook) {
            return (<></>);
        }
        else {
            return (
                <>
                    {
                        (!userPermissions || !userPermissions.cartOnlyOption) && <>
                            <input id={`rbStructureBook_${cartItemId}`} type="radio" className="btn-check" name={`rbStructureBookOperation_${cartItemId}`} autoComplete="off" onChange={e => structureBookOperationOnChange(e)} defaultChecked={true} />
                            <label id={`lblStructureBook_${cartItemId}`} className="btn btn-sm btn-flight-operation-selected mr-2" htmlFor={`rbStructureBook_${cartItemId}`}>{t("Button:Book")}</label>
                        </>
                    }
                    {
                        (cartItem.canOptionBook || (userPermissions && userPermissions.cartOnlyOption)) && <>
                            <input id={`rbStructureOption_${cartItemId}`} type="radio" className="btn-check btn-sm" name={`rbStructureBookOperation_${cartItemId}`} autoComplete="off" onChange={e => structureBookOperationOnChange(e)} defaultChecked={false} />
                            <label id={`lblStructureOption_${cartItemId}`} className="btn btn-sm btn-flight-operation" htmlFor={`rbStructureOption_${cartItemId}`}>{t("MyBook:Option")}</label>
                        </>
                    }
                </>
            );
        }
    }

    // src methods
    const handlePageClick = async e => {
        setPageSelected(e.selected);
        callGetStructureList(srcCarpetId, e.selected, filterSelected);
    };
    const selectHotel = (structureId) => {
        setStructureList(null);
        callStartAvailabilityFromCart(structureId);
        scrollTo(null, `cartStructure_cartItemId_${cartItemId}`);
    }

    // src filters methods
    function callFilterValues(filterSelectedUpdate) {
        callGetStructureList(srcCarpetId, 0, filterSelectedUpdate);
    }
    const onChangeAZ = (e) => {
        setIsOrderAZ(!isOrderAZ);

        if (filterSelected.orderBy === "3") { // nome az
            let filterSelectedUpdate = {
                ...filterSelected,
                orderBy: "2"
            };

            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
        else if (filterSelected.orderBy === "2") { // nome za
            let filterSelectedUpdate = {
                ...filterSelected,
                orderBy: "3"
            };

            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
        else if (filterSelected.orderBy === "4") { // distanza az
            let filterSelectedUpdate = {
                ...filterSelected,
                orderBy: "8"
            };

            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
        else if (filterSelected.orderBy === "8") { // distanza za
            let filterSelectedUpdate = {
                ...filterSelected,
                orderBy: "4"
            };

            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
    }
    function onChangeSortBy(event) {
        let orderByValue = event.target.selectedOptions[0].value;

        let filterSelectedUpdate = {
            ...filterSelected,
            orderBy: orderByValue
        };

        if (orderByValue === "0" || orderByValue === "1") {
            setIsChangeAzDisabled(true);
        }
        else {
            setIsChangeAzDisabled(false);
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }
    function onApplyFilter(selectedItems, type) {
        let filterSelectedUpdate = { ...filterSelected };

        switch (type) {
            case 'StructureStars': {
                filterSelectedUpdate.categories = selectedItems;
                break;
            }
            case 'StructureMealplans': {
                filterSelectedUpdate.mealplans = selectedItems;
                break;
            }
            case 'StructureAmenities': {
                filterSelectedUpdate.services = selectedItems;
                break;
            }
            case 'StructureSuppliers': {
                filterSelectedUpdate.suppliers = selectedItems;
                break;
            }
            case 'BestRoomTypes': {
                filterSelectedUpdate.bestRoomTypes = selectedItems;
                break;
            }
            case 'StructureCityLocations': {
                filterSelectedUpdate.cityLocations = selectedItems;
                break;
            }
            default:
                break;
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }
    function onApplyFilterName(name) {
        let filterSelectedUpdate = {
            ...filterSelected,
            structureName: name
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }
    function onApplyFilterPrice(rangePrice) {
        let filterSelectedUpdate = {
            ...filterSelected,
            rangePrice: rangePrice
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }
    function onClearFilter() {
        let filterSelectedUpdate = {
            ...filterSelected,
            categories: [],
            mealplans: [],
            amenities: [],
            suppliers: [],
            bestRoomTypes: [],
            structureName: null,
            rangePrice: null,
            onlyFavourite: false,
            onlyBestSeller: false,
            onlyRecomended: false
        }

        setIsResetSelectedItem(!isResetSelectedItem);
        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    // avl methods
    const selectAlternativeRoom = (selectedRoomInfo) => {
        if (structureAvailability.roomGroupInfo.length === 1) {
            let selectedRooms = [];
            if (selectedRoomInfo) {
                selectedRooms.push({ id: selectedRoomInfo.id, sequence: selectedRoomInfo.sequence });
            } else {
                selectedRooms = [];
            }
            setAvailRoomsReq(selectedRooms);
            return;
        }
        else {
            let selectedRooms = [...availRoomsReq];
            if (selectedRoomInfo) {
                if (selectedRooms.filter(x => x.id === selectedRoomInfo.id).length > 0) {
                    selectedRooms = selectedRooms.filter(x => x.id !== selectedRoomInfo.id && x.sequence < selectedRoomInfo.sequence);
                }
                else {
                    selectedRooms.push({ id: selectedRoomInfo.id, sequence: selectedRoomInfo.sequence });
                }
            }
            else {
                selectedRooms = [];
            }
            setAvailRoomsReq(selectedRooms);
            callGetStructureAvailability(structureAvailability.carpetId, structureAvailability.structureId, selectedRooms);
        }
    }
    const selectCombination = (data) => {
        setIsLoadingAvl(true);

        let selectedRoomIds = [];
        for (let i = 0; i < availRoomsReq.length; i++) {
            selectedRoomIds.push(availRoomsReq[i].id);
        }

        callReplaceItemInCart(selectedRoomIds);
    }

    function onClickDeleteNotAvailable() {
        onOpenModalConfirmDelete(cartItemId);
    }

    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : (
                    <>
                        <div id={`cartStructure_cartItemId_${cartItemId}`} className={"card mt-3 p-3 " + productNotAvailableClass_Border(productBookParams)}>
                            <div className="card p-3">
                                <div className={productNotAvailableClass_Opacity(productBookParams)}>

                                    <div className="row">
                                        <div className="col-7">
                                            <M3IconProductType productType={cartItem.productType} externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                                            <span className="titlecard">{cartItem.name}</span>
                                            {getStructureStars(cartItem.category)}
                                        </div>
                                        <div className="col-2">
                                            <div class="w-100">
                                                <M3Pax adt={cartItem.adults}
                                                    chd={cartItem.children}
                                                    chdAges={cartItem.childrenAges}
                                                    inf={cartItem.infants}
                                                    infAges={cartItem.infantAges}
                                                    extraOptions={{ showInfant: false, iconClass: "icon-15" }} />
                                            </div>
                                        </div>
                                        <div className="col-3 text-right">
                                            {cartItem.status && cartItem.status === 'OnRequest' && <span className="text-warning h6 mb-0 small d-block"><var>{cartItem.status}</var></span>}
                                            {cartItem.supplier && <span className="text-info small d-block"><var>{cartItem.supplier.supplierName}</var></span>}
                                        </div>
                                    </div>

                                    <div><span>{cartItem.address}</span></div>
                                    <div className="btn-group ml-4" role="group" aria-label="Basic radio toggle button group">
                                        {/*<input type="radio" className="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked="" />*/}
                                        {/*<label className="btn btn-outline-success btn-sm" for="btnradio1">Prenota</label>*/}
                                        {/*<input type="radio" className="btn-check" name="btnradio" id="btnradio2" autocomplete="off" />*/}
                                        {/*<label className="btn btn-outline-success btn-sm" for="btnradio2">opziona</label>*/}
                                    </div>

                                    {callTermsFinished &&
                                        <React.Fragment>
                                            <div className="d-inline float-end">
                                                <button className="btn btn-sm btn-danger" onClick={() => onOpenModalConfirmDelete(cartItemId)}>{t("Button:Delete")}</button>
                                            </div>

                                            <div className="d-inline float-end mr-4">
                                                {renderAction()}
                                            </div>
                                        </React.Fragment>
                                    }

                                    <p><label>{formatDateTime(cartItem.dateFrom, cultureName)} - {formatDateTime(cartItem.dateTo, cultureName)}</label></p>
                                </div>
                                {cartItem.rooms.map(room =>
                                    <div key={room.sequence} className={productNotAvailableClass_Opacity(productBookParams)}>
                                        <div class="w-100 mb-1">
                                            <label>Room <var>{room.sequence}: {room.roomName}</var></label>
                                            {
                                                (
                                                    (room.notes !== null && room.notes !== undefined && room.notes.length > 0) ||
                                                    (room.resortFee !== null && room.resortFee !== undefined && room.resortFee.length > 0)
                                                ) && <label className="ml-1 cursor-pointer text-info" data-bs-toggle="modal" data-bs-target={"#modal_CartItem_" + cartItemId + "_" + room.sequence}>
                                                    <M3Icon iconName="Info" externalClass="icon-10 text-info" />
                                                </label>
                                            }
                                        </div>
                                        <div class="w-100 mb-1"><label><M3Icon iconName="MealPlan" externalClass="icon-10 mr-1" /><var>{room.planName}</var></label></div>
                                        <div className="row">
                                            {
                                                room.cancelPolicy && <div className="col-12">
                                                    <label className="penalty">
                                                        <span className={room.cancelPolicy.isFree ? "penalty free" : "penalty"}>
                                                            <M3Icon iconName="CancelPolicy" externalClass="icon-10 mr-1" />
                                                            {room.cancelPolicy.isFree ? t("CancelPolicy:FreeCancellation") + " " + formatDateTime(room.cancelPolicy.expirationDate, { twoDigits: true }) : t("CancelPolicy:PenaltyCancellation")}
                                                        </span>
                                                        <CancelPolicyModal cancellationPolicy={room.cancelPolicy} />
                                                    </label>
                                                </div>

                                            }
                                        </div>

                                        {/*Passengers*/}
                                        {!callTermsFinished
                                            ? (<><Loading textMsg={t(`Cart:LoadingBookingTerms`)} isSmall={true} /></>)
                                            : (
                                                <>
                                                    {productBookParams !== null && productBookParams !== undefined && productBookParams.IsAvailable
                                                        ? (
                                                            <>
                                                                {room.passengers.map(pax =>
                                                                    <div key={pax.index} className="row mt-2">
                                                                        <div className="form-floating col-md-3">
                                                                            <select id={`ci_${cartItem.cartItemId}_room_${room.sequence}_pax_${pax.index}_ddlPax`} className="form-select border-0 border-bottom border-dark" aria-label="Fornitore" onChange={(e) => handleChangePax(cartItem, cartPaxesNames, handleBookParameters, room.sequence, pax.index, `ci_${cartItem.cartItemId}_room_${room.sequence}_pax_${pax.index}_ddlPax`, e)}>
                                                                                <option value="-1">{t("Button:Select")}&nbsp; pax</option>
                                                                                {cartPaxesNames !== null && cartPaxesNames !== undefined && cartPaxesNames.length > 0
                                                                                    ? (
                                                                                        cartPaxesNames.map(paxName =>
                                                                                            <option key={paxName.Index} value={paxName.Index}>{paxName.BpFirstName[0].Value} {paxName.BpLastName[0].Value}</option>
                                                                                        )
                                                                                    )
                                                                                    : (<></>)
                                                                                }
                                                                            </select>
                                                                            <label htmlFor={`ci_${cartItem.cartItemId}_room_${room.sequence}_pax_${pax.index}`}>
                                                                                <span className={BookParameterMandatoryCssClass.Mandatory}>{t("MyBook:Passenger")}&nbsp; {pax.paxType} {pax.index}:</span>
                                                                            </label>
                                                                        </div>

                                                                        {renderPaxBookParameters(cartItem, productBookParams, staticBookParams, handleBookParameters, room.sequence, pax.index)}
                                                                    </div>
                                                                )}

                                                                {renderOptionBookParameters(cartItem, productBookParams, handleBookParameters, room.sequence)}
                                                            </>
                                                        )
                                                        : (<></>)
                                                    }
                                                </>
                                            )
                                        }


                                        <div className="modal fade" id={"modal_CartItem_" + cartItemId + "_" + room.sequence} tabIndex="-1" aria-labelledby={"modal_CartItem_Label_" + cartItemId + "_" + room.sequence} aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id={"modal_CartItem_Label_" + cartItemId + "_" + room.sequence}>Note camera</h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        {/*Resort Fee*/}
                                                        {room.resortFee !== null && room.resortFee !== undefined && room.resortFee.length > 0 && <div className="row">
                                                            <div className="col-12">
                                                                <h5>Resort Fee</h5>
                                                            </div>
                                                            <div className="col-12">
                                                                {
                                                                    room.resortFee.map((txt, index) => {
                                                                        return <React.Fragment key={index}>{txt}<br /></React.Fragment>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        }

                                                        {/*Note Camera*/}
                                                        {room.notes !== null && room.notes !== undefined && room.notes.length > 0 && <div className="row">
                                                            <div className="col-12">
                                                                <h5>Note Camera</h5>
                                                            </div>
                                                            <div className="col-12">
                                                                {
                                                                    room.notes.map((txt, index) => {
                                                                        return <React.Fragment key={index}>{txt}<br /></React.Fragment>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className='modal-footer'>
                                                        <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t('Button:Close')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )}

                                {/*Product Not Available*/}
                                {productBookParams !== null && productBookParams !== undefined && !productBookParams.IsAvailable
                                    ? (
                                        <AvailableStructure
                                            detailUrl={productBookParams.TermsError_DetailUrl}
                                            listUrl={productBookParams.TermsError_ListUrl}
                                            quotationId={productBookParams.QuotationId}
                                            quotationItemId={productBookParams.QuotationItemId}
                                            deleteFn={onClickDeleteNotAvailable}
                                            changeProductFn={onClickChangeProduct}
                                            mode="CART"
                                        />
                                    )
                                    : (<></>)
                                }

                                {/* Search List */}
                                {isLoadingSrc && <div className="mt-4"><Loading textMsg={t(`Product:Structure:LoadingListAlternatives`)} isSmall={true} /></div>}
                                {!isLoadingSrc && isSrcError && <div className="mt-4"><Loading textMsg={t(`Product:Structure:LoadingListAlternativesError`)} isSmall={true} /></div>}

                                {/* Search List Filters */}
                                {!isLoadingSrc && structureList &&
                                    <div className="col-12 mt-1">
                                        {filterValues && <>
                                            <div className='d-none d-sm-block'>
                                                <button onClick={e => setSrcFilterShow(!srcFilterShow)} type="button" className="btn btn-primary m-0 p-1 w-10">
                                                    <M3Icon iconName="Filter" externalClass="icon-15 text-bluesoft" hasLabel={false} />
                                                    {t(`Button:Filter`)}
                                                </button>
                                            </div>
                                        </>}

                                        {srcFilterShow && filterValues && <>
                                            <div className='d-none d-sm-block'>
                                                {/* Nome */}
                                                <ProductFilterByName
                                                    items={filterValues.hotelNames}
                                                    label={t("SearchEngine:FindHotel")}
                                                    type="StructureName"
                                                    onApplyFilter={onApplyFilterName}
                                                    resetSelectedItem={isResetSelectedItem} />

                                                <button className="btn btn-sm btn-block tp-btn-search animate-up-2 mt-4" onClick={(e) => onClearFilter(e)}>
                                                    <data m3lab="Button.FilterRemove">{t('Button:FilterRemove')}</data>
                                                </button>

                                                { /* Price */}
                                                <ProductFilterByPrice
                                                    rangePriceInfo={rangePriceInfo}
                                                    onApplyFilter={onApplyFilterPrice}
                                                    resetSelectedItem={isResetSelectedItem} />

                                                { /* BestRoomTypes */}
                                                <ProductFilter
                                                    items={filterValues.bestRoomTypes}
                                                    label="Product:Structure:Filter:Generic:Title"
                                                    type="BestRoomTypes"
                                                    onApplyFilter={onApplyFilter}
                                                    resetSelectedItem={isResetSelectedItem} />

                                                { /* Stelle */}
                                                <ProductFilter
                                                    items={filterValues.categories}
                                                    label="Product:Structure:Filter:Star:Title"
                                                    type="StructureStars"
                                                    onApplyFilter={onApplyFilter}
                                                    resetSelectedItem={isResetSelectedItem} />
                                                { /* Trattamento */}
                                                <ProductFilter
                                                    items={filterValues.mealplans}
                                                    label="Product:Structure:Filter:MealPlan:Title"
                                                    type="StructureMealplans"
                                                    onApplyFilter={onApplyFilter}
                                                    resetSelectedItem={isResetSelectedItem} />

                                                { /* Amenities */}
                                                <ProductFilter
                                                    items={filterValues.amenities}
                                                    label="Product:Structure:Filter:Amenities"
                                                    type="StructureAmenities"
                                                    onApplyFilter={onApplyFilter}
                                                    resetSelectedItem={isResetSelectedItem} />

                                                { /* Zone */}
                                                <ProductFilter
                                                    items={filterValues.cityLocations}
                                                    label="Product:Structure:Filter:CityLocation"
                                                    type="StructureCityLocations"
                                                    onApplyFilter={onApplyFilter}
                                                    resetSelectedItem={isResetSelectedItem} />

                                                { /* Provider */}
                                                <ProductFilter
                                                    items={filterValues.suppliers}
                                                    label="Product:Structure:Filter:Provider"
                                                    type="StructureSuppliers"
                                                    onApplyFilter={onApplyFilter}
                                                    resetSelectedItem={isResetSelectedItem} />
                                            </div>
                                        </>}
                                    </div>
                                }

                                {!isLoadingSrc && structureList &&
                                    <div className="m-0 p-2">
                                        {structureList && structureList.length > 0 && structureList.map((item, key) => {
                                                return <HotelItemList key={key}
                                                    item={item}
                                                    showPriceBar={false}
                                                    showCost={false}
                                                    extraParam={null}
                                                    modalOpenProductId={null}
                                                    onClickSearch={selectHotel}
                                                    onFavourite={null}
                                                />
                                            })
                                        }

                                        {/* Paginator */}
                                        {structureList && structureList.length > 0 && <>
                                            <div className="col-12 col-sm-3 col-md-3 col-lg-39 col-xl-3 pt-1"></div>
                                            <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 pt-1">
                                                <nav aria-label="Page navigation comments" className="mt-4">
                                                    <ReactPaginate
                                                        breakLabel="..."
                                                        nextLabel={t('pagination:nextLabel')}
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={5}
                                                        pageCount={pageCount}
                                                        previousLabel={t('pagination:previousLabel')}
                                                        renderOnZeroPageCount={null}
                                                        forcePage={pageSelected}

                                                        breakClassName="page-item"
                                                        breakLinkClassName="page-link"
                                                        marginPagesDisplayed={2}
                                                        containerClassName="pagination justify-content-center"
                                                        pageClassName="page-item"
                                                        pageLinkClassName="page-link"
                                                        previousClassName="page-item"
                                                        previousLinkClassName="page-link"
                                                        nextClassName="page-item"
                                                        nextLinkClassName="page-link"
                                                        activeClassName="active"
                                                    />
                                                </nav>
                                            </div>
                                        </>
                                        }
                                    </div>
                                }

                                {/* Availability */}
                                {isLoadingAvl && <div className="mt-4"><Loading textMsg={t(`Product:Structure:LoadingAlternatives`)} isSmall={true} /></div>}
                                {!isLoadingAvl && isAvlError && <div className="mt-4"><Loading textMsg={t(`Product:Structure:LoadingAlternativesError`)} isSmall={true} /></div>}
                                {!isLoadingAvl && structureAvailability &&
                                    <div className="m-0 p-2">
                                        {structureAvailability.roomGroupInfo && structureAvailability.roomGroupInfo.length > 0 && structureAvailability.roomGroupInfo.map((roomGroup, accomodationIdx) =>
                                            <React.Fragment key={accomodationIdx}>
                                                <div>
                                                    <div className="row p-1">
                                                        <div className="h5 col-12 col-lg-5">
                                                            <data m3lab="Product.structure.Accomodation">{t(`Product:Structure:Accomodation`)} <var>{roomGroup.sequence}</var></data>
                                                        </div>
                                                    </div>
                                                    {roomGroup.roomGroups && roomGroup.roomGroups.map((room, idx) =>
                                                        <HotelRoom
                                                            key={idx}
                                                            room={room}
                                                            roomIndex={idx}
                                                            showPriceBar={false}
                                                            showCost={false}
                                                            enableCanAddToCart={{ allowed: true }}
                                                            totalAccomodation={structureAvailability.roomGroupInfo.length}
                                                            handleMealPlanIdAndSequence={selectAlternativeRoom}
                                                            multiRoomSelected={availRoomsReq}
                                                            handleFinalBook={selectCombination}
                                                            templateInfo={{}}
                                                        />
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                }
                            </div>

                            {/* --- DEBUG --- */}
                            {debug === "1"
                                ? (<><br /><br />{JSON.stringify(productBookParams)}</>)
                                : (<></>)
                            }
                            {/* --- DEBUG --- */}
                        </div>
                    </>
                )
            }
        </>
    )
}