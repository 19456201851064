import React from 'react';
import { Header } from '../PageComponent/Header';
import { useTranslation } from 'react-i18next';

export const LayoutMyBook = ({ websiteId, children }) => {
    const { t } = useTranslation();

    document.title = t(`home:title`) + " - MyBook";

    return (
        <>
            <Header websiteId={websiteId} /> />
            <div id="childrenContainer">
                {children}
            </div>
        </>
    );
}

