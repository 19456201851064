import React, { useState, useEffect } from 'react';
import { imageOnLoad } from '../../../../js/CDNUtility';
import configData from "../../../../appsettings.json";

export const ActivityBoxStatic = ({ activity, codiceProdotto, index, modalOpenActivityId }) => {
    const [imageUrl, setImageUrl] = useState('');
    let imageOnLeft = index % 2 === 0;

    useEffect(() => {
        if (activity && activity.thumbUrl) {
            setImageUrl(activity.thumbUrl);
        } else {
            setImageUrl(imageOnLoad());
        }

    }, [activity]);

    function onModalOpenActivityId(productId) {
        modalOpenActivityId(productId);
    }

    return (
        <>
            <div id={`activityDetail_${activity.activityId}`} className="w-100">
                <div className="card border-gray-600 my-4 animate-up-5 shadow">
                    <div className="row no-gutters">
                        {/*thumb left*/}
                        {imageOnLeft && <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div className="rounded" style={{ backgroundImage: "url(" + encodeURI(imageUrl) + "), url(" + imageOnLoad() + ")", backgroundRepeat: "no - repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100% " }}>
                        </div>
                        </div>}

                    {/*body*/}
                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                        <div className="myBook-card-body">
                            <div>
                                <div className="h5">
                                    <var>{activity.name}</var>
                                    <span className="float-end">
                                            <label className="btn-neutral text-decoration-underline px-2" onClick={e => onModalOpenActivityId(codiceProdotto)}>Maggiori Dettagli</label>
                                    </span>
                                </div>
                                <div className="w-100" style={{ maxHeight: '200px', height: '200px', overflow: 'hidden' }}>
                                    <span className="text-justify">
                                        {activity.descriptions && activity.descriptions.length > 0
                                            ? (<><p dangerouslySetInnerHTML={{ __html: activity.descriptions[0].value }}></p></>)
                                            : (<></>)
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*thumb right*/}
                    {!imageOnLeft && <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="rounded" style={{ backgroundImage: "url(" + encodeURI(imageUrl) + "), url(" + imageOnLoad(configData.Settings.Products.Activity.IdTipoProdotto) + ")", backgroundRepeat: "no - repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100%" }}>
                        </div>
                    </div>}
                </div>
            </div>
        </div >
        </>
    );
}
