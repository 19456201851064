import React, { useEffect, useState } from 'react';
import configData from "../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { M3Icon } from '../../Common/M3Icon';
import { InputPaxesCompositionContainer } from '../../Common/Paxes/InputPaxesCompositionContainer.js';
import { InputCalendarContainer } from '../../Common/Calendar/InputCalendarContainer.js';

export const TrippieSearchEngine = ({ isFake }) => {
    const { t } = useTranslation();

    const [selectTrippieName, setTrippieName] = useState(null);
    const [selectComposition, setSelectComposition] = useState(null);
    const [selectDates, setSelectDates] = useState(null);
    const [isValidForm, setIsValidForm] = useState({
        isValidTrippieName: false,
        isValidDates: false,
        isValidComposition: false,
        isAllFieldValid: false
    });

    const [isStartSearch, setIsStartSearch] = useState(false);

    const [showPaxes, setShowPaxes] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);

    useEffect(() => {
        let isValidFormUpdate = { ...isValidForm };
        if (selectTrippieName && selectTrippieName.length > 5)
            isValidFormUpdate.isValidTrippieName = true;
        else
            isValidFormUpdate.isValidTrippieName = false;

        let isValidDates = false;
        if (selectDates)
            isValidDates = selectDates.dateFrom && selectDates.dateTo;

        isValidFormUpdate.isValidDates = isValidDates;

        if (selectComposition)
            isValidFormUpdate.isValidComposition = true;

        isValidFormUpdate.isAllFieldValid = isValidFormUpdate.isValidDates && isValidFormUpdate.isValidComposition && isValidFormUpdate.isValidTrippieName;

        setIsValidForm(isValidFormUpdate);
    }, [selectTrippieName, selectComposition, selectDates]);


    const onChangeTravelName = function (event) {
        setTrippieName(event.target.value);
    }
    // funzioni per la composizione
    function onClickSelectPaxes(selectComposition) {
        setShowPaxes(false);

        setSelectComposition(selectComposition);
    }

    function onOpenPanelPaxes() {
        setShowCalendar(false);
        setShowPaxes(true);
    }

    // funzioni per il calendario
    function onOpenPanelCalendar() {
        setShowCalendar(true);
        setShowPaxes(false);
    }

    function onClickSelectDates(values) {
        setShowCalendar(false);

        setSelectDates({
            dateFrom: values.dateFrom,
            dateTo: values.dateTo,
        });
    }

    async function onSearchButton() {
        setIsStartSearch(true);

        try {
            const requestOption = {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' }
            };

            const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'trippie/HaveProduct', requestOption);
            const response = await fetchedRes.json();
            if (response.success && !response.haveProduct) {
                confirmSaveTrippie();
            } else if (response.success && response.haveProduct) {
                let btn = document.getElementById("open_ConfirmSaveTrippie");
                btn.click();
            }

        } catch (ex) {
            console.error(ex);
        }

        setIsStartSearch(false);
    }

    async function confirmSaveTrippie() {

        const requestOptionResetTrippie = {
            method: 'DELETE',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        await fetch(configData.Settings.CommonApi_BaseUrl + 'trippie/ResetTrippie', requestOptionResetTrippie);

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                text: selectTrippieName,
                dateFrom: selectDates.dateFrom,
                dateTo: selectDates.dateTo,
                roomsComposition: selectComposition
            })
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'trippie/SaveHeader', requestOption);
        const response = await fetchedRes.json();
        if (response.success) {
            goToTrippiePage();
        }

    }

    function goToTrippiePage() {
        let url = `${configData.Settings.Base_Url}trippie`;
        window.open(url, '_self');
    }

    function getYesterdayDate() {

        const today = new Date();
        let yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        console.log(new Date(yesterday));
        return new Date(yesterday);
    }

    return (<>
        <div className="row mb-3 " style={{ pointerEvents: isFake && 'none' }}>
            <div className="col-12 col-sm-3 my-1">
                <div class="w-100">
                    <div class="input-group rounded pr-1 bg-white">
                        <div className="input-group rounded pr-1 bg-white">
                            <span className="input-group-text px-2 w-10" id="basic-addon1">
                                <M3Icon iconName="Place" />
                            </span>
                            <div className="pl-1 w-90">
                                <label for="Destination" class="form-label h065"><data m3lab="SearchEngine.Destination">{t('Trippie:SearchEngine:TravelTitle')}</data></label>
                                <input className="form-control h075 border-0 shadow-none w-100"
                                    id="Destination"
                                    value={selectTrippieName}
                                    onChange={e => onChangeTravelName(e)}
                                    placeholder={t('Trippie:SearchEngine:TravelTitle')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 my-1">
                <InputCalendarContainer
                    minDate={getYesterdayDate()}
                    isForceOpenLeft={true}
                    dateFrom={selectDates ? selectDates.dateFrom : null}
                    dateTo={selectDates ? selectDates.dateTo : null}
                    extraOptions={{ multiDate: true }}
                    onOpenPanelCalendar={onOpenPanelCalendar}
                    onClickSelectDates={onClickSelectDates}
                    modeView="SEARCHENGINE"
                    productType={configData.Settings.Products.Structure.IdTipoProdotto}
                    closeExternal={showCalendar}
                    type="xl" />
            </div>
            <div className="col-12 col-sm-12 col-md-3 my-1">
                <InputPaxesCompositionContainer
                    productType={configData.Settings.Products.Structure.IdTipoProdotto}
                    callOnSelectComposition={onClickSelectPaxes}
                    onOpenPanelPaxes={onOpenPanelPaxes}
                    closeExternal={showPaxes}
                    modeView="SEARCHENGINE"
                    type="xl" />
            </div>
            <div className="col-12 col-sm-2 my-1 p-0">
                <button
                    className="btn tp-btn-search w-100 py-3"
                    onClick={_ => onSearchButton()}
                    disabled={!isValidForm.isAllFieldValid && !isStartSearch}
                >
                    {isStartSearch ? <><div className="spinner-border spinner-border-sm text-primary" role="status"><span className="sr-only"></span></div></> : <>{t('Button:Create')}</>}
                </button>
            </div>
            <div className="col-12 col-sm-3 my-1 d-inline text-right "></div>
        </div>
        <a id={"open_ConfirmSaveTrippie"} data-bs-toggle="modal" data-bs-target={"#modal_ConfirmSaveTrippie"}></a>
        <div className="modal fade" id={"modal_ConfirmSaveTrippie"} tabIndex="-1" aria-labelledby="modal_ConfirmSaveTrippieLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modal_ConfirmSaveTrippieLabel">Svuota Trippie</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        Il tuo trippie contiene già dei prodotti o step.<br />
                        Cosa vuoi fare?
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">Chiudi</button>
                        <button type="button" className="btn bt-sm tp-btn-search" onClick={_ => goToTrippiePage()}>Vai al trippie</button>
                        <button type="button" className="btn bt-sm tp-btn-search" onClick={_ => confirmSaveTrippie()}>Elimina prodotti presenti</button>
                    </div>
                </div>
            </div>
        </div>
    </>);
}