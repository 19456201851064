import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const HeaderUserInfo = ({ item }) => {
    const { t } = useTranslation();

    const [username] = useState(() => {
        const jCurrentUser = localStorage.getItem("CurrentUser");
        if (jCurrentUser !== null && jCurrentUser !== undefined) {
            let currentUser = JSON.parse(jCurrentUser);

            let savedUsername = currentUser.name;
            if (currentUser.impersonate !== null && currentUser.impersonate !== undefined) {
                savedUsername = currentUser.impersonate;
            } else {
                if (savedUsername)
                    savedUsername = savedUsername.split('@')[0];
            }

            return savedUsername || "";
        }

        return "";
    });

    function getClassName(className) {
        if (!className)
            return '';

        try {
            return className.split("#")[1];
        } catch (ex) { }

        return '';
    }

    return (
        <>
            <a className={getClassName(item.className) + " " + "d-none d-sm-block"} aria-current="page" href="#">
                <div>
                    <div className="h065 text-decoration-none">{t('Header:Welcome')}:</div>
                    <div className="h065">{username}</div>
                </div>
            </a>
        </>
    );
}