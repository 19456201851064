import React, { useEffect, useState } from 'react';

export const WebContentNavBar = ({ item }) => {

    const [navBarChildren, setNavBarChildren] = useState([]);
    const [navBarButton, setNavBarButton] = useState([]);

    const idModal = "WebContentNavBar_" + getRandomInt();
    function getRandomInt() {
        return Math.floor(Math.random() * 100000);
    }

    useEffect(() => {

        try {
            let myContainer = document.querySelector(`#${idModal}`);
            let classesToShow = [];
            let myButton = myContainer.querySelectorAll("[class^=nav-bar-button-]");
            let selectedButton = '';

            for (let i = 0; i < myButton.length; i++) {

                let className = myButton[i].className;
                if (className.includes('nav-bar-button-')) {
                    setNavBarButton(className);
                    if (!selectedButton)
                        selectedButton = className;

                    let itemToShow = className.replaceAll('nav-bar-button-', '');
                    let classToShow = 'navbar-item-' + itemToShow;
                    classesToShow.push(classToShow);
                }
            }

            setNavBarChildren(classesToShow);
            if (selectedButton)
                showItems(selectedButton, classesToShow);

        } catch (ex) { }

    }, [])

    function clickHandler(event) {

        try {
            const classList = event.target.classList;
            for (let i = 0; i < classList.length; i++) {
                let className = classList[i];
                if (className.includes('nav-bar-button-')) {
                    showItems(className, navBarChildren);
                }
            }
        } catch (ex) { }

    }

    function showItems(className, classesToShow) {
        let itemToShow = className.replaceAll('nav-bar-button-', '');
        let classToShow = 'navbar-item-' + itemToShow;

        let classNames = document.getElementsByClassName(classToShow);
        for (let j = 0; j < classNames.length; j++) {
            classNames[j].style.display = '';
        }

        for (let j = 0; j < classesToShow.length; j++) {
            if (classesToShow[j] !== classToShow) {
                let items = document.getElementsByClassName(classesToShow[j]);
                for (let k = 0; k < items.length; k++) {
                    items[k].style.display = 'none';
                }
            }
        }
    }

    return <>
        <div id={idModal} onClick={clickHandler} dangerouslySetInnerHTML={{ __html: item.html }}></div>
    </>
}