import React from "react"
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";

export const InputPaxesComposition = ({ productType, closePanelCalendar, modeView, isMobile }) => {
    const { t } = useTranslation();

    function onClosePanel() {
        closePanelCalendar();
    }
    function getLabel() {
        if (productType === configData.Settings.Products.Structure.IdTipoProdotto)
            return t('SearchEngine:RoomList');

        if (productType === configData.Settings.Products.Tour.IdTipoProdotto)
            return t('SearchEngine:RoomList');

        return t('SearchEngine:Composition');
    }

    return (
        <>
            <div className="input-group bg-white rounded pr-1">
                <div className={"pl-1 pt-2 " + (modeView === 'MODAL' ? "w-75" : "w-90")}>
                    <input className="form-control border-0 shadow-none w-100"
                        id="Composition"
                        type="text"
                        readOnly
                        value=""
                        placeholder={getLabel()} />
                </div>
                {
                    !isMobile && <button type="button" className="btn-close mt-2 mr-1 m-auto w-auto" onClick={_ => onClosePanel()}></button>
                }
            </div>
        </>
    );
}
