import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import configData from "../../../../appsettings.json";
import { searchCarpetParameter } from '../../../../js/Constant';
import { getCurrentUserFromJwtToken } from '../../../../js/Utils.js';
import { M3Icon } from "../../../Common/M3Icon";
import { Loading } from '../../../Common/Loading';
import { Error } from '../../../Common/Error';
import { ProductItemNotFound } from '../../../Product/Common/ProductItemNotFound';
import { ProductFilter } from '../../../Product/Common/Filter/ProductFilter';
import { ProductFilterByName } from '../../../Product/Common/Filter/ProductFilterByName';
import { ProductFilterByPrice } from '../../../Product/Common/Filter/ProductFilterByPrice';
import { HotelItemList } from '../../../Product/Hotel/components/HotelItemList';
import { HotelModalStatic } from '../../../Product/Hotel/components/HotelModalStatic';


export const HotelList = ({ carpetId, clientFlowId, extraParam, onClickSearch, saveIntervalCarpetPing }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    // carpet
    const [carpetDataStatusRandom, setCarpetDataStatusRandom] = useState(null);
    const [carpetStatus, setCarpetStatus] = useState(null);
    const [carpetDataStatus, setCarpetDataStatus] = useState(null);
    const [carpetTime, setCarpetTime] = useState(1);
    const [intervalCarpetPing, setIntervalCarpetPing] = useState(null);

    // page
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingFilter, setIsLoadingFilter] = useState(false);
    const [isError, setIsError] = useState(false);
    const [items, setItems] = useState({});
    const [totalItems, setTotalItems] = useState(0);
    const [itemsListMode, setItemsListMode] = useState(0);
    const [showPriceBar, setShowPriceBar] = useState(false);
    const [isLoadingListResult, setIsLoadingListResult] = useState(true);

    const [modalOpenProductId, setModalOpenProductId] = useState(null);
    const [isFavouriteSelected, setIsFavouriteSelected] = useState(false);
    const [searchEngineExtraParams, setSearchEngineExtraParams] = useState(null);

    // roles
    const [showCost, setShowCost] = useState(false);
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);

    // filtri
    const [isResetSelectedItem, setIsResetSelectedItem] = useState(false);
    const [isOrderAZ, setIsOrderAZ] = useState(true);
    const [isChangeAzDisabled, setIsChangeAzDisabled] = useState(true);
    const [filterValues, setFilterValues] = useState(null);
    const [rangePriceInfo, setRangePriceInfo] = useState({
        min: "0",
        max: "5000",
        step: "50",
        currency: "EUR"
    });

    const [filterSelected, setFilterSelected] = useState({
        structureName: null,
        categories: [],
        mealplans: [],
        amenities: [],
        suppliers: [],
        bestRoomTypes: [],
        rangePrice: null,
        pageNumber: 0,
        orderBy: searchCarpetParameter.hotel.orderBy,
        onlyFavourite: false,
        onlyBestSeller: false,
        onlyRecomended: false
    });

    function isFilterSelected() {
        if (filterSelected && (filterSelected.structureName ||
            filterSelected.categories.length > 0 ||
            filterSelected.mealplans.length > 0 ||
            filterSelected.amenities.length > 0 ||
            filterSelected.suppliers.length > 0 ||
            filterSelected.bestRoomTypes.length > 0 ||
            filterSelected.rangePrice
        ))
            return true;

        return false;
    }

    // paging
    const itemsPerPage = searchCarpetParameter.hotel.listMaxItem;
    const [pageCount, setPageCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(0);

    const onChangeShowPriceBar = () => {
        setShowPriceBar(!showPriceBar);
    }

    useEffect(() => {
        setIsLoading(true);
        setIsError(false);
        getCarpetStatus();

        try {

            let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
            let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);

            if (currentUser.roles) {
                if (!currentUser.roles.includes("FE_NonVisualizzaNetto"))
                    setEnableShowNetPrices(true);


                if (currentUser.roles.includes("FE_ShowCost"))
                    setShowCost(true);
            }

        } catch (ex) {
        }

    }, [carpetId]);

    useEffect(() => {
        switch (carpetStatus) {
            case 'Error':
                setIsError(true);
                setIsLoading(false);
                break;

            default: // 'Processing', 'Queued', 'Completed'
                break;
        }

    }, [carpetStatus]);

    useEffect(() => {
        switch (carpetDataStatus) {
            case 'Completed':
                callGetNewData();
                clearCarpetInterval();
                break;

            case 'NewData':
                callGetNewData();
                //clearCarpetInterval();
                break;

            case 'Processing':
            case 'NoChanges':
                setCarpetInterval();
                break;

            default:
                break;
        }

    }, [carpetDataStatus, carpetDataStatusRandom]);

    useEffect(() => {
        saveIntervalCarpetPing(intervalCarpetPing);
    }, [intervalCarpetPing])

    function onIsFavourites(value) {
        let filterSelectedUpdate = {
            ...filterSelected
        };

        filterSelectedUpdate.onlyFavourite = value;

        callFilterValues(filterSelectedUpdate);
        setFilterSelected(filterSelectedUpdate);
    }

    function onIsBestSeller(value) {
        let filterSelectedUpdate = {
            ...filterSelected
        };

        filterSelectedUpdate.onlyBestSeller = value;

        callFilterValues(filterSelectedUpdate);
        setFilterSelected(filterSelectedUpdate);
    }

    function onIsReccomended(value) {
        let filterSelectedUpdate = {
            ...filterSelected
        };

        filterSelectedUpdate.onlyRecomended = value;

        callFilterValues(filterSelectedUpdate);
        setFilterSelected(filterSelectedUpdate);
    }
    function onFavourite(favourite) {
        setIsFavouriteSelected(favourite)
    }

    // API carpet
    async function callGetNewData() {
        let callStructureList = filterValues === null || carpetStatus === 'Completed';
        getStructureListFilters(callStructureList);

        setIsLoading(false);
        setIsError(false);

        // conto quante chiamate sto facendo
        // ogni chiamata avviene dopo un tot di tempo, quindi in base al numero di chiamate so quanto è passato
        let callTicks = carpetTime * searchCarpetParameter.hotel.carpetPingMs;
        let finishTime = callTicks > searchCarpetParameter.hotel.carpetMaxTimeMs
        setCarpetTime(carpetTime + 1);

        if (carpetStatus !== 'Completed' && !finishTime) {
            setIsLoadingFilter(true);
            setCarpetInterval();
        } else {
            setIsLoadingFilter(false);
            clearCarpetInterval();
        }
    }

    async function getStructureList(filterSelectedUpdate, pageNumber) {
        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetId,
            pageNumber: pageNumber,
            pageSize: searchCarpetParameter.hotel.listMaxItem,
            showCost: showCost,
            structureName: filterSelectedUpdate.structureName,
            categories: filterSelectedUpdate.categories,
            mealplans: filterSelectedUpdate.mealplans,
            services: filterSelectedUpdate.services,
            suppliers: filterSelectedUpdate.suppliers,
            bestRoomTypes: filterSelectedUpdate.bestRoomTypes,
            rangePrice: filterSelectedUpdate.rangePrice,
            cityLocations: filterSelectedUpdate.cityLocations,
            orderBy: filterSelectedUpdate.orderBy,
            onlyFavourite: filterSelectedUpdate.onlyFavourite,
            onlyBestSeller: filterSelectedUpdate.onlyBestSeller,
            onlyRecomended: filterSelectedUpdate.onlyRecomended
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}structure/getstructurelist`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setItems(response);

            if (response && response.structures && response.structures.length > 0) {
                setSearchEngineExtraParams({
                    latitude: (response.structures[0].latitude ? response.structures[0].latitude : ''),
                    longitude: (response.structures[0].longitude ? response.structures[0].longitude : ''),
                    structureId: response.structures[0].structureId
                });
            }

            renderPaginator(response.totalStructures);
        }

        if (carpetDataStatus === 'Completed') {
            setIsLoadingFilter(false);
        }

        setIsLoadingListResult(false);
    }

    async function getStructureListFilters(callStructureList) {
        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetId
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}structure/getstructurelistfilters`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setFilterValues(response);
            setTotalItems(response.totalStructures);

            if (carpetDataStatus === 'Completed' && response.rangePricesInfo) {
                setRangePriceInfo({
                    ...rangePriceInfo,
                    min: response.rangePricesInfo.min,
                    max: response.rangePricesInfo.max,
                    currency: response.rangePricesInfo.name
                });
            }

            if (response.hotelSearchParameter && response.hotelSearchParameter.destinationType !== "Destination") {
                filterSelected.orderBy = "8"; // distanza
                setIsChangeAzDisabled(false);
                setFilterSelected(filterSelected);
            }

            if (callStructureList)
                getStructureList(filterSelected, 0);
        }
    }

    async function getCarpetStatus() {
        console.log(`Call GetCarpetStatus [CarpetId = ${carpetId}]`);

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}carpet/getcarpetstatus/${carpetId}`, requestOption);
        if (!fetchedRes.ok) {
            setIsError(true);
        } else {
            const response = await fetchedRes.json();

            let dataStatus = response.dataStatus;
            let status = response.status;
            if (response.success) {
                setCarpetStatus(status);

                if (status !== 'Error') {
                    // messo per richiamare il getCarpetStatus, nel caso riceva piu volte lo status NoChanges (lo status Processing esiste solo lato FE)
                    setCarpetDataStatus(dataStatus !== carpetDataStatus ? dataStatus : 'Processing');
                    setCarpetDataStatusRandom(Math.random());
                }
            } else {
                setIsError(true);
            }

            console.log(`Status: ${status} - Data Status: ${dataStatus}`);
        }
    }

    function setCarpetInterval() {
        if (!intervalCarpetPing) {
            //console.log("--- setCarpetInterval");
            let interval = setInterval(() => { getCarpetStatus(); }, searchCarpetParameter.hotel.carpetPingMs);
            setIntervalCarpetPing(interval);
        }
    }
    function clearCarpetInterval() {
        if (intervalCarpetPing) {
            //console.log("--- clearCarpetInterval");
            clearInterval(intervalCarpetPing);
            setIntervalCarpetPing(null);
        }
    }
    function clearCarpetAllInterval() {
        if (intervalCarpetPing) {
            for (let i = 0; i < intervalCarpetPing.length; i++) {
                let intId = intervalCarpetPing[i];
                clearInterval(intId);
            }

            setIntervalCarpetPing([]);
        }
    }

    // metodi filtro
    function callFilterValues(filterSelectedUpdate) {
        setIsLoadingFilter(true);
        getStructureList(filterSelectedUpdate, 0);
    }

    const onChangeAZ = (e) => {
        setIsOrderAZ(!isOrderAZ);

        if (filterSelected.orderBy === "3") { // nome az
            let filterSelectedUpdate = {
                ...filterSelected,
                orderBy: "2"
            };

            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
        else if (filterSelected.orderBy === "2") { // nome za
            let filterSelectedUpdate = {
                ...filterSelected,
                orderBy: "3"
            };

            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
        else if (filterSelected.orderBy === "4") { // distanza az
            let filterSelectedUpdate = {
                ...filterSelected,
                orderBy: "8"
            };

            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
        else if (filterSelected.orderBy === "8") { // distanza za
            let filterSelectedUpdate = {
                ...filterSelected,
                orderBy: "4"
            };

            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
    }

    function onChangeSortBy(event) {
        let orderByValue = event.target.selectedOptions[0].value;

        let filterSelectedUpdate = {
            ...filterSelected,
            orderBy: orderByValue
        };

        if (orderByValue === "0" || orderByValue === "1") {
            setIsChangeAzDisabled(true);
        }
        else {
            setIsChangeAzDisabled(false);
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilter(selectedItems, type) {
        let filterSelectedUpdate = { ...filterSelected };

        switch (type) {
            case 'StructureStars': {
                filterSelectedUpdate.categories = selectedItems;
                break;
            }
            case 'StructureMealplans': {
                filterSelectedUpdate.mealplans = selectedItems;
                break;
            }
            case 'StructureAmenities': {
                filterSelectedUpdate.services = selectedItems;
                break;
            }
            case 'StructureSuppliers': {
                filterSelectedUpdate.suppliers = selectedItems;
                break;
            }
            case 'BestRoomTypes': {
                filterSelectedUpdate.bestRoomTypes = selectedItems;
                break;
            }
            case 'StructureCityLocations': {
                filterSelectedUpdate.cityLocations = selectedItems;
                break;
            }
            default:
                break;
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilterName(name) {
        let filterSelectedUpdate = {
            ...filterSelected,
            structureName: name
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilterPrice(rangePrice) {
        let filterSelectedUpdate = {
            ...filterSelected,
            rangePrice: rangePrice
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onClearFilter() {
        let filterSelectedUpdate = {
            ...filterSelected,
            categories: [],
            mealplans: [],
            amenities: [],
            suppliers: [],
            bestRoomTypes: [],
            structureName: null,
            rangePrice: null,
            onlyFavourite: false,
            onlyBestSeller: false,
            onlyRecomended: false
        }

        setIsResetSelectedItem(!isResetSelectedItem);
        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onOpenMap() {
        localStorage.setItem("ProductList", JSON.stringify(items.structures));
        window.location.href = configData.Settings.Base_Url + "hotel/hotelMap/?cId=" + carpetId + "&cfId=" + clientFlowId;
    }

    // metodi paginator
    const handlePageClick = async e => {
        setPageSelected(e.selected);

        let filterSelectedUpdate = {
            ...filterSelected,
            pageNumber: e.selected + 1
        }

        setFilterSelected(filterSelectedUpdate);
        getStructureList(filterSelected, e.selected);
    };

    function renderPaginator(totalItems) {
        setPageCount(Math.ceil(totalItems / itemsPerPage));
    }

    function onChangeItemsListMode(mode) {
        setItemsListMode(mode);
    }

    function getFilterOrderByValue(orderValue) {
        let res = null;

        searchCarpetParameter.hotel.orderByValues.forEach(function (order) {
            if (order.descendingValue === parseInt(orderValue)) {
                res = order.value;
            }
        });

        if (res == null) {
            res = orderValue;
        }

        return res;
    }

    async function onClickSearchInternal(structureId, filters = null) {
        clearCarpetAllInterval();

        let structureName = structureId;
        let myItem = items.structures.filter(x => x.structureId === structureId);
        if (myItem.length > 0)
            structureName = myItem[0].name;

        onClickSearch(structureId, structureName, filters, filterSelected, filterValues.hotelSearchParameter);
    }

    return (
        <>
            <div className="bg-bluesoft-100">
                <div className="container pt-4">
                    <div className="row">
                        {isLoading && <div className="col-12"><Loading textMsg={t('Product:Structure:LoadingList')} /></div>}
                        {!isLoading && isError && <div className="col-12"><Error textMsg={t('Product:Structure:Error')} /></div>}

                        {
                            !isLoading && !isError && items && <>
                                <div className="col-12 col-sm-12 col-md-5">
                                    <div className="d-table h-100">
                                        <div className="d-table-row ">
                                            <div className="d-table-cell align-bottom pb-3 h5">
                                                <data m3lab="Product:Structure.NStructure">
                                                    {t(`Product:Structure:NStructure`)}:
                                                </data>
                                                <span className="ml-2">
                                                    <var>
                                                        {!isFilterSelected() ? totalItems : items.totalStructures}
                                                    </var>
                                                    {
                                                        isLoadingFilter && <div className="spinner-border spinner-border-sm text-primary ml-2" role="status"></div>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-7 pt-1 text-right d-none d-sm-block">
                                    <div className="row">
                                        <div className="col-7 text-right pr-0">
                                            <span className="input-group mb-3 text-right">
                                                <select className="form-select border-0" aria-label="Disabled select example" value={getFilterOrderByValue(filterSelected.orderBy)} onChange={(e) => { onChangeSortBy(e); }}>
                                                    <option value="">
                                                        <data m3lab="General.Order.OrderBy">{t(`General:Order:OrderBy`)}</data>
                                                    </option>
                                                    {filterValues &&
                                                        searchCarpetParameter.hotel.orderByValues.filter(x => (filterValues.hotelSearchParameter.ray === 0 ? x.value !== 8 : x)).map((item, key) => {
                                                            return <option key={key} value={item.value}>
                                                                <data m3lab={item.label} >{t(item.label)}</data>
                                                            </option>
                                                        })
                                                    }
                                                </select>
                                                <button disabled={isChangeAzDisabled} className="btn-neutral btn m-0 p-0 pr-1 d-inline" type="button" id="button-addon2" onClick={(e) => { onChangeAZ(e); }}>
                                                    <div className={(!isOrderAZ ? "" : "d-none")}>
                                                        <data m3ico="SortBy icon-15 text-bluesoft">
                                                            <data m3ico="ResultList icon-25">
                                                                <M3Icon iconName="OrderByAsc" externalClass="icon-15 text-bluesoft p-1" hasLabel={false} />
                                                            </data>
                                                        </data>
                                                    </div>
                                                    <div className={(isOrderAZ ? "" : "d-none")}>
                                                        <data m3ico="SortBy icon-15 text-bluesoft">
                                                            <data m3ico="ResultList icon-25">
                                                                <M3Icon iconName="OrderByDesc" externalClass="icon-15 text-bluesoft p-1" hasLabel={false} />
                                                            </data>
                                                        </data>
                                                    </div>
                                                </button>
                                            </span>
                                        </div>
                                        <div className="col-5 pl-0">
                                            <button data-bs-toggle="modal" data-bs-target="#filterModal" className="openFilterButton p-1 px-2 pt-2 rounded border-0 shadow tp-btn-search">
                                                {t(`General:OpenFilter`)}
                                            </button>

                                            {
                                                enableShowNetPrices && <button className={(showPriceBar ? "tp-btn-select" : "tp-btn-outline-select") + " ms-2 p-1 px-2 pt-2 rounded border-0 shadow"} m3rool="f_ShowPriceBar" onClick={(e) => { onChangeShowPriceBar() }}>
                                                    <data m3lab="Button.Net">
                                                        {t("Button:Net")}
                                                    </data>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                { /* Colonna Items */}
                                <div className="col-12 col-sm-12 pt-1">
                                    {
                                        !isLoadingListResult && items && items.structures && items.structures.length > 0 && itemsListMode === 0 && items.structures.map((item, key) => {
                                            return <HotelItemList key={key}
                                                item={item}
                                                showPriceBar={showPriceBar}
                                                showCost={showCost}
                                                extraParam={extraParam}
                                                modalOpenProductId={setModalOpenProductId}
                                                onClickSearch={onClickSearchInternal}
                                                onFavourite={onFavourite}
                                            />
                                        })
                                    }
                                    {
                                        isLoadingListResult && <div className="text-center"><Loading textMsg={t('Product:Structure:LoadingList')} /></div>
                                    }
                                    {
                                        !isLoadingListResult && (!items || (!items.structures || items.structures.length === 0)) && <ProductItemNotFound label="Nessuna struttura trovata" />
                                    }
                                </div>

                                {modalOpenProductId &&
                                    <HotelModalStatic structureId={modalOpenProductId} modalCloseProductId={setModalOpenProductId} isNotEngine={true} isFavouriteSelected={isFavouriteSelected} />
                                }

                                { /* Paginator */}
                                {
                                    items && items.structures && items.structures.length > 0 && <>
                                        <div className="col-12 col-sm-3 col-md-3 col-lg-39 col-xl-3 pt-1"></div>
                                        <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 pt-1">
                                            <nav aria-label="Page navigation comments" className="mt-4">
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t('pagination:nextLabel')}
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel={t('pagination:previousLabel')}
                                                    renderOnZeroPageCount={null}
                                                    forcePage={pageSelected}

                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    marginPagesDisplayed={2}
                                                    containerClassName="pagination justify-content-center"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    activeClassName="active"
                                                />
                                            </nav>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="modal tp-search-input fade" id="filterModal" tabIndex="-1" aria-labelledby="EngineModifyActivty" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen-xxl-down">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <div className="h4 text-center w-100 mt-2">
                                {t('SearchEngine:UpdateSearch')}
                            </div>
                            <button type="button" className="btn-close mt-2 pr-4" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body bg-gray-300">

                            {filterValues && <div className=''>
                                {/* Nome */}
                                <ProductFilterByName
                                    items={filterValues.hotelNames}
                                    label={t("SearchEngine:FindHotel")}
                                    type="StructureName"
                                    onApplyFilter={onApplyFilterName}
                                    resetSelectedItem={isResetSelectedItem} />

                                <button className="btn btn-sm btn-block tp-btn-search animate-up-2 mt-4" onClick={(e) => onClearFilter(e)}>
                                    <data m3lab="Button.FilterRemove">{t('Button:FilterRemove')}</data>
                                </button>

                                { /* Price */}
                                <ProductFilterByPrice
                                    rangePriceInfo={rangePriceInfo}
                                    onApplyFilter={onApplyFilterPrice}
                                    resetSelectedItem={isResetSelectedItem} />

                                { /* BestRoomTypes */}
                                <ProductFilter
                                    items={filterValues.bestRoomTypes}
                                    label="Product:Structure:Filter:Generic:Title"
                                    type="BestRoomTypes"
                                    onApplyFilter={onApplyFilter}
                                    resetSelectedItem={isResetSelectedItem} />

                                { /* Stelle */}
                                <ProductFilter
                                    items={filterValues.categories}
                                    label="Product:Structure:Filter:Star:Title"
                                    type="StructureStars"
                                    onApplyFilter={onApplyFilter}
                                    resetSelectedItem={isResetSelectedItem} />
                                { /* Trattamento */}
                                <ProductFilter
                                    items={filterValues.mealplans}
                                    label="Product:Structure:Filter:MealPlan:Title"
                                    type="StructureMealplans"
                                    onApplyFilter={onApplyFilter}
                                    resetSelectedItem={isResetSelectedItem} />

                                { /* Amenities */}
                                <ProductFilter
                                    items={filterValues.amenities}
                                    label="Product:Structure:Filter:Amenities"
                                    type="StructureAmenities"
                                    onApplyFilter={onApplyFilter}
                                    resetSelectedItem={isResetSelectedItem} />

                                { /* Zone */}
                                <ProductFilter
                                    items={filterValues.cityLocations}
                                    label="Product:Structure:Filter:CityLocation"
                                    type="StructureCityLocations"
                                    onApplyFilter={onApplyFilter}
                                    resetSelectedItem={isResetSelectedItem} />

                                { /* Provider */}
                                <ProductFilter
                                    items={filterValues.suppliers}
                                    label="Product:Structure:Filter:Provider"
                                    type="StructureSuppliers"
                                    onApplyFilter={onApplyFilter}
                                    resetSelectedItem={isResetSelectedItem} />
                            </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}