import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from './AuthProvider';
import { Loading } from '../Common/Loading';
import { getCDNLogo } from "../../js/CDNUtility";
import configData from "../../appsettings.json";
import Cookies from 'universal-cookie';
import { getWebsiteId } from '../../js/Utils.js';

export const Login = ({ hideRegistration, hideLogo }) => {
    const { onLogin } = useAuth();
    const cookies = new Cookies();

    const { t } = useTranslation();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginErrorMsg, setLoginErrorMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [supportedLanguages, setSupportedLanguages] = useState(["en-US", "it-IT"]);


    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let ux = params.get('ux');
        let pw = params.get('pw');
        if (ux && pw) {
            let request = {
                username: ux,
                password: pw,
                websiteId: getWebsiteId(),
                supportedLanguages: supportedLanguages
            };
            LoginWithOldParams(request);
        }
    }, []);

    async function LoginWithOldParams(credentials) {
        const response = await loginUser(credentials);
        if (response.success === true) {
            setLoginErrorMsg(false);
            onLogin(response.token, configData.Settings.Base_Url, null, response.isanonymous, response.addToOrder, response.startModule);
        } else {
            setLoginErrorMsg(true);
            setIsLoading(false);
        }
    }

    async function loginUser(credentials) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(credentials)
        };

        cookies.remove("CultureName", { path: '/' });
        cookies.remove("SessionToken", { path: '/' });
        return fetch(configData.Settings.CommonApi_BaseUrl + 'auth/login', requestOption).then(data => data.json());
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setIsLoading(true);

        let request = {
            username: username,
            password: password,
            websiteId: getWebsiteId(),
            supportedLanguages: supportedLanguages
        };

        const response = await loginUser(request);
        if (response.success === true) {
            setLoginErrorMsg(false);
            onLogin(response.token, null, null, response.isanonymous, response.addToOrder, response.startModule);
        } else {
            setLoginErrorMsg(true);
            setIsLoading(false);
        }
    }

    function goTo(e, link) {
        e.stopPropagation();
        e.preventDefault();
        window.location.href = configData.Settings.Base_Url + link;
    }

    return (
        <div className="row">
            {
                !hideLogo && <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                    <img src={getCDNLogo()} alt={t('home:title')} style={{ width: '200px' }} />
                    <h4>{t("home:welcome")}</h4>
                </div>
            }
            <div className="d-flex justify-content-center">
                {isLoading
                    ? (<Loading textMsg={t('Loading:Login')} />)
                    : (
                        <>
                            <form onSubmit={handleSubmit} className="w-100">
                                <div className="row">
                                    <div className="col-12 py-2">
                                        <input id="iUx" type="text" className="form-control" placeholder="Username" aria-label="Username" onChange={e => setUsername(e.target.value)} />
                                    </div>
                                    <div className="col-12 py-2">
                                        <input id="iPw" type="password" className="form-control" placeholder="Password" aria-label="Password" onChange={e => setPassword(e.target.value)} />
                                    </div>
                                    <div className="mt-2 d-flex forgot-pwd-msg">
                                        <label className="pt-1">{t("Login:Forgot")}</label> &nbsp;
                                        <a className="small p-0" href="ForgotPassword">{t("Login:ForgotUsername")}</a>
                                    </div>
                                    <div className="col-12 py-2">
                                        <button type="submit" className="btn bg-custom  btn-primary w-100">{t("Login:Submit")}</button>
                                    </div>
                                    {
                                        !hideRegistration && <><div className="text-center mt-2">
                                            <label>{t("Login:NotRegister")}</label>
                                        </div>
                                            {
                                                configData.Settings.Login.RegisterAgency && <div className="col-12 py-2">
                                                    <button className="btn bg-custom btn-primary w-100" onClick={(e) => goTo(e, "RegistrationUserAgency")}>
                                                        {t("Login:ForTravelAgent")}
                                                    </button>
                                                </div>
                                            }
                                            {
                                                configData.Settings.Login.RegisterEndUser && <div className="col-12 py-2">
                                                    <button className="btn btn-sm buttonCustom mt-3 w-100" onClick={(e) => goTo(e, "RegistrationUser")}>
                                                        {t("Login:ForTraveler")}
                                                    </button>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </form>
                        </>
                    )
                }
            </div>
            {loginErrorMsg ? (
                <div className="row">
                    <div className="col-12 py-2 text-center">
                        <h6 className="penalty">Invalid username or password</h6>
                    </div>
                </div>
            ) : (<></>)}
        </div>
    );
}