import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../../../js/Utils';

export const PrintAccountBalanceSubHeader = ({ titleData, cultureName }) => {
    const { t } = useTranslation();

    return (
        <>
            <table style={{ borderTop: '1px', borderTopColor: '#e7e7e7', marginBottom: '20px' }}>
                <tr>
                    <td style={{ fontSize: '16px', textAlign: 'center' }}>
                        <label style={{ fontSize: '14px' }}>{t(`documents:numeroestrattoconto`)}: </label><b>{titleData.docNumber} </b>
                        <label style={{ fontSize: '14px' }}>Data: </label><b>{formatDateTime(titleData.docDate, cultureName, { twoDigits: true })}</b>
                    </td>
                </tr>
            </table>
            <div style={{ borderTop: '1px', borderTopColor: '#e7e7e7', borderTopWidth: '2px', height: '5px' }} ></div>
            <table style={{ borderTop: '1px', borderTopColor: '#e7e7e7', marginBottom: '30px' }}>
                <tr style={{ marginBottom: '5px' }}>
                    <td>
                        <div style={{ fontSize: '10px' }}><label style={{ fontSize: '12px' }}>{t("MyBook:ToAgency")}:</label></div>
                    </td>
                    <td>
                        <div style={{ fontSize: '10px' }}><label style={{ fontSize: '12px' }}>{t("MyBook:Travel")}:</label></div>
                    </td>
                    <td>
                        <div style={{ fontSize: '12px' }}><label style={{ fontSize: '12px' }}>Ref Pax:</label></div>
                    </td>
                </tr>
                <tr style={{ marginBottom: '5px' }}>
                    <td>
                        <div style={{ fontSize: '14px' }}>{titleData.agencyName}</div>
                    </td>
                    <td>
                        <div style={{ fontSize: '14px' }}>
                            <label style={{ fontSize: '12px' }}>{t("MyBook:StartDate")}: </label>
                            {formatDateTime(titleData.startDate, cultureName, { twoDigits: true })}
                            <label style={{ fontSize: '12px', marginLeft: '2px' }}> {t("MyBook:EndDate")}: </label>{formatDateTime(titleData.endDate, cultureName, { twoDigits: true })}
                        </div>
                    </td>
                    <td>
                        <div style={{ fontSize: '14px' }}>{titleData.refPax}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div style={{ fontSize: '12px' }}>{titleData.username}</div>
                    </td>
                    <td>
                        <div style={{ fontSize: '12px' }}>
                            <label style={{ fontSize: '12px' }}>{t("MyBook:TravelTitle")}:</label>
                            {titleData.title}
                        </div>
                    </td>
                    <td></td>
                </tr>
            </table>
        </>
    );
}