import React, { useState, useEffect } from 'react';
import { formatDateTime, createRenderHtml } from '../../../js/Utils.js';
import { M3Icon } from '../../Common/M3Icon';
import { useTranslation } from 'react-i18next';


export const QuotationItemChat = ({ chat, callReadChat, callAddChatAnswer, isReplyPossible, cultureName, isAgencyMode }) => {
    const { t } = useTranslation();
    const noteToAddMaxLength = 1000;

    const [isLoadingChatToAdd, setIsLoadingChatToAdd] = useState(false);

    const [chatAnswerToAdd, setChatAnswerToAdd] = useState("");
    const [isValidChatAnswerToAdd, setIsValidChatAnswerToAdd] = useState(false);

    function onChatAddAnswer(event) {
        const myValue = event.target.value;
        const isValid = !(myValue === '' || myValue === undefined || myValue === null);

        setChatAnswerToAdd(myValue);
        setIsValidChatAnswerToAdd(isValid);
    }

    function onSendAddChatAnswer() {
        setIsLoadingChatToAdd(true);

        let answerChatId = chat.chatId;
        if (chat.parentId)
            answerChatId = chat.parentId;

        callAddChatAnswer({
            chatId: answerChatId,
            text: chatAnswerToAdd
        });
    }

    function onClickReadChat() {
        callReadChat(chat.chatId);
    }

    useEffect(() => {
        setIsLoadingChatToAdd(false);
        setIsValidChatAnswerToAdd(false);
    }, [chat]);

    return (
        <>
            <div className="d-flex justify-content-between mb-4">
                <span className="font-small">
                    <span className="font-weight-bold">{chat.name}</span>
                    <span className="ml-2">{formatDateTime(chat.created, cultureName, { withTime: true })}</span>
                </span>
                <div>
                    {
                        !chat.isRead && <button className="btn btn-link text-danger"
                            onClick={onClickReadChat}
                            aria-label="report button"
                            data-toggle="tooltip"
                            data-placement="top"
                            title=""
                            data-original-title="Report comment"
                            data-bs-original-title="Report comment"
                            aria-describedby="tooltip214794">
                            <span className="far fa-flag"></span>
                        </button>
                    }
                    {
                        !isAgencyMode && chat.usersReaded && chat.usersReaded.length > 0 && <button className="btn btn-link text-black"
                            onClick={onClickReadChat}
                            aria-label="report button"
                            data-toggle="tooltip"
                            data-placement="top"
                            title=""
                            data-original-title="Report comment"
                            data-bs-original-title="Report comment"
                            aria-describedby="tooltip761515">
                            <span className="far fa-flag"></span>
                        </button>
                    }
                    {
                        !isAgencyMode &&chat.usersReaded && chat.usersReaded.length > 0 && chat.usersReaded.map((userRead, keyUser) => {
                            return <React.Fragment key={keyUser}><span>{userRead.name} {formatDateTime(userRead.readDate, cultureName, { withTime: true, noYear: true })}</span><br /></React.Fragment>
                        })
                    }
                </div>
            </div>
            <p className="m-0" dangerouslySetInnerHTML={createRenderHtml(chat.note)}></p>
            {
                isReplyPossible && <>
                    <div className="mt-4 mb-3 w-100 text-right">
                        <a className="btn font-weight-light h6 text-end w-100 text-decoration-none" data-bs-toggle="collapse" data-bs-target={`#pnlChatAnswer_${chat.chatId}`} aria-expanded="true" aria-controls={`#pnlChatAnswer_${chat.chatId}`}>
                            <M3Icon iconName="Reply" externalClass="h085" /> Reply
                        </a>
                    </div>
                    <div id={`pnlChatAnswer_${chat.chatId}`} className="accordion-collapse collapse" role="region">
                        <div>
                            <textarea
                                className="form-control border border-light-gray"
                                id="txtChatAnswer"
                                value={chatAnswerToAdd}
                                placeholder={t("MyBook:SendAuthorize")}
                                rows="6"
                                style={{maxHeight: '150px'} }
                                maxLength={noteToAddMaxLength}
                                onChange={e => onChatAddAnswer(e)}></textarea>

                            <div className="d-flex justify-content-between mt-3">
                                <small className="font-weight-light text-dark">
                                    <span id="charactersRemaining">
                                        this will be filled with {noteToAddMaxLength} from the textarea's maxlength characters remaining
                                    </span>
                                </small>
                                {
                                    isLoadingChatToAdd && <div className="spinner-border" role="status"><span className="sr-only">Invio...</span></div>
                                }
                                {
                                    !isLoadingChatToAdd && <button className="btn tp-btn-confirm animate-up-2"
                                        onClick={onSendAddChatAnswer}
                                        disabled={!(isValidChatAnswerToAdd)}>
                                        Send
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}