import React, { useState, useEffect, useRef } from 'react';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { InputCalendarReadOnly } from '../../../Common/Calendar/InputCalendarReadOnly';
import { InputMonthCalendarReadOnly } from '../../../Common/MonthPicker/InputMonthCalendarReadOnly';
import { InputPaxesCompositionReadOnly } from '../../../Common/Paxes/InputPaxesCompositionReadOnly';
import { StructureSearchEngine } from "../StructureSearchEngine";
import { ActivitySearchEngine } from "../ActivitySearchEngine";
import { FlightSearchEngine } from "../FlightSearchEngine";
import { TrainSearchEngine } from "../TrainSearchEngine";
import { TransferSearchEngine } from "../TransferSearchEngine";
import { TourSearchEngine } from "../TourSearchEngine";
import { CruiseSearchEngine } from "../CruiseSearchEngine";
import { getFlightType, getFlightDates } from "../../../../js/ProductService";
import { OsmTravelPlaceMap } from './../../../TravelPlaceMap/OsmTravelPlaceMap';
import { CarSearchEngine } from '../CarSearchEngine';
import { InputSearchDestinationOnlyRead } from '../../../Common/Destination/InputSearchDestinationOnlyRead';
import { getDateObj } from "../../../../js/Utils.js";

export const ListSearchEngineCV = ({ imageUrl, searchParameters, productType, typeSearch, extraParams,  isShowMap = false }) => {
    const { t } = useTranslation();

    const [normalizeSearchParam, setNormalizeSearchParam] = useState({});
    const [extraOptions, setExtraOptions] = useState({ multiDate: false });
    const [multiDestinationName, setMultiDestinationName] = useState(false);

    const [isOnlyDestination, setIsOnlyDestination] = useState(false);

    const [showFilterArea, setShowFilterArea] = useState(false);
    const [sizeInMeters, setSizeInMeters] = useState(5000);
    const [featureFiltered, setFeatureFiltered] = useState([]);

    const [isShowViaggiatori, setIsShowViaggiatori] = useState(true);
    const [isShowMapImage, setIsShowMapImage] = useState(true);


    // usato solo quando si apre la modal, e bisogna resettare i dati com'erano in origine
    const [resetValues, setResetValues] = useState(false);

    //map
    const geoJsonRef = useRef();
    const [mapArr, setMapArr] = useState(null);
    const [map, setMap] = useState(null);

    useEffect(() => {
        createDefaultSearchParam();

        document
            .getElementById("EngineModifyActivty")
            .addEventListener('shown.bs.modal', function () {
                setResetValues(true);
            });

        document
            .getElementById("EngineModifyActivty")
            .addEventListener('hidden.bs.modal', function () {
                setResetValues(false);
            });

    }, []);

    useEffect(() => {
        createDefaultSearchParam();
    }, [searchParameters])

    function createDefaultSearchParam() {
        let normalizeSearchParam = {};

        if (searchParameters && productType === configData.Settings.Products.Structure.IdTipoProdotto) {
            normalizeSearchParam.destinationName = searchParameters.destinationDesc;

            setMultiDestinationName(false);
            setExtraOptions({ ...extraOptions, multiDate: true });
            normalizeSearchParam.fromPeriod = searchParameters.checkIn;
            normalizeSearchParam.toPeriod = searchParameters.checkOut;

            if (isShowMap && extraParams && extraParams.longitude && extraParams.latitude) {
                setMapArr({
                    type: "FeatureCollection",
                    features: [{
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [extraParams.longitude, extraParams.latitude]
                        },
                        properties: {
                            StructureId: extraParams && extraParams.structureId,
                            StructureName: extraParams && typeSearch !== "SRC" ? extraParams.productName : normalizeSearchParam.destinationName,
                            StructureCategory: "&lt;i class=&#39;fas fa-star&#39;&gt;&lt;/i&gt;&lt;i class=&#39;fas fa-star&#39;&gt;&lt;/i&gt;",
                            StructureThumbnail: configData.Settings.CDN_Custom + "/images/noimage/no-image-hotel.jpg",
                            BestPriceAmount: null,
                            BestPriceCurrency: null,
                            BestMealplan: null
                        }
                    }]
                });
            }
        }

        if (searchParameters && productType === configData.Settings.Products.Activity.IdTipoProdotto) {
            normalizeSearchParam.destinationName = searchParameters.destinationDesc;

            setMultiDestinationName(false);
            setExtraOptions({ ...extraOptions, multiDate: true });
            normalizeSearchParam.fromPeriod = searchParameters.dateFrom;
            normalizeSearchParam.toPeriod = searchParameters.dateTo;

            if (isShowMap && extraParams && extraParams.longitude && extraParams.latitude) {
                setMapArr({
                    type: "FeatureCollection",
                    features: [{
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [extraParams.longitude, extraParams.latitude]
                        },
                        properties: {
                            StructureId: extraParams && extraParams.activityId,
                            StructureName: extraParams && typeSearch !== "SRC" ? extraParams.productName : normalizeSearchParam.destinationName,
                            StructureCategory: "&lt;i class=&#39;fas fa-star&#39;&gt;&lt;/i&gt;&lt;i class=&#39;fas fa-star&#39;&gt;&lt;/i&gt;",
                            StructureThumbnail: configData.Settings.CDN_Custom + "/images/noimage/no-image-hotel.jpg",
                            BestPriceAmount: null,
                            BestPriceCurrency: null,
                            BestMealplan: null
                        }
                    }]
                });
            }
        }

        if (searchParameters && productType === configData.Settings.Products.Flight.IdTipoProdotto) {
            let flightType = getFlightType(searchParameters);
            let flightDates = getFlightDates(searchParameters);

            let departureLocation = searchParameters.legsCriteria[0].departureLocation;
            let arrivalLocation = searchParameters.legsCriteria[0].arrivalLocation;

            if (flightType === 3) {
                let lastIndex = searchParameters.legsCriteria.length;
                arrivalLocation = searchParameters.legsCriteria[lastIndex - 1].arrivalLocation;
            }

            if (!departureLocation.name) {
                let depName = `${departureLocation.code} > ${arrivalLocation.code}`;
                normalizeSearchParam.destinationName = [depName];
            } else {
                normalizeSearchParam.destinationName = [`${departureLocation.code} - ${departureLocation.name}`, `${arrivalLocation.code} - ${arrivalLocation.name}`];
            }

            if (flightDates) {
                normalizeSearchParam.fromPeriod = flightDates[0].dateFrom;
                if (flightType === 2)
                    normalizeSearchParam.toPeriod = flightDates[0].dateTo;

            } else {
                normalizeSearchParam.fromPeriod = null;
                normalizeSearchParam.toPeriod = null;
            }

            if (flightType === 2) {
                setExtraOptions({ ...extraOptions, multiDate: true });
            } else {
                setExtraOptions({ ...extraOptions, multiDate: false });
            }

            setMultiDestinationName(true);
        }

        if (searchParameters && productType === configData.Settings.Products.Train.IdTipoProdotto) {
            let outboundLegCriteria = searchParameters.outboundLegCriteria;
            let returnLegCriteria = searchParameters.returnLegCriteria;

            normalizeSearchParam.destinationName = [outboundLegCriteria.departureLocation.stationDesc, outboundLegCriteria.arrivalLocation.stationDesc];

            setMultiDestinationName(true);

            normalizeSearchParam.fromPeriod = outboundLegCriteria.departureDate;
            normalizeSearchParam.toPeriod = null;

            normalizeSearchParam.timeFrom = outboundLegCriteria.departureTimeSlot.fromTime;
            normalizeSearchParam.timeTo = null;

            if (returnLegCriteria) {
                normalizeSearchParam.toPeriod = returnLegCriteria.departureDate;
                normalizeSearchParam.timeTo = returnLegCriteria.departureTimeSlot.fromTime;

                setExtraOptions({ ...extraOptions, multiDate: true });
            } else {
                setExtraOptions({ ...extraOptions, multiDate: false });
            }
        }

        if (searchParameters && productType === configData.Settings.Products.CarRental.IdTipoProdotto) {
            setIsShowViaggiatori(false);
            setIsShowMapImage(false);
            setIsOnlyDestination(true);

            if (searchParameters.dropOffSameAsPickup) {
                normalizeSearchParam.destinationName = [searchParameters.pickupDestinationName, searchParameters.pickupDestinationName];
            }
            else {
                normalizeSearchParam.destinationName = [searchParameters.pickupDestinationName, searchParameters.dropoffDestinationName];
            }

            normalizeSearchParam.fromPeriod = searchParameters.pickupDate;
            normalizeSearchParam.toPeriod = searchParameters.dropoffDate;

            normalizeSearchParam.timeFrom = new Date(searchParameters.pickupDate).toTimeString().split(' ')[0].slice(0, -3);
            normalizeSearchParam.timeTo = new Date(searchParameters.dropoffDate).toTimeString().split(' ')[0].slice(0, -3);
        }

        if (searchParameters && productType === configData.Settings.Products.Transfer.IdTipoProdotto) {
            setIsShowViaggiatori(true);
            setIsShowMapImage(false);
            setIsOnlyDestination(true);

            normalizeSearchParam.destinationName = [searchParameters.pickupPoint.name, searchParameters.dropoffPoint.name];

            normalizeSearchParam.fromPeriod = searchParameters.pickupDate;
            normalizeSearchParam.toPeriod = searchParameters.returnPickUpDate;
        }

        if (searchParameters && productType === configData.Settings.Products.Tour.IdTipoProdotto) {
            normalizeSearchParam.destinationName = searchParameters.destinationDesc;

            setMultiDestinationName(false);
            setExtraOptions({ ...extraOptions, multiDate: true });
            let updateCalendar = searchParameters.dates.map(x => getDateObj(x))[0];
            normalizeSearchParam.selectMonths = updateCalendar;

            if (isShowMap && extraParams && extraParams.touchPoints && extraParams.touchPoints.length > 0) {
                let featureArr = [];

                if (typeSearch === "SRC") {
                    extraParams.touchPoints.forEach(function (touchPoint, index) {
                        featureArr.push({
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: [touchPoint.longitude, touchPoint.latitude]
                            },
                            properties: {
                                StructureId: touchPoint.id,
                                StructureName: touchPoint.webDestinationName,
                                BestMealplan: null,
                            }
                        });
                    });
                }

                if (typeSearch === "AVL") {
                    let coordinatesLines = [];
                    extraParams.touchPoints.forEach(function (touchPoint, index) {
                        coordinatesLines.push([touchPoint.longitude, touchPoint.latitude]);

                        featureArr.push({
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: [touchPoint.longitude, touchPoint.latitude]
                            },
                            properties: {
                                StructureId: touchPoint.id,
                                StructureName: touchPoint.webDestinationName,
                                ItineraryPointId: index + 1,
                                ItineraryId: 1,
                            }
                        });
                    });

                    featureArr.unshift({
                        type: "Feature",
                        properties: {
                            ItineraryId: 1,
                            ItineraryName: "Itinerario"
                        },
                        geometry: {
                            coordinates: coordinatesLines,
                            type: "LineString"
                        }
                    });
                }

                setMapArr({
                    type: "FeatureCollection",
                    features: featureArr
                });
            }
        }

        if (searchParameters && productType === configData.Settings.Products.Cruise.IdTipoProdotto) {
            normalizeSearchParam.destinationName = searchParameters.destinationDesc;

            if (!normalizeSearchParam.destinationName) {
                try {
                    let portName = searchParameters.portNames && searchParameters.portNames.length > 0 ? searchParameters.portNames[0] : '';
                    normalizeSearchParam.destinationName = portName;
                } catch (ex) { }
            }

            setMultiDestinationName(false);
            setExtraOptions({ ...extraOptions, multiDate: true });

            normalizeSearchParam.fromPeriod = searchParameters.dateFrom;
            normalizeSearchParam.toPeriod = searchParameters.dateTo;

        }

        setNormalizeSearchParam(normalizeSearchParam);

    }

    const updateFeatures = (features) => {
        setFeatureFiltered(features);
    }

    const clusterMarkerPopup = (childMarkers) => {
        var htmlContainer = "<div>";
        htmlContainer += '<div style="display:block; max-height: 400px; width: 250px; overflow-x: hidden;" />';
        childMarkers.forEach(function (m) {
            if (searchParameters && productType === configData.Settings.Products.Structure.IdTipoProdotto) {
                if (m.feature?.properties?.StructureName) {
                    htmlContainer += "<div><label>Name: " + m.feature.properties.StructureName + " </label></div>"
                }
                if (m.feature?.properties?.htmlDescriptionBox) {

                    htmlContainer += m.feature.properties.htmlDescriptionBox;
                }
            }
            htmlContainer += "<hr/>"
        });
        htmlContainer += "</div>";
        htmlContainer += "</div>";
        return htmlContainer;
    }

    const markerPopup = (feature) => {
        var htmlContainer = '<div style="display:block; max-height: 400px; width: 250px; overflow-x: hidden;" />';
        if (searchParameters && productType === configData.Settings.Products.Structure.IdTipoProdotto) {
            if (feature.properties?.StructureName) {
                htmlContainer += "<div><label>Name: " + feature.properties.StructureName + " </label></div>"
            }
            if (feature.properties?.ItineraryId) {
                htmlContainer += "<div><label>Id: " + feature.properties.ItineraryId + " </label></div>"
            }

            if (feature.properties?.ItineraryName) {
                htmlContainer += "<div><label>Name: " + feature.properties.ItineraryName + " </label></div>"
            }

            if (feature.properties?.ItineraryPointId) {
                htmlContainer += "<div><label>Punto: " + feature.properties.ItineraryPointId + " </label></div>"
            }
        }

        htmlContainer += "</div>";
        return htmlContainer;
    }

    return (
        <>
            <div className="bg-white rounded shadow d-none d-sm-block">
                {isShowMapImage &&
                    <div className=" row mb-3 mx-1">
                        <div className="col-12 col-sm-12 p-1">
                            {mapArr && isShowMap ?
                                <>
                                    <OsmTravelPlaceMap
                                        data={mapArr}
                                        center={[45.6479389724, 8.7796935685]}
                                        zoom={13}
                                        scrollWheelZoom={true}
                                        showFilterArea={showFilterArea}
                                        sizeInMeters={sizeInMeters}
                                        showClusterPopup={true}
                                        updateFeatures={updateFeatures}
                                        clusterMarkerPopup={clusterMarkerPopup}
                                        markerPopup={markerPopup}
                                        geoJsonRef={geoJsonRef}
                                        map={map}
                                        setMap={setMap}
                                    >
                                    </OsmTravelPlaceMap>
                                    <div className="h6 mb-0 bg-gray-300" style={{ position: "absolute", bottom: "0", zIndex: "1000", width: "98%", textAlign: "center" }}>{typeSearch !== "SRC" ? extraParams.productName : searchParameters.destinationDesc}</div>
                                </>
                                :
                                <div className="text-center bg-gray-300">

                                    <div className="wrap-img-list d-flex align-items-center w-100 img rounded" style={{ backgroundImage: `url("${imageUrl}")` }}>

                                        <div className="d-table h-100 w-100">
                                            <div className="d-table-row text-center">
                                                <div className="d-table-cell align-bottom ">
                                                    <div className="h6 mb-0 bg-gray-300 rounded-top">
                                                        {!multiDestinationName && !(extraParams && extraParams.productName) && normalizeSearchParam.destinationName}
                                                        {
                                                            multiDestinationName && !(extraParams && extraParams.productName) && normalizeSearchParam.destinationName && normalizeSearchParam.destinationName.map((destName, index) => {
                                                                return <div key={index}>{destName}</div>;
                                                            })
                                                        }
                                                        {(extraParams && extraParams.productName) && <span dangerouslySetInnerHTML={{ __html: extraParams.productName }}></span>}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
                <div className="row mb-3 mx-1" data-bs-toggle="modal" data-bs-target="#EngineModifyActivty">
                    {isOnlyDestination &&
                        <>
                            <div className="col-12 col-sm-12 p-1">
                                {
                                    normalizeSearchParam.destinationName && normalizeSearchParam.destinationName.length > 0 &&
                                    <InputSearchDestinationOnlyRead
                                        defaultSelectDestination={{ Text: normalizeSearchParam.destinationName[0] }}
                                        onlyView={true}
                                        productType={productType}
                                        extraOptions={extraOptions}
                                        modeView="COMPACTVIEW"
                                    />
                                }
                            </div>
                            {normalizeSearchParam.destinationName && normalizeSearchParam.destinationName.length > 1 &&
                                <div className="col-12 col-sm-12 p-1">
                                    <InputSearchDestinationOnlyRead
                                        defaultSelectDestination={{ Text: normalizeSearchParam.destinationName[1] }}
                                        onlyView={true}
                                        productType={productType}
                                        extraOptions={(productType === configData.Settings.Products.CarRental.IdTipoProdotto) ? { label: 'SearchEngine:DeliveryPoint' } : ""}
                                        modeView="COMPACTVIEW"
                                    />
                                </div>
                            }
                        </>
                    }
                    {productType !== configData.Settings.Products.Tour.IdTipoProdotto &&
                        <div className="col-12 col-sm-12 p-1">
                            <InputCalendarReadOnly
                                dateFrom={normalizeSearchParam.fromPeriod}
                                dateTo={normalizeSearchParam.toPeriod}
                                timeFrom={normalizeSearchParam.timeFrom}
                                timeTo={normalizeSearchParam.timeTo}
                                hadDuration={false}
                                extraOptions={extraOptions}
                                modeView="COMPACTVIEW" />
                        </div>
                    }
                    {productType === configData.Settings.Products.Tour.IdTipoProdotto &&
                        <div className="col-12 col-sm-12 p-1">
                            <InputMonthCalendarReadOnly
                                selectMonths={normalizeSearchParam.selectMonths}
                                extraOptions={extraOptions}
                                modeView="COMPACTVIEW" />
                        </div>
                    }
                    {isShowViaggiatori &&
                        <div className="col-12 col-sm-12 p-1">
                            <InputPaxesCompositionReadOnly
                                searchParameters={searchParameters}
                                productType={productType}
                                modeView="COMPACTVIEW" />
                        </div>
                    }
                    <div className="col-12 col-sm-12 mb-1">
                        <button className="btn btn-sm tp-btn-search w-100 text-white">
                            <data m3lab="Button.Update">
                                {t("Button:Update")}
                            </data>
                        </button>
                    </div>
                </div>
            </div>

            <div className="modal tp-search-input fade" id="EngineModifyActivty" tabIndex="-1" aria-labelledby="EngineModifyActivty" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen-xxl-down">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <div className="h4 text-center w-100 mt-2">
                                {t('SearchEngine:UpdateSearch')}
                            </div>
                            <button type="button" className="btn-close mt-2 pr-4" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body bg-gray-300">

                            {
                                productType === configData.Settings.Products.Structure.IdTipoProdotto && <StructureSearchEngine
                                    searchParameters={searchParameters}
                                    typeSearch={typeSearch}
                                    extraParams={extraParams}
                                    resetValues={resetValues}
                                    modeView="MODAL" />
                            }

                            {
                                productType === configData.Settings.Products.Activity.IdTipoProdotto && <ActivitySearchEngine
                                    searchParameters={searchParameters}
                                    typeSearch={typeSearch}
                                    extraParams={extraParams}
                                    resetValues={resetValues}
                                    modeView="MODAL" />
                            }

                            {
                                productType === configData.Settings.Products.Flight.IdTipoProdotto && <FlightSearchEngine
                                    searchParameters={searchParameters}
                                    typeSearch={typeSearch}
                                    extraParams={extraParams}
                                    resetValues={resetValues}
                                    modeView="MODAL" />
                            }

                            {
                                productType === configData.Settings.Products.Train.IdTipoProdotto && <TrainSearchEngine
                                    searchParameters={searchParameters}
                                    typeSearch={typeSearch}
                                    extraParams={extraParams}
                                    resetValues={resetValues}
                                    modeView="MODAL" />
                            }
                            {
                                productType === configData.Settings.Products.CarRental.IdTipoProdotto && <CarSearchEngine
                                    searchParameters={searchParameters}
                                    typeSearch={typeSearch}
                                    extraParams={extraParams}
                                    resetValues={resetValues}
                                    modeView="MODAL" />
                            }
                            {
                                productType === configData.Settings.Products.Transfer.IdTipoProdotto && <TransferSearchEngine
                                    searchParameters={searchParameters}
                                    typeSearch={typeSearch}
                                    extraParams={extraParams}
                                    resetValues={resetValues}
                                    modeView="MODAL" />
                            }
                            {
                                productType === configData.Settings.Products.Tour.IdTipoProdotto && <TourSearchEngine
                                    searchParameters={searchParameters}
                                    typeSearch={typeSearch}
                                    extraParams={extraParams}
                                    resetValues={resetValues}
                                    modeView="MODAL" />
                            }
                            {
                                productType === configData.Settings.Products.Cruise.IdTipoProdotto && <CruiseSearchEngine
                                    searchParameters={searchParameters}
                                    typeSearch={typeSearch}
                                    extraParams={extraParams}
                                    resetValues={resetValues}
                                    modeView="MODAL" />
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}
