import React, { useEffect, useState } from 'react';

export const AddServiceNote = ({ serviceNote, noteTypeToAdd, propertyName, roomIndex, onBlurEvent }) => {

    const [serviceNoteObj, setServiceNoteObj] = useState({
        idsTipoNota: [],
        note: '',
        roomIndex: roomIndex
    })

    const [propertyNameParam, setProperyNameParam] = useState('serviceNote');

    useEffect(() => {
        if (serviceNote) {
            if (!serviceNote.idsTipoNota)
                serviceNote.idsTipoNota = []

            setServiceNoteObj(serviceNote);
        } else {
            let idsTipoNote = [];
            if (noteTypeToAdd)
                idsTipoNote = [noteTypeToAdd];

            setServiceNoteObj({
                idsTipoNota: idsTipoNote,
                note: '',
                roomIndex: roomIndex
            });
        }

        if (propertyName)
            setProperyNameParam(propertyName);
        else
            setProperyNameParam('serviceNote');

    }, [serviceNote])

    function onChangeCheckBox(idTipoNota) {

        let newIdsTipoNota = [...serviceNoteObj.idsTipoNota];
        if (newIdsTipoNota.some(x => x === idTipoNota)) {
            newIdsTipoNota = newIdsTipoNota.filter(x => x !== idTipoNota);
        } else {
            newIdsTipoNota.push(idTipoNota);
        }

        let updServiceNote = {
            ...serviceNoteObj,
            idsTipoNota: newIdsTipoNota
        };

        setServiceNoteObj(updServiceNote);

        if (roomIndex < 0)
            onBlurEvent(updServiceNote, propertyNameParam);
        else
            onBlurEvent(roomIndex, propertyNameParam, updServiceNote);
    }

    function onInternalBlurEvent(event) {
        let newValue = event.target.value;
        let updServiceNote = {
            ...serviceNoteObj,
            note: newValue
        };

        setServiceNoteObj(updServiceNote);
        if (roomIndex < 0)
            onBlurEvent(updServiceNote, propertyNameParam);
        else
            onBlurEvent(roomIndex, propertyNameParam, updServiceNote);
    }

    function renderPlaceholder() {
        if (noteTypeToAdd) {
            switch (noteTypeToAdd) {
                case 12:
                    return "Descrizione/Scheda prodotto";
            }
        }

        return "Note Servizio";
    }

    return (
        <div className="my-2">
            {
                !noteTypeToAdd && <div className="row py-2">
                    <div className="col-3">
                        <input id="cb_note_servizio_7"
                            type="checkbox"
                            checked={serviceNoteObj.idsTipoNota.some(x => x === 7)}
                            onChange={_ => onChangeCheckBox(7)} />
                        <label htmlFor="cb_note_servizio_7" className="active labelBooleanBp">
                            <span>
                                Note Voucher
                            </span>
                        </label>
                    </div>
                    <div className="col-3">
                        <input id="cb_note_servizio_11"
                            type="checkbox"
                            checked={serviceNoteObj.idsTipoNota.some(x => x === 11)}
                            onChange={_ => onChangeCheckBox(11)} />
                        <label htmlFor="cb_note_servizio_11" className="active labelBooleanBp">
                            <span>
                                Note Documento Viaggio
                            </span>
                        </label>
                    </div>
                    <div className="col-3">
                        <input id="cb_note_servizio_10"
                            type="checkbox"
                            checked={serviceNoteObj.idsTipoNota.some(x => x === 10)}
                            onChange={_ => onChangeCheckBox(10)} />
                        <label htmlFor="cb_note_servizio_10" className="active labelBooleanBp">
                            <span>
                                Note Conferma Viaggio
                            </span>
                        </label>
                    </div>
                    <div className="col-3">
                        <input id="cb_note_servizio_13"
                            type="checkbox"
                            checked={serviceNoteObj.idsTipoNota.some(x => x === 13)}
                            onChange={_ => onChangeCheckBox(13)} />
                        <label htmlFor="cb_note_servizio_13" className="active labelBooleanBp">
                            <span>
                                Note Preventivo
                            </span>
                        </label>
                    </div>
                </div>
            }

            <div className="row">
                <div className="col-12 form-floating ">
                    <textarea className="form-control"
                        placeholder={renderPlaceholder()}
                        value={serviceNoteObj.note}
                        onChange={(e) => { onInternalBlurEvent(e); }}
                        id="floatingTextarea2"
                        style={{ height: '100px' }} >
                    </textarea>
                    <label htmlFor="floatingTextarea2">{renderPlaceholder()}</label>
                </div>
            </div>
        </div>
    );
}
