import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';
import { handleError } from "../../../js/Utils";

export const StripeSuccess = () => {
    const cookies = new Cookies();

    const { t } = useTranslation();

    const [searchParams] = useSearchParams();
    let customerId = searchParams.get("cId");
    let transactionCode = searchParams.get("oId");
    let orderNumber = searchParams.get("oN");
    let sessionId = searchParams.get("session_id");

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(true);

    useEffect(() => {
        callStripeSuccess();
    }, []);

    function callStripeSuccess() {

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}paymentgateway/stripesuccess/${customerId}/${orderNumber}/${sessionId}/${transactionCode}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {

                let baseUrlConfirmCart = `${configData.Settings.Base_Url}cart-confirm/${orderNumber}`;
                let currentUserObj = JSON.parse(localStorage.getItem("CurrentUser"));
                if (currentUserObj.autoLoginToken)
                    baseUrlConfirmCart = `${configData.Settings.Base_Url}?ual=${currentUserObj.autoLoginToken}&ru=${encodeURIComponent(baseUrlConfirmCart)}`;


                window.location.href = baseUrlConfirmCart

                setIsLoading(false);
                setIsError(false);
            })
            .catch((err) => {
                setIsLoading(false);
                setIsError(true);
                console.log(err);
            });

    }

    return (
        <div className="row text-center">
            {
                isLoading && !isError && <Loading textMsg={t('Loading:PaymentSuccess')} />
            }
            {
                !isLoading && isError && <Error textMsg={t('Loading:PaymentError')} />
            }
            {
                !isLoading && !isError && <Loading textMsg={t('Loading:PaymentSuccess')} />
            }
        </div>
    );
}
