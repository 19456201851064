import { useEffect, useRef } from 'react';

export function useInterval(callback, fnCondition, delay) {
    const savedCallback = useRef();

    // remember last callback
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // set interval
    useEffect(() => {
        let id = -1;
        const tick = async () => {
            const response = await savedCallback.current();
            if (fnCondition(response)) {
                id = setTimeout(tick, delay);
            } else {
                clearTimeout(id);
            }
        };

        tick();
        return () => id && clearTimeout(id);
    }, [delay]);
}