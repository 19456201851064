import React, { useState, useEffect } from 'react';
import configData from "../../../../appsettings.json";
import { useLocation } from "react-router-dom";
import { handleError } from "../../../../js/Utils";
import { useTranslation } from 'react-i18next'
import { Calendar } from 'primereact/calendar';
import { InputPaxesCompositionContainer } from '../../../Common/Paxes/InputPaxesCompositionContainer';
import Cookies from 'universal-cookie';

export const QuotationForm = ({ item }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [titlePage, setTitlePage] = useState(null);
    const [isSend, setIsSend] = useState(false);
    const [date, setDate] = useState(null);
    const [selectComposition, setSelectComposition] = useState(null);
    const [borderDate, setBorderDate] = useState(null);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [message, setMessage] = useState(null);

    const [showPaxes, setShowPaxes] = useState(false);

    const [inputData, setInputData] = useState(
        {
            title: '',
            name: '',
            surname: '',
            email: '',
            phone: '',
            msg: '',
            date: '',
            adults: '',
            children: '',
            cultureCode: cultureName
        }
    );



    useEffect(() => {
        if (location) {
            let tmp = location.pathname.slice(location.pathname.lastIndexOf("/"), location.pathname.length);
            setTitlePage(tmp.slice(1));
        }
    }, [location])

    const [validateInputData, setValidateInputData] = useState(
        {
            name: false,
            isTouchedName: false,

            surname: false,
            isTouchedSurname: false,

            email: false,
            isTouchedEmail: false,

            phone: false,
            isTouchedPhone: false,

            isValidForm: false
        }
    );

    async function sendEmail() {
        setIsLoading(true);

        let resInputData = {};

        resInputData["nameRefPax"] = inputData["name"];
        resInputData["surnameRefPax"] = inputData["surname"];
        resInputData["emailRefPax"] = inputData["email"];
        resInputData["description"] = inputData["msg"];
        resInputData["fromLocation"] = titlePage;
        resInputData["title"] = titlePage;
        resInputData["adults"] = selectComposition[0].adults;
        resInputData["children"] = selectComposition[0].children;
        resInputData["startDate"] = date;
        resInputData["phone"] = inputData["phone"];
        resInputData["citizenshipRefPax"] = cultureName;

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(resInputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'quotationTO/sendQuotationForm', requestOption);
        const response = await fetchedRes.json();

        if (response.success && response.send) {
            setMessage(t("Login:YourRequestSendeWithSuccess"));
            setIsLoading(false);
        }
        else if (response.success && response.exist) {
            setIsError(true);
            setIsLoading(false);
            setErrorMsg(t("Login:MailAlreadyExists"));
        }
        else {
            setIsError(true);
            setIsLoading(false);
            setErrorMsg(t("Login:RegistrationErrorMessage"));
        }
    }

    function onOpenPanelPaxes() {
        setShowPaxes(true);
    }

    function onClickSelectPaxes(selectComposition) {
        setSelectComposition(selectComposition);
    }

    function onChange(event, propertyName) {
        let updateInputData = { ...inputData };

        let newValue = event.target.value;

        updateInputData[propertyName] = newValue;

        let isValidForm = false;
        let prevValidateInputData = { ...validateInputData };
        delete prevValidateInputData.isValidForm;
        const isNotEmpty = (newValue !== '' && newValue !== undefined);

        switch (propertyName) {
            case 'name': {
                delete prevValidateInputData.name;
                delete prevValidateInputData.isTouchedName;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    name: isNotEmpty,
                    isTouchedName: true
                });
                break;
            }

            case 'surname': {
                delete prevValidateInputData.surname;
                delete prevValidateInputData.isTouchedSurname;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    surname: isNotEmpty,
                    isTouchedSurname: true
                });
                break;
            }

            case 'email': {
                delete prevValidateInputData.email;
                delete prevValidateInputData.isTouchedEmail;
                let isValidEmailFormat = isNotEmpty;
                if (isNotEmpty) {
                    const isValidMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(newValue);
                    isValidEmailFormat = isValidEmailFormat && isValidMail;
                }

                isValidForm = isValidEmailFormat && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    email: isValidEmailFormat,
                    isTouchedEmail: true
                });
                break;
            }

            case 'phone': {
                delete prevValidateInputData.phone;
                delete prevValidateInputData.isTouchedPhone;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    phone: isNotEmpty,
                    isTouchedPhone: true
                });
                break;
            }
        }

        setInputData(updateInputData);
    }

    function onSetDate(value) {
        if (value) {
            setBorderDate("");
        }
        else {
            setBorderDate("1px solid red");
        }

        setDate(value);
    }

    return (
        <>
            <div className="container bg-custom rounded p-5">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-4 text-white">
                        {t("Login:Name") + " *"}
                        <input type="text"
                            className={"form-control font-weight-light mb-2 w-100 border rounded " + (validateInputData.name ? "border-light" : "border-danger")}
                            id="nameContactForm"
                            aria-describedby="emailHelp"
                            value={inputData.name}
                            style={{ height: "54px" }}
                            onChange={e => onChange(e, 'name')}
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 text-white">
                        {t("Login:Surname") + " *"}
                        <input type="text"
                            className={"form-control font-weight-light mb-2 w-100 border rounded " + (validateInputData.surname ? "border-light" : "border-danger")}
                            id="nameContactForm"
                            aria-describedby="emailHelp"
                            value={inputData.surname}
                            style={{ height: "54px" }}
                            onChange={e => onChange(e, 'surname')}
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 text-white">
                        Mail *
                        <input type="email"
                            className={"form-control font-weight-light mb-2 w-100 border rounded " + (validateInputData.email ? "border-light" : "border-danger")}
                            id="emailContactForm"
                            aria-describedby="emailHelp"
                            value={inputData.email}
                            style={{ height: "54px" }}
                            onChange={e => onChange(e, 'email')}
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 text-white">
                        {t("Login:Phone") + " *"}
                        <input type="number"
                            className={"form-control font-weight-light mb-2 w-100 border rounded " + (validateInputData.phone ? "border-light" : "border-danger")}
                            aria-describedby="emailHelp"
                            value={inputData.phone}
                            style={{ height: "54px" }}
                            onChange={e => onChange(e, 'phone')}
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 col-md-12 col-lg-4 text-white calendarSendQuotaton">
                        {t("MyBook:Date") + " *"}
                        <br />
                        <Calendar value={date} inputStyle={{ borderRadius: "5px", height: "54px", border: borderDate, width: '100%' }} onChange={(e) => onSetDate(e.value)} dateFormat="dd/mm/yy" />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 text-white">
                        {t("MyBook:Passengers")}
                        <InputPaxesCompositionContainer
                            searchParameters={null}
                            productType={configData.Settings.Products.Activity.IdTipoProdotto}
                            callOnSelectComposition={onClickSelectPaxes}
                            onOpenPanelPaxes={onOpenPanelPaxes}
                            closeExternal={false}
                            modeView={"SEARCHENGINE"}
                            type="l"
                        />
                    </div>

                    <div className="col-12  p-3 text-white">
                        Note
                        <textarea
                            className={"form-control font-weight-light mb-2 w-100 h-200 border rounded "}
                            id="textAreaContactForm"
                            value={inputData.msg}
                            onChange={e => onChange(e, 'msg')}
                            rows="6"
                            style={{ height: "150px" }}
                        >
                        </textarea>
                    </div>
                    <div className="col-12 p-1 text-white text-center">
                        {message ?
                            <div>{message}</div>
                            :
                            <button className="btn btn-sm buttonCustom mt-3 btn btn-outline-light text-white" disabled={!validateInputData.isValidForm} onClick={e => sendEmail()}>
                                {!isLoading ? <>{t("General:SendOffer")}</>
                                    : <div className="spinner-border spinner-border-sm text-light" role="status" style={{ 'fontSize': '9px' }}>
                                        <span className="sr-only"></span>
                                    </div>
                                }
                            </button>
                        }
                        {errorMsg && <div>{errorMsg}</div>}
                    </div>
                </div>
            </div>
        </>
    );
}