import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Loading } from '../../Common/Loading';
import configData from "../../../appsettings.json";
import { useTranslation } from 'react-i18next';

export const TrainChangePaxModal = ({ passengers, pnr }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null)
    const [changePaxError, setChangePaxError] = useState(null)
    const [changePaxSuccess, setChangePaxSuccess] = useState(null)

    const [iframeUrl, setIframeUrl] = useState('');
    const [confirmPaxesModalLoading, setConfirmPaxesModalLoading] = useState(false);
    const [allowedToChange, setAllowedToChange] = useState([]);

    // Assuming `passengers` is the state containing the current list of travelers,
    // and each passenger has been edited in the form to include new names, surnames, genders, and birthdates

    async function handleEditPax(e) {
        e.preventDefault();
        setChangePaxError(false);
        setChangePaxSuccess(false);
        setConfirmPaxesModalLoading(true);

        // Collect new passenger details from the form and filter out unchanged passengers
        const updatedPassengers = passengers.map((pax, index) => {
            const newNameInput = e.target[`newFirstName-${index}`];
            const newSurnameInput = e.target[`newLastName-${index}`];
            const originalFirstName = pax.fullName.split(" ")[0];
            const originalLastName = pax.fullName.split(" ")[1];

            // Use existing values as defaults if the inputs are not found or the values are empty
            const newFirstName = newNameInput && newNameInput.value.trim() !== '' ? newNameInput.value : originalFirstName;
            const newLastName = newSurnameInput && newSurnameInput.value.trim() !== '' ? newSurnameInput.value : originalLastName;

            return {
                ...pax,
                newFirstName,
                newLastName,
                hasChanged: newFirstName !== originalFirstName || newLastName !== originalLastName // Flag to track changes
            };
        }).filter(pax => pax.hasChanged); // Only include passengers that have changed

        // Construct the request payload using filtered updated passenger details
        const changePaxesRequest = {
            PNR: pnr,
            NewPaxes: updatedPassengers.map(pax => ({
                IdPax: Number(pax.index),
                NewName: pax.newFirstName,
                NewSurname: pax.newLastName,
                NewGender: pax.infos[0].paramValue,
                NewBirthDate: pax.birthDate,
                NewPaxType: pax.paxType,
                IdPrenotazione: 0
            })),
            ProductType: 9,
        };

        // Proceed with the fetch request as previously outlined
        try {
            const response = await fetch(`${configData.Settings.CommonApi_BaseUrl}Train/ChangePaxes`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(changePaxesRequest)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            if (result.success) {
                setChangePaxError(false);
                setChangePaxSuccess(true);
                console.log("ChangePaxes operation was successful", result);
                // Further success handling here
            } else {
                setChangePaxError(true);
                setChangePaxSuccess(false);
                // Error handling here
            }

            setConfirmPaxesModalLoading(false);
        } catch (error) {
            setChangePaxError(true);
            setChangePaxSuccess(false);

            setConfirmPaxesModalLoading(false);
            console.error('Failed to change passenger details:', error);
            // Error handling here
        }
    }



    async function getPostBookOperations() {
        setIsLoading(true);
        setErrorMsg(null)
        const requestBody = {
            PNR: pnr,
            OperationType: "ChangePaxes",
        };

        try {
            const response = await fetch(`${configData.Settings.CommonApi_BaseUrl}Train/GetPostBookOperations`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setIsLoading(false);

            const data = await response.json();
            if (!data.success) {
                return setErrorMsg(`${t('MyBook:operationPostError')}`);
            }
            setAllowedToChange(data.changePaxes.outbound.reduce((indexes, item) => {
                indexes.push(...item.travelerIndexes);
                return indexes;
            }, []));
        } catch (error) {
            console.error('Error:', error);
            // Handle errors, e.g., show error message
        }
    }

    useEffect(() => {
        const modal = document.getElementById('change_pax');
        const handleModalShown = () => getPostBookOperations();
        modal.addEventListener('shown.bs.modal', handleModalShown);

        return () => {
            modal.removeEventListener('shown.bs.modal', handleModalShown);
        };
    }, []);

    return (
        <div className="modal fade" id="change_pax" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{t('MyBook:modalTitleChangePax')}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {isLoading ? (
                            <Loading textMsg={t('MyBook:Loading')} />
                        ) : errorMsg ? (
                            <>
                                <span className="text-danger">{errorMsg}</span>
                            </>
                        ) : (
                            <form onSubmit={handleEditPax}>
                                {passengers && passengers?.map((pax, index) => (
                                    <div key={index} className="mb-3 d-flex align-items-center">
                                        <span className="h6">{t('MyBook:passengerLabel', { index: pax.index })}</span>
                                        {allowedToChange.includes(parseInt(pax.index)) ? (
                                            <div className="row g-2">
                                                <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        name={`newFirstName-${index}`}
                                                        defaultValue={pax.fullName.split(" ")[0]}
                                                        required
                                                        className="form-control"
                                                        placeholder={t('MyBook:firstNamePlaceholder')}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        name={`newLastName-${index}`}
                                                        defaultValue={pax.fullName.split(" ")[1]}
                                                        required
                                                        className="form-control"
                                                        placeholder={t('MyBook:surnamePlaceholder')}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <p className="mb-2 ms-2 text-danger">{t('MyBook:passengerChangeNotAllowed')}</p>
                                        )}
                                    </div>
                                ))}
                                <div className="d-flex align-items-end flex-column justify-content-end  mt-4">
                                    <div>
                                        {!changePaxError && changePaxSuccess && <p className="text-success">{t('MyBook:changeSuccessful')}</p>}
                                        {changePaxError && <p className="text-danger">{t('MyBook:changeUnsuccessful')}</p>}
                                    </div>
                                    {
                                        changePaxSuccess && <button className="btn bt-sm tp-btn-delete" onClick={_ => window.location.reload()}>
                                            {t('Button:Close')}
                                        </button>
                                    }
                                    {
                                        !changePaxSuccess && <button className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">
                                            {t('Button:Close')}
                                        </button>
                                    }
                                    {
                                        allowedToChange && !confirmPaxesModalLoading && !changePaxSuccess && <button type="submit" className="btn btn-primary">
                                            {t('Button:Confirm')}
                                        </button>
                                    }
                                    {
                                        confirmPaxesModalLoading && <><div class="spinner-border text-primary" role="status">
                                            <span class="sr-only"></span>
                                        </div></>
                                    }
                                    <div>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>

    );
};
