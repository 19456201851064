import React, { useState, useEffect } from 'react';
import configData from "../../appsettings.json";
import { handleError } from "../../js/Utils";

export const ClearCache = () => {
    useEffect(() => {
        callGetMemoryCache();
    }, []);

    const [memoryCache, setMemoryCache] = useState([]);
    const [memoryCacheFiltered, setMemoryCacheFiltered] = useState([]);

    function callGetMemoryCache() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}constant/getmemorycache`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setMemoryCache(item);
                setMemoryCacheFiltered(item.items);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function removeCacheItem(myKey) {
        const requestOption = {
            method: 'DELETE',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}constant/removememorycache/${myKey}`, requestOption)
            .then((res) => handleError(res))
            .then(() => {
                callGetMemoryCache();
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function onFilterList(event) {
        let filterValue = event.target.value;
        if (!filterValue)
            setMemoryCacheFiltered(memoryCache.items);
        else {
            let memoryCacheFlt = memoryCache.items.filter(x => x.name.toLowerCase().includes(filterValue.toLowerCase()));
            setMemoryCacheFiltered(memoryCacheFlt);
        }

    }

    return (
        <div className="section section-lg bg-white" id="content">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h3>Memory cache Dim: {memoryCache.totalDimension} {memoryCache.totalDimensionSize}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <input type="text" placeholder="Filtra per nome" onChange={e => onFilterList(e)} />
                    </div>
                </div>
                <table className="table">
                    <tr className="mb-2">
                        <td style={{ width: '60%' }}>
                            <b>Name</b>
                        </td>
                        <td style={{ width: '20%' }}>
                            <b></b>
                        </td>
                    </tr>
                    {
                        memoryCacheFiltered && memoryCacheFiltered.length > 0 && memoryCacheFiltered.map((item, key) =>
                            <tr key={key}>
                                <td>
                                    {item.name}
                                </td>
                                <td>
                                    <button type="button"
                                        className="btn btn-light border border-dark"
                                        onClick={e => removeCacheItem(item.name)}>
                                        Rimuovi
                                    </button>
                                </td>
                            </tr>
                        )
                    }
                </table>
            </div>
        </div>
    );
};