import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { StructureDetailPrint } from '../product/StructureDetailPrint';
import { FlightDetailPrint } from '../product/FlightDetailPrint';
import { ActivityDetailPrint } from '../product/ActivityDetailPrint';
import { CarRentalDetailPrint } from '../product/CarRentalDetailPrint';
import { TransferDetailPrint } from '../product/TransferDetailPrint';
import { CruiseDetailPrint } from '../product/CruiseDetailPrint';
import { TrainDetail } from '../../Reservation/TrainDetail';
import { formatPrice, formatDateTime } from '../../../js/Utils.js';


export const PrintStepItemAlternatives = ({ alternativeArr, isPrintCliente, isTemplate = false, isConfirmPrint = true }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    return (
        <>
            {alternativeArr.length > 0 &&
                <div className="w-100 borderColor marginBottomPdf">
                    {alternativeArr.filter(x => x.productType === 'Flight').map((qInfo) =>
                        <>
                            <table className="marginTopPdfAlternatives w-100 mt-2">
                                <tr className="row rounded rounded-3 bg-gray-300 my-1 mx-0 p-1">
                                    <td className="w-80">
                                        <tr>
                                            <td className="w-100">
                                                <span className="h6 m-0">
                                                    {qInfo.name}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-80">
                                                <label>
                                                    {qInfo.flightDateInfo && qInfo.flightDateInfo.length > 0 && qInfo.flightDateInfo.map((info, key) =>
                                                        <>
                                                            {t(`Product:Flight:Item`)}&nbsp;
                                                            {key + 1}:&nbsp;
                                                            {formatDateTime(info.date, cultureName)} &nbsp;
                                                        </>
                                                    )}
                                                </label>
                                            </td>
                                            <td>
                                                {qInfo.cancelPolicy.isFree ?
                                                    <label className="text-success">{t(`CancelPolicy:FreeCancellation`)} {formatDateTime(qInfo.cancelPolicy.expirationDate, { twoDigits: true })}</label>
                                                    :
                                                    <label className="penalty">{t(`CancelPolicy:PenaltyCancellation`)}</label>
                                                }
                                            </td>
                                        </tr>
                                    </td>
                                    <td className="w-20 text-right">
                                        <div className="btn btn-sm tp-btn-outline-search bg-gray-100" style={{ pointerEvents: "none" }}>
                                            {t(`Template:Option`)}
                                            <div>{(Math.sign(parseInt(formatPrice(qInfo.diffPriceAmount, qInfo.diffPriceCurrency, cultureName))) === 1 ? "+" : "") + formatPrice(qInfo.diffPriceAmount, qInfo.diffPriceCurrency, cultureName)} </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </>
                    )}
                    {alternativeArr.filter(x => x.productType !== 'Flight').map((qInfo) =>
                        <>
                            <table className="marginTopPdfAlternatives w-100 mt-2">
                                <tr className="row rounded rounded-3 bg-gray-300 my-1 mx-0 p-1">
                                    <td className="w-80">
                                        <tr>
                                            <td className="w-80">
                                                <span className="h6 m-0">
                                                    {qInfo.name}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>
                                                    {qInfo.address}
                                                </label>
                                            </td>
                                            <td>
                                                {qInfo.cancelPolicy.isFree ?
                                                    <label className="text-success">{t(`CancelPolicy:FreeCancellation`)} {formatDateTime(qInfo.cancelPolicy.expirationDate, { twoDigits: true })}</label>
                                                    :
                                                    <label className="penalty">{t(`CancelPolicy:PenaltyCancellation`)}</label>
                                                }
                                            </td>
                                        </tr>
                                    </td>
                                    <td className="w-20 text-right">
                                        <div className="btn btn-sm tp-btn-outline-search bg-gray-100" style={{ pointerEvents: "none" }}>
                                            {t(`Template:Option`)}
                                            <div>{(Math.sign(parseInt(formatPrice(qInfo.diffPriceAmount, qInfo.diffPriceCurrency, cultureName))) === 1 ? "+" : "") + formatPrice(qInfo.diffPriceAmount, qInfo.diffPriceCurrency, cultureName)} </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </>
                    )}
                </div>
        }
        </>
    );
}