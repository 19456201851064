import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import configData from "../../appsettings.json";
import { Loading } from '../Common/Loading';
import { useTranslation } from 'react-i18next';
import { Error } from '../Common/Error';
import { CartScadenziario } from '../Cart/CartScadenziario';
import { QuotationHeader } from './components/QuotationHeader';
import { QuotationTableMargin } from './components/QuotationTableMargin';
import { QuotationRecapItemADV } from './components/QuotationRecapItemADV';
import { QuotationSteps } from './components/QuotationSteps';
import { QuotationBaseButton } from './components/QuotationBaseButton';
import { handleError, getCurrentUserFromJwtToken, getWebsiteId } from "../../js/Utils";

export const Quotation = () => {
    const { t } = useTranslation();
    let { quotationId } = useParams();
    let { authcode } = useParams();

    let params = new URLSearchParams(window.location.search);
    let opSend = params.get('opSend');

    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const pdfFileName = "Preventivo" + quotationId;

    const [userIdWorkNow, SetUserIdWorkNow] = useState(null);
    const [actualUserInfo, setActualUserInfo] = useState({});
    const [customerInfoQuotation, setCustomerInfoQuotation] = useState({});
    const [quotation, setQuotation] = useState(null);
    const [quotationHeader, setQuotationHeader] = useState(null);
    const [quotationPaxes, setQuotationPaxes] = useState(null);
    const [quotationTableMarginItems, setQuotationTableMarginItems] = useState([]);
    const [quotationRecapItems, setQuotationRecapItems] = useState([]);
    const [quotationItemStep, setQuotationItemStep] = useState([]);
    const [quotationItemStepPrint, setQuotationItemStepPrint] = useState([]);
    const [quotationMode, setQuotationMode] = useState([]);
    const [quotationAllowedOperation, setQuotationAllowedOperation] = useState(null);

    const [minProductDate, setMinProductDate] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadAfterPage, setIsLoadAfterPage] = useState(false);
    const [isError, setIsError] = useState(false);
    const [sendMailMsg, setSendMailMsg] = useState("");

    const [showHistory] = useState({ isDeleted: false, isReplaced: false });
    const [scadenziario, setScadenziario] = useState(null);
    const [dynamicNotes, setDynamicNotes] = useState([]);

    const [quotationSettings] = useState({
        baseUrl: configData.Settings.Base_Url,
        minPercTotaleMargineLordo: configData.Settings.QuotationTO.MinPercTotaleMargineLordo,
        maxPercTotaleMargineLordo: configData.Settings.QuotationTO.MaxPercTotaleMargineLordo,
        groupedAddictionalProductADV: configData.Settings.QuotationTO.GroupedAddictionalProductADV,
        excludeRicavoTMFromMargineSede: configData.Settings.QuotationTO.ExcludeRicavoTMFromMargineSede,
        calcoloTMType: configData.Settings.QuotationTO.CalculateMarginTM,
        isDeleted: false,
        isReplaced: false,
        isAdmin: false
    });

    const [tipoProdotto, setTipoProdotto] = useState([]);
    const [websiteId, setWebsiteId] = useState();

    let oldArrTipoProdotto = [...tipoProdotto];

    useEffect(() => {
        if (!isLoading && quotation && opSend) {
            let btnSendMail = document.getElementById('openSendMailQuotationAuto');
            if (btnSendMail)
                btnSendMail.click();
        }
            
    }, [isLoading])

    useEffect(() => {
        callGetQuotationDetail();
        let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
        let websiteId = 0;

        if (jCurrentUser && jCurrentUser.token) {
            let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);
            websiteId = currentUser.websiteId;
        } else {
            websiteId = getWebsiteId();
        }

        setWebsiteId(websiteId);
    }, []);

    useEffect(() => {
        if (isLoadAfterPage) {
            callGetScadenziario();
            callGetCustomerInfoQuotation();
        }

    }, [isLoadAfterPage]);

    function getProductType(idTipoProdotto) {
        if (oldArrTipoProdotto.indexOf(idTipoProdotto) === -1) {
            oldArrTipoProdotto.push(Number(idTipoProdotto));
            setTipoProdotto(oldArrTipoProdotto);
        }
    }

    async function callGetDynamicNote(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'dynamicnote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();

        if (response.dynamicNotes != null && response.dynamicNotes.length > 0) {
            setDynamicNotes(arr => [...arr, response.dynamicNotes]);
        }
    }

    function openPopupLoadUpdate() {
        var myBtn = document.getElementById("openQuotationTO_LoadingAfterUpdate");
        myBtn.click();
    }

    function closePopupLoadUpdate() {
        setTimeout(() => {
            let myBtn = document.getElementById("closeQuotationTO_LoadingAfterUpdate");
            myBtn.click();
        }, 1000);
    }

    function callGetQuotationDetail() {
        setIsLoading(true);

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: -1,
                quotationSettings: quotationSettings,
                authcode: authcode
            })

        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationdetailadv`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotation(item);

                SetUserIdWorkNow(item.userIdWorkNow);

                setActualUserInfo({
                    username: item.userQuotation,
                    userId: item.userQuotationId,
                    agencyName: item.agencyName,
                    agencyId: item.agencyId
                });

                setQuotationHeader(
                    {
                        name: item.name,
                        ticketId: item.ticketId,
                        quotationId: item.quotationId,
                        agencyName: item.agencyName,
                        createDate: item.createDate,
                        tablePricing: item.tablePricing,
                        commentsTotal: item.chatTotal,
                        commentsToReadTotal: item.chatTotalToRead,
                        internalNotesTotal: item.totalInternalNotes,
                        status: item.status,
                        numeroOrdine: item.numeroOrdine,
                        isCommissionable: item.tablePricing.isCommissionable
                    }
                );

                setQuotationMode(item.quotationMode);
                setQuotationAllowedOperation(item.allowedOperations);

                setQuotationRecapItems(item.recapItems);
                setQuotationTableMarginItems({
                    hasIVACee: item.tablePricing.hasIVACee,
                    tableMargin: item.tablePricing.tableMargin,
                    totalCommissionAmount: item.tablePricing.totalCommission,
                    totalCommissionable: item.tablePricing.totalCommissionable,
                    totalCommissionCurrency: item.tablePricing.currency,
                    totalNotCommissionableAmount: item.tablePricing.totalNotCommissionable,
                    totalNotCommissionableCurrency: item.tablePricing.currency
                });

                setQuotationItemStep(item.quotationItemStep);

                if (item.recapItems && item.recapItems.length > 0) {
                    var myDates = item.recapItems.filter(r => r.productType !== 'Generic').map(function (myRecapItem) { return new Date(myRecapItem.dateFrom) });
                    var minDate = new Date(Math.min.apply(null, myDates))
                    setMinProductDate(minDate);
                }

                setIsError(false);
                setIsLoadAfterPage(true);

                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setQuotation(null);
                setIsError(true);
                setIsLoading(false);
            });

    }

    function callOnCallSaveProductInfo(response, type, id) {
        let isFound = false;

        let quotationItemStepToPrint = [...quotationItemStepPrint];
        if (quotationItemStepPrint && quotationItemStepPrint.length === 0)
            quotationItemStepToPrint = [...quotationItemStep];

        for (let i = 0; i < quotationItemStepToPrint.length; i++) {
            let itemStep = quotationItemStepToPrint[i];
            if (itemStep) {
                for (let j = 0; j < itemStep.quotationItemInfo.length; j++) {
                    let qInfo = itemStep.quotationItemInfo[j];

                    if (qInfo.wsBookingId && type === 'QuotationTO_MyBook') {
                        if (qInfo.wsBookingId === id) {
                            qInfo.productInfo = response;
                            isFound = true;
                            callDynamicNotes(qInfo);
                            break;
                        }
                    }

                    if (qInfo.id && type === 'QuotationTO') {
                        if (qInfo.id === id) {
                            qInfo.productInfo = response;
                            isFound = true;
                            callDynamicNotes(qInfo);
                            break;
                        }
                    }
                }
            }

            if (isFound)
                break;
        }

        setQuotationItemStepPrint(quotationItemStepToPrint);
    }

    function callDynamicNotes(qInfo) {
        let idTipoProdotto = 99;
        if (qInfo.productType === "Activity") {
            //getProductType(configData.Settings.Products.Activity.IdTipoProdotto);
            idTipoProdotto = configData.Settings.Products.Activity.IdTipoProdotto;
        }
        if (qInfo.productType === "Flight") {
            //getProductType(configData.Settings.Products.Flight.IdTipoProdotto);
            idTipoProdotto = configData.Settings.Products.Flight.IdTipoProdotto;
        }
        if (qInfo.productType === "Structure") {
            //getProductType(configData.Settings.Products.Structure.IdTipoProdotto);
            idTipoProdotto = configData.Settings.Products.Structure.IdTipoProdotto;
        }
        if (qInfo.productType === "Tour") {
            //getProductType(configData.Settings.Products.Tour.IdTipoProdotto);
            idTipoProdotto = configData.Settings.Products.Tour.IdTipoProdotto;
        }
        if (qInfo.productType === "Transfer") {
            //getProductType(configData.Settings.Products.Transfer.IdTipoProdotto);
            idTipoProdotto = configData.Settings.Products.Transfer.IdTipoProdotto;
        }
        if (qInfo.productType === "CarRental") {
            //getProductType(configData.Settings.Products.CarRental.IdTipoProdotto);
            idTipoProdotto = configData.Settings.Products.CarRental.IdTipoProdotto;
        }

        const getDynamicNote = async (inputData) => {
            const response = callGetDynamicNote(inputData);
        }

        getDynamicNote({
            cultureCode: cultureName,
            websiteId: websiteId,
            pageId: 14,
            productTypes: [idTipoProdotto],
            productCode: qInfo.productInfo ? qInfo.productInfo.productId : null,
            checkInFrom: qInfo.productInfo.priceBar ? qInfo.productInfo.priceBar.startDate : null,
            checkInTo: qInfo.productInfo.priceBar ? qInfo.productInfo.priceBar.endDate : null,
            webDestinationIds: qInfo.productInfo.webDestinationIds ? qInfo.productInfo.webDestinationIds : null,
            supplierId: qInfo.productInfo.supplierId ? qInfo.productInfo.supplierId : null,
        });
    }

    function callGetScadenziario() {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationscadenziario`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setScadenziario(item);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function updateQuotationAfterAPI(item) {
        setQuotationHeader({
            ...quotationHeader,
            status: item.status,
            tablePricing: item.tablePricing
        });
        setQuotationRecapItems(item.recapItems);
        setQuotationTableMarginItems({
            hasIVACee: item.tablePricing.hasIVACee,
            tableMargin: item.tablePricing.tableMargin,
            totalCommissionAmount: item.tablePricing.totalCommission,
            totalCommissionable: item.tablePricing.totalCommissionable,
            totalCommissionCurrency: item.tablePricing.currency,
            totalNotCommissionableAmount: item.tablePricing.totalNotCommissionable,
            totalNotCommissionableCurrency: item.tablePricing.currency
        });

        setQuotationItemStep(item.quotationItemStep);

        if (item.scadenziario)
            setScadenziario(item.scadenziario);

        closePopupLoadUpdate();
    }

    function callGetCustomerInfoQuotation() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}customer/getcustomerinfo/${actualUserInfo.agencyId}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setCustomerInfoQuotation(item);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function callOnSendMailAgency(item) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                ticketId: quotationHeader.ticketId,
                fromMail: item.fromMail,
                toMail: item.toMail,
                body: item.body,
                defaultFromMail: item.defaultFromMail,
                canAutoLogin: item.canAutoLogin,
                endUserId: item.endUserId
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/sendmailagency`, requestOption)
            .then((res) => handleError(res))
            .then(() => {
                setQuotationHeader({ ...quotationHeader, status: 1 });
                setSendMailMsg("Email inviata con successo");
            })
            .catch((err) => {
                console.error(err);
                setSendMailMsg("Non è stato possibile inviare la mail");
            });
    }

    function onCallOnBuyAndConfirm() {
        onCallDirectAddToCart(2);
    }

    function onCallOnBuyQuotation() {
        onCallDirectAddToCart(1);
    }

    function onCallDirectAddToCart(mode) {
        let myBtn = document.getElementById("openQuotationTO_SelectItemAddToCart");
        myBtn.click();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/directlyaddtocart/${quotationId}/${mode}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {

                if (!item.stkn) {
                    window.location.href = `${configData.Settings.Base_Url}cart?qId=${quotationId}`;
                } else {
                    fetch(configData.Settings.CommonApi_BaseUrl + 'auth/logout', { credentials: 'include' })
                        .then(data => {
                            window.location.href = `${configData.Settings.Base_Url}?sTkn=${item.stkn}&ru=${encodeURIComponent(configData.Settings.Base_Url)}cart?qId=${quotationId}`;
                        });
                }

            })
            .catch((err) => {
                console.error(err);
            });
    }

    function onSaveProductData(inputData) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,

                quotationItemId: inputData.quotationItemId,

                activityData: inputData.activityData,
                carData: inputData.carData,
                cruiseData: inputData.cruiseData,
                flightData: inputData.flightData,
                structureData: inputData.structureData,
                tourData: inputData.tourData,
                transferData: inputData.transferData,
                trainData: inputData.trainData
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/UpdateQuotationItemStaticData`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }
    return (
        <>
            <div className="section section-lg bg-white" id="content">
                <div className="container ">
                    {
                        isLoading
                            ? (<Loading textMsg={t("MyBook:Loading")} />)
                            : !isError && quotation !== null ?
                                <>
                                    <QuotationBaseButton
                                        quotationId={quotationId}
                                        authCode={authcode}
                                        pdfFileName={pdfFileName}
                                        quotationMode={quotationMode}
                                        quotationHeader={quotationHeader}
                                        quotationPaxes={quotationPaxes}
                                        quotationTableMarginItems={quotationTableMarginItems}
                                        quotationRecapItems={quotationRecapItems}
                                        quotationItemStepPrint={quotationItemStepPrint}
                                        dynamicNotes={dynamicNotes}
                                        customerInfoQuotation={customerInfoQuotation}
                                        scadenziario={scadenziario}
                                        cultureName={cultureName}
                                        callOnSendMailAgency={callOnSendMailAgency}
                                        sendMailMsg={sendMailMsg}
                                        quotationAllowedOperation={quotationAllowedOperation}
                                    />
                                    <div className="mybook-order mybook-order-main p-0">
                                        <div className="card border-light p-0 p-md-4">
                                            <QuotationHeader
                                                quotationHeader={quotationHeader}
                                                cultureName={cultureName}
                                                callConfirmAll={undefined}
                                                quotationMode={quotationMode}
                                                quotationAllowedOperation={quotationAllowedOperation}
                                                callOnBuyAndConfirm={onCallOnBuyAndConfirm}
                                                callOnBuyQuotation={onCallOnBuyQuotation}
                                            />
                                        </div>
                                        {
                                            quotationMode !== 'QuotationEndUser' && <QuotationTableMargin
                                                marginItems={quotationTableMarginItems}
                                                cultureName={cultureName}
                                                isCommissionable={quotationHeader.isCommissionable}
                                                quotationMode={quotationMode}
                                            />
                                        }
                                    </div>
                                    {
                                        quotationMode !== 'QuotationEndUser' && quotationRecapItems && quotationRecapItems.length > 0
                                            ? <QuotationRecapItemADV
                                                recapItems={quotationRecapItems}
                                                cultureName={cultureName}
                                            />
                                            : <></>
                                    }
                                    {
                                        scadenziario && <div className="p-4"><CartScadenziario
                                            selectedAddProdIds={[]}
                                            orderNumber={-1}
                                            mode={"QuotationTO"}
                                            extScadenziario={scadenziario} /></div>
                                    }
                                    {
                                        quotationItemStep && quotationItemStep.length > 0
                                            ? <QuotationSteps items={quotationItemStep}
                                                cultureName={cultureName}
                                                callOnChangeStatusQuotationItem={undefined}
                                                onSaveSelectAlternative={undefined}
                                                onCallSaveProductInfo={callOnCallSaveProductInfo}
                                                showHistory={showHistory}
                                                quotationMode={quotationMode}
                                                saveProductData={onSaveProductData}
                                            />
                                            : <></>
                                    }

                                </>
                                : <Error textMsg={t("MyBook:QuotationNotAvailable")} />
                    }
                </div >
            </div >

            <a id="openQuotationTO_LoadingAfterUpdate" data-bs-toggle="modal" data-bs-target="#quotationTO_LoadingPage"></a>
            <div className="modal fade" id="quotationTO_LoadingPage" tabIndex="-1" aria-labelledby="quotationTO_LoadingPageLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <a id="closeQuotationTO_LoadingAfterUpdate" data-bs-dismiss="modal" />
                            <Loading textMsg={t("MyBook:ReloadingQuotation")} />
                        </div>
                    </div>
                </div>
            </div>


            <a id="openQuotationTO_SelectItemAddToCart" data-bs-toggle="modal" data-bs-target="#quotationTO_SelectItemAddToCart"></a>
            <div className="modal fade" id="quotationTO_SelectItemAddToCart" tabIndex="-1" aria-labelledby="quotationTO_SelectItemAddToCartLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <a id="closeQuotationTO_LoadingAfterUpdate" data-bs-dismiss="modal" />
                            <Loading textMsg={t("MyBook:RedirectedInOtherPage")} />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}