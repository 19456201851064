import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollTo } from '../../../../js/Utils.js';

export const ActivityDetailDescriptions = ({ activityDetail, showOnlyGeneral }) => {
    const { t } = useTranslation();
    const [descriptions, setDescriptions] = useState([]);

    useEffect(() => {
        if (activityDetail.descriptions && activityDetail.descriptions.length > 0) {
            if (!showOnlyGeneral) {
                setDescriptions(activityDetail.descriptions);
            }
            else {
                // Nota sintetica
                let generalDescription = activityDetail.descriptions.filter(d => d.renderType.toUpperCase() === 'INTRO');

                if (generalDescription.length === 0) {

                    generalDescription = activityDetail.descriptions.filter(d => d.renderType.toUpperCase() === 'INEVIDENZA');

                    // Prendo la general
                    if (generalDescription.length === 0) {
                        try {
                            // escludo il program che è sempre in prima posizione
                            generalDescription = activityDetail.descriptions.filter(d => d.renderType.toUpperCase() === 'GENERAL');
                        } catch (ex) { }


                    }
                }

                if (generalDescription)
                    setDescriptions(generalDescription);
            }
        }

    }, [activityDetail])



    return (
        <>
            {
                descriptions && descriptions.map((desc, key) => {
                    return <React.Fragment key={key}>
                        <p className="h6"><var>{desc.type}</var></p>
                        {
                            showOnlyGeneral && <>
                                {
                                    <div id="description" className="text-justify mb-2" dangerouslySetInnerHTML={{ __html: desc.value }} style={{ maxHeight: '75px', overflow: 'hidden' }}></div>
                                }
                                {
                                    desc.value.length > 189 && <a href={void (0)} className="cursor-pointer text-decoration-none" onClick={event => scrollTo(event, "activityDetailDescriptionsAccordion")}>
                                        <data m3Lab="General.Continue">
                                            {t('General:Continue')}
                                        </data>
                                    </a>
                                }
                            </>
                        }
                        {
                            !showOnlyGeneral && <p className="small text-justify">
                                <var dangerouslySetInnerHTML={{ __html: desc.value }}></var>
                            </p>
                        }
                    </React.Fragment>;
                })
            }
        </>
    );
}