import React, { useEffect, useState } from 'react';
import configData from "../../../../appsettings.json";
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../../js/Utils.js';


export const FlightLegsListBlock = ({ item, index, fares }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const [cultureName] = useState(cookies.get("CultureName"));

    useEffect(() => {
    }, [item]);

    function getSegmentsTooltip(stopsDurations) {
        let res = "";
        
        stopsDurations.forEach(function (stop) {
            res += t(`Product:Flight:Stopover`) +" "+ stop.duration +" h - ("+ stop.arrivalAirportIATA +") "+ stop.arrivalAirportName +"\n" 
        });

        return res;
    }

    function getFareBaggages() {
        let res = "";
        let isFound = false;
        fares.forEach(function (fare) {
            fare.renderFaresDetails.forEach(function (renderFare) {
                renderFare.renderFareDetails.forEach(function (fareDetail) {
                    if (fareDetail.legIndex === index && !isFound) {
                        isFound = true;
                        res = fareDetail.baggage;

                    }
                });
            });
        });

        return res;
    }


    return (
        <>
            {item && <>
                <div className="row h6 mb-0">
                    <div className="col-3">
                        <div className="d-inline">
                            <div className="d-table h-100">
                                <div className="d-table-row h-100">
                                    <div className="d-table-cell align-middle">{formatDateTime(item.departureDate, cultureName, { noYear: true } ) }</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="row">
                            <div className="col-12 p-0 m-0 d-block">
                                <div className="row">
                                    <div className="col-12">
                                        <img src={item.mktAirlineLogo} className="w-100 p-2 w-10" alt=""/>
                                        <label className="pl2">{item.mktAirlineName}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-inline">
                                    <label className="mr-1">({item.departureAirportIATA})</label>{item.departureAirportName}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="d-inline"><label className="mr-1">({item.arrivalAirportIATA})</label>{item.arrivalAirportName}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-4 pl-2 px-1">
                        <label>
                            <data m3lab="Product.Flight.Takeoff">
                                {t(`Product:Flight:Takeoff`)}
                            </data> 
                        </label>
                        <label className="ml-1">({item.departureAirportIATA})</label>
                        <div>
                            <label>
                                {item.departureTime}
                            </label>
                        </div>
                        <div>
                            <label>{item.mktAirlineIATA + item.flightNumber }</label>
                        </div>
                    </div>
                    <div className="col-4 text-center">
                        <label>
                            <data m3lab="Product.Flight.Duration">
                                {t(`Product:Flight:Duration`)}
                            </data> 
                        </label>
                        <label className="ml-1">
                            {item.duration} h
                        </label>
                        <div className="w-100"></div>
                        {item.stops && item.stops.numStops > 0 ?
                            <>
                                <div title={getSegmentsTooltip(item.stops.stopsDurations) }>
                                    <label>{item.stops.numStops}</label>
                                    <label className="ml-1">
                                        {item.stops.numStops > 1 ?
                                            <data m3lab="Product.Flight.Stopovers">
                                                {t(`Product:Flight:Stopovers`)}
                                            </data>
                                        :
                                            <data m3lab="Product.Flight.Stopover">
                                                {t(`Product:Flight:Stopover`)}
                                            </data>
                                        }
                                    </label>
                                    <label className="ml-1">
                                        ({item.stops && item.stops.totalDuration} h)
                                    </label>
                                    {
                                        item.stops.isConnectionLong && <>
                                            <br />
                                            <label class="bg-danger px-2 text-white h6">Connection long</label>
                                        </>
                                    }
                                </div>
                            </>
                            :
                            <label>
                                <data m3lab="Product.Flight.Direct">
                                    {t(`Product:Flight:Direct`)}
                                </data>
                            </label>
                        }
                        <div className="w-100 border-top"></div>
                        {fares && 
                            <label>{getFareBaggages()}</label>
                        }
                    </div>
                    <div className="col-4 pr-2 px-1 text-right">
                        <label>
                            <data m3lab="Product.Flight.Landing">
                                {t(`Product:Flight:Landing`)}
                            </data>
                        </label>
                        <label className="ml-1">({item.arrivalAirportIATA})</label>
                        <div>
                            <label>
                                {item.arrivalTime}
                            </label>
                        </div>
                        <div>
                            <label>{item.segments ? item.segments.at(-1).mktCarrier + item.segments.at(-1).flightNumber : item.mktAirlineIATA + item.flightNumber}</label>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
    );
}