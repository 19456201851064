import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { Loading } from '../Common/Loading';
import { BookParameterId, BookParameterMandatoryCssClass } from '../../js/Constant';
import configData from "../../appsettings.json";
import { M3Icon } from '../Common/M3Icon';

export const CartBillingData = ({ inputData, paxesBookParams, handleBillingData, isCommissionable }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [countries, setCountries] = useState([]);
    const [dialCodes, setDialCodes] = useState([]);
    const [paxStraniero, setPaxStraniero] = useState(false);
    const [isTypePrivato, setIsTypePrivato] = useState(true);

    useEffect(() => {

        if (!countries || countries.length === 0) {
            const getCountries = async () => { const response = callGetCountries(); }
            getCountries();
        }

        if (!dialCodes || dialCodes.length === 0) {
            const getDialCodes = async () => { const response = callGetDialCodes(); }
            getDialCodes();
        }

    }, [])

    useEffect(() => {
        setIsLoading(true);
        setDefaultValues(paxesBookParams);
        setIsLoading(false);
    }, [paxesBookParams]);


    async function callGetCountries() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Constant/GetCountries/' + cultureName, requestOption);
        const response = await fetchedRes.json();
        setCountries(response);
    }

    async function callGetDialCodes() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Constant/GetDialCodes', requestOption);
        const response = await fetchedRes.json();
        setDialCodes(response);
    }

    const setDefaultValues = (inputData) => {
        if (inputData === null || inputData === undefined || inputData.length === 0 ||
            inputData[0].BookParameters === null || inputData[0].BookParameters === undefined || inputData[0].BookParameters.length === 0)
            return;

        let paxData = inputData[0].BookParameters;

        for (let i = 0; i < paxData.length; i++) {
            let inputTarget = document.getElementById("BpBillingPax_" + paxData[i].Id);
            if (inputTarget !== undefined && inputTarget !== null) {
                inputTarget.value = paxData[i].Value;
                paramOnChange("PAX", inputTarget.id, inputTarget.value);
            }
        }

    }

    const getLabelSpanClass = (isMandatory) => {
        return isMandatory
            ? BookParameterMandatoryCssClass.Mandatory
            : BookParameterMandatoryCssClass.NotMandatory;
    }

    const getLocalizedName = (id) => {
        let bpDesc = t(`bookParameters:${id}`);
        return bpDesc;
    }

    // gestisce l'evento di onChange sul valore del parametro, effettuando anche una validazione
    const paramOnChange = (type, id, val) => {
        let inputTarget = document.getElementById(id);
        inputTarget.classList.remove("is-valid");
        inputTarget.classList.remove("is-invalid");

        let aSplit = id.split('_');
        let bpId = aSplit[1];

        handleBillingData({ Id: bpId, Value: val, Mandatory: inputTarget.required });
    }

    // gestisce l'evento di onChange sul valore del parametro BillingType (Privato o Azienda)
    const paramTypeOnChange = (val) => {
        let iRagioneSociale = document.getElementById("BpBillingAgency_RagioneSociale");
        let iPartitaIva = document.getElementById("BpBillingAgency_PartitaIva");

        if (val === "P") {
            iRagioneSociale.required = false;
            iRagioneSociale.classList.remove("is-invalid");
            iPartitaIva.required = false;
            iPartitaIva.classList.remove("is-invalid");
            setIsTypePrivato(true);
        } else {
            iRagioneSociale.required = true;
            iPartitaIva.required = true;
            setIsTypePrivato(false);
        }

        // force re-render
        handleBillingData({ Id: "RagioneSociale", Value: iRagioneSociale.value, Mandatory: iRagioneSociale.required });
        handleBillingData({ Id: "PartitaIva", Value: iPartitaIva.value, Mandatory: iPartitaIva.required });
    }


    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : <>
                    <div>
                        <h5>{t("MyBook:TitleCartBilling")}&nbsp;</h5>
                    </div>

                    {/*Type*/}
                    <p className="mt-4">
                        <M3Icon iconName="CartBillingData" externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />{t("MyBook:SelectTypology")}:
                    </p>
                    <div className="row mt-2">
                        <div className="col-md-2">
                            <input id="BpBillingPax_BillingType_P" type="radio" name="BpBillingPax_BillingType" value="P" onChange={e => paramTypeOnChange('P')} defaultChecked />
                            &nbsp;<label htmlFor="BpBillingPax_BillingType_P">{t("MyBook:Private")}&nbsp;</label>
                        </div>
                        <div className="col-md-2">
                            <input id="BpBillingPax_BillingType_A" type="radio" name="BpBillingPax_BillingType" value="A" onChange={e => paramTypeOnChange('A')} />
                            &nbsp;<label htmlFor="BpBillingPax_BillingType_A">{t("MyBook:Company")}&nbsp;</label>
                        </div>
                    </div>

                    {/*Agenzia*/}
                    <p className="mt-4" style={{ display: !isTypePrivato ? "" : "none" }}>
                        <M3Icon iconName="CartBillingData" externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />{t("MyBook:AgencyIntestatary")}:
                    </p>
                    <div className="row mt-2" style={{ display: !isTypePrivato ? "" : "none" }}>
                        <div className="form-floating col-md-3">
                            <input id="BpBillingAgency_RagioneSociale" type="text" className="form-control" placeholder="Ragione Sociale" onChange={e => paramOnChange("AGENCY", e.target.id, e.target.value)} />
                            <label htmlFor="BpBillingAgency_RagioneSociale" className="active">
                                <span className={getLabelSpanClass(!isTypePrivato)}>{t("MyBook:CompanyName")}&nbsp; {isTypePrivato ? "" : "(*)"}</span>
                            </label>
                        </div>
                        <div className="form-floating col-md-3">
                            <input id="BpBillingAgency_PartitaIva" type="text" className="form-control" placeholder="Partita IVA" onChange={e => paramOnChange("AGENCY", e.target.id, e.target.value)} />
                            <label htmlFor="BpBillingAgency_PartitaIva" className="active">
                                <span className={getLabelSpanClass(!isTypePrivato)}>{t("Login:Vat")}&nbsp; {isTypePrivato ? "" : "(*)"}</span>
                            </label>
                        </div>
                    </div>

                    {/*Passeggero*/}
                    <p className="mt-4">
                        <M3Icon iconName="PaxAdl" externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />{t("MyBook:PassengerCartBuilling")}
                    </p>

                    {/*Riga 1*/}
                    <div className="row mt-2">
                        <div className="form-floating col-md-3">
                            <input id={`BpBillingPax_${BookParameterId.Personal.FirstName}`} type="text" className="form-control" placeholder="Nome" required onChange={e => paramOnChange("PAX", e.target.id, e.target.value)} />
                            <label htmlFor={`BpBillingPax_${BookParameterId.Personal.FirstName}`} className="active">
                                <span className={getLabelSpanClass(true)}>{getLocalizedName(BookParameterId.Personal.FirstName)}&nbsp; (*)</span>
                            </label>
                        </div>
                        <div className="form-floating col-md-3">
                            <input id={`BpBillingPax_${BookParameterId.Personal.MiddleName}`} type="text" className="form-control" placeholder="Secondo nome" onChange={e => paramOnChange("PAX", e.target.id, e.target.value)} />
                            <label htmlFor={`BpBillingPax_${BookParameterId.Personal.MiddleName}`} className="active">
                                <span className={getLabelSpanClass(false)}>{getLocalizedName(BookParameterId.Personal.MiddleName)}&nbsp;</span>
                            </label>
                        </div>
                        <div className="form-floating col-md-3">
                            <input id={`BpBillingPax_${BookParameterId.Personal.LastName}`} type="text" className="form-control" placeholder="Cognome" required onChange={e => paramOnChange("PAX", e.target.id, e.target.value)} />
                            <label htmlFor={`BpBillingPax_${BookParameterId.Personal.LastName}`} className="active">
                                <span className={getLabelSpanClass(true)}>{getLocalizedName(BookParameterId.Personal.LastName)}&nbsp; (*)</span>
                            </label>
                        </div>
                        <div className="form-floating col-md-1">
                            <div className="mt-3">
                                <div>
                                    <input id={`BpBillingPax_${BookParameterId.Personal.Gender}_M`} type="radio" name={`BpBillingPax_${BookParameterId.Personal.Gender}`} value="M" onChange={e => paramOnChange("PAX", e.target.id, e.target.value)} defaultChecked />
                                    &nbsp;<label htmlFor={`BpBillingPax_${BookParameterId.Personal.Gender}_M`}>M</label>

                                    <input id={`BpBillingPax_${BookParameterId.Personal.Gender}_F`} type="radio" name={`BpBillingPax_${BookParameterId.Personal.Gender}`} value="F" onChange={e => paramOnChange("PAX", e.target.id, e.target.value)} />
                                    &nbsp;<label htmlFor={`BpBillingPax_${BookParameterId.Personal.Gender}_F`}>F</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Riga 2*/}
                    <div className="row">
                        <div className="form-floating col-md-3">
                            <input id={`BpBillingPax_${BookParameterId.Personal.FiscalCode}`} type="text" className="form-control" placeholder="Codice Fiscale" required={!paxStraniero && isCommissionable} disabled={paxStraniero} onChange={e => paramOnChange("PAX", e.target.id, e.target.value)} />
                            <label htmlFor={`BpBillingPax_${BookParameterId.Personal.FiscalCode}`} className="active">
                                <span className={getLabelSpanClass(!paxStraniero && isCommissionable)}>{getLocalizedName(BookParameterId.Personal.FiscalCode)} {!paxStraniero && isCommissionable ? " (*)" : ""}</span>
                            </label>
                        </div>
                        <div className="form-floating col-md-3">
                            <input id={`BpBillingPax_BirthCity`} type="text" className="form-control" placeholder="Città natale" disabled={paxStraniero} required={!paxStraniero && isCommissionable} onChange={e => paramOnChange("PAX", e.target.id, e.target.value)} />
                            <label htmlFor={`BpBillingPax_BirthCity`} className="active">
                                <span className={getLabelSpanClass(!paxStraniero && isCommissionable)}>{getLocalizedName("BirthCity")} {!paxStraniero && isCommissionable ? " (*)" : ""}</span>
                            </label>
                        </div>
                        <div className="col-md-3 pt-3">
                            <input id={`BpBillingPax_PaxEE`} className="form-check-input" type="checkbox" value="PaxEE" onChange={e => setPaxStraniero(e.target.checked)} />
                            <label htmlFor={`BpBillingPax_PaxEE`} className="active">&nbsp;&nbsp;{getLocalizedName("ForeignPassenger")}</label>
                        </div>
                    </div>

                    {/*Riga 3*/}
                    <div className="row">
                        <div className="form-floating col-md-3">
                            <input id={`BpBillingPax_${BookParameterId.Address.Address1}`} type="text" className="form-control" placeholder="Indirizzo" required={isCommissionable} onChange={e => paramOnChange("PAX", e.target.id, e.target.value)} />
                            <label htmlFor={`BpBillingPax_${BookParameterId.Address.Address1}`} className="active">
                                <span className={getLabelSpanClass(isCommissionable)}>{getLocalizedName(BookParameterId.Address.Address1)} {isCommissionable ? " (*)" : ""}</span>
                            </label>
                        </div>
                        <div className="form-floating col-md-3">
                            <input id={`BpBillingPax_${BookParameterId.Address.City}`} type="text" className="form-control" placeholder="Città" required={isCommissionable} onChange={e => paramOnChange("PAX", e.target.id, e.target.value)} />
                            <label htmlFor={`BpBillingPax_${BookParameterId.Address.City}`} className="active">
                                <span className={getLabelSpanClass(isCommissionable)}>{getLocalizedName(BookParameterId.Address.City)} {isCommissionable ? " (*)" : ""}</span>
                            </label>
                        </div>
                        <div className="form-floating col-md-2">
                            <input id={`BpBillingPax_${BookParameterId.Address.Zip}`} type="number" className="form-control" placeholder="CAP" required={isCommissionable} onChange={e => paramOnChange("PAX", e.target.id, e.target.value)} />
                            <label htmlFor={`BpBillingPax_${BookParameterId.Address.Zip}`} className="active">
                                <span className={getLabelSpanClass(isCommissionable)}>{getLocalizedName(BookParameterId.Address.Zip)} {isCommissionable ? " (*)" : ""}</span>
                            </label>
                        </div>
                    </div>

                    {/*Riga 4*/}
                    <div className="row">
                        <div className="form-floating col-md-3">
                            <input id={`BpBillingPax_${BookParameterId.Contact.Email}`} type="email" className="form-control" placeholder="Email" required onChange={e => paramOnChange("PAX", e.target.id, e.target.value)} />
                            <label htmlFor={`BpBillingPax_${BookParameterId.Contact.Email}`} className="active">
                                <span className={getLabelSpanClass(true)}>{getLocalizedName(BookParameterId.Contact.Email)} (*)</span>
                            </label>
                        </div>
                        <div className="form-floating col-md-3">
                            <div className="form-floating">
                                <select id={`BpBillingPax_${BookParameterId.Contact.HomePhone}-Prefix`} className="form-select border-0 border-bottom border-dark" aria-label="Fornitore" onChange={e => paramOnChange("PAX", e.target.id, e.target.value)}>
                                    <option value="-1">{t("Button:Select")}</option>
                                    {dialCodes.map((x, index) =>
                                        <option key={index} value={x.codeDial} selected={x.codeDial === inputData.defaultValues.dialCode}>{x.isoTwoLetter} - {x.codeDial}</option>
                                    )}
                                </select>
                                <label htmlFor={`BpBillingPax_${BookParameterId.Contact.HomePhone}-Prefix`}>
                                    <span className={getLabelSpanClass(true)}>{getLocalizedName(`${BookParameterId.Contact.HomePhone}_PREFIX`)}&nbsp; (*)</span>
                                </label>
                            </div>
                        </div>
                        <div className="form-floating col-md-3">
                            <input id={`BpBillingPax_${BookParameterId.Contact.HomePhone}`} type="number" className="form-control" placeholder="Telefono" required onChange={e => paramOnChange("PAX", e.target.id, e.target.value)} />
                            <label htmlFor={`BpBillingPax_${BookParameterId.Contact.HomePhone}`} className="active">
                                <span className={getLabelSpanClass(true)}>{getLocalizedName(BookParameterId.Contact.HomePhone)}&nbsp; (*)</span>
                            </label>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
