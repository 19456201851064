import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { M3IconProductType } from '../../Common/M3IconProductType';
import { Loading } from '../../Common/Loading';
import configData from "../../../appsettings.json";

export const FormChangeMarkupFee = ({ cartSummary, showCalculatorMarkup, showCalculatorFee, handleChangeMarkupFee }) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [isSavedOk, setIsSavedOk] = useState(false);
    const [isSavedError, setIsSavedError] = useState(false);
    const [mkpFeeSelection, setMkpFeeSelection] = useState([]);


    useEffect(() => {
        if (mkpFeeSelection.length === 0 && cartSummary && cartSummary.items && cartSummary.items.length > 0) {
            let newMarkupFeeSelection = [];
            for (let i = 0; i < cartSummary.items.length; i++) {
                let cartItem = cartSummary.items[i];
                if (cartItem.productSubType === 99) continue;

                let tmpObj = {
                    CartItemId: cartItem.cartItemId,
                    Markup: cartItem.markupPerc,
                    MarkupType: "P",
                    Fee: cartItem.fee
                }
                newMarkupFeeSelection.push(tmpObj);
            }
            setMkpFeeSelection(newMarkupFeeSelection);
        }
    }, []);

    const paramOnChange = (type, id, val) => {
        let cartItemId = Number(id.split("_")[1]);

        const updMkpFeeSelection = mkpFeeSelection.map((item, idx) => {
            if (item.CartItemId === cartItemId) {
                if (type === "MKP") item.Markup = Number(val);
                if (type === "MKPT") item.MarkupType = val;
                if (type === "FEE") item.Fee = Number(val);
            }
            return item;
        });

        setMkpFeeSelection(updMkpFeeSelection);
    }

    const saveChanges = (e) => {
        if (e) e.preventDefault();

        setIsSavedOk(false);
        setIsSavedError(false);
        setIsLoading(true);

        callSaveMarkupFee();
    }

    async function callSaveMarkupFee() {
        let inputData = { Items: mkpFeeSelection };
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/SaveMarkupFee', requestOption);

        let saveResult = false;

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response && response.success) {
                saveResult = true;
            }
        }

        setIsSavedOk(saveResult);
        setIsSavedError(!saveResult);
        setIsLoading(false);
        handleChangeMarkupFee();
    }

    const getDefaultItemMarkup = (item) => {
        if (item.markupPerc)
            return item.markupPerc;

        if (item.pricing)
            return item.pricing.agencyMarkupPerc;

        return "";
    }
    const getDefaultItemFee = (item) => {
        if (item.fee)
            return item.fee;

        if (item.pricing)
            return item.pricing.agencyFee;

        return "";
    }


    return (
        <>
            <a id="open_ChangeMarkupFeeCart" data-bs-toggle="modal" data-bs-target="#modal_ChangeMarkupFeeCart"></a>
            <div className="modal fade" id="modal_ChangeMarkupFeeCart" tabIndex="-1" aria-labelledby="modal_SaveCartAndPrint" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal_ChangeMarkupFeeCartLabel">
                                {!showCalculatorFee && showCalculatorMarkup && <> {t("Button:UpdateMarkup")}</>}
                                {showCalculatorFee && !showCalculatorMarkup && <> {t("Button:UpdateFee")}</>}
                                {showCalculatorFee && showCalculatorMarkup && <> {t("Button:UpdateMarkupAndFee")}</>}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row pb-2">
                                <div className="col-6"><strong>Prodotto</strong></div>
                                <div className="col-2"><strong>{t('Product:Structure:Filter:Price')}</strong></div>
                                {
                                    showCalculatorMarkup && <div className="col-1 p-0"><strong>Markup</strong></div>
                                }
                                <div className="col-1 p-0">&nbsp;</div>
                                {
                                    showCalculatorFee && <div className="col-1 p-0"><strong>Fee</strong></div>
                                }
                            </div>

                            {cartSummary && cartSummary.items && cartSummary.items.length > 0 &&
                                cartSummary.items.filter(x => x.productSubType !== 99).map((item, index) =>
                                    <React.Fragment key={index}>
                                        <div className="row">
                                            <div className="col-6">
                                                <M3IconProductType productType={item.productType} productSubtype={item.productSubType} externalClass="h100 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                                                {item.productName}
                                            </div>
                                            <div className="col-2">
                                                {
                                                    item.pricing ? <>{item.pricing.displayPrice} {item.pricing.displayPriceCurrency}</> : <></>
                                                }
                                            </div>
                                            {
                                                showCalculatorMarkup && <><div className="col-1 p-0">
                                                    <input id={`ChangeMkp_${item.cartItemId}`} type="number" className="form-control" placeholder="Markup" defaultValue={item.pricing ? item.pricing.agencyMarkupPerc : ""} onChange={e => paramOnChange("MKP", e.target.id, e.target.value)} />
                                                </div>
                                                    <div className="col-1 pl-0">
                                                        <select id={`ChangeMkpType_${item.cartItemId}`} className="form-control border-bottom border-dark" style={{ height: '2.5rem' }} onChange={e => paramOnChange("MKPT", e.target.id, e.target.value)}>
                                                            <option value="P">%</option>
                                                            <option value="V">EUR</option>
                                                        </select>
                                                    </div>
                                                </>
                                            }
                                            {
                                                showCalculatorFee && <div className="col-1 p-0">
                                                    <input id={`ChangeFee_${item.cartItemId}`} type="number" className="form-control" placeholder="Fee" defaultValue={getDefaultItemFee(item)} onChange={e => paramOnChange("FEE", e.target.id, e.target.value)} />
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        </div>
                        <div className='modal-footer'>
                            {isLoading && <Loading textMsg={t(`Cart:Calculator_MarkupFee_Saving`)} isSmall={true} />}
                            {!isLoading && isSavedOk && <h6>{t(`Cart:Calculator_MarkupFee_SaveOk`)}</h6>}
                            {!isLoading && isSavedError && <h6>{t(`Cart:Calculator_MarkupFee_SaveError`)}</h6>}

                            {!isLoading && <button type="button" className="btn bt-sm tp-btn-confirm" onClick={(e) => saveChanges(e)}>{t(`Button:Save`)}</button>}
                            <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t(`Button:Close`)}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
