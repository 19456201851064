import React from 'react';

export const QuotationMarkupRule = ({ markupRules, resetMarkupValue }) => {
    return (
        <>
            <div className="card border-gray-400 p-0 p-md-4 mt-4">
                <div className="row p-3">
                    {
                        markupRules && markupRules.rules && markupRules.rules.length > 0
                            ? <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                                <table className="p-0 h065 table-hover w-100">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nome Regola</th>
                                            <th scope="col">Prodotti</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            markupRules.rules.map((rule, index) => {
                                                return <tr key={index} className="p-0">
                                                    <td>{rule.ruleName ? rule.ruleName : <>Regola Id: {rule.ruleId}</>}</td>
                                                    <td>
                                                        {
                                                            rule.isDefaultAllProduct ? "Tutti" : rule.productTypes.map((pt, indexPt) => {
                                                                return <div key={indexPt}>
                                                                    {pt}{indexPt < rule.productTypes.length - 1 ? "," : ""}
                                                                </div>
                                                            })
                                                        }
                                                    </td>
                                                </tr>
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            : <></>
                    }
                    <div className="col-12 col-sm-12 col-md-2 col-lg-2">
                        <button class="btn btn-outline-gray px-2 py-1" onClick={_ => resetMarkupValue()}>
                            Reset default
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
