import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { M3DiscountCodes } from '../../../Common/M3DiscountCodes';

export const CruiseDetailGroupCabinFilter = ({ discountPaxesCodes, onSelectDiscounts, promotionsFilter, onSelectPromoFilter, resetFilter, avlCarpetId }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const { t } = useTranslation();
    const [selectFilterPromo, setSelectFilterPromo] = useState([]);

    useEffect(() => {
        setSelectFilterPromo([]);
    }, [resetFilter])

    function onChangePromoFilter(promo) {
        let updFilter = [...selectFilterPromo];

        if (!updFilter.some(x => x.id === promo.id))
            updFilter.push(promo);
        else
            updFilter = updFilter.filter(x => x.id !== promo.id);

        setSelectFilterPromo(updFilter);
        onSelectPromoFilter(updFilter);
    }

    return (
        <>
            {
                ((promotionsFilter && promotionsFilter.length > 0) || discountPaxesCodes) && <div className=" card  bg-bluesoft-100 mt-1">
                    {
                        promotionsFilter && promotionsFilter.length > 0 && <>
                            <h5 className="border-bottom p-0 mt-4 mb-0"><data m3lab="Product.Structure.Rooms"><label>{t('Product:Cruise:CabinTypeFilter')}</label> {t('Product:Cruise:CabinFare')}</data></h5>
                            <div className="row mt-2 ml-1">
                                {
                                    promotionsFilter.map((promo, index) => {
                                        return <div className="col-3 pb-2" key={index}>
                                            <div className={"card d-flex h-100 justify-content-center align-items-center small text-center border-blue-100 cursor-pointer " + (selectFilterPromo.some(x => x.id === promo.id) ? "bg-custom text-white" : "bg-white text-blue")}
                                                onClick={_ => onChangePromoFilter(promo)}>
                                                <div className="flex-row justify-content-center align-items-center">
                                                    <div className="flex-column">
                                                        {promo.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </>
                    }
                    {
                        discountPaxesCodes && <div className="row mt-2">
                            <div className="col-12 border-top">
                                <div className="card bg-bluesoft-100 border-light pt-2">
                                    <div className="row">
                                        <div className="col-12">
                                            <M3DiscountCodes discountCodes={discountPaxesCodes} onSelectDiscounts={onSelectDiscounts} avlCarpetId={avlCarpetId} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    );
}