import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { M3Icon as IconJs } from '../../js/Icon';

export const M3Icon = ({ iconName, externalClass, hasLabel }) => {
    const { t } = useTranslation();
    const [icon, setIcon] = useState(null);

    useEffect(() => {
        getIcon();
    }, [iconName])

    function getIcon() {
        try {
            setIcon(IconJs[iconName]);
        }
        catch {
            console.error("Icon not found " + iconName)
        }
    }

    return (
        <>
            {
                icon && <span className={icon.class + " " + externalClass} title={t(icon.LabelKay)}>{icon.Value}</span>
            }
            {
                icon && hasLabel && <>{t(icon.LabelKay)}</>
            }
        </>
    );
}
