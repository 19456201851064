import React, { useState, useEffect } from 'react';
import configData from "../../appsettings.json";
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { ListBox } from 'primereact/listbox';

export const SelectCitizenship = ({ defaultCitizenshipId, onSelectCitizenship, modeView }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    const [selectCitizenship, setSelectCitizenship] = useState({ isoId: 380, name: 'Italy', isoThreeLetter: 'ITA', isSelected: true });
    const [showCitizenshipPanel, setShowCitizenshipPanel] = useState(false);
    const [citizenships, setCitizenships] = useState(null);

    useEffect(() => {

        const getCitizenship = async () => { callGetCitizenship(); }
        getCitizenship();

        if (selectCitizenship && !defaultCitizenshipId)
            onSelectCitizenship(selectCitizenship);
    }, []);

    useEffect(() => {

        if (defaultCitizenshipId && citizenships && citizenships.length > 0) {
            let indexOfNewSelected = citizenships.findIndex(x => x.isoId === defaultCitizenshipId);
            if (indexOfNewSelected >= 0) {
                let selectDefaultCitizenship = citizenships[indexOfNewSelected];
                if (selectDefaultCitizenship) {
                    setSelectCitizenship(selectDefaultCitizenship);
                    onSelectCitizenship(selectDefaultCitizenship);
                }
            }
        }

    }, [defaultCitizenshipId, citizenships]);

    useEffect(() => {

        // metto un timeout perchè il render arriva un po in ritardo.
        setTimeout(() => {

            let myItem = null;

            try {
                myItem = document.getElementsByClassName("p-inputtext p-component p-listbox-filter p-listbox-filter")[0];
            } catch (ex) { }

            let citiName = '';
            try {
                citiName = citizenships.filter(x => x.isoId === selectCitizenship.isoId)[0].name;
            } catch (ex) { }

            if (myItem) {
                myItem.value = citiName;
                myItem.focus();
                myItem.select();
            }

        }, 100);

    }, [showCitizenshipPanel])

    async function callGetCitizenship() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'constant/getcitizenshipids/' + cultureName, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response && response.length > 0) {
                let mappingCitizenship = response;
                if (defaultCitizenshipId) {
                    let indexOf = mappingCitizenship.findIndex(x => x.isSelected);
                    if (indexOf >= 0) {
                        mappingCitizenship[indexOf].isSelected = false;
                    }

                    let indexOfNewSelected = mappingCitizenship.findIndex(x => x.isoId === defaultCitizenshipId);
                    if (indexOfNewSelected >= 0) {
                        mappingCitizenship[indexOfNewSelected].isSelected = true;
                    }
                }

                setCitizenships(mappingCitizenship);

                let selectDefaultCitizenship = mappingCitizenship.filter(x => x.isSelected);
                if (selectDefaultCitizenship) {
                    setSelectCitizenship(selectDefaultCitizenship[0]);
                    onSelectCitizenship(selectDefaultCitizenship[0]);
                }

            }

        } else {
            console.log("Error get citizenship");
        }

    }

    function openCitizenshipdsPanel() {
        setShowCitizenshipPanel(!showCitizenshipPanel)
    }

    function onChangeDropDownCitizenship(item) {
        setSelectCitizenship(item);
        onSelectCitizenship(item);
        setShowCitizenshipPanel(false);
    }

    return (
        <>
            <label id="Citizenship" onClick={_ => openCitizenshipdsPanel()} className={`d-none ${modeView === 'MODAL' ? "text-dark" : "text-white"}`}>
                <data m3lab="SearchEngine.Citizenship">
                    {t('SearchEngine:Citizenship')}
                </data>
                <div className="d-inline-block p-1 m-0 text-decoration-underline h075 text-right cursor-pointer">{selectCitizenship.isoThreeLetter}</div>
            </label>
            {
                showCitizenshipPanel && citizenships && <div className="mb-2">
                    <ListBox filter
                        value={selectCitizenship}
                        onChange={(e) => onChangeDropDownCitizenship(e.value)}
                        options={citizenships}
                        optionLabel="name"
                        listStyle={{ height: '250px', textAlign: 'left' }} />
                </div>
            }
        </>
    );
}
