import React, { useState, useEffect, useRef } from 'react';
import configData from "../../appsettings.json";
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { TrippieHeader } from './components/TrippieHeader';
import { TrippieSelectTransport } from './components/TrippieSelectTransport';
import { TrippieSteps } from './components/TrippieSteps';
import { TrippieFooterButton } from './components/TrippieFooterButton';
import { TrippieManageStepModal } from './components/TrippieManageStepModal';
import { OsmTravelPlaceMap } from '../TravelPlaceMap/OsmTravelPlaceMap';
import { compareDates, scrollTo, getWebsiteId } from '../../js/Utils.js';
import { TrippieHotelProductResult } from './product/hotel/TrippieHotelProductResult';
import { TrippieFlightProductResult } from './product/flight/TrippieFlightProductResult';
import { TrippieActivityProductResult } from './product/activity/TrippieActivityProductResult';
import { TrippieCarProductResult } from './product/car/TrippieCarProductResult';
import { TrippieTrainProductResult } from './product/train/TrippieTrainProductResult';
import { TrippieTourProductResult } from './product/tour/TrippieTourProductResult';
import { TrippieTransferProductResult } from './product/transfer/TrippieTransferProductResult';
import { createStepsMissing, createDefaultStep } from './service/TrippieService';
import { generateClientFlowId, createFlightCarpet, getStructureCarpetId, getActivityCarpetId, getTrainCarpetId, getTransferCarpetId, getTourCarpetId, getCarCarpetId, getFlightType } from '../../js/ProductService.js';
import { PrintCommissionsDetail } from '../Print/components/PrintCommissionsDetail.js';
import { CartAddictionalProducts } from '../Cart/CartAddictionalProducts.js';
import { TrippieMarginItems } from './components/TrippieMarginItems.js';

export const Trippie = () => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const { t } = useTranslation();

    // modal
    const [modalSendRequestBooking] = useState('TrippiSendRequestBooking');
    const [isLoadingSendRequestBooking, setIsLoadingSendRequestBooking] = useState(false);

    const [modalSaveTrippie] = useState('TrippiSaveAndPrint');
    const [isLoadingSaveTrippie, setIsLoadingSaveTrippie] = useState(false);
    const [onSendResponseResultSaveTrippie, setOnSendResponseResultSaveTrippie] = useState(null);

    const [stepIndexToDelete, setStepIndexToDelete] = useState(null);
    const [cartItemIdToDelete, setCartItemIdToDelete] = useState(null);

    const [addictionalProducts, setAddictionalProducts] = useState(null);
    const [selectedAddProdIds, setSelectedAddProdIds] = useState([]);
    const [isClickCalculateAddProd, setIsClickCalculateAddProd] = useState(null);

    // map
    const geoJsonRef = useRef();
    const [map, setMap] = useState(null);
    const [mapArr, setMapArr] = useState([{
        type: "FeatureCollection",
        features: []
    }]);

    const [showFilterArea, setShowFilterArea] = useState(false);
    const [sizeInMeters, setSizeInMeters] = useState(0);
    const [featureFiltered, setFeatureFiltered] = useState([]);

    const clusterMarkerPopup = (childMarkers) => {
        var htmlContainer = "<div>";
        htmlContainer += '<div style="display:block; max-height: 400px; width: 250px; overflow-x: hidden;" />';
        childMarkers.forEach(function (m) {

            htmlContainer += "<div><label>Name: " + m.properties?.StructureName + " </label></div>";
            htmlContainer += "<hr/>"

        });
        htmlContainer += "</div>";
        htmlContainer += "</div>";
        return htmlContainer;
    }

    const updateFeatures = (features) => {
        setFeatureFiltered(features);
    }

    const markerPopup = (feature) => {
        var htmlContainer = '<div style="display:block; max-height: 400px; width: 250px; overflow-x: hidden;" />';

        htmlContainer += "<div><label>Name: " + feature.properties?.StructureName + " </label></div>";

        htmlContainer += "</div>";
        return htmlContainer;
    }

    function configureMap(mySteps) {
        let featureArr = [];

        if (mySteps && mySteps.length > 0) {
            let coordinatesLines = [];

            mySteps.forEach(function (myStep, index) {
                let lat = myStep.latitude;
                let lon = myStep.longitude;

                if (lat && lat !== 0) {
                    coordinatesLines.push([lon, lat]);

                    featureArr.push({
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [lon, lat]
                        },
                        properties: {
                            StructureId: myStep.id,
                            StructureCategory: myStep.stepType,
                            StructureName: myStep.stepName,
                            ItineraryPointId: myStep.step,
                            ItineraryId: 1,
                        }
                    });
                }

            });

            if (mySteps.length > 1) {

                // the last step is flight, I add a line from last step to first step
                if (mySteps[mySteps.length - 1].stepType === (configData.Settings.Products.Flight.IdTipoProdotto + "")) {
                    coordinatesLines.push(coordinatesLines[0]);
                    featureArr.push(featureArr[0]);
                }
            }

            featureArr.unshift({
                type: "Feature",
                properties: {
                    ItineraryId: 1,
                    ItineraryName: "Itinerario"
                },
                geometry: {
                    coordinates: coordinatesLines,
                    type: "LineString"
                }
            });
        }

        setMapArr([{
            type: "FeatureCollection",
            features: featureArr
        }]);
    }

    // trippie
    const [searchParameters, setSearchParameters] = useState({
        Text: '',
        dateFrom: null,
        dateTo: null,
        roomsComposition: [{
            adults: 2,
            children: 0,
            childAges: []
        }]
    });

    const defaultItem = {
        internalId: null,

        productType: null,
        productFrom: null,
        productTo: null,
        dateFrom: null,
        dateTo: null,
        clientFlowId: null,
        carpetId: null,
        carpetId: null,
        carpetAvlId: null,
        searchParams: null,

        carpetId: null,
        carpetAvlId: null,

        renderInfo: null,

        // Activity
        activityInfo: null,

        // Flight
        legIndex: 1,
        itineraryInfo: null,

        // Structure
        structureInfo: null,

        // Tour
        tourInfo: null,

        // Transfer
        transferInfo: null
    };

    const [searchType, setSearchType] = useState('FLIGHT');
    const [isValidSearchParam, setIsValidSearchParam] = useState(false);
    const [steps, setSteps] = useState(null);
    const [tableMarginItems, setTableMarginItems] = useState(null);
    const [changeDataStep, setChangeDataStep] = useState(1);
    const [productSearchParam, setProductSearchParam] = useState(null);

    const [itemsForStepsItems, setItemsForStepsItems] = useState([]);
    const [marginItemsPaxes, setMarginItemsPaxes] = useState([]);


    // Load from cart
    useEffect(() => {

        const getTrippieInitData = async () => {
            const response = callGetLoadTrippie();
        }

        getTrippieInitData();

    }, [])

    async function onUpdateSearchParam(travelName, dateFrom, dateTo, selectComposition) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                text: travelName,
                dateFrom: dateFrom,
                dateTo: dateTo,
                roomsComposition: selectComposition
            })
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'trippie/SaveHeader', requestOption);
        const response = await fetchedRes.json();
        if (response.success) {
            if (response.baseInfo && response.baseInfo.roomsComposition)
                setSearchParameters(response.baseInfo);

            getPaxesForStepsItems(response.baseInfo, steps);
        }
    }

    async function callGetLoadTrippie() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Trippie/LoadCart', requestOption);
        const response = await fetchedRes.json();

        if (response.success) {
            if (response.baseInfo && response.baseInfo.roomsComposition)
                setSearchParameters(response.baseInfo);

            setSteps(response.steps);
            configureTableMarginItems(response);
            configureMap(response.steps);

            getPaxesForStepsItems(response.baseInfo, response.steps);

            if (response.baseInfo)
                checkIsValidSearchParam(response.baseInfo.dateFrom, response.baseInfo.dateTo, response.baseInfo.roomsComposition);
        }

    }

    // Manage Header Cart
    function checkIsValidSearchParam(dateFrom, dateTo, composition) {
        let isValidSP = true;
        if (!dateFrom)
            isValidSP = false;

        if (!dateTo)
            isValidSP = false;

        if (!composition || composition.length === 0)
            isValidSP = false;

        setIsValidSearchParam(isValidSP);
    }

    function canAddFlightTransport(mySteps) {
        if (!mySteps || mySteps.length === 0)
            return true;

        let existService = true;

        //let otherSteps = mySteps.filter(x => x.stepType !== (configData.Settings.Products.Flight.IdTipoProdotto + ""));
        //if (otherSteps && otherSteps.length > 0)
        existService = mySteps.some(x => x.items && x.items.length > 0);

        return existService;
    }


    function configureTableMarginItems(response) {
        if (response && response.tablePricing)
            setTableMarginItems({
                hasIVACee: response.tablePricing.hasIVACee,
                tableMargin: response.tablePricing.tableMargin,
                totalCommissionAmount: response.tablePricing.totalCommission,
                totalCommissionable: response.tablePricing.totalCommissionable,
                totalCommissionCurrency: response.tablePricing.currency,
                totalNotCommissionableAmount: response.tablePricing.totalNotCommissionable,
                totalNotCommissionableCurrency: response.tablePricing.currency,
                totalPrice: response.tablePricing.totalPrice,
                currency: response.tablePricing.currency,
            });
        else
            setTableMarginItems(null);

        if (response && response.recapItems)
            setItemsForStepsItems(response.recapItems);
        else
            setItemsForStepsItems(null);
    }

    // Manage Step
    const onAddStep = () => {
        try {
            let btn = document.getElementById("open_modal_trippie_manage_step");
            btn.click();
        } catch (ex) { }
    }

    const onAddMissingStep = (index) => {
        let updSteps = [...steps];
        let newSteps = createStepsMissing(index, updSteps, searchParameters);
        setSteps(newSteps);

        const getTrippieInitData = async (newSteps) => {
            const response = callSaveSteps(null, newSteps);
        }
        getTrippieInitData(newSteps);
    }

    const onSaveSteps = (baseInfo, newSteps) => {
        const getTrippieInitData = async (newSteps) => {
            const response = callSaveSteps(baseInfo, newSteps);
        }
        getTrippieInitData(newSteps);
    }

    async function callSaveSteps(baseInfo, newSteps) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ baseInfo: baseInfo, items: newSteps })
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'trippie/SaveSteps', requestOption);
        const response = await fetchedRes.json();
        if (response.success) {
            setSteps(response.steps);
            if (response.baseInfo)
                setSearchParameters(response.baseInfo);

            getPaxesForStepsItems(response.baseInfo, response.steps);

            configureTableMarginItems(response);

            configureMap(response.steps);
        }
    }

    const getSteps = () => {
        const getStepsInitData = async () => {
            callGetSteps();
        }
        getStepsInitData();
    }

    async function callGetSteps() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'trippie/getSteps', requestOption);
        const response = await fetchedRes.json();

        if (response.success) {
            setSteps(response.steps);
            configureTableMarginItems(response);
            configureMap(response.steps);
        }
    }


    const onRemoveItem = (index, indexItem) => {
        let updSteps = [...steps];

        updSteps[index].items.splice(indexItem, 1);
        if (updSteps[index].items.length === 0)
            updSteps.splice(index, 1);

        setSteps(updSteps);
    }

    async function onUpdateFlightParam(selectTransport, destinations, dates, searchParams = null) {
        let updSteps = [...steps];
        setSearchType(selectTransport);

        if (selectTransport === 'NO') {
            updSteps = updSteps.filter(x => x.stepType !== (configData.Settings.Products.Flight.IdTipoProdotto + ""));
        }

        if (selectTransport === configData.Settings.Products.Flight.IdTipoProdotto) {
            let flightArr = [];
            updSteps = [];
            //updSteps = updSteps.filter(x => x.stepType !== (configData.Settings.Products.Flight.IdTipoProdotto + ""));
            let lastDate = '';
            for (let i = 0; i < destinations.length; i++) {

                let dest = destinations[i];
                let date = dates[i];

                //let existPreviousStep = steps.filter(x => x.stepType !== (configData.Settings.Products.Flight.IdTipoProdotto + "") && compareDates(x.stepFrom, date.dateFrom) === -1);
                let existPreviousStep = [];
                if (existPreviousStep && existPreviousStep.length > 0) {
                    for (let j = 0; j < existPreviousStep.length; j++) {
                        flightArr.push(existPreviousStep[j]);
                    }
                }

                lastDate = date.dateFrom;

                let newItem = { ...defaultItem };
                newItem.internalId = generateClientFlowId();
                newItem.productType = configData.Settings.Products.Flight.IdTipoProdotto;
                newItem.productFrom = dest.dep.Text;
                newItem.productTo = dest.arr.Text;
                newItem.dateFrom = date.dateFrom;
                newItem.dateTo = null;
                newItem.legIndex = (i + 1);

                flightArr.push({
                    id: -1,
                    stepType: configData.Settings.Products.Flight.IdTipoProdotto + "",
                    stepName: "Volo " + dest.dep.IataCode + ' - ' + dest.arr.IataCode,
                    stepFrom: date.dateFrom,
                    stepTo: date.dateFrom,
                    nights: 0,
                    webDestinationName: dest.dep.Text,
                    webDestinationId: dest.dep.Id,
                    items: [newItem]
                });
            }

            let updFlightType = getFlightType(searchParams);
            if (updFlightType === 2) {
                let dest = destinations[0];
                let date = dates[0];

                let newItem = { ...defaultItem };
                newItem.internalId = generateClientFlowId();
                newItem.productType = configData.Settings.Products.Flight.IdTipoProdotto;
                newItem.productFrom = dest.arr.Text;
                newItem.productTo = dest.dep.Text;
                newItem.dateFrom = date.dateTo;
                newItem.dateTo = null;
                newItem.legIndex = 2;

                flightArr.push({
                    id: -1,
                    stepType: configData.Settings.Products.Flight.IdTipoProdotto + "",
                    stepName: "Volo " + dest.arr.IataCode + ' - ' + dest.dep.IataCode,
                    stepFrom: date.dateTo,
                    stepTo: date.dateTo,
                    nights: 0,
                    webDestinationName: dest.arr.Text,
                    webDestinationId: dest.arr.Id,
                    items: [newItem]
                });
            }

            let updSearchParam = { ...searchParameters }
            //let existNextStep = steps.filter(x => x.stepType !== (configData.Settings.Products.Flight.IdTipoProdotto + "") && compareDates(x.stepFrom, lastDate) > -1);
            //let existNextStep = [];
            //if (existNextStep && existNextStep.length > 0) {
            //    for (let j = 0; j < existNextStep.length; j++) {
            //        flightArr.push(existNextStep[j]);
            //    }
            //} else if (steps.filter(x => x.stepType !== (configData.Settings.Products.Flight.IdTipoProdotto + "")).length < 1) {

            let originalFlightArr = [...flightArr];
            for (let i = originalFlightArr.length - 2; i >= 0; i--) {
                flightArr = createDefaultStep(i, flightArr, updSearchParam);
            }

            //}

            updSteps = flightArr;

            // search flight
            if (searchParams) {
                let clientFlowId = generateClientFlowId();

                const cookies = new Cookies();
                const sessionToken = cookies.get("SessionToken");

                let inputData = {
                    carpetOperation: "SRC",
                    clientFlowId: clientFlowId,
                    sessionToken: sessionToken,
                    websiteId: getWebsiteId(),
                    flightStandardSearchParameters: searchParams,
                    productSubType: 51 // flight
                };

                let carpetId = await createFlightCarpet(inputData);
                onStartSearch(0, clientFlowId, carpetId, searchParams, configData.Settings.Products.Flight.IdTipoProdotto);
            }
        }
        
        setSteps(updSteps);
    }

    function onStartSearch(stepIndex, clientFlowId, carpetId, searchParams, productType) {
        switch (productType) {
            case configData.Settings.Products.Activity.IdTipoProdotto: {
                setProductSearchParam({
                    stepIndex: stepIndex,
                    cartItemId: null,
                    productType: productType,
                    searchParams: searchParams,
                    clientFlowId: clientFlowId,
                    carpetId: carpetId,
                    carpetAvlId: null
                });

                break;
            }

            case configData.Settings.Products.CarRental.IdTipoProdotto: {
                setProductSearchParam({
                    stepIndex: stepIndex,
                    cartItemId: null,
                    productType: productType,
                    searchParams: searchParams,
                    clientFlowId: clientFlowId,
                    carpetId: carpetId,
                    carpetAvlId: null
                });

                break;
            }

            case configData.Settings.Products.Flight.IdTipoProdotto: {
                setProductSearchParam({
                    stepIndex: stepIndex,
                    cartItemId: null,
                    productType: productType,
                    searchParams: searchParams,
                    clientFlowId: clientFlowId,
                    carpetId: carpetId,
                    carpetAvlId: null
                });

                break;
            }


            case configData.Settings.Products.Structure.IdTipoProdotto: {
                setProductSearchParam({
                    stepIndex: stepIndex,
                    cartItemId: null,
                    productType: productType,
                    searchParams: searchParams,
                    clientFlowId: clientFlowId,
                    carpetId: carpetId,
                    carpetAvlId: null
                });

                break;
            }

            case configData.Settings.Products.Tour.IdTipoProdotto: {
                setProductSearchParam({
                    stepIndex: stepIndex,
                    cartItemId: null,
                    productType: productType,
                    searchParams: searchParams,
                    clientFlowId: clientFlowId,
                    carpetId: carpetId,
                    carpetAvlId: null
                });

                break;
            }

            case configData.Settings.Products.Transfer.IdTipoProdotto: {
                setProductSearchParam({
                    stepIndex: stepIndex,
                    cartItemId: null,
                    productType: productType,
                    searchParams: searchParams,
                    clientFlowId: clientFlowId,
                    carpetId: carpetId,
                    carpetAvlId: null
                });

                break;
            }

            case configData.Settings.Products.Train.IdTipoProdotto: {
                setProductSearchParam({
                    stepIndex: stepIndex,
                    cartItemId: null,
                    productType: productType,
                    searchParams: searchParams,
                    clientFlowId: clientFlowId,
                    carpetId: carpetId,
                    carpetAvlId: null
                });

                break;
            }
            default: break;
        }

        try {
            scrollTo(null, "trippieSearchListResult");
        } catch (ex) { }
      
    }

    function onAddFlight(itinRecap, stepIndex) {
        if (stepIndex === 0) {
            let updSteps = [...steps];

            const getTrippieInitData = async (newSteps) => {
                const response = callSaveSteps(null, newSteps);
                getSteps();
            }
            getTrippieInitData(updSteps);
        } else {
            getSteps();
        }

        setProductSearchParam(null);
    }

    function createNewItem(productType, psp, carpetId, carpetAvailability, renderInfo) {

        let newItem = { ...defaultItem };
        newItem.internalId = generateClientFlowId();

        newItem.productType = productType;
        newItem.productFrom = null;
        newItem.productTo = null;
        newItem.dateFrom = null;
        newItem.dateTo = null;
        newItem.clientFlowId = psp.clientFlowId;
        newItem.searchParams = psp.searchParams;

        newItem.carpetId = carpetId;
        newItem.carpetAvlId = carpetAvailability;

        newItem = updateItem(productType, newItem, renderInfo);
        return newItem;
    }

    function updateItem(productType, item, renderInfo) {
        switch (productType) {
            case configData.Settings.Products.Activity.IdTipoProdotto: {
                item.renderInfo = renderInfo;

                break;
            }

            case configData.Settings.Products.CarRental.IdTipoProdotto: {
                item.renderInfo = renderInfo;

                break;
            }

            case configData.Settings.Products.Structure.IdTipoProdotto: {
                item.renderInfo = renderInfo;

                break;
            }

            case configData.Settings.Products.Tour.IdTipoProdotto: {
                item.renderInfo = renderInfo;

                break;
            }

            case configData.Settings.Products.Transfer.IdTipoProdotto: {
                item.renderInfo = renderInfo;

                break;
            }

            case configData.Settings.Products.Train.IdTipoProdotto: {
                item.renderInfo = renderInfo;

                break;
            }
            default: break;
        }

        return item;
    }

    function updateStepSelectItem(stepIndex, internalId, newItem) {
        let updSteps = [...steps];
        let items = updSteps[stepIndex - 1].items;

        if (!internalId) {
            if (!items)
                items = [];

            items.push(newItem);
        } else {
            let foundIndex = items.findIndex(x => x.internalId === internalId);
            items[foundIndex] = newItem;
        }

        updSteps[stepIndex - 1].items = items;

        setSteps(updSteps);
        setProductSearchParam(null);
        setChangeDataStep(changeDataStep + 1);
        getSteps();

        try {
            scrollTo(null, "stepInfo_" + { stepIndex });
        } catch (ex) { }
       
    }

    function onAddHotel(stepIndex, internalId, hotelRecap, carpetId, carpetAvailability) {
        let newItem = createNewItem(configData.Settings.Products.Structure.IdTipoProdotto, productSearchParam, carpetId, carpetAvailability, { ...hotelRecap });
        updateStepSelectItem(stepIndex, internalId, newItem)
    }

    function onAddActivity(stepIndex, internalId, activityRecap, carpetId, carpetAvailability) {
        let newItem = createNewItem(configData.Settings.Products.Activity.IdTipoProdotto, productSearchParam, carpetId, carpetAvailability, { ...activityRecap });
        updateStepSelectItem(stepIndex, internalId, newItem)
    }

    function onAddCar(stepIndex, internalId, carRecap, carpetId, carpetAvailability) {
        let newItem = createNewItem(configData.Settings.Products.CarRental.IdTipoProdotto, productSearchParam, carpetId, carpetAvailability, { ...carRecap });
        updateStepSelectItem(stepIndex, internalId, newItem)
    }

    function onAddTransfer(stepIndex, internalId, transferRecap, carpetId) {
        let newItem = createNewItem(configData.Settings.Products.Transfer.IdTipoProdotto, productSearchParam, carpetId, null, { ...transferRecap });
        updateStepSelectItem(stepIndex, internalId, newItem)
    }

    function onAddTour(stepIndex, internalId, tourRecap, carpetId, carpetAvailability) {
        let newItem = createNewItem(configData.Settings.Products.Tour.IdTipoProdotto, productSearchParam, carpetId, carpetAvailability, { ...tourRecap });
        updateStepSelectItem(stepIndex, internalId, newItem)
    }

    function onAddTrain() {

    }

    function removeItemAfterSearch(stepIndex, cartItemId) {
        let updSteps = [...steps];

        var haveMultiLegs = updSteps.filter(x => x.refFlightCartItem === cartItemId);

        setStepIndexToDelete(stepIndex);
        setCartItemIdToDelete(cartItemId);

        if (!haveMultiLegs || haveMultiLegs.length < 2) {
            deleteCartItemAndReGenerateStep(updSteps, stepIndex, cartItemId);
        } else {

            try {
                let myBtn = document.getElementById("open_confirmDeleteItem");
                myBtn.click();
            } catch (ex) {

            }

        }
    }

    function onConfirmDeleteCartItems() {
        let updSteps = [...steps];

        deleteCartItemAndReGenerateStep(updSteps, stepIndexToDelete, cartItemIdToDelete);
    }

    function deleteCartItemAndReGenerateStep(updSteps, stepIndex, cartItemId) {
        let items = updSteps[stepIndex].items;
        if (!items)
            items = [];

        updSteps[stepIndex].items = items.filter(x => x.cartItemId !== cartItemId);
        updSteps = updSteps.filter(x => x.refFlightCartItem !== cartItemId);

        setSteps(updSteps);

        const deleteCartItem = async (cartItemIdParam, newSteps) => {
            const response = callDeleteCartItem(cartItemIdParam, newSteps);
        }

        deleteCartItem(cartItemId, updSteps);
    }

    async function callDeleteCartItem(cartItemIdParam, newSteps) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ cartItemIdDelete: cartItemIdParam, items: newSteps })
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'trippie/DeleteCartItem', requestOption);
        const response = await fetchedRes.json();
        if (response.success) {
            setSteps(response.steps);

            configureTableMarginItems(response);

            setStepIndexToDelete(null);
            setCartItemIdToDelete(null);
        }
    }


    async function updateItemAfterSearch(stepIndex, cartItemId, productType, searchParams) {
        let clientFlowId = generateClientFlowId();
        let carpetId = null;

        if (cartItemId) {
            switch (productType) {
                case configData.Settings.Products.Activity.IdTipoProdotto: {
                    carpetId = await getActivityCarpetId(searchParams, clientFlowId);
                    break;
                }

                case configData.Settings.Products.CarRental.IdTipoProdotto: {
                    carpetId = await getCarCarpetId(searchParams, clientFlowId);
                    break;
                }

                case configData.Settings.Products.Structure.IdTipoProdotto: {
                    carpetId = await getStructureCarpetId(searchParams, clientFlowId);
                    break;
                }

                case configData.Settings.Products.Tour.IdTipoProdotto: {
                    carpetId = await getTourCarpetId(searchParams, clientFlowId);
                    break;
                }

                case configData.Settings.Products.Transfer.IdTipoProdotto: {
                    carpetId = await getTransferCarpetId(searchParams, clientFlowId);
                    break;
                }

                case configData.Settings.Products.Train.IdTipoProdotto: {
                    carpetId = await getTrainCarpetId(searchParams, clientFlowId);
                    break;
                }

                case configData.Settings.Products.Flight.IdTipoProdotto: {
                    const cookies = new Cookies();
                    const sessionToken = cookies.get("SessionToken");

                    let inputData = {
                        carpetOperation: "SRC",
                        clientFlowId: clientFlowId,
                        sessionToken: sessionToken,
                        websiteId: getWebsiteId(),
                        flightStandardSearchParameters: searchParams,
                        productSubType: 51 // flight
                    };

                    carpetId = await createFlightCarpet(inputData);
                    break;
                }

                default:
                    break;
            }

            setProductSearchParam({
                stepIndex: stepIndex,
                cartItemId: cartItemId,
                productType: productType,
                searchParams: searchParams,
                clientFlowId: clientFlowId,
                carpetId: carpetId,
                carpetAvlId: null
            });

            try {
                scrollTo(null, "trippieSearchListResult");
            } catch (ex) { }
            
        }
        else
            console.error("Missing itemToUpdate");
    }

    async function callSaveRequestBooking() {
        const inputData = createSaveTrippieRequest();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'trippie/SaveRichiestaInterventoBooking', requestOption);
        const response = await fetchedRes.json();

        if (response) {
            setOnSendResponseResultSaveTrippie({ success: true, quotationId: response.quotationId, ticketId: response.ticketId });
        } else {
            setOnSendResponseResultSaveTrippie({ success: false, message: "Generic Error" });
        }

        setIsLoadingSendRequestBooking(false);

        try {
            let myBtn = document.getElementById("open_confirmsavequotation");
            myBtn.click();
        } catch (ex) {

        }

    }

    function saveRequestBooking() {
        setIsLoadingSendRequestBooking(true);
        setIsLoadingSaveTrippie(false);
        setOnSendResponseResultSaveTrippie(null);

        try {
            let myBtn = document.getElementById("open_" + modalSendRequestBooking);
            myBtn.click();
        } catch (ex) {

        }

        callSaveRequestBooking();
    }

    async function callSaveTrippie() {
        const inputData = createSaveTrippieRequest();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'trippie/savecartquotation', requestOption);
        const response = await fetchedRes.json();

        if (response.quotationId) {
            let url = `${configData.Settings.Base_Url}quotation/${response.quotationId}?opSend=1`;
            setOnSendResponseResultSaveTrippie({ success: true, quotationId: response.quotationId, url: url });
        } else {
            setOnSendResponseResultSaveTrippie({ success: false });
        }

        try {
            let myBtn = document.getElementById("open_confirmsavequotation");
            myBtn.click();
        } catch (ex) {

        }

    }

    function goToQuotationPage() {
        window.location.href = onSendResponseResultSaveTrippie.url;
    }

    function saveTrippie() {
        setIsLoadingSendRequestBooking(false);
        setIsLoadingSaveTrippie(true);
        setOnSendResponseResultSaveTrippie(null);

        try {
            let myBtn = document.getElementById("open_" + modalSaveTrippie);
            myBtn.click();
        } catch (ex) {

        }

        callSaveTrippie();
    }

    function createSaveTrippieRequest() {
        return {
            baseInfo: searchParameters,
            steps: steps,
            addictionalProducts: addictionalProducts,
            addictionalProductSelected: selectedAddProdIds
        };
    }

    const getPaxesForStepsItems = (trippieBaseInfo, updSteps) => {
        if (!updSteps || updSteps.lentgh === 0) {
            return null;
        }

        let paxCompositions = trippieBaseInfo.roomsComposition;

        let totalAdults = 0;
        let totalChildren = 0;

        if (paxCompositions)
            for (let i = 0; i < paxCompositions.length; i++) {
                totalAdults += paxCompositions[i].adults;
                totalChildren += paxCompositions[i].children;
            }

        let paxes = [];

        for (let i = 0; i < totalAdults; i++) {
            paxes.push({ bookParameters: [{ id: 'PAX_TYPE_QUOT', value: 'ADT' }] });
        }
        for (let i = 0; i < totalChildren; i++) {
            paxes.push({ bookParameters: [{ id: 'PAX_TYPE_QUOT', value: 'CHD' }] });
        }

        setMarginItemsPaxes(paxes);
    }

    function goToCart() {

        localStorage.removeItem("TrippieAddictionalProducts");

        if (selectedAddProdIds) {
            let jCurrentAddToOrder = JSON.stringify(selectedAddProdIds);
            localStorage.setItem("TrippieAddictionalProducts", jCurrentAddToOrder);
        }

        window.location.href = `cart`;
    }

    async function callDeleteCart() {
        const requestOption = { method: 'DELETE', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Trippie/ResetTrippie', requestOption);
        const response = await fetchedRes.json();

        if (response.deleted) {
            window.location.reload();
        }
    }

    async function calculateAddictionalProduct() {
        setIsClickCalculateAddProd(true);

        let apiToCall = 'Trippie/InitAddictionalProducts';

        let inputData = {};
        inputData.AddictionalProductsSettings = { GroupMandatory: configData.Settings.Cart.AddictionalProductsGroupMandatory, GroupMandatoryDescription: t(`Cart:GroupMandatoryDescription`) };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + apiToCall, requestOption);
        const response = await fetchedRes.json();

        if (response && response.success) {
            setAddictionalProducts(response.addictionalProducts);
        }
    }

    async function updateTableMargin(selectedAddictionalProducts) {

        let apiToCall = 'Trippie/UpdateAddictionalProducts';

        let inputData = {};
        inputData.selectedAddictionalProducts = selectedAddictionalProducts;
        inputData.addictionalProducts = addictionalProducts;
        inputData.AddictionalProductsSettings = { GroupMandatory: configData.Settings.Cart.AddictionalProductsGroupMandatory, GroupMandatoryDescription: t(`Cart:GroupMandatoryDescription`) };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + apiToCall, requestOption);
        const response = await fetchedRes.json();

        if (response && response.success) {
            configureTableMarginItems(response);
        }
    }

    // gestisce l'aggiunta di un prodotto aggiuntivo (assicurazioni, etc.) tra quelli selezionati
    const handleAddProdSelection = (aProducts) => {
        let tmpSelected = [];

        // copy array
        for (var i = 0; i < selectedAddProdIds.length; i++) {
            tmpSelected.push(selectedAddProdIds[i]);
        }

        for (let i = 0; i < aProducts.length; i++) {
            let objIndex = tmpSelected.findIndex(x => x.idProdotto === aProducts[i].idProdotto);

            if (objIndex < 0)
                tmpSelected.push(aProducts[i]);
            else
                tmpSelected[objIndex] = aProducts[i];
        }

        setSelectedAddProdIds(tmpSelected);
        updateTableMargin(tmpSelected);
    }

    return (
        <>
            <div className="row mt-4">
                <div className="col-12 col-sm-12 col-md-6">
                    <TrippieHeader
                        onUpdateSearchParam={onUpdateSearchParam}
                        searchParameters={searchParameters} />

                    {
                        (!searchParameters || !searchParameters.text || !searchParameters.dateFrom) && <div className="h5 text-danger text-center mt-2">Compila le date di partenza e dai un nome al tuo trippie</div>
                    }
                    {
                        searchParameters && searchParameters.text && searchParameters.dateFrom && <>
                            {
                                canAddFlightTransport(steps) && <TrippieSelectTransport
                                    searchParameters={searchParameters}
                                    onUpdateFlightParam={onUpdateFlightParam} />
                            }

                            <div className="mt-4">
                                <TrippieSteps
                                    steps={steps}
                                    changeDataStep={changeDataStep}
                                    searchParameters={searchParameters}
                                    onAddStep={onAddStep}
                                    onAddMissingStep={onAddMissingStep}
                                    onRemoveItem={onRemoveItem}
                                    onStartSearch={onStartSearch}
                                    updateItemAfterSearch={updateItemAfterSearch}
                                    removeItemAfterSearch={removeItemAfterSearch}
                                />
                            </div>
                        </>
                    }
                    {
                        isClickCalculateAddProd && <CartAddictionalProducts
                            isTrippie={true}
                            handleAddProdSelection={handleAddProdSelection} />
                    }

                    {itemsForStepsItems && itemsForStepsItems.length > 0 &&
                        <div className="row">
                            <div className="col-12 col-sm-1 col-md-1 col-lg-1">
                            </div>
                            <div className="col-12 col-sm-11 col-md-11 col-lg-11">
                                <PrintCommissionsDetail
                                    marginItems={itemsForStepsItems}
                                    taxesFlightArr={null}
                                    isPrintCliente={false}
                                    paxes={marginItemsPaxes}
                                    onHandleIsPrint={null}
                                    isConfirmPrint={true}
                                />
                            </div>
                        </div>
                    }
                    {
                        steps && steps.length > 0 && <TrippieFooterButton
                            saveTrippie={saveTrippie}
                            saveRequestBooking={saveRequestBooking}
                            goToCart={goToCart}
                            isClickCalculateAddProd={isClickCalculateAddProd}
                            calculateAddictionalProduct={calculateAddictionalProduct} />
                    }

                    <TrippieManageStepModal
                        steps={steps}
                        searchParameters={searchParameters}
                        onSaveSteps={onSaveSteps} />

                    <a id="open_confirmDeleteItem" data-bs-toggle="modal" data-bs-target="#modal_confirmDeleteItem"></a>
                    <div className="modal fade" id="modal_confirmDeleteItem" tabIndex="-1" aria-labelledby="modal_confirmDeleteItem_label" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="modal_confirmDeleteItem_label">Conferma cancellazione</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 h5 text-danger">
                                            Stai per eliminare un prodotto multitratta.<br />
                                            Sei sicuro di continuare
                                        </div>
                                    </div>
                                </div>
                                <div className='modal-footer'>
                                    <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t("Button:Close")}</button>
                                    <button type="button" className="btn bt-sm tp-btn-confirm" data-bs-dismiss="modal" onClick={_ => onConfirmDeleteCartItems()}>
                                        {t("Button:Confirm")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <a id={"open_ResetTrippie"} data-bs-toggle="modal" data-bs-target={"#modal_ResetTrippie"}></a>
                    <div className="modal fade" id={"modal_ResetTrippie"} tabIndex="-1" aria-labelledby="modal_ResetTrippieLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="modal_ResetTrippieLabel">Svuota Trippie</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    Sei sicuro di voler svuotare il trippie?
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">Chiudi</button>
                                    <button type="button" className="btn bt-sm tp-btn-search" onClick={_ => callDeleteCart()}>{t('Button:Confirm')}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <a id={"open_confirmsavequotation"} data-bs-toggle="modal" data-bs-target={"#modal_confirmsavequotation"}></a>
                    <div className="modal fade" id={"modal_confirmsavequotation"} tabIndex="-1" aria-labelledby="modal_confirmsavequotationLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="modal_confirmsavequotationLabel">Svuota Trippie</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    {
                                        onSendResponseResultSaveTrippie && onSendResponseResultSaveTrippie.success && <>
                                            {
                                                onSendResponseResultSaveTrippie.ticketId && <div className="row mt-4 mb-4">
                                                    <div className="col-12 text-center">
                                                        <h5>Richiesta {onSendResponseResultSaveTrippie.ticketId} - Preventivo {onSendResponseResultSaveTrippie.quotationId}</h5>
                                                    </div>

                                                    <div className="col-12 text-center penalty free">
                                                        La tua richiesta è stata salvata con successo, a breve riceverai una email riepilogativa.
                                                    </div>
                                                </div>

                                            }

                                            {
                                                !onSendResponseResultSaveTrippie.ticketId && <div className="row mt-4 mb-4">
                                                    <div className="col-12 text-center">
                                                        <h5>Preventivo {onSendResponseResultSaveTrippie.quotationId}</h5>
                                                    </div>
                                                    <div className="col-12 text-center penalty free">
                                                        Il tuo trippie è stato salvato con successo.
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                    {
                                        (!onSendResponseResultSaveTrippie || !onSendResponseResultSaveTrippie.success) && <>
                                            <div className="row mt-4 mb-4">
                                                <div className="col-12 text-center text-danger">
                                                    Si è verificato un errore durante il salvataggio del trippie
                                                </div>
                                            </div>
                                        </>
                                    }

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">Chiudi</button>
                                    {
                                        onSendResponseResultSaveTrippie && onSendResponseResultSaveTrippie.url && <button
                                            type="button"
                                            className="btn bt-sm tp-btn-search"
                                            onClick={_ => goToQuotationPage()}
                                            data-bs-dismiss="modal">
                                            Vedi il tuo preventivo
                                        </button>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="trippieSearchListResult" className="col-12 col-sm-12 col-md-6 bg-white" style={{ zIndex: 100 }}>
                    <TrippieMarginItems
                        tableMarginItems={tableMarginItems}
                        callDeleteCart={callDeleteCart} />

                    {
                        !productSearchParam && <div className="generalMapContainer mt-4">
                            <OsmTravelPlaceMap
                                data={mapArr}
                                center={[0, 0]}
                                zoom={2}
                                scrollWheelZoom={true}
                                showFilterArea={showFilterArea}
                                sizeInMeters={sizeInMeters}
                                showClusterPopup={true}
                                updateFeatures={updateFeatures}
                                clusterMarkerPopup={clusterMarkerPopup}
                                markerPopup={markerPopup}
                                geoJsonRef={geoJsonRef}
                                map={map}
                                setMap={setMap}
                            >
                            </OsmTravelPlaceMap>
                        </div>
                    }
                    {
                        productSearchParam && <>
                            {
                                productSearchParam.productType === configData.Settings.Products.Activity.IdTipoProdotto && <TrippieActivityProductResult
                                    productSearchParam={productSearchParam}
                                    onAddActivity={onAddActivity} />
                            }
                            {
                                productSearchParam.productType === configData.Settings.Products.CarRental.IdTipoProdotto && <TrippieCarProductResult
                                    productSearchParam={productSearchParam}
                                    onAddCar={onAddCar} />
                            }
                            {
                                productSearchParam.productType === configData.Settings.Products.Flight.IdTipoProdotto && <TrippieFlightProductResult
                                    productSearchParam={productSearchParam}
                                    onAddFlight={onAddFlight} />
                            }
                            {
                                productSearchParam.productType === configData.Settings.Products.Structure.IdTipoProdotto && <TrippieHotelProductResult
                                    productSearchParam={productSearchParam}
                                    onAddHotel={onAddHotel} />
                            }
                            {
                                productSearchParam.productType === configData.Settings.Products.Tour.IdTipoProdotto && <TrippieTourProductResult
                                    productSearchParam={productSearchParam}
                                    onAddTour={onAddTour} />
                            }
                            {
                                productSearchParam.productType === configData.Settings.Products.Transfer.IdTipoProdotto && <TrippieTransferProductResult
                                    productSearchParam={productSearchParam}
                                    onAddTransfer={onAddTransfer} />
                            }
                            {
                                productSearchParam.productType === configData.Settings.Products.Train.IdTipoProdotto && <TrippieTrainProductResult
                                    productSearchParam={productSearchParam}
                                    onAddTrain={onAddTrain} />
                            }
                        </>
                    }
                </div>
            </div>
        </>
    );
}
