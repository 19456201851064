import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

export const ProductDataCarOffice = ({ offices, onUpdateData }) => {
    const { t } = useTranslation();

    const dtRef = useRef(null);
    const [normalizeOffices, setNormalizeOffices] = useState({});
    const [dayOfWeekOptions] = useState([
        { label: t("Calendar:Day:Sunday"), value: 0 },
        { label: t("Calendar:Day:Monday"), value: 1 },
        { label: t("Calendar:Day:Tuesday"), value: 2 },
        { label: t("Calendar:Day:Wedsnday"), value: 3 },
        { label: t("Calendar:Day:Thursday"), value: 4 },
        { label: t("Calendar:Day:Friday"), value: 5 },
        { label: t("Calendar:Day:Sunday"), value: 6 },
    ]);

    useEffect(() => {
        if (offices)
            setNormalizeOffices(offices);
    }, [offices])

    const onChangeOfficeData = (myOfficeData) => {
        onUpdateData(myOfficeData);
    };

    const bodyDelete = (rowData) => {
        return (
            <div className="cursor-pointer" onClick={_ => onRemove(rowData)}>
                <span class="material-icons-outlined text-danger">delete</span>
            </div>
        );
    };

    const onRowEditComplete = (e) => {
        let _desc = { ...normalizeOffices };
        let { newData, index } = e;

        _desc.openingHours[index] = newData;

        onUpdateData(_desc);
    };

    const editDayOfWeek = (options) => {
        let dayOfWeekAvailable = [...dayOfWeekOptions];

        let defaultDayofWeek = options.value;
        if (!(typeof defaultDayofWeek === 'number')) {
            defaultDayofWeek = options.rowData.dayOfWeek;
        }

        return (
            <>
                <Dropdown
                    value={defaultDayofWeek}
                    options={dayOfWeekAvailable}
                    onChange={(e) => changeDayofWeek(e, options)}
                    filter
                    optionLabel="label"
                    placeholder="Tipo Categoria"
                    listStyle={{ height: '150px', textAlign: 'left' }} />
            </>
        );
    };

    const changeDayofWeek = (event, options) => {
        options.editorCallback(event.value);
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    function onRemove(rowData) {
        let updOffices = normalizeOffices.filter(x => x.id !== rowData.id);
        onUpdateData(updOffices);
    }

    function onCallAddRow() {
        let updNormalizeOffices = [...normalizeOffices]
        if (!updNormalizeOffices) {
            updNormalizeOffices = [];
        }

        let fakeId = Math.floor(Math.random() * 100000);

        updNormalizeOffices.push({
            id: fakeId
        });

        setNormalizeOffices(updNormalizeOffices);
    }

    const dayOfWeekBodyTemplate = (rowData) => {
        let dateOfWeekLabel = "";

        switch (rowData.dayOfWeek) {
            case 0:
                dateOfWeekLabel = "Sunday";
                break;

            case 1:
                dateOfWeekLabel = "Monday";
                break;

            case 2:
                dateOfWeekLabel = "Tuesday";
                break;

            case 3:
                dateOfWeekLabel = "Wedsnday";
                break;

            case 4:
                dateOfWeekLabel = "Thursday";
                break;

            case 5:
                dateOfWeekLabel = "Friday";
                break;

            case 6:
                dateOfWeekLabel = "Saturday";
                break;

        }

        return (
            <div className="flex align-items-center gap-2">
                {t("Calendar:Day:" + dateOfWeekLabel)}
            </div>
        );
    };

    return (
        <>
            <div className="row">

                <div className="form-floating col-6">
                    <InputText id="updName" type="text" value={normalizeOffices.officeName} className="form-control" onChange={(e) => onChangeOfficeData({ ...normalizeOffices, officeName: e.target.value })} />
                    <label htmlFor="updName" class="active">Nome auto</label>
                </div>

                <div className="form-floating col-6">
                    <InputText id="updaddress" type="text" value={normalizeOffices.address} className="form-control" onChange={(e) => onChangeOfficeData({ ...normalizeOffices, address: e.target.value })} />
                    <label htmlFor="updaddress" class="active">Indirizzo</label>
                </div>

                <div className="form-floating col-4">
                    <InputText id="updphone" type="text" value={normalizeOffices.phone} className="form-control" onChange={(e) => onChangeOfficeData({ ...normalizeOffices, phone: e.target.value })} />
                    <label htmlFor="updphone" class="active">Telefono</label>
                </div>

                <div className="form-floating col-4">
                    <InputText id="updemail" type="text" value={normalizeOffices.email} className="form-control" onChange={(e) => onChangeOfficeData({ ...normalizeOffices, email: e.target.value })} />
                    <label htmlFor="updemail" class="active">Email</label>
                </div>

                <div className="form-floating col-4">
                    <InputText id="updFax" type="text" value={normalizeOffices.fax} className="form-control" onChange={(e) => onChangeOfficeData({ ...normalizeOffices, fax: e.target.value })} />
                    <label htmlFor="updFax" class="active">Fax</label>
                </div>

            </div>
            <div className="row">
                <div className="col-12">
                    <h6 className="mt-2">Orari uffici</h6>

                    <DataTable value={normalizeOffices.openingHours}
                        ref={dtRef}
                        dataKey="id"
                        editMode="row"
                        onRowEditComplete={onRowEditComplete}
                        tableStyle={{ minWidth: '50rem' }}>
                        <Column field="dayOfWeek" header="Giorno" body={dayOfWeekBodyTemplate} editor={(options) => editDayOfWeek(options)}></Column>
                        <Column field="opening" header="Orario Apertura" editor={(options) => textEditor(options)}></Column>
                        <Column field="closure" header="Orario Chiusura" editor={(options) => textEditor(options)}></Column>
                        <Column rowEditor={true}></Column>
                        <Column body={bodyDelete}></Column>
                    </DataTable>
                    <div className="col-12 text-right">
                        <button type="button"
                            className="btn bt-sm tp-btn-confirm"
                            onClick={_ => onCallAddRow()}>
                            {t("MyBook:Add")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
