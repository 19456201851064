import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { MenuBarVertical } from '../MenuBarVertical';
import { Loading } from '../../Common/Loading';
import { useTranslation } from 'react-i18next';
import { getCurrentUserFromJwtToken } from '../../../js/Utils.js';
import { QuotationTOLight } from '../QuotationsTO/QuotationTOLight';
import configData from "../../../appsettings.json";

export const Quotations = () => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [totalQuotations, setTotalQuotations] = useState(0);
    const [quotations, setQuotations] = useState([]);

    // filters
    const daysCreatedFromBeforeToday = 90;
    const [quotationId, setQuotationId] = useState();
    const [userId, setUserId] = useState();
    const [quotationName, setQuotationName] = useState();
    const [createdFrom, setCreatedFrom] = useState();
    const [createdTo, setCreatedTo] = useState();

    // paging
    const itemsPerPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(0);


    useEffect(() => {
        const getQuotations = async (inputData) => {
            const response = callGetQuotations(inputData);
        }

        let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
        let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);
        setUserId(currentUser.userId);

        var defaultCreatedFromDate = getDefaultCreatedFromDate();
        setCreatedFrom(defaultCreatedFromDate);

        setIsLoading(true);
        getQuotations({ UserId: currentUser.userId, CreatedFrom: defaultCreatedFromDate, pageNum: 1, pageSize: itemsPerPage, QuotationType: 2 });
    }, []);


    const getDefaultCreatedFromDate = () => {
        var today = new Date();
        var date = today.setDate(today.getDate() - daysCreatedFromBeforeToday);
        var defaultFromBookDate = new Date(date).toISOString().split('T')[0]; // yyyy-mm-dd
        return defaultFromBookDate;
    }

    async function callGetQuotations(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'QuotationTO/GetQuotations', requestOption);
        const response = await fetchedRes.json();

        setTotalQuotations(response.totalQuotations);
        setPageCount(Math.ceil(response.totalQuotations / itemsPerPage));

        setQuotations(response.quotations);

        setIsLoading(false);
    }

    const handleSubmit = async e => {
        e.preventDefault();

        setIsLoading(true);
        setPageSelected(0);

        let inputData = {
            QuotationId: quotationId === '' ? null : quotationId,
            UserId: userId,
            TicketId: null,
            IsAgencyVisible: null,
            Name: quotationName === '' ? null : quotationName,
            Status: null,
            CreatedFrom: createdFrom === '' ? null : createdFrom,
            CreatedTo: createdTo === '' ? null : createdTo,
            PageNum: 1,
            PageSize: itemsPerPage,
            QuotationType: 2 // prendo solo quelli del quotation salvati dal cart
        };

        const response = await callGetQuotations(inputData);
    }

    const handlePageClick = async e => {
        setPageSelected(e.selected);

        let inputData = {
            QuotationId: quotationId === '' ? null : quotationId,
            UserId: userId,
            TicketId: null,
            Name: quotationName === '' ? null : quotationName,
            Status: null,
            CreatedFrom: createdFrom === '' ? null : createdFrom,
            CreatedTo: createdTo === '' ? null : createdTo,
            PageNum: e.selected + 1,
            PageSize: itemsPerPage,
            QuotationType: 2
        };

        const response = await callGetQuotations(inputData);
        window.scrollTo(0, 0);
    };

    function onChangeCreateFrom(e) {
        setCreatedFrom(e.target.value)
    }
    
    function onChangeCreateTo(e) {
        setCreatedTo(e.target.value)
    }

    return (
        <>
            <MenuBarVertical activeAnchorId="aQuotations" />

            <div className="section section-lg bg-gray-200 page-content" id="content">
                <div className="container">
                    {/*Filter Orders*/}
                    <div className="card border-light p-0 p-md-4 mb-4">
                        <div className="row">
                            {/*CreatedFrom*/}
                            <div className="col-12 col-lg-3">
                                <span style={{ fontSize: '12px' }}> {t("MyBook:CreatedFrom")}</span> <br />
                                <div className="input-group input-group-md mb-3 mb-lg-0">
                                    <input className="form-control datepicker" placeholder="Creato dal" type="date" value={createdFrom} onChange={e => onChangeCreateFrom(e)} required />
                                </div>
                            </div>

                            {/*CreatedTo*/}
                            <div className="col-12 col-lg-3">
                                <span style={{ fontSize: '12px' }}>{t("MyBook:To")}</span> <br />
                                <div className="input-group input-group-md mb-3 mb-lg-0">
                                    <input className="form-control datepicker" placeholder="Creato al" type="date" value={createdTo} onChange={e => onChangeCreateTo(e)} required />
                                </div>
                            </div>

                            {/*QuotationName*/}
                            <div className="col-12 col-lg-4">
                                <br />
                                <div className="input-group input-group-md mb-3 mb-lg-0">
                                    <input className="form-control" placeholder={t("MyBook:Name")} type="text" onChange={e => setQuotationName(e.target.value.trim())} required />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="ph-file-text"></i></span>
                                    </div>
                                </div>
                            </div>

                            {/*Search Button*/}
                            <div className="col-12 col-lg-1">
                                <br />
                                <button className="tp-btn-login btn-sm mb-3 mt-md-0 animate-up-2" type="submit" onClick={handleSubmit}>
                                    {t("MyBook:Search")}
                                </button>
                            </div>

                            {/*QuotationId*/}
                            <div className="col-12 col-lg-3">
                                <br />
                                <div className="input-group input-group-md mb-3 mb-lg-0">
                                    <input className="form-control" placeholder={t("MyBook:NQuotation")} type="text" onChange={e => setQuotationId(e.target.value.trim())} required />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="ph-file-text-light"></i></span>
                                    </div>
                                </div>
                            </div>

                            {/*Status*/}
                            <div className="col-12 col-lg-3">
                                <br />
                                &nbsp;
                            </div>

                            <div className="col-12 col-lg-4">
                                <br />
                                &nbsp;
                            </div>
                            <div className="col-12 col-lg-1">
                                <br />
                                &nbsp;
                            </div>
                        </div>
                    </div>

                    {/*Quotations*/}
                    {isLoading
                        ? (<Loading textMsg={t('Loading:Quotation')} />)
                        : (
                            <>
                                {quotations !== undefined && quotations !== null && quotations.length > 0
                                    ? (
                                        quotations.map((quotation, index) => {
                                            return <QuotationTOLight key={index} quotation={quotation} isQuotationTO={false} />
                                        })
                                    ) : (
                                        <h6>{t("MyBook:NothingQuotationFound")}.</h6>
                                    )
                                }
                            </>
                        )
                    }

                    <nav aria-label="Page navigation comments" className="mt-4">
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={t('pagination:nextLabel')}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel={t('pagination:previousLabel')}
                            renderOnZeroPageCount={null}
                            forcePage={pageSelected}

                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            marginPagesDisplayed={2}
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            activeClassName="active"
                        />
                    </nav>
                </div>
            </div>
        </>
    );
}
