import React from 'react';
import { useTranslation } from 'react-i18next';

export const TourDetailCategoryInfo = ({ tourId, tourDetail, activityInfo }) => {
    const { t } = useTranslation();

    return (
        <>
            <p className="h6"><data m3lab="Structure.Service">Info:</data></p>
            <div className="card bg-gray-100 table small text-muted w-100">
                <div className="row p-2">
                    {
                        tourDetail.durationTime && tourDetail.durationTime > 0 ? <div className="col-12 col-sm-6 col-md-6 col-lg-6 py-1">
                            <span className="p-2 small">
                                <label className="h6 mr-1"><data m3lab="Product.Activity.Duration">{t('Product:Activity:Duration')}: </data></label>
                                <var>{tourDetail.durationTime && tourDetail.durationTime > 0 ? tourDetail.durationTime + " " + t('Product:Tour:Filter:Nights')  : "-"}</var>
                            </span>
                        </div> : <></>
                    }
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 py-1">
                        <span className="p-2 small">
                            <label className="h6 mr-1"><data m3lab="Product.Activity.Code">{t('Product:Activity:Code')}: </data></label>
                            <var>{tourDetail.tourId ? tourDetail.tourId : tourId}</var>
                        </span>
                    </div>
                   
                    {
                        tourDetail.generalCategory && tourDetail.generalCategory.length > 0 && <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-1">
                            <div className="small h6 mb-0">
                                <data m3lab="Product.Tour.Services">{t('Product:Tour:Services')}: </data>
                            </div>
                            {
                                tourDetail.generalCategory.map((category, key) => {
                                    return <div key={key} className="my-0 py-0"><label><var>{category.typeName + " - " + category.name}</var></label></div>;
                                })
                            }
                        </div>
                    }
                </div>
            </div>
        </>
    );
}