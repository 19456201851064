import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { DMCExtras } from '../../Common/DMC/DMCExtras';
import { DMCPriceBreakDown } from '../../Common/DMC/DMCPriceBreakDown';
import { ProductAllPrice } from '../../Common/Pricing/ProductAllPrice';
import { ProductCalendarTime } from '../../Common/ProductCalendarTime';
import { getDisplayPrice, formatDateTime, getDisplayPriceLabel } from '../../../../js/Utils.js';
import { M3Icon } from '../../../Common/M3Icon';
import { ProductNotAllowedAddToCart } from '../../Common/ProductNotAllowedAddToCart';

export const ActivityDetailOption = ({ option, showPriceBar, showCost, enableShowPriceBreakDown, enableCanAddToCart, onBook, templateInfo, quotationInfo }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    const [optionPricing, setOptionPricing] = useState({});
    const [modalId, setModalId] = useState('infoCamera1-1');

    const [labelPagamento, setLabelPagamento] = useState("");
    const [labelCancelPolicy, setLabelCancelPolicy] = useState("");
    const [classCancelPolicy, setClassCancelPolicy] = useState("");

    const [selectTime, setSelectTime] = useState(null);
    const [selectAddictionalOption, setSelectAddictionalOption] = useState(null);
    const [selectExtras, setSelectExtras] = useState([]);

    const [addictionalOption, setAddictionalOption] = useState(null);
    useEffect(() => {
        setOptionPricing(option.pricing);
        setModalId('infoCamera1-' + option.id);

        let defaultSelectTime = '';
        let defaultAddictionalOptionId = '';
        if (option.additionalOptionsTime && option.additionalOptionsTime.length > 0) {
            let selectedTime = option.additionalOptionsTime.filter(x => x.isSelected);
            if (selectedTime && selectedTime.length > 0) {
                defaultSelectTime = selectedTime[0].label;
                defaultAddictionalOptionId = selectedTime[0].value;
            }
            else {
                defaultSelectTime = option.additionalOptionsTime[0].label;
                defaultAddictionalOptionId = option.additionalOptionsTime[0].value;
            }

            setSelectTime(defaultSelectTime);
        }

        getCancelPolicy();
        if (option.additionalOptions && option.additionalOptions.length > 1)
            filterAddictionalOptionByTime(defaultSelectTime);
        else
            setSelectAddictionalOption(defaultAddictionalOptionId);

    }, [option])

    function getCancelPolicy() {
        if (!option || !option.cancellationPolicy)
            return;

        if (option && option.cancellationPolicy.isFree) {
            setLabelPagamento(t(`CancelPolicy:PayAfter`));
            setLabelCancelPolicy(t(`CancelPolicy:RefundableBeforeDate`) + formatDateTime(option.cancellationPolicy.expirationDate, cultureName));
            setClassCancelPolicy("text-success");
        }
        else {
            setLabelPagamento(t(`CancelPolicy:PayNow`));
            setLabelCancelPolicy(t(`CancelPolicy:NotRefundable`));
            setClassCancelPolicy("text-danger");
        }
    }

    function onSelectAddictionalTime(addOptionSelected) {
        setSelectTime(addOptionSelected.label);
        setSelectAddictionalOption(null);

        if (option.additionalOptions && option.additionalOptions.length > 1)
            filterAddictionalOptionByTime(addOptionSelected.label);
        else {
            setOptionPricing(addOptionSelected.pricing);
            setSelectAddictionalOption(addOptionSelected.value);
        }

    }

    function filterAddictionalOptionByTime(defaultSelectTime) {
        if (!option.additionalOptions && option.additionalOptions.length > 1)
            return;

        // setto il default selezionato, succede solo la prima volta
        if (!defaultSelectTime)
            defaultSelectTime = selectTime;

        // potrebbe essere un activity senza orario
        if (!defaultSelectTime)
            setAddictionalOption(option.additionalOptions);

        let fltadditionalOptions = [];
        let defaultAddictionalOptionSelected = '';
        for (let i = 0; i < option.additionalOptions.length; i++) {
            let aopt = option.additionalOptions[i];
            if (aopt.validValues && aopt.validValues.includes(defaultSelectTime)) {
                fltadditionalOptions.push(aopt);
                if (aopt.isSelected)
                    defaultAddictionalOptionSelected = aopt;
            }

        }

        setAddictionalOption(fltadditionalOptions);

        if (!defaultAddictionalOptionSelected && fltadditionalOptions.length > 1)
            defaultAddictionalOptionSelected = fltadditionalOptions[0];

        setSelectAddictionalOption(defaultAddictionalOptionSelected);

        if (defaultAddictionalOptionSelected.pricing)
            setOptionPricing(defaultAddictionalOptionSelected.pricing);
    }

    function onSelectAddictionalOption(addOptionSelected) {
        setSelectAddictionalOption(addOptionSelected);
        setOptionPricing(addOptionSelected.pricing);
    }

    function onSelectExtra(extra, isSelected) {
        let updateArray = [...selectExtras];
        let updateOptionPricing = {...optionPricing};

        if (!isSelected) {
            if (extra.amount)
                updateOptionPricing.displayPrice -= extra.amount;

            updateArray = updateArray.filter(x => x.id !== extra.id);
        } else {
            if (extra.amount)
                updateOptionPricing.displayPrice += extra.amount;

            updateArray.push(extra);
        }

        setSelectExtras(updateArray);
        setOptionPricing(updateOptionPricing);
    }

    function onClickBook() {
        let normExtras = [];
        if (selectExtras && selectExtras.length > 0)
            normExtras = selectExtras.map(x => x.id);

        let item = {
            optionCode: option.id,
            selectTime: selectTime,
            SelectAddictionalOptionId: selectAddictionalOption,
            selectExtraIds: normExtras
        };

        onBook(item);
    }

    return (
        <>
            <div className="row mx-0">
                <div className="col-12 p-1">
                    <div className="card border-light bg-gray-100 small animate-up-5 mb-2">
                        <div className="row small ">
                            <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 h6 px-2 m-0 pl-3 pt-2">
                                <var>{option.name}</var>
                            </div>
                            <div className="col-6 col-sm-1 col-md-1 col-lg-1 col-xl-1 pl-0 pt-2">
                                {
                                    option.notes && <>
                                        <data m3lab="Structure.Note">{t('Structure:Note')}</data>
                                        <button className="btn p-0" data-bs-toggle="modal" data-bs-target={"#" + modalId}>
                                            <data m3ico="info text-gray-500 icon-10">
                                                <M3Icon iconName="Info" externalClass="text-gray-500 icon-10" />
                                            </data>
                                        </button>
                                    </>
                                }
                            </div>
                            <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-0 text-right m-0">
                                <div className="h4 p-2 mb-0">
                                    <span className="mt-0">
                                        <var>{getDisplayPrice(optionPricing, cultureName, showCost, templateInfo)}</var>
                                    </span>
                                </div>
                                <div className="">
                                    {t(getDisplayPriceLabel(showCost, optionPricing.isCommisionable))}
                                </div>
                                {option.status && option.status === 'OnRequest' && <div className="text-info">OnRequest</div>}
                                <span className="text-warning mx-1">{option.supplier && option.supplier.supplierName}</span>
                                <ProductAllPrice
                                    pricing={optionPricing}
                                    showCost={showCost}
                                    showPriceBar={showPriceBar} />
                            </div>
                            <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 x-2 m-0 pl-3 pt-2">
                                <span>{labelCancelPolicy}</span>
                            </div>
                            <div className="col-6 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-md-0 pt-2">
                                <span className={classCancelPolicy} >{labelPagamento}</span>
                            </div>
                            <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-0 text-right m-0">
                                {
                                    (templateInfo || quotationInfo || (enableCanAddToCart && enableCanAddToCart.allowed)) &&
                                    <button className="btn-sm tp-btn-outline-book float-end m-0 px-2 py-1 mb-1" onClick={(e) => onClickBook()}>
                                        <data m3lab="Button.Book">
                                                {!templateInfo && !quotationInfo && <span dangerouslySetInnerHTML={{ __html: t(`Button:AddToCart`) }}></span>}
                                            {templateInfo && t(`Button:Select`)}
                                            {quotationInfo && !quotationInfo.quotationItemIdToChange && t(`Button:AddQuotation`)}
                                            {quotationInfo && quotationInfo.quotationItemIdToChange && t(`Button:ReplaceQuotation`)}
                                        </data>
                                    </button>
                                }
                                <ProductNotAllowedAddToCart enableCanAddToCart={enableCanAddToCart} />
                            </div>
                            {
                                enableShowPriceBreakDown && option.priceBreakDown && option.priceBreakDown.length > 0 && <DMCPriceBreakDown
                                    priceBreakDownArr={option.priceBreakDown}
                                    idAccordion={"PBD_" + option.id} />
                            } 
                            {
                                option.extras && option.extras.length > 0 && <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="row my-2 bg-white mx-1 rounded">
                                        <div><data m3lab="Product.Activity.AddictionalOptions">{t('Product:Activity:AddictionalOptions')}:</data></div>
                                        {
                                            option.extras.map((extra, key) => {
                                                return <React.Fragment key={key}>
                                                    <DMCExtras extra={extra} onSelect={onSelectExtra} />
                                                </React.Fragment>
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                addictionalOption && addictionalOption.length > 1 && <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="row my-2 bg-white mx-1 rounded">
                                        <div>
                                            {
                                                addictionalOption[0].renderType === 'Language'
                                                    ? <><data m3lab="Product.Activity.Languages">{t('Product:Activity:Languages')}</data></>
                                                    : <span className="mr-2"><data m3lab="Product.Activity.AddictionalOptions">{t('Product:Activity:AddictionalOptions')}:</data></span>
                                            }
                                            {
                                                addictionalOption.map((ao, key) => {
                                                    return ao.renderType === 'Default'
                                                        ? <React.Fragment key={key}>
                                                            <button className={"btn" + (selectAddictionalOption && selectAddictionalOption.value === ao.value ? " tp-btn-select" : "")} onClick={(e) => { onSelectAddictionalOption(ao) }}>
                                                                {ao.label}
                                                            </button>
                                                        </React.Fragment >
                                                        : <React.Fragment key={key} > <button className="btn" onClick={(e) => { onSelectAddictionalOption(ao) }}>
                                                            <img className=" img-thumbnail card-img-bottom w-35" src="https://cdn.travelplace.ch/common/images/ico/language/it-it.png" />
                                                            <div className="small">TODO Italiano</div>
                                                        </button>
                                                            <button className="btn">
                                                                <img className=" img-thumbnail card-img-bottom w-35" src="https://cdn.travelplace.ch/common/images/ico/language/en-en.png" />
                                                                <div className="small">Inglese</div>
                                                            </button>
                                                        </React.Fragment >
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                option.additionalOptionsTime && <ProductCalendarTime
                                    productTimes={option.additionalOptionsTime}
                                    onSelectTime={onSelectAddictionalTime}
                                    imageUrl="https://media.activitiesbank.com/38027/ENG/XL/38027_1.jpg" />
                            }
                            {
                                option.notes && <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby={modalId} aria-hidden="true">
                                    <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="infoCamera1-1">
                                                    <data m3lab="Structure.Note">{t('Structure:Note')}</data>
                                                </h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <div dangerouslySetInnerHTML={{ __html: option.notes }}></div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-gray-300" data-bs-dismiss="modal">
                                                    <data m3lab="Button.Chiudi">{t("Button:Chiudi")}</data>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}