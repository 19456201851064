import React, { useState } from 'react';
import { AddContact } from './AddContact';
import { useTranslation } from 'react-i18next';


export const Contact = ({ contact, cultureName, onSaveSuccess }) => {
    const { t } = useTranslation();
    const [isUpdateMode, setIsUpdateMode] = useState(false);

    function onSaveSuccessContact() {
        onCloseAddContactPanel();
        onSaveSuccess();
    }

    function onCloseAddContactPanel() {
        setIsUpdateMode(false);
    }

    return (
        <>
            {
                !isUpdateMode
                    ? <li className="list-group-item border-bottom">
                        <div className="row align-items-center">
                            <div className="col-2">{contact.name}</div>
                            <div className="col-3">{contact.surname}</div>
                            <div className="col-3">{contact.email}</div>
                            <div className="col-2">{contact.mobile}</div>
                            <div className="col-2 text-rigth">
                                <button className="btn tp-btn-add btn-sm w-75" onClick={e => setIsUpdateMode(!isUpdateMode)}>
                                    <span className="material-icons-outlined">edit</span> {t("MyBook:Modify")}
                                </button>
                            </div>
                            {!contact.userId && <div className="col-12 text-warning">{t("MyBook:ContactNotAssociated")}</div>}
                        </div>
                    </li>
                    : <AddContact contact={contact} onSaveSuccess={onSaveSuccessContact} onClosePanel={onCloseAddContactPanel} />
            }
        </>
    );
}