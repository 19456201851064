import React from 'react';
import { formatDateTime, formatPrice } from '../../../js/Utils.js';
import { M3IconProductType } from '../../Common/M3IconProductType.js';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";

export const QuotationRecapItemADV = (props) => {
    const { t } = useTranslation();
    function getNumNights(item){
        if (item.productType === "Structure" && item.dateFrom && item.dateTo) {
            var dateFrom = new Date(item.dateFrom);
            var dateTo = new Date(item.dateTo);
            var timeDiff = Math.abs(dateTo.getTime() - dateFrom.getTime());
            return Math.ceil(timeDiff / (1000 * 3600 * 24));
        }
    }

    return (
        <>
            <div key="quoationRecapItem" className="card border-gray-300 mb-4">
                <div className="p-4">
                    <div className="">
                        <table className="table-hover p-0 h075 w-100">
                            <thead>
                                <tr key="header_recapItem">
                                    <th scope="col">{t("MyBook:Type")}</th>
                                    <th scope="col">{t("MyBook:Product")}</th>
                                    <th scope="col">{t("MyBook:Period")}</th>
                                    <th scope="col">{t("Product:Structure:Nights")}</th>
                                    {!configData.Settings.QuotationTO.HideSaleRecapItem && <th scope="col">{t("MyBook:Sale")}</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.recapItems && props.recapItems.map((item, key) => {
                                        return <React.Fragment key={key}>
                                            {
                                                (key === 0 || item.step !== props.recapItems[key - 1].step) && <tr className="bg-light p-0">
                                                    <td colSpan="8">
                                                        {item.step > -1 && <>Step {item.step} {item.stepName && <span> - {item.stepName}</span>}</>}
                                                        {item.step === -1 && t("MyBook:StepAssuranceText")}
                                                        {item.step === -2 && t("MyBook:DiscountCommercialText")}
                                                    </td>
                                                </tr>
                                            }
                                            <tr className={"p-0" + !(item.wsBookingId ? "" : " bg-gray-200")}>
                                                <th scope="row">
                                                    <M3IconProductType productType={item.productType} productSubtype={item.productSubType} externalClass={!item.wsBookingId ? "text-gray-300" : "text-dark"} />
                                                </th>
                                                <td className="td-product-name">
                                                    <div className="d-flex align-items-lg-start">
                                                        {item.productName}
                                                    </div>
                                                </td>
                                                <td>
                                                    {
                                                        item.productType !== "Generic" && <>
                                                            <div>{formatDateTime(item.dateFrom, props.cultureName, { twoDigits: true })}</div>
                                                            {
                                                                item.dateTo
                                                                    ? <div>{formatDateTime(item.dateTo, props.cultureName, { twoDigits: true })}</div>
                                                                    : <></>
                                                            }
                                                        </>
                                                    }
                                                </td>
                                                <td>
                                                    <div className="h6">{getNumNights(item)}</div>
                                                </td>
                                                {!configData.Settings.QuotationTO.HideSaleRecapItem &&
                                                    <td>
                                                        <div className="h6">{formatPrice(item.sellAmount, item.sellCurrency, props.cultureName)}</div>
                                                    </td>
                                                }
                                            </tr>
                                        </React.Fragment>;
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}