import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { M3Icon } from '../../../Common/M3Icon';
import { getDisplayPrice } from '../../../../js/Utils.js';
import { openNewWindowTrain } from '../../../../js/ProductService.js';

export const TrainFarePivotExtended = ({ fare, farePivotRowHeader, index, selectFares, showCost, templateInfo, onSelectFares, onChangeShowFares }) => {
    const { t } = useTranslation();

    const cookies = new Cookies();
    const [cultureName] = useState(cookies.get("CultureName"));

    const maxItemsToShow = 8;
    const [showItems, setShowItems] = useState(false);

    function renderPivotOffertId(fare, offertId) {
        let offerName = offertId;
        let offertLink = '';
        if (fare.offerList) {
            let offer = fare.offerList.filter(x => x.id === offertId);
            if (offer && offer.length > 0) {
                offerName = offer[0].name;
                offertLink = offer[0].descriptionLink;
            }

        }

        return <>
            {offerName.replace('_', ' ')}
            <span className="d-none">{offertId}</span>
            {
                offertLink && <button className="btn py-1  p-0" onClick={_ => openNewWindowTrain(offertLink)}>
                    <data m3ico="info text-gray-500 icon-10">
                        <M3Icon iconName="Info" externalClass="text-gray-500 icon-10" />
                    </data>
                </button>
            }
        </>;
    }

    function renderPivotServiceId(fare, serviceId) {
        let serviceName = serviceId;
        let serviceLink = '';
        if (fare.serviceList) {
            let service = fare.serviceList.filter(x => x.id === serviceId);
            if (service && service.length > 0) {
                serviceName = service[0].name;
                serviceLink = service[0].descriptionLink;
            }
        }

        return <>
            {
                serviceLink && <button className="btn p-0" onClick={_ => openNewWindowTrain(serviceLink)}>
                    <data m3ico="info text-gray-500 icon-10">
                        <M3Icon iconName="Info" externalClass="text-gray-500 icon-10" />
                    </data>
                </button>
            }

            <div>{serviceName} <span className="d-none">{serviceId}</span></div>
        </>;
    }

    function isSelectedFare(rowComponent, key, selectFares) {
        let className = '';

        if (!rowComponent)
            return '';

        if (selectFares && selectFares.length >= (key + 1)) {
            if (selectFares[key].fareComponents.uniqueId === rowComponent.uniqueId)
                className = 'bg-blue text-white rounded shadow';
        }

        return className;
    }

    function isAvailableSelectedButton(rowComponent, key, selectFares) {
        let className = 'btn-neutral';

        if (!rowComponent)
            return className;

        if (selectFares && selectFares.length >= (key + 1)) {
            if (selectFares[key].fareComponents.uniqueId === rowComponent.uniqueId)
                className = 'text-white';
        }

        return className;
    }

    function isSelectService(serviceId, key, selectFares) {
        let className = '';

        if (selectFares && selectFares.length >= (key + 1)) {
            if (selectFares[key].fareComponents.serviceTypeId === serviceId)
                className = 'bg-blue text-white fw-bold';
        }

        return className;
    }

    function isSelectOffert(offertId, key, selectFares) {
        let className = '';

        if (selectFares && selectFares.length >= (key + 1)) {
            if (selectFares[key].fareComponents.offerTypeId === offertId)
                className = 'bg-blue text-white fw-bold';
        }

        return className;
    }

    function handleOnSelectFares(service, key) {
        onSelectFares(service, key, fare.fareArgs);
    }

    function showMoreFares() {
        onChangeShowFares(1);
    }

    return (
        <>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="d-table text-center">
                    <div className="d-table-row">
                        <div className="d-table-cell w-15"></div>
                        {
                            farePivotRowHeader && farePivotRowHeader.slice(0, maxItemsToShow).map((serviceId, keyService) => {
                                return <div key={keyService}
                                    className={"d-table-cell w-15 rounded-top border-top border-right border-left border-2 h075 px-1 py-2 " + isSelectService(serviceId, index, selectFares)}  >
                                    {renderPivotServiceId(fare, serviceId)}
                                </div>
                            })
                        }
                        {
                            farePivotRowHeader && farePivotRowHeader.length > maxItemsToShow && <div className="d-table-cell w-15 rounded-top border-top border-right border-left border-2 h075 px-1 py-2 " >
                                <button className="tp-btn-outline-select p-1 rounded border-0 shadow" onClick={_ => showMoreFares(null)}>Show More</button>
                            </div>
                        }
                    </div>
                    <div className="d-table-row mb-2" style={{ height: '0.25rem' }}> <div className="d-table-cell w-15"></div></div>
                    {
                        fare.farePivot && fare.farePivot.map((pivot, keyPivot) => {
                            return <React.Fragment key={keyPivot}>
                                <div className="d-table-row">
                                    <div className="d-table-cell w-20 text-left py-1 pr-1">
                                        <div className={"rounded-start fw-bold bg-gray-200 px-1 py-1 " + isSelectOffert(pivot.offertId, index, selectFares)}>
                                            {renderPivotOffertId(fare, pivot.offertId)}
                                        </div>
                                    </div>
                                    {
                                        pivot.rows && pivot.rows.slice(0, maxItemsToShow).map((row, keyRow) => {
                                            return <div key={keyRow} className={"d-table-cell w-15 p-2 m-0 " + isSelectedFare(row.fareComponents, index, selectFares)} >
                                                {
                                                    row.fareComponents && row.fareComponents.pricing && <button
                                                        className={"btn w-100 h075 p-1 py-2 " + isAvailableSelectedButton(row.fareComponents, index, selectFares)}
                                                        onClick={_ => handleOnSelectFares(row.fareComponents, index)}>
                                                        {getDisplayPrice(row.fareComponents.pricing, cultureName, showCost, templateInfo)}
                                                    </button>
                                                }
                                                {
                                                    (!row.fareComponents || !row.fareComponents.pricing) && <button className="btn-neutral btn w-100 h075 p-1 py-2 disabled">
                                                        {t('Product:Train:FareNotAvailableAbbr')}
                                                    </button>
                                                }
                                            </div>
                                        })
                                    }
                                </div>
                            </React.Fragment>
                        })
                    }

                </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <label>{t('Product:Train:SelectFareMessagge')}</label>
            </div>
        </>
    );
}