import React, { useState } from 'react';
import { imageOnError } from '../../../../js/CDNUtility';
import { useEffect } from 'react';
import configData from "../../../../appsettings.json";


export const GalleryList = ({ productId, defaultImage, gallery, alternativeText, onClickSearch, productType }) => {

    const [imgClass, setImgClass] = useState('img-corusel-250');

    function goToDetail() {
        onClickSearch(productId);
    }

    useEffect(() => {
        if (productType === configData.Settings.Products.CarRental.IdTipoProdotto) {
            setImgClass('img-corusel-180')
        }
    }, []);

    return (
        <div id={"carouselExampleControlsNoTouching" + productId} className="carousel slide cursor-pointer" data-bs-touch="false" data-bs-interval="false" onClick={(e) => goToDetail()}>
            <div className="carousel-inner rounded">
                <div className="carousel-item mh-250 text-center active">
                    <img src={defaultImage ? defaultImage : ""} className={"d-block flex-shrink-0 " + imgClass} alt={alternativeText} onError={e => imageOnError(e, productType)} />
                </div>
                {
                    gallery && gallery.map((image, key) =>
                        <div key={key} className="carousel-item mh-250">
                            <img src={image} className={"d-block flex-shrink-0 " + imgClass} alt={alternativeText} onError={e => imageOnError(e, productType)} />
                        </div>
                    )}
            </div>
            {
                gallery && gallery.length > 1 && <>
                    <button className="carousel-control-prev" type="button" data-bs-target={"#carouselExampleControlsNoTouching" + productId} data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target={"#carouselExampleControlsNoTouching" + productId} data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </>
            }
        </div>
    );
}