import React from 'react';
import { useTranslation } from 'react-i18next';
import { CruiseBaseInfo } from './CruiseBaseInfo';
import { CruiseItinerary } from './CruiseItinerary';
import { M3Icon } from '../../../Common/M3Icon'

export const CruiseDetailStaticData = ({ cruiseData }) => {
    const { t } = useTranslation();

    return (
        <div className="row px-1">
            <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                <div className="h6">
                    <M3Icon iconName="Place" externalClass="icon-15" />
                    <var>{cruiseData.countriesTouch}</var>
                </div>
                <div className="h4"><var>{cruiseData.name}</var></div>
            </div>
            <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                <img src={cruiseData.providerLogoUrl} />
            </div>
            <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                <CruiseItinerary itinerary={cruiseData.itinerary} />
            </div>
            <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                <CruiseBaseInfo cruiseData={cruiseData} />
            </div>
        </div>
    );
}