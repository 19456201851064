import React, { useEffect } from 'react';
import configData from "../../../appsettings.json";
import { useSearchParams } from "react-router-dom";
import { handleError } from "../../../js/Utils";

export const JointlyPayNotify = () => {
    const [searchParams] = useSearchParams();

    let externalCode = searchParams.get("jointly_token");
    let voucherCode = searchParams.get("jointly_voucher_code");
    let externalStatus = searchParams.get("jointly_status");

    useEffect(() => {
        callJointlyPayNotify();
    }, []);

    function callJointlyPayNotify() {

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}paymentgateway/JointlyPayNotify/${externalCode}/${voucherCode}/${externalStatus}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
            })
            .catch((err) => {
                console.log(err);
            });

    }

    return (
        <div className="row text-center">
            <div className="col-12 penalty">
            </div>
        </div>
    );
}
