import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';


export const PrintTitleSeparator = ({ title }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    
    return (
        <>
            <table className="w-100 mt-5">
                <tbody>
                    <tr>
                        <td className="h3 w-40">
                            {title}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}