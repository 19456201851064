import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Loading } from '../Common/Loading';
import { M3Icon } from '../Common/M3Icon';
import { Passenger } from './Passenger';
import { PriceBar } from './PriceBar';
import { CancelReservationModal } from './OperationModals/CancelReservationModal';
import { OptionConfirmModal } from './OperationModals/OptionConfirmModal';
import { PayWithCreditCardModal } from './OperationModals/PayWithCreditCardModal';
import { OperationButtons } from './OperationButtons';
import { QuotationOperationButtons } from '../QuotationTO/components/QuotationOperationButtons';
import { formatPrice, formatDateTime } from '../../js/Utils.js';
import configData from "../../appsettings.json";
import { imageOnError, imageOnLoadCruise } from '../../js/CDNUtility';
import { renderWebsite } from '../../js/Constant';
import { Error } from '../Common/Error';
import { useTranslation } from 'react-i18next';
import { ServiceNote } from './ServiceNote';
import { CruiseDetailCabinCategoryPriceTable } from '../Product/Cruise/components/CruiseDetailCabinCategoryPriceTable';
import { CruiseDetailCabinCategoryServices } from '../Product/Cruise/components/CruiseDetailCabinCategoryServices';
import { UpdateCruiseData } from '../MyBook/ProductData/components/UpdateCruiseData';

export const CruiseDetail = ({ id, type, showNetPrices, showCost, customFunctions, forceRender, saveProductData }) => {
    const { t } = useTranslation();

    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [cruiseDetail, setCruiseDetail] = useState([]);
    const [panelId, setPanelId] = useState(id);

    const [modalId, setModalId] = useState("");
    const [resetValue, setResetValue] = useState(1);
    const [isEnabledUpdateStaticData, setIsEnabledUpdateStaticData] = useState(false);

    useEffect(() => {
        setResetValue(1);
        loadItem();

        setModalId("modalUpdateStaticDAta_" + id)
    }, []);

    useEffect(() => {
        if (forceRender && forceRender > 0) {
            loadItem();
            setResetValue(1);
        }
    }, [forceRender]);

    function loadItem() {
        setIsLoading(true);
        switch (type) {
            case 'MyBook':
            case 'QuotationTO_MyBook':
                const getCruiseDetail = async (inputData) => {
                    const response = callGetCruiseDetail(inputData);
                }
                getCruiseDetail({ PNR: id, ProductType: configData.Settings.Products.Cruise.IdTipoProdotto, Options: { WebsiteBaseUrl: configData.Settings.OldWebSite_BaseUrl } });
                break;
            case 'QuotationTO':
                const getQuotationItem = async (inputData) => {
                    const response = callGetQuotationItem(inputData);
                }
                getQuotationItem(id);
                break;
            default:
                setIsLoading(false);
        }
    }

    async function callGetCruiseDetail(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetReservationDetail', requestOption);
        if (fetchedRes.ok) {

            const response = await fetchedRes.json();

            if (type === 'QuotationTO_MyBook' || type === 'MyBook')
                customFunctions.onCallSaveProductInfo(response, type, id)

            setCruiseDetail(response);
            setIsError(false);

        } else {
            setErrorMsg(t("MyBook:PracticeNotAvailableMsg").replace("##ID##", id));
            setIsError(true);
        }

        setIsLoading(false);
    }

    async function callGetQuotationItem(id) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationitem/${id}`, requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            customFunctions.onCallSaveProductInfo(response, type, id);

            setCruiseDetail(response);
            setIsError(false);

            if (response.allowedOperations && response.allowedOperations.EnabledUpdateStaticData)
                setIsEnabledUpdateStaticData(response.allowedOperations.EnabledUpdateStaticData.enabled && (type !== 'QuotationTOEndUser' && type !== 'QuotationEndUser'));
        } else {
            setErrorMsg(t("MyBook:QuotaionProductNotLoadMsg").replace("##ID##", id));
            setIsError(true);
        }

        setIsLoading(false);
    }

    function formatItineraryDate(datetime) {
        let myDate = new Date(datetime);
        return new Intl.DateTimeFormat(cultureName, { month: "long", day: "2-digit", weekday: "short" }).format(myDate);
    }

    const renderPassengerCostCharges = (paxName, charges) => {
        return (
            charges.map((charge, index) =>
                <div key={index} className="row no-gutters mybook-flight RTable">
                    <div className="col-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">{paxName}</div>
                    <div className="col-1 col-sm-3 col-md-3 col-lg-3 col-xl-3">{charge.chargeType}</div>
                    <div className="col-1 col-sm-3 col-md-3 col-lg-3 col-xl-3">{formatPrice(charge.amount, charge.currency, cultureName)}</div>
                </div>
            )
        );
    }

    const renderPassengerCosts = (paxesPriceDetails) => {
        return (
            paxesPriceDetails.map(paxesPriceDetail =>
                renderPassengerCostCharges(paxesPriceDetail.paxName, paxesPriceDetail.charges)
            )
        );
    }

    function onClickOpenUpdateStaticData() {
        try {
            setResetValue((resetValue + 1));
        } catch (ex) { }
    }

    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : isError ? <Error textMsg={errorMsg} />
                    : (
                        <>
                            <div id={'aDetail_' + id}></div>

                            {/*Vertical Left Icon Bar*/}
                            {
                                type === 'MyBook'
                                    ? <div className="col-12 col-sm-10 col-md-6 col-lg-1 mb-4">
                                        <div className="timeline timeline-one timeline-mybook h-100">
                                            <div className="timeline-item h-100">
                                                <span className="icon icon-success"><i className="ph-boat-bold h2"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                            }

                            {/*Cruise*/}
                            <div className={type === 'MyBook' ? "col-12 col-sm-10 col-md-6 col-lg-11 mb-4" : ""}>
                                <div className={type === 'MyBook' ? "card border-light animate-up-5" : "card border-light mb-4 animate-up-5"}>
                                    <div className="row no-gutters">
                                        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                                            {/*Testata Cruise*/}
                                            <div className="row  no-gutters ">
                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                    <img src={cruiseDetail.shipImageUrl ? cruiseDetail.shipImageUrl : imageOnLoadCruise()} alt="Thumb" className="card-img p-2 rounded-xl" onError={e => imageOnError(e, configData.Settings.Products.Cruise.IdTipoProdotto)} />
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                    <div className="myBook-card-body">
                                                        <div className="mybook-hotel">
                                                            <h5>
                                                                {
                                                                    (type === 'QuotationTO' || type === 'QuotationTO_MyBook')
                                                                    && (customFunctions && !customFunctions.isAgencyMode)
                                                                    && (cruiseDetail.quotationDetail && cruiseDetail.priceBar.isManualLoading)
                                                                    && <span className="material-icons-outlined mx-1" title="Prodotto manuale" style={{ fontSize: '16px' }}>front_hand</span>
                                                                }
                                                                {
                                                                    type === 'QuotationTO' && <span className="badge bg-gray-300 text-dark mr-2">{id}</span>
                                                                }
                                                                {cruiseDetail.itineraryName} <a className="ico info" style={{ display: 'none' }}><i className="fas fa-info-circle"></i></a>
                                                            </h5>
                                                            <p className="my-2">
                                                                <span><i className="ph-map-pin-fill"></i>{cruiseDetail.shipName}</span>
                                                            </p>
                                                            <div className="row cruise-itinerary-summary">
                                                                <div className="col-4">
                                                                    <div className="small mb-1">Porto di Partenza</div>
                                                                    <div className="small mb-3 fw-bold">{cruiseDetail.itinerarySegments[0].portName}</div>
                                                                </div>
                                                                <div className="col-4">
                                                                    <a href="#" className="small" data-bs-toggle="collapse" data-bs-target={'#cruiseItin_' + panelId} aria-expanded="true" aria-controls={'cruiseItin_' + panelId}>
                                                                        <i className="ph-plus-circle"></i> {t("MyBook:LookItinerary")}
                                                                    </a>
                                                                </div>
                                                                <div className="col-4">
                                                                    <div className="small mb-1">{t("Product:Cruise:ArrivalPort")}</div>
                                                                    <div className="small mb-3 fw-bold">{cruiseDetail.itinerarySegments[cruiseDetail.itinerarySegments.length - 1].portName}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="float-left">
                                                            {type === 'MyBook' && <OperationButtons pnr={id} productType={configData.Settings.Products.Cruise.IdTipoProdotto} status={cruiseDetail.priceBar.status} allowedOps={cruiseDetail.allowedOperations} />}
                                                            {
                                                                type === 'QuotationTO' && <QuotationOperationButtons
                                                                    id={id}
                                                                    callOnChangeStatusQuotationItem={customFunctions.callOnChangeStatusQuotationItem}
                                                                    onCallRequoteQuotationItem={customFunctions.onCallRequoteQuotationItem}
                                                                    onCallUpdateQuotationItem={customFunctions.onCallUpdateQuotationItem}
                                                                    isCartQuotation={customFunctions.isCartQuotation}
                                                                    quotationMode={customFunctions.quotationMode}
                                                                    reservationDetail={cruiseDetail.reservationDetail}
                                                                    quotationDetail={cruiseDetail.quotationDetail} />
                                                            }

                                                            {cruiseDetail.optionExpiresDate && cruiseDetail.priceBar.status === "OPTION" &&
                                                                <div className="pt-2 text-orange">Data scadenza opzione: {formatDateTime(cruiseDetail.optionExpiresDate, cultureName)}</div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id={'cruiseItin_' + panelId} className="mybook-hotel cruise-itinerary collapse" role="region" aria-labelledby="heading1c">
                                                    <div className="row cruise-itinerary-header">
                                                        <div className="col-3">{t("MyBook:Day")}</div>
                                                        <div className="col-5">{t("Product:Cruise:Port")}</div>
                                                        <div className="col-2">{t("MyBook:Departure")}</div>
                                                        <div className="col-2">{t("MyBook:Arrival")}</div>
                                                    </div>
                                                    {cruiseDetail.itinerarySegments.map((seg, index) =>
                                                        <div key={index} className="row cruise-itinerary-body">
                                                            <div className="col-3">{formatItineraryDate(seg.day)}</div>
                                                            <div className="col-5">{seg.portName}</div>
                                                            <div className="col-2">{seg.departure}</div>
                                                            <div className="col-2">{seg.arrival}</div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="mybook-hotel-room">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                                            <h6>
                                                                <span className="small">{t("Product:Cruise:Cabin")}:</span> {cruiseDetail.cabinCategory.name}
                                                            </h6>

                                                            <h6>
                                                                {t("MyBook:Composition")}:&nbsp;&nbsp;
                                                                {cruiseDetail.cabinCategory.adults} <M3Icon iconName="PaxAdl" />
                                                                {cruiseDetail.cabinCategory.children} <M3Icon iconName="PaxChd" />
                                                                {
                                                                    cruiseDetail.cabinCategory.children && cruiseDetail.cabinCategory.children > 0 && cruiseDetail.cabinCategory.childrenAges && cruiseDetail.cabinCategory.childrenAges.length > 0 && <>
                                                                        ({t('SearchEngine:Age')}:
                                                                        {
                                                                            cruiseDetail.cabinCategory.childrenAges.map((age, index) => {
                                                                                return age + (index === (cruiseDetail.cabinCategory.childrenAges.length - 1) ? "" : ",");
                                                                            })
                                                                        }
                                                                        )
                                                                    </>
                                                                }
                                                            </h6>
                                                        </div>

                                                        <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                                            {
                                                                cruiseDetail.cancelPolicy && <span className={cruiseDetail.cancelPolicy.isFree ? "penalty free" : "penalty"}>
                                                                    <i className="ph-money-light"></i>
                                                                    {cruiseDetail.cancelPolicy.isFree ? t("CancelPolicy:FreeCancellation") + formatDateTime(cruiseDetail.cancelPolicy.expirationDate, { twoDigits: true }) + " " + renderWebsite.cancelPolicy.time : t("CancelPolicy:PenaltyCancellation")}
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        (type === 'MyBook' || type === 'QuotationTO_MyBook') && <>
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                    <span> <i className="ph-poker-chip"></i><label>Deck:</label> {cruiseDetail.cabinCategory.deck.name}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                    <span><i className="ph-bed"></i><label> {t("Product:Cruise:CabinNumber")}:</label> {cruiseDetail.cabinCategory.deck.cabinNumber}</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                            {
                                                                (type === 'MyBook' || type === 'QuotationTO_MyBook')
                                                                    ? <span><i className="ph-user-fill"></i><label>Ref.Pax:</label> {cruiseDetail.cabinCategory.refPax}</span>
                                                                    : <></>
                                                            }
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 right">
                                                            <h2 className="accordion-header " id="heading1c">
                                                                <a href="#" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={'#pannelCabinCategory' + panelId} aria-expanded="true" aria-controls={'pannelCabinCategory' + panelId}>
                                                                    <span className="icon-title h6 mb-0 font-weight-bold text-right w-100"> {t("CancelPolicy:PassengerList")} - Note</span>
                                                                </a>
                                                            </h2>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                            <div id={'pannelCabinCategory' + panelId} className="accordion-collapse collapse" role="region" aria-labelledby="heading1c">
                                                                <div className="accordion-body">
                                                                    {/*Passengers*/}
                                                                    {
                                                                        (type === 'MyBook' || type === 'QuotationTO_MyBook')
                                                                            ? <div className="pt-3">
                                                                                <div>{t("MyBook:Passengers")}:</div>
                                                                                {cruiseDetail.cabinCategory && cruiseDetail.cabinCategory.passengers && cruiseDetail.cabinCategory.passengers.map((passenger, key) =>
                                                                                    <Passenger key={key} passenger={passenger} />
                                                                                )}
                                                                            </div>
                                                                            : <></>
                                                                    }

                                                                    {
                                                                        cruiseDetail.cabinCategory.descriptions && cruiseDetail.cabinCategory.descriptions.length > 0 && <div className="pt-3 small">
                                                                            <h6>Descrizione Cabina</h6>
                                                                            <div className="row no-gutters">
                                                                                {
                                                                                    cruiseDetail.cabinCategory.descriptions.map((desc, index) =>
                                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                                            <p key={index} dangerouslySetInnerHTML={{ __html: desc }}></p>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    {
                                                                        cruiseDetail.cabinCategory && cruiseDetail.cabinCategory.includeServices && <div className="pt-3 small">
                                                                            <CruiseDetailCabinCategoryPriceTable
                                                                                services={cruiseDetail.cabinCategory.includeServices}
                                                                                isIncludedService={true}
                                                                                title="Servizi Inclusi"
                                                                                readonly={true} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        cruiseDetail.cabinCategory && cruiseDetail.cabinCategory.optionalServices && <div className="pt-3 small">
                                                                            <CruiseDetailCabinCategoryServices
                                                                                services={cruiseDetail.cabinCategory.optionalServices}
                                                                                title={t('Product:Cruise:OptionalService')}
                                                                                readonly={true} />
                                                                        </div>
                                                                    }

                                                                    {/*Costi*/}
                                                                    {
                                                                        cruiseDetail.cabinCategory.paxesPriceDetails && <div className="pt-3 small">
                                                                            <h6>Costi</h6>
                                                                            <div className="row no-gutters mybook-flight TTable">
                                                                                <div className="col-3 col-sm-6 col-md-6 col-lg-6 col-xl-6">{t("MyBook:Passenger")}</div>
                                                                                <div className="col-1 col-sm-3 col-md-3 col-lg-3 col-xl-3">{t("MyBook:TypeAmount")}</div>
                                                                                <div className="col-1 col-sm-3 col-md-3 col-lg-3 col-xl-3">{t("MyBook:Cost")}</div>
                                                                            </div>
                                                                            {renderPassengerCosts(cruiseDetail.cabinCategory.paxesPriceDetails)}
                                                                        </div>
                                                                    }
                                                                    {
                                                                        cruiseDetail.note && <div className="pt-3 small">
                                                                            <h6>Note</h6>
                                                                            <div className="row no-gutters mybook-flight TTable">
                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                                    <div dangerouslySetInnerHTML={{ __html: cruiseDetail.note }}></div>
                                                                                </div>
                                                                            </div>
                                                                            {renderPassengerCosts(cruiseDetail.cabinCategory.paxesPriceDetails)}
                                                                        </div>
                                                                    }
                                                                    {
                                                                        cruiseDetail.publicNotes && cruiseDetail.publicNotes.map((note, index) =>
                                                                            <p key={index} dangerouslySetInnerHTML={{ __html: note }}></p>
                                                                        )
                                                                    }

                                                                    {
                                                                        <ServiceNote notes={cruiseDetail.notesService} noteTypeToShow={[12]} />
                                                                    }
                                                                    {
                                                                        <ServiceNote notes={cruiseDetail.notesService} noteTypeToShow={[13]} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        {/*Price Bar*/}
                                        <PriceBar
                                            oPriceBar={cruiseDetail.priceBar}
                                            showNetPrices={showNetPrices}
                                            reservationDetail={cruiseDetail.reservationDetail}
                                            quotationDetail={cruiseDetail.quotationDetail}
                                            quotationMode={customFunctions.quotationMode}
                                            customView={{ notShowPaymentAvailable: (type === 'QuotationTO' || type === 'QuotationTO_MyBook') }}
                                            isEnabledUpdateStaticData={isEnabledUpdateStaticData}
                                            onClickOpenUpdateStaticData={onClickOpenUpdateStaticData}/>
                                    </div>
                                </div>
                            </div>

                            {/*Modals*/}
                            {type === 'MyBook' && <CancelReservationModal pnr={id} productType={configData.Settings.Products.Cruise.IdTipoProdotto} />}
                            {type === 'MyBook' && <OptionConfirmModal pnr={id} productType={configData.Settings.Products.Cruise.IdTipoProdotto} productName={cruiseDetail.itineraryName} />}
                            {type === 'MyBook' && cruiseDetail.priceBar.paymentData !== null && <PayWithCreditCardModal pnr={id} productType={configData.Settings.Products.Cruise.IdTipoProdotto} paymentData={cruiseDetail.priceBar.paymentData} />}
                            {isEnabledUpdateStaticData && <UpdateCruiseData
                                modalId={modalId}
                                quotationItemId={id}
                                saveProductData={saveProductData}
                                resetValue={resetValue} />}
                        </>
                    )
            }
        </>
    );
}