import React from 'react';
import { getCDNLogoDocument } from "../../../../../js/CDNUtility";
import { useTranslation } from 'react-i18next';

export const PrintAccountBalanceHeader = ({ extraInfo }) => {
    const { t } = useTranslation();


    return (
        <>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                <table className="">
                    <tr style={{ borderBottom: '1px solid #d0d1d6' }}>
                        <td>
                            <img src={getCDNLogoDocument()} height="100" alt="Logo" />
                        </td>
                        <td style={{ fontSize: '16px' }}>
                            <div className="my-0"><label style={{ fontSize: '14px' }}>{t("Print:Client")}:</label> {extraInfo.headerData.companyName}</div>
                            <div className="list-group-item font-weight-normal pl-0 py-0">{extraInfo.headerData.address}</div>
                            <div className="list-group-item font-weight-normal pl-0 py-0">{extraInfo.headerData.zipCode} {extraInfo.headerData.city}</div>
                            <div className="list-group-item font-weight-normal pl-0 py-0">
                                {
                                    extraInfo.headerData.isCommissionable === 'false'
                                        ? extraInfo.headerData.fiscalCode ? <><label style={{ fontSize: '12px' }}>{t("MyBook:idCode")}:</label> {extraInfo.headerData.fiscalCode}</> : ""
                                        : extraInfo.headerData.vatCode ? <><label style={{ fontSize: '12px' }}>{t("MyBook:PIVA")}:</label> {extraInfo.headerData.vatCode}</> : ""
                                }
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </>
    );
}