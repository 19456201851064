import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../Auth/AuthProvider';
import { GetModulesRules, getAuthToken, getCurrentUserFromJwtToken } from "../../../js/Utils";

export const ProductNavBar = ({ moduleSelected, setModuleSelected }) => {
    const { t } = useTranslation();

    const { onChangeModuleSelected } = useAuth();
    let token = getAuthToken();

    const [rules, setRules] = useState(null);

    useEffect(() => {
        const callGetModuleRules = async () => {
            const moduleRules = await GetModulesRules();
            setRules(moduleRules);
        };
        callGetModuleRules();
    }, []);

    function isVisibleProduct(productName) {
        if (!rules)
            return false;

        let isVisibleTrippie = false;
        try {
            let currentUser = getCurrentUserFromJwtToken(token);
            if (currentUser && currentUser.roles.includes("W_EngineTrippie"))
                isVisibleTrippie = true;
        } catch (ex) { }

        try {

            switch (productName) {
                case 'ACTIVITY':
                    {
                        return rules["ACTIVITY"];
                        break;
                    }
                case 'CARRENTAL':
                case 'RENTCAR':
                    {
                        return rules["CARRENTAL"];
                        break;
                    }
                case 'CRUISE':
                    {
                        return rules["CRUISE"];
                        break;
                    }
                case 'STRUCTURE':
                    {
                        return rules["STRUCTURE"];
                        break;
                    }
                case 'TRANSFER':
                    {
                        return rules["TRANSFER"];
                        break;
                    }
                case 'TOUR':
                    {
                        return rules["TOUR"];
                        break;
                    }
                case 'FLIGHT':
                    {
                        return rules["FLIGHT"];
                        break;
                    }
                case 'TRAIN':
                    {
                        return rules["TRAIN"];
                        break;
                    }
                case 'TRIPPIE':
                    {
                        return isVisibleTrippie;
                        break;
                    }
                default: {
                    return true;
                }
            }


        } catch (ex) {
            return false;
        }
    }

    function onClickModuleSelected(type) {
        if (token) {

            if (setModuleSelected)
                setModuleSelected(type);

            onChangeModuleSelected(type);

            if (!window.location.pathname.includes('home'))
                window.location.href = 'home';
        } else {
            const button = document.querySelector('.open-login-modal');
            button.click();
        }
    }

    return (<>
        <div className="tab-search bg-custom2 d-flex border-bottom">
            <div className="flex-row p-0">
                {isVisibleProduct('FLIGHT') && <div className={moduleSelected === "FLIGHT" ? "bg-custom" : "flex-column flex-shrink-1"} onClick={e => onClickModuleSelected("FLIGHT")}>
                    {t("Product:Flight:Item")}
                </div>}
                {isVisibleProduct('STRUCTURE') && <div className={moduleSelected === "HOTEL" ? "bg-custom" : "flex-column flex-shrink-1"} onClick={e => onClickModuleSelected("HOTEL")}>
                    {t("Product:Structure:Item")}
                </div>}
                {isVisibleProduct('ACTIVITY') && <div className={moduleSelected === "ACTIVITY" ? "bg-custom" : "flex-column flex-shrink-1"} onClick={e => onClickModuleSelected("ACTIVITY")}>
                    {t("Product:Activity:Item")}
                </div>}
                {isVisibleProduct('TRANSFER') && <div className={moduleSelected === "TRANSFER" ? "bg-custom" : "flex-column flex-shrink-1"} onClick={e => onClickModuleSelected("TRANSFER")}>
                    {t("Product:Transfer:Item")}
                </div>}
                {isVisibleProduct('CARRENTAL') && <div className={moduleSelected === "CARRENTAL" ? "bg-custom" : "flex-column flex-shrink-1"} onClick={e => onClickModuleSelected("CARRENTAL")}>
                    {t("Product:RentCar:Item")}
                </div>}
                {isVisibleProduct('CRUISE') && <div className={moduleSelected === "CRUISE" ? "bg-custom" : "flex-column flex-shrink-1"} onClick={e => onClickModuleSelected("CRUISE")}>
                    {t("Product:Cruise:Item")}
                </div>}
                {isVisibleProduct('TRAIN') && <div className={moduleSelected === "TRAIN" ? "bg-custom" : "flex-column flex-shrink-1"} onClick={e => onClickModuleSelected("TRAIN")}>
                    {t("Product:Train:Item")}
                </div>}
                {isVisibleProduct('TOUR') && <div className={moduleSelected === "TOUR" ? "bg-custom" : "flex-column flex-shrink-1"} onClick={e => onClickModuleSelected("TOUR")}>
                    {t("Product:Tour:Item")}
                </div>}
                {isVisibleProduct('TRIPPIE') && <div className={moduleSelected === "TRIPPIE" ? "bg-custom" : "flex-column flex-shrink-1"} onClick={e => onClickModuleSelected("TRIPPIE")}>
                    {t("Trippie")}
                </div>}
            </div>
        </div>
    </>);
}