import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatDateTime, formatPrice, getDisplayPriceLabel, getDisplayPrice } from '../../../../js/Utils.js';
import { ProductAllPrice } from '../../Common/Pricing/ProductAllPrice';
import { M3Icon } from "../../../Common/M3Icon.js"
import { Loading } from '../../../Common/Loading';
import { ProductNotAllowedAddToCart } from '../../Common/ProductNotAllowedAddToCart';

export const FlightDetailResume = ({ fares, fareLegInfo, ancillariesSelectedBagagge, ancillariesSelectedBase, isLoadingFares, showCost, enableShowNetPrices, enableCanAddToCart, onBook, templateInfo, quotationInfo }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));
    const [legInfos, setLegInfos] = useState([]);
    let [openMobileAdditionalInfo, setOpenMobileAdditionalInfo] = useState(false)

    // state of flight in page
    const [showPriceBar, setShowPriceBar] = useState(false);
    const [totalPrice, setTotalPrice] = useState(null);

    useEffect(() => {
        if (fareLegInfo && fareLegInfo.legs) {
            let updLegInfos = [];

            for (let i = 0; i < fareLegInfo.legs.length; i++) {
                let segments = fareLegInfo.legs[i].segments;

                let depSegment = segments[0];
                let arrSegment = segments[segments.length - 1];

                updLegInfos.push({
                    itineraryName: depSegment.departureAirportIATA + " > " + arrSegment.arrivalAirportIATA,
                    departureDate: depSegment.departureDate,
                    departureTime: depSegment.departureTime,
                    arrivalDate: arrSegment.arrivalDate,
                    arrivalTime: arrSegment.arrivalTime,
                });
            }

            setLegInfos(updLegInfos);
        }

        if (fares && fares.length > 0) {

            console.log(fares);

            setTotalPrice({
                displayPrice: fares[0].price.displayPrice,
                valutaCliente: fares[0].price.valutaCliente,
                costoFornitore: fares[0].price.costoFornitore
            });
        }

    }, [fareLegInfo]);

    useEffect(() => {
        let updTotalPrice = { ...totalPrice };
        if (fares && fares.length > 0) {
            updTotalPrice = {
                displayPrice: fares[0].price.displayPrice,
                valutaCliente: fares[0].price.valutaCliente,
                costoFornitore: fares[0].price.costoFornitore,
                isCommissionable: fares[0].price.isCommissionable
            };
        }


        if (ancillariesSelectedBagagge) {
            for (let i = 0; i < ancillariesSelectedBagagge.length; i++) {
                let anc = ancillariesSelectedBagagge[i];
                updTotalPrice.displayPrice += getTotalAncillarySelectedMapping(anc);
                updTotalPrice.costoFornitore += getTotalAncillarySelectedMapping(anc);
            }
        }

        if (ancillariesSelectedBase) {
            for (let i = 0; i < ancillariesSelectedBase.length; i++) {
                let anc = ancillariesSelectedBase[i];
                updTotalPrice.displayPrice += getTotalAncillarySelectedMapping(anc);
                updTotalPrice.costoFornitore += getTotalAncillarySelectedMapping(anc);
            }
        }

        setTotalPrice(updTotalPrice);

    }, [ancillariesSelectedBagagge, ancillariesSelectedBase, fares]);


    // Ancillary utilities
    function getTotalAncillarySelectedMapping(ancillary) {
        let totalPrice = 0;
        if (ancillary.valueSelected && ancillary.valueSelected.length > 0) {
            totalPrice = ancillary.valueSelected.reduce((partialSum, a) => partialSum + a.value.amount, 0);
        } else {
            totalPrice += ancillary.ancillary.amount.amount;
        }

        return totalPrice;
    }

    function renderAncillary(ancillary) {
        return <div className="ml-1">
            {(!ancillary.valueSelected || ancillary.valueSelected.length === 0) && <>{ancillary.ancillary.description} {formatPrice(ancillary.ancillary.amount.amount, 'EUR', cultureName)}</>}
            {
                ancillary.valueSelected && ancillary.valueSelected.length > 0 && ancillary.valueSelected.map((vv, key) => {
                    return <div key={key} className="mb-1">
                        {vv.description} {formatPrice(vv.value.amount, 'EUR', cultureName)}
                    </div>
                })
            }
        </div>
    }

    function getTotalFarePax() {
        let paxLabel = '';
        if (fares && fares.length > 0) {
            let total = 0;
            let paxesIndex = [];
            for (let i = 0; i < fares[0].renderFaresDetails.length; i++) {
                paxesIndex.push(fares[0].renderFaresDetails[i].travelers);
            }
            let uniqueIndex = paxesIndex.filter(onlyUnique);
            total = uniqueIndex.length;
            paxLabel = total + ' pax';
        }

        return paxLabel;
    }

    function getFarePaxDetail() {
        let paxesArr = [];
        let showLabelSeePrice = false;

        if (fares && fares.length > 0) {
            fareLegInfo.travelers.forEach((traveler) => {
                let paxes = { type: "", baggage: null, age: null };
                if (traveler.passengerTypeCodes && traveler.passengerTypeCodes[0]) {
                    paxes["type"] = traveler.passengerTypeCodes[0];
                }
                else { // travelfusion non passa i tipi
                    paxes["type"] = "ADT";
                }
                fares[0].renderFaresDetails.forEach((fareDetail) => {
                    if (fareDetail.travelers.find(x => x === traveler.index)) {
                        fareDetail.renderFareDetails.forEach((detail) => {
                            paxes["baggage"] = detail.baggage ? (detail.baggage.replace("Pcs", t(`Product:Flight:Baggage`))) : t(`Product:Flight:NoBaggage`);
                            if (traveler.age)
                                paxes["age"] = traveler.age;
                        });

                        if (paxesArr[traveler.index] && paxesArr[traveler.index].baggage && paxesArr[traveler.index].baggage !== paxes.baggage) {
                            showLabelSeePrice = true;
                        }

                        paxesArr[traveler.index] = paxes;
                    }
                });
            });
        }

        return (
            <>
                {paxesArr.map((pax, idx) =>
                    <p className="p-0 m-0 h075">
                        {pax.type === "ADT"
                            ? idx + "° " + t(`General:Adult`) + ": " + pax.baggage
                            : t(`SearchEngine:Child`) + " " + pax.age + " " + t(`General:Ages`) + ": " + pax.baggage
                        }
                    </p>
                )
                }
                {showLabelSeePrice &&
                    <p className="p-0 m-0 h075">
                        {t(`Product:Flight:SeePriceSummury`)}
                    </p>
                }
            </>
        )
    }

    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    // button function
    const onChangeShowPriceBar = () => {
        setShowPriceBar(!showPriceBar);
    }

    function onClickBook() {
        onBook(null);
    }

    return (
        <>

            <div className="bg-white rounded shadow d-none d-sm-block">
                <div className=" row mb-3 mx-1">
                    <div className="col-12 col-sm-12 p-1">
                        <div className="text-center bg-gray-300">
                            <div className="wrap-img-list d-flex align-items-center w-100 img rounded" style={{ backgroundImage: "url(https://cdn.travelplace.ch/travelplace40/img/flight_thum.gif)" }}>
                                <div className="d-table h-100 w-100">
                                    <div className="d-table-row text-center">
                                        <div className="d-table-cell align-bottom">
                                            <div className="h6 mb-0 bg-gray-300 rounded-top">
                                                {
                                                    legInfos && legInfos.length > 0 && legInfos.map((legInfo, key) => {
                                                        return <div key={key}> {legInfo.itineraryName} {formatDateTime(legInfo.departureDate, cultureName, { noYear: true })}</div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    isLoadingFares && <div className=" row mb-3 mx-1">
                        <div className="col-12 col-sm-12 p-1">
                            <Loading textMsg={t('Product:Flight:LoadingFares')} />
                        </div>
                    </div>
                }

                {fares && <>
                    {
                        enableShowNetPrices && <div className="row mb-3 mx-1">
                            <div className="col-8 col-sm-8">
                                <div className="h6">
                                    <data m3lab="Product.Flight.FareSelected">
                                        {t(`Product:Flight:FareSelected`)}:
                                    </data>
                                </div>
                            </div>
                            <div className="col-4 col-sm-4 text-right">
                                <button className={(showPriceBar ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow"} m3rool="f_ShowPriceBar" onClick={(e) => { onChangeShowPriceBar() }}>
                                    <data m3lab="Button.Net">
                                        {t("Button:Net")}
                                    </data>
                                </button>
                            </div>
                        </div>
                    }

                    <div className="row mb-3 mx-1" data-bs-toggle="modal" data-bs-target="#EngineModifyActivty">
                        {
                            fares[0].supplier && <div className="col-12 col-sm-12 p-1">
                                <div className="h6">
                                    GDS
                                </div>
                                <p className="p-0 m-0 h075">
                                    {fares[0].supplier.supplierName}
                                </p>
                            </div>
                        }
                        {/* Passeggeri */}
                        <div className="col-12 col-sm-12 p-1">
                            <div className="h6">
                                <data m3lab="Product.Flight.Passengers">
                                    {t(`Product:Flight:Passengers`)}:
                                </data>
                            </div>

                            {getFarePaxDetail()}
                        </div>


                        {/* bagagli da stiva*/}
                        <div className="col-12 col-sm-12 p-1 mt-1">
                            <div className="h6">
                                <data m3lab="Product.Flight.HoldBaggage">
                                    {t(`Product:Flight:HoldBaggage`)}:
                                </data>
                            </div>
                            <p className="p-0 m-0 h075">
                                {/*fares[0].renderFaresDetails && fares[0].renderFaresDetails.length > 0 && fares[0].renderFaresDetails[0].renderFareDetails.length > 0 && fares[0].renderFaresDetails[0].renderFareDetails[0].baggage ?
                                    fares[0].renderFaresDetails && fares[0].renderFaresDetails.length > 0 && fares[0].renderFaresDetails[0].renderFareDetails.length > 0 && fares[0].renderFaresDetails[0].renderFareDetails[0].baggage
                                    : <></>

                                */}
                                {
                                    ancillariesSelectedBagagge && ancillariesSelectedBagagge.length > 0 ? ancillariesSelectedBagagge.map((ancillary, key) => {
                                        return <React.Fragment key={key} >{renderAncillary(ancillary)}</React.Fragment>;
                                    }) : <></>
                                }
                                {
                                    (ancillariesSelectedBagagge && ancillariesSelectedBagagge.length > 0) ||
                                        fares[0].renderFaresDetails && fares[0].renderFaresDetails.length > 0 && fares[0].renderFaresDetails[0].renderFareDetails.length > 0 && fares[0].renderFaresDetails[0].renderFareDetails[0].baggage
                                        ? <></>
                                        : <data m3lab="Product.Flight.NoOptionalBaggages">
                                            {t(`Product:Flight:NoOptionalBaggages`)}
                                        </data>
                                }
                            </p>
                        </div>


                        {/* Servizi */}
                        <div className="col-12 col-sm-12 p-1 mt-1">
                            <div className="h6">
                                <data m3lab="Product.Flight.Services">
                                    {t(`Product:Flight:Services`)}:
                                </data>
                            </div>
                            <p className="p-0 m-0 h075">
                                {ancillariesSelectedBase && ancillariesSelectedBase.length > 0 ? ancillariesSelectedBase.map((ancillary, key) => {
                                    return <React.Fragment key={key} >{renderAncillary(ancillary)}</React.Fragment>;
                                }) :
                                    <data m3lab="Product.Flight.NothingServices">
                                        {t(`Product:Flight:NothingServices`)}
                                    </data>
                                }
                            </p>
                        </div>

                        {/* Posto */}
                        <div className="col-12 col-sm-12 p-1 mt-1">
                            <div className="h6">
                                <data m3lab="Product.Flight.SeatAssignment">
                                    {t(`Product:Flight:SeatAssignment`)}:
                                </data>
                            </div>
                            <p className="p-0 m-0 h075">
                                {fares[0].ancillaries ?
                                    //todo
                                    <div></div>
                                    :
                                    <data m3lab="Product.Flight.NothinSeatAssigned">
                                        {t(`Product:Flight:NothinSeatAssigned`)}
                                    </data>
                                }
                            </p>
                        </div>


                        {/* Tariffa e tasse */}
                        <div className="col-12 col-sm-12 p-1 mt-1">
                            <div className="h6">
                                <data m3lab="Product.Flight.FareAndTaxes">
                                    {t(`Product:Flight:FareAndTaxes`)}:
                                </data>
                            </div>
                            <p className="p-0 m-0 h075">
                                <data m3lab="Product.Flight.Fare">
                                    {t(`Product:Flight:Fare`)}:
                                </data>
                                <span className="ml-1">
                                    {fares[0].price && fares[0].taxes && formatPrice(fares[0].price.displayPrice - fares[0].taxes.displayPrice, fares[0].price.valutaCliente, cultureName)}
                                </span>
                            </p>
                            <p className="p-0 m-0 h075 mt-1">
                                <data m3lab="Product.Flight.Taxes">
                                    {t(`Product:Flight:Taxes`)}:
                                </data>
                                <span className="ml-1">
                                    {fares[0].taxes && formatPrice(fares[0].taxes.displayPrice, fares[0].taxes.valutaCliente, cultureName)}
                                </span>
                            </p>
                            {/* Totale */}
                            <div className="mt-1">
                                <div className="h6 p-0">
                                    {
                                        t(getDisplayPriceLabel(showCost, totalPrice.isCommisionable, 'General:TotPrice'))
                                    }:
                                    {getDisplayPrice(totalPrice, cultureName, showCost, null)}
                                </div>

                                {
                                    <ProductAllPrice showPriceBar={showPriceBar} showCost={showCost} pricing={fares[0].price} />
                                }
                            </div>
                        </div>


                        <div className="col-12 col-sm-12 mb-1 mt-1">
                            {
                                (templateInfo || quotationInfo || (enableCanAddToCart && enableCanAddToCart.allowed)) && <button className="btn btn-sm tp-btn-search w-100 text-white" onClick={(e) => onClickBook()}>
                                    <data m3lab="Button.Book">
                                        <data m3lab="Button.Book">
                                            {!templateInfo && !quotationInfo && <span dangerouslySetInnerHTML={{ __html: t(`Button:AddToCart`) }}></span>}
                                            {templateInfo && t(`Button:Select`)}
                                            {quotationInfo && !quotationInfo.quotationItemIdToChange && t(`Button:AddQuotation`)}
                                            {quotationInfo && quotationInfo.quotationItemIdToChange && t(`Button:ReplaceQuotation`)}
                                        </data>
                                    </data>
                                </button>
                            }
                            <ProductNotAllowedAddToCart enableCanAddToCart={enableCanAddToCart} />
                        </div>
                    </div>
                </>
                }
            </div>
            {fares && <div className='d-block d-sm-none bg-blue text-white p-4 rounded my-4 fs-6'>
                <div className="d-grid" style={{ gridTemplateColumns: '1fr 1fr' }}>

                    <div className=''>
                        {
                            legInfos && legInfos.length > 0 && legInfos.map((legInfo, key) => {
                                return <div key={key}> {legInfo.itineraryName} {formatDateTime(legInfo.departureDate, cultureName, { noYear: true })}</div>
                            })
                        }
                    </div>


                </div>

                <div className="">
                    <div className="">
                        <data m3lab="Product.Flight.Passengers">
                            {t(`Product:Flight:Passengers`)}:
                        </data>
                    </div>

                    {getFarePaxDetail()}
                    <div className="">
                        {
                            (ancillariesSelectedBagagge && ancillariesSelectedBagagge.length > 0) ||
                                fares[0].renderFaresDetails && fares[0].renderFaresDetails.length > 0 && fares[0].renderFaresDetails[0].renderFareDetails.length > 0 && fares[0].renderFaresDetails[0].renderFareDetails[0].baggage
                                ? <data m3lab="Product.Flight.HoldBaggage">
                                    {t(`Product:Flight:HoldBaggage`)}:
                                </data>
                                : <data m3lab="Product.Flight.NoOptionalBaggages">

                                </data>
                        }
                        {
                            ancillariesSelectedBagagge && ancillariesSelectedBagagge.length > 0 ? ancillariesSelectedBagagge.map((ancillary, key) => {
                                return <React.Fragment key={key} >{renderAncillary(ancillary)}</React.Fragment>;
                            }) : <></>
                        }
                    </div>
                    {ancillariesSelectedBase && ancillariesSelectedBase.length > 0 && <> <div className="">
                        <data m3lab="Product.Flight.Services">
                            {t(`Product:Flight:Services`)}:
                        </data>
                    </div>
                        <p className="p-0 m-0">
                            {ancillariesSelectedBase.map((ancillary, key) => {
                                return <React.Fragment key={key} >{renderAncillary(ancillary)}</React.Fragment>;
                            })
                            }
                        </p> </>}

                    <data m3lab="Product.Flight.FareAndTaxes" style={{ fontSize: '12px' }}>

                        {t(`Product:Flight:FareAndTaxes`)}: {" "}
                        <data m3lab="Product.Flight.Fare">
                            {t(`Product:Flight:Fare`)}:
                        </data>
                        <span className="ml-1">
                            {fares[0].price && fares[0].taxes && formatPrice(fares[0].price.displayPrice - fares[0].taxes.displayPrice, fares[0].price.valutaCliente, cultureName)}
                        </span>


                        <data m3lab="Product.Flight.Taxes">
                            {" "} {t(`Product:Flight:Taxes`)}:
                        </data>
                        <span className="ml-1">
                            {fares[0].taxes && formatPrice(fares[0].taxes.displayPrice, fares[0].taxes.valutaCliente, cultureName)}
                        </span>
                    </data>
                </div>

                {/* Totale */}
                <div className="mt-1">
                    <div className="p-0 p-0 h3 text-white text-center">
                        {
                            !showCost && totalPrice && totalPrice.isCommisionable ? <data m3lab="General.Commission">{t('General:Commission')}</data> : <data m3lab="General.TotPrice">{t('General:TotPrice')}: {totalPrice && !showCost && <var>{formatPrice(totalPrice.displayPrice, totalPrice.valutaCliente, cultureName)}</var>}
                                {totalPrice && showCost && <var>{formatPrice(totalPrice.costoFornitore, totalPrice.valutaCliente, cultureName)}</var>}</data>
                        }
                        {
                            showCost && <data m3lab="General.BuyPrice">{t('General:BuyPrice')}</data>
                        }
                    </div>

                    {
                        <ProductAllPrice showPriceBar={showPriceBar} showCost={showCost} pricing={fares[0].price} />
                    }
                </div>

                <div className="mt-4">
                    {
                        (templateInfo || quotationInfo || (enableCanAddToCart && enableCanAddToCart.allowed)) && <button className="btn btn-sm tp-btn-search text-white w-100" onClick={(e) => onClickBook()}>
                            <data m3lab="Button.Book">
                                <data m3lab="Button.Book">
                                    {!templateInfo && !quotationInfo && <span dangerouslySetInnerHTML={{ __html: t(`Button:AddToCart`) }}></span>}
                                    {templateInfo && t(`Button:Select`)}
                                    {quotationInfo && !quotationInfo.quotationItemIdToChange && t(`Button:AddQuotation`)}
                                    {quotationInfo && quotationInfo.quotationItemIdToChange && t(`Button:ReplaceQuotation`)}
                                </data>
                            </data>
                        </button>
                    }
                    <ProductNotAllowedAddToCart enableCanAddToCart={enableCanAddToCart} />
                </div>
            </div>
            }
        </>
    );
}
