import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import configData from "../../../../appsettings.json";
import { M3Icon } from "../../../Common/M3Icon";
import { ProductActionMenu } from "../../Common/ProductActionMenu";
import { formatDateTime } from "../../../../js/Utils";

export const TrainItemHeader = ({ item, supplier, onModalOpenProductId }) => {
    const { t } = useTranslation();

    const [departureSegment, setDepartureSegment] = useState();
    const [arrivalSegment, setArrivalSegment] = useState();

    useEffect(() => {

        if (item) {
            setDepartureSegment(item.leg.segments[0]);
            setArrivalSegment(item.leg.segments[item.leg.segments.length - 1]);
        }


    }, [item])

    return (
        <>
            {
                departureSegment && <>
                    <div className="row h6 mb-0">
                        <div className="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <div className="d-inline">
                                <div className="d-table h-100">
                                    <div className="d-table-row h-100">
                                        <div className="d-table-cell align-middle">
                                            <span style={{ fontSize: '12px', paddingRight: '5px' }}>{formatDateTime(departureSegment.departure.date, "it-IT", { noYear: true })}</span>
                                            {departureSegment.departure.time}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
                                    <div className="d-inline">{departureSegment.departure.stationName}</div>
                                </div>
                                <div className="col-12 col-sm-6  col-md-6 col-lg-6 col-xl-6 ">
                                    <div className="d-inline">{arrivalSegment && arrivalSegment.arrival.stationName}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 col-sm-0 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                            <div className="btn-group text-right float-end pr-2 d-none  d-sm-none d-md-block">
                                <button className="btn p-0 m-0" type="button" id="menuItem1" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                    <M3Icon iconName="MenuFunctions" />
                                </button>
                                <ProductActionMenu
                                    productId={item.leg.uniqueId}
                                    productType={configData.Settings.Products.Train.IdTipoProdotto}
                                    handleModalOpenProductId={onModalOpenProductId}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="row mb-3">
                        <div className="col-0 col-sm-1 col-md-1 col-lg-1 col-xl-1 p-0 m-0 d-none d-sm-block">
                            {
                                supplier && <img src={`${configData.Settings.CDN_Common}/images/train/supplier/${supplier.supplierId}.png`} className="w-100 p-2" />
                            }
                        </div>
                        <div className="col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-2 px-1">
                            <label>{t("Product:Train:Going")}</label>
                            <label></label>
                            <div>
                                <label>
                                    {departureSegment.departure.time}
                                    {departureSegment && departureSegment.isDayAfter && <>*</>}
                                </label>
                                {arrivalSegment && arrivalSegment.isDayAfter && <>{t('Product:Train:DepartureDayAfter')}</>}
                            </div>
                            <div>
                                <label>N. {departureSegment.vehicle && departureSegment.vehicle.number}</label>
                            </div>
                        </div>
                        <div className="col-4 col-sm-7 col-md-7 col-lg-7 col-xl-7 text-center">
                            <label>{t("Product:Train:Duration")} {item.leg.duration} h</label>
                            <div className="w-100"></div>
                            <label>
                                {
                                    item.leg.segments.length === 1 && <data m3lab="Product.Train.Direct">
                                        {t(`Product:Train:Direct`)}
                                    </data>
                                }
                                {
                                    item.leg.segments.length === 2 && <data m3lab="Product.Train.Stopover">
                                        {item.leg.segments.length - 1} {t(`Product:Train:Stopover`)} ({item.leg.durationStop})
                                    </data>
                                }
                                {
                                    item.leg.segments.length > 2 && <data m3lab="Product.Train.Stopovers">
                                        {item.leg.segments.length - 1} {t(`Product:Train:Stopovers`)} ({item.leg.durationStop})
                                    </data>
                                }
                            </label>
                            <div className="w-100 border-top"></div>
                            {departureSegment.vehicle && departureSegment.vehicle.code2 && departureSegment.vehicle.imgUrl && <img className="w-20 d-none d-sm-inline" src={departureSegment.vehicle.imgUrl} />}
                            {departureSegment.vehicle && departureSegment.vehicle.code2 && departureSegment.vehicle.imgUrl && <img className="w-100 d-inline d-sm-none" src={departureSegment.vehicle.imgUrl} />}
                        </div>
                        <div className="col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 pr-2 px-1 text-right">
                            <label>{t("Product:Train:Return")}</label>
                            <div>
                                <label>
                                    {arrivalSegment && arrivalSegment.arrival.time}
                                    {arrivalSegment && arrivalSegment.isDayAfter && <>*</>}
                                </label>
                                {arrivalSegment && arrivalSegment.isDayAfter && <>{t('Product:Train:ArrivalDayAfter')}</>}
                            </div>
                            <div>
                                <label></label>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}