import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { formatDateTime, formatPrice, getCurrentUserFromJwtToken } from '../../js/Utils.js';
import { downloadDocumentPDF, replaceAllStyleElements, setCancelPolicy, setNoteProductBig, setFlightTaxesArr } from "../../js/PrintUtility";
import configData from "../../appsettings.json";
import '../Custom/Print/PrintCssForHtml.css';
import { HeaderPDFPrint } from '../Custom/Print/HeaderPDFPrint.js';
import { PrintProductsNotes } from './components/PrintProductsNotes.js';
import { PrintStepHeader } from '../Print/components/PrintStepHeader.js';
import { PrintPaxes } from '../Print/components/PrintPaxes.js';
import { PrintStepItem } from '../Print/components/PrintStepItem.js';
import { PrintAncillaries } from '../Print/components/PrintAncillaries.js';
import { PrintCommissionsDetailCompact } from './components/PrintCommissionsDetailCompact.js';
import { PrintCommissionsDetail } from '../Print/components/PrintCommissionsDetail.js';
import { PrintDynamicNotes } from '../Print/components/PrintDynamicNotes.js';
import { TourStaticPrint } from '../Print/components/TourStaticPrint.js';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PrintPdfDocuments } from '../Common/PrintPdfDocuments';
import { printCssStyle } from './../Custom/Print/PrintCss';
import { Loading } from '../Common/Loading';
import { Error } from '../Common/Error';
import { generateQuotationHTMLConfirm } from "./../Print/service/ConfirmPrintService.js";
import { PrintItineraryResume } from '../Print/components/PrintItineraryResume.js';
import { TemplatePrintStepHeader } from '../Print/template/TemplatePrintStepHeader.js';
import { TemplatePrintImageHeader } from '../Print/template/TemplatePrintImageHeader.js';
import { TemplatePrintHeaderDescription } from '../Print/template/TemplatePrintHeaderDescription.js';
import { TemplatePrintInclusion } from '../Print/template/TemplatePrintInclusion.js';
import { PrintScadenziario } from '../Print/components/PrintScadenziario.js';
import { PrintCancelPolicy } from './components/PrintCancelPolicy.js';
import { PrintTitleSeparator } from './components/PrintTitleSeparator.js';
import { PrintProductsNotesStep } from './components/PrintProductsNotesStep.js';
import { getHeaderPrint } from "./service/CommonPrintService"


export const ConfirmBookingEndUser = () => {
    let { orderNumber } = useParams();
    let { cultureName } = useParams()
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const [myHtml, setMyHtml] = useState([]);
    const [websiteId, setWebsiteId] = useState();
    const [pdfFileName, setPdfFileName] = useState(t("Print:ConfirmBooking") + orderNumber);

    const [orders, setOrders] = useState([]);
    const [prodottiEnabled, setProdottiEnabled] = useState([]);

    const [extraInfoPrint, setExtraInfoPrint] = useState({});
    const [isFinishPrintPDF, setIsFinishPrintPDF] = useState(0);
    const [isDownloadPDF, setIsDownloadPDF] = useState(true);
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);
    const [itemStepPrint, setItemStepPrint] = useState([]);
    const [dynamicNotes, setDynamicNotes] = useState([]);
    const [dynamicNotesTop, setDynamicNotesTop] = useState([]);
    const [dynamicNotesStep, setDynamicNotesStep] = useState([]);
    const [scadenziario, setScadenziario] = useState({});
    const [isLoadingPrintCliente, setIsLoadingPrintCliente] = useState(false);
    const [template, setTemplate] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);

    // stati di stampa componenti
    const [isPrintHeaderImage, setIsPrintHeaderImage] = useState(true);
    const [isPrintHeaderDesc, setIsPrintHeaderDesc] = useState(true);
    const [isPrintItineraryResume, setIsPrintItineraryResume] = useState(true);
    const [isPrintDynamicNotesTop, setIsPrintDynamicNotesTop] = useState(true);
    const [isPrintPaxes, setIsPrintPaxes] = useState(true);
    const [isPrintAncillaries, setIsPrintAncillaries] = useState(true);
    const [isPrintInclusions, setIsPrintInclusions] = useState(true);
    const [isPrintCommissions, setIsPrintCommissions] = useState(true);
    const [isPrintDynamicNotes, setIsPrintDynamicNotes] = useState(true);
    const [isPrintStructureNotes, setIsPrintStructureNotes] = useState(true);
    const [isPrintScadenziario, setIsPrintScadenziario] = useState(true);
    const [isPrintCancelPolicy, setIsPrintCancelPolicy] = useState(true);
    const [isPrintNoteBig, setIsPrintNoteBig] = useState(true);
    const [isShowAfterAllCall, setIsShowAfterAllCall] = useState(true); // serve per ricaricare in caso di async lunghe

    // net prices
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);

    const [userAddToOrder, setUserAddToOrder] = useState(false);
    const [paxes, setPaxes] = useState([]);

    const [headerElements, setHeaderElements] = useState([])
    const [footerElements, setFooterElements] = useState([])

    let ordersTmp = [];
    let arrProduct = [];
    let totPratiche = 0; // usato per contare il totale pratiche e proseguire dopo le chiamate asincrone
    var tmpDynamicNotesStep = [];
    async function getPdfHeader() {
        const headerElements = await getHeaderPrint(100, cultureName) // pdf header
        setHeaderElements(headerElements)
    }

    async function getPdfFooter() {
        const footerElements = await getHeaderPrint(200, cultureName) // pdf header
        setFooterElements(footerElements)
    }
    useEffect(() => {
        getPdfFooter()
        getPdfHeader()
        const getOrders = async (inputData) => {
            const response = callGetOrders(inputData);
        }

        const getScadenziario = async (inputData) => {
            const response = callGetScadenziario(inputData);
        }

        getScadenziario(orderNumber);

        let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
        let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);
        setWebsiteId(currentUser.websiteId);

        if (currentUser.roles !== undefined) {
            if (!currentUser.roles.includes("FE_NonVisualizzaNetto")) setEnableShowNetPrices(true);
            if (currentUser.roles.includes("MB_AddToOrder")) setUserAddToOrder(true);
        }

        const getPaxes = async (inputData) => {
            const response = callGetPaxes(inputData);
        }

        getPaxes(orderNumber);

        setIsLoading(true);
        getOrders({ NumeroOrdine: orderNumber, pageNum: 1 });
    }, []);

    useEffect(() => {
        setIsLoadingPrint(false);
        setIsLoadingPrintCliente(false);
    }, [isFinishPrintPDF]);


    useEffect(() => {
        if (!isShowAfterAllCall)
            setIsShowAfterAllCall(true);
    }, [isShowAfterAllCall]);

    async function callGetTemplate(inputData) {
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Template/GetTemplate', requestOption);
        const response = await fetchedRes.json();

        if (response && response.success) {
            setTemplate(response.template);

            // set default selected products
            let tmpSelectedProducts = [];
            if (response.template.steps && response.template.steps.length > 0) {
                for (let i = 0; i < response.template.steps.length; i++) {
                    if (response.template.steps[i].templateData && response.template.steps[i].templateData.length > 0) {
                        for (let j = 0; j < response.template.steps[i].templateData.length; j++) {
                            if (response.template.steps[i].templateData[j].inclusion === 1 ||
                                (response.template.steps[i].templateData[j].inclusion === 2 && response.template.steps[i].templateData[j].isDefault === true)
                            ) {
                                tmpSelectedProducts.push(response.template.steps[i].templateData[j].templateDataId);
                            }
                        }
                    }
                }
            }
            setSelectedProducts(tmpSelectedProducts);
        }
        else {
            setIsError(true);
            console.error(response.errorMsg);
        }
    }

    async function callGetReservationDetail(inputData, step) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetReservationDetail', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            let productSubType = "";
            let idTipoProdotto = null;
            let productType = "";

            if (response.productType === configData.Settings.Products.Structure.IdTipoProdotto) {
                productType = "Structure";
                productSubType = "Hotel";
                idTipoProdotto = configData.Settings.Products.Structure.IdTipoProdotto;
            }
            else if (response.productType === configData.Settings.Products.Tour.IdTipoProdotto) {
                productSubType = "Tour";
                productType = "Tour";
                idTipoProdotto = configData.Settings.Products.Tour.IdTipoProdotto;
            }
            else if (response.productType === configData.Settings.Products.Activity.IdTipoProdotto) {
                productType = "Activity";
                if (response.productSubType === 12)
                    productSubType = "Tour";
                else if (response.productSubType === 41)
                    productSubType = "Transfer";
                else if (response.productSubType === 51)
                    productSubType = "Flight";
                else
                    productSubType = "Activity";

                idTipoProdotto = configData.Settings.Products.Activity.IdTipoProdotto;
            }
            else if (response.productType === configData.Settings.Products.CarRental.IdTipoProdotto) {
                productSubType = "CarRental";
                productType = "CarRental";
                idTipoProdotto = configData.Settings.Products.CarRental.IdTipoProdotto;
            }
            else if (response.productType === configData.Settings.Products.Transfer.IdTipoProdotto) {
                productSubType = "Transfer";
                productType = "Transfer";
                idTipoProdotto = configData.Settings.Products.Transfer.IdTipoProdotto;
            }
            else if (response.productType === configData.Settings.Products.Flight.IdTipoProdotto) {
                productSubType = "Flight";
                productType = "Flight";
                idTipoProdotto = configData.Settings.Products.Flight.IdTipoProdotto;
            }
            else if (response.productType === configData.Settings.Products.Train.IdTipoProdotto) {
                productSubType = "Train";
                productType = "Train";
                idTipoProdotto = configData.Settings.Products.Train.IdTipoProdotto;
            }
            else if (response.productType === configData.Settings.Products.Cruise.IdTipoProdotto) {
                productSubType = "Cruise";
                productType = "Cruise";
                idTipoProdotto = configData.Settings.Products.Cruise.IdTipoProdotto;
            }
            else {
                productSubType = "Generic";
                productType = "Generic";
                idTipoProdotto = configData.Settings.Products.Generic.IdTipoProdotto;
            }

            if (response.priceBar &&
                (
                    response.priceBar.status === "CONFIRMED" ||
                    response.priceBar.status === "ONREQUEST" ||
                    response.priceBar.status === "PRINTED" ||
                    response.priceBar.status === "REFUND" ||
                    response.priceBar.status === "PENALTY" ||
                    (response.priceBar.status === "MIX" && response.productType === configData.Settings.Products.Structure.IdTipoProdotto)
                )
            ) {
                arrProduct.push({
                    productSubType: productSubType,
                    productInfo: response,
                    productType: productType,
                });
            }
            else {
                totPratiche--;
            }

            if (totPratiche === arrProduct.length) {
                if (arrProduct.length > 0) {
                    let orderArr = [];

                    // ordina pratica
                    ordersTmp[0].pratiche.map((pratica, index) => {
                        for (let i = 0; i < arrProduct.length; i++) {
                            if (arrProduct[i].productInfo.priceBar && arrProduct[i].productInfo.priceBar.wsBookingId === pratica.wsBookingId) {
                                if (pratica.productType === "Flight") {
                                    let resTax = 0;
                                    arrProduct[i].productInfo.passengerFareComponents.map(x => {
                                        resTax = resTax + x.taxesPrice;
                                    });

                                    pratica.taxAmount = resTax; // aggiunta perchè manca nelle api
                                }

                                arrProduct[i].productInfo.stepId = pratica.stepId;

                                orderArr.push(arrProduct[i]);
                                break;
                            }
                        }
                    });

                    let finalArr = orderArr;
                    setItemStepPrint(finalArr);

                    setOrders(ordersTmp); //risettato perchè aggiunta sopa la taxAmount
                }
                else
                    setIsError(true);
            }


            if (arrProduct.length === 0) {
                setIsError(true);
            }

            const getDynamicNote = async (inputData) => {
                const response = callGetDynamicNote(inputData);
            }

            getDynamicNote({
                cultureCode: cultureName,
                websiteId: websiteId,
                pageId: 13,
                styleId: 4,
                productTypes: [idTipoProdotto],
                productCode: response.productId,
                checkInFrom: response.priceBar ? response.priceBar.startDate : null,
                checkInTo: response.priceBar ? response.priceBar.endDate : null,
                webDestinationIds: response.webDestinationIds ? response.webDestinationIds : null,
                supplierId: response.supplierId ? response.supplierId : null,
            });

            const getDynamicNoteTop = async (inputData) => {
                const response = callGetDynamicNoteTop(inputData);
            }

            getDynamicNoteTop({
                cultureCode: cultureName,
                websiteId: websiteId,
                pageId: 13,
                styleId: 3,
                productTypes: [idTipoProdotto],
                productCode: response.productId,
                checkInFrom: response.priceBar ? response.priceBar.startDate : null,
                checkInTo: response.priceBar ? response.priceBar.endDate : null,
                webDestinationIds: response.webDestinationIds ? response.webDestinationIds : null,
                supplierId: response.supplierId ? response.supplierId : null,
            });

            const getDynamicNoteStep = async (inputData, step) => {
                const response = callGetDynamicNoteStep(inputData, step);
            }

            getDynamicNoteStep({
                cultureCode: cultureName,
                websiteId: websiteId,
                pageId: 13,
                styleId: 5,
                productTypes: [idTipoProdotto],
                productCode: response.productId,
                checkInFrom: response.priceBar ? response.priceBar.startDate : null,
                checkInTo: response.priceBar ? response.priceBar.endDate : null,
                webDestinationIds: response.webDestinationIds ? response.webDestinationIds : null,
                supplierId: response.supplierId ? response.supplierId : null,
            }, step);
        }
        else { // se resituisce errore togliere elimare praticha dal totale
            totPratiche--;
        }

        setIsLoading(false);
    }

    async function callGetDynamicNote(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'dynamicnote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();

        if (response.dynamicNotes != null && response.dynamicNotes.length > 0) {
            setDynamicNotes(arr => [...arr, response.dynamicNotes]);
        }
    }

    async function callGetDynamicNoteTop(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'dynamicnote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();

        if (response.dynamicNotes != null && response.dynamicNotes.length > 0) {
            setDynamicNotesTop(arr => [...arr, response.dynamicNotes]);
        }
    }

    async function callGetDynamicNoteStep(inputData, step) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'dynamicnote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();

        if (response.dynamicNotes != null && response.dynamicNotes.length > 0) {
            setDynamicNotesStep(arr => [...arr, response.dynamicNotes]);
        }

        if (response.dynamicNotes != null && response.dynamicNotes.length > 0) {

            for (var i = 0; i < response.dynamicNotes.length; i++) {
                response.dynamicNotes[i]["step"] = step;

                if (tmpDynamicNotesStep.length > 0 && tmpDynamicNotesStep.find(x => x.noteId === response.dynamicNotes[i].noteId && x.step === step)) {
                }
                else {
                    tmpDynamicNotesStep.push(response.dynamicNotes[i]);
                }
            }

            // order by priority
            tmpDynamicNotesStep.sort(function (a, b) {
                return a.priority - b.priority;
            });

            setDynamicNotesStep(tmpDynamicNotesStep);
            setIsShowAfterAllCall(false);
        }
    }

    async function callGetOrders(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/getorders', requestOption);
        const response = await fetchedRes.json();

        if (!response.orders || response.orders.length === 0) {
            setIsError(true);
            return;
        }

        var arrayPratiche = [];
        if (response.orders[0].templateId) {
            callGetTemplate({
                templateId: response.orders[0].templateId,
                cultureCode: cultureName
            });
        }

        response.orders[0].praticheSteps.map((praticheStep, idx) => {
            praticheStep.pratiche.map((pratica, index) => {
                if (pratica.isActive || pratica.status === "REFUND") {
                    pratica.stepId = praticheStep.step;
                    arrayPratiche.push(pratica);
                }
            });
        });
        response.orders[0].pratiche = arrayPratiche;

        setOrders(response.orders);
        ordersTmp = response.orders;
        setProdottiEnabled(response.idModuliEnabled);

        if (response.orders.length === 0)
            return;

        if (response.orders[0].pratiche.length === 0) {
            setIsError(true);
            return;
        }

        const getReservation = async (inputData, step) => {
            const response = callGetReservationDetail(inputData, step);
        }

        response.orders[0].pratiche.map(pratica => {
            totPratiche = response.orders[0].pratiche.length;
            let tipoPratica;

            if (pratica.tipoPratica === configData.Settings.Products.Structure.Code)
                tipoPratica = configData.Settings.Products.Structure.IdTipoProdotto;
            else if (pratica.tipoPratica === configData.Settings.Products.Activity.Code)
                tipoPratica = configData.Settings.Products.Activity.IdTipoProdotto;
            else if (pratica.tipoPratica === configData.Settings.Products.CarRental.Code)
                tipoPratica = configData.Settings.Products.CarRental.IdTipoProdotto;
            else if (pratica.tipoPratica === configData.Settings.Products.Transfer.Code)
                tipoPratica = configData.Settings.Products.Transfer.IdTipoProdotto;
            else if (pratica.tipoPratica === configData.Settings.Products.Flight.Code)
                tipoPratica = configData.Settings.Products.Flight.IdTipoProdotto;
            else if (pratica.tipoPratica === configData.Settings.Products.Train.Code)
                tipoPratica = configData.Settings.Products.Train.IdTipoProdotto;
            else if (pratica.tipoPratica === configData.Settings.Products.Cruise.Code)
                tipoPratica = configData.Settings.Products.Cruise.IdTipoProdotto;
            else if (pratica.tipoPratica === configData.Settings.Products.Generic.Code)
                tipoPratica = configData.Settings.Products.Generic.IdTipoProdotto;
            else if (pratica.tipoPratica === configData.Settings.Products.Tour.Code)
                tipoPratica = configData.Settings.Products.Tour.IdTipoProdotto;

            getReservation({
                PNR: pratica.wsBookingId,
                ProductType: tipoPratica,
                Options: { WebsiteBaseUrl: configData.Settings.OldWebSite_BaseUrl },
            }, pratica.stepId);
        });

        setIsLoading(false);
    }

    async function callGetPaxes() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetOrderPaxes/' + orderNumber, requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setPaxes(response);
        };
    }

    async function callGetScadenziario(orderNumber) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}reservation/GetScadenziarioFromOrder/${orderNumber}`, requestOption);
        const response = await fetchedRes.json();

        if (response.rate && response.rate.length > 0) {
            let resultObj = response.rate.reduce(function (r, a) {
                let data = formatDateTime(a.expirePaymentDate, cultureName);
                r[data] = r[data] || [];
                r[data].push(a);

                return r;
            }, Object.create(null));

            var resultArr = Object.entries(resultObj);

            if (configData.Settings.Cart.ShowScadenziario)
                setScadenziario(resultArr);
        }

        setIsLoading(false);
    }


    function dowloadPDFAfterRender(filename, url) {
        downloadDocumentPDF(filename, url);

        setIsFinishPrintPDF(isFinishPrintPDF + 1);
        setIsDownloadPDF(true);
    }

    function onClickPrintPDF() {
        setPdfFileName(t("Print:ConfirmBooking") + "_" + t("Print:Agency") + "_" + orderNumber);
        setIsLoadingPrint(true);
        callPrintPDF();
    }

    function onClickPrintPDFCliente() {
        setPdfFileName(t("Print:ConfirmBooking") + "_" + t("Print:Client") + "_" + orderNumber);
        setIsLoadingPrintCliente(true);
        callPrintPDFCliente();
    }



    var idArrNote = [];
    function showTextStep(note) {
        let hideText = false;

        if (!idArrNote.find(x => x.id === note.noteId && note.step === x.step)) {
            idArrNote.push({ id: note.noteId, step: note.step });
        }
        else {
            hideText = true;
        }

        return hideText;
    }

    var showImage = true;
    function getShowImageStep() {
        return showImage;
    }

    function setShowImageStep(isShow) {
        showImage = isShow;
    }

    function callPrintPDF() {
        let myArr = generateQuotationHTMLConfirm(
            itemStepPrint,
            orders[0].bookDate,
            t,
            paxes,
            dynamicNotes,
            dynamicNotesTop,
            dynamicNotesStep,
            orders[0].pratiche,
            orders[0].praticheSteps,
            template,
            scadenziario,
            getCancelPolicyArr(),
            getNotePorductBigArr(),
            getTaxesFlightArr(),
            cultureName,
            false,
            isPrintHeaderDesc,
            isPrintItineraryResume,
            isPrintPaxes,
            isPrintAncillaries,
            isPrintCommissions,
            isPrintInclusions,
            isPrintScadenziario,
            isPrintCancelPolicy,
            isPrintNoteBig,
            true
        );

        setIsDownloadPDF(false);
        setMyHtml(myArr);
        setExtraInfoPrint({
            quotationId: orderNumber,
            paxes: paxes,
            startDate: orders[0].startDate,
            endDate: orders[0].endDate,
            titleFirstPage: t("Print:ConfirmBookingTitle"),
            isPrintCliente: false,
            numberLabel: t("MyBook:NOrder")
        });
    }

    function callPrintPDFCliente() {
        let myArr = generateQuotationHTMLConfirm(
            itemStepPrint,
            orders[0].bookDate,
            t,
            paxes,
            dynamicNotes,
            dynamicNotesTop,
            dynamicNotesStep,
            orders[0].pratiche,
            orders[0].praticheSteps,
            template,
            scadenziario,
            getCancelPolicyArr(),
            getNotePorductBigArr(),
            getTaxesFlightArr(),
            cultureName,
            true,
            isPrintHeaderDesc,
            isPrintItineraryResume,
            isPrintPaxes,
            isPrintAncillaries,
            isPrintCommissions,
            isPrintInclusions,
            isPrintScadenziario,
            isPrintCancelPolicy,
            isPrintNoteBig,
            true
        );

        setIsDownloadPDF(false);
        setMyHtml(myArr);
        setExtraInfoPrint({
            quotationId: orderNumber,
            paxes: paxes,
            startDate: orders[0].startDate,
            endDate: orders[0].endDate,
            titleFirstPage: t("Print:ConfirmBookingClientTitle"),
            isPrintCliente: true
        });
    }

    var isFirstProgrammaDiViaggio = true;
    function getIsFirstProgrammaDiViaggio() {
        return isFirstProgrammaDiViaggio;
    }

    function setProgrammaDiViaggioFalse() {
        if (isFirstProgrammaDiViaggio)
            isFirstProgrammaDiViaggio = false;
    }


    function getCancelPolicyArr() {
        let cancelPolicyArr = [];

        if (!template) { // non è tempalte
            itemStepPrint.map(qInfo => {
                setCancelPolicy(qInfo, cancelPolicyArr, t);

            });
        }
        else if (template.steps && template.steps.length > 0) { // è tempalte
            template.steps.map((step, stepKey) => {
                itemStepPrint.filter(x => x.productInfo.stepId === step.step).map((item, itemKey) => {
                    setCancelPolicy(item, cancelPolicyArr, t);
                });
            });
        }

        return cancelPolicyArr;
    }


    function getNotePorductBigArr() {
        var arrNotesProdcuts = [];

        if (!template) { // non è tempalte
            itemStepPrint.map(qInfo => {
                setNoteProductBig(qInfo, arrNotesProdcuts);

            });
        }
        else if (template.steps && template.steps.length > 0) { // è tempalte
            template.steps.map((step, stepKey) => {
                itemStepPrint.filter(x => x.productInfo.stepId === step.step).map((item, itemKey) => {
                    setNoteProductBig(item, arrNotesProdcuts);
                });
            });
        }

        return arrNotesProdcuts;
    }


    function getTaxesFlightArr() {
        var arrTaxes = [];

        if (!template) { // non è tempalte
            itemStepPrint.map(qInfo => {
                if (qInfo.productType === "Flight")
                    setFlightTaxesArr(qInfo.productInfo, arrTaxes);
            });
        }
        else if (template.steps && template.steps.length > 0) { // è tempalte
            template.steps.map((step, stepKey) => {
                itemStepPrint.filter(x => x.productInfo.stepId === step.step).map((item, itemKey) => {
                    if (item.productType === "Flight")
                        setFlightTaxesArr(item.productInfo, arrTaxes);
                });
            });
        }

        return arrTaxes;
    }

    return (
        <>
            <section className="confirmBookingSection bg-gray section bg-soft section-lg pt-5">
                {itemStepPrint !== undefined && itemStepPrint !== null && itemStepPrint.length !== 0 ?
                    <div className="container">
                        <div className="row document mb-3">
                            <div className="col-3">
                                <h4>{t("MyBook:OrderConfirm")}</h4>
                            </div>
                            <div className="col-5 text-left">
                                <a className="btn btn-secondary stepHeader text-white ml-3" onClick={onClickPrintPDFCliente} >
                                    {!isLoadingPrintCliente && <>
                                        <span className="far fa-file-pdf mr-2"></span>{t(`Button:Print`)}
                                    </>}
                                    {
                                        isLoadingPrintCliente && <>
                                            <div className="spinner-border spinner-border-sm text-white h7" role="status">
                                            </div>
                                            <span className="sr-only">Loading...</span>
                                        </>
                                    }
                                </a>
                            </div>
                            {!isDownloadPDF && <PDFDownloadLink className="lnkPDFDownload" document={<PrintPdfDocuments footerElements={footerElements} headerElements={headerElements} html={myHtml} agency={orders[0].agencyName} title={""} stylesheet={printCssStyle} extraInfo={extraInfoPrint} />} fileName={pdfFileName} >
                                {({ blob, url, loading, error }) => {
                                    if (!loading && blob) {
                                        dowloadPDFAfterRender(pdfFileName, URL.createObjectURL(blob));
                                    }
                                }}
                            </PDFDownloadLink>
                            }
                        </div>
                        <div className="card border-light p-4 p-md-5 position-relative">
                            <HeaderPDFPrint headerElements={headerElements} />

                            {template && template.photoGallery && template.photoGallery.items && template.photoGallery.items[0] &&
                                <TemplatePrintImageHeader
                                    image={template.photoGallery.items[0].url}
                                    onHandleIsPrint={setIsPrintHeaderImage}
                                />
                            }
                            {template &&
                                <div className="mt-3" style={{ display: 'grid' }}>
                                    <TemplatePrintHeaderDescription
                                        title={template.name}
                                        description={template.description}
                                        onHandleIsPrint={setIsPrintHeaderDesc}
                                    />
                                </div>
                            }

                            {/* note dinamiche top */}
                            <div className="mt-3" style={{ display: 'grid' }}>
                                {dynamicNotesTop && dynamicNotesTop.length > 0 &&
                                    <PrintDynamicNotes
                                        notes={dynamicNotesTop}
                                        cultureName={cultureName}
                                        onHandleIsPrint={setIsPrintDynamicNotesTop}
                                    />
                                }
                            </div>

                            {/* passeggeri */}
                            {paxes && paxes.length > 0 &&
                                <div className="mt-3" style={{ display: 'grid' }}>
                                    <PrintPaxes
                                        paxes={paxes}
                                        isConfirmPrint={true}
                                        onHandleIsPrint={setIsPrintPaxes}
                                    />
                                </div>
                            }

                            {/* itinerario */}
                            <PrintItineraryResume
                                paxes={paxes}
                                onHandleIsPrint={setIsPrintItineraryResume}
                                isConfirmPrint={true}
                                isNotStep={template ? false : true}
                            />


                            {isShowAfterAllCall && orders && orders.length > 0 && orders[0].praticheSteps && orders[0].praticheSteps.length > 0 && orders[0].praticheSteps.map((step, stepKey) =>
                                <>
                                    <TemplatePrintStepHeader
                                        key={step.step}
                                        title={step.stepDestinationName}
                                        isStep={true}
                                        stepIndex={step.step}
                                        itemSteps={itemStepPrint}
                                        minDate={step.dateFrom}
                                        maxDate={step.dateTo}
                                    />
                                    {itemStepPrint.filter(x => x.productInfo.stepId === step.step).map((item, itemKey) =>
                                        <>
                                            {itemKey === 0 && dynamicNotesStep && dynamicNotesStep.length > 0 &&
                                                <>
                                                    {setShowImageStep(true)}
                                                    {dynamicNotesStep.map(note =>
                                                        note.step === step.step &&
                                                        <>
                                                            <div className="mb-3" style={{ display: 'grid' }}>
                                                                <PrintProductsNotesStep
                                                                    notes={[note]}
                                                                    cultureName={cultureName}
                                                                    isShowImage={getShowImageStep()}
                                                                    hideText={showTextStep(note)}
                                                                />
                                                                {setShowImageStep(false)}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            }
                                            <div style={{ display: 'grid' }}>
                                                <PrintStepItem
                                                    key={step.step}
                                                    qInfo={item}
                                                    isPrintCliente={true}
                                                    isTemplate={true}
                                                    isNotStep={false}
                                                    isPrintPdf={false}
                                                />
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            {/* no template */}
                            {!template && itemStepPrint.map(qInfo => <>
                                {/* prodotti */}
                                <div style={{ display: 'grid' }}>
                                    <PrintStepItem
                                        qInfo={qInfo}
                                        isPrintCliente={true}
                                        isNotesHide={false}
                                        isPrintPdf={false}
                                    />
                                </div>
                            </>
                            )}
                            <div className="borderBottomColorCustom"></div>

                            {/* titolo quotazione */}
                            <div className="mt-3" style={{ display: 'grid' }}>
                                <PrintTitleSeparator
                                    title={t(`Template:Quotation`)}
                                />
                            </div>

                            {/* quote accessorie */}
                            <div className="mt-3" style={{ display: 'grid' }}>
                                <PrintAncillaries
                                    recapItems={itemStepPrint}
                                    cultureName={cultureName}
                                    isConfirmPrint={true}
                                    isPrintCliente={true}
                                    onHandleIsPrint={setIsPrintAncillaries}
                                />
                            </div>


                            {/* Tabella Prezzi */}
                            <div className="mt-3" style={{ display: 'grid' }}>
                                <PrintCommissionsDetailCompact
                                    marginItems={orders[0].pratiche}
                                    taxesFlightArr={getTaxesFlightArr()}
                                    cultureName={cultureName}
                                    paxes={paxes}
                                    isPrintCliente={true}
                                    onHandleIsPrint={setIsPrintCommissions}
                                />
                            </div>


                            {/* quota comprende, non comprende */}
                            {template && template.inclusions && template.inclusions.length > 0 &&
                                template.inclusions.map(inclusion =>
                                    <div className="mt-3 card bg-white p-2 shadow" style={{ display: 'grid' }}>
                                        <TemplatePrintInclusion
                                            inclusion={inclusion}
                                            onHandleIsPrint={setIsPrintInclusions}
                                        />
                                    </div>
                                )
                            }

                            {/* note dinamiche */}
                            {dynamicNotes && dynamicNotes.length > 0 && <>
                                <div className="mt-3" style={{ display: 'grid' }}>
                                    <PrintDynamicNotes
                                        notes={dynamicNotes}
                                        cultureName={cultureName}
                                        onHandleIsPrint={setIsPrintDynamicNotes}
                                    />
                                </div>
                            </>
                            }

                            {/* note big */}
                            <div className="mt-3" style={{ display: 'grid' }}>
                                <PrintProductsNotes
                                    arrNotesProdcuts={getNotePorductBigArr()}
                                    onHandleIsPrint={setIsPrintNoteBig}
                                />
                            </div>

                            {/* programma di viaggio, note extra */}
                            {itemStepPrint.map(qInfo => <>
                                {qInfo.productType === 'Activity' && qInfo.productInfo.option.descriptions.length > 0 &&
                                    <>
                                        <div className="mt-3" style={{ display: 'grid' }}>
                                            <PrintStepHeader
                                                title={qInfo.productInfo.name}
                                                PNR=''
                                                totaleViaggio=''
                                                iconName='directions_walk'
                                                cultureName={cultureName}
                                                onHandleIsPrint={setIsPrintStructureNotes}
                                                isShowPrintButton={getIsFirstProgrammaDiViaggio()}
                                            />
                                        </div>
                                        {setProgrammaDiViaggioFalse() /* viene viusualizzata la prima e le altre no */}
                                        {qInfo.productInfo.option.descriptions.filter(x => x.isPrint).map((descToPrint, idx) =>
                                            <TourStaticPrint
                                                titleStep={qInfo.productInfo.name}
                                                descriptions={replaceAllStyleElements(descToPrint.description)}
                                                title={descToPrint.title}
                                                tourName={idx === 0 ? qInfo.productInfo.name : ''}

                                            />
                                        )}
                                    </>
                                }
                                {/* note extra struttura */}
                                {qInfo.productType === 'Structure' && qInfo.productInfo.descriptions.length > 0 &&
                                    <>
                                        <div className="mt-3" style={{ display: 'grid' }}>
                                            <PrintStepHeader
                                                title={qInfo.productInfo.name}
                                                PNR=''
                                                totaleViaggio=''
                                                iconName='info_hotel'
                                                cultureName={cultureName}
                                                onHandleIsPrint={setIsPrintStructureNotes}
                                                isShowPrintButton={getIsFirstProgrammaDiViaggio()}
                                            />
                                        </div>
                                        {setProgrammaDiViaggioFalse()}
                                        {qInfo.productInfo.descriptions.filter(x => x.isPrint).map((descToPrint, idx) =>
                                            <TourStaticPrint
                                                titleStep={qInfo.productInfo.name}
                                                descriptions={replaceAllStyleElements(descToPrint.description)}
                                                title={descToPrint.title}
                                                tourName={idx === 0 ? qInfo.productInfo.name : ''}
                                            />
                                        )}
                                    </>
                                }
                                {/* note extra cruise
                                {qInfo.productType === 'Cruise' && qInfo.productInfo.descriptions.length > 0 &&
                                    <>
                                        <div className="mt-3" style={{ display: 'grid' }}>
                                            <PrintStepHeader
                                                title={qInfo.productInfo.itineraryName}
                                                PNR=''
                                                totaleViaggio=''
                                                iconName=''
                                                cultureName={cultureName}
                                                onHandleIsPrint={setIsPrintStructureNotes}
                                                isShowPrintButton={getIsFirstProgrammaDiViaggio()}
                                            />
                                        </div>
                                        {setProgrammaDiViaggioFalse()}
                                        {qInfo.productInfo.descriptions.filter(x => x.isPrint).map((descToPrint, idx) =>
                                            <TourStaticPrint
                                                titleStep={qInfo.productInfo.name}
                                                descriptions={replaceAllStyleElements(descToPrint.description)}
                                                title={descToPrint.title}
                                                tourName={idx === 0 ? qInfo.productInfo.name : ''}
                                            />
                                        )}
                                    </>
                                 */}
                            </>
                            )}
                        </div>
                    </div>
                    :
                    !isError ?
                        <Loading textMsg={t("MyBook:Loading")} />
                        :
                        <div className="text-center h4">
                            <Error textMsg={t("MyBook:OrderNotAvailable")} />
                        </div>
                }
            </section>
        </>
    );
}
