import React from 'react';
import configData from "../../../appsettings.json";
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../Common/Modal.js';
import { formatPrice, formatDateTime, getQuotationStatusClass, getQuotationItemStatusClass, getQuotationItemStatus } from '../../../js/Utils.js';
import { M3IconProductType } from '../../Common/M3IconProductType.js';

export const QuotationTOLight = ({ quotation, quotationDetail, isQuotationTO }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const renderProducts = (items) => {
        return (
            <>
                {items.map(item =>
                    <tr key={item.quotationItemId} data-quotationitemid={item.quotationItemId}>
                        <td scope="row">
                            {<M3IconProductType productType={item.productType} productSubType={item.productSubType} />}
                        </td>
                        <td>
                            <div className="d-flex align-items-lg-start">
                                {item.productName}
                            </div>
                        </td>
                        <td>{formatDateTime(item.startDate, cultureName, { noYear: true })}</td>
                        <td>{formatDateTime(item.endDate, cultureName, { noYear: true })}</td>
                        {
                            isVisiblePrice() && <td>{formatPrice(item.price, item.currency, cultureName)}</td>
                        }
                        {isQuotationTO && <td className={`d-flex align-items-end status ${getQuotationItemStatusClass(item.status)}`}>>{t(getQuotationItemStatus(item.status))}</td>}
                    </tr>
                )}
            </>
        );
    }

    function isVisiblePrice() {
        return !quotationDetail || !isQuotationTO || (quotationDetail.showPriceQuotationTOADV && isQuotationTO);
    }

    const renderProdottiCorrelati = (items) => {
        return (
            <>
                {items.map(item =>
                    <tr key={item.quotationItemId} data-quotationitemid={item.quotationItemId}>
                        <td scope="row">
                            {<M3IconProductType productType={item.productType} productSubType={item.productSubType} />}
                        </td>
                        <td>
                            <div className="d-flex align-items-lg-start">
                                {item.productName}
                            </div>
                        </td>
                        <td> </td>
                        <td> </td>
                        {
                            isVisiblePrice() && <td>{formatPrice(item.price, item.currency, cultureName)}</td>
                        }
                        {isQuotationTO && <td className={`d-flex align-items-end status`}> </td>}
                    </tr>
                )}
            </>
        );
    }

    async function handleOnConfirm() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'QuotationTO/ReloadQuotationToCart/' + quotation.quotationId, requestOption);
        const response = await fetchedRes.json();

        let outObj = {
            success: response.success,
            bodyHtml: response.success
                ? "<p><span class='penalty free'>" + t("MyBook:QuotationLoadWithSuccess") +"</span></p>"
                : "<p><span class='penalty'>"+ t("MyBook:ErrorDuringLoadingQuotation") +"</span></p>"
        };

        if (response.success)
            window.location.href = `${configData.Settings.Base_Url}cart?qId=${quotation.quotationId}`;
        else {
            return outObj;
        }
       
    }

    async function handleOnOpen() {
        let outObj = {
            success: true,
            bodyHtml: "<p><span>"+ t("MyBook:AttentionProductAreInCart")+ (isQuotationTO ? 'N. ' + quotation.quotationId : quotation.name) + ".<br/><br/>" + t("MyBook:ClickToGoHaed") +".</span></p>"
        };

        return outObj;
    }

    const handleOnClose = () => {
        return true;
    }

    const modalSettings = {
        id: 'ReloadCart_' + quotation.quotationId,
        title: 'Ricarica Preventivo ' + (isQuotationTO ? 'N. ' + quotation.quotationId : quotation.name),
        body: null,
        lblClose: 'Chiudi',
        lblConfirm: 'Conferma',
        isLoading: true,
        loadingMsg: t("MyBook:YouGoToCart"),
        showBtnConfirm: true,
        showBtnClose: true,
        fnOnConfirm: handleOnConfirm,
        fnOnOpen: handleOnOpen,
        fnOnClose: handleOnClose
    };

    return (
        <>
            <div className="card mb-4">
                <div className="row no-gutters">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
                        {/*Quotation Header*/}
                        <div>
                            <span className="h5 m-4 pt-5">
                                <label>{t("MyBook:NQuotation")}:</label> {quotation.quotationId} <label>del</label> {formatDateTime(quotation.created, cultureName, { twoDigits: true })}
                                <label>{t("MyBook:Quotation")}:</label> {quotation.name} <label>del</label> {formatDateTime(quotation.created, cultureName, { twoDigits: true })}
                            </span>
                        </div>
                        <div>
                            {isQuotationTO && <span className="h5 m-4 pt-5"><label>{quotation.name}</label></span>}
                        </div>

                        {/*Pratiche*/}
                        <div className="p-0 p-md-4 mb-4">
                            {quotation.items.length > 0
                                ? (
                                    <>
                                        <table className="mybook-table table-hover p-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{t("MyBook:Type")}</th>
                                                    <th scope="col">{t("MyBook:Product")}</th>
                                                    <th scope="col">{t("MyBook:Start")}</th>
                                                    <th scope="col">{t("MyBook:End")}</th>
                                                    {
                                                        isVisiblePrice() && <th scope="col">{t("MyBook:Value")}</th>
                                                    }
                                                    {isQuotationTO && <th scope="col">{t("MyBook:State")}</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderProducts(quotation.items)}
                                                {quotation.prodottiCorrelati && quotation.prodottiCorrelati.length > 0 && renderProdottiCorrelati(quotation.prodottiCorrelati)}
                                            </tbody>
                                        </table>
                                    </>
                                )
                                : (<span style={{ fontSize: '12px' }}>{t("MyBook:NotLoadProductQuotationText")}.</span>)
                            }
                        </div>
                    </div>

                    {/*Price Bar*/}
                    <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 mybook-priceBar status unset bg-gray-300">
                        <div className="d-table w-100 h-100">
                            <div className="d-table-row h-50">
                                <div className="d-table-cell align-top">
                                    <span className="text-muted font-small d-block">{t("MyBook:TotalQuotation")}</span>
                                    <h3>{formatPrice(quotation.totalPrice, quotation.currency, cookies.get("CultureName"))}</h3>
                                    <div>
                                        {isQuotationTO && <h6 className={`status ${getQuotationStatusClass(quotation.status)}`}>{t(getQuotationItemStatus(quotation.status))}</h6>}
                                    </div>
                                    <div>
                                        <p><span><label>{t("MyBook:StartDate")}:</label>{formatDateTime(quotation.startDate, cultureName, { twoDigits: true })}</span></p>
                                        <p><span><label>{t("MyBook:EndDate")}:</label>{formatDateTime(quotation.endDate, cultureName, { twoDigits: true })}</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-table-row h-50">
                                <div className="d-table-cell align-bottom">
                                    <div className="Structure-btn-seleziona">
                                        {isQuotationTO && <a href={`quotationtoadv/${quotation.quotationId}`} target="_blank" className="badge bg-primary text-decoration-none h085 p-2 mt-2 mb-1 w-80">{t("MyBook:Detail")}</a>}
                                        {!isQuotationTO && <a href={`quotation/${quotation.quotationId}`} target="_blank" className="badge bg-primary text-decoration-none h085 p-2 mt-2 mb-1 w-80">{t("MyBook:Detail")}</a>}
                                        {!isQuotationTO && <a href="" className="badge bg-primary text-decoration-none h085 p-2 mt-2 mb-1 w-80" data-bs-toggle="modal" data-bs-target={'#ReloadCart_' + quotation.quotationId}>{t("MyBook:ReloadQuotation")}</a>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Reload Cart Modal*/}
            <Modal {...modalSettings} />
        </>
    );
}
