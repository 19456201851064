import React, { useState, useEffect } from 'react';
import { InputSearchDestinationContainer } from './InputSearchDestinationContainer.js';
import { useTranslation } from 'react-i18next';

export const InputSearchDestinationModal = ({ modalId, productType, index, callOnSelectDestination, rules }) => {
    const { t } = useTranslation();
    const [selectDestination, setSelectDestination] = useState({});
    const [showDestination, setShowDestination] = useState(false);

    function onSelectSearchDestination(newSelectDestination, index = null) {
        setSelectDestination(newSelectDestination);
    }

    function onConfirmSelectDestination() {
        let cleanSelectDestination = { ...selectDestination };
        delete cleanSelectDestination.CssStyle;
        delete cleanSelectDestination.IsGroupTitle;

        if (index || index === 0)
            callOnSelectDestination(selectDestination, index);
        else
            callOnSelectDestination(selectDestination);

    }

    function onOpenPanelDestination() {
        setShowDestination(true);
    }

    return (<>
        <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby={modalId + "Label"} aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={modalId + "Label"}>Seleziona Destinazione</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <InputSearchDestinationContainer
                                defaultSelectDestination={selectDestination}
                                productType={productType}
                                onSelectSearchDestination={onSelectSearchDestination}
                                onOpenPanelDestination={onOpenPanelDestination}
                                closeExternal={showDestination}
                                extraOptions={{ rules: rules }}
                                modeView="MODAL" />
                        </div>
                        <div className='modal-footer'>
                            <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t('Button:Close')}</button>
                            <button type="button" className="btn bt-sm tp-btn-confirm" data-bs-dismiss="modal" onClick={onConfirmSelectDestination}>Conferma</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}
