import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import configData from "../../../../appsettings.json";
import { searchCarpetParameter } from '../../../../js/Constant';
import { getCurrentUserFromJwtToken } from '../../../../js/Utils.js';
import { M3Icon } from "../../../Common/M3Icon";
import { Loading } from '../../../Common/Loading';
import { Error } from '../../../Common/Error';
import { ProductItemNotFound } from '../../../Product/Common/ProductItemNotFound';
import { ProductFilter } from '../../../Product/Common/Filter/ProductFilter';
import { ProductFilterByName } from '../../../Product/Common/Filter/ProductFilterByName';
import { ProductFilterByPrice } from '../../../Product/Common/Filter/ProductFilterByPrice';
import { TransferItemList } from '../../../Product/Transfer/components/TransferItemList';


export const TransferList = ({ carpetId, cartItemId, clientFlowId, extraParam, step, onAddProduct,saveIntervalCarpetPing }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const cultureName = cookies.get("CultureName");

    // carpet
    const [carpetStatus, setCarpetStatus] = useState(null);
    const [carpetDataStatus, setCarpetDataStatus] = useState(null);
    const [carpetTime, setCarpetTime] = useState(1);
    const [intervalCarpetPing, setIntervalCarpetPing] = useState([]);

    // page
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingFilter, setIsLoadingFilter] = useState(false);
    const [isError, setIsError] = useState(false);
    const [items, setItems] = useState({});
    const [showPriceBar, setShowPriceBar] = useState(false);
    const [isLoadingListResult, setIsLoadingListResult] = useState(true);
    const [modalOpenProductId, setModalOpenProductId] = useState(null);
    const [quotationInfo, setQuotationInfo] = useState(null);

    // roles
    const [showCost, setShowCost] = useState(false);
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);
    const [enableCanAddToCart, setEnableCanAddToCart] = useState(false);

    // filtri
    const [isResetSelectedItem, setIsResetSelectedItem] = useState(false);
    const [isOrderAZ, setIsOrderAZ] = useState(true);
    const [isChangeAzDisabled, setIsChangeAzDisabled] = useState(true);
    const [filterValues, setFilterValues] = useState(null);
    const [rangePriceInfo, setRangePriceInfo] = useState({ min: "0", max: "5000", step: "50", currency: "EUR" });

    const [filterSelected, setFilterSelected] = useState({
        transferName: null,
        departureDestination: [],
        arrivalDestination: [],
        vehicleType: [],
        suppliers: [],
        rangePrice: null,
        pageNumber: 0,
        orderBy: searchCarpetParameter.transfer.orderBy
    });

    // paging
    const itemsPerPage = searchCarpetParameter.hotel.listMaxItem;
    const [pageCount, setPageCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(0);


    useEffect(() => {
        setIsLoading(true);
        setIsError(false);
        getCarpetStatus();

        let sCurrentUser = localStorage.getItem("CurrentUser");
        if (!sCurrentUser)
            return;

        let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
        let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);

        if (currentUser.roles) {
            if (!currentUser.roles.includes("FE_NonVisualizzaNetto")) setEnableShowNetPrices(true);
            if (currentUser.roles.includes("FE_ShowCost")) setShowCost(true);
        }
    }, []);

    useEffect(() => {
        switch (carpetStatus) {
            case 'Processing':
            case 'Queued':
            case 'Completed':
                break;

            case 'Error':
                setIsError(true);
                setIsLoading(false);
                break;

            default:
                break;
        }
    }, [carpetStatus]);

    useEffect(() => {
        switch (carpetDataStatus) {
            case 'Completed':
                callGetNewData();
                clearCarpetAllInterval();
                break;

            case 'NewData':
                callGetNewData();
                break;

            case 'Processing':
            case 'NoChanges':
                let interval = setInterval(() => {
                    getCarpetStatus();
                }, searchCarpetParameter.hotel.carpetPingMs);
                addInterval(interval);
                break;

            default:
                break;
        }
    }, [carpetDataStatus]);

    useEffect(() => {
        saveIntervalCarpetPing(intervalCarpetPing);
    }, [intervalCarpetPing])

    // API carpet
    async function callGetNewData() {
        if (filterValues === null || carpetStatus === 'Completed') {
            getTransferListFilters();
        }

        setIsLoading(false);
        setIsError(false);

        // conto quante chiamate sto facendo
        // ogni chiamata avviene dopo un tot di tempo, quindi in base al numero di chiamate so quanto è passato
        let callTicks = carpetTime * searchCarpetParameter.transfer.carpetPingMs;
        let finishTime = callTicks > searchCarpetParameter.transfer.carpetMaxTimeMs
        setCarpetTime(carpetTime + 1);

        if (carpetStatus !== 'Completed' && !finishTime) {
            setIsLoadingFilter(true);

            let interval = setInterval(() => {
                getCarpetStatus();
            }, searchCarpetParameter.transfer.carpetPingMs);
            addInterval(interval);

        } else {
            setIsLoadingFilter(false);
        }
    }

    async function getTransferList(filterSelectedUpdate, pageNumber) {
        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetId,
            pageNumber: pageNumber,
            pageSize: searchCarpetParameter.hotel.listMaxItem,
            showCost: showCost,
            transferName: filterSelectedUpdate.transferName,
            departureDestination: filterSelectedUpdate.departureDestination,
            arrivalDestination: filterSelectedUpdate.arrivalDestination,
            VehicleType: filterSelectedUpdate.vehicleType,
            suppliers: filterSelectedUpdate.suppliers,
            rangePrice: filterSelectedUpdate.rangePrice,
            orderBy: filterSelectedUpdate.orderBy
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestParam) };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}Transfer/GetTransferList`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setItems(response);
            renderPaginator(response.totalTransfers);

            if (response.permissions)
                setEnableCanAddToCart(response.permissions['ADDTOCART']);
        }

        if (carpetDataStatus === 'Completed') {
            setIsLoadingFilter(false);
        }

        setIsLoadingListResult(false);
    }

    async function getTransferListFilters() {
        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetId
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestParam) };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}Transfer/GetTransferListFilters`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setFilterValues(response);

            if (carpetDataStatus === 'Completed' && response.rangePricesInfo) {
                setRangePriceInfo({
                    ...rangePriceInfo,
                    min: response.rangePricesInfo.min,
                    max: response.rangePricesInfo.max,
                    currency: response.rangePricesInfo.name
                });
            }

            getTransferList(filterSelected, 0);
        }
    }


    async function getCarpetStatus() {
        console.log(`Call GetCarpetStatus [CarpetId = ${carpetId}]`);

        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}carpet/getcarpetstatus/${carpetId}`, requestOption);
        if (!fetchedRes.ok) {
            setIsError(true);
        } else {
            const response = await fetchedRes.json();

            let dataStatus = response.dataStatus;
            let status = response.status;
            if (response.success) {
                setCarpetStatus(status);

                if (status !== 'Error') {
                    // messo per richiamare il getCarpetStatus, nel caso riceva piu volte lo status NoChanges.
                    // lo status Processing esiste solo lato FE
                    if (status !== carpetDataStatus)
                        setCarpetDataStatus(dataStatus);
                    else
                        setCarpetDataStatus('Processing');
                }


            } else {
                setIsError(true);
            }

            console.log(`Status ${status}`);
            console.log(`Data Status ${dataStatus}`);
        }

    }

    function addInterval(intervalId) {
        let arr = [...intervalCarpetPing];
        arr.push(intervalId);
        setIntervalCarpetPing(arr);
    }

    function clearCarpetAllInterval() {
        for (let i = 0; i < intervalCarpetPing.length; i++) {
            let intId = intervalCarpetPing[i];
            clearInterval(intId);
        }

        setIntervalCarpetPing([]);
    }

    // metodi filtro
    function callFilterValues(filterSelectedUpdate) {
        setIsLoadingFilter(true);
        getTransferList(filterSelectedUpdate, 0);
    }

    const onChangeAZ = (e) => {
        setIsOrderAZ(!isOrderAZ);

        if (filterSelected.orderBy === "3") { // nome az
            let filterSelectedUpdate = { ...filterSelected, orderBy: "2" };
            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
        else if (filterSelected.orderBy === "2") { // nome za
            let filterSelectedUpdate = { ...filterSelected, orderBy: "3" };
            setFilterSelected(filterSelectedUpdate);
            callFilterValues(filterSelectedUpdate);
        }
    }

    function onChangeSortBy(event) {
        let orderByValue = event.target.selectedOptions[0].value;

        let filterSelectedUpdate = { ...filterSelected, orderBy: orderByValue };

        if (orderByValue === "0" || orderByValue === "1") {
            setIsChangeAzDisabled(true);
        }
        else {
            setIsChangeAzDisabled(false);
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilter(selectedItems, type) {
        let filterSelectedUpdate = { ...filterSelected };

        switch (type) {
            case 'DepartureDestination':
                filterSelectedUpdate.departureDestination = selectedItems;
                break;

            case 'ArrivalDestination':
                filterSelectedUpdate.arrivalDestination = selectedItems;
                break;

            case 'VehicleType':
                filterSelectedUpdate.vehicleType = selectedItems;
                break;

            case 'TransferSuppliers':
                filterSelectedUpdate.suppliers = selectedItems;
                break;

            default:
                break;
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilterName(name) {
        let filterSelectedUpdate = { ...filterSelected, transferName: name }
        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilterPrice(rangePrice) {
        let filterSelectedUpdate = { ...filterSelected, rangePrice: rangePrice }
        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onClearFilter() {
        let filterSelectedUpdate = {
            ...filterSelected,
            departureDestination: [],
            arrivalDestination: [],
            vehicleType: [],
            suppliers: [],
            rangePrice: null
        }

        setIsResetSelectedItem(!isResetSelectedItem);
        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function getFilterOrderByValue(orderValue) {
        let res = null;

        searchCarpetParameter.hotel.orderByValues.forEach(function (order) {
            if (order.descendingValue === parseInt(orderValue)) {
                res = order.value;
            }
        });

        if (res == null) {
            res = orderValue;
        }

        return res;
    }

    // metodi paginator
    const handlePageClick = async e => {
        setPageSelected(e.selected);

        let filterSelectedUpdate = { ...filterSelected, pageNumber: e.selected + 1 }
        setFilterSelected(filterSelectedUpdate);
        getTransferList(filterSelected, e.selected);
    };

    function renderPaginator(totalItems) {
        setPageCount(Math.ceil(totalItems / itemsPerPage));
    }

    const onChangeShowPriceBar = () => {
        setShowPriceBar(!showPriceBar);
    }

    async function onClickSearch(internalId, filters = null) {
        console.log("Transfer InternalId: " + internalId);

        // add to cart
        let addToCartRq = {
            cultureCode: cultureName,
            carpetId: carpetId,
            selectedInternalId: internalId,
            step: step,
            cartItemId: cartItemId
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(addToCartRq) };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}Transfer/addtotrippie`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (!response || !response.success) {
                console.log(response);
            } else {
                onAddProduct(response.transferRecap);
            }
        } else {
            console.log(fetchedRes);
        }
    }


    return (
        <>
            <div className="bg-bluesoft-100">
                <div className="container">
                    <div className="row mt-4">
                        {isLoading && <div className="col-12"><Loading textMsg={t('Product:Transfer:LoadingList')} /></div>}
                        {!isLoading && isError && <div className="col-12"><Error textMsg={t('Product:Transfer:Error')} /></div>}

                        {
                            !isLoading && !isError && items &&
                            <>
                                {/* Total Transfers */}
                                <div className="col-12 col-sm-12 col-md-5">
                                    <div className="d-table h-100">
                                        <div className="d-table-row ">
                                            <div className="d-table-cell align-bottom pb-3 h5">
                                                <data m3lab="Product:Transfer.NTransfer">
                                                    {t(`Product:Transfer:NTransfer`)}:
                                                </data>
                                                <span className="ml-2">
                                                    <var>{items.totalTransfers}</var>
                                                    {isLoadingFilter && <div className="spinner-border spinner-border-sm text-primary ml-2" role="status"></div>}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-7 pt-1 text-right d-none d-sm-block">
                                    <div className="row">
                                        <div className="col-7 text-right pr-0">
                                            <span className="input-group mb-3 text-right">
                                                <select className="form-select border-0" aria-label="Disabled select example" value={filterSelected.orderBy} onChange={(e) => { onChangeSortBy(e); }}>
                                                    <option value="">
                                                        <data m3lab="General.Order.OrderBy">{t(`General:Order:OrderBy`)}</data>
                                                    </option>
                                                    {filterValues &&
                                                        searchCarpetParameter.transfer.orderByValues.map((item, key) => {
                                                            return <option key={key} value={item.value}>
                                                                <data m3lab={item.label} >{t(item.label)}</data>
                                                            </option>
                                                        })
                                                    }
                                                </select>
                                                <button className="btn btn-neutral m-0 p-0 pr-1 d-inline" type="button" id="button-addon2">
                                                    <data m3ico="SortBy icon-15 text-bluesoft">
                                                        <M3Icon iconName="SortBy" externalClass="icon-15 text-bluesoft p-1" hasLabel={false} />
                                                    </data>
                                                </button>
                                            </span>
                                        </div>
                                        <div className="col-5 pl-0">
                                            <button data-bs-toggle="modal" data-bs-target="#filterModal" className="openFilterButton p-1 px-2 pt-2 rounded border-0 shadow tp-btn-search">
                                                {t(`General:OpenFilter`)}
                                            </button>

                                            {
                                                enableShowNetPrices && <button className={(showPriceBar ? "tp-btn-select" : "tp-btn-outline-select") + " ms-2 p-1 px-2 pt-2 rounded border-0 shadow"} m3rool="f_ShowPriceBar" onClick={(e) => { onChangeShowPriceBar() }}>
                                                    <data m3lab="Button.Net">
                                                        {t("Button:Net")}
                                                    </data>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                { /* Colonna Items */}
                                <div className="col-12 col-sm-12 pt-1 d-none d-sm-block">
                                    {!isLoadingListResult && items && items.transfers && items.transfers.length > 0 &&
                                        <div className="row">
                                            {
                                                items.transfers && items.transfers.map((item, key) => {
                                                    return <TransferItemList key={key}
                                                        item={item}
                                                        showPriceBar={showPriceBar}
                                                        showCost={showCost}
                                                        modalOpenProductId={setModalOpenProductId}
                                                        enableCanAddToCart={enableCanAddToCart}
                                                        quotationInfo={quotationInfo}
                                                        onClickSearch={onClickSearch}
                                                    />
                                                })
                                            }
                                        </div>
                                    }

                                    {isLoadingListResult && <div className="text-center"><Loading textMsg={t('Product:Transfer:LoadingList')} /></div>}
                                    {!isLoadingListResult && (!items || (!items.transfers || items.transfers.length === 0)) && <ProductItemNotFound label="Nessun transfer trovato" />}
                                </div>

                                {/* Paginator */}
                                {items && items.structures && items.structures.length > 0 &&
                                    <>
                                        <div className="col-12 col-sm-3 col-md-3 col-lg-39 col-xl-3 pt-1"></div>
                                        <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 pt-1">
                                            <nav aria-label="Page navigation comments" className="mt-4">
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t('pagination:nextLabel')}
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel={t('pagination:previousLabel')}
                                                    renderOnZeroPageCount={null}
                                                    forcePage={pageSelected}

                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    marginPagesDisplayed={2}
                                                    containerClassName="pagination justify-content-center"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    activeClassName="active"
                                                />
                                            </nav>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>

            </div>
            <div className="modal tp-search-input fade" id="filterModal" tabIndex="-1" aria-labelledby="EngineModifyActivty" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen-xxl-down">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <div className="h4 text-center w-100 mt-2">
                                {t('SearchEngine:UpdateSearch')}
                            </div>
                            <button type="button" className="btn-close mt-2 pr-4" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body bg-gray-300">

                            {filterValues && <>
                                {/* Name */}
                                <ProductFilterByName
                                    items={filterValues.transferNames}
                                    label={t("SearchEngine:FindTransfer")}
                                    type="TransferName"
                                    onApplyFilter={onApplyFilterName}
                                    resetSelectedItem={isResetSelectedItem} />

                                {/* Departure Destination */}
                                <ProductFilter
                                    items={filterValues.departureDestinations}
                                    label="Product:Transfer:Filter:DepartureDestination"
                                    type="DepartureDestination"
                                    onApplyFilter={onApplyFilter}
                                    resetSelectedItem={isResetSelectedItem} />

                                {/* Arrival Destination */}
                                <ProductFilter
                                    items={filterValues.arrivalDestinations}
                                    label="Product:Transfer:Filter:ArrivalDestination"
                                    type="ArrivalDestination"
                                    onApplyFilter={onApplyFilter}
                                    resetSelectedItem={isResetSelectedItem} />

                                {/* Vehicle Type */}
                                <ProductFilter
                                    items={filterValues.vehiclesTypes}
                                    label="Product:Transfer:Filter:VehicleType"
                                    type="VehicleType"
                                    onApplyFilter={onApplyFilter}
                                    resetSelectedItem={isResetSelectedItem} />

                                {/* Price */}
                                <ProductFilterByPrice
                                    rangePriceInfo={rangePriceInfo}
                                    onApplyFilter={onApplyFilterPrice}
                                    resetSelectedItem={isResetSelectedItem} />

                                { /* Provider */}
                                <ProductFilter
                                    items={filterValues.suppliers}
                                    label="Product:Transfer:Filter:Provider"
                                    type="TransferSuppliers"
                                    onApplyFilter={onApplyFilter}
                                    resetSelectedItem={isResetSelectedItem} />
                            </>}
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}