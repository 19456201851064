import React from 'react';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";
import { CarSingleExtra } from './CarSingleExtra';

export const CarExtrasLoco = ({ option }) => {
    const { t } = useTranslation();

    return (
        <>
            {option && option.spotChargesInfo && option.spotChargesInfo.onSpotCharges && option.spotChargesInfo.onSpotCharges.length > 0 &&
                <div className="card border-light p-1 mt-2 shadow ">
                    <div className="row">
                        <div className="col-1 text-center">
                            <h3 className="mb-0">
                                <data m3ico="FlightServices mr-2 h3r">
                                    <M3Icon iconName="FlightServices" externalClass="mr-2 h3r" />
                                </data>
                            </h3>
                        </div>
                        <div className="col-10 text-left d-flex float-start p-1">
                            <div className="h5 mr-4">
                                <data m3lab="Product.RentCar.ExtraOnPickup">
                                    {t('Product:RentCar:ExtraOnPickup')}
                                </data>
                            </div>
                            <data m3lab="Product.RentCar.PayOnPickup">
                                <label className="fw-bold"> {t('Product:RentCar:PayOnSpotCharges')}:</label>
                            </data>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row m-1 pr-4 bg-gray-300">
                            <div className="col-1 p-2">
                            </div>
                            <div className="col-7 p-2">
                                {t('Product:RentCar:Description')}
                            </div>
                            <div className="col-2 text-center p-2"></div>
                            <div className="col-2 text-right p-2">
                                <span className="small">
                                    {t('Product:RentCar:Price')}
                                </span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                {option.spotChargesInfo.onSpotCharges.map(extra =>
                                    <div className="row pr-3 border-bottom">
                                        <CarSingleExtra
                                            extra={extra}
                                            isReadOnly={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}