import React, { useState, useEffect } from 'react';
import { M3Icon } from './M3Icon';

export const M3StructureStars = ({ category }) => {
    let starIcon = <data m3ico="Star text-warning icon-15"><M3Icon iconName="Star" externalClass="text-warning icon-15" /></data>;
    
    switch (category && category.toString()) {
        case "1":
        case "2":
            return (
                <>
                    {starIcon}
                </>
            );

        case "3":
        case "4":
            return (
                <>
                    {starIcon}{starIcon}
                </>
            );

        case "5":
        case "6":
            return (
                <>
                    {starIcon}{starIcon}{starIcon}
                </>
            );

        case "7":
        case "8":
            return (
                <>
                    {starIcon}{starIcon}{starIcon}{starIcon}
                </>
            );

        case "9":
        case "10":
            return (
                <>
                    {starIcon}{starIcon}{starIcon}{starIcon}{starIcon}
                </>
            );

        case "11":
        case "12":
        case "13":
        case "14":
        case "15":
        case "99":
        default:
            return (<></>);
    }
}