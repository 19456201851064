import React, { useState, useEffect } from 'react';
import { formatDateTime, createRenderHtml } from '../../../js/Utils.js';
import { useTranslation } from 'react-i18next';

export const QuotationInternalNotes = (props) => {
    const { t } = useTranslation();

    const noteToAddMaxLength = 1000;

    const [noteToAdd, setNoteToAdd] = useState("");
    const [isLoadingNoteToAdd, setIsLoadingNoteToAdd] = useState(false);
    const [isValidNoteToAdd, setValidNoteToAdd] = useState(false);

    function onTextAreaChange(event) {
        var note = event.target.value;
        setValidNoteToAdd(note && note.length > 0);
        setNoteToAdd(note);
    }

    function onClickAddInternalNote() {
        setIsLoadingNoteToAdd(true);
        props.callAddInternalNote(noteToAdd);
    }

    function onClickLoadAllInternalNote() {
        props.callGetAllInternalNote();
    }

    useEffect(() => {
        setIsLoadingNoteToAdd(false);
        setNoteToAdd("");
        setValidNoteToAdd(false);
    }, [props]);

    return (
        <div className="section section-md bg-white text-dark pt-0 line-bottom-light" id="noteInterne">

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8">
                        <div className="mt-3">
                            <h4>{t("MyBook:InternalNotes")}:</h4>
                            <textarea className="form-control border border-light-gray" id="txtInternalNote" value={noteToAdd} placeholder="Inserisci nota interna" rows="6" style={{ minHeight: '150px' }} maxLength={noteToAddMaxLength} onChange={onTextAreaChange}></textarea>
                            <div className="d-flex justify-content-between mt-3">
                                <small className="font-weight-light text-dark">
                                    <span id="charactersRemaining">
                                        this will be filled with {noteToAddMaxLength} from the textarea's maxlength characters remaining
                                    </span>
                                </small>
                                <button className="btn tp-btn-confirm animate-up-2" onClick={onClickAddInternalNote} disabled={!isValidNoteToAdd || isLoadingNoteToAdd}>
                                    Salva
                                </button>
                            </div>
                            {
                                props.quotationInternalNotes !== null & props.quotationInternalNotes !== undefined & props.quotationInternalNotes.length > 0
                                    ? props.quotationInternalNotes.map((internalNote) => {
                                        return <div key={`internal_note_${internalNote.id}`} className="mt-5">
                                            <div className="card border-light rounded p-4 mb-4">
                                                <div className="d-flex justify-content-between mb-4">
                                                    <span className="font-small">
                                                        <span className="font-weight-bold">{internalNote.name}</span>
                                                        <span className="ml-2">{formatDateTime(internalNote.created, props.cultureName, { withTime: true, noYear: true })}</span>
                                                    </span>
                                                </div>
                                                <p className="m-0" dangerouslySetInnerHTML={createRenderHtml(internalNote.note)}></p>
                                            </div>
                                        </div>;
                                    })
                                    : <p>Nessuna nota interna inserita</p>
                            }

                            <div className="mt-5 text-center">
                                {
                                    props.quotationInternalNotes !== null & props.quotationInternalNotes !== undefined & props.quotationInternalNotes.length < props.totalInternalNotes
                                        ? <button id="loadOnClickInternalNotes" className="btn btn-primary btn-loading-overlay mr-2 mb-2" onClick={onClickLoadAllInternalNote}>
                                            <span className="spinner">
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </span>
                                            <span className="ml-1 btn-inner-text">Load more notes</span>
                                        </button>
                                        :
                                        <p id="allLoadedInternalNotes">
                                            That's all!
                                        </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}