import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ListBox } from 'primereact/listbox';
import { M3Icon } from "./M3Icon";

export const SelectRay = ({ defaultRay, onSelectRay, defaultRayValues }) => {
    const { t } = useTranslation();

    const [selectRay, setSelectRay] = useState(10);
    const [rayAvailbles, setRayAvailbles] = useState([1, 5, 10, 15, 20, 50, 100]);
    const [showRayPanel, setShowRayPanel] = useState(false);

    useEffect(() => {
        if (defaultRayValues && defaultRayValues.length > 0)
            setRayAvailbles(defaultRayValues);

    }, [defaultRayValues])

    useEffect(() => {
        if (defaultRay)
            setSelectRay(defaultRay);

    }, [defaultRay]);

    function openRayPanel() {
        setShowRayPanel(!showRayPanel)
    }

    function onChangeDropDownRay(item) {
        setSelectRay(item);
        onSelectRay(item);
        setShowRayPanel(false);
    }

    return (
        <>
            <div className="w-100">
                <div className="input-group rounded pr-1 bg-white" onClick={_ => openRayPanel()}>
                    <span className="input-group-text px-2 w-30 bg-white" id="basic-addon1">
                        <M3Icon iconName="Ray" />
                    </span>
                    <div className="pl-1 w-70">
                        <label htmlFor="ddlRay" className="form-label h065">
                            <data m3lab="SearchEngine.Ray">
                                {t('SearchEngine:Ray')}
                            </data>
                        </label>

                        <input className="form-control h075 w-100 border-0 shadow-none"
                            id="ddlRay"
                            value={selectRay}
                            readOnly />
                    </div>
                </div>
                {
                    showRayPanel && rayAvailbles && <div className="mb-2">
                        <ListBox value={selectRay}
                            onChange={(e) => onChangeDropDownRay(e.value)}
                            options={rayAvailbles}
                            listStyle={{ height: '250px', textAlign: 'left' }} />
                    </div>
                }
            </div>
        </>
    );
}
