import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { Loading } from '../../Common/Loading';
import { M3Icon } from '../../Common/M3Icon'
import { CartBuoniScontoSelectButton } from './components/CartBuoniScontoSelectButton';
import configData from "../../../appsettings.json";

export const CartBuoniSconto = ({ buoniScontoSelectedMap, onSelectBuonoSconto }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [buoniScontoRebate, setBuoniScontoRebate] = useState([]);
    const [buoniScontoGiftCard, setBuoniScontoGiftCard] = useState([]);
    const [buoniScontoDiscount, setBuoniScontoDiscount] = useState([]);
    const [haveBuoniSconto, setHaveBuoniSconto] = useState(false);


    useEffect(() => {
        const getBuoniSconto = async () => { const response = callGetGBuoniSconto(); }

        setIsLoading(true);
        getBuoniSconto();

    }, []);

    async function callGetGBuoniSconto() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/GetBuoniSconto', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response && response.length > 0) {
                let responseFlt = response.filter(x => x.key === 'Discount');
                if (responseFlt && responseFlt[0])
                    setBuoniScontoDiscount(responseFlt[0].value);

                responseFlt = response.filter(x => x.key === 'Gift');
                if (responseFlt && responseFlt[0])
                    setBuoniScontoGiftCard(responseFlt[0].value);

                responseFlt = response.filter(x => x.key === 'Rebate');
                if (responseFlt && responseFlt[0])
                    setBuoniScontoRebate(responseFlt[0].value);

                setHaveBuoniSconto(true);
            } else {
                setHaveBuoniSconto(false);
            }
        } else {
            setHaveBuoniSconto(false);
        }

        setIsLoading(false);
    }

    return (
        <>
            {
                isLoading
                    ? (<Loading textMsg={t('MyBook:Loading')} />)
                    : (
                        <>
                            {
                                haveBuoniSconto && <div className="basket-card card mt-4 p-3 basket">
                                    <div>
                                        <M3Icon iconName="CreditCard" externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                                        <span className="basket titlecard">Buoni Sconto</span>
                                    </div>
                                    <div className="mt-2">

                                        <CartBuoniScontoSelectButton
                                            type="Discount"
                                            buoniSconto={buoniScontoDiscount}
                                            msg="Clicca per selezionare il buono sconto da applicare"
                                            onSelectBuoniSconto={onSelectBuonoSconto}
                                        />

                                        <CartBuoniScontoSelectButton
                                            type="GiftCard"
                                            buoniSconto={buoniScontoGiftCard}
                                            msg="Seleziona le Gift Card che vuoi usare"
                                            onSelectBuoniSconto={onSelectBuonoSconto}
                                        />

                                        <CartBuoniScontoSelectButton
                                            type="Rebate"
                                            buoniSconto={buoniScontoRebate}
                                            msg="Seleziona la pratica e il buono sconto che vuoi applicare"
                                            onSelectBuoniSconto={onSelectBuonoSconto}
                                        />
                                    </div>
                                </div>
                            }
                        </>
                    )
            }
        </>
    )
}
