import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const QuotationOperationButtons = ({ id, callOnChangeStatusQuotationItem, onCallRequoteQuotationItem, onCallUpdateQuotationItem, quotationMode, quotationDetail }) => {
    const { t } = useTranslation();

    function onClickConfirm() {
        var status = 1;
        if (isSelectStatus(quotationDetail.status, 'CONFIRM'))
            status = 0;

        callOnChangeStatusQuotationItem(id, status);
    }

    function onClickDelete() {
        var status = 80;
        if (isSelectStatus(quotationDetail.status, 'DELETE'))
            status = 0;

        callOnChangeStatusQuotationItem(id, status);
    }

    function onClickReactive() {
        callOnChangeStatusQuotationItem(id, 0);
    }

    const onClickRequote = useCallback(() => {
        onCallRequoteQuotationItem(id);
    }, [id, onCallRequoteQuotationItem]);

    const onClickUpdate = useCallback(() => {
        onCallUpdateQuotationItem(id);
    }, [id, onCallUpdateQuotationItem]);

    function isDisabled(quotationItemStatus, buttonType) {
        switch (buttonType) {
            case 'CONFIRM': return isConfirmDisabled(quotationItemStatus);
            case 'DELETE': return isDeleteDisabled(quotationItemStatus);
            case 'REQUOTE': return isRequoteDisabled(quotationItemStatus);
            case 'UPDATE': return isUpdateDisabled(quotationItemStatus);
            case 'REACTIVE': return isReactiveDisabled(quotationItemStatus);
            default:
                return true;
        }
    }

    function isConfirmDisabled(quotationItemStatus) {
        switch (quotationItemStatus) {
            case 0: return false; // Draft
            case 1: return false; // Confirm
            case 5: return true; // ConfirmBook
            case 6: return true; // NotConfirmBook
            case 70: return true; // Replace
            case 80: return true; // Deleted
        }
    }

    function isDeleteDisabled(quotationItemStatus) {
        switch (quotationItemStatus) {
            case 0: return false;
            case 1: return true;
            case 5: return true;
            case 6: return false;
            case 70: return false;
            case 80: return false;
        }
    }

    function isRequoteDisabled(quotationItemStatus) {
        switch (quotationItemStatus) {
            case 0: return false;
            case 1: return true;
            case 5: return true;
            case 6: return true;
            case 70: return true;
            case 80: return true;
        }
    }

    function isUpdateDisabled(quotationItemStatus) {
        switch (quotationItemStatus) {
            case 0: return false;
            case 1: return true;
            case 5: return true;
            case 6: return true;
            case 70: return true;
            case 80: return true;
        }
    }

    function isReactiveDisabled(quotationItemStatus) {
        switch (quotationItemStatus) {
            case 0: return true;
            case 1: return true;
            case 5: return true;
            case 6: return false;
            case 70: return false;
            case 80: return false;
        }
    }

    function isReactiveAvailableReactive(quotationItemStatus) {
        switch (quotationItemStatus) {
            case 0: return false;
            case 1: return false;
            case 5: return false;
            case 6: return true;
            case 70: return true;
            case 80: return true;
        }
    }

    function getSelectedClass(quotationItemStatus, buttonType) {
        var selectedClass = 'btn-quotation-status-select';

        return isSelectStatus(quotationItemStatus, buttonType) ? selectedClass : '';
    }

    function isSelectStatus(quotationItemStatus, buttonType) {
        switch (buttonType) {
            case 'CONFIRM': return quotationItemStatus === 1;
            case 'DELETE': {
                switch (quotationItemStatus) {
                    case 6:
                    case 70:
                    case 80:
                        return true;

                    default:
                        return false;
                }
            }

            default:
                return false;
        }
    }

    return (
        <>
            <div className="float-left">
                {(quotationMode === 'QuotationTOWorking' || quotationMode === 'QuotationTOEndUser') && <button className={`tp-btn-confirm btn-sm item-btn-bar ${getSelectedClass(quotationDetail.status, 'CONFIRM')}`} onClick={onClickConfirm} disabled={isDisabled(quotationDetail.status, 'CONFIRM')}>{t(`quotationto:button:confirm`)}</button>}
                {(quotationMode === 'QuotationTOWorking' || quotationMode === 'QuotationTOEndUser') && <button className={`tp-btn-delete btn-sm item-btn-bar ${getSelectedClass(quotationDetail.status, 'DELETE')}`} onClick={onClickDelete} disabled={isDisabled(quotationDetail.status, 'DELETE')}>{t("Button:Delete")}</button>}
                {quotationMode === 'QuotationTOWorking' && !quotationDetail.isManualLoading && <button className={`btn btn-outline-gray btn-sm item-btn-bar ${getSelectedClass(quotationDetail.status, 'REQUOTE')}`} onClick={onClickRequote} disabled={isDisabled(quotationDetail.status, 'REQUOTE')}>{t("Button:Requote")}</button>}
                {quotationMode === 'QuotationTOWorking' && !quotationDetail.isManualLoading && <button className={`btn btn-outline-dark btn-sm item-btn-bar ${getSelectedClass(quotationDetail.status, 'UPDATE')}`} onClick={onClickUpdate} disabled={isDisabled(quotationDetail.status, 'UPDATE')}>{t("Button:Update")}</button>}
                {quotationMode === 'QuotationTOWorking' && isReactiveAvailableReactive(quotationDetail.status) && <button className={`btn btn-outline-dark btn-sm item-btn-bar ${getSelectedClass(quotationDetail.status, 'REQUOTE')}`} onClick={onClickReactive} disabled={isDisabled(quotationDetail.status, 'REACTIVE')}>{t("Button:Reactive")}</button>}
            </div>
        </>
    );
}
