import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../js/Utils.js';

export const CartBookParameterError = ({ invalidBookParameters }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName, setCultureName] = useState(cookies.get("CultureName"));

    function renderInfo(bpData) {
        if (bpData.MinValue && bpData.MaxValue) {
            return (
                <>
                    <span>{t(`Cart:BookParameterError_ValueBetween`)}</span>
                    &nbsp;
                    {bpData.BookParameterType === "StringBookParameter" && <span>{bpData.MinValue} - {bpData.MaxValue}</span>}
                    {bpData.BookParameterType === "DateTimeBookParameter" && <span>{formatDateTime(bpData.MinValue, cultureName)} - {formatDateTime(bpData.MaxValue, cultureName)}</span>}
                    {bpData.BookParameterType === "NumericBookParameter" && <span>{bpData.MinValue} - {bpData.MaxValue}</span>}
                </>
            )
        }
        else if (bpData.MinValue && !bpData.MaxValue) {
            return (
                <>
                    <span>{t(`Cart:BookParameterError_ValueGreaterThen`)}</span>
                    &nbsp;
                    {bpData.BookParameterType === "StringBookParameter" && <span>{bpData.MinValue}</span>}
                    {bpData.BookParameterType === "DateTimeBookParameter" && <span>{formatDateTime(bpData.MinValue, cultureName)}</span>}
                    {bpData.BookParameterType === "NumericBookParameter" && <span>{bpData.MinValue}</span>}
                </>
            )
        }
        else if (!bpData.MinValue && bpData.MaxValue) {
            return (
                <>
                    <span>{t(`Cart:BookParameterError_ValueLessThen`)}</span>
                    &nbsp;
                    {bpData.BookParameterType === "StringBookParameter" && <span>{bpData.MaxValue}</span>}
                    {bpData.BookParameterType === "DateTimeBookParameter" && <span>{formatDateTime(bpData.MaxValue, cultureName)}</span>}
                    {bpData.BookParameterType === "NumericBookParameter" && <span>{bpData.MaxValue}</span>}
                </>
            )
        }

        if (bpData.HasValidValues) {
            return (
                <>
                    <span>{t(`Cart:BookParameterError_ValidValues`)}</span>
                </>
            )
        }
    }

    return (
        <>
            {!invalidBookParameters || invalidBookParameters.length === 0
                ? (<></>)
                : (

                    <div className="row mt-4">
                        {invalidBookParameters.map((invalidBp, key1) => {
                            return (
                                <React.Fragment key={key1}>
                                    <div className="col-12">
                                        <h6 style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: `${t(`MyBook:Product`)}: <b>${invalidBp.ProductName}</b>` }}></h6>
                                    </div>
                                    <div className="card" style={{ border: '1px solid red', padding: '5px', margin: '10px', width: '98%' }}>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-4 h075">{t(`Cart:BookParameterError_Header_PaxNumber`)}</div>
                                                <div className="col-4 h075">{t(`Cart:BookParameterError_Header_Parameter`)}</div>
                                                <div className="col-4 h075">{t(`Cart:BookParameterError_Header_NoteInfo`)}</div>
                                            </div>
                                            <div className="row">
                                                {invalidBp.BookParameterData.map((bpData, key2) => {
                                                    return (
                                                        <React.Fragment key={key2}>
                                                            <div className="col-4 h100">
                                                                {bpData.PaxIndex ?? "--"}
                                                            </div>
                                                            <div className="col-4 h100">
                                                                {t(`bookParameters:${bpData.BookParameterId}`)}
                                                            </div>
                                                            <div className="col-4 h100">
                                                                {renderInfo(bpData)}
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                )
            }
        </>
    );
}