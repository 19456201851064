import React, { useState } from 'react';
import configData from "../../../../appsettings.json";
import { handleError } from "../../../../js/Utils";

export const ContactForm = ({ item }) => {
    const [isSend, setIsSend] = useState(false);

    const [inputData, setInputData] = useState(
        {
            name: '',
            email: '',
            phone: '',
            msg: '',
            acceptPrivacy: false
        }
    );

    const [isValidInputData, setIsValidInputData] = useState(
        {
            name: false,
            isTouchedName: false,

            email: false,
            isTouchedEmail: false,

            phone: false,
            isTouchedPhone: false,

            msg: false,
            isTouchedMsg: false,

            acceptPrivacy: false,
            isTouchedPrivacy: false,

            isValidForm: false
        }
    );

    function saveInputForm(action, redirectBottone) {

        if (action) {

            let request = {
                name: inputData.name,
                surname: '',
                email: inputData.email,
                phone: inputData.phone,
                msg: inputData.msg,
                regione: '',
                formType: action,
                acceptPrivacy: inputData.acceptPrivacy,
            }

            const requestOption = {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            };

            fetch(`${configData.Settings.CommonApi_BaseUrl}webcontent/saveforminfobv`, requestOption)
                .then((res) => handleError(res))
                .then((response) => {
                    setIsSend(true);

                    if (item.bottoneURL)
                        window.open(item.bottoneURL + "?frId=" + response.requestId, '_blank');

                })
                .catch((err) => {
                    setIsSend(false);

                    console.error(err);
                });

        } else {
            if (item.bottoneURL) {

                if (item.redirectbottone.toLowerCase().includes('http'))
                    window.open(item.redirectbottone, '_blank');
                else if (item.redirectbottone.includes('/'))
                    window.open(item.redirectbottone, '_blank');
                else
                    window.open('webcontent/' + item.redirectbottone);

            }

        }
    }

    function onBlur(event, propertyName) {
        let updateInputData = { ...inputData };
        let updateIsValidInputData = { ...isValidInputData };

        switch (propertyName) {
            case 'name': {
                let newValue = event.target.value;
                updateInputData[propertyName] = newValue;

                const isEmpty = newValue === null || newValue === '' || newValue === undefined;
                updateIsValidInputData[propertyName] = !isEmpty;
                updateIsValidInputData.isTouchedName = true;

                break;
            }
            case 'email': {
                let newValue = event.target.value;
                updateInputData[propertyName] = newValue;

                const isEmpty = newValue === null || newValue === '' || newValue === undefined;
                const isValidMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(newValue);

                updateIsValidInputData[propertyName] = !isEmpty && isValidMail;
                updateIsValidInputData.isTouchedEmail = true;

                break;
            }
            case 'phone': {
                let newValue = event.target.value;
                updateInputData[propertyName] = newValue;

                const isEmpty = newValue === null || newValue === '' || newValue === undefined;
                const isValidPhone = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(newValue);

                updateIsValidInputData[propertyName] = !isEmpty && isValidPhone;
                updateIsValidInputData.isTouchedPhone = true;

                break;
            }
            case 'msg': {
                let newValue = event.target.value;
                updateInputData[propertyName] = newValue;

                const isEmpty = newValue === null || newValue === '' || newValue === undefined;
                updateIsValidInputData[propertyName] = !isEmpty;
                updateIsValidInputData.isTouchedMsg = true;

                break;
            }
            case 'acceptPrivacy': {
                let newValue = event.target.checked;

                updateInputData[propertyName] = newValue;
                updateIsValidInputData[propertyName] = newValue;
                updateIsValidInputData.isTouchedPrivacy = true;

                break;
            }
        }


        delete updateIsValidInputData.isValidForm;
        const isValidForm = Object.values(updateIsValidInputData).every(item => item);
        updateIsValidInputData.isValidForm = isValidForm;

        setInputData(updateInputData);
        setIsValidInputData(updateIsValidInputData);
    }

    return <div className="row">

        <div className="col-6">
            <div className="form-group">
                <input type="text"
                    className={"form-control font-weight-light mb-2 w-100 border " + (isValidInputData.name ? "border-light" : "border-danger")}
                    id="nameContactForm"
                    aria-describedby="emailHelp"
                    value={inputData.name}
                    onChange={e => onBlur(e, 'name')}
                    onBlur={e => onBlur(e, 'name')}
                    placeholder="Nome *" />
                {(!isValidInputData.name && isValidInputData.isTouchedName) && <span className="text-danger">Campo nome obbligatorio</span>}

                <input type="email"
                    className={"form-control font-weight-light mb-2 w-100 border " + (isValidInputData.email ? "border-light" : "border-danger")}
                    id="emailContactForm"
                    aria-describedby="emailHelp"
                    value={inputData.email}
                    onChange={e => onBlur(e, 'email')}
                    onBlur={e => onBlur(e, 'email')}
                    placeholder="Email *" />
                {(!isValidInputData.email && isValidInputData.isTouchedEmail) && <span className="text-danger">La email non è valida</span>}

                <input type="text"
                    className={"form-control font-weight-light mb-2 w-100 border " + (isValidInputData.phone ? "border-light" : "border-danger")}
                    id="phoneContactForm"
                    aria-describedby="emailHelp"
                    value={inputData.phone}
                    onChange={e => onBlur(e, 'phone')}
                    onBlur={e => onBlur(e, 'phone')}
                    placeholder="Cellulare *" />
                {(!isValidInputData.phone && isValidInputData.isTouchedPhone) && <span className="text-danger">Il cellulare non è valido</span>}
            </div>
        </div>

        <div className="col-6">
            <div className="form-group">
                <textarea
                    className={"form-control font-weight-light mb-2 w-100 border " + (isValidInputData.msg ? "border-light" : "border-danger")}
                    id="textAreaContactForm"
                    value={inputData.msg}
                    onChange={e => onBlur(e, 'msg')}
                    onBlur={e => onBlur(e, 'msg')}
                    rows="4"
                    placeholder="Lascia un messaggio *"></textarea>

                {(!isValidInputData.msg && isValidInputData.isTouchedMsg) && <div className="text-danger">Devi inserire un messaggio</div>}

                <input type="checkbox"
                    className={"border " + (isValidInputData.acceptPrivacy ? "border-light" : "border-danger")}
                    checked={inputData.acceptPrivacy}
                    onChange={e => onBlur(e, 'acceptPrivacy')} />
                Ho letto e accetto <a href="https://www.borsaviaggi.it/privacy" className="text-white-50" target="_blank"><u>l'informativa sulla privacy</u>
                </a>

                {(!isValidInputData.acceptPrivacy && isValidInputData.isTouchedPrivacy) && <span className="text-danger">Devi accettare l'informativa sulla privacy</span>}

                <div className="text-right mt-1">
                    <button
                        className="btn btn-primary"
                        disabled={!isValidInputData.isValidForm}
                        onClick={e => saveInputForm(item.formTypeName, item.bottoneURL)}>
                        {item.bottoneTesto ? item.bottoneTesto : "Invia"}
                    </button>
                    {
                        isSend && <div>La tua richiesta è stata inviata con successo.</div>
                    }
                </div>
            </div>
        </div>
    </div>;
}