import { getDiffDays, addDaysToDatePicker, compareDates } from '../../../js/Utils.js';
import configData from "../../../appsettings.json";

const defaultStep = {
    id: -1,
    step: 0,
    stepType: 'Step', // or Flight
    stepName: '',
    stepFrom: null,
    stepTo: null,
    nights: 0,
    webDestinationName: null,
    webDestinationId: null,
    latitude: null,
    longitude: null,
    items: []
};

export function createStepsWithNewOne(index, updSteps, searchParameters, stepType = null) {
    let newSteps = createDefaultStep(index, updSteps, searchParameters, false, stepType);
    return newSteps;
}

export function createStepsMissing(index, updSteps, searchParameters) {
    let newSteps = createDefaultStep(index, updSteps, searchParameters, true);
    return newSteps;
}

export function createStepsRemoveOne(index, updSteps) {

    const newSteps = [
        ...updSteps.slice(0, index),
        ...updSteps.slice(index + 1)
    ];
    let updnewSteps = checkStepsHaveMissingDateAfter(newSteps);

    return updnewSteps;
}

export function createStepSearchParam(updStep, roomsComposition) {
    if (!updStep.stepSearchParam)
        updStep.stepSearchParam = {};

    updStep.stepSearchParam.dateFrom = updStep.stepFrom;
    updStep.stepSearchParam.dateTo = updStep.stepTo;
    updStep.stepSearchParam.roomsComposition = roomsComposition;

    return updStep;
}

export function createDefaultStep(index, updSteps, updSearchParam, isAddMissingStepNight = false, stepType = null) {
    let newStep = { ...defaultStep };
    let updIndex = (index + 1);

    newStep.step = updIndex;

    if (stepType)
        newStep.stepType = stepType;

    newStep.missingDateToNextStep = false;

    const prevSteps = [...updSteps.slice(0, updIndex)];
    const nextSteps = [...updSteps.slice(updIndex)];

    newStep.stepName = "";

    if (!isAddMissingStepNight) {

        if (prevSteps.length > 0) {
            let getLastItems = prevSteps[prevSteps.length - 1];
            if (getLastItems.stepTo)
                newStep.stepFrom = getLastItems.stepTo;
            else
                newStep.stepFrom = getLastItems.stepFrom;
        }

        if (nextSteps.length > 0) {
            newStep.stepTo = nextSteps[0].stepFrom;
            newStep.missingDateToNextStep = false;
        }

        if (!newStep.stepFrom && updSearchParam) {
            newStep.stepFrom = updSearchParam.dateFrom;
        }

        if (!newStep.stepTo && updSearchParam) {
            newStep.stepTo = updSearchParam.dateTo;
        }

    } else {
        if (index === -1) {
            newStep.stepFrom = updSearchParam.dateFrom;
            newStep.stepTo = updSteps[0].stepFrom;
            newStep.missingDateToNextStep = false;
        }
        else if (prevSteps.length > 0) {
            prevSteps[prevSteps.length - 1].missingDateToNextStep = false;
            newStep.stepFrom = prevSteps[prevSteps.length - 1].missingStepFrom;
            newStep.stepTo = prevSteps[prevSteps.length - 1].missingStepTo;
            newStep.missingDateToNextStep = false;
        }
    }

    if (stepType && stepType === (configData.Settings.Products.Flight.IdTipoProdotto + ""))
        newStep.stepTo = newStep.stepFrom

    newStep.nights = getDiffDays(newStep.stepFrom, newStep.stepTo);
    newStep.maxNights = getDiffDays(newStep.stepFrom, updSearchParam.dateTo);
    newStep = createStepSearchParam(newStep, updSearchParam.roomsComposition);

    const newSteps = [
        ...updSteps.slice(0, updIndex),
        newStep,
        ...updSteps.slice(updIndex)
    ];

    checkStepsHaveMissingDateAfter(newSteps);

    return newSteps;
}

function checkStepsHaveMissingDateAfter(newSteps) {

    for (let i = 0; i < newSteps.length - 1; i++) {
        let prevStep = newSteps[i].stepTo;
        let nextStep = newSteps[i + 1].stepFrom;

        let diffDays = getDiffDays(nextStep, prevStep);
        newSteps[i].missingDateToNextStep = diffDays > 1;
        if (newSteps[i].missingDateToNextStep) {
            try {
                if (i > 0) {
                    newSteps[i].missingStepFrom = newSteps[i - 1].stepTo;
                    newSteps[i].missingStepTo = newSteps[i].stepFrom;
                } else {
                    newSteps[i].missingStepFrom = newSteps[i].stepTo;
                    newSteps[i].missingStepTo = newSteps[i + 1].stepFrom;
                }

            } catch (ex) { }
        }
    }

    return newSteps;
}

export function isPreviousVoidStep(stepInfo, dateFrom) {
    let days = getDiffDays(dateFrom, stepInfo.stepFrom);
    return days > 0;
}

export function canAddAnotherStep(steps, stepIndex, dateTo) {
    if ((steps.length - 1) === stepIndex) {
        let days = getDiffDays(steps[stepIndex].stepTo, dateTo);
        return days > 0;
    }

    return true;
}

export function changeStepNightsFromSearchParam(dateFrom, updSteps, dateTo) {
    if (dateFrom && updSteps && updSteps.length > 0) {
        updSteps[0].stepFrom = dateFrom;
        updSteps[0].stepTo = addDaysToDatePicker(dateFrom, updSteps[0].nights);
        updSteps[0].maxNights = getDiffDays(dateFrom, dateTo);

        changeStepNights(updSteps, 0, dateTo);
    }

    return updSteps;
}

export function createStepsSearchParam(updSteps, roomsComposition) {
    for (let i = 0; i < updSteps.length; i++) {
        updSteps[i] = createStepSearchParam(updSteps[i], roomsComposition);
    }

    return updSteps;
}

export function isValidNights(newValue, stepInfo, searchParameters) {
    let isValid = false;
    let maxNightsValue = 0;

    if (searchParameters) {
        if (!newValue)
            newValue = 0;

        let newDateStr = addDaysToDatePicker(stepInfo.stepFrom, newValue);

        let newDate = new Date(newDateStr).getTime();
        let dateTo = new Date(searchParameters.dateTo).getTime();

        if (newDate <= dateTo) {
            isValid = true;
        }

        maxNightsValue = getDiffDays(stepInfo.stepFrom, searchParameters.dateTo);
    }

    return { isValid, maxNightsValue };
}

export function changeStepNights(updSteps, index, dateTo, isAddingNights = 0) {

    for (let i = 0; i < updSteps.length; i++) {
        if (i > index) {
            let prevDateTo = updSteps[i - 1].stepTo;
            updSteps[i].stepFrom = prevDateTo;
            
            if (updSteps[i].stepType === (configData.Settings.Products.Flight.IdTipoProdotto + "")) {
                updSteps[i].nights = 0;
                updSteps[i].stepTo = updSteps[i].stepFrom;
            }
            else if (isAddingNights > 0)
            {
                updSteps[i].nights = updSteps[i].nights - isAddingNights;
                updSteps[i].stepTo = addDaysToDatePicker(prevDateTo, updSteps[i].nights);
                isAddingNights = 0;
            }

            let newMaxNights = getDiffDays(updSteps[i].stepFrom, dateTo);

            try {
                updSteps[i].maxNights = parseInt(newMaxNights);
            } catch (ex) {
                updSteps[i].maxNights = 0;
            }

            try {
                let diffStepFrom = compareDates(updSteps[i].stepFrom, dateTo);
                let diffStepTo = compareDates(updSteps[i].stepTo, dateTo);

                updSteps[i].isOutsideRange = diffStepTo > 0 || diffStepFrom > 0;

            } catch (ex) { }
        }
    }

    checkStepsHaveMissingDateAfter(updSteps);

    return updSteps;
}
