import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { M3TextAreaCustom } from '../../../../Common/M3TextAreaCustom';

export const ProductDataDescriptions = ({ allCategoryDescriptions, descriptions, descriptionIsHTML, onUpdateData }) => {
    const { t } = useTranslation();

    const [normalizeDescriptions, setNormalizeDescriptions] = useState([]);

    useEffect(() => {
        if (descriptions)
            setNormalizeDescriptions(descriptions);
    }, [descriptions])

    const editCategoryType = (options) => {
        let categoryAvailable = [...allCategoryDescriptions];

        let defaultCategoryId = options.value;
        if (!(typeof defaultCategoryId === 'number')) {
            defaultCategoryId = options.rowData.categoryId;
        }

        let normAvailable = categoryAvailable.map(d => { return { label: d.category, value: d.categoryId + "" }; });
        normAvailable = normAvailable.filter(x => !normalizeDescriptions || !normalizeDescriptions.some(d => x.value === d.categoryId) || x.value === defaultCategoryId);

        return (
            <>
                <Dropdown
                    value={defaultCategoryId}
                    options={normAvailable}
                    onChange={(e) => changeCategoryId(e, options)}
                    filter
                    optionLabel="label"
                    placeholder="Tipo Categoria"
                    listStyle={{ height: '150px', textAlign: 'left' }} />
            </>
        );
    };

    const changeCategoryId = (event, options) => {
        options.editorCallback(event.value);
    };

    const onRowEditComplete = (e) => {
        let _desc = [...normalizeDescriptions];
        let { newData, index } = e;

        // bug in react prime dropdown doesnt work with object
        if (allCategoryDescriptions && allCategoryDescriptions.length > 0)
            newData.category = allCategoryDescriptions.filter(x => (x.categoryId + "") === newData.categoryId)[0].category;

        // bug in react prime when use custom component to update
        newData.description = e.data.description;

        _desc[index] = newData;

        onUpdateData(_desc);
    };

    const editCategoryName = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const editDescriptions = (options) => {
        let defaultText = options.value;
        if (!defaultText)
            defaultText = options.rowData.description;

        if (descriptionIsHTML)
            return (
                <M3TextAreaCustom defaultText={defaultText} onChangeText={onChangeText} dataRowOptions={options} />
            );

        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const onChangeText = (event, options) => {
        options.editorCallback(event);
    };

    const bodyDescriptions = (options) => {
        return <div dangerouslySetInnerHTML={{ __html: options.description }}></div>;
    };

    const statusBodyTemplate = (options) => {
        return options.category;
    };

    const bodyDelete = (rowData) => {
        return (
            <div className="cursor-pointer" onClick={_ => onRemove(rowData)}>
                <span class="material-icons-outlined text-danger">delete</span>
            </div>
        );
    };

    function onRemove(rowData) {
        let updNormalizeDescriptions = normalizeDescriptions.filter(x => x.id !== rowData.id);
        onUpdateData(updNormalizeDescriptions);
    }

    function onCallAddRow() {
        let updDescriptions = [...normalizeDescriptions]
        if (!updDescriptions) {
            updDescriptions = [];
        }

        let fakeId = Math.floor(Math.random() * 100000);

        updDescriptions.push({
            id: fakeId
        });

        setNormalizeDescriptions(updDescriptions);
    }

    return (
        <>
            <DataTable value={normalizeDescriptions}
                editMode="row"
                dataKey="id"
                onRowEditComplete={onRowEditComplete}
                tableStyle={{ minWidth: '50rem' }}>
                {
                    allCategoryDescriptions && allCategoryDescriptions.length > 0 && <Column field="categoryId" header="Categoria" body={statusBodyTemplate} style={{ width: '20%' }} editor={(options) => editCategoryType(options)}></Column>
                }
                {
                    (!allCategoryDescriptions || allCategoryDescriptions.length == 0) && <Column field="category" header="Categoria" body={statusBodyTemplate} style={{ width: '20%' }} editor={(options) => editCategoryName(options)}></Column>
                }
                <Column field="description" body={bodyDescriptions} header="Descrizione" editor={(options) => editDescriptions(options)} style={{ width: '60%' }}></Column>
                <Column rowEditor={true} style={{ width: '10%' }}></Column>
                <Column body={bodyDelete} style={{ width: '10%' }}></Column>
            </DataTable>
            <div className="col-12 text-right">
                <button type="button"
                    className="btn bt-sm tp-btn-confirm"
                    onClick={_ => onCallAddRow()}>
                    {t("MyBook:Add")}
                </button>
            </div>
        </>
    );
}
