export const M3Icon = {
    //-------Generale-------//
    MenuBurgher: {
        LabelKay: "MenuBurgher", //Chiave di traduzione label//
        ToolTip: "Menu",
        class: "material-icons",
        Value: "menu"
    },
    Home: {
        LabelKay: "Home", //Chiave di traduzione label//
        ToolTip: "Home",
        class: "material-icons",
        Value: "home"
    },
    MenuFunctions: {
        LabelKay: "MenuFunctions", //Chiave di traduzione label//
        ToolTip: "Menu Functions",
        class: "material-icons",
        Value: "list"
    },
    PaxAdl: {
        LabelKay: "PaxAdl", //Chiave di traduzione label//
        ToolTip: "Adult",
        class: "material-icons",
        Value: "person"
    },
    PaxChd: {
        LabelKay: "PaxChd", //Chiave di traduzione label//
        ToolTip: "Chaild",
        class: "material-icons",
        Value: "person_outline"
    },
    PaxInf: {
        LabelKay: "PaxInf", //Chiave di traduzione label//
        ToolTip: "Infant",
        class: "material-icons",
        Value: "child_friendly"
    },
    Info: {
        LabelKay: "Info", //Chiave di traduzione label//
        ToolTip: "Info",
        class: "material-icons",
        Value: "info_outline"
    },
    Delete: {
        LabelKay: "Delete", //Chiave di traduzione label//
        ToolTip: "Delete",
        class: "material-icons",
        Value: "delete_forever"
    },
    CancelPolicy: {
        LabelKay: "CancelPolicy", //Chiave di traduzione label//
        ToolTip: "Cancel Policy",
        class: "material-icons",
        Value: "local_atm"
    },
    Night: {
        LabelKay: "Night", //Chiave di traduzione label//
        ToolTip: "Night",
        class: "material-icons",
        Value: "bed"
    },
    GPSPoint: {
        LabelKay: "GPS", //Chiave di traduzione label//
        ToolTip: "GPS Point",
        class: "material-icons",
        Value: "mode_of_travel"
    },
    CityLocation: {
        LabelKay: "GPS", //Chiave di traduzione label//
        ToolTip: "GPS Point",
        class: "material-icons",
        Value: "zoom_out_map"
    },
    Ray: {
        LabelKay: "Ray", //Chiave di traduzione label//
        ToolTip: "Ray",
        class: "material-icons-outlined",
        Value: "share_location"
    },
    Filter: {
        LabelKay: "Filter", //Chiave di traduzione label//
        ToolTip: "Filter",
        class: "material-icons",
        Value: "filter_alt"
    },
    ResultList: {
        LabelKay: "ResultList", //Chiave di traduzione label//
        ToolTip: "Result List",
        class: "material-icons",
        Value: "dns"
    },
    ResultBloc: {
        LabelKay: "ResultBloc", //Chiave di traduzione label//
        ToolTip: "Result Bloc",
        class: "material-icons",
        Value: "grid_view"
    },
    Edit: {
        LabelKay: "Edit", //Chiave di traduzione label//
        ToolTip: "Edit",
        class: "material-icons",
        Value: "mode_edit"
    },
    Remove: {
        LabelKay: "Remove", //Chiave di traduzione label//
        ToolTip: "Remove",
        class: "material-icons",
        Value: "remove"
    },
    RemoveCircle: {
        LabelKay: "Remove", //Chiave di traduzione label//
        ToolTip: "Remove",
        class: "material-icons",
        Value: "remove_circle"
    },
    Add: {
        LabelKay: "Add", //Chiave di traduzione label//
        ToolTip: "Add",
        class: "material-icons",
        Value: "add"
    },
    AddCircle: {
        LabelKay: "Add", //Chiave di traduzione label//
        ToolTip: "Add",
        class: "material-icons",
        Value: "add_circle"
    },
    Update: {
        LabelKay: "Update", //Chiave di traduzione label//
        ToolTip: "Update",
        class: "material-icons",
        Value: "modify"
    },
    UpdateCircle: {
        LabelKay: "Update", //Chiave di traduzione label//
        ToolTip: "Update",
        class: "material-icons",
        Value: "modify_circle"
    },
    Place: {
        LabelKay: "Place", //Chiave di traduzione label//
        ToolTip: "Place",
        class: "material-icons",
        Value: "place"
    },
    OrderByDesc: {
        LabelKay: "OrderByDesc", //Chiave di traduzione label//
        ToolTip: "Order by",
        class: "material-icons",
        Value: "expand_more"
    },
    OrderByAsc: {
        LabelKay: "OrderByAsc", //Chiave di traduzione label//
        ToolTip: "Order by",
        class: "material-icons",
        Value: "expand_less"
    },
    Close: {
        LabelKay: "Close", //Chiave di traduzione label//
        ToolTip: "Close",
        class: "material-icons",
        Value: "close"
    },
    Done: {
        LabelKay: "Done", //Chiave di traduzione label//
        ToolTip: "Done",
        class: "material-icons",
        Value: "done"
    },
    Lock: {
        LabelKay: "Lock", //Chiave di traduzione label//
        ToolTip: "Lock",
        class: "material-icons",
        Value: "lock_outline"
    },
    Print: {
        LabelKay: "Print", //Chiave di traduzione label//
        ToolTip: "Print",
        class: "material-icons",
        Value: "print"
    },
    PrintDisabled: {
        LabelKay: "PrintDisabled", //Chiave di traduzione label//
        ToolTip: "Print Disabled",
        class: "material-icons",
        Value: "print_disabled"
    },
    ArrowDown: {
        LabelKay: "ArrowDown", //Chiave di traduzione label//
        ToolTip: "Arrow Down",
        class: "material-icons",
        Value: "keyboard_double_arrow_down"
    },
    TableRows: {
        LabelKay: "TableRows", //Chiave di traduzione label//
        ToolTip: "Table Rows",
        class: "material-icons",
        Value: "table_rows"
    },
    NavigateNext: {
        LabelKay: "NavigateNext", //Chiave di traduzione label//
        ToolTip: "Navigate Next",
        class: "material-icons",
        Value: "navigate_next"
    },
    ContentPaste: {
        LabelKay: "ContentPaste", //Chiave di traduzione label//
        ToolTip: "Content Paste",
        class: "material-icons",
        Value: "content_paste"
    },

    ChevronRight: {
        LabelKay: "Next", //Chiave di traduzione label//
        ToolTip: "Next",
        class: "material-icons",
        Value: "chevron_right"
    },
    Time: {
        LabelKay: "Time", //Chiave di traduzione label//
        ToolTip: "Time",
        class: "material-icons",
        Value: "watch_later"
    },


    //-------Activity-------//
    Activity: {
        LabelKay: "Product:Activity:Item", //Chiave di traduzione label//
        ToolTip: "Product:Activity:Item",
        class: "material-icons",
        Value: "directions_walk"
    },

    //-------Rent Car-------//
    RentCar: {
        LabelKay: "RentCar", //Chiave di traduzione label//
        ToolTip: "RentCar",
        class: "material-icons",
        Value: "directions_car"
    },
    RentCarPickUp: {
        LabelKay: "RentCarPickUp", //Chiave di traduzione label//
        ToolTip: "Pick Up",
        class: "material-icons",
        Value: "car_rental"
    },
    RentCarDropOff: {
        LabelKay: "RentCarDropOff", //Chiave di traduzione label//
        ToolTip: "Drop Off",
        class: "material-icons",
        Value: "car_rental"
    },
    Disabled: {
        LabelKay: "Disabled", //Chiave di traduzione label//
        ToolTip: "Disabled",
        class: "material-icons",
        Value: "disabled_by_default"
    },
    //-------Cruise-------//
    Cruise: {
        LabelKay: "Product:Cruise:Item", //Chiave di traduzione label//
        ToolTip: "Product:Cruise:Item",
        class: "material-icons",
        Value: "directions_boat"
    },
    Ship: {
        LabelKay: "Product:Cruise:Ship", //Chiave di traduzione label//
        ToolTip: "Product:Cruise:Ship",
        class: "material-icons",
        Value: "directions_boat"
    },
    Cabin: {
        LabelKay: "Product:Cruise:Cabin", //Chiave di traduzione label//
        ToolTip: "Product:Cruise:Cabin",
        class: "material-icons",
        Value: "bed"
    },
    Port: {
        LabelKay: "Product:Cruise:Port", //Chiave di traduzione label//
        ToolTip: "Product:Cruise:Port",
        class: "material-icons",
        Value: "houseboat"
    },

    //-------Structure-------//
    Structure: {
        LabelKay: "Product:Structure:Item", //Chiave di traduzione label//
        ToolTip: "Product:Structure:Item",
        class: "material-icons",
        Value: "business"
    },
    MealPlan: {
        LabelKay: "MealPlan", //Chiave di traduzione label//
        ToolTip: "MealPlan",
        class: "material-icons",
        Value: "restaurant"
    },
    CityTax: {
        LabelKay: "CityTax", //Chiave di traduzione label//
        ToolTip: "CityTax",
        class: "material-icons",
        Value: "announcement"
    },
    Star: {
        LabelKay: "Star", //Chiave di traduzione label//
        ToolTip: "Star",
        class: "material-icons",
        Value: "star"
    },

    //-------Transfer-------//
    Transfer: {
        LabelKay: "Product:Transfer:Item", //Chiave di traduzione label//
        ToolTip: "Product:Transfer:Item",
        class: "material-icons",
        Value: "airport_shuttle"
    },

    //-------Tour-------//
    Tour: {
        LabelKay: "Product:Tour:Item", //Chiave di traduzione label//
        ToolTip: "Product:Tour:Item",
        class: "material-icons",
        Value: "tour"
    },

    //-------Treni-------//
    Train: {
        LabelKay: "Product:Train:Item", //Chiave di traduzione label//
        ToolTip: "Product:Train:Item",
        class: "material-icons",
        Value: "train"
    },
    TrainStation: {
        LabelKay: "SearchEngine:Station", //Chiave di traduzione label//
        ToolTip: "SearchEngine:Station",
        class: "material-icons",
        Value: "directions_railway_filled"
    },
    TrainCard: {
        LabelKay: "Product:Train:TrainCard", //Chiave di traduzione label//
        ToolTip: "Product:Train:TrainCard",
        class: "material-icons",
        Value: "card_membership"
    },

    //-------Flight-------//
    Flight: {
        LabelKay: "Product:Flight:Item", //Chiave di traduzione label//
        ToolTip: "Product:Flight:Item",
        class: "material-icons",
        Value: "flight"
    },
    NoFlight: {
        LabelKay: "", //Chiave di traduzione label//
        ToolTip: "",
        class: "material-icons",
        Value: "airplanemode_inactive"
    },
    FlightOff: {
        LabelKay: "FlightTakeOff", //Chiave di traduzione label//
        ToolTip: "Flight TakeOff",
        class: "material-icons",
        Value: "flight_takeoff"
    },
    FlightLand: {
        LabelKay: "FlightLand", //Chiave di traduzione label//
        ToolTip: "Flight Land",
        class: "material-icons",
        Value: "flight_land"
    },
    FlightBagage: {
        LabelKay: "FlightBagage", //Chiave di traduzione label//
        ToolTip: "Baggage",
        class: "material-icons",
        Value: "luggage"
    },
    FlightSeat: {
        LabelKay: "FlightSeat", //Chiave di traduzione label//
        ToolTip: "Seat",
        class: "material-icons",
        Value: "airline_seat_recline_normal"
    },
    FlightAddService: {
        LabelKay: "FlightAddService", //Chiave di traduzione label//
        ToolTip: "Add Service",
        class: "material-icons",
        Value: "library_add"
    },
    FlightRoule: {
        LabelKay: "FlightRoule", //Chiave di traduzione label//
        ToolTip: "Fare Roules",
        class: "material-icons",
        Value: "segment"
    },
    FlightFareTable: {
        LabelKay: "FlightFareTable", //Chiave di traduzione label//
        ToolTip: "Fare Table",
        class: "material-icons",
        Value: "table_chart"
    },
    FlightTicket: {
        LabelKay: "FlightTicket", //Chiave di traduzione label//
        ToolTip: "Flight Ticket",
        class: "material-icons",
        Value: "airplane_ticket"
    },
    FlightServices: {
        LabelKay: "FlightService", //Chiave di traduzione label//
        ToolTip: "Flight Service",
        class: "material-icons",
        Value: "bookmark_add"
    },
    FlightAlliance: {
        LabelKay: "FlightAlliance", //Chiave di traduzione label//
        ToolTip: "FLight Alliance",
        class: "material-icons",
        Value: "group_work"
    },
    FlightCabinClass: {
        LabelKay: "FlightCabinClass", //Chiave di traduzione label//
        ToolTip: "FLight Cabin Class",
        class: "material-icons",
        Value: "flight_class"
    },
    FlightFaresType: {
        LabelKay: "FlightFaresType", //Chiave di traduzione label//
        ToolTip: "FLight Fares Type",
        class: "material-icons",
        Value: "airplane_ticket"
    },
    FlightStop: {
        LabelKay: "FlightStop", //Chiave di traduzione label//
        ToolTip: "FLight Stop",
        class: "material-icons",
        Value: "connecting_airports"
    },
    //---------Amenities--------//
    Wifi: {
        LabelKay: "Wifi", //Chiave di traduzione label//
        ToolTip: "Wifi",
        class: "material-icons",
        Value: "wifi"
    },
    SafeBox: {
        LabelKay: "SafeBox", //Chiave di traduzione label//
        ToolTip: "SafeBox",
        class: "material-icons",
        Value: "lock"
    },
    Parking: {
        LabelKay: "Parking", //Chiave di traduzione label//
        ToolTip: "Parking",
        class: "material-icons",
        Value: "local_parking"
    },
    Accessible: {
        LabelKay: "Accessible", //Chiave di traduzione label//
        ToolTip: "Accessible",
        class: "material-icons",
        Value: "accessible"
    },
    Pet: {
        LabelKay: "Pet", //Chiave di traduzione label//
        ToolTip: "Pet",
        class: "material-icons",
        Value: "pets"
    },
    AirCondition: {
        LabelKay: "AirCondition", //Chiave di traduzione label//
        ToolTip: "Air Condition",
        class: "material-icons",
        Value: "ac_unit"
    },
    BusinessCenter: {
        LabelKay: "BusinessCenter", //Chiave di traduzione label//
        ToolTip: "Business Center",
        class: "material-icons",
        Value: "groups_3"
    },
    CreditCard: {
        LabelKay: "CreditCard", //Chiave di traduzione label//
        ToolTip: "Credit Card",
        class: "material-icons",
        Value: "credit_card"
    },
    Fitness: {
        LabelKay: "Fitness", //Chiave di traduzione label//
        ToolTip: "Fitness",
        class: "material-icons",
        Value: "petsfitness_center"
    },
    Golf: {
        LabelKay: "Golf", //Chiave di traduzione label//
        ToolTip: "Golf",
        class: "material-icons",
        Value: "golf_course"
    },
    MiniClub: {
        LabelKay: "MiniClub", //Chiave di traduzione label//
        ToolTip: "Mini Club",
        class: "material-icons",
        Value: "child_care"
    },
    AirportShuttle: {
        LabelKay: "AirportShuttle", //Chiave di traduzione label//
        ToolTip: "Airport   Shuttle",
        class: "material-icons",
        Value: "airport_shuttle"
    },
    Pool: {
        LabelKay: "Pool", //Chiave di traduzione label//
        ToolTip: "Pool",
        class: "material-icons",
        Value: "pool"
    },
    Reception: {
        LabelKay: "Reception", //Chiave di traduzione label//
        ToolTip: "Reception",
        class: "material-icons",
        Value: "support_agent"
    },
    Restourant: {
        LabelKay: "Restourant", //Chiave di traduzione label//
        ToolTip: "Restourant",
        class: "material-icons",
        Value: "food_bank"
    },
    SPA: {
        LabelKay: "SPA", //Chiave di traduzione label//
        ToolTip: "SPA",
        class: "material-icons",
        Value: "spa"
    },


    //--------Tag--------------//
    Suggest: {
        LabelKay: "Suggest", //Chiave di traduzione label//
        ToolTip: "Suggest",
        class: "material-icons",
        Value: "star"
    },
    FavoriteOutline: {
        LabelKay: "FavoriteOutline", //Chiave di traduzione label//
        ToolTip: "FavoriteOutline",
        class: "material-icons",
        Value: "favorite_border"
    },
    Favorite: {
        LabelKay: "Favorite", //Chiave di traduzione label//
        ToolTip: "Favorite",
        class: "material-icons",
        Value: "favorite"
    },

    // Informazioni sulle liste/dettagli
    FormatOverline: {
        LabelKay: "Info", //Chiave di traduzione label//
        ToolTip: "Info",
        class: "material-icons",
        Value: "format_overline"
    },
    Start: {
        LabelKay: "Info", //Chiave di traduzione label//
        ToolTip: "Info",
        class: "material-icons",
        Value: "hail"
    },
    DoneAll: {
        LabelKay: "Info", //Chiave di traduzione label//
        ToolTip: "Info",
        class: "material-icons",
        Value: "done_all"
    },
    RemoveDone: {
        LabelKay: "Info", //Chiave di traduzione label//
        ToolTip: "Info",
        class: "material-icons",
        Value: "remove_done"
    },

    // SearchEngine
    CalendarMonth: {
        LabelKay: "SearchEngine:Period", //Chiave di traduzione label//
        ToolTip: "SearchEngine:Period",
        class: "material-icons",
        Value: "calendar_month"
    },
    Groups: {
        LabelKay: "SearchEngine:Travelers", //Chiave di traduzione label//
        ToolTip: "SearchEngine:Travelers",
        class: "material-icons",
        Value: "groups"
    },
    RoundTrip: {
        LabelKay: "SearchEngine:Roundtrip", //Chiave di traduzione label//
        ToolTip: "SearchEngine:Roundtrip",
        class: "material-icons",
        Value: "multiple_stop"
    },
    OneWay: {
        LabelKay: "SearchEngine:Oneway", //Chiave di traduzione label//
        ToolTip: "SearchEngine:Oneway",
        class: "material-icons",
        Value: "arrow_right_alt"
    },
    MultiStep: {
        LabelKay: "SearchEngine:Multistep", //Chiave di traduzione label//
        ToolTip: "SearchEngine:Multistep",
        class: "material-icons",
        Value: "route"
    },

    // Destination
    Recent: {
        LabelKay: "SearchEngine:Recent", //Chiave di traduzione label//
        ToolTip: "SearchEngine:Recent",
        class: "material-icons",
        Value: "restore"
    },
    Destination: {
        LabelKay: "SearchEngine:Destination", //Chiave di traduzione label//
        ToolTip: "SearchEngine:Destination",
        class: "material-icons",
        Value: "location_on"
    },
    World: {
        LabelKay: "SearchEngine:World", //Chiave di traduzione label//
        ToolTip: "SearchEngine:World",
        class: "material-icons",
        Value: "public"
    },

    // Cart
    Cart: {
        LabelKay: "Cart", //Chiave di traduzione label//
        ToolTip: "Cart",
        class: "material-icons",
        Value: "shopping_cart_checkout"
    },
    CartBillingData: {
        LabelKay: "Cart:BillingData", //Chiave di traduzione label//
        ToolTip: "Cart:BillingData",
        class: "material-icons",
        Value: "mode_edit"
    },
    Scadenziario: {
        LabelKay: "Cart:Scadenziario", //Chiave di traduzione label//
        ToolTip: "Cart:Scadenziario",
        class: "material-icons",
        Value: "euro"
    },
    Generic: {
        LabelKay: "Cart:Generic", //Chiave di traduzione label//
        ToolTip: "Cart:Generic",
        class: "material-icons",
        Value: "description"
    },
    Save: {
        LabelKay: "Cart:Save", //Chiave di traduzione label//
        ToolTip: "Cart:Save",
        class: "material-icons",
        Value: "save"
    },
    Send: {
        LabelKay: "Cart:Send", //Chiave di traduzione label//
        ToolTip: "Cart:Send",
        class: "material-icons",
        Value: "email"
    },
    Reply: {
        LabelKay: "Cart:Send", //Chiave di traduzione label//
        ToolTip: "Cart:Send",
        class: "material-icons",
        Value: "reply"
    },
    Pdf: {
        LabelKay: "Cart:Print", //Chiave di traduzione label//
        ToolTip: "Cart:Print",
        class: "material-icons",
        Value: "description"
    },
    Preview: {
        LabelKay: "Cart:Preview", //Chiave di traduzione label//
        ToolTip: "Cart:Preview",
        class: "material-icons",
        Value: "preview"
    },
    Search: {
        LabelKay: "Cart:Search", //Chiave di traduzione label//
        ToolTip: "Cart:Search",
        class: "material-icons",
        Value: "search"
    },
    Signpost: {
        LabelKay: "Cart:Signpost", //Chiave di traduzione label//
        ToolTip: "Cart:Signpost",
        class: "material-icons",
        Value: "signpost"
    },
    MyBook: {
        LabelKay: "Cart:MyBook", //Chiave di traduzione label//
        ToolTip: "Cart:MyBook",
        class: "material-icons",
        Value: "book"
    },
    Wallet: {
        LabelKay: "Cart:Wallet", //Chiave di traduzione label//
        ToolTip: "Cart:Wallet",
        class: "material-icons",
        Value: "account_balance_wallet"
    },
    Savings: {
        LabelKay: "Cart:Savings", //Chiave di traduzione label//
        ToolTip: "Cart:Savings",
        class: "material-icons",
        Value: "savings"
    },

    // Quotation
    Operation: {
        LabelKay: "Quotation:Operation", //Chiave di traduzione label//
        ToolTip: "Quotation:Operation",
        class: "material-icons-outlined",
        Value: "calculate"
    },
    Check: {
        LabelKay: "Quotation:Operation", //Chiave di traduzione label//
        ToolTip: "Quotation:Operation",
        class: "material-icons-outlined",
        Value: "check_box"
    },
    Cloud: {
        LabelKay: "Quotation:Operation", //Chiave di traduzione label//
        ToolTip: "Quotation:Operation",
        class: "material-icons-outlined",
        Value: "cloud"
    },
    AddNote: {
        LabelKay: "Quotation:Operation", //Chiave di traduzione label//
        ToolTip: "Quotation:Operation",
        class: "material-icons-outlined",
        Value: "note_add"
    },
    Clock: {
        LabelKay: "Quotation:Operation", //Chiave di traduzione label//
        ToolTip: "Quotation:Operation",
        class: "material-icons-outlined",
        Value: "schedule"
    },

    // Template
    Template: {
        LabelKay: "Template:TemplatePackageSummaryTitle", //Chiave di traduzione label//
        ToolTip: "Template:TemplatePackageSummaryTitle",
        class: "material-icons-outlined",
        Value: "cases"
    }
}