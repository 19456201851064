import React, { useState, useEffect } from 'react';
import { M3Icon } from "../../../Common/M3Icon";

export const ProductFilterByName = ({ items, label, type, onApplyFilter, resetSelectedItem, noPredictions }) => {
    const [nameToFilter, setNameToFilter] = useState('');

    const [predictions, setPredictions] = useState([]);

    useEffect(() => {

        document
            .getElementById("txtFilterNamePR")
            .addEventListener('keyup', function (e) {
                if (e.key === 'Enter' || e.keyCode === 13) {
                    let name = document.getElementById("txtFilterNamePR").value;

                    setNameToFilter(name);
                    callOnApplyFilter(name);
                }
            });

    }, []);

    useEffect(() => {
        setPredictions([]);
        setNameToFilter('');
    }, [resetSelectedItem])

    function onChangeName(event) {
        let name = event.target.value;

        let filterNames = [];
        if (items && items.length > 0 && name && name.length > 3)
            filterNames = items.filter(n => n.toLowerCase().includes(name.toLowerCase()));

        setNameToFilter(name);
        if (!noPredictions)
            setPredictions(filterNames);

        if (!name)
            callOnApplyFilter(name);
    }

    function onSelectPrediction(value) {
        setNameToFilter(value);
        callOnApplyFilter(value);
    }

    function callOnApplyFilter(name) {
        setPredictions([]);
        onApplyFilter(name);
    }

    return (
        <>
            <div className="input-group mb-3 shadow">

                <input
                    id="txtFilterNamePR"
                    type="text"
                    className="small border-0 w-90 rounded"
                    placeholder={label}
                    value={nameToFilter}
                    onChange={e => onChangeName(e)}
                    aria-describedby="button-addon2" />
                <button className="btn btn-neutral m-0 p-0 pr-1 d-inline w-10" type="button" id="button-addon2" onClick={e => { callOnApplyFilter(nameToFilter) }}>
                    <data m3ico="Filter icon-15 text-bluesoft">
                        <M3Icon iconName="Filter" externalClass="icon-15 text-bluesoft" hasLabel={false} />
                    </data>
                </button>
            </div>
            {
                predictions && predictions.length > 0 && <div style={{ maxHeight: '120px', overflowY: 'scroll' }}>
                    <ul className="px-4">
                        {
                            predictions.map((prediction, key) => {
                                return <li key={key} className="mt-1"
                                    onClick={e => onSelectPrediction(prediction)}
                                    style={{ fontSize: '12px', cursor: 'poiner' }}
                                >
                                    <div className="w-100" key={key}>{prediction}</div>
                                </li>
                            })
                        }
                    </ul>
                </div>
            }
        </>
    );
}