import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CarSearchEngine } from "../../Product/SearchEngine/CarSearchEngine";

export const CarSearchEngineTrippie = ({ searchParameters, typeSearch, extraParams, onStartSearch }) => {
    const { t } = useTranslation();
    const [extraParamsNorm, setExtraParamsNorm] = useState();

    useEffect(() => {
        if (extraParams) {
            extraParams.validDateRule = undefined;
            setExtraParamsNorm(extraParams);
        }
    }, [extraParams])

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <div className="h6 fw-bold d-none">Auto suggerite</div>
                </div>
                <div className="col-6 h6 text-end text-decoration-none">
                    <span className="cursor-pointer btn tp-btn-search" data-bs-toggle="modal" data-bs-target="#EngineModifyCar">Ricerca</span>
                </div>
            </div>

            <div className="modal tp-search-input fade" id="EngineModifyCar" tabIndex="-1" aria-labelledby="EngineModifyCar" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen-xxl-down">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <div className="h4 text-center w-100 mt-2">
                                {t('SearchEngine:UpdateSearch')} - {t('Product:RentCar:Item')}
                            </div>
                            <button type="button" className="btn-close mt-2 pr-4 tp-btn-trippie" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body bg-gray-300">
                            <CarSearchEngine
                                searchParameters={searchParameters}
                                typeSearch={typeSearch}
                                extraParams={extraParamsNorm}
                                resetValues={false}
                                isTrippie={true}
                                onSearchTrippie={onStartSearch}
                                modeView="MODAL" />
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}