import React, { useState, useEffect } from 'react';
import { getCustomStyle, getColumnClasses } from '../service/WebContentService';
import { WebContentFormColumn } from './WebContentFormColumn';

export const WebContentFormItems = ({ item }) => {
    const [myStyle, setMyStyle] = useState({});
    const [formId, setFormId] = useState('');
    const [containerClass, setContainerClass] = useState('container');
    const [rowClass, setRowClass] = useState('row mx-0');

    useEffect(() => {
        let cssStyle = getCustomStyle(item.form);
        setMyStyle(cssStyle);

        let myFormId = `WebContent_Form_${item.form.webContentId}`;
        if (item.form.anchor)
            myFormId = item.form.anchor;

        setFormId(myFormId);

        if (item.form.fullscreen) {
            setContainerClass('');
            setRowClass('row mx-0 px-5');
        }
        
        if (item.form.backgroundUrl && item.form.backgroundUrl === 'DEFAULT') {
            setContainerClass('container bg-image-bv');
        }
           

    }, []);
    
    return <div id={formId} className={containerClass} >
        <div className={rowClass} style={myStyle}>
            {
                item.form.columns && item.form.columns.length > 0 ? <WebContentFormColumn item={item.form.columns[0]} />
                                                                  : <></>
            }
            {
                item.form.spaziamento && item.form.spaziamento > 0 ? <div className={getColumnClasses(item.form.spaziamento)}></div>
                                                                   : <></>
            }
            {
                item.form.columns && item.form.columns.length > 1 ? <WebContentFormColumn item={item.form.columns[1]} />
                                                                  : <></>
            }
        </div>
    </div>;
}