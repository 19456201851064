import React from 'react';
import { useTranslation } from 'react-i18next';

export const HeaderMyBook = ({ item, modal }) => {
    const { t } = useTranslation();

    function getClassName(className) {
        if (!className)
            return '';

        try {
            return className.split("#")[1];
        } catch (ex) { }

        return '';
    }

    return (
        <a href="mybook/orders" aria-current="page" className={`${getClassName(item.className)} d-none d-sm-block ${(modal ? 'd-block' : '')}`}>
            <div>
                <div className={`${modal ? "" : 'h065'} text-decoration-none`}>
                    {t('Header:Your')}:
                </div>
                <div className={`${modal ? '' : 'h065'}`}>
                    > {t('Header:Orders')}
                </div>
            </div>
        </a>
    );
}