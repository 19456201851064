export function getCustomStyle(item) {
    let cssStyle = {};

    if (item.marginTop)
        cssStyle.marginTop = item.marginTop;

    if (item.marginBottom)
        cssStyle.marginBottom = item.marginBottom;

    if (item.paddingTop)
        cssStyle.paddingTop = item.paddingTop;

    if (item.paddingBottom)
        cssStyle.paddingBottom = item.paddingBottom;

    if (item.backgroundUrl && item.backgroundUrl.includes('http')) {
        cssStyle.backgroundImage = `url("${item.backgroundUrl}")`;
        cssStyle.backgroundRepeat = 'no-repeat';
        cssStyle.backgroundSize = 'cover';

    } else {
        cssStyle.borderRadius = '10px';
    }

    if (item.backgroundColor)
        cssStyle.backgroundColor = item.backgroundColor;

    if (item.textColor)
        cssStyle.textColor = item.textColor;

    return cssStyle;
}

export function getColumnClasses(columnType) {
    return "col-" + columnType;
}