import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GalleryList } from "../../Common/Image/GalleryList";
import { ProductPriceBlock } from '../../Common/Pricing/ProductPriceBlock';


export const CruiseItemListBlock = ({ item, isViewDetail = false, showPriceBar, showCost, extraParam, onClickSearch }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!extraParam)
            extraParam = {};

        extraParam.productId = item.cruiseCode;
    }, [item]);

    function onClickBlockSearch(cruiseCode, extSelectedFlt) {
        let cabinCategoryCode = item.bestPriceCabinCategoryCode;
        if (extSelectedFlt && extSelectedFlt.length > 0) {
            let selectCC = extSelectedFlt.filter(x => x.type === 'SELECTLABEL');
            if (selectCC.length > 0)
                cabinCategoryCode = selectCC[0].value;
        }

        let ids = {
            cruiseCode: item.cruiseCode,
            cabinCategoryCode: cabinCategoryCode
        };

        onClickSearch(ids);
    }

    return (
        <>
            <div className="col-12 col-sm-3 col-md-3 col-lg-3 p-1 cursor-pointer" onClick={_ => onClickBlockSearch()}>
                <div className="card animate-up-5 h-100 shadow">
                    <GalleryList
                        productId={item.cruiseCode}
                        defaultImage={item.thumbUrl}
                        gallery={item.images}
                        alternativeText={item.name}
                    />
                    <div className="card-body p-1 m-0">
                        <div className="p-1 card-text">
                            <span className="card-title small p-0 m-0">
                                <var>{item.name}</var>
                            </span>
                        </div>
                    </div>
                    <div className="card-footer p-0">
                        <ProductPriceBlock
                            pricing={item.pricing}
                            supplier={item.supplier}
                            showPriceBar={showPriceBar}
                            showCost={showCost}
                            typePrice="XCabin"
                            otherParam={{ buttonEnabled: true }} />
                    </div>
                </div>
            </div>
        </>
    );
}