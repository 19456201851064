import React from 'react';
import { imageOnLoad } from '../../../js/CDNUtility';
import { replaceAllStyleElements } from "../../../js/PrintUtility";
import { useTranslation } from 'react-i18next';


export const ProductDescription = ({ index, category, description, gallery, noImage, productType }) => {
    const { t } = useTranslation();
    function getImage() {
        let imageUrl = imageOnLoad(productType);

        if (gallery) {
            let position = 0;
            if (index > 0)
                position = (gallery.length % index) + 1;

            try {
                let newUrl = gallery[position];

                if (newUrl.path)
                    imageUrl = newUrl.path;
                else if (newUrl)
                    imageUrl = newUrl;

            } catch (ex) { }

        }

        let myStyle = {
            backgroundImage: `url("${imageUrl}"), url("${imageOnLoad(productType)}")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "100%"
        };
        return <div className="rounded" style={myStyle}></div>;
    }

    function isAllScreen(myCategory) {
        if (!myCategory)
            return false;

        switch (myCategory.toUpperCase()) {
            case 'PROGRAM':
            case 'PROGRAMMA':
                return true;
            default:
                return false;
        }
    }

    function getTitle(title) {
        if (!title)
            return false;
        
        switch (title.toUpperCase().trim()) {
            case 'PROGRAM':
                return t('MyBook:Program');
            case 'PROGRAMMA':
                return t('MyBook:Program');
            case 'INCLUSIONS/EXCLUSIONS':
                return t('MyBook:INLCUSIONS/EXLUSIONS');
            case 'ACTIVITY DESCRIPTION':
                return t('MyBook:ActivityDescription');

            default:
                return title;
        }
    }
    
    return (
        <>
            <div className="card my-4 animate-up-5 shadow">
                {!noImage ?
                    <div className="row no-gutters">
                        {
                            !isAllScreen(category) && index % 2 === 0 && <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                {getImage()}
                            </div>
                        }
                        <div className={isAllScreen(category) ? "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" : "col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8"}>
                            <div className="myBook-card-body pb-2">
                                <div>
                                    <div className="h4">
                                        <var>{category}</var>
                                    </div>
                                    <div className="w-100">
                                        <div className="text-justify" dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(description) }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            !isAllScreen(category) && index % 2 !== 0 && <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                {getImage()}
                            </div>
                        }
                    </div>
                :
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="myBook-card-body pb-2">
                            <div>
                                <div className="h4">
                                    <var>{getTitle(category)}</var>
                                </div>
                                <div className="w-100">
                                    <div className="text-justify" dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(description) }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}