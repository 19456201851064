import React, { useEffect, useState } from 'react';
import { DocHeader } from './DocHeader';
import { DocOrderDetail } from './DocOrderDetail';
import { DocTitle } from './DocTitle';
import { TimetablePayments } from './TimetablePayments';
import { useParams } from "react-router-dom";
import { formatPrice } from '../../js/Utils.js';
import configData from "../../appsettings.json";
import { Loading } from '../Common/Loading';
import { getCDNLogoDocument } from "../../js/CDNUtility";
import { AccountBalancePrintButton } from "./print/AccountBalance/AccountBalancePrintButton";
import { useTranslation } from 'react-i18next';
import { getHeaderPrint } from "../Print/service/CommonPrintService"
import { FooterPDFPrint } from '../Custom/Print/FooterPDFPrint';

export const AccountBalance = () => {
    let { orderNumber } = useParams();
    let { userType } = useParams();
    let { visualizationType } = useParams();
    let { grouped } = useParams();
    let { cultureName } = useParams()
    let { authcode } = useParams()
    const [HeaderData, setHeaderData] = useState([]);
    const [DocOrderDetailData, setDocOrderDetailData] = useState([]);
    const [TitleData, setTitleData] = useState([]);
    const [order, setOrder] = useState({ pratiche: [] });
    const [beginData, setBeginData] = useState([]);
    const [endData, setEndData] = useState([]);
    const [scadenziario, setScadenziario] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [footerElements, setFooterElements] = useState([])
    const { t } = useTranslation();
    async function getPdfFooter() {
        const footerElements = await getHeaderPrint(200, cultureName) // pdf header
        setFooterElements(footerElements)
    }
    useEffect(() => {
        getPdfFooter()
        const loadData = async (data) => {
            const response = await fetch(configData.Settings.CommonApi_BaseUrl + 'document/GetAccountBalance/' + data.OrderNumber + '/' + data.UserType + '/' + data.VisualizationType + '/' + data.Grouped + '/' + data.CultureName + '/' + data.AuthCode, { credentials: 'include' });
            if (!response.ok) return;

            const jresponse = await response.json();
            setOrder(jresponse);
            var hd = jresponse.header;
            hd.isCommissionable = jresponse.isCommissionable;
            setHeaderData(hd);
            setTitleData(jresponse.title);
            setBeginData({ section: "BEGIN" });
            setEndData({ section: "END" });
            setDocOrderDetailData(jresponse);
            setIsLoading(false);
        }
        loadData({ OrderNumber: orderNumber, VisualizationType: visualizationType, UserType: userType, Grouped: grouped, CultureName: cultureName, AuthCode: authcode }).catch(console.error);
    }, []);

    function onTimetableDetail(scadenziario) {
        if (configData.Settings.Cart.ShowScadenziario)
            setScadenziario(scadenziario);
    }

    const renderResumeCommissionRows = (commissions, grouped) => {
        return (
            <>
                {commissions.map(comm =>
                    <tr key={comm.roundPerc}>
                        <td className="left">  {
                            grouped === 'true' ? ('Commissione')
                                : <strong>Commissione {comm.label} %</strong>
                        }
                        </td>
                        <td className="right">{formatPrice(comm.amount, comm.currency, cultureName)}</td>
                    </tr>
                )}
            </>
        );
    }

    function callPrintPDFFile() {
        let myButton = document.getElementById('printAccountBalance');
        myButton.click();
    }

    const renderView = (order, userType, grouped, cultureName, authcode) => {
        return <main>
            <section className="section bg-soft section-lg pt-5">
                <div className="container">
                    <div className="row justify-content-center document">
                        <div className="col-12 col-lg-10">
                            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between mb-4">
                                <div className=" d-print-none">

                                    <a href={`mybook/orders/detail/${orderNumber}`} className="mb-4 mb-lg-0">
                                        <button className="btn btn-print mb-4  mr-2" >
                                            <span className="material-icons-outlined position-relative" style={{ top: '6px' }}>arrow_back</span> {t("MyBook:ComeToOrder")}
                                        </button>
                                    </a>
                                    <button className="btn btn-primary mb-4 mr-2" onClick={e => callPrintPDFFile()}>
                                        <span className="material-icons-outlined">file_download</span> {t("Button:Print")} PDF
                                    </button>
                                    <AccountBalancePrintButton
                                        footerElements={footerElements}
                                        headerData={HeaderData}
                                        titleData={TitleData}
                                        order={order}
                                        visualizationType={visualizationType}
                                        userType={userType}
                                        grouped={grouped}
                                        cultureName={cultureName}
                                        scadenziario={scadenziario}
                                    />
                                    {
                                        userType === 'A' ?
                                            <a href={`Documents/AccountBalance/${orderNumber}/U/V/false/${cultureName}/${authcode}`} target='_new' className='mb-4 mb-lg-0'>
                                                <button className="btn btn-print mb-4 mr-2" >
                                                    <span className="material-icons-outlined position-relative" style={{ top: '6px' }}>search</span> {t("MyBook:ClientVersion")}
                                                </button>
                                            </a>
                                            : ''
                                    }
                                </div>
                            </div>
                            <div className="card border-light p-4 p-md-5 position-relative">
                                <div className="d-flex justify-content-between pb-4 pb-md-4 mb-4 mb-md-4 border-bottom border-light">
                                    <div className="document-invoice data-honer">
                                        <img src={getCDNLogoDocument()} className="d-lg-block" height="50" alt="Logo" />
                                    </div>
                                    <DocHeader Data={HeaderData} />
                                </div>
                                <DocTitle cultureName={cultureName} Data={TitleData} />
                                {/*<!--Table Prezzi dettglio-->*/}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="table-responsive">
                                            {
                                                order.pratiche.length > 0 ? (
                                                    <DocOrderDetail order={order} visualizationType={visualizationType} userType={userType} grouped={grouped} cultureName={cultureName} />
                                                ) : (
                                                    isLoading
                                                        ? <Loading textMsg={t('MyBook:Loading')} />
                                                        : <></>
                                                )
                                            }
                                            <div className="d-flex justify-content-end text-right mb-4 border-bottom">
                                                <div className="mt-4">
                                                    <table className="table table-clear document-invoice">
                                                        <tbody>
                                                            <tr className="py-1">
                                                                <td className="left">
                                                                    <strong>Totale</strong>
                                                                </td>
                                                                <td className="right">{formatPrice(order.totalInvoicePrice, order.currency, cultureName)}</td>
                                                            </tr>
                                                            {/*<!--Da qui in giu solo per profilo agenzia-->*/}
                                                            {userType === 'A' && order.resumeCommissions && order.resumeCommissions.length > 0 ? renderResumeCommissionRows(order.resumeCommissions, grouped)
                                                                : ''}
                                                            {/*<!--fino  qui in giu solo per profilo agenzia-->*/}
                                                            {userType === 'A' ? (
                                                                <tr className="py-1">
                                                                    <td className="left">
                                                                        <strong>Netto da pagare</strong>
                                                                    </td>
                                                                    <td className="right">{formatPrice(order.totalToPay, order.currency, cultureName)}</td>
                                                                </tr>
                                                            ) : ''}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {/*<!--Scadenziario-->*/}
                                            {userType === 'A' ? (
                                                <TimetablePayments
                                                    orderNr={orderNumber}
                                                    visualizationType={visualizationType}
                                                    userType={userType}
                                                    grouped={grouped}
                                                    cultureName={cultureName}
                                                    currency={order.currency}
                                                    onTimetableDetail={onTimetableDetail}
                                                />
                                            ) : ''}
                                        </div>
                                    </div>
                                    <div className="text-center">

                                    <FooterPDFPrint footerElements={footerElements} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    }

    const handlePrintPdf = async e => {
        e.preventDefault();

        setIsLoading(true);
        //const d = new Date();
        //const fd = d.getFullYear() + (d.getMonth() + 1).toString() + d.getDate().toString();
        const url = configData.Settings.Base_Url + 'api/Document/GetAccountBalance/' + orderNumber + '/' + userType + '/P/' + grouped + '/' + cultureName + '/' + authcode;
        const response = await fetch(url, null).then(data => data.json());
        return response;
    }

    return (
        <>
            {visualizationType === 'V' ? renderView(order, userType, grouped, cultureName, authcode) : <></>}
        </>
    );
}
