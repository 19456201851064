import React, { useState, useEffect } from 'react';
import configData from "../../../../appsettings.json";
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../../js/Utils.js';
import { Timeline } from 'primereact/timeline';
import { Loading } from '../../../Common/Loading';
import { Error } from '../../../Common/Error';

export const TrainModal = ({ trainLeg, carpetId, modalCloseProductId }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    const [segmentInfos, setSegmentInfos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (trainLeg) {
            let myBtn = document.getElementById("btn_openModalTrain");
            myBtn.click();

            getTrainStopsAndNotes();
        }
    }, [trainLeg]);

    useEffect(() => {
        let myModal = document.getElementById("schedStaticaModalTrain");
        myModal.addEventListener('hidden.bs.modal', function () {
            modalCloseProductId(null);
        });
    }, []);

    async function getTrainStopsAndNotes() {
        setIsLoading(true);
        setIsError(false);

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}train/gettrainstopsandnotes/${carpetId}/${trainLeg.leg.uniqueId}/${cultureName}`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response.success) {
                let updSegmentInfo = [];
                for (let i = 0; i < trainLeg.leg.segments.length; i++) {
                    let segment = trainLeg.leg.segments[i];

                    let duration = '';
                    if (segment.duration) {
                        let durationParts = segment.duration.split(':');
                        duration = durationParts[0] + 'h ' + durationParts[1] + 'min';
                        if (duration[0] === "0")
                            duration = duration.slice(1);
                    }

                    let item = {
                        timeline: [],
                        vehicle: null,
                        notes: [],
                        duration: duration,
                        segment: segment,
                        isDayAfter: segment.isDayAfter
                    };

                    if (response.stops && response.stops.length > i) {
                        let stop = response.stops[i];
                        item.timeline = stop.timings;
                        item.vehicle = stop.vehicle;
                    }

                    if (response.notes && response.notes.notes && response.notes.notes.length > 0 && segment.noteIds && segment.noteIds.length > 0) {
                        let noteMatching = response.notes.notes.filter(x => segment.noteIds.includes(x.id));
                        item.notes = noteMatching;
                    }

                    updSegmentInfo.push(item);
                }

                setSegmentInfos(updSegmentInfo);
            } else {
                setIsError(true);
            }
        } else {
            setIsError(true);
        }

        setIsLoading(false);
    }


    return (
        <>
            <a id="btn_openModalTrain" data-bs-toggle="modal" data-bs-target="#schedStaticaModalTrain"></a>
            <div className="modal fade" id={"schedStaticaModalTrain"} tabIndex="-1" aria-labelledby={"exampleModalLabel"} aria-hidden="true">
                <div className="modal-dialog modal-xxl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id={"exampleModalLabel"}>
                                <data m3lab="Product:Train:Itinerary">{t(`Product:Train:Itinerary`)}</data>
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">

                            {
                                isLoading && !isError && <div className="row pt-4"><Loading textMsg="Stiamo interrogando il fornitore per avere l'itinerario sotto" /></div>
                            }
                            {
                                !isLoading && isError && <div className="row pt-4"><Error textMsg="Errore in fase di caricamento" /></div>
                            }
                            {
                                !isLoading && !isError && segmentInfos && segmentInfos.length > 0 && segmentInfos.map((segmentInfo, key) => {
                                    return <div className="row pt-4" key={key}>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                            <span className="mr-3 h085 fw-bold h5">
                                                {segmentInfo.segment.departure.stationName} ({segmentInfo.segment.departure.time} {segmentInfo && segmentInfo.segment.departure.isDayAfter && "*"})

                                                {segmentInfo && segmentInfo.segment.isDayAfter && <>{t('Product:Train:DepartureDayAfter')}</>}
                                            </span>
                                            <span className="h085 fw-bold h5"> > </span>
                                            <span className="ml-3 h085 fw-bold h5">
                                                {segmentInfo.segment.arrival.stationName} ({segmentInfo.segment.arrival.time} {segmentInfo && segmentInfo.segment.arrival.isDayAfter && "*"})
                                                {segmentInfo && segmentInfo.segment.arrival.isDayAfter && <>{t('Product:Train:ArrivalDayAfter')}</>}
                                            </span>

                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            {
                                                segmentInfo.timeline && segmentInfo.timeline.length > 0 && <Timeline
                                                    value={segmentInfo.timeline}
                                                    opposite={(item) => <small>
                                                        {item.arrivalTime ? formatDateTime(item.arrivalTime, cultureName, { onlyTime: true }) : formatDateTime(item.departureTime, cultureName, { onlyTime: true })}
                                                        {item.isDayAfter && "*"}
                                                    </small>}
                                                    content={(item) => <small>{item.stationName}</small>} />
                                            }
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                            <div className=" h5">
                                                {segmentInfo.vehicle && segmentInfo.vehicle.name} {segmentInfo.vehicle && segmentInfo.vehicle.number} -<label>{segmentInfo.duration}</label>
                                            </div>
                                            {segmentInfo.notes && segmentInfo.notes.length > 0 && <div className="h6">{t(`Product:Train:Services`)}</div>}
                                            {
                                                segmentInfo.notes && segmentInfo.notes.length > 0 && segmentInfo.notes.map((note, key) => {
                                                    return <React.Fragment key={key}>
                                                        <div className="mb-2">
                                                            {
                                                                note.icon && note.icon.data && <img
                                                                    src={"data:image/png;base64," + note.icon.data}
                                                                    className="mr-2 icon-10"
                                                                    alt={note.title}
                                                                    style={{ height: '15px' }}
                                                                />
                                                            }
                                                            {
                                                                note.description && note.description.length > 0 && note.description.map((desc, key) => {
                                                                    return <label key={key}>
                                                                        {desc}
                                                                    </label>
                                                                })
                                                            }
                                                            {
                                                                (!note.description || note.description.length === 0) && <label>{note.title}</label>
                                                            }
                                                        </div>
                                                    </React.Fragment>
                                                })
                                            }

                                        </div>
                                    </div>
                                })
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                <data m3lab="Button.Close">{t('Button:Close')}</data>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}