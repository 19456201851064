import React from 'react';
import { replaceAllStyleElements } from '../../../../../js/PrintUtility';

export const PrintVoucherBookingNote = ({ notes }) => {
    return (
        <>
            {notes && notes.length > 0 &&
            <>
                <div style={{ border: "1px solid grey", borderRadius: "5px", padding: "5px" }}>
                    <table className="document-bg-std x-100">
                        {notes.map((n, index) =>
                            <tr key={index}>
                                <td style={{ textAlign: "left" }}>
                                    <span dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(n.nota) }} />
                                </td>
                            </tr>
                        )}
                    </table>
                </div>
            </>
            }
        </>
    );
}