import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { imageOnError } from '../../../js/CDNUtility';
import { formatDateTime, formatPrice } from '../../../js/Utils.js';
import { getIconUrlToPrintCustom } from '../../../js/PrintUtility';
import { BookParameterId } from '../../../js/Constant';
import { PrintStepHeader } from './PrintStepHeader';


export const PrintPaxes = ({ paxes, isConfirmPrint, onHandleIsPrint }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();

    return (
        <>
            {paxes.length > 0 &&
                <>
                <PrintStepHeader
                    title="Passeggeri"
                    PNR=''
                    totaleViaggio=''
                    iconName=''
                    cultureName={cultureName}
                    onHandleIsPrint={onHandleIsPrint}
                />
                <div className="border">
                    <table className="w-100 p-1 shadow">
                        <thead>
                            <tr className="h6 customBackground">
                                <td>{t(`bookParameters:GST-01`)}</td>
                                <td>{t(`bookParameters:GST-03`)}</td>
                                <td>{t(`bookParameters:GST-05`)}</td>
                                <td className="text-right">{t(`bookParameters:GST-04`)}</td>
                            </tr>
                        </thead>
                        <tbody>
                            {paxes.map((pax, index) => 
                            <>
                                <tr className="border-bottom" key={index}>
                                    {isConfirmPrint ?
                                    <>
                                        <td>{pax.bookParameters.filter(x => x.id === BookParameterId.Personal.FirstName)[0].value ?
                                                pax.bookParameters.filter(x => x.id === BookParameterId.Personal.FirstName)[0].value
                                            :
                                                t(`General:Adult`)
                                        }
                                        </td>
                                        <td>{pax.bookParameters.filter(x => x.id === BookParameterId.Personal.LastName)[0].value}</td>
                                        <td>{pax.bookParameters.filter(x => x.id === BookParameterId.Personal.Gender)[0] &&
                                                pax.bookParameters.filter(x => x.id === BookParameterId.Personal.Gender)[0].value
                                            }
                                        </td>
                                        <td className="text-right">
                                            {pax.bookParameters.filter(x => x.id === BookParameterId.Personal.BirthDate)[0] &&
                                                formatDateTime(pax.bookParameters.filter(x => x.id === BookParameterId.Personal.BirthDate)[0].value, cultureName)
                                            }
                                        </td>
                                    </>
                                    :
                                    <>
                                        <td>{pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.FirstName)[0].bpValue ?
                                                pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.FirstName)[0].bpValue
                                            :
                                                t(`General:Adult`)
                                            }
                                        </td>
                                        <td>{pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.LastName)[0].bpValue}</td>
                                        <td>{pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.Gender)[0] &&
                                            pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.Gender)[0].bpValue
                                        }
                                        </td>
                                        <td className="text-right">
                                            {pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0] &&
                                                formatDateTime(pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0].bpValue, cultureName)
                                            }
                                        </td>
                                    </>
                                    }
                                </tr>
                            </>
                            )}
                        </tbody>
                    </table>
                </div>
            </>
            }   
        </>
    );
}