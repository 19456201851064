import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../Common/Loading';
import { clearAllSessionData } from '../../js/Utils.js';

export const GoToPrimary = () => {
    const { t } = useTranslation();

    useEffect(() => {
        clearAllSessionData();
        window.location.href = '/';
    }, [])

    return (
        <>
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className="col-12 text-center">
                        <Loading textMsg={t('Loading:GoToPrimary')} />
                    </div>
                </div>
            </div>
        </>
    );
}