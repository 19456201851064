import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatPrice, formatDateTime } from '../../../../js/Utils';
import { renderWebsite } from '../../../../js/Constant';
import { ProductAllPrice } from '../../Common/Pricing/ProductAllPrice';
import { CruiseDetailCabinCategoryPriceTable } from './CruiseDetailCabinCategoryPriceTable';
import { CruiseDetailCabinCategoryServices } from './CruiseDetailCabinCategoryServices';
import { ProductNotAllowedAddToCart } from '../../Common/ProductNotAllowedAddToCart';
import { M3Icon } from '../../../Common/M3Icon.js';

export const CruiseDetailCabinCategory = ({ cabinCategory, onClickBook, showCost, showPriceBar, enableCanAddToCart, quotationInfo, templateInfo, onOpenCancelPolicyDetail }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const { t } = useTranslation();
    const [showDetail, setShowDetail] = useState(false);

    const [pricing, setPricing] = useState(null);
    const [optionalServices, setOptionalServices] = useState([]);

    useEffect(() => {

        setPricing({ ...cabinCategory.pricing });
        setOptionalServices(cabinCategory.optionalServices);

    }, [cabinCategory])

    function onSelectOptionalServices(code, isChecked) {
        let updArr = [...optionalServices];

        let index = updArr.findIndex(x => x.code === code)
        if (index >= 0) {
            updArr[index].isSelected = isChecked;
        }

        let totalPricing = { ...cabinCategory.pricing };

        for (let i = 0; i < updArr.length; i++) {
            let fare = updArr[i];

            if (fare.isSelected) {
                // totalPricing.costoFornitore += fare.pricing.costoFornitore;

                totalPricing.commission += fare.commission;
                totalPricing.displayPrice += fare.amount;
            }

        }

        setPricing(totalPricing);
        setOptionalServices(updArr);
    }

    function onClickBookItem() {
        let optSelected = [];
        if (optionalServices)
            optSelected = optionalServices.filter(x => x.isSelected).map(x => x.code);

        let bookItem = {
            cabinCategoryProductCode: cabinCategory.productCode,
            selectOnBoardServices: optSelected
        };

       onClickBook(bookItem);
    }

    function getBgExperienceId(experienceId) {
        if (!experienceId)
            return "bg-orange-100";

        return "bg-cruise-experience-" + (experienceId + "").replaceAll(' ', '').toUpperCase();
    }

    function renderDeckDescription(descriptions) {
        if (!descriptions || descriptions.length === 0)
            return <p className="h065"></p>

        return descriptions.map((dd, index) => {
            return <p key={index} className="h065">
                {dd}
            </p>
        })
    }

    const renderTotalCabinPrice = (totalPrice) => {
        if (!totalPrice)
            return "";

        if (templateInfo && templateInfo.templateInfo && templateInfo.templateInfo.selectedProductPrice) {
            let diffPrice = totalPrice.displayPrice - templateInfo.templateInfo.selectedProductPrice;
            let sign = diffPrice > 0 ? "+" : "";

            return `${sign}${formatPrice(diffPrice, totalPrice.displayPriceCurrency, cultureName)}`;
        }

        return formatPrice(totalPrice.displayPrice, totalPrice.displayPriceCurrency, cultureName)
    }

    return (
        <>
            <div className={"row py-1 border-bottom animate-up-3 mx-1 rounded mealPlanRoom h075 " + getBgExperienceId(cabinCategory.experienceId)}>
                <div className="col-12 cursor-pointer" onClick={_ => setShowDetail(!showDetail)}>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <span className="h6">{cabinCategory.name}</span>
                            {renderDeckDescription(cabinCategory.descriptions)}
                        </div>
                        <div className="col-12 col-lg-1 small p-0">
                            <var>{cabinCategory.experience}</var>
                        </div>
                        <div className="col-12 col-lg-3">
                            <var>{cabinCategory.promotion?.name}</var>
                        </div>
                        <div className="col-12 col-lg-2">
                            <div className="d-table">
                                <div className="d-table-row text-right w-100">
                                    <div className="d-table-cell h6 w-100">
                                        <span className="mt-0">
                                            {pricing && renderTotalCabinPrice(pricing)}
                                        </span>
                                        {
                                            <ProductAllPrice showPriceBar={showPriceBar} showCost={showCost} pricing={pricing} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    showDetail && <div className="col-12 col-lg-12 h085">
                        <div className="row">
                            <div className="col-12 col-lg-7">
                                <label className="mr-1">{t("Product:Cruise:Decks")}: </label>
                                <var>
                                    {
                                        cabinCategory.decks && cabinCategory.decks.map((deck, indexDeck) => {
                                            return <React.Fragment key={indexDeck}>
                                                {deck.name}
                                                {indexDeck < (cabinCategory.decks.length - 1) ? "," : ""}
                                            </React.Fragment>
                                        })
                                    }
                                </var>
                            </div>
                            <div className="col-5 col-lg-5">
                                {
                                    cabinCategory.cancelPolicy && <span className={cabinCategory.cancelPolicy.isFree ? "penalty free" : "penalty"}>
                                        {
                                            cabinCategory.cancelPolicy.isFree
                                                ? t("CancelPolicy:FreeCancellation") + formatDateTime(cabinCategory.cancelPolicy.expirationDate, { twoDigits: true }) + " " + renderWebsite.cancelPolicy.time
                                                : t("CancelPolicy:PenaltyCancellation")
                                        }
                                        <span className="cursor-pointer" onClick={e => onOpenCancelPolicyDetail(cabinCategory.cancelPolicy)}>
                                            <M3Icon iconName="Info" externalClass="icon-10 text-dark cursor-pointer" />
                                        </span>
                                    </span>
                                }
                            </div>

                            {
                                cabinCategory.paxesPriceDetails && <CruiseDetailCabinCategoryPriceTable
                                    services={cabinCategory.paxesPriceDetails}
                                    title={t('Product:Cruise:PaxesDetailPrice')}
                                    isIncludedService={false} />
                            }
                            {
                                cabinCategory.includeServices && <CruiseDetailCabinCategoryPriceTable
                                    services={cabinCategory.includeServices}
                                    isIncludedService={true} />
                            }
                            {
                                optionalServices && <CruiseDetailCabinCategoryServices
                                    services={optionalServices}
                                    title={t('Product:Cruise:OptionalService')}
                                    onSelect={onSelectOptionalServices} />
                            }

                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pl-0 text-right m-0">
                                <div className="btn-sm m-0 px-2 py-3 h-100 d-inline-block text-orange">{cabinCategory.canOption ? "Opzionabile" : ""}</div>
                                {
                                    (templateInfo || quotationInfo || (enableCanAddToCart && enableCanAddToCart.allowed)) &&
                                    <button className="btn-sm tp-btn-outline-book float-end m-0 px-2 py-1 mt-2 mb-1" onClick={(e) => onClickBookItem()}>
                                            <data m3lab="Button.Book">
                                            {!templateInfo && !quotationInfo && <span dangerouslySetInnerHTML={{ __html: t(`Button:AddToCart`) }}></span>}
                                            {templateInfo && t(`Button:Select`)}
                                            {quotationInfo && !quotationInfo.quotationItemIdToChange && t(`Button:AddQuotation`)}
                                            {quotationInfo && quotationInfo.quotationItemIdToChange && t(`Button:ReplaceQuotation`)}
                                        </data>
                                    </button>
                                }
                                <ProductNotAllowedAddToCart enableCanAddToCart={enableCanAddToCart} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}