import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { HotelStaticContainer } from './HotelStaticContainer';


export const HotelModalStatic = ({ structureId, modalCloseProductId, isNotEngine, isFavouriteSelected }) => {
    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");
    const { t } = useTranslation();

    useEffect(() => {
        if (structureId) {
            let myBtn = document.getElementById("btn_openModalHotel");
            myBtn.click();
        }
    }, [structureId]);

    useEffect(() => {

        let myModal = document.getElementById("schedStaticaModalHotel");
        myModal.addEventListener('hidden.bs.modal', function () {
            modalCloseProductId(null);
        });

    }, []);


    return (
        <>
            <a id="btn_openModalHotel" data-bs-toggle="modal" data-bs-target="#schedStaticaModalHotel"></a>
            <div className="modal fade" id={"schedStaticaModalHotel"} tabIndex="-1" aria-labelledby={"exampleModalLabel"} aria-hidden="true">
                <div className="modal-dialog modal-xxl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id={"exampleModalLabel"}>
                                <data m3lab="ActionMenu.Detail">{t(`ActionMenu:Detail`)}</data>
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <HotelStaticContainer structureId={structureId} isStaticView={true} isNotEngine={isNotEngine} favourite={isFavouriteSelected } />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                <data m3lab="Button.Close">{t('Button:Close')}</data>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}