import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { searchCarpetParameter } from '../../../js/Constant';
import { getCurrentUserFromJwtToken, isMobile } from '../../../js/Utils.js';
import { M3Icon } from "../../Common/M3Icon";
import { ProductHeader } from '../Common/ProductHeader';
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';
import { ProductItemNotFound } from '../Common/ProductItemNotFound';
import { ProductFilter } from '../Common/Filter/ProductFilter';
import { ProductFilterByName } from '../Common/Filter/ProductFilterByName';
import { ProductFilterByPrice } from '../Common/Filter/ProductFilterByPrice';
import { getCarDetailLink } from '../../../js/ProductService.js';
import { ListSearchEngineCV } from '../SearchEngine/compactView/ListSearchEngineCV';
import { CarItemList } from './components/CarItemList';
import { CarItemListBlock } from './components/CarItemListBlock';
import { ListSearchEngineCVMobile } from '../SearchEngine/compactView/ListSearchEngineCVMobile';
import { ProductNavBar } from '../SearchEngine/ProductNavBar';

export const CarList = () => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    let params = new URLSearchParams(window.location.search);
    let carpetId = params.get('cId');
    let clientFlowId = params.get('cfId');

    let extraParam = {
        carpetId: carpetId,
        clientFlowId: clientFlowId,
        productId: 0
    }

    // carpet
    const [carpetStatus, setCarpetStatus] = useState(null);
    const [carpetDataStatus, setCarpetDataStatus] = useState(null);
    const [carpetTime, setCarpetTime] = useState(1);
    const [intervalCarpetPing, setIntervalCarpetPing] = useState([]);

    // page
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingFilter, setIsLoadingFilter] = useState(false);
    const [isError, setIsError] = useState(false);
    const [items, setItems] = useState({});
    const [itemsListMode, setItemsListMode] = useState(0);
    const [showPriceBar, setShowPriceBar] = useState(false);
    const [isLoadingListResult, setIsLoadingListResult] = useState(true);

    const [modalOpenProductId, setModalOpenProductId] = useState(null);

    // roles
    const [showCost, setShowCost] = useState(false);
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);

    // filtri
    const [isResetSelectedItem, setIsResetSelectedItem] = useState(false);
    const [isOrderAZ, setIsOrderAZ] = useState(true);
    const [isChangeAzDisabled, setIsChangeAzDisabled] = useState(true);
    const [filterValues, setFilterValues] = useState(null);
    const [rangePriceInfo, setRangePriceInfo] = useState({
        min: "0",
        max: "5000",
        step: "50",
        currency: "EUR"
    });

    const [filterSelected, setFilterSelected] = useState({
        rangePrice: null,
        pageNumber: 0,
        orderBy: searchCarpetParameter.car.orderBy,
        providers: [],
        classes: [],
        types: [],
        onBoardEquipment: [],
        pickupDestinations: [],
        dropoffDestinations: [],
        carOptions: [],
        pickupOffices: [],
        dropoffOffices: [],
        suppliers: [],
        officeCode: "",
        carName: null,
    });

    // paging
    const itemsPerPage = searchCarpetParameter.car.listMaxItem;
    const [pageCount, setPageCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(0);

    const onChangeShowPriceBar = () => {
        setShowPriceBar(!showPriceBar);
    }

    useEffect(() => {
        if (isMobile())
            setItemsListMode(1);

        setIsLoading(true);
        setIsError(false);
        getCarpetStatus();

        try {

            let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
            let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);

            if (currentUser.roles) {
                if (!currentUser.roles.includes("FE_NonVisualizzaNetto"))
                    setEnableShowNetPrices(true);


                if (currentUser.roles.includes("FE_ShowCost"))
                    setShowCost(true);
            }

        } catch (ex) {
        }

    }, []);


    useEffect(() => {

        switch (carpetStatus) {
            case 'Processing':
            case 'Queued':
            case 'Completed':
                {
                    break;
                }
            case 'Error':
                {
                    setIsError(true);
                    setIsLoading(false);
                    break;
                }
        }

    }, [carpetStatus]);

    useEffect(() => {
        switch (carpetDataStatus) {
            case 'Completed':
                {
                    callGetNewData();
                    clearCarpetAllInterval();
                    break;
                }

            case 'NewData':
                {
                    callGetNewData();
                    break;
                }

            case 'Processing':
            case 'NoChanges':
                {

                    let interval = setInterval(() => {
                        getCarpetStatus();
                    }, searchCarpetParameter.car.carpetPingMs);
                    addInterval(interval);

                    break;
                }
        }

    }, [carpetDataStatus]);

    // API carpet
    async function callGetNewData() {
        if (filterValues === null || carpetStatus === 'Completed')
            getCarListFilters();

        setIsLoading(false);
        setIsError(false);

        // conto quante chiamate sto facendo
        // ogni chiamata avviene dopo un tot di tempo, quindi in base al numero di chiamate so quanto è passato
        let callTicks = carpetTime * searchCarpetParameter.car.carpetPingMs;
        let finishTime = callTicks > searchCarpetParameter.car.carpetMaxTimeMs
        setCarpetTime(carpetTime + 1);

        if (carpetStatus !== 'Completed' && !finishTime) {
            setIsLoadingFilter(true);

            let interval = setInterval(() => {
                getCarpetStatus();
            }, searchCarpetParameter.car.carpetPingMs);
            addInterval(interval);

        } else {
            setIsLoadingFilter(false);
        }
    }

    async function getCarList(filterSelectedUpdate, pageNumber) {
        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetId,
            pageNumber: pageNumber,
            carName: filterSelectedUpdate.carName,
            pageSize: searchCarpetParameter.car.listMaxItem,
            showCost: showCost,
            rangePrice: filterSelectedUpdate.rangePrice,
            orderBy: filterSelectedUpdate.orderBy,
            officeCode: filterSelectedUpdate.officeCode,
            providers: filterSelectedUpdate.providers,
            classes: filterSelectedUpdate.classes,
            types: filterSelectedUpdate.types,
            onBoardEquipment: filterSelectedUpdate.onBoardEquipment,
            pickupDestinations: filterSelectedUpdate.pickupDestinations,
            dropoffDestinations: filterSelectedUpdate.dropoffDestinations,
            carOptions: filterSelectedUpdate.carOptions,
            pickupOffices: filterSelectedUpdate.pickupOffices,
            dropoffOffices: filterSelectedUpdate.dropoffOffices,
            suppliers: filterSelectedUpdate.suppliers,
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}carRental/getCarRentalList`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setItems(response);
            renderPaginator(response.totalCarRentals);

        }

        if (carpetDataStatus === 'Completed') {
            setIsLoadingFilter(false);
        }

        setIsLoadingListResult(false);
    }

    async function getCarListFilters() {
        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetId
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}carRental/getCarRentalListFilters`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setFilterValues(response);

            if (carpetDataStatus === 'Completed' && response.rangePricesInfo) {
                setRangePriceInfo({
                    ...rangePriceInfo,
                    min: response.rangePricesInfo.min,
                    max: response.rangePricesInfo.max,
                    currency: response.rangePricesInfo.name
                });


            }

            getCarList(filterSelected, 0);
        }
    }

    async function getCarpetStatus() {
        console.log(`Call GetCarpetStatus [CarpetId = ${carpetId}]`);

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}carpet/getcarpetstatus/${carpetId}`, requestOption);
        if (!fetchedRes.ok) {
            setIsError(true);
        } else {
            const response = await fetchedRes.json();

            let dataStatus = response.dataStatus;
            let status = response.status;
            if (response.success) {
                setCarpetStatus(status);

                if (status !== 'Error') {
                    // messo per richiamare il getCarpetStatus, nel caso riceva piu volte lo status NoChanges.
                    // lo status Processing esiste solo lato FE
                    if (status !== carpetDataStatus)
                        setCarpetDataStatus(dataStatus);
                    else
                        setCarpetDataStatus('Processing');
                }


            } else {
                setIsError(true);
            }

            console.log(`Status ${status}`);
            console.log(`Data Status ${dataStatus}`);
        }
    }

    function addInterval(intervalId) {
        let arr = [...intervalCarpetPing];
        arr.push(intervalId);
        setIntervalCarpetPing(arr);
    }

    function clearCarpetAllInterval() {
        for (let i = 0; i < intervalCarpetPing.length; i++) {
            let intId = intervalCarpetPing[i];
            clearInterval(intId);
        }

        setIntervalCarpetPing([]);
    }

    // metodi filtro
    function callFilterValues(filterSelectedUpdate) {
        setIsLoadingFilter(true);
        getCarList(filterSelectedUpdate, 0);
    }

    function onChangeSortBy(event) {
        let orderByValue = event.target.selectedOptions[0].value;

        let filterSelectedUpdate = {
            ...filterSelected,
            orderBy: orderByValue
        };

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilter(selectedItems, type) {
        let filterSelectedUpdate = { ...filterSelected };

        switch (type) {
            case 'CarSuppliers': {
                filterSelectedUpdate.suppliers = selectedItems;
                break;
            }

            case 'CarProviders': {
                filterSelectedUpdate.providers = selectedItems;
                break;
            }

            case 'CarPickUp': {
                filterSelectedUpdate.pickupDestinations = selectedItems;
                break;
            }

            case 'DropOffPoint': {
                filterSelectedUpdate.dropoffDestinations = selectedItems;
                break;
            }

            case 'CarClasses': {
                filterSelectedUpdate.classes = selectedItems;
                break;
            }

            case 'CarTypes': {
                filterSelectedUpdate.types = selectedItems;
                break;
            }

            case 'CarOnBoardEquipment': {
                filterSelectedUpdate.onBoardEquipment = selectedItems;
                break;
            }

        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilterName(name) {
        let filterSelectedUpdate = {
            ...filterSelected,
            carName: name
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilterPrice(rangePrice) {
        let filterSelectedUpdate = {
            ...filterSelected,
            rangePrice: rangePrice
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onClearFilter() {
        let filterSelectedUpdate = {
            ...filterSelected,
            rangePrice: null,
            providers: [],
            classes: [],
            types: [],
            onBoardEquipment: [],
            pickupDestinations: [],
            dropoffDestinations: [],
            carOptions: [],
            pickupOffices: [],
            dropoffOffices: [],
            suppliers: [],
            officeCode: "",
            carName: null,
        }

        setIsResetSelectedItem(!isResetSelectedItem);
        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    // metodi paginator
    const handlePageClick = async e => {
        setPageSelected(e.selected);

        let filterSelectedUpdate = {
            ...filterSelected,
            pageNumber: e.selected + 1
        }

        setFilterSelected(filterSelectedUpdate);
        getCarList(filterSelected, e.selected);
    };

    function renderPaginator(totalItems) {
        setPageCount(Math.ceil(totalItems / itemsPerPage));
    }

    function onChangeItemsListMode(mode) {
        setItemsListMode(mode);
    }

    async function onClickSearch(carId, filters = null) {
        let extraParam = '';

        if (filters && filters.length > 0) {
            for (let i = 0; i < filters.length; i++) {
            }
        }

        let url = await getCarDetailLink(filterValues.carRentalSearchParameter, carId, carpetId, clientFlowId);
        if (extraParam)
            url += extraParam;

        if (filterSelected.suppliers && filterSelected.suppliers.length > 0) {
            let arrTmp = [];
            filterSelected.suppliers.forEach((supplier) => {
                arrTmp.push(supplier.id);
            });

            url += "&suppliersIds=" + arrTmp.join();
        }
        if (url)
            window.open(url, '_blank');
    }

    return (
        <>
            <div className="bg-bluesoft-100">
                {
                    configData.Settings.Website.EngineType === 'WITHNAVBAR' && <ProductNavBar moduleSelected="CARRENTAL" />
                }
                {
                    configData.Settings.Website.ShowProductHeaderList && <div>
                        <ProductHeader text="" pageCode='Home_Gallery' />
                    </div>
                }
                <div className="container ">
                    <div className="row mt-4">
                        {/* Loading */}
                        {isLoading && <div className="col-12"><Loading textMsg={t('Product:RentCar:LoadingList')} /></div>}

                        {/* Error */}
                        {!isLoading && isError && (!items || !items.carRentals || items.carRentals.length === 0) && <div className="col-12"><Error textMsg={t('Product:RentCar:Error')} /></div>}

                        {/* Items */}
                        {!isLoading && items &&
                            <>
                                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                </div>

                                <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                                    <div className="d-table h-100">
                                        <div className="d-table-row ">
                                            <div className="d-table-cell align-bottom pb-3 h5">
                                                <data m3lab="Product:RentCar.NCar">
                                                    {t(`Product:RentCar:NCar`)}:
                                                </data>
                                                <span className="ml-2">
                                                    <var>
                                                        {items.totalCarRentals}
                                                    </var>
                                                    {
                                                        isLoadingFilter && <div className="spinner-border spinner-border-sm text-primary ml-2" role="status"></div>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-1 d-none d-sm-block text-right">
                                    <div className="row">
                                        <div className="col-7 text-right pr-0">
                                            <span className="input-group mb-3 text-right">
                                                <select className="form-select border-0" aria-label="Disabled select example" value={filterSelected.orderBy} onChange={(e) => { onChangeSortBy(e); }}>
                                                    { /* messo 4 solo perchè di default le activity hanno ordinamento per rilevanza */}
                                                    <option value="4">
                                                        <data m3lab="General.Order.OrderBy">{t(`General:Order:OrderBy`)}</data>
                                                    </option>
                                                    {
                                                        searchCarpetParameter.car.orderByValues.map((item, key) => {
                                                            return <option key={key} value={item.value}>
                                                                <data m3lab={item.label} >{t(item.label)}</data>
                                                            </option>
                                                        })
                                                    }
                                                </select>
                                                <button className="btn btn-neutral m-0 p-0 pr-1 d-inline" type="button" id="button-addon2">
                                                    <data m3ico="SortBy icon-15 text-bluesoft">
                                                        <M3Icon iconName="SortBy" externalClass="icon-15 text-bluesoft p-1" hasLabel={false} />
                                                    </data>
                                                </button>
                                            </span>
                                        </div>
                                        <div className="col-5 pl-0">
                                            <button className={(itemsListMode === 0 ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow"} onClick={(e) => { onChangeItemsListMode(0) }}>
                                                <data m3ico="ResultList icon-25">
                                                    <M3Icon iconName="ResultList" externalClass="icon-25" hasLabel={false} />
                                                </data>
                                            </button>
                                            <button className={(itemsListMode === 1 ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow"} onClick={(e) => { onChangeItemsListMode(1) }}>
                                                <data m3ico="ResultBloc icon-25">
                                                    <M3Icon iconName="ResultBloc" externalClass="icon-25" hasLabel={false} />
                                                </data>
                                            </button>
                                            {
                                                enableShowNetPrices && <button className={(showPriceBar ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow"} m3rool="f_ShowPriceBar" onClick={(e) => { onChangeShowPriceBar() }}>
                                                    <data m3lab="Button.Net">
                                                        {t("Button:Net")}
                                                    </data>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-1 text-right d-block d-sm-none">
                                    <div className="d-flex mb-4 align-items-center" style={{ gap: "1rem" }}>
                                        <div className="col-7 text-right pr-0 pl-0">
                                            <span className="input-group text-right">
                                                <select className="form-select border-0" aria-label="Disabled select example" value={filterSelected.orderBy} onChange={(e) => { onChangeSortBy(e); }}>
                                                    <option value="">
                                                        <data m3lab="General.Order.OrderBy">{t(`General:Order:OrderBy`)}</data>
                                                    </option>
                                                    {
                                                        searchCarpetParameter.flight.orderByValues.map((item, key) => {
                                                            return <option key={key} value={item.value}>
                                                                <data m3lab={item.label} >{t(item.label)}</data>
                                                            </option>
                                                        })
                                                    }
                                                </select>

                                            </span>
                                        </div>
                                        <div className="">
                                            <button className={(itemsListMode === 0 ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow d-none d-sm-inline"} onClick={(e) => { onChangeItemsListMode(0) }}>
                                                <data m3ico="ResultList icon-25">
                                                    <M3Icon iconName="ResultList" externalClass="icon-25" hasLabel={false} />
                                                </data>
                                            </button>
                                            <button className={(itemsListMode === 1 ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow d-none d-sm-inline"} onClick={(e) => { onChangeItemsListMode(1) }}>
                                                <data m3ico="ResultBloc icon-25">
                                                    <M3Icon iconName="ResultBloc" externalClass="icon-25" hasLabel={false} />
                                                </data>
                                            </button>
                                            {
                                                enableShowNetPrices && <button className={(showPriceBar ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow"} m3rool="f_ShowPriceBar" onClick={(e) => { onChangeShowPriceBar() }}>
                                                    <data m3lab="Button.Net">
                                                        {t("Button:Net")}
                                                    </data>
                                                </button>
                                            }
                                        </div>
                                        <button data-bs-toggle="modal" data-bs-target="#filterModal" className="openFilterButton p-1 px-2 pt-2 rounded border-0 shadow tp-btn-search">
                                            {t(`General:OpenFilter`)}
                                        </button>
                                    </div>
                                </div>
                                {filterValues && <div className="d-block d-sm-none">
                                    <ListSearchEngineCVMobile
                                        imageUrl="https://cdn.travelplace.ch/travelplace40/img/maps_list.jpg"
                                        searchParameters={filterValues.carRentalSearchParameter}
                                        productType={configData.Settings.Products.CarRental.IdTipoProdotto}
                                        typeSearch="SRC"
                                    />
                                </div>}
                                {/* Colonna Filtri */}
                                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mt-1">
                                    {filterValues && <>

                                        <ListSearchEngineCV
                                            imageUrl="https://cdn.travelplace.ch/travelplace40/img/maps_list.jpg"
                                            searchParameters={filterValues.carRentalSearchParameter}
                                            productType={configData.Settings.Products.CarRental.IdTipoProdotto}
                                            typeSearch="SRC" />
                                        <div className='d-none d-sm-block'>
                                        {/* Nome */}
                                        <ProductFilterByName
                                            items={filterValues.carNames}
                                            label={t("SearchEngine:FindCar")}
                                            type="CarName"
                                            onApplyFilter={onApplyFilterName}
                                            resetSelectedItem={isResetSelectedItem} />

                                        <button className="btn btn-sm btn-block tp-btn-search animate-up-2 mt-4" onClick={(e) => onClearFilter(e)}>
                                            <data m3lab="Button.FilterRemove">{t('Button:FilterRemove')}</data>
                                        </button>

                                        { /* Price */}
                                        <ProductFilterByPrice
                                            rangePriceInfo={rangePriceInfo}
                                            onApplyFilter={onApplyFilterPrice}
                                            resetSelectedItem={isResetSelectedItem} />

                                        { /* PickUp */}
                                        <ProductFilter
                                            items={filterValues.pickupDestinations}
                                            label="Product:RentCar:Filter:PickUpPoint"
                                            type="CarPickUp"
                                            onApplyFilter={onApplyFilter}
                                            resetSelectedItem={isResetSelectedItem} />

                                        { /* DropOff */}
                                        <ProductFilter
                                            items={filterValues.dropoffDestinations}
                                            label="Product:RentCar:Filter:DropOffPoint"
                                            type="CarPickUp"
                                            onApplyFilter={onApplyFilter}
                                            resetSelectedItem={isResetSelectedItem} />

                                        { /* Classes */}
                                        <ProductFilter
                                            items={filterValues.classes}
                                            label="Product:RentCar:Filter:Classes"
                                            type="CarClasses"
                                            onApplyFilter={onApplyFilter}
                                            resetSelectedItem={isResetSelectedItem} />

                                        { /* Types */}
                                        <ProductFilter
                                            items={filterValues.types}
                                            label="Product:RentCar:Filter:CarTypes"
                                            type="CarTypes"
                                            onApplyFilter={onApplyFilter}
                                            resetSelectedItem={isResetSelectedItem} />

                                        { /* On board equipment */}
                                        <ProductFilter
                                            items={filterValues.onBoardEquipment}
                                            label="Product:RentCar:Filter:onBoardEquipment"
                                            type="CarOnBoardEquipment"
                                            onApplyFilter={onApplyFilter}
                                            resetSelectedItem={isResetSelectedItem}
                                        />

                                        { /* Supplier */}
                                        <ProductFilter
                                            items={filterValues.suppliers}
                                            label="Product:RentCar:Filter:Supplier"
                                            type="CarSuppliers"
                                            onApplyFilter={onApplyFilter}
                                            resetSelectedItem={isResetSelectedItem} />

                                        { /* Provider */}
                                        <ProductFilter
                                            items={filterValues.providers}
                                            label="Product:RentCar:Filter:Provider"
                                            type="CarProviders"
                                            onApplyFilter={onApplyFilter}
                                                resetSelectedItem={isResetSelectedItem} />
                                            </div>
                                    </>
                                    }
                                </div>

                                { /* Colonna Items */}
                                <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 pt-1">
                                    {
                                        !isLoadingListResult && items && items.carRentals && items.carRentals.length > 0 && itemsListMode === 0 && items.carRentals.map((item, key) => {
                                            return <CarItemList key={key}
                                                item={item}
                                                showPriceBar={showPriceBar}
                                                showCost={showCost}
                                                extraParam={extraParam}
                                                modalOpenProductId={setModalOpenProductId}
                                                onClickSearch={onClickSearch}
                                            />
                                        })
                                    }
                                    {
                                        !isLoadingListResult && items && items.carRentals && items.carRentals.length > 0 && itemsListMode === 1 && <div className="row">
                                            {
                                                items.carRentals.map((item, key) => {
                                                    return <CarItemListBlock key={key}
                                                        item={item}
                                                        showPriceBar={showPriceBar}
                                                        showCost={showCost}
                                                        extraParam={extraParam}
                                                        modalOpenProductId={setModalOpenProductId}
                                                        onClickSearch={onClickSearch}
                                                    />
                                                })
                                            }
                                        </div>
                                    }
                                </div>

                                { /* Paginator */}
                                {
                                    items && items.carRentals && items.carRentals.length > 0 && <>
                                        <div className="col-12 col-sm-3 col-md-3 col-lg-39 col-xl-3 pt-1"></div>
                                        <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 pt-1">
                                            <nav aria-label="Page navigation comments" className="mt-4">
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t('pagination:nextLabel')}
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel={t('pagination:previousLabel')}
                                                    renderOnZeroPageCount={null}
                                                    forcePage={pageSelected}

                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    marginPagesDisplayed={2}
                                                    containerClassName="pagination justify-content-center"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    activeClassName="active"
                                                />
                                            </nav>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="modal tp-search-input fade" id="filterModal" tabIndex="-1" aria-labelledby="EngineModifyActivty" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen-xxl-down">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <div className="h4 text-center w-100 mt-2">
                                {t('SearchEngine:UpdateSearch')}
                            </div>
                            <button type="button" className="btn-close mt-2 pr-4" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body bg-gray-300">
                            {
                                filterValues && <>
                                    {/* Nome */}
                                    <ProductFilterByName
                                        items={filterValues.carNames}
                                        label={t("SearchEngine:FindCar")}
                                        type="CarName"
                                        onApplyFilter={onApplyFilterName}
                                        resetSelectedItem={isResetSelectedItem} />

                                    <button className="btn btn-sm btn-block tp-btn-search animate-up-2 mt-4" onClick={(e) => onClearFilter(e)}>
                                        <data m3lab="Button.FilterRemove">{t('Button:FilterRemove')}</data>
                                    </button>

                                    { /* Price */}
                                    <ProductFilterByPrice
                                        rangePriceInfo={rangePriceInfo}
                                        onApplyFilter={onApplyFilterPrice}
                                        resetSelectedItem={isResetSelectedItem} />

                                    { /* PickUp */}
                                    <ProductFilter
                                        items={filterValues.pickupDestinations}
                                        label="Product:RentCar:Filter:PickUpPoint"
                                        type="CarPickUp"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem} />

                                    { /* DropOff */}
                                    <ProductFilter
                                        items={filterValues.dropoffDestinations}
                                        label="Product:RentCar:Filter:DropOffPoint"
                                        type="CarPickUp"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem} />

                                    { /* Classes */}
                                    <ProductFilter
                                        items={filterValues.classes}
                                        label="Product:RentCar:Filter:Classes"
                                        type="CarClasses"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem} />

                                    { /* Types */}
                                    <ProductFilter
                                        items={filterValues.types}
                                        label="Product:RentCar:Filter:CarTypes"
                                        type="CarTypes"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem} />

                                    { /* On board equipment */}
                                    <ProductFilter
                                        items={filterValues.onBoardEquipment}
                                        label="Product:RentCar:Filter:onBoardEquipment"
                                        type="CarOnBoardEquipment"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem}
                                    />

                                    { /* Supplier */}
                                    <ProductFilter
                                        items={filterValues.suppliers}
                                        label="Product:RentCar:Filter:Supplier"
                                        type="CarSuppliers"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem} />

                                    { /* Provider */}
                                    <ProductFilter
                                        items={filterValues.providers}
                                        label="Product:RentCar:Filter:Provider"
                                        type="CarProviders"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem} />
                                </>
                            }
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}