import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../Common/Loading';
import configData from "../../../appsettings.json";
import { formatDateTime } from '../../../js/Utils.js';
import { DynamicNote } from '../DynamicNote';
import { BookingNote } from '../BookingNote';
import { replaceAllStyleElements, getPaxGenderVoucher } from '../../../js/PrintUtility';
import { M3Icon } from "../../Common/M3Icon";
import { BookParameterId } from '../../../js/Constant';


export const VoucherActivityDetail = ({ id, customProductDetail, customDynamicNote, customBookingNote }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [productDetail, setProductDetail] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        const getProductDetail = async (inputData) => {

            const response = callGetProductDetail(inputData);
        }
        getProductDetail({ PNR: id, ProductType: configData.Settings.Products.Activity.IdTipoProdotto, Options: { WebsiteBaseUrl: configData.Settings.OldWebSite_BaseUrl } });
    }, []);

    function onCustomDynamicNote(dynamicNote) {
        customDynamicNote(dynamicNote);
    }

    function onCustomBookingNote(bookingNote) {
        customBookingNote(bookingNote);
    }

    async function callGetProductDetail(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetReservationDetail', requestOption);
        const response = await fetchedRes.json();
        setProductDetail(response);
        customProductDetail(response);
        setIsLoading(false);
    }
    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : (
                    <>
                        <div className="stepHeader text-center mt-2 mb-2" style={{ borderRadius: "5px", padding: "7px" }}>
                            <h5 className="color-text-voucher">{t("Print:Voucher:Activity")} &nbsp;- &nbsp; {t("Print:Voucher:BookingNumber")}: {productDetail.option.providerRecordLocatorId}</h5>
                        </div>
                        <div style={{ border: "1px solid grey", borderRadius: "5px" }}>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <span><label>{t("Print:Voucher:Service")}:</label> {productDetail.name}</span>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <span><label>{t("Print:Voucher:Date")}:</label> {formatDateTime(productDetail.priceBar.startDate, { twoDigits: true })}</span>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <span><label>Total Pax:</label> {productDetail.option.passengers && productDetail.option.passengers.length}</span>
                                </div>
                                {productDetail.option.passengers && productDetail.option.passengers.length > 0 && productDetail.option.passengers.map(pax =>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <span key={pax.index} ><label>{t("Print:Voucher:Pax")} {pax.index} :</label> {pax.fullName}</span> {getPaxGenderVoucher(pax, BookParameterId)} {pax.paxType !== "Adult" && pax.paxType}
                                    </div>
                                )}
                                {productDetail.option.providerReservationId &&
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <span><label>{t("Print:Voucher:BookingNumber")}:</label> {productDetail.option.providerReservationId}</span>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="stepHeader text-center mt-2 mb-2" style={{ borderRadius: "5px", padding: "7px" }}>
                            <h5 className="color-text-voucher">{t("Print:Voucher:BookingDetailReservationNumber")}:&nbsp;{productDetail.priceBar.wsBookingId}</h5>
                        </div>

                        {productDetail.option.notes !== null && productDetail.option.notes !== undefined && productDetail.option.notes.length > 0 &&
                            <div className="card mt-4 border-gray-500 content-block">
                                <div className="card  ">
                                    <div>Note</div>
                                    <div>
                                        <ul>
                                            {productDetail.option.notes.map((note, index) =>
                                                <li key={index}>
                                                    <span className="document-txt-note" dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(note) }} />
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {<BookingNote idPratica={productDetail.idPratica} customBookingNote={onCustomBookingNote} />}
                        {<DynamicNote pageId="11" idTipoProdotto={configData.Settings.Products.Activity.IdTipoProdotto} productDetail={productDetail} customDynamicNote={onCustomDynamicNote} />}
                    </>
                )
            }
        </>
    );
}