import React, { useEffect, useState } from 'react';
import configData from "../../appsettings.json";
import { manualRangeParameter } from "../../js/Constant";
import { transferPointType } from "../../js/Enums";
import { handleError, addDaysToDatePicker } from "../../js/Utils";
import { InputSearchDestinationModal } from "../Common/Destination/InputSearchDestinationModal";
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { AddServiceNote } from './components/AddServiceNote';

export const TransferManual = (props) => {
    const { t } = useTranslation();
    const today = new Date().toISOString().split("T")[0];

    const transferDetailDefault = {
        id: -1,
        productType: configData.Settings.Products.Transfer.IdTipoProdotto,
        name: '',

        isOneWay: true,
        city: {},

        providerId: 'NOT',
        subProviderId: 'NOT',
        payBy: '',
        reservationId: '',
        recordLocator: '',

        pickupDate: '',
        pickupTime: '',
        dropoffDate: '',
        dropoffTime: '',
        cancelPolicy: '',


        vehicleType: 99,
        imageUrl: '',
        buyPriceAmount: null,
        buyPriceCurrency: 'EUR',
        buyPriceChange: null,
        internalNote: '',

        pickupPoint: {
            pointType: 'NOT',
            id: '',
            departurePoint: '',
            destinationPoint: ''
        },

        dropoffPoint: {
            pointType: 'NOT',
            id: '',
            departurePoint: '',
            destinationPoint: ''
        },

        totalAdults: manualRangeParameter.transfer.defaultAdults,
        totalChildren: manualRangeParameter.transfer.defaultChildren,
        ages: [],

        serviceNote: null,
        serviceNoteDesc: null
    };

    const validateTransferDefault = {
        isValidName: false,
        isTouchedName: false,
        isValidCity: false,
        isTouchedCity: false,
        isValidProviderId: false,
        isTouchedProviderId: false,

        isValidPickupDate: false,
        isTouchedPickupDate: false,
        isValidPickupTime: false,
        isTouchedPickupTime: false,

        isValidDropoffDate: true,
        isTouchedDropoffDate: true,
        isValidDropoffTime: true,
        isTouchedDropoffTime: true,

        isValidCancelPolicy: false,
        isTouchedCancelPolicy: false,

        isTouchedBuyPriceAmount: false,
        isValidBuyPriceAmount: false,

        isValidPickupPoint: {
            isTouchedDeparturePoint: false,
            isValidDeparturePoint: false,
            isTouchedDestinationPoint: false,
            isValidDestinationPoint: false,
        },

        isValidDropoffPoint: {
            isTouchedDeparturePoint: true,
            isValidDeparturePoint: true,
            isTouchedDestinationPoint: true,
            isValidDestinationPoint: true,
        },

        isValidForm: false
    };

    const [currencies, setCurrencies] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [vehicleTypes, setVehicleTypes] = useState([]);

    const [transferDetail, setTransferDetail] = useState({ ...transferDetailDefault });
    const [validateInputData, setValidateInputData] = useState({ ...validateTransferDefault });
    const [validateValueDefault, setValidateValueDefault] = useState({ maxCancelPolicy: '' });

    useEffect(() => {
        if (props.isOpen) {
            if (currencies.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}constant/getcurrency`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setCurrencies(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (suppliers.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}customer/getallsupplierforproduct/${configData.Settings.Products.Transfer.IdTipoProdotto}`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setSuppliers(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (vehicleTypes.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}transfer/gettransfertypes`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setVehicleTypes(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (!props.item) {
                setTransferDetail({ ...transferDetailDefault });
                setValidateInputData({ ...validateTransferDefault });
            }
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (!props.item) {
            setTransferDetail({ ...transferDetailDefault });
            setValidateInputData({ ...validateTransferDefault });
        } else if (props.item.productType === configData.Settings.Products.Transfer.IdTipoProdotto) {
            setTransferDetail(props.item);

            let isValidAD = { ...validateTransferDefault };
            Object.keys(isValidAD).forEach(key => {
                if (key === 'isValidPickupPoint' || key === 'isValidDropoffPoint') {
                    isValidAD[key].isValidDeparturePoint = true;
                    isValidAD[key].isTouchedDeparturePoint = true;
                    isValidAD[key].isValidDestinationPoint = true;
                    isValidAD[key].isTouchedDestinationPoint = true;
                }
                else {
                    isValidAD[key] = true;
                }

            });

            setValidateInputData(isValidAD);
        }
    }, [props.item]);

    function onBlurEvent(event, propertyName) {
        let updateTransferDetail = { ...transferDetail };

        let newValue = '';
        if (propertyName === 'providerId' || propertyName === 'buyPriceCurrency' || propertyName === 'vehicleType') {
            newValue = event.key;
            if (!newValue)
                newValue = event;
        }
        else if (propertyName === 'serviceNote' || propertyName === 'serviceNoteDesc') {
            newValue = event;
        }
        else {
            newValue = event.target.value;
        }

        if (propertyName === 'isOneWay') {
            newValue = true;

            updateTransferDetail.dropoffDate = '';
            updateTransferDetail.dropoffTime = '';
        }
        else if (propertyName === 'isRoundTrip') {
            newValue = false;
            propertyName = 'isOneWay';
        }

        if (propertyName === 'buyPriceChange' && !newValue) {
            newValue = null;
        }

        if (propertyName === 'buyPriceCurrency') {
            let exchangeRate = 1;
            if (currencies && currencies.length > 0) {
                let currency = currencies.find(element => element.value === newValue);
                if (currency)
                    exchangeRate = currency.rate;
            }

            updateTransferDetail['buyPriceChange'] = exchangeRate;
        }

        updateTransferDetail[propertyName] = newValue;
        setTransferDetail(updateTransferDetail);

        checkValidateInputData(propertyName, newValue);
    }

    function onBlurPointEvent(event, destPoint, propertyName) {
        let updateTransferDetail = { ...transferDetail };

        let newValue = '';
        if (propertyName === 'pointType') {
            newValue = event.key;
            if (!newValue)
                newValue = event;
        } else {
            newValue = event.target.value;
        }

        updateTransferDetail[destPoint][propertyName] = newValue;

        const isNotEmpty = (newValue !== '' && newValue !== undefined);
        var updateValidateInputData = { ...validateInputData };
        var isValidPoint = false;

        switch (propertyName) {
            case 'departurePoint':

                if (destPoint === 'pickupPoint') {
                    updateValidateInputData.isValidPickupPoint.isValidDeparturePoint = isNotEmpty;
                    updateValidateInputData.isValidPickupPoint.isTouchedDeparturePoint = true;

                    isValidPoint = updateValidateInputData.isValidPickupPoint.isValidDestinationPoint;
                } else if (destPoint === 'dropoffPoint') {
                    updateValidateInputData.isValidDropoffPoint.isValidDeparturePoint = isNotEmpty;
                    updateValidateInputData.isValidDropoffPoint.isTouchedDeparturePoint = true;

                    isValidPoint = updateValidateInputData.isValidPickupPoint.isValidDeparturePoint && updateValidateInputData.isValidPickupPoint.isValidDestinationPoint
                        && updateValidateInputData.isValidDropoffPoint.isValidDestinationPoint;
                }

                updateValidateInputData.isValidForm = isNotEmpty && isValidPoint && checkIsValidTransfer();

                break;

            case 'destinationPoint':

                if (destPoint === 'pickupPoint') {
                    updateValidateInputData.isValidPickupPoint.isValidDestinationPoint = isNotEmpty;
                    updateValidateInputData.isValidPickupPoint.isTouchedDestinationPoint = true;

                    isValidPoint = updateValidateInputData.isValidPickupPoint.isValidDeparturePoint;
                } else if (destPoint === 'dropoffPoint') {
                    updateValidateInputData.isValidDropoffPoint.isValidDestinationPoint = isNotEmpty;
                    updateValidateInputData.isValidDropoffPoint.isTouchedDestinationPoint = true;

                    isValidPoint = updateValidateInputData.isValidPickupPoint.isValidDeparturePoint && updateValidateInputData.isValidPickupPoint.isValidDestinationPoint
                        && updateValidateInputData.isValidDropoffPoint.isValidDeparturePoint;
                }

                updateValidateInputData.isValidForm = isNotEmpty && isValidPoint && checkIsValidTransfer();

                break;

            default:
                break;
        }

        setTransferDetail(updateTransferDetail);
        setValidateInputData(updateValidateInputData);
    }

    function checkValidateInputData(propertyName, value) {
        let isValidForm = false;
        let prevValidateInputData = { ...validateInputData };
        delete prevValidateInputData.isValidPickupPoint;
        delete prevValidateInputData.isValidDropoffPoint;
        delete prevValidateInputData.isValidForm;

        const isNotEmpty = (value !== '' && value !== undefined);
        const isSelectedOption = value !== 'NOT';

        switch (propertyName) {
            case 'name': {
                delete prevValidateInputData.isValidName;
                delete prevValidateInputData.isTouchedName;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item) && checkIsValidTransferPoint();

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidName: isNotEmpty, isTouchedName: true });
                break;
            }
            case 'city': {
                delete prevValidateInputData.isValidCity;
                delete prevValidateInputData.isTouchedCity;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidCity: isNotEmpty, isTouchedCity: true });
                break;
            }
            case 'providerId': {
                delete prevValidateInputData.isValidProviderId;
                delete prevValidateInputData.isTouchedProviderId;
                isValidForm = isSelectedOption && Object.values(prevValidateInputData).every(item => item) && checkIsValidTransferPoint();

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidProviderId: isSelectedOption, isTouchedProviderId: true });
                break;
            }
            case 'pickupDate': {
                delete prevValidateInputData.isValidPickupDate;
                delete prevValidateInputData.isTouchedPickupDate;
                delete prevValidateInputData.isValidCancelPolicy;
                delete prevValidateInputData.isTouchedCancelPolicy;

                const isValidDate = (new Date(value) !== "Invalid Date") && new Date(value) >= new Date(today);
                isValidForm = isNotEmpty && isValidDate && Object.values(prevValidateInputData).every(item => item);

                let newValidateInputData = {
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidPickupDate: isNotEmpty && isValidDate,
                    isTouchedPickupDate: true,
                    isValidCancelPolicy: isNotEmpty,
                    isTouchedCancelPolicy: true

                };

                setValidateInputData(newValidateInputData);

                break;
            }
            case 'pickupTime': {
                delete prevValidateInputData.isValidPickupTime;
                delete prevValidateInputData.isTouchedPickupTime;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item) && checkIsValidTransferPoint();

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidPickupTime: isNotEmpty, isTouchedPickupTime: true });
                break;
            }
            case 'dropoffDate': {
                delete prevValidateInputData.isValidDropoffDate;
                delete prevValidateInputData.isTouchedDropoffDate;

                let isValidDate = (new Date(value) !== "Invalid Date") && new Date(value) >= new Date(today);
                const checkIn = new Date(transferDetail['pickupDate']);
                const checkOut = new Date(value);

                let isGreaterThanCheckIn = checkOut >= checkIn;
                isValidDate = isValidDate && isGreaterThanCheckIn;

                isValidForm = isNotEmpty && isValidDate && Object.values(prevValidateInputData).every(item => item) && checkIsValidTransferPoint();

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidDropoffDate: isNotEmpty && isValidDate,
                    isTouchedDropoffDate: true
                });
                break;
            }
            case 'dropoffTime': {
                delete prevValidateInputData.isValidDropoffTime;
                delete prevValidateInputData.isTouchedDropoffTime;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item) && checkIsValidTransferPoint();

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidDropoffTime: isNotEmpty, isTouchedDropoffTime: true });
                break;
            }
            case 'cancelPolicy': {
                delete prevValidateInputData.isValidCancelPolicy;
                delete prevValidateInputData.isTouchedCancelPolicy;

                let isValidDate = (new Date(value) !== "Invalid Date") && new Date(value) >= new Date(today);
                const checkIn = new Date(transferDetail['pickupDate']);
                const cancelPolicy = new Date(value);
                let isLessThanCheckIn = cancelPolicy <= checkIn;
                isValidDate = isValidDate && isLessThanCheckIn;

                isValidForm = isNotEmpty && isValidDate && Object.values(prevValidateInputData).every(item => item) && checkIsValidTransferPoint();

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidCancelPolicy: isNotEmpty && isValidDate,
                    isTouchedCancelPolicy: true
                });
                break;
            }
            case 'buyPriceAmount': {
                delete prevValidateInputData.isValidBuyPriceAmount;
                delete prevValidateInputData.isTouchedBuyPriceAmount;

                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item) && checkIsValidTransferPoint();

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidBuyPriceAmount: isNotEmpty, isTouchedBuyPriceAmount: true });
                break;
            }
            case 'isOneWay': {
                delete prevValidateInputData.isValidDropoffDate;
                delete prevValidateInputData.isTouchedDropoffDate;
                delete prevValidateInputData.isValidDropoffTime;
                delete prevValidateInputData.isTouchedDropoffTime;

                if (value) {
                    isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item) && checkIsValidTransfer(true);

                    setValidateInputData({
                        ...validateInputData,

                        isValidDropoffDate: true,
                        isTouchedDropoffDate: true,
                        isValidDropoffTime: true,
                        isTouchedDropoffTime: true,

                        isValidForm: isValidForm
                    });
                } else {
                    setValidateInputData({
                        ...validateInputData,
                        isValidDropoffDate: false,
                        isTouchedDropoffDate: true,
                        isValidDropoffTime: false,
                        isTouchedDropoffTime: true,

                        isValidForm: false
                    });
                }
            }
            default:
                break;
        }
    }

    function checkIsValidTransfer(forceIsOneWay) {
        var isValid = validateInputData.isValidName && validateInputData.isValidProviderId && validateInputData.isValidPickupDate && validateInputData.isValidPickupTime
            && validateInputData.isValidCancelPolicy && validateInputData.isValidBuyPriceAmount;

        if (!transferDetail.isOneWay && !forceIsOneWay)
            isValid = isValid && validateInputData.isValidDropoffDate && validateInputData.isValidDropoffTime;

        return isValid;
    }

    function checkIsValidTransferPoint() {
        var isValid = validateInputData.isValidPickupPoint.isValidDeparturePoint && validateInputData.isValidPickupPoint.isValidDestinationPoint;

        if (!transferDetail.isOneWay)
            isValid = isValid && validateInputData.isValidDropoffPoint.isValidDeparturePoint && validateInputData.isValidDropoffPoint.isValidDestinationPoint;

        return isValid;
    }

    function onChangePickupDate(event) {
        var updateTransferDetail = { ...transferDetail };
        updateTransferDetail.pickupDate = event.target.value;

        if (transferDetail.dropoffDate && new Date(transferDetail.dropoffDate) < new Date(event.target.value)) {
            updateTransferDetail.dropoffDate = '';
        }

        let cpDate = addDaysToDatePicker(event.target.value, -1);
        updateTransferDetail.cancelPolicy = cpDate;

        if (event.target.value) {
            setValidateValueDefault({ maxCancelPolicy: cpDate });
        }
        else {
            setValidateValueDefault({ maxCancelPolicy: '' });
        }

        setTransferDetail(updateTransferDetail);
        checkValidateInputData("pickupDate", event.target.value);
    }

    function onChangeTotalChildren(event) {
        let numChildren = event.target.value ? parseInt(event.target.value) : 0;

        if (numChildren > manualRangeParameter.transfer.maxChildren) {
            numChildren = manualRangeParameter.transfer.maxChildren;
        } else if (numChildren < manualRangeParameter.transfer.minChildren) {
            numChildren = manualRangeParameter.transfer.minChildren;
        }

        let ages = [...transferDetail.ages];

        let diffChildren = ages.length - numChildren;
        if (diffChildren > 0) {
            ages.splice(numChildren);
        } else if (diffChildren < 0) {
            for (let i = 0; i < (diffChildren * -1); i++)
                ages.push(0);
        }

        setTransferDetail({ ...transferDetail, totalChildren: numChildren, ages: ages });
    }

    function onChangeAge(event, index) {
        let ages = [...transferDetail.ages];
        var age = event.target.value ? parseInt(event.target.value) : 0;

        if (age > manualRangeParameter.transfer.maxChildAge) {
            age = manualRangeParameter.transfer.maxChildAge;
        } else if (age < manualRangeParameter.transfer.minChildAge) {
            age = manualRangeParameter.transfer.minChildAge;
        }

        ages[index] = age;

        setTransferDetail({ ...transferDetail, ages: ages });
    }

    function callOnSelectDestination(item) {
        var updateTransferDetail = { ...transferDetail };
        updateTransferDetail.city = { webId: item.Id + "", text: item.Text, geoId: item.GeoId + "" };

        setTransferDetail(updateTransferDetail);
        checkValidateInputData("city", item);
    }

    function saveTransferManual() {
        props.callAddManualQuotationItem(transferDetail);
    }

    return (
        <div className="p-4">
            <div className="row">
                <h4>Trasferimenti</h4>
                <div className={"form-floating col-md-6" + (!validateInputData.isValidName ? " insert-danger" : "")}>
                    <input type="text" className="form-control" value={transferDetail.name} id="floatingInput" placeholder="nome visualizzabile auto" onChange={(e) => { onBlurEvent(e, 'name'); }} onBlur={(e) => { onBlurEvent(e, 'name'); }} />
                    <label htmlFor="floatingInput" className="active">Nome transfer *</label>
                    {
                        validateInputData.isTouchedName && !validateInputData.isValidName && <div className="text-danger">{t('Login:FieldNameIsMandatory')}</div>
                    }
                </div>
                <div className="form-floating col-md-3">
                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" className="btn-check" name="btnDirectionTransfer" id="btnDirectionTransfer_OneWay" checked={transferDetail.isOneWay} onChange={(e) => onBlurEvent(e, 'isOneWay')} />
                        <label className="btn btn-outline-primary" htmlFor="btnDirectionTransfer_OneWay">One Way</label>

                        <input type="radio" className="btn-check" name="btnDirectionTransfer" id="btnDirectionTransfer_RoundTrip" checked={!transferDetail.isOneWay} onChange={(e) => onBlurEvent(e, 'isRoundTrip')} />
                        <label className="btn btn-outline-primary" htmlFor="btnDirectionTransfer_RoundTrip">A/R</label>
                    </div>
                </div>
                <div className={"form-floating col-md-3" + (!validateInputData.isValidCity ? " insert-danger" : "")}>
                    <input type="text"
                        className="form-control"
                        id="floatingInput"
                        value={transferDetail.city && transferDetail.city.text ? transferDetail.city.text : ''}
                        data-bs-toggle="modal"
                        data-bs-target="#TR_Manual_City"
                        placeholder="Città * (Applica regole)"
                        readOnly />
                    <label htmlFor="floatingInput" className="active">
                        Città * (Applica regole)
                    </label>

                    <InputSearchDestinationModal
                        modalId={"TR_Manual_City"}
                        productType={configData.Settings.Products.Transfer.IdTipoProdotto}
                        callOnSelectDestination={callOnSelectDestination}
                        rules={{ enableDestination: true }} />
                    {
                        validateInputData.isTouchedCity && !validateInputData.isValidCity && <div className="text-danger">Il campo città è obbligatorio</div>
                    }
                </div>
            </div>
            <div className="row pt-2">
                <div className="col-md-3">
                    <div className={"form-floating" + (!validateInputData.isValidProviderId ? " insert-danger" : "")}>
                        <span className="p-float-label form-select-label-m3-react-prime">
                            {
                                suppliers && suppliers.length > 0 && <Dropdown value={transferDetail.providerId}
                                    onChange={(e) => onBlurEvent(e.value, 'providerId')}
                                    inputId="dd-provider"
                                    filter
                                    className="w-100 form-select-m3-react-prime"
                                    options={suppliers}
                                    optionLabel="label" />
                            }
                            <label htmlFor="dd-provider">Fornitore *</label>
                        </span>
                        {
                            validateInputData.isTouchedProviderId && !validateInputData.isValidProviderId && <div className="text-danger">Il campo fornitore è obbligatorio</div>
                        }
                    </div>
                </div>
                <div className="form-floating col-md-3">
                    <input type="text" className="form-control" value={transferDetail.payBy === null ? '' : transferDetail.payBy} id="floatingInput" placeholder="Fornitore per voucher" onChange={(e) => { onBlurEvent(e, 'payBy'); }} />
                    <label htmlFor="floatingInput" className="active">Pay by:</label>
                </div>
                <div className="form-floating col-md-3">
                    <input type="text" className="form-control" value={transferDetail.recordLocator === null ? '' : transferDetail.recordLocator} id="floatingInput" placeholder="IdRecordLocator" onChange={(e) => { onBlurEvent(e, 'recordLocator'); }} />
                    <label htmlFor="floatingInput" className="active">Cod prenotazione:</label>
                </div>
                <div className="form-floating col-md-3">
                    <input type="text" className="form-control" value={transferDetail.reservationId === null ? '' : transferDetail.reservationId} id="floatingInput" placeholder="IdReservation" onChange={(e) => { onBlurEvent(e, 'reservationId'); }} />
                    <label htmlFor="floatingInput" className="active">Cod prenotazione 2:</label>
                </div>

            </div>
            <div className="row pt-4">
                <div className={"form-floating col-md-3" + (!validateInputData.isValidPickupDate ? " insert-danger" : "")}>
                    <input type="date" className="form-control" value={transferDetail.pickupDate} id="txt_SM_CheckIn" placeholder="01/01/99" min={today} onChange={onChangePickupDate} />
                    <label htmlFor="floatingInput" className="active">Data PickUp *</label>
                    {
                        validateInputData.isTouchedPickupDate && !validateInputData.isValidPickupDate && <div className="text-danger">Il campo pickup è obbligatorio</div>
                    }
                </div>
                <div className={"form-floating col-md-2" + (!validateInputData.isValidPickupTime ? " insert-danger" : "")}>
                    <input type="time" className="form-control" value={transferDetail.pickupTime} id="txt_SM_CheckIn" placeholder="01/01/99" onChange={(e) => { onBlurEvent(e, 'pickupTime'); }} />
                    <label htmlFor="floatingInput" className="active">h PickUp *</label>
                    {
                        validateInputData.isTouchedPickupTime && !validateInputData.isValidPickupTime && <div className="text-danger">Il campo ora è obbligatorio</div>
                    }
                </div>
                {
                    !transferDetail.isOneWay && <>
                        <div className={"form-floating col-md-3" + (!validateInputData.isValidDropoffDate ? " insert-danger" : "")}>
                            <input type="date" className="form-control" value={transferDetail.dropoffDate} id="txt_SM_CheckIn" placeholder="01/01/99" min={transferDetail.pickupDate} onChange={(e) => { onBlurEvent(e, 'dropoffDate'); }} />
                            <label htmlFor="floatingInput" className="active">Data DropOff *</label>
                            {
                                validateInputData.isTouchedDropoffDate && !validateInputData.isValidDropoffDate && <div className="text-danger">Il campo dropoff è obbligatorio</div>
                            }
                        </div>
                        <div className={"form-floating col-md-2" + (!validateInputData.isValidDropoffTime ? " insert-danger" : "")}>
                            <input type="time" className="form-control" value={transferDetail.dropoffTime} id="txt_SM_CheckIn" placeholder="01/01/99" onChange={(e) => { onBlurEvent(e, 'dropoffTime'); }} />
                            <label htmlFor="floatingInput" className="active">h DropOff *</label>
                            {
                                validateInputData.isTouchedDropoffTime && !validateInputData.isValidDropoffTime && <div className="text-danger">Il campo ora è obbligatorio</div>
                            }
                        </div>
                    </>
                }
                <div className={"form-floating col-md-2" + (!validateInputData.isValidCancelPolicy ? " insert-danger" : "")}>
                    <input type="date"
                        className="form-control"
                        value={transferDetail.cancelPolicy}
                        id="txt_SM_CancelPolicy"
                        placeholder="01/01/99"
                        max={validateValueDefault.maxCancelPolicy}
                        onChange={(e) => { onBlurEvent(e, 'cancelPolicy'); }} />
                    <label htmlFor="floatingInput" className="active">Inizio penale *</label>
                    {
                        validateInputData.isTouchedCancelPolicy && !validateInputData.isValidCancelPolicy && <div className="text-danger">Il campo cancel policy è obbligatorio</div>
                    }
                </div>
                <div className="form-floating col-md-2">
                    <span className="p-float-label form-select-label-m3-react-prime">
                        {
                            vehicleTypes && vehicleTypes.length > 0 && <Dropdown value={transferDetail.vehicleType}
                                onChange={(e) => onBlurEvent(e.value, 'vehicleType')}
                                inputId="dd-provider"
                                filter
                                className="w-100 form-select-m3-react-prime"
                                options={vehicleTypes}
                                optionLabel="label" />
                        }
                        <label htmlFor="dd-provider">Tipo veicolo</label>
                    </span>
                </div>
            </div>
            <div className="form-floating col-md-12">
                <input type="text"
                    className="form-control"
                    value={transferDetail.imageUrl}
                    id="floatingInput"
                    placeholder="Url immagine"
                    onChange={(e) => { onBlurEvent(e, 'imageUrl'); }} />
                <label htmlFor="floatingInput" className="active">Url Immagine</label>
            </div>

            <div>
                <AddServiceNote
                    serviceNote={transferDetail.serviceNoteDesc}
                    roomIndex={-1}
                    noteTypeToAdd={12}
                    propertyName="serviceNoteDesc"
                    onBlurEvent={onBlurEvent} />
            </div>

            <div className="form-floating pt-2">
                <textarea className="form-control"
                    placeholder="Note Interne"
                    value={transferDetail.internalNote}
                    onChange={(e) => { onBlurEvent(e, 'internalNote'); }}
                    id="floatingTextarea2"
                    style={{ height: '100px' }} ></textarea>
                <label htmlFor="floatingTextarea2">Note Interne</label>
            </div>

            <div className="badge-gray-600 mt-4">Servizio</div>

            <div className="row pt-4">
                <div className="col-md-3">
                    <div className="form-floating">

                        <span className="p-float-label form-select-label-m3-react-prime">
                            {
                                transferPointType && transferPointType.length > 0 && <Dropdown value={transferDetail.pickupPoint.pointType}
                                    onChange={(e) => onBlurPointEvent(e.value, 'pickupPoint', 'pointType')}
                                    inputId="dd-pickupPoint"
                                    filter
                                    className="w-100 form-select-m3-react-prime"
                                    options={transferPointType.map(d => { return { label: d.text, value: d.key }; })}
                                    optionLabel="label" />
                            }
                            <label htmlFor="dd-pickupPoint">Point Type</label>
                        </span>
                    </div>
                </div>
                <div className="form-floating col-md-2">
                    <input type="number" className="form-control" id="floatingInput" value={transferDetail.pickupPoint.id} onChange={(e) => { onBlurPointEvent(e, 'pickupPoint', 'id'); }} placeholder="123.45" />
                    <label htmlFor="floatingInput" className="active">Sigla/id </label>
                </div>
                <div className={"form-floating col-md-3" + (!validateInputData.isValidPickupPoint.isValidDeparturePoint ? " insert-danger" : "")}>
                    <input type="Text" className="form-control" id="floatingInput" value={transferDetail.pickupPoint.departurePoint} onChange={(e) => { onBlurPointEvent(e, 'pickupPoint', 'departurePoint'); }} placeholder="123.45" />
                    <label htmlFor="floatingInput" className="active">Punto partenza *</label>
                    {
                        validateInputData.isValidPickupPoint.isTouchedDeparturePoint && !validateInputData.isValidPickupPoint.isValidDeparturePoint && <div className="text-danger">Il campo punto partenza è obbligatorio</div>
                    }
                </div>
                <div className={"form-floating col-md-4" + (!validateInputData.isValidPickupPoint.isValidDestinationPoint ? " insert-danger" : "")}>
                    <input type="Text" className="form-control" id="floatingInput" value={transferDetail.pickupPoint.destinationPoint} onChange={(e) => { onBlurPointEvent(e, 'pickupPoint', 'destinationPoint'); }} placeholder="123.45" />
                    <label htmlFor="floatingInput" className="active">Indirizzo destinazione *</label>
                    {
                        validateInputData.isValidPickupPoint.isTouchedDestinationPoint && !validateInputData.isValidPickupPoint.isValidDestinationPoint && <div className="text-danger">Il campo indirizzo destinazione è obbligatorio</div>
                    }
                </div>
            </div>

            {
                !transferDetail.isOneWay && <div className="row pt-4">
                    <div className="col-md-3">
                        <div className="form-floating">
                            {
                                transferPointType && transferPointType.length > 0 && <Dropdown value={transferDetail.dropoffPoint.pointType}
                                    onChange={(e) => onBlurPointEvent(e.value, 'dropoffPoint', 'pointType')}
                                    inputId="dd-dropoffPoint"
                                    filter
                                    className="w-100 form-select-m3-react-prime"
                                    options={transferPointType.map(d => { return { label: d.text, value: d.key }; })}
                                    optionLabel="label" />
                            }
                            <label htmlFor="dd-dropoffPoint">Point Type</label>
                        </div>
                    </div>
                    <div className="form-floating col-md-2">
                        <input type="number" className="form-control" id="floatingInput" value={transferDetail.dropoffPoint.id} onChange={(e) => { onBlurPointEvent(e, 'dropoffPoint', 'id'); }} placeholder="123.45" />
                        <label htmlFor="floatingInput" className="active">Sigla/id </label>
                    </div>
                    <div className={"form-floating col-md-3" + (!validateInputData.isValidDropoffPoint.isValidDeparturePoint ? " insert-danger" : "")}>
                        <input type="Text" className="form-control" id="floatingInput" value={transferDetail.dropoffPoint.departurePoint} onChange={(e) => { onBlurPointEvent(e, 'dropoffPoint', 'departurePoint'); }} placeholder="123.45" />
                        <label htmlFor="floatingInput" className="active">Punto partenza *</label>
                        {
                            validateInputData.isValidDropoffPoint.isTouchedDeparturePoint && !validateInputData.isValidDropoffPoint.isValidDeparturePoint && <div className="text-danger">Il campo punto partenza è obbligatorio</div>
                        }
                    </div>
                    <div className={"form-floating col-md-4" + (!validateInputData.isValidDropoffPoint.isValidDestinationPoint ? " insert-danger" : "")}>
                        <input type="Text" className="form-control" id="floatingInput" value={transferDetail.dropoffPoint.destinationPoint} onChange={(e) => { onBlurPointEvent(e, 'dropoffPoint', 'destinationPoint'); }} placeholder="123.45" />
                        <label htmlFor="floatingInput" className="active">Indirizzo destinazione *</label>
                        {
                            validateInputData.isValidDropoffPoint.isTouchedDestinationPoint && !validateInputData.isValidDropoffPoint.isValidDestinationPoint && <div className="text-danger">Il campo indirizzo destinazione è obbligatorio</div>
                        }
                    </div>
                </div>
            }

            <div className="row pt-2">
                <div className={"form-floating col-md-2" + (!validateInputData.isValidBuyPriceAmount ? " insert-danger" : "")} >
                    <input type="number" className="form-control" value={transferDetail.buyPriceAmount === null ? '' : transferDetail.buyPriceAmount} id="floatingInput" placeholder="123.45" onChange={(e) => { onBlurEvent(e, 'buyPriceAmount'); }} />
                    <label htmlFor="floatingInput" className="active">Costo *</label>
                    {
                        validateInputData.isTouchedBuyPriceAmount && !validateInputData.isValidBuyPriceAmount && <div className="error-input">Il campo costo è obbligatorio</div>
                    }
                </div>
                <div className="col-md-2">
                    <div className="form-floating">
                        <span className="p-float-label form-select-label-m3-react-prime">
                            {
                                currencies && currencies.length > 0 && <Dropdown value={transferDetail.buyPriceCurrency}
                                    onChange={(e) => onBlurEvent(e.value, 'buyPriceCurrency')}
                                    inputId="dd-buyPriceCurrency"
                                    filter
                                    className="w-100 form-select-m3-react-prime"
                                    options={currencies}
                                    optionLabel="label" />
                            }
                            <label htmlFor="dd-buyPriceCurrency">Valuta</label>
                        </span>
                    </div>
                </div>
                <div className="form-floating col-md-2">
                    <input type="number" className="form-control" id="floatingInput" placeholder="123.45" value={transferDetail.buyPriceChange === null ? '' : transferDetail.buyPriceChange} onChange={(e) => { onBlurEvent(e, 'buyPriceChange'); }} />
                    <label htmlFor="floatingInput" className="active">Tasso Cambio </label>
                </div>

                <div className="form-floating col-2">
                    <input type="number" className="form-control" id="floatingInput" placeholder="adults" value={transferDetail.totalAdults} min={manualRangeParameter.transfer.minRoom} max={manualRangeParameter.transfer.maxAdults} onChange={(e) => { onBlurEvent(e, 'totalAdults'); }} />
                    <label htmlFor="floatingInput" className="active">N. Adulti </label>
                </div>
                <div className="form-floating col-2">
                    <input type="number" className="form-control" id="floatingInput" placeholder="children" value={transferDetail.totalChildren} min="0" max={manualRangeParameter.transfer.maxChildren} onChange={(e) => { onChangeTotalChildren(e); }} />
                    <label htmlFor="floatingInput" className="active">N. Bambini</label>
                </div>
                {
                    transferDetail.ages.length > 0 && transferDetail.ages.map((item, key) => {
                        return <div key={key} className="form-floating col-2">
                            <input type="number" className="form-control" id="floatingInput" placeholder="children" min={manualRangeParameter.transfer.minChildAge} max={manualRangeParameter.transfer.maxChildAge} value={item} onChange={(e) => { onChangeAge(e, key); }} />
                            <label htmlFor="floatingInput" className="active">Età {key + 1}</label>
                        </div>
                    })
                }
                <div>L'età effettiva al momento della partenza deve corrispondere all'età dichiarata al momento della dell'entrata in struttura. Età ragazzi 17-0 compiuti</div>

                <div>
                    <AddServiceNote
                        serviceNote={transferDetail.serviceNote}
                        roomIndex={-1}
                        onBlurEvent={onBlurEvent} />
                </div>
            </div>


            <div className="float-end">
                {props.isError && <span className="text-danger">Errore in fase di inserimento, controllare i dati</span>}
                <button className="btn tp-btn-add btn-sm" disabled={!validateInputData.isValidForm} onClick={saveTransferManual}>{t('Button:Save')}</button>
            </div>
        </div>
    );
}