import React, { useState, useEffect, useCallback } from 'react';
import { M3Icon } from "../../Common/M3Icon";
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";

export const ProductActionMenu = ({ productId, productType, handleModalOpenProductId, handleModalOpenFareDetail }) => {
    const { t } = useTranslation();

    const [labelShowStaticDetail, setLabelShowStaticDetail] = useState('');
    const [isShowStaticDetail, setIsShowStaticDetail] = useState(false);
    const [isShowMap, setIsShowMap] = useState(false);
    const [isShowFavourite, setIsShowFavourite] = useState(false);
    const [isBlockLeg, setIsBlockLeg] = useState(false);
    const [isPrint, setIsPrint] = useState(false);
    const [isShowFlightPrices, setIsShowFlightPrices] = useState(false);
    const [labelShowFlightPrice, setLabelShowFlightPrice] = useState('');

    useEffect(() => {

        switch (productType) {

            case configData.Settings.Products.Activity.IdTipoProdotto: {
                setLabelShowStaticDetail('ActionMenu:Detail');
                setIsShowStaticDetail(true);
                setIsShowMap(false);
                setIsShowFavourite(false);
                break;
            }

            case configData.Settings.Products.Flight.IdTipoProdotto: {
                setLabelShowStaticDetail('ActionMenu:Detail');
                setIsShowStaticDetail(true);
                setIsBlockLeg(false);
                setIsPrint(false);
                setIsShowFlightPrices(true);
                setLabelShowFlightPrice('ActionMenu:FlightDetailFare')
                break;
            }

            case configData.Settings.Products.Train.IdTipoProdotto: {
                setLabelShowStaticDetail('Product:Train:Itinerary');
                setIsShowStaticDetail(true);
                setIsShowMap(false);
                setIsShowFavourite(false);
                break;
            }

            case configData.Settings.Products.Structure.IdTipoProdotto: {
                setLabelShowStaticDetail('ActionMenu:Detail');
                setIsShowStaticDetail(true);
                setIsShowMap(true);
                setIsShowFavourite(true);
                break;
            }


            case configData.Settings.Products.CarRental.IdTipoProdotto: {
                setLabelShowStaticDetail('ActionMenu:Detail');
                setIsShowStaticDetail(true);
                setIsShowMap(false);
                setIsShowFavourite(false);
                break;
            }

            case configData.Settings.Products.Tour.IdTipoProdotto: {
                setLabelShowStaticDetail('ActionMenu:Detail');
                setIsShowStaticDetail(true);
                setIsShowMap(false);
                setIsShowFavourite(false);
                break;
            }

            case configData.Settings.Products.Cruise.IdTipoProdotto: {
                setLabelShowStaticDetail('ActionMenu:Detail');
                setIsShowStaticDetail(true);
                setIsShowMap(false);
                setIsShowFavourite(false);
                break;
            }
        }
    }, [])

    const handleInputClick = useCallback(event => {
        handleModalOpenProductId(productId);
    }, [handleModalOpenProductId]);

    function goToMap() {
        
    }

    function saveFavouriteProduct() {
        console.log("TODO SAVE FAVOURITE. Cercare nelle API SaveFavouriteUserRq");
    }

    const handleInputClickFlightFare = useCallback(event => {
        handleModalOpenFareDetail(productId);
    }, [handleModalOpenFareDetail]);

    return (
        <>
            <ul className="dropdown-menu dropdown-menu-end">
                {
                    isShowStaticDetail && <li>
                        <a className="dropdown-item" data-bs-toggle="modal" data-bs-target={"#schedStaticaModal" + productId} onClick={e => handleInputClick(e)}>
                            <data m3ico="Info icon-10"><M3Icon iconName="Info" externalClass="icon-10" /></data>
                            <label><data m3lab="ActionMenu.Detail">{t(labelShowStaticDetail)}</data></label>
                        </a>
                    </li>
                }
                {
                    isShowMap && <li>
                        <a className="dropdown-item d-none" href={goToMap()} >
                            <data m3ico="Place icon-10"><M3Icon iconName="Place" externalClass="icon-10" /></data>
                            <label><data m3lab="ActionMenu.Map">{t(`ActionMenu:Map`)}</data></label>
                        </a>
                    </li>
                }
                {
                    isShowFavourite && <li>
                        <a className="dropdown-item text-danger d-none" href="javscript:void()" onClick={(e) => saveFavouriteProduct()}>
                            <data m3ico="FavoriteOutline icon-10">
                                <M3Icon iconName="FavoriteOutline" externalClass="icon-10" /></data>
                            <label><data m3lab="ActionMenu.Prefer">{t(`ActionMenu:Prefer`)}</data></label>
                        </a>
                    </li>
                }
                {
                    isBlockLeg && <li>
                        <a className="dropdown-item" href="#">
                            <data m3ico="Lock icon-10">
                                <M3Icon iconName="Lock" externalClass="icon-10" />
                            </data>
                            <label>
                                <data m3lab="ActionMenu.LockDeparture">{t(`ActionMenu:LockDeparture`)}</data>
                            </label>
                        </a>
                    </li>
                }
                {
                    isBlockLeg && <li>
                        <a className="dropdown-item" href="#">
                            <data m3ico="Lock icon-10">
                                <M3Icon iconName="Lock" externalClass="icon-10" />
                            </data>
                            <label>
                                <data m3lab="ActionMenu.LockArrival">{t(`ActionMenu:LockArrival`)}</data>
                            </label>
                        </a>
                    </li>
                }
                {
                    isBlockLeg && <li>
                        <a className="dropdown-item" href="#">
                            <data m3ico="Lock icon-10">
                                <M3Icon iconName="Lock" externalClass="icon-10" />
                            </data>
                            <label>
                                <data m3lab="ActionMenu.LockFlight">{t(`ActionMenu:LockFlight`)}</data>
                            </label>
                        </a>
                    </li>
                }
                {
                    isPrint && <li>
                        <a className="dropdown-item" href="#">
                            <data m3ico="Print icon-10">
                                <M3Icon iconName="Print" externalClass="icon-10" />
                            </data>
                            <label>
                                <data m3lab="ActionMenu.Print">{t(`ActionMenu:Print`)}</data>
                            </label>
                        </a>
                    </li>
                }
                {
                    isShowFlightPrices &&
                    <li>
                        <a className="dropdown-item" data-bs-toggle="modal" data-bs-target={"#schedStaticaModal" + productId} onClick={e => handleInputClickFlightFare(e)}>
                            <data m3ico="Info icon-10"><M3Icon iconName="Info" externalClass="icon-10" /></data>
                            <label><data m3lab="ActionMenu.Detail">{t(labelShowFlightPrice)}</data></label>
                        </a>
                    </li>
                }
            </ul>
        </>
    );
}