import React from 'react';
import { ContactForm } from './form/ContactForm';
import { SubscriberForm } from './form/SubscriberForm';

export const WebContentForm = ({ item }) => {

    return <div className="p-3">
        {item.titolo && <div dangerouslySetInnerHTML={{ __html: item.titolo }}></div>}
        {item.sottotitolo && <div dangerouslySetInnerHTML={{ __html: item.sottotitolo }}></div>}
        {
            item.formTypeId === 3 &&
            <div>
                <ContactForm item={item} />
            </div>
        }
        {
            item.formTypeId !== 3 && <div>
                <SubscriberForm item={item} />
            </div>
        }
        {item.footer && <div dangerouslySetInnerHTML={{ __html: item.footer }}></div>}
    </div>;
}