import React from 'react';
import { useTranslation } from 'react-i18next';

export const DocHeader = ({ Data }) => {
    const { t } = useTranslation();

    return (
        <>
            <div>
                <h4 className="my-0"><label>{t("Print:Client")}: </label> {Data.companyName}</h4>
                <ul className="list-group simple-list pl-5">
                    <li className="list-group-item font-weight-normal pl-0 py-0">{Data.address}</li>
                    <li className="list-group-item font-weight-normal pl-0 py-0">{Data.zipCode} {Data.city}</li>
                    <li className="list-group-item font-weight-normal pl-0 py-0">
                        {Data.isCommissionable === 'false'
                            ? ("C.F.:") 
                            : ("P.IVA:")
                        }
                        {Data.isCommissionable === false ? Data.fiscalCode : Data.vatCode}
                    </li>
                </ul>
            </div>
            {/*
            =========================================
            <br/>Total Obj: {JSON.stringify(Data)}<br/>
            */}
        </>
    );
}

