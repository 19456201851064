import React from 'react';
import { useTranslation } from 'react-i18next';
import configData from "../../../../appsettings.json";
import { getCDNLogo } from "../../../../js/CDNUtility";

export const HeaderLogo = ({ item, modal }) => {
    const { t } = useTranslation();

    return (
        <>
        <a className="d-none d-sm-block" href={configData.Settings.Base_Url}>
            <img src={getCDNLogo()} alt={t(`home:title`)} className={item?.className ? item?.className : ""} />
            </a>
            <div className={`${modal && 'd-none'}` }>


                <a className="d-block d-sm-none" href={configData.Settings.Base_Url}>
                    <img src={getCDNLogo()} className="mb-4" alt={t(`home:title`)} style={{
                    width: '150px'
                } } />
        </a>
            </div>
        </>
    );
}