import React from 'react';
import { useTranslation } from 'react-i18next';

export const TrippieFooterButton = ({ saveTrippie, saveRequestBooking, isClickCalculateAddProd, goToCart, calculateAddictionalProduct }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="row mt-4 mb-2">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                    <button className="btn tp-btn-book w-50" onClick={_ => calculateAddictionalProduct()}>Calcola prodotti aggiuntivi</button>
                </div>
            </div>
            {
                isClickCalculateAddProd && <div className="row mb-2">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-right">
                        <button className="btn tp-btn-book w-100" onClick={_ => saveTrippie()}>{t('Button:SaveAndPrint')}</button>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-right">
                        <button className="btn tp-btn-book w-100" onClick={_ => saveRequestBooking()}>{t('Button:RequestBooking')}</button>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-right">
                        <button className="btn tp-btn-book w-100" onClick={_ => goToCart()}>Vai al carrello</button>
                    </div>
                </div>
            }
        </>
    );
}
