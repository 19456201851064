import React, { useEffect, useState, useCallback } from 'react';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';

export const HotelCombinationFilter = ({ handleMealPlanFilter, bestRoomTablePrice }) => {
    const { t } = useTranslation();
    const buttonClass = " btn btn-sm btn-neutral btnCombination ";

    const handleInputClick = useCallback(event => {
        let value = null;
        if (event.currentTarget.className.includes("tp-btn-select")) {
            event.currentTarget.className = buttonClass;
            value = null;
        }
        else {
            setClassClick();
            event.currentTarget.className = 'tp-btn-select ' + buttonClass;
            value = event.currentTarget.value;
        }
        
        handleMealPlanFilter(value);
    }, [handleMealPlanFilter]);


    function setClassClick() {
        const allWithClass = Array.from(
            document.querySelectorAll('button.btnCombination')
        );

        allWithClass.forEach(element => {
            element.className = buttonClass;
        });
    }

    useEffect(() => {
    }, []);

    return (
        <div className="mt-4 bg-gray-300 rounded p-1">
            <h6 className="m-0 p-1"><data m3lab="Product.Structure.Filter.TabCombinationTitle">{t(`Product:Structure:Filter:TabCombinationTitle`)}:</data></h6>
            <span className="text-muted small"><data m3lab="Product.Structure.Filter.TabCombinationText">{t(`Product:Structure:Filter:TabCombinationText`)}</data></span>

            <div className="">
                <div className="card border-light badge-gray-300">
                    <table className="small w-100 text-center">
                        <thead>
                            <tr className="badge-gray-300">
                                <th className="w-33"><data m3lab="Product.Structure.Filter.TabCombinationTCol1">{t(`Product:Structure:Filter:TabCombinationTCol1`)}</data></th>
                                <th className="w-33"><data m3lab="Product.Structure.Filter.TabCombinationTCol2">{t(`Product:Structure:Filter:TabCombinationTCol2`)}</data></th>
                                <th className="w-33"><data m3lab="Product.Structure.Filter.TabCombinationTCol3">{t(`Product:Structure:Filter:TabCombinationTCol3`)}</data></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><data m3lab="Product.MealPlan.MealPlan.TextOB">{t(`Product:Structure:Filter:MealPlan:TextOB`)}</data></td>
                                <td>
                                    <button className={buttonClass} value="true_1" onClick={e => handleInputClick(e)}><var>{bestRoomTablePrice.bestFreeOb ? bestRoomTablePrice.bestFreeOb : "-"}</var></button>
                                </td>
                                <td>
                                    <button className={buttonClass} value="false_1" onClick={e => handleInputClick(e)}><var>{bestRoomTablePrice.bestPenaltyOb ? bestRoomTablePrice.bestPenaltyOb : "-"}</var></button>
                                </td>
                            </tr>
                            <tr>
                                <td><data m3lab="Product.MealPlan.MealPlan.TextBB">{t(`Product:Structure:Filter:MealPlan:TextBB`)}</data></td>
                                <td>
                                    <button className={buttonClass} value="true_2" onClick={e => handleInputClick(e)}><var>{bestRoomTablePrice.bestFreeBb ? bestRoomTablePrice.bestFreeBb : "-"}</var></button>
                                </td>
                                <td>
                                    <button className={buttonClass} value="false_2" onClick={e => handleInputClick(e)}><var>{bestRoomTablePrice.bestPenaltyBb ? bestRoomTablePrice.bestPenaltyBb : "-"}</var></button>
                                </td>
                            </tr>
                            <tr>
                                <td><data m3lab="Product.MealPlan.MealPlan.TextHB">{t(`Product:Structure:Filter:MealPlan:TextHB`)}</data></td>
                                <td>
                                    <button className={buttonClass} value="true_3" onClick={e => handleInputClick(e)}><var>{bestRoomTablePrice.bestFreeHb ? bestRoomTablePrice.bestFreeHb : "-"}</var></button>
                                </td>
                                <td>
                                    <button className={buttonClass} value="false_3" onClick={e => handleInputClick(e)}><var>{bestRoomTablePrice.bestPenaltyHb ? bestRoomTablePrice.bestPenaltyHb : "-"}</var></button>
                                </td>
                            </tr>
                            <tr>
                                <td><data m3lab="Product.MealPlan.MealPlan.TextFB">{t(`Product:Structure:Filter:MealPlan:TextFB`)}</data></td>
                                <td>
                                    <button className={buttonClass} value="true_4" onClick={e => handleInputClick(e)}><var>{bestRoomTablePrice.bestFreeFb ? bestRoomTablePrice.bestFreeFb : "-"}</var></button>
                                </td>
                                <td>
                                    <button className={buttonClass} value="false_4" onClick={e => handleInputClick(e)}><var>{bestRoomTablePrice.bestPenaltyFb ? bestRoomTablePrice.bestPenaltyFb : "-"}</var></button>
                                </td>
                            </tr>
                            <tr>
                                <td><data m3lab="Product.MealPlan.MealPlan.TextAI">{t(`Product:Structure:Filter:MealPlan:TextAI`)}</data></td>
                                <td>
                                    <button className={buttonClass} value="true_5" onClick={e => handleInputClick(e)}><var>{bestRoomTablePrice.bestFreeAi ? bestRoomTablePrice.bestFreeAi : "-"}</var></button>
                                </td>
                                <td>
                                    <button className={buttonClass} value="false_5" onClick={e => handleInputClick(e)}><var>{bestRoomTablePrice.bestPenaltyAi ? bestRoomTablePrice.bestPenaltyAi : "-"}</var></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}