import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import configData from "../../appsettings.json";
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
import { Loading } from '../Common/Loading';

export const EdenRed = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    let authUrl = searchParams.get("authUrl");
    let code = searchParams.get("code");
    let receivedBase64Url = searchParams.get("state");
    let authMethod = "group/portale-beneficiari/sso-partner-welfare-auth";
    useEffect(() => {
        debugger;
        // settings
        let client_id = configData.Settings.Welfare.EdenRed.client_id;
        let redirect_uri = configData.Settings.Welfare.EdenRed.redirect_uri;
        if (authUrl) {
            let p_p_id_auth = "FlexbenSsoPartnerOauth2AuthorizePBenef_WAR_FlexbenSsoPartnerOauthPBenef";
            let decodedAuthUrl = decodeURIComponent(authUrl);
            debugger;
            let state = {
                authUrl: `${authUrl}`
            };
            let base64State = encode(state);
            //let url = `${authUrl}/group/portale-beneficiari/sso-partner-welfare-auth?p_p_id=FlexbenSsoPartnerOauth2AuthorizePBenef_WAR_FlexbenSsoPartnerOauthPBenef&response_type=code&p_p_lifecycle=2&client_id=43&state=getToken&redirect_uri=https://www.travelplace.ch/Welfare/EdenRed`;
            let url = `${decodedAuthUrl}/${authMethod}?p_p_id=${p_p_id_auth}&response_type=code&p_p_lifecycle=2&client_id=${client_id}&state=${base64State}&redirect_uri=${redirect_uri}`;
            console.log(`AuthUrl: ${url}`);
            window.location.href = url; 
        }

        if (code) {
            debugger;
            try {
                console.log('code:' + code);
                console.log('received base64 state:' + receivedBase64Url);
                let decodedState = decode(receivedBase64Url);
                console.log('decoded state:' + decodedState);
                let state = JSON.parse(decodedState);
                let decodedAuthUrl = decodeURIComponent(state.authUrl);
                callAuthentication(code, decodedAuthUrl);
            } catch (error) {
                debugger;
                console.error('Si è verificato un errore:', error.message);
            }
        }

    }, []);

    async function callAuthentication(code, authUrl) {
        let sharedApiUrl = configData.Settings.CommonApi_BaseUrl;
        var bodyRq = {
            AuthUrl: authUrl,
            Code: code,
        };
        const tokenRq = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyRq)
        };
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: tokenRq.body };

        const fetchedRes = await fetch(sharedApiUrl + 'welfare/edenRed/landing', requestOption)
        console.log(fetchedRes);
        debugger;
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            console.log(`fetchedRes: ${response}`);
            window.location.href = response.url;
        } else {
            console.log(fetchedRes);
        }
    }

    const encode = (str) => {
        const encoder = new TextEncoder();
        const buffer = encoder.encode(JSON.stringify(str));
        return btoa(String.fromCharCode(...new Uint8Array(buffer)));
    };

    const decode = (encodedString) => {
        const decoder = new TextDecoder('utf-8');
        const buffer = Uint8Array.from(atob(encodedString), c => c.charCodeAt(0));
        return decoder.decode(buffer);
    };

    return (
        <>
            <div className="text-center p-5">
                <h2>In attesa di autorizzazione...</h2>
                <h2>Waiting for Authorization...</h2>
                <div className="col-12"><Loading /></div>
            </div>
        </>
    );
}