import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { searchCarpetParameter } from '../../../js/Constant';
import { getCurrentUserFromJwtToken, getBasicDetailUrl } from '../../../js/Utils.js';
import { M3Icon } from "../../Common/M3Icon";
import { ProductHeader } from '../Common/ProductHeader';
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';
import { ProductItemNotFound } from '../Common/ProductItemNotFound';
import { ProductFilter } from '../Common/Filter/ProductFilter';
import { ProductFilterByName } from '../Common/Filter/ProductFilterByName';
import { ProductFilterByPrice } from '../Common/Filter/ProductFilterByPrice';
import { ProductDestinationFilter } from '../Common/Filter/ProductDestinationFilter';
import { ProductFilterMultiDestinationTime } from '../Common/Filter/ProductFilterMultiDestinationTime';
import { FlightItemList } from './components/FlightItemList';
import { FlightItemListBlock } from './components/FlightItemListBlock';
import { FlightModal } from './components/FlightModal';
import { FlightModalFareDetail } from './components/FlightModalFareDetail';
import { ListSearchEngineCV } from '../SearchEngine/compactView/ListSearchEngineCV';
import { convertHourInMilliseconds, isMobile } from '../../../js/Utils.js';
import { ListSearchEngineCVMobile } from '../SearchEngine/compactView/ListSearchEngineCVMobile';
import { ProductNavBar } from '../SearchEngine/ProductNavBar';

export const FlightList = () => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    let params = new URLSearchParams(window.location.search);
    let carpetId = params.get('cId');
    let clientFlowId = params.get('cfId');

    let extraParam = {
        carpetId: carpetId,
        clientFlowId: clientFlowId,
        productId: 0
    }

    // carpet
    const [carpetStatus, setCarpetStatus] = useState(null);
    const [carpetDataStatus, setCarpetDataStatus] = useState(null);
    const [carpetTime, setCarpetTime] = useState(1);
    const [intervalCarpetPing, setIntervalCarpetPing] = useState([]);

    // page
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [items, setItems] = useState({});
    const [itemsListMode, setItemsListMode] = useState(0);
    const [isLoadingFilter, setIsLoadingFilter] = useState(false);
    const [showPriceBar, setShowPriceBar] = useState(false);
    const [isLoadingListResult, setIsLoadingListResult] = useState(true);

    const [modalOpenFlightFare, setModalOpenFlightFare] = useState(null);
    const [modalOpenFareDetail, setModalOpenFareDetail] = useState(null);

    // roles
    const [showCost, setShowCost] = useState(false);
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);
    const [excludePackageTO, setExcludePackageTO] = useState(false);

    // filtri
    const [isResetSelectedItem, setIsResetSelectedItem] = useState(false);
    const [filterValues, setFilterValues] = useState(null);
    const [rangePriceInfo, setRangePriceInfo] = useState({
        min: "0",
        max: "5000",
        step: "50",
        currency: "EUR"
    });

    const [filterSelected, setFilterSelected] = useState({
        rangePrice: null,
        pageNumber: 0,
        providers: [],
        baggages: [],
        stops: [],
        bestFlightTypes: [],
        orderBy: searchCarpetParameter.flight.orderBy,
        airportArrivals: [],
        airportDepartures: [],
        mktCarriers: [],
        flightTimeInfo: null
    });

    // paging
    const itemsPerPage = searchCarpetParameter.flight.listMaxItem;
    const [pageCount, setPageCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(0);

    const onChangeShowPriceBar = () => {
        setShowPriceBar(!showPriceBar);
    }

    useEffect(() => {
        if (isMobile())
            setItemsListMode(1);

        setIsLoading(true);
        setIsError(false);

        getCarpetStatus();

        try {

            let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
            let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);

            if (currentUser.roles) {
                if (!currentUser.roles.includes("FE_NonVisualizzaNetto"))
                    setEnableShowNetPrices(true);

                if (currentUser.roles.includes("FE_ShowCost"))
                    setShowCost(true);

                if (currentUser.roles.includes("W_ExcludePackageTO"))
                    setExcludePackageTO(true);
            }

        } catch (ex) {
        }

    }, []);


    useEffect(() => {

        switch (carpetStatus) {
            case 'Processing':
            case 'Queued':
            case 'Completed':
                {
                    break;
                }
            case 'Error':
                {
                    setIsError(true);
                    setIsLoading(false);
                    break;
                }
        }

    }, [carpetStatus]);

    useEffect(() => {
        switch (carpetDataStatus) {
            case 'Completed':
                {
                    callGetNewData();
                    clearCarpetAllInterval();
                    break;
                }

            case 'NewData':
                {
                    callGetNewData();
                    break;
                }

            case 'Processing':
            case 'NoChanges':
                {

                    let interval = setInterval(() => {
                        getCarpetStatus();
                    }, searchCarpetParameter.flight.carpetPingMs);
                    addInterval(interval);

                    break;
                }
        }

    }, [carpetDataStatus]);

    // API carpet
    async function callGetNewData() {
        if (filterValues === null || carpetStatus === 'Completed')
            getFlightListFilters();

        getFlightList(filterSelected, 0);

        setIsLoading(false);
        setIsError(false);

        // conto quante chiamate sto facendo
        // ogni chiamata avviene dopo un tot di tempo, quindi in base al numero di chiamate so quanto è passato
        let callTicks = carpetTime * searchCarpetParameter.flight.carpetPingMs;
        let finishTime = callTicks > searchCarpetParameter.flight.carpetMaxTimeMs
        setCarpetTime(carpetTime + 1);

        if (carpetStatus !== 'Completed' && !finishTime) {
            setIsLoadingFilter(true);

            let interval = setInterval(() => {
                getCarpetStatus();
            }, searchCarpetParameter.flight.carpetPingMs);
            addInterval(interval);

        } else {
            setIsLoadingFilter(false);
        }

    }

    async function getFlightList(filterSelectedUpdate, pageNumber) {
        setIsLoadingFilter(true);

        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetId,
            pageNumber: pageNumber,
            stops: filterSelectedUpdate.stops,
            baggages: filterSelectedUpdate.baggages,
            bestFlightTypes: filterSelectedUpdate.bestFlightTypes,
            flightNumber: filterSelectedUpdate.flightNumber,
            pageSize: searchCarpetParameter.flight.listMaxItem,
            showCost: showCost,
            providers: filterSelectedUpdate.suppliers,
            rangePrice: filterSelectedUpdate.rangePrice,
            orderBy: filterSelectedUpdate.orderBy,
            airportDepartures: filterSelectedUpdate.airportDepartures,
            airportArrivals: filterSelectedUpdate.airportArrivals,
            airlinesMkt: filterSelectedUpdate.mktCarriers,
            flightTimeInfo: filterSelectedUpdate.flightTimeInfo,
            excludeRestriction: excludePackageTO
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}flight/getflightlist`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setItems(response);
            renderPaginator(response.totalFlights);
        }

        if (carpetDataStatus === 'Completed') {
            setIsLoadingFilter(false);
        }

        setIsLoadingListResult(false);
    }

    async function getFlightListFilters() {
        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetId,
            excludeRestriction: excludePackageTO
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}flight/getflightlistfilters`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setFilterValues(response);

            if (carpetDataStatus === 'Completed' && response.rangePricesInfo) {
                setRangePriceInfo({
                    ...rangePriceInfo,
                    min: response.rangePricesInfo.min,
                    max: response.rangePricesInfo.max,
                    currency: response.rangePricesInfo.name
                });

            }
        }
    }

    async function getCarpetStatus() {
        console.log(`Call GetCarpetStatus [CarpetId = ${carpetId}]`);
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}carpet/getcarpetstatus/${carpetId}`, requestOption);

        if (!fetchedRes.ok) {
            setIsError(true);
        } else {
            const response = await fetchedRes.json();

            let dataStatus = response.dataStatus;
            let status = response.status;
            if (response.success) {
                setCarpetStatus(status);

                if (status !== 'Error') {
                    // messo per richiamare il getCarpetStatus, nel caso riceva piu volte lo status NoChanges.
                    // lo status Processing esiste solo lato FE
                    if (status !== carpetDataStatus)
                        setCarpetDataStatus(dataStatus);
                    else
                        setCarpetDataStatus('Processing');
                }


            } else {
                setIsError(true);
            }

            console.log(`Status ${status}`);
            console.log(`Data Status ${dataStatus}`);
        }

    }

    function addInterval(intervalId) {
        let arr = [...intervalCarpetPing];
        arr.push(intervalId);
        setIntervalCarpetPing(arr);
    }

    function clearCarpetAllInterval() {
        for (let i = 0; i < intervalCarpetPing.length; i++) {
            let intId = intervalCarpetPing[i];
            clearInterval(intId);
        }

        setIntervalCarpetPing([]);
    }

    // metodi filtro
    function callFilterValues(filterSelectedUpdate) {
        getFlightList(filterSelectedUpdate, 0);
    }

    function onChangeSortBy(event) {
        let orderByValue = event.target.selectedOptions[0].value;

        let filterSelectedUpdate = {
            ...filterSelected,
            orderBy: orderByValue
        };

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilter(selectedItems, type) {
        let filterSelectedUpdate = { ...filterSelected };

        switch (type) {
            case 'FlightBaggages': {
                filterSelectedUpdate.baggages = selectedItems;
                break;
            }
            case 'BestFlightTypes': {
                filterSelectedUpdate.bestFlightTypes = selectedItems;
                break;
            }
            case 'FlightSuppliers': {
                filterSelectedUpdate.suppliers = selectedItems;
                break;
            }
            case 'FlightStops': {
                filterSelectedUpdate.stops = selectedItems;
                break;
            }
            case 'FlightMkt': {
                filterSelectedUpdate.mktCarriers = selectedItems;
                break;
            }
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilterAirportDeparture(selectedItems, type) {
        let filterSelectedUpdate = {
            ...filterSelected,
            airportDepartures: selectedItems
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilterMultiDestinationTime(selectedItems, type) {
        let flightTimeInfo = { ...filterSelected.flightTimeInfo };

        if (type === 'FlightDestinationTime') {

            if (!flightTimeInfo || !flightTimeInfo.flightTimeLegCriterias)
                flightTimeInfo = { flightTimeLegCriterias: selectedItems };

            for (let i = 0; i < flightTimeInfo.flightTimeLegCriterias.length; i++) {
                flightTimeInfo.flightTimeLegCriterias[i].departureTimeFrom = selectedItems[i].departureTimeFrom;
                flightTimeInfo.flightTimeLegCriterias[i].departureTimeTo = selectedItems[i].departureTimeTo;
                flightTimeInfo.flightTimeLegCriterias[i].arrivalTimeFrom = selectedItems[i].arrivalTimeFrom;
                flightTimeInfo.flightTimeLegCriterias[i].arrivalTimeTo = selectedItems[i].arrivalTimeTo;
            }
        }
        else if (type === 'FlightStops') {
            if (!flightTimeInfo || !flightTimeInfo.flightTimeLegCriterias)
                flightTimeInfo = {flightTimeLegCriterias: Array.from({ length: filterValues.destinationNames.length }, () => ({}))};
            
            for (let i = 0; i < flightTimeInfo.flightTimeLegCriterias.length; i++) {

                if (!flightTimeInfo.flightTimeLegCriterias[i].departureTimeFrom)
                    flightTimeInfo.flightTimeLegCriterias[i].departureTimeFrom = '2099-01-01 00:00:00';

                if (!flightTimeInfo.flightTimeLegCriterias[i].departureTimeTo)
                    flightTimeInfo.flightTimeLegCriterias[i].departureTimeTo = '2099-01-01 23:59:59';

                if (!flightTimeInfo.flightTimeLegCriterias[i].arrivalTimeFrom)
                    flightTimeInfo.flightTimeLegCriterias[i].arrivalTimeFrom = '2099-01-01 00:00:00';

                if (!flightTimeInfo.flightTimeLegCriterias[i].arrivalTimeTo)
                    flightTimeInfo.flightTimeLegCriterias[i].arrivalTimeTo = '2099-01-01 23:59:59';

                // Convert hour ("22:00") to milliseconds values
                flightTimeInfo.flightTimeLegCriterias[i].stopsDurationFrom = convertHourInMilliseconds(selectedItems[i].minStopDuration);
                flightTimeInfo.flightTimeLegCriterias[i].stopsDurationTo = convertHourInMilliseconds(selectedItems[i].maxStopDuration);
            }
        }

        let filterSelectedUpdate = {
            ...filterSelected,
            flightTimeInfo: flightTimeInfo
        };

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilterAirportArrival(selectedItems, type) {
        let filterSelectedUpdate = {
            ...filterSelected,
            airportArrivals: selectedItems
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilterName(number) {
        let filterSelectedUpdate = {
            ...filterSelected,
            flightNumber: number.toUpperCase()
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilterPrice(rangePrice) {
        let filterSelectedUpdate = {
            ...filterSelected,
            rangePrice: rangePrice
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onClearFilter() {
        let filterSelectedUpdate = {
            ...filterSelected,
            rangePrice: null,
            providers: [],
            baggages: [],
            bestFlightTypes: [],
            stops: [],
            airportArrivals: [],
            airportDepartures: [],
            mktCarriers: [],
            flightTimeInfo: null
        }

        setIsResetSelectedItem(!isResetSelectedItem);
        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    // metodi paginator
    const handlePageClick = async e => {
        setPageSelected(e.selected);

        let filterSelectedUpdate = {
            ...filterSelected,
            pageNumber: e.selected + 1
        }

        setFilterSelected(filterSelectedUpdate);
        getFlightList(filterSelected, e.selected);
    };

    function renderPaginator(totalItems) {
        setPageCount(Math.ceil(totalItems / itemsPerPage));
    }

    function onChangeItemsListMode(mode) {
        setItemsListMode(mode);
    }

    async function onClickSearch(ids, filters = null) {
        let extraParam = '';
        /*if (filters && filters.length > 0) {
            for (let i = 0; i < filters.length; i++) {
                if (filters[0].type === 'MEALPLANID' && filters[0].value)
                    extraParam += '&sMealplanId=' + filters[0].value

            }
        }*/

        let param = {};
        param.clientFlowId = clientFlowId;
        param.carpetId = carpetId;
        param.itineraryId = ids.itineraryId;
        param.fareId = ids.fareId;

        let url = getBasicDetailUrl(configData.Settings.Products.Flight.IdTipoProdotto, param);
        if (extraParam)
            url += extraParam;

        if (url)
            window.open(url, '_blank');
    }

    const getProductName = (searchParams) => {
        if (searchParams.legsCriteria.length === 1 || searchParams.legsCriteria.length === 2)
            return null;

        let productName = "";

        for (let i = 0; i < searchParams.legsCriteria.length; i++) {
            let leg = searchParams.legsCriteria[i];
            productName += `${i + 1}. ${leg.departureLocation.code} - ${leg.arrivalLocation.code}`;

            if (i !== searchParams.legsCriteria.length - 1) {
                productName += "<br/>";
            }
        }

        return productName;
    }


    return (
        <>
            <div className="bg-bluesoft-100">
                {
                    configData.Settings.Website.EngineType === 'WITHNAVBAR' && <ProductNavBar moduleSelected="FLIGHT" />
                }
                {
                    configData.Settings.Website.ShowProductHeaderList && <div>
                        <ProductHeader text="" pageCode='Home_Gallery' />
                    </div>
                }
                <div className="container ">
                    <div className="row mt-4">
                        {/* Loading */}
                        {isLoading && <div className="col-12"><Loading textMsg={t('Product:Flight:LoadingList')} /></div>}

                        {/* Error */}
                        {!isLoading && isError && (!items || !items.flights || items.flights.length === 0) && <div className="col-12"><Error textMsg={t('Product:Flight:Error')} /></div>}

                        {/* Items */}
                        {!isLoading && items &&
                            <>
                                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3"></div>

                                <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                                    <div className="d-table h-100">
                                        <div className="d-table-row ">
                                            <div className="d-table-cell align-bottom pb-3 h5">
                                                <data m3lab="Product.Flight.NFlight">
                                                    {t(`Product:Flight:NFlight`)}:
                                                </data>
                                                <span className="ml-2">
                                                    <var>
                                                        {items.totalFlights}
                                                    </var>
                                                    {
                                                        isLoadingFilter && <div className="spinner-border spinner-border-sm text-primary ml-2" role="status"></div>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-1 text-right d-none d-sm-block">
                                    <div className="row">
                                        <div className="col-7 text-right pr-0">
                                            <span className="input-group mb-3 text-right">
                                                <select className="form-select border-0" aria-label="Disabled select example" value={filterSelected.orderBy} onChange={(e) => { onChangeSortBy(e); }}>
                                                    <option value="">
                                                        <data m3lab="General.Order.OrderBy">{t(`General:Order:OrderBy`)}</data>
                                                    </option>
                                                    {
                                                        searchCarpetParameter.flight.orderByValues.map((item, key) => {
                                                            return <option key={key} value={item.value}>
                                                                <data m3lab={item.label} >{t(item.label)}</data>
                                                            </option>
                                                        })
                                                    }
                                                </select>
                                                <button className="btn btn-neutral m-0 p-0 pr-1 d-inline" type="button" id="button-addon2">
                                                    <data m3ico="SortBy icon-15 text-bluesoft">
                                                        <M3Icon iconName="SortBy" externalClass="icon-15 text-bluesoft p-1" hasLabel={false} />
                                                    </data>
                                                </button>
                                            </span>
                                        </div>
                                        <div className="col-5 pl-0">
                                            <button className={(itemsListMode === 0 ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow d-none d-sm-inline"} onClick={(e) => { onChangeItemsListMode(0) }}>
                                                <data m3ico="ResultList icon-25">
                                                    <M3Icon iconName="ResultList" externalClass="icon-25" hasLabel={false} />
                                                </data>
                                            </button>
                                            <button className={(itemsListMode === 1 ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow d-none d-sm-inline"} onClick={(e) => { onChangeItemsListMode(1) }}>
                                                <data m3ico="ResultBloc icon-25">
                                                    <M3Icon iconName="ResultBloc" externalClass="icon-25" hasLabel={false} />
                                                </data>
                                            </button>
                                            {
                                                enableShowNetPrices && <button className={(showPriceBar ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow"} m3rool="f_ShowPriceBar" onClick={(e) => { onChangeShowPriceBar() }}>
                                                    <data m3lab="Button.Net">
                                                        {t("Button:Net")}
                                                    </data>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Colonna Filtri */}
                                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mt-1">

                                    {
                                        filterValues && filterValues.flightSearchParameter && <>

                                            <ListSearchEngineCV
                                                imageUrl="https://cdn.travelplace.ch/travelplace40/img/flight_thum.gif"
                                                searchParameters={filterValues.flightSearchParameter}
                                                productType={configData.Settings.Products.Flight.IdTipoProdotto}
                                                typeSearch="SRC"
                                                extraParams={{ productName: getProductName(filterValues.flightSearchParameter)}}
                                            />
                                            <ListSearchEngineCVMobile
                                                imageUrl="https://cdn.travelplace.ch/travelplace40/img/flight_thum.gif"
                                                searchParameters={filterValues.flightSearchParameter}
                                                productType={configData.Settings.Products.Flight.IdTipoProdotto}
                                                typeSearch="SRC"
                                            />

                                        </>
                                    }
                                    <div className="pt-1 text-right d-block d-sm-none">
                                        <div className="d-flex mb-4 align-items-center" style={{ gap: "1rem" }}>
                                            <div className="col-7 text-right pr-0 pl-0">
                                                <span className="input-group text-right">
                                                    <select className="form-select border-0" aria-label="Disabled select example" value={filterSelected.orderBy} onChange={(e) => { onChangeSortBy(e); }}>
                                                        <option value="">
                                                            <data m3lab="General.Order.OrderBy">{t(`General:Order:OrderBy`)}</data>
                                                        </option>
                                                        {
                                                            searchCarpetParameter.flight.orderByValues.map((item, key) => {
                                                                return <option key={key} value={item.value}>
                                                                    <data m3lab={item.label} >{t(item.label)}</data>
                                                                </option>
                                                            })
                                                        }
                                                    </select>
                                                    
                                                </span>
                                            </div>
                                            <div className="">
                                                <button className={(itemsListMode === 0 ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow d-none d-sm-inline"} onClick={(e) => { onChangeItemsListMode(0) }}>
                                                    <data m3ico="ResultList icon-25">
                                                        <M3Icon iconName="ResultList" externalClass="icon-25" hasLabel={false} />
                                                    </data>
                                                </button>
                                                <button className={(itemsListMode === 1 ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow d-none d-sm-inline"} onClick={(e) => { onChangeItemsListMode(1) }}>
                                                    <data m3ico="ResultBloc icon-25">
                                                        <M3Icon iconName="ResultBloc" externalClass="icon-25" hasLabel={false} />
                                                    </data>
                                                </button>
                                                {
                                                    enableShowNetPrices && <button className={(showPriceBar ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow"} m3rool="f_ShowPriceBar" onClick={(e) => { onChangeShowPriceBar() }}>
                                                        <data m3lab="Button.Net">
                                                            {t("Button:Net")}
                                                        </data>
                                                    </button>
                                                }                                                
                                            </div>
                                            <button data-bs-toggle="modal" data-bs-target="#filterModal" className="openFilterButton p-1 px-2 pt-2 rounded border-0 shadow tp-btn-search">
                                                {t(`General:OpenFilter`)}
                                            </button>
                                        </div>
                                    </div>
                                    {
                                        filterValues && <div className='d-none d-sm-block'>

                                            { /* Codice */}
                                            <ProductFilterByName
                                                items={filterValues.flightNumbers}
                                                label={t('SearchEngine:CodeFlight')}
                                                type="FlightNumber"
                                                onApplyFilter={onApplyFilterName}
                                                resetSelectedItem={isResetSelectedItem} />

                                            <button className="btn btn-sm btn-block tp-btn-search animate-up-2 mt-4" onClick={(e) => onClearFilter(e)}>
                                                <data m3lab="Button.FilterRemove">{t('Button:FilterRemove')}</data>
                                            </button>

                                            { /* Price */}
                                            <ProductFilterByPrice
                                                rangePriceInfo={rangePriceInfo}
                                                onApplyFilter={onApplyFilterPrice}
                                                resetSelectedItem={isResetSelectedItem} />

                                            { /* BestFlightTypes */}
                                            <ProductFilter
                                                items={filterValues.bestFlightTypes}
                                                label="Product:Flight:Filter:Generic:Title"
                                                type="BestFlightTypes"
                                                iconTitle="luggage"
                                                onApplyFilter={onApplyFilter}
                                                resetSelectedItem={isResetSelectedItem} />

                                            { /* Bagagli */}
                                            <ProductFilter
                                                items={filterValues.baggages}
                                                label="Product:Flight:Filter:Baggages"
                                                type="FlightBaggages"
                                                iconTitle="luggage"
                                                onApplyFilter={onApplyFilter}
                                                resetSelectedItem={isResetSelectedItem} />

                                            { /* Scali */}
                                            <ProductFilter
                                                items={filterValues.stops}
                                                label="Product:Flight:Filter:Stops"
                                                type="FlightStops"
                                                iconTitle="connecting_airports"
                                                onApplyFilter={onApplyFilter}
                                                resetSelectedItem={isResetSelectedItem}
                                                subFilterOptions={{ subItems: filterValues.destinationNames, onApplyFilter: onApplyFilterMultiDestinationTime }} />

                                            { /* Aeroporti */}
                                            <ProductDestinationFilter
                                                departureAirports={filterValues.departureAirports}
                                                arrivalAirports={filterValues.arrivalAirports}
                                                label="Product:Flight:Filter:Airports"
                                                iconTitle="flight"
                                                type=""
                                                onApplyFilterAirportDeparture={onApplyFilterAirportDeparture}
                                                onApplyFilterAirportArrival={onApplyFilterAirportArrival}
                                                resetSelectedItem={isResetSelectedItem} />

                                            { /* Orari Partenze arrivi */}
                                            <ProductFilterMultiDestinationTime
                                                destinationNames={filterValues.destinationNames}
                                                type="FlightDestinationTime"
                                                label="Product:Flight:Filter:Hours"
                                                iconTitle="Time"
                                                onApplyFilter={onApplyFilterMultiDestinationTime}
                                                resetSelectedItem={isResetSelectedItem} />

                                            { /* Compagnia aerea */}
                                            <ProductFilter
                                                items={filterValues.mktCarriers}
                                                label="Product:Flight:Filter:FlightCompany"
                                                type="FlightMkt"
                                                iconTitle="airlines"
                                                onApplyFilter={onApplyFilter}
                                                resetSelectedItem={isResetSelectedItem} />

                                            { /* GDS */}
                                            <ProductFilter
                                                items={filterValues.suppliers}
                                                label="Product:Flight:Filter:GDS"
                                                type="FlightSuppliers"
                                                iconTitle="shop_two"
                                                onApplyFilter={onApplyFilter}
                                                resetSelectedItem={isResetSelectedItem} />
                                        </div>
                                    }
                                </div>

                                { /* Colonna Items */}
                                <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 pt-1">
                                    {
                                        !isLoadingListResult && items && items.flights && items.flights.length > 0 && itemsListMode === 0 && items.flights.map((item, key) => {

                                            return <><div className="d-none d-sm-block">

                                                <FlightItemList key={key}
                                                    item={item}
                                                    showPriceBar={showPriceBar}
                                                    showCost={showCost}
                                                    extraParam={extraParam}
                                                    modalOpenProductId={setModalOpenFlightFare}
                                                    modalOpenFareDetail={setModalOpenFareDetail }
                                                    onClickSearch={onClickSearch}
                                                />
                                            </div>
                                                <div className="d-block d-sm-none" onClick={() => onClickSearch({ itineraryId: item.uniqueId, fareId: item.fares && item.fares[0] && item.fares[0].uniqueId })}>
                                                    <FlightItemList key={key}
                                                        item={item}
                                                        showPriceBar={showPriceBar}
                                                        showCost={showCost}
                                                        extraParam={extraParam}
                                                        modalOpenProductId={setModalOpenFlightFare}
                                                        modalOpenFareDetail={setModalOpenFareDetail}
                                                        onClickSearch={onClickSearch}
                                                    />
                                                </div>
                                            </>
                                        })
                                    }
                                    {
                                        !isLoadingListResult && items && items.flights && items.flights.length > 0 && itemsListMode === 1 && <div className="row">
                                            {
                                                items.flights.map((item, key) => {
                                                    return <FlightItemListBlock key={key}
                                                        item={item}
                                                        showPriceBar={showPriceBar}
                                                        showCost={showCost}
                                                        extraParam={extraParam}
                                                        modalOpenProductId={setModalOpenFlightFare}
                                                        onClickSearch={onClickSearch}
                                                    />
                                                })
                                            }
                                        </div>
                                    }
                                    {
                                        isLoadingListResult && <div className="text-center"><Loading textMsg={t('Product:Flight:LoadingList')} /></div>
                                    }
                                    {
                                        !isLoadingListResult && (!items || (!items.flights || items.flights.length === 0)) && <ProductItemNotFound label="Nessun volo trovato" />
                                    }
                                </div>

                                {
                                    modalOpenFlightFare && <FlightModal flightFare={modalOpenFlightFare} modalCloseProductId={setModalOpenFlightFare} />
                                }
                                {
                                    modalOpenFareDetail && <FlightModalFareDetail flightFare={modalOpenFareDetail} modalCloseProductId={setModalOpenFareDetail} />
                                }

                                { /* Paginator */}
                                {
                                    items && items.flights && items.flights.length > 0 && <>
                                        <div className="col-12 col-sm-3 col-md-3 col-lg-39 col-xl-3 pt-1"></div>
                                        <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 pt-1">
                                            <nav aria-label="Page navigation comments" className="mt-4">
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t('pagination:nextLabel')}
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel={t('pagination:previousLabel')}
                                                    renderOnZeroPageCount={null}
                                                    forcePage={pageSelected}

                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    marginPagesDisplayed={2}
                                                    containerClassName="pagination justify-content-center flex-wrap"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    activeClassName="active"
                                                />
                                            </nav>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="modal tp-search-input fade" id="filterModal" tabIndex="-1" aria-labelledby="EngineModifyActivty" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen-xxl-down">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <div className="h4 text-center w-100 mt-2">
                                {t('SearchEngine:UpdateSearch')}
                            </div>
                            <button type="button" className="btn-close mt-2 pr-4" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body bg-gray-300">
                            {
                                filterValues && <>

                                    { /* Codice */}
                                    <ProductFilterByName
                                        items={filterValues.flightNumbers}
                                        label="codice volo"
                                        type="FlightNumber"
                                        onApplyFilter={onApplyFilterName}
                                        resetSelectedItem={isResetSelectedItem} />

                                    <button className="btn btn-sm btn-block tp-btn-search animate-up-2 mt-4" onClick={(e) => onClearFilter(e)}>
                                        <data m3lab="Button.FilterRemove">{t('Button:FilterRemove')}</data>
                                    </button>

                                    { /* Price */}
                                    <ProductFilterByPrice
                                        rangePriceInfo={rangePriceInfo}
                                        onApplyFilter={onApplyFilterPrice}
                                        resetSelectedItem={isResetSelectedItem} />

                                    { /* BestFlightTypes */}
                                    <ProductFilter
                                        items={filterValues.bestFlightTypes}
                                        label="Product:Flight:Filter:Generic:Title"
                                        type="BestFlightTypes"
                                        iconTitle="luggage"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem} />

                                    { /* Bagagli */}
                                    <ProductFilter
                                        items={filterValues.baggages}
                                        label="Product:Flight:Filter:Baggages"
                                        type="FlightBaggages"
                                        iconTitle="luggage"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem} />

                                    { /* Scali */}
                                    <ProductFilter
                                        items={filterValues.stops}
                                        label="Product:Flight:Filter:Stops"
                                        type="FlightStops"
                                        iconTitle="connecting_airports"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem}
                                        subFilterOptions={{ subItems: filterValues.destinationNames, onApplyFilter: onApplyFilterMultiDestinationTime }} />

                                    { /* Aeroporti */}
                                    <ProductDestinationFilter
                                        departureAirports={filterValues.departureAirports}
                                        arrivalAirports={filterValues.arrivalAirports}
                                        label="Product:Flight:Filter:Airports"
                                        iconTitle="flight"
                                        type=""
                                        onApplyFilterAirportDeparture={onApplyFilterAirportDeparture}
                                        onApplyFilterAirportArrival={onApplyFilterAirportArrival}
                                        resetSelectedItem={isResetSelectedItem} />

                                    { /* Orari Partenze arrivi */}
                                    <ProductFilterMultiDestinationTime
                                        destinationNames={filterValues.destinationNames}
                                        type="FlightDestinationTime"
                                        label="Product:Flight:Filter:Hours"
                                        iconTitle="Time"
                                        onApplyFilter={onApplyFilterMultiDestinationTime}
                                        resetSelectedItem={isResetSelectedItem} />

                                    { /* Compagnia aerea */}
                                    <ProductFilter
                                        items={filterValues.mktCarriers}
                                        label="Product:Flight:Filter:FlightCompany"
                                        type="FlightMkt"
                                        iconTitle="airlines"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem} />

                                    { /* GDS */}
                                    <ProductFilter
                                        items={filterValues.suppliers}
                                        label="Product:Flight:Filter:GDS"
                                        type="FlightSuppliers"
                                        iconTitle="shop_two"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem} />
                                </>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}