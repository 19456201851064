import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDisplayPrice, getMealPlanLabelExtended, getDisplayPriceLabel, formatDateTime } from '../../../../js/Utils.js';
import Cookies from 'universal-cookie';
import { ProductAllPrice } from './ProductAllPrice';
import { ProductNotAllowedAddToCart } from '../ProductNotAllowedAddToCart';

export const ProductPrice = ({ productId, pricing, supplier, showPriceBar, showCost, isPackage, isPackageTO, isPromotion, otherParam, typePrice, onClickSearch, templateInfo, isFromTour, showPriceTrippie, isTrippie, removeTrippie, updateTrippie, startDate, endDate }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const [cultureName] = useState(cookies.get("CultureName"));

    function goToDetail() {
        if (productId)
            onClickSearch(productId, supplier);
        else
            onClickSearch();
    }

    function showPrice() {
        if (templateInfo)
            return !templateInfo.isPrimary;

        if (isTrippie)
            return showPriceTrippie;

        return !isFromTour;
    }

    return (
        <>
            {
                pricing &&
                <div className="text-right float-end">
                    <div className="d-flex d-sm-block justify-content-center align-items-center gap-2">
                        {
                            isTrippie && <div className="mb-1" style={{ fontSize: '10px' }}>
                                {
                                    startDate && <span>
                                        {endDate ? t('MyBook:From') : t('MyBook:Date')} {formatDateTime(startDate, cultureName, { noYear: true })}
                                    </span>
                                }
                                {endDate && <span> {t('MyBook:To')} {formatDateTime(endDate, cultureName, { noYear: true })}</span>}
                            </div>
                        }
                        {typePrice === 'NTPax' && !isTrippie && !templateInfo && <label className="w-100"><data m3lab="General.NTPax"> {t(`General:NTPax`)}:</data></label>}
                        {typePrice === 'XPax' && !isTrippie && !templateInfo && <label className="w-100"><data m3lab="General.XPax"> {t(`General:XPax`)}:</data></label>}
                        {typePrice === 'XCabin' && !isTrippie && !templateInfo && <label className="w-100"><data m3lab="General.XPax"> {t(`General:XCabin`)}:</data></label>}
                        {typePrice === 'TotPrice' && !isTrippie && !templateInfo && <label className="w-100"><data m3lab="General.TotPrice"> {t(`General:TotPrice`)}:</data></label>}
                        {showPrice() && <span className="h3"><var>{getDisplayPrice(pricing, cultureName, showCost, templateInfo)}</var></span>}
                        {showPrice() && <div className="h085"><data m3lab="General.Commission">{t(getDisplayPriceLabel(showCost, pricing.isCommisionable))}</data></div>}
                        {
                            otherParam && otherParam.mealPlanId && <span className="text-dark h075 small d-block">
                                <var>{t(getMealPlanLabelExtended(otherParam.mealPlanId))}</var>
                            </span>
                        }
                        <div className='d-none d-sm-block'>
                            <ProductAllPrice showPriceBar={showPriceBar} showCost={showCost} pricing={pricing} />
                        </div>
                        {supplier && <span className="text-warning small d-block"><var>{supplier.supplierName}</var></span>}
                        {isPackage && <div className="d-inline-flex bg-warning text-white small w-auto px-2 py-1 mt-1 rounded"><var>Package</var></div>}
                        {isPackageTO && <div className="d-inline-flex bg-warning text-white small w-auto px-2 py-1 mt-1 rounded"><var>Package TO</var></div>}
                        {isPromotion && <div className="d-inline-flex bg-warning text-white small w-auto px-2 py-1 mt-1 rounded"><var>Promotion</var></div>}
                    </div>
                    <div className='d-flex d-sm-none align-items-center gap-2'>

                        {
                            <div className="d-none d-sm-none d-md-block"><data m3lab="General.Commission">{t(getDisplayPriceLabel(showCost, pricing.isCommisionable, 'General:Net'))}</data></div>
                        }
                        <ProductAllPrice showPriceBar={showPriceBar} showCost={showCost} pricing={pricing} />
                    </div>
                    <div className="position-innerit h-100">
                        {
                            !isTrippie && (!templateInfo || templateInfo.showSelectButton) &&
                            <div className="d-none d-sm-block position-absolute bottom-0 end-0  pr-3">
                                <button className={"btn btn-sm mb-1 " + ((!otherParam || !otherParam.buttonClassName) ? "tp-btn-select" : otherParam.buttonClassName)} onClick={(e) => goToDetail()}>
                                    <data m3lab="Button.Detail">
                                        {
                                            otherParam && otherParam.buttonLabel && <span dangerouslySetInnerHTML={{ __html: otherParam.buttonEnabled && t(otherParam.buttonLabel) }}></span>
                                        }
                                        {(!otherParam || !otherParam.buttonLabel) && otherParam.buttonEnabled && !templateInfo && t(`Button:Detail`)}
                                        {(!otherParam || !otherParam.buttonLabel) && otherParam.buttonEnabled && (templateInfo && templateInfo.showSelectButton) && t(`Button:Select`)}
                                        {
                                            otherParam && <ProductNotAllowedAddToCart enableCanAddToCart={otherParam.buttonEnabled} />
                                        }
                                    </data>
                                </button>
                            </div>
                        }
                        {
                            !isTrippie && (!templateInfo || templateInfo.showSelectButton) &&
                            <div className="d-block d-sm-none">
                                <button className={"btn btn-sm mb-1 " + ((!otherParam || !otherParam.buttonClassName) ? "tp-btn-select" : otherParam.buttonClassName)} onClick={(e) => goToDetail()}>
                                    <data m3lab="Button.Detail">
                                        {
                                            otherParam && otherParam.buttonLabel && t(otherParam.buttonLabel)
                                        }
                                        {(!otherParam || !otherParam.buttonLabel) && otherParam.buttonEnabled && !templateInfo && t(`Button:Detail`)}
                                        {(!otherParam || !otherParam.buttonLabel) && otherParam.buttonEnabled && (templateInfo && templateInfo.showSelectButton) && t(`Button:Select`)}
                                        {
                                            otherParam && <ProductNotAllowedAddToCart enableCanAddToCart={otherParam.buttonEnabled} />
                                        }
                                    </data>
                                </button>
                            </div>
                        }
                        {
                            isTrippie && <div className="d-sm-block position-absolute bottom-0 end-0  pr-3">
                                <button className="btn btn-sm mb-1 tp-btn-select" onClick={(e) => updateTrippie()}>
                                    <data m3lab="Button.Remove">
                                        {t(`Button:Update`)}
                                    </data>
                                </button>
                                <button className="btn btn-sm mb-1 tp-btn-delete" onClick={(e) => removeTrippie()}>
                                    <data m3lab="Button.Remove">
                                        {t(`Button:Remove`)}
                                    </data>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
}