import React, { useEffect, useState, useCallback } from 'react';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';

export const HotelMealPlanFilter = ({ mealplansDefault, handleMealPlanFilter, isReset }) => {
    const { t } = useTranslation();
    const [mealplanArr, setMealplanArr] = useState([]);
    const buttonClass = " btn btn-sm w-100 p-1 btnMealplan ";

    useEffect(() => {
        if (mealplansDefault) {
            setMealplanArr([mealplansDefault]);
            const allWithClass = Array.from(
                document.querySelectorAll('button.btnMealplan')
            );

            allWithClass.forEach(element => {
                if (element.value === mealplansDefault)
                    element.className = 'tp-btn-select ' + buttonClass;
            });
        }
    }, [mealplansDefault]);

    useEffect(() => {
        if (isReset) {
            setMealplanArr([]);
            setClassReset();
        }
    }, [isReset]);

    const handleInputClick = useCallback(event => {
        if (event.currentTarget.className.includes("bg-white"))
            event.currentTarget.className = 'tp-btn-select ' + buttonClass;
        else
            event.currentTarget.className = 'bg-white ' + buttonClass;

        let _mealplanArr = [...mealplanArr ];
        let arrTmp = mealplanArr.find(x => x === event.currentTarget.value);
        
        if (!arrTmp) {
            _mealplanArr.push(event.currentTarget.value);
        }
        else {
           _mealplanArr = _mealplanArr.filter(x => x !== event.currentTarget.value)
        }
        setMealplanArr(_mealplanArr);

        handleMealPlanFilter(_mealplanArr);
    }, [handleMealPlanFilter]);

    function setClassReset() {
        const allWithClass = Array.from(
            document.querySelectorAll('button.btnMealplan')
        );

        allWithClass.forEach(element => {
            element.className = 'bg-white ' + buttonClass;
        });
    }


    return (
        <div className="mt-4 bg-gray-300 rounded p-1 shadow">
            <h6 className="p-1 m-0">
                <data m3lab="Product.Structure.Filter.MealPlan.Title">{t(`Product:Structure:Filter:MealPlan:Title`)}:</data>
            </h6>

            <div>
                <div className="d-table w-100">
                    <div className="d-table-row">
                        <div className="d-table-cell w-50 p-1">
                            <button value={1} className={"bg-white" + buttonClass} onClick={e => handleInputClick(e)}>
                                <data m3lab="Product.Structure.Filter.MealPlan.TextOB">{t(`Product:Structure:Filter:MealPlan:TextOB`)}</data>
                            </button>
                        </div>
                        <div className="d-table-cell w-50 p-1">
                            <button value={2} className={"bg-white" + buttonClass} onClick={e => handleInputClick(e)}>
                                <data m3lab="Product.Structure.Filter.MealPlan.TextBB">{t(`Product:Structure:Filter:MealPlan:TextBB`)}</data>
                            </button>
                        </div>
                    </div>
                    <div className="d-table-row">
                        <div className="d-table-cell w-50 p-1">
                            <button value={3} className={"bg-white" + buttonClass} onClick={e => handleInputClick(e)}>
                                <data m3lab="Product.Structure.Filter.MealPlan.TextHB">{t(`Product:Structure:Filter:MealPlan:TextHB`)}</data>
                            </button>
                        </div>
                        <div className="d-table-cell w-50 p-1">
                            <button value={4} className={buttonClass + "bg-white" } onClick={e => handleInputClick(e)}>
                                <data m3lab="Product.Structure.Filter.MealPlan.TextFB">{t(`Product:Structure:Filter:MealPlan:TextFB`)}</data>
                            </button>
                        </div>
                    </div>
                    <div className="d-table-row">
                        <div className="d-table-cell w-50 p-1">
                            <button value={5}  className={buttonClass + "bg-white" } onClick={e => handleInputClick(e)}>
                                <data m3lab="Product.Structure.Filter.MealPlan.TextAI">{t(`Product:Structure:Filter:MealPlan:TextAI`)}</data>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}