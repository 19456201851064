import React, { useState, useEffect } from 'react';
import configData from "../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../Common/M3Icon";
import { InputSearchDestinationContainer } from '../../Common/Destination/InputSearchDestinationContainer';
import { InputPaxesCompositionContainer } from '../../Common/Paxes/InputPaxesCompositionContainer';
import { InputCalendarContainer } from '../../Common/Calendar/InputCalendarContainer';
import { SelectCitizenship } from '../../Common/SelectCitizenship';
import { compareDates, getAuthToken, getCurrentUserFromJwtToken } from '../../../js/Utils.js';
import { getTrainListLink, generateClientFlowId, callGetLastItemsSearch, callGetGetProductRules, getTrainCarpetId } from '../../../js/ProductService.js';

export const TrainSearchEngine = ({ searchParameters, modeView, resetValues, extraParams, isFake, isTrippie, onSearchTrippie }) => {
    const { t } = useTranslation();

    const [lastSearchItems, setLastSearchItems] = useState([]);
    const [defaultCitizenshipId, setDefaultCitizenshipId] = useState(null);
    const [isStartSearch, setIsStartSearch] = useState(false);
    const [isValidComposition, setIsValidComposition] = useState(true);

    const [textColorClass, setTextColorClass] = useState('text-white');

    const [selectCitizenship, setSelectCitizenship] = useState(null);
    const [selectDestinations, setSelectDestinations] = useState([{}]);
    const [selectComposition, setSelectComposition] = useState(null);
    const [selectDates, setSelectDates] = useState(null);
    const [selectTimes, setSelectTimes] = useState(null);
    const [isValidForm, setIsValidForm] = useState({
        isValidDestination: false,
        isValidDates: false,
        isValidComposition: false,
        isAllFieldValid: false
    });

    // tipo train informazioni
    const [trainType, setTrainType] = useState(2);
    const [filteringOptions, setFilteringOptions] = useState([]);
    const [extraOptionsCalendarOutbound, setExtraOptionsCalendarOutbound] = useState({ multiDate: false, numberOfMonths: 1, showTime: true, label: 'SearchEngine:DateFrom', labelPlaceHolder: 'SearchEngine:SingleDate' });
    const [extraOptionsCalendarReturn] = useState({ multiDate: false, numberOfMonths: 1, showTime: true, label: 'SearchEngine:DateTo', labelPlaceHolder: 'SearchEngine:SingleDate' });
    const [validDateRule, setValidDateRule] = useState(extraParams && extraParams.validDateRule ? extraParams.validDateRule : {});
    const [showTrainCardPanel, setShowTrainCardPanel] = useState(false);
    const [numberCards, setNumberCards] = useState([]);

    // gestisco la visibilità
    const [showDestination, setShowDestination] = useState([{ dep: false, arr: false }]);
    const [showPaxes, setShowPaxes] = useState(false);
    const [showCalendarOutbound, setShowCalendarOutbound] = useState(false);
    const [showCalendarReturn, setShowCalendarReturn] = useState(false);
    const [enableFlightSearchAdvanced, setEnableFlightSearchAdvanced] = useState(true);

    useEffect(() => {
        const getLastSearchItems = async () => {
            const response = await callGetLastItemsSearch(110);
            setLastSearchItems(response);
        }

        const getRules = async () => {
            const response = await callGetGetProductRules(110);

            if (response) {
                try {
                    setIsValidComposition(response['ADDTOCART'].allowed);
                } catch (ex) {
                    console.error(ex);
                }
            }
        }

        const getFilteringOptions = async () => {
            await callGetFilteringOptions();
        }

        getLastSearchItems();
        getRules();
        getFilteringOptions();

        let token = getAuthToken();
        let currentUser = getCurrentUserFromJwtToken(token);
        if (currentUser && currentUser.roles.includes("Engine_NoFidelityCardTrain"))
            setEnableFlightSearchAdvanced(false);

        if (modeView === 'MODAL')
            setTextColorClass('text-dark');
        else
            setTextColorClass('text-white');
    }, []);

    useEffect(() => {
        setShowPaxes(false);
        setValuesHowDefault();
    }, [searchParameters, resetValues]);

    useEffect(() => {
        let isValidFormUpdate = {
            isValidDestinationMap: [],

            isValidDestination: false,
            isValidDates: false,
            isValidTimes: false,
            isValidComposition: false,

            isValidCardsNumber: false,

            isAllFieldValid: false
        };


        // verifico che tutte le destinazioni siano compilate
        let isValidDestinationMap = [{ dep: false, arr: false }];
        let isValidDestination = false;
        if (selectDestinations) {
            isValidDestinationMap = Object.values(selectDestinations)
                .map(x => (
                    {
                        dep: x && x.dep !== null && x.dep !== undefined && x.dep.Id,
                        arr: x && x.arr !== null && x.arr !== undefined && x.arr.Id,
                    })
                );
            isValidDestination = Object.values(isValidDestinationMap).every(x => x && x.dep && x.arr);
        }

        isValidFormUpdate.isValidDestinationMap = isValidDestinationMap;
        isValidFormUpdate.isValidDestination = isValidDestination;

        // verifico che tutte le date siano compilate
        let isValidDates = false;
        if (selectDates) {
            if (trainType === 2)
                isValidDates = Object.values(selectDates).every(x => x);
            else
                isValidDates = selectDates.dateFrom !== null && selectDates.dateFrom !== undefined;
        }

        isValidFormUpdate.isValidDates = isValidDates;

        if (selectComposition)
            isValidFormUpdate.isValidComposition = true;

        isValidFormUpdate.isValidCardsNumber = true;

        isValidFormUpdate.isAllFieldValid = isValidFormUpdate.isValidDates && isValidFormUpdate.isValidComposition && isValidFormUpdate.isValidDestination && isValidFormUpdate.isValidCardsNumber;
        setIsValidForm(isValidFormUpdate);

    }, [selectDestinations, selectDates, selectComposition, numberCards, showTrainCardPanel]);


    function setValuesHowDefault() {

        if (searchParameters) {
            let outboundLegCriteria = searchParameters.outboundLegCriteria;
            let returnLegCriteria = searchParameters.returnLegCriteria;
            let updateDepartureStation = {
                Text: outboundLegCriteria.departureLocation.stationDesc,
                Id: outboundLegCriteria.departureLocation.stationId,
                Type: outboundLegCriteria.departureLocation.stationType
            };

            let updateArrivalStation = {
                Text: outboundLegCriteria.arrivalLocation.stationDesc,
                Id: outboundLegCriteria.arrivalLocation.stationId,
                Type: outboundLegCriteria.departureLocation.stationType
            }

            setSelectDestinations([{ dep: updateDepartureStation, arr: updateArrivalStation }]);

            let updateCalendar = {
                dateFrom: outboundLegCriteria.departureDate,
                dateTo: null
            };

            let updateTimes = {
                timeFrom: outboundLegCriteria.departureTimeSlot.fromTime,
                timeTo: null
            };

            if (returnLegCriteria) {
                updateCalendar.dateTo = returnLegCriteria.departureDate;
                updateTimes.timeTo = returnLegCriteria.departureTimeSlot.fromTime;
                setTrainType(2);

                setExtraOptionsCalendarOutbound({ ...extraOptionsCalendarOutbound, multiDate: true, numberOfMonths: 1, label: 'SearchEngine:DateTo', labelPlaceHolder: 'SearchEngine:Date' });
            } else {
                setTrainType(1);
                setExtraOptionsCalendarOutbound({ ...extraOptionsCalendarOutbound, multiDate: false, numberOfMonths: 1, label: 'SearchEngine:Date', labelPlaceHolder: 'SearchEngine:Date' });
            }

            setSelectDates(updateCalendar);
            setSelectTimes(updateTimes);

            let updateTravelers = [];

            let adults = searchParameters.travelers.filter(x => !(x.passengerTypeCodes && x.passengerTypeCodes.length == 0) || x.passengerTypeCodes.includes('ADT'));
            let children = searchParameters.travelers.filter(x => x.passengerTypeCodes && x.passengerTypeCodes.length > 0 && x.passengerTypeCodes.includes('CHD')).length;
            let childAges = searchParameters.travelers.filter(x => x.age || x.age === 0).map(x => x.age);

            updateTravelers.push({
                adults: adults.length,
                children: children,
                childAges: childAges
            });

            if (adults && adults.every(x => x.loyaltyCode)) {
                setShowTrainCardPanel(true);
                setNumberCards(adults.map(x => x.loyaltyCode));
            } else {
                setShowTrainCardPanel(false);
                setNumberCards([...Array(adults)]);
            }

            setSelectComposition(updateTravelers);

            setDefaultCitizenshipId(searchParameters.citizenship);

        }
        else {
            setTrainType(2);
            setExtraOptionsCalendarOutbound({ ...extraOptionsCalendarOutbound, multiDate: false, numberOfMonths: 1, label: 'SearchEngine:DateFrom' });
        }
    }

    async function onSearchButton() {
        setIsStartSearch(true);

        try {
            let newSP = {};

            if (searchParameters) {
                newSP = searchParameters;
            } else {
                newSP = {
                    filteringOptions: [],
                    outboundFareArgs: null,
                    outboundLegCriteria: null,
                    returnLegCriteria: null,
                    travelers: []
                };
            }

            newSP.directionData = 1;
            newSP.filteringOptions = filteringOptions;

            let timeFrom = selectTimes.timeFrom;
            if (timeFrom.length < 5)
                timeFrom = "0" + timeFrom;

            newSP.outboundLegCriteria = {
                arrivalLocation: {
                    stationId: selectDestinations[0].arr.Id,
                    stationDesc: selectDestinations[0].arr.Text,
                    stationType: selectDestinations[0].arr.Type
                },
                departureDate: selectDates.dateFrom,
                departureLocation: {
                    stationId: selectDestinations[0].dep.Id,
                    stationDesc: selectDestinations[0].dep.Text,
                    stationType: selectDestinations[0].dep.Type
                },
                departureTimeSlot: {
                    fromTime: timeFrom,
                    toTime: null
                }
            }

            if (trainType === 2) {
                let returnLegCriteria = { ...newSP.outboundLegCriteria };

                returnLegCriteria.arrivalLocation = { ...newSP.outboundLegCriteria.departureLocation };
                returnLegCriteria.departureLocation = { ...newSP.outboundLegCriteria.arrivalLocation };

                returnLegCriteria.departureDate = selectDates.dateTo;

                let timeTo = selectTimes.timeTo;
                if (timeTo.length < 5)
                    timeTo = "0" + timeTo;

                returnLegCriteria.departureTimeSlot = {
                    fromTime: timeTo,
                    toTime: null
                };

                newSP.returnLegCriteria = returnLegCriteria;
            }


            let travelers = [];
            let trvIndex = 0;
            for (let i = 0; i < selectComposition.length; i++) {
                let composition = selectComposition[i];

                for (let j = 0; j < composition.adults; j++) {
                    trvIndex++;

                    let trv = {
                        index: trvIndex
                    };

                    if (showTrainCardPanel && numberCards && numberCards > 0) {
                        trv.loyaltyCode = numberCards[j];
                    }

                    travelers.push(trv);
                }

                for (let j = 0; j < composition.childAges.length; j++) {
                    let age = composition.childAges[j];
                    trvIndex++;
                    let type = 'CHD';
                    let paxType = 'Child';

                    travelers.push({
                        index: trvIndex,
                        age: age,
                        passengerTypeCodes: [type],
                        paxType: paxType
                    });

                }

            }
            newSP.travelers = travelers;

            if (selectCitizenship)
                newSP.citizenship = selectCitizenship.isoId;

            if (isTrippie) {
                let clientFlowId = generateClientFlowId();
                let carpetId = await getTrainCarpetId(newSP, clientFlowId);

                onSearchTrippie(extraParams.stepIndex, clientFlowId, carpetId, newSP, configData.Settings.Products.Train.IdTipoProdotto);
                setIsStartSearch(false);
                return;
            }

            let clientFlowId = generateClientFlowId();
            let url = await getTrainListLink(newSP, clientFlowId);
            window.open(url, '_self');

        } catch (ex) {
            console.error(ex);
        }

        setIsStartSearch(false);

    }

    async function callGetFilteringOptions() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'train/gettrainoffertrules', requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response.success) {
                setFilteringOptions(response.filteringOptions);
            }

        } else {
            console.log("Error get LastItemsSearch");
        }
    }

    // funzioni destinazione
    function onSelectSearchDestination(newSelectDestination, externalId) {
        let externalIdParts = externalId.split('_');
        let indexToUpdate = parseInt(externalIdParts[1]);
        let updateSelectDestinations = [...selectDestinations];
        let isDeparture = externalIdParts[0].includes('departure');

        if (isDeparture) {
            updateSelectDestinations[indexToUpdate].dep = newSelectDestination;
        }
        else {
            updateSelectDestinations[indexToUpdate].arr = newSelectDestination;
        }

        // se sono nel caso di un multitratta compilo i leg successivi
        if (trainType === 3) {
            if (indexToUpdate === 0 && isDeparture) {
                let lastIndex = updateSelectDestinations.length - 1;
                updateSelectDestinations[lastIndex].arr = newSelectDestination;
            }

            // se è un arrivo setto la partenza del leg successivo
            if (!isDeparture && (indexToUpdate + 1) < updateSelectDestinations.length) {
                updateSelectDestinations[indexToUpdate + 1].dep = newSelectDestination;
            }
        }

        setSelectDestinations(updateSelectDestinations);
    }

    function onOpenPanelDestination(externalId) {
        setShowPaxes(false);

        let externalIdParts = externalId.split('_');
        let isDeparture = externalIdParts[0].includes('departure');
        let indexToUpdate = parseInt(externalIdParts[1]);

        let showDestinationMap = getShowDestinationLegs(false);
        if (isDeparture) {
            showDestinationMap[indexToUpdate].dep = true;
        }
        else {
            showDestinationMap[indexToUpdate].arr = true;
        }

        setShowDestination(showDestinationMap);

        setShowCalendarOutbound(false);
        setShowCalendarReturn(false);
    }

    // funzioni per la composizione
    function onClickSelectPaxes(selectComposition) {
        setNumberCards([...Array(selectComposition[0].adults)]);
        setSelectComposition(selectComposition);
    }

    function onOpenPanelPaxes() {
        setShowPaxes(true);

        let showDestinationMap = getShowDestinationLegs(false);
        setShowDestination(showDestinationMap);

        setShowCalendarOutbound(false);
        setShowCalendarReturn(false);
    }

    // funzioni per il calendario
    function onOpenPanelCalendarOutbound() {
        setShowPaxes(false);

        let showDestinationMap = getShowDestinationLegs(false);
        setShowDestination(showDestinationMap);

        setShowCalendarOutbound(true);
        setShowCalendarReturn(false);
    }

    function onOpenPanelCalendarReturn() {
        setShowPaxes(false);

        let showDestinationMap = getShowDestinationLegs(false);
        setShowDestination(showDestinationMap);

        setShowCalendarOutbound(false);
        setShowCalendarReturn(true);
    }

    function onClickSelectDatesOutbound(values) {
        setShowCalendarOutbound(false);

        let updSelectDates = {
            ...selectDates,
            dateFrom: values.dateFrom
        };
        if (updSelectDates.dateTo && values.dateFrom) {
            let isLess = compareDates(updSelectDates.dateTo, values.dateFrom);
            if (isLess < 0)
                updSelectDates.dateTo = null;
        }

        let updValidDateRule = {
            ...validDateRule
        };
        updValidDateRule.minDate = values.dateFrom;

        setSelectDates(updSelectDates);
        setValidDateRule(updValidDateRule);


        setSelectTimes({
            ...selectTimes,
            timeFrom: values.timeFrom
        })
    }

    function onClickSelectDatesReturn(values) {
        setShowCalendarReturn(false);

        setSelectDates({
            ...selectDates,
            dateTo: values.dateFrom
        });

        setSelectTimes({
            ...selectTimes,
            timeTo: values.timeFrom
        })
    }

    // funzioni cittadinanza
    function onSelectCitizenship(item) {
        setSelectCitizenship(item);
    }

    // funzioni cambio tipo
    function onChangeTrainType(event, type) {
        let isChecked = event.target.checked;
        setTrainType(type);

        if (type === 1 && isChecked) {
            setExtraOptionsCalendarOutbound({ ...extraOptionsCalendarOutbound, multiDate: false, numberOfMonths: 1, label: 'SearchEngine:Date' });
        } else if (type === 2 && isChecked) {
            setExtraOptionsCalendarOutbound({ ...extraOptionsCalendarOutbound, multiDate: true, numberOfMonths: 1, label: 'SearchEngine:DateFrom' });
        }

    }

    function getShowDestinationLegs(value) {
        let result = [];

        if (showDestination && showDestination.length > 0) {
            for (let i = 0; i < showDestination.length; i++) {
                result.push({ dep: value, arr: value });
            }
        }

        return result;
    }

    // funzioni ricerca avanzata
    function onBlurTrainCard(event, key) {
        let updNumberCard = [...numberCards];

        updNumberCard[key] = event.target.value;

        setNumberCards(updNumberCard);
    }

    return (
        <>
            <div className="btn-group btn-sm rounded-1" role="group" aria-label="Basic example">
                <div className="form-check form-check-inline">
                    <input className="form-check-input"
                        type="radio"
                        id="Return"
                        value="option1"
                        checked={trainType === 2}
                        onChange={(e) => onChangeTrainType(e, 2)} />

                    <data m3ico="RoundTrip text-gray-600 icon-10">
                        <M3Icon iconName="RoundTrip" externalClass={"icon-10 " + textColorClass} />
                    </data>
                    <label className={"form-check-label " + textColorClass} htmlFor="Return">
                        <data m3lab="SearchEngine.Roundtrip">
                            {t('SearchEngine:Roundtrip')}
                        </data>
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input"
                        type="radio"
                        id="OneWay"
                        value="option2"
                        checked={trainType === 1}
                        onChange={(e) => onChangeTrainType(e, 1)} />

                    <data m3ico="OneWay text-gray-600 icon-10">
                        <M3Icon iconName="OneWay" externalClass={"icon-10 " + textColorClass} />
                    </data>
                    <label className={"form-check-label " + textColorClass} htmlFor="OneWay">
                        <data m3lab="SearchEngine.OneWay">
                            {t('SearchEngine:OneWay')}
                        </data>
                    </label>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-12 col-sm-3 my-1">
                    <InputSearchDestinationContainer
                        externalId="departureSegment_0"
                        defaultSelectDestination={selectDestinations[0].dep}
                        productType={configData.Settings.Products.Train.IdTipoProdotto}
                        lastSearchItems={lastSearchItems}
                        onSelectSearchDestination={onSelectSearchDestination}
                        onOpenPanelDestination={onOpenPanelDestination}
                        extraOptions={{ label: 'SearchEngine:DepartureStation', iconName: 'TrainStation', selectGroupTitle: true }}
                        closeExternal={showDestination[0].dep}
                        modeView={modeView} />
                </div>
                <div className="col-12 col-sm-3 my-1">
                    <InputSearchDestinationContainer
                        externalId="arrivalSegment_0"
                        defaultSelectDestination={selectDestinations[0].arr}
                        productType={configData.Settings.Products.Train.IdTipoProdotto}
                        lastSearchItems={lastSearchItems}
                        onSelectSearchDestination={onSelectSearchDestination}
                        onOpenPanelDestination={onOpenPanelDestination}
                        extraOptions={{ label: 'SearchEngine:ArrivalStation', iconName: 'TrainStation', selectGroupTitle: true }}
                        closeExternal={showDestination[0].arr}
                        modeView={modeView} />
                </div>

                <div className="col-12 col-sm-3 my-1">
                    <div className={trainType === 2 ? "row" : ""}>
                        <div className={trainType === 2 ? "d-none d-sm-block col-12 col-sm-6 col-md-6 col-lg-6 pl-0" : ""}>
                            <InputCalendarContainer
                                dateFrom={selectDates ? selectDates.dateFrom : null}
                                timeFrom={selectTimes ? selectTimes.timeFrom : null}
                                extraOptions={extraOptionsCalendarOutbound}
                                productType={configData.Settings.Products.Train.IdTipoProdotto}
                                onClickSelectDates={onClickSelectDatesOutbound}
                                onOpenPanelCalendar={onOpenPanelCalendarOutbound}
                                closeExternal={showCalendarOutbound}
                                modeView={modeView}
                                validDateRule={extraParams ? extraParams.validDateRule : null}
                                type="m" />
                        </div>
                        {
                            trainType === 2 && <div className="d-none d-sm-block col-12 col-sm-6 col-md-6 col-lg-6 pr-0">
                                <InputCalendarContainer
                                    dateFrom={selectDates ? selectDates.dateTo : null}
                                    timeFrom={selectTimes ? selectTimes.timeTo : null}
                                    extraOptions={extraOptionsCalendarReturn}
                                    validDateRule={validDateRule}
                                    productType={configData.Settings.Products.Train.IdTipoProdotto}
                                    onClickSelectDates={onClickSelectDatesReturn}
                                    onOpenPanelCalendar={onOpenPanelCalendarReturn}
                                    closeExternal={showCalendarReturn}
                                    modeView={modeView}
                                    type="m" />
                            </div>
                        }
                        <div className={trainType === 2 ? "d-block d-sm-none col-12 col-sm-6 col-md-6 col-lg-6 my-1" : "d-none"}>
                            <InputCalendarContainer
                                dateFrom={selectDates ? selectDates.dateFrom : null}
                                timeFrom={selectTimes ? selectTimes.timeFrom : null}
                                extraOptions={extraOptionsCalendarOutbound}
                                productType={configData.Settings.Products.Train.IdTipoProdotto}
                                onClickSelectDates={onClickSelectDatesOutbound}
                                onOpenPanelCalendar={onOpenPanelCalendarOutbound}
                                closeExternal={showCalendarOutbound}
                                modeView={modeView}
                                type="m" />
                        </div>
                        {
                            trainType === 2 && <div className="d-block d-sm-none col-12 col-sm-6 col-md-6 col-lg-6 my-1">
                                <InputCalendarContainer
                                    dateFrom={selectDates ? selectDates.dateTo : null}
                                    timeFrom={selectTimes ? selectTimes.timeTo : null}
                                    extraOptions={extraOptionsCalendarReturn}
                                    validDateRule={validDateRule}
                                    productType={configData.Settings.Products.Train.IdTipoProdotto}
                                    onClickSelectDates={onClickSelectDatesReturn}
                                    onOpenPanelCalendar={onOpenPanelCalendarReturn}
                                    closeExternal={showCalendarReturn}
                                    modeView={modeView}
                                    type="m" />
                            </div>
                        }
                    </div>
                </div>
                <div className="col-12 col-sm-2 my-1">
                    <InputPaxesCompositionContainer
                        searchParameters={searchParameters}
                        productType={configData.Settings.Products.Train.IdTipoProdotto}
                        callOnSelectComposition={onClickSelectPaxes}
                        onOpenPanelPaxes={onOpenPanelPaxes}
                        closeExternal={showPaxes}
                        modeView={modeView}
                        type="l" />
                </div>
                <div className="col-12 col-sm-1 my-1 p-0">
                    <button
                        className="btn tp-btn-search w-100 py-3"
                        onClick={_ => onSearchButton()}
                        disabled={!isValidForm.isAllFieldValid && !isStartSearch}
                    >
                        {isStartSearch ? <><div className="spinner-border spinner-border-sm text-primary" role="status"><span className="sr-only"></span></div></> : <>{t('Button:Search')}</>}
                    </button>
                </div>

                <div className="col-12 col-sm-9 my-1">

                </div>
                <div className="col-12 col-sm-3 my-1 d-inline text-right">
                    <SelectCitizenship
                        defaultCitizenshipId={defaultCitizenshipId}
                        onSelectCitizenship={onSelectCitizenship}
                        modeView={modeView} />
                </div>

            </div>
            {
                enableFlightSearchAdvanced && <div className="row mb-3">
                    <div className="col-12 col-sm-12 my-1">
                        <p>
                            <a className={"h075 text-decoration-none  cursor-pointer " + textColorClass} onClick={_ => setShowTrainCardPanel(!showTrainCardPanel)}>
                                {t('Product:Train:InsertTrainCard')}
                            </a>
                        </p>
                        {
                            showTrainCardPanel && <div>
                                {
                                    numberCards && numberCards.map((nc, key) => {
                                        return <div key={key} className="d-inline-block mr-2">
                                            <div className="input-group bg-white rounded pr-1">
                                                <span className="input-group-text px-2" id="basic-addon1">
                                                    <img src="https://cdn.travelplace.ch/common/images/logos/trenitalia.png" alt="Trenitalia" style={{ width: '64px' }} />
                                                </span>
                                                <div className="pl-1">
                                                    <label htmlFor="FidityCard1"
                                                        className="form-label h065">
                                                        Cartafreccia Trenitalia
                                                    </label>
                                                    <input className="form-control h075 w-100 border-0 shadow-none px-0"
                                                        value={nc}
                                                        placeholder="es:12456789"
                                                        onChange={e => onBlurTrainCard(e, key)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            }
            {
                !isValidComposition && <div className="row mb-3">
                    <div className={"col-12 col-sm-12 my-1  " + textColorClass}>
                        {t('SearchEngine:WarningCartComposition')}
                    </div>
                </div>
            }
        </>
    );
}