import React from 'react';
import { useTranslation } from 'react-i18next';

export const ActivityDetailCategoryInfo = ({ activityId, activityDetail, activityInfo }) => {
    const { t } = useTranslation();

    return (
        <>
            <p className="h6"><data m3lab="Structure.Service">Info:</data></p>
            <div className="card bg-gray-100 table small text-muted w-100">
                <div className="row p-2">
                    {
                        ((activityDetail.duration && activityDetail.duration.length > 0) || (activityInfo && activityInfo.duration && activityInfo.duration.length > 0)) && <div className="col-12 col-sm-6 col-md-6 col-lg-6 py-1">
                            <span className="p-2 small">
                                <label className="h6 mr-1"><data m3lab="Product.Activity.Duration">{t('Product:Activity:Duration')}:</data></label>
                                {
                                    activityDetail.duration && activityDetail.duration.map((category, key) => {
                                        return <var key={key}>{category.name}</var>;
                                    })
                                }
                                {
                                    (!activityDetail.duration || activityDetail.duration.length === 0) && activityInfo && activityInfo.duration && activityInfo.duration.map((category, key) => {
                                        return <var key={key}>{category.name}</var>;
                                    })
                                }
                            </span>
                        </div>
                    }
                    {
                        activityDetail.durationTime && activityDetail.durationTime > 0 ? <div className="col-12 col-sm-6 col-md-6 col-lg-6 py-1">
                            <span className="p-2 small">
                                <label className="h6 mr-1"><data m3lab="Product.Activity.Duration">{t('Product:Activity:Duration')}: </data></label>
                                <var>{activityDetail.durationTime && activityDetail.durationTime > 0 ? activityDetail.durationTime : "-"}</var>
                            </span>
                        </div> : <></>
                    }
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 py-1">
                        <span className="p-2 small">
                            <label className="h6 mr-1"><data m3lab="Product.Activity.Code">{t('Product:Activity:Code')}: </data></label>
                            <var>{activityDetail.activityId ? activityDetail.activityId : activityId}</var>
                        </span>
                    </div>
                   
                    {
                        activityDetail.generalCategory && activityDetail.generalCategory.length > 0 && <div className="col-12 col-sm-12 col-md-12 col-lg-12 py-1">
                            <div className="small h6 mb-0">
                                <data m3lab="Product.Activity.Category">{t('Product:Activity:Category')}: </data>
                            </div>
                            {
                                activityDetail.generalCategory.map((category, key) => {
                                    return <div key={key} className="my-0 py-0"><label><var>{category.name}</var></label></div>;
                                })
                            }
                        </div>
                    }
                </div>
            </div>
        </>
    );
}