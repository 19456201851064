import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TrainItemHeader } from './TrainItemHeader';
import { ProductPrice } from "../../Common/Pricing/ProductPrice";

export const TrainSelectedItinerary = ({ itinerary, type, showPriceBar, onModalOpenProductId, onClickUpdate, extraParam, removeTrippie, updateTrippie }) => {
    const { t } = useTranslation();

    const [totalPriceFare, setTotalPriceFare] = useState(null);
    const [supplier, setSupplier] = useState(null);

    useEffect(() => {
        let totalPricing = {
            valutaFornitore: "EUR",
            valutaCliente: "EUR",
            displayPriceCurrency: "EUR",
            costoFornitore: 0,
            networkPrice: 0,
            invoicePrice: 0,
            agencyPrice: 0,
            agencyPriceCommissionable: 0,
            agencyFee: 0,
            agencyMarkupPerc: 10,
            agencyMarkupVal: 0,
            commission: 0,
            displayPrice: 0,
            isCommisionable: false
        };

        for (let i = 0; i < itinerary.selectedFares.length; i++) {
            let fare = itinerary.selectedFares[i].fareComponents;

            totalPricing.valutaFornitore = fare.pricing.valutaFornitore;
            totalPricing.valutaCliente = fare.pricing.valutaCliente;
            totalPricing.displayPriceCurrency = fare.pricing.displayPriceCurrency;

            totalPricing.costoFornitore += fare.pricing.costoFornitore;
            totalPricing.networkPrice += fare.pricing.networkPrice;
            totalPricing.invoicePrice += fare.pricing.invoicePrice;
            totalPricing.agencyPrice += fare.pricing.agencyPrice;
            totalPricing.agencyPriceCommissionable += fare.pricing.agencyPriceCommissionable;
            totalPricing.agencyFee += fare.pricing.agencyFee;
            totalPricing.agencyMarkupPerc = fare.pricing.agencyMarkupPerc;
            totalPricing.agencyMarkupVal += fare.pricing.agencyMarkupVal;
            totalPricing.commission += fare.pricing.commission;
            totalPricing.displayPrice += fare.pricing.displayPrice;

            totalPricing.isCommisionable = fare.pricing.isCommisionable;
        }

        setSupplier(itinerary.fares.tariffe[0].supplier);
        setTotalPriceFare(totalPricing);

    }, [itinerary])

    function renderPivotOffertId(fare, offertId) {
        let offerName = offertId;
        if (fare.offerList) {
            let offer = fare.offerList.filter(x => x.id === offertId);
            if (offer && offer.length > 0) {
                offerName = offer[0].name;
            }

        }

        return offerName.replace('_', ' ');
    }

    function renderPivotServiceId(fare, serviceId) {
        let serviceName = serviceId;
        if (fare.serviceList) {
            let service = fare.serviceList.filter(x => x.id === serviceId);
            if (service && service.length > 0) {
                serviceName = service[0].name;
            }
        }

        return serviceName;
    }

    function handleClickSearch() {
        onClickUpdate();
    }

    return (
        <>
            <div className={"card shadow shadow-hover animate-up-3 p-1 d-none d-sm-block " + (type && type === 'SELECTSEAT' ? '' : 'mb-4')}>
                {
                    itinerary && <div className="row no-gutters">
                        <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                            <div className="card-body pt-2 px-0  pb-0">
                                <div>
                                    <div className="w-100">
                                        <TrainItemHeader
                                            item={itinerary}
                                            supplier={supplier}
                                            onModalOpenProductId={onModalOpenProductId} />

                                        {
                                            itinerary.selectedFares.map((fare, key) => {
                                                return <div class="h6">
                                                    {
                                                        itinerary.fares.tariffe.length > 1 && <div className="mb-2">{itinerary.fares.tariffe[key].fromStation} - {itinerary.fares.tariffe[key].toStation}</div>
                                                    }
                                                    <label>{t('Product:Train:Service')}:</label> {renderPivotServiceId(itinerary.fares.tariffe[key], fare.fareComponents.serviceTypeId)}
                                                    <label className="ml-2">{t('Product:Train:Offert')}:</label> {renderPivotOffertId(itinerary.fares.tariffe[key], fare.fareComponents.offerTypeId)}
                                                </div>
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-1 pr-3">
                            {
                                totalPriceFare && <ProductPrice
                                    itineraryId={itinerary.leg.uniqueId}
                                    pricing={totalPriceFare ? totalPriceFare : null}
                                    supplier={supplier ? supplier : null}
                                    showPriceBar={showPriceBar}
                                    typePrice="TotPrice"
                                    otherParam={{ buttonLabel: 'Button:Update', buttonEnabled: true }}
                                    onClickSearch={handleClickSearch}
                                />
                            }
                        </div>
                    </div>
                }
            </div> 
            <div className={"card shadow mobile-choosen-card shadow-hover animate-up-3 p-1 d-block d-sm-none " + (type && type === 'SELECTSEAT' ? '' : 'mb-4')}>
                {
                    itinerary && <div className="row no-gutters">
                        <div className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                            <div className="card-body pt-2 px-0  pb-0">
                                <div>
                                    <div className="w-100">
                                        <TrainItemHeader
                                            item={itinerary}
                                            supplier={supplier}
                                            onModalOpenProductId={onModalOpenProductId} />

                                        {
                                            itinerary.selectedFares.map((fare, key) => {
                                                return <div class="h6">
                                                    {
                                                        itinerary.fares.tariffe.length > 1 && <div className="mb-2">{itinerary.fares.tariffe[key].fromStation} - {itinerary.fares.tariffe[key].toStation}</div>
                                                    }
                                                    <label>{t('Product:Train:Service')}:</label> {renderPivotServiceId(itinerary.fares.tariffe[key], fare.fareComponents.serviceTypeId)}
                                                    <label className="ml-2">{t('Product:Train:Offert')}:</label> {renderPivotOffertId(itinerary.fares.tariffe[key], fare.fareComponents.offerTypeId)}
                                                </div>
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-1 pr-3">
                            {
                                totalPriceFare && <ProductPrice
                                    itineraryId={itinerary.leg.uniqueId}
                                    pricing={totalPriceFare ? totalPriceFare : null}
                                    supplier={supplier ? supplier : null}
                                    showPriceBar={showPriceBar}
                                    typePrice="TotPrice"
                                    otherParam={{ buttonLabel: 'Button:Update', buttonEnabled: true }}
                                    isTrippie={extraParam && extraParam.isTrippie}
                                    showPriceTrippie={extraParam && extraParam.showPriceTrippie}
                                    removeTrippie={removeTrippie}
                                    updateTrippie={updateTrippie}
                                    onClickSearch={handleClickSearch}
                                />
                            }
                        </div>
                    </div>
                }
            </div>
        </>
    );
}