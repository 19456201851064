import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { formatPrice } from '../../../../js/Utils.js';

export const DMCExtras = ({ extra, onSelect, readOnly }) => {
    const cookies = new Cookies();
    const [cultureName] = useState(cookies.get("CultureName"));
    const [isSelectExtra, setSelectExtra] = useState(false);

    function onChangeExtraSelected(event, extra) {
        const isSelected = event.target.checked;
        setSelectExtra(isSelected);

        onSelect(extra, isSelected);
    }

    return (
        <>
            {
                extra && <>
                    <div className="col-8 col-sm-8 col-md-8 col-lg-8 pl-5 small"><var><div dangerouslySetInnerHTML={{ __html: extra.description }}></div></var></div>
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 ">
                        <div className="d-table ">
                            <div className="d-table-row text-right w-100">
                                <div className="d-table-cell small w-100">
                                    {!readOnly && <span className="mt-0"><var>{formatPrice(extra.amount, extra.currency, cultureName)}</var></span>}
                                    {
                                        !readOnly && extra.cost && <div>
                                            <span className="mt-0">
                                                (Costo: <var>{formatPrice(extra.cost, extra.costCurrency, cultureName)}</var>)
                                            </span>
                                        </div>
                                    }
                                </div>
                                {
                                    !readOnly && <div className="d-table-cell mb-0">
                                        <input className="h5 bg-primary mx-2 my-0"
                                            type="checkbox"
                                            defaultValue="false"
                                            value={isSelectExtra}
                                            onChange={(e) => onChangeExtraSelected(e, extra)} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}