import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { InputSearchDestinationOnlyRead } from './InputSearchDestinationOnlyRead';
import { InputSearchDestination } from './InputSearchDestination';
import { InputListDestination } from './InputListDestination';
import { closeTpSearchInput } from '../../../js/Utils';
import configData from "../../../appsettings.json";

export const InputSearchDestinationContainer = ({ externalId, defaultSelectDestination, productType, hideAllInput, onSelectSearchDestination, lastSearchItems, modeView, closeExternal, clearSearch, extraOptions, onOpenPanelDestination }) => {

    const { t } = useTranslation();

    const [selectDestination, setSelectDestination] = useState(null); // {departure, arrival} 
    const [showDestination, setShowDestination] = useState(false);
    const [autoFocus, setAutoFocus] = useState(false);
    const [showRecent, setShowRecent] = useState(false);
    const [modalStyle, setModalStyle] = useState({
        overflow: 'unset',
        height: (!isMobile() ? (isMobile(false) && modeView === 'MODAL' && showDestination ? '' : '0px') : '100%'),
        zIndex: '200'
    });

    const [idModal] = useState(getRandomInt());
    function getRandomInt() {
        return Math.floor(Math.random() * 100000);
    }
    const modalId = "Destinations_" + idModal;

    useEffect(() => {

        let modalIdName = "Destinations_" + idModal;
        let modalDestination = document.getElementById(modalIdName);
        if (modalDestination) {
            modalDestination.addEventListener('shown.bs.modal', function () {

                let windowScreenWidth = window.screen.width;
                let componentWidth = document.getElementById(modalIdName).getBoundingClientRect().width;
                let componentStartingPoint = document.getElementById(modalIdName).getBoundingClientRect().x;
                let overflowWidth = componentStartingPoint + componentWidth - windowScreenWidth;
                if (overflowWidth > 0) {

                    setModalStyle({
                        maxWidth: componentWidth - overflowWidth - 20 + 'px',
                        overflow: 'unset',
                        height: (!isMobile() ? (isMobile(false) && modeView === 'MODAL' ? '' : '0px') : '100%'),
                        zIndex: '200'
                    });

                } else {
                    setModalStyle({});
                }

                setAutoFocus(true);
            });

            modalDestination.addEventListener('hidden.bs.modal', function () {
                setModalStyle({});
                setAutoFocus(false);
            });
        }

        if (modeView === 'SEARCHENGINE')
            closeTpSearchInput(modalId, closePanelDestination);
    }, [])

    useEffect(() => {
        setSelectDestination(defaultSelectDestination);
    }, [defaultSelectDestination]);

    useEffect(() => {
        setShowDestination(closeExternal);
    }, [closeExternal]);

    useEffect(() => {
        setResultDestination({ isLoading: false, isError: false, options: [], searchText: '' });
    }, [clearSearch]);

    // gestisco destinazioni
    const [resultDestination, setResultDestination] = useState({ isLoading: false, isError: false, options: [], searchText: '' });
    const [newItems, setNewItems] = useState({ isLoading: false, isError: false, options: [], searchText: '' });

    // funzioni per le destinationi
    function callOnSearchDestination(values) {
        setShowRecent(false);
        setNewItems(values);
    }

    useEffect(() => {

        if (newItems && newItems.searchText === resultDestination.searchText) {
            let updRD = { ...resultDestination };

            updRD.isLoading = updRD.isLoading && newItems.isLoading;

            if (newItems && newItems.options && newItems.options.length > 0) {

                for (let i = 0; i < newItems.options.length; i++) {
                    let myItem = newItems.options[i];

                    let item = updRD.options.find(x => x.Id === myItem.Id);
                    if (!item)
                        updRD.options.push(myItem);
                }

                // raggruppo mongo/tipo destinazione
                // mongo ordino per priority
                // concat i vari array
                updRD.options.sort(function (a, b) {
                    return b.Priority - a.Priority;
                });
            }

            setResultDestination(updRD);
        } else if (newItems && newItems.searchText !== resultDestination.searchText) {
            if (!newItems.options)
                newItems.options = [];

            setResultDestination(newItems);
        }

    }, [newItems]);

    function callOnSelectDestination(selectDestination) {

        setSelectDestination(selectDestination);
        setShowDestination(false);
        setShowRecent(false);
        if (!externalId)
            onSelectSearchDestination(selectDestination);
        else
            onSelectSearchDestination(selectDestination, externalId);

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnCloseModalDestination_" + idModal);
            btn.click();
        }
    }

    function openPanelDestination() {
        setShowDestination(true);
        setShowRecent(true);

        if (!externalId)
            onOpenPanelDestination();
        else
            onOpenPanelDestination(externalId);

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnModalDestination_" + idModal);
            btn.click();
        }
    }

    function closePanelDestination() {
        setShowDestination(false);
        setShowRecent(false);

        try {

            let myModal = document.getElementById(idModal);
            myModal.style.setProperty("height", "0px");

        } catch (ex) { }

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnCloseModalDestination_" + idModal);
            btn.click();
        } else {

            try {
                hideAllInput();
            } catch (ex) { }
        }
    }

    function clearSelection() {
        setSelectDestination(null);

        if (!externalId)
            onSelectSearchDestination(null);
        else
            onSelectSearchDestination(null, externalId);
    }

    function isMobile(excludeModeView = true) {
        if (excludeModeView && modeView === 'MODAL')
            return false;

        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const isMobileWidth = window.innerWidth <= 991;

        // Controllo per dispositivi Android
        if (/android/i.test(userAgent)) {
            return true;
        }

        // Controllo per dispositivi iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return true;
        }

        return isMobileWidth;
    }

    const getPlaceholder = () => {
        if (extraOptions && extraOptions.inputText)
            return t(extraOptions.inputText);

        switch (productType) {
            case configData.Settings.Products.Flight.IdTipoProdotto:
                return t('SearchEngine:DepartureAirport');

            case configData.Settings.Products.Train.IdTipoProdotto:
                return t('SearchEngine:Station');

            default:
                return t('SearchEngine:Destination');
        }
    }

    return (
        <>
            <div className="w-100">
                {
                    !showDestination && <InputSearchDestinationOnlyRead
                        defaultSelectDestination={selectDestination}
                        openPanelDestination={openPanelDestination}
                        productType={productType}
                        extraOptions={extraOptions}
                        clearSelection={clearSelection}
                    />

                }
            </div>



            <a id={"btnModalDestination_" + idModal} data-bs-toggle="modal" data-bs-target={"#" + modalId}></a>

            <div className='d-block'>


                <div
                    className={"modal " + (isMobile() ? "" : " tp-search-input position-relative ") + (modeView === 'MODAL' ? "d-block" : "fade")}
                    id={modalId}
                    tabIndex="-1"
                    aria-labelledby={"DestinationsLabel_" + modalId}
                    aria-hidden="true"
                    style={modalStyle}>
                    <div className={"modal-dialog " + (isMobile() ? "h-100 modal-dialog-scrollable" : "")}>

                        {
                            showDestination && <div className={"modal-content " + (isMobile() ? "h-100" : "")}>
                                {
                                    isMobile()
                                        ? <div className="modal-header p-0">
                                            <div className="container py-3">
                                                <div className="row">
                                                    <div className="col-1">
                                                        <button className="btn btn-link p-0 m-0 text-decoration-none" onClick={_ => closePanelDestination()}>
                                                            <span class="material-icons-outlined">
                                                                arrow_back
                                                            </span>
                                                        </button>
                                                    </div>
                                                    <div className="col-11 text-center">
                                                        <h6 className="btn btn-link p-0 m-0 text-decoration-none fw-bold text-dark">
                                                            {getPlaceholder()}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : <></>
                                }
                                <div className="modal-header p-0">
                                    <InputSearchDestination
                                        defaultSelectDestination={selectDestination}
                                        productType={productType}
                                        modeView={modeView}
                                        autoFocus={autoFocus}
                                        callOnSearchDestination={callOnSearchDestination}
                                        extraOptions={extraOptions}
                                        closePanelDestination={closePanelDestination}
                                        isMobile={isMobile()} />
                                </div>
                                <div className="modal-body">
                                    <InputListDestination
                                        options={resultDestination.options}
                                        lastSearchItems={lastSearchItems}
                                        showRecent={showRecent}
                                        isLoading={resultDestination.isLoading}
                                        isError={resultDestination.isError}
                                        extraOptions={extraOptions}
                                        callOnSelectDestination={callOnSelectDestination} />
                                </div>
                            </div>
                        }

                        <a id={"btnCloseModalDestination_" + idModal} data-bs-dismiss="modal"></a>
                    </div>
                </div>
            </div>



        </>
    );
}
