import React, { useEffect, useState, useCallback } from 'react';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";
import { formatPrice, formatDateTime } from '../../../../js/Utils.js';
import Cookies from 'universal-cookie';
import { ProductAllPrice } from '../../Common/Pricing/ProductAllPrice';
import { ProductNotAllowedAddToCart } from '../../Common/ProductNotAllowedAddToCart';

export const CarResume = ({ option, supplier, carExtrasSelected, carExtrasLocoSelected, handleClickBook, enableShowNetPrices, showCost, enableCanAddToCart, templateInfo, quotationInfo }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");
    const [newTotal, setNewTotal] = useState(null);
    const [showPriceBar, setShowPriceBar] = useState(false);

    useEffect(() => {
        let total = 0;
        carExtrasSelected.map(selectedExtra => {
            total = total + (selectedExtra.extra.amount * selectedExtra.quantity);
        });
        setNewTotal(total);
    }, [carExtrasSelected]);

    // button function
    const onChangeShowPriceBar = () => {
        setShowPriceBar(!showPriceBar);
    }

    const onClickBook = useCallback(event => {
        handleClickBook(true);
    }, [handleClickBook]);

    return (
        <>
            {option &&
                <div className="inner-wrapper-sticky">
                    <div className="card border-light p-2 shadow">
                        <h5>
                            <data m3ico="RentCar mr-2 h3r">
                                <M3Icon iconName="RentCar" externalclassName="icon-15 h3r mr-2" />
                            </data>
                            <data m3lab="MyBook.Summury"> {t('MyBook:Summary')}:</data>
                        </h5>
                        {enableShowNetPrices &&
                            <div className="row mb-3 mx-1">
                                <div className="col-8 col-sm-8">
                                    <div className="h6">
                                        <data m3lab="Product.Flight.FareSelected">
                                            {t(`Product:Flight:FareSelected`)}:
                                        </data>
                                    </div>
                                </div>
                                <div className="col-4 col-sm-4 text-right">
                                    <button className={(showPriceBar ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow"} m3rool="f_ShowPriceBar" onClick={(e) => { onChangeShowPriceBar() }}>
                                        <data m3lab="Button.Net">
                                            {t("Button:Net")}
                                        </data>
                                    </button>
                                </div>
                            </div>
                        }
                        <div className="row mb-3 mx-1" data-bs-toggle="modal" data-bs-target="#EngineModifyActivty">
                            {supplier &&
                                <div className="col-12 col-sm-12 p-1">
                                    <div className="h6">
                                        {t('Product:RentCar:Filter:Supplier')}:
                                    </div>
                                    <p className="p-0 m-0 h075">
                                        <var>{supplier}</var>
                                    </p>
                                </div>
                            }
                            <div className="col-12 col-sm-12 p-1">
                                <div className="h6">
                                    {t('Product:RentCar:Package')}:
                                </div>
                                <p className="p-0 m-0 h075">
                                    <var>{option && option.name}</var>
                                </p>
                            </div>
                            {/* inclusion */}
                            <div className="col-12 col-sm-12 p-1">
                                <div className="h6">
                                    <data m3lab="Product.RentCar.Include">
                                        {t('Product:RentCar:Include')}:
                                    </data>
                                </div>
                                {option && option.inclusionDetails && option.inclusionDetails.length > 0 && option.inclusionDetails.map((inclusion, idx) =>
                                    inclusion.description != null && inclusion.description !== "" && idx < 10 &&
                                    <div className="p-0 m-0 h075">
                                        <var>
                                            <label dangerouslySetInnerHTML={{ __html: "- " + (inclusion.description.slice(0, 100) + (inclusion.description.length > 100 && "...")) }}></label>
                                        </var>
                                    </div>
                                )}
                                <div className="collapse multi-collapse" id="inclusionCollapse">
                                    {option && option.inclusionDetails && option.inclusionDetails.length > 0 && option.inclusionDetails.map((inclusion, idx) =>
                                        inclusion.description != null && inclusion.description !== "" && idx >= 10 &&
                                        <div className="p-0 m-0 h075">
                                            <var>
                                                <label dangerouslySetInnerHTML={{ __html: "- " + (inclusion.description.slice(0, 100) + (inclusion.description.length > 100 && "...")) }}></label>
                                            </var>
                                        </div>
                                    )}
                                </div>
                                {option && option.inclusionDetails && option.inclusionDetails.length > 10 &&
                                    <div className="text-center mt-2">
                                        <span data-bs-toggle="collapse" href="#inclusionCollapse" role="button" aria-expanded="false" aria-controls="inclusionCollapse">
                                            {t('General:More')}
                                        </span>
                                    </div>
                                }
                            </div>
                            {/* extra */}
                            {carExtrasSelected && carExtrasSelected.length > 0 &&
                                <div className="col-12 col-sm-12 p-1">
                                    <div className="h6">
                                        <data m3lab="Product.RentCar.Extra">
                                            {t('Product:RentCar:Extra')}:
                                        </data>
                                    </div>
                                    {carExtrasSelected.map(selectedExtra =>
                                        <p className="p-0 m-0 h075">
                                            <var>-{selectedExtra.quantity}x&nbsp;{selectedExtra.extra.description}:&nbsp;{formatPrice(selectedExtra.extra.amount, selectedExtra.extra.currency, cultureCode)}</var>
                                        </p>
                                    )}
                                </div>
                            }
                            {/* loco */}
                            {(option && option.spotChargesInfo && option.spotChargesInfo.onSpotCharges && option.spotChargesInfo.onSpotCharges.length > 0 || (carExtrasLocoSelected && carExtrasLocoSelected.length > 0)) &&
                                <div className="col-12 col-sm-12 p-1">
                                    <div className="h6">
                                        <data m3lab="Product.RentCar.ExtraOnPickup">
                                            {t('Product:RentCar:ExtraOnPickup')}&nbsp;
                                        </data>
                                        <label>
                                            ({t('Product:RentCar:ExtraOnPickupText')})
                                        </label>
                                        :
                                    </div>

                                    {carExtrasLocoSelected && carExtrasLocoSelected.length > 0 && carExtrasLocoSelected.map(selectedExtraLoco =>
                                        <p className="p-0 m-0 h075">
                                            <var>-{selectedExtraLoco.quantity}x&nbsp;{selectedExtraLoco.extra.description}:&nbsp;{formatPrice(selectedExtraLoco.extra.amount, selectedExtraLoco.extra.currency, cultureCode)}</var>
                                        </p>
                                    )}

                                    {option && option.spotChargesInfo && option.spotChargesInfo.onSpotCharges && option.spotChargesInfo.onSpotCharges.length > 0 && option.spotChargesInfo.onSpotCharges.map(selectedExtraLoco =>
                                        <p className="p-0 m-0 h075">
                                            <var>&nbsp;{selectedExtraLoco.description}:&nbsp;
                                                {formatPrice((selectedExtraLoco.amount ? selectedExtraLoco.amount : selectedExtraLoco.costAmount), (selectedExtraLoco.currency ? selectedExtraLoco.currency : selectedExtraLoco.costCurrency), cultureCode)}</var>
                                        </p>
                                    )}
                                </div>
                            }
                            {/* policy */}
                            <div className="col-12 col-sm-12 p-1 mt-1">
                                <div className="h6 mt-0 mb-0">
                                    {option.cancellationPolicy && option.cancellationPolicy.isFree ?
                                        <label className="text-success">{t(`CancelPolicy:FreeCancellation`)} {formatDateTime(option.cancellationPolicy.expirationDate, { twoDigits: true })}</label>
                                        :
                                        <label className="penalty">{t(`CancelPolicy:PenaltyCancellation`)}</label>
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 p-1 mt-1">
                                <div className="mt-0">
                                    <div className="h5 p-0">
                                        <data m3lab="General.TotPrice">
                                            {t('General:TotPrice')}:
                                            {option && option.pricing &&
                                                <var> {formatPrice(option.pricing.displayPrice + newTotal, option.pricing.displayPriceCurrency, cultureCode)}</var>
                                            }
                                        </data>
                                    </div>
                                </div>
                            </div>
                            {
                                <ProductAllPrice showPriceBar={showPriceBar} showCost={showCost} pricing={option.pricing} />
                            }
                            <div className="col-12 col-sm-12 mb-1 mt-1 p-0">
                                {
                                    (templateInfo || quotationInfo || (enableCanAddToCart && enableCanAddToCart.allowed)) &&
                                    <button className="btn btn-sm tp-btn-search w-100 text-white" onClick={(e) => onClickBook()}>
                                        <data m3lab="Button.Book">
                                            <data m3lab="Button.Book">
                                                {!templateInfo && !quotationInfo && <span dangerouslySetInnerHTML={{ __html: t(`Button:AddToCart`) }}></span>}
                                                {templateInfo && t(`Button:Select`)}
                                                {quotationInfo && !quotationInfo.quotationItemIdToChange && t(`Button:AddQuotation`)}
                                                {quotationInfo && quotationInfo.quotationItemIdToChange && t(`Button:ReplaceQuotation`)}
                                            </data>
                                        </data>
                                    </button>
                                }
                                <ProductNotAllowedAddToCart enableCanAddToCart={enableCanAddToCart} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}