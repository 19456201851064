import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";

export const ProductDestinationFilter = ({ departureAirports, arrivalAirports, label, type, onApplyFilterAirportDeparture, onApplyFilterAirportArrival, resetSelectedItem }) => {
    const { t } = useTranslation();
    const [selectedItemsDeparture, setSelectedItemsDeparture] = useState([]);
    const [selectedItemsArrival, setSelectedItemsArrival] = useState([]);
    const [showFilter, setShowFilter] = useState(true);

    useEffect(() => {
        setSelectedItemsDeparture([]);
        setSelectedItemsArrival([])
    }, [resetSelectedItem]);

    function onCallApplyFilterDeparture(item) {
        let updateSelectedItems = [...selectedItemsDeparture];

        let isSelect = updateSelectedItems.some(x => x.id === item.id);
        if (isSelect)
            updateSelectedItems = updateSelectedItems.filter(x => x.id !== item.id);
        else
            updateSelectedItems.push(item);

        setSelectedItemsDeparture(updateSelectedItems);
        onApplyFilterAirportDeparture(updateSelectedItems, type);
    }

    function onCallApplyFilterArrival(item) {
        let updateSelectedItems = [...selectedItemsArrival];

        let isSelect = updateSelectedItems.some(x => x.id === item.id);
        if (isSelect)
            updateSelectedItems = updateSelectedItems.filter(x => x.id !== item.id);
        else
            updateSelectedItems.push(item);

        setSelectedItemsArrival(updateSelectedItems);
        onApplyFilterAirportArrival(updateSelectedItems, type);
    }

    function onShowFilter(event) {
        event.preventDefault();
        setShowFilter(!showFilter);
    }

    function getLabel(name) {
        return name;
    }

    return (
        <>
            {
            departureAirports && departureAirports.length > 0 && <div className="card bg-white rounded active my-1 text-primary h6 shadow">
                <a href="#" className="text-decoration-none " onClick={(e) => onShowFilter(e)}>
                    <div className="row">
                        <div className="col-10 pl-4 pt-2">
                            <span className="p-2 text-left text-blue">
                                <data m3lab={label}>
                                    <span className="material-icons icon-10">flight</span>
                                    {t(label)}
                                </data>
                            </span>
                        </div>
                        <div className="col-1 text-left p-1">
                            <data m3ico="Remove text-blue">
                                {showFilter && <M3Icon iconName="Remove" externalClass="text-blue" hasLabel={false} />}
                                {!showFilter && <M3Icon iconName="Add" externalClass="text-blue" hasLabel={false} />}
                            </data>
                        </div>
                    </div>
                </a>
                {showFilter &&
                    <ul className="list-group px-1">
                        <span className="p-2 text-left text-blue">
                            <span className="material-icons icon-10">flight_takeoff</span>
                            <data m3lab={label}>
                                {t('Product:Flight:Takeoff')}
                            </data>
                        </span>
                        {departureAirports.map((item, key) => {
                            return <li key={key} className="list-group-item p-0 m-1 border-0 ">
                                <input type="checkbox"
                                    value={selectedItemsDeparture.some(x => x.id === item.id)}
                                    checked={selectedItemsDeparture.some(x => x.id === item.id)}
                                    onChange={e => onCallApplyFilterDeparture(item)} />
                                <var><label className="px-2">{getLabel(item.name)}</label></var>
                            </li>
                        })}
                    </ul>
                }
                {showFilter &&
                    <ul className="list-group px-1">
                        <span className="p-2 text-left text-blue">
                            <span className="material-icons icon-10">flight_land</span>
                            <data m3lab={label}>
                                {t('Product:Flight:Landing')}
                            </data>
                        </span>
                        {arrivalAirports.map((item, key) => {
                            return <li key={key} className="list-group-item p-0 m-1 border-0 ">
                                <input type="checkbox"
                                    value={selectedItemsArrival.some(x => x.id === item.id)}
                                    checked={selectedItemsArrival.some(x => x.id === item.id)}
                                    onChange={e => onCallApplyFilterArrival(item)} />
                                <var><label className="px-2">{getLabel(item.name)}</label></var>
                            </li>
                        })}
                    </ul>
                }
            </div>
            }
        </>
    );
}