import React, { useEffect, useState, useCallback } from 'react';
import { M3Icon } from "../../Common/M3Icon";


export const IconChoosePrint = ({ handleIsPrint }) => {
    const [isPrint, setIsPrint] = useState(true);

    const handleClick = useCallback(event => {
        handleIsPrint(event);
    }, [handleIsPrint]);

    function onClickDisabled(e) {
        setIsPrint(false);
        handleClick(false);
    }

    function onClickEnabled(e) {
        setIsPrint(true);
        handleClick(true);
    }

    return (
        <>
            {isPrint ?
                <span onClick={e => onClickDisabled(e)} style={{userSelect: "none"} }>
                    <M3Icon iconName="Print" externalClass="" hasLabel={false}  />
                </span>
                :
                <span onClick={e => onClickEnabled(e)} style={{ userSelect: "none" }}>
                    <M3Icon iconName="PrintDisabled" externalClass="text-danger" hasLabel={false} />
                </span>
            }
        </>
    );
}