import React, { useEffect, useState, useRef } from 'react';
import configData from "../../appsettings.json";
import { flightPaxType } from "../../js/Enums";
import { handleError } from "../../js/Utils";
import { InputSearchDestinationModal } from "../Common/Destination/InputSearchDestinationModal";
import { M3Icon } from '../Common/M3Icon';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { AddServiceNote } from './components/AddServiceNote';

export const FlightManual = (props) => {
    const { t } = useTranslation();
    const today = new Date().toISOString().split("T")[0];

    const legsTextImport = useRef();
    const travelersTextImport = useRef();
    const faresTextImport = useRef();

    const [isLegsTextImport, setIsLegsTextImport] = useState("");

    const handleChangeLegsText = (event) => {
        setIsLegsTextImport(event.target.value.trim());
    };

    const flightItineraryDefault = {
        id: 0,
        departureDate: '',
        departureTime: '',
        departureAirport: {},

        arrivalDate: '',
        arrivalTime: '',
        arrivalAirport: {},

        marketingCarrier: '',
        flightNumber: '',
        bookingClass: '',
        bagagge: null,
        weight: null,
        segments: []
    }

    const paxPricingDefault = {
        id: 0,
        paxType: flightPaxType[0].key,
        age: null,
        buyPriceAmount: null,
        buyPriceCurrency: 'EUR',
        buyPriceChange: null,
        taxesAmount: null
    };

    const flightDetailDefault = {
        id: -1,
        productType: configData.Settings.Products.Flight.IdTipoProdotto,
        city: null,

        imageUrl: '',
        recordLocator: null,
        reservationId: null,
        payBy: null,
        internalNote: '',

        providerId: 'NOT',
        cancelPolicy: '',

        itinerary: [{ ...flightItineraryDefault }],
        paxPricing: [{ ...paxPricingDefault }],

        serviceNote: null,
        serviceNoteDesc: null
    };

    const flightSegmentDefault = {
        id: 0,
        departureDate: '',
        departureTime: '',
        departureAirport: {},

        arrivalDate: '',
        arrivalTime: '',
        arrivalAirport: {},

        marketingCarrier: '',
        flightNumber: '',

        bookingClass: '',
        bagagge: null,
        weight: null,
    }

    const validateFlightSegmentDefault = {
        isValidDepartureDate: false,
        isTouchedDepartureDate: false,
        isValidDepartureTime: false,
        isTouchedDepartureTime: false,
        isValidDepartureAirport: false,
        isTouchedDepartureAirport: false,

        isValidArrivalDate: false,
        isTouchedArrivalDate: false,
        isValidArrivalTime: false,
        isTouchedArrivalTime: false,
        isValidArrivalAirport: false,
        isTouchedArrivalAirport: false,

        isValidMarketingCarrier: false,
        isTouchedMarketingCarrier: false,
        isValidFlightNumber: false,
        isTouchedFlightNumber: false,
    }

    const validatePaxPricingDefault = {
        isValidBuyPriceAmount: false,
        isTouchedBuyPriceAmount: false
    };

    const validateFlightItineraryDefault = {
        isValidDepartureDate: false,
        isTouchedDepartureDate: false,
        isValidDepartureTime: false,
        isTouchedDepartureTime: false,
        isValidDepartureAirport: false,
        isTouchedDepartureAirport: false,

        isValidArrivalDate: false,
        isTouchedArrivalDate: false,
        isValidArrivalTime: false,
        isTouchedArrivalTime: false,
        isValidArrivalAirport: false,
        isTouchedArrivalAirport: false,

        isValidMarketingCarrier: false,
        isTouchedMarketingCarrier: false,
        isValidFlightNumber: false,
        isTouchedFlightNumber: false,

        isValidSegment: []
    };

    const validateFlightDefault = {
        isTouchedProviderId: false,
        isValidProviderId: false,

        isValidItinerary: [{ ...validateFlightItineraryDefault }],
        isValidPaxPricing: [{ ...validatePaxPricingDefault }],

        isValidForm: false
    };

    const [currencies, setCurrencies] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [airlines, setAirlines] = useState([]);
    const [errorMsgPNRFromFile, setErrorMsgPNRFromFile] = useState();

    const [flightDetail, setFlightDetail] = useState({ ...flightDetailDefault });
    const [validateInputData, setValidateInputData] = useState({ ...validateFlightDefault });

    const [isErrorImportPNR, setIsErrorImportPNR] = useState(false);
    const [isLoadingImportPNR, setIsLoadingImportPNR] = useState(false);

    const [isErrorImportPNRFromFile, setIsErrorImportPNRFromFile] = useState(false);
    const [isLoadingImportPNRFromFile, setIsLoadingImportPNRFromFile] = useState(false);

    useEffect(() => {
        if (props.isOpen) {
            if (currencies.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}constant/getcurrency`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setCurrencies(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (suppliers.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}customer/getallsupplierforproduct/${configData.Settings.Products.Flight.IdTipoProdotto}`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setSuppliers(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (airlines.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}flight/getairlines`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setAirlines(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (!props.item) {
                setFlightDetail({ ...flightDetailDefault });
                setValidateInputData({ ...validateFlightDefault });
            }
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (!props.item) {
            setFlightDetail({ ...flightDetailDefault });
            setValidateInputData({ ...validateFlightDefault });
        } else if (props.item.productType === configData.Settings.Products.Flight.IdTipoProdotto) {
            setFlightDetail(props.item);

            createFlightValidateAllTrue(props.item, true);
        }
    }, [props.item]);

    function createFlightValidateAllTrue(flightTmp, isValidFares) {
        var isValidCD = { ...validateFlightDefault };

        Object.keys(isValidCD).forEach(key => {
            if (key === 'isValidItinerary') {
                var myArray = [];
                for (var i = 0; i < flightTmp.itinerary.length; i++) {

                    var mySegments = [];
                    for (var j = 0; j < flightTmp.itinerary[i].segments.length; j++) {
                        var itemSeg = { ...validateFlightSegmentDefault };
                        Object.keys(itemSeg).forEach(r => itemSeg[r] = true);
                        mySegments.push(itemSeg);
                    }

                    var item = { ...validateFlightItineraryDefault };
                    delete item.isValidSegment;

                    Object.keys(item).forEach(r => item[r] = true);

                    item.isValidSegment = mySegments;

                    myArray.push(item);
                }

                isValidCD[key] = myArray;
            } else if (key === 'isValidPaxPricing') {
                var myArray = [];
                for (var i = 0; i < flightTmp.paxPricing.length; i++) {
                    var item = { ...validatePaxPricingDefault };
                    Object.keys(item).forEach(r => item[r] = isValidFares);
                    myArray.push(item);
                }

                isValidCD[key] = myArray;
            } else {
                isValidCD[key] = true;
            }
        });

        if (!isValidFares)
            isValidCD.isValidForm = false;

        setValidateInputData(isValidCD);
    }

    function onBlurEvent(event, propertyName) {
        let updateFlightDetail = { ...flightDetail };

        let newValue = '';
        if (propertyName === 'providerId') {
            newValue = event.key;
            if (!newValue)
                newValue = event;
        }
        else if (propertyName === 'serviceNote' || propertyName === 'serviceNoteDesc') {
            newValue = event;
        }
        else {
            newValue = event.target.value;
        }

        updateFlightDetail[propertyName] = newValue;
        setFlightDetail(updateFlightDetail);

        checkValidateInputData(propertyName, newValue);
    }

    function checkValidateInputData(propertyName, value) {
        let isValidForm = false;
        let prevValidateInputData = { ...validateInputData };
        delete prevValidateInputData.isValidItinerary;
        delete prevValidateInputData.isValidPaxPricing;
        delete prevValidateInputData.isValidForm;

        const isSelectedOption = value !== 'NOT';

        switch (propertyName) {
            case 'providerId': {
                delete prevValidateInputData.isValidProviderId;
                delete prevValidateInputData.isTouchedProviderId;
                isValidForm = isSelectedOption && Object.values(prevValidateInputData).every(item => item) && checkIsValidItinerary(-1) && checkIsValidPaxPricing(-1);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidProviderId: isSelectedOption, isTouchedProviderId: true });
                break;
            }
            default:
                break;
        }
    }

    function onBlurItineraryEvent(event, index, propertyName) {
        let updateItinerary = [...flightDetail.itinerary];

        let newValue = '';
        if (propertyName === 'marketingCarrier') {
            newValue = event.key;
            if (!newValue)
                newValue = event;
        } else {
            newValue = event.target.value;
        }

        updateItinerary[index][propertyName] = newValue;

        switch (propertyName) {
            case 'departureDate':
            case 'arrivalDate': {
                for (var i = 0; i < updateItinerary.length; i++) {
                    var newDate = new Date(event.target.value);

                    if (i > index) {
                        if (updateItinerary[i].departureDate && new Date(updateItinerary[i].departureDate) < newDate) {
                            updateItinerary[i].departureDate = '';
                        }

                        if (updateItinerary[i].arrivalDate && new Date(updateItinerary[i].arrivalDate) < newDate) {
                            updateItinerary[i].arrivalDate = '';
                        }
                    } else if (i === index) {
                        if (updateItinerary[i].arrivalDate && new Date(updateItinerary[i].arrivalDate) < newDate) {
                            updateItinerary[i].arrivalDate = '';
                        }
                    }
                }

                break;
            }

            default: break;
        }

        var updateFlightDetail = { ...flightDetail, itinerary: updateItinerary };
        setFlightDetail(updateFlightDetail);
        checkValidateInputDataItinerary(newValue, index, propertyName);
    }

    function checkValidateInputDataItinerary(value, index, propertyName) {
        let isValidForm = { ...validateInputData.isValidForm };
        let prevValidateInputData = [...validateInputData.isValidItinerary];

        const isNotEmpty = (value !== '' && value !== undefined);
        const isSelectedOption = (value !== 'NOT' && value !== undefined);

        switch (propertyName) {
            case 'departureDate': {
                delete prevValidateInputData[index].isValidDepartureDate;
                delete prevValidateInputData[index].isTouchedDepartureDate;

                let isValidDate = isNotEmpty && (new Date(value) !== "Invalid Date") && new Date(value) >= new Date(today);
                if (index > 0) {
                    //let yesterday = new Date(value);
                    //yesterday = new Date(yesterday.setDate(yesterday.getDate() - 1));

                    //const prevDate = new Date(flightDetail.itinerary[index - 1].arrivalDate);
                    //isValidDate = isValidDate && yesterday >= prevDate;
                }

                isValidForm = checkIsValidFlightInfo() && isValidDate && Object.values(prevValidateInputData[index]).every(item => item) && checkIsValidItinerary(index) && checkIsValidPaxPricing(-1);

                prevValidateInputData[index].isValidDepartureDate = isValidDate;
                prevValidateInputData[index].isTouchedDepartureDate = true;

                let newValidateInputData = {
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidItinerary: prevValidateInputData
                };

                setValidateInputData(newValidateInputData);
                break;
            }
            case 'departureTime': {
                delete prevValidateInputData[index].isValidDepartureTime;
                delete prevValidateInputData[index].isTouchedDepartureTime;

                isValidForm = checkIsValidFlightInfo() && isNotEmpty && Object.values(prevValidateInputData[index]).every(item => item) && checkIsValidItinerary(index) && checkIsValidPaxPricing(-1);

                prevValidateInputData[index].isValidDepartureTime = isNotEmpty;
                prevValidateInputData[index].isTouchedDepartureTime = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidItinerary: prevValidateInputData });
                break;
            }
            case 'departureAirport': {
                delete prevValidateInputData[index].isValidDepartureAirport;
                delete prevValidateInputData[index].isTouchedDepartureAirport;

                isValidForm = checkIsValidFlightInfo() && isNotEmpty && Object.values(prevValidateInputData[index]).every(item => item) && checkIsValidItinerary(index) && checkIsValidPaxPricing(-1);

                prevValidateInputData[index].isValidDepartureAirport = isNotEmpty;
                prevValidateInputData[index].isTouchedDepartureAirport = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidItinerary: prevValidateInputData });
                break;
            }
            case 'arrivalDate': {
                delete prevValidateInputData[index].isValidArrivalDate;
                delete prevValidateInputData[index].isTouchedArrivalDate;

                let isValidDate = isNotEmpty && (new Date(value) !== "Invalid Date") && new Date(value) >= new Date(today);
                if (index > -1) {
                    //const prevDate = new Date(flightDetail.itinerary[index].departureDate);
                    //isValidDate = isValidDate && new Date(value) >= prevDate;
                }

                isValidForm = checkIsValidFlightInfo() && isValidDate && Object.values(prevValidateInputData[index]).every(item => item) && checkIsValidItinerary(index) && checkIsValidPaxPricing(-1);

                prevValidateInputData[index].isValidArrivalDate = isValidDate;
                prevValidateInputData[index].isTouchedArrivalDate = true;

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidItinerary: prevValidateInputData
                });
                break;
            }
            case 'arrivalTime': {
                delete prevValidateInputData[index].isValidArrivalTime;
                delete prevValidateInputData[index].isTouchedArrivalTime;

                isValidForm = checkIsValidFlightInfo() && isNotEmpty && Object.values(prevValidateInputData[index]).every(item => item) && checkIsValidItinerary(index) && checkIsValidPaxPricing(-1);

                prevValidateInputData[index].isValidArrivalTime = isNotEmpty;
                prevValidateInputData[index].isTouchedArrivalTime = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidItinerary: prevValidateInputData });
                break;
            }
            case 'arrivalAirport': {
                delete prevValidateInputData[index].isValidArrivalAirport;
                delete prevValidateInputData[index].isTouchedArrivalAirport;

                isValidForm = checkIsValidFlightInfo() && isNotEmpty && Object.values(prevValidateInputData[index]).every(item => item) && checkIsValidItinerary(index) && checkIsValidPaxPricing(-1);

                prevValidateInputData[index].isValidArrivalAirport = isNotEmpty;
                prevValidateInputData[index].isTouchedArrivalAirport = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidItinerary: prevValidateInputData });
                break;
            }
            case 'marketingCarrier': {
                delete prevValidateInputData[index].isValidMarketingCarrier;
                delete prevValidateInputData[index].isTouchedMarketingCarrier;

                isValidForm = checkIsValidFlightInfo() && isNotEmpty && Object.values(prevValidateInputData[index]).every(item => item) && checkIsValidItinerary(index) && checkIsValidPaxPricing(-1);

                prevValidateInputData[index].isValidMarketingCarrier = isSelectedOption;
                prevValidateInputData[index].isTouchedMarketingCarrier = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidItinerary: prevValidateInputData });
                break;
            }
            case 'flightNumber': {
                delete prevValidateInputData[index].isValidFlightNumber;
                delete prevValidateInputData[index].isTouchedFlightNumber;

                isValidForm = checkIsValidFlightInfo() && isNotEmpty && Object.values(prevValidateInputData[index]).every(item => item) && checkIsValidItinerary(index) && checkIsValidPaxPricing(-1);

                prevValidateInputData[index].isValidFlightNumber = isNotEmpty;
                prevValidateInputData[index].isTouchedFlightNumber = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidItinerary: prevValidateInputData });
                break;
            }
            default:
                break;
        }
    }

    function onAddItineraryStep() {
        let updateItinerary = [...flightDetail.itinerary];

        var newItinerary = { ...flightItineraryDefault };
        newItinerary.id = updateItinerary[updateItinerary.length - 1].id + 1;
        updateItinerary.push(newItinerary);

        setFlightDetail({ ...flightDetail, itinerary: updateItinerary });
        let updateValidItinerary = [...validateInputData.isValidItinerary];
        updateValidItinerary.push(validateFlightItineraryDefault);
        setValidateInputData({ ...validateInputData, isValidForm: false, isValidItinerary: updateValidItinerary });
    }

    function onRemoveItineraryStep(removeLegIndex) {
        let updateItinerary = [...flightDetail.itinerary];
        updateItinerary.splice(removeLegIndex, 1);

        setFlightDetail({ ...flightDetail, itinerary: updateItinerary });

        let updateValidItinerary = [...validateInputData.isValidItinerary];
        updateValidItinerary.splice(removeLegIndex, 1);

        setValidateInputData({
            ...validateInputData,
            isValidForm: checkIsValidFlightInfo() && checkIsValidItinerary(updateValidItinerary.length - 1, 1),
            isValidItinerary: updateValidItinerary
        });
    }

    function onBlurSegmentEvent(event, index, indexS, propertyName) {
        let updateItinerary = [...flightDetail.itinerary];
        let newValue = event.target.value;

        updateItinerary[index].segments[indexS][propertyName] = newValue;

        switch (propertyName) {
            case 'departureDate':
            case 'arrivalDate': {
                for (var i = 0; i < updateItinerary[index].segments.length; i++) {
                    var newDate = new Date(event.target.value);

                    if (i > indexS) {
                        if (updateItinerary[index].segments[i].departureDate && new Date(updateItinerary[index].segments[i].departureDate) < newDate) {
                            updateItinerary[index].segments[i].departureDate = '';
                        }

                        if (updateItinerary[index].segments[i].arrivalDate && new Date(updateItinerary[index].segments[i].arrivalDate) < newDate) {
                            updateItinerary[index].segments[i].arrivalDate = '';
                        }
                    } else if (i === indexS) {
                        if (updateItinerary[index].segments[i].arrivalDate && new Date(updateItinerary[index].segments[i].arrivalDate) < newDate) {
                            updateItinerary[index].segments[i].arrivalDate = '';
                        }
                    }
                }

                break;
            }

            default: break;
        }

        var updateFlightDetail = { ...flightDetail, itinerary: updateItinerary };
        setFlightDetail(updateFlightDetail);
        checkValidateInputDataItinerarySegment(newValue, index, indexS, propertyName);
    }

    function convertMinDateFlight(date) {
        if (!date)
            return date;

        let dateParts = date.split('-');
        let dateTime = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

        let yesterday = new Date(dateTime.setDate(dateTime.getDate() - 1));

        let normMonth = yesterday.getMonth() + 1;
        if (normMonth < 10)
            normMonth = 0 + "" + normMonth;

        let normDay = yesterday.getDate();
        if (normDay < 10)
            normDay = 0 + "" + normDay;

        return yesterday.getFullYear() + "-" + normMonth + "-" + normDay;
    }

    function checkValidateInputDataItinerarySegment(value, index, indexS, propertyName) {
        let isValidForm = { ...validateInputData.isValidForm };
        let prevValidateInputData = [...validateInputData.isValidItinerary];

        const isNotEmpty = (value !== '' && value !== undefined);
        const isSelectedOption = (value !== 'NOT' && value !== undefined);
        var prevSegmentValid = prevValidateInputData[index].isValidSegment[indexS];

        switch (propertyName) {
            case 'departureDate': {
                delete prevSegmentValid.isValidDepartureDate;
                delete prevSegmentValid.isTouchedDepartureDate;

                let isValidDate = isNotEmpty && (new Date(value) !== "Invalid Date") && new Date(value) >= new Date(today);
                if (index > 0) {
                    //const prevDate = new Date(flightDetail.itinerary[index - 1].arrivalDate);
                    //isValidDate = isValidDate && new Date(value) >= prevDate;
                }

                isValidForm = checkIsValidFlightInfo() && isValidDate && Object.values(prevSegmentValid).every(item => item) && checkIsValidItinerary(index) && checkIsValidPaxPricing(-1);

                prevSegmentValid.isValidDepartureDate = isValidDate;
                prevSegmentValid.isTouchedDepartureDate = true;

                let newValidateInputData = { ...validateInputData, isValidForm: isValidForm, isValidItinerary: prevValidateInputData };

                setValidateInputData(newValidateInputData);
                break;
            }
            case 'departureTime': {
                delete prevSegmentValid.isValidDepartureTime;
                delete prevSegmentValid.isTouchedDepartureTime;

                isValidForm = checkIsValidFlightInfo() && isNotEmpty && Object.values(prevSegmentValid).every(item => item) && checkIsValidItinerary(index) && checkIsValidPaxPricing(-1);

                prevSegmentValid.isValidDepartureTime = isNotEmpty;
                prevSegmentValid.isTouchedDepartureTime = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidItinerary: prevValidateInputData });
                break;
            }
            case 'departureAirport': {
                delete prevSegmentValid.isValidDepartureAirport;
                delete prevSegmentValid.isTouchedDepartureAirport;

                isValidForm = checkIsValidFlightInfo() && isNotEmpty && Object.values(prevSegmentValid).every(item => item) && checkIsValidItinerary(index) && checkIsValidPaxPricing(-1);

                prevSegmentValid.isValidDepartureAirport = isNotEmpty;
                prevSegmentValid.isTouchedDepartureAirport = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidItinerary: prevValidateInputData });
                break;
            }
            case 'arrivalDate': {
                delete prevSegmentValid.isValidArrivalDate;
                delete prevSegmentValid.isTouchedArrivalDate;

                let isValidDate = isNotEmpty && (new Date(value) !== "Invalid Date") && new Date(value) >= new Date(today);
                if (index > -1) {
                    //const prevDate = new Date(flightDetail.itinerary[index].departureDate);
                    //isValidDate = isValidDate && new Date(value) >= prevDate;
                }

                isValidForm = checkIsValidFlightInfo() && isValidDate && Object.values(prevSegmentValid).every(item => item) && checkIsValidItinerary(index) && checkIsValidPaxPricing(-1);

                prevSegmentValid.isValidArrivalDate = isValidDate;
                prevSegmentValid.isTouchedArrivalDate = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidItinerary: prevValidateInputData });
                break;
            }
            case 'arrivalTime': {
                delete prevSegmentValid.isValidArrivalTime;
                delete prevSegmentValid.isTouchedArrivalTime;

                isValidForm = checkIsValidFlightInfo() && isNotEmpty && Object.values(prevSegmentValid).every(item => item) && checkIsValidItinerary(index) && checkIsValidPaxPricing(-1);

                prevSegmentValid.isValidArrivalTime = isNotEmpty;
                prevSegmentValid.isTouchedArrivalTime = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidItinerary: prevValidateInputData });
                break;
            }
            case 'arrivalAirport': {
                delete prevSegmentValid.isValidArrivalAirport;
                delete prevSegmentValid.isTouchedArrivalAirport;

                isValidForm = checkIsValidFlightInfo() && isNotEmpty && Object.values(prevSegmentValid).every(item => item) && checkIsValidItinerary(index) && checkIsValidPaxPricing(-1);

                prevSegmentValid.isValidArrivalAirport = isNotEmpty;
                prevSegmentValid.isTouchedArrivalAirport = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidItinerary: prevValidateInputData });
                break;
            }
            case 'marketingCarrier': {
                delete prevSegmentValid.isValidMarketingCarrier;
                delete prevSegmentValid.isTouchedMarketingCarrier;

                isValidForm = checkIsValidFlightInfo() && isNotEmpty && Object.values(prevSegmentValid).every(item => item) && checkIsValidItinerary(index) && checkIsValidPaxPricing(-1);

                prevSegmentValid.isValidMarketingCarrier = isSelectedOption;
                prevSegmentValid.isTouchedMarketingCarrier = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidItinerary: prevValidateInputData });
                break;
            }
            case 'flightNumber': {
                delete prevSegmentValid.isValidFlightNumber;
                delete prevSegmentValid.isTouchedFlightNumber;

                isValidForm = checkIsValidFlightInfo() && isNotEmpty && Object.values(prevSegmentValid).every(item => item) && checkIsValidItinerary(index) && checkIsValidPaxPricing(-1);

                prevSegmentValid.isValidFlightNumber = isNotEmpty;
                prevSegmentValid.isTouchedFlightNumber = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidItinerary: prevValidateInputData });
                break;
            }
            default:
                break;
        }
    }


    function onAddSegment(legIndex) {
        let updateItinerary = [...flightDetail.itinerary];
        let updateValidItinerary = [...validateInputData.isValidItinerary];

        let newSegment = { ...flightSegmentDefault };
        let validateSegment = { ...validateFlightSegmentDefault };
        let isFirstSegmentToAdd = updateItinerary[legIndex].segments.length === 0;

        let lastSegmentIndex = 1;
        if (updateItinerary[legIndex].segments.length > 0)
            lastSegmentIndex = updateItinerary[legIndex].segments[updateItinerary[legIndex].segments.length - 1].id;

        newSegment.id = lastSegmentIndex + 1;
        newSegment.departureDate = updateItinerary[legIndex].departureDate;
        newSegment.departureTime = updateItinerary[legIndex].departureTime;
        newSegment.arrivalDate = updateItinerary[legIndex].arrivalDate;

        validateSegment.isValidDepartureDate = true;
        validateSegment.isTouchedDepartureDate = true;
        validateSegment.isValidDepartureTime = true;
        validateSegment.isTouchedDepartureTime = true;
        validateSegment.isValidArrivalDate = true;
        validateSegment.isTouchedArrivalDate = true;

        if (isFirstSegmentToAdd) {
            newSegment.departureAirport = updateItinerary[legIndex].departureAirport;

            validateSegment.isValidDepartureAirport = true;
            validateSegment.isTouchedDepartureAirport = true;
        }

        updateItinerary[legIndex].segments.push(newSegment);
        updateValidItinerary[legIndex].isValidSegment.push(validateSegment);

        if (isFirstSegmentToAdd) {
            let returnSegment = { ...newSegment }
            let returnValidateSegment = { ...validateSegment }
            returnSegment.id++;
            returnSegment.departureDate = updateItinerary[legIndex].departureDate;
            returnSegment.arrivalDate = updateItinerary[legIndex].arrivalDate;
            returnSegment.departureAirport = {};
            returnSegment.arrivalAirport = updateItinerary[legIndex].arrivalAirport;

            returnValidateSegment.isValidDepartureAirport = false;
            returnValidateSegment.isTouchedDepartureAirport = false;
            validateSegment.isValidDepartureTime = false;
            validateSegment.isTouchedDepartureTime = false;
            returnValidateSegment.isValidArrivalAirport = true;
            returnValidateSegment.isTouchedArrivalAirport = true;

            updateItinerary[legIndex].segments.push(returnSegment);
            updateValidItinerary[legIndex].isValidSegment.push(returnValidateSegment);
        }

        setFlightDetail({ ...flightDetail, itinerary: updateItinerary });
        setValidateInputData({ ...validateInputData, isValidForm: false, isValidItinerary: updateValidItinerary });
    }

    function onRemoveSegment(legIndex, removeSegmentIndex) {
        let updateItinerary = [...flightDetail.itinerary];
        let updateValidItinerary = [...validateInputData.isValidItinerary];

        if (updateItinerary[legIndex].segments.length > 2) {
            updateItinerary[legIndex].segments.splice(removeSegmentIndex, 1);
            updateValidItinerary[legIndex].isValidSegment.splice(removeSegmentIndex, 1);
        }
        else {
            updateItinerary[legIndex].segments = [];
            updateValidItinerary[legIndex].isValidSegment = [];
        }

        setFlightDetail({ ...flightDetail, itinerary: updateItinerary });
        setValidateInputData({
            ...validateInputData,
            isValidForm: checkIsValidFlightInfo() && checkIsValidItinerary(updateValidItinerary.length - 1, 1),
            isValidItinerary: updateValidItinerary
        });
    }

    function onBlurPaxPricingEvent(event, index, propertyName) {
        let updatePaxPricing = [...flightDetail.paxPricing];

        let newValue = '';
        if (propertyName === 'buyPriceCurrency' || propertyName === 'paxType') {
            newValue = event.key;
            if (!newValue)
                newValue = event;
        } else {
            newValue = event.target.value;
        }

        if (propertyName === 'buyPriceChange' && !newValue) {
            newValue = null;
        }

        if (propertyName === 'paxType') {
            let defaultAge = null;
            switch (newValue) {

                case 'CHD': {
                    defaultAge = "6";
                    break;
                }

                case 'INF': {
                    defaultAge = "0";
                    break;
                }
            }

            updatePaxPricing[index]['age'] = defaultAge;
        }

        if (propertyName === 'buyPriceCurrency') {
            let exchangeRate = 1;
            if (currencies && currencies.length > 0) {
                let currency = currencies.find(element => element.value === newValue);
                if (currency)
                    exchangeRate = currency.rate;
            }

            for (let i = 0; i < updatePaxPricing.length; i++) {
                updatePaxPricing[i][propertyName] = newValue;
                updatePaxPricing[i]['buyPriceChange'] = exchangeRate;
            }
        } else if (propertyName === 'buyPriceChange') {
            for (let i = 0; i < updatePaxPricing.length; i++) {
                updatePaxPricing[i]['buyPriceChange'] = newValue;
            }
        }

        updatePaxPricing[index][propertyName] = newValue;
        setFlightDetail({ ...flightDetail, paxPricing: updatePaxPricing });
        checkValidateInputDataPaxPricing(newValue, index, propertyName);
    }

    function checkValidateInputDataPaxPricing(value, index, propertyName) {
        let isValidForm = { ...validateInputData.isValidForm };
        let prevValidateInputData = [...validateInputData.isValidPaxPricing];

        const isNotEmpty = (value !== '' && value !== undefined);
        switch (propertyName) {
            case 'buyPriceAmount': {
                delete prevValidateInputData[index].isValidBuyPriceAmount;
                delete prevValidateInputData[index].isTouchedBuyPriceAmount;

                isValidForm = checkIsValidFlightInfo() && isNotEmpty && Object.values(prevValidateInputData[index]).every(item => item) && checkIsValidItinerary(-1) && checkIsValidPaxPricing(-1);

                prevValidateInputData[index].isValidBuyPriceAmount = isNotEmpty;
                prevValidateInputData[index].isTouchedBuyPriceAmount = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidPaxPricing: prevValidateInputData });
                break;
            }
            default:
                break;
        }
    }

    function checkIsValidFlightInfo() {
        return validateInputData.isValidProviderId;
    }

    function checkIsValidItinerary(exludeIndex) {
        if (!validateInputData.isValidItinerary)
            return false;

        var isValid = true;
        for (var i = 0; i < validateInputData.isValidItinerary.length; i++) {
            if (i !== exludeIndex) {
                var isValidSegment = checkIsValidSegment(validateInputData.isValidItinerary[i].isValidSegment, -1);

                isValid = isValid && isValidSegment && Object.values(validateInputData.isValidItinerary[i]).every(x => x);
            }
        }

        return isValid;
    }

    function checkIsValidSegment(segments, exludeIndex) {
        if (!segments || segments.length === 0)
            return true;

        var isValid = true;
        for (var i = 0; i < segments.length; i++) {
            if (i !== exludeIndex) {
                isValid = isValid && Object.values(segments[i]).every(x => x);
            }
        }

        return isValid;
    }

    function addPaxPricing() {
        let updatePaxPricing = [...flightDetail.paxPricing];

        var newCabinPricing = { ...paxPricingDefault };
        newCabinPricing.buyPriceCurrency = updatePaxPricing[0].buyPriceCurrency;
        newCabinPricing.buyPriceChange = updatePaxPricing[0].buyPriceChange;

        newCabinPricing.id = updatePaxPricing.length;
        updatePaxPricing.push(newCabinPricing);

        setFlightDetail({ ...flightDetail, paxPricing: updatePaxPricing });
        let updateValidPaxPricing = [...validateInputData.isValidPaxPricing];
        updateValidPaxPricing.push(validatePaxPricingDefault);
        setValidateInputData({ ...validateInputData, isValidForm: false, isValidPaxPricing: updateValidPaxPricing });
    }

    function removePaxPricing() {
        let updatePaxPricing = [...flightDetail.paxPricing];

        updatePaxPricing.splice(updatePaxPricing.length - 1);

        setFlightDetail({ ...flightDetail, paxPricing: updatePaxPricing });

        let updateValidPaxPricing = [...validateInputData.isValidPaxPricing];
        updateValidPaxPricing.splice(updateValidPaxPricing.length - 1);
        setValidateInputData({
            ...validateInputData,
            isValidForm: checkIsValidFlightInfo() && checkIsValidItinerary(-1) && checkIsValidPaxPricing(updateValidPaxPricing.length - 1),
            isValidPaxPricing: updateValidPaxPricing
        });
    }

    function checkIsValidPaxPricing(exludeIndex) {
        if (!validateInputData.isValidPaxPricing)
            return false;

        var isValid = true;
        for (var i = 0; i < validateInputData.isValidPaxPricing.length; i++) {
            if (i !== exludeIndex) {
                isValid = isValid && Object.values(validateInputData.isValidPaxPricing[i]).every(x => x);
            }
        }

        return isValid;
    }

    function importPNR() {
        setIsLoadingImportPNR(true);
        setIsErrorImportPNR(false);

        const param = {
            pnr: flightDetail.reservationId,
            quotationItemId: flightDetail.id,
            providerId: flightDetail.providerId
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(param)
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}flight/importpnr`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                createFlightValidateAllTrue(item, true);
                setFlightDetail(item);
                setIsLoadingImportPNR(false);
            })
            .catch((err) => {
                console.error(err);
                setIsErrorImportPNR(true);
                setIsLoadingImportPNR(false);
            });
    }

    function importPNRFromFile() {
        let myBtn = document.getElementById("openFlightImportPNRFromFile");
        myBtn.click();
    }

    function callOnSelectAirport(item, refValue) {
        let updateItinerary = [...flightDetail.itinerary];
        let newValue = { text: item.Text, webId: item.IataCode, };
        const index = refValue.split(',')[0];
        const propertyName = refValue.split(',')[1];
        updateItinerary[index][propertyName] = newValue;

        setFlightDetail({ ...flightDetail, itinerary: updateItinerary });
        checkValidateInputDataItinerary(newValue, index, propertyName);
    }

    function callOnSelectSegmentAirport(item, refValue) {
        let updateItinerary = [...flightDetail.itinerary];
        let newValue = { text: item.Text, webId: item.IataCode, };
        const index = refValue.split(',')[0];
        const indexS = refValue.split(',')[1];
        const propertyName = refValue.split(',')[2];
        updateItinerary[index].segments[indexS][propertyName] = newValue;

        setFlightDetail({ ...flightDetail, itinerary: updateItinerary });
        checkValidateInputDataItinerarySegment(newValue, index, indexS, propertyName);
    }

    function saveFlightManual() {
        props.callAddManualQuotationItem(flightDetail);
    }

    function callOnSaveImportPNRFromFile() {
        setIsLoadingImportPNRFromFile(true);
        setIsErrorImportPNR(false);

        const inputData = {
            quotationItemId: flightDetail.id,
            legs: legsTextImport.current.value.trim(),
            travelers: travelersTextImport.current.value.trim(),
            fare: faresTextImport.current.value.trim(),
            providerId: flightDetail.providerId
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        let apiName = 'ImportPNRAmadeus';
        if (flightDetail.providerId === '12189')
            apiName = 'ImportPNRSabre';

        fetch(`${configData.Settings.CommonApi_BaseUrl}flight/${apiName}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                let myFlightItem = item;
                let isValidFare = true;

                if (!myFlightItem.paxPricing || myFlightItem.paxPricing.length === 0) {
                    myFlightItem.paxPricing = [{ ...paxPricingDefault }];
                    isValidFare = false;
                }

                createFlightValidateAllTrue(myFlightItem, isValidFare);
                setFlightDetail(myFlightItem);
                setIsLoadingImportPNRFromFile(false);

                let myBtn = document.getElementById("closeFlightImportPNRFromFile");
                myBtn.click();

                legsTextImport.current.value = "";
                travelersTextImport.current.value = "";
                faresTextImport.current.value = "";
            })
            .catch((err) => {
                setErrorMsgPNRFromFile("Si è verificato un errore, ricontrollare i dati inseriti.");
                console.error(err);
                setIsErrorImportPNRFromFile(true);
                setIsLoadingImportPNRFromFile(false);
            });
    }

    return (
        <>
            <div className="p-4">
                <div className="row">
                    <h4>Volo</h4>
                </div>
                <div className="row pt-2">
                    <div className="col-md-3">
                        <div className={"form-floating" + (!validateInputData.isValidProviderId ? " insert-danger" : "")} >
                            <span className="p-float-label form-select-label-m3-react-prime">
                                {
                                    suppliers && suppliers.length > 0 && <Dropdown value={flightDetail.providerId}
                                        onChange={(e) => onBlurEvent(e.value, 'providerId')}
                                        inputId="dd-provider"
                                        filter
                                        className="w-100 form-select-m3-react-prime"
                                        options={suppliers}
                                        optionLabel="label" />
                                }
                                <label htmlFor="dd-provider">Fornitore *</label>
                            </span>
                            {
                                validateInputData.isTouchedProviderId && !validateInputData.isValidProviderId && <div className="text-danger">Il campo fornitore è obbligatorio</div>
                            }
                        </div>
                    </div>
                    <div className="form-floating col-md-2">
                        <input type="text" className="form-control" value={flightDetail.reservationId === null ? '' : flightDetail.reservationId} id="floatingInput" placeholder="IdRecordLocator" onChange={(e) => { onBlurEvent(e, 'reservationId'); }} />
                        <label htmlFor="floatingInput" className="active">PNR:</label>
                        {
                            isLoadingImportPNR && <div className="spinner-border" role="status"><span className="sr-only"></span></div>
                        }
                        {
                            !isLoadingImportPNR && isErrorImportPNR && <div className="text-danger">Volo non trovato</div>
                        }
                    </div>
                    <div className="form-floating col-md-1 mt-4">
                        <button className="btn btn-sm btn-gray-400"
                            disabled={!(flightDetail.reservationId !== null && flightDetail.providerId !== 'NOT' && !isLoadingImportPNR)}
                            onClick={importPNR}
                        >Import</button>
                    </div>
                    <div className="form-floating col-md-4 mt-4 ml-2">
                        <button id="importFromFileBtn" className="btn btn-sm btn-gray-400"
                            disabled={(flightDetail.providerId === 'NOT')}
                            onClick={importPNRFromFile}>Import from file</button>
                    </div>
                </div>

                <div className="row pt-4">
                    <label>Itinerario: il primo step è obbligatorio</label>
                </div>
                {
                    flightDetail.itinerary.map((item, key) => {
                        return <div key={key} className="row pt-2 pb-3 border-bottom border-light">
                            <div className="col-12"><label>Volo {key + 1}:</label></div>
                            <div className={"form-floating col-md-2" + (!validateInputData.isValidItinerary[key].isValidDepartureDate ? " insert-danger" : "")}>
                                <input type="date"
                                    className="form-control"
                                    value={item.departureDate}
                                    min={key > 0 ? flightDetail.itinerary[key - 1].arrivalDate : today}
                                    onChange={(e) => onBlurItineraryEvent(e, key, "departureDate")}
                                    onBlur={(e) => onBlurItineraryEvent(e, key, "departureDate")}
                                    id="floatingInput"
                                    placeholder="01/01/99" />
                                <label htmlFor="floatingInput">D. Partenza *</label>
                                {
                                    validateInputData.isValidItinerary[key].isTouchedDepartureDate && !validateInputData.isValidItinerary[key].isValidDepartureDate && <div className="text-danger">Il campo data partenza non è valido</div>
                                }
                            </div>
                            <div className={"form-floating col-md-2" + (!validateInputData.isValidItinerary[key].isValidDepartureTime ? " insert-danger" : "")}>
                                <input type="time"
                                    className="form-control"
                                    value={item.departureTime}
                                    onChange={(e) => onBlurItineraryEvent(e, key, "departureTime")}
                                    onBlur={(e) => onBlurItineraryEvent(e, key, "departureTime")}
                                    id="floatingInput"
                                    placeholder="01/01/99" />
                                <label htmlFor="floatingInput">H. Partenza *</label>
                                {
                                    validateInputData.isValidItinerary[key].isTouchedDepartureTime && !validateInputData.isValidItinerary[key].isValidDepartureTime && <div className="text-danger">Il campo ora partenza è obbligatorio</div>
                                }
                            </div>
                            <div className={"form-floating col-md-2" + (!validateInputData.isValidItinerary[key].isValidDepartureAirport ? " insert-danger" : "")}>
                                <input type="text"
                                    className="form-control"
                                    id="floatingInput"
                                    value={item.departureAirport && item.departureAirport.text ? item.departureAirport.text : ''}
                                    data-bs-toggle="modal"
                                    data-bs-target={"#FDM_Manual_City_" + key}
                                    placeholder="Città * (Applica regole)"
                                    readOnly />
                                <label htmlFor="floatingInput" className="active">
                                    Aeroporto P.
                                </label>

                                <InputSearchDestinationModal
                                    modalId={"FDM_Manual_City_" + key}
                                    productType={configData.Settings.Products.Flight.IdTipoProdotto}
                                    callOnSelectDestination={callOnSelectAirport}
                                    index={key + ",departureAirport"}
                                    rules={{ enableAirport: true, enableCity: true }} />
                                {
                                    validateInputData.isValidItinerary[key].isTouchedDepartureAirport && !validateInputData.isValidItinerary[key].isValidDepartureAirport && <div className="text-danger">Il campo città è obbligatorio</div>
                                }
                            </div>
                            <div className={"form-floating col-md-2" + (!validateInputData.isValidItinerary[key].isValidArrivalDate ? " insert-danger" : "")}>
                                <input type="date"
                                    className="form-control"
                                    value={item.arrivalDate}
                                    min={flightDetail.itinerary[key].departureDate ? convertMinDateFlight(flightDetail.itinerary[key].departureDate) : today}
                                    onChange={(e) => onBlurItineraryEvent(e, key, "arrivalDate")}
                                    onBlur={(e) => onBlurItineraryEvent(e, key, "arrivalDate")}
                                    id="floatingInput"
                                    placeholder="01/01/99" />
                                <label htmlFor="floatingInput">D. Arrivo *</label>
                                {
                                    validateInputData.isValidItinerary[key].isTouchedArrivalDate && !validateInputData.isValidItinerary[key].isValidArrivalDate && <div className="text-danger">Il campo data arrivo non è valido</div>
                                }
                            </div>
                            <div className={"form-floating col-md-2" + (!validateInputData.isValidItinerary[key].isValidArrivalTime ? " insert-danger" : "")}>
                                <input type="time"
                                    className="form-control"
                                    value={item.arrivalTime}
                                    onChange={(e) => onBlurItineraryEvent(e, key, "arrivalTime")}
                                    onBlur={(e) => onBlurItineraryEvent(e, key, "arrivalTime")}
                                    id="floatingInput"
                                    placeholder="01/01/99" />
                                <label htmlFor="floatingInput">H. Arrivo *</label>
                                {
                                    validateInputData.isValidItinerary[key].isTouchedArrivalTime && !validateInputData.isValidItinerary[key].isValidArrivalTime && <div className="text-danger">Il campo ora arrivo è obbligatorio</div>
                                }
                            </div>
                            <div className={"form-floating col-md-2" + (!validateInputData.isValidItinerary[key].isValidArrivalAirport ? " insert-danger" : "")}>
                                <input type="text"
                                    className="form-control"
                                    id="floatingInput"
                                    value={item.departureAirport && item.arrivalAirport.text ? item.arrivalAirport.text : ''}
                                    data-bs-toggle="modal"
                                    data-bs-target={"#FAM_Manual_City" + key}
                                    placeholder="Città * (Applica regole)"
                                    readOnly />
                                <label htmlFor="floatingInput" className="active">
                                    Aeroporto A.
                                </label>

                                <InputSearchDestinationModal
                                    modalId={"FAM_Manual_City" + key}
                                    productType={configData.Settings.Products.Flight.IdTipoProdotto}
                                    callOnSelectDestination={callOnSelectAirport}
                                    index={key + ",arrivalAirport"}
                                    rules={{ enableAirport: true, enableCity: true }} />
                                {
                                    validateInputData.isValidItinerary[key].isTouchedArrivalAirport && !validateInputData.isValidItinerary[key].isValidArrivalAirport && <div className="text-danger">Il campo città è obbligatorio</div>
                                }
                            </div>
                            <div className={"form-floating col-md-3 mt-2" + (!validateInputData.isValidItinerary[key].isValidMarketingCarrier ? " insert-danger" : "")}>

                                {
                                    airlines && airlines.length > 0 && <Dropdown value={item.marketingCarrier}
                                        onChange={(e) => onBlurItineraryEvent(e.value, key, 'marketingCarrier')}
                                        inputId="dd-marketingCarrier"
                                        filter
                                        className="w-100 form-select-m3-react-prime"
                                        options={airlines}
                                        optionLabel="label" />
                                }
                                <label htmlFor="dd-marketingCarrier">Compagnia aerea *</label>

                                {
                                    validateInputData.isValidItinerary[key].isTouchedMarketingCarrier && !validateInputData.isValidItinerary[key].isValidMarketingCarrier && <div className="text-danger">Il campo compagnia area è obbligatorio</div>
                                }
                            </div>
                            <div className={"form-floating col-md-2 mt-2" + (!validateInputData.isValidItinerary[key].isTouchedFlightNumber ? " insert-danger" : "")}>
                                <input type="text"
                                    className="form-control"
                                    value={item.flightNumber}
                                    onChange={(e) => onBlurItineraryEvent(e, key, "flightNumber")}
                                    onBlur={(e) => onBlurItineraryEvent(e, key, "flightNumber")}
                                    id="floatingInput"
                                    placeholder="01/01/99" />
                                <label htmlFor="floatingInput">N. volo. *</label>
                                {
                                    validateInputData.isValidItinerary[key].isTouchedFlightNumber && !validateInputData.isValidItinerary[key].isValidFlightNumber && <div className="text-danger">Il campo compagnia area è obbligatorio</div>
                                }
                            </div>
                            <div className="form-floating col-md-2 mt-2">
                                <input type="text"
                                    className="form-control"
                                    value={item.bookingClass}
                                    onChange={(e) => onBlurItineraryEvent(e, key, "bookingClass")}
                                    id="floatingInput"
                                    placeholder="01/01/99" />
                                <label htmlFor="floatingInput">Classe </label>
                            </div>
                            <div className="form-floating col-md-2 mt-2">
                                <input type="number"
                                    className="form-control"
                                    id="floatingInput"
                                    value={item.bagagge === null ? '' : item.bagagge}
                                    onChange={(e) => onBlurItineraryEvent(e, key, 'bagagge')}
                                    placeholder="123.45" />
                                <label htmlFor="floatingInput" className="active">Bagagli</label>
                            </div>
                            <div className="form-floating col-md-2 mt-2">
                                <input type="number"
                                    className="form-control"
                                    value={item.weight === null ? '' : item.weight}
                                    onChange={(e) => onBlurItineraryEvent(e, key, 'weight')}
                                    id="floatingInput"
                                    placeholder="123.45" />
                                <label htmlFor="floatingInput" className="active">Totale KG Bg.</label>
                            </div>
                            <div className="form-floating col-md-12 mt-2">
                                <div className="row pt-2 pb-2 m-0">
                                    <div className="col-10">
                                        Scali:
                                    </div>
                                    <div className="col-2 text-end">
                                        {item.segments.length === 0 ? <button className="btn btn-sm tp-btn-add" onClick={e => onAddSegment(key)}><M3Icon iconName="Add" externalClass="h085" /> Scali</button> : ""}
                                    </div>
                                    {
                                        item.segments && item.segments.length > 0 && item.segments.map((seg, keyS) => {
                                            return <div key={keyS} className="col-12">
                                                <div className="row pt-2 pb-3 border-bottom border-light">
                                                    <div className={"form-floating col-md-2" + (!validateInputData.isValidItinerary[key].isValidSegment[keyS].isValidDepartureDate ? " insert-danger" : "")}>
                                                        <input type="date"
                                                            className="form-control"
                                                            value={seg.departureDate}
                                                            min={keyS > 0 ? item.segments[keyS - 1].arrivalDate : item.arrivalDate}
                                                            onChange={(e) => onBlurSegmentEvent(e, key, keyS, "departureDate")}
                                                            onBlur={(e) => onBlurSegmentEvent(e, key, keyS, "departureDate")}
                                                            id="floatingInput"
                                                            placeholder="01/01/99" />
                                                        <label htmlFor="floatingInput">D. Partenza *</label>
                                                    </div>
                                                    <div className={"form-floating col-md-2" + (!validateInputData.isValidItinerary[key].isValidSegment[keyS].isValidDepartureTime ? " insert-danger" : "")}>
                                                        <input type="time"
                                                            className="form-control"
                                                            value={seg.departureTime}
                                                            onChange={(e) => onBlurSegmentEvent(e, key, keyS, "departureTime")}
                                                            onBlur={(e) => onBlurSegmentEvent(e, key, keyS, "departureTime")}
                                                            id="floatingInput"
                                                            placeholder="01/01/99" />
                                                        <label htmlFor="floatingInput">H. Partenza *</label>
                                                    </div>
                                                    <div className={"form-floating col-md-2" + (!validateInputData.isValidItinerary[key].isValidSegment[keyS].isValidDepartureAirport ? " insert-danger" : "")}>
                                                        <input type="text"
                                                            className="form-control"
                                                            id="floatingInput"
                                                            value={seg.departureAirport && seg.departureAirport.text ? seg.departureAirport.text : ''}
                                                            data-bs-toggle="modal"
                                                            data-bs-target={"#FDM_Manual_City_" + key + "_segment_" + keyS}
                                                            placeholder="Città * (Applica regole)"
                                                            readOnly />
                                                        <label htmlFor="floatingInput" className="active">
                                                            Aeroporto P.
                                                        </label>

                                                        <InputSearchDestinationModal
                                                            modalId={"FDM_Manual_City_" + key + "_segment_" + keyS}
                                                            productType={configData.Settings.Products.Flight.IdTipoProdotto}
                                                            callOnSelectDestination={callOnSelectSegmentAirport}
                                                            index={key + "," + keyS + ",departureAirport"}
                                                            rules={{ enableAirport: true, enableCity: true }} />
                                                    </div>
                                                    <div className={"form-floating col-md-2" + (!validateInputData.isValidItinerary[key].isValidSegment[keyS].isValidArrivalDate ? " insert-danger" : "")}>
                                                        <input type="date"
                                                            className="form-control"
                                                            value={seg.arrivalDate}
                                                            min={seg.departureDate ? convertMinDateFlight(seg.departureDate) : today}
                                                            onChange={(e) => onBlurSegmentEvent(e, key, keyS, "arrivalDate")}
                                                            onBlur={(e) => onBlurSegmentEvent(e, key, keyS, "arrivalDate")}
                                                            id="floatingInput"
                                                            placeholder="01/01/99" />
                                                        <label htmlFor="floatingInput">D. Arrivo *</label>
                                                    </div>
                                                    <div className={"form-floating col-md-2" + (!validateInputData.isValidItinerary[key].isValidSegment[keyS].isValidArrivalTime ? " insert-danger" : "")}>
                                                        <input type="time"
                                                            className="form-control"
                                                            value={seg.arrivalTime}
                                                            onChange={(e) => onBlurSegmentEvent(e, key, keyS, "arrivalTime")}
                                                            onBlur={(e) => onBlurSegmentEvent(e, key, keyS, "arrivalTime")}
                                                            id="floatingInput"
                                                            placeholder="01/01/99" />
                                                        <label htmlFor="floatingInput">H. Arrivo *</label>
                                                    </div>
                                                    <div className={"form-floating col-md-2" + (!validateInputData.isValidItinerary[key].isValidSegment[keyS].isValidArrivalAirport ? " insert-danger" : "")}>
                                                        <input type="text"
                                                            className="form-control"
                                                            id="floatingInput"
                                                            value={seg.departureAirport && seg.arrivalAirport.text ? seg.arrivalAirport.text : ''}
                                                            data-bs-toggle="modal"
                                                            data-bs-target={"#FAM_Manual_City" + key + "_segment_" + keyS}
                                                            placeholder="Città * (Applica regole)"
                                                            readOnly />
                                                        <label htmlFor="floatingInput" className="active">
                                                            Aeroporto A.
                                                        </label>

                                                        <InputSearchDestinationModal
                                                            modalId={"FAM_Manual_City" + key + "_segment_" + keyS}
                                                            productType={configData.Settings.Products.Flight.IdTipoProdotto}
                                                            callOnSelectDestination={callOnSelectSegmentAirport}
                                                            index={key + "," + keyS + ",arrivalAirport"}
                                                            rules={{ enableAirport: true, enableCity: true }} />
                                                    </div>
                                                    <div className={"form-floating col-md-3 mt-2" + (!validateInputData.isValidItinerary[key].isValidSegment[keyS].isValidMarketingCarrier ? " insert-danger" : "")}>
                                                        <select className="form-select"
                                                            id="floatingSelect"
                                                            value={seg.marketingCarrier}
                                                            onChange={(e) => onBlurSegmentEvent(e, key, keyS, "marketingCarrier")}
                                                            onBlur={(e) => onBlurSegmentEvent(e, key, keyS, "marketingCarrier")}
                                                            aria-label="TipoPAX">
                                                            <option key={-1} value="NOT">Seleziona</option>
                                                            {
                                                                airlines.map((item, key) => {
                                                                    return <option key={key} value={item.value}>{item.value} - {item.label}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className={"form-floating col-md-2 mt-2" + (!validateInputData.isValidItinerary[key].isValidSegment[keyS].isValidFlightNumber ? " insert-danger" : "")}>
                                                        <input type="text"
                                                            className="form-control"
                                                            value={seg.flightNumber}
                                                            onChange={(e) => onBlurSegmentEvent(e, key, keyS, "flightNumber")}
                                                            onBlur={(e) => onBlurSegmentEvent(e, key, keyS, "flightNumber")}
                                                            id="floatingInput"
                                                            placeholder="01/01/99" />
                                                        <label htmlFor="floatingInput">N. volo. *</label>
                                                    </div>
                                                    <div className="form-floating col-md-2 mt-2">
                                                        <input type="text"
                                                            className="form-control"
                                                            value={seg.bookingClass}
                                                            onChange={(e) => onBlurSegmentEvent(e, key, keyS, "bookingClass")}
                                                            id="floatingInput"
                                                            placeholder="01/01/99" />
                                                        <label htmlFor="floatingInput">Classe </label>
                                                    </div>
                                                    <div className="form-floating col-md-2 mt-2">
                                                        <input type="number"
                                                            className="form-control"
                                                            id="floatingInput"
                                                            value={seg.bagagge === null ? '' : seg.bagagge}
                                                            onChange={(e) => onBlurSegmentEvent(e, key, keyS, 'bagagge')}
                                                            placeholder="123.45" />
                                                        <label htmlFor="floatingInput" className="active">Bagagli</label>
                                                    </div>
                                                    <div className="form-floating col-md-2 mt-2">
                                                        <input type="number"
                                                            className="form-control"
                                                            value={seg.weight === null ? '' : seg.weight}
                                                            onChange={(e) => onBlurSegmentEvent(e, key, keyS, 'weight')}
                                                            id="floatingInput"
                                                            placeholder="123.45" />
                                                        <label htmlFor="floatingInput" className="active">Totale KG Bg.</label>
                                                    </div>
                                                    <div className="form-floating col-md-1 text-end">
                                                        {
                                                            keyS === (item.segments.length - 1) && <button className="btn btn-sm tp-btn-add mt-4" onClick={e => onAddSegment(key)}>
                                                                <M3Icon iconName="Add" externalClass="h085" /> Scalo
                                                            </button>
                                                        }
                                                        {
                                                            (item.segments.length > 2 || keyS === (item.segments.length - 1)) && <button className="btn btn-sm tp-btn-delete ml-1 mt-4" onClick={e => onRemoveSegment(key, keyS)}>
                                                                <M3Icon iconName="Remove" externalClass="h085" /> Scalo
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="form-floating col-md-12 text-end">
                                {
                                    key === (flightDetail.itinerary.length - 1) && <button className="btn tp-btn-add btn-sm mb-2 mr-2 border" onClick={e => onAddItineraryStep()}>
                                        <M3Icon iconName="Add" externalClass="h085" /> Volo
                                    </button>
                                }
                                {
                                    flightDetail.itinerary.length > 1 && <button className="btn tp-btn-delete btn-sm mb-2 border" onClick={e => onRemoveItineraryStep(key)}>
                                        <M3Icon iconName="Remove" externalClass="h085" /> volo
                                    </button>
                                }
                            </div>
                        </div>
                    })
                }


                <div>
                    <AddServiceNote
                        serviceNote={flightDetail.serviceNoteDesc}
                        roomIndex={-1}
                        noteTypeToAdd={12}
                        propertyName="serviceNoteDesc"
                        onBlurEvent={onBlurEvent} />
                </div>

                <div className="form-floating pt-2">
                    <textarea className="form-control"
                        placeholder="Note Interne"
                        value={flightDetail.internalNote}
                        onChange={(e) => { onBlurEvent(e, 'internalNote'); }}
                        id="floatingTextarea2"
                        style={{ height: '100px' }} ></textarea>
                    <label htmlFor="floatingTextarea2">Note Interne</label>
                </div>

                <div className="badge-gray-600 mt-4">Passeggeri</div>

                {
                    flightDetail.paxPricing.map((item, key) => {
                        return <div key={key} className="row pt-4">
                            <div className="form-floating col-md-2">
                                <div className="form-floating">
                                    {
                                        flightPaxType && flightPaxType.length > 0 && <Dropdown value={item.paxType + ""}
                                            onChange={(e) => onBlurPaxPricingEvent(e.value, key, 'paxType')}
                                            filter
                                            inputId="dd-paxType"
                                            className="w-100 form-select-m3-react-prime"
                                            options={flightPaxType.map(d => { return { label: d.text, value: d.key }; })}
                                            optionLabel="label" />
                                    }
                                    <label htmlFor="dd-paxType">Tipo Passeggero</label>
                                </div>
                            </div>
                            <div className="form-floating col-md-1">
                                <input type="number"
                                    className="form-control px-0"
                                    value={item.age === null ? '' : item.age}
                                    onChange={(e) => onBlurPaxPricingEvent(e, key, 'age')}
                                    id="floatingInput"
                                    placeholder="123.45" />
                                <label htmlFor="floatingInput" className="active">Età </label>
                            </div>
                            <div className={"form-floating col-md-2" + (!validateInputData.isValidPaxPricing[key].isValidBuyPriceAmount ? " insert-danger" : "")}>
                                <input type="number"
                                    className="form-control"
                                    value={item.buyPriceAmount === null ? '' : item.buyPriceAmount}
                                    onChange={(e) => onBlurPaxPricingEvent(e, key, 'buyPriceAmount')}
                                    onBlur={(e) => onBlurPaxPricingEvent(e, key, 'buyPriceAmount')}
                                    id="floatingInput"
                                    placeholder="123.45" />
                                <label htmlFor="floatingInput" className="active">Tariffa no tax *</label>
                                {
                                    validateInputData.isValidPaxPricing[key].isTouchedBuyPriceAmount && !validateInputData.isValidPaxPricing[key].isValidBuyPriceAmount && <div className="text-danger">Il campo costo è obbligatorio</div>
                                }
                            </div>
                            <div className="form-floating col-md-2">
                                <input type="number"
                                    className="form-control"
                                    value={item.taxesAmount === null ? '' : item.taxesAmount}
                                    onChange={(e) => onBlurPaxPricingEvent(e, key, 'taxesAmount')}
                                    id="floatingInput"
                                    placeholder="123.45" />
                                <label htmlFor="floatingInput" className="active">Tax</label>
                            </div>
                            <div className="form-floating col-md-2">
                                <div className="form-floating">

                                    {
                                        currencies && currencies.length > 0 && <Dropdown value={item.buyPriceCurrency}
                                            onChange={(e) => onBlurPaxPricingEvent(e.value, key, 'buyPriceCurrency')}
                                            inputId="dd-buyPriceCurrency"
                                            filter
                                            className="w-100 form-select-m3-react-prime"
                                            options={currencies}
                                            optionLabel="label" />
                                    }
                                    <label htmlFor="dd-buyPriceCurrency">Valuta</label>
                                </div>
                            </div>
                            <div className="form-floating col-md-2">
                                <input type="number"
                                    className="form-control"
                                    id="floatingInput"
                                    value={item.buyPriceChange === null ? '' : item.buyPriceChange}
                                    onChange={(e) => onBlurPaxPricingEvent(e, key, 'buyPriceChange')}
                                    placeholder="123.45" />
                                <label htmlFor="floatingInput" className="active">Tasso di cambio</label>
                            </div>

                            <div className="form-floating col-md-1">
                                {
                                    key > 0 && key === (flightDetail.paxPricing.length - 1) && <button className="btn btn-sm tp-btn-delete mt-4" onClick={removePaxPricing}><M3Icon iconName="Remove" externalClass="h085" /></button>
                                }
                                {
                                    key === (flightDetail.paxPricing.length - 1) && <button className="btn btn-sm tp-btn-add mt-4" onClick={addPaxPricing}><M3Icon iconName="Add" externalClass="h085" /></button>
                                }
                            </div>
                        </div>

                    })
                }

                <div className="mt-2 mb-2">L'età effettiva al momento della partenza deve corrispondere all'età dichiarata al momento dell'imbarco. Età ragazzi 2-12 , Neonati 0-1 compiuti</div>

                <div>
                    <AddServiceNote
                        serviceNote={flightDetail.serviceNote}
                        roomIndex={-1}
                        onBlurEvent={onBlurEvent} />
                </div>

                <div className=" float-end">
                    {props.isError && <span className="text-danger">Errore in fase di inserimento, controllare i dati</span>}
                    <button className="btn tp-btn-add btn-sm" disabled={!validateInputData.isValidForm} onClick={saveFlightManual}>{t('Button:Save')}</button>
                </div>
            </div>

            <a id="openFlightImportPNRFromFile" data-bs-toggle="modal" data-bs-target="#flightImportPNRFromFile"></a>
            <div className='modal fade' id="flightImportPNRFromFile" tabIndex="-1" aria-labelledby="flightImportPNRFromFileLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" style={{ height: '100%' }}>
                    <div className="modal-content" style={{ height: '80%' }}>
                        <div className="modal-header p-0">
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Legs</button>
                                    <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Travelers</button>
                                    <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Fares</button>
                                </div>
                            </nav>
                        </div>
                        <div className="modal-body p-3" style={{ background: '#f5f9fe' }}>
                            <div className="tab-content" id="nav-tabContent" style={{ height: '100%' }}>
                                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" style={{ height: '100%' }}>
                                    <textarea className="form-control" ref={legsTextImport} rows="3" style={{ height: '100%', fontSize: '13px' }} onChange={handleChangeLegsText}></textarea>
                                </div>
                                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" style={{ height: '100%' }}>
                                    <textarea className="form-control" ref={travelersTextImport} rows="3" style={{ height: '100%', fontSize: '13px' }}></textarea>
                                </div>
                                <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" style={{ height: '100%' }}>
                                    <textarea className="form-control" ref={faresTextImport} rows="3" style={{ height: '100%', fontSize: '13px' }}></textarea>
                                </div>
                            </div>
                        </div>
                        {
                            <div className='modal-footer' style={{ background: '#f5f9fe', borderTop: 'none' }}>
                                {
                                    !isLoadingImportPNRFromFile && isErrorImportPNRFromFile && <div className="text-danger">{errorMsgPNRFromFile}</div>
                                }
                                <button id="closeFlightImportPNRFromFile" type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t('Button:Close')}</button>
                                {
                                    isLoadingImportPNRFromFile && <div className="spinner-border" role="status"><span className="sr-only"></span></div>
                                }
                                {
                                    <button type="button" className="btn bt-sm tp-btn-confirm"
                                        disabled={isLoadingImportPNRFromFile || !isLegsTextImport}
                                        onClick={callOnSaveImportPNRFromFile}>Salva
                                    </button>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}