import React, { useEffect, useState, useCallback } from 'react';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";
import { formatPrice, formatDateTime } from '../../../../js/Utils.js';
import Cookies from 'universal-cookie';



export const CarOptions = ({ option, productCodeSelected, idx, handleClickOption, selectedPrice }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");
    const [productCodeOld, setProductCodeOld] = useState(null);
    const [isSelected, setIsSelected] = useState(false);

    const onClickOption = useCallback(event => {
        handleClickOption(event);
    }, [handleClickOption]);

    useEffect(() => {
        if (option) {
            setProductCodeOld(option.productCode);
        }
        if (idx === 0 && !productCodeSelected) {
            setIsSelected(true);
            handleClickOption(option);
        }
    }, []);

    useEffect(() => {
        if (productCodeSelected && productCodeOld === productCodeSelected) {
            setIsSelected(true);
        }
        else if (productCodeSelected && productCodeOld !== productCodeSelected) {
            setIsSelected(false);
        }
    }, [productCodeSelected]);

    return (
        <>
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 mb-1">
                <div className="card border-light bg-gray-300 shadow">
                    <div className="row p-2">
                        <div className="h6 col-12 col-lg-12 mb-0">
                            <var>{option.name}</var>
                        </div>
                        <div className="col-6 col-lg-4 text-right mb-0">
                        </div>
                    </div>

                    <div class="bg-white p-2">
                        <div className="row h075">
                            {option.inclusionDetails && option.inclusionDetails.length > 0 && option.inclusionDetails.map(inclusion =>
                                inclusion.description != null && inclusion.description !== "" &&
                                    <div class="col-12 d-flex">
                                        <data m3ico="Done text-success mr-z2 h5r icon-15">
                                            <M3Icon iconName="Done" externalClass="icon-15 text-success mr-2 h5r icon-15" />
                                        </data>
                                        <var> <label dangerouslySetInnerHTML={{ __html: inclusion.description }} /></var>
                                    </div>
                            )}

                            {option.exclusionDetails && option.exclusionDetails.length > 0 && option.exclusionDetails.map(exclusion =>
                                exclusion.description != null && exclusion.description !== "" &&
                                    <div className="col-12  d-flex">
                                        <data m3ico="Close text-danger mr-2 h5r icon-15">
                                            <M3Icon iconName="Close" externalClass="icon-15 text-danger mr-2 h5r icon-15" />
                                        </data>
                                        <var> <label dangerouslySetInnerHTML={{ __html: exclusion.description }} /></var>
                                    </div>
                            )}
                        
                        </div>
                        <div className="text-center">
                            <div className="h6 mt-2 mb-0">
                                {isSelected && option.pricing &&
                                    formatPrice(option.pricing.displayPrice, option.pricing.displayPriceCurrency, cultureCode)
                                }
                                {
                                    !isSelected && selectedPrice &&
                                        (Math.sign(option.pricing.displayPrice - selectedPrice) === 1 ? "+ " : "") + formatPrice(option.pricing.displayPrice - selectedPrice, option.pricing.valutaCliente, cultureCode)
                                }
                            </div>
                            <div className="h6 mt-0 mb-2">
                                {option.cancellationPolicy && option.cancellationPolicy.isFree ?
                                    <label className="text-success">{t(`CancelPolicy:FreeCancellation`)} {formatDateTime(option.cancellationPolicy.expirationDate, { twoDigits: true })}</label>
                                :
                                    <label className="penalty">{t(`CancelPolicy:PenaltyCancellation`)}</label>
                                }
                            </div>

                            {isSelected ?
                                <button className="btn btn-sm tp-btn-search selected">
                                    <data m3lab="Button.Selected">{t('Button:Selected')}</data>
                                </button>
                            :
                                <button className="btn btn-sm tp-btn-select" onClick={(e) => onClickOption(option)} >
                                    <data m3lab="Button.Change">{t('Button:Change')}</data>
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}