import React, { useState, useEffect } from 'react';
import configData from "../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { handleError } from "../../../js/Utils";
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';


export const BlogDetailItem = ({ articleCode, blogId, cultureName }) => {
    const { t } = useTranslation();
    const [article, setArticle] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}blog/getblogarticlebycode/${blogId}/${articleCode}/${cultureName}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setArticle(item);

                setIsLoading(false);
                setIsError(false);
            })
            .catch((err) => {
                console.error(err);

                setIsLoading(false);
                setIsError(true);
            });

    }, [])

    return (
        <>
            {
                isError && <div className="container custom_container"><div className="text-center"><Error textMsg={t('Error:Blog')} /></div></div>
            }
            {
                article ? <>
                    <section className="breadcrumbs_aria">
                        <div className="container custom_container">
                            <div className="row">
                                <div className="col-12">
                                    <h2>{article.title}</h2>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="medical_blog_details_area sec_pad">
                        <div className="container custom_container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="blog_single_info">
                                        <div className="media_blog_content">
                                            {article.contents && article.contents.length > 0 && article.contents.map((item, key) => { return <p key={key} dangerouslySetInnerHTML={{ __html: item }}></p> })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
                    : isLoading ? <div className="text-center"><Loading textMsg={t('Loading:BlogDetail')} /></div>
                        : !isError ? <div className="text-center"><Error textMsg={t('Error:Blog')} /></div> : <></>
            }
        </>
    );
}