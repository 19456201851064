import React from 'react';
import { WebMenuVoices } from '../WebMenuVoices';

export const WebMenuRow = ({ item, rules, modal }) => {
    return (
        <div className={"row " + (item.className ? item.className : "") + `${modal ? ' flex-column-reverse' : ''}`}>
            {
                item.children && item.children.length > 0 && item.children.map((chd, key) => {
                    return <WebMenuVoices key={key} item={chd} modal={modal} rules={rules} />;
                })
            }
        </div>
    );
}
