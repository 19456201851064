import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Cookies from 'universal-cookie';
import { MenuBarVertical } from '../MenuBarVertical';
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { ProductFilterByName } from '../../Product/Common/Filter/ProductFilterByName';

export const HotelFavourite = () => {
    const { t } = useTranslation();

    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [giftCards, setGiftCards] = useState([]);
    const [giftCardsFiltered, setGiftCardsFiltered] = useState([]);
    const [giftCardsToShow, setGiftCardsToShow] = useState([]);
    const [hotelFind, setHotelFind] = useState(null);
    const [hotels, setHotels] = useState(null);
    const [originalHotels, setOriginalHotels] = useState(null);

    // paging
    const itemsPerPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(0);

    // filtering
    const [filterActive, setFilterActive] = useState({ toUse: false, isUsed: false });

    useEffect(() => {
        const getGiftCards = async () => {
            const response = callGetGiftCards();
        }

        setIsLoading(true);
        getGiftCards();
    }, []);

    async function callGetGiftCards() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'user/getgiftcards', requestOption);
        var myItems = [];

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            myItems = response;

            setGiftCards(myItems);
            setGiftCardsFiltered(myItems);
            setIsError(false);
        } else {
            setGiftCards(myItems);
            setGiftCardsFiltered(myItems);
            setIsError(true);
        }

        renderPaginator(myItems, pageSelected);
        paginationGiftCards(myItems, 0);

        setIsLoading(false);
    }

    const handlePageClick = async e => {
        paginationGiftCards(giftCardsFiltered, e.selected);
    };

    function renderPaginator(items, pageSelected) {
        setPageCount(Math.ceil(items.length / itemsPerPage));
        setPageSelected(pageSelected);
    }

    function paginationGiftCards(myItems, pagePosition) {
        var startFrom = 0;
        var endTo = itemsPerPage;
        if (pagePosition > 0) {
            startFrom = itemsPerPage + pagePosition - 1;
            endTo = itemsPerPage + itemsPerPage + pagePosition;
        }

        setGiftCardsToShow(myItems.slice(startFrom, endTo));
        setPageSelected(pagePosition);

        window.scrollTo(0, 0);
    }


    function findHotel(value) {
        setHotelFind(value);

        let _hotels = [...originalHotels];

        let tmpArr = [];
        _hotels.forEach(function (hotel) {
            if (hotel.name.toLowerCase().indexOf(value) > -1 || hotel.address.city.toLowerCase().indexOf(value) > -1 || hotel.address.address.toLowerCase().indexOf(value) > -1) {
                tmpArr.push(hotel);
            }
        });

        setHotels(tmpArr.length > 0 ? tmpArr : _hotels);
    }

    return (
        <>
            <MenuBarVertical activeAnchorId="aHotelFavourite" />

            <div className="section section-lg bg-gray-200 page-content" id="content">
                <div className="container">
                    {
                        isLoading
                            ? (<Loading textMsg={t(`Product:Structure:LoadingList`)} />)
                            : (

                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card border-light p-0 p-md-4 mb-4">
                                            <div className="card-header">
                                                <h5 className="mb-0"><i className="ph-credit-card-bold">{t(`MyBook:HotelFavourite`)}</i></h5>
                                                <div className="mt-1">
                                                    <div className="mt-1">
                                                        <div className="btn-group" aria-label="">
                                                            <input
                                                                className="form-control w-100 rounded mt-2 mb-3"
                                                                style={{ border: "1px solid gray" }}
                                                                placeholder={t('MyBook:FilterHotels')}
                                                                value={hotelFind}
                                                                onChange={event => findHotel(event.target.value.toLowerCase().trim())}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isError ? <Error textMsg={t('Product:Structure:Error')}  />
                                                    : giftCardsToShow !== undefined && giftCardsToShow !== null && giftCardsToShow.length > 0
                                                        ? (
                                                            <>
                                                                {/* 
                                                                <ul className="list-group list-group-flush">
                                                                    {
                                                                        giftCardsToShow.map((giftCard, index) => {
                                                                            return <GiftCard key={index} giftCard={giftCard} cultureName={cultureName} />;
                                                                        })
                                                                    }
                                                                </ul>
                                                                */}

                                                                <nav aria-label="Page navigation comments" className="mt-4">
                                                                    <ReactPaginate
                                                                        breakLabel="..."
                                                                        nextLabel={t('pagination:nextLabel')}
                                                                        onPageChange={handlePageClick}
                                                                        pageRangeDisplayed={5}
                                                                        pageCount={pageCount}
                                                                        previousLabel={t('pagination:previousLabel')}
                                                                        renderOnZeroPageCount={null}
                                                                        forcePage={pageSelected}

                                                                        breakClassName="page-item"
                                                                        breakLinkClassName="page-link"
                                                                        marginPagesDisplayed={2}
                                                                        containerClassName="pagination justify-content-center"
                                                                        pageClassName="page-item"
                                                                        pageLinkClassName="page-link"
                                                                        previousClassName="page-item"
                                                                        previousLinkClassName="page-link"
                                                                        nextClassName="page-item"
                                                                        nextLinkClassName="page-link"
                                                                        activeClassName="active"
                                                                    />
                                                                </nav>
                                                            </>
                                                        ) : (
                                                            <h6>{t(`MyBook:NothingHotelFounded`)}</h6>
                                                        )
                                            }

                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>
            </div>
        </>
    );
}