import React, { useState, useEffect } from 'react';
import configData from "../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../Common/M3Icon";
import { InputSearchDestinationContainer } from '../../Common/Destination/InputSearchDestinationContainer';
import { InputCalendarContainer } from '../../Common/Calendar/InputCalendarContainer';
import { SelectCitizenship } from '../../Common/SelectCitizenship';
import { getFlightListLink, generateClientFlowId, callGetLastItemsSearch, callGetGetProductRules, getFlightDates, getFlightType } from '../../../js/ProductService.js';
import { compareDates, getAuthToken, getCurrentUserFromJwtToken } from '../../../js/Utils.js';
import { searchRangeParameter } from '../../../js/Constant';

export const FlightSearchEngine = ({ searchParameters, onSearchButtonFlight, paxComposition }) => {
    const { t } = useTranslation();
    const modeView = "SEARCHENGINE";

    const [defaultCitizenshipId, setDefaultCitizenshipId] = useState(null);
    const [isStartSearch, setIsStartSearch] = useState(false);
    const [isValidComposition, setIsValidComposition] = useState(true);

    const [selectCitizenship, setSelectCitizenship] = useState({ isoId: 380, name: 'Italy', isoThreeLetter: 'ITA', isSelected: true });
    const [selectDestinations, setSelectDestinations] = useState([{}, {}]);
    const [selectDates, setSelectDates] = useState(null);
    const [isValidForm, setIsValidForm] = useState({
        isValidDestination: false,
        isValidDates: false,
        isValidComposition: false,
        isAllFieldValid: false
    });

    const [textColorClass, setTextColorClass] = useState('text-white');

    // tipo voli informazioni
    const [flightType, setFlightType] = useState(2);
    const [multiLegIndexes, setMultiLegIndexes] = useState([]);
    const [extraOptionsCalendar, setExtraOptionsCalendar] = useState({ multiDate: true });
    const [validDateRule, setValidDateRule] = useState([{}]);
    const [advancedOptions, setAdvancedOptions] = useState({
        withFreeBagsOnly: false,
        refundableOnly: false,
        excludeLowCost: false,
        includeOnlyLowCost: false,
        noRestrictions: false,
        noAdvancePurchase: false,

        categories: 0, // value possible Any=0, Direct=1, NoStop=2
        cabinClass: null,
        ptcOption: null,

        includeAlliances: null,
        excludeAlliances: null,

        includeConnectionPoints: null,
        excludeConnectionPoints: null,

        includeAirlines: null,
        excludeAirlines: null
    });
    const [airAlliances, setAirAlliances] = useState([]);
    const [ptcOptions, setPtcOptions] = useState([]);
    const [cabinClasses, setCabinClasses] = useState([]);
    const [showIncludeStop, setShowIncludeStop] = useState(false);
    const [showExcludeStop, setShowExcludeStop] = useState(false);
    const [enableFlightSearchAdvanced, setEnableFlightSearchAdvanced] = useState(false);

    // gestisco la visibilità
    const [showDestination, setShowDestination] = useState([{ dep: false, arr: false }]);
    const [showPaxes, setShowPaxes] = useState(false);
    const [showCalendar, setShowCalendar] = useState([false]);
    const [collapseAdvancedOptions, setCollapseAdvancedOptions] = useState(true);

    useEffect(() => {
        const getRules = async () => {
            const response = await callGetGetProductRules(51);

            if (response) {
                try {
                    setIsValidComposition(response['ADDTOCART'].allowed);
                } catch (ex) {
                    console.error(ex);
                }
            }
        }

        const getFlightPtcOptionsGroups = async () => {

            const requestOption = {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' }
            };

            const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'flight/GetFlightPtcOptionsGroups', requestOption);

            if (fetchedRes.ok) {

                const response = await fetchedRes.json();
                if (!response)
                    response = [];

                setPtcOptions(response);

            } else {
                console.log("Error get getFlightPtcOptionsGroups");
            }
        }

        const getFlightCabinClasses = async () => {

            const requestOption = {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' }
            };

            const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'flight/GetFlightCabinClasses', requestOption);

            if (fetchedRes.ok) {

                const response = await fetchedRes.json();
                if (!response)
                    response = [];

                setCabinClasses(response);
            } else {
                console.log("Error get GetFlightCabinClasses");
            }
        }

        const getAirAllianceInfo = async () => {

            const requestOption = {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' }
            };

            const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'flight/getairallianceinfo', requestOption);

            if (fetchedRes.ok) {

                const response = await fetchedRes.json();
                if (!response)
                    response = [];

                let defaultValueSelect = { value: null, label: 'Button:Select', isSelected: false };

                response.unshift(defaultValueSelect);
                setAirAlliances(response);

            } else {
                console.log("Error get GetAirAllianceInfo");
            }

        }

        getRules();
        getFlightCabinClasses();
        getFlightPtcOptionsGroups();
        getAirAllianceInfo();

        let token = getAuthToken();
        let currentUser = getCurrentUserFromJwtToken(token);
        if (currentUser && currentUser.roles.includes("W_FlightSearchAdvanced"))
            setEnableFlightSearchAdvanced(true);

        if (modeView === 'MODAL')
            setTextColorClass('text-dark');
        else
            setTextColorClass('text-white');

    }, []);

    useEffect(() => {
        let updAdvancedOptions = { ...advancedOptions };

        // airAlliances
        if (airAlliances && !updAdvancedOptions.includeAlliances) {
            let defaultSelected = airAlliances.filter(x => x.isSelected);

            if (defaultSelected && defaultSelected.length > 0) {
                updAdvancedOptions.includeAlliances = defaultSelected[0];
                updAdvancedOptions.excludeAlliances = defaultSelected[0];
            } else {
                updAdvancedOptions.includeAlliances = airAlliances.filter(x => !x.value)[0];
                updAdvancedOptions.excludeAlliances = airAlliances.filter(x => !x.value)[0];
            }
        }

        if (airAlliances && (updAdvancedOptions.includeAlliances && !updAdvancedOptions.includeAlliances.label)) {
            let defaultSelected = airAlliances.filter(x => x.value === updAdvancedOptions.includeAlliances.value);

            if (defaultSelected && defaultSelected.length > 0)
                updAdvancedOptions.includeAlliances.label = defaultSelected[0].label;
        }

        if (airAlliances && (updAdvancedOptions.excludeAlliances && !updAdvancedOptions.excludeAlliances.label)) {
            let defaultSelected = airAlliances.filter(x => x.value === updAdvancedOptions.excludeAlliances.value);

            if (defaultSelected && defaultSelected.length > 0)
                updAdvancedOptions.excludeAlliances.label = defaultSelected[0].label;
        }

        // ptc options
        if (ptcOptions && !updAdvancedOptions.ptcOption) {
            let defaultSelected = ptcOptions.filter(x => x.isSelected);
            if (defaultSelected && defaultSelected.length > 0)
                updAdvancedOptions.ptcOption = defaultSelected[0];
            else if (ptcOptions && ptcOptions.length > 0)
                updAdvancedOptions.ptcOption = ptcOptions[0];
        }

        if (ptcOptions && (updAdvancedOptions.ptcOption && !updAdvancedOptions.ptcOption.label)) {
            let defaultSelected = ptcOptions.filter(x => x.value === updAdvancedOptions.ptcOption.value);

            if (defaultSelected && defaultSelected.length > 0)
                updAdvancedOptions.ptcOption.label = defaultSelected[0].label;
        }

        // Cabin classes
        if (cabinClasses && !updAdvancedOptions.cabinClass) {
            let defaultSelected = cabinClasses.filter(x => x.isSelected);
            if (defaultSelected && defaultSelected.length > 0)
                updAdvancedOptions.cabinClass = defaultSelected[0]
            else if (cabinClasses && cabinClasses.length > 0)
                updAdvancedOptions.cabinClass = cabinClasses[0];
        }

        if (cabinClasses && (updAdvancedOptions.cabinClass && !updAdvancedOptions.cabinClass.label)) {
            let defaultSelected = cabinClasses.filter(x => x.value === updAdvancedOptions.cabinClass.value);

            if (defaultSelected && defaultSelected.length > 0)
                updAdvancedOptions.cabinClass.label = defaultSelected[0].label;
        }

        setAdvancedOptions(updAdvancedOptions);

        if (!searchParameters || !searchParameters.dateFrom)
            resetDestinationLegs(1);
        else
            resetDate(searchParameters)

    }, [airAlliances, ptcOptions, cabinClasses])

    useEffect(() => {
        setShowPaxes(false);
        setShowIncludeStop(false);
        setShowExcludeStop(false);

        if (searchParameters && searchParameters.dateFrom)
            resetDate(searchParameters);

        setFlightType(2);

    }, [searchParameters]);

    useEffect(() => {
        let isValidFormUpdate = {
            isValidDestinationMap: [],
            isValidDatesMap: [],

            isValidDestination: false,
            isValidDates: false,
            isValidComposition: false,
            isAllFieldValid: false
        };


        // verifico che tutte le destinazioni siano compilate
        let isValidDestinationMap = [{ dep: false, arr: false }];
        let isValidDestination = false;
        if (selectDestinations) {
            isValidDestinationMap = Object.values(selectDestinations)
                .map(x => (
                    {
                        dep: x && x.dep !== null && x.dep !== undefined && x.dep.Id,
                        arr: x && x.arr !== null && x.arr !== undefined && x.arr.Id,
                    })
                );
            isValidDestination = Object.values(isValidDestinationMap).every(x => x && x.dep && x.arr);
        }

        isValidFormUpdate.isValidDestinationMap = isValidDestinationMap;
        isValidFormUpdate.isValidDestination = isValidDestination;

        // verifico che tutte le date siano compilate
        let isValidDatesMap = [false];
        let isValidDates = false;
        if (selectDates) {
            isValidDatesMap = Object.values(selectDates).map(x => x && x.dateFrom !== null && x.dateFrom !== undefined);
            isValidDates = Object.values(isValidDatesMap).every(x => x);
        }

        isValidFormUpdate.isValidDatesMap = isValidDatesMap;
        isValidFormUpdate.isValidDates = isValidDates;

        if (paxComposition)
            isValidFormUpdate.isValidComposition = true;

        isValidFormUpdate.isAllFieldValid = isValidFormUpdate.isValidDates && isValidFormUpdate.isValidComposition && isValidFormUpdate.isValidDestination;
        setIsValidForm(isValidFormUpdate);

    }, [selectDestinations, selectDates, paxComposition]);

    function resetDate(sp) {
        // compilo le regole per il calendario
        let updValidDateRule = [...validDateRule];

        for (let i = 0; i < updValidDateRule.length; i++) {
            if (i === 0) {
                updValidDateRule[i].minDate = sp.dateFrom;
                updValidDateRule[i].maxDate = sp.dateTo;

                if (!updValidDateRule[i].maxDate && searchParameters && searchParameters.dateTo)
                    updValidDateRule[1].maxDate = searchParameters.dateTo;
            } else {

                // se ci sono date selezionate e non sono valide, resetto il valore
                let dateFrom = selectDates[i].dateFrom;
                if (dateFrom && sp.dateFrom) {
                    let isLess = compareDates(dateFrom, sp.dateFrom);
                    if (isLess < 0)
                        selectDates[i].dateFrom = null;
                }
            }

        }

        resetDestinationLegs(1);

        if (sp) {
            let updSelectDates = [];
            updSelectDates.push({ dateFrom: sp.dateFrom, dateTo: sp.dateTo });
            setSelectDates(updSelectDates);
        }

        setValidDateRule(updValidDateRule);
    }

    async function onSearchButton() {
        setIsStartSearch(true);

        try {
            let newSP = {
                "legsCriteria": [],
                "fareOptions": {
                    "publicFares": true,
                    "privateFares": true,
                    "passengerTypesOptions": []
                },
                "filteringOptions": {},
                "travelers": []
            };

            if (!newSP.filteringOptions)
                newSP.filteringOptions = {};

            // Manage advanced option
            newSP.filteringOptions.withFreeBagsOnly = advancedOptions.withFreeBagsOnly;
            newSP.filteringOptions.refundableOnly = advancedOptions.refundableOnly;
            newSP.filteringOptions.excludeLowCost = advancedOptions.excludeLowCost;
            newSP.filteringOptions.includeOnlyLowCost = advancedOptions.includeOnlyLowCost;
            newSP.filteringOptions.noRestrictions = advancedOptions.noRestrictions;
            newSP.filteringOptions.noAdvancePurchase = advancedOptions.noAdvancePurchase;

            newSP.fareOptions.passengerTypesOptions = advancedOptions.ptcOption.value.split(',').map(x => parseInt(x));
            let cabinClass = null
            if (advancedOptions.cabinClass.value)
                cabinClass = advancedOptions.cabinClass.value;

            let includeAlliances = [];
            if (advancedOptions.includeAlliances.value)
                includeAlliances.push(advancedOptions.includeAlliances.value);

            let excludeAlliances = [];
            if (advancedOptions.excludeAlliances.value)
                excludeAlliances.push(advancedOptions.excludeAlliances.value);

            let includeAirlines = advancedOptions.includeAirlines;
            let excludeAirlines = advancedOptions.excludeAirlines;

            let includeConnectionPoints = [];
            let excludeConnectionPoints = [];
            if (advancedOptions.includeConnectionPoints) {
                includeConnectionPoints.push({ Code: advancedOptions.includeConnectionPoints.IataCode, Qualifier: advancedOptions.includeConnectionPoints.Type });
            }

            if (advancedOptions.excludeConnectionPoints) {
                excludeConnectionPoints.push({ Code: advancedOptions.excludeConnectionPoints.IataCode, Qualifier: advancedOptions.excludeConnectionPoints.Type });
            }

            // create legs Criteria
            let legsCriteria = [];
            for (let i = 0; i < selectDestinations.length; i++) {
                let destinationInfo = selectDestinations[i];
                let dates = selectDates[i];
                let depQualifier = destinationInfo.dep.Type === 'Airport' ? 0 : 2;
                let arrQualifier = destinationInfo.arr.Type === 'Airport' ? 0 : 2;

                if (destinationInfo.dep.WebDestinationTypeId && destinationInfo.dep.WebDestinationTypeId.length > 0) {
                    let isCity = destinationInfo.dep.WebDestinationTypeId.some(x => x === 10);
                    if (isCity)
                        depQualifier = 2;
                }

                if (destinationInfo.arr.WebDestinationTypeId && destinationInfo.arr.WebDestinationTypeId.length > 0) {
                    let isCity = destinationInfo.arr.WebDestinationTypeId.some(x => x === 10);
                    if (isCity)
                        arrQualifier = 2;
                }

                let departureLocation = {
                    code: destinationInfo.dep.IataCode,
                    name: destinationInfo.dep.Text,
                    qualifier: depQualifier
                };

                let arrivalLocation = {
                    code: destinationInfo.arr.IataCode,
                    name: destinationInfo.arr.Text,
                    qualifier: arrQualifier
                };

                legsCriteria.push({
                    departureLocation: departureLocation,
                    arrivalLocation: arrivalLocation,
                    date: dates.dateFrom,

                    flightCategory: advancedOptions.categories,
                    cabinClass: cabinClass,

                    includeConnectionPoints: includeConnectionPoints,
                    excludeConnectionPoints: excludeConnectionPoints,

                    includeAirlines: includeAirlines,
                    excludeAirlines: excludeAirlines,

                    includeAlliances: includeAlliances,
                    excludeAlliances: excludeAlliances
                });

                if (flightType === 2) {
                    let arrLeg = { ...legsCriteria[0] };
                    arrLeg.departureLocation = legsCriteria[0].arrivalLocation;
                    arrLeg.arrivalLocation = legsCriteria[0].departureLocation;
                    arrLeg.date = dates.dateTo;

                    legsCriteria.push(arrLeg);
                }
            }

            newSP.legsCriteria = legsCriteria;

            let travelers = [];
            let trvIndex = 0;
            for (let i = 0; i < paxComposition.length; i++) {
                let composition = paxComposition[i];

                for (let j = 0; j < composition.adults; j++) {
                    trvIndex++;
                    travelers.push({
                        index: trvIndex
                    });
                }

                for (let j = 0; j < composition.childAges.length; j++) {
                    let age = composition.childAges[j];
                    trvIndex++;
                    let type = 'CHD';
                    let paxType = 'Child';

                    if (age <= searchRangeParameter.flight.maxInfantAge) {
                        type = 'INF';
                        paxType = 'Child';
                    }


                    travelers.push({
                        index: trvIndex,
                        age: age,
                        passengerTypeCodes: [type],
                        paxType: paxType
                    });

                }

            }
            newSP.travelers = travelers;

            if (selectCitizenship)
                newSP.citizenship = selectCitizenship.isoId;


            onSearchButtonFlight(selectDestinations, selectDates, newSP);

        } catch (ex) {
            console.error(ex);
        }

        setIsStartSearch(false);

    }

    // funzioni destinazione
    function onSelectSearchDestination(newSelectDestination, externalId) {
        let externalIdParts = externalId.split('_');
        let indexToUpdate = parseInt(externalIdParts[1]);
        let updateSelectDestinations = [...selectDestinations];
        let isDeparture = externalIdParts[0].includes('departure');

        if (isDeparture) {
            updateSelectDestinations[indexToUpdate].dep = newSelectDestination;
        }
        else {
            updateSelectDestinations[indexToUpdate].arr = newSelectDestination;
        }

        // se sono nel caso di un multitratta compilo i leg successivi
        if (flightType === 3) {
            if (indexToUpdate === 0 && isDeparture) {
                let lastIndex = updateSelectDestinations.length - 1;
                updateSelectDestinations[lastIndex].arr = newSelectDestination;
            }

            // se è un arrivo setto la partenza del leg successivo
            if (!isDeparture && (indexToUpdate + 1) < updateSelectDestinations.length) {
                updateSelectDestinations[indexToUpdate + 1].dep = newSelectDestination;
            }
        }

        setSelectDestinations(updateSelectDestinations);
    }

    function onOpenPanelDestination(externalId) {
        setShowPaxes(false);
        setShowIncludeStop(false);
        setShowExcludeStop(false);

        let externalIdParts = externalId.split('_');
        let isDeparture = externalIdParts[0].includes('departure');
        let indexToUpdate = parseInt(externalIdParts[1]);

        let showDestinationMap = getShowDestinationLegs(false);
        if (isDeparture) {
            showDestinationMap[indexToUpdate].dep = true;
        }
        else {
            showDestinationMap[indexToUpdate].arr = true;
        }

        setShowDestination(showDestinationMap);

        let showCalendarmap = getShowCalendarLegs(false);
        setShowCalendar(showCalendarmap);
    }

    function onOpenPanelPaxes() {
        setShowPaxes(true);

        setShowIncludeStop(false);
        setShowExcludeStop(false);

        let showDestinationMap = getShowDestinationLegs(false);
        setShowDestination(showDestinationMap);

        let showCalendarmap = getShowCalendarLegs(false);
        setShowCalendar(showCalendarmap);
    }

    // funzioni per il calendario
    function onOpenPanelCalendar(externalId) {
        setShowPaxes(false);

        setShowIncludeStop(false);
        setShowExcludeStop(false);

        let showDestinationMap = getShowDestinationLegs(false);
        setShowDestination(showDestinationMap);

        let externalIdParts = externalId.split('_');
        let indexToUpdate = parseInt(externalIdParts[1]);

        let showCalendarmap = getShowCalendarLegs(false);
        showCalendarmap[indexToUpdate] = true;
        setShowCalendar(showCalendarmap);
    }

    function onClickSelectDates(values, externalId) {

        let externalIdParts = externalId.split('_');
        let indexToUpdate = parseInt(externalIdParts[1]);
        let updSelectDates = [...selectDates];
        selectDates[indexToUpdate].dateFrom = values.dateFrom;
        selectDates[indexToUpdate].dateTo = values.dateTo;

        setSelectDates(updSelectDates);

        // compilo le regole per il calendario
        let updValidDateRule = [...validDateRule];
        for (let i = 0; i < updValidDateRule.length; i++) {
            if (i > indexToUpdate) {
                updValidDateRule[i].minDate = values.dateFrom;

                // se ci sono date selezionate e non sono valide, resetto il valore
                let dateFrom = selectDates[i].dateFrom;
                if (dateFrom && values.dateFrom) {
                    let isLess = compareDates(dateFrom, values.dateFrom);
                    if (isLess < 0)
                        selectDates[i].dateFrom = null;
                }

            }
        }

        setValidDateRule(updValidDateRule);
    }

    // funzioni cittadinanza
    function onSelectCitizenship(item) {
        setSelectCitizenship(item);
    }

    // funzioni cambio tipo
    function onChangeCbAdvancedOptions(event, property) {
        const isSelected = event.target.checked;
        let value = '';
        if (property === 'categories') {

            value = 0;
            if (isSelected)
                value = 2;

        } else {
            value = isSelected;
        }


        let updAdvOpt = { ...advancedOptions };

        updAdvOpt[property] = value;

        setAdvancedOptions(updAdvOpt);
    }

    function onChangeFlightType(event, type) {
        let isChecked = event.target.checked;
        setFlightType(type);
        if (type === 1 && isChecked) {

            if (flightType === 3) {
                resetDestinationLegs(1);

                let lastValidDateRule = {};
                if (searchParameters && searchParameters.dateFrom)
                    lastValidDateRule.minDate = searchParameters.dateFrom;

                if (searchParameters && searchParameters.dateTo)
                    lastValidDateRule.maxDate = searchParameters.dateTo;

                setValidDateRule([lastValidDateRule]);
                setMultiLegIndexes([]);
            }


            setExtraOptionsCalendar({ ...extraOptionsCalendar, multiDate: false });
        } else if (type === 2 && isChecked) {

            if (flightType === 3) {
                resetDestinationLegs(1);

                let lastValidDateRule = {};
                if (searchParameters && searchParameters.dateFrom)
                    lastValidDateRule.minDate = searchParameters.dateFrom;

                if (searchParameters && searchParameters.dateTo)
                    lastValidDateRule.maxDate = searchParameters.dateTo;

                setValidDateRule([lastValidDateRule]);

                setMultiLegIndexes([]);
            }

            setExtraOptionsCalendar({ ...extraOptionsCalendar, multiDate: true });
        }
        else if (type === 3 && isChecked) {
            onClickAddLeg(true);
            setExtraOptionsCalendar({ ...extraOptionsCalendar, multiDate: false });
        }

    }

    function getShowDestinationLegs(value) {
        let result = [];

        if (showDestination && showDestination.length > 0) {
            for (let i = 0; i < showDestination.length; i++) {
                result.push({ dep: value, arr: value });
            }
        }

        return result;
    }

    function resetDestinationLegs(totalLegs) {

        let updSelectDates = [];
        let updShowDestination = [];
        let updShowCalendar = [];
        let updValidDateRule = [];
        if (totalLegs < 1)
            totalLegs = 1;

        for (let i = 0; i < totalLegs; i++) {
            updSelectDates.push({ dateFrom: null, dateTo: null });
            updShowDestination.push({ dep: false, arr: false });
            updShowCalendar.push(false);
            updValidDateRule.push([{}]);
        }

        setSelectDestinations(updShowDestination);
        setSelectDates(updSelectDates);
        setShowCalendar(updShowCalendar);
        setValidDateRule(updValidDateRule);
    }

    function getShowCalendarLegs(value) {
        let result = [];

        if (showCalendar && showCalendar.length > 0) {
            for (let i = 0; i < showCalendar.length; i++) {
                result.push(value);
            }
        }

        return result;
    }

    // funzioni gestione multitratta
    function onClickAddLeg(resetFlightType = false) {
        let updSelectDates = [...selectDates];
        let updShowDestination = [...showDestination];
        let updSelectDestinations = [...selectDestinations];
        let updShowCalendar = [...showCalendar];
        let updValidDateRule = [...validDateRule];
        updSelectDates.push({ dateFrom: null, dateTo: null });
        updShowDestination.push({ dep: false, arr: false });
        updShowCalendar.push(false);

        let lastValidDateRule = {};

        if (searchParameters && searchParameters.dateFrom)
            lastValidDateRule.minDate = searchParameters.dateFrom;

        if (searchParameters && searchParameters.dateTo)
            lastValidDateRule.maxDate = searchParameters.dateTo;

        updValidDateRule.push(lastValidDateRule);

        // setto i default dei leg
        let previousLegArrival = {};
        let firstLegDeparture = {};
        try {
            firstLegDeparture = updSelectDestinations[0].dep;
            previousLegArrival = updSelectDestinations[updSelectDestinations.length - 1].arr;
        } catch (ex) {
            console.error(ex);
        }

        updSelectDestinations.push({ dep: previousLegArrival, arr: firstLegDeparture });

        let updMultiLegIndexes = [];
        for (let i = 1; i < updSelectDates.length; i++)
            updMultiLegIndexes.push(i);

        if (resetFlightType) {
            // viene fatto solamente quando cambio il tipo di volo
            updSelectDates[0].dateTo = null;
            updValidDateRule[1].minDate = updSelectDates[0].dateFrom;

            if (searchParameters && searchParameters.dateTo)
                updValidDateRule[1].maxDate = searchParameters.dateTo;
        }

        setMultiLegIndexes(updMultiLegIndexes);
        setSelectDestinations(updSelectDestinations);
        setSelectDates(updSelectDates);
        setShowDestination(updShowDestination);
        setShowCalendar(updShowCalendar);
        setValidDateRule(updValidDateRule);
    }

    function onClickRemoveLeg(legIndex) {
        let updSelectDates = [...selectDates];
        let updShowDestination = [...showDestination];
        let updSelectDestinations = [...selectDestinations];

        updSelectDates.splice(legIndex, 1);
        updShowDestination.splice(legIndex, 1);
        updSelectDestinations.splice(legIndex, 1);

        let updMultiLegIndexes = [];
        for (let i = 1; i < updSelectDates.length; i++)
            updMultiLegIndexes.push(i);

        setMultiLegIndexes(updMultiLegIndexes);
        setSelectDestinations(updSelectDestinations);
        setSelectDates(updSelectDates);
        setShowDestination(updShowDestination);
    }

    // funzioni ricerca avanzata

    function onOpenPanelIncludeStop() {
        setShowPaxes(false);

        setShowIncludeStop(true);
        setShowExcludeStop(false);

        let showDestinationMap = getShowDestinationLegs(false);
        setShowDestination(showDestinationMap);

        let showCalendarmap = getShowCalendarLegs(false);
        setShowCalendar(showCalendarmap);
    }

    function onOpenPanelExcludeStop() {
        setShowPaxes(false);

        setShowIncludeStop(false);
        setShowExcludeStop(true);

        let showDestinationMap = getShowDestinationLegs(false);
        setShowDestination(showDestinationMap);

        let showCalendarmap = getShowCalendarLegs(false);
        setShowCalendar(showCalendarmap);
    }

    function onSelectExcludeStop(newSelectDestination) {
        setAdvancedOptions({
            ...advancedOptions,
            excludeConnectionPoints: newSelectDestination
        });
    }

    function onSelectIncludeStop(newSelectDestination) {
        setAdvancedOptions({
            ...advancedOptions,
            includeConnectionPoints: newSelectDestination
        });
    }

    function onSelectIncludeAlliance(alliance) {
        setAdvancedOptions({
            ...advancedOptions,
            includeAlliances: alliance
        });
    }

    function onSelectExcludeAlliance(alliance) {
        setAdvancedOptions({
            ...advancedOptions,
            excludeAlliances: alliance
        });
    }

    function onSelectCabinClasses(cabinClass) {
        setAdvancedOptions({
            ...advancedOptions,
            cabinClass: cabinClass
        });
    }

    function onSelectPtcOption(ptcOption) {
        setAdvancedOptions({
            ...advancedOptions,
            ptcOption: ptcOption
        });
    }

    return (
        <>
            <div className="d-sm-block bg-custom rounded w-100 h-100 pt-3 px-2">
                <div className="btn-group btn-sm rounded-1" role="group" aria-label="Basic example">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input"
                            type="radio"
                            id="Return"
                            value="option1"
                            checked={flightType === 2}
                            onChange={(e) => onChangeFlightType(e, 2)} />

                        <data m3ico="RoundTrip text-gray-600 icon-10">
                            <M3Icon iconName="RoundTrip" externalClass={"icon-10 " + textColorClass} />
                        </data>
                        <label className={"form-check-label " + textColorClass} htmlFor="Return">
                            <data m3lab="SearchEngine.Roundtrip">
                                {t('SearchEngine:Roundtrip')}
                            </data>
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input"
                            type="radio"
                            id="OneWay"
                            value="option2"
                            checked={flightType === 1}
                            onChange={(e) => onChangeFlightType(e, 1)} />

                        <data m3ico="OneWay text-gray-600 icon-10">
                            <M3Icon iconName="OneWay" externalClass={"icon-10 " + textColorClass} />
                        </data>
                        <label className={"form-check-label  " + textColorClass} htmlFor="OneWay">
                            <data m3lab="SearchEngine.OneWay">
                                {t('SearchEngine:OneWay')}
                            </data>
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input"
                            type="radio"
                            id="Multistep"
                            value="option3"
                            checked={flightType === 3}
                            onChange={(e) => onChangeFlightType(e, 3)} />

                        <data m3ico="MultiStep text-gray-600 icon-10">
                            <M3Icon iconName="MultiStep" externalClass={"icon-10 " + textColorClass} />
                        </data>
                        <label className={"form-check-label " + textColorClass} htmlFor="Multistep">
                            <data m3lab="SearchEngine.MultiStep">
                                {t('SearchEngine:MultiStep')}
                            </data></label>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12 col-sm-4 my-1">
                        <InputSearchDestinationContainer
                            externalId="departureSegment_0"
                            defaultSelectDestination={selectDestinations[0].dep}
                            productType={configData.Settings.Products.Flight.IdTipoProdotto}
                            onSelectSearchDestination={onSelectSearchDestination}
                            onOpenPanelDestination={onOpenPanelDestination}
                            closeExternal={showDestination[0].dep}
                            extraOptions={{ selectGroupTitle: true }}
                            modeView={modeView} />
                    </div>
                    <div className="col-12 col-sm-4 my-1">
                        <InputSearchDestinationContainer
                            externalId="arrivalSegment_0"
                            defaultSelectDestination={selectDestinations[0].arr}
                            productType={configData.Settings.Products.Flight.IdTipoProdotto}
                            onSelectSearchDestination={onSelectSearchDestination}
                            onOpenPanelDestination={onOpenPanelDestination}
                            closeExternal={showDestination[0].arr}
                            extraOptions={{ label: 'SearchEngine:ArrivalAirport', inputText: 'SearchEngine:ArrivalAirport', selectGroupTitle: true }}
                            modeView={modeView} />
                    </div>

                    <div className="col-12 col-sm-4 my-1">
                        <InputCalendarContainer
                            externalId="calendar_0"
                            productType={configData.Settings.Products.Flight.IdTipoProdotto}
                            dateFrom={selectDates && selectDates.length > 0 ? selectDates[0].dateFrom : null}
                            dateTo={selectDates && selectDates.length > 0 ? selectDates[0].dateTo : null}
                            extraOptions={extraOptionsCalendar}
                            validDateRule={validDateRule[0]}
                            onClickSelectDates={onClickSelectDates}
                            onOpenPanelCalendar={onOpenPanelCalendar}
                            closeExternal={showCalendar[0]}
                            modeView={modeView}
                            type="m" />
                    </div>

                    <div className="col-12 col-sm-9">

                    </div>
                    <div className="col-12 col-sm-3 d-inline text-right">
                        <SelectCitizenship
                            defaultCitizenshipId={defaultCitizenshipId}
                            onSelectCitizenship={onSelectCitizenship}
                            modeView={modeView} />
                    </div>

                </div>
                {
                    flightType === 3 && multiLegIndexes && multiLegIndexes.map((legIndex, index) => {
                        return <div key={index} className="row mb-3">
                            <div className="col-12 col-sm-4 my-1">
                                <InputSearchDestinationContainer
                                    externalId={"departureSegment_" + legIndex}
                                    defaultSelectDestination={selectDestinations[legIndex].dep}
                                    productType={configData.Settings.Products.Flight.IdTipoProdotto}
                                    onSelectSearchDestination={onSelectSearchDestination}
                                    onOpenPanelDestination={onOpenPanelDestination}
                                    closeExternal={showDestination[legIndex].dep}
                                    extraOptions={{ selectGroupTitle: true }}
                                    modeView={modeView} />
                            </div>
                            <div className="col-12 col-sm-4 my-1">
                                <InputSearchDestinationContainer
                                    externalId={"arrivalSegment_" + legIndex}
                                    defaultSelectDestination={selectDestinations[legIndex].arr}
                                    productType={configData.Settings.Products.Flight.IdTipoProdotto}
                                    onSelectSearchDestination={onSelectSearchDestination}
                                    onOpenPanelDestination={onOpenPanelDestination}
                                    closeExternal={showDestination[legIndex].arr}
                                    extraOptions={{ label: 'SearchEngine:ArrivalAirport', inputText: 'SearchEngine:ArrivalAirport', selectGroupTitle: true }}
                                    modeView={modeView} />
                            </div>

                            <div className="col-12 col-sm-4 my-1">
                                <InputCalendarContainer
                                    externalId={"calendar_" + legIndex}
                                    dateFrom={selectDates && selectDates.length > 0 ? selectDates[legIndex - 1].dateFrom : null}
                                    dateTo={selectDates && selectDates.length > 0 ? selectDates[legIndex - 1].dateTo : null}
                                    extraOptions={extraOptionsCalendar}
                                    validDateRule={validDateRule[legIndex]}
                                    onClickSelectDates={onClickSelectDates}
                                    onOpenPanelCalendar={onOpenPanelCalendar}
                                    closeExternal={showCalendar[legIndex]}
                                    modeView={modeView}
                                    type="m" />
                            </div>

                            <div className="col-12 col-sm-6 my-1">

                            </div>
                            <div className="col-12 col-sm-6 my-1 p-0 text-right">
                                {
                                    (legIndex > 0 && multiLegIndexes.length > 1) && <span>
                                        <button className="btn h075 text-white" onClick={_ => onClickRemoveLeg(legIndex)}>
                                            <data m3ico="Remove">
                                                <M3Icon iconName="Remove" externalClass={"h075 mr-2 " + textColorClass} />
                                            </data>
                                            <data m3lab="SearchEngine.RemoveLeg">
                                                {t('SearchEngine:RemoveLeg')}
                                            </data>
                                        </button>
                                    </span>
                                }
                                {
                                    multiLegIndexes.length < 5 && <span className="mr-2">
                                        <button className="btn h075 text-white" onClick={_ => onClickAddLeg()}>
                                            <data m3ico="Add">
                                                <M3Icon iconName="Add" externalClass={"h075 mr-2 " + textColorClass} />
                                            </data>
                                            <data m3lab="SearchEngine.AddLeg">
                                                {t('SearchEngine:AddLeg')}
                                            </data>
                                        </button>
                                    </span>
                                }
                            </div>
                        </div>
                    })
                }
                {
                    enableFlightSearchAdvanced && <div className="row mb-3">
                        <div className="col-12 col-sm-9 my-1">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                    type="checkbox"
                                    id="Fligth_FreeBagage"
                                    value="option1"
                                    checked={advancedOptions.withFreeBagsOnly}
                                    onChange={(e) => onChangeCbAdvancedOptions(e, 'withFreeBagsOnly')} />
                                <label className={"form-check-label  " + textColorClass} htmlFor="Fligth_FreeBagage">{t('SearchEngine:FlightAdvancedOptions:WithFreeBagsOnly')}</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                    type="checkbox"
                                    id="Fligth_OnlyRefound"
                                    value="option2"
                                    checked={advancedOptions.refundableOnly}
                                    onChange={(e) => onChangeCbAdvancedOptions(e, 'refundableOnly')} />
                                <label className={"form-check-label  " + textColorClass} htmlFor="Fligth_OnlyRefound">{t('SearchEngine:FlightAdvancedOptions:RefundableOnly')}</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                    type="checkbox"
                                    id="Fligth_Direct"
                                    value="option3"
                                    checked={advancedOptions.categories !== 0}
                                    onChange={(e) => onChangeCbAdvancedOptions(e, 'categories')} />
                                <label className={"form-check-label  " + textColorClass} htmlFor="Fligth_Direct">{t('SearchEngine:FlightAdvancedOptions:FlighCategoryDirect')}</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                    type="checkbox"
                                    id="Fligth_ExLowCost"
                                    checked={advancedOptions.excludeLowCost}
                                    onChange={(e) => onChangeCbAdvancedOptions(e, 'excludeLowCost')} />
                                <label className={"form-check-label  " + textColorClass} htmlFor="Fligth_ExLowCost">{t('SearchEngine:FlightAdvancedOptions:ExcludeLowCost')}</label>
                            </div>
                        </div>
                        <div className="col-12 col-sm-2 my-1 text-right">
                            <div className="accordion accordion-flush " id="accordionFlushExample">
                                <div className="accordion-item  bg-transparent">
                                    <label className="accordion-header" id="flush-headingOne">
                                        <label className="" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                            <span className={"h065  " + textColorClass}>{t('SearchEngine:FlightAdvancedOptions:Label')}</span>
                                        </label>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 my-1">
                            <div id="flush-collapseOne" className={`accordion-collapse ${collapseAdvancedOptions ? "collapse" : ""}`} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample" >
                                <div className="row">
                                    <div className="col-12 col-sm-2 my-1">
                                        <div className="input-group bg-white rounded pr-1">
                                            <span className="input-group-text px-2" id="basic-addon1">
                                                <M3Icon iconName="FlightCabinClass" externalClass="" />
                                            </span>
                                            <div className="pl-1">
                                                <label htmlFor="CabinClass" className="form-label h065">{t('SearchEngine:FlightAdvancedOptions:CabinClasses')}</label>
                                                <div className="dropdown h075">
                                                    <div className="form-control btn btn-sm dropdown-toggle shadow-none h075" type="button" id="CabinClass" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {advancedOptions.cabinClass && advancedOptions.cabinClass.label}
                                                    </div>
                                                    <ul className="dropdown-menu" aria-labelledby="CabinClass" >
                                                        {
                                                            cabinClasses && cabinClasses.map((cc, key) => {
                                                                return cc && <li key={key} onClick={_ => onSelectCabinClasses(cc)}>
                                                                    <div className={"dropdown-item " + ((advancedOptions.cabinClass && cc.value === advancedOptions.cabinClass.value) ? "active" : "")}>
                                                                        {cc.label}
                                                                    </div>
                                                                </li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-2 my-1">
                                        <div className="input-group bg-white rounded pr-1">
                                            <span className="input-group-text px-2" id="basic-addon1">
                                                <M3Icon iconName="FlightFaresType" externalClass="" />
                                            </span>
                                            <div className="pl-1">
                                                <label htmlFor="TypeFare" className="form-label h065">{t('SearchEngine:FlightAdvancedOptions:FaresType')}</label>
                                                <div className="dropdown h075">
                                                    <div className="form-control btn btn-sm dropdown-toggle shadow-none h075" type="button" id="TypeFare" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {advancedOptions.ptcOption && advancedOptions.ptcOption.label}
                                                    </div>
                                                    <ul className="dropdown-menu" aria-labelledby="TypeFare" >
                                                        {
                                                            ptcOptions && ptcOptions.map((ptc, key) => {
                                                                return ptc && <li key={key} onClick={_ => onSelectPtcOption(ptc)}>
                                                                    <div className={"dropdown-item " + ((advancedOptions.ptcOption && ptc.value === advancedOptions.ptcOption.value) ? "active" : "")}>
                                                                        {ptc.label}
                                                                    </div>
                                                                </li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3 my-1">
                                        <InputSearchDestinationContainer
                                            externalId="includeStop"
                                            defaultSelectDestination={advancedOptions.includeConnectionPoints}
                                            productType={configData.Settings.Products.Flight.IdTipoProdotto}
                                            onSelectSearchDestination={onSelectIncludeStop}
                                            onOpenPanelDestination={onOpenPanelIncludeStop}
                                            closeExternal={showIncludeStop}
                                            modeView={modeView}
                                            extraOptions={{ label: 'SearchEngine:FlightAdvancedOptions:IncludeStop', iconName: "FlightStop", inputText: 'SearchEngine:FlightAdvancedOptions:SelectAirport', clearSelection: true }} />
                                    </div>
                                    <div className="col-12 col-sm-3 my-1">
                                        <InputSearchDestinationContainer
                                            externalId="excludeStop"
                                            defaultSelectDestination={advancedOptions.excludeConnectionPoints}
                                            productType={configData.Settings.Products.Flight.IdTipoProdotto}
                                            onSelectSearchDestination={onSelectExcludeStop}
                                            onOpenPanelDestination={onOpenPanelExcludeStop}
                                            closeExternal={showExcludeStop}
                                            modeView={modeView}
                                            extraOptions={{ label: 'SearchEngine:FlightAdvancedOptions:ExcludeStop', iconName: "FlightStop", inputText: 'SearchEngine:FlightAdvancedOptions:SelectAirport', clearSelection: true }} />
                                    </div>
                                    <div className="col-12 col-sm-4 my-1 text-right">
                                        <h6 className="mt-3">{t('SearchEngine:FlightAdvancedOptions:Alliance')}</h6>
                                    </div>
                                    <div className="col-12 col-sm-3 my-1">
                                        <div className="input-group bg-white rounded pr-1">
                                            <span className="input-group-text px-2" id="basic-addon1">
                                                <M3Icon iconName="FlightAlliance" externalClass="" />
                                            </span>
                                            <div className="pl-1">
                                                <label htmlFor="AllinaceIclusion" className="form-label h065">{t('SearchEngine:FlightAdvancedOptions:IncludeAlliance')}</label>
                                                <div className="dropdown h075">
                                                    <div className="form-control btn btn-sm dropdown-toggle shadow-none h075" type="button" id="AllinaceIclusion" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {advancedOptions.includeAlliances && advancedOptions.includeAlliances.value && advancedOptions.includeAlliances.label}
                                                        {advancedOptions.includeAlliances && !advancedOptions.includeAlliances.value && <>{t(advancedOptions.includeAlliances.label)}</>}
                                                    </div>
                                                    <ul className="dropdown-menu" aria-labelledby="AllinaceIclusion" >
                                                        {
                                                            airAlliances && airAlliances.map((alliance, key) => {
                                                                return alliance && <li key={key} onClick={_ => onSelectIncludeAlliance(alliance)}>
                                                                    <div className={"dropdown-item " + ((advancedOptions.includeAlliances && (alliance.value === advancedOptions.includeAlliances.value)) ? "active" : "")}>
                                                                        {alliance.value && alliance.label}
                                                                        {!alliance.value && <>{t(alliance.label)}</>}
                                                                    </div>
                                                                </li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3 my-1">
                                        <div className="input-group bg-white rounded pr-1">
                                            <span className="input-group-text px-2" id="basic-addon1">
                                                <M3Icon iconName="FlightAlliance" externalClass="text-warning" />
                                            </span>
                                            <div className="pl-1">
                                                <label htmlFor="AllinaceExclusion" className="form-label h065">{t('SearchEngine:FlightAdvancedOptions:ExcludeAlliance')}</label>
                                                <div className="dropdown h075">
                                                    <button className="form-control btn btn-sm dropdown-toggle shadow-none h075" type="button" id="AllinaceExclusion" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {advancedOptions.excludeAlliances && advancedOptions.excludeAlliances.value && advancedOptions.excludeAlliances.label}
                                                        {advancedOptions.excludeAlliances && !advancedOptions.excludeAlliances.value && <>{t(advancedOptions.excludeAlliances.label)}</>}
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="AllinaceExclusion" >
                                                        {
                                                            airAlliances && airAlliances.map((alliance, key) => {
                                                                return airAlliances && <li key={key} onClick={_ => onSelectExcludeAlliance(alliance)}>
                                                                    <div className={"dropdown-item " + ((advancedOptions.excludeAlliances && (alliance.value === advancedOptions.excludeAlliances.value)) ? "active" : "")}>
                                                                        {alliance.value && alliance.label}
                                                                        {!alliance.value && <>{t(alliance.label)}</>}
                                                                    </div>
                                                                </li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-2 my-1">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    !isValidComposition && <div className="row mb-3">
                        <div className={"col-12 col-sm-12 my-1  " + textColorClass}>
                            {t('SearchEngine:WarningCartComposition')}
                        </div>
                    </div>
                }
                <div className="row mt-1 mb-2">
                    <div className="col-12 col-sm-9">

                    </div>
                    <div className="col-12 col-sm-3">
                        <button
                            className="btn tp-btn-search w-100 py-3"
                            onClick={_ => onSearchButton()}
                            disabled={!isValidForm.isAllFieldValid && !isStartSearch}
                        >
                            {isStartSearch ? <><div className="spinner-border spinner-border-sm text-primary" role="status"><span className="sr-only"></span></div></> : <>{t('Button:Search')}</>}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}