import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Loading } from '../Common/Loading';
import { M3Icon } from '../Common/M3Icon';
import { Passenger } from './Passenger';
import { PriceBar } from './PriceBar';
import { CancelReservationModal } from './OperationModals/CancelReservationModal';
import { PayWithCreditCardModal } from './OperationModals/PayWithCreditCardModal';
import { PrintPnrModal } from './OperationModals/PrintPnrModal';
import { RequotePnrModal } from './OperationModals/RequotePnrModal';
import { OperationButtons } from './OperationButtons';
import { QuotationOperationButtons } from '../QuotationTO/components/QuotationOperationButtons';
import { formatPrice, formatDateTime } from '../../js/Utils.js';
import configData from "../../appsettings.json";
import { imageOnError } from '../../js/CDNUtility';
import { Error } from '../Common/Error';
import { useTranslation } from 'react-i18next';
import { ServiceNote } from './ServiceNote';
import { UpdateFlightData } from '../MyBook/ProductData/components/UpdateFlightData';

export const FlightDetail = ({ id, type, showNetPrices, showCost, customFunctions, forceRender, saveProductData }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [flightDetail, setFlightDetail] = useState([]);

    const [modalId, setModalId] = useState("");
    const [resetValue, setResetValue] = useState(1);
    const [isEnabledUpdateStaticData, setIsEnabledUpdateStaticData] = useState(false);


    useEffect(() => {

        setResetValue(1);
        loadItem();

        setModalId("modalUpdateStaticDAta_" + id)
    }, []);

    useEffect(() => {
        if (forceRender && forceRender > 0) {
            loadItem();
            setResetValue(1);
        }
    }, [forceRender]);

    function loadItem() {
        setIsLoading(true);
        switch (type) {
            case 'MyBook':
            case 'QuotationTO_MyBook':
                const getFlightDetail = async (inputData) => {
                    const response = callGetFlightDetail(inputData);
                }

                getFlightDetail({ PNR: id, ProductType: configData.Settings.Products.Flight.IdTipoProdotto, Options: { WebsiteBaseUrl: configData.Settings.OldWebSite_BaseUrl } });
                break;
            case 'QuotationTO':
                const getQuotationItem = async (inputData) => {
                    const response = callGetQuotationItem(inputData);
                }
                getQuotationItem(id);
                break;
            default:
                setIsLoading(false);
        }
    }

    async function callGetFlightDetail(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetReservationDetail', requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (type === 'QuotationTO_MyBook' || type === 'MyBook')
                customFunctions.onCallSaveProductInfo(response, type, id)

            setFlightDetail(response);
            setIsError(false);
        } else {
            setErrorMsg(t("MyBook:PracticeNotAvailableMsg").replace("##ID##", id));
            setIsError(true);
        }

        setIsLoading(false);
    }

    async function callGetQuotationItem(id) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationitem/${id}`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            customFunctions.onCallSaveProductInfo(response, type, id);

            setFlightDetail(response);
            setIsError(false);

            if (response.allowedOperations && response.allowedOperations.EnabledUpdateStaticData)
                setIsEnabledUpdateStaticData(response.allowedOperations.EnabledUpdateStaticData.enabled && (type !== 'QuotationTOEndUser' && type !== 'QuotationEndUser'));
        } else {
            setErrorMsg(t("MyBook:QuotaionProductNotLoadMsg").replace("##ID##", id));
            setIsError(true);
        }

        setIsLoading(false);
    }

    const renderLegs = (legs) => {
        return (
            <>
                {legs.map((leg, key) =>
                    <div key={leg.legIndex}>
                        <div className="row mybook-flight">
                            {
                                key === 0 && <div className="col-12 text-left">
                                    {
                                        (type === 'QuotationTO' || type === 'QuotationTO_MyBook')
                                        && (customFunctions && !customFunctions.isAgencyMode)
                                        && (flightDetail.quotationDetail && flightDetail.priceBar.isManualLoading)
                                        && <span className="material-icons-outlined mx-1" title="Prodotto manuale" style={{ fontSize: '16px' }}>front_hand</span>
                                    }
                                    {
                                        type === 'QuotationTO' && <span className="badge bg-gray-300 text-dark mr-2">{id}</span>
                                    }
                                </div>
                            }
                            <div className="col-12 col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                                <img src={leg.airlineLogoUrl} alt={leg.airlineName} onError={e => imageOnError(e, configData.Settings.Products.Flight.IdTipoProdotto)} />
                            </div>
                            <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <h4>
                                    {leg.departureAirportIATA}
                                </h4>
                            </div>
                            <div className="row col-12 col-sm-7 col-md-7 col-lg-7 col-xl-7">
                                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                    <span>{leg.flightNumber}</span><p>{leg.airlineName}</p>
                                </div>
                                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                    <span>{formatDateTime(leg.departureDate, cultureName, { onlyTime: true })} </span><p>{formatDateTime(leg.departureDate, cultureName, { noYear: true })}</p>
                                </div>
                                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                    <span>-{leg.duration}h-</span>
                                    <p>
                                        {leg.segments.length === 1
                                            ? t("Product:Flight:Direct")
                                            : (<a data-bs-toggle="collapse" data-bs-target={'#pnlSegments_Leg' + leg.legIndex} aria-expanded="true" aria-controls={'pnlSegments_Leg' + leg.legIndex}>
                                                <i className="ph-plus-circle"></i> {leg.segments.length - 1} &nbsp;{t("Product:Flight:Stopover")}
                                            </a>)}
                                    </p>
                                </div>
                                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                    <span>{formatDateTime(leg.arrivalDate, cultureName, { onlyTime: true })}</span><p>{formatDateTime(leg.arrivalDate, cultureName, { noYear: true })}</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <h4>{leg.arrivalAirportIATA}</h4>
                            </div>
                        </div>
                        <div id={'pnlSegments_Leg' + leg.legIndex} className="accordion-collapse collapse" role="region" aria-labelledby="heading1c">
                            {leg.segments.map(segment =>
                                <div key={segment.segmentIndex} className="row mybook-flight">
                                    <div className="col-12 col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">&nbsp;</div>
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">&nbsp;</div>
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <span>{segment.flightNumber}</span><p>{segment.airlineName}</p>
                                    </div>
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <span>{segment.departureAirportIATA}</span> {formatDateTime(segment.departureDate, cultureName, { onlyTime: true })}
                                    </div>
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <span>{segment.arrivalAirportIATA}</span> {formatDateTime(segment.arrivalDate, cultureName, { onlyTime: true })}
                                    </div>
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        {segment.duration}
                                    </div>
                                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-3">&nbsp;</div>
                                    {segment.stopDuration === null
                                        ? (<></>)
                                        : (<div className="col-12 text-center">
                                            <span>{segment.stopDuration} <i className="ph-clock"></i>&nbsp;{t("Product:Flight:StopoverDuration")}</span>
                                        </div>)}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </>
        );
    }

    const renderPassengerFareComponents = (passengerFareComponents) => {
        return (
            <>
                {passengerFareComponents.map((paxFareCompo, index) =>
                    <div key={index} className="row">
                        <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <i className="ph-user-fill"></i><label>{t("MyBook:Passenger")}&nbsp; {paxFareCompo.passenger.index}:</label>
                        </div>
                        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 text-left">
                            <div className="row">
                                <p className="my-1">
                                    <span>{paxFareCompo.passenger.paxType}</span>
                                    <span>{paxFareCompo.passenger.fullName}</span>
                                    <span>{paxFareCompo.passenger.birthDate !== null ? formatDateTime(paxFareCompo.passenger.birthDate, cultureName) : ""}</span>
                                </p>
                            </div>
                            <div className="row">
                                {paxFareCompo.fareDetails.map((paxFareDetail, index) =>
                                    <div key={index} className="col-12 p-0">
                                        <label>{t("Product:Flight:Section")}:</label> <span>{paxFareDetail.depAirport} - {paxFareDetail.arrAirport}</span>
                                        <label>Class:</label> <span>{paxFareDetail.bookingClass}</span>
                                        {paxFareDetail.ticketNumber !== null ? (<><label>Ticket</label> <span>{paxFareDetail.ticketNumber}</span></>) : (<></>)}
                                        {paxFareDetail.baggages !== null ? (<><span><i className="ph-suitcase-light"></i>{paxFareDetail.baggages}</span></>) : (<></>)}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }

    const renderPassengerCosts = (passengerFareComponents) => {
        return (
            <>
                {passengerFareComponents.map((paxFareCompo, index) =>
                    <div key={index} className="row no-gutters mybook-flight RTable">
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">{paxFareCompo.passenger.fullName}</div>
                        <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">{paxFareCompo.fareDetails[0].fareBasis}</div>
                        <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">{paxFareCompo.fareDetails[0].bookingClass !== null ? paxFareCompo.fareDetails[0].bookingClass.split('/')[0] : "--"}</div>
                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">{paxFareCompo.fareDetails[0].baggages !== null ? paxFareCompo.fareDetails[0].baggages : "--"}</div>
                        <div className="row no-gutters col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">{formatPrice(paxFareCompo.farePrice - paxFareCompo.taxesPrice, paxFareCompo.currency, cultureName)}</div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">{formatPrice(paxFareCompo.taxesPrice, paxFareCompo.currency, cultureName)}</div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">{formatPrice(paxFareCompo.farePrice, paxFareCompo.currency, cultureName)}</div>
                        </div>
                    </div>
                )}
            </>
        );
    }

    const renderPassengerSeatSelected = (passengerSeatSelected) => {
        return (
            <>
                {passengerSeatSelected.map((paxSeat, index) =>
                    <div key={index} className="row no-gutters mybook-flight RTable">
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">{paxSeat.paxName}</div>
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">{paxSeat.segmentName}</div>
                        <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">{paxSeat.value}</div>
                        <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-2">{formatPrice(paxSeat.amount, paxSeat.currency, cultureName)}</div>
                    </div>
                )}
            </>
        );
    }

    const renderPassengerAncillaries = (passengerAncillarySelected) => {
        return (
            <>
                {passengerAncillarySelected.map((paxAncillary, index) =>
                    <div key={index} className="row no-gutters mybook-flight RTable">
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">{paxAncillary.paxName}</div>
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">{paxAncillary.segmentName}</div>
                        <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">{paxAncillary.value}</div>
                        <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-2">{formatPrice(paxAncillary.amount, paxAncillary.currency, cultureName)}</div>
                    </div>
                )}
            </>
        );
    }

    function onClickOpenUpdateStaticData() {
        try {
            setResetValue((resetValue + 1));
        } catch (ex) { }
    }

    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : isError ? <Error textMsg={errorMsg} />
                    : (
                        <>
                            <div id={'aDetail_' + id}></div>

                            {/*Vertical Left Icon Bar*/}
                            {
                                type === 'MyBook'
                                    ? <div className="col-12 col-sm-10 col-md-6 col-lg-1 mb-4">
                                        <div className="timeline timeline-one timeline-mybook h-100">
                                            <div className="timeline-item h-100">
                                                <span className="icon icon-success"><i className="ph-airplane-bold h2"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                            }

                            {/*Flight*/}
                            <div className={type === 'MyBook' ? "col-12 col-sm-10 col-md-6 col-lg-11 mb-4" : ""}>
                                <div className={type === 'MyBook' ? "card border-light animate-up-5" : "card border-light mb-4 animate-up-5"}>
                                    <div className="row no-gutters">
                                        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">

                                            {renderLegs(flightDetail.legs)}

                                            <div className="row mybook-hotel-room">
                                                <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                                    <h6>
                                                        {t("MyBook:Composition")}:&nbsp;&nbsp;
                                                        {flightDetail.adults} <M3Icon iconName="PaxAdl" />
                                                        {flightDetail.children} <M3Icon iconName="PaxChd" />
                                                        {
                                                            flightDetail.children && flightDetail.children > 0 && flightDetail.childrenAges && flightDetail.childrenAges.length > 0 ? <>
                                                                ({t('SearchEngine:Age')}:
                                                                {
                                                                    flightDetail.childrenAges.map((age, index) => {
                                                                        return age + (index === (flightDetail.childrenAges.length - 1) ? "" : ",");
                                                                    })
                                                                }
                                                                )
                                                            </> : <></>
                                                        }

                                                        {flightDetail.infants} <M3Icon iconName="PaxInf" />
                                                        {
                                                            flightDetail.infants && flightDetail.infants > 0 && flightDetail.infantAges && flightDetail.infantAges.length > 0 ? <>
                                                                ({t('SearchEngine:Age')}:
                                                                {
                                                                    flightDetail.infantAges.map((age, index) => {
                                                                        return age + (index === (flightDetail.infantAges.length - 1) ? "" : ",");
                                                                    })
                                                                }
                                                                )
                                                            </> : <></>
                                                        }
                                                    </h6>
                                                </div>
                                            </div>

                                            <div className="row no-gutters">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <div className="card-body">
                                                        <div className="float-left">
                                                            {type === 'MyBook' && <OperationButtons pnr={id} productType={configData.Settings.Products.Flight.IdTipoProdotto} status={flightDetail.priceBar.status} allowedOps={flightDetail.allowedOperations} />}
                                                            {type === 'QuotationTO' && <QuotationOperationButtons
                                                                id={id}
                                                                callOnChangeStatusQuotationItem={customFunctions.callOnChangeStatusQuotationItem}
                                                                onCallRequoteQuotationItem={customFunctions.onCallRequoteQuotationItem}
                                                                onCallUpdateQuotationItem={customFunctions.onCallUpdateQuotationItem}
                                                                isCartQuotation={customFunctions.isCartQuotation}
                                                                quotationMode={customFunctions.quotationMode}
                                                                reservationDetail={flightDetail.reservationDetail}
                                                                quotationDetail={flightDetail.quotationDetail} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mybook-flight">
                                                {(type === 'MyBook' || type === 'QuotationTO_MyBook') ? renderPassengerFareComponents(flightDetail.passengerFareComponents) : <></>}

                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 right">
                                                        <h2 className="accordion-header " id="heading1c">
                                                            <a className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={'#pannelFlight' + id} aria-expanded="true" aria-controls={'pannelFlight' + id}>
                                                                <span className="icon-title h6 mb-0 font-weight-bold text-right w-100">{t("MyBook:DetailPassengerInfo")} </span>
                                                            </a>
                                                        </h2>
                                                    </div>

                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mybook-flight note">
                                                        <div id={'pannelFlight' + id} className="accordion-collapse collapse" role="region" aria-labelledby="heading1c">
                                                            <div className="accordion-body">
                                                                {
                                                                    (type === 'MyBook' || type === 'QuotationTO_MyBook')
                                                                        ? <div className="pt-3">
                                                                            <h6>{t("MyBook:Passengers")}:</h6>
                                                                            {
                                                                                flightDetail.passengers.map(passenger =>
                                                                                    <Passenger key={passenger.index} passenger={passenger} />
                                                                                )
                                                                            }
                                                                        </div>
                                                                        : <></>
                                                                }
                                                                <div className="pt-3 small">
                                                                    <h6>{t("MyBook:Costs")}</h6>
                                                                    <div className="row no-gutters mybook-flight TTable">
                                                                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">{t("MyBook:Passenger")}</div>
                                                                        <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">F. Basis</div>
                                                                        <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">Class</div>
                                                                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">Bagaglio</div>
                                                                        <div className="row no-gutters col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">Tariffa</div>
                                                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">Tasse</div>
                                                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">Tot. Costo</div>
                                                                        </div>
                                                                    </div>
                                                                    {renderPassengerCosts(flightDetail.passengerFareComponents)}
                                                                </div>
                                                                {
                                                                    flightDetail.seatInfos && flightDetail.seatInfos.length > 0 && <div className="pt-3 small">
                                                                        <h6>Posti selezionati</h6>
                                                                        <div className="row no-gutters mybook-flight TTable">
                                                                            <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">Passeggero</div>
                                                                            <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-3">Tratta</div>
                                                                            <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">Posto</div>
                                                                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">Tot.</div>
                                                                        </div>
                                                                        {renderPassengerSeatSelected(flightDetail.seatInfos)}
                                                                    </div>
                                                                }
                                                                {
                                                                    flightDetail.ancillariesInfos && flightDetail.ancillariesInfos.length > 0 && <div className="pt-3 small">
                                                                        <h6>Posti selezionati</h6>
                                                                        <div className="row no-gutters mybook-flight TTable">
                                                                            <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">Passeggero</div>
                                                                            <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-3">Tratta</div>
                                                                            <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">Selezionato</div>
                                                                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">Tot.</div>
                                                                        </div>
                                                                        {renderPassengerAncillaries(flightDetail.ancillariesInfos)}
                                                                    </div>
                                                                }
                                                                {
                                                                    flightDetail.notes && <div className="pt-3 small">
                                                                        <h6>Note</h6>
                                                                        <div className="row no-gutters">
                                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                                <div dangerouslySetInnerHTML={{ __html: flightDetail.notes }}></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {
                                                                    <ServiceNote notes={flightDetail.notesService} noteTypeToShow={[12]} />
                                                                }
                                                                {
                                                                    <ServiceNote notes={flightDetail.notesService} noteTypeToShow={[13]} />
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*Price Bar*/}
                                        <PriceBar
                                            oPriceBar={flightDetail.priceBar}
                                            showNetPrices={showNetPrices}
                                            showCost={showCost}
                                            reservationDetail={flightDetail.reservationDetail}
                                            quotationDetail={flightDetail.quotationDetail}
                                            quotationMode={customFunctions.quotationMode}
                                            customView={{ notShowPaymentAvailable: (type === 'QuotationTO' || type === 'QuotationTO_MyBook') }}
                                            isEnabledUpdateStaticData={isEnabledUpdateStaticData}
                                            onClickOpenUpdateStaticData={onClickOpenUpdateStaticData}/>
                                    </div>
                                </div>
                            </div>

                            {/*Modals*/}
                            {type === 'MyBook' && <CancelReservationModal pnr={id} productType={configData.Settings.Products.Flight.IdTipoProdotto} />}
                            {type === 'MyBook' && <PrintPnrModal pnr={id} productType={configData.Settings.Products.Flight.IdTipoProdotto} />}
                            {type === 'MyBook' && <RequotePnrModal pnr={id} productType={configData.Settings.Products.Flight.IdTipoProdotto} />}
                            {type === 'MyBook' && flightDetail.priceBar.paymentData !== null && <PayWithCreditCardModal pnr={id} productType={configData.Settings.Products.Flight.IdTipoProdotto} paymentData={flightDetail.priceBar.paymentData} />}
                            {isEnabledUpdateStaticData && <UpdateFlightData
                                modalId={modalId}
                                quotationItemId={id}
                                saveProductData={saveProductData}
                                resetValue={resetValue} />}
                        </>
                    )
            }
        </>
    );
}