import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { QuotationTableMargin } from '../../QuotationTO/components/QuotationTableMargin.js';
import { formatPrice } from '../../../js/Utils.js';
import { M3Icon } from '../../Common/M3Icon.js';

export const TrippieMarginItems = ({ tableMarginItems, callDeleteCart }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const { t } = useTranslation();

    const [totalPrice, setTotalPrice] = useState(null);
    const [totalPriceCurrency, setTotalPriceCurrency] = useState(null);
    const [isCommissionable, setIsCommissionable] = useState(null);
    const [marginItems, setMarginItems] = useState(null);

    useEffect(() => {
        if (tableMarginItems) {
            setIsCommissionable(tableMarginItems.isCommissionable);
            setTotalPrice(tableMarginItems.totalPrice);
            setTotalPriceCurrency(tableMarginItems.currency);
            setMarginItems(tableMarginItems);
        }

    }, [tableMarginItems])

    function onClickConfirmDeleteTrippie() {
        try {
            let myBtn = document.getElementById("open_ResetTrippie");
            myBtn.click();
        } catch (ex) { }
    }

    return (
        <>
            {
                <div className="trippie-card bg-white" style={{ position: 'sticky', top: '105px', zIndex: '1000', backgroundColor: '#ffffff' }}>
                    {
                        marginItems && <>
                            <div className="text-right pr-4">
                                <span className="h5 fw-bold">{t("General:TotPrice")}</span>
                                <span className="h5 fw-bold mr-2">{isCommissionable ? "Commissionabile" : ""}</span>
                                <span className="h2">{formatPrice(totalPrice, totalPriceCurrency, cultureName)}</span>
                            </div>
                            <QuotationTableMargin
                                marginItems={marginItems}
                                cultureName={cultureName}
                                isCommissionable={isCommissionable}
                            />
                        </>
                    }
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-2 mr-2 text-right">
                                <button className="text-left btn btn-sm tp-btn-select py-2 py-2 " onClick={() => onClickConfirmDeleteTrippie()}>
                                    <M3Icon iconName="Delete" externalClass="h125 text-white mr-4 position-relative top-3" />
                                    Svuota Trippie
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
