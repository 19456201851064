import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Gallery } from '../../../Product/Gallery';
import { CruiseDetailStaticData } from './CruiseDetailStaticData';
import configData from "../../../../appsettings.json";

export const CruiseModalStatic = ({ cruiseInfo, modalCloseProductId }) => {
    const { t } = useTranslation();

    useEffect(() => {

        let myModal = document.getElementById("schedStaticCruise");
        myModal.addEventListener('hidden.bs.modal', function () {
            modalCloseProductId(null);
        });

    }, []);

    useEffect(() => {

        if (cruiseInfo) {
            let myBtn = document.getElementById("btn_openModalCruise");
            myBtn.click();
        }

    }, [cruiseInfo]);

    return (
        <>
            <a id="btn_openModalCruise" data-bs-toggle="modal" data-bs-target="#schedStaticCruise"></a>
            <div className="modal fade" id="schedStaticCruise" tabIndex="-1" aria-labelledby="schedStaticCruiseLabel" aria-hidden="true">
                <div className="modal-dialog modal-xxl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="schedStaticCruiseLabel">
                                <data m3lab="ActionMenu.Detail">{t(`ActionMenu:Detail`)}</data>
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row px-2">
                                <div className="col-9">
                                    <label><var>{cruiseInfo.city}</var></label>
                                </div>
                            </div>
                            <div className="row p-3">
                                <Gallery gallery={cruiseInfo.images ? cruiseInfo.images.map((img) => { return img.path }) : null} modeView="modal" productType={configData.Settings.Products.Transfer.IdTipoProdotto } />
                            </div>
                            <CruiseDetailStaticData cruiseData={cruiseInfo} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                <data m3lab="Button.Close">{t('Button:Close')}</data>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}