import React from 'react';
import { Header } from '../PageComponent/Header';
import { useTranslation } from 'react-i18next';

export const LayoutWebSite = (props) => {
    const { t } = useTranslation();

    document.title = t(`home:title`);

    return (
        <div className="glamour-custom-layout  min-vh-100">
            {props.websiteId !== null && <Header websiteId={props.websiteId} />}
            <div id="childrenContainer">
                {props.children}
            </div>
        </div>
    );
}

