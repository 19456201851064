import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Loading } from '../Common/Loading';
import { M3Icon } from '../Common/M3Icon';
import { Passenger } from './Passenger';
import { PriceBar } from './PriceBar';
import { CancelReservationModal } from './OperationModals/CancelReservationModal';
import { PayWithCreditCardModal } from './OperationModals/PayWithCreditCardModal';
import { OperationButtons } from './OperationButtons';
import { QuotationOperationButtons } from '../QuotationTO/components/QuotationOperationButtons';
import { formatPrice, formatDateTime } from '../../js/Utils.js';
import configData from "../../appsettings.json";
import { Error } from '../Common/Error';
import { useTranslation } from 'react-i18next';
import { TrainChangePaxModal } from './OperationModals/TrainChangePaxModal';
import { TrainChangeDateModal } from './OperationModals/TrainChangeDateModal';
import { TrainChangeItinerary } from './OperationModals/TrainChangeItinerary';
import { ServiceNote } from './ServiceNote';
import { UpdateTrainData } from '../MyBook/ProductData/components/UpdateTrainData';



export const TrainDetail = ({ id, type, showNetPrices, showCost, customFunctions, forceRender, saveProductData }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [trainDetail, setTrainDetail] = useState([]);
    let [openChangeDatePanel, setOpenChangeDatePanel] = useState(false)
    let [openChangeIteneraryPanel, setOpenChangeIteneraryPanel] = useState(false)

    const [modalId, setModalId] = useState("");
    const [resetValue, setResetValue] = useState(1);
    const [isEnabledUpdateStaticData, setIsEnabledUpdateStaticData] = useState(false);


    useEffect(() => {
        setResetValue(1);
        loadItem();

        setModalId("modalUpdateStaticDAta_" + id)
    }, []);

    useEffect(() => {
        if (forceRender && forceRender > 0) {
            loadItem();
            setResetValue(1);
        }
    }, [forceRender]);

    function loadItem() {
        setIsLoading(true);
        switch (type) {
            case 'MyBook':
            case 'QuotationTO_MyBook':
                const getTrainDetail = async (inputData) => {
                    const response = callGetTrainDetail(inputData);
                }
                getTrainDetail({ PNR: id, ProductType: configData.Settings.Products.Train.IdTipoProdotto, Options: { WebsiteBaseUrl: configData.Settings.OldWebSite_BaseUrl } });
                break;
            case 'QuotationTO':
                const getQuotationItem = async (inputData) => {
                    const response = callGetQuotationItem(inputData);
                }
                getQuotationItem(id);
                break;
            default:
                setIsLoading(false);
        }
    }

    async function callGetTrainDetail(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetReservationDetail', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (type === 'QuotationTO_MyBook' || type === 'MyBook')
                customFunctions.onCallSaveProductInfo(response, type, id)

            setTrainDetail(response);
            setIsError(false);

        } else {
            setErrorMsg(t("MyBook:PracticeNotAvailableMsg").replace("##ID##", id));
            setIsError(true);
        }

        setIsLoading(false);
    }

    async function callGetQuotationItem(id) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationitem/${id}`, requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            customFunctions.onCallSaveProductInfo(response, type, id);

            setTrainDetail(response);
            setIsError(false);

            if (response.allowedOperations && response.allowedOperations.EnabledUpdateStaticData)
                setIsEnabledUpdateStaticData(response.allowedOperations.EnabledUpdateStaticData.enabled && (type !== 'QuotationTOEndUser' && type !== 'QuotationEndUser'));
        } else {
            setErrorMsg(t("MyBook:QuotaionProductNotLoadMsg").replace("##ID##", id));
            setIsError(true);
        }

        setIsLoading(false);
    }

    const renderLeg = (leg, key) => {
        return (
            <div className="row mybook-trein">
                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <h6>
                        {
                            type === 'QuotationTO' && key === 0 && <span className="badge bg-gray-300 text-dark mr-2">{id}</span>
                        }
                        {leg.segments[0].departureStation}
                    </h6>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="row">
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <span>{leg.segments[0].trainNumber}</span>
                            <p>{leg.segments[0].trainName}</p>
                        </div>
                        <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                            <span>{formatDateTime(leg.segments[0].departureDate, cultureName, { onlyTime: true })}</span>
                            <p>{formatDateTime(leg.segments[0].departureDate, cultureName, { noYear: true })}</p>
                        </div>
                        <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                            <span className="time">{leg.segments[0].duration}h</span>
                            <p>durata</p>
                        </div>
                        <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                            <span>{formatDateTime(leg.segments[0].arrivalDate, cultureName, { onlyTime: true })}</span>
                            <p>{formatDateTime(leg.segments[0].arrivalDate, cultureName, { noYear: true })}</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <h6>{leg.segments[0].arrivalStation}</h6>
                </div>
            </div>
        );
    }

    const renderPassengerFareComponents = (passengerFareComponents) => {
        return (
            <>
                {passengerFareComponents.map((paxFareCompo, index) =>
                    <div key={index} className="row">
                        <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <i className="ph-user-fill"></i><label>{t("MyBook:Passenger")}&nbsp; {paxFareCompo.passenger.index}:</label>
                        </div>
                        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 text-left">
                            <div className="row">
                                <p className="my-1">
                                    <span>{paxFareCompo.passenger.paxType}</span>
                                    <span>{paxFareCompo.passenger.fullName}</span>
                                    <span>{paxFareCompo.passenger.birthDate !== null ? formatDateTime(paxFareCompo.passenger.birthDate, cultureName) : ""}</span>
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-12 p-0">
                                    <label>{t("Product:Train:Section")}:</label> <span>{paxFareCompo.fareDetails[0].depStation} - {paxFareCompo.fareDetails[0].arrStation}</span>
                                    <label>{t("Product:Train:Item")} N.</label> <span>{paxFareCompo.fareDetails[0].trainNumber}</span>
                                    {paxFareCompo.fareDetails[0].ticketNumber !== null ? (<><label>Ticket:</label> <span>{paxFareCompo.fareDetails[0].ticketNumber}</span></>) : (<></>)}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }

    const renderFareDetails = (paxFullName, fareDetails) => {
        return (
            fareDetails.map((fareDetail, index) =>
                <div key={index} className="row no-gutters mybook-flight RTable">
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">{paxFullName}</div>
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">{fareDetail.ticketNumber}</div>
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-2">{fareDetail.offerName}</div>
                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">{fareDetail.serviceName}</div>
                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">{fareDetail.trainNumber}</div>
                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">{formatPrice(fareDetail.price, fareDetail.currency, cultureName)}</div>
                </div>
            )
        );
    }

    const renderPassengerCosts = (passengerFareComponents) => {
        return (
            passengerFareComponents.map(paxFareCompo =>
                renderFareDetails(paxFareCompo.passenger.fullName, paxFareCompo.fareDetails)
            )
        );
    }

    function onClickOpenUpdateStaticData() {
        try {
            setResetValue((resetValue + 1));
        } catch (ex) { }
    }

    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : isError ? <Error textMsg={errorMsg} />
                    : (
                        <>
                            <div id={'aDetail_' + id}></div>

                            {/*Vertical Left Icon Bar*/}
                            {
                                type === 'MyBook'
                                    ? <div className="col-12 col-sm-10 col-md-6 col-lg-1 mb-4">
                                        <div className="timeline timeline-one timeline-mybook h-100">
                                            <div className="timeline-item h-100">
                                                <span className="icon icon-success"><i className="ph-train-bold h2"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                            }

                            {/*Train*/}
                            <div className={type === 'MyBook' ? "col-12 col-sm-10 col-md-6 col-lg-11 mb-4" : ""}>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-4">
                                    <div className={type === 'MyBook' ? "card border-light animate-up-5" : "card border-light mb-4 animate-up-5"}>
                                        <div className="row no-gutters">
                                            <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                                                {/*Leg Outbound*/}
                                                {renderLeg(trainDetail.outbound.leg)}

                                                {/*Leg Return*/}
                                                {
                                                    trainDetail.return !== null && trainDetail.return !== undefined
                                                        ? (renderLeg(trainDetail.return.leg))
                                                        : (<></>)
                                                }

                                                <div className="row no-gutters">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div className="card-body">
                                                            <div className="float-left">
                                                                {type === 'MyBook' && <OperationButtons isOpenChangeDatePanel={openChangeDatePanel} openChangeDatePanel={setOpenChangeDatePanel} isOpenChangeIteneraryPanel={openChangeIteneraryPanel} openChangeIteneraryPanel={setOpenChangeIteneraryPanel} pnr={id} productType={configData.Settings.Products.Train.IdTipoProdotto} status={trainDetail.priceBar.status} allowedOps={trainDetail.allowedOperations} />}
                                                                {
                                                                    type === 'QuotationTO' && <QuotationOperationButtons
                                                                        id={id}
                                                                        callOnChangeStatusQuotationItem={customFunctions.callOnChangeStatusQuotationItem}
                                                                        onCallRequoteQuotationItem={customFunctions.onCallRequoteQuotationItem}
                                                                        onCallUpdateQuotationItem={customFunctions.onCallUpdateQuotationItem}
                                                                        isCartQuotation={customFunctions.isCartQuotation}
                                                                        quotationMode={customFunctions.quotationMode}
                                                                        reservationDetail={trainDetail.reservationDetail}
                                                                        quotationDetail={trainDetail.quotationDetail} />
                                                                }
                                                                <p></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {openChangeDatePanel && <>
                                                    {type === 'MyBook' && <TrainChangeDateModal openChangeDatePanel={setOpenChangeDatePanel}  pnr={id} passengers={trainDetail.passengers} />}
                                                </>}
                                                {openChangeIteneraryPanel && <>
                                                    {type === 'MyBook' && <TrainChangeItinerary trainDetail={trainDetail} openChangeIteneraryPanel={setOpenChangeIteneraryPanel} pnr={id} passengers={trainDetail.passengers} />}
                                                </>}
                                                <div className="row mybook-hotel-room">
                                                    <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                                        <h6>
                                                            Composizione:&nbsp;&nbsp;
                                                            {trainDetail.adults} <M3Icon iconName="PaxAdl" />
                                                            {trainDetail.children} <M3Icon iconName="PaxChd" />

                                                            {
                                                                trainDetail.childrenAges && trainDetail.childrenAges.length > 0 && <>
                                                                    ({t('SearchEngine:Age')}:
                                                                    {
                                                                        trainDetail.childrenAges.map((age, index) => {
                                                                            return age + (index === (trainDetail.childrenAges.length - 1) ? "" : ",");
                                                                        })
                                                                    }
                                                                    )
                                                                </>
                                                            }
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div className="mybook-flight">
                                                    {renderPassengerFareComponents(trainDetail.passengerFareComponents)}

                                                    <div className="row">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 right">
                                                            <h2 className="accordion-header " id="heading1c">
                                                                <a className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={'#pannelTrain' + id} aria-expanded="true" aria-controls={'pannelTrain' + id}>
                                                                    <span className="icon-title h6 mb-0 font-weight-bold text-right w-100">{t("MyBook:DetailPassengerCostsInfo")}</span>
                                                                </a>
                                                            </h2>
                                                        </div>

                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mybook-trein note">
                                                            <div id={'pannelTrain' + id} className="accordion-collapse collapse" role="region" aria-labelledby="heading1c">
                                                                <div className="accordion-body">
                                                                    {
                                                                        type === 'MyBook'
                                                                            ? <div className="pt-3">
                                                                                <h6>{t("MyBook:Passengers")}:</h6>
                                                                                {
                                                                                    trainDetail.passengers.map(passenger =>
                                                                                        <Passenger key={passenger.index} passenger={passenger} />
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            : <></>
                                                                    }

                                                                    <div className="pt-3 small">
                                                                        <h6>Costi</h6>
                                                                        <div className="row no-gutters mybook-flight TTable">
                                                                            <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">{t("MyBook:Passenger")}</div>
                                                                            <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">Ticket</div>
                                                                            <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-2">{t("MyBook:Offer")}</div>
                                                                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">{t("MyBook:Service")}</div>
                                                                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">N. &nbsp;{t("Product:Train:Item")}</div>
                                                                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">{t("MyBook:Cost")}</div>
                                                                        </div>
                                                                        {renderPassengerCosts(trainDetail.passengerFareComponents)}
                                                                    </div>

                                                                    {
                                                                        trainDetail.publicNotes && <div className="pt-3 small">
                                                                            <h6>Note</h6>
                                                                            {trainDetail.publicNotes.map((note, index) =>
                                                                                <p key={index} dangerouslySetInnerHTML={{ __html: note }}></p>
                                                                            )}
                                                                        </div>
                                                                    }
                                                                    {
                                                                        <ServiceNote notes={trainDetail.notesService} noteTypeToShow={[12]} />
                                                                    }
                                                                    {
                                                                        <ServiceNote notes={trainDetail.notesService} noteTypeToShow={[13]} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/*Price Bar*/}
                                            <PriceBar
                                                oPriceBar={trainDetail.priceBar}
                                                showNetPrices={showNetPrices}
                                                showCost={showCost}
                                                reservationDetail={trainDetail.reservationDetail}
                                                quotationDetail={trainDetail.quotationDetail}
                                                quotationMode={customFunctions.quotationMode}
                                                customView={{ notShowPaymentAvailable: (type === 'QuotationTO' || type === 'QuotationTO_MyBook') }}
                                                isEnabledUpdateStaticData={isEnabledUpdateStaticData}
                                                onClickOpenUpdateStaticData={onClickOpenUpdateStaticData} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*Modals*/}
                            {type === 'MyBook' && <TrainChangePaxModal trainDetail={trainDetail} pnr={id} passengers={trainDetail.passengers} />}
                            {type === 'MyBook' && <CancelReservationModal pnr={id} productType={configData.Settings.Products.Train.IdTipoProdotto} />}
                            {type === 'MyBook' && trainDetail.priceBar.paymentData !== null && <PayWithCreditCardModal pnr={id} productType={configData.Settings.Products.Train.IdTipoProdotto} paymentData={trainDetail.priceBar.paymentData} />}
                            {isEnabledUpdateStaticData && <UpdateTrainData
                                modalId={modalId}
                                quotationItemId={id}
                                saveProductData={saveProductData}
                                resetValue={resetValue} />}
                        </>
                    )
            }
        </>
    );
}