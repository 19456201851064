import React, { useState, useEffect } from 'react';
import { MenuBarVertical } from './MenuBarVertical';
import configData from "../../appsettings.json";
import { useTranslation } from 'react-i18next';


async function resetPassword(inputData) {
    const requestOption = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(inputData)
    };

    return fetch(configData.Settings.CommonApi_BaseUrl + 'user/changepassword', requestOption)/*.then(data => data.json())*/;
}

export const Profile = () => {
    const { t } = useTranslation();
    const [userProfile, setUserProfile] = useState([]);

    const [passwordOld, setPasswordOld] = useState();
    const [passwordNew, setPasswordNew] = useState();
    const [passwordNewVerify, setPasswordNewVerify] = useState();
    const [changePasswordErrorMsg, setChangePasswordErrorMsg] = useState(false);
    const [changePasswordOkMsg, setChangePasswordOkMsg] = useState(false);

    useEffect(() => {
        console.log(configData.Settings.CommonApi_BaseUrl);

        let isSubscribed = true;

        const fetchData = async () => {
            const response = await fetch(configData.Settings.CommonApi_BaseUrl + 'user/getprofile', { credentials: 'include' });
            const data = await response.json();

            if (isSubscribed) {
                setUserProfile(data);
            }
        }

        fetchData()
            .catch(console.error); // make sure to catch any error

        return () => isSubscribed = false; // cancel any future `setData`
    }, []);

    const validatePasswordNew = (e) => {
        let inputTarget = document.getElementById("inputPasswordNew");
        inputTarget.classList.remove("is-valid");
        inputTarget.classList.remove("is-invalid");

        let pwd = e.target.value;

        let isNotValid = pwd === "" || pwd === null || pwd === undefined || pwd.length < 8 || pwd.length > 20 || pwd.indexOf(' ') > 0;
        if (isNotValid) {
            if (!inputTarget.className.includes("is-invalid"))
                inputTarget.className += " is-invalid";
        } else {
            if (!inputTarget.className.includes("is-valid"))
                inputTarget.className += " is-valid";

            setPasswordNew(pwd);
        }
    }

    const validatePasswordNewVerify = (e) => {
        let inputPasswordNew = document.getElementById("inputPasswordNew");
        let actualPasswordNew = inputPasswordNew.value;

        let inputPasswordNewVerify = document.getElementById("inputPasswordNewVerify");
        inputPasswordNewVerify.classList.remove("is-valid");
        inputPasswordNewVerify.classList.remove("is-invalid");

        let actualValue = e.target.value;

        let isNotValid = actualValue !== actualPasswordNew;
        if (isNotValid) {
            if (!inputPasswordNewVerify.className.includes("is-invalid"))
                inputPasswordNewVerify.className += " is-invalid";
        } else {
            if (!inputPasswordNewVerify.className.includes("is-valid"))
                inputPasswordNewVerify.className += " is-valid";

            setPasswordNewVerify(actualValue);
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();

        if (passwordNew !== passwordNewVerify)
            return;

        const response = await resetPassword({ PasswordOld: passwordOld, PasswordNew: passwordNew });
        if (response.ok) {
            setChangePasswordErrorMsg(false);
            setChangePasswordOkMsg(true);
        } else {
            setChangePasswordErrorMsg(true);
            setChangePasswordOkMsg(false);
        }
    }

    return (
        <>
            <MenuBarVertical activeAnchorId="aProfilo" />

            <div className="section section-lg bg-gray-200 page-content" id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card card-body bg-white border-light mb-4">
                                <h2 className="h5 mb-4">{t("MyBook:GeneralInformation")} </h2>
                                <form>
                                    <div className="row">
                                        <div className="form-floating col-md-6 mb-3">
                                            <input className="form-control" id="first_name" type="text" value={userProfile.ragioneSociale} placeholder="ragioneSociale" readOnly />
                                            <label htmlFor="first_name" className="active">{t("MyBook:CompanyName")} </label>
                                        </div>
                                        <div className="form-floating col-md-6 mb-3">
                                            <input className="form-control" id="last_name" type="text" value={userProfile.ragioneSocialeAgg} placeholder="ragioneSocialeAgg" readOnly />
                                            <label htmlFor="last_name" className="active">{t("MyBook:CommercialName")} </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-floating col-12">
                                            <input className="form-control" id="address" type="text" value={userProfile.address} placeholder="address" readOnly />
                                            <label htmlFor="address" className="active">{t("MyBook:Address")} </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-floating col-sm-4 mb-3">
                                            <input className="form-control" id="city" type="text" value={userProfile.addressCity} placeholder="addressCity" readOnly />
                                            <label htmlFor="city" className="active">{t("MyBook:City")}</label>
                                        </div>
                                        <div className="form-floating col-sm-4 mb-3">
                                            <input className="form-control" id="state" type="text" value={userProfile.addressCountry} placeholder="addressCountry" readOnly />
                                            <label htmlFor="city" className="active">{t("MyBook:State")}</label>
                                        </div>
                                        <div className="form-floating col-sm-4">
                                            <input className="form-control" id="zip" type="tel" value={userProfile.addressZipCode} placeholder="addressZipCode" readOnly />
                                            <label htmlFor="zip" className="active">{t("MyBook:Zip")}</label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="card border-light p-0 p-md-4 mb-4">
                                <div className="card-body">
                                    <h3 className="h5 mb-0">{t("MyBook:ChangePassword")}</h3>

                                    {!changePasswordOkMsg ? (
                                        <form className="form mt-5" autoComplete="off" onSubmit={handleSubmit}>
                                            <div className="form-floating mb-4">
                                                <input type="password" className="form-control" id="inputPasswordOld" onChange={e => setPasswordOld(e.target.value)} required />
                                                <label htmlFor="inputPasswordOld" className="active">{t("MyBook:ActualPassword")}</label>
                                            </div>
                                            <div className="form-floating mb-4">
                                                <input type="password" className="form-control" id="inputPasswordNew" onChange={e => validatePasswordNew(e)} required />
                                                <label htmlFor="inputPasswordNew" className="active">{t("MyBook:NewPassword")}</label>
                                                <span className="form-text small text-muted">
                                                    {t("Login:PasswordText")}
                                                </span>
                                            </div>
                                            <div className="form-floating mb-4">
                                                <input type="password" className="form-control" id="inputPasswordNewVerify" onChange={e => validatePasswordNewVerify(e)} required />
                                                <label htmlFor="inputPasswordNewVerify" className="active">{t("MyBook:Verify")}</label>
                                                <span className="form-text small text-muted">
                                                    {t("MyBook:ConfirmPassword")}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-dark">{t(`Button:Save`)}</button>
                                            </div>
                                        </form>
                                    ) : (
                                        <div className="row">
                                            <div className="col-12 py-2">
                                                <h6 className="status confirm">{t("MyBook:ResetPasswordSuccess")}</h6>
                                            </div>
                                        </div>
                                    )}

                                    {changePasswordErrorMsg ? (
                                        <div className="row">
                                            <div className="col-12 py-2 text-center">
                                                <h6 className="status delete">Error resetting password</h6>
                                            </div>
                                        </div>
                                    ) : (<></>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
