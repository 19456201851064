import React from 'react';
import { formatPrice } from '../../../js/Utils.js';
import { IconChoosePrint } from './IconChoosePrint.js';
import { useTranslation } from 'react-i18next';
import { PrintStepHeader } from './PrintStepHeader.js';



export const PrintScadenziario = ({ scadenziario, cultureName, onHandleIsPrint }) => {
    const { t } = useTranslation();

    function getTotalAmountRate(rataArr) {
        let total = 0;
        let currency = "";


        rataArr.map(el => {
            total += el.amount ? el.amount : el.totalAmount; // totalAmount per conferma
            currency = el.currency;
        });

        return formatPrice(total, currency, cultureName);
    }

    return (
        <>
            {scadenziario && scadenziario.length > 0 &&
                <>
                <PrintStepHeader
                    title={t(`scadenziario:item`)}
                    PNR=''
                    totaleViaggio=''
                    iconName=''
                    cultureName={cultureName}
                    onHandleIsPrint={onHandleIsPrint}
                />
                <div className="p-2 border shadow">
                    <table className="w-100">
                        <thead className="h6">
                            <tr className="text-right h6 customBackground" >
                                <td className="text-left">
                                    {t(`General:Description`)}
                                </td>
                                <td className="text-left w-20">
                                    {t(`General:Date`)}
                                </td>
                                <td className="text-right w-20">
                                    {t(`General:Total`)}
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {scadenziario.map((rata, index) =>
                                <tr key={index} >
                                    <td>
                                        {t(`scadenziario:rate`)} {index + 1}
                                    </td>
                                    <td className="text-left w-20">
                                        {rata[0]}
                                    </td>
                                    <td className="text-right w-20">
                                        {getTotalAmountRate(rata[1])}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </>
            }
        </>
    );
}