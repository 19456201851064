import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import configData from "../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { getWebsiteId } from '../../js/Utils.js';


export const CartRegistration = ({ callSetValidForm }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();


    const [inputData, setInputData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        privacyPolicy: false
    });

    const [validateInputData, setValidateInputData] = useState({
        isValidEmail: false,
        isTouchedEmail: false,

        isValidPassword: false,
        isTouchedPassword: false,

        isValidConfirmPassword: false,
        isTouchedConfirmPassword: false,

        isValidPrivacyPolicy: false,
        isTouchedPrivacyPolicy: false,

        isValidForm: false
    });

    const [userExist, setUserExist] = useState(false);

    const [emailErrorMsg, setEmailErrorMsg] = useState('');
    const [emailCheckUserLoading, setEmailCheckUserLoading] = useState(false);
    const [dynamicNotes, setDynamicNotes] = useState([]);
    const [selectedDynamicNotes, setSelectedDynamicNotes] = useState([]);

    useEffect(() => {
        const getDynamicNotes = async () => { const response = callGetDynamicNotes(); }
        getDynamicNotes();
    }, [])

    async function callGetDynamicNotes() {
        let inputData = {
            cultureCode: cultureName,
            websiteId: parseInt(getWebsiteId()),
            pageId: 16,
            productTypes: [],
            productCode: null,
            checkInFrom: null,
            checkInTo: null,
            webDestinationIds: null,
            supplierId: null,
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'DynamicNote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();

        if (response.success) {
            setDynamicNotes(response.dynamicNotes);
            let dynamicNotesSelected = [];
            if (response.dynamicNotes && response.dynamicNotes.length > 0) {

                for (let i = 0; i < response.dynamicNotes.length; i++) {
                    let dn = response.dynamicNotes[i];
                    if (dn.checkTypeId !== 1) {
                        dynamicNotesSelected.push({
                            noteId: dn.noteId,
                            selected: false,
                            touched: false
                        });
                    }
                }

                setSelectedDynamicNotes(dynamicNotesSelected);
            }

            if (dynamicNotesSelected.length === 0) {
                setValidateInputData({
                    ...validateInputData,
                    isValidPrivacyPolicy: false
                });
            }
        }
    }

    // verifica validità dati
    function onBlurEventMail(event, propertyName) {
        const checkUsernameExistAsync = async (event, propertyName) => { const response = callCheckUsernameExist(event, propertyName); }
        checkUsernameExistAsync(event, propertyName);
    }

    async function callCheckUsernameExist(event, propertyName) {
        setEmailCheckUserLoading(false);
        let isValidEmailFormat = true;
        let isNewUserName = true;

        // verifico che sia una mail cosi evito di chiamare il server
        let email = event.target.value;
        const isNotEmpty = (email !== '' && email !== undefined);
        if (!isNotEmpty)
            setEmailErrorMsg(t('Login:MailNotValid'));

        if (isNotEmpty) {
            const isValidMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
            isValidEmailFormat = isValidEmailFormat && isValidMail;

            if (!isValidEmailFormat)
                setEmailErrorMsg(t('Login:MailNotValid'));

        } else {
            isValidEmailFormat = false;
        }

        if (isValidEmailFormat) {
            setEmailCheckUserLoading(true);

            let checkUserNameRq = {
                Email: email,
                WebsiteId: parseInt(getWebsiteId())
            };

            const requestOption = {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(checkUserNameRq)
            };

            const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'user/checkusernameexists', requestOption);
            if (fetchedRes.ok) {
                const response = await fetchedRes.json();
                isNewUserName = !response.exist;
                setUserExist(response.exist);
            }
            else {
                isNewUserName = false;
            }
        }

        setEmailCheckUserLoading(false);
        setUserExist(!isNewUserName);
        if (isNewUserName)
            onBlurEvent(event, propertyName, isNewUserName && isValidEmailFormat);

    }

    function onBlurEvent(event, propertyName, isExternalValid = true) {
        let updateInputData = { ...inputData };

        let newValue = event.target.value;

        updateInputData[propertyName] = newValue;

        let isValidForm = false;

        let prevValidateInputData = { ...validateInputData };
        delete prevValidateInputData.isValidForm;
        const isNotEmpty = (newValue !== '' && newValue !== undefined);

        switch (propertyName) {
            case 'email': {
                delete prevValidateInputData.isValidEmail;
                delete prevValidateInputData.isTouchedEmail;

                isValidForm = isExternalValid && Object.values(prevValidateInputData).every(item => item);
                if (isValidForm)
                    setEmailErrorMsg('');

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidEmail: isExternalValid,
                    isTouchedEmail: true
                });
                break;
            }

            case 'password': {
                delete prevValidateInputData.isValidPassword;
                delete prevValidateInputData.isTouchedPassword;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                let isValidPassword = isNotEmpty;
                let isValidConfirmPassword = isNotEmpty;

                if (isNotEmpty) {
                    let isStrongPassword = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(newValue);
                    isValidPassword = isValidPassword && isStrongPassword;

                    if (isValidPassword)
                        isValidConfirmPassword = newValue === updateInputData.password;
                }

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidPassword: isValidPassword,
                    isTouchedPassword: true,
                    isValidConfirmPassword: isValidConfirmPassword
                });
                break;
            }

            case 'confirmPassword': {
                delete prevValidateInputData.isValidConfirmPassword;
                delete prevValidateInputData.isTouchedConfirmPassword;

                const isValidConfirmPassword = isNotEmpty && newValue === updateInputData.password;
                isValidForm = isValidConfirmPassword && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidConfirmPassword: isValidConfirmPassword,
                    isTouchedConfirmPassword: true
                });
                break;
            }
        }

        setInputData(updateInputData);
    }

    function onCheckDynamicNote(noteId, event) {
        let isChecked = event.target.checked;
        let isAllChecked = true;
        let isAllTouched = true;

        // aggiorno
        let sNotes = [...selectedDynamicNotes];
        let selectedNoteIdx = sNotes.findIndex((x => x.noteId === noteId));
        sNotes[selectedNoteIdx].selected = isChecked;
        sNotes[selectedNoteIdx].touched = true;

        // verifico che siano tutte checcate
        if (dynamicNotes && dynamicNotes.length > 0) {

            for (let i = 0; i < dynamicNotes.length; i++) {
                let myNote = dynamicNotes[i];

                if (myNote.noteId === noteId && !isChecked) {
                    isAllChecked = false;
                }
                else if (myNote.checkTypeId !== 1 && myNote.noteId !== noteId) {
                    let selectedNoteIdx = sNotes.findIndex((x => x.NoteId === noteId));
                    if (selectedNoteIdx > 0 && !sNotes[selectedNoteIdx].Selected) {
                        isAllChecked = false;
                    }
                }

                if (!isAllChecked)
                    break;

            }

            isAllTouched = Object.values(sNotes).every(item => item.touched);
        }

        // verifico che il form sia valido
        let prevValidateInputData = { ...validateInputData };
        delete prevValidateInputData.isValidForm;
        delete prevValidateInputData.isValidPrivacyPolicy;
        delete prevValidateInputData.isTouchedPrivacyPolicy;

        let isValidForm = isAllChecked && Object.values(prevValidateInputData).every(item => item);

        setValidateInputData({
            ...validateInputData,
            isValidForm: isValidForm,
            isValidPrivacyPolicy: isAllChecked,
            isTouchedPrivacyPolicy: isAllTouched
        });
    }

    useEffect(() => {
        if (userExist) {
            callSetValidForm(inputData);
        }
        else {
            if (validateInputData.isValidForm) {
                callSetValidForm(inputData);
            } else {
                callSetValidForm(null);
            }
        }

    }, [validateInputData])

    return (
        <>
            <div>
                <h5>{t("MyBook:ConfirmEmail")}</h5>
                <p className="m-2"><span>{t('Cart:InsertMail')}</span></p>
            </div>
            <div className={"form-floating col-md-12" + (validateInputData.isTouchedEmail && !validateInputData.isValidEmail ? " insert-danger" : "")}>
                <input type="text"
                    className="form-control"
                    placeholder="Email *"
                    aria-label="Email"
                    onChange={e => onBlurEventMail(e, 'email')}
                />
                {
                    !userExist && validateInputData.isTouchedEmail && !validateInputData.isValidEmail && <div className="text-danger mt-1">{emailErrorMsg}</div>
                }
                <label htmlFor="floatingInput" className="active">
                    Email *:
                    {
                        emailCheckUserLoading && <div className="spinner-border text-primary spinner-border-sm position-relative ml-2" role="status" style={{ top: '-2px' }}>
                            <span className="sr-only"></span>
                        </div>
                    }
                </label>
            </div>
            {
                !userExist && <>
                    <div className={"form-floating col-md-12" + ((validateInputData.isTouchedPassword && !validateInputData.isValidPassword) || (validateInputData.isTouchedConfirmPassword && !validateInputData.isValidConfirmPassword) ? " insert-danger" : "")}>
                        <input type="password"
                            className="form-control"
                            placeholder="Password *"
                            aria-label="Password"
                            onChange={e => onBlurEvent(e, 'password')}
                        />
                        <label htmlFor="floatingInput" className="active">Password *:</label>
                    </div>
                    <div className={"form-floating col-md-12" + (validateInputData.isTouchedConfirmPassword && !validateInputData.isValidConfirmPassword ? " insert-danger" : "")}>
                        <input type="password"
                            className="form-control"
                            placeholder="Conferma Password *"
                            aria-label="Conferma Password"
                            onChange={e => onBlurEvent(e, 'confirmPassword')}
                        />
                        <label htmlFor="floatingInput" className="active">{t("Login:ConfirmPassword")} *:</label>
                    </div>
                    {
                        validateInputData.isTouchedPassword && !validateInputData.isValidPassword && <div className="form-floating mt-2 col-md-12 text-danger">
                            {t('Login:PasswordText')}
                        </div>
                    }
                    {
                        validateInputData.isTouchedConfirmPassword && !validateInputData.isValidConfirmPassword && <div className="form-floating mt-2 col-md-12 text-danger">
                            {t('Login:CoincidentPassword')}
                        </div>
                    }
                    {
                        dynamicNotes && dynamicNotes.length > 0 && dynamicNotes.map((dn, key) => {
                            return <div key={key} className="p-2">
                                <span>
                                    {
                                        dn.checkTypeId !== 1 && <input type="checkbox"
                                            className="mr-1 position-relative"
                                            value={selectedDynamicNotes && selectedDynamicNotes.length > 0 ? selectedDynamicNotes.some(sdn => sdn.noteId === dn.noteId && sdn.checked) : false}
                                            defaultValue="false"
                                            style={{ 'top': '2px' }}
                                            onClick={e => onCheckDynamicNote(dn.noteId, e)}
                                        />
                                    }
                                    <label dangerouslySetInnerHTML={{ __html: dn.note }}></label>
                                </span>
                            </div>
                        })
                    }
                    {
                        validateInputData.isTouchedPrivacyPolicy && !validateInputData.isValidPrivacyPolicy && <div className="form-floating my-2 col-md-12 text-danger">
                            {t("MyBook:NotSelectedTerms")}
                        </div>
                    }
                </>
            }
        </>
    )
}
