import React from 'react';
import { formatDateTime } from '../../../../../js/Utils.js';
import { replaceAllStyleElements, getPaxGenderVoucher } from '../../../../../js/PrintUtility.js';
import { BookParameterId } from '../../../../../js/Constant';

export const PrintVoucherTransfer = ({ productDetail, cultureName, t }) => {

    return (
        <>
            {productDetail &&
                <>
                    <div className="stepHeader text-center mt-2" style={{ borderRadius: "5px", padding: "7px" }}>
                        <h5 className="color-text-voucher">{t("Print:Voucher:Transfer")} &nbsp;- &nbsp;{t("Print:Voucher:BookingNumber")}: {productDetail.providerRecordLocatorId}</h5>
                    </div>
                    {/*spazio*/}
                    <div style={{ padding: "7px" }}></div>
                    <div style={{ border: "1px solid grey", borderRadius: "5px" }}>
                        <table>
                            <tr>
                                <td style={{ textAlign: "left" }}>
                                    <span><label>{t("Print:Voucher:Service")}:</label> {productDetail.name}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left" }}>
                                    <span><label>Ref Pax:</label> {productDetail.refPax}</span>
                                </td>
                                <td style={{ textAlign: "left" }}>
                                    <span><label>{t("Print:Voucher:DataArrival")}: </label> {formatDateTime(productDetail.outbound.date, cultureName, { withTime: true })}</span>
                                </td>
                            </tr>
                            <tr>
                                {productDetail.isRoundTrip &&
                                    <span><label>{t("Print:Voucher:DataReturn")}: </label> {formatDateTime(productDetail.return.date, cultureName, { withTime: true })}</span>
                                }
                            </tr>
                            <tr>
                                {productDetail.passengers.map(pax =>
                                    <td key={pax.index} style={{ textAlign: "left" }}>
                                        <span><label> {t("Print:Voucher:Pax")} {pax.index}: </label> {pax.fullName} {getPaxGenderVoucher(pax, BookParameterId)}  {pax.paxType !== "Adult" && pax.paxType}</span>
                                    </td>
                                )}
                            </tr>
                            {productDetail.providerReservationId &&
                                <tr>
                                    <td><span><label>{t("Print:Voucher:BookingNumber")}:</label> {productDetail.providerReservationId}</span></td>
                                </tr>
                            }
                        </table>
                    </div>
                    {/*spazio*/}
                    <div style={{ padding: "7px" }}></div>

                    <div className="stepHeader color-text-voucher" style={{ borderRadius: "5px", padding: "7px" }}>
                        <h5>{t("Print:Voucher:BookingDetailReservationNumber")}:&nbsp; {productDetail.priceBar.wsBookingId}</h5>
                    </div>

                        <table>
                            <tr style={{ paddingTop: "7px", paddingBottom: "7px" }}>
                                <td style={{ border: "1px solid grey", borderRadius: "5px", }}>
                                    <tr>
                                        <td style={{ textAlign: "left" }}>
                                            <label>{t("Print:Voucher:Arrival")}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "left", maxWidth: "48px" }}>
                                            <span><label>Pick up: </label></span>
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                            <span>{productDetail.outbound.from}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "left", maxWidth: "48px" }}>
                                            <span><label>Drop off: </label></span>
                                        </td>
                                        <td style={{ textAlign: "left"}}>
                                            <span>{productDetail.outbound.to}</span>
                                        </td>
                                    </tr>
                                    {productDetail.flightInfo !== null &&
                                    <>
                                    {productDetail.flightInfo.outboundFlightNumber && 
                                        <tr>
                                            <td style={{ textAlign: "left", maxWidth: "48px" }}>
                                                <span><label>{t("Print:Voucher:FlightNum")}: </label></span>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <span>{productDetail.flightInfo.outboundFlightNumber}</span>
                                            </td>
                                        </tr>
                                    }
                                    {productDetail.flightInfo.outboundFlightArrivalTime && 
                                        <tr>
                                            <td style={{ textAlign: "left", maxWidth: "48px" }}>
                                                <span><label>{t("Print:Voucher:FlightTime")}: </label></span>
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <span>{productDetail.flightInfo.outboundFlightArrivalTime}</span>
                                            </td>
                                        </tr>
                                    }
                                    </>
                                    }
                            </td>
                            {productDetail.isRoundTrip &&
                                <td style={{ border: "1px solid grey", borderRadius: "5px", marginLeft: "10px" }}>
                                    <tr>
                                        <td style={{ textAlign: "left" }}>
                                            <label>{t("Print:Voucher:Return")}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "left", maxWidth: "48px" }}>
                                            <span> <label>Pick up: </label></span>
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                            <span>{productDetail.return.from}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "left", maxWidth: "48px" }}>
                                            <span><label>Drop off: </label></span>
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                            <span>{productDetail.return.to}</span>
                                        </td>
                                    </tr>
                                    {productDetail.flightInfo !== null && productDetail.flightInfo.returnFlightNumber &&
                                        <>
                                        {productDetail.flightInfo.returnFlightNumber &&
                                            <tr>
                                                <td style={{ textAlign: "left", maxWidth: "48px" }}>
                                                    <span><label>{t("Print:Voucher:FlightNum")}: </label></span>
                                                </td>
                                                <td style={{ textAlign: "left" }}>
                                                    <span>{productDetail.flightInfo.returnFlightNumber}</span>
                                                </td>
                                            </tr>
                                        }
                                        {productDetail.flightInfo.returnFlightDepartureTime &&
                                            <tr>
                                                <td style={{ textAlign: "left", maxWidth: "48px" }}>
                                                    <span><label>{t("Print:Voucher:FlightTime")}: </label></span>
                                                </td>
                                                <td style={{ textAlign: "left" }}>
                                                    <span>{productDetail.flightInfo.returnFlightDepartureTime}</span>
                                                </td>
                                            </tr>
                                        }
                                        </>
                                    }
                                </td>
                            }
                            </tr>
                        </table>

                    {/*spazio*/}
                    <div style={{ padding: "7px" }}></div>

                    <div style={{ border: "1px solid grey", borderRadius: "5px", }}>
                        <table>
                            <tr>
                                <td style={{ textAlign: "left" }}>
                                    <h5>{t("Print:Voucher:Characteristics")}</h5>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left" }}>
                                    <span><label>{t("Print:Voucher:Passengers")}:</label> {productDetail.adults + productDetail.children} </span>
                                    
                                    {productDetail.vehicle !== null &&
                                    <>
                                        <span> <label>{t("Print:Voucher:VehicleType")}:</label> <span>{productDetail.vehicle.type} </span></span>
                                        {productDetail.vehicle.numberOfBags && <span><label>{t("Print:Voucher:BagsNumber")}:</label> <span>{productDetail.vehicle.numberOfBags} </span></span>}
                                    </>
                                    }
                                </td>
                            </tr>
                        </table>
                        {productDetail.note &&
                            <div style={{ padding: "5px", lineHeight: "1.2" }}>
                                    <span style={{textAlign: "justify" }} className="document-txt-note" dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(productDetail.note) }} />
                            </div>
                        }
                        {productDetail.trxText &&
                            <div style={{ padding: "5px", lineHeight: "1.2" }}>
                                    <span dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(productDetail.trxText) }}></span>
                            </div>
                        }
                    </div>
                </>
            }
        </>
    );
}