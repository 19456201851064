import React from 'react';
import { imageOnError, imageOnLoad } from '../../../js/CDNUtility';
import { IconChoosePrint } from '../components/IconChoosePrint';


export const TemplatePrintImageHeader = ({ image, onHandleIsPrint }) => {
    
    return (
        <table className="w-100">
            <tbody>
                <tr>
                    <td className="w-20 no-print" style={{ backgroundImage: (image ? 'url("' + image + '")' : "url(" + imageOnLoad() + ")"), backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "500px" }}>
                        <div style={{position: "relative", top: "-230px"}}>
                            <IconChoosePrint handleIsPrint={onHandleIsPrint} />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}