import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { MenuBarVertical } from '../MenuBarVertical';
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';
import { Contact } from './components/Contact';
import { AddContact } from './components/AddContact';

export const Rubrica = () => {
    const { t } = useTranslation();

    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [isOpenAddNewContactPanel, setIsOpenAddNewContactPanel] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingFilter, setIsLoadingFilter] = useState(false);
    const [isError, setIsError] = useState(false);
    const [contacts, setContacts] = useState([]);

    // paging
    const itemsPerPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(0);

    // filtering
    const [filterName, setFilterName] = useState('');

    useEffect(() => {
        let inputData = {
            IdStakeholder: -1,
            filterName: filterName,
            PageNum: 1,
            PageSize: itemsPerPage
        };

        const getContacts = async () => {
            const response = callGetContacts(inputData);
        }

        setIsLoading(true);
        setIsLoadingFilter(true);
        getContacts();
    }, []);

    async function callGetContacts(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'contact/getcontactsrubrica', requestOption);
        var myItems = [];

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            let myItems = response.contacts;

            setContacts(myItems);
            setIsError(false);

            renderPaginator(response.totalContacts, inputData.pageSelected);
        } else {
            setContacts([]);
            setIsError(true);

            renderPaginator(0, pageSelected);
        }

        setIsLoading(false);
        setIsLoadingFilter(false);
        window.scroll(0, 0);
    }

    const handlePageClick = async e => {
        setPageSelected(e.selected);
        setIsLoadingFilter(true);

        let inputData = {
            idStakeholder: -1,
            filterName: filterName,
            PageNum: e.selected + 1,
            PageSize: itemsPerPage
        };

        const response = await callGetContacts(inputData);
    };

    function renderPaginator(totalItems, pageSelected) {
        setPageCount(Math.ceil(totalItems / itemsPerPage));
        setPageSelected(pageSelected);
    }

    const filterContacts = async e => {
        setPageSelected(0);
        setIsLoadingFilter(true);

        let inputData = {
            IdStakeholder: -1,
            filterName: filterName,
            PageNum: 1,
            PageSize: itemsPerPage
        };

        const response = await callGetContacts(inputData);
    }

    function handleSubmit() {
        filterContacts();
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            filterContacts();
        }
    }

    function onOpenNewContactPanel() {
        setIsOpenAddNewContactPanel(!isOpenAddNewContactPanel);
    }

    function onCloseNewContactPanel() {
        setIsOpenAddNewContactPanel(false);
    }

    async function onSaveSuccess() {
        onCloseNewContactPanel();

        let inputData = {
            idStakeholder: -1,
            filterName: filterName,
            PageNum: pageSelected + 1,
            PageSize: itemsPerPage
        };

        const response = await callGetContacts(inputData);
    }

    return (
        <>
            <MenuBarVertical activeAnchorId="aRubrica" />

            <div className="section section-lg bg-gray-200 page-content" id="content">
                <div className="container">
                    {isLoading && <Loading textMsg={t("MyBook:LoadingAddressBook")} />}
                    {isError && <Error textMsg={t("MyBook:ErrorLoading")} />}
                    {!isLoading && !isError && <div className="row">
                        <div className="col-lg-12">
                            <div className="card border-light p-0 p-md-4 mb-4">
                                <div className="card-header">
                                    <h5 className="mb-0"><span className="material-icons-outlined">contact_page</span> {t("MyBook:AddressBook")} </h5>
                                    <div className="row mt-4">
                                        <div className="col-12 col-lg-8">
                                            <div className="input-group input-group-md mb-3 mb-lg-0">
                                                <input className="form-control" placeholder="Cerca per nome cognome"
                                                    type="text"
                                                    onChange={e => setFilterName(e.target.value)}
                                                    onKeyPress={e => handleKeyPress(e)}
                                                />
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="ph-file-text"></i></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-1">
                                            <button className="tp-btn-login btn-sm mb-3 mt-md-0 animate-up-2" onClick={handleSubmit}>
                                                {
                                                    isLoadingFilter ? <div className="spinner-border text-primary" role="status"><span className="sr-only"></span></div> : t("MyBook:Search")
                                                }
                                            </button>
                                        </div>
                                        <div className="col-12 col-lg-1">
                                            <button className={"btn-sm mb-3 mt-md-0 animate-up-2 " + (!isOpenAddNewContactPanel ? "tp-btn-login" : "btn-danger")} onClick={onOpenNewContactPanel}>
                                                {!isOpenAddNewContactPanel ? "AGGIUNGI" : "ANNULLA"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {
                                    contacts !== null && contacts.length > 0
                                        ? (
                                            <>
                                                <ul className="list-group list-group-flush">
                                                    {
                                                        <li className="list-group-item border-bottom">
                                                            <div className="row align-items-center">
                                                                <div className="col-2 font-weight-bold">{t("MyBook:Name")}</div>
                                                                <div className="col-3 font-weight-bold">{t("MyBook:Surname")}</div>
                                                                <div className="col-3 font-weight-bold">Email</div>
                                                                <div className="col-2 font-weight-bold">{t("MyBook:Phone")} </div>
                                                                <div className="col-2 font-weight-bold"></div>
                                                            </div>
                                                        </li>
                                                    }
                                                    {
                                                        isOpenAddNewContactPanel && <AddContact onSaveSuccess={onSaveSuccess} onClosePanel={onCloseNewContactPanel} />
                                                    }
                                                    {
                                                        contacts.map((contact, index) => {
                                                            return <Contact
                                                                key={index}
                                                                contact={contact}
                                                                cultureName={cultureName}
                                                                onSaveSuccess={onSaveSuccess}
                                                            />;
                                                        })
                                                    }
                                                </ul>

                                                <nav aria-label="Page navigation comments" className="mt-4">
                                                    <ReactPaginate
                                                        breakLabel="..."
                                                        nextLabel={t('pagination:nextLabel')}
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={5}
                                                        pageCount={pageCount}
                                                        previousLabel={t('pagination:previousLabel')}
                                                        renderOnZeroPageCount={null}
                                                        forcePage={pageSelected}

                                                        breakClassName="page-item"
                                                        breakLinkClassName="page-link"
                                                        marginPagesDisplayed={2}
                                                        containerClassName="pagination justify-content-center"
                                                        pageClassName="page-item"
                                                        pageLinkClassName="page-link"
                                                        previousClassName="page-item"
                                                        previousLinkClassName="page-link"
                                                        nextClassName="page-item"
                                                        nextLinkClassName="page-link"
                                                        activeClassName="active"
                                                    />
                                                </nav>
                                            </>
                                        ) : (
                                            <div className="row">
                                                <div className="col-12">
                                                    <h6>{t("MyBook:NothingContact")}</h6>
                                                </div>
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </>
    );
}