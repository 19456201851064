import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime, getStructureStars, getStatusClass } from '../../../js/Utils.js';
import { imageOnError, imageOnLoadHotel } from '../../../js/CDNUtility';
import { getDifferenceDay, getIconUrlToPrint, replaceAllStyleElements, isNotesTooBig, getAllFirstUppercase, getFirstUppercase, getNoteCut  } from '../../../js/PrintUtility';
import { HotelStaticContainer } from '../../Product/Hotel/components/HotelStaticContainer';


export const StructureDetailPrint = ({ id, type, showNetPrices, structureDetail, isPrintCliente, isNotStep, isConfirmPrint, isPrintPdf = false, isHideCancelPolicy }) => {
    const { t } = useTranslation();
    
    function getFinalRooms(rooms) {
        if (isConfirmPrint) {
            return rooms.filter(x =>
                x.priceBar.status === "CONFIRMED" ||
                x.priceBar.status === "ONREQUEST" ||
                x.priceBar.status === "PRINTED" ||
                x.priceBar.status === "REFUND" ||
                x.priceBar.status === "PENALTY"
            );
        }
        else { // quotation
            return rooms;
        }
    }

    function getGeneralStatus(rooms) {
        if (isConfirmPrint) {
            let res = rooms.find(x =>
                x.priceBar.status === "ONREQUEST" ||
                x.priceBar.status === "PRINTED" ||
                x.priceBar.status === "REFUND" ||
                x.priceBar.status === "PENALTY"
            );
            
            if (res)
                return "MIX";
            else
                return "CONFIRMED"
        }
    }

    var generalStatus = "";
    if (structureDetail && structureDetail.rooms) {
        generalStatus = getGeneralStatus(structureDetail.rooms);
    }

    return (
        <>
        {/*Hotel*/}
            {structureDetail &&
                <table className="w-100 border shadow">
                    <tbody>
                        <tr className="shadow">
                            <td className="widthTdImage">
                                <img src={`data:image;base64, ` + structureDetail.thumbUrlB} alt="Thumb" className="imgProductStructure" onError={imageOnError} />
                            </td>
                            <td className="w-80  px-3">
                                <table className="w-100">
                                    <tbody>
                                        <tr className="h6">
                                            <td colspan="2" className="fontBold">{t(`Product:Structure:Item`)}</td>
                                            <td className="text-right">
                                                {isConfirmPrint && structureDetail.priceBar && structureDetail.priceBar.wsBookingId &&
                                                    <>
                                                    N. PNR: {structureDetail.priceBar.wsBookingId}
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="h5">
                                            <td colspan="4">
                                                {getAllFirstUppercase(structureDetail.name)}
                                                {!isPrintPdf &&
                                                    <button className={"btn p-0 ml-3 "} data-bs-toggle="modal" data-bs-target={"#schedStaticaModalHotel"+ structureDetail.productId}>
                                                        <data m3ico="info text-gray-500 icon-10">
                                                            <span class="material-icons text-gray-500 icon-10">info</span>
                                                        </data>
                                                    </button>
                                                }
                                            </td>
                                            <td className="align-top text-right w-30">
                                                {isConfirmPrint && structureDetail && structureDetail.rooms && generalStatus !== "CONFIRMED" &&
                                                    <span className={"h6 status " + getStatusClass(generalStatus)}>{t(`statiPrenotazione:` + generalStatus)}</span>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                {getStructureStars(structureDetail.category)}
                                            </td>
                                        </tr>

                                        {structureDetail.rooms && structureDetail.rooms.length > 0 && getFinalRooms(structureDetail.rooms).map((room, key) =>
                                        <>
                                            <tr key={key}>
                                                <td className="w-imagePdf w-5">
                                                    <img src={getIconUrlToPrint('room')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    <label>
                                                        {getFirstUppercase(room.roomName ? room.roomName : room.name)} ({getDifferenceDay(room.priceBar.startDate, room.priceBar.endDate)} {t(`Product:Structure:Nights`)})
                                                    </label>
                                                </td>
                                                <td className="w-30 text-left">
                                                    <td>
                                                        <td className="w-imagePdf">
                                                            <img src={getIconUrlToPrint('person')} className="ico-image mr-1" alt="" />
                                                        </td>
                                                        <td>
                                                        {room.refPax ?
                                                            <>
                                                                <label className="mr-1">Pax Rif.: </label>
                                                                <label>{room.refPax}</label>
                                                                <label>&nbsp;({room.adults + room.children} pax)</label>
                                                            </>
                                                            :
                                                            <>
                                                                <label>{room.adults}&nbsp;{t(`Template:Adults`)}&nbsp;</label>
                                                                <label>{room.children}&nbsp;{t(`Template:Children`)}&nbsp;</label>
                                                            </>
                                                        }
                                                        </td>
                                                    </td>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="w-imagePdf w-5">
                                                    <img src={getIconUrlToPrint('treatment')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    <label>
                                                        {getFirstUppercase(room.planName)}
                                                    </label>
                                                </td>
                                                <td className="w-30 text-right">
                                                    {isConfirmPrint && room.priceBar.status !== "CONFIRMED" &&
                                                        <span className={"h6 status " + getStatusClass(room.priceBar.status)}>{t(`statiPrenotazione:` + room.priceBar.status)}</span>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="w-imagePdf">
                                                    <img src={getIconUrlToPrint('announcement')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    {room.resortFees && room.resortFees.length > 0 ?
                                                        room.resortFees.map((resortFee, index) =>
                                                            <label key={index}>{resortFee}</label>
                                                        )
                                                    :
                                                        <label>{t(`Product:Structure:InfoTaxes`)}</label>
                                                    }
                                                </td>
                                            </tr>
                                            {room.extras && room.extras.length > 0 && room.extras.map((extra, key) =>
                                                <tr>
                                                    <td className="w-60 text-left">
                                                        <label>Extra &nbsp;{key + 1}:&nbsp;</label>
                                                        <label dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(extra.description) }} />
                                                    </td>
                                                </tr>
                                            )}
                                            <tr className="border-bottom">
                                                {!isHideCancelPolicy && room.cancelPolicy && !room.cancelPolicy.isFree &&
                                                <>
                                                    <td className="w-imagePdf">
                                                        <img src={getIconUrlToPrint('localAtm')} className="ico-image mr-1" alt="" />
                                                    </td>
                                                    <td>
                                                        {!room.cancelPolicy.isFree &&
                                                            <label className="penalty">{t(`CancelPolicy:PenaltyCancellation`)}</label>
                                                        }
                                                    </td>
                                                </>
                                            }
                                            </tr>
                                        </>
                                        )}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
            {structureDetail.rooms && structureDetail.rooms.length > 0 && getFinalRooms(structureDetail.rooms).map((room, key) => room.notes.length > 0 && room.notes[0] !== "" &&
                <>
                    <div className="w-100 marginTopPdfAlternatives" style={{ padding: '5px', textAlign: "justify" }}> {/* da lascaire per problema split pagina react PDF */}
                        <span className="text-left">Note Camera {key + 1}</span>
                        {room.notes.length <= 2 && !isNotesTooBig(room.notes) ?
                            room.notes.map((note, index) =>
                                <p style={{ textAlign: 'left' }}><label dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(note) }} /></p>
                            )
                        :
                            <p style={{ textAlign: 'left' }}><label dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(getNoteCut(room.notes)) }} />...</p>
                        }
                    </div>
                </>
            )}

            {structureDetail && !isPrintPdf &&
                <div className="modal fade" id={"schedStaticaModalHotel" + structureDetail.productId} tabIndex="-1" aria-labelledby={"exampleModalLabel"} aria-hidden="true">
                    <div className="modal-dialog modal-xxl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id={"exampleModalLabel"}>
                                    <data m3lab="ActionMenu.Detail">{t(`ActionMenu:Detail`)}</data>
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <HotelStaticContainer structureId={structureDetail.productId} isStaticView={true} isNotEngine={true} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                    <data m3lab="Button.Close">{t('Button:Close')}</data>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
