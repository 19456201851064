import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from '../Auth/AuthProvider';
import { getAuthToken } from '../../js/Utils.js';
import configData from "../../appsettings.json";

export const ProtectedRoute = ({ children }) => {
    const { onLogout } = useAuth();
    let location = useLocation();

    let token = getAuthToken();
    if (!token) {
        return <Navigate to="/" replace state={{ from: location }} />;
    }

    const jCurrentUser = localStorage.getItem("CurrentUser");
    if (jCurrentUser !== null && jCurrentUser !== undefined) {
        let currentUser = JSON.parse(jCurrentUser);

        let today = new Date();
        let isExpireDate = !currentUser.expire;
        if (!isExpireDate) {
            isExpireDate = new Date(currentUser.expire).getTime() < today.getTime();

            try {

                let normToday = new Date(new Date().toISOString().split('T')[0]).getTime();
                let normExpireDate = new Date(currentUser.expire.split('T')[0]).getTime();
                isExpireDate = normExpireDate < normToday;

            } catch (ex) {

            }
        }

        if (isExpireDate) {
            onLogout();
            return <Navigate to="/" replace state={{ from: location }} />;
        } else {
            return children;
        }
    }
};