import React, { useEffect, useState } from 'react';
import { ProductDescription } from "../../Common/ProductDescription";
import configData from "../../../../appsettings.json";

export const ActivityDetailDescriptionsAccordion = ({ activityDetail, gallery }) => {
    const [descriptions, setDescriptions] = useState([]);

    useEffect(() => {

        if (activityDetail.descriptions) {
            let normalizeDescriptions = [];

            for (let i = 0; i < activityDetail.descriptions.length; i++) {
                let d = activityDetail.descriptions[i];
                if (d.renderType.toUpperCase() === 'INCLUSION' || d.renderType.toUpperCase() === 'EXCLUSION'
                    || d.renderType.toUpperCase() === 'MEETINGPOINT' || d.renderType.toUpperCase() === 'SAFETYINFO'
                    || d.renderType.toUpperCase() === 'INTRO')
                    continue;

                normalizeDescriptions.push(d);

            }

            setDescriptions(normalizeDescriptions);
        }

    }, [activityDetail]);

    return (
        <>
            {
                descriptions && descriptions.map((desc, key) => {
                    return <React.Fragment key={key}>
                        <ProductDescription index={key}
                            category={desc.type}
                            description={desc.value}
                            productType={configData.Settings.Products.Activity.IdTipoProdotto}
                            gallery={gallery}
                            noImage={true}
                        />
                    </React.Fragment>;
                })
            }
        </>
    );
}