import { Home } from "./components/Home";
import { Quotation } from "./components/QuotationTO/Quotation";
import { ForgotPassword } from "./components/User/ForgotPassword";
import { RegistrationUser } from "./components/User/RegistrationUser";
import { RegistrationUserAgency } from "./components/User/RegistrationUserAgency";
import { RegistrationConfirm } from "./components/User/RegistrationConfirm";
import { ResetPassword } from "./components/User/ResetPassword";
import { GoToPrimary } from "./components/User/GoToPrimary";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    }, {
        path: '/quotation/:quotationId/:authcode',
        element: <Quotation />,
        visibleOnlyBook: false,
        patternNoRedirect: /^\/quotation\/(\d+)\/([a-f0-9]{32})$/
    },
    {
        path: '/ForgotPassword',
        element: <ForgotPassword />,
        visibleOnlyBook: false
    },
    {
        path: '/RegistrationUser',
        element: <RegistrationUser />,
        visibleOnlyBook: false
    },
    {
        path: '/RegistrationUserAgency',
        element: <RegistrationUserAgency />,
        visibleOnlyBook: false
    },
    {
        path: '/RegistrationConfirm/:cultureCode/:ConfirmToken/:ru',
        element: <RegistrationConfirm />,
        visibleOnlyBook: false
    },
    {
        path: '/ResetPassword',
        element: <ResetPassword />,
        visibleOnlyBook: false
    },
    {
        path: '/primary',
        element: <GoToPrimary />,
        visibleOnlyBook: false
    }
];

export default AppRoutes;
