import React, { useState, useEffect } from 'react';
import { formatPrice, formatDateTime } from '../../js/Utils.js';
import { Loading } from '../Common/Loading';
import configData from "../../appsettings.json";
import { useTranslation } from 'react-i18next';

export const TimetablePayments = ({ orderNr, visualizationType, userType, grouped, cultureName, currency, onTimetableDetail }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [timetable, setTimetableDetail] = useState([]);
    const { t } = useTranslation();


    useEffect(() => {
        const getTimetableDetail = async (inputData) => {
            const response = callGetTimetableDetail(inputData);
        }

        setIsLoading(true);
        var withOrderDetails = grouped === 'false';
        getTimetableDetail({ orderNumber: orderNr, withOrderDetails: withOrderDetails });
    }, []);


    async function callGetTimetableDetail(inputData) {
        var method = 'document/GetPaymentsTimetable/' + inputData.orderNumber + '/' + inputData.withOrderDetails;
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + method, { credentials: 'include' });

        const response = await fetchedRes.json();

        setTimetableDetail(response);
        onTimetableDetail(response);
        setIsLoading(false);
    }

    const renderPrintTimetable = (timetable, visualizationType, userType, grouped, cultureName, currency) => {
        return (
            <>
                <div style={{ pageBreakInside: "avoid" }} className="mt-3">
                    <div className="text-center bg-gray-300">
                        <div className="w-100 pl-3">
                            <span className="h4 ml-1">{t("scadenziario:item")}</span>
                        </div>
                    </div>
                    <table className="w-100 mb-3">
                        <tbody className="document-invoice text-right">
                            {
                                timetable.deadlineGroups?.map(
                                    (payment) => (
                                        <>
                                            <tr key={payment.index} className="bg-gray-300">
                                                {grouped === 'false' ?
                                                    <>
                                                        <td colSpan="5">
                                                            <span className="h6"><label className="text-gray-700 mr-2" style={{ fontSize: "1rem !Important" }}>{t("MyBook:Expiring")} {formatDateTime(payment.deadlineDate, cultureName, { twoDigits: true })}</label></span>
                                                            {formatPrice(payment.amount, payment.currency, cultureName)}
                                                        </td>
                                                    </>
                                                    :
                                                    <>
                                                        <td className="text-left border-bottom border-gray-400">
                                                            {payment.index} {t("MyBook:Expiring")}
                                                        </td>
                                                        <td className="text-left border-bottom border-gray-400">
                                                            {formatDateTime(payment.deadlineDate, cultureName, { twoDigits: true })}
                                                        </td>
                                                        <td className="pr-5 border-bottom border-gray-400">
                                                            {formatPrice(payment.amount, payment.currency, cultureName)}
                                                        </td>
                                                    </>
                                                }
                                            </tr>
                                            {
                                                grouped === 'false' ?
                                                    payment.deadlineProducts?.map( // <--- Also notice here, we have wrapped it in curly braces, because it is an "expression" inside JSX.
                                                        (dp) => (
                                                            <tr key={dp.index} >
                                                                <td className="text-left border-bottom border-gray-400">
                                                                    {dp.productName}
                                                                </td>
                                                                <td className="pr-5 border-bottom border-gray-400">
                                                                    {formatPrice(dp.amount, payment.currency, cultureName)}
                                                                </td>
                                                                <td className="pr-5 border-bottom border-gray-400">
                                                                    {userType === 'A' ? <span>{dp.commissionPerc}%</span> : ''}
                                                                </td>
                                                                <td className="pr-5 border-bottom border-gray-400">
                                                                    {userType === 'A' ? <span>{formatPrice(dp.commission, payment.currency, cultureName)}</span> : ''}
                                                                </td>
                                                            </tr>
                                                        )) : ''
                                            }
                                        </>
                                    )
                                )
                            }
                        </tbody>
                    </table>
                </div>{/*<!--Chiudi Dettaglio Scadenziario-->*/}
            </>
        );
    }

    const renderViewTimetable = (timetable, visualizationType, userType, grouped, cultureName, currency) => {
        return (
            <>
                <h5>{t("scadenziario:item")}:
                    {timetable.numDeadlines === 0 ? (<small> {t("MyBook:NoPaymentsExpected")}</small>) : ''}
                </h5>
                <div className="col-12">
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <tbody className="document-invoice">
                                {
                                    timetable.deadlineGroups?.map(
                                        (payment) => (
                                            <>
                                                <tr key={payment.index}>
                                                    <td colSpan="5" className="document-bg-std">
                                                        <label>{t("MyBook:Expiring")} {formatDateTime(payment.deadlineDate, cultureName, { twoDigits: true })}:</label>
                                                        <span className="h5">{formatPrice(payment.amount, payment.currency, cultureName)} </span>
                                                    </td>
                                                </tr>

                                                {grouped === 'false' ?
                                                    payment.deadlineProducts?.map( // <--- Also notice here, we have wrapped it in curly braces, because it is an "expression" inside JSX.
                                                        (dp) => (
                                                            <tr key={dp.index} >
                                                                <td>
                                                                    <small>{dp.productName}</small>
                                                                </td>
                                                                <td>
                                                                    <small>{formatPrice(dp.amount, payment.currency, cultureName)}</small>
                                                                </td>
                                                                <td>
                                                                    {userType === 'A' ? <small>{dp.commissionPerc}%</small> : ''}
                                                                </td>
                                                                <td>
                                                                    {userType === 'A' ? <small>{formatPrice(dp.commission, payment.currency, cultureName)}</small> : ''}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    : ''
                                                }
                                            </>
                                        )
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div> {/*<!--End Scadenziario-->*/}

            </>
        );
    }

    return (
        <>
            {
                configData.Settings.Cart.ShowScadenziario && isLoading
                    ? (<Loading textMsg={t('MyBook:Loading')} />)
                    : (
                        visualizationType === 'P'
                            ? renderPrintTimetable(timetable, visualizationType, userType, grouped, cultureName, currency)
                            : renderViewTimetable(timetable, visualizationType, userType, grouped, cultureName, currency)
                    )
            }
        </>
    );
}
