import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { PassengerPrint } from './PassengerPrint';
import { PriceBarPrint } from './PriceBarPrint';
import { formatDateTime, getStatusClass, formatPrice } from '../../../js/Utils.js';
import { imageOnError, imageOnLoad } from '../../../js/CDNUtility';
import { replaceAllStyleElements, getIconUrlToPrint, isNotesTooBig, getAllFirstUppercase, getFirstUppercase } from '../../../js/PrintUtility';


export const CruiseDetailPrint = ({ cruiseDetail, isConfirmPrint, isNotStep, isDocViaggio = false, isShowNote = false }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();

    function formatItineraryDate(datetime) {
        let myDate = new Date(datetime);
        return new Intl.DateTimeFormat(cultureName, { month: "long", day: "2-digit", weekday: "short" }).format(myDate);
    }

    const renderPassengerCostCharges = (paxName, charges) => {
        return (
            charges.map((charge, index) =>
                <tr key={index} className="badge-white">
                    <td className="col-3 col-sm-6 col-md-6 col-lg-6" style={{ textAlign: 'left' }}>
                        <label>{paxName}</label>
                    </td>
                    <td className="col-1 col-sm-3 col-md-3 col-lg-3" style={{ textAlign: 'left' }}>
                        <label>{charge.chargeType}</label>
                    </td>
                    <td className="col-1 col-sm-3 col-md-3 col-lg-3" style={{ textAlign: 'left' }}>
                        <label>{formatPrice(charge.amount, charge.currency, cultureName)}</label>
                    </td>
                </tr>
            )
        );
    }

    const renderPassengerCosts = (paxesPriceDetails) => {
        return (
            paxesPriceDetails.map(paxesPriceDetail =>
                renderPassengerCostCharges(paxesPriceDetail.paxName, paxesPriceDetail.charges)
            )
        );
    }

    return (
        <>
            {cruiseDetail &&
                <table className="w-100 border shadow">
                    <tbody>
                        <tr className="shadow">
                            <td className="widthTdImage">
                                <img src={`data:image;base64, ` + cruiseDetail.thumbUrlB} alt="Thumb" className="imgProducts" onError={imageOnError} />
                            </td>
                            <td className="w-80  px-3">
                                <table className="w-100">
                                    <tbody>
                                        <tr className="h6">
                                            <td colspan="2" className="fontBold">
                                                <td colspan="2" className="fontBold">{t(`Product:Cruise:Item`)}</td>
                                            </td>
                                            <td className="text-right">
                                                {isConfirmPrint && cruiseDetail.priceBar && cruiseDetail.priceBar.wsBookingId &&
                                                <>
                                                    N.PNR: {cruiseDetail.priceBar.wsBookingId}
                                                </>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="h5">
                                            <td colspan="4">
                                                {getAllFirstUppercase(cruiseDetail.itineraryName)} {cruiseDetail.shipName && " (" + getAllFirstUppercase(cruiseDetail.shipName) +")"}
                                            </td>
                                            <td className="align-top  text-right w-30">
                                                {isConfirmPrint &&
                                                    <span className={"h6 status " + getStatusClass(cruiseDetail.priceBar.status)}>{t(`statiPrenotazione:` + cruiseDetail.priceBar.status)}</span>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-imagePdf">
                                                <img src={getIconUrlToPrint('cruise')} className="ico-image mr-1" alt="" />
                                            </td>
                                            <td className="w-40">
                                                <label>{t(`Product:Cruise:DeparturePort`)}:&nbsp;</label>
                                                <label>
                                                    {cruiseDetail.itinerarySegments && cruiseDetail.itinerarySegments[0] && getFirstUppercase(cruiseDetail.itinerarySegments[0].portName)}
                                                </label>
                                            </td>
                                            <td className="w-30">
                                                <td className="w-imagePdf">
                                                    <img src={getIconUrlToPrint('cruise')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    <label>&nbsp;{t(`Product:Cruise:ArrivalPort`)}:&nbsp;</label>
                                                    <label>
                                                    {cruiseDetail.itinerarySegments && cruiseDetail.itinerarySegments[0] && getFirstUppercase(cruiseDetail.itinerarySegments[cruiseDetail.itinerarySegments.length-1].portName)}
                                                    </label>
                                                </td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-imagePdf">
                                                <img src={getIconUrlToPrint('room')} className="ico-image mr-1" alt="" />
                                            </td>
                                            <td>
                                                <label>
                                                    {getFirstUppercase(cruiseDetail.cabinCategory.name)}&nbsp;
                                                    ({t(`Product:Cruise:Nights`)}&nbsp;{cruiseDetail.duration})
                                                </label>
                                            </td>
                                            <td className="w-30">
                                                <td className="w-imagePdf">
                                                    <img src={getIconUrlToPrint('person')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    {cruiseDetail.cabinCategory.refPax ?
                                                        <>
                                                            <label className="mr-1">Pax Rif.: </label>
                                                            <label>{cruiseDetail.cabinCategory.refPax}</label>
                                                            <label>&nbsp;({cruiseDetail.cabinCategory.adults + cruiseDetail.cabinCategory.children} pax)</label>
                                                        </>
                                                        :
                                                        <>
                                                            <label>{cruiseDetail.cabinCategory.adults}&nbsp;{t(`Template:Adults`)}&nbsp;</label>
                                                            <label>{cruiseDetail.cabinCategory.children}&nbsp;{t(`Template:Children`)}&nbsp;</label>
                                                        </>
                                                    }
                                                </td>
                                            </td>
                                        </tr>
                                        <tr>
                                        {cruiseDetail.cabinCategory &&
                                            <>
                                                <td className="w-imagePdf">
                                                </td>
                                                <td className="w-40">
                                                    <label>{t(`Product:Cruise:Bridge`)}:&nbsp;</label>
                                                    <label>
                                                        {cruiseDetail.cabinCategory.deck && getFirstUppercase(cruiseDetail.cabinCategory.deck.name)}
                                                    </label>
                                                </td>
                                                <td className="col-4">
                                                    {cruiseDetail.cabinCategory.deck.cabinNumber &&
                                                    <>
                                                        <label>{t(`Product:Cruise:CabinNumber`)}:&nbsp;</label>
                                                        {cruiseDetail.cabinCategory.deck.cabinNumber}
                                                    </>
                                                    }
                                            </td>
                                            </>
                                        }
                                        </tr>
                                        {!isDocViaggio && cruiseDetail.cancelPolicy &&
                                            <tr>
                                                <td className="w-imagePdf">
                                                    <img src={getIconUrlToPrint('localAtm')} className="ico-image mr-1" alt="" />
                                                </td>
                                                <td>
                                                    {cruiseDetail.cancelPolicy.isFree ?
                                                        <label className="text-success">{t(`CancelPolicy:FreeCancellation`)} {formatDateTime(cruiseDetail.cancelPolicy.expirationDate, { twoDigits: true })}</label>
                                                        :
                                                        <label className="penalty">{t(`CancelPolicy:PenaltyCancellation`)}</label>
                                                    }
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        </>
    );
}