import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import configData from "../../../appsettings.json";

export const GestPayFail = () => {
    const cookies = new Cookies();

    useEffect(() => {
        if (cookies.get("orderNumber")) {
            window.location.href = `${configData.Settings.Base_Url}cart`;
        }
    }, []);

    return (
        <div className="row text-center">
            <div className="col-12 penalty">
                <p><strong>Carta non accettata o transazione non andata a buon fine.</strong></p>
                <p>Clicca 'Chiudi' per aggiornare la pagina</p>
            </div>
        </div>
    );
}
