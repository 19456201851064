import React, { useState, useEffect } from 'react';
import configData from "../../appsettings.json";
import { handleError, formatDateTime } from "../../js/Utils";

export const LibsVersion = () => {
    useEffect(() => {
        callGetVersion();
    }, []);

    const [versionArray, setVersionArray] = useState([]);

    function callGetVersion() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}constant/GetVersion`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setVersionArray(item);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    return (
        <div className="section section-lg bg-white" id="content">
            <div className="container">
                <table className="table">
                    <tr className="mb-2">
                        <td style={{ width: '60%'}}>
                            <b>Library</b>
                        </td>
                        <td style={{ width: '20%'}}>
                            <b>Version</b>
                        </td>
                        <td>
                            <b>Last Modified</b>
                        </td>
                    </tr>
                    {
                        versionArray && versionArray.length > 0 && versionArray.map((version, key) =>
                            <tr key={key}>
                                <td>
                                    {version.libraryName}
                                </td>
                                <td>
                                    {version.version}
                                </td>
                                <td>
                                    {formatDateTime(version.lastModified, 'it-IT', {withTime: true})}
                                </td>
                            </tr>
                        )
                    }
                </table>
            </div>
        </div>
    );
};