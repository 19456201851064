import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../Common/Loading';
import { OrderDetailScadenziarioPayCcModal } from './OrderDetailScadenziarioPayCcModal'
import { formatPrice, formatDateTime } from '../../../js/Utils.js';
import configData from "../../../appsettings.json";
import { M3Icon } from '../../Common/M3Icon';

export const OrderDetailScadenziario = ({ orderNumber, isVisibleScadenziarioPayment, customFunctions }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [isLoading, setIsLoading] = useState(false);
    const [scadenziario, setScadenziario] = useState({});
    const [selectedRate, setSelectedRate] = useState([]);
    const [selectedRateTotalPrice, setSelectedRateTotalPrice] = useState("0");

    const [modalRataIdx, setModalRataIdx] = useState([]);
    const [modalPaymentData, setModalPaymentData] = useState();

    useEffect(() => {
        const getScadenziario = async (inputData) => {
            const response = callGetScadenziario(inputData);
        }

        setIsLoading(true);
        getScadenziario(orderNumber);
    }, []);

    async function callGetScadenziario(orderNumber) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}reservation/GetScadenziarioFromOrder/${orderNumber}`, requestOption);
        const response = await fetchedRes.json();

        setScadenziario(response);
        customFunctions.onCallSaveScadenziario(response);

        // init selected rate array - start
        if (response.rate !== null && response.rate !== undefined) {
            let mySelectedRate = [];
            for (let i = 0; i < response.rate.length; i++) {
                let rata = response.rate[i];
                mySelectedRate.push({ RataIdx: i, Amount: rata.totalAmount, Currency: rata.currency, Selected: false });
            }
            setSelectedRate(mySelectedRate);
        }
        // init selected rate array - end

        setIsLoading(false);
    }

    const handleSetRata = (e, rataIdx) => {
        let mySelectedRate = selectedRate;
        let objIndex = mySelectedRate.findIndex((x => x.RataIdx === rataIdx));
        if (objIndex < 0)
            return;

        mySelectedRate[objIndex].Selected = e.target.checked;
        setSelectedRate(mySelectedRate);
        updateSelectedRateData();
    }

    const handlePayment = (e) => {
        e.preventDefault();
        console.log(JSON.stringify(selectedRate));


    }

    const updateSelectedRateData = () => {
        let amount = 0;
        let currency = selectedRate[0].Currency;

        let selectedRateIdx = [];

        for (let i = 0; i < selectedRate.length; i++) {
            if (selectedRate[i].Selected) {
                amount += selectedRate[i].Amount;
                selectedRateIdx.push(i);
            }
        }

        setSelectedRateTotalPrice(formatPrice(amount, currency, cultureName));

        setModalRataIdx(selectedRateIdx);
        setModalPaymentData({ productName: t("MyBook:TimetableRate"), amount: amount, currency: currency });
    }


    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : (scadenziario !== null && scadenziario !== undefined && scadenziario.rate !== undefined && scadenziario.rate.length > 0
                    ?
                    (
                        !configData.Settings.MyBook.HidePaymentSection && <div className="card border-light p-0 p-md-4 mb-4">
                            <div className="card-header">
                                <h5 className="mb-0"><M3Icon iconName="CreditCard" />{t("MyBook:Payments")} </h5>
                                <p className="text-sm mb-0">{t("MyBook:SelectRatesToPay")}:</p>
                            </div>
                            <ul className="list-group list-group-flush">
                                {
                                    scadenziario !== undefined && scadenziario.rate !== undefined && scadenziario.rate.length > 0
                                        ? (
                                            <>
                                                {
                                                    scadenziario.rate.map((rata, index) =>
                                                        <li key={index} className="list-group-item border-bottom">
                                                            <div className="row align-items-center">
                                                                <div className="col-auto"><i className="ph-file-bold h2"></i></div>
                                                                <div className="col ml-n2">
                                                                    <h6 className="text-sm mb-0">{t("MyBook:Rate")}&nbsp;{index + 1}</h6>
                                                                    {/*<p className="card-text small text-muted status confirm">(785798) Confermata</p>*/}
                                                                </div>
                                                                <div className="col-md d-none d-md-block">
                                                                    <p className="text-muted my-0">{t("MyBook:NetValue")}: <b>{formatPrice(rata.totalAmount, rata.currency, cultureName)}</b></p>
                                                                    
                                                                    <p>{t(`scadenziario:paymentDeadline`)}: {formatDateTime(rata.expirePaymentDate, cultureName)}</p>
                                                                </div>
                                                                {
                                                                    isVisibleScadenziarioPayment && <div className="col-auto">
                                                                        <div className="btn-group">
                                                                            <div className="form-check form-switch">
                                                                                <input id={'switch_' + index} type="checkbox" className="form-check-input" onChange={e => handleSetRata(e, index)} />
                                                                                <label className="form-check-label" htmlFor={'switch_' + index}></label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                            </>
                                        )
                                        : (<></>)
                                }
                                {
                                    isVisibleScadenziarioPayment && <li className="list-group-item">
                                        <div className="row mx-n3">
                                            <div className="col col-lg px-2">
                                            </div>
                                            <div className="col-auto px-2">
                                                {t("MyBook:TotalToPayWithCard")}: <b>{selectedRateTotalPrice}</b>
                                                &nbsp;
                                                <a className="btn btn-xs btn-outline-dark" data-bs-toggle="modal" data-bs-target={'#OrderDetailScadenziarioPayCc_' + orderNumber}>{t("MyBook:Pay")} </a>
                                            </div>
                                        </div>
                                    </li>
                                }
                            </ul>
                        </div>
                    )
                    : (<></>)
                )
            }

            {modalPaymentData !== undefined && <OrderDetailScadenziarioPayCcModal orderNumber={orderNumber} rataIdx={modalRataIdx} paymentData={modalPaymentData} />}
        </>
    );
}
