import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { InputPaxesCompositionReadOnly } from './InputPaxesCompositionReadOnly';
import { InputPaxesCompositionPanel } from './InputPaxesCompositionPanel';
import { M3Icon } from '../../Common/M3Icon';
import configData from "../../../appsettings.json";

export const InputPaxesCompositionWithModalContainer = ({ searchParameters, productType, callOnSelectComposition, onOpenPanelPaxes, closeExternal, modeView, type, supportedComposition }) => {

    const { t } = useTranslation();

    const [selectComposition, setSelectComposition] = useState(null);
    const [showPaxesComposition, setShowPaxesComposition] = useState(false);
    const [customPaxComposition, setCustomPaxComposition] = useState(false);

    const [idModal] = useState(getRandomInt());
    function getRandomInt() {
        return Math.floor(Math.random() * 100000);
    }
    const modalId = "Composition_" + idModal;


    useEffect(() => {
        let modalIdName = "Composition_" + idModal;
        let modalDestination = document.getElementById(modalIdName);
        if (modalDestination) {
            modalDestination.addEventListener('shown.bs.modal', function () {
                onOpenPanelPaxes();
            });
        }
    }, []);

    useEffect(() => {
        setShowPaxesComposition(closeExternal);
    }, [closeExternal]);


    function onClickOpenPaxesComposition() {
        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnModalComposition_" + idModal);
            btn.click();
        }

        setShowPaxesComposition(true);
    }

    function onClickSelectPaxes(selectCompositionParam) {
        setSelectComposition(selectCompositionParam);
        callOnSelectComposition(selectCompositionParam);
        setShowPaxesComposition(false);

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnCloseModalComposition_" + idModal);
            btn.click();
        }
    }

    function getCompositionFromCode(code) {
        switch (code) {
            case "1A": return { adults: 1, children: 0 };
            case "2A": return { adults: 2, children: 0 };
            case "3A": return { adults: 3, children: 0 };
            case "4A": return { adults: 4, children: 0 };
            case "2A1C": return { adults: 2, children: 1, childAges: [12] };
            case "2A2C": return { adults: 2, children: 2, childAges: [12, 12] };
            case "1A1J": return { adults: 1, children: 1, childAges: [2] };
            case "1A1C": return { adults: 1, children: 1, childAges: [12] };
            case "2A1J": return { adults: 2, children: 1, childAges: [2] };
            case "2A1C1J": return { adults: 2, children: 2, childAges: [12, 2] };
            case "2A2J": return { adults: 2, children: 2, childAges: [2, 2] };
            default: return { adults: 2, children: 0 };
        }
    }

    function onClickPaxComposition(e) {
        setCustomPaxComposition(false);
        let cbCustomPaxElem = document.getElementById("cbCustomPax");
        cbCustomPaxElem.checked = false;

        let allElements = document.querySelectorAll('*');
        allElements.forEach(el => el.classList.remove('cruise-search-engine-pax-selected'));

        let inputId = e.currentTarget.id;
        let spanElement = document.getElementById(inputId);
        spanElement.classList.add("cruise-search-engine-pax-selected");

        let composition = getCompositionFromCode(inputId);
        setSelectComposition([composition]);
        callOnSelectComposition([composition]);
    }

    function onChangeCbCustomPax(e) {
        let isCustomPax = e.currentTarget.checked;
        if (isCustomPax) {
            let allElements = document.querySelectorAll('*');
            allElements.forEach(el => el.classList.remove('cruise-search-engine-pax-selected'));
        }

        setCustomPaxComposition(isCustomPax);
    }



    return (
        <>
            <div className="w-100">
                {
                    (modeView !== 'MODAL' || !showPaxesComposition) && <InputPaxesCompositionReadOnly
                        searchParameters={searchParameters}
                        defaultSelectComposition={selectComposition}
                        productType={productType}
                        callOnShowPanel={onClickOpenPaxesComposition}
                        callOnSelectComposition={onClickSelectPaxes}
                        modeView={modeView}
                        type={type} />
                }
            </div>

            <a id={"btnModalComposition_" + idModal} data-bs-toggle="modal" data-bs-target={"#" + modalId}></a>
            <div id={modalId}
                className="modal fade"
                tabIndex="-1"
                aria-labelledby={"CompositionLabel_" + modalId}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal_PaxCompositionLabel">{t(`SearchEngine:Travelers`)}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {supportedComposition && supportedComposition.length > 0
                                ? (<>
                                    {/*Header*/}
                                    <div className="row mb-2 cruise-search-engine-pax-header">
                                        <div className="col-3 p-2 pl-3">
                                            <M3Icon iconName="PaxAdl" externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                                            <span className="p-2" style={{ position: 'relative', top: '-6px' }}>
                                                {t(`SearchEngine:Adults`)} (18+)
                                            </span>
                                        </div>
                                        <div className="col-4 p-2 pl-3">
                                            <M3Icon iconName="PaxChd" externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                                            <span className="p-2" style={{ position: 'relative', top: '-6px' }}>
                                                {t(`SearchEngine:Children`)} (12-17 {t(`General:Ages`)})
                                            </span>
                                        </div>
                                        <div className="col-4 p-2 pl-3">
                                            <M3Icon iconName="PaxChd" externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                                            <span className="p-2" style={{ position: 'relative', top: '-6px' }}>
                                                Junior (2-11 {t(`General:Ages`)})
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 p-3">
                                            <span className="p-2 h075">{t(`SearchEngine:Cruise:SelectPaxComposition`)}</span>
                                        </div>
                                    </div>

                                    {/*1A*/}
                                    <div className="row border-bottom border-light">
                                        <div className="col-3 p-3">
                                            <span id="1A" className="cruise-search-engine-pax p-2" onClick={e => onClickPaxComposition(e)}>
                                                1 {t(`SearchEngine:Adult`)}
                                            </span>
                                        </div>
                                        <div className="col-4 p-3">
                                            <span id="1A1C" className="cruise-search-engine-pax p-2" onClick={e => onClickPaxComposition(e)}>
                                                1 {t(`SearchEngine:Adult`)} + 1 {t(`SearchEngine:Child`)}
                                            </span>
                                        </div>
                                        <div className="col-4 p-3">
                                            <span id="1A1J" className="cruise-search-engine-pax p-2" onClick={e => onClickPaxComposition(e)}>
                                                1 {t(`SearchEngine:Adult`)} + 1 Junior
                                            </span>
                                        </div>
                                    </div>

                                    {/*2A*/}
                                    <div className="row ">
                                        <div className="col-3 p-3">
                                            <span id="2A" className="cruise-search-engine-pax p-2" onClick={e => onClickPaxComposition(e)}>
                                                2 {t(`SearchEngine:Adults`)}
                                            </span>
                                        </div>
                                        <div className="col-4 p-3">
                                            <span id="2A1C" className="cruise-search-engine-pax p-2" onClick={e => onClickPaxComposition(e)}>
                                                2 {t(`SearchEngine:Adults`)} + 1 {t(`SearchEngine:Child`)}
                                            </span>
                                            <br /><br />
                                            <span id="2A2C" className="cruise-search-engine-pax p-2" onClick={e => onClickPaxComposition(e)}>
                                                2 {t(`SearchEngine:Adults`)} + 2 {t(`SearchEngine:Children`)}
                                            </span>
                                        </div>
                                        <div className="col-4 p-3">
                                            <span id="2A1J" className="cruise-search-engine-pax p-2" onClick={e => onClickPaxComposition(e)}>
                                                2 {t(`SearchEngine:Adults`)} + 1 Junior
                                            </span>

                                            <br /><br />

                                            <span id="2A2J" className="cruise-search-engine-pax p-2" onClick={e => onClickPaxComposition(e)}>
                                                2 {t(`SearchEngine:Adults`)} + 2 Junior
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row border-bottom border-light">
                                        <div className="col-3 p-1">
                                        </div>
                                        <div className="col-8 p-1 pl-3">
                                            <span id="2A1C1J" className="cruise-search-engine-pax p-2" onClick={e => onClickPaxComposition(e)}>
                                                2 {t(`SearchEngine:Adults`)} + 1 {t(`SearchEngine:Child`)} + 1 Junior
                                            </span>
                                        </div>
                                    </div>

                                    {/*3A*/}
                                    <div className="row border-bottom border-light">
                                        <div className="col-3 p-3">
                                            <span id="3A" className="cruise-search-engine-pax p-2" onClick={e => onClickPaxComposition(e)}>
                                                3 {t(`SearchEngine:Adults`)}
                                            </span>
                                        </div>
                                        <div className="col-4 p-3">
                                        </div>
                                        <div className="col-4 p-3">
                                        </div>
                                    </div>

                                    {/*4A*/}
                                    <div className="row border-bottom border-light">
                                        <div className="col-3 p-3">
                                            <span id="4A" className="cruise-search-engine-pax p-2" onClick={e => onClickPaxComposition(e)}>
                                                4 {t(`SearchEngine:Adults`)}
                                            </span>
                                        </div>
                                        <div className="col-4 p-3">
                                        </div>
                                        <div className="col-4 p-3">
                                        </div>
                                    </div>
                                </>)
                                : (<></>)
                            }

                            {/*Custom Composition*/}
                            <div className="row mt-2">
                                <div className="col-12">
                                    <input id="cbCustomPax" type="checkbox" onClick={e => onChangeCbCustomPax(e)} />&nbsp;
                                    <label for="cbCustomPax">{t(`SearchEngine:Cruise:CustomPaxCompositionChoose`)}</label>
                                </div>
                            </div>

                            {customPaxComposition
                                ? (<>
                                    <div className="row mt-2 mb-2">
                                        <div className="col-12">
                                            <span className="h075">{t(`SearchEngine:Cruise:CustomPaxCompositionAlert`)}</span>
                                        </div>
                                    </div>

                                    <InputPaxesCompositionPanel
                                        searchParameters={searchParameters}
                                        defaultSelectComposition={selectComposition}
                                        productType={productType}
                                        callOnSelectComposition={onClickSelectPaxes}
                                        type={type} />
                                </>)
                                : (<></>)
                            }
                        </div>
                        <div className="modal-footer">
                            {!customPaxComposition &&
                                <button type="button" className="btn btn-sm tp-btn-fatto" data-bs-dismiss="modal">
                                    <span className="h065">{t(`Button:Confirm`)}</span>
                                </button>
                            }
                        </div>
                    </div>

                    <a id={"btnCloseModalComposition_" + idModal} data-bs-dismiss="modal"></a>
                </div>
            </div>
        </>
    );
}
