import React, { useState, useEffect } from 'react';
import { BookParameterId } from '../../../js/Constant';
import { validateBookParam, handleError } from '../../../js/Utils';
import configData from "../../../appsettings.json";
import { M3Icon } from '../../Common/M3Icon';
import { useTranslation } from 'react-i18next';

export const QuotationPaxesForm = (props) => {
    const { t } = useTranslation();
    const bookParamQuotationPaxType = 'PAX_TYPE_QUOT';
    const bookParamQuotationBirthCity = 'PAX_BIRTH_CITY';

    const today = new Date().toISOString().split("T")[0];

    const [startDate, setStartDate] = useState(today);
    const [isAgencyMode, setIsAgencyMode] = useState(true);
    const [paxesInsertBpValues, setPaxesInsertBpValues] = useState([]);

    useEffect(() => {
        if (props.paxes) {
            let myPaxes = props.paxes
                .map((pax) => {
                    let myPax = { ...pax };
                    let bookParams = myPax.bookParams.filter((bp) => !props.isAgencyMode || (bp.isMandatory || isBpAlwaysShow(myPax.bookParams, bp.bpCode)));

                    for (let i = 0; i < bookParams.length; i++) {
                        let myItem = bookParams[i];
                        let forceValidation = false;
                        if (myItem.bpCode === BookParameterId.Personal.FiscalCode && myItem.bpValue)
                            forceValidation = true;

                        myItem.isValid = !myItem.isMandatory || validateBookParam(myItem.bpCode, myItem.bpValue, forceValidation);
                        myItem.isChange = false;
                    }

                    myPax.bookParams = bookParams;

                    return myPax;
                })
                .filter((p) => p.bookParams && p.bookParams.length > 0);

            setPaxesInsertBpValues(myPaxes);
        }

        if (props.startDate)
            setStartDate(props.startDate);

        if (props.isAgencyMode !== undefined && props.isAgencyMode !== null)
            setIsAgencyMode(props.isAgencyMode);

    }, [props]);

    function isBpAlwaysShow(bookParams, bpCode) {
        var isAlwaysShow = false;

        switch (bpCode) {

            case bookParamQuotationBirthCity: {
                let hadFiscalCode = bookParams.filter(x => x.bpCode === BookParameterId.Personal.FiscalCode);
                if (hadFiscalCode && hadFiscalCode.length > 0)
                    isAlwaysShow = true;

                break;
            }

            case BookParameterId.Personal.BirthDate: {
                let hadFiscalCode = bookParams.filter(x => x.bpCode === BookParameterId.Personal.FiscalCode);
                if (hadFiscalCode && hadFiscalCode.length > 0)
                    isAlwaysShow = true;

                break;
            }
        }


        return isAlwaysShow;
    }

    function getMinBirthDate(defaultValue, typeBP) {
        if (!startDate)
            return;

        let type = 'ADT';
        try {
            let type = typeBP[0].bpValue;
        } catch (ex) {
        }

        let strMyDate = startDate;
        if (startDate instanceof Date)
            strMyDate = startDate.toDateString();

        let myDate = new Date(strMyDate);

        if (!defaultValue) {
            switch (type) {
                case 'ADT': {
                    // 18+
                    return '';
                }

                case 'CHD': {
                    // 2 - 17
                    return subtractYears(myDate, 17);
                }

                case 'INF': {
                    // 0 - 1
                    return subtractYears(myDate, 1);
                }
            }
        }

        return subtractYears(myDate, parseInt(defaultValue));
    }

    function getMaxBirthDate(defaultValue, typeBP) {
        if (!startDate)
            return;

        let type = 'ADT';
        try {
            let type = typeBP[0].bpValue;
        } catch (ex) {
        }

        let strMyDate = startDate;
        if (startDate instanceof Date)
            strMyDate = startDate.toDateString();

        let myDate = new Date(strMyDate);

        if (!defaultValue) {
            switch (type) {
                case 'ADT': {
                    // 18+
                    return subtractYears(myDate, 18);
                }

                case 'CHD': {
                    // 2 - 17
                    return subtractYears(myDate, 2);
                }

                case 'INF': {
                    // 0 - 1
                    return subtractYears(myDate, 0);
                }
            }
        }

        return subtractYears(myDate, parseInt(defaultValue) - 1);
    }

    function subtractYears(originalDate, years) {
        let date = new Date(originalDate.getTime());
        date.setFullYear(date.getFullYear() - years);

        if (date >= new Date())
            date = new Date();

        let day = date.getDate();
        if (day < 10)
            day = "0" + day;

        let month = date.getMonth();
        if (month < 10)
            month = "0" + month;

        return date.getFullYear() + "-" + month + "-" + day;
    }

    function onBlurBpValue(event, idPax, propertyName) {
        let oldPaxes = [...paxesInsertBpValues];
        let selectIndexPax = oldPaxes.findIndex(x => x.idPax === idPax);

        if (selectIndexPax < 0)
            return;

        let isEmpty = false;
        if (!event.target.value)
            isEmpty = true;

        let updatePaxBpValues = oldPaxes[selectIndexPax].bookParams;

        switch (propertyName) {
            case 'firstName': {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Personal.FirstName)[0];

                myItem.bpValue = event.target.value;
                myItem.isValid = (isEmpty && !myItem.isMandatory) || validateBookParam(myItem.bpCode, myItem.bpValue, false);
                myItem.isChange = true;

                break;
            }
            case 'lastName': {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Personal.LastName)[0];

                myItem.bpValue = event.target.value;
                myItem.isValid = (isEmpty && !myItem.isMandatory) || validateBookParam(myItem.bpCode, myItem.bpValue, false);
                myItem.isChange = true;

                break;
            }
            case 'gender': {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Personal.Gender)[0];

                myItem.bpValue = event.target.value;
                myItem.isValid = (isEmpty && !myItem.isMandatory) || validateBookParam(myItem.bpCode, myItem.bpValue, false);
                myItem.isChange = true;

                break;
            }
            case 'birthDate': {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0];

                myItem.bpValue = event.target.value;
                myItem.isValid = (isEmpty && !myItem.isMandatory) || validateBookParam(myItem.bpCode, myItem.bpValue, false);
                myItem.isChange = true;

                break;
            }
            case 'email': {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Contact.Email)[0];

                myItem.bpValue = event.target.value;
                myItem.isValid = (isEmpty && !myItem.isMandatory) || validateBookParam(myItem.bpCode, myItem.bpValue, false);
                myItem.isChange = true;

                break;
            }
            case 'mobilePhone': {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Contact.HomePhone)[0];

                myItem.bpValue = event.target.value;
                myItem.isValid = (isEmpty && !myItem.isMandatory) || validateBookParam(myItem.bpCode, myItem.bpValue, false);
                myItem.isChange = true;

                break;
            }
            case 'expiryDatePassport': {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Document.ExpirationDate)[0];

                myItem.bpValue = event.target.value;
                myItem.isValid = (isEmpty && !myItem.isMandatory) || validateBookParam(myItem.bpCode, myItem.bpValue, false);
                myItem.isChange = true;

                break;
            }
            case 'numberPassport': {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Document.Number)[0];

                myItem.bpValue = event.target.value;
                myItem.isValid = (isEmpty && !myItem.isMandatory) || validateBookParam(myItem.bpCode, myItem.bpValue, false);
                myItem.isChange = true;

                break;
            }
            case 'fiscalCode': {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === BookParameterId.Personal.FiscalCode)[0];

                myItem.bpValue = event.target.value;
                let isValidFC = myItem.bpValue.length === 16;

                myItem.isValid = (isEmpty && !myItem.isMandatory) || isValidFC;
                myItem.isChange = true;

                if (isValidFC) {
                    const inputData = { FiscalCode: myItem.bpValue };
                    const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };

                    fetch(configData.Settings.CommonApi_BaseUrl + 'Utility/FiscalCodeCheck', requestOption)
                        .then((res) => handleError(res))
                        .then((response) => {
                            let oldPaxToInsert = [...paxesInsertBpValues];
                            let updatePaxBpValuesToInsert = oldPaxes[selectIndexPax].bookParams;

                            let myItem = updatePaxBpValuesToInsert.filter(x => x.bpCode === BookParameterId.Personal.FiscalCode)[0];
                            myItem.isValid = response.success;

                            oldPaxToInsert[selectIndexPax].bookParams = updatePaxBpValuesToInsert;
                            setPaxesInsertBpValues(oldPaxToInsert);

                            if (response.success) {

                                // se e' valido, compilo automaticamente gender, data di nascita e citta di nascita
                                const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
                                fetch(configData.Settings.CommonApi_BaseUrl + 'Utility/FiscalCodeGetData/' + myItem.bpValue, requestOption)
                                    .then((res) => handleError(res))
                                    .then((autoCompleteCF) => {

                                        let oldPaxToInsert = [...paxesInsertBpValues];
                                        let updatePaxBpValuesToInsert = oldPaxes[selectIndexPax].bookParams;

                                        let myItemGender = updatePaxBpValuesToInsert.filter(x => x.bpCode === BookParameterId.Personal.Gender)[0];
                                        if (myItemGender && myItemGender) {
                                            myItemGender.bpValue = autoCompleteCF.gender;
                                            myItemGender.isValid = true;
                                            myItemGender.isChange = true;
                                        }

                                        let myItemBirthCity = updatePaxBpValuesToInsert.filter(x => x.bpCode === bookParamQuotationBirthCity)[0];
                                        if (myItemBirthCity && myItemBirthCity) {
                                            myItemBirthCity.bpValue = autoCompleteCF.birthCity;
                                            myItemBirthCity.isValid = true;
                                            myItemBirthCity.isChange = true;
                                        }

                                        let myItemBirthDate = updatePaxBpValuesToInsert.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0];
                                        if (myItemBirthDate && autoCompleteCF.birthDate) {
                                            myItemBirthDate.bpValue = autoCompleteCF.birthDate.split("T")[0];
                                            myItemBirthDate.isValid = true;
                                            myItemBirthDate.isChange = true;
                                        }

                                        oldPaxToInsert[selectIndexPax].bookParams = updatePaxBpValuesToInsert;
                                        setPaxesInsertBpValues(oldPaxToInsert);
                                    })
                                    .catch(() => { });
                            }

                        }).catch(() => { });
                }

                break;
            }

            case 'birthCity': {
                let myItem = updatePaxBpValues.filter(x => x.bpCode === bookParamQuotationBirthCity)[0];

                myItem.bpValue = event.target.value;
                myItem.isValid = (isEmpty && !myItem.isMandatory) || validateBookParam(myItem.bpCode, myItem.bpValue, false);
                myItem.isChange = true;

                break;
            }
        }

        oldPaxes[selectIndexPax].bookParams = updatePaxBpValues;
        setPaxesInsertBpValues(oldPaxes);
    }

    function onClickDelete(idPax) {
        props.onDeletePax(idPax);
    }

    function onClickAddBpValues(idPax) {
        let myPaxes = [...paxesInsertBpValues];
        let selectIndexPax = myPaxes.findIndex(x => x.idPax === idPax);

        if (selectIndexPax < 0)
            return;

        let myPax = { ...myPaxes[selectIndexPax] };
        delete myPax.isRefPax;
        delete myPax.quotationId;

        myPax.bookParams = myPax.bookParams.filter((p) => p.isChange)
            .map((bp) => {
                delete bp.isValid;
                delete bp.isMandatory;
                delete bp.isChange;
                delete bp.defaultValue;

                return bp;
            });

        if (myPax.bookParams.length === 0)
            return;

        props.onAddPaxBpValues([myPax]);
    }

    function onClickSaveAllPaxes() {
        let myPaxes = [...paxesInsertBpValues];

        let paxesWithBpChanged = myPaxes
            .map((pax) => {
                let myPax = { ...pax };
                delete myPax.isRefPax;
                delete myPax.quotationId;

                myPax.bookParams = myPax.bookParams
                    .filter((p) => p.isChange)
                    .map((bp) => {
                        delete bp.isValid;
                        delete bp.isMandatory;
                        delete bp.isChange;
                        delete bp.defaultValue;

                        return bp;
                    });

                return myPax;
            })
            .filter((pax) => pax.bookParams && pax.bookParams.length > 0);

        props.onAddPaxBpValues(paxesWithBpChanged);
    }

    function GetPaxesAgeInfo(bookParams) {
        let myAgeLabel = "";

        let dateBirthBp = bookParams.filter(x => x.bpCode === BookParameterId.Personal.BirthDate);
        if (dateBirthBp && dateBirthBp.length > 0) {
            try {

                let strMyDate = props.startDate;
                if (props.startDate instanceof Date)
                    strMyDate = props.startDate.toDateString();

                let myDate = new Date(strMyDate);

                let strBirthDate = dateBirthBp[0].bpValue;
                let myBirthDate = new Date(strBirthDate);

                var diff = (myDate.getTime() - myBirthDate.getTime()) / 1000;
                diff /= (60 * 60 * 24);
                var age = Math.abs(Math.round(diff / 365.25));
                if (!age && dateBirthBp[0].defaultValue)
                    age = parseInt(dateBirthBp[0].defaultValue);

                if (age < 2) {
                    myAgeLabel = "INF - " + age + " Anni";
                } else if (age < 18) {
                    myAgeLabel = "CHD - " + age + " Anni";
                } else {
                    myAgeLabel = "ADT";
                }

            } catch (ex) {
                myAgeLabel = "ADT";
            }

        } else {
            myAgeLabel = "ADT";
        }

        return myAgeLabel;
    }

    return (
        <>   {
            paxesInsertBpValues && paxesInsertBpValues.length > 0 && paxesInsertBpValues.map((pax, key) => {
                return (<div key={key} className="row pt-2 bg-gray-300 p-2">
                    <div className="col-12">
                        <h6>
                            {pax.isRefPax ? <>Rif Pax</> : <>{t("MyBook:Passenger")}&nbsp; {pax.idPax}</>}
                            &nbsp;(
                            {
                                !props.orderNumber && <>
                                    {pax.bookParams.filter(x => x.bpCode === bookParamQuotationPaxType)[0] && pax.bookParams.filter(x => x.bpCode === bookParamQuotationPaxType)[0].bpValue}
                                    {
                                        pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.BirthDate).length > 0 && pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0].defaultValue && <>
                                            - {pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0].defaultValue} Anni
                                        </>
                                    }
                                </>
                            }
                            {
                                props.orderNumber && <>
                                    {
                                        GetPaxesAgeInfo(pax.bookParams)
                                    }
                                </>
                            }
                            )
                        </h6>
                    </div>
                    {
                        pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.LastName).length > 0 && <div className={"form-floating col-md-3 my-2 " + (pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.LastName)[0].isValid ? "" : "insert-danger")}>
                            <input type="text"
                                className="form-control"
                                id="floatingInput"
                                value={pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.LastName)[0].bpValue}
                                onChange={e => onBlurBpValue(e, pax.idPax, 'lastName')}
                                onBlur={e => onBlurBpValue(e, pax.idPax, 'lastName')}
                                disabled={props.orderNumber || props.extraOptions.readonly}
                                placeholder="Cognome" />

                            <label htmlFor="floatingInput" className="active ">
                                {t("Login:Surname")}
                                {pax.bookParams.filter(bp => bp.bpCode === BookParameterId.Personal.LastName)[0].isMandatory ? "*" : ""}
                            </label>
                        </div>

                    }
                    {
                        pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.FirstName).length > 0 && <div className={"form-floating col-md-3 my-2 " + (pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.FirstName)[0].isValid ? "" : "insert-danger")}>
                            <input type="text"
                                className="form-control"
                                id="floatingInput"
                                value={pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.FirstName)[0].bpValue}
                                onChange={e => onBlurBpValue(e, pax.idPax, 'firstName')}
                                onBlur={e => onBlurBpValue(e, pax.idPax, 'firstName')}
                                disabled={props.orderNumber || props.extraOptions.readonly}
                                placeholder="Nome" />
                            <label htmlFor="floatingInput" className="active">
                                {t("Login:Name")}
                                {pax.bookParams.filter(bp => bp.bpCode === BookParameterId.Personal.FirstName)[0].isMandatory ? "*" : ""}
                            </label>
                        </div>
                    }
                    {
                        pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.Gender).length > 0 && <div className={"form-floating col-md-3 my-2 " + (pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.Gender)[0].isValid ? "" : "insert-danger")}>
                            <select className="form-select"
                                id="floatingSelect"
                                value={pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.Gender)[0].bpValue}
                                onChange={e => onBlurBpValue(e, pax.idPax, 'gender')}
                                onBlur={e => onBlurBpValue(e, pax.idPax, 'gender')}
                                disabled={props.orderNumber || props.extraOptions.readonly}
                                aria-label="Sesso">
                                <option value="">{t("MyBook:Select")}</option>
                                <option value="M">{t("MyBook:Male")}</option>
                                <option value="F">{t("MyBook:Female")}</option>
                            </select>

                            <label htmlFor="floatingInput" className="active">
                                {t("MyBook:Sex")}
                                {pax.bookParams.filter(bp => bp.bpCode === BookParameterId.Personal.Gender)[0].isMandatory ? "*" : ""}
                            </label>
                        </div>
                    }
                    {
                        pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.BirthDate).length > 0 && <div className={"form-floating col-md-3 my-2 " + (pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0].isValid ? "" : "insert-danger")}>
                            <input type="date"
                                className="form-check"
                                id="floatingInput"
                                min={getMinBirthDate(pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0].defaultValue, pax.bookParams.filter(x => x.bpCode === bookParamQuotationPaxType))}
                                max={getMaxBirthDate(pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0].defaultValue, pax.bookParams.filter(x => x.bpCode === bookParamQuotationPaxType))}
                                value={pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.BirthDate)[0].bpValue}
                                onChange={e => onBlurBpValue(e, pax.idPax, 'birthDate')}
                                onBlur={e => onBlurBpValue(e, pax.idPax, 'birthDate')}
                                disabled={props.orderNumber || props.extraOptions.readonly}
                                placeholder="Data nascita" />

                            <label htmlFor="floatingInput" className="active">
                                {t("MyBook:BirthDay")}
                                {pax.bookParams.filter(bp => bp.bpCode === BookParameterId.Personal.BirthDate)[0].isMandatory ? "*" : ""}
                            </label>
                        </div>
                    }
                    {
                        pax.bookParams.filter(x => x.bpCode === bookParamQuotationBirthCity).length > 0 && <div className={"form-floating col-md-3 my-2 " + (pax.bookParams.filter(x => x.bpCode === bookParamQuotationBirthCity)[0].isValid ? "" : "insert-danger")}>

                            <input type="text"
                                className="form-control"
                                id="floatingInput"
                                value={pax.bookParams.filter(x => x.bpCode === bookParamQuotationBirthCity)[0].bpValue}
                                onChange={e => onBlurBpValue(e, pax.idPax, 'birthCity')}
                                onBlur={e => onBlurBpValue(e, pax.idPax, 'birthCity')}
                                disabled={props.orderNumber || props.extraOptions.readonly}
                                placeholder="Luogo di nascita" />

                            <label htmlFor="floatingInput" className="active">
                                {t("MyBook:BirthPlace")}
                                {pax.bookParams.filter(bp => bp.bpCode === bookParamQuotationBirthCity)[0].isMandatory ? "*" : ""}
                            </label>
                        </div>
                    }
                    {
                        pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.FiscalCode).length > 0 && <div className={"form-floating col-md-3 my-2 " + (pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.FiscalCode)[0].isValid ? "" : "insert-danger")}>
                            <input type="text"
                                className="form-control"
                                id="floatingInput"
                                value={pax.bookParams.filter(x => x.bpCode === BookParameterId.Personal.FiscalCode)[0].bpValue}
                                onChange={e => onBlurBpValue(e, pax.idPax, 'fiscalCode')}
                                onBlur={e => onBlurBpValue(e, pax.idPax, 'fiscalCode')}
                                disabled={props.orderNumber || props.extraOptions.readonly}
                                placeholder="email" />
                            <label htmlFor="floatingInput" className="active">
                                {t("MyBook:idCode")}
                                {pax.bookParams.filter(bp => bp.bpCode === BookParameterId.Personal.FiscalCode)[0].isMandatory ? "*" : ""}
                            </label>
                        </div>

                    }
                    {
                        pax.bookParams.filter(x => x.bpCode === BookParameterId.Document.Number).length > 0 && <div className={"form-floating col-md-3 my-2 " + (pax.bookParams.filter(x => x.bpCode === BookParameterId.Document.Number)[0].isValid ? "" : "insert-danger")}>
                            <input type="text"
                                className="form-control"
                                id="floatingInput"
                                value={pax.bookParams.filter(x => x.bpCode === BookParameterId.Document.Number)[0].bpValue}
                                onChange={e => onBlurBpValue(e, pax.idPax, 'numberPassport')}
                                onBlur={e => onBlurBpValue(e, pax.idPax, 'numberPassport')}
                                disabled={props.orderNumber || props.extraOptions.readonly}
                                placeholder="Numero passaporto" />
                            <label htmlFor="floatingInput" className="active">
                                {t("MyBook:PassportNumber")}
                                {pax.bookParams.filter(bp => bp.bpCode === BookParameterId.Document.Number)[0].isMandatory ? "*" : ""}
                            </label>
                        </div>
                    }
                    {
                        pax.bookParams.filter(x => x.bpCode === BookParameterId.Document.ExpirationDate).length > 0 && <div className={"form-floating col-md-3 my-2 " + (pax.bookParams.filter(x => x.bpCode === BookParameterId.Document.ExpirationDate)[0].isValid ? "" : "insert-danger")}>
                            <input type="date"
                                className="form-control"
                                id="floatingInput"
                                value={pax.bookParams.filter(x => x.bpCode === BookParameterId.Document.ExpirationDate)[0].bpValue}
                                min={today}
                                onChange={e => onBlurBpValue(e, pax.idPax, 'expiryDatePassport')}
                                onBlur={e => onBlurBpValue(e, pax.idPax, 'expiryDatePassport')}
                                disabled={props.orderNumber || props.extraOptions.readonly}
                                placeholder="Data Passaporto" />
                            <label htmlFor="floatingInput" className="active">
                                {t("MyBook:Passenger")}
                                {pax.bookParams.filter(bp => bp.bpCode === BookParameterId.Document.ExpirationDate)[0].isMandatory ? "*" : ""}
                            </label>
                        </div>
                    }
                    {
                        pax.bookParams.filter(x => x.bpCode === BookParameterId.Contact.HomePhone).length > 0 && <div className={"form-floating col-md-3 my-2 " + (pax.bookParams.filter(x => x.bpCode === BookParameterId.Contact.HomePhone)[0].isValid ? "" : "insert-danger")}>
                            <input type="tel"
                                className="form-control"
                                id="floatingInput"
                                value={pax.bookParams.filter(x => x.bpCode === BookParameterId.Contact.HomePhone)[0].bpValue}
                                onChange={e => onBlurBpValue(e, pax.idPax, 'mobilePhone')}
                                onBlur={e => onBlurBpValue(e, pax.idPax, 'mobilePhone')}
                                disabled={props.orderNumber || props.extraOptions.readonly}
                                placeholder="telefono" />
                            <label htmlFor="floatingInput" className="active">
                                {t("MyBook:Phone")}
                                {pax.bookParams.filter(bp => bp.bpCode === BookParameterId.Contact.HomePhone)[0].isMandatory ? "*" : ""}
                            </label>
                        </div>
                    }

                    {
                        pax.bookParams.filter(x => x.bpCode === BookParameterId.Contact.Email).length > 0 && <div className={"form-floating col-md-3 my-2 " + (pax.bookParams.filter(x => x.bpCode === BookParameterId.Contact.Email)[0].isValid ? "" : "insert-danger")}>
                            <input type="email"
                                className="form-control"
                                id="floatingInput"
                                value={pax.bookParams.filter(x => x.bpCode === BookParameterId.Contact.Email)[0].bpValue}
                                onChange={e => onBlurBpValue(e, pax.idPax, 'email')}
                                onBlur={e => onBlurBpValue(e, pax.idPax, 'email')}
                                disabled={props.orderNumber || props.extraOptions.readonly}
                                placeholder="email" />
                            <label htmlFor="floatingInput" className="active">
                                E-mail
                                {pax.bookParams.filter(bp => bp.bpCode === BookParameterId.Contact.Email)[0].isMandatory ? "*" : ""}
                            </label>
                        </div>
                    }
                    {
                        !props.orderNumber && !props.extraOptions.readonly && !props.extraOptions.readonly && <div className="col-12 text-end">
                            {!isAgencyMode && <button className="btn tp-btn-delete btn-sm mr-2 my-2 h085" onClick={e => onClickDelete(pax.idPax)}><M3Icon iconName="Remove" externalClass="h085" /> {t("MyBook:Passenger")}</button>}
                            {
                                props.extraOptions.saveSinglePax && <button className="btn tp-btn-add btn-sm mr-2 my-2 h085" onClick={e => onClickAddBpValues(pax.idPax)}><M3Icon iconName="Save" externalClass="h085" /> Save Pax</button>
                            }
                        </div>
                    }

                </div>
                );
            })
        }
            {
                !props.orderNumber && !props.extraOptions.readonly && paxesInsertBpValues && paxesInsertBpValues.length > 0 && <div className="row pt-2 bg-gray-300 p-2 border-top border-success border-2">
                    <div className="col-12 text-end">
                        <button className="btn btn-sm tp-btn-add mb-2" onClick={e => onClickSaveAllPaxes()}>Save All</button>
                    </div>
                </div>
            }
        </>
    );
}