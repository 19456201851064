import React, { useEffect, useState } from 'react';
import configData from "../../appsettings.json";
import { handleError, addDaysToDatePicker } from "../../js/Utils";
import { InputSearchDestinationModal } from "../Common/Destination/InputSearchDestinationModal";
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { AddServiceNote } from './components/AddServiceNote';

export const CarManual = (props) => {
    const { t } = useTranslation();
    const today = new Date().toISOString().split("T")[0];

    const carDetailDefault = {
        id: -1,
        productType: configData.Settings.Products.CarRental.IdTipoProdotto,
        name: '',
        city: {},

        providerId: 'NOT',
        subProviderId: 'NOT',

        imageUrl: '',
        payBy: '',
        reservationId: '',
        recordLocator: '',
        internalNote: '',

        pickupDate: '',
        pickupTime: '',
        dropoffDate: '',
        dropoffTime: '',
        cancelPolicy: '',

        buyPriceAmount: null,
        buyPriceCurrency: 'EUR',
        buyPriceChange: null,

        pickupOffice: '',
        pickupOfficeAddress: '',
        pickupOfficePhone: '',

        dropoffOffice: '',
        dropoffOfficeAddress: '',
        dropoffOfficePhone: '',

        driverAge: 25,
        totalPassengers: null,
        totalBaggage: null,

        serviceNote: null,
        serviceNoteDesc: null
    }
    const validateCarDefault = {
        isValidName: false,
        isTouchedName: false,
        isValidCity: false,
        isTouchedCity: false,
        isValidProviderId: false,
        isTouchedProviderId: false,

        isValidPickupDate: false,
        isTouchedPickupDate: false,
        isValidPickupTime: false,
        isTouchedPickupTime: false,

        isValidDropoffDate: false,
        isTouchedDropoffDate: false,
        isValidDropoffTime: false,
        isTouchedDropoffTime: false,

        isValidCancelPolicy: false,
        isTouchedCancelPolicy: false,

        isTouchedBuyPriceAmount: false,
        isValidBuyPriceAmount: false,

        isTouchedPickupOffice: false,
        isValidPickupOffice: false,

        isTouchedDropoffOffice: false,
        isValidDropoffOffice: false,

        isValidForm: false
    }

    const [currencies, setCurrencies] = useState([]);
    const [suppliers, setSuppliers] = useState([]);

    const [carDetail, setCarDetail] = useState({ ...carDetailDefault });
    const [validateInputData, setValidateInputData] = useState({ ...validateCarDefault });
    const [validateValueDefault, setValidateValueDefault] = useState({ maxCancelPolicy: '' });

    useEffect(() => {
        if (props.isOpen) {
            if (currencies.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}constant/getcurrency`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setCurrencies(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (suppliers.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}customer/getallsupplierforproduct/${configData.Settings.Products.CarRental.IdTipoProdotto}`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setSuppliers(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (!props.item) {
                setCarDetail({ ...carDetailDefault });
                setValidateInputData({ ...validateCarDefault });
            }
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (!props.item) {
            setCarDetail({ ...carDetailDefault });
            setValidateInputData({ ...validateCarDefault });
        } else if (props.item.productType === configData.Settings.Products.CarRental.IdTipoProdotto) {
            setCarDetail(props.item);

            let isValidAD = { ...validateCarDefault };
            Object.keys(isValidAD).forEach(key => {
                isValidAD[key] = true;
            });

            setValidateInputData(isValidAD);
        }
    }, [props.item]);

    function onBlurEvent(event, propertyName) {
        let updateCarDetail = { ...carDetail };

        let newValue = '';
        if (propertyName === 'providerId' || propertyName === 'subProviderId' || propertyName === 'buyPriceCurrency') {
            newValue = event.key;
            if (!newValue)
                newValue = event;
        }
        else if (propertyName === 'serviceNote' || propertyName === 'serviceNoteDesc') {
            newValue = event;
        }
        else {
            newValue = event.target.value;
        }

        if (propertyName === 'buyPriceChange' && !newValue) {
            newValue = null;
        }

        updateCarDetail[propertyName] = newValue;

        switch (propertyName) {
            case 'pickupOffice': {
                updateCarDetail.dropoffOffice = newValue;
                break;
            }
            case 'pickupOfficePhone': {
                updateCarDetail.dropoffOfficePhone = newValue;
                break;
            }
            case 'pickupOfficeAddress': {
                updateCarDetail.dropoffOfficeAddress = newValue;
                break;
            }
        }

        if (propertyName === 'buyPriceCurrency') {
            let exchangeRate = 1;
            if (currencies && currencies.length > 0) {
                let currency = currencies.find(element => element.value === newValue);
                if (currency)
                    exchangeRate = currency.rate;
            }

            updateCarDetail['buyPriceChange'] = exchangeRate;
        }

        setCarDetail(updateCarDetail);

        checkValidateInputData(propertyName, newValue);
    }

    function checkValidateInputData(propertyName, value) {
        let isValidForm = false;
        let prevValidateInputData = { ...validateInputData };
        delete prevValidateInputData.isValidForm;

        const isNotEmpty = (value !== '' && value !== undefined);
        const isSelectedOption = value !== 'NOT';

        switch (propertyName) {
            case 'name': {
                delete prevValidateInputData.isValidName;
                delete prevValidateInputData.isTouchedName;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidName: isNotEmpty, isTouchedName: true });
                break;
            }
            case 'city': {
                delete prevValidateInputData.isValidCity;
                delete prevValidateInputData.isTouchedCity;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidCity: isNotEmpty, isTouchedCity: true });
                break;
            }
            case 'providerId': {
                delete prevValidateInputData.isValidProviderId;
                delete prevValidateInputData.isTouchedProviderId;
                isValidForm = isSelectedOption && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidProviderId: isSelectedOption, isTouchedProviderId: true });
                break;
            }
            case 'pickupDate': {
                delete prevValidateInputData.isValidPickupDate;
                delete prevValidateInputData.isTouchedPickupDate;
                delete prevValidateInputData.isValidCancelPolicy;
                delete prevValidateInputData.isTouchedCancelPolicy;

                const isValidDate = (new Date(value) !== "Invalid Date") && new Date(value) >= new Date(today);
                isValidForm = isNotEmpty && isValidDate && Object.values(prevValidateInputData).every(item => item);

                let newValidateInputData = {
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidPickupDate: isNotEmpty && isValidDate,
                    isTouchedPickupDate: true,
                    isValidCancelPolicy: isNotEmpty,
                    isTouchedCancelPolicy: true
                };

                setValidateInputData(newValidateInputData);

                break;

            }
            case 'pickupTime': {
                delete prevValidateInputData.isValidPickupTime;
                delete prevValidateInputData.isTouchedPickupTime;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidPickupTime: isNotEmpty, isTouchedPickupTime: true });
                break;
            }
            case 'dropoffDate': {
                delete prevValidateInputData.isValidDropoffDate;
                delete prevValidateInputData.isTouchedDropoffDate;

                let isValidDate = (new Date(value) !== "Invalid Date") && new Date(value) >= new Date(today);
                const checkIn = new Date(carDetail['pickupDate']);
                const checkOut = new Date(value);

                let isGreaterThanCheckIn = checkOut >= checkIn;
                isValidDate = isValidDate && isGreaterThanCheckIn;

                isValidForm = isNotEmpty && isValidDate && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidDropoffDate: isNotEmpty && isValidDate,
                    isTouchedDropoffDate: true
                });
                break;
            }
            case 'dropoffTime': {
                delete prevValidateInputData.isValidDropoffTime;
                delete prevValidateInputData.isTouchedDropoffTime;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidDropoffTime: isNotEmpty, isTouchedDropoffTime: true });
                break;
            }
            case 'cancelPolicy': {
                delete prevValidateInputData.isValidCancelPolicy;
                delete prevValidateInputData.isTouchedCancelPolicy;

                let isValidDate = (new Date(value) !== "Invalid Date") && new Date(value) >= new Date(today);
                const checkIn = new Date(carDetail['pickupDate']);
                const cancelPolicy = new Date(value);
                let isLessThanCheckIn = cancelPolicy <= checkIn;
                isValidDate = isValidDate && isLessThanCheckIn;

                isValidForm = isNotEmpty && isValidDate && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidCancelPolicy: isNotEmpty && isValidDate,
                    isTouchedCancelPolicy: true
                });
                break;
            }
            case 'buyPriceAmount': {
                delete prevValidateInputData.isValidBuyPriceAmount;
                delete prevValidateInputData.isTouchedBuyPriceAmount;

                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidBuyPriceAmount: isNotEmpty, isTouchedBuyPriceAmount: true });
                break;
            }
            case 'pickupOffice': {
                delete prevValidateInputData.isValidPickupOffice;
                delete prevValidateInputData.isTouchedPickupOffice;

                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidPickupOffice: isNotEmpty,
                    isTouchedPickupOffice: true,
                    isValidDropoffOffice: isNotEmpty,
                    isTouchedDropoffOffice: true
                });
                break;
            }
            case 'dropoffOffice': {
                delete prevValidateInputData.isValidDropoffOffice;
                delete prevValidateInputData.isTouchedDropoffOffice;

                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidDropoffOffice: isNotEmpty, isTouchedDropoffOffice: true });
                break;
            }
            default:
                break;
        }
    }

    function onChangePickupDate(event) {
        var updateCarDetail = { ...carDetail };
        updateCarDetail.pickupDate = event.target.value;

        if (carDetail.dropoffDate && new Date(carDetail.dropoffDate) < new Date(event.target.value)) {
            updateCarDetail.dropoffDate = '';
        }

        let cpDate = addDaysToDatePicker(event.target.value, -1);
        updateCarDetail.cancelPolicy = cpDate;

        if (event.target.value) {
            setValidateValueDefault({ maxCancelPolicy: cpDate });
        }
        else {
            setValidateValueDefault({ maxCancelPolicy: '' });
        }

        setCarDetail(updateCarDetail);
        checkValidateInputData("pickupDate", event.target.value);
    }

    function callOnSelectDestination(item) {
        var updateCarRentalDetail = { ...carDetail };
        updateCarRentalDetail.city = { webId: item.Id + "", text: item.Text, geoId: item.GeoId + "" };

        setCarDetail(updateCarRentalDetail);
        checkValidateInputData("city", item);
    }

    function saveCarManual() {
        props.callAddManualQuotationItem(carDetail);
    }

    return (
        <div className="p-4">
            <div className="row">
                <h4>Autonoleggio</h4>
                <div className="col-md-3">
                    <div className="form-floating">
                        <span className="p-float-label form-select-label-m3-react-prime">
                            {
                                suppliers && suppliers.length > 0 && <Dropdown value={carDetail.subProviderId}
                                    onChange={(e) => onBlurEvent(e.value, 'subProviderId')}
                                    inputId="dd-subProviderId"
                                    filter
                                    className="w-100 form-select-m3-react-prime"
                                    options={suppliers}
                                    optionLabel="label" />
                            }
                            <label htmlFor="dd-subProviderId">Società autonoleggio</label>
                        </span>
                    </div>
                </div>
                <div className={"form-floating col-md-6" + (!validateInputData.isValidName ? " insert-danger" : "")} >
                    <input type="text" className="form-control" value={carDetail.name} id="floatingInput" placeholder="nome visualizzabile auto" onChange={(e) => { onBlurEvent(e, 'name'); }} />
                    {
                        validateInputData.isTouchedName && !validateInputData.isValidName && <div className="text-danger">{t('Login:FieldNameIsMandatory')}</div>
                    }
                    <label htmlFor="floatingInput" className="active">Nome auto *</label>
                </div>
                <div className={"form-floating col-md-3" + (!validateInputData.isValidCity ? " insert-danger" : "")}>
                    <input type="text"
                        className="form-control"
                        id="floatingInput"
                        value={carDetail.city && carDetail.city.text ? carDetail.city.text : ''}
                        data-bs-toggle="modal"
                        data-bs-target="#CM_Manual_City"
                        placeholder="Città * (Applica regole)"
                        readOnly />
                    <label htmlFor="floatingInput" className="active">
                        Città * (Applica regole)
                    </label>

                    <InputSearchDestinationModal
                        modalId={"CM_Manual_City"}
                        productType={configData.Settings.Products.CarRental.IdTipoProdotto}
                        callOnSelectDestination={callOnSelectDestination}
                        rules={{ enableDestination: true }} />
                    {
                        validateInputData.isTouchedCity && !validateInputData.isValidCity && <div className="text-danger">Il campo città è obbligatorio</div>
                    }
                </div>
            </div>
            <div className="row pt-2">
                <div className="col-md-3">
                    <div className={"form-floating" + (!validateInputData.isValidProviderId ? " insert-danger" : "")}>
                        <span className="p-float-label form-select-label-m3-react-prime">
                            {
                                suppliers && suppliers.length > 0 && <Dropdown value={carDetail.providerId}
                                    onChange={(e) => onBlurEvent(e.value, 'providerId')}
                                    inputId="dd-providerId"
                                    filter
                                    className="w-100 form-select-m3-react-prime"
                                    options={suppliers}
                                    optionLabel="label" />
                            }
                            <label htmlFor="dd-providerId">Fornitore *</label>
                        </span>
                        {
                            validateInputData.isTouchedProviderId && !validateInputData.isValidProviderId && <div className="text-danger">Il campo fornitore è obbligatorio</div>
                        }
                    </div>
                </div>
                <div className="form-floating col-md-3">
                    <input type="text" className="form-control" value={carDetail.payBy === null ? '' : carDetail.payBy} id="floatingInput" placeholder="Fornitore per voucher" onChange={(e) => { onBlurEvent(e, 'payBy'); }} />
                    <label htmlFor="floatingInput" className="active">Pay by:</label>
                </div>
                <div className="form-floating col-md-3">
                    <input type="text" className="form-control" value={carDetail.recordLocator === null ? '' : carDetail.recordLocator} id="floatingInput" placeholder="IdRecordLocator" onChange={(e) => { onBlurEvent(e, 'recordLocator'); }} />
                    <label htmlFor="floatingInput" className="active">Cod prenotazione:</label>
                </div>
                <div className="form-floating col-md-3">
                    <input type="text" className="form-control" value={carDetail.reservationId === null ? '' : carDetail.reservationId} id="floatingInput" placeholder="IdReservation" onChange={(e) => { onBlurEvent(e, 'reservationId'); }} />
                    <label htmlFor="floatingInput" className="active">Cod prenotazione 2:</label>
                </div>

            </div>
            <div className="row pt-4">
                <div className={"form-floating col-md-3" + (!validateInputData.isValidPickupDate ? " insert-danger" : "")} >
                    <input type="date" className="form-control" value={carDetail.pickupDate} id="txt_SM_CheckIn" placeholder="01/01/99" min={today} onChange={onChangePickupDate} />
                    <label htmlFor="floatingInput" className="active">Data PickUp *</label>
                    {
                        validateInputData.isTouchedPickupDate && !validateInputData.isValidPickupDate && <div className="text-danger">Il campo pickup non è valido</div>
                    }
                </div>
                <div className={"form-floating col-md-2" + (!validateInputData.isValidPickupTime ? " insert-danger" : "")}>
                    <input type="time" className="form-control" value={carDetail.pickupTime} id="txt_SM_CheckIn" placeholder="01/01/99" onChange={(e) => { onBlurEvent(e, 'pickupTime'); }} />
                    <label htmlFor="floatingInput" className="active">h PickUp *</label>
                    {
                        validateInputData.isTouchedPickupTime && !validateInputData.isValidPickupTime && <div className="text-danger">Il campo ora è obbligatorio</div>
                    }
                </div>
                <div className={"form-floating col-md-3" + (!validateInputData.isValidDropoffDate ? " insert-danger" : "")} >
                    <input type="date" className="form-control" value={carDetail.dropoffDate} id="txt_SM_CheckIn" placeholder="01/01/99" min={carDetail.pickupDate} onChange={(e) => { onBlurEvent(e, 'dropoffDate'); }} />
                    <label htmlFor="floatingInput" className="active">Data DropOff *</label>
                    {
                        validateInputData.isTouchedDropoffDate && !validateInputData.isValidDropoffDate && <div className="text-danger">Il campo dropoff non è valido</div>
                    }
                </div>
                <div className={"form-floating col-md-2" + (!validateInputData.isValidDropoffTime ? " insert-danger" : "")} >
                    <input type="time" className="form-control" value={carDetail.dropoffTime} id="txt_SM_CheckIn" placeholder="01/01/99" onChange={(e) => { onBlurEvent(e, 'dropoffTime'); }} />
                    <label htmlFor="floatingInput" className="active">h DropOff *</label>
                    {
                        validateInputData.isTouchedDropoffTime && !validateInputData.isValidDropoffTime && <div className="text-danger">Il campo ora è obbligatorio</div>
                    }
                </div>
                <div className={"form-floating col-md-2" + (!validateInputData.isValidCancelPolicy ? " insert-danger" : "")}>
                    <input type="date"
                        className="form-control"
                        value={carDetail.cancelPolicy}
                        id="txt_SM_CancelPolicy"
                        placeholder="01/01/99"
                        max={validateValueDefault.maxCancelPolicy}
                        onChange={(e) => { onBlurEvent(e, 'cancelPolicy'); }} />
                    <label htmlFor="floatingInput" className="active">Inizio penale *</label>
                    {
                        validateInputData.isTouchedCancelPolicy && !validateInputData.isValidCancelPolicy && <div className="text-danger">Il campo cancel policy non è valido</div>
                    }
                </div>
            </div>
            <div className="row pt-4">
                <div className="form-floating col-md-12">
                    <input type="text"
                        className="form-control"
                        value={carDetail.imageUrl}
                        id="floatingInput"
                        placeholder="Url immagine"
                        onChange={(e) => { onBlurEvent(e, 'imageUrl'); }} />
                    <label htmlFor="floatingInput" className="active">Url Immagine</label>
                </div>
            </div>

            <div>
                <AddServiceNote
                    serviceNote={carDetail.serviceNoteDesc}
                    roomIndex={-1}
                    noteTypeToAdd={12}
                    propertyName="serviceNoteDesc"
                    onBlurEvent={onBlurEvent} />
            </div>
            <div className="form-floating pt-2">
                <textarea className="form-control"
                    placeholder="Note Interne"
                    value={carDetail.internalNote}
                    onChange={(e) => { onBlurEvent(e, 'internalNote'); }}
                    id="floatingTextarea2"
                    style={{ height: '100px' }} ></textarea>
                <label htmlFor="floatingTextarea2">Note Interne</label>
            </div>

            <div className="badge-gray-600 mt-4">Dettagli</div>
            <div className="row pt-4">
                <div className={"form-floating col-md-4" + (!validateInputData.isValidPickupOffice ? " insert-danger" : "")}>
                    <input type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Ufficio pickup"
                        value={carDetail.pickupOffice}
                        onChange={(e) => { onBlurEvent(e, 'pickupOffice'); }} />
                    <label htmlFor="floatingInput" className="active">Ufficio PickUp *:</label>
                    {
                        validateInputData.isTouchedPickupOffice && !validateInputData.isValidPickupOffice && <div className="error-input">Il campo ufficio è obbligatorio</div>
                    }
                </div>
                <div className="form-floating col-md-5">
                    <input type="text" className="form-control" id="floatingInput" placeholder="indirizzo" value={carDetail.pickupOfficeAddress} onChange={(e) => { onBlurEvent(e, 'pickupOfficeAddress'); }} />
                    <label htmlFor="floatingInput" className="active">Indirizzo:</label>
                </div>
                <div className="form-floating col-md-3">
                    <input type="tel" className="form-control" id="floatingInput" placeholder="telefono" value={carDetail.pickupOfficePhone} onChange={(e) => { onBlurEvent(e, 'pickupOfficePhone'); }} />
                    <label htmlFor="floatingInput" className="active">Telefono:</label>
                </div>
            </div>
            <div className="row pt-4">
                <div className={"form-floating col-md-4" + (!validateInputData.isValidDropoffOffice ? " insert-danger" : "")} >
                    <input type="text" className="form-control" id="floatingInput" placeholder="ufficio dropoff" value={carDetail.dropoffOffice} onChange={(e) => { onBlurEvent(e, 'dropoffOffice'); }} />
                    <label htmlFor="floatingInput" className="active">Ufficio DropOff *:</label>
                    {
                        validateInputData.isTouchedDropoffOffice && !validateInputData.isValidDropoffOffice && <div className="error-input">Il campo ufficio è obbligatorio</div>
                    }
                </div>
                <div className="form-floating col-md-5">
                    <input type="text" className="form-control" id="floatingInput" placeholder="indirizzo" value={carDetail.dropoffOfficeAddress} onChange={(e) => { onBlurEvent(e, 'dropoffOfficeAddress'); }} />
                    <label htmlFor="floatingInput" className="active">Indirizzo:</label>
                </div>
                <div className="form-floating col-md-3">
                    <input type="tel" className="form-control" id="floatingInput" placeholder="telefono" value={carDetail.dropoffOfficePhone} onChange={(e) => { onBlurEvent(e, 'dropoffOfficePhone'); }} />
                    <label htmlFor="floatingInput" className="active">Telefono:</label>
                </div>
            </div>
            <div className="row pt-2">
                <div className="form-floating col-2">
                    <input type="number" className="form-control" id="floatingInput" placeholder="Eta Guidatore" value={carDetail.driverAge === null ? '' : carDetail.driverAge} onChange={(e) => { onBlurEvent(e, 'driverAge'); }} />
                    <label htmlFor="floatingInput" className="active">Eta Guidatore</label>
                </div>
                <div className="form-floating col-2">
                    <input type="number" className="form-control" id="floatingInput" placeholder="N. Passeggeri" value={carDetail.totalPassengers === null ? '' : carDetail.totalPassengers} onChange={(e) => { onBlurEvent(e, 'totalPassengers'); }} />
                    <label htmlFor="floatingInput" className="active">N. Passeggeri </label>
                </div>
                <div className="form-floating col-2">
                    <input type="number" className="form-control" id="floatingInput" placeholder="Bagagli" value={carDetail.totalBaggage === null ? '' : carDetail.totalBaggage} onChange={(e) => { onBlurEvent(e, 'totalBaggage'); }} />
                    <label htmlFor="floatingInput" className="active">Bagagli</label>
                </div>
                <div className={"form-floating col-md-2" + (!validateInputData.isValidBuyPriceAmount ? " insert-danger" : "")} >
                    <input type="number" className="form-control" value={carDetail.buyPriceAmount === null ? '' : carDetail.buyPriceAmount} id="floatingInput" placeholder="123.45" onChange={(e) => { onBlurEvent(e, 'buyPriceAmount'); }} />
                    <label htmlFor="floatingInput" className="active">Costo *</label>
                    {
                        validateInputData.isTouchedBuyPriceAmount && !validateInputData.isValidBuyPriceAmount && <div className="error-input">Il campo costo è obbligatorio</div>
                    }
                </div>
                <div className="col-md-2">
                    <div className="form-floating">
                        <span className="p-float-label form-select-label-m3-react-prime">
                            {
                                currencies && currencies.length > 0 && <Dropdown value={carDetail.buyPriceCurrency}
                                    onChange={(e) => onBlurEvent(e.value, 'buyPriceCurrency')}
                                    inputId="dd-buyPriceCurrency"
                                    filter
                                    className="w-100 form-select-m3-react-prime"
                                    options={currencies}
                                    optionLabel="label" />
                            }
                            <label htmlFor="dd-buyPriceCurrency">Valuta</label>
                        </span>
                    </div>
                </div>
                <div className="form-floating col-md-2">
                    <input type="number" className="form-control" id="floatingInput" placeholder="123.45" value={carDetail.buyPriceChange === null ? '' : carDetail.buyPriceChange} onChange={(e) => { onBlurEvent(e, 'buyPriceChange'); }} />
                    <label htmlFor="floatingInput" className="active">Tasso Cambio </label>
                </div>
                <div>L'età effettiva al momento della partenza deve corrispondere all'età dichiarata al momento della presa dell'auto</div>
                <div>
                    <AddServiceNote
                        serviceNote={carDetail.serviceNote}
                        roomIndex={-1}
                        onBlurEvent={onBlurEvent} />
                </div>
            </div>

            <div className=" float-end">
                {props.isError && <span className="text-danger">Errore in fase di inserimento, controllare i dati</span>}
                <button className="btn tp-btn-add btn-sm" disabled={!validateInputData.isValidForm} onClick={saveCarManual}>{t('Button:Save')}</button>
            </div>
        </div>
    );
}