import 'leaflet/dist/leaflet.css';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import React, { useEffect, useState, useRef } from 'react';
import L, { divIcon } from "leaflet";
import pm from '@geoman-io/leaflet-geoman-free';
import { MapContainer, LayerGroup, TileLayer, Marker, CircleMarker, Popup, Tooltip, Polyline, Rectangle, LayersControl, FeatureGroup, GeoJSON } from 'react-leaflet';
import GeoJSONLayer from './GeoJSONLayer';
import MarkerClusterGroup from 'react-leaflet-cluster'

export const TravelPlaceMap = ({ geoJsonRef, map, setMap, data, children, center, zoom, scrollWheelZoom, showFilterArea, sizeInMeters, showClusterPopup, updateFeatures, clusterMarkerPopup, markerPopup, cultureCode, showCluster, handleClickPointOnMap }) => {
    const filterLayerRef = useRef();
    const featureFilteredRef = useRef();
    const [filterLayerDraggable, setfilterLayerDraggable] = useState(true);
    const [filterLayer, setFilterLayer] = useState(null);

    const [geoJsonKey, addToGeoJsonKey] = useState(1);

    const filterLayerStyle = { color: "#000000", opacity: 0.35, weight: 1 };

    useEffect(() => {
        if (geoJsonRef && geoJsonRef.current) {
            var geojsonBounds = geoJsonRef.current.getBounds();
            if (!geojsonBounds.isValid()) {
                geojsonBounds = L.latLngBounds([-90, -180], [90, 180]);
            }
            map.fitBounds(geojsonBounds);
        }
    }, [map]);

    useEffect(() => {
        if (!map)
            return;

        var rectBounds = showFilterArea
            ? map.getCenter().toBounds(sizeInMeters)
            : null;

        setFilterLayer(rectBounds);
        renderMap();
    }, [map, showFilterArea, sizeInMeters]);

    useEffect(() => {
        renderMap();
    }, [filterLayer, data]);

    function markerFilter(feature) {
        if (feature.geometry.type === "LineString")
            return true;

        var latLng = L.GeoJSON.coordsToLatLng(feature.geometry.coordinates);
        if (filterLayerRef?.current) {
            if (!filterLayerRef.current.getBounds().contains(latLng)) {
                return false;
            }
        }

        if (feature?.properties?.StructureId) {
            featureFilteredRef.current = [...featureFilteredRef.current, {
                id: feature.properties.StructureId,
                name: feature.properties.StructureName,
                latLng: latLng,
                isSelected: false,
                marker: null,
                price: feature.properties.BestPriceAmount,
                currency: feature.properties.BestPriceCurrency,
                category: feature.properties.StructureCategory

            }]
            updateFeatures(featureFilteredRef.current);
        }

        return true;
    }

    function renderMap() {
        featureFilteredRef.current = [];
        updateFeatures([]);
        addToGeoJsonKey(geoJsonKey + 1);
    }

    const filterLayerEventHandlers = {
        add(e) {
            e.target.pm.enableLayerDrag();
        },
        'pm:dragend'() {
            renderMap();
        },
    };

    const setColor = ({ properties }) => {
        return { weight: 1 };
    };

    const customMarkerIcon = (name) =>
        divIcon({
            html: name,
            className: "icon"
        });

    const setIcon = ({ properties }, latlng) => {
        return L.marker(latlng, { icon: customMarkerIcon(properties.StructureName) });
    };

    const MarkerClusterMouseOver = (e) => {
        if (showClusterPopup === true) {
            if (e.layer.options.opacity < 1) return;

            var childMarkers = e.layer.getAllChildMarkers();

            if (childMarkers.length <= 5) {
                if (clusterMarkerPopup === undefined) {
                    clusterMarkerPopup = defaultClusterMarkerPopup;
                }

                var htmlContainer = clusterMarkerPopup(childMarkers);

                e.sourceTarget
                    .bindPopup(htmlContainer, { closeButton: true, closeOnClick: true, autoClose: true })
                    .openPopup();
            }
        }
    };

    const MarkerClusterMouseOut = (e) => {
        if (showClusterPopup === true) {
            var popup = e.sourceTarget.getPopup();
            if (popup && popup.options?.closeButton !== true) {
                e.sourceTarget.closePopup();
            }
        }
    };

    const defaultClusterMarkerPopup = (childMarkers) => {
        var htmlContainer = "<div>";
        htmlContainer += '<div style="display:block; max-height: 400px; width: 250px; overflow-x: hidden;" />';
        childMarkers.forEach(function (m) {
            // hotel
            if (m.feature?.properties?.StructureId) {
                htmlContainer += "<div><label>Id: " + m.feature.properties.StructureId + " </label></div>"
            }
            if (m.feature?.properties?.StructureName) {
                htmlContainer += "<div><label>Name: " + m.feature.properties.StructureName + " </label></div>"
            }
            htmlContainer += "<hr/>"
        });
        htmlContainer += "</div>";
        htmlContainer += "</div>";
        return htmlContainer;
    }

    return (
        <MapContainer
            ref={setMap}
            center={center}
            zoom={zoom}
            scrollWheelZoom={scrollWheelZoom}
        >
            {children ||
                <LayersControl>
                    <LayersControl.BaseLayer checked name="Open Street Map">
                        <TileLayer
                            attribution='Travelplace &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            eventHandlers={{
                                add: (e) => {
                                    console.log("Added Layer:", e.target);
                                },
                                remove: (e) => {
                                    console.log("Removed layer:", e.target);
                                }
                            }}
                        />
                    </LayersControl.BaseLayer>

                    {/*<LayersControl.BaseLayer name="Mapbox Map">*/}
                    {/*    <TileLayer*/}
                    {/*        attribution='Travelplace &copy; <a href="https://www.mapbox.com">Mapbox</a> '*/}
                    {/*        url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token={accessToken}"*/}
                    {/*        accessToken={"your token here"}*/}
                    {/*    />*/}
                    {/*</LayersControl.BaseLayer>*/}

                    {/*<LayersControl.BaseLayer name="Mapbox Map Satellite">*/}
                    {/*    <TileLayer*/}
                    {/*        attribution='Travelplace &copy; <a href="https://www.mapbox.com">Mapbox</a> '*/}
                    {/*        url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token={accessToken}"*/}
                    {/*        accessToken={"your token here"}*/}
                    {/*    />*/}
                    {/*</LayersControl.BaseLayer>*/}

                    <LayersControl.BaseLayer name="Google Map">
                        <TileLayer
                            attribution="Travelplace &copy; Google Maps"
                            url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                        />
                    </LayersControl.BaseLayer>

                    <LayersControl.BaseLayer name="Google Map Satellite">
                        <LayerGroup>
                            <TileLayer
                                attribution="Travelplace &copy; Google Maps Satellite"
                                url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                            />
                            <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}" />
                        </LayerGroup>
                    </LayersControl.BaseLayer>
                </LayersControl>
            }
            {showCluster ?
                <MarkerClusterGroup key={`markercluster-${geoJsonKey}`} onMouseOver={MarkerClusterMouseOver} onMouseOut={MarkerClusterMouseOut}>
                    <GeoJSONLayer key={`geoJson-${geoJsonKey}`} data={data} geoJsonRef={geoJsonRef} markerFilter={markerFilter} markerPopup={markerPopup} cultureCode={cultureCode} handleClickPointOnMap={handleClickPointOnMap} />
                </MarkerClusterGroup>
                :
                <GeoJSONLayer key={`geoJson-${geoJsonKey}`} data={data} geoJsonRef={geoJsonRef} markerFilter={markerFilter} markerPopup={markerPopup} cultureCode={cultureCode} handleClickPointOnMap={handleClickPointOnMap} />
            }

            {showFilterArea && filterLayer &&
                <Rectangle bounds={filterLayer} pathOptions={filterLayerStyle} draggable={filterLayerDraggable} eventHandlers={filterLayerEventHandlers} ref={filterLayerRef} />
            }
        </MapContainer>
    );
}
