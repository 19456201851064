import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { Loading } from '../Common/Loading';
import { formatPrice } from '../../js/Utils.js';
import configData from "../../appsettings.json";
import { M3Icon } from '../Common/M3Icon';

export const CartAddictionalProducts = ({ defaultCheckedItems, handleAddProdSelection, handleSaveOriginalAddProd, templateAddProdRequest, forceRender, isTrippie }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [initDefaultSelections, setInitDefaultSelections] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [cartAddictProds, setCartAddictProds] = useState([]);
    const [templateDataIds, setTemplateDataIds] = useState([]);
    const [forceRenderId, setForceRenderId] = useState(1);

    useEffect(() => {
        setIsLoading(true);

        if (isTrippie) {
            getCartAddictProds({ AddictionalProductsSettings: { GroupMandatory: configData.Settings.Cart.AddictionalProductsGroupMandatory, GroupMandatoryDescription: t(`Cart:GroupMandatoryDescription`) } });
        }
        else if (!templateAddProdRequest) {
            getCartAddictProds({ AddictionalProductsSettings: { GroupMandatory: configData.Settings.Cart.AddictionalProductsGroupMandatory, GroupMandatoryDescription: t(`Cart:GroupMandatoryDescription`) } });
        } else {
            let rq = templateAddProdRequest;
            rq.AddictionalProductsSettings = { GroupMandatory: configData.Settings.Cart.AddictionalProductsGroupMandatory, GroupMandatoryDescription: t(`Cart:GroupMandatoryDescription`) };
            getCartAddictProds(rq);
        }
    }, []);

    // executed after first useEffect (when loading is done) to invoke parent method
    useEffect(() => {

        if (cartAddictProds === null || cartAddictProds === undefined || cartAddictProds.length === 0 || !initDefaultSelections)
            return;

        setInitDefaultSelections(false);
        callHandleAddProdSelection(cartAddictProds);
    }, [isLoading]);


    useEffect(() => {

        if (templateAddProdRequest) {
            let reloadAddProd = true;

            if (templateAddProdRequest.SelectedProducts && templateAddProdRequest.SelectedProducts.length > 0) {
                let myItems = templateAddProdRequest.SelectedProducts
                    .filter(x => x.Selected)
                    .map(x => x.TemplateDataId)
                    .sort();

                if (myItems.toString() === templateDataIds.toString())
                    reloadAddProd = false;

                setTemplateDataIds(myItems);
            }

            if (reloadAddProd || (forceRender && forceRender !== forceRenderId)) {
                setIsLoading(true);
                let rq = templateAddProdRequest;
                rq.AddictionalProductsSettings = { GroupMandatory: configData.Settings.Cart.AddictionalProductsGroupMandatory, GroupMandatoryDescription: t(`Cart:GroupMandatoryDescription`) };
                getCartAddictProds(rq);
            }

            if (forceRender)
                setForceRenderId(forceRender);
        }

    }, [templateAddProdRequest, forceRender]);


    const getCartAddictProds = async (inputData) => {
        const response = callGetCartAddictProds(inputData);
    }
    async function callGetCartAddictProds(inputData) {
        let apiToCall = !templateAddProdRequest ? 'ShoppingCart/GetAddictionalProducts' : 'Template/GetAddictionalProducts';

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + apiToCall, requestOption);
        const response = await fetchedRes.json();

        if (templateAddProdRequest) {
            callHandleAddProdSelection(response.items);
            handleSaveOriginalAddProd(response.originalAddictionalProduct);
            setCartAddictProds(response.items);
        } else {

            if (!response.addProds)
                setCartAddictProds(response);
            else {
                if (defaultCheckedItems && defaultCheckedItems.length > 0) {
                    for (let i = 0; i < response.addProds.length; i++) {
                        let item = defaultCheckedItems.filter(x => x.idProdotto === response.addProds[i].idProdotto);
                        if (item && item.length > 0)
                            response.addProds[i].consigliato = item[0].selected;
                    }
                }

                setCartAddictProds(response.addProds);


            }
        }

        setIsLoading(false);
    }

    const handleChangeSelection = (idProd, selected, groupId, price) => {
        let data = [];

        if (groupId === undefined || groupId === null) {
            // modalità singolo prodotto
            data.push({ idProdotto: idProd, selected: selected, price: price });
        } else {
            // se sono in modalità raggruppata, allora setto tutti i prodotti del gruppo allo stesso modo
            for (let i = 0; i < cartAddictProds.length; i++) {
                if (cartAddictProds[i].groupId === null || cartAddictProds[i].groupId !== groupId)
                    continue;

                for (let j = 0; j < cartAddictProds[i].groupedIdsProdotto.length; j++) {
                    data.push({ idProdotto: cartAddictProds[i].groupedIdsProdotto[j], selected: selected });
                }
            }
        }

        handleAddProdSelection(data);
    }
    const handleAlternativeChangeSelection = (idProdSelected) => {
        let data = [];

        for (let i = 0; i < cartAddictProds.length; i++) {
            if (!cartAddictProds[i].isAlternative)
                continue;

            let productoToAdd = {
                idProdotto: cartAddictProds[i].idProdotto,
                selected: cartAddictProds[i].idProdotto === idProdSelected,
                price: cartAddictProds[i].price,
                netPrice: cartAddictProds[i].netPrice
            };
            data.push(productoToAdd);
        }

        handleAddProdSelection(data);
    }

    const callHandleAddProdSelection = (cartAddictProds) => {
        let data = [];

        for (let i = 0; i < cartAddictProds.length; i++) {
            if (cartAddictProds[i].obbligatorio || cartAddictProds[i].consigliato) {
                if (!cartAddictProds[i].groupId && (!cartAddictProds[i].groupedIdsProdotto || cartAddictProds[i].groupedIdsProdotto.length === 0)) {
                    // modalità singolo prodotto
                    data.push({ idProdotto: cartAddictProds[i].idProdotto, selected: true, price: cartAddictProds[i].price, netPrice: cartAddictProds[i].netPrice });
                } else {
                    // se sono in modalità raggruppata, allora setto tutti i prodotti del gruppo allo stesso modo
                    for (let j = 0; j < cartAddictProds[i].groupedIdsProdotto.length; j++) {

                        // ho gia' il prezzo totale sul primo prodotto, quindi per gli altri lo lascio a zero
                        let price = 0;
                        let netPrice = 0;

                        if (cartAddictProds.filter(x => x.idProdotto === cartAddictProds[i].groupedIdsProdotto[j]).length > 0) {
                            price = cartAddictProds.filter(x => x.idProdotto === cartAddictProds[i].groupedIdsProdotto[j])[0].price;
                            netPrice = cartAddictProds.filter(x => x.idProdotto === cartAddictProds[i].groupedIdsProdotto[j])[0].netPrice;
                        }
                        data.push({ idProdotto: cartAddictProds[i].groupedIdsProdotto[j], selected: true, price: price, netPrice: netPrice });
                    }
                }
            }
        }

        handleAddProdSelection(data);
    }


    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : (
                    <>{
                        (cartAddictProds && cartAddictProds.length > 0) &&
                        <div className="basket-card card mt-2 p-3">
                            <div>
                                <M3Icon iconName="Generic" externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                                <span className="basket titlecard">{t("MyBook:ProtectYourTrvel")}</span>
                            </div>

                            {/*Prodotti NON in alternativa tra loro*/}
                            {cartAddictProds.filter(x => !x.isAlternative && x.idTipoProdotto !== 3).map((addProd, index) =>
                                <div key={index} className="p-2">
                                    <span>{addProd.manualName ? addProd.manualName : addProd.descrizione}</span>

                                    {addProd.pdfUrl && <span className="pl-2"><a href={addProd.pdfUrl} target="_blank">Vedi Pdf</a></span>}

                                    {
                                        addProd.pdfUrls && addProd.pdfUrls.map((pdf, index) => {
                                            return <span className="pl-2">
                                                <a href={pdf} target="_blank">
                                                    Vedi Pdf
                                                    {
                                                        addProd.pdfUrls.length > 1 ? <> {(index + 1)}</> : <></>
                                                    }
                                                </a>
                                            </span>;
                                        })
                                    }

                                    {addProd.obbligatorio && (
                                        <p>
                                            <label className="d-inline-block">{t("MyBook:Mandatory")}</label>
                                            <span className="d-inline-block float-end">{formatPrice(addProd.price, addProd.currency, cultureName)}</span>
                                        </p>
                                    )}

                                    {!addProd.obbligatorio && (
                                        <>
                                            <p className="mb-1">
                                                <label className="d-inline-block">
                                                    <input id={`rb_addProd_${addProd.idProdotto}_0`} type="radio" className="mr-1" name={`btnradio_addProd_${addProd.idProdotto}`} autoComplete="off" defaultChecked={addProd.consigliato} onChange={() => handleChangeSelection(addProd.idProdotto, true, addProd.groupId, addProd.price)} />
                                                    {addProd.labelSelect
                                                        ? <span dangerouslySetInnerHTML={{ __html: addProd.labelSelect }}></span>
                                                        : t(`Cart:AddictionalProductAdd`)
                                                    }
                                                </label>
                                                <span className="d-inline-block float-end">{formatPrice(addProd.price, addProd.currency, cultureName)}</span>
                                            </p>
                                            <p className="mb-1">
                                                <label className="d-inline-block">
                                                    <input id={`rb_addProd_${addProd.idProdotto}_1`} type="radio" className="mr-1" name={`btnradio_addProd_${addProd.idProdotto}`} autoComplete="off" onChange={() => handleChangeSelection(addProd.idProdotto, false, addProd.groupId, addProd.price)} />
                                                    {addProd.labelNotSelect
                                                        ? <span dangerouslySetInnerHTML={{ __html: addProd.labelNotSelect }}></span>
                                                        : t(`Cart:AddictionalProductNotAdd`)
                                                    }
                                                </label>
                                                <span className="d-inline-block float-end">{formatPrice(0, addProd.currency, cultureName)}</span>
                                            </p>
                                        </>
                                    )}
                                </div>
                            )}

                            {/*Prodotti IN ALTERNATIVA tra loro*/}
                            {cartAddictProds.filter(x => x.isAlternative).map((addProd, index) =>
                                <div key={index} className="p-2">
                                    <p className="mb-1">
                                        <label className="d-inline-block">
                                            <input id={`rb_addProd_alternative_${addProd.idProdotto}`} type="radio" className="mr-1" name="btnradio_addProd_alternative" autoComplete="off" onChange={() => handleAlternativeChangeSelection(addProd.idProdotto)} defaultChecked={addProd.consigliato} />
                                            {addProd.descrizione}
                                            {addProd.pdfUrl && <span className="pl-2"><a href={addProd.pdfUrl} target="_blank">Vedi Pdf</a></span>}
                                            {
                                                addProd.pdfUrls && addProd.pdfUrls.map((pdf, index) => {
                                                    return <span className="pl-2">
                                                        <a href={pdf} target="_blank">
                                                            Vedi Pdf
                                                            {
                                                                addProd.pdfUrls.length > 1 ? <> {(index + 1)}</> : <></>
                                                            }
                                                        </a>
                                                    </span>;
                                                })
                                            }
                                        </label>
                                        <span className="d-inline-block float-end">{formatPrice(addProd.price, addProd.currency, cultureName)}</span>
                                    </p>
                                </div>
                            )}
                        </div>
                    }
                    </>
                )
            }
        </>
    )
}
