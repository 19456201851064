import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { formatDateTime, formatPrice } from '../../../../js/Utils.js';
import { useTranslation } from 'react-i18next';

export const CartBuoniScontoModal = ({ type, buoniSconto, msg, onSelectBuoniSconto }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [selectedBuoniSconto, setSelectedBuoniSconto] = useState([]);

    function onChangeCheckbox(event, id) {
        const isSelected = event.target.checked;
        saveSelectedBuoniSconto(id, isSelected, null);
    }

    function onChangeDropdown(event, id) {
        const selectedValue = event.target.selectedOptions[0].value;
        const isSelected = selectedValue !== '-1';
        saveSelectedBuoniSconto(id, isSelected, selectedValue);
    }

    function saveSelectedBuoniSconto(id, isSelected, idPratica) {
        let updateArray = [...selectedBuoniSconto];

        updateArray = updateArray.filter(x => x.buonoScontoId !== id);

        if (isSelected) {
            let myObj = { buonoScontoId: id, idPratica: idPratica };
            updateArray.push(myObj);
        }

        setSelectedBuoniSconto(updateArray);
    }

    function confirmSelectedBuoniSconto(e) {
        if (e) e.preventDefault();

        onSelectBuoniSconto(selectedBuoniSconto);
    }

    return (
        <>
            <a id={"open_SelectDiscount_" + type} data-bs-toggle="modal" data-bs-target={"#modal_SelectDiscount_" + type} ></a>
            <div className="modal fade" id={"modal_SelectDiscount_" + type} tabIndex="-1" aria-labelledby={"modal_SelectDiscount_" + type} aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal_SelectDiscountLabel">Buoni Sconto</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row mb-2">
                                <div className="col-12 h6">
                                    {msg}
                                </div>
                            </div>
                            <div className="row mb-2 font-weight-bold">
                                <div className="col-2">Numero</div>
                                <div className="col-2">Titolo</div>
                                <div className="col-2">Scadenza</div>
                                <div className="col-2">Splittabile</div>
                                <div className="col-2">Valore</div>
                                <div className="col-2"></div>
                            </div>
                            {
                                buoniSconto && buoniSconto.map((bs, index) => {
                                    return <div key={index} className="row border-bottom mb-2 py-3">
                                        <div className="col-2">{bs.code}</div>
                                        <div className="col-2">{bs.title}</div>
                                        <div className="col-2">
                                            {bs.expiresOn ? formatDateTime(bs.expiresOn, cultureName) : "-"}
                                        </div>
                                        <div className="col-2">{bs.isSplit ? "SI" : "NO"}</div>
                                        <div className="col-2">
                                            {bs.amountType === 'P' && <>{bs.amount} {bs.currency}</>}
                                            {bs.amountType !== 'P' && <>{formatPrice(bs.amount, bs.currency, cultureName)}</>}
                                        </div>
                                        {
                                            bs.buonoScontoType === 'Rebate' && <div className="col-2">
                                                <select className="form-select" id="floatingSelect" onChange={(e) => { onChangeDropdown(e, bs.id); }} aria-label="Valuta">
                                                    <option value="-1">Seleziona</option>
                                                    {
                                                        bs.cartItems && bs.cartItems.map((item, key) => {
                                                            return <option key={key} value={item.value}>{item.label}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        }
                                        {
                                            bs.buonoScontoType !== 'Rebate' && <div className="col-2">
                                                <input type="checkbox" defaultValue="false" onChange={(e) => onChangeCheckbox(e, bs.id)} />
                                            </div>
                                        }
                                    </div>
                                })
                            }
                        </div>
                        <div className='modal-footer'>
                            {/*{*/}
                            {/*    !isLoading && onSendResponseResult && !onSendResponseResult.success && <span className="text-danger" style={{ float: 'left', margin: '0 auto' }}>{t('Cart:CartErrorGeneric')}</span>*/}
                            {/*}*/}
                            <button type="button" className="btn bt-sm tp-btn-confirm" data-bs-dismiss="modal" onClick={e => confirmSelectedBuoniSconto(e)}>Conferma</button>
                            <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">Chiudi</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
