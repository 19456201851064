import React, { useState, useEffect } from 'react';
import { getCustomStyle, getColumnClasses } from '../service/WebContentService';
import { WebContentForm } from './WebContentForm';

export const WebContentFormColumn = ({ item }) => {
    const [myStyle, setMyStyle] = useState({});

    useEffect(() => {
        let cssStyle = getCustomStyle(item);
        setMyStyle(cssStyle);
    }, []);

    return <div style={myStyle} className={getColumnClasses(item.dimensione)}>
        {
            item.formSubType === 1 && <WebContentForm item={item} />
        }
        {
            item.formSubType === 0 && <div dangerouslySetInnerHTML={{ __html: item.contenuto }}></div>
        }
    </div>;
}