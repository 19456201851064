import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

export const AddNoteXml = ({ roomNotes, label, onBlurEvent }) => {
    const { t } = useTranslation();

    const [normalizeRoomsNotes, setNormalizeRoomNotes] = useState([]);

    useEffect(() => {

        if (roomNotes) {
            let updRoomNotes = []
            for (let i = 0; i < roomNotes.length; i++) {
                let room = { ...roomNotes[i] };
               
                updRoomNotes.push(room);
            }

            setNormalizeRoomNotes(updRoomNotes);
        }

    }, [roomNotes])

    function getRoomTitle(index) {
        if (label) {
            if (label === 'Treno')
                return index === 0 ? 'Treno Andata' : 'Treno Ritorno';

            return label + " " + (index + 1);
        }

        return "";
    }

    function onCallAddRow(index) {
        let updDescriptions = [...normalizeRoomsNotes]
        if (!updDescriptions[index].noteXML) {
            updDescriptions[index].noteXML = [];
        }

        updDescriptions[index].noteXML.push({ index: index, note: '' });

        setNormalizeRoomNotes(updDescriptions);
    }

    const onRowEditComplete = function (e, roomIndex) {
        let updDescriptions = [...normalizeRoomsNotes];
        let { newData, index } = e;
        if (!updDescriptions[roomIndex].update)
            updDescriptions[roomIndex].update = 0;

        updDescriptions[roomIndex].update = updDescriptions[roomIndex].update + 1;
        updDescriptions[roomIndex].noteXML[index] = newData;

        mappingOnBlurEvent(updDescriptions);
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const bodyDelete = (rowData) => {
        return (
            <div className="cursor-pointer" onClick={_ => onRemove(rowData)}>
                <span class="material-icons-outlined text-danger">delete</span>
            </div>
        );
    };

    function onRemove(rowData) {
        let updDescriptions = [...normalizeRoomsNotes];
        updDescriptions[rowData.index].noteXML = updDescriptions[rowData.index].noteXML.filter(x => x.note !== rowData.note);
        mappingOnBlurEvent(updDescriptions);
    }

    function mappingOnBlurEvent(updDescriptions) {
        let updRoomNotes = []
        for (let i = 0; i < updDescriptions.length; i++) {
            let room = { ...updDescriptions[i] };
            updRoomNotes.push(room);
        }

        onBlurEvent(updRoomNotes);
    }

    return (
        <div className="my-2">
            <div className="row">
                {
                    normalizeRoomsNotes && normalizeRoomsNotes.map((room, index) => {
                        return <React.Fragment key={index}>
                            <h6>{getRoomTitle(index)}</h6>
                            {
                                room.noteXML && room.noteXML.length > 0 ? <div className="col-12">
                                    <DataTable value={room.noteXML}
                                        editMode="row"
                                        dataKey="id"
                                        onRowEditComplete={e => onRowEditComplete(e, index)}
                                        tableStyle={{ minWidth: '50rem' }}>
                                        <Column field="note" header="Nota" editor={(options) => textEditor(options)} style={{ width: '80%' }}></Column>
                                        <Column rowEditor={true} style={{ width: '10%' }}></Column>
                                        <Column body={bodyDelete} style={{ width: '10%' }}></Column>
                                    </DataTable>
                                </div>
                                    : <></>
                            }
                            <div className="col-12 text-right">
                                <button type="button"
                                    className="btn bt-sm tp-btn-confirm"
                                    onClick={_ => onCallAddRow(index)}>
                                    {t("MyBook:Add")}
                                </button>
                            </div>
                        </React.Fragment>
                    })
                }

            </div>
        </div>
    );
}
