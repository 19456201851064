import React from 'react';
import { M3Icon } from "../../../Common/M3Icon";

export const ActivityDetailHeader = ({ activityDetail }) => {

    function getDescriptionMeetingPoint() {
        if (activityDetail.descriptions && activityDetail.descriptions.length > 0) {
            let desc = activityDetail.descriptions.filter(d => d.renderType.toUpperCase() === 'MEETINGPOINT');

            if (desc && desc.length > 0) {
                return <div className=" border bg-gray-300 p-2 rounded mb-3">
                    <div className="d-inline">
                        <label className="">
                            <data m3ico="Start icon-10"><M3Icon iconName="Start" externalClass="icon-10" hasLabel={false} /></data>
                        </label>
                    </div>
                    <div className="d-inline small "><var dangerouslySetInnerHTML={{ __html: desc[0].value }}></var></div>
                </div>;
            }
        }
    }

    return (
        <>
            <div className="h6">
                <data m3ico="Place icon-15">
                    <M3Icon iconName="Place" externalClass="icon-15" hasLabel={false} />
                </data>
                <var>{activityDetail.city ? activityDetail.city : (activityDetail.touchPoints && activityDetail.touchPoints.length > 0 && activityDetail.touchPoints[0].webDestinationName)}</var>
            </div>
            <div className="h4"><var>{activityDetail.name}</var></div>
            {getDescriptionMeetingPoint()}
        </>
    );
}