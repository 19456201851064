import React, { useEffect, useState } from 'react';
import configData from "../../appsettings.json";
import { manualRangeParameter } from "../../js/Constant";
import { handleError, addDaysToDatePicker } from "../../js/Utils";
import { InputSearchDestinationModal } from "../Common/Destination/InputSearchDestinationModal";
import { M3Icon } from '../Common/M3Icon';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { AddServiceNote } from './components/AddServiceNote';

export const StructureManual = (props) => {
    const { t } = useTranslation();
    const today = new Date().toISOString().split("T")[0];

    const roomDefault = {
        id: 0,
        name: '',
        mealPlanId: "0",
        mealPlanName: '',
        buyPriceAmount: null,
        buyPriceCurrency: 'EUR',
        buyPriceChange: null,
        cityTax: null,
        note: '',
        totalAdults: manualRangeParameter.hotel.defaultAdults,
        totalChildren: manualRangeParameter.hotel.defaultChildren,
        ages: []
    }

    const structureDetailDefault = {
        id: -1,
        productType: configData.Settings.Products.Structure.IdTipoProdotto,
        idStruttura: null,
        name: '',
        address: '',
        city: {},
        providerId: 'NOT',

        description: '',
        imageUrl: '',
        payBy: '',
        reservationId: '',
        recordLocator: '',
        internalNote: '',
        serviceNoteDesc: null,

        checkIn: '',
        checkOut: '',
        cancelPolicy: '',
        rooms: [
            { ...roomDefault }
        ]
    }

    const validateRoomDefault = {
        isValidName: false,
        isTouchedName: false,
        isValidBuyPriceAmount: false,
        isTouchedBuyPriceAmount: false
    }

    const validateStructureRoomDefault = {
        isValidName: false,
        isTouchedName: false,
        isValidAddress: false,
        isTouchedAddress: false,
        isValidCity: false,
        isTouchedCity: false,
        isValidProviderId: false,
        isTouchedProviderId: false,
        isValidCheckIn: false,
        isTouchedCheckIn: false,
        isValidCheckOut: false,
        isTouchedCheckOut: false,
        isValidCancelPolicy: false,
        isTouchedCancelPolicy: false,

        isValidRoom: [{ ...validateRoomDefault }],

        isValidForm: false
    };

    const [currencies, setCurrencies] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [roomTreatments, setRoomTreatments] = useState([]);

    const [structureDetail, setStructureDetail] = useState({ ...structureDetailDefault });
    const [validateInputData, setValidateInputData] = useState({ ...validateStructureRoomDefault });
    const [validateValueDefault, setValidateValueDefault] = useState({ maxCancelPolicy: '', minCheckOut: '' });
    const [isErrorLoadStructureData, setIsErrorStructureData] = useState(false);
    const [isLoadingStructureData, setIsLoadingStructureData] = useState(false);


    useEffect(() => {
        if (props.isOpen) {
            if (currencies.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}constant/getcurrency`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setCurrencies(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (suppliers.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}customer/getallsupplierforproduct/${configData.Settings.Products.Structure.IdTipoProdotto}`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setSuppliers(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (roomTreatments.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}structure/getalltreatments`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setRoomTreatments(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (!props.item) {
                setStructureDetail({ ...structureDetailDefault });
                setValidateInputData({ ...validateStructureRoomDefault });
            }
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (!props.item) {
            setStructureDetail({ ...structureDetailDefault });
            setValidateInputData({ ...validateStructureRoomDefault });
        } else if (props.item.productType === configData.Settings.Products.Structure.IdTipoProdotto) {
            setStructureDetail(props.item);

            var isValidSD = { ...validateStructureRoomDefault };
            Object.keys(isValidSD).forEach(key => {
                if (key === 'isValidRoom') {
                    var myArray = [];
                    for (var i = 0; i < props.item.rooms.length; i++) {
                        var item = { ...validateRoomDefault };
                        Object.keys(item).forEach(r => item[r] = true);
                        myArray.push(item);
                    }

                    isValidSD[key] = myArray;
                } else {
                    isValidSD[key] = true;
                }
            });

            setValidateInputData(isValidSD);
        }
    }, [props.item]);

    function onBlurEvent(event, propertyName) {
        let updateStructureDetail = { ...structureDetail };
        let newValue = '';
        if (propertyName === 'providerId') {
            newValue = event.key;
            if (!newValue)
                newValue = event;
        }
        else if (propertyName === 'serviceNote' || propertyName === 'serviceNoteDesc') {
            newValue = event;
        }
        else {
            newValue = event.target.value;
        }

        updateStructureDetail[propertyName] = newValue;

        setStructureDetail(updateStructureDetail);

        checkValidateInputData(propertyName, newValue);
    }

    function checkValidateInputData(propertyName, value) {
        let isValidForm = false;
        let prevValidateInputData = { ...validateInputData };
        delete prevValidateInputData.isValidRoom;
        delete prevValidateInputData.isValidForm;

        const isNotEmpty = (value !== '' && value !== undefined);
        const isSelectedProvider = value !== 'NOT';

        switch (propertyName) {
            case 'name': {
                delete prevValidateInputData.isValidName;
                delete prevValidateInputData.isTouchedName;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item) && checkIsValidAllRooms(-1);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidName: isNotEmpty, isTouchedName: true });
                break;
            }
            case 'city': {
                delete prevValidateInputData.isValidCity;
                delete prevValidateInputData.isTouchedCity;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item) && checkIsValidAllRooms(-1);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidCity: isNotEmpty, isTouchedCity: true });
                break;
            }
            case 'idStruttura': {
                setIsErrorStructureData(false);
                setIsLoadingStructureData(false);
                break;
            }
            case 'address': {
                delete prevValidateInputData.isValidAddress;
                delete prevValidateInputData.isTouchedAddress;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item) && checkIsValidAllRooms(-1);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidAddress: isNotEmpty, isTouchedAddress: true });
                break;
            }
            case 'providerId': {
                delete prevValidateInputData.isValidProviderId;
                delete prevValidateInputData.isTouchedProviderId;
                isValidForm = isSelectedProvider && Object.values(prevValidateInputData).every(item => item) && checkIsValidAllRooms(-1);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidProviderId: isSelectedProvider, isTouchedProviderId: true });
                break;
            }
            case 'checkIn': {
                delete prevValidateInputData.isValidCheckIn;
                delete prevValidateInputData.isTouchedCheckIn;
                delete prevValidateInputData.isValidCancelPolicy;
                delete prevValidateInputData.isTouchedCancelPolicy;

                const isValidDate = (new Date(value) !== "Invalid Date") && new Date(value) >= new Date(today);
                isValidForm = isNotEmpty && isValidDate && Object.values(prevValidateInputData).every(item => item) && checkIsValidAllRooms(-1);

                let newValidateInputData = {
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidCheckIn: isNotEmpty && isValidDate,
                    isTouchedCheckIn: true,
                    isValidCancelPolicy: isNotEmpty,
                    isTouchedCancelPolicy: true
                };

                setValidateInputData(newValidateInputData);
                break;
            }
            case 'checkOut': {
                delete prevValidateInputData.isValidCheckOut;
                delete prevValidateInputData.isTouchedCheckOut;

                let isValidDate = (new Date(value) !== "Invalid Date") && new Date(value) > new Date(today);

                const checkIn = new Date(structureDetail['checkIn']);
                const checkOut = new Date(value);

                let isGreaterThanCheckIn = checkOut >= checkIn;
                isValidDate = isValidDate && isGreaterThanCheckIn;

                isValidForm = isNotEmpty && isValidDate && Object.values(prevValidateInputData).every(item => item) && checkIsValidAllRooms(-1);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidCheckOut: isNotEmpty && isValidDate,
                    isTouchedCheckOut: true
                });
                break;
            }
            case 'cancelPolicy': {
                delete prevValidateInputData.isValidCancelPolicy;
                delete prevValidateInputData.isTouchedCancelPolicy;

                let isValidDate = (new Date(value) !== "Invalid Date") && new Date(value) >= new Date(today);

                const checkIn = new Date(structureDetail['checkIn']);
                const cancelPolicy = new Date(value);
                let isLessThanCheckIn = cancelPolicy <= checkIn;
                isValidDate = isValidDate && isLessThanCheckIn;

                isValidForm = isNotEmpty && isValidDate && Object.values(prevValidateInputData).every(item => item) && checkIsValidAllRooms(-1);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidCancelPolicy: isNotEmpty && isValidDate,
                    isTouchedCancelPolicy: true
                });
                break;
            }
            default:
                break;
        }
    }

    function checkIsValidStructureInfo() {
        return validateInputData.isValidName && validateInputData.isValidAddress && validateInputData.isValidProviderId &&
            validateInputData.isValidCheckIn && validateInputData.isValidCheckOut && validateInputData.isValidCancelPolicy
            && validateInputData.isValidCity;
    }

    function onChangeCheckIn(event) {
        var updateStructureDetail = { ...structureDetail };
        updateStructureDetail.checkIn = event.target.value;

        if (structureDetail.checkOut && new Date(structureDetail.checkOut) < new Date(event.target.value)) {
            updateStructureDetail.checkOut = '';
        }

        let cpDate = addDaysToDatePicker(event.target.value, -1);
        updateStructureDetail.cancelPolicy = cpDate;

        if (event.target.value) {
            var minCheckOut = addDaysToDatePicker(event.target.value, 1);
            setValidateValueDefault({ minCheckOut: minCheckOut, maxCancelPolicy: cpDate });
        }
        else {
            setValidateValueDefault({ minCheckOut: '', maxCancelPolicy: '' });
        }

        setStructureDetail(updateStructureDetail);
        checkValidateInputData("checkIn", event.target.value);
    }

    function addRoom() {
        let updateRooms = [...structureDetail.rooms];

        var newRoom = { ...roomDefault };
        newRoom.buyPriceCurrency = updateRooms[0].buyPriceCurrency;
        newRoom.buyPriceChange = updateRooms[0].buyPriceChange;

        newRoom.id = updateRooms[updateRooms.length - 1].id + 1;
        updateRooms.push(newRoom);

        setStructureDetail({ ...structureDetail, rooms: updateRooms });
        let updateValidRoom = [...validateInputData.isValidRoom];
        updateValidRoom.push(validateRoomDefault);
        setValidateInputData({ ...validateInputData, isValidForm: false, isValidRoom: updateValidRoom });
    }

    function removeRoom(removeIndex) {
        let updateRooms = [...structureDetail.rooms];

        updateRooms.splice(removeIndex, 1);

        setStructureDetail({ ...structureDetail, rooms: updateRooms });

        let updateValidRoom = [...validateInputData.isValidRoom];
        updateValidRoom.splice(removeIndex, 1);
        setValidateInputData({ ...validateInputData, isValidForm: checkIsValidStructureInfo() && checkIsValidAllRooms(removeIndex), isValidRoom: updateValidRoom });
    }

    function onBlurRoom(index, propertyName, event) {
        let updateRooms = [...structureDetail.rooms];
        let newValue = '';
        if (propertyName === 'buyPriceCurrency') {
            newValue = event.key;
            if (!newValue)
                newValue = event;
        }
        else if (propertyName === 'serviceNote' || propertyName === 'serviceNoteDesc') {
            newValue = event;
        }
        else {
            newValue = event.target.value;
        }

        if (propertyName === 'totalAdults') {
            let numAdults = event.target.value ? parseInt(event.target.value) : 0;

            if (numAdults > manualRangeParameter.hotel.maxAdults) {
                numAdults = manualRangeParameter.hotel.maxAdults;
            } else if (numAdults < manualRangeParameter.hotel.minAdults) {
                numAdults = manualRangeParameter.hotel.minAdults;
            }

            newValue = numAdults;
        }

        if (propertyName === 'buyPriceChange' && !newValue) {
            newValue = null;
        }

        if (propertyName === 'buyPriceCurrency') {
            let exchangeRate = 1;
            if (currencies && currencies.length > 0) {
                let currency = currencies.find(element => element.value === newValue);
                if (currency)
                    exchangeRate = currency.rate;
            }

            for (let i = 0; i < updateRooms.length; i++) {
                updateRooms[i][propertyName] = newValue;
                updateRooms[i]['buyPriceChange'] = exchangeRate;
            }
        }
        else if (propertyName === 'buyPriceChange') {
            for (let i = 0; i < updateRooms.length; i++) {
                updateRooms[i]['buyPriceChange'] = newValue;
            }
        }

        if (propertyName === 'mealPlanId') {
            const tratSelected = roomTreatments.filter(t => t.value === newValue);
            if (tratSelected && tratSelected.length > 0)
                updateRooms[index]["mealPlanName"] = tratSelected[0].text;
        }

        updateRooms[index][propertyName] = newValue;
        setStructureDetail({ ...structureDetail, rooms: updateRooms });

        checkValidateRoomInputData(index, propertyName, newValue);
    }

    function checkValidateRoomInputData(index, propertyName, value) {
        let isValidForm = { ...validateInputData.isValidForm };
        let prevValidateInputData = { ...validateInputData.isValidRoom };

        const isNotEmpty = (value !== '' && value !== undefined);

        switch (propertyName) {
            case 'name': {
                delete prevValidateInputData[index].isValidName;
                delete prevValidateInputData[index].isTouchedName;

                var isValidCurrentRoom = prevValidateInputData[index].isValidBuyPriceAmount;

                isValidForm = checkIsValidStructureInfo() && isValidCurrentRoom && isNotEmpty && checkIsValidAllRooms(index);

                prevValidateInputData[index].isValidName = isNotEmpty;
                prevValidateInputData[index].isTouchedName = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, rooms: prevValidateInputData });
                break;
            }
            case 'buyPriceAmount': {
                delete prevValidateInputData[index].isValidBuyPriceAmount;
                delete prevValidateInputData[index].isTouchedBuyPriceAmount;

                var isValidCurrentRoom = prevValidateInputData[index].isValidName;

                isValidForm = checkIsValidStructureInfo() && isValidCurrentRoom && isNotEmpty && checkIsValidAllRooms(index);

                prevValidateInputData[index].isValidBuyPriceAmount = isNotEmpty;
                prevValidateInputData[index].isTouchedBuyPriceAmount = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, rooms: prevValidateInputData });
                break;
            }
            default:
                break;
        }
    }

    function checkIsValidAllRooms(exludeIndex) {
        if (!validateInputData.isValidRoom)
            return false;

        var isValid = true;
        for (var i = 0; i < validateInputData.isValidRoom.length; i++) {
            if (i !== exludeIndex) {
                isValid = isValid && Object.values(validateInputData.isValidRoom[i]).every(x => x);
            }
        }

        return isValid;
    }

    function onChangeRoomChildren(index, event) {
        let updateRooms = [...structureDetail.rooms];
        var room = updateRooms[index];

        let numChildren = event.target.value ? parseInt(event.target.value) : 0;

        if (numChildren > manualRangeParameter.hotel.maxChildren) {
            numChildren = manualRangeParameter.hotel.maxChildren;
        } else if (numChildren < manualRangeParameter.hotel.minChildren) {
            numChildren = manualRangeParameter.hotel.minChildren;
        }

        room.totalChildren = numChildren;

        let diffChildren = room.ages.length - numChildren;
        if (diffChildren > 0) {
            room.ages.splice(numChildren);
        } else if (diffChildren < 0) {
            for (let i = 0; i < (diffChildren * -1); i++)
                room.ages.push(0);
        }

        updateRooms[index] = room;

        setStructureDetail({ ...structureDetail, rooms: updateRooms });
    }

    function onChangeRoomAge(roomIndex, index, event) {
        let updateRooms = [...structureDetail.rooms];
        var room = updateRooms[roomIndex];

        var age = event.target.value ? parseInt(event.target.value) : 0;

        if (age > manualRangeParameter.hotel.maxChildAge) {
            age = manualRangeParameter.hotel.maxChildAge;
        } else if (age < manualRangeParameter.hotel.minChildAge) {
            age = manualRangeParameter.hotel.minChildAge;
        }

        room.ages[index] = age;

        updateRooms[roomIndex] = room;
        setStructureDetail({ ...structureDetail, rooms: updateRooms });
    }

    function loadStructureData() {
        setIsErrorStructureData(false);
        setIsLoadingStructureData(true);

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}structure/getstructureminimaldata/${structureDetail.idStruttura}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                let prevValidateInputData = { ...validateInputData };
                delete prevValidateInputData.isValidName;
                delete prevValidateInputData.isTouchedName;
                delete prevValidateInputData.isValidAddress;
                delete prevValidateInputData.isTouchedAddress;

                let isValidName = false;
                if (item.name)
                    isValidName = true;

                let isValidAddress = false;
                if (item.address)
                    isValidAddress = true;

                let isValidForm = isValidName && isValidAddress && Object.values(prevValidateInputData).every(item => item) && checkIsValidAllRooms(-1);

                setStructureDetail({
                    ...structureDetail,
                    idStruttura: item.idStruttura,
                    name: item.name,
                    address: item.address,
                    imageUrl: item.imageUrl
                });

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidName: isValidName,
                    isTouchedName: true,
                    isValidAddress: isValidAddress,
                    isTouchedAddress: true
                });

                setIsLoadingStructureData(false);
            })
            .catch((err) => {
                console.error(err);
                setIsErrorStructureData(true);
                setIsLoadingStructureData(false);
            });
    }

    function callOnSelectDestination(item) {
        var updateStructureDetail = { ...structureDetail };
        updateStructureDetail.city = { webId: item.Id + "", text: item.Text, geoId: item.GeoId + "" };

        setStructureDetail(updateStructureDetail);
        checkValidateInputData("city", item);
    }

    function saveStructureManual() {
        props.callAddManualQuotationItem(structureDetail);
    }

    return (
        <>
            <h4>Hotel</h4>
            <div className="p-4">
                <div className="row">
                    <div className="form-floating col-md-3">
                        <input type="number"
                            className="form-control"
                            id="floatingInput"
                            placeholder="12345689"
                            onChange={(e) => { onBlurEvent(e, 'idStruttura'); }}
                        />
                        <label htmlFor="floatingInput" className="active">idStruttura</label>
                        <button
                            className="btn btn-sm btn-gray-400"
                            disabled={!structureDetail.idStruttura && !isLoadingStructureData}
                            onClick={loadStructureData}
                        >carica</button>
                        {
                            isLoadingStructureData && <div className="spinner-border" role="status"><span className="sr-only"></span></div>
                        }
                        {
                            isErrorLoadStructureData && !isLoadingStructureData && <div className="text-danger">Struttura non trovata</div>
                        }
                    </div>
                    <div className={"form-floating col-md-3" + (!validateInputData.isValidName ? " insert-danger" : "")} >
                        <input type="text" className="form-control" value={structureDetail.name} id="floatingInput" placeholder="nome visualizzabile hotel" onChange={(e) => { onBlurEvent(e, 'name'); }} />
                        <label htmlFor="floatingInput" className="active">Nome Hotel *</label>
                        {
                            validateInputData.isTouchedName && !validateInputData.isValidName && <div className="text-danger">{t('Login:FieldNameIsMandatory')}</div>
                        }
                    </div>
                    <div className={"form-floating col-md-3" + (!validateInputData.isValidAddress ? " insert-danger" : "")} >
                        <input type="text" className="form-control" value={structureDetail.address} id="floatingInput" placeholder="indirizzo hotel visibile" onChange={(e) => { onBlurEvent(e, 'address'); }} />
                        <label htmlFor="floatingInput" className="active">Indirizzo * (visibile su web)</label>
                        {
                            validateInputData.isTouchedAddress && !validateInputData.isValidAddress && <div className="text-danger">Il campo indirizzo è obbligatorio</div>
                        }
                    </div>
                    <div className={"form-floating col-md-3" + (!validateInputData.isValidCity ? " insert-danger" : "")}>
                        <input type="text"
                            className="form-control"
                            id="floatingInput"
                            value={structureDetail.city && structureDetail.city.text ? structureDetail.city.text : undefined}
                            data-bs-toggle="modal"
                            data-bs-target="#SM_Manual_City"
                            placeholder="Città * (Applica regole)"
                            readOnly />
                        <label htmlFor="floatingInput" className="active">Città * (Applica regole)</label>
                        <InputSearchDestinationModal
                            modalId={"SM_Manual_City"}
                            productType={configData.Settings.Products.Structure.IdTipoProdotto}
                            callOnSelectDestination={callOnSelectDestination}
                            rules={{ enableDestination: true }} />
                        {
                            validateInputData.isTouchedCity && !validateInputData.isValidCity && <div className="text-danger">Il campo città è obbligatorio</div>
                        }
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col-md-3">
                        <div className={"form-floating" + (!validateInputData.isValidProviderId ? " insert-danger" : "")}>
                            <span className="p-float-label form-select-label-m3-react-prime">
                                {
                                    suppliers && suppliers.length > 0 && <Dropdown value={structureDetail.providerId}
                                        onChange={(e) => onBlurEvent(e.value, 'providerId')}
                                        inputId="dd-provider"
                                        filter
                                        className="w-100 form-select-m3-react-prime"
                                        options={suppliers}
                                        optionLabel="label" />
                                }
                                <label htmlFor="dd-provider">Fornitore *</label>
                            </span>
                            {
                                validateInputData.isTouchedProviderId && !validateInputData.isValidProviderId && <div className="text-danger">Il campo fornitore è obbligatorio</div>
                            }
                        </div>
                    </div>
                    <div className="form-floating col-md-3">
                        <input type="text" className="form-control" value={structureDetail.payBy === null ? '' : structureDetail.payBy} id="floatingInput" placeholder="Fornitore per voucher" onChange={(e) => { onBlurEvent(e, 'payBy'); }} />
                        <label htmlFor="floatingInput" className="active">Pay by:</label>
                    </div>
                    <div className="form-floating col-md-3">
                        <input type="text" className="form-control" value={structureDetail.recordLocator === null ? '' : structureDetail.recordLocator} id="floatingInput" placeholder="IdRecordLocator" onChange={(e) => { onBlurEvent(e, 'recordLocator'); }} />
                        <label htmlFor="floatingInput" className="active">Cod prenotazione:</label>
                    </div>
                    <div className="form-floating col-md-3">
                        <input type="text" className="form-control" value={structureDetail.reservationId === null ? '' : structureDetail.reservationId} id="floatingInput" placeholder="IdReservation" onChange={(e) => { onBlurEvent(e, 'reservationId'); }} />
                        <label htmlFor="floatingInput" className="active">Cod prenotazione 2:</label>
                    </div>

                </div>
                <div className="row pt-4">
                    <div className={"form-floating col-md-3" + (!validateInputData.isValidCheckIn ? " insert-danger" : "")}>
                        <input type="date" className="form-control" value={structureDetail.checkIn} id="txt_SM_CheckIn" placeholder="01/01/99" min={today} onChange={onChangeCheckIn} />
                        <label htmlFor="floatingInput" className="active">Data Check-in *</label>
                        {
                            validateInputData.isTouchedCheckIn && !validateInputData.isValidCheckIn && <div className="text-danger">Il campo checkin non è valido</div>
                        }
                    </div>
                    <div className={"form-floating col-md-3" + (!validateInputData.isValidCheckOut ? " insert-danger" : "")}>
                        <input type="date"
                            className="form-control"
                            value={structureDetail.checkOut}
                            id="txt_SM_CheckOut"
                            placeholder="01/01/99"
                            min={validateValueDefault.minCheckOut}
                            onChange={(e) => { onBlurEvent(e, 'checkOut'); }} />
                        <label htmlFor="floatingInput" className="active">Data Check-out *</label>
                        {
                            validateInputData.isTouchedCheckOut && !validateInputData.isValidCheckOut && <div className="text-danger">Il campo checkout non è valido</div>
                        }
                    </div>
                    <div className={"form-floating col-md-3" + (!validateInputData.isValidCancelPolicy ? " insert-danger" : "")}>
                        <input type="date"
                            className="form-control"
                            value={structureDetail.cancelPolicy}
                            id="txt_SM_CancelPolicy"
                            placeholder="01/01/99"
                            max={validateValueDefault.maxCancelPolicy}
                            onChange={(e) => { onBlurEvent(e, 'cancelPolicy'); }} />
                        <label htmlFor="floatingInput" className="active">Inizio penale *</label>
                        {
                            validateInputData.isTouchedCancelPolicy && !validateInputData.isValidCancelPolicy && <div className="text-danger">Il campo cancel policy non è valido</div>
                        }
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="form-floating col-md-12">
                        <input type="text"
                            className="form-control"
                            value={structureDetail.imageUrl}
                            id="floatingInput"
                            placeholder="Url immagine"
                            onChange={(e) => { onBlurEvent(e, 'imageUrl'); }} />
                        <label htmlFor="floatingInput" className="active">Url Immagine</label>
                    </div>
                </div>
                <div>
                    <AddServiceNote
                        serviceNote={structureDetail.serviceNoteDesc}
                        roomIndex={-1}
                        noteTypeToAdd={12}
                        propertyName="serviceNoteDesc"
                        onBlurEvent={onBlurEvent} />
                </div>
                <div className="form-floating pt-2">
                    <textarea className="form-control"
                        placeholder="Note Interne"
                        value={structureDetail.internalNote}
                        onChange={(e) => { onBlurEvent(e, 'internalNote'); }}
                        id="floatingTextarea2"
                        style={{ height: '100px' }} ></textarea>
                    <label htmlFor="floatingTextarea2">Note Interne</label>
                </div>
                {
                    structureDetail.rooms.map((room, key) => {
                        return <div key={key}>
                            <div className="badge-gray-600 mt-4">Camera {(key + 1)}</div>
                            <div className="row pt-4">
                                <div className={"form-floating col-md-5" + (!validateInputData.isValidRoom[key].isValidName ? " insert-danger" : "")}>
                                    <input type="text"
                                        className="form-control" value={room.name} placeholder="nomeCamera" onChange={(e) => { onBlurRoom(key, 'name', e); }} />
                                    <label htmlFor="floatingInput" className="active">Nome camera *</label>
                                    {
                                        validateInputData.isValidRoom[key].isTouchedName && !validateInputData.isValidRoom[key].isValidName && <div className="error-input">{t('Login:FieldNameIsMandatory')}</div>
                                    }
                                </div>
                                <div className="col-md-2 ">
                                    <div className="form-floating">
                                        <select className="form-select" id="floatingSelect" value={room.mealPlanId} onChange={(e) => { onBlurRoom(key, 'mealPlanId', e); }} aria-label="MealPlan">
                                            {
                                                roomTreatments && roomTreatments.map((item, key) => {
                                                    return <option key={key} value={item.value}>{item.label}</option>
                                                })
                                            }
                                        </select>
                                        <label htmlFor="floatingSelect">Tratt.</label>
                                    </div>
                                </div>
                                <div className="form-floating col-md-5">
                                    <input type="text" className="form-control" id="floatingInput" placeholder="nomeCamera" value={room.mealPlanName} onChange={(e) => { onBlurRoom(key, 'mealPlanName', e); }} />
                                    <label htmlFor="floatingInput" className="active">Descrizione Trattamento </label>

                                </div>
                                <div className={"form-floating col-md-2" + (!validateInputData.isValidRoom[key].isValidBuyPriceAmount ? " insert-danger" : "")} >
                                    <input type="number" className="form-control" value={room.buyPriceAmount === null ? '' : room.buyPriceAmount} id="floatingInput" placeholder="123.45" onChange={(e) => { onBlurRoom(key, 'buyPriceAmount', e); }} />
                                    <label htmlFor="floatingInput" className="active">Costo *</label>
                                    {
                                        validateInputData.isValidRoom[key].isTouchedBuyPriceAmount && !validateInputData.isValidRoom[key].isValidBuyPriceAmount && <div className="error-input">Il campo costo è obbligatorio</div>
                                    }
                                </div>
                                <div className="col-md-2">
                                    <div className="form-floating">
                                        <span className="p-float-label form-select-label-m3-react-prime">
                                            {
                                                currencies && currencies.length > 0 && <Dropdown value={room.buyPriceCurrency}
                                                    onChange={(e) => onBlurRoom(key, 'buyPriceCurrency', e.value)}
                                                    inputId="dd-buyPriceCurrency"
                                                    filter
                                                    className="w-100 form-select-m3-react-prime"
                                                    options={currencies}
                                                    optionLabel="label" />
                                            }
                                            <label htmlFor="dd-buyPriceCurrency">Valuta</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="form-floating col-md-2">
                                    <input type="number" className="form-control" id="floatingInput" placeholder="123.45" value={room.buyPriceChange === null ? '' : room.buyPriceChange} onChange={(e) => { onBlurRoom(key, 'buyPriceChange', e); }} />
                                    <label htmlFor="floatingInput" className="active">Tasso Cambio </label>
                                </div>
                                <div className="form-floating col-md-2">
                                    <input type="text" className="form-control" id="floatingInput" placeholder="CityTax" value={room.cityTax === null ? '' : room.cityTax} onChange={(e) => { onBlurRoom(key, 'cityTax', e); }} />
                                    <label htmlFor="floatingInput" className="active">C. Tax (testo)</label>
                                </div>
                            </div>
                            <div className="row pt-2">
                                <div className="form-floating col-2">
                                    <input type="number" className="form-control" id="floatingInput" placeholder="adults" value={room.totalAdults} min={manualRangeParameter.hotel.minRoom} max={manualRangeParameter.hotel.maxAdults} onChange={(e) => { onBlurRoom(key, 'totalAdults', e); }} />
                                    <label htmlFor="floatingInput" className="active">N. Adulti </label>
                                </div>
                                <div className="form-floating col-2">
                                    <input type="number" className="form-control" id="floatingInput" placeholder="children" value={room.totalChildren} min="0" max={manualRangeParameter.hotel.maxChildren} onChange={(e) => { onChangeRoomChildren(key, e); }} />
                                    <label htmlFor="floatingInput" className="active">N. Bambini</label>
                                </div>
                                {
                                    room.ages.length > 0 && room.ages.map((item, key) => {
                                        return <div key={key} className="form-floating col-2">
                                            <input type="number" className="form-control" id="floatingInput" placeholder="children" min={manualRangeParameter.hotel.minChildAge} max={manualRangeParameter.hotel.maxChildAge} value={item} onChange={(e) => { onChangeRoomAge(room.id, key, e); }} />
                                            <label htmlFor="floatingInput" className="active">Età {key + 1}</label>
                                        </div>
                                    })
                                }
                                <div>L'età effettiva al momento della partenza deve corrispondere all'età dichiarata al momento della dell'entrata in struttura. Età bambini 17-0 compiuti</div>
                            </div>

                            <div>
                                <AddServiceNote
                                    serviceNote={room.serviceNote}
                                    roomIndex={key}
                                    onBlurEvent={onBlurRoom} />
                            </div>
                            <div className="pt-4 text-end">
                                {
                                    key === (structureDetail.rooms.length - 1) && structureDetail.rooms.length < manualRangeParameter.hotel.maxRoom && <button className="btn tp-btn-add btn-sm my-2 mr-2" onClick={addRoom}>
                                        <M3Icon iconName="Add" externalClass="h085" /> Camera
                                    </button>
                                }
                                {
                                    structureDetail.rooms.length > 1 && <button className="btn tp-btn-delete btn-sm my-2 border" onClick={e => removeRoom(key)}>
                                        <M3Icon iconName="Remove" externalClass="h085" /> Camera
                                    </button>
                                }
                            </div>
                        </div>
                    })
                }
                <div className=" float-end">
                    {props.isError && <span className="text-danger">Errore in fase di inserimento, controllare i dati</span>}
                    <button className="btn tp-btn-add btn-sm" disabled={!validateInputData.isValidForm} onClick={saveStructureManual}>{t('Button:Save')}</button>
                </div>
            </div>
        </>
    );
}