import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";
import { formatPrice } from '../../../../js/Utils.js';
import { FlightAncillaryOption } from './FlightAncillaryOption.js';


export const FlightAncillaryType = ({ ancillaries, ancillariesTotalPrice, type, flightFare, onChangeAncillary }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    return (
        <>
            <div className="card border-light p-1 mt-2 shadow">
                <div className="w-100 mt-1 pr-4">
                    <div className=" text-center float-start w-90">
                        <label>
                            {type === 'BAGGAGE' && t('Product:Flight:ChooseAncillariesBagaggeSubTitle')}
                            {type === 'BASE' && t('Product:Flight:ChooseAncillariesSubTitle')}
                        </label>
                    </div>
                    <div className="h6 float-end">
                        {formatPrice(ancillariesTotalPrice.amount, ancillariesTotalPrice.currency, cultureName)}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                        <div className="text-center">
                            <h1 className="">
                                <data m3ico="ContentPaste mr-2 h5r">
                                    {type === 'BAGGAGE' && <M3Icon iconName="FlightBagage" externalClass="mr-2 h5r" />}
                                    {type === 'BASE' && <M3Icon iconName="FlightServices" externalClass="mr-2 h5r" />}
                                </data>
                            </h1>
                        </div>
                        <div className="h5">
                            +
                            {type === 'BAGGAGE' && t('Product:Flight:Baggages')}
                            {type === 'BASE' && t('Product:Flight:Services')}
                        </div>
                    </div>
                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                        <FlightAncillaryOption ancillariesMap={ancillaries}
                            flightFare={flightFare}
                            onChangeAncillary={onChangeAncillary} />
                    </div>
                </div>
            </div>
        </>
    );
}