import React from 'react';

export const AvailableFlight = ({ listUrl, quotationId, quotationItemId, deleteFn, changeProductFn, mode }) => {

    function onClickDelete() {
        deleteFn();
    }

    const onClickChangeProduct = () => {
        changeProductFn(listUrl, quotationId, quotationItemId);
    }

    const onClickChangeQuotationItem = () => {
        changeProductFn(quotationItemId);
    }

    return (
        <>
            {
                mode === 'QuotationTO' && <div className="allertError mt-4 p-2">
                    <p>Il prodotto non è più disponibile, seleziona una delle seguenti opzioni:</p>
                    <button className="btn btn-sm btn-soft d-inline-block" onClick={onClickChangeQuotationItem}>Sostituisci Volo</button>
                </div>
            }
            {
                mode === 'CART' &&
                <div className="allertError mt-4 p-2">
                    <p>Il prodotto non è più disponibile</p>
                    {/*<button className="btn btn-sm btn-soft d-inline-block" onClick={onClickChangeProduct}>Sostituisci Volo</button>*/}
                    &nbsp;&nbsp;<button className="btn btn-sm btn-danger" onClick={onClickDelete}>Elimina</button>
                </div>
            }
        </>
    )
}
