import React from 'react';
import { formatDateTime } from '../../../js/Utils.js';
import { getIconUrlToPrintCustom } from '../../../js/PrintUtility.js';
import { IconChoosePrint } from './IconChoosePrint.js';

export const PrintStepHeader = ({ iconName, title, PNR, onHandleIsPrint, isShowPrintButton=true }) => {
    return (
        <div className="rounded-top text-white stepHeader">
            <table className="w-100">
                <tbody>
                    <tr style={{ textAlign: 'left', }}>
                        {isShowPrintButton &&
                            <td style={{ width: '2%', maxWidth: '2%' }}>
                                <IconChoosePrint handleIsPrint={onHandleIsPrint} />
                            </td>
                        }
                        {iconName &&
                            <td style={{ width: '8%', maxWidth: '8%', marginTop: '-10px', marginBottom: '-15px', }}>
                                <img src={getIconUrlToPrintCustom(iconName)} className="ico-image-extra-large" />
                            </td>
                        }
                        <td className="pl-2">
                            <span className="h5 text-white float-start">
                                { title }
                            </span>
                        </td>
                        {PNR &&
                            <td className="col-lg-3 text-white" style={{ textAlign: 'left', borderLeft: '1px solid #FFFFFF', }}>
                                <tr>
                                    <div className="badge-white badge-pnr" style={{padding: '2px', left: '23px', top: '7px' }}>
                                        <h6>PNR: {PNR}</h6>
                                    </div>
                                </tr>
                            </td>
                        }
                    </tr>
                </tbody>
            </table>
        </div>
    );
}