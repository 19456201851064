import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import configData from "../../appsettings.json";
import { useAuth } from '../Auth/AuthProvider';
import { useTranslation } from 'react-i18next';
import { handleError, formatDateTime, formatPrice, getCurrentUserFromJwtToken, GetModulesRules } from "../../js/Utils";
import { registerSessionQuotation, createQuotationInfoSession } from '../../js/QuotationUtils';
import { Loading } from '../Common/Loading';
import { Error } from '../Common/Error';
import { QuotationHeader } from './components/QuotationHeader';
import { QuotationInternalNotes } from './components/QuotationInternalNotes';
import { QuotationLogs } from './components/QuotationLogs';
import { QuotationTableMargin } from './components/QuotationTableMargin';
import { QuotationRecapItem } from './components/QuotationRecapItem';
import { QuotationSteps } from './components/QuotationSteps';
import { QuotationChats } from './components/QuotationChats';
import { QuotationSelectAgency } from './components/QuotationSelectAgency';
import { QuotationPaxes } from './components/QuotationPaxes';
import { QuotationBaseButton } from './components/QuotationBaseButton';
import { CartScadenziario } from '../Cart/CartScadenziario';
import { StructureManual } from '../ManualProduct/StructureManual';
import { ActivityManual } from '../ManualProduct/ActivityManual';
import { TourManual } from '../ManualProduct/TourManual';
import { CarManual } from '../ManualProduct/CarManual';
import { TransferManual } from '../ManualProduct/TransferManual';
import { CruiseManual } from '../ManualProduct/CruiseManual';
import { FlightManual } from '../ManualProduct/FlightManual';
import { GenericManual } from '../ManualProduct/GenericManual';
import { isQuotationPaxesFilled } from "./service/QuotationService";
import { M3IconProductType } from '../Common/M3IconProductType';
import { M3Icon } from '../Common/M3Icon';
import { QuotationMarkupRule } from './components/QuotationMarkupRules.js';

export const QuotationTO = () => {
    const { t } = useTranslation();
    let { quotationId } = useParams();
    const { onChangeModuleSelected, getDefaultModule, setQuotationInfoSession } = useAuth();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const pdfFileName = "Preventivo" + quotationId;

    const [rules, setRules] = useState(null);

    const [userIdWorkNow, setUserIdWorkNow] = useState(null);
    const [actualUserInfo, setActualUserInfo] = useState({});
    const [customerInfoQuotation, setCustomerInfoQuotation] = useState({});
    const [quotation, setQuotation] = useState(null);
    const [quotationHeader, setQuotationHeader] = useState(null);
    const [quotationTableMarginItems, setQuotationTableMarginItems] = useState([]);
    const [quotationInternalNotes, setQuotationInternalNotes] = useState([]);
    const [quotationRecapItems, setQuotationRecapItems] = useState([]);
    const [quotationMarkupRules, setQuotationMarkupRules] = useState(null);
    const [quotationItemStep, setQuotationItemStep] = useState([]);
    const [totalInternalNotes, setTotalInternalNotes] = useState(0);
    const [limitQuotationNote, setLimitQuotationNote] = useState(5);
    const [quotationItemStepPrint, setQuotationItemStepPrint] = useState([]);
    const [quotationMode, setQuotationMode] = useState([]);
    const [quotationAllowedOperation, setQuotationAllowedOperation] = useState(null);

    const [quotationLogs, setQuotationLogs] = useState([]);
    const [limitQuotationLogs, setLimitQuotationLogs] = useState(10);
    const [totalLogs, setTotalLogs] = useState(0);

    const [quotationChats, setQuotationChats] = useState([]);
    const [limitQuotationChats, setLimitQuotationChats] = useState(5);
    const [totalChats, setTotalChats] = useState(0);

    const [quotationPaxes, setQuotationPaxes] = useState([]);
    const [isAllQuotationPaxesFilled, setIsAllQuotationPaxesFilled] = useState(false);
    const [minProductDate, setMinProductDate] = useState(null);
    const [isLoadingSaveAllPaxes, setIsLoadingSaveAllPaxes] = useState(true);

    const [isTabSelectedNote, setIsTabSelectedNote] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadAfterPage, setIsLoadAfterPage] = useState(false);
    const [haveSaveQuotationInfo, setHaveSaveQuotationInfo] = useState(false);
    const [isError, setIsError] = useState(false);
    const [tipoProdotto, setTipoProdotto] = useState([]);
    const [websiteId, setWebsiteId] = useState();

    let oldArrTipoProdotto = [...tipoProdotto];

    const [quotationSettings, setQuotationSettings] = useState({
        baseUrl: configData.Settings.Base_Url,
        minPercTotaleMargineLordo: configData.Settings.QuotationTO.MinPercTotaleMargineLordo,
        maxPercTotaleMargineLordo: configData.Settings.QuotationTO.MaxPercTotaleMargineLordo,
        groupedAddictionalProductADV: false,
        excludeRicavoTMFromMargineSede: configData.Settings.QuotationTO.ExcludeRicavoTMFromMargineSede,
        calcoloTMType: configData.Settings.QuotationTO.CalculateMarginTM,
        isDeleted: false,
        isReplaced: false,
        isAdmin: true
    });

    const [isOpenAddManualProduct, setIsOpenAddManualProduct] = useState({
        hotel: false,
        activity: false,
        tour: false,
        car: false,
        transfer: false,
        cruise: false,
        flight: false,
        generic: false
    });
    const [isErrorAddManualProduct, setIsErrorAddManualProduct] = useState({
        hotel: false,
        activity: false,
        tour: false,
        car: false,
        transfer: false,
        cruise: false,
        flight: false,
        generic: false
    });
    const [manualProductToUpdate, setManualProductToUpdate] = useState(null);

    const [quotationItemsAddToCart, setQuotationItemsAddToCart] = useState([]);
    const [addToCartStep, setAddToCartStep] = useState(0);
    const [addToCartUserId, setAddToCartUserId] = useState('');
    const [isLoadingItemsAddToCart, setIsLoadingItemsAddToCart] = useState(false);
    const [sendMailMsg, setSendMailMsg] = useState("");
    const [sendMailAuthMsg, setSendMailAuthMsg] = useState("");
    const [scadenziario, setScadenziario] = useState(null);

    const [dynamicNotes, setDynamicNotes] = useState([]);

    useEffect(() => {
        callGetQuotationDetail();
        let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
        let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);
        setWebsiteId(currentUser.websiteId);

        const callGetModuleRules = async () => {
            const moduleRules = await GetModulesRules();
            setRules(moduleRules);
        };
        callGetModuleRules();
    }, []);

    useEffect(() => {
        if (isLoadAfterPage) {
            setAddListenerManualProduct();
            callGetInternalNote(limitQuotationNote);
            callGetChats(limitQuotationChats);
            callGetScadenziario();
            callGetCustomerInfoQuotation();
            callGetPaxes();
        }

    }, [isLoadAfterPage]);

    useEffect(() => {

        if (!haveSaveQuotationInfo) {

            const callSaveSessionQuotation = async () => {
                let response = await registerSessionQuotation(parseInt(quotationId), null);
                if (response) {
                    setQuotationInfoSession(response.quotationInfo);
                } else {
                    console.error("ERROR: register Quotation");
                }
            }

            callSaveSessionQuotation();
        }

    }, [haveSaveQuotationInfo])

    async function callGetDynamicNote(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'dynamicnote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();

        if (response.dynamicNotes != null && response.dynamicNotes.length > 0) {
            setDynamicNotes(arr => [...arr, response.dynamicNotes]);
        }
    }

    function openPopupLoadUpdate() {
        let myBtn = document.getElementById("openQuotationTO_LoadingAfterUpdate");
        myBtn.click();
    }

    function closePopupLoadUpdate() {
        setTimeout(() => {
            let myBtn = document.getElementById("closeQuotationTO_LoadingAfterUpdate");
            myBtn.click();
        }, 1000);
    }

    function callGetQuotationDetail() {
        setIsLoading(true);

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: -1,
                quotationSettings: quotationSettings
            })

        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationdetail`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotation(item);

                setActualUserInfo({
                    username: item.userQuotation,
                    userId: item.userQuotationId,
                    agencyName: item.agencyName,
                    agencyId: item.agencyId
                });

                setUserIdWorkNow(item.userIdWorkNow);

                setQuotationHeader(
                    {
                        name: item.name,
                        ticketId: item.ticketId,
                        quotationId: item.quotationId,
                        agencyName: item.agencyName,
                        createDate: item.createDate,
                        tablePricing: item.tablePricing,
                        commentsTotal: item.chatTotal,
                        commentsToReadTotal: item.chatTotalToRead,
                        internalNotesTotal: item.totalInternalNotes,
                        status: item.status,
                        numeroOrdine: item.numeroOrdine,
                        isAgencyVisible: item.isAgencyVisible,
                        authorizationToSend: item.authorizationToSend,
                        authorizationStatus: item.authorizationStatus,
                        canAuthorization: item.canAuthorization,
                        isCommissionable: item.tablePricing.isCommissionable
                    }
                );
                setQuotationMode(item.quotationMode);
                setQuotationAllowedOperation(item.allowedOperations);

                setTotalInternalNotes(item.totalInternalNotes);
                setTotalLogs(item.totalLogs);
                setTotalChats(item.chatTotal);

                setQuotationRecapItems(item.recapItems);
                setQuotationMarkupRules(item.quotationMarkupRules);

                setQuotationTableMarginItems({
                    hasIVACee: item.tablePricing.hasIVACee,
                    tableMargin: item.tablePricing.tableMargin,
                    totalCommissionAmount: item.tablePricing.totalCommission,
                    totalCommissionable: item.tablePricing.totalCommissionable,
                    totalCommissionCurrency: item.tablePricing.currency,
                    totalNotCommissionableAmount: item.tablePricing.totalNotCommissionable,
                    totalNotCommissionableCurrency: item.tablePricing.currency
                });

                setQuotationItemStep(item.quotationItemStep);

                if (item.recapItems && item.recapItems.length > 0) {
                    var myDates = item.recapItems.filter(r => r.productType !== 'Generic').map(function (myRecapItem) { return new Date(myRecapItem.dateFrom) });
                    var minDate = new Date(Math.min.apply(null, myDates))
                    setMinProductDate(minDate);
                }

                setIsError(false);
                setIsLoadAfterPage(true);

                setIsLoading(false);

                setHaveSaveQuotationInfo(true);
            })
            .catch((err) => {
                console.error(err);
                setQuotation(null);
                setIsError(true);
                setIsLoading(false);
            });

    }

    function setAddListenerManualProduct() {

        let formHotel = document.getElementById('formHotel');
        formHotel.addEventListener('shown.bs.collapse', function () {
            setIsOpenAddManualProduct({ ...isOpenAddManualProduct, hotel: true });
            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, hotel: false });
        });

        formHotel.addEventListener('hidden.bs.collapse', function () {
            setIsOpenAddManualProduct({ ...isOpenAddManualProduct, hotel: false });
            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, hotel: false });
            setManualProductToUpdate(null);
        });

        let formActivity = document.getElementById('formActivity');
        formActivity.addEventListener('shown.bs.collapse', function () {
            setIsOpenAddManualProduct({ ...isOpenAddManualProduct, activity: true });
            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, activity: false });
        });

        formActivity.addEventListener('hidden.bs.collapse', function () {
            setIsOpenAddManualProduct({ ...isOpenAddManualProduct, activity: false });
            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, activity: false });
            setManualProductToUpdate(null);
        });

        let formTour = document.getElementById('formTour');
        formTour.addEventListener('shown.bs.collapse', function () {
            setIsOpenAddManualProduct({ ...isOpenAddManualProduct, tour: true });
            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, tour: false });
        });

        formTour.addEventListener('hidden.bs.collapse', function () {
            setIsOpenAddManualProduct({ ...isOpenAddManualProduct, tour: false });
            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, tour: false });
            setManualProductToUpdate(null);
        });

        let formCar = document.getElementById('formCar');
        formCar.addEventListener('shown.bs.collapse', function () {
            setIsOpenAddManualProduct({ ...isOpenAddManualProduct, car: true });
            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, car: false });
        });

        formCar.addEventListener('hidden.bs.collapse', function () {
            setIsOpenAddManualProduct({ ...isOpenAddManualProduct, car: false });
            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, car: false });
            setManualProductToUpdate(null);
        });

        let formTransfer = document.getElementById('formTransfer');
        formTransfer.addEventListener('shown.bs.collapse', function () {
            setIsOpenAddManualProduct({ ...isOpenAddManualProduct, transfer: true });
            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, transfer: false });
        });

        formTransfer.addEventListener('hidden.bs.collapse', function () {
            setIsOpenAddManualProduct({ ...isOpenAddManualProduct, transfer: false });
            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, transfer: false });
            setManualProductToUpdate(null);
        });

        let formCruise = document.getElementById('formCruise');
        formCruise.addEventListener('shown.bs.collapse', function () {
            setIsOpenAddManualProduct({ ...isOpenAddManualProduct, cruise: true });
            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, cruise: false });
        });

        formCruise.addEventListener('hidden.bs.collapse', function () {
            setIsOpenAddManualProduct({ ...isOpenAddManualProduct, cruise: false });
            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, cruise: false });
            setManualProductToUpdate(null);
        });

        let formFlight = document.getElementById('formFlight');
        formFlight.addEventListener('shown.bs.collapse', function () {
            setIsOpenAddManualProduct({ ...isOpenAddManualProduct, flight: true });
            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, flight: false });
        });

        formFlight.addEventListener('hidden.bs.collapse', function () {
            setIsOpenAddManualProduct({ ...isOpenAddManualProduct, flight: false });
            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, flight: false });
            setManualProductToUpdate(null);
        });

        let formGeneric = document.getElementById('formGeneric');
        formGeneric.addEventListener('shown.bs.collapse', function () {
            setIsOpenAddManualProduct({ ...isOpenAddManualProduct, generic: true });
            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, generic: false });
        });

        formGeneric.addEventListener('hidden.bs.collapse', function () {
            setIsOpenAddManualProduct({ ...isOpenAddManualProduct, generic: false });
            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, generic: false });
            setManualProductToUpdate(null);
        });
    }

    function callAddInternalNote(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                text: inputData,
                limit: limitQuotationNote
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/AddQuotationNote`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationInternalNotes(item);

                let totalNotes = quotationInternalNotes.length + 1;
                setTotalInternalNotes(totalNotes);
                setQuotationHeader({ ...quotationHeader, internalNotesTotal: totalNotes });
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
            });
    }

    function callGetInternalNote(limit = -1) {
        setLimitQuotationNote(limit);

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationnotes/${quotationId}/${limit}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationInternalNotes(item);
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
            });
    }

    function callGetLogs(limit = -1) {
        setLimitQuotationLogs(limit);

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationlogs/${quotationId}/${limit}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationLogs(item);
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
            });
    }

    function changeTabSelectedNote(selectedId) {
        if (selectedId === 1) {
            callGetInternalNote(limitQuotationNote);
        }

        if (selectedId === 2) {
            callGetLogs(limitQuotationLogs);
        }

        setIsTabSelectedNote(selectedId);
    }

    function callOnChangeQuotationStatus(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                status: inputData.status,
                logNote: inputData.noteStatusToAdd
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/updatequotationstatus`, requestOption)
            .then((res) => {
                setQuotationHeader({ ...quotationHeader, status: inputData.status });
            })
            .catch((err) => {
                console.error(err);
                setQuotationHeader({ ...quotationHeader, status: quotationHeader.status });
                setIsError(true);
            });
    }

    function callSaveChangeMarkup(inputData) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                items: inputData
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/updatequotationitemsmarkup`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
                closePopupLoadUpdate();
            });
    }

    function callSaveChangeCommissionable(inputData) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                items: inputData
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/updatequotationitemscommissionable`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
                closePopupLoadUpdate();
            });
    }

    function callResetMarkupValue() {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/ResetQuotationItemsCommissionable`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
                closePopupLoadUpdate();
            });
    }

    function callSaveChangeIsCommissionable(inputData) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                items: inputData
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/updatequotationitemsiscommissionable`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
                closePopupLoadUpdate();
            });
    }

    function callSaveBuyPrice(inputData) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                items: inputData
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/updatequotationitemsbuyprice`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
                closePopupLoadUpdate();
            });
    }

    function callSaveChangeAPIsSelected(inputData) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                items: inputData
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/updatequotationaddictionalproductsisselected`, requestOption)
            .then(res => handleError(res))
            .then(item => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                closePopupLoadUpdate();
                console.error(err);
                setIsError(true);
            });
    }

    function callAddQuotationItemManual(inputData) {
        inputData.quotationId = parseInt(quotationId);
        inputData.userIdWorkNow = parseInt(userIdWorkNow);
        inputData.quotationSettings = quotationSettings;

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        let apiMethod = '';
        switch (inputData.productType) {
            case configData.Settings.Products.Structure.IdTipoProdotto:
                apiMethod = 'addquotationstructuremanual';
                break;

            case configData.Settings.Products.Activity.IdTipoProdotto:

                if (inputData.productSubType !== 12) {
                    apiMethod = 'addquotationactivitymanual';
                } else {
                    apiMethod = 'addquotationtourmanual';
                }

                break;

            case configData.Settings.Products.CarRental.IdTipoProdotto:
                apiMethod = 'addquotationcarrentalmanual';
                break;

            case configData.Settings.Products.Transfer.IdTipoProdotto:
                apiMethod = 'addquotationtransfermanual';
                break;

            case configData.Settings.Products.Cruise.IdTipoProdotto:
                apiMethod = 'addquotationcruisemanual';
                break;

            case configData.Settings.Products.Flight.IdTipoProdotto:
                apiMethod = 'addquotationflightmanual';
                break;

            case configData.Settings.Products.Generic.IdTipoProdotto:
                apiMethod = 'addquotationgenericmanual';
                break;

            default:
                break;
        }

        openPopupLoadUpdate();

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/${apiMethod}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                switch (inputData.productType) {
                    case configData.Settings.Products.Structure.IdTipoProdotto:
                        apiMethod = 'addquotationstructuremanual';
                        setAddManualProductAccordion('Structure', false);
                        break;

                    case configData.Settings.Products.Activity.IdTipoProdotto:

                        if (inputData.productSubType !== 12) {
                            apiMethod = 'addquotationactivitymanual';
                            setAddManualProductAccordion('Activity', false);
                        } else {
                            apiMethod = 'addquotationtourmanual';
                            setAddManualProductAccordion('Tour', false);
                        }

                        break;

                    case configData.Settings.Products.CarRental.IdTipoProdotto:
                        apiMethod = 'addquotationcarrentalmanual';
                        setAddManualProductAccordion('CarRental', false);
                        break;

                    case configData.Settings.Products.Transfer.IdTipoProdotto:
                        apiMethod = 'addquotationtransfermanual';
                        setAddManualProductAccordion('Transfer', false);
                        break;

                    case configData.Settings.Products.Cruise.IdTipoProdotto:
                        apiMethod = 'addquotationcruisemanual';
                        setAddManualProductAccordion('Cruise', false);
                        break;

                    case configData.Settings.Products.Flight.IdTipoProdotto:
                        apiMethod = 'addquotationflightmanual';
                        setAddManualProductAccordion('Flight', false);
                        break;

                    case configData.Settings.Products.Generic.IdTipoProdotto:
                        apiMethod = 'addquotationgenericmanual';
                        setAddManualProductAccordion('Generic', false);
                        break;

                    default:
                        break;
                }

                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);

                switch (inputData.productType) {
                    case configData.Settings.Products.Structure.IdTipoProdotto:
                        setIsErrorAddManualProduct({ ...isErrorAddManualProduct, hotel: true });
                        break;

                    case configData.Settings.Products.Activity.IdTipoProdotto:

                        if (inputData.productSubType !== 12) {
                            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, activity: true });
                        } else {
                            setIsErrorAddManualProduct({ ...isErrorAddManualProduct, tour: true });
                        }

                        break;

                    case configData.Settings.Products.CarRental.IdTipoProdotto:
                        setIsErrorAddManualProduct({ ...isErrorAddManualProduct, car: true });
                        break;

                    case configData.Settings.Products.Transfer.IdTipoProdotto:
                        setIsErrorAddManualProduct({ ...isErrorAddManualProduct, transfer: true });
                        break;

                    case configData.Settings.Products.Cruise.IdTipoProdotto:
                        setIsErrorAddManualProduct({ ...isErrorAddManualProduct, cruise: true });
                        break;

                    case configData.Settings.Products.Flight.IdTipoProdotto:
                        setIsErrorAddManualProduct({ ...isErrorAddManualProduct, flight: true });
                        break;

                    case configData.Settings.Products.Generic.IdTipoProdotto:
                        setIsErrorAddManualProduct({ ...isErrorAddManualProduct, generic: true });
                        break;

                    default:
                        break;
                }

                closePopupLoadUpdate();
            });
    }

    function callDeleteManualQuotationItem(genericDetail) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: -1,
                quotationSettings: quotationSettings
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/DeleteQuotationAddictionalProductManual/${genericDetail.idProdotto}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setManualProductToUpdate(item);

                setAddManualProductAccordion('Generic', true);
                document.querySelector("#pnlUpdateManualProductItem").scrollIntoView();
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function callOpenUpdateManualProduct(pType, qId, pSubType) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        let apiMethod = `getquotationitemmanual/${qId}`;
        if (pType === 'Generic' || pType === 99)
            apiMethod = `GetQuotationAddictionalProductManual/${quotationId}/${qId}`;

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/${apiMethod}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setManualProductToUpdate(item);

                if (pType === 'Activity' && pSubType === 'Tour')
                    pType = 'Tour';

                setAddManualProductAccordion(pType, true);
                document.querySelector("#pnlUpdateManualProductItem").scrollIntoView();
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function setAddManualProductAccordion(pType, open) {
        let formProduct = undefined;
        let lnkFormProduct = undefined;
        switch (pType) {
            case 'Activity': {
                formProduct = document.getElementById('formActivity');
                lnkFormProduct = document.getElementById('lnkFormActivity');
                break;
            }
            case 'CarRental': {
                formProduct = document.getElementById('formCar');
                lnkFormProduct = document.getElementById('lnkFormCar');
                break;
            }
            case 'Cruise': {
                formProduct = document.getElementById('formCruise');
                lnkFormProduct = document.getElementById('lnkFormCruise');
                break;
            }
            case 'Flight': {
                formProduct = document.getElementById('formFlight');
                lnkFormProduct = document.getElementById('lnkFormFlight');
                break;
            }
            case 'Structure': {
                formProduct = document.getElementById('formHotel');
                lnkFormProduct = document.getElementById('lnkFormHotel');
                break;
            }
            case 'Tour': {
                formProduct = document.getElementById('formTour');
                lnkFormProduct = document.getElementById('lnkFormTour');
                break;
            }
            case 'Transfer': {
                formProduct = document.getElementById('formTransfer');
                lnkFormProduct = document.getElementById('lnkFormTransfer');
                break;
            }
            case 'Generic': {
                formProduct = document.getElementById('formGeneric');
                lnkFormProduct = document.getElementById('lnkFormGeneric');
                break;
            }
        }

        if (formProduct && open && !formProduct.classList.contains('show')) {
            lnkFormProduct.click();
        }

        if (formProduct && !open && formProduct.classList.contains('show')) {
            lnkFormProduct.click();
        }
    }

    function callOnSaveChangeStep(items) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                items: items
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/updatestep`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }

    function callOnSaveStepInfo(items) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                items: items
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/updatestepinfo`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }

    function callOnAutoGenerateStep() {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                autoGenerateStepGroupBy: configData.Settings.QuotationTO.AutoGenerateStepGroupBy
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/autogeneratestep`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }

    function callOnSaveChangeAlternative(items) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                items: items
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/updatealternative`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }

    function callOnSaveSelectAlternative(item) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                oldSelectedAlternativeId: item.oldSelectedAlternativeId,
                selectedAlternativeId: item.selectedAlternativeId
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/selectalternative`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }

    function callOnCallRequoteQuotationItem(quotationItemId) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getisavailable/${quotationItemId}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                let oldQuotationItemStep = [...quotationItemStep];

                for (let i = 0; i < oldQuotationItemStep.length; i++) {
                    let myStep = oldQuotationItemStep[i];

                    if (myStep.quotationItemInfo) {
                        let indexToUpdate = myStep.quotationItemInfo.findIndex((qItem) => qItem.id === item.quotationItemId);

                        if (indexToUpdate > -1) {
                            myStep.quotationItemInfo[indexToUpdate].isAvailable = item.isAvailable;
                            myStep.forceUpdate = Math.random() * 10000;
                        }
                    }
                }

                setQuotationItemStep(oldQuotationItemStep);
                closePopupLoadUpdate();
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }

    function onCallRequoteAll() {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getallitemareavailable/${quotationId}`, requestOption)
            .then((res) => handleError(res))
            .then((items) => {
                let oldQuotationItemStep = [...quotationItemStep];

                for (let i = 0; i < oldQuotationItemStep.length; i++) {
                    let myStep = oldQuotationItemStep[i];

                    if (myStep.quotationItemInfo) {
                        for (let j = 0; j < myStep.quotationItemInfo.length; j++) {
                            let myItemInfo = myStep.quotationItemInfo[j];
                            let indexToUpdate = items.findIndex((qItem) => qItem.quotationItemId === myItemInfo.id);

                            if (indexToUpdate > -1) {
                                myItemInfo.isAvailable = items[indexToUpdate].isAvailable;
                                myItemInfo.forceUpdate = Math.random() * 10000;
                            }
                        }


                    }
                }

                setQuotationItemStep(oldQuotationItemStep);
                closePopupLoadUpdate();
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }

    function onSaveProductData(inputData) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,

                quotationItemId: inputData.quotationItemId,

                activityData: inputData.activityData,
                carData: inputData.carData,
                cruiseData: inputData.cruiseData,
                flightData: inputData.flightData,
                structureData: inputData.structureData,
                tourData: inputData.tourData,
                transferData: inputData.transferData,
                trainData: inputData.trainData
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/UpdateQuotationItemStaticData`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }


    async function callOnCallUpdateQuotationItem(id) {

        let response = await registerSessionQuotation(parseInt(quotationId), id);
        if (response) {
            setQuotationInfoSession(response.quotationInfo);

            let redirectUrl = response.url
            if (response.idProdotto && response.productType.toString() === configData.Settings.Products.Activity.IdTipoProdotto) {
                redirectUrl = 'Activity/ActivityDetailStatic/?aId=' + response.idProdotto;
            } else if (response.idProdotto && response.productType.toString() === configData.Settings.Products.Structure.IdTipoProdotto) {
                redirectUrl = 'Hotel/HotelDetailStatic/?sId=' + response.idProdotto;
            }

            window.location.href = redirectUrl;
        }

    }

    function callOnChangeStatusQuotationItem(id, status) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                quotationItemId: id,
                status: status
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/updatequotationitemstatus`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }

    function callConfirmAll() {

        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/confirmallquotation`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });

    }

    function callOnCallSaveProductInfo(response, type, id) {
        let isFound = false;

        let quotationItemStepToPrint = [...quotationItemStepPrint];
        if (quotationItemStepPrint && quotationItemStepPrint.length === 0)
            quotationItemStepToPrint = [...quotationItemStep];

        for (let i = 0; i < quotationItemStepToPrint.length; i++) {
            let itemStep = quotationItemStepToPrint[i];
            if (itemStep) {
                for (let j = 0; j < itemStep.quotationItemInfo.length; j++) {
                    let qInfo = itemStep.quotationItemInfo[j];

                    if (qInfo.wsBookingId && type === 'QuotationTO_MyBook') {
                        if (qInfo.wsBookingId === id) {
                            qInfo.productInfo = response;
                            isFound = true;
                            callDynamicNotes(qInfo);
                            break;
                        }
                    }

                    if (qInfo.id && type === 'QuotationTO') {
                        if (qInfo.id === id) {
                            qInfo.productInfo = response;
                            isFound = true;
                            callDynamicNotes(qInfo);
                            break;
                        }
                    }
                }
            }

            if (isFound)
                break;
        }

        setQuotationItemStepPrint(quotationItemStepToPrint);
    }

    function callDynamicNotes(qInfo) {
        let idTipoProdotto = 99;
        if (qInfo.productType === "Activity") {
            idTipoProdotto = configData.Settings.Products.Activity.IdTipoProdotto;
        }
        if (qInfo.productType === "Flight") {
            idTipoProdotto = configData.Settings.Products.Flight.IdTipoProdotto;
        }
        if (qInfo.productType === "Structure") {
            idTipoProdotto = configData.Settings.Products.Structure.IdTipoProdotto;
        }
        if (qInfo.productType === "Tour") {
            idTipoProdotto = configData.Settings.Products.Tour.IdTipoProdotto;
        }
        if (qInfo.productType === "Transfer") {
            idTipoProdotto = configData.Settings.Products.Transfer.IdTipoProdotto;
        }
        if (qInfo.productType === "CarRental") {
            idTipoProdotto = configData.Settings.Products.CarRental.IdTipoProdotto;
        }

        const getDynamicNote = async (inputData) => {
            const response = callGetDynamicNote(inputData);
        }

        getDynamicNote({
            cultureCode: cultureName,
            websiteId: websiteId,
            pageId: 14,
            productTypes: [idTipoProdotto],
            productCode: qInfo.productInfo ? qInfo.productInfo.productId : null,
            checkInFrom: qInfo.productInfo.priceBar ? qInfo.productInfo.priceBar.startDate : null,
            checkInTo: qInfo.productInfo.priceBar ? qInfo.productInfo.priceBar.endDate : null,
            webDestinationIds: qInfo.productInfo.webDestinationIds ? qInfo.productInfo.webDestinationIds : null,
            supplierId: qInfo.productInfo.supplierId ? qInfo.productInfo.supplierId : null,
        });
    }

    function callChangeIsVisibleAgency(checked) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                isVisibleAgency: checked
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/updateisvisibleagency`, requestOption)
            .then((res) => handleError(res))
            .then(() => {
                setQuotationHeader({ ...quotationHeader, isAgencyVisible: checked });
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const callOnSelectedAddToCart = useCallback(() => {
        setAddToCartStep(0);
        setIsLoadingItemsAddToCart(true);

        let myBtn = document.getElementById("openQuotationTO_SelectItemAddToCart");
        myBtn.click();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/selectaddtocart/${quotationId}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationItemsAddToCart(item);
                setIsLoadingItemsAddToCart(false);
            })
            .catch((err) => {
                console.error(err);
                setIsLoadingItemsAddToCart(false);
            });
    }, []);

    function onCallSelectAgency(item) {
        setAddToCartStep(1);
        setAddToCartUserId(item.username);
    }

    function callOnAddToCart() {
        setIsLoadingItemsAddToCart(true);

        let itemsAddToCart = [];
        quotationItemsAddToCart.forEach((item) => {
            item.quotationItemInfo.forEach((qInfo => {
                if (qInfo.isSelected)
                    itemsAddToCart.push({
                        id: qInfo.id,
                        productType: qInfo.productType,
                        idsProdCorrelato: qInfo.idsProdCorrelato ? qInfo.idsProdCorrelato : []
                    });
            }));
        });

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                items: itemsAddToCart,
                userIdAddToCart: addToCartUserId
            })
        };
        setIsLoadingItemsAddToCart(true);
        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/addtocart`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                if (!item.stkn) {
                    window.location.href = `${configData.Settings.Base_Url}cart-quotation`;
                } else {
                    fetch(configData.Settings.CommonApi_BaseUrl + 'auth/logout', { credentials: 'include' })
                        .then(data => {
                            window.location.href = `${configData.Settings.Base_Url}?sTkn=${item.stkn}&ru=${encodeURIComponent(configData.Settings.Base_Url)}cart-quotation`;
                        });
                }
            })
            .catch((err) => {
                console.error(err);
                setIsLoadingItemsAddToCart(false);
            });
    }

    function callOnSendMailAgency(item) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                ticketId: quotationHeader.ticketId,
                fromMail: item.fromMail,
                toMail: item.toMail,
                body: item.body,
                defaultFromMail: item.defaultFromMail,
                canAutoLogin: item.canAutoLogin,
                endUserId: item.endUserId
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/sendmailagency`, requestOption)
            .then((res) => handleError(res))
            .then(() => {
                setQuotationHeader({ ...quotationHeader, status: 1 });
                setSendMailMsg("Email inviata con successo");
            })
            .catch((err) => {
                console.error(err);
                setSendMailMsg("Non è stato possibile inviare la mail");
            });
    }

    function callSendRequestAuthorization() {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                ticketId: quotationHeader.ticketId
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/sendrequesttosendmail`, requestOption)
            .then((res) => handleError(res))
            .then(() => {
                setSendMailAuthMsg("Email inviata con successo");
            })
            .catch((err) => {
                console.error(err);
                setSendMailAuthMsg("Non è stato possibile inviare la mail");
            });
    }

    function callSendAuthorization(isAuth) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                ticketId: quotationHeader.ticketId,
                isAuthToSend: isAuth
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/changestatusrequesttosendmail`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                let mailMsgResult = "";
                if (item.status === 'Accepted') {
                    mailMsgResult = "La mail di autorizzazione di invio del preventivo è stata inviata";
                } else if (item.status === 'Reject') {
                    mailMsgResult = "La mail di NON autorizzazione di invio del preventivo è stata inviata";
                }

                setSendMailAuthMsg(mailMsgResult);
            })
            .catch((err) => {
                console.error(err);

                let mailMsgResult = "";
                if (isAuth) {
                    mailMsgResult = "La mail di autorizzazione di invio del preventivo non è stata inviata";
                } else {
                    mailMsgResult = "La mail di NON autorizzazione di invio del preventivo non è stata inviata";
                }

                setSendMailAuthMsg(mailMsgResult);
            });
    }

    function updateQuotationAfterAPI(item) {
        setQuotationHeader({
            ...quotationHeader,
            status: item.status,
            tablePricing: item.tablePricing,
            authorizationToSend: item.authorizationToSend,
            authorizationStatus: item.authorizationStatus,
            canAuthorization: item.canAuthorization,
            isCommissionable: item.tablePricing.isCommissionable
        });
        setQuotationRecapItems(item.recapItems);
        setQuotationTableMarginItems({
            hasIVACee: item.tablePricing.hasIVACee,
            tableMargin: item.tablePricing.tableMargin,
            totalCommissionAmount: item.tablePricing.totalCommission,
            totalCommissionable: item.tablePricing.totalCommissionable,
            totalCommissionCurrency: item.tablePricing.currency,
            totalNotCommissionableAmount: item.tablePricing.totalNotCommissionable,
            totalNotCommissionableCurrency: item.tablePricing.currency
        });

        setQuotationItemStep(item.quotationItemStep);

        if (item.scadenziario)
            setScadenziario(item.scadenziario);

        closePopupLoadUpdate();
    }

    function onCheckedAddToCart(event, index, indexItem) {
        let updateItems = [...quotationItemsAddToCart];
        updateItems[index].quotationItemInfo[indexItem].isSelected = event.target.checked;

        setQuotationItemsAddToCart(updateItems);
    }

    function callGetChats(limit = -1) {
        setLimitQuotationChats(limit);

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationchat/${quotationId}/${limit}/${userIdWorkNow}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationChats(item);
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
            });
    }

    function callAddChat(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                text: inputData,
                limit: limitQuotationChats
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/AddQuotationChat`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationChats(item);

                let totalChats = quotationChats.length + 1;
                setTotalChats(totalChats);
                setQuotationHeader({ ...quotationHeader, commentsTotal: totalChats });
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
            });
    }

    function callAddChatAnswer(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                chatId: inputData.chatId,
                text: inputData.text,
                limit: limitQuotationChats
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/AddQuotationChatAnswer`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationChats(item);

                let totalChats = quotationChats.length + 1;
                setTotalChats(totalChats);
                setQuotationHeader({ ...quotationHeader, commentsTotal: totalChats });
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
            });
    }

    function callReadChat(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                chatId: inputData,
                limit: limitQuotationChats
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/UpdateQuotationChatRead`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationChats(item);

                let totalChats = quotationChats.length + 1;
                setTotalChats(totalChats);
                setQuotationHeader({ ...quotationHeader, commentsTotal: totalChats });
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
            });
    }

    function onCallChangeShowHistory(checked, whoSet) {
        let newQuotationSettings = { ...quotationSettings };
        if (whoSet === 'D') {
            newQuotationSettings.isDeleted = checked;
            setQuotationSettings(newQuotationSettings);
        }
        else if (whoSet === 'R') {
            newQuotationSettings.isReplaced = checked;
            setQuotationSettings(newQuotationSettings);
        }

        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: newQuotationSettings
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/showHistoryQuotation`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }

    function isVisibleProduct(productName) {
        if (!rules)
            return false;

        try {

            switch (productName) {
                case 'ACTIVITY':
                    {
                        return rules["ACTIVITY"];
                        break;
                    }
                case 'RENTCAR':
                    {
                        return rules["CARRENTAL"];
                        break;
                    }
                case 'CRUISE':
                    {
                        return rules["CRUISE"];
                        break;
                    }
                case 'STRUCTURE':
                    {
                        return rules["STRUCTURE"];
                        break;
                    }
                case 'TRANSFER':
                    {
                        return rules["TRANSFER"];
                        break;
                    }
                case 'TOUR':
                    {
                        return rules["TOUR"];
                        break;
                    }
                case 'FLIGHT':
                    {
                        return rules["FLIGHT"];
                        break;
                    }
                case 'TRAIN':
                    {
                        return rules["TRAIN"];
                        break;
                    }
                default: {
                    return true;
                }
            }


        } catch (ex) {
            return false;
        }
    }

    async function goToAddProduct(pType) {
        let pageName = ''

        switch (pType) {
            case configData.Settings.Products.Activity.IdTipoProdotto: {
                pageName = 'ACTIVITY';
                break;
            }
            case configData.Settings.Products.CarRental.IdTipoProdotto: {
                pageName = 'CARRENTAL';
                break;
            }
            case configData.Settings.Products.Cruise.IdTipoProdotto: {
                pageName = 'CRUISE';
                break;
            }
            case configData.Settings.Products.Flight.IdTipoProdotto: {
                pageName = 'FLIGHT';
                break;
            }
            case configData.Settings.Products.Structure.IdTipoProdotto: {
                pageName = 'HOTEL';
                break;
            }
            case configData.Settings.Products.Tour.IdTipoProdotto: {
                pageName = 'TOUR';
                break;
            }
            case configData.Settings.Products.Transfer.IdTipoProdotto: {
                pageName = 'TRANSFER';
                break;
            }
            case configData.Settings.Products.Train.IdTipoProdotto: {
                pageName = 'TRAIN';
                break;
            }
            default: {
                pageName = getDefaultModule;
                break;
            }
        }

        let response = await registerSessionQuotation(parseInt(quotationId), null);
        if (response) {
            setQuotationInfoSession(response.quotationInfo);

            onChangeModuleSelected(pageName);
            window.location.href = 'home';
        } else {
            console.error("ERROR: register Quotation");
        }
    }

    function callQuotationAddDiscount(inputData) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                amount: inputData.amount,
                currency: inputData.currency,
                idProdotto: inputData.idProdotto,
                descrizione: inputData.descrizione,
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/quotationadddiscount`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }

    function callSaveConfirmDiscount(inputData) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                status: inputData.status,
                amount: inputData.amount,
                id: inputData.idProdottoCorrelato
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/quotationconfirmdiscount`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }

    function callSaveQuotationItemPublicNote(inputData) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                quotationItemId: inputData.quotationItemId,
                note: inputData.publicNote,
                serviceNote: inputData.serviceNote,
                description: inputData.description
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/updatequotationitempublicnote`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function onDeleteBuonoSconto(discountId) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                discountId: discountId
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/quotationdeletediscount`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                updateQuotationAfterAPI(item);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }

    function callGetScadenziario() {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationscadenziario`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setScadenziario(item);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function callGetPaxes() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationpaxes/${quotationId}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationPaxes(item);
                setIsLoadingSaveAllPaxes(false);

                const isAllPaxesFilled = isQuotationPaxesFilled(item);
                setIsAllQuotationPaxesFilled(isAllPaxesFilled);
            })
            .catch((err) => {
                console.error(err);
                setIsLoadingSaveAllPaxes(false);
            });
    }

    function onAddorUpdatePax(myPax) {
        openPopupLoadUpdate();

        myPax.quotationId = quotationId;
        myPax.userIdWorkNow = userIdWorkNow;
        myPax.quotationSettings = quotationSettings;

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(myPax)
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/quotationaddorupdatepax`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationPaxes(item);
                closePopupLoadUpdate();

                const isAllPaxesFilled = isQuotationPaxesFilled(item);
                setIsAllQuotationPaxesFilled(isAllPaxesFilled);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }

    function onDeletePax(idPax) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                idPax: idPax
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/quotationdeletepax`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationPaxes(item);
                closePopupLoadUpdate();

                const isAllPaxesFilled = isQuotationPaxesFilled(item);
                setIsAllQuotationPaxesFilled(isAllPaxesFilled);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }

    function onAddPaxBpValues(myPax) {
        openPopupLoadUpdate();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quotationId: quotationId,
                userIdWorkNow: userIdWorkNow,
                quotationSettings: quotationSettings,
                paxes: myPax
            })
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/updatequotationpaxbpvalues`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setQuotationPaxes(item);
                closePopupLoadUpdate();

                const isAllPaxesFilled = isQuotationPaxesFilled(item);
                setIsAllQuotationPaxesFilled(isAllPaxesFilled);
            })
            .catch((err) => {
                console.error(err);
                closePopupLoadUpdate();
            });
    }

    function callGetCustomerInfoQuotation() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}customer/getcustomerinfo/${actualUserInfo.agencyId}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setCustomerInfoQuotation(item);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    return (
        <>
            <div className="container ">
                <div className="section section-lg bg-white" id="content">
                    <div className="container ">
                        {
                            isLoading
                                ? (<Loading textMsg="Caricamento preventivo in corso..." />)
                                : !isError && quotation !== null ?
                                    <>
                                        <QuotationBaseButton
                                            quotationId={quotationId}
                                            pdfFileName={pdfFileName}
                                            quotationMode={quotationMode}
                                            quotationHeader={quotationHeader}
                                            quotationPaxes={quotationPaxes}
                                            quotationTableMarginItems={quotationTableMarginItems}
                                            quotationRecapItems={quotationRecapItems}
                                            quotationItemStepPrint={quotationItemStepPrint}
                                            dynamicNotes={dynamicNotes}
                                            customerInfoQuotation={customerInfoQuotation}
                                            scadenziario={scadenziario}
                                            cultureName={cultureName}
                                            callOnSendMailAgency={callOnSendMailAgency}
                                            sendMailMsg={sendMailMsg}
                                            quotationAllowedOperation={quotationAllowedOperation}
                                        />
                                        <div className="mybook-order mybook-order-main p-0">
                                            <div className="card border-light p-0 p-md-4">
                                                <QuotationHeader
                                                    quotationHeader={quotationHeader}
                                                    callOnChangeQuotationStatus={callOnChangeQuotationStatus}
                                                    callOnSelectedAddToCart={callOnSelectedAddToCart}
                                                    sendMailAuthMsg={sendMailAuthMsg}
                                                    cultureName={cultureName}
                                                    callConfirmAll={callConfirmAll}
                                                    callChangeIsVisibleAgency={callChangeIsVisibleAgency}
                                                    callSendRequestAuthorization={callSendRequestAuthorization}
                                                    callSendAuthorization={callSendAuthorization}
                                                    quotationMode={quotationMode}
                                                    quotationAllowedOperation={quotationAllowedOperation}
                                                />
                                            </div>
                                            <QuotationMarkupRule
                                                markupRules={quotationMarkupRules}
                                                resetMarkupValue={callResetMarkupValue}
                                            />
                                            <QuotationTableMargin
                                                marginItems={quotationTableMarginItems}
                                                cultureName={cultureName}
                                                isCommissionable={quotationHeader.isCommissionable}
                                                quotationMode={quotationMode}
                                            />
                                        </div>
                                        {
                                            scadenziario && <div className="p-4"><CartScadenziario
                                                selectedAddProdIds={[]}
                                                orderNumber={-1}
                                                mode={"QuotationTO"}
                                                extScadenziario={scadenziario} /></div>
                                        }
                                        {
                                            quotationRecapItems && quotationRecapItems.length > 0
                                                ? <QuotationRecapItem recapItems={quotationRecapItems}
                                                    quotationId={quotationId}
                                                    cultureName={cultureName}
                                                    callSaveChangeMarkup={callSaveChangeMarkup}
                                                    callSaveChangeCommissionable={callSaveChangeCommissionable}
                                                    callSaveChangeIsCommissionable={callSaveChangeIsCommissionable}
                                                    callSaveBuyPrice={callSaveBuyPrice}
                                                    callSaveChangeAPIsSelected={callSaveChangeAPIsSelected}
                                                    callOpenUpdateManualProduct={callOpenUpdateManualProduct}
                                                    callQuotationAddDiscount={callQuotationAddDiscount}
                                                    callSaveConfirmDiscount={callSaveConfirmDiscount}
                                                    callSaveQuotationItemPublicNote={callSaveQuotationItemPublicNote}
                                                    onDeleteBuonoSconto={onDeleteBuonoSconto}
                                                />
                                                : <></>
                                        }
                                        {
                                            !isLoadingSaveAllPaxes && <QuotationPaxes
                                                paxes={quotationPaxes}
                                                startDate={minProductDate}
                                                onAddorUpdatePax={onAddorUpdatePax}
                                                onDeletePax={onDeletePax}
                                                onAddPaxBpValues={onAddPaxBpValues}
                                                orderNumber={quotationHeader.numeroOrdine}
                                                isAllQuotationPaxesFilled={isAllQuotationPaxesFilled}
                                                isAgencyMode={false}
                                            />
                                        }
                                        <div className="card border-gray-300">
                                            <div className="row p-4">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-4  mybook-order-card">
                                                    {t("MyBook:AddProductWebsite")} <M3Icon iconName="Cloud" />
                                                </div>
                                                {
                                                    isVisibleProduct('ACTIVITY') && <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                                                        <button onClick={e => goToAddProduct(configData.Settings.Products.Activity.IdTipoProdotto)} className="mybook-order-addproduct border-0 bg-transparent">
                                                            <M3IconProductType productType="Activity" />
                                                        </button>
                                                    </div>
                                                }
                                                {
                                                    isVisibleProduct('RENTCAR') && <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                                                        <button onClick={e => goToAddProduct(configData.Settings.Products.CarRental.IdTipoProdotto)} className="mybook-order-addproduct border-0 bg-transparent">
                                                            <M3IconProductType productType="CarRental" />
                                                        </button>
                                                    </div>
                                                }
                                                {
                                                    isVisibleProduct('CRUISE') && <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                                                        <button onClick={e => goToAddProduct(configData.Settings.Products.Cruise.IdTipoProdotto)} className="mybook-order-addproduct border-0 bg-transparent">
                                                            <M3IconProductType productType="Cruise" />
                                                        </button>
                                                    </div>
                                                }
                                                {
                                                    isVisibleProduct('HOTEL') && <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                                                        <button onClick={e => goToAddProduct(configData.Settings.Products.Structure.IdTipoProdotto)} className="mybook-order-addproduct border-0 bg-transparent">
                                                            <M3IconProductType productType="Structure" />
                                                        </button>
                                                    </div>
                                                }
                                                {
                                                    isVisibleProduct('TRANSFER') && <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                                                        <button onClick={e => goToAddProduct(configData.Settings.Products.Transfer.IdTipoProdotto)} className="mybook-order-addproduct border-0 bg-transparent">
                                                            <M3IconProductType productType="Transfer" />
                                                        </button>
                                                    </div>
                                                }
                                                {
                                                    isVisibleProduct('TOUR') && <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                                                        <button onClick={e => goToAddProduct(configData.Settings.Products.Tour.IdTipoProdotto)} className="mybook-order-addproduct border-0 bg-transparent">
                                                            <M3IconProductType productType="Tour" />
                                                        </button>
                                                    </div>
                                                }
                                                {
                                                    isVisibleProduct('TRAIN') && <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                                                        <button onClick={e => goToAddProduct(configData.Settings.Products.Train.IdTipoProdotto)} className="mybook-order-addproduct border-0 bg-transparent">
                                                            <M3IconProductType productType="Train" />
                                                        </button>
                                                    </div>
                                                }
                                                {
                                                    isVisibleProduct('FLIGHT') && <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                                                        <button onClick={e => goToAddProduct(configData.Settings.Products.Flight.IdTipoProdotto)} className="mybook-order-addproduct border-0 bg-transparent">
                                                            <M3IconProductType productType="Flight" />
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                            <div id="pnlUpdateManualProductItem" className="row p-4">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-4  mybook-order-card">
                                                    {t("MyBook:AddProductManually")} <M3Icon iconName="Edit" />
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                                                    <h2 className="accordion-header ">
                                                        <a id="lnkFormActivity" className="h4" data-bs-toggle="collapse" data-bs-target="#formActivity" aria-expanded="true" aria-controls="formActivity">
                                                            <M3IconProductType productType="Activity" />
                                                        </a>
                                                    </h2>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                                                    <h2 className="accordion-header ">
                                                        <a id="lnkFormCar" className="h4" data-bs-toggle="collapse" data-bs-target="#formCar" aria-expanded="true" aria-controls="formCar">
                                                            <M3IconProductType productType="CarRental" />
                                                        </a>
                                                    </h2>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                                                    <h2 className="accordion-header ">
                                                        <a id="lnkFormCruise" className="h4" data-bs-toggle="collapse" data-bs-target="#formCruise" aria-expanded="true" aria-controls="formCruise">
                                                            <M3IconProductType productType="Cruise" />
                                                        </a>
                                                    </h2>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                                                    <h2 className="accordion-header ">
                                                        <a id="lnkFormHotel" className="h4" data-bs-toggle="collapse" data-bs-target="#formHotel" aria-expanded="true" aria-controls="formHotel">
                                                            <M3IconProductType productType="Structure" />
                                                        </a>
                                                    </h2>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                                                    <h2 className="accordion-header ">
                                                        <a id="lnkFormTransfer" className="h4" data-bs-toggle="collapse" data-bs-target="#formTransfer" aria-expanded="true" aria-controls="formTransfer">
                                                            <M3IconProductType productType="Transfer" />
                                                        </a>
                                                    </h2>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                                                    <h2 className="accordion-header ">
                                                        <a id="lnkFormTour" className="h4" data-bs-toggle="collapse" data-bs-target="#formTour" aria-expanded="true" aria-controls="formTour">
                                                            <M3IconProductType productType="Tour" />
                                                        </a>
                                                    </h2>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                                                    <h2 className="accordion-header ">
                                                        <a id="lnkFormFlight" className="h4" data-bs-toggle="collapse" data-bs-target="#formFlight" aria-expanded="true" aria-controls="formFlight">
                                                            <M3IconProductType productType="Flight" />
                                                        </a>
                                                    </h2>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                                                    <h2 className="accordion-header ">
                                                        <a id="lnkFormGeneric" className="h4" data-bs-toggle="collapse" data-bs-target="#formGeneric" aria-expanded="true" aria-controls="formGeneric">
                                                            <M3IconProductType productType="Generic" />
                                                        </a>
                                                    </h2>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 border-0">
                                                    <div id="formActivity" className="accordion-collapse collapse" role="region" aria-labelledby="heading1AM">
                                                        <div className="accordion-body">
                                                            {
                                                                <ActivityManual item={manualProductToUpdate} isOpen={isOpenAddManualProduct.activity} isError={isErrorAddManualProduct.activity} callAddManualQuotationItem={callAddQuotationItemManual} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 border-0">
                                                    <div id="formCar" className="accordion-collapse collapse" role="region" aria-labelledby="heading1AM">
                                                        <div className="accordion-body">
                                                            {
                                                                <CarManual item={manualProductToUpdate} isOpen={isOpenAddManualProduct.car} isError={isErrorAddManualProduct.car} callAddManualQuotationItem={callAddQuotationItemManual} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 border-0">
                                                    <div id="formCruise" className="accordion-collapse collapse" role="region" aria-labelledby="heading1AM">
                                                        <div className="accordion-body">
                                                            {
                                                                <CruiseManual item={manualProductToUpdate} isOpen={isOpenAddManualProduct.cruise} isError={isErrorAddManualProduct.cruise} callAddManualQuotationItem={callAddQuotationItemManual} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <div id="formHotel" className="accordion-collapse collapse" role="region" aria-labelledby="heading1HM">
                                                        <div className="accordion-body">
                                                            {
                                                                <StructureManual item={manualProductToUpdate} isOpen={isOpenAddManualProduct.hotel} isError={isErrorAddManualProduct.hotel} callAddManualQuotationItem={callAddQuotationItemManual} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 border-0">
                                                    <div id="formTransfer" className="accordion-collapse collapse" role="region" aria-labelledby="heading1AM">
                                                        <div className="accordion-body">
                                                            {
                                                                <TransferManual item={manualProductToUpdate} isOpen={isOpenAddManualProduct.transfer} isError={isErrorAddManualProduct.transfer} callAddManualQuotationItem={callAddQuotationItemManual} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 border-0">
                                                    <div id="formTour" className="accordion-collapse collapse" role="region" aria-labelledby="heading1AM">
                                                        <div className="accordion-body">
                                                            {
                                                                <TourManual item={manualProductToUpdate} isOpen={isOpenAddManualProduct.tour} isError={isErrorAddManualProduct.tour} callAddManualQuotationItem={callAddQuotationItemManual} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 border-0">
                                                    <div id="formFlight" className="accordion-collapse collapse" role="region" aria-labelledby="heading1AM">
                                                        <div className="accordion-body">
                                                            {
                                                                <FlightManual item={manualProductToUpdate} isOpen={isOpenAddManualProduct.flight} isError={isErrorAddManualProduct.flight} callAddManualQuotationItem={callAddQuotationItemManual} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 border-0">
                                                    <div id="formGeneric" className="accordion-collapse collapse" role="region" aria-labelledby="heading1AM">
                                                        <div className="accordion-body">
                                                            {
                                                                <GenericManual quotationId={quotationId} item={manualProductToUpdate} isOpen={isOpenAddManualProduct.generic} isError={isErrorAddManualProduct.generic} callDeleteManualQuotationItem={callDeleteManualQuotationItem} callAddManualQuotationItem={callAddQuotationItemManual} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            quotationItemStep && quotationItemStep.length > 0
                                                ? <QuotationSteps items={quotationItemStep}
                                                    cultureName={cultureName}
                                                    showHistory={quotationSettings}
                                                    onSaveChangeStep={callOnSaveChangeStep}
                                                    onSaveStepInfo={callOnSaveStepInfo}
                                                    onAutoGenerateStep={callOnAutoGenerateStep}
                                                    onSaveChangeAlternative={callOnSaveChangeAlternative}
                                                    onSaveSelectAlternative={callOnSaveSelectAlternative}
                                                    onCallRequoteQuotationItem={callOnCallRequoteQuotationItem}
                                                    onCallUpdateQuotationItem={callOnCallUpdateQuotationItem}
                                                    callOnChangeStatusQuotationItem={callOnChangeStatusQuotationItem}
                                                    onCallSaveProductInfo={callOnCallSaveProductInfo}
                                                    onCallChangeShowHistory={onCallChangeShowHistory}
                                                    onCallRequoteAll={onCallRequoteAll}
                                                    quotationMode={quotationMode}
                                                    saveProductData={onSaveProductData}
                                                />
                                                : <></>
                                        }

                                        {
                                            configData.Settings.QuotationTO.ShowChat && <QuotationChats
                                                quotationChats={quotationChats}
                                                totalChats={totalChats}
                                                limitQuotationChats={limitQuotationChats}
                                                callAddChat={callAddChat}
                                                callAddChatAnswer={callAddChatAnswer}
                                                callReadChat={callReadChat}
                                                callGetAllChat={callGetChats}
                                                cultureName={cultureName}
                                                isAgencyMode={false} />
                                        }

                                        <ul className="nav nav-tabs bg-white pt-2 pb-2">
                                            <li className="nav-item">
                                                <button
                                                    className={"nav-link " + (isTabSelectedNote === 1 ? "active" : "")}
                                                    onClick={e => changeTabSelectedNote(1)}>
                                                    {t("MyBook:SeeInternalNotes")}
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={"nav-link " + (isTabSelectedNote === 2 ? "active" : "")}
                                                    onClick={e => changeTabSelectedNote(2)}>
                                                    {t("MyBook:SeeLogs")}
                                                </button>
                                            </li>
                                        </ul>
                                        {
                                            isTabSelectedNote === 1 && <QuotationInternalNotes
                                                quotationInternalNotes={quotationInternalNotes}
                                                totalInternalNotes={totalInternalNotes}
                                                callAddInternalNote={callAddInternalNote}
                                                callGetAllInternalNote={callGetInternalNote}
                                                cultureName={cultureName} />
                                        }
                                        {
                                            isTabSelectedNote === 2 && <QuotationLogs
                                                quotationLogs={quotationLogs}
                                                totalLogs={totalLogs}
                                                callGetAllLogs={callGetLogs}
                                                cultureName={cultureName} />
                                        }
                                    </>
                                    : <Error textMsg={t("MyBook:QuotationNotAvailable")} />
                        }

                    </div >
                </div >

                <a id="openQuotationTO_LoadingAfterUpdate" data-bs-toggle="modal" data-bs-target="#quotationTO_LoadingPage"></a>
                <div className="modal fade" id="quotationTO_LoadingPage" tabIndex="-1" aria-labelledby="quotationTO_LoadingPageLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-body">
                                <a id="closeQuotationTO_LoadingAfterUpdate" data-bs-dismiss="modal" />
                                <Loading textMsg={t("MyBook:ReloadingQuotation")} />
                            </div>
                        </div>
                    </div>
                </div>


                <a id="openQuotationTO_SelectItemAddToCart" data-bs-toggle="modal" data-bs-target="#quotationTO_SelectItemAddToCart"></a>
                <div className="modal fade" id="quotationTO_SelectItemAddToCart" tabIndex="-1" aria-labelledby="quotationTO_SelectItemAddToCartLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="quotationTO_SelectItemAddToCartLabel">{t("MyBook:AddToCart")}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {
                                    addToCartStep === 0 && <QuotationSelectAgency
                                        items={actualUserInfo}
                                        onCallSelectAgency={onCallSelectAgency} />
                                }
                                {addToCartStep === 1 && isLoadingItemsAddToCart && <Loading textMsg="Caricamento" />}
                                {
                                    addToCartStep === 1 && !isLoadingItemsAddToCart ? quotationItemsAddToCart && quotationItemsAddToCart.length > 0
                                        ? quotationItemsAddToCart.map((item, key) => {
                                            return <div key={key} className="row">
                                                <div className="col-1"><b>Tipo</b></div>
                                                <div className="col-4"><b>Nome</b></div>
                                                <div className="col-2"><b>Prezzo</b></div>
                                                <div className="col-3"><b>Dal - al</b></div>
                                                <div className="col-2 text-right"><b>Aggiungi</b></div>
                                                <div className="col-12 mt-1 mb-1">
                                                    {item.step > -1 && <>Step {item.step} {item.stepName && <> - {item.stepName}</>}</>}
                                                    {item.step === -1 && <>{t("MyBook:StepAssurrance")} </>}
                                                </div>
                                                {
                                                    item.quotationItemInfo && item.quotationItemInfo.map((product, keyI) => {
                                                        return <div key={keyI} className="col-12 mt-1">
                                                            <div className="row m-0">
                                                                <div className="col-1">
                                                                    <M3IconProductType productType={product.productType} productSubtype={product.productSubType} />
                                                                </div>
                                                                <div className="col-4">{product.name}</div>
                                                                <div className="col-2">{formatPrice(product.priceAmount, product.priceCurrency, cultureName)}</div>
                                                                {item.step !== -1 && <div className="col-3">{formatDateTime(product.checkIn, cultureName, { noYear: true })} {product.checkOut && <> - {formatDateTime(product.checkOut, cultureName, { noYear: true })}</>}</div>}
                                                                {item.step === -1 && <div className="col-3"></div>}
                                                                <div className="col-2 text-right">
                                                                    <input type="checkbox" checked={product.isSelected} onChange={e => onCheckedAddToCart(e, key, keyI)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        })
                                        : <span>{t("MyBook:NothingProductToAddCart")}</span>
                                        : <></>
                                }
                            </div>
                            {
                                <div className='modal-footer'>
                                    <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t("Button:Close")}</button>
                                    {addToCartStep === 1 && quotationItemsAddToCart && quotationItemsAddToCart.length > 0 && <button type="button"
                                        className="btn bt-sm tp-btn-confirm"
                                        onClick={callOnAddToCart}>{t("MyBook:Add")}</button>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}