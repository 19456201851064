import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { Loading } from '../../Common/Loading';
import { formatDateTime } from '../../../js/Utils.js';
import configData from "../../../appsettings.json";

export const ChangeStructureDatesModal = ({ pnr, productType, structureDetail }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);

    const [showBtnConfirm, setShowBtnConfirm] = useState(true);
    const [showBtnClose, setShowBtnClose] = useState(true);

    const [newCheckIn, setNewCheckIn] = useState();
    const [newCheckOut, setNewCheckOut] = useState();


    const onChangeNewCheckIn = (value) => { setNewCheckIn(value); }
    const onChangeNewCheckOut = (value) => { setNewCheckOut(value); }

    const onClose = () => {
        window.location.reload(true);
    }

    async function onConfirm() {
        setIsLoading(true);

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ PNR: pnr, ProductType: productType, NewCheckIn: newCheckIn, NewCheckOut: newCheckOut })
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/ChangeDates', requestOption);
        const response = await fetchedRes.json();

        setIsLoading(false);
        setIsFinished(true);
        setShowSuccessMsg(true);
    }


    return (
        <div className="modal fade" id={"ChangeStructureDates_" + pnr} tabIndex="-1" aria-labelledby={"ChangeStructureDatesLabel_" + pnr} aria-hidden="true">
            <div className="modal-dialog modal-lg" style={{ width: '65%', maxWidth: 'none' }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Cambio Date</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {
                            isLoading
                                ? (<Loading textMsg={t('MyBook:Loading')} />)
                                : (!isFinished
                                    ? (
                                        <>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-3">CheckIn</div>
                                                    <div className="col-3">CheckOut</div>
                                                    <div className="col-3">New CheckIn</div>
                                                    <div className="col-3">New CheckOut</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-3">
                                                        {formatDateTime(structureDetail.priceBar.startDate, cultureName)}
                                                    </div>
                                                    <div className="col-3">
                                                        {formatDateTime(structureDetail.priceBar.endDate, cultureName)}
                                                    </div>
                                                    <div className="col-3">
                                                        <input type="date" className="form-control datepicker" onChange={e => onChangeNewCheckIn(e.target.value)} />
                                                    </div>
                                                    <div className="col-3">
                                                        <input type="date" className="form-control datepicker" onChange={e => onChangeNewCheckOut(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                    : (
                                        <>
                                            {showSuccessMsg
                                                ? (<h6 className="penalty free">Richiesta inviata con successo</h6>)
                                                : (<h6 className="penalty">Si &egrave; verificato un errore</h6>)}
                                        </>
                                    )
                                )
                        }
                    </div>
                    <div className={isLoading ? ('modal-footer d-none') : ('modal-footer')}>
                        <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal" onClick={onClose}>{t('Button:Close')}</button>
                        {!isFinished ? (<button type="button" className="btn bt-sm tp-btn-confirm" onClick={onConfirm}>Cambia Date</button>) : (<></>)}
                    </div>
                </div>
            </div>
        </div>
    );
}
