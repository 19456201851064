import { AccountBalance } from "./components/Documents/AccountBalance";
import { AccountBalanceAutoDownload } from "./components/Documents/AccountBalanceAutoDownload";
import { AccountBalancePerBook } from "./components/Documents/AccountBalancePerBook";
import { AccountBalancePerBookAutoDownload } from "./components/Documents/AccountBalancePerBookAutoDownload";
import { SaferPaySuccess } from "./components/PaymentGateway/SaferPay/SaferPaySuccess";
import { SaferPayFail } from "./components/PaymentGateway/SaferPay/SaferPayFail";
import { SaferPayBack } from "./components/PaymentGateway/SaferPay/SaferPayBack";
import { XPaySuccess } from "./components/PaymentGateway/XPay/XPaySuccess";
import { XPayBack } from "./components/PaymentGateway/XPay/XPayBack";
import { GestPaySuccess } from "./components/PaymentGateway/GestPay/GestPaySuccess";
import { GestPayFail } from "./components/PaymentGateway/GestPay/GestPayFail";
import { StripeSuccess } from "./components/PaymentGateway/Stripe/StripeSuccess";
import { StripeCancel } from "./components/PaymentGateway/Stripe/StripeCancel";
import { JointlyPayValidate } from "./components/PaymentGateway/Jointly/JointlyPayValidate";
import { JointlyPayNotify } from "./components/PaymentGateway/Jointly/JointlyPayNotify";
import { JointlyPayResult } from "./components/PaymentGateway/Jointly/JointlyPayResult";
import { IntesaSanPaoloSuccess } from "./components/PaymentGateway/IntesaSanPaolo/IntesaSanPaoloSuccess";
import { IntesaSanPaoloCancel } from "./components/PaymentGateway/IntesaSanPaolo/IntesaSanPaoloCancel";
import { Voucher } from "./components/Documents/Voucher";
import { TravelDoc } from "./components/Documents/TravelDoc";
import { ConfirmBooking } from "./components/Print/ConfirmBooking";
import { ConfirmBookingEndUser } from "./components/Print/ConfirmBookingEndUser";
import { QuotationTo } from "./components/Print/Quotation";
import { DocViaggio } from "./components/Print/DocViaggio";
import { EdenRed } from "./components/Welfare/EdenRed";

import { ConfirmBookingV2 } from "./components/PrintV2/ConfirmBooking";
import { ConfirmBookingEndUserV2 } from "./components/PrintV2/ConfirmBookingEndUser";
import { QuotationToV2 } from "./components/PrintV2/Quotation";
import { DocViaggioV2 } from "./components/PrintV2/DocViaggio";

const AppRoutes_Document = [
    {
        path: '/documents/accountBalance/:orderNumber/:userType/:visualizationType/:grouped/:cultureName/:authcode',
        element: <AccountBalance />,
        visibleOnlyBook: false
    }, {
        path: '/documents/accountBalanceDownload/:orderNumber/:userType/:visualizationType/:grouped/:cultureName/:authcode',
        element: <AccountBalanceAutoDownload />,
        visibleOnlyBook: false
    }, {
        path: '/PaymentGateway/SaferPay/Success/:cId/:oId',
        element: <SaferPaySuccess />,
        visibleOnlyBook: true
    }, {
        path: '/PaymentGateway/SaferPay/Fail/:cId/:oId',
        element: <SaferPayFail />,
        visibleOnlyBook: true
    }, {
        path: '/PaymentGateway/SaferPay/Back/:cId/:oId',
        element: <SaferPayBack />,
        visibleOnlyBook: true
    }, {
        path: '/PaymentGateway/XPay/Success',
        element: <XPaySuccess />,
        visibleOnlyBook: true
    }, {
        path: '/PaymentGateway/XPay/Back',
        element: <XPayBack />,
        visibleOnlyBook: true
    }, {
        path: '/PaymentGateway/GestPay/Success',
        element: <GestPaySuccess />,
        visibleOnlyBook: true
    }, {
        path: '/PaymentGateway/GestPay/Fail',
        element: <GestPayFail />,
        visibleOnlyBook: true
    }, {
        path: '/PaymentGateway/Stripe/Success',
        element: <StripeSuccess />,
        visibleOnlyBook: true
    }, {
        path: '/PaymentGateway/Stripe/Cancel',
        element: <StripeCancel />,
        visibleOnlyBook: true
    }, {
        path: '/PaymentGateway/Jointly/PayValidate',
        element: <JointlyPayValidate />,
        visibleOnlyBook: true
    }, {
        path: '/PaymentGateway/Jointly/PayNotify',
        element: <JointlyPayNotify />,
        visibleOnlyBook: true
    }, {
        path: '/PaymentGateway/Jointly/JointlyPayResult',
        element: <JointlyPayResult />,
        visibleOnlyBook: true
    }, {
        path: '/documents/voucher/:orderNumber/:wsBookingId/:visualizationType/:cultureName/:authcode',
        element: <Voucher />,
        visibleOnlyBook: false
    }, {
        path: '/documents/accountBalancePerBook/:orderNumber/:wsBookingId/:userType/:visualizationType/:grouped/:cultureName/:authcode',
        element: <AccountBalancePerBook />,
        visibleOnlyBook: false
    }, {
        path: '/documents/accountBalancePerBookDownload/:orderNumber/:wsBookingId/:userType/:visualizationType/:grouped/:cultureName/:authcode',
        element: <AccountBalancePerBookAutoDownload />,
        visibleOnlyBook: false
    }, {
        path: '/documents/traveldocs/:orderNumber/:cultureName/:authcode',
        element: <TravelDoc />,
        visibleOnlyBook: false
    }, {
        path: '/documents/confirmBookingEnduser/:orderNumber/:cultureName',
        element: <ConfirmBookingEndUser />,
        visibleOnlyBook: false
    }, {
        path: '/documents/confirmBooking/:orderNumber/:cultureName',
        element: <ConfirmBooking />,
        visibleOnlyBook: false
    }, {
        path: '/documents/docBookViaggio/:orderNumber/:cultureName',
        element: <DocViaggio />,
        visibleOnlyBook: false
    }, {
        path: '/documents/quotation/:quotationId',
        element: <QuotationTo />,
        visibleOnlyBook: false
    }, {
        path: '/PaymentGateway/IntesaSanPaolo/Success',
        element: <IntesaSanPaoloSuccess />,
        visibleOnlyBook: true
    }, {
        path: '/PaymentGateway/IntesaSanPaolo/Cancel',
        element: <IntesaSanPaoloCancel />,
        visibleOnlyBook: true
    }, {
        path: '/Welfare/EdenRed',
        element: <EdenRed />,
        visibleOnlyBook: false

    }

    ,{
        path: '/documentsV2/confirmBookingEnduser/:orderNumber/:cultureName',
        element: <ConfirmBookingEndUserV2 />,
        visibleOnlyBook: false
    }, {
        path: '/documentsV2/confirmBooking/:orderNumber/:cultureName',
        element: <ConfirmBookingV2 />,
        visibleOnlyBook: false
    }, {
        path: '/documentsV2/docBookViaggio/:orderNumber/:cultureName',
        element: <DocViaggioV2 />,
        visibleOnlyBook: false
    }, {
        path: '/documentsV2/quotation/:quotationId',
        element: <QuotationToV2 />,
        visibleOnlyBook: false
    }
];

export default AppRoutes_Document;
