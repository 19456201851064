import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { MenuBarVertical } from './MenuBarVertical';
import { Loading } from '../Common/Loading';
import { OrderLight } from './Orders/OrderLight';
import { useTranslation } from 'react-i18next';
import { getCurrentUserFromJwtToken } from '../../js/Utils.js';
import configData from "../../appsettings.json";

export const Orders = () => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [myStakeholderId, setMyStakeholderId] = useState();
    const [myUserId, setMyUserId] = useState();
    const [totalOrders, setTotalOrders] = useState(0);
    const [orders, setOrders] = useState([]);

    // filters
    const daysBookFromBeforeToday = 90;
    const [stakeholderId, setStakeholderId] = useState();
    const [stakeholdersFatturazione, setStakeholdersFatturazione] = useState([]);
    const [userId, setUserId] = useState();
    const [websiteId, setWebsiteId] = useState();
    const [fromCheckin, setFromCheckin] = useState();
    const [toCheckin, setToCheckin] = useState();
    const [fromBookDate, setFromBookDate] = useState();
    const [toBookDate, setToBookDate] = useState();
    const [paxName, setPaxName] = useState();
    const [orderNumber, setOrderNumber] = useState();
    const [praticaNumber, setPraticaNumber] = useState();

    // user authorizations
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);
    const [showNetPrices, setShowNetPrices] = useState(false);
    const [showStakeholderFatt, setShowStakeholderFatt] = useState(false);
    const [pricingB2C, setPricingB2C] = useState(false);

    // paging
    const itemsPerPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(0);


    useEffect(() => {
        const getOrders = async (inputData) => { const response = callGetOrders(inputData); }
        const getStakeholderFatturazione = async (inputData) => { const response = callGetStakeholderFatturazione(inputData); }

        let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
        let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);
        if (currentUser.roles) {
            if (!currentUser.roles.includes("FE_NonVisualizzaNetto")) setEnableShowNetPrices(true);
            if (currentUser.roles.includes("DATA_HeadTravelManager")) setShowStakeholderFatt(true);
            if (currentUser.roles.includes("US_PricingB2C")) setPricingB2C(true);
        }

        setMyStakeholderId(currentUser.stakeholderId);
        setStakeholderId(currentUser.stakeholderId);

        setMyUserId(currentUser.userId);
        setUserId(currentUser.userId);

        setWebsiteId(currentUser.websiteId);

        var defaultFromBookDate = getDefaultFromBookDate();
        setFromBookDate(defaultFromBookDate);

        setIsLoading(true);

        getStakeholderFatturazione(currentUser.stakeholderId);
        getOrders({ IdStakeholder: currentUser.stakeholderId, UserId: currentUser.userId, WebsiteId: currentUser.websiteId, BookDateFrom: defaultFromBookDate, pageNum: 1, pageSize: itemsPerPage });
    }, []);


    const getDefaultFromBookDate = () => {
        var today = new Date();
        var date = today.setDate(today.getDate() - daysBookFromBeforeToday);
        var defaultFromBookDate = new Date(date).toISOString().split('T')[0]; // yyyy-mm-dd
        return defaultFromBookDate;
    }

    async function callGetOrders(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/getorders', requestOption);
        const response = await fetchedRes.json();

        setTotalOrders(response.totalOrders);
        setPageCount(Math.ceil(response.totalOrders / itemsPerPage));

        var sortedOrders = [...response.orders];
        sortedOrders.sort((a, b) => b.idOrdine - a.idOrdine);
        setOrders(sortedOrders);

        setIsLoading(false);
    }

    async function callGetStakeholderFatturazione(idStakeholder) {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Customer/GetCustomersFromFatturazione/' + idStakeholder, requestOption);
        const response = await fetchedRes.json();

        if (response && response.success && response.customers) {
            setStakeholdersFatturazione(response.customers);
        }
    }

    const handleShowNetPrices = async e => {
        e.preventDefault();
        setShowNetPrices(!showNetPrices);
    }

    const handleStakeholderFattOnChange = (val) => {
        setStakeholderId(val);

        if (val !== myStakeholderId) {
            setUserId(null); // se sono su un altro stakeholder devo annullare lo userId
        } else {
            setUserId(myUserId); // altrimenti lo ripristino
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();

        setIsLoading(true);
        setPageSelected(0);

        let inputData = {
            BookDateFrom: orderNumber || praticaNumber ? null : (fromBookDate === '' ? getDefaultFromBookDate() : fromBookDate),
            BookDateTo: orderNumber || praticaNumber ? null : (toBookDate === '' ? null : toBookDate),
            CheckInFrom: orderNumber || praticaNumber ? null : (fromCheckin === '' ? null : fromCheckin),
            CheckInTo: orderNumber || praticaNumber ? null : (toCheckin === '' ? null : toCheckin),
            RefPax: paxName === '' ? null : paxName,
            NumeroOrdine: orderNumber === '' ? null : orderNumber,
            WsBookingId: praticaNumber === '' ? null : praticaNumber,
            IdStakeholder: stakeholderId,
            UserId: userId,
            WebsiteId: websiteId,
            PageNum: 1,
            PageSize: itemsPerPage
        };

        const response = await callGetOrders(inputData);
    }

    const handlePageClick = async e => {
        setPageSelected(e.selected);

        let inputData = {
            BookDateFrom: fromBookDate === '' ? getDefaultFromBookDate() : fromBookDate,
            BookDateTo: toBookDate === '' ? null : toBookDate,
            CheckInFrom: fromCheckin === '' ? null : fromCheckin,
            CheckInTo: toCheckin === '' ? null : toCheckin,
            RefPax: paxName === '' ? null : paxName,
            NumeroOrdine: orderNumber === '' ? null : orderNumber,
            WsBookingId: praticaNumber === '' ? null : praticaNumber,
            IdStakeholder: stakeholderId,
            UserId: userId,
            PageNum: e.selected + 1,
            PageSize: itemsPerPage
        };

        const response = await callGetOrders(inputData);
        window.scrollTo(0, 0);
    };

    return (
        <>
            <MenuBarVertical activeAnchorId="aOrdini" />

            <div className="section section-lg  bg-gray-200 page-content" id="content">
                <div className="container">
                    {/*Filter Orders*/}
                    <div className="card border-light p-0 p-md-4 mb-4">
                        <div className="row">
                            <div className="col-12 col-lg-10">
                                <p className="text-sm mb-0"> {t("MyBook:FilterOrders")}</p>

                                {showStakeholderFatt && stakeholdersFatturazione && stakeholdersFatturazione.length > 0
                                    ? (
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="ph-users-four-bold"></i></span>
                                            <select id="stakeholderFatt" className="custom-select w-100" onChange={e => handleStakeholderFattOnChange(e.target.value)}>
                                                <option value={myStakeholderId}>{t("MyBook:Select")}...</option>
                                                {stakeholdersFatturazione.map(x =>
                                                    <option key={x.id} value={x.id}>{x.ragioneSociale}</option>
                                                )}
                                            </select>
                                        </div>
                                    )
                                    : (<></>)
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-3">
                                <span style={{ fontSize: '12px' }}>{t("MyBook:FromCheckIn")}</span> <br />
                                <div className="input-group input-group-md mb-3 mb-lg-0">
                                    <input className="form-control datepicker" placeholder="Da check-in" type="date" onChange={e => setFromCheckin(e.target.value)} required />
                                </div>
                            </div>
                            <div className="col-12 col-lg-3">
                                <span style={{ fontSize: '12px' }}>{t("MyBook:FromCheckOut")}</span> <br />
                                <div className="input-group input-group-md mb-3 mb-lg-0">
                                    <input className="form-control datepicker" placeholder="Da check-out" type="date" onChange={e => setToCheckin(e.target.value)} required />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <br />
                                <div className="input-group input-group-md mb-3 mb-lg-0">
                                    <input className="form-control" placeholder={t("MyBook:TravelerName")} type="text" onChange={e => setPaxName(e.target.value.trim())} required />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="ph-user"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-1">
                                <br />
                                <button className="tp-btn-login btn-sm mb-3 mt-md-0 animate-up-2" type="submit" onClick={handleSubmit}>
                                    {t("MyBook:Search")}
                                </button>
                            </div>
                            <div className="col-12 col-lg-3">
                                <span style={{ fontSize: '12px' }}>{t("MyBook:FromDateBook")}</span> <br />
                                <div className="input-group input-group-md mb-3 mb-lg-0">
                                    <input className="form-control datepicker" placeholder="Da data book" type="date" defaultValue={getDefaultFromBookDate()} onChange={e => setFromBookDate(e.target.value)} required />
                                </div>
                            </div>
                            <div className="col-12 col-lg-3">
                                <span style={{ fontSize: '12px' }}>{t("MyBook:ToDateBook")}</span> <br />
                                <div className="input-group input-group-md mb-3 mb-lg-0 small">
                                    <input className="form-control datepicker" placeholder="Alla data book" type="date" onChange={e => setToBookDate(e.target.value)} required />
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <br />
                                <div className="input-group input-group-md mb-3 mb-lg-0">
                                    <input className="form-control" placeholder={t("MyBook:NOrder")} type="text" onChange={e => setOrderNumber(e.target.value.trim())} required />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <span class="material-icons">list_alt</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-1 text-right">
                                
                                    <br />
                                    {enableShowNetPrices
                                        ? (
                                            <a className="tp-btn-outline-select p-1 px-2 pt-2 rounded border-0 shadow " type="submit" onClick={handleShowNetPrices}>N</a>
                                        )
                                        : (<></>)
                                        }
                            </div>
                            <div className="col-12 col-lg-6">
                            </div>
                            <div className="col-12 col-lg-4">
                                <br />
                                <div className="input-group input-group-md mb-3 mb-lg-0">
                                    <input className="form-control" placeholder={t("MyBook:NPractice")} type="text" onChange={e => setPraticaNumber(e.target.value.trim())} required />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <span class="material-icons">list_alt</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Orders*/}
                    {isLoading
                        ? (<Loading textMsg={t('Loading:Order')} />)
                        : (
                            <>
                                {orders !== undefined && orders !== null && orders.length > 0
                                    ? (
                                        orders.map((order, index) => {
                                            return <OrderLight key={index} order={order} showNetPrices={showNetPrices ? '1' : '0'} pricingB2C={pricingB2C} />;
                                        })
                                    ) : (
                                        <h6>{t("MyBook:NothingOrderFound")}.</h6>
                                    )
                                }
                            </>
                        )
                    }

                    <nav aria-label="Page navigation comments" className="mt-4">
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={t('pagination:nextLabel')}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel={t('pagination:previousLabel')}
                            renderOnZeroPageCount={null}
                            forcePage={pageSelected}

                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            marginPagesDisplayed={2}
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            activeClassName="active"

                            // eslint-disable-next-line no-unused-vars
                            //hrefBuilder={(page, pageCount, selected) => page >= 1 && page <= pageCount ? `/page/${page}` : '#' }
                        />
                    </nav>
                </div>
            </div>
        </>
    );
}
