import React, { useState, useEffect } from "react";
import { InputMonthCalendar } from './InputMonthCalendar';
import { InputMonthCalendarReadOnly } from './InputMonthCalendarReadOnly';
import { M3MonthPicker } from './M3MonthPicker';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { closeTpSearchInput } from '../../../js/Utils';

export const InputMonthCalendarContainer = ({ externalId, selectMonths, productType, onClickSelectDates, onOpenPanelCalendar, closeExternal, extraOptions, validDateRule, modeView, type }) => {
    const { t } = useTranslation();

    const [selectDates, setSelectDates] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);

    const [idModal] = useState(getRandomInt());
    function getRandomInt() {
        return Math.floor(Math.random() * 100000);
    }
    const modalId = "MonthCalendar_" + idModal;

    useEffect(() => {

        if (modeView === 'SEARCHENGINE')
            closeTpSearchInput(modalId, closePanelCalendar);

    }, []);


    useEffect(() => {
        setSelectDates(selectMonths);
    }, [selectMonths]);

    useEffect(() => {
        setShowCalendar(closeExternal);
    }, [closeExternal]);

    function onClickOpenCalendar() {
        setShowCalendar(!showCalendar);

        if (!externalId)
            onOpenPanelCalendar();
        else
            onOpenPanelCalendar(externalId);

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnModalMonthCalendar_" + idModal);
            btn.click();
        }
    }

    function closePanelCalendar() {
        setShowCalendar(false);

        try {

            let myModal = document.getElementById(idModal);
            myModal.style.setProperty("height", "0px");

        } catch (ex) { }

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnCloseModalMonthCalendar_" + idModal);
            btn.click();
        }
    }

    function onSelectDate(values) {
        setShowCalendar(false);
        setSelectDates(values);
        if (!externalId)
            onClickSelectDates(values);
        else
            onClickSelectDates(values, externalId);

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnCloseModalMonthCalendar_" + idModal);
            btn.click();
        }

    }

    function isMobile(excludeModeView = true) {
        if (excludeModeView && modeView === 'MODAL')
            return false;

        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const isMobileWidth = window.innerWidth <= 991;

        // Controllo per dispositivi Android
        if (/android/i.test(userAgent)) {
            return true;
        }

        // Controllo per dispositivi iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return true;
        }

        return isMobileWidth;
    }

    const getPlaceholder = () => {
        if (extraOptions && extraOptions.label)
            return t(extraOptions.label);

        if (!extraOptions || !extraOptions.multiDate)
            return t('SearchEngine:SelectMonth');

        return t('SearchEngine:SelectMonths');
    }

    return (
        <>
            <div className="w-100">
                {
                    !showCalendar && <InputMonthCalendarReadOnly
                        selectMonths={selectDates}
                        extraOptions={extraOptions}
                        modeView="MODAL"
                        onClickOpen={onClickOpenCalendar} />
                }
            </div>

            <a id={"btnModalMonthCalendar_" + idModal} data-bs-toggle="modal" data-bs-target={"#" + modalId}></a>
            <div
                className={"modal " + (isMobile() ? "" : " tp-search-input position-relative ") + (modeView === 'MODAL' ? "d-block" : "fade")}
                style={{ overflow: 'unset', height: (!isMobile() ? (isMobile(false) && modeView === 'MODAL' && showCalendar ? '' : '0px') : '100%'), zIndex: '200' }}
                id={modalId}
                tabIndex="-1"
                aria-labelledby={"CalendarLabel_" + idModal}
                aria-hidden="true">
                <div className={"modal-dialog " + (isMobile() ? "h-100 modal-dialog-scrollable" : "")}>

                    {
                        showCalendar && <div className={"modal-content " + (isMobile() ? "h-100" : "")}>
                            {
                                isMobile()
                                    ? <div className="modal-header p-0">
                                        <div className="container py-3">
                                            <div className="row">
                                                <div className="col-1">
                                                    <button className="btn btn-link p-0 m-0 text-decoration-none" onClick={_ => closePanelCalendar()}>
                                                        <span class="material-icons-outlined">
                                                            arrow_back
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="col-11 text-center">
                                                    <h6 className="btn btn-link p-0 m-0 text-decoration-none fw-bold text-dark">
                                                        {getPlaceholder()}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                            }
                            <div className="modal-header p-0">
                                <InputMonthCalendar
                                    selectMonths={selectDates}
                                    extraOptions={extraOptions}
                                    modeView="MODAL"
                                    closePanelCalendar={closePanelCalendar}
                                    onClickOpen={onClickOpenCalendar}
                                    isMobile={isMobile()} />
                            </div>
                            <div className="modal-body p-0 wm-992 modal-calendar-responsive">
                                <M3MonthPicker
                                    selectMonths={selectDates}
                                    extraOptions={extraOptions}
                                    validDateRule={validDateRule}
                                    onSelectDate={onSelectDate}/>
                            </div>
                        </div>
                    }

                    <a id={"btnCloseModalMonthCalendar_" + idModal} data-bs-dismiss="modal"></a>
                </div>
            </div>
        </>
    );
}
