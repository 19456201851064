import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../Common/Modal.js';
import configData from "../../../appsettings.json";

export const RequotePnrModal = ({ pnr, productType }) => {

    async function handleOnConfirm() {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ PNR: pnr, ProductType: productType })
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/RequotePNR', requestOption);
        const response = await fetchedRes.json();

        let outObj = {
            success: response.success,
            bodyHtml: response.success
                ? "<p><span class='penalty free'>PNR aggiornato con successo</span></p>"
                : "<p><span class='penalty'>Si &egrave; verificato un errore durante l'aggiornamento del PNR.</span></p>"
        };

        return outObj;
    }

    async function handleOnOpen() {
        let outObj = {
            success: true,
            bodyHtml: "<p><span>Vuoi davvero riquotare la prenotazione n. " + pnr + "?</span></p>"
        };

        return outObj;
    }

    const handleOnClose = () => {
        window.location.reload(true);
    }

    const modalSettings = {
        id: 'RequotePNR_' + pnr,
        title: 'Requote Reservation N. ' + pnr,
        body: null,
        lblClose: 'Chiudi',
        lblConfirm: 'Riquota',
        isLoading: true,
        loadingMsg: 'Caricamento in corso...',
        showBtnConfirm: true,
        showBtnClose: true,
        fnOnConfirm: handleOnConfirm,
        fnOnOpen: handleOnOpen,
        fnOnClose: handleOnClose
    };

    return (
        <Modal {...modalSettings} />
    );
}
