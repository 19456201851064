import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductPrice } from "../../Common/Pricing/ProductPrice";
import { FlightLegsList } from "./FlightLegsList";


export const FlightItemList = ({ item, isTemplate, isViewDetail = false, showPriceBar, showCost, extraParam, modalOpenProductId, onClickSearch, fareId, modalOpenFareDetail, removeTrippie, updateTrippie }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!extraParam)
            extraParam = {};

        extraParam.productId = item.uniqueId;
    }, [item]);

    function onModalOpenProductId() {
        modalOpenProductId(item);
    }

    function onModalOpenFareDetail() {
        modalOpenFareDetail(item);
    }

    function handleClickSearch() {
        onClickSearch({ itineraryId: item.uniqueId, fareId: item.fares && item.fares[0] && item.fares[0].uniqueId });
    }

    return (
        <>
            <div className={isTemplate ? "" : "card shadow shadow-hover mb-4 animate-up-3 p-1"}>
                <div className="row no-gutters">
                    <div className={!isViewDetail ? "col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10" : "col-12"} >
                        <div className="card-body bg-white pt-2 px-0 pb-0 p-2 rounded mb-2 pr-4">
                            <div>
                                <div className="w-100">
                                    {/* per il flight List, no segmenti */}
                                    {!isViewDetail && item && item.legs && item.legs.length > 0 && item.legs.map((product, key) => {
                                        return <React.Fragment key={key}>
                                            <FlightLegsList item={product}
                                                airPlaneLogo={product.mktAirlineLogo}
                                                airPlaneName={product.mktAirlineName}
                                                airPlaneIATA={product.mktAirlineIATA}
                                                arrivalAirportName={product.arrivalAirportName}
                                                arrivalAirportIATA={product.arrivalAirportIATA}
                                                index={key}
                                                fares={item.fares}
                                                modalOpenProductId={onModalOpenProductId}
                                                modalOpenFareDetail={onModalOpenFareDetail}
                                                isViewDetail={isViewDetail}
                                            />
                                        </React.Fragment>
                                    })}

                                    {/* per il flight Detail, con i segmenti */}
                                    {isViewDetail && item && item.legs && item.legs.length > 0 && item.legs.map((leg, key) =>
                                        leg.segments && leg.segments.length > 0 && leg.segments.map((segment, keySegments) => {
                                            return <React.Fragment key={keySegments}>
                                                <FlightLegsList item={segment}
                                                    airPlaneLogo={leg.mktAirlineLogo}
                                                    airPlaneName={leg.mktAirlineName}
                                                    airPlaneIATA={leg.mktAirlineIATA}
                                                    arrivalAirportName={leg.arrivalAirportName}
                                                    arrivalAirportIATA={leg.arrivalAirportIATA}
                                                    index={key}
                                                    fares={fareId ? item.fares.filter(x => x.uniqueId === parseInt(fareId)) : item.fares}
                                                    modalOpenProductId={onModalOpenProductId}
                                                    modalOpenFareDetail={onModalOpenFareDetail}
                                                    isViewDetail={isViewDetail}
                                                    isSegment={leg.stops && leg.stops.stopsDurations && leg.stops.stopsDurations[keySegments - 1] ? true : false}
                                                />
                                                {leg.stops && leg.stops.stopsDurations && leg.stops.stopsDurations[keySegments] &&
                                                    <div className="row">
                                                        <div className="col-2 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                                                            <label></label>
                                                        </div>
                                                        <div className="col-10 col-sm-11 col-md-11 col-lg-11 col-xl-11 bg-gray-100 rounded text-center">
                                                            <span className="h075">
                                                                <data m3lab="Product.Flight.Stopover">{t('Product:Flight:Stopover')}</data>
                                                            </span>
                                                            <span className="h075 ml-1">
                                                                {leg.stops.stopsDurations[keySegments].duration} h -
                                                            </span>
                                                            <span className="h075 ml-1">
                                                                <data m3lab="Product.Flight.AirportOf">{t('Product:Flight:AirportOf')}</data>
                                                            </span>
                                                            <span className="h075 ml-1">
                                                                ({leg.stops.stopsDurations[keySegments].arrivalAirportIATA}) {leg.stops.stopsDurations[keySegments].arrivalAirportName}
                                                            </span>
                                                            {
                                                                leg.stops.isConnectionLong && <>
                                                                    <br />
                                                                    <label class="bg-danger px-2 text-white h6">Connection long</label>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        })
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        !isViewDetail && <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-1 pr-3">
                            <ProductPrice
                                itineraryId={item.uniqueId}
                                pricing={item.fares && item.fares[0] ? item.fares[0].price : null}
                                supplier={item.fares && item.fares[0] ? item.fares[0].supplier : null}
                                isPackageTO={item.fares[0].hasRestriction}
                                showCost={showCost}
                                showPriceBar={showPriceBar}
                                typePrice="TotPrice"
                                otherParam={{ mealPlanId: item.bestMealPlanId, buttonEnabled: true }}
                                onClickSearch={handleClickSearch}
                                templateInfo={extraParam.templateInfo}
                                isTrippie={extraParam && extraParam.isTrippie}
                                showPriceTrippie={extraParam && extraParam.showPriceTrippie}
                                removeTrippie={removeTrippie}
                                updateTrippie={updateTrippie}
                            />
                        </div>
                    }
                </div>
            </div>
        </>
    );
}