import React, { useState, useEffect } from "react";
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../Common/M3Icon";
import { InputSearchDestinationOnlyRead } from './InputSearchDestinationOnlyRead';
import { DebounceInput } from 'react-debounce-input';
import { closeTpSearchInput } from '../../../js/Utils';
import configData from "../../../appsettings.json";

export const InputSearchHotel = ({ externalId, selectedDestinationFromEngine, defaultSelectHotels, onSelectSearchHotel, onUnselectSearchHotel, modeView, closeExternal, clearSearch, onOpenPanelHotel }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    const [isLoading, setIsLoading] = useState(false);

    const [selectDestination, setSelectDestination] = useState(null);
    const [showDestination, setShowDestination] = useState(false);
    const [autoFocus, setAutoFocus] = useState(false);
    const [modalStyle, setModalStyle] = useState({
        overflow: 'unset',
        height: '0px',
        zIndex: '200'
    });

    const [idModal] = useState(getRandomInt());
    function getRandomInt() {
        return Math.floor(Math.random() * 100000);
    }
    const modalId = "Destinations_" + idModal;

    const pereferiteHotel = { name: "Cerca solo tra i tuoi preferiti", structureId: -999 };
    //const [hotels, setHotels] = useState([pereferiteHotel]); // uncomment this line and comment line below to enable favorite
    const [hotels, setHotels] = useState([]);
    const [notHotelFound, setNotHotelFound] = useState(false);


    useEffect(() => {
        let modalIdName = "Destinations_" + idModal;
        let modalDestination = document.getElementById(modalIdName);
        if (modalDestination) {
            modalDestination.addEventListener('shown.bs.modal', function () {
                let windowScreenWidth = window.screen.width;
                let componentWidth = document.getElementById(modalIdName).getBoundingClientRect().width;
                let componentStartingPoint = document.getElementById(modalIdName).getBoundingClientRect().x;
                let overflowWidth = componentStartingPoint + componentWidth - windowScreenWidth;
                if (overflowWidth > 0) {
                    setModalStyle({
                        maxWidth: componentWidth - overflowWidth - 20 + 'px',
                        overflow: 'unset',
                        height: '0px',
                        zIndex: '200'
                    });
                } else {
                    setModalStyle({});
                }

                setAutoFocus(true);
            });

            modalDestination.addEventListener('hidden.bs.modal', function () {
                setModalStyle({});
                setAutoFocus(false);
            });
        }

        if (modeView === 'SEARCHENGINE')
            closeTpSearchInput(modalId, closePanelHotel);
    }, [])

    useEffect(() => {
        setSelectDestination(defaultSelectHotels);
    }, [defaultSelectHotels]);

    useEffect(() => {
        setShowDestination(closeExternal);
    }, [closeExternal]);

    useEffect(() => {
        let diFindHotel = document.getElementById('diFindHotel');
        if (diFindHotel) {
            diFindHotel.focus();
            diFindHotel.select();
        }
    }, [autoFocus]);


    function callOnSelectHotel(selectDestination) {
        setSelectDestination(selectDestination);
        setShowDestination(false);
        if (!externalId)
            onSelectSearchHotel(selectDestination);
        else
            onSelectSearchHotel(selectDestination, externalId);

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnCloseModalDestination_" + idModal);
            btn.click();
        }
    }

    function openPanelHotel() {
        setShowDestination(true);

        if (!externalId)
            onOpenPanelHotel();
        else
            onOpenPanelHotel(externalId);

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnModalDestination_" + idModal);
            btn.click();
        }
    }

    function closePanelHotel() {
        setShowDestination(false);

        try {

            let myModal = document.getElementById(idModal);
            myModal.style.setProperty("height", "0px");

        } catch (ex) { }

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnCloseModalDestination_" + idModal);
            btn.click();
        }
    }

    function clearSelection() {
        setSelectDestination(null);

        if (!externalId)
            onSelectSearchHotel(null);
        else
            onSelectSearchHotel(null, externalId);
    }

    async function callGetHotels(searchText) {
        if (searchText.length < 3)
            return;

        setIsLoading(true);

        let destinationId = selectedDestinationFromEngine && selectedDestinationFromEngine.Type === "Destination" ? selectedDestinationFromEngine.Id : null;

        var request = {
            Text: searchText,
            CultureCode: cultureName,
            Limit: 50,
            Destinations: destinationId
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(request) };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}structure/GetHotels`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            let tmpHotels = [];
            //tmpHotels.push(pereferiteHotel);

            if (response.success && response.hotels && response.hotels.length > 0) {
                tmpHotels = tmpHotels.concat(response.hotels);
            } else {
                setHotels([]);
                setNotHotelFound(true);
            }

            setHotels(tmpHotels);
        }

        setIsLoading(false);
    }

    function onSelectHotel(item) {
        callOnSelectHotel(item);
    }

    function onUnselectHotel(item) {
        onUnselectSearchHotel(item);
    }


    return (
        <>
            <div className="w-100">
                {!showDestination &&
                    <>
                        <InputSearchDestinationOnlyRead
                            defaultSelectDestination={{ Text: defaultSelectHotels && defaultSelectHotels.structureId === -999 ? defaultSelectHotels.name : null }}
                            openPanelDestination={openPanelHotel}
                            productType={0}
                            extraOptions={{ iconName: "Structure", label: t(`Product:Structure:Item`), inputText: t(`SearchEngine:FindHotel`) }}
                            clearSelection={clearSelection}
                        />

                        {defaultSelectHotels && defaultSelectHotels.length > 0 &&
                            <div className="mt-2">
                                <ul className="list-unstyled">
                                    {defaultSelectHotels.map((hotel, index) => {
                                        let hotelName = hotel.structureId === -999 ? "Preferiti" : hotel.name;
                                        return <li className="mb-2">
                                            <span className="p-2 bg-white text-custom rounded">{hotelName}
                                                <a href onClick={e => onUnselectHotel(hotel)} className="cursor-pointer">
                                                    <M3Icon iconName="Close" externalClass="pb-1 align-middle" />
                                                </a>
                                            </span>
                                        </li>;
                                    })}
                                </ul>
                            </div>
                        }
                    </>
                }
            </div>

            <a id={"btnModalDestination_" + idModal} data-bs-toggle="modal" data-bs-target={"#" + modalId}></a>

            <div className='d-block'>
                <div className={"modal tp-search-input position-relative " + (modeView === 'MODAL' ? "d-block" : "fade")}
                    id={modalId}
                    tabIndex="-1"
                    aria-labelledby={"DestinationsLabel_" + modalId}
                    aria-hidden="true"
                    style={modalStyle}
                >

                    <div className="modal-dialog">
                        {
                            showDestination && <div className="modal-content">
                                <div className="modal-header p-0">
                                    <DebounceInput
                                        id="diFindHotel"
                                        className="form-control border-0 shadow-none w-100 rounded"
                                        minLength={3}
                                        placeholder={t('SearchEngine:FindHotel')}
                                        debounceTimeout={1000}
                                        value=""
                                        onChange={event => callGetHotels(event.target.value.trim())}
                                    />
                                    <button type="button" className="btn-close mt-2 mr-1 m-auto" onClick={_ => closePanelHotel()}></button>
                                </div>
                                <div className="modal-body">
                                    <ul className="list-group overflow-300">
                                        {isLoading &&
                                            <li className="list-group-item border-0 p-0 ">
                                                <div className="d-table-cell h-100 align-middle"></div>
                                                <div className="d-table-cell p-2">
                                                    <p className="p-0 m-0 h075">
                                                        <div className="spinner-border spinner-border-sm text-primary" role="status">
                                                            <span className="sr-only"></span>
                                                        </div>
                                                    </p>
                                                    <label className="h065"></label>
                                                </div>
                                            </li>
                                        }

                                        {!isLoading && hotels && hotels.length > 0 && hotels.map((hotel, index) => (
                                            <li id={hotel.structureId}
                                                key={index}
                                                className={`list-group-item border-0 p-0 cursor-pointer ${hotel.structureId === -999 ? "mb-2 border-bottom" : ""}`}
                                                onClick={_ => onSelectHotel(hotel)}
                                            >
                                                <div className="d-table-cell h-100 align-middle">
                                                    {hotel.structureId === -999
                                                        ? (<M3Icon iconName="Favorite" externalClass="" />)
                                                        : (<M3Icon iconName="Structure" externalClass="" />)
                                                    }
                                                </div>
                                                <div className="d-table-cell p-2">
                                                    <p className="p-0 m-0 h075">
                                                        {hotel.name}{hotel.address ? `, ${hotel.address.city}` : ""}
                                                    </p>
                                                </div>
                                            </li>
                                        ))
                                        }

                                        {!isLoading && notHotelFound &&
                                            <li className="list-group-item border-0 p-0 ">
                                                <div className="d-table-cell h-100 align-middle"></div>
                                                <div className="d-table-cell p-2">
                                                    <p className="p-0 m-0 h075">
                                                        <data m3lab="SearchEngine:HotelsNotFound">
                                                            {t('SearchEngine:HotelsNotFound')}
                                                        </data>
                                                    </p>
                                                    <label className="h065"></label>
                                                </div>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        }

                        <a id={"btnCloseModalDestination_" + idModal} data-bs-dismiss="modal"></a>
                    </div>
                </div>
            </div>
        </>
    );
}
