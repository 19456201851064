import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { useAuth } from '../../Auth/AuthProvider';
import { MenuBarVertical } from '../MenuBarVertical';
import { Loading } from '../../Common/Loading';
import { StructureDetail } from '../../Reservation/StructureDetail';
import { ActivityDetail } from '../../Reservation/ActivityDetail';
import { CarRentalDetail } from '../../Reservation/CarRentalDetail';
import { TransferDetail } from '../../Reservation/TransferDetail';
import { FlightDetail } from '../../Reservation/FlightDetail';
import { TrainDetail } from '../../Reservation/TrainDetail';
import { TourDetail } from '../../Reservation/TourDetail';
import { CruiseDetail } from '../../Reservation/CruiseDetail';
import { GenericDetail } from '../../Reservation/GenericDetail';
import { Documents } from './Documents';
import { OrderDetailScadenziario } from './OrderDetailScadenziario';
import { getCurrentUserFromJwtToken, formatPrice, formatDateTime, scrollTo } from '../../../js/Utils.js';
import configData from "../../../appsettings.json";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PrintPdfDocuments } from '../../Common/PrintPdfDocuments';
import { printCssStyle } from './../../Custom/Print/PrintCss';
import { downloadDocumentPDF } from "../../../js/PrintUtility";
import { generateQuotationHTMLConfirm } from "./../../Print/service/ConfirmPrintService.js";
import { generateQuotationHTMLDocViaggio } from "./../../Print/service/DocViaggioPrintService.js";
import { M3IconProductType } from '../../Common/M3IconProductType.js';
import { M3Icon } from '../../Common/M3Icon.js';

export const OrderDetail = () => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const { onChangeModuleSelected, getDefaultModule, setAddToOrderSession } = useAuth();

    const cultureName = cookies.get("CultureName");
    const { orderNumber } = useParams();
    const [myHtml, setMyHtml] = useState([]);
    const [myHtmlDocumentiDiViaggio, setMyHtmlDocumentiDiViaggio] = useState([]);
    const [websiteId, setWebsiteId] = useState();
    const pdfFileName = "ConfermaPrenotazione" + orderNumber;
    const pdfFileNameDocViaggio = "DocumentiViaggio" + orderNumber;

    const [isLoading, setIsLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [prodottiEnabled, setProdottiEnabled] = useState([]);
    const [myOrder, setMyOrder] = useState({});

    const [extraInfoPrint, setExtraInfoPrint] = useState({});
    const [extraInfoPrintDocumentiDiViaggio, setExtraInfoPrintDocumentiDiViaggio] = useState({});
    const [isFinishPrintPDF, setIsFinishPrintPDF] = useState(0);
    const [isFinishPrintPDFDocumentiDiViaggio, setIsFinishPrintPDFDocumentiDiViaggio] = useState(0);
    const [isDownloadPDF, setIsDownloadPDF] = useState(true);
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);
    const [isDownloadPDFDocumentiDiViaggio, setIsDownloadPDFDocumentiDiViaggio] = useState(true);
    const [isLoadingPrintDocumentiDiViaggio, setIsLoadingPrintDocumentiDiViaggio] = useState(false);
    const [quotationItemStepPrint, setQuotationItemStepPrint] = useState([]);
    const [customFunctionSingleProduct, setCustomFunctionSingleProduct] = useState({});
    const [customFunctionGetScadenziario, setCustomFunctionGetScadenziario] = useState({});
    const [tipoProdotto, setTipoProdotto] = useState([]);
    const [quotationItemStep, setQuotationItemStep] = useState([]);
    const [dynamicNotes, setDynamicNotes] = useState([]);
    const [dynamicNotesDocViaggi, setDynamicNotesDocViaggi] = useState([]);
    const [dynamicNotesTop, setDynamicNotesTop] = useState([]);
    const [scadenziarioPrint, setScadenziarioPrint] = useState([]);

    // net prices
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);
    const [showNetPrices, setShowNetPrices] = useState(false);
    const [showCost, setShowCost] = useState(false);

    const [userAddToOrder, setUserAddToOrder] = useState(false);
    const [userShowPrint, setUserShowPrint] = useState(false);
    const [pricingB2C, setPricingB2C] = useState(false);
    const [userShowDocumentiDiViaggio, setUserShowDocumentiDiViaggio] = useState(false);
    const [paxes, setPaxes] = useState([]);
    const [isVisibleScadenziarioPayment, setIsVisibleScadenziarioPayment] = useState(true);

    let oldArrTipoProdotto = [...tipoProdotto];

    useEffect(() => {
        const getOrders = async (inputData) => {
            const response = callGetOrders(inputData);
        }

        let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
        let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);
        setWebsiteId(currentUser.websiteId);

        if (currentUser.roles !== undefined) {
            if (!currentUser.roles.includes("FE_NonVisualizzaNetto")) setEnableShowNetPrices(true);
            if (!currentUser.roles.includes("FE_ShowCost")) setShowCost(true);
            if (currentUser.roles.includes("MB_AddToOrder")) setUserAddToOrder(true);
            if (currentUser.roles.includes("US_OrderDetail_ShowPrint")) setUserShowPrint(true);
            if (currentUser.roles.includes("US_PricingB2C")) setPricingB2C(true);
        }

        const getPaxes = async (inputData) => {
            const response = callGetPaxes(inputData);
        }

        getPaxes(orderNumber);

        setIsLoading(true);
        getOrders({ NumeroOrdine: orderNumber, pageNum: 1 });

        setCustomFunctionSingleProduct({
            onCallSaveProductInfo: onCallSaveProductInfoStep,
        });

        setCustomFunctionGetScadenziario({
            onCallSaveScadenziario: onCallSaveScadenziario,
        });
    }, []);

    useEffect(() => {
        setIsLoadingPrint(false);
    }, [isFinishPrintPDF]);

    useEffect(() => {
        setIsLoadingPrintDocumentiDiViaggio(false);
    }, [isFinishPrintPDFDocumentiDiViaggio]);


    function getProductType(idTipoProdotto) {
        if (oldArrTipoProdotto.indexOf(idTipoProdotto) === -1) {
            oldArrTipoProdotto.push(Number(idTipoProdotto));
            setTipoProdotto(oldArrTipoProdotto);
        }
    }

    function onCallSaveScadenziario(scadenziario) {
        if (scadenziario && scadenziario.rate && scadenziario.rate.length > 0) {
            let resultObj = scadenziario.rate.reduce(function (r, a) {
                let data = formatDateTime(a.expirePaymentDate, cultureName);
                r[data] = r[data] || [];
                r[data].push(a);

                return r;
            }, Object.create(null));

            var resultArr = Object.entries(resultObj);

            setScadenziarioPrint(resultArr);
        }
    }

    function onCallSaveProductInfoStep(item, type, id) {
        callOnCallSaveProductInfo(item, type, id);
    }

    function callOnCallSaveProductInfo(response, type, id) {
        let isFound = false;

        let quotationItemStepToPrint = [...quotationItemStepPrint];
        if (quotationItemStepPrint && quotationItemStepPrint.length === 0)
            quotationItemStepToPrint = [...quotationItemStep];

        let productSubType = "";
        let idTipoProdotto = null;
        let productType = "";

        if (response.productType === configData.Settings.Products.Structure.IdTipoProdotto) {
            productType = "Structure";
            productSubType = "Hotel";
            idTipoProdotto = configData.Settings.Products.Structure.IdTipoProdotto;
        }
        else if (response.productType === configData.Settings.Products.Tour.IdTipoProdotto) {
            productSubType = "Tour";
            productType = "Tour";
            idTipoProdotto = configData.Settings.Products.Tour.IdTipoProdotto;
        }
        else if (response.productType === configData.Settings.Products.Activity.IdTipoProdotto) {
            productType = "Activity";
            if (response.productSubType === 12)
                productSubType = "Tour";
            else
                productSubType = "Activity";

            idTipoProdotto = configData.Settings.Products.Activity.IdTipoProdotto;
        }
        else if (response.productType === configData.Settings.Products.CarRental.IdTipoProdotto) {
            productSubType = "CarRental";
            productType = "CarRental";
            idTipoProdotto = configData.Settings.Products.CarRental.IdTipoProdotto;
        }
        else if (response.productType === configData.Settings.Products.Transfer.IdTipoProdotto) {
            productSubType = "Transfer";
            productType = "Transfer";
            idTipoProdotto = configData.Settings.Products.Transfer.IdTipoProdotto;
        }
        else if (response.productType === configData.Settings.Products.Flight.IdTipoProdotto) {
            productSubType = "Flight";
            productType = "Flight";
            idTipoProdotto = configData.Settings.Products.Flight.IdTipoProdotto;
        }
        else if (response.productType === configData.Settings.Products.Train.IdTipoProdotto) {
            productSubType = "Train";
            productType = "Train";
            idTipoProdotto = configData.Settings.Products.Train.IdTipoProdotto;
        }
        else if (response.productType === configData.Settings.Products.Cruise.IdTipoProdotto) {
            productSubType = "Cruise";
            productType = "Cruise";
            idTipoProdotto = configData.Settings.Products.Cruise.IdTipoProdotto;
        }
        else {
            productSubType = "Generic";
            productType = "Generic";
            idTipoProdotto = configData.Settings.Products.Generic.IdTipoProdotto;
        }

        if (response.priceBar &&
            (
                response.priceBar.status === "CONFIRMED" ||
                response.priceBar.status === "ONREQUEST" ||
                response.priceBar.status === "PRINTED" ||
                response.priceBar.status === "REFUND" ||
                response.priceBar.status === "PENALTY"
            )
        ) {
            setQuotationItemStepPrint(arr => [...arr, {
                productSubType: productSubType,
                productInfo: response,
                productType: productType,
            }]);
        }

        // se ho il paymentdata popolato vuol dire che posso pagare con carta
        // se c'è ne almeno uno NON popolato, vuol dire che è gia stato pagato
        // quindi li nascondo
        if (response.priceBar && !response.priceBar.paymentData) {
            setIsVisibleScadenziarioPayment(false);
        }

        const getDynamicNote = async (inputData) => {
            const response = callGetDynamicNote(inputData);
        }

        getDynamicNote({
            cultureCode: cultureName,
            websiteId: websiteId,
            pageId: 13, // conferma prenotazione
            productTypes: [idTipoProdotto],
            productCode: response.productId,
            checkInFrom: response.priceBar ? response.priceBar.startDate : null,
            checkInTo: response.priceBar ? response.priceBar.endDate : null,
            webDestinationIds: response.webDestinationIds ? response.webDestinationIds : null,
            supplierId: response.supplierId ? response.supplierId : null,
        });

        const getDynamicNoteDocViaggi = async (inputData) => {
            const response = callGetDynamicNoteDocViaggi(inputData);
        }

        getDynamicNoteDocViaggi({
            cultureCode: cultureName,
            websiteId: websiteId,
            pageId: 12, // doc Viaggi
            productTypes: [idTipoProdotto],
            productCode: response.productId,
            checkInFrom: response.priceBar ? response.priceBar.startDate : null,
            checkInTo: response.priceBar ? response.priceBar.endDate : null,
            webDestinationIds: response.webDestinationIds ? response.webDestinationIds : null,
            supplierId: response.supplierId ? response.supplierId : null,
        });


        const getDynamicNoteTop = async (inputData) => {
            const response = callGetDynamicNoteTop(inputData);
        }

        getDynamicNoteTop({
            cultureCode: cultureName,
            websiteId: websiteId,
            pageId: 15, // viaggi top docs
            productTypes: [idTipoProdotto],
            productCode: response.productId,
            checkInFrom: response.priceBar ? response.priceBar.startDate : null,
            checkInTo: response.priceBar ? response.priceBar.endDate : null,
            webDestinationIds: response.webDestinationIds ? response.webDestinationIds : null,
            supplierId: response.supplierId ? response.supplierId : null,
        });
    }

    async function callGetDynamicNote(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'dynamicnote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();

        if (response.dynamicNotes != null && response.dynamicNotes.length > 0) {
            setDynamicNotes(arr => [...arr, response.dynamicNotes]);
        }
    }

    async function callGetDynamicNoteDocViaggi(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'dynamicnote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();

        if (response.dynamicNotes != null && response.dynamicNotes.length > 0) {
            setDynamicNotesDocViaggi(arr => [...arr, response.dynamicNotes]);
        }
    }

    async function callGetDynamicNoteTop(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'dynamicnote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();

        if (response.dynamicNotes != null && response.dynamicNotes.length > 0) {
            setDynamicNotesTop(arr => [...arr, response.dynamicNotes]);
        }
    }

    async function callGetOrders(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/getorders', requestOption);
        const response = await fetchedRes.json();

        setOrders(response.orders);
        setProdottiEnabled(response.idModuliEnabled);

        if (response.orders !== undefined && response.orders !== null && response.orders.length > 0) {
            setMyOrder(response.orders[0]);
        }

        let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
        let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);
        let showDocumentiDiViaggio = false;
        let orders = response.orders[0];
        if (orders && orders.pratiche && orders.enableTravelDoc && !orders.pratiche.every(x => { return x.status === "DELETED" })) {
            try {
                let tipiPratiche = new Set(orders.pratiche.filter(x => { return x.status !== "DELETED" && x.tipoPratica !== 'X' }).map(x => x.tipoPratica)).size;
                showDocumentiDiViaggio = tipiPratiche > 0;
            } catch (ex) {
                console.error(ex);
            }
        }
        else {
            showDocumentiDiViaggio = false;
        }

        setUserShowDocumentiDiViaggio(showDocumentiDiViaggio);

        setIsLoading(false);
    }

    async function callGetPaxes() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetOrderPaxes/' + orderNumber, requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setPaxes(response);
        };
    }

    const renderSummary = (pratiche) => {
        return (
            <>
                {pratiche.map(pratica =>
                    <tr key={pratica.idPratica} data-numpratica={pratica.wsBookingId}>
                        <td scope="row">
                            <M3IconProductType productType={pratica.tipoPratica} externalClass="h2" />
                        </td>
                        <td>
                            <div className="d-flex align-items-lg-start">
                                {pratica.productName}
                            </div>
                        </td>
                        <td>{formatDateTime(pratica.startDate, cultureName, { noYear: true })}</td>
                        <td>{formatDateTime(pratica.endDate, cultureName, { noYear: true })}</td>
                        {
                            orders && orders.reservationDetail && orders.reservationDetail.showPriceQuotationTOADV && <td>{formatPrice((!pricingB2C ? pratica.invoicePrice : pratica.prezzoCliente), pratica.currency, cultureName)}</td>
                        }
                        <td>{pratica.wsBookingId}</td>
                        <td className="d-flex align-items-end"><a href="#" className="text-decoration-none mt-2" onClick={event => scrollTo(event, 'aDetail_' + pratica.wsBookingId)}><span className="badge">{t("MyBook:Detail")}</span></a></td>
                    </tr>
                )}
            </>
        );
    }

    const renderSummaryCommissionable = (pratiche) => {
        return (
            <>
                {pratiche.map(pratica =>
                    pratica.isActive && pratica.isCommissionable
                        ? (
                            <tr key={pratica.idPratica} data-numpratica={pratica.wsBookingId} className="p-0">
                                <td>{pratica.productName}</td>
                                <td>{formatPrice((!pricingB2C ? pratica.invoicePrice : pratica.prezzoCliente), pratica.currency, cultureName)}</td>
                            </tr>
                        )
                        : (null)
                )}
            </>
        );
    }
    const renderSummaryNotCommissionable = (pratiche) => {
        return (
            <>
                {pratiche.map(pratica =>
                    pratica.isActive && !pratica.isCommissionable
                        ? (
                            <tr key={pratica.idPratica} data-numpratica={pratica.wsBookingId} className="p-0">
                                <td>{pratica.productName}</td>
                                <td>{formatPrice((!pricingB2C ? pratica.invoicePrice : pratica.prezzoCliente), pratica.currency, cultureName)}</td>
                            </tr>
                        )
                        : (null)
                )}
            </>
        );
    }
    const getTotalCommissionable = (pratiche) => {
        let total = (pratiche.reduce((a, v) => a = a + (v.isActive ? ((v.isCommissionable ? v.commissioneAgenzia : 0)) : 0), 0));
        let outPrice = formatPrice(total, pratiche[0].currency, cultureName);
        return outPrice;
    }
    const getTotalNotCommissionable = (pratiche) => {
        let total = (pratiche.reduce((a, v) => a = a + (v.isActive ? (v.isCommissionable ? 0 : (!pricingB2C ? v.invoicePrice : v.prezzoCliente)) : 0), 0));
        let outPrice = formatPrice(total, pratiche[0].currency, cultureName);
        return outPrice;
    }

    const renderProducts = (pratiche) => {
        return (
            <div className="col-12 col-lg-12">
                {pratiche.map(pratica =>
                    <div key={pratica.idPratica} data-numpratica={pratica.wsBookingId} className="row">
                        {pratica.tipoPratica === configData.Settings.Products.Structure.Code && <StructureDetail id={pratica.wsBookingId} type={"MyBook"} showNetPrices={showNetPrices} showCost={showCost} customFunctions={customFunctionSingleProduct} />}
                        {pratica.tipoPratica === configData.Settings.Products.Activity.Code && <ActivityDetail id={pratica.wsBookingId} type={"MyBook"} showNetPrices={showNetPrices} showCost={showCost} customFunctions={customFunctionSingleProduct} />}
                        {pratica.tipoPratica === configData.Settings.Products.CarRental.Code && <CarRentalDetail id={pratica.wsBookingId} type={"MyBook"} showNetPrices={showNetPrices} showCost={showCost} customFunctions={customFunctionSingleProduct} />}
                        {pratica.tipoPratica === configData.Settings.Products.Transfer.Code && <TransferDetail id={pratica.wsBookingId} type={"MyBook"} showNetPrices={showNetPrices} showCost={showCost} customFunctions={customFunctionSingleProduct} />}
                        {pratica.tipoPratica === configData.Settings.Products.Flight.Code && <FlightDetail id={pratica.wsBookingId} type={"MyBook"} showNetPrices={showNetPrices} showCost={showCost} customFunctions={customFunctionSingleProduct} />}
                        {pratica.tipoPratica === configData.Settings.Products.Train.Code && <TrainDetail id={pratica.wsBookingId} type={"MyBook"} showNetPrices={showNetPrices} showCost={showCost} customFunctions={customFunctionSingleProduct} />}
                        {pratica.tipoPratica === configData.Settings.Products.Cruise.Code && <CruiseDetail id={pratica.wsBookingId} type={"MyBook"} showNetPrices={showNetPrices} showCost={showCost} customFunctions={customFunctionSingleProduct} />}
                        {pratica.tipoPratica === configData.Settings.Products.Tour.Code && <TourDetail id={pratica.wsBookingId} type={"MyBook"} showNetPrices={showNetPrices} showCost={showCost} customFunctions={customFunctionSingleProduct} />}
                        {pratica.tipoPratica === configData.Settings.Products.Generic.Code && <GenericDetail id={pratica.wsBookingId} type={"MyBook"} showNetPrices={showNetPrices} showCost={showCost} prodottoCorrelatoInfo={pratica.prodottoCorrelatoInfo} customFunctions={customFunctionSingleProduct} />}
                    </div>
                )}
            </div>
        )
    }

    const handleShowNetPrices = async e => {
        setShowNetPrices(!showNetPrices);
        renderProducts(orders[0].pratiche);
    }


    async function addToOrder(moduleId) {
        // generate orderInfo
        let oldProductPNRs = [];
        for (let i = 0; i < orders[0].pratiche.length; i++) {
            oldProductPNRs.push(orders[0].pratiche[i].wsBookingId);
        }
        let orderInfo = { OrderNumber: orderNumber, ProductTypeToAdd: Number(moduleId), OldProductPNRs: oldProductPNRs };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(orderInfo) };
        const response = await fetch(configData.Settings.CommonApi_BaseUrl + 'auth/SessionRegisterAddToOrder', requestOption).then(data => data.json());

        if (response.success) {
            let pageName = ''

            switch (response.productType) {
                case configData.Settings.Products.Activity.IdTipoProdotto: {
                    pageName = 'ACTIVITY';
                    break;
                }
                case configData.Settings.Products.CarRental.IdTipoProdotto: {
                    pageName = 'CARRENTAL';
                    break;
                }
                case configData.Settings.Products.Cruise.IdTipoProdotto: {
                    pageName = 'CRUISE';
                    break;
                }
                case configData.Settings.Products.Flight.IdTipoProdotto: {
                    pageName = 'FLIGHT';
                    break;
                }
                case configData.Settings.Products.Structure.IdTipoProdotto: {
                    pageName = 'HOTEL';
                    break;
                }
                case configData.Settings.Products.Tour.IdTipoProdotto: {
                    pageName = 'TOUR';
                    break;
                }
                case configData.Settings.Products.Transfer.IdTipoProdotto: {
                    pageName = 'TRANSFER';
                    break;
                }
                case configData.Settings.Products.Train.IdTipoProdotto: {
                    pageName = 'TRAIN';
                    break;
                }
                default: {
                    pageName = getDefaultModule;
                    break;
                }
            }

            setAddToOrderSession(orderInfo);
            onChangeModuleSelected(pageName);
            window.location.href = 'home';

        } else {
            window.location.reload(true);
        }
    }

    const addToOrderOnClose = () => {
        window.location.reload(true);
    }

    function dowloadPDFAfterRender(filename, url) {
        downloadDocumentPDF(filename, url);

        setIsFinishPrintPDF(isFinishPrintPDF + 1);
        setIsDownloadPDF(true);
    }

    function dowloadPDFAfterRenderDocumentiDiViaggio(filename, url) {
        downloadDocumentPDF(filename, url);

        setIsFinishPrintPDFDocumentiDiViaggio(isFinishPrintPDFDocumentiDiViaggio + 1);
        setIsDownloadPDFDocumentiDiViaggio(true);
    }

    var arrayPratiche = [];
    var finalArr = [];
    function ordinamnetoPrint() {
        // riordina array come ordine pratiche
        orders[0].pratiche.map((pratica, index) => {
            if (pratica.isActive) {
                arrayPratiche.push(pratica);
            }
            // ordina pratica
            for (let i = 0; i < quotationItemStepPrint.length; i++) {
                if (quotationItemStepPrint[i].productInfo.priceBar && quotationItemStepPrint[i].productInfo.priceBar.wsBookingId === pratica.wsBookingId) {
                    finalArr.push(quotationItemStepPrint[i]);
                    break;
                }
            }
        });
    }

    function callPrintPDF() {
        ordinamnetoPrint();

        let myArr = generateQuotationHTMLConfirm(
            orderNumber,
            orders[0].bookDate,
            finalArr,
            paxes,
            dynamicNotes,
            arrayPratiche,
            scadenziarioPrint,
            cultureName
        );

        setIsDownloadPDF(false);
        setMyHtml(myArr);
        setExtraInfoPrint({
            quotationId: orderNumber,
            paxes: paxes,
            startDate: orders[0].startDate,
            endDate: orders[0].endDate,
            titleFirstPage: 'CONFERMA DI PRENOTAZIONE',
        });
    }

    function callPrintDocViaggio() {
        ordinamnetoPrint();

        let myArr = generateQuotationHTMLDocViaggio(
            orderNumber,
            orders[0].bookDate,
            finalArr,
            paxes,
            dynamicNotesDocViaggi,
            dynamicNotesTop,
            arrayPratiche,
            scadenziarioPrint,
            cultureName,
            true
        );

        setIsDownloadPDFDocumentiDiViaggio(false);
        setMyHtmlDocumentiDiViaggio(myArr);
        setExtraInfoPrintDocumentiDiViaggio({
            quotationId: orderNumber,
            paxes: paxes,
            startDate: orders[0].startDate,
            endDate: orders[0].endDate,
            titleFirstPage: 'DOCUMENTI DI VIAGGIO',
        });
    }

    function onClickPrintPDF() {
        setIsLoadingPrint(true);
        callPrintPDF();
    }

    function onClickPrintPDFDocumentiDiViaggio() {
        setIsLoadingPrintDocumentiDiViaggio(true);
        callPrintDocViaggio();
    }

    return (
        <>
            <MenuBarVertical activeAnchorId="aOrdini" />

            <div className="section section-lg bg-gray-200 page-content" id="content">
                <div className="container">

                    {isLoading
                        ? (<Loading textMsg={t('MyBook:Loading')} />)
                        : (
                            <>
                                {orders === undefined || orders === null || orders.length === 0
                                    ? (
                                        <>
                                            <div className="mybook-order mybook-order-main p-0">
                                                <div className="card border-light p-0 p-md-4 mb-4">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {t("MyBook:NothingOrderFound")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                    : (
                                        <>
                                            {/*Order Block*/}
                                            <div className="p-0">
                                                <div className="card border-gray-400 p-0 p-md-4 mb-4">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-9 col-md-1 col-lg-1 border text-lg-center p-0  mybook-order-card">
                                                            <div><label>{t("MyBook:NOrder")}</label><h6>{orderNumber}</h6></div>
                                                            <div><label>{t("MyBook:Date")}</label> <h6>{formatDateTime(myOrder.bookDate, cultureName, { twoDigits: true })}</h6></div>
                                                        </div>
                                                        <div className="col-12 col-sm-9 col-md-9 col-lg-9  border">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-4  mybook-order-card">
                                                                    <div><label>{t("MyBook:OrderAccountholder")}</label> <h6>{myOrder.refPax}</h6></div>
                                                                    <div><label>{t("MyBook:BookingUser")}</label><h6>{myOrder.codiceAgente}</h6></div>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-8  mybook-order-card">
                                                                    <div className="row" style={{ display: 'none' }}>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 my-2">
                                                                            <span><label>{t("MyBook:ChooseHowAddOrder")}:</label> {t("MyBook:ClickOnICon")}</span>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                                                            <a href="#" className="mybook-order-addproduct"><i className="ph-buildings-bold h4" data-bs-toggle="tooltip" data-bs-placement="top" title="Hotel"></i></a>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                                                            <a href="#" className="mybook-order-addproduct"><i className="ph-person-simple-walk-bold h4" data-bs-toggle="tooltip" data-bs-placement="top" title="Activity"></i></a>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                                                            <a href="#" className="mybook-order-addproduct"><i className="ph-signpost-bold h4" data-bs-toggle="tooltip" data-bs-placement="top" title="Tour"></i></a>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                                                            <a href="#" className="mybook-order-addproduct"><i className="ph-airplane-bold h4" data-bs-toggle="tooltip" data-bs-placement="top" title="Volo"></i></a>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                                                            <a href="#" className="mybook-order-addproduct"><i className="ph-train-bold h4" data-bs-toggle="tooltip" data-bs-placement="top" title="Treno"></i></a>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                                                            <a href="#" className="mybook-order-addproduct"><i className="ph-car-bold h4" data-bs-toggle="tooltip" data-bs-placement="top" title="Auto"></i></a>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                                                            <a href="#" className="mybook-order-addproduct"><i className="ph-bus-bold h4" data-bs-toggle="tooltip" data-bs-placement="top" title="Transfer"></i></a>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                                                            <a href="#" className="mybook-order-addproduct"><i className="ph-boat-bold h4" data-bs-toggle="tooltip" data-bs-placement="top" title="Crociera"></i></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-9 col-md-6 col-lg-2 border text-lg-center  mybook-order-card">
                                                            <h6>{t("MyBook:OrderTotal")}</h6>
                                                            <h3>{formatPrice((!pricingB2C ? myOrder.totalInvoicePrice : myOrder.totalPrezzoCliente), myOrder.currency, cultureName)}</h3>

                                                            {userAddToOrder
                                                                ? (
                                                                    <p>
                                                                        <a href="" className="badge bg-primary text-decoration-none pb-2" data-bs-toggle="modal" data-bs-target={'#AddToOrder_' + orderNumber}>{t("MyBook:AddToOrder")}</a>
                                                                    </p>
                                                                )
                                                                : (<></>)
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/*Commission*/}
                                            {
                                                orders && orders.reservationDetail && orders.reservationDetail.showPriceQuotationTOADV && <div className="row mb-3">
                                                    <div className="card col-12 col-sm-12 col-md-12 col-lg-6 border-gray-400" id="bookingoffice">
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 text-center bg-gray-200 h-100 align-middle">
                                                                <div className="h6 text-danger">{t("MyBook:NotComm.")}</div>
                                                                <div className="h5 text-danger">
                                                                    {orders !== undefined && orders !== null && orders.length > 0
                                                                        ? (getTotalNotCommissionable(orders[0].pratiche))
                                                                        : ("0")
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                                                                <table className="p-0 mybook-table-min table-hover w-100">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">{t("MyBook:Voice")}</th>
                                                                            <th scope="col">{t("MyBook:Imp.")}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {orders !== undefined && orders !== null && orders.length > 0
                                                                            ? (renderSummaryNotCommissionable(orders[0].pratiche))
                                                                            : (<></>)
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card col-12 col-sm-12 col-md-12 col-lg-6 border-gray-400" id="bookingoffice">
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 text-center bg-gray-200">
                                                                <div className="h6 text-success">{t("MyBook:Commission")}</div>
                                                                <div className="h5 text-success">
                                                                    {orders !== undefined && orders !== null && orders.length > 0
                                                                        ? (getTotalCommissionable(orders[0].pratiche))
                                                                        : ("0")
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                                                                <table className="table-hover p-0 mybook-table-min w-100">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">{t("MyBook:Voice")}</th>
                                                                            <th scope="col">{t("MyBook:Imp.")}</th>
                                                                            <th scope="col">{t("MyBook:Comm.")}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            orders !== undefined && orders !== null && orders.length > 0
                                                                                ? (renderSummaryCommissionable(orders[0].pratiche))
                                                                                : (<></>)
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            }
                                            
                                            {/*Table Summary*/}

                                            <div className="row mb-3">
                                                <div className="card col-12 col-sm-12 col-md-12 col-lg-12 border-gray-400">
                                                    <div className="card border-light p-0 p-md-4 mb-4">
                                                        <table className="mybook-table table-hover p-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">{t("MyBook:Type")}</th>
                                                                    <th scope="col">{t("MyBook:Product")}</th>
                                                                    <th scope="col">{t("MyBook:Start")}</th>
                                                                    <th scope="col">{t("MyBook:End")}</th>
                                                                    {
                                                                        orders && orders.reservationDetail && orders.reservationDetail.showPriceQuotationTOADV && <th scope="col">{t("MyBook:Value")}</th>
                                                                    }
                                                                    <th scope="col">{t("MyBook:NPractice")}</th>
                                                                    <th scope="col">{t("MyBook:Detail")}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    orders !== undefined && orders !== null && orders.length > 0
                                                                        ? (renderSummary(orders[0].pratiche))
                                                                        : (<></>)
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            {/*Link detail page order*/}
                                            <div className="my-2">
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 my-1 small mybook-mt-15">
                                                        <M3Icon iconName="ArrowDown" externalClass="mr-2 h085" />{t("MyBook:ProductList")}
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 my-1 small mybook-mt-15">
                                                        <a href="#" onClick={event => scrollTo(event, 'aOrderPayments_' + orderNumber)} style={{ color: '#000000' }}><M3Icon iconName="ArrowDown" externalClass="mr-2 h085" />{t("MyBook:Payments")}</a>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 my-1 small mybook-mt-15">
                                                        <a href="#" onClick={event => scrollTo(event, 'aOrderDocuments_' + orderNumber)} style={{ color: '#000000' }}><M3Icon iconName="ArrowDown" externalClass="mr-2 h085" />{t("MyBook:Documents")}</a>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-3 my-1 small mybook-mt-15"></div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 text-center">
                                                        {enableShowNetPrices ? (<button className={(showNetPrices ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow"} m3rool="f_ShowPriceBar" onClick={(e) => { handleShowNetPrices() }}>
                                                            <data m3lab="Button.Net">
                                                                {t("Button:Net")}
                                                            </data>
                                                        </button>) : (<></>)}
                                                    </div>
                                                </div>
                                            </div>

                                            {/*Products*/}
                                            {
                                                orders !== undefined && orders !== null && orders.length > 0
                                                    ? (renderProducts(orders[0].pratiche))
                                                    : (<></>)
                                            }

                                            {/*Scadenziario*/}
                                            <div id={'aOrderPayments_' + orderNumber}></div>
                                            {
                                                orders !== undefined && orders !== null && orders.length > 0
                                                    ? (<OrderDetailScadenziario
                                                        orderNumber={orderNumber}
                                                        customFunctions={customFunctionGetScadenziario}
                                                        isVisibleScadenziarioPayment={isVisibleScadenziarioPayment}
                                                    />)
                                                    : (<></>)
                                            }

                                            {/*Documents*/}
                                            <div id={'aOrderDocuments_' + orderNumber}></div>
                                            {(userShowPrint || userShowDocumentiDiViaggio) && (orders && orders.length > 0 && orders[0].pratiche && !orders[0].pratiche.every(x => { return x.status === "DELETED" })) &&
                                                <div className="card border-light p-0 p-md-4 mb-4">
                                                    <div className="card-header">
                                                        <p className="text-sm mb-0">{t("MyBook:DownloadConfirmBooking")}:</p>
                                                    </div>
                                                    <ul className="list-group list-group-flush">
                                                        {
                                                            userShowPrint && <li className="list-group-item">
                                                                <div className="row align-items-center">
                                                                    <div className="col-auto">
                                                                        <i className="ph-file-doc-bold h2"></i>
                                                                    </div>
                                                                    <div className="col ml-n2" style={{ maxWidth: '696px' }}>
                                                                        <h6 className="text-sm mb-0">Pdf</h6>
                                                                        <p className="card-text small text-muted status confirm">
                                                                            {t("MyBook:BookingConfirm")}
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <div className="row">
                                                                            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 pr-5" style={{ minWidth: '100px' }}></div>
                                                                            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                                                <a href={`${configData.Settings.Base_Url}Documents/confirmBooking/${orderNumber}/${cultureName}`} target="_blank">
                                                                                    <span className="btn btn-md badge bg-primary text-white">{t("MyBook:Download")}</span>
                                                                                </a>
                                                                                {!isDownloadPDF && <PDFDownloadLink className="lnkPDFDownload" document={<PrintPdfDocuments html={myHtml} title={""} stylesheet={printCssStyle} extraInfo={extraInfoPrint} />} fileName={pdfFileName} >
                                                                                    {({ blob, url, loading, error }) => {
                                                                                        if (!loading && blob) {
                                                                                            dowloadPDFAfterRender(pdfFileName, URL.createObjectURL(blob));
                                                                                        }
                                                                                    }}
                                                                                </PDFDownloadLink>
                                                                                }
                                                                            </div>
                                                                            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                        }
                                                        {
                                                            userShowDocumentiDiViaggio && <li className="list-group-item">
                                                                <div className="row align-items-center">
                                                                    <div className="col-auto">
                                                                        <i className="ph-file-doc-bold h2"></i>
                                                                    </div>
                                                                    <div className="col ml-n2" style={{ maxWidth: '696px' }}>
                                                                        <h6 className="text-sm mb-0">Pdf</h6>
                                                                        <p className="card-text small text-muted status confirm">
                                                                            {t("MyBook:DocViaggio")}
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <div className="row">
                                                                            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 pr-5" style={{ minWidth: '100px' }}></div>
                                                                            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                                                <a href={`${configData.Settings.Base_Url}Documents/docBookViaggio/${orderNumber}/${cultureName}`} target="_blank">
                                                                                    <span className="btn btn-md badge bg-primary text-white">{t("MyBook:Download")}</span>
                                                                                </a>
                                                                                {!isDownloadPDFDocumentiDiViaggio && <PDFDownloadLink className="lnkPDFDownload" document={<PrintPdfDocuments html={myHtmlDocumentiDiViaggio} title={""} stylesheet={printCssStyle} extraInfo={extraInfoPrintDocumentiDiViaggio} />} fileName={pdfFileNameDocViaggio} >
                                                                                    {({ blob, url, loading, error }) => {
                                                                                        if (!loading && blob) {
                                                                                            dowloadPDFAfterRenderDocumentiDiViaggio(pdfFileNameDocViaggio, URL.createObjectURL(blob));
                                                                                        }
                                                                                    }}
                                                                                </PDFDownloadLink>
                                                                                }
                                                                            </div>
                                                                            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                            {
                                                orders !== undefined && orders !== null && orders.length > 0
                                                    ? (<Documents orderNumber={orderNumber} />)
                                                    : (<></>)
                                            }
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </div>

            <div className="modal fade" id={'AddToOrder_' + orderNumber} tabIndex="-1" aria-labelledby={'AddToOrder_' + orderNumber + 'Label'} aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{t("MyBook:OrderN.")} {orderNumber}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>{t("MyBook:SelectProductTOAdd")}:</p>
                            {prodottiEnabled !== null && prodottiEnabled !== undefined && prodottiEnabled.length > 0
                                ? (
                                    prodottiEnabled.map((id, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <button type="button" className="btn bt-sm" onClick={e => addToOrder(id)}>
                                                    <M3IconProductType productSubtype={id} hasLabel="true" />
                                                </button>
                                                {index % 4 === 3 ? (<><br /></>) : (<></>)}
                                            </React.Fragment>
                                        )
                                    })
                                )
                                : (<></>)
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal" onClick={addToOrderOnClose}>{t("MyBook:Close")} </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
