import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../../js/Utils.js';

export const CruiseItinerary = ({ itinerary }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const { t } = useTranslation();

    function getDayOfWeek(dayOfWeek) {
        let dateOfWeekLabel = "";

        switch (dayOfWeek) {
            case 0:
                dateOfWeekLabel = "Sunday";
                break;

            case 1:
                dateOfWeekLabel = "Monday";
                break;

            case 2:
                dateOfWeekLabel = "Tuesday";
                break;

            case 3:
                dateOfWeekLabel = "Wedsnday";
                break;

            case 4:
                dateOfWeekLabel = "Thursday";
                break;

            case 5:
                dateOfWeekLabel = "Friday";
                break;

            case 6:
                dateOfWeekLabel = "Saturday";
                break;

        }

        return t("Calendar:DayShort:"+ dateOfWeekLabel);
    }

    function renderLabel(portName) {

        if (portName === "ATSEA")
            return t("Product:Cruise:AtSea");

        return portName;
    }

    return (
        <>
            {
                itinerary && itinerary.segments && itinerary.segments.length > 0 && <>
                    <p className="h6"><var>{t("Product:Cruise:Itinerary")}</var></p>
                    <div className="card small text-justify bg-gray-100 rounded">
                        <table className="w-100   h085 ml-1 mr-3">
                            <tbody>
                                <tr className="text-center py-1 h6">
                                    <td className="w-10">{t("MyBook:Day")}</td>
                                    <td className="w-10">{t("MyBook:Date")}</td>
                                    <td className="w-50 text-left pl-2">{t("Product:Cruise:Port")}</td>
                                    <td className="w-10">{t("Product:Cruise:Departure")}</td>
                                    <td className="w-10''">{t("Product:Cruise:Arrival")}</td>
                                </tr>
                                {
                                    itinerary.segments.map((segment, index) => {
                                        return <tr key={index} className="text-center bg-white my-1 px-3">
                                            <td className="my-2 h6 h085 w-10">{getDayOfWeek(segment.dayOfWeek)}</td>
                                            <td className="w-10 h065">{formatDateTime(segment.date, cultureName)}</td>
                                            <td className="w-50 text-left pl-2">{renderLabel(segment.portName)}</td>
                                            <td className="w-10">{segment.departureTime ? segment.departureTime : "-"}</td>
                                            <td className="w-10''">{segment.arrivalTime ? segment.arrivalTime : "-"}</td>
                                        </tr>
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </>
            }
        </>
    );
}