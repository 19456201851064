import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { ProductHeader } from '../../Product/Common/ProductHeader';
import { Loading } from '../../Common/Loading';
import { M3Icon } from '../../Common/M3Icon';
import configData from "../../../appsettings.json";

export const RegistrationGiftCards = () => {
    const { t } = useTranslation();

    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [isError, setIsError] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [giftCardCode, setGiftCardCode] = useState('');

    useEffect(() => {
    }, []);

    function onClickRegisterGiftCard() {
        setIsLoading(true);
        setIsError(false);
        setIsValid(false);

        async function getData() {

            const requestOption = {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    giftCardCode: giftCardCode
                })
            };

            const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'User/RegisterGiftCards', requestOption);
            if (fetchedRes.ok) {
                const response = await fetchedRes.json();

                if (response && response.isValid) {
                    setIsValid(true);
                } else {
                    setIsError(true);
                }
            } else {
                setIsError(true);
            }

            setIsLoading(false);

        }
        getData();
    }

    function insertNewGiftCard(resetAll) {
        setIsError(false);
        setIsValid(false);

        if (resetAll)
            setGiftCardCode('');
    }

    return (
        <>
            <main className="">
                <div className='position-relative bg-custom2'>
                    <div className='w-100'>
                        <ProductHeader pageCode='GiftCard' height="700px" />
                    </div>
                </div>
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                            <div className="bg-light text-center py-2 px-4">
                                <div className="h5 fw-bold text-custom">Carica la tua gift card</div>
                                <div className="text-dark h6">La registrazione del codice è gratuita, presta solo attenzione alla scadenza della tua carta</div>

                                <div className="row no-margin mt-4">
                                    {
                                        !isLoading && !isError && !isValid && <>
                                            <div className="col-12 col-sm-12 col-md-3"></div>
                                            <div className="col-12 col-sm-12 col-md-4">
                                                <input type="text"
                                                    value={giftCardCode}
                                                    onChange={e => setGiftCardCode(e.target.value)}
                                                    id="txtGiftCard"
                                                    className="form-control w-100"
                                                    placeholder="Inserisci il codice ricevuto tramite email" />
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-2 text-left mt-2">
                                                <a
                                                    className="tp-btn-login btn-md text-decoration-none px-2 py-2 rounded animate-up-2"
                                                    onClick={_ => onClickRegisterGiftCard()}>
                                                    Registra gift card
                                                </a>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-3"></div>
                                        </>
                                    }

                                    {
                                        isLoading && <div className="col-12 col-sm-12 col-md-12">
                                            <Loading />
                                        </div>
                                    }

                                    {
                                        isError && <div className="col-12 col-sm-12 col-md-12">
                                            <div className="row m-0 bg-white py-2">
                                                <div class="col-1 col-sm-1 col-md-1 no-padding">
                                                    <M3Icon iconName="Close" externalClass="text-danger" />
                                                </div>
                                                <div class="col-11 col-sm-11 col-md-11 text-left no-padding text-gray">
                                                    <div>
                                                        Caricamento non riuscito!<br />
                                                        Il codice inserito potrebbe essere sbagliato o non più valido,
                                                        <span className="cursor-pointer text-dark border-bottom border-dark" onClick={_ => insertNewGiftCard(false)}>clicca qui</span> per provare di nuovo.
                                                    </div>
                                                    <div className="mt-2"><span className="text-dark">Attenzione:</span> se gli errori si ripeteranno per 1h non sarà più possibile inserire alcun codice.</div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        isValid && <div className="col-12 col-sm-12 col-md-12">
                                            <div className="row m-0 bg-white py-2">
                                                <div class="col-1 col-sm-1 col-md-1 no-padding">
                                                    <M3Icon iconName="Done" externalClass="text-success" />
                                                </div>
                                                <div class="col-11 col-sm-11 col-md-11 text-left no-padding text-gray">
                                                    <div>
                                                        Buono caricato con successo!<br />
                                                        Se sei in possesso di un altro codice, <span className="cursor-pointer text-dark border-bottom border-dark" onClick={_ => insertNewGiftCard(true)}>clicca qui per caricarlo</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}