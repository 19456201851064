import ReactDOMServer from "react-dom/server";
import { generateComponentInBody, replaceAllStyleElements, dynamicNotesDistincts } from "../../../js/PrintUtility";
import { formatPrice } from '../../../js/Utils.js';
import { PrintStepHeader } from "../components/PrintStepHeader";
import { PrintStepItem } from "../components/PrintStepItem";
import { TourStaticPrint } from "../components/TourStaticPrint";
import { PrintAncillaries } from "../components/PrintAncillaries";
import { PrintCommissionsDetail } from "../components/PrintCommissionsDetail";
import { PrintCommissionsDetailCompact } from "../components/PrintCommissionsDetailCompact";
import { PrintPaxes } from "../components/PrintPaxes";
import { PrintDynamicNotes } from "../components/PrintDynamicNotes";
import { PrintTitle } from "../components/PrintTitle";
import { TemplatePrintInclusion } from "../template/TemplatePrintInclusion";
import { TemplatePrintStepHeader } from "../template/TemplatePrintStepHeader";
import { PrintItineraryResume } from "../components/PrintItineraryResume";
import { PrintTitleSeparator } from "../components/PrintTitleSeparator";
import { TemplatePrintHeaderDescription } from "../template/TemplatePrintHeaderDescription";
import { PrintStepItemAlternatives } from "../components/PrintStepItemAlternatives";
import { PrintProductsNotes } from "../components/PrintProductsNotes";
import { PrintScadenziario } from "../components/PrintScadenziario";
import { PrintCancelPolicy } from "../components/PrintCancelPolicy";
import { PrintProductsNotesStep } from '../components/PrintProductsNotesStep';
import configData from "../../../appsettings.json";

// verifica se esite qualche valore
function checkIfExistValues(recapItems) {
    let ok = recapItems.filter((item) => item.productType === "Generic")
    if (ok.length > 0)
        return true;
    else
        return false;
}

export function generateQuotationHTML(t, quotationRecapItems, quotation, itemStepPrint, quotationPaxes, scadenziario, dynamicNotes, dynamicNotesStep, dynamicNotesTop, quotationAlternatives, cancelPolicyArr, noteProdottoBigArr, cultureName, isPrintCliente = false, isPrintHeaderDesc, isPrintItineraryResume, isPrintPaxes, isPrintAncillaries, isPrintInclusions, isPrintCommissions, isPrintServicesNotSelected, isShowAlternatives, isPrintScadenziario, isPrintDynamicNotes, isPrintStructureNotes, isPrintCancelPolicy, isPrintNoteBig, isQuotationTo) {
    let myArr = [];
    
    let rowTitle = ReactDOMServer.renderToString(<PrintTitle isPrintCliente={isPrintCliente} title={t("Print:QuotationTitle")} titleCliente={t("Print:QuotationClientTitle")} />);

    myArr.push({ html: generateComponentInBody(rowTitle), break: false, wrap: true });

    let rTitleDescription = ReactDOMServer.renderToString(<TemplatePrintHeaderDescription
        title={quotation.name}
        description={quotation.description}
    />);

    myArr.push({ html: generateComponentInBody(rTitleDescription), break: false, wrap: true });

    if (dynamicNotesTop && dynamicNotesTop.length > 0) {
        let rowDynamicNotesTop = ReactDOMServer.renderToString(<PrintDynamicNotes
            notes={dynamicNotesTop}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowDynamicNotesTop), break: false, wrap: true });
    }

    let isBreakItinerary = false;

    if (isPrintPaxes && quotationPaxes && quotationPaxes.length > 0) {
        let rPaxes = ReactDOMServer.renderToString(<PrintPaxes paxes={quotationPaxes} isConfirmPrint={false} />);

        myArr.push({ html: generateComponentInBody(rPaxes), break: false, wrap: true });

        isBreakItinerary = true;
    }

    /* itinerario */
    if (isPrintItineraryResume) {
        let itineraryResume_ = ReactDOMServer.renderToString(<PrintItineraryResume
            paxes={quotationPaxes}
            isConfirmPrint={false}
            isNotStep={false}
        />);

        myArr.push({ html: generateComponentInBody(itineraryResume_), break: isBreakItinerary, wrap: true });
    }


    var finalArr = itemStepPrint;
    var idArrNote = [];
    if (quotation && quotation.quotationItemStep && quotation.quotationItemStep.length > 0) {
        quotation.quotationItemStep.map((step, stepKey) => {
            let rowTemplateStepHeader = ReactDOMServer.renderToString(<TemplatePrintStepHeader
                key={stepKey}
                title={step.stepName}
                isStep={true}
                stepIndex={step.step}
                minDate={step.dateFrom}
                maxDate={step.dateTo}
            />);

            myArr.push({ html: generateComponentInBody(rowTemplateStepHeader), break: false, wrap: false });

            if (step.quotationItemInfo && step.quotationItemInfo.length > 0) {
                step.quotationItemInfo.filter(x => x.isSelected).map((item, itemKey) => {

                    if (itemKey === 0 && dynamicNotesStep && dynamicNotesStep.length > 0) {
                        var showImage = true;
                        var hideText = false;

                        dynamicNotesStep.map((note, idx) => {
                            if (note.step === step.step) {
                                if (!idArrNote.find(x => x.id === note.noteId && note.step === x.step)) {
                                    idArrNote.push({ id: note.noteId, step: note.step });
                                }
                                else {
                                    hideText = true;
                                }

                                let rowNoteStep = ReactDOMServer.renderToString(<PrintProductsNotesStep
                                    notes={[note]}
                                    cultureName={cultureName}
                                    isShowImage={showImage}
                                    hideText={hideText}
                                />);

                                myArr.push({ html: generateComponentInBody(rowNoteStep), break: false, wrap: true });

                                showImage = false;
                            }
                        });
                    }

                    let rowTemplateStep = ReactDOMServer.renderToString(<PrintStepItem
                        key={itemKey}
                        qInfo={itemStepPrint.find(c => c.quotationItemId === item.id)}
                        isPrintCliente={false}
                        isTemplate={true}
                        isConfirmPrint={false}
                        isNotStep={false}
                        isQuotationTo={isQuotationTo}
                    />);

                    let wrap = false;
                    if (item.productType === "Flight")
                        wrap = true;

                    myArr.push({ html: generateComponentInBody(rowTemplateStep), break: false, wrap: wrap });

                    if (isShowAlternatives && quotationAlternatives.filter(c => c.quotationItemIdFather === item.id && c.productType === item.productType).length > 0) {
                        let rowTemplateStepAlternatives = ReactDOMServer.renderToString(<PrintStepItemAlternatives
                            key={itemKey}
                            alternativeArr={quotationAlternatives.filter(c => c.quotationItemIdFather === item.id)}
                            isPrintCliente={false}
                            isTemplate={true}
                            isConfirmPrint={false}
                        />);

                        myArr.push({ html: generateComponentInBody(rowTemplateStepAlternatives), break: false, wrap: true });
                    }
                })

                let rowBorderBottom = ReactDOMServer.renderToString(<div className="borderBottomColorCustom"></div>);
                myArr.push({ html: generateComponentInBody(rowBorderBottom), break: false, wrap: true });
            }
        })
    }

    let rowTitleSeparator = ReactDOMServer.renderToString(<PrintTitleSeparator
        title={t(`Template:Quotation`)}
    />);

    myArr.push({ html: generateComponentInBody(rowTitleSeparator), break: true, wrap: true });

    /* Quote accessorie */
    if (isPrintAncillaries && checkIfExistValues(quotationRecapItems)) {
        let rAncillaries = ReactDOMServer.renderToString(<PrintAncillaries
            recapItems={quotationRecapItems}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rAncillaries), break: false, wrap: false });
    }

    /*if (isPrintServicesNotSelected && checkIfExistValues(quotationRecapItems)) {
        let ServiecsNotSelectedTitle = ReactDOMServer.renderToString(<PrintStepHeader
            title="Servizi Opzionali Aggiuntivi"
            PNR=''
            totaleViaggio=''
            iconName=''
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(ServiecsNotSelectedTitle), break: false, wrap: true });

        let servicesNotSelected = ReactDOMServer.renderToString(<PrintAncillariesNotSelected
            recapItems={quotationRecapItems}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(servicesNotSelected), break: false, wrap: true });
    }*/

    /* Totale viaggio - dettaglio commissioni */
    if (isPrintCommissions) {
        let rowCommDetail = ReactDOMServer.renderToString(<PrintCommissionsDetailCompact
            marginItems={quotationRecapItems}
            cultureName={cultureName}
            paxes={quotationPaxes}
            isConfirmPrint={false}
            isPrintCliente={isPrintCliente}
        />);

        myArr.push({ html: generateComponentInBody(rowCommDetail), break: false, wrap: false });
    }
    
    /* Scadenziario */
    if ((!configData.Settings.HidePrintScadenziarioCliente && isPrintScadenziario) ||
        (configData.Settings.HidePrintScadenziarioCliente && !isPrintCliente && isPrintScadenziario)
    ) {
        let rowScadenziario = ReactDOMServer.renderToString(<PrintScadenziario
            scadenziario={scadenziario}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowScadenziario), break: false, wrap: false });
    }

    
    /* Cancel Policy */
    if (isPrintCancelPolicy && !isPrintCliente) {
        let rowCancelPolicy = ReactDOMServer.renderToString(<PrintCancelPolicy
            cancelPolicyArr={cancelPolicyArr}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowCancelPolicy), break: false, wrap: false });
    }

    /* Note dinamiche */
    if (dynamicNotes && dynamicNotes.length > 0) {
        let notePagamenti = dynamicNotesDistincts(dynamicNotes).filter(x => x.categoryName === "Pagamenti");
        if (notePagamenti) {
            let rowDynamicNotes = ReactDOMServer.renderToString(<PrintDynamicNotes
                notes={[notePagamenti]}
                cultureName={cultureName}
            />);

            myArr.push({ html: generateComponentInBody(rowDynamicNotes), break: false, wrap: true });
        }

        myArr.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });


        let note = dynamicNotesDistincts(dynamicNotes).filter(x => x.categoryName !== "Pagamenti");
        let rowDynamicNotes = ReactDOMServer.renderToString(<PrintDynamicNotes
            notes={[note]}
            cultureName={cultureName}
        />);

        myArr.push({ html: generateComponentInBody(rowDynamicNotes), break: false, wrap: true });
    }

    /* quota comprende, non comprende
    if (template && template.inclusions && template.inclusions.length > 0 && isPrintInclusions) {
        template.inclusions.map(inclusion => {
            let rowInclusion = ReactDOMServer.renderToString(<TemplatePrintInclusion
                inclusion={inclusion}
            />);

            myArr.push({ html: generateComponentInBody(rowInclusion), break: false, wrap: true });
        })
    } */

    let isFirstTour = true;

    if (isPrintNoteBig && noteProdottoBigArr.length > 0) {
        isFirstTour = false;

        myArr.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });

        let rowPorductsNotes = ReactDOMServer.renderToString(<PrintProductsNotes
            arrNotesProdcuts={noteProdottoBigArr}
        />);

        myArr.push({ html: generateComponentInBody(rowPorductsNotes), break: false, wrap: true });
    }

    let schedeStatiche = [];
    let nameActivityTourArr = [];
    let nameStructureArr = [];

    if (isPrintStructureNotes) {
        for (var j = 0; j < finalArr.length; j++) {
            let qInfo = finalArr[j];
            /* Programma di viaggio, note extra */
            if (qInfo.productType === 'Activity' && qInfo.productInfo.option.descriptions.length > 0) {

                if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // esclude note in xml non DMC
                    continue;

                if (nameActivityTourArr.indexOf(qInfo.productInfo.name) === -1) // controlla se esiste un duplicato di Tour 
                    nameActivityTourArr.push(qInfo.productInfo.name);
                else
                    continue;

                if (isFirstTour) {
                    schedeStatiche.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });
                    isFirstTour = false;
                }

                let rowSchedaSt;
                let tourName;
                let descToPrint = qInfo.productInfo.option.descriptions.filter(x => x.isPrint);

                let rowSchedaTitle = ReactDOMServer.renderToString(<PrintStepHeader
                    title={qInfo.productInfo.name}
                    PNR=''
                    totaleViaggio=''
                    iconName='directions_walk'
                    cultureName={cultureName}
                />);
                schedeStatiche.push({ html: generateComponentInBody(rowSchedaTitle), break: false, wrap: true });

                for (var k = 0; k < descToPrint.length; k++) {
                    let newDescription = replaceAllStyleElements(descToPrint[k].description);

                    if (k > 0)
                        tourName = "";
                    else
                        tourName = qInfo.productInfo.name;

                    rowSchedaSt = ReactDOMServer.renderToString(<TourStaticPrint
                        descriptions={newDescription}
                        title={descToPrint[k].title}
                        tourName={tourName}
                    />);

                    schedeStatiche.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
                }
            }
            else if (qInfo.productType === 'Structure' && qInfo.productInfo.descriptions.length > 0) {
                if (qInfo.productInfo.quotationDetail != null && !qInfo.productInfo.quotationDetail.isManualLoading && !qInfo.productInfo.priceBar.isDMC) // esclude note in xml non DMC
                    continue;
                if (nameStructureArr.indexOf(qInfo.productInfo.name) === -1) // controlla se esiste un duplicato
                    nameStructureArr.push(qInfo.productInfo.name);
                else
                    continue;

                if (isFirstTour) {
                    schedeStatiche.push({ html: generateComponentInBody('<div></div>'), break: true, wrap: false });
                    isFirstTour = false;
                }

                let rowSchedaSt;
                let tourName;
                let descToPrint = qInfo.productInfo.descriptions.filter(x => x.isPrint);

                let rowSchedaTitle = ReactDOMServer.renderToString(<PrintStepHeader
                    title={qInfo.productInfo.name}
                    PNR=''
                    totaleViaggio=''
                    iconName='info_hotel'
                    cultureName={cultureName}
                />);
                schedeStatiche.push({ html: generateComponentInBody(rowSchedaTitle), break: false, wrap: true });

                for (var k = 0; k < descToPrint.length; k++) {
                    let newDescription = replaceAllStyleElements(descToPrint[k].description);

                    if (k > 0)
                        tourName = "";
                    else
                        tourName = qInfo.productInfo.name;

                    rowSchedaSt = ReactDOMServer.renderToString(<TourStaticPrint
                        descriptions={newDescription}
                        title={descToPrint[k].title}
                        tourName={tourName}
                    />);
                    schedeStatiche.push({ html: generateComponentInBody(rowSchedaSt), break: false, wrap: true });
                }
            }
        }
    }

    myArr.push(...schedeStatiche);

    return myArr;
}