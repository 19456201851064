import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import configData from "../../../../appsettings.json";
import { useAuth } from '../../../Auth/AuthProvider';
import { searchCarpetParameter } from '../../../../js/Constant';
import { getCurrentUserFromJwtToken, getWebsiteId } from '../../../../js/Utils.js';
import { M3Pax } from "../../../Common/M3Pax";
import { Loading } from '../../../Common/Loading';
import { Error } from '../../../Common/Error';
import { ListSearchEngineCV } from '../../../Product/SearchEngine/compactView/ListSearchEngineCV';
import { createStructureCarpet } from '../../../../js/ProductService';
import { HotelStaticContainer } from '../../../Product/Hotel/components/HotelStaticContainer';
import { HotelRoom } from '../../../Product/Hotel/components/HotelRoom';
import { HotelRoomFilter } from '../../../Product/Hotel/filters/HotelRoomFilter';
import { HotelCancelPolicyFilter } from '../../../Product/Hotel/filters/HotelCancelPolicyFilter';
import { HotelPackagesFilter } from '../../../Product/Hotel/filters/HotelPackagesFilter';
import { HotelMealPlanFilter } from '../../../Product/Hotel/filters/HotelMealPlanFilter';
import { HotelCombinationFilter } from '../../../Product/Hotel/filters/HotelCombinationFilter';
import { ProductFilter } from '../../../Product/Common/Filter/ProductFilter';
import { ProductNavBar } from '../../../Product/SearchEngine/ProductNavBar';
import { ListSearchEngineCVMobile } from "../../../Product/SearchEngine/compactView/ListSearchEngineCVMobile"


export const HotelDetail = ({ structureId, forceRender, clientFlowId, caId, carpetSearchId, defaultMealplanId, onRequest, onlyPack, favourite, defualtSuppliersIds, step, cartItemId, onAddProduct, saveIntervalCarpetPing }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    // carpet
    // per questioni di ottimizzazione genero il carpet direttamente nella pagina
    const [carpetAvailabilityId, setCarpetAvailabilityId] = useState(null);
    const [carpetStatus, setCarpetStatus] = useState(null);
    const [carpetDataStatus, setCarpetDataStatus] = useState(null);
    const [carpetTime, setCarpetTime] = useState(1);
    const [intervalCarpetPing, setIntervalCarpetPing] = useState([]);

    // page
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [isErrorStaticData, setIsErrorStaticData] = useState(false);
    const [isMessageNotFoundRooms, setIsMessageNotFoundRooms] = useState(false);
    const [structureData, setStructureData] = useState(null);
    const [structureDetail, setStructureDetail] = useState(null);
    const [isLoadingListResult, setIsLoadingListResult] = useState(true);
    const [showPriceBar, setShowPriceBar] = useState(false);
    const [quotationInfo, setQuotationInfo] = useState(null);

    // roles
    const [showCost, setShowCost] = useState(false);
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);
    const [enableShowPriceBreakDown, setEnableShowPriceBreakDown] = useState(false);
    const [enableCanAddToCart, setEnableCanAddToCart] = useState(false);

    // filtri
    const [filterValues, setFilterValues] = useState(null);
    const [filterSelected, setFilterSelected] = useState({
        roomName: null,
        numRoomsToView: searchCarpetParameter.hotel.detaMaxilItem,
        showAllRooms: false,
        onlyRefundable: null,
        onlyWithPenalty: null,
        onlyAvailable: null,
        onlyPackages: onlyPack ? true : null,
        onlyNoPackages: null,
        mealplans: defaultMealplanId ? [defaultMealplanId] : [],
        supplierIds: defualtSuppliersIds ? defualtSuppliersIds.split(',') : [],
        availRoomsReq: []
    });
    const [isFilterCombination, setIsFilterCombination] = useState(false);

    const [extrasRoom, setExtrasRoom] = useState([]);

    function onSetMealPlanIdAndSequence(mealPlanIdAndSequence) {
        if (structureDetail && structureDetail.roomGroupInfo.length === 1) {
            return;
        }

        let updFilterSelected = { ...filterSelected };
        if (mealPlanIdAndSequence) {
            if (updFilterSelected.availRoomsReq.filter(item => item.id === mealPlanIdAndSequence.id).length > 0) {
                updFilterSelected.availRoomsReq = updFilterSelected.availRoomsReq.filter((item) => item.id !== mealPlanIdAndSequence.id && item.sequence < mealPlanIdAndSequence.sequence);
            }
            else {
                updFilterSelected.availRoomsReq.push({ id: mealPlanIdAndSequence.id, sequence: mealPlanIdAndSequence.sequence });
            }
        }
        else {
            updFilterSelected.availRoomsReq = [];
        }

        setFilterSelected(updFilterSelected);
        callFilterValues(updFilterSelected, 0);
    }


    function onHandleRoomExtras(extrasRooms) {
        let _extrasRoom = [...extrasRoom];

        if (_extrasRoom.length > 0 && _extrasRoom.find(x => x.mealPlanId === extrasRooms.mealPlanId)) {
            if (extrasRooms.extras.isSelected) {
                _extrasRoom.find(x => x.mealPlanId === extrasRooms.mealPlanId).selectExtraIds.push(extrasRooms.extras.id);
            }
            else {
                _extrasRoom.find(x => x.mealPlanId === extrasRooms.mealPlanId).selectExtraIds = _extrasRoom.find(x => x.mealPlanId === extrasRooms.mealPlanId).selectExtraIds.filter(x => x !== extrasRooms.extras.id);
            }
        }
        else {
            if (extrasRooms.extras.isSelected)
                _extrasRoom.push({ mealPlanId: extrasRooms.mealPlanId, roomId: extrasRooms.roomId, selectExtraIds: [extrasRooms.extras.id] });
        }

        setExtrasRoom(_extrasRoom);
    }

    function onHandleFinalBook(finalMealPlan, totalAccomodation) {
        let bookItem = {
            cultureCode: cultureName,
            carpetId: carpetAvailabilityId,
            selectedRoomIds: [],
            selectRoomExtras: []
        };

        let canAddToCart = false;
        let selectedRoomIds = [];
        if (totalAccomodation === 1) {
            canAddToCart = true;
            selectedRoomIds = [finalMealPlan.id];
        }
        else if (filterSelected.availRoomsReq && filterSelected.availRoomsReq.length === totalAccomodation) {
            canAddToCart = true;
            selectedRoomIds = filterSelected.availRoomsReq.map(x => x.id);
        }

        bookItem.selectedRoomIds = selectedRoomIds;

        if (extrasRoom && extrasRoom.length > 0) {
            // match tra camera selezionata ed extra selezionati
            selectedRoomIds.forEach(function (selectedRoom) {
                if (extrasRoom.find(x => x.mealPlanId === selectedRoom)) {
                    let roomFound = (extrasRoom.find(x => x.mealPlanId === selectedRoom));
                    bookItem.selectRoomExtras.push({ roomId: roomFound.roomId, selectExtraIds: roomFound.selectExtraIds });
                }
            });
        }

        if (canAddToCart) {
            const addToCart = async (bookItem) => { callAddToCart(bookItem); }
            addToCart(bookItem);
        }
    }

    async function callAddToCart(bookItem) {

        bookItem.step = step;
        bookItem.cartItemId = cartItemId;

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bookItem)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}structure/addtotrippie`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (!response || !response.success) {
                console.log(response);
            } else {
                onAddProduct(response.hotelRecap);
            }
        } else {
            console.log(fetchedRes);
        }
    }

    const onFilterRefundable = (e) => {
        let _filterSelected = { ...filterSelected };
        _filterSelected.onlyRefundable = e;

        setFilterSelected(_filterSelected);

        callFilterValues(_filterSelected, 0);

        setIsFilterCombination(false);
    };

    const onFilterNotRefundable = (e) => {
        let _filterSelected = { ...filterSelected };
        _filterSelected.onlyWithPenalty = e;

        setFilterSelected(_filterSelected);

        callFilterValues(_filterSelected, 0);

        setIsFilterCombination(false);
    };


    const onFilterPackages = (e) => {
        let _filterSelected = { ...filterSelected };
        _filterSelected.onlyPackages = e;

        setFilterSelected(_filterSelected);

        callFilterValues(_filterSelected, 0);

        setIsFilterCombination(false);
    };


    const onFilterNotPackages = (e) => {
        let _filterSelected = { ...filterSelected };
        _filterSelected.onlyNoPackages = e;

        setFilterSelected(_filterSelected);

        callFilterValues(_filterSelected, 0);

        setIsFilterCombination(false);
    };

    const onFilterMealplan = (e) => {
        let _filterSelected = { ...filterSelected };

        _filterSelected.mealplans = e;
        setFilterSelected(_filterSelected);

        callFilterValues(_filterSelected, 0);

        setIsFilterCombination(false);
    };

    const onFilterCombination = (e) => {
        let _filterSelected = { ...filterSelected };

        if (e) {
            let arrTmp = e.split("_");

            _filterSelected.onlyRefundable = (arrTmp[0].toLowerCase() === 'true');
            _filterSelected.mealplans = [arrTmp[1]];
        }
        else {
            _filterSelected.onlyRefundable = null;
            _filterSelected.mealplans = [];
        }
        setFilterSelected(_filterSelected);

        callFilterValues(_filterSelected, 0);

        setIsFilterCombination(true);
    };

    const onFilterRoom = (e) => {
        let _filterSelected = { ...filterSelected };

        _filterSelected.roomName = e;
        setFilterSelected(_filterSelected);

        callFilterValues(_filterSelected, 0);

        setIsFilterCombination(false);
    };

    const onChangeShowPriceBar = () => {
        setShowPriceBar(!showPriceBar);
    }

    function callFilterValues(filterSelectedUpdate) {
        //setIsLoadingListResult(true);
        getStructureDetail(filterSelectedUpdate, 0);
    }


    function getCombinationArr(roomGroups) {
        if (roomGroups && roomGroups.length === 0)
            return;

    }

    useEffect(() => {
        setIsLoading(true);
        setIsError(false);
        getStaticData();

        try {

            let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
            let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);

            if (currentUser.roles) {
                if (!currentUser.roles.includes("FE_NonVisualizzaNetto"))
                    setEnableShowNetPrices(true);

                if (currentUser.roles.includes("FE_ShowCost"))
                    setShowCost(true);

                if (currentUser.roles.includes("FE_ShowPriceBreakDown"))
                    setEnableShowPriceBreakDown(true);
            }

        } catch (ex) {
        }

    }, [forceRender]);

    useEffect(() => {
        switch (carpetStatus) {
            case 'Processing':
            case 'Queued':
            case 'Completed':
                {
                    break;
                }
            case 'Error':
                {
                    setIsError(true);
                    setIsLoading(false);
                    break;
                }
        }

    }, [carpetStatus]);

    useEffect(() => {
        switch (carpetDataStatus) {
            case 'Completed':
                {
                    callGetNewData();
                    clearCarpetAllInterval();
                    break;
                }

            case 'NewData':
                {
                    callGetNewData();
                    break;
                }

            case 'Processing':
            case 'NoChanges':
                {

                    let interval = setInterval(() => {
                        getCarpetStatus();
                    }, searchCarpetParameter.flight.carpetPingMs);
                    addInterval(interval);

                    break;
                }
        }

    }, [carpetDataStatus]);

    useEffect(() => {
        saveIntervalCarpetPing(intervalCarpetPing);
    }, [intervalCarpetPing])

    useEffect(() => {

        startDetailSearch();

    }, [structureData]);

    useEffect(() => {
        if (carpetAvailabilityId)
            getCarpetStatus();
    }, [carpetAvailabilityId]);

    // API carpet
    async function startDetailSearch() {
        if (!caId) {
            const cookies = new Cookies();
            const sessionToken = cookies.get("SessionToken");

            // chiamo l'api per ottenere i searchParameters
            let requestParam = {
                cultureCode: cultureName,
                carpetId: carpetSearchId
            };

            const requestOption = {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestParam)
            };

            const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}structure/getstructurelistfilters`, requestOption);

            if (fetchedRes.ok) {
                const response = await fetchedRes.json();
                response.hotelSearchParameter.structureId = structureId;

                let inputData = {
                    carpetOperation: "AVL",
                    clientFlowId: clientFlowId,
                    sessionToken: sessionToken,
                    hotelSearchParameters: response.hotelSearchParameter,
                    websiteId: getWebsiteId(),
                    googleApiKeyServer: configData.Settings.Google.ApiKeyServer,
                    productSubType: 1 // structure
                };

                caId = await createStructureCarpet(inputData);
            }
        }

        setCarpetAvailabilityId(caId);
    }

    async function getCarpetStatus() {
        console.log(`Call GetCarpetStatus [CarpetId = ${carpetAvailabilityId}]`);

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}carpet/getcarpetstatus/${carpetAvailabilityId}`, requestOption);

        if (!fetchedRes.ok) {
            setIsError(true);
        } else {
            const response = await fetchedRes.json();

            let dataStatus = response.dataStatus;
            let status = response.status;
            if (response.success) {
                setCarpetStatus(status);

                if (status !== 'Error') {
                    // messo per richiamare il getCarpetStatus, nel caso riceva piu volte lo status NoChanges.
                    // lo status Processing esiste solo lato FE
                    if (status !== carpetDataStatus)
                        setCarpetDataStatus(dataStatus);
                    else
                        setCarpetDataStatus('Processing');
                }


            } else {
                setIsError(true);
            }

            console.log(`Status ${status}`);
            console.log(`Data Status ${dataStatus}`);
        }

    }

    function addInterval(intervalId) {
        let arr = [...intervalCarpetPing];
        arr.push(intervalId);
        setIntervalCarpetPing(arr);
    }

    function clearCarpetAllInterval() {
        for (let i = 0; i < intervalCarpetPing.length; i++) {
            let intId = intervalCarpetPing[i];
            clearInterval(intId);
        }

        setIntervalCarpetPing([]);
    }

    async function getStaticData() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}structure/GetStructureData/${structureId}/${cultureName}`, requestOption);
        const response = await fetchedRes.json();

        if (response.success) {
            setStructureData(response.structure);
            setIsLoading(false);
        } else {
            setIsErrorStaticData(true);
            console.error(response.errorMsg);
        }
    }


    async function callGetNewData() {
        if (filterValues === null || carpetStatus === 'Completed')
            getStructureDetailFilters();

        getStructureDetail(filterSelected, 0);

        setIsError(false);

        // conto quante chiamate sto facendo
        // ogni chiamata avviene dopo un tot di tempo, quindi in base al numero di chiamate so quanto è passato
        let callTicks = carpetTime * searchCarpetParameter.hotel.carpetPingMs;
        let finishTime = callTicks > searchCarpetParameter.hotel.carpetMaxTimeMs
        setCarpetTime(carpetTime + 1);

        if (carpetStatus !== 'Completed' && !finishTime) {
            setIsLoadingListResult(true);

            let interval = setInterval(() => {
                getCarpetStatus();
            }, searchCarpetParameter.hotel.carpetPingMs);
            addInterval(interval);

        } else {
            setIsLoadingListResult(false);
        }
    }

    async function getStructureDetail(filterSelectedUpdate, pageNumber) {
        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetAvailabilityId,
            structureId: structureId,
            retrieveStaticData: true, // DA CAPIRE PERCHE' STATO FATTO COSI

            numRoomsToView: filterSelectedUpdate.numRoomsToView,
            showAllRooms: filterSelectedUpdate.showAllRooms,
            roomName: filterSelectedUpdate.roomName,
            onlyRefundable: filterSelectedUpdate.onlyRefundable,
            onlyWithPenalty: filterSelectedUpdate.onlyWithPenalty,
            onlyAvailable: filterSelectedUpdate.onlyAvailable,
            onlyPackages: filterSelectedUpdate.onlyPackages,
            onlyNoPackages: filterSelectedUpdate.onlyNoPackages,
            mealplans: filterSelectedUpdate.mealplans,
            supplierIds: filterSelectedUpdate.supplierIds,
            availRoomsReq: filterSelectedUpdate.availRoomsReq
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}structure/getstructureavailability`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response.structure) {
                setStructureDetail(response.structure);
                if (response.structure.roomGroupInfo)
                    getCombinationArr(response.structure.roomGroups);
            }
            else {
                setIsMessageNotFoundRooms(true);
            }

            if (response.permissions)
                setEnableCanAddToCart(response.permissions['ADDTOCART']);
        }

        setIsLoadingListResult(false);
    }

    async function getStructureDetailFilters() {
        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetAvailabilityId
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}structure/getstructureavailabilityfilters`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setFilterValues(response);
        }
    }


    function onApplyFilter(selectedItems, type) {
        let filterSelectedUpdate = { ...filterSelected };
        switch (type) {
            case 'StructureSuppliers': {
                let resIdsArr = [];
                selectedItems.map(item => {
                    resIdsArr.push(item.id);
                });

                filterSelectedUpdate.supplierIds = resIdsArr;
                break;
            }

        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }


    return (
        <>
            <main className="bg-gray-300">
                <div className="container">
                    <div className="card border-light bg-white p-1">
                        {isLoading && !isErrorStaticData && <Loading textMsg={t('MyBook:Loading')} />}
                        {
                            !isLoading && isErrorStaticData && <div className="card border-light  bg-gray-100 small mb-2">
                                <div className="row small ">
                                    <div className="col-12">
                                        <Error textMsg={t('Product:Structure:ErrorDetail')} />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !isLoading && structureData && <>
                                <HotelStaticContainer _structureData={structureData} favourite={favourite ? favourite : structureDetail && structureDetail.favourite} />
                                <div className="row">
                                    <div className="col-12 no-gutters">
                                        {!isError && isLoadingListResult ?
                                            <Loading textMsg={t('MyBook:Loading')} />
                                            :
                                            <>
                                                {isMessageNotFoundRooms || isError ?
                                                    <div className="col-12 pnlError text-center text-danger mt-4">
                                                        <data m3lab="Product.Structure.NotRoomFound">
                                                            {t("Product:Structure:NotRoomFound")}
                                                        </data>
                                                    </div>
                                                    :
                                                    structureDetail && structureDetail.roomGroupInfo && structureDetail.roomGroupInfo.map((roomGroup, accomodationIdx) =>
                                                        <React.Fragment key={accomodationIdx}>
                                                            <div className="card border-light bg-gray-300 pt-2 mt-4">
                                                                <div className="row p-1 ">
                                                                    <div className="h5 col-12 col-lg-5 ">
                                                                        <data m3lab="Product.structure.Accomodation">{t(`Product:Structure:Accomodation`)} <var>{roomGroup.sequence}</var></data>
                                                                    </div>
                                                                    <div className="h5 col-12 col-lg-2 ">
                                                                        {filterValues && filterValues.hotelSearchParameter && filterValues.hotelSearchParameter.roomsComposition && filterValues.hotelSearchParameter.roomsComposition.length > 0 &&
                                                                            <M3Pax
                                                                                adt={filterValues.hotelSearchParameter.roomsComposition[accomodationIdx] && filterValues.hotelSearchParameter.roomsComposition[accomodationIdx].adults}
                                                                                chd={filterValues.hotelSearchParameter.roomsComposition[accomodationIdx] && filterValues.hotelSearchParameter.roomsComposition[accomodationIdx].children}
                                                                                inf={filterValues.hotelSearchParameter.roomsComposition[accomodationIdx] && filterValues.hotelSearchParameter.roomsComposition[accomodationIdx].childAges}
                                                                            />
                                                                        }
                                                                    </div>
                                                                    <div className="col-12 col-lg-5 text-right">

                                                                        <button data-bs-toggle="modal" data-bs-target="#filterModal" className="openFilterButton p-1 px-2 pt-2 rounded border-0 shadow tp-btn-search">
                                                                            {t(`General:OpenFilter`)}
                                                                        </button>

                                                                        {
                                                                            enableShowNetPrices && <button className={(showPriceBar ? "tp-btn-select" : "tp-btn-outline-select") + " ms-2 p-1 px-2 pt-2 rounded border-0 shadow"} m3rool="f_ShowPriceBar" onClick={(e) => { onChangeShowPriceBar() }}>
                                                                                <data m3lab="Button.Net">
                                                                                    {t("Button:Net")}
                                                                                </data>
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {roomGroup.roomGroups && roomGroup.roomGroups.map((room, idx) =>
                                                                    <>
                                                                        {idx < 10 &&
                                                                            <HotelRoom
                                                                                key={idx}
                                                                                room={room}
                                                                                roomIndex={idx}
                                                                                showPriceBar={showPriceBar}
                                                                                showCost={showCost}
                                                                                enableShowPriceBreakDown={enableShowPriceBreakDown}
                                                                                enableCanAddToCart={enableCanAddToCart}
                                                                                totalAccomodation={structureDetail.roomGroupInfo.length}
                                                                                handleMealPlanIdAndSequence={onSetMealPlanIdAndSequence}
                                                                                multiRoomSelected={filterSelected.availRoomsReq}
                                                                                handleFinalBook={onHandleFinalBook}
                                                                                quotationInfo={quotationInfo}
                                                                                handleRoomExtras={onHandleRoomExtras}
                                                                            />
                                                                        }
                                                                    </>
                                                                )}
                                                                <div className="collapse multi-collapse" id="inclusionCollapse">
                                                                    {roomGroup.roomGroups && roomGroup.roomGroups.length > 10 &&
                                                                        roomGroup.roomGroups.map((room, idx) =>
                                                                            <>
                                                                                {idx >= 10 &&
                                                                                    <HotelRoom
                                                                                        key={idx}
                                                                                        room={room}
                                                                                        roomIndex={idx}
                                                                                        showPriceBar={showPriceBar}
                                                                                        showCost={showCost}
                                                                                        enableShowPriceBreakDown={enableShowPriceBreakDown}
                                                                                        enableCanAddToCart={enableCanAddToCart}
                                                                                        totalAccomodation={structureDetail.roomGroupInfo.length}
                                                                                        handleMealPlanIdAndSequence={onSetMealPlanIdAndSequence}
                                                                                        multiRoomSelected={filterSelected.availRoomsReq}
                                                                                        handleFinalBook={onHandleFinalBook}
                                                                                        quotationInfo={quotationInfo}
                                                                                        handleRoomExtras={onHandleRoomExtras}
                                                                                    />
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>

                                                                {roomGroup.roomGroups && roomGroup.roomGroups.length > 10 &&
                                                                    <div className="text-center mt-2 mb-2">
                                                                        <span data-bs-toggle="collapse" href="#inclusionCollapse" role="button" aria-expanded="false" aria-controls="inclusionCollapse">
                                                                            {t('General:Other')} ({roomGroup.roomGroups.length - 10} {t('SearchEngine:Rooms')})
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                            </>
                                        }
                                    </div>

                                    <div className="modal tp-search-input fade" id="filterModal" tabIndex="-1" aria-labelledby="EngineModifyActivty" aria-hidden="true">
                                        <div className="modal-dialog modal-fullscreen-xxl-down">
                                            <div className="modal-content">
                                                <div className="modal-header p-0">
                                                    <div className="h4 text-center w-100 mt-2">
                                                        {t('SearchEngine:UpdateSearch')}
                                                    </div>
                                                    <button type="button" className="btn-close mt-2 pr-4" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body bg-gray-300">
                                                    <div>
                                                        {
                                                            filterValues && structureDetail && structureDetail.roomGroupInfo && <>
                                                                <h5 className="p-0 pt-4"> <data m3lab="Product.structure.Filter.FilterDetail">{t(`Product:Structure:Filter:FilterDetail`)}</data></h5>
                                                                <HotelRoomFilter handleRoom={onFilterRoom} />
                                                                <HotelPackagesFilter
                                                                    handleIsRef={onFilterPackages}
                                                                    handleIsNotRef={onFilterNotPackages}
                                                                    isReset={isFilterCombination}
                                                                />
                                                                {!filterValues.noShowCancelPolicy &&
                                                                    <HotelCancelPolicyFilter
                                                                        handleIsRef={onFilterRefundable}
                                                                        handleIsNotRef={onFilterNotRefundable}
                                                                        isReset={isFilterCombination}
                                                                    />
                                                                }
                                                                <HotelMealPlanFilter
                                                                    mealplansDefault={defaultMealplanId}
                                                                    handleMealPlanFilter={onFilterMealplan}
                                                                    isReset={isFilterCombination}
                                                                />
                                                                {!filterValues.noShowCancelPolicy &&
                                                                    <HotelCombinationFilter
                                                                        handleMealPlanFilter={onFilterCombination}
                                                                        bestRoomTablePrice={filterValues.bestRoomTablePrice}
                                                                    />
                                                                }
                                                                { /* Provider ProductFilter */}
                                                                <ProductFilter
                                                                    items={filterValues.suppliers}
                                                                    label="Product:Structure:Filter:Provider"
                                                                    type="StructureSuppliers"
                                                                    onApplyFilter={onApplyFilter}
                                                                    defaultSelectIds={defualtSuppliersIds && defualtSuppliersIds.length > 0 ? defualtSuppliersIds.split(",") : null}
                                                                />
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </main>

            {/* dettaglio modale */}
            <div className="modal fade" id="infoHotel" tabIndex="-1" aria-labelledby="infoHotel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="infoHotel">
                                <data m3lab="General.Descriptions">
                                    {t("General:Descriptions")}
                                </data>
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {structureData && structureData.descriptions && structureData.descriptions.map((description, key) =>
                                <React.Fragment key={key}>
                                    <p className="h6"><var>{description.category}</var></p>
                                    <p className="small">
                                        <var dangerouslySetInnerHTML={{ __html: description.description.replaceAll("<br/>", "") }}></var>
                                    </p>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-gray-300" data-bs-dismiss="modal">
                                <data m3lab="Button.Chiudi">
                                    {t("Button:Chiudi")}
                                </data>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}