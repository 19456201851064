import React from 'react';
import { useTranslation } from 'react-i18next';
import { TrainSearchEngine } from "../../Product/SearchEngine/TrainSearchEngine";

export const TrainSearchEngineTrippie = ({ searchParameters, typeSearch, extraParams, onStartSearch }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <div class="h6 fw-bold">Treni suggeriti</div>
                </div>
                <div className="col-6 h6 text-end text-decoration-none">
                    <span className="cursor-pointer" data-bs-toggle="modal" data-bs-target="#EngineModifyTrain">Ricerca</span>
                </div>
            </div>

            <div className="modal tp-search-input fade" id="EngineModifyTrain" tabIndex="-1" aria-labelledby="EngineModifyTrain" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen-xxl-down">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <div className="h4 text-center w-100 mt-2">
                                {t('SearchEngine:UpdateSearch')} - {t('Product:Train:Item')}
                            </div>
                            <button type="button" className="btn-close mt-2 pr-4" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body bg-gray-300">
                            <TrainSearchEngine
                                searchParameters={searchParameters}
                                typeSearch={typeSearch}
                                extraParams={extraParams}
                                resetValues={false}
                                isTrippie={true}
                                onSearchTrippie={onStartSearch}
                                modeView="MODAL" />
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
}