import React from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { formatDateTime } from '../../js/Utils.js';

export const Passenger = ({passenger}) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const getParamValue = (paramValue, paramType) => {
        if (paramType === 'Mindcube.Travel.DateTimeBookParameter') {
            return formatDateTime(paramValue, cultureName, { twoDigits: true });
        }

        return paramValue;
    }

    return (
        <p className="my-0">
            <label>{passenger.index} &nbsp;{t("MyBook:Passenger")}:</label>
            <span><label>{t("MyBook:Name")}:</label> {passenger.fullName} &nbsp;</span>
            {passenger.infos.map((paxInfo, index) =>
                <span key={index}><label>{t(`bookParameters:${paxInfo.paramName}`)}:</label> {getParamValue(paxInfo.paramValue, paxInfo.paramType)} &nbsp;</span>
            )}
        </p>
    );
}
