import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../js/Utils.js';

export const DocTitle = ({ cultureName, Data }) => {
    const { t } = useTranslation();

    return (
        <> 
            <div className="mb-4 mt-2 d-flex align-items-center justify-content-center h4">
                <div><label>{t(`documents:numeroestrattoconto`)}: </label>{Data.docNumber} </div>
                <div><label>&nbsp;{t("MyBook:Date")}: </label>{formatDateTime(Data.docDate, cultureName, { twoDigits: true })}</div>
            </div>
            <div className=" border-top border-light mb-4">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-3 m-0 document-print-3">
                        <label>{t("MyBook:ToAgency")}:</label> <h6>{Data.agencyName}</h6><label>{Data.username}</label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 m-0 text-md-center  document-print-6">
                        <p className="mb-0"><label>{t("MyBook:Travel")}</label></p>
                        <div className="mb-0">
                            <span><label>{t("MyBook:StartDate")}: </label>{formatDateTime(Data.startDate, cultureName, { twoDigits: true })}&nbsp;<label> {t("MyBook:EndDate")}: </label>{formatDateTime(Data.endDate, cultureName, { twoDigits: true })}</span>{/*<!--prima data dei servizio / Ultima data di fine servizio-->*/}
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-2">
                            <span><label>{t("MyBook:TravelTitle")}:</label>{Data.title}</span>{/*<!--se un template il nome del template-->*/}
                        </div>
                    </div>
                    <div className="col-12 col-sm-9 col-md-6 col-lg-3 m-0 document-print-3">
                        <label>Pax rif:</label>
                        <h6>{Data.refPax}</h6>
                    </div>
                </div>
            </div>{/*<!--CloseOrder Block-->*/}
        </>
    );
}

