import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";
import { ProductFilterByTime } from "./ProductFilterByTime";
import { convertHourInDateTime } from '../../../../js/Utils.js';

export const ProductFilterMultiDestinationTime = ({ destinationNames, type, label, iconTitle, onApplyFilter, resetSelectedItem }) => {
    const { t } = useTranslation();
    const rangeTimeInfo = {
        min: '00:00',
        max: '23:59',
        selectMin: '00:00',
        selectMax: '23:59',
        step: 15
    };

    const [defaultRangeTimeInfo, setDefaultRangeTimeInfo] = useState([]);
    const [showFilter, setShowFilter] = useState(true);

    useEffect(() => {
        let updDefaultRangeTimeInfo = [];
        for (let i = 0; i < destinationNames.length; i++) {
            updDefaultRangeTimeInfo.push({
                legCriteria: destinationNames[i],
                departure: { ...rangeTimeInfo },
                arrival: { ...rangeTimeInfo }
            });
        }

        setDefaultRangeTimeInfo(updDefaultRangeTimeInfo);
    }, [destinationNames])

    useEffect(() => {
        let updDefaultRangeTimeInfo = [];
        for (let i = 0; i < destinationNames.length; i++) {
            updDefaultRangeTimeInfo.push({
                legCriteria: destinationNames[i],
                departure: { ...rangeTimeInfo },
                arrival: { ...rangeTimeInfo }
            });
        }
        setDefaultRangeTimeInfo(updDefaultRangeTimeInfo);

    }, [resetSelectedItem]);

    function onApplyFilterTime(value, keyId) {
        let selectedItems = [];
        let updateRangeTimeInfo = [...defaultRangeTimeInfo];

        let keyParts = keyId.split('_');
        let typeFlt = keyParts[0];
        let index = parseInt(keyParts[1]);

        for (let i = 0; i < updateRangeTimeInfo.length; i++) {
            let item = {};

            if (index !== i) {
                item.departureTimeFrom = convertHourInDateTime(updateRangeTimeInfo[i].departure.selectMin);
                item.departureTimeTo = convertHourInDateTime(updateRangeTimeInfo[i].departure.selectMax);

                item.arrivalTimeFrom = convertHourInDateTime(updateRangeTimeInfo[i].arrival.selectMin);
                item.arrivalTimeTo = convertHourInDateTime(updateRangeTimeInfo[i].arrival.selectMax);

            } else {

                if (typeFlt === 'A') {
                    updateRangeTimeInfo[index].departure.selectMin = value.min;
                    updateRangeTimeInfo[index].departure.selectMax = value.max;

                    item.departureTimeFrom = convertHourInDateTime(value.min);
                    item.departureTimeTo = convertHourInDateTime(value.max);

                } else {
                    item.departureTimeFrom = convertHourInDateTime(updateRangeTimeInfo[i].departure.selectMin);
                    item.departureTimeTo = convertHourInDateTime(updateRangeTimeInfo[i].departure.selectMax);
                }

                if (typeFlt === 'R') {
                    updateRangeTimeInfo[index].arrival.selectMin = value.min;
                    updateRangeTimeInfo[index].arrival.selectMax = value.max;

                    item.arrivalTimeFrom = convertHourInDateTime(value.min);
                    item.arrivalTimeTo = convertHourInDateTime(value.max);
                } else {
                    item.arrivalTimeFrom = convertHourInDateTime(updateRangeTimeInfo[i].arrival.selectMin);
                    item.arrivalTimeTo = convertHourInDateTime(updateRangeTimeInfo[i].arrival.selectMax);
                }

            }

            selectedItems.push(item);
        }

        setDefaultRangeTimeInfo(updateRangeTimeInfo);
        onApplyFilter(selectedItems, type);
    }

    function onShowFilter(event) {
        event.preventDefault();
        setShowFilter(!showFilter);
    }

    return (
        <>
            {
                defaultRangeTimeInfo && defaultRangeTimeInfo.length > 0 && <div className="card bg-white rounded active my-1 text-primary h6 shadow">
                    <a href="#" className="text-decoration-none " onClick={(e) => onShowFilter(e)}>
                        <div className="row">
                            <div className="col-10 pl-4 pt-2">
                                <span className="p-2 text-left text-blue">
                                    <data m3lab={label}>
                                        <M3Icon iconName={iconTitle} externalClass="icon-10" />
                                        {t(label)}
                                    </data>
                                </span>
                            </div>
                            <div className="col-1 text-left p-1">
                                <data m3ico="Remove text-blue">
                                    {showFilter && <M3Icon iconName="Remove" externalClass="text-blue" hasLabel={false} />}
                                    {!showFilter && <M3Icon iconName="Add" externalClass="text-blue" hasLabel={false} />}
                                </data>
                            </div>
                        </div>
                    </a>
                    {
                        showFilter && defaultRangeTimeInfo.map((item, key) => {
                            return <ul key={key} className="list-group">
                                <div className="h6 mb-0 pl-2 mb-1">
                                    {defaultRangeTimeInfo.length === 2 && key === 0 && <data m3lab='Product:Flight:Going'>{t('Product:Flight:Going')}</data>}
                                    {defaultRangeTimeInfo.length === 2 && key === 1 && <data m3lab='Product:Flight:Return'>{t('Product:Flight:Return')}</data>}
                                    {type === 'FlightDestinationTime' && defaultRangeTimeInfo.length !== 2 && <>{item.legCriteria.departureLocation.code} - {item.legCriteria.arrivalLocation.code}</>}
                                </div>
                                <label className="mb-0 pl-2"><data m3lab='Product:Flight:Takeoff'>{t('Product:Flight:Takeoff')}</data></label>
                                {
                                    <ProductFilterByTime
                                        rangeTimeInfo={defaultRangeTimeInfo[key].departure}
                                        onApplyFilter={onApplyFilterTime}
                                        resetSelectedItem={resetSelectedItem}
                                        type={"A_" + key}
                                        extraOptions={{ isSubFilter: true, index: key }} />
                                }
                                <label className="mb-0 pl-2"><data m3lab='Product:Flight:Landing'>{t('Product:Flight:Landing')}</data></label>
                                {
                                    <ProductFilterByTime
                                        rangeTimeInfo={defaultRangeTimeInfo[key].arrival}
                                        onApplyFilter={onApplyFilterTime}
                                        resetSelectedItem={resetSelectedItem}
                                        type={"R_" + key}
                                        extraOptions={{ isSubFilter: true }} />
                                }
                            </ul>
                        })
                    }
                </div>
            }
        </>
    );
}