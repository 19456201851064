import React from 'react';
import { replaceAllStyleElements } from '../../../../../js/PrintUtility';

export const PrintVoucherDynamicNote = ({ notes, t }) => {
    return (
        <>
            {notes && notes.length > 0 &&
                <>
                    <div className="stepHeader color-text-voucher" style={{ borderRadius: "5px", padding: "7px" }}>
                        <h5>Note &nbsp;{t("home:title")}</h5>
                    </div>
                    {notes.map((n, index) =>
                        <>
                            <div style={{ padding: "7px" }}></div>
                            <div style={{ border: "1px solid grey", borderRadius: "5px", padding: "5px", textAlign: "justify" }}>
                                <span style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(n.note) }} />
                            </div>
                        </>
                    )}
                </>
            }
        </>
    );
}