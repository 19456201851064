// Common Utilities JS
import Cookies from 'universal-cookie';
import { BookParameterId, searchRangeParameter } from './Constant';
import configData from "../appsettings.json";
import { imageOnLoadIsFavourite, imageOnLoadIsNotFavourite } from './CDNUtility.js';

const cookies = new Cookies();

/* START WHITELABEL */

export const setWebsiteInfoSession = (website) => {
    if (website) {
        localStorage.removeItem("WebsiteInfo");
        let jWebsite = JSON.stringify(website);
        localStorage.setItem("WebsiteInfo", jWebsite);
    } else {
        localStorage.removeItem("WebsiteInfo");
    }

}

export const clearAllSessionData = () => {

    if (cookies.get("SessionToken")) {
        cookies.remove("SessionToken", { path: "/" });
    }

    if (cookies.get("orderNumber")) {
        cookies.remove("orderNumber", { path: "/" });
    }

    if (cookies.get("TravelplaceAuth0")) {
        cookies.remove("TravelplaceAuth0", { path: "/" });
    }

    localStorage.clear();
    // sessionStorage.clear();
};

export const getWebsiteInfo = () => {

    const jWebsite = localStorage.getItem("WebsiteInfo");
    if (jWebsite !== null && jWebsite !== undefined) {
        let currentWebsite = JSON.parse(jWebsite);
        return currentWebsite;
    }

    return null;
}

export const getWebsiteId = () => {

    const websiteInfo = getWebsiteInfo();
    if (websiteInfo)
        return websiteInfo.websiteId;

    return configData.Settings.WebsiteId;
}

export const getWebsiteIsAnonymous = () => {

    const websiteInfo = getWebsiteInfo();
    if (websiteInfo)
        return websiteInfo.isAnonymous;

    return configData.Settings.Website.IsAnonymous;
}

export const getWebsiteGetStakeholderIdRegistration = () => {

    const websiteInfo = getWebsiteInfo();
    if (websiteInfo)
        return websiteInfo.isAnonymous;

    if (configData.Settings.EndUser)
        return configData.Settings.EndUser.StakeholderId;

    return null;
}

/* END WHITELABEL */

export const getAuthToken = () => {
    let sToken = "";
    let i = 0;
    while (true) {
        let tmpToken = cookies.get("TravelplaceAuth" + i);
        if (tmpToken === undefined)
            break;

        i++;
        sToken += tmpToken;
    }

    return sToken;
}

export const decodeJwtToken = (jwtToken) => {
    let base64Url = jwtToken.split('.')[1];
    let base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}

export const getCurrentUserFromJwtToken = (jwtToken) => {
    let oJwtDecoded = decodeJwtToken(jwtToken);

    let expiredDate = new Date(oJwtDecoded.exp * 1000);
    expiredDate.setHours(23);
    expiredDate.setMinutes(59);
    expiredDate.setSeconds(59);

    let currentUser = {
        username: oJwtDecoded["Username"],
        userId: oJwtDecoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"],
        name: oJwtDecoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"].trim(),
        token: jwtToken,
        expire: expiredDate,
        websiteId: oJwtDecoded["WebsiteId"],
        idCanaleVendita: oJwtDecoded["IdCanaleVendita"],
        stakeholderId: oJwtDecoded["StakeholderId"],
        roles: oJwtDecoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
    };

    return currentUser;
}

export const formatNumber = (value, cultureCode) => {
    if (!value)
        value = 0;

    cultureCode = cultureCode || "en-US";
    return new Intl.NumberFormat("it-IT", { minimumFractionDigits: 2 }).format(value);
}

export const formatPrice = (value, currency, cultureCode, options = {}) => {
    if (value === null || value === undefined || !currency)
        return "0";

    cultureCode = cultureCode || "en-US";
    let signDisplay = "auto";
    if (options.signDisplay) {
        signDisplay = "always";
    }

    let minimumFractionDigits = 2;
    if (options.noDecimals)
        minimumFractionDigits = 0;

    return new Intl.NumberFormat(cultureCode, { style: 'currency', currency: currency, signDisplay: signDisplay, minimumFractionDigits: minimumFractionDigits }).format(value);
}

export function getDisplayPrice(pricing, cultureName, showCost, templateInfo) {
    if (pricing) {
        let price = templateInfo && templateInfo.selectedProductPrice
            ? pricing.displayPrice - templateInfo.selectedProductPrice
            : pricing.displayPrice;

        let sign = "";
        if (templateInfo && templateInfo.selectedProductPrice) {
            sign = price > 0 ? "+" : "";
        }

        return `${sign}${formatPrice(price, pricing.valutaCliente, cultureName)}`;
    }

    return formatPrice(0, pricing.valutaCliente, cultureName);
}

export function getDisplayPriceLabel(showCost, isCommisionable, labelCustom) {
    if (!showCost && isCommisionable)
        return "General:Commission";

    return labelCustom;
}


// START DateTime Utility

export const formatDateTime = (datetime, cultureCode, options) => {
    if (!datetime)
        return "";

    cultureCode = cultureCode || "en-US";

    let myDate = new Date(datetime);

    /* se non ho options, restituisco una data in formato default dd/MM/yyyy */
    if (options === null || options === undefined)
        return new Intl.DateTimeFormat(cultureCode, { year: "numeric", month: "2-digit", day: "2-digit" }).format(myDate);

    if (options.monthLabel) {
        if (options.noYear)
            return new Intl.DateTimeFormat(cultureCode, { month: "long", day: "2-digit" }).format(myDate);

        return new Intl.DateTimeFormat(cultureCode, { year: "2-digit", month: "long", day: "2-digit" }).format(myDate);

    }

    if (options.twoDigits)
        return new Intl.DateTimeFormat(cultureCode, { year: "2-digit", month: "2-digit", day: "2-digit" }).format(myDate);

    if (options.withTime && options.noYear)
        return new Intl.DateTimeFormat(cultureCode, { month: "2-digit", day: "2-digit", hour: "numeric", minute: "numeric" }).format(myDate);

    if (options.noYear)
        return new Intl.DateTimeFormat(cultureCode, { month: "2-digit", day: "2-digit" }).format(myDate);

    if (options.withTime)
        return new Intl.DateTimeFormat(cultureCode, { year: "numeric", month: "2-digit", day: "2-digit", hour: "numeric", minute: "numeric" }).format(myDate)

    if (options.onlyTime)
        return new Intl.DateTimeFormat(cultureCode, { hour: "numeric", minute: "numeric" }).format(myDate);
}

export const convertHourInDateTime = (hour) => {
    let defaultSeconds = "00";
    if (hour === '23:59')
        defaultSeconds = "59";

    return "2099-01-01 " + hour + ":" + defaultSeconds;
}

export const convertHourInMilliseconds = (hour) => {
    let totalMs = 0;

    if (hour) {
        let hourPart = hour.split(':');
        let hrs = parseInt(hourPart[0]);
        let min = parseInt(hourPart[1]);

        let sec = 0;
        if (hourPart.length > 2)
            sec = parseInt(hourPart[2]);

        totalMs = (hrs * 60 * 60 + min * 60 + sec) * 1000
    }

    return totalMs;
}

// END DateTime Utility


export const getModuleName = (moduleId) => {
    moduleId = moduleId + ""; // fix toString

    switch (moduleId) {
        case "1": return "Hotel";
        case "2": return "Apartment";

        case "11": return "Activity";
        case "12": return "Tour";

        case "21": return "CarRental";

        case "31": return "Cruise";

        case "41": return "Transfer";

        case "51": return "Flight";

        case "71": return "Package";

        case "81": return "Coupon";

        case "91": return "RentSellProduct";

        case "110": return "Train";

        case "Generic":
        case "99":
            return "Apartment";

        default: return "";
    }
}

export const getStatusClass = (status) => {
    switch (status) {
        case "UNDEFINED": return "manage";
        case "ONREQUEST": return "onrequest";
        case "CONFIRMED": return "confirm";
        case "DELETED": return "delete";
        case "OPTION": return "option";
        case "MANAGE": return "manage";
        case "SOLDOUT": return "delete";
        case "PRINTING": return "print";
        case "PRINTED": return "print";
        case "DELETERQ": return "delete";
        case "PAID": return "payed";
        case "VOID": return "payed";
        case "REFUND": return "payed";
        case "PENALTY": return "penalty";
        case "WAIT": return "wait";
        case "NOTFINALIZE": return "notfinalize";
        default: return "sospended";
    }
}

export const getStructureStars = (categoryId) => {
    switch (categoryId) {
        case "1":
        case "2":
            return (
                <>
                    <span className="star fas fa-star text-warning"></span>
                </>
            );

        case "3":
        case "4":
            return (
                <>
                    <span className="star fas fa-star text-warning"></span><span className="star fas fa-star text-warning"></span>
                </>
            );

        case "5":
        case "6":
            return (
                <>
                    <span className="star fas fa-star text-warning"></span><span className="star fas fa-star text-warning"></span><span className="star fas fa-star text-warning"></span>
                </>
            );

        case "7":
        case "8":
            return (
                <>
                    <span className="star fas fa-star text-warning"></span><span className="star fas fa-star text-warning"></span><span className="star fas fa-star text-warning"></span><span className="star fas fa-star text-warning"></span>
                </>
            );

        case "9":
        case "10":
            return (
                <>
                    <span className="star fas fa-star text-warning"></span><span className="star fas fa-star text-warning"></span><span className="star fas fa-star text-warning"></span><span className="star fas fa-star text-warning"></span><span className="star fas fa-star text-warning"></span>
                </>
            );

        case "11":
        case "12":
        case "13":
        case "14":
        case "15":
        case "99":
        default:
            return (<></>);
    }
}

export const scrollTo = (e, aId) => {
    if (e)
        e.preventDefault();

    var item = document.getElementById(aId);
    const yOffset = document.getElementById("header").offsetHeight;
    const y = item.getBoundingClientRect().top + window.pageYOffset - yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
}

export const closeTpSearchInput = (modalId, closePanel) => {
    let fakeModalM3 = document.getElementById(modalId);
    if (fakeModalM3) {
        fakeModalM3.addEventListener('shown.bs.modal', function () {
            try {
                let element = document.getElementsByClassName('modal-backdrop')[0];
                element.style.setProperty("z-index", 100);

                element.addEventListener('click', function () {
                    closePanel();
                }, true);

            } catch (ex) { }

            try {
                let element = document.getElementsByTagName('body')[0];
                element.style.removeProperty("overflow");
                element.style.setProperty("overflow-y", "scroll");
            } catch (ex) { }

            try {

                let element = document.getElementsByTagName('header')[0];
                element.addEventListener("click", () => {
                    closePanel();
                });

            } catch (ex) {

            }
        });
    }
}

export const createRenderHtml = (str) => {
    return { __html: str };
}

export function groupBy(arr, property) {
    var reducedArr = arr.reduce(function (memo, x) {
        if (!memo[x[property]]) { memo[x[property]] = []; }
        memo[x[property]].push(x);
        return memo;
    }, {});

    return reducedArr;
};

export function countElementsInRange(array, min, max) {
    // this function count how many elements there are in the range "min"-"max"" inside the "array"
    let count = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i] >= min && array[i] <= max) {
            count++;
        }
    }
    return count;
}

export function handleError(response) {
    if (!response.ok) {
        throw Error(response.body);
    }

    if (!response.body)
        return "";

    return response.json();
};

export const getQuotationItemStatus = (status) => {
    switch ((status + "").toUpperCase()) {
        case "0":
        case "DRAFT":
            return 'quotationto:status:draft';

        case "1":
        case "CONFIRMED":
            return 'quotationto:status:confirmed';

        case "2":
        case "MODIFY":
            return 'quotationto:status:update';

        case "3":
        case "ANNULLED":
            return 'quotationto:status:cancel';
        case "4":
        case "OPTION":
            return 'quotationto:status:option';
        case "5":
        case "CONFIRMEDBOOK":
        case "CONFIRMEDWITHBOOK":
            return 'quotationto:status:confirmwithbook';
        case "6":
        case "NOTCONFIRMEDBOOK":
            return 'quotationto:status:notconfirmwithbook';

        case "70":
        case "REPLACED":
            return 'quotationto:status:replaced';

        case "80":
        case "DELETED":
            return 'quotationto:status:delete';

        case "SENT":
            return 'quotationto:status:send';

        case "WORKED":
            return 'quotationto:status:working';

        default: return "Undefined";
    }
}

export const getQuotationStatusClass = (status) => {
    switch (status) {
        case "Draft": return "manage";
        case "Sent": return "payed";
        case "Modified": return "option";
        case "Annulled": return "delete";
        case "Confirmed": return "confirm";
        case "ConfirmedWithBook": return "confirm";
        case "Worked": return "payed";
        default: return "sospended";
    }
}
export const getQuotationItemStatusClass = (status) => {

    switch (status.toString().toUpperCase()) {
        case "DRAFT":
        case "0":
            return "text-warning";

        case "CONFIRMED":
        case "1":
            return "text-success";

        case "MODIFY":
        case "2":
            return "text-info";

        case "ANNULLED":
        case "3":
            return "text-danger";

        case "OPTION":
        case "4":
            return "text-info";

        case "CONFIRMEDBOOK":
        case "5":
            return "text-success";

        case "NOTCONFIRMEDBOOK":
        case "6":
            return "text-danger";

        case "REPLACED":
        case "70":
            return "text-info";

        case "DELETED":
        case "80":
            return "text-danger fw-bold";

        default: return "sospended";
    }
}

export const validateBookParam = (id, val, forceValidate) => {
    switch (id) {
        case BookParameterId.Personal.BirthDate:
            try {
                let [year, month, day] = val.split('-').map(Number);
                let date = new Date(year, month - 1, day);
                let today = new Date();
                let sYear = val.split('-')[0];

                return date < today && year > 1920 && sYear.length === 4;
            }
            catch (ex) {
                return Number(val.split('-')[0]) > 1920 && val.split('-')[0].length === 4;
            }

        case BookParameterId.Personal.Gender:
        case BookParameterId.Document.Type:
            return val.length === 1;

        case BookParameterId.Contact.Email:
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/.test(val);

        case BookParameterId.Contact.HomePhone:
            return val.length > 6;

        case BookParameterId.Personal.FiscalCode:
            return forceValidate;

        default:
            return val && val.length > 1;
    }
}

export const getPaymentType = (paymentTypeId) => {
    switch (paymentTypeId) {
        case 1: return "BankTransfer";
        case 2: return "CreditCard";
        case 3: return "FidoFornitore";
        case 4: return "CreditCardSupplier";
        case 5: return "WelfarePoints";
        case 6: return "FidoCliente";

        default: return "";
    }
}
export const getPaymentTypeIconName = (paymentTypeId) => {
    switch (paymentTypeId) {
        case 1: return "banktransfer";
        case 2: return "creditcard";
        case 3: return "credit";
        case 4: return "creditcard";
        case 5: return "credit";
        case 6: return "credit";

        default: return "";
    }
}

export const getMealPlanLabelExtended = (mealPlanId, onlyType = false) => {
    let type = "";

    switch (mealPlanId) {
        case "0":
            {
                //type = "NN";
                type = "OB";
                break;
            }
        case "2":
            {
                type = "BB";
                break;
            }
        case "3":
            {
                type = "HB";
                break;
            }
        case "4":
            {
                type = "FB";
                break;
            }
        case "5":
            {
                type = "AI";
                break;
            }
        case "1":
        default:
            {
                type = "OB";
                break;
            }

    }

    if (onlyType)
        return type
    else
        return "Product:Structure:Filter:MealPlan:Text" + type;
}

export const getBasicDetailUrl = (productType, extraParam) => {
    let link = "";

    switch (productType) {
        case configData.Settings.Products.Activity.IdTipoProdotto: {
            link = "Activity/ActivityDetail?aId=" + extraParam.productId + "&cId=" + extraParam.carpetId + "&cfId=" + extraParam.clientFlowId;
            break;
        }

        case configData.Settings.Products.Structure.IdTipoProdotto: {
            link = "Hotel/HotelDetail?sId=" + extraParam.productId + "&cId=" + extraParam.carpetId + "&cfId=" + extraParam.clientFlowId;
            break;
        }

        case configData.Settings.Products.Flight.IdTipoProdotto: {
            link = "Flight/FlightDetail?cId=" + extraParam.carpetId + "&cfId=" + extraParam.clientFlowId + "&itineraryId=" + extraParam.itineraryId + "&fareId=" + extraParam.fareId;
            break;
        }

        case configData.Settings.Products.Cruise.IdTipoProdotto: {
            link = "Cruise/CruiseDetail?cId=" + extraParam.carpetId + "&cfId=" + extraParam.clientFlowId + "&cruiseCode=" + extraParam.cruiseCode + "&ccCode=" + extraParam.cabinCategoryCode;
            break;
        }

        default:
            break;
    }

    return link;
}

export const getBasicMapUrl = (productType, extraParam) => {
    let link = "";

    switch (productType) {
        case configData.Settings.Products.Structure.IdTipoProdotto: {
            link = "Hotel/HotelMap?cId=" + extraParam.carpetId + "&cfId=" + extraParam.clientId;
            break;
        }

        default:
            break;
    }

    return link;
}


// START Calender Method

export function addDaysToDatePicker(dateStr, addDays) {
    var updateDate = '';

    if (dateStr) {
        updateDate = new Date(dateStr);

        let times = updateDate.getTime() + (addDays * (24 * 60 * 60 * 1000))
        updateDate.setTime(times);
        updateDate = updateDate.toISOString().split('T')[0];
    }

    return updateDate;
};

export function compareDates(d1, d2) {
    let date1 = new getDateObj(d1).getTime();
    let date2 = new getDateObj(d2).getTime();

    if (date1 < date2) {
        return -1;
    } else if (date1 > date2) {
        return 1;
    } else {
        return 0;
    }
};

export function getDateObj(myDate, time = null) {

    if (!myDate)
        return null;

    let dateFromParts = myDate.split('T')[0].split('-');
    let hours = 0;
    let minutes = 0;
    if (time) {
        let timeParts = time.split(':');
        hours = timeParts[0];
        minutes = timeParts[1];
    }

    let myDateObj = new Date(dateFromParts[0], dateFromParts[1] - 1, dateFromParts[2], hours, minutes);

    return myDateObj;

};

export function getDiffDays(dateFrom, dateTo) {
    // arrivano in formato string yyyy-MM-dd
    if (dateFrom && dateTo) {
        let dateFromObj = getDateObj(dateFrom);
        let dateToObj = getDateObj(dateTo);

        const date1 = new Date(dateFromObj);
        const date2 = new Date(dateToObj);
        const diffTime = Math.abs(date2 - date1);

        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    return "";
}

export function getDateRangeLabel(dateFrom, dateTo, timeFrom = null, timeTo = null) {
    let cultureName = cookies.get("CultureName");
    let label = "";

    if (dateFrom) {
        let dateFromObj = getDateObj(dateFrom);
        label = formatDateTime(dateFromObj, cultureName, { noYear: true });
    }

    if (timeFrom)
        label += ` (${timeFrom})`;

    if (dateTo) {
        let dateToObj = getDateObj(dateTo);
        label += " - " + formatDateTime(dateToObj, cultureName, { noYear: true });
    }

    if (timeTo)
        label += ` (${timeTo})`;

    return label;
}

export function getMonthRangeLabel(months) {
    if (Array.isArray(months)) {
        let label = ''
        for (let i = 0; i < months.length; i++) {
            label += getMonthToString(months) + ',';
        }

        return label;
    } else {
        return getMonthToString(months);
    }
}

export function getMonthToString(myDate) {
    if (!myDate)
        return '';

    if (typeof myDate === 'string')
        myDate = getDateObj(myDate);

    let monthStr = myDate.getMonth() + 1;
    return monthStr + "/" + myDate.getFullYear();
}

// END Calendar Method


// START Paxes Compostion Method

export function getDefaultRoom(type) {
    return {
        adults: searchRangeParameter[type].defaultAdults,
        children: searchRangeParameter[type].defaultChildren,
        childAges: []
    }
}

export function getTotalPaxesFromSearchParameters(passengerCompositions, productType, typeComposition = false) {
    let totalAdtPC = 0;
    let totalChdPC = 0;
    let totalInfPC = 0;
    let totalRM = 1;

    // se è un volo o treno, bisogna gestirlo in un modo diverso il calcolo
    // se productType null, gestirlo come le strutture e altri prodotti (vuol dire che sto calcolando i pax in modo normalizzo per il motore lato FE)
    if (passengerCompositions && passengerCompositions.length > 0) {

        let useTravelerObj = productType === configData.Settings.Products.Flight.IdTipoProdotto || productType === configData.Settings.Products.Train.IdTipoProdotto;
        if (!useTravelerObj || typeComposition) {
            for (let i = 0; i < passengerCompositions.length; i++) {
                let pc = passengerCompositions[i];
                totalAdtPC += pc.adults;

                if (productType !== configData.Settings.Products.Flight.IdTipoProdotto)
                    totalChdPC += pc.children;
                else {

                    for (let j = 0; j < pc.childAges.length; j++) {
                        let age = pc.childAges[j];
                        if (age > searchRangeParameter.flight.maxInfantAge && age <= searchRangeParameter.flight.maxChildAge)
                            totalChdPC += 1;
                        else
                            totalInfPC += 1;
                    }

                }
            }

            totalRM = passengerCompositions.length;

        } else {

            for (let i = 0; i < passengerCompositions.length; i++) {
                let pc = passengerCompositions[i];

                if ((pc.age || pc.age === 0) && pc.age <= searchRangeParameter.flight.maxInfantAge) {
                    totalInfPC += 1;
                } else if (pc.age && pc.age <= searchRangeParameter.flight.maxChildAge) {
                    totalChdPC += 1;
                } else {
                    totalAdtPC += 1;
                }

            }

            totalRM = 1;
        }

    }

    return {
        totalAdtPC: totalAdtPC,
        totalChdPC: totalChdPC,
        totalInfPC: totalInfPC,
        totalRM: totalRM,
    };
}

// END Paxes Compostion Method


// START Website Utility

export async function GetModulesRules() {
    let MyKeyHeaderRules = "HeaderItemsRules_" + getWebsiteId();

    try {
        let token = getAuthToken();
        let currentUser = getCurrentUserFromJwtToken(token);
        MyKeyHeaderRules += "_" + currentUser.userId
    } catch (ex) { }

    let strMyRules = sessionStorage.getItem(MyKeyHeaderRules);
    if (!strMyRules) {

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}website/getmodules/${getWebsiteId()}`, requestOption);

        if (fetchedRes.ok) {
            const item = await fetchedRes.json();
            sessionStorage.setItem(MyKeyHeaderRules, JSON.stringify(item));
            return item;
        } else {
            console.error(fetchedRes);
        }

    } else {
        let jCurrentHeader = JSON.parse(strMyRules);
        return jCurrentHeader;
    }
}

export function getIconFavoutire(isFavourite, t, onClickFavourite, isShowLabel) {
    return (
        <>
            <button className="btn m-0 p-0 h6" m3Role="w_Favorite" onClick={e => onClickFavourite(e)}>
                {isShowLabel && <data m3lab="Button.Prefer">{t(`Button:Prefer`)}</data>}
                {/*<data m3ico="FavoriteOutline icon-10">
                    <M3Icon iconName="FavoriteOutline" externalClass="icon-10" />
                </data>*/}
                <img className="ml-2 mb-1" src={isFavourite ? imageOnLoadIsFavourite() : imageOnLoadIsNotFavourite()} height="18px" />
            </button>
        </>
    );
}

// END Website Utility

export function isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobileWidth = window.innerWidth <= 991;

    // Controllo per dispositivi Android
    if (/android/i.test(userAgent)) {
        return true;
    }

    // Controllo per dispositivi iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true;
    }

    return isMobileWidth;
}
