import React from 'react';
import { formatPrice, formatDateTime } from '../../../js/Utils.js';
import { translateQuotationCommissionableGroupType } from '../enums/quotationTOEnums';
import { useTranslation } from 'react-i18next';

export const QuotationPrintHeader = (props) => {
    const { t } = useTranslation();

    return (
        <> <table className="mybook-order mybook-order-main p-0" style={{ marginBottom: '5px' }}>
            <div className="card border-light p-0 p-md-4">
                <tr className="row">
                    <td className="col-lg-2 border text-lg-center p-0 mybook-order-card">
                        <div>
                            <label>N. preventivo</label><h5>{props.quotationHeader.ticketId} - {props.quotationHeader.quotationId}</h5>
                        </div>
                        {props.quotationHeader.numeroOrdine &&
                            <div style={{paddingTop: '15px'}}><label>Numero Ordine</label><h5>{props.quotationHeader.numeroOrdine}</h5>
                        </div>}
                        <div style={{ paddingTop: '15px' }}><label>Data</label> <h5>{formatDateTime(props.quotationHeader.createDate, props.cultureName, { twoDigits: true })}</h5></div>
                    </td>
                    <td className="col-lg-8 border">
                        <div className="row">
                            <div className="mybook-order-card">
                                <div style={{ paddingTop: '15px' }}>
                                    <label>Agenzia di viaggio</label><h5>{props.customerInfoQuotation.ragioneSociale}</h5>
                                    <label>Indirizzo</label><h5>{props.customerInfoQuotation.address}</h5>
                                    <label>Città</label><h5>{props.customerInfoQuotation.zipCode}, {props.customerInfoQuotation.city}</h5>
                                    <label>Contatti</label><h5>{props.customerInfoQuotation.email}, {props.customerInfoQuotation.phone}</h5>
                                    <label></label><h5>{props.customerInfoQuotation.logoUrl}</h5>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className="col-lg-4 border text-lg-center mybook-order-card">
                        <h6>Totale preventivo</h6>
                        {!props.isAgencyMode && <h6>{props.quotationHeader.tablePricing.isCommissionable ? "Commissionable" : ""}</h6>}
                        <h3>{formatPrice(props.quotationHeader.tablePricing.totalPrice, props.quotationHeader.tablePricing.currency, props.cultureName)}</h3>
                    </td>
                </tr>

                <div className="p-0 pt-2">
                    <div className=" card border-gray-400">
                        <div className="row">
                            {
                                !props.isAgencyMode && props.quotationHeader.tablePricing.tableMargin.totalInfoItems && props.quotationHeader.tablePricing.tableMargin.totalInfoItems.length > 0 ?
                                    props.quotationHeader.tablePricing.tableMargin.totalInfoItems.map((item, index) => {
                                        return <div key={`table_tr_recap_comm_item_${index}`} className="col-12 col-sm-12 col-md-12 col-lg-3">
                                            <span><label>{translateQuotationCommissionableGroupType(item)}</label>{formatPrice(item.grossPriceAmount, item.grossPriceCurrency, props.cultureName)}</span>
                                        </div>;
                                    })
                                    : <></>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </table>
        </>

    );
}