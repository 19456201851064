import React from 'react';
import { WebMenuVoices } from '../WebMenuVoices';

export const WebMenuListVertical = ({ item, rules }) => {
    return (
        <>
            <ul className={item.className ? item.className : "list-group list-unstyled"}>
                {
                    item.children && item.children.length > 0 && item.children.map((chd, key) => {
                        return <li key={key} className={chd.className ? chd.className : ""}><WebMenuVoices item={chd} rules={rules} /></li>;
                    })
                }
            </ul>
        </>
    );
}