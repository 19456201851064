import React, { useState, useEffect } from 'react';
import { Editor } from 'primereact/editor';

export const M3TextAreaCustom = ({ defaultText, onChangeText, dataRowOptions }) => {
    const [text, setText] = useState('');

    useEffect(() => {
        setText(defaultText);
    }, [defaultText])

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <button type="button" className="ql-list" value="bullet" aria-label="List"></button>
                <button className="ql-clean" aria-label="Remove Styles"></button>
            </span>
        );
    };

    const header = renderHeader();

    const onTextChange = (event) => {
        setText(event.htmlValue);

        if (!dataRowOptions)
            onChangeText(event.htmlValue);
        else
            onChangeText(event.htmlValue, dataRowOptions);
    };

    return (
        <>
            <Editor
                value={text}
                headerTemplate={header}
                onTextChange={(e) => onTextChange(e)}
                style={{ height: '150px' }} />

        </>
    );
}
