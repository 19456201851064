import React, { useEffect, useState } from 'react';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { GalleryList } from "../../Common/Image/GalleryList";
import { M3Icon } from "../../../Common/M3Icon";
import { ProductActionMenu } from "../../Common/ProductActionMenu";
import { ProductPrice } from "../../Common/Pricing/ProductPrice";
import { getBasicDetailUrl } from '../../../../js/Utils.js';


export const CarItemList = ({ item, showPriceBar, showCost, extraParam, modalOpenProductId, onClickSearch, removeTrippie, updateTrippie }) => {
    const { t } = useTranslation();
    const [detailLink, setDetailLink] = useState('');

    useEffect(() => {
        if (!extraParam)
            extraParam = {};

        extraParam.productId = item.id;
        setDetailLink(getBasicDetailUrl(configData.Settings.Products.CarRental.IdTipoProdotto, extraParam));
    }, [item]);

    function onModalOpenProductId(productId) {
        modalOpenProductId(productId);
    }

    return (
        <>
            <div className="card shadow shadow-hover mb-4 animate-up-3 ">
                <div className="row no-gutters">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <GalleryList
                            productId={item.id}
                            defaultImage={item.imageURL}
                            gallery={[]}
                            alternativeText={item.name}
                            onClickSearch={onClickSearch}
                            productType={configData.Settings.Products.CarRental.IdTipoProdotto }
                        />
                        <div className="text-center">
                            <label>
                                <data m3lab="Product.RentCar.SippCode">{t("Product:RentCar:SippCode")}</data>:
                            </label>
                            <span className="ml-2 fw-bold h7">
                                <var>
                                    <div title={item.categoryName} className="mr-1 sippCode">
                                        {item.categoryLetter}
                                    </div>
                                    <div title={item.typeName} className="mr-1 sippCode">
                                        {item.typeLetter}
                                    </div>
                                    <div title={item.transmissionDriveName} className="mr-1 sippCode">
                                        {item.transmissionDriveLetter}
                                    </div>
                                    <div title={item.fuelAirCondName} className="mr-1 sippCode">
                                        {item.fuelAirCondLetter}
                                    </div>
                                </var>
                            </span>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <div className="card-body pt-2 px-0 pb-0">
                            <div>
                                <div className="w-100">
                                    <div className="row">
                                        <div className="col-12 col-sm-8 col-md-10 col-lg-10 col-xl-10">
                                            <label>
                                                {item.supplierName && (item.supplierName + " - " )}{item.supplier && item.supplier.supplierName}
                                            </label>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                            <div className="btn-group text-right float-end pr-2">
                                            </div>
                                        </div>

                                    </div>
                                    <div className="h5">
                                        <var>
                                            {item.name}
                                        </var>
                                    </div>

                                    <div className="w-100">
                                        <label>
                                            <data m3ico="Place ico-10">
                                                <M3Icon iconName="Place" externalclassName="icon-10" />
                                            </data>
                                            <data m3lab="Product.RentCar.Pick">{t("Product:RentCar:Pick")}</data>
                                            <var> {item.pickUpInfo && item.pickUpInfo.address}</var>
                                        </label>
                                    </div>
                                    <div className="w-100">
                                        <label>
                                            <data m3ico="Place ico-10">
                                                <M3Icon iconName="Place" externalclassName="icon-10" />
                                            </data>
                                            <data m3lab="Product.RentCar.Drop">{t("Product:RentCar:Drop")}</data>
                                            <var> {item.dropOffInfo && item.dropOffInfo.address}</var>
                                        </label>
                                    </div>
                                    <div className="d-flex mt-2">
                                        <div className="w-20">
                                            <label>
                                                <data m3ico="PaxAdl ico-10">
                                                    <M3Icon iconName="PaxAdl" externalclassName="icon-10" />
                                                </data>
                                                <var>{item.passengers}&nbsp;Pax</var>
                                            </label>
                                        </div>
                                        <div className="w-25">
                                            <label>
                                                <data m3ico="FlightBagage ico-10">
                                                    <M3Icon iconName="FlightBagage" externalclassName="icon-10" />
                                                </data>
                                                <var>{item.luggages} </var>
                                                <data m3lab="Product.RentCar.Baggages">{t("Product:RentCar:Baggages")}</data>
                                            </label>
                                        </div>
                                        {item.airCondition && item.airCondition.toLowerCase() === "ac" &&
                                            <div className="w-20">
                                                <label>
                                                    <data m3ico="AirCondition ico-10">
                                                        <M3Icon iconName="AirCondition" externalclassName="icon-10" />
                                                    </data>
                                                    <var>AC</var>
                                                </label>
                                            </div>
                                        }
                                        <div className="w-20">
                                            <label>
                                                <data m3ico="RentCar ico-10">
                                                    <M3Icon iconName="RentCar" externalclassName="icon-10" />
                                                </data>
                                                <var>{item.typeName} </var>
                                                {/*<data m3lab="Product.RentCar.Doors">{t("Product:RentCar:Doors")}</data>*/}
                                            </label>
                                        </div>
                                    </div>
                                    {/*<div className="w-100 mb-2 mt-4">
                                        <label>
                                            <data m3lab="Product.RentCar.OtherSolutions">{t("Product:RentCar:OtherSolutions")}:</data>
                                        </label>
                                    </div>
                                    <div className="position-innerit h-100 ">
                                        <div className=" bottom-0 w-100 pb-1">
                                            <table className=" w-100 text-center small">
                                                <thead>
                                                    <tr className="small">
                                                        <td>
                                                            <var>Hertz</var>
                                                        </td>
                                                        <td>
                                                            <var>Six</var>
                                                        </td>
                                                        <td>
                                                            <var>Sicily car</var>
                                                        </td>
                                                        <td>
                                                            <var>Alamo</var>
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><button className="btn btn-sm btn-neutral focus">300</button></td>
                                                        <td><button className="btn btn-sm btn-neutral"><var>550</var></button></td>
                                                        <td><button className="btn btn-sm btn-neutral"><var>150</var></button></td>
                                                        <td><button className="btn btn-sm btn-neutral"><var>550</var></button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 p-1 pr-3">
                        <ProductPrice
                            productId={item.id}
                            startDate={item.startDate}
                            endDate={item.endDate}
                            pricing={item.pricing}
                            supplier={item.supplier}
                            showPriceBar={showPriceBar}
                            showCost={showCost}
                            detailLink={detailLink}
                            typePrice="NTPax"
                            otherParam={{ mealPlanId: item.bestMealPlanId, buttonEnabled: true }}
                            onClickSearch={onClickSearch}
                            templateInfo={extraParam ? extraParam.templateInfo : null}
                            isTrippie={extraParam && extraParam.isTrippie}
                            showPriceTrippie={extraParam && extraParam.showPriceTrippie}
                            removeTrippie={removeTrippie}
                            updateTrippie={updateTrippie}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}