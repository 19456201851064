import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const HeaderProductLink = ({ item, moduleSelected, onChangePage, modal }) => {
    const { t } = useTranslation();

    const [linkId, setLinkId] = useState('');
    const [translationCode, setTranslationCode] = useState('');
    const [isSelected, setIsSelected] = useState('active');

    useEffect(() => {
        const myPathName = window.location.pathname.toUpperCase();

        switch (item.html) {
            case '#PRODUCT_HOME#': {
                if (moduleSelected === 'HOME')
                    setIsSelected('');

                setLinkId('HOME');
                setTranslationCode('Home');
                break;
            }
            case '#PRODUCT_ACTIVITY#':
                {
                    if (myPathName.includes('ACTIVITY'))
                        setIsSelected('');

                    setLinkId('ACTIVITY');
                    setTranslationCode('Product:Activity:Item');
                    break;
                }
            case '#PRODUCT_RENTCAR#':
                {
                    if (moduleSelected === 'CARRENTAL')
                        setIsSelected('');

                    setLinkId('CARRENTAL');
                    setTranslationCode('Product:RentCar:Item');
                    break;
                }
            case '#PRODUCT_CRUISE#':
                {
                    if (moduleSelected === 'CRUISE')
                        setIsSelected('');

                    setLinkId('CRUISE');
                    setTranslationCode('Product:Cruise:Item');
                    break;
                }
            case '#PRODUCT_STRUCTURE#':
                {
                    if (moduleSelected === 'HOTEL')
                        setIsSelected('');

                    setLinkId('HOTEL');
                    setTranslationCode('Product:Structure:Item');
                    break;
                }
            case '#PRODUCT_TRIPPIE#':
                {
                    if (moduleSelected === 'TRIPPIE')
                        setIsSelected('');

                    setLinkId('TRIPPIE');
                    setTranslationCode('Trippie');
                    break;
                }
            case '#PRODUCT_TRANSFER#':
                {
                    if (moduleSelected === 'TRANSFER')
                        setIsSelected('');

                    setLinkId('TRANSFER');
                    setTranslationCode('Product:Transfer:Item');
                    break;
                }
            case '#PRODUCT_TOUR#':
                {
                    if (moduleSelected === 'TOUR')
                        setIsSelected('');

                    setLinkId('TOUR');
                    setTranslationCode('Product:Tour:Item');
                    break;
                }
            case '#PRODUCT_FLIGHT#':
                {
                    if (moduleSelected === 'FLIGHT')
                        setIsSelected('');

                    setLinkId('FLIGHT');
                    setTranslationCode('Product:Flight:Item');
                    break;
                }
            case '#PRODUCT_TRAIN#':
                {
                    if (moduleSelected === 'TRAIN')
                        setIsSelected('');

                    setLinkId('TRAIN');
                    setTranslationCode('Product:Train:Item');
                    break;
                }
            default: {
                break;
            }
        }

    }, []);

    useEffect(() => {

        if (linkId === moduleSelected)
            setIsSelected('');
        else
            setIsSelected('active');

    }, [moduleSelected])

    function getClassName(className) {
        if (!className)
            return '';

        try {
            return className.split("#")[1];
        } catch (ex) { }

        return '';
    }

    return (
        <>
            <a className={getClassName(item.className) + " " + isSelected + ` ${modal ? 'd-block' : 'd-none'} d-sm-block`} style={{
                fontSize: modal ? '1.5rem' : ''
            }} aria-current="page" href="javascript:void(0)" onClick={_ => onChangePage(linkId)} >
                {t(translationCode)}
            </a>
        </>
    );
}
