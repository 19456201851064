import React, { useState, useEffect } from 'react';
import { M3Icon } from './M3Icon';

export const M3IconProductType = ({ productType, productSubtype, externalClass, hasLabel }) => {
    const [iconProductType, setIconProductType] = useState(null);

    useEffect(() => {

        if (productSubtype) {
            setModuleIcon(productSubtype);
        } else {

            productType = productType + ""; // fix toString
            switch (productType.toUpperCase()) {
                case "S":
                case "STRUCTURE":
                case "HOTEL":
                case "0":
                    setIconProductType("Structure");
                    break;
                case "A":
                case "ACTIVITY":
                case "1":
                    setIconProductType("Activity");
                    break;
                case "C":
                case "CARRENTAL":
                case "CAR":
                case "2":
                    setIconProductType("RentCar");
                    break;
                case "N":
                case "CRUISE":
                case "3":
                    setIconProductType("Cruise");
                    break;
                case "T":
                case "TRANSFER":
                case "4":
                    setIconProductType("Transfer");
                    break;
                case "F":
                case "FLIGHT":
                case "5":
                    setIconProductType("Flight");
                    break;
                case "P":
                case "PACKAGE":
                case "6":
                    setIconProductType("Package");
                    break;
                case "K":
                case "COUPON":
                case "7":
                    setIconProductType("Coupon");
                    break;
                case "R":
                case "RENTSELLPR":
                case "8":
                    setIconProductType("RentSellPr");
                    break;
                case "I":
                case "TRAIN":
                case "9":
                    setIconProductType("Train");
                    break;
                case "O":
                case "TOUR":
                case "10":
                    setIconProductType("Tour");
                    break;
                case "GENERIC":
                case "99":
                    setIconProductType("Generic");
                    break;
                case "-1": // fake id to manage template
                    setIconProductType("Template");
                    break;
                default:
                    setIconProductType("Generic");
                    break;
            }

        }

    }, [productType, productSubtype])

    function setModuleIcon(moduleId) {
        moduleId = moduleId + ""; // fix toString

        switch (moduleId.toUpperCase()) {
            case "1":
            case "2":
            case "HOTEL":
            case "APARTMENT":
                setIconProductType("Structure");
                break;

            case "11":
            case "ACTIVITY":
                setIconProductType("Activity");
                break;

            case "12":
            case "TOUR":
                setIconProductType("Tour");
                break;

            case "21":
            case "CARRENTAL":
            case "CAR":
                setIconProductType("RentCar");
                break;

            case "31":
            case "CRUISE":
                setIconProductType("Cruise");
                break;

            case "41":
            case "TRANSFER":
                setIconProductType("Transfer");
                break;

            case "51":
            case "FLIGHT":
                setIconProductType("Flight");
                break;

            case "71":
            case "PACKAGE":
                setIconProductType("Package");
                break;

            case "81":
            case "COUPON":
                setIconProductType("Coupon");
                break;

            case "91":
            case "RENTSELLPR":
                setIconProductType("RentSellPr");
                break;

            case "110":
            case "TRAIN":
                setIconProductType("Train");
                break;

            case "99":
            case "GENERIC":
                setIconProductType("Generic");
                break;

            case "-1": // fake id to manage template
                setIconProductType("Template");
                break;

            default:
                setIconProductType("FlightServices");
                break;

        }
    }

    return (
        <>
            <M3Icon iconName={iconProductType} externalClass={externalClass} hasLabel={hasLabel} />
        </>
    );
}
