export const activityDuration = [
    { key: '400', text: 'Half-day morning' },
    { key: '410', text: 'Full day' },
    { key: '420', text: 'Flexible' },
    { key: '430', text: 'Evening' },
    { key: '440', text: 'Multi-day' },
    { key: '450', text: 'Half-day afternoon' },
    { key: '460', text: 'Roundtrips' },
    { key: '470', text: 'Multiple options' },
]

export const transferPointType = [
    { key: '4', text: 'City' },
    { key: '7', text: 'Airport' },
    { key: '8', text: 'Port' },
    { key: '9', text: 'TrainStation' }
]

export const paxType = [
    { key: '0', text: 'Adult' },
    { key: '1', text: 'Child' }
]

export const flightPaxType = [
    { key: 'ADT', text: 'Adult' },
    { key: 'CHD', text: 'Child' },
    { key: 'INF', text: 'Infant' }
]