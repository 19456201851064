import React, { useState, useEffect } from 'react';
import configData from "../../../../appsettings.json";
import { handleError } from "../../../../js/Utils";

export const SubscriberForm = ({ item }) => {
    const [isSend, setIsSend] = useState(false);

    const [inputData, setInputData] = useState(
        {
            name: '',
            surname: '',
            email: '',
            phone: '',
            regione: 'NOT',
            acceptPrivacy: false
        });

    const [isValidInputData, setIsValidInputData] = useState(
        {
            name: false,
            isTouchedName: false,

            surname: false,
            isTouchedSurname: false,

            email: false,
            isTouchedEmail: false,

            phone: false,
            isTouchedPhone: false,

            regione: false,
            isTouchedRegione: false,

            acceptPrivacy: false,
            isTouchedPrivacy: false,

            isValidForm: false
        }
    );

    const [regioniIta, setRegioniIta] = useState([]);

    useEffect(() => {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}constant/getregioniita`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                setRegioniIta(item);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    function saveInputForm(action, bottoneUrl) {

        if (action) {

            let request = {
                name: inputData.name,
                surname: inputData.surname,
                email: inputData.email,
                phone: inputData.phone,
                msg: '',
                regione: inputData.regione,
                formType: action,
                acceptPrivacy: inputData.acceptPrivacy,
            }

            const requestOption = {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            };

            fetch(`${configData.Settings.CommonApi_BaseUrl}webcontent/saveforminfobv`, requestOption)
                .then((res) => handleError(res))
                .then((response) => {
                    setIsSend(true);

                    if (item.bottoneURL)
                        window.open(item.bottoneURL + "?frId=" + response.requestId, '_blank');

                })
                .catch((err) => {
                    setIsSend(false);

                    console.error(err);
                });

        } else {
            if (item.bottoneURL) {

                if (item.bottoneURL.toLowerCase().includes('http'))
                    window.open(item.bottoneURL, '_blank');
                else if (item.bottoneURL.includes('/'))
                    window.open(item.bottoneURL, '_blank');
                else
                    window.open('webcontent/' + item.bottoneURL);

            }

        }
    }

    function onBlur(event, propertyName) {
        let updateInputData = { ...inputData };
        let updateIsValidInputData = { ...isValidInputData };

        switch (propertyName) {
            case 'name': {
                let newValue = event.target.value;
                updateInputData[propertyName] = newValue;

                const isEmpty = newValue === null || newValue === '' || newValue === undefined;
                updateIsValidInputData[propertyName] = !isEmpty;
                updateIsValidInputData.isTouchedName = true;

                break;
            }
            case 'surname': {
                let newValue = event.target.value;
                updateInputData[propertyName] = newValue;

                const isEmpty = newValue === null || newValue === '' || newValue === undefined;
                updateIsValidInputData[propertyName] = !isEmpty;
                updateIsValidInputData.isTouchedSurname = true;

                break;
            }
            case 'email': {
                let newValue = event.target.value;
                updateInputData[propertyName] = newValue;

                const isEmpty = newValue === null || newValue === '' || newValue === undefined;
                const isValidMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(newValue);

                updateIsValidInputData[propertyName] = !isEmpty && isValidMail;
                updateIsValidInputData.isTouchedEmail = true;

                break;
            }
            case 'phone': {
                let newValue = event.target.value;
                updateInputData[propertyName] = newValue;

                const isEmpty = newValue === null || newValue === '' || newValue === undefined;
                const isValidPhone = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(newValue);

                updateIsValidInputData[propertyName] = !isEmpty && isValidPhone;
                updateIsValidInputData.isTouchedPhone = true;

                break;
            }
            case 'regione': {
                let selectedId = event.target.value;

                let index = event.target.selectedIndex;
                let newValue = event.target[index].text;
                updateInputData[propertyName] = newValue;

                const isEmpty = selectedId === 'NOT' || newValue === null || newValue === '' || newValue === undefined;
                updateIsValidInputData[propertyName] = !isEmpty;
                updateIsValidInputData.isTouchedRegione = true;

                break;
            }
            case 'acceptPrivacy': {
                let newValue = event.target.checked;

                updateInputData[propertyName] = newValue;
                updateIsValidInputData[propertyName] = newValue;
                updateIsValidInputData.isTouchedPrivacy = true;

                break;
            }
        }

        delete updateIsValidInputData.isValidForm;
        const isValidForm = Object.values(updateIsValidInputData).every(item => item);
        updateIsValidInputData.isValidForm = isValidForm;

        setInputData(updateInputData);
        setIsValidInputData(updateIsValidInputData);
    }

    return <div className="row">
        <div className="col-12">
            <div className="form-group">
                <input type="text"
                    className={"form-control font-weight-light mb-2 w-100 border " + (isValidInputData.name ? "border-light" : "border-danger")}
                    id="nameContactForm"
                    value={inputData.name}
                    onChange={e => onBlur(e, 'name')}
                    onBlur={e => onBlur(e, 'name')}
                    aria-describedby="emailHelp"
                    placeholder="Nome *" />
                {(!isValidInputData.name && isValidInputData.isTouchedName) && <span className="text-danger">Campo nome obbligatorio</span>}

                <input type="text"
                    className={"form-control font-weight-light mb-2 w-100 border " + (isValidInputData.surname ? "border-light" : "border-danger")}
                    id="surnameContactForm"
                    value={inputData.surname}
                    onChange={e => onBlur(e, 'surname')}
                    onBlur={e => onBlur(e, 'surname')}
                    aria-describedby="emailHelp"
                    placeholder="Cognome *" />
                {(!isValidInputData.surname && isValidInputData.isTouchedSurname) && <span className="text-danger">Campo cognome obbligatorio</span>}

                <input type="email"
                    className={"form-control font-weight-light mb-2 w-100 border " + (isValidInputData.email ? "border-light" : "border-danger")}
                    id="emailContactForm"
                    value={inputData.email}
                    onChange={e => onBlur(e, 'email')}
                    onBlur={e => onBlur(e, 'email')}
                    aria-describedby="emailHelp"
                    placeholder="Email *" />
                {(!isValidInputData.email && isValidInputData.isTouchedEmail) && <span className="text-danger">La email non è valida</span>}

                <input type="text"
                    className={"form-control font-weight-light mb-2 w-100 border " + (isValidInputData.phone ? "border-light" : "border-danger")}
                    id="phoneContactForm"
                    value={inputData.phone}
                    onChange={e => onBlur(e, 'phone')}
                    onBlur={e => onBlur(e, 'phone')}
                    aria-describedby="emailHelp"
                    placeholder="Cellulare *" />
                {(!isValidInputData.phone && isValidInputData.isTouchedPhone) && <span className="text-danger">Il cellulare non è valido</span>}

                <select
                    className={"form-select font-weight-light mb-2 w-100 border " + (isValidInputData.regione ? "border-light" : "border-danger")}
                    id="floatingSelect"
                    onChange={e => onBlur(e, 'regione')}
                    aria-label="type">
                    <option key="-1" value="NOT">Seleziona Regione *</option>
                    {
                        regioniIta.map((item, key) => {
                            return <option key={key} value={item.sigla}>{item.name}</option>
                        })
                    }
                </select>
                {(!isValidInputData.regione && isValidInputData.isTouchedRegione) && <div className="text-danger">Seleziona una regione</div>}

                <input type="checkbox"
                    className={"border " + (isValidInputData.acceptPrivacy ? "border-light" : "border-danger")}
                    checked={inputData.acceptPrivacy}
                    onChange={e => onBlur(e, 'acceptPrivacy')} />
                Ho letto e accetto <a href="https://www.borsaviaggi.it/privacy" className="text-white-50" target="_blank"><u>l'informativa sulla privacy</u></a>
                {(!isValidInputData.acceptPrivacy && isValidInputData.isTouchedPrivacy) && <div className="text-danger">Devi accettare l'informativa sulla privacy</div>}

                <div className="text-right mt-1">
                    <button
                        className="btn btn-primary"
                        disabled={!isValidInputData.isValidForm}
                        onClick={e => saveInputForm(item.formTypeName, item.bottoneURL)}>
                        {item.bottoneTesto ? item.bottoneTesto : "Invia"}
                    </button>
                    {
                        isSend && <div>La tua richiesta è stata inviata con successo.</div>
                    }
                </div>

            </div>
        </div>
    </div>;
}