import React, { useState, useEffect } from "react";
import { InputTime } from './InputTime';
import { InputTimeReadOnly } from './InputTimeReadOnly';
import { M3Time } from './M3Time';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { closeTpSearchInput } from '../../../js/Utils';

export const InputTimeContainer = ({ externalId, time, productType, onOpenPanelTime, onHandleTime, closeExternal, extraOptions, modeView, type }) => {
    const { t } = useTranslation();

    const [selectTime, setSelectTime] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);

    const [idModal] = useState(getRandomInt());
    function getRandomInt() {
        return Math.floor(Math.random() * 100000);
    }
    const modalId = "Time_" + idModal;

    useEffect(() => {

        if (modeView === 'SEARCHENGINE')
            closeTpSearchInput(modalId, closePanelCalendar);

    }, []);


    useEffect(() => {
        if (time) {
            setSelectTime(time);
        }
    }, [time]);

    useEffect(() => {
        if (selectTime)
            onHandleTime(selectTime.toTimeString().split(' ')[0].slice(0, -3));
    }, [selectTime]);

    useEffect(() => {
        setShowCalendar(closeExternal);
    }, [closeExternal]);

    function onClickOpenCalendar() {
        setShowCalendar(!showCalendar);

        if (!externalId)
            onOpenPanelTime();
        else
            onOpenPanelTime(externalId);

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnModalTime_" + idModal);
            btn.click();
        }
    }

    function closePanelCalendar() {
        setShowCalendar(false);

        try {

            let myModal = document.getElementById(idModal);
            myModal.style.setProperty("height", "0px");

        } catch (ex) { }

        if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnCloseModalTime_" + idModal);
            btn.click();
        }
    }

    function onSelectTime(value) {
        setSelectTime(value);

        /*if (modeView === 'SEARCHENGINE') {
            let btn = document.getElementById("btnCloseModalTime_" + idModal);
            btn.click();
        }*/
    }

    function isMobile(excludeModeView = true) {
        if (excludeModeView && modeView === 'MODAL')
            return false;

        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const isMobileWidth = window.innerWidth <= 991;

        // Controllo per dispositivi Android
        if (/android/i.test(userAgent)) {
            return true;
        }

        // Controllo per dispositivi iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return true;
        }

        return isMobileWidth;
    }

    const getPlaceholder = () => {
        return t('SearchEngine:Time');
    }

    return (
        <>
            <div className="w-100">
                {
                    !showCalendar && <InputTimeReadOnly
                        time={selectTime && selectTime.toTimeString().split(' ')[0].slice(0, -3)}
                        extraOptions={extraOptions}
                        productType={productType}
                        modeView="MODAL"
                        onClickOpen={onClickOpenCalendar} />
                }
            </div>

            <a id={"btnModalTime_" + idModal} data-bs-toggle="modal" data-bs-target={"#" + modalId}></a>
            <div
                className={"modal " + (isMobile() ? "" : " tp-search-input position-relative ") + (modeView === 'MODAL' ? "d-block" : "fade") + ((isMobile() || modeView === 'MODAL') ? "" : " wm-250")}
                style={{ overflow: 'unset', height: (!isMobile() ? (isMobile(false) && modeView === 'MODAL' && showCalendar ? '' : '0px') : '100%'), zIndex: (!isMobile() ? '200' : '999') }}
                id={modalId}
                tabIndex="-1"
                aria-labelledby={"CalendarLabel_" + idModal}
                aria-hidden="true">
                <div className={"modal-dialog " + (isMobile() ? "h-100 modal-dialog-scrollable" : "")}>

                    {
                        showCalendar && <div className={"modal-content " + (isMobile() ? "h-100" : "")}>
                            {
                                isMobile()
                                    ? <div className="modal-header p-0">
                                        <div className="container py-3">
                                            <div className="row">
                                                <div className="col-1">
                                                    <button className="btn btn-link p-0 m-0 text-decoration-none" onClick={_ => closePanelCalendar()}>
                                                        <span class="material-icons-outlined">
                                                            arrow_back
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="col-11 text-center">
                                                    <h6 className="btn btn-link p-0 m-0 text-decoration-none fw-bold text-dark">
                                                        {getPlaceholder()}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                            }
                            <div className="modal-header p-0">
                                <InputTime
                                    time={selectTime}
                                    productType={productType}
                                    extraOptions={extraOptions}
                                    modeView={isMobile() ? "MODAL" : modeView}
                                    type={type}
                                    closePanelCalendar={closePanelCalendar}
                                    isMobile={isMobile()} />
                            </div>
                            <div className={"modal-body " + (isMobile() ? "" : "p-0 wm-250 modal-calendar-responsive")}>
                                <M3Time
                                    time={selectTime}
                                    extraOptions={extraOptions}
                                    onSelectTime={onSelectTime}
                                    productType={productType}
                                />
                            </div>
                        </div>
                    }

                    <a id={"btnCloseModalTime_" + idModal} data-bs-dismiss="modal"></a>
                </div>
            </div>
        </>
    );
}
