import React from 'react';
import { formatPrice } from '../../../../../js/Utils.js';
import { getProductIconToPrint } from '../../../../../js/PrintUtility';
import { useTranslation } from 'react-i18next';


export const PrintAccountBalanceServizi = ({ order, visualizationType, userType, grouped, cultureName }) => {
    const { t } = useTranslation();

    return (
        <>
            {
                <table className="table mb-0 document-invoice">
                    <thead className="bg-light border-top" style={{ backgroundColor: '#edf0f7', borderBottom: '2px solid #424767' }}>
                        <tr>
                            <td style={{ textAlign: 'left', fontSize: '12px', paddingRight: '3px', color: '#424767', height: '30px', verticalAlign: 'middle' }}>
                                Item
                            </td>
                            <td style={{ textAlign: 'left', fontSize: '12px', color: '#424767', height: '30px', verticalAlign: 'middle' }}>
                                {t("Template:Service")}
                            </td>
                            <td style={{ textAlign: 'left', fontSize: '12px', color: '#424767', height: '30px', verticalAlign: 'middle' }}>
                                {t("MyBook:Value")}
                            </td>
                            <td style={{ textAlign: 'left', fontSize: '12px', color: '#424767', height: '30px', verticalAlign: 'middle' }}>
                                {userType === 'A' && grouped === 'false' ? '% Comm.' : ''}
                            </td>
                            <td style={{ textAlign: 'left', fontSize: '12px', color: '#424767', height: '30px', verticalAlign: 'middle' }}>
                                {userType === 'A' && grouped === 'false' ? 'Val.Comm.' : ''}
                            </td>
                        </tr>
                    </thead>
                    <tbody style={{ textAlign: 'left', fontSize: '12px', borderBottom: '2px solid #edf0f7' }} >
                        {
                            order.pratiche.map(pratica =>
                                <tr key={pratica.idPratica}>
                                    <td style={{ fontSize: '12px', textAlign: 'center' }}>
                                        <img src={getProductIconToPrint(pratica.tipoPratica)} className="ico-image" />
                                    </td>
                                    <td style={{ fontSize: '12px', textAlign: 'left' }}>
                                        {pratica.productName}
                                    </td>
                                    <td style={{ fontSize: '12px', textAlign: 'left' }}>
                                        {formatPrice(pratica.invoicePrice, pratica.currency, cultureName)}
                                    </td>
                                    <td style={{ fontSize: '12px', textAlign: 'left' }}>
                                        {userType === 'A' && grouped === 'false'
                                            ? <>{pratica.commissioneAgenziaPerc}%</>
                                            : ''}
                                    </td>
                                    <td style={{ fontSize: '12px', textAlign: 'left' }}>{userType === 'A' && grouped === 'false'
                                        ? formatPrice(pratica.commissioneAgenzia, pratica.currency, cultureName)
                                        : ''}
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            }
            <table>
                <tbody>
                    <tr>
                        <td style={{ width: '85%' }}></td>
                        <td style={{ fontSize: '12px', textAlign: 'left', borderBottom: '1px solid #edf0f7', width: '5%' }}>
                            <strong>{t("MyBook:Total")}</strong>
                        </td>
                        <td style={{ fontSize: '12px', textAlign: 'right', borderBottom: '1px solid #edf0f7', width: '10%' }}>{formatPrice(order.totalInvoicePrice, order.currency, cultureName)}</td>
                    </tr>
                    {userType === 'A' && order.resumeCommissions && order.resumeCommissions.length > 0 ?
                        order.resumeCommissions.map(comm =>
                            <tr key={comm.roundPerc} style={{ textAlign: 'right', fontSize: '12px' }}>
                                <td style={{ width: '85%' }}></td>
                                <td style={{ fontSize: '12px', textAlign: 'left', borderBottom: '1px solid #edf0f7', width: '5%' }}>  {
                                    grouped === 'true' ? ('Commissione')
                                        : <strong>{t("Template:Commission")} {comm.label} %</strong>
                                }
                                </td>
                                <td style={{ fontSize: '12px', textAlign: 'right', borderBottom: '1px solid #edf0f7', width: '10%' }}>{formatPrice(comm.amount, comm.currency, cultureName)}</td>
                            </tr>
                        )
                        : ''}
                    {userType === 'A' ? (
                        <tr>
                            <td style={{ width: '85%' }}></td>
                            <td style={{ fontSize: '12px', textAlign: 'left', borderBottom: '1px solid #edf0f7', width: '5%' }}>
                                <strong>{t("Template:Tot\tNetToPay")}</strong>
                            </td>
                            <td style={{ fontSize: '12px', textAlign: 'right', borderBottom: '1px solid #edf0f7', width: '10%' }}>{formatPrice(order.totalToPay, order.currency, cultureName)}</td>
                        </tr>
                    ) : ''}
                </tbody>
            </table>
        </>
    );
}