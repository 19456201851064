import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import configData from "../../../appsettings.json";
import { Loading } from '../../Common/Loading';
import { useAuth } from '../../Auth/AuthProvider';
import { useTranslation } from 'react-i18next';
import { Error } from '../../Common/Error';
import { ActivityStaticContainer } from './components/ActivityStaticContainer';

export const ActivityDetailStatic = () => {
    const { t } = useTranslation();

    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");

    let params = new URLSearchParams(window.location.search);
    let activityId = params.get('aId');
    let onRequest = params.get('onReq');
    let { getQuotationInfoSession } = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [activityDetail, setActivityDetail] = useState({});
    const [searchParam, setSearchParam] = useState(null);
    const [extraParamSearch, setExtraParamSearch] = useState({
        activityId: activityId,
        testRequest: params.get('testRequest'),
        onRequest: onRequest,
        city: ''
    });

    useEffect(() => {
        getActivity();
    }, []);

    useEffect(() => {

        if (getQuotationInfoSession && getQuotationInfoSession.quotationInfoSearchParam) {
            setSearchParam(getQuotationInfoSession.quotationInfoSearchParam.activitySearchParameters);
        }

    }, [getQuotationInfoSession])


    async function getActivity() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}activity/GetActivityData/${activityId}/${cultureCode}`, requestOption);
        const response = await fetchedRes.json();

        if (response.success) {
            setActivityDetail(response.activity);

            setExtraParamSearch({
                ...extraParamSearch,
                city: response.activity.city,
                productName: response.activity.name
            });

            console.log(response.activity);
            setIsLoading(false);
        } else {
            setIsError(true);
            console.error(response.errorMsg);
        }
    }

    return (
        <>
            {isLoading && !isError && <Loading textMsg={t("MyBook:Loading")} />}
            {!isLoading && isError && <Error textMsg={t("MyBook:ErrorLoading")} />}
            {
                !isLoading && !isError && <main className="bg-gray-300">
                    <div className="container mt-5">
                        <ActivityStaticContainer
                            activityId={activityId}
                            activityDetail={activityDetail}
                            searchParameters={searchParam}
                            extraParamSearch={extraParamSearch} />
                    </div>
                </main >
            }
        </>
    );
}