import React, { useState, useEffect } from 'react';
import { TransferList } from './TransferList';

export const TrippieTransferProductResult = ({ productSearchParam, onAddTransfer }) => {
    const [extraParam, setExtraParam] = useState(null);
    const [availabilityParam, setAvailabilityParam] = useState(null);
    const [forceClearIntervalList, setForceClearIntervalList] = useState([]);

    useEffect(() => {

        if (productSearchParam) {
            setExtraParam({
                carpetId: productSearchParam.carpetId,
                clientFlowId: productSearchParam.clientFlowId,
                productId: 0
            });

            setAvailabilityParam(null);
        }

    }, [productSearchParam])

    function saveIntervalCarpetPing(intervalPing) {
        let upd = [...forceClearIntervalList];
        upd.push(intervalPing);
        setForceClearIntervalList(upd);
    }

    function clearCarpetAllInterval() {
        if (forceClearIntervalList) {
            for (let i = 0; i < forceClearIntervalList.length; i++) {
                try {
                    let intId = forceClearIntervalList[i];
                    clearInterval(intId);
                } catch (ex) { }

            }

            setForceClearIntervalList([]);
        }
    }

    function onAddProduct(transferRecap) {
        clearCarpetAllInterval();
        onAddTransfer(productSearchParam.stepIndex, productSearchParam.cartItemId, transferRecap, productSearchParam.carpetId, null);
    }

    return (
        <>
            {
                availabilityParam && <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item cursor-pointer" aria-current="page" onClick={_ => setAvailabilityParam(null)}>List</li>
                    </ol>
                </nav>
            }
            {
                productSearchParam.carpetId && !availabilityParam && <TransferList carpetId={productSearchParam.carpetId}
                    clientFlowId={productSearchParam.clientFlowId}
                    cartItemId={productSearchParam.cartItemId}
                    extraParam={extraParam}
                    step={productSearchParam.stepIndex}
                    saveIntervalCarpetPing={saveIntervalCarpetPing}
                    onAddProduct={onAddProduct} />
            }
        </>
    );
}