import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { useAuth } from '../../Auth/AuthProvider';
import { searchCarpetParameter } from '../../../js/Constant';
import { getCurrentUserFromJwtToken } from '../../../js/Utils.js';
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';
import { CarStaticContainer } from './components/CarStaticContainer';
import { CarOptions } from './components/CarOptions';
import { CarTermsConditions } from './components/CarTermsConditions';
import { CarInclusionExclusion } from './components/CarInclusionExclusion';
import { CarExtras } from './components/CarExtras';
import { CarExtrasLoco } from './components/CarExtrasLoco';
import { CarResume } from './components/CarResume';
import { ProductNavBar } from '../SearchEngine/ProductNavBar';


export const CarDetail = () => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    let params = new URLSearchParams(window.location.search);
    let carId = params.get('carId');
    let carpetSearchId = params.get('cId');
    let clientFlowId = params.get('cfId');

    let defualtSuppliersIds = params.get('suppliersIds');
    let { getQuotationInfoSession } = useAuth();

    const [selectedOption, setSelectedOption] = useState(null);
    const [extraSelectedArr, setExtraSelectedArr] = useState([]);
    const [isLoadingExtra, setIsLoadingExtra] = useState(true);
    const [isLoadingExtraLoco, setIsLoadingExtraLoco] = useState(true);
    const [extraLocoSelectedArr, setExtraLocoSelectedArr] = useState([]);


    // page
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [isErrorStaticData, setIsErrorStaticData] = useState(false);
    const [isMessageNotFound, setIsMessageNotFound] = useState(false);
    const [carData, setCarData] = useState(null);
    const [carDetail, setCarDetail] = useState(null);
    const [isLoadingListResult, setIsLoadingListResult] = useState(true);
    const [showPriceBar, setShowPriceBar] = useState(false);
    const [quotationInfo, setQuotationInfo] = useState(null);

    // roles
    const [showCost, setShowCost] = useState(false);
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);
    const [enableShowPriceBreakDown, setEnableShowPriceBreakDown] = useState(false);
    const [enableCanAddToCart, setEnableCanAddToCart] = useState(false);
    const [isBookClick, setIsBookClick] = useState(false);

    async function callAddToCart(bookItem) {

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bookItem)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}carrental/addtocart`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response.success) {
                if (quotationInfo && quotationInfo.quotationId) {
                    if (quotationInfo.isFromCart)
                        window.location.href = `cart-quotation`;
                    else
                        window.location.href = `quotationTO/${quotationInfo.quotationId}`;
                } else {
                    window.location.href = `cart`;
                }
            }

        } else {
            console.log(fetchedRes);
        }

    }

    useEffect(() => {
        setIsLoading(true);
        setIsError(false);
        getStaticData({ carpetId: carpetSearchId, internalId: carId, cultureCode: cultureName });
        getCarDetail({ carpetId: carpetSearchId, internalId: carId, cultureCode: cultureName, numOptionsToView: 10, showAllOptions: true });

        try {

            let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
            let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);

            if (currentUser.roles) {
                if (!currentUser.roles.includes("FE_NonVisualizzaNetto"))
                    setEnableShowNetPrices(true);

                if (currentUser.roles.includes("FE_ShowCost"))
                    setShowCost(true);

                if (currentUser.roles.includes("FE_ShowPriceBreakDown"))
                    setEnableShowPriceBreakDown(true);
            }

        } catch (ex) {
        }
    }, []);


    useEffect(() => {
        if (getQuotationInfoSession && getQuotationInfoSession.quotationId)
            setQuotationInfo(getQuotationInfoSession);
        else
            setQuotationInfo(null);

    }, [getQuotationInfoSession]);


    //refreshing extras
    useEffect(() => {
        if (!isLoadingExtra)
            setIsLoadingExtra(true);

    }, [isLoadingExtra]);

    //refreshing extras loco
    useEffect(() => {
        if (!isLoadingExtraLoco)
            setIsLoadingExtraLoco(true);

    }, [isLoadingExtraLoco]);

    useEffect(() => {
        if (isBookClick) {
            let extrasArr = [];
            if (extraSelectedArr && extraSelectedArr.length > 0) {
                extraSelectedArr.map(extra => {
                    extrasArr.push({ id: extra.extra.id, quantity: extra.quantity });
                });
            }

            let extrasLocoArr = [];

            if (extraLocoSelectedArr && extraLocoSelectedArr.length > 0) {
                extraLocoSelectedArr.map(extra => {
                    extrasLocoArr.push({ id: extra.extra.id, quantity: extra.quantity });
                });
            }

            let bookItem = {
                carpetId: carpetSearchId,
                internalId: carId,
                cultureCode: cultureName,
                clientFlowId: clientFlowId,
                productCode: selectedOption.productCode,
                selectExtraIds: extrasArr.length > 0 ? extrasArr : null, 
                selectExtraLocoIds: extrasLocoArr.length > 0 ? extrasLocoArr : null, 
            }

            const addToCart = async (bookItem) => { callAddToCart(bookItem); }
            addToCart(bookItem);
        }
    }, [isBookClick]);
    

    async function getStaticData(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'carrental/getCarRentalDetail', requestOption);
        const response = await fetchedRes.json();

        if (response.success) {
            setCarData(response.carRental);
            setIsLoading(false);
            
        } else {
            setIsErrorStaticData(true);
            console.error(response.errorMsg);
        }
    }

    async function getCarDetail(inputData) {
       const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'carrental/GetCarRentalAvailability', requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response.carRental && response.carRental.options) {
                setCarDetail(response.carRental);
            }
            else {
                setIsMessageNotFound(true);
            }
            if (response.permissions)
                setEnableCanAddToCart(response.permissions['ADDTOCART']);
        }

        setIsLoadingListResult(false);
    }

    function onSetSelectedOption(option) {
        setSelectedOption(option);
        setIsLoadingExtra(false);
        setExtraSelectedArr([]);
        setIsLoadingExtraLoco(false);
        setExtraLocoSelectedArr([]);
    }

    function onHandleClickExtras(selectedExtra) {
        let _extraSelectedArr = [...extraSelectedArr];

        let index = _extraSelectedArr.findIndex((item) => item.extra.id === selectedExtra.extra.id);
        if (index === -1) {
            _extraSelectedArr.push(selectedExtra);
        }

        _extraSelectedArr.forEach(function (selectExtra, index) {
            if (selectedExtra.extra.id === selectExtra.extra.id && !selectedExtra.isSelected) {
                var arr = _extraSelectedArr.filter(function (item) {
                    return item.extra.id !== selectExtra.extra.id
                });
                _extraSelectedArr = arr;
            }
            else if (selectedExtra.extra.id === selectExtra.extra.id && selectedExtra.isSelected) {
                var arr = _extraSelectedArr.filter(function (item) {
                    return item.extra.id !== selectExtra.extra.id
                });
                _extraSelectedArr = arr;
                _extraSelectedArr.push(selectedExtra);
            }
        });
    
        setExtraSelectedArr(_extraSelectedArr);
    }

    function onHandleClickExtrasLoco(selectedExtra) {
        let _extraSelectedArr = [...extraLocoSelectedArr];

        let index = _extraSelectedArr.findIndex((item) => item.extra.id === selectedExtra.extra.id);
        if (index === -1) {
            _extraSelectedArr.push(selectedExtra);
        }

        _extraSelectedArr.forEach(function (selectExtra, index) {
            if (selectedExtra.extra.id === selectExtra.extra.id && !selectedExtra.isSelected) {
                var arr = _extraSelectedArr.filter(function (item) {
                    return item.extra.id !== selectExtra.extra.id
                });
                _extraSelectedArr = arr;
            }
            else if (selectedExtra.extra.id === selectExtra.extra.id && selectedExtra.isSelected) {
                var arr = _extraSelectedArr.filter(function (item) {
                    return item.extra.id !== selectExtra.extra.id
                });
                _extraSelectedArr = arr;
                _extraSelectedArr.push(selectedExtra);
            }
        });

        setExtraLocoSelectedArr(_extraSelectedArr);
    }

    function backToList() {
        window.location.href = `/carrental/carrentallist/?cId=${carpetSearchId}&cfId=${clientFlowId}`;
    }

    return (
        <>
            <main className="bg-gray-300">
                {
                    configData.Settings.Website.EngineType === 'WITHNAVBAR' && <ProductNavBar moduleSelected="CARRENTAL" />
                }
                <div className="container mt-5 ">

                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="cursor-pointer small mb-1" onClick={_ => backToList()}>
                                <span class="material-icons-outlined icon-small">arrow_back_ios</span> {t('ActionMenu:ReturnList')}
                            </div>
                        </div>
                    </div>
                    <div className="card border-light bg-white p-1">
                        {isLoading && !isErrorStaticData && <Loading textMsg={t('MyBook:Loading')} />}
                        {
                            !isLoading && isErrorStaticData && <div className="card border-light  bg-gray-100 small mb-2">
                                <div className="row small ">
                                    <div className="col-12">
                                        <Error textMsg={t('Product:RentCar:ErrorDetail')} />
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            !isLoading && carData && <>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                                        <CarStaticContainer carData={carData} />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                        <CarResume
                                            supplier={carDetail && carDetail.supplier && carDetail.supplier.supplierName}
                                            option={selectedOption}
                                            carExtrasSelected={extraSelectedArr}
                                            carExtrasLocoSelected={extraLocoSelectedArr}
                                            handleClickBook={setIsBookClick}
                                            enableShowNetPrices={enableShowNetPrices}
                                            showCost={showCost}
                                            quotationInfo={quotationInfo}
                                            enableCanAddToCart={enableCanAddToCart }
                                        />
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        {!isError && isLoadingListResult ?
                                            <Loading textMsg={t('MyBook:Loading')} />
                                            :
                                            <>
                                                {isMessageNotFound || isError ?
                                                    <div className="col-12 pnlError text-center text-danger mt-4">
                                                        <data m3lab="Product.RentCar.ErrorDetail">
                                                            {t("Product:RentCar:ErrorDetail")}
                                                        </data>
                                                    </div>
                                                :
                                                carDetail && carDetail.options && carDetail.options.length > 0 &&
                                                <>
                                                    <div className="row">
                                                        {carDetail.options.map((option, idx) =>
                                                            <CarOptions
                                                                option={option}
                                                                productCodeSelected={selectedOption && selectedOption.productCode}
                                                                idx={idx}
                                                                handleClickOption={onSetSelectedOption}
                                                                selectedPrice={selectedOption && selectedOption.pricing && selectedOption.pricing.displayPrice}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="row p-3">
                                                        <CarInclusionExclusion
                                                            option={selectedOption ? selectedOption : carDetail.options[0]}
                                                        />
                                                    </div>
                                                    <div className="row p-3">
                                                        {isLoadingExtra &&
                                                            <CarExtras
                                                                option={selectedOption ? selectedOption : carDetail.options[0]}
                                                                handleSelectedExtraArr={onHandleClickExtras}
                                                                handleSelectedExtrasLoco={onHandleClickExtrasLoco}
                                                            />
                                                    }
                                                    </div>
                                                    <div className="row p-3">
                                                        {isLoadingExtraLoco &&
                                                            <CarExtrasLoco
                                                                option={selectedOption ? selectedOption : carDetail.options[0]}
                                                            />
                                                        }
                                                    </div>
                                                    {carDetail.termsAndConditions &&
                                                        <div className="row p-3">
                                                            <CarTermsConditions termsAndConditions={carDetail.termsAndConditions} />
                                                        </div>
                                                    }
                                                </>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className="col-4">
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </main>

            {/* dettaglio modale */}
            <div className="modal fade" id="infoHotel" tabIndex="-1" aria-labelledby="infoHotel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="infoHotel">
                                <data m3lab="General.Descriptions">
                                    {t("General:Descriptions")}
                                </data>
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {carData && carData.descriptions && carData.descriptions.map((description, key) =>
                                <React.Fragment key={key}>
                                    <p className="h6"><var>{description.category}</var></p>
                                    <p className="small">
                                        <var dangerouslySetInnerHTML={{ __html: description.description }}></var>
                                    </p>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-gray-300" data-bs-dismiss="modal">
                                <data m3lab="Button.Chiudi">
                                    {t("Button:Chiudi")}
                                </data>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}