import React from 'react';
import { WebContentBasicItem } from './WebContentBasicItem';
import { WebContentFormItems } from './WebContentFormItems';
import { WebContentCarousel } from './WebContentCarousel';
import { WebContentNavBar } from './WebContentNavBar';
import { QuotationForm } from "./form/QuotationForm";

export const WebContentItem = ({ item }) => {

    return <>
        {
            !item.engineCode && <WebContentBasicItem item={item} />
        }
        {
            item.engineCode === 'FORM' && <WebContentFormItems item={item} />
        }
        {
            item.engineCode === 'Q.REQ' && <QuotationForm item={item} />
        }
        {
            item.engineCode === 'CAROUSEL' && <WebContentCarousel item={item} />
        }
        {
            item.engineCode === 'NAVBAR' && <WebContentNavBar item={item} />
        }
    </>
}