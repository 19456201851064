import React from 'react';
import { WebMenuVoices } from '../WebMenuVoices';

export const WebMenuListHorizontal = ({ item, rules, modal }) => {

    function getClassName(className) {
        if (!className)
            return '';

        try {
            return className.split("#")[0];
        } catch (ex) { }

        return className;
    }

    return (
        <>
            <ul className={item.className ? `${item.className} d-none d-sm-flex` : "list-group list-unstyled list-group-horizontal"}>
                {
                    item.children && item.children.length > 0 && item.children.map((chd, key) => {
                        return <li key={key} className={getClassName(chd.className)}><WebMenuVoices modal={modal} item={chd} rules={rules} /></li>;
                    })
                }
            </ul>
            <ul className={`d-sm-none ${!modal ? 'align-items-start' : ''} ${modal ? 'd-block' : 'd-flex'}`}>
                {
                    item.children && item.children.length > 0 && item.children.map((chd, key) => {
                        return <li key={key} className={getClassName(chd.className) + ` ${modal ? 'd-flex justify-content-center' : ''}`}><WebMenuVoices modal={modal} item={chd} rules={rules} /></li>;
                    })
                }
            </ul>
        </>
    );
}