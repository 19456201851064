import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import { BlogDetailItem } from './components/BlogDetailItem';

export const BlogDetail = ({ extraParams }) => {
    const cookies = new Cookies();
    const [cultureName, setCultureName] = useState(cookies.get("CultureName"));
    const [articleCode, setArticleCode] = useState('');
    const [blogId, setBlogId] = useState(1);
    const [loading, setLoading] = useState(true);

    let { articleCodeParam } = useParams();

    useEffect(() => {
        let cultureCode = cultureName;
        if (!cultureCode) {
            cultureCode = 'it-IT';
            setCultureName(cultureCode);
        }

        if (extraParams && extraParams.length > 0) {
            for (let i = 0; i < extraParams.length; i++) {
                let paramBody = extraParams[i];

                switch (paramBody.key) {
                    case 'ARTICLECODE': {
                        setArticleCode(paramBody.value);
                        break;
                    }

                    case 'BLOGID': {
                        setBlogId(paramBody.value);
                        break;
                    }
                }
            }
        } else {
            setArticleCode(articleCodeParam);
            setBlogId(1);
        }

        setLoading(false);
    }, [])

    return <>{!loading && < BlogDetailItem articleCode={articleCode} blogId={blogId} cultureName={cultureName} />}</>;
}