import React from 'react';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";

export const ConfirmDeleteCartItemModal = ({ cartItemId }) => {
    const { t } = useTranslation();

    async function callDeleteCartItem() {
        const requestOption = { method: 'DELETE', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/DeleteCartItem/' + cartItemId, requestOption);
        const response = await fetchedRes.json();

        if (response.deleted) {
            window.location.reload();
        }
    }

    return (
        <>
            <a id={"btnOpenModalDeleteCartItemId"} data-bs-toggle="modal" data-bs-target={"#modal_ConfirmDeleteCartItem"}></a>

            <div className="modal fade" id={"modal_ConfirmDeleteCartItem"} tabIndex="-1" aria-labelledby="modal_ConfirmDeleteCartItemLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal_ConfirmDeleteCartItemLabel">{t(`Button:Delete`)}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h6 className="mb-4">{t(`MyBook:AreUSureToDelete`)}</h6>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal">{t('Button:Close')}</button>
                            <button type="button" className="btn bt-sm tp-btn-confirm" onClick={_ => callDeleteCartItem()} > {t('Button:Confirm')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}