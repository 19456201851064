import React from 'react';
import { useTranslation } from 'react-i18next';
import { replaceAllStyleElements } from '../../../../js/PrintUtility';

export const TourDetailDescriptions = ({ tourDetail }) => {
    const { t } = useTranslation();
    function getLabelType(title) {
        if (title.toLowerCase() === "program") {
            return t('Product:Tour:Program')
        }
        else if (title.toLowerCase() === "inclusions/exclusions") {
            return t('Product:Tour:InclusionExlusion')
        }
        else {
            return title
        }
    }

    return (
        <>
            {tourDetail.descriptions && tourDetail.descriptions.length > 0 && tourDetail.descriptions.filter(x => x.renderType.toUpperCase() !== 'INTRO').map((desc, key) =>
                <>
                    <div className="card my-4 animate-up-5 shadow p-2">
                        <div className="myBook-card-body pb-2">
                            <div class="h4"><var>{getLabelType(desc.type)}</var></div>
                            <p className="small text-justify">
                                <var dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(desc.value) }}></var>
                            </p>
                        </div>
                    </div>
                </>
            )
            }
        </>
    );
}