import React, { useEffect, useState } from 'react';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { GalleryList } from "../../Common/Image/GalleryList";
import { M3Icon } from "../../../Common/M3Icon";
import { ProductActionMenu } from "../../Common/ProductActionMenu";
import { ProductPrice } from "../../Common/Pricing/ProductPrice";
import { formatPrice } from '../../../../js/Utils.js';
import Cookies from 'universal-cookie';

export const CarInclusionExclusion = ({ option }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureCode = cookies.get("CultureName");
    
    return (
        <>
            {/* inlcusion */}
            <div className="card border-light p-1 mt-3 shadow">
                <div className="w-100 mt-1 pr-4">
                    <div className="h5 text-left float-start w-90 pl-2">
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                        <div className="text-center">
                            <h1 className="">
                                <data m3ico="Check text-success mr-2 h5r">
                                    <M3Icon iconName="Check" externalClass=" text-success mr-2 h5r" />
                                </data>
                            </h1>
                        </div>
                        <div className="h5 text-center">
                            <data m3lab="Product.RentCar.Include">
                                {t('Product:RentCar:Include')}
                            </data>
                        </div>
                    </div>

                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                        <div className="row pr-3">
                            
                            {option.inclusionDetails && option.inclusionDetails.length > 0 && option.inclusionDetails.map(inclusion =>
                                inclusion.description != null && inclusion.description !== "" &&
                                    <>
                                        <div className="col-10">
                                            - <span className="small fw-semibold" dangerouslySetInnerHTML={{ __html: inclusion.description }}></span>
                                            </div>
                                        <div className="col-2 text-right">
                                            <span className="small">{formatPrice(inclusion.valueAmount ? inclusion.valueAmount : 0, inclusion.valueCurrency, cultureCode)}</span>
                                        </div>
                                    </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* exclusion */}
            <div className="card border-light p-1 mt-2 shadow">
                <div className="w-100 mt-1 pr-4">
                    <div className="h5 text-left float-start w-90 pl-2">
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                        <div className="text-center">
                            <h1 className="">
                                <data m3ico="Disabled text-danger mr-2 h5r">
                                    <M3Icon iconName="Disabled" externalClass="mr-2 h5r text-danger" />
                                </data>
                            </h1>
                        </div>
                        <div className="h5 text-center">
                            <data m3lab="Product.RentCar.NotInclude">
                                {t('Product:RentCar:NotInclude')}
                            </data>
                        </div>
                    </div>

                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-10">
                        <div className="row pr-3">
                            {option.exclusionDetails && option.exclusionDetails.length > 0 ? option.exclusionDetails.map(exclusion =>
                                exclusion.description != null && exclusion.description !== "" &&
                                    <>
                                        <div className="col-10">
                                            <div className="col-10">
                                                - <span className="small fw-semibold" dangerouslySetInnerHTML={{ __html: exclusion.description }}></span>
                                            </div>
                                        </div>
                                        <div className="col-2 text-right">
                                            <span className="small">{formatPrice(exclusion.valueAmount ? exclusion.valueAmount : 0, exclusion.valueCurrency, cultureCode)}</span>
                                        </div>
                                    </>
                            )
                                :
                            <div className="mt-5">
                                {t('Product:RentCar:ExlusionText')}
                            </div>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}