import React from 'react';
import { WebMenuVoices } from '../WebMenuVoices';

export const WebMenuNavBar = ({ item, rules,modal }) => {
    return (
        <>
            <nav className={item.className ? item.className : ""}>
                {
                    item.children && item.children.length > 0 && item.children.map((chd, key) => {
                        return <WebMenuVoices modal={modal } key={key} item={chd} rules={rules} />;
                    })
                }
            </nav>
        </>
    );
}
