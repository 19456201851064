import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useAuth } from '../../Auth/AuthProvider';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../Common/Loading';
import { handleChangePax, renderProductBookParameters, renderPaxBookParameters, productNotAvailableClass_Opacity, productNotAvailableClass_Border } from './SharedFunctions';
import { formatDateTime } from '../../../js/Utils.js';
import { registerSessionQuotation } from '../../../js/QuotationUtils';
import { BookParameterMandatoryCssClass } from '../../../js/Constant';
import { AvailableTransfer } from "../Available/AvailableTransfer";
import { M3IconProductType } from '../../Common/M3IconProductType';
import { DebounceInput } from 'react-debounce-input';
import configData from "../../../appsettings.json";
import { M3Pax } from "../../Common/M3Pax";

export const CartTransfer = ({ cartItemId, isCartQuotation, userPermissions, cartPaxesNames, staticBookParams, productBookParams, handleBookParameters, handleReservationData, userLockBook, callTermsFinished, onOpenModalConfirmDelete }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const cultureName = cookies.get("CultureName");
    const { setQuotationInfoSession } = useAuth();

    // debug
    const [searchParams] = useSearchParams();
    let debug = searchParams.get("debug");

    const [isLoading, setIsLoading] = useState(true);
    const [cartItem, setCartItem] = useState([]);


    useEffect(() => {
        const getCartItem = async () => {
            callGetCartItem();
        }

        setIsLoading(true);
        getCartItem();
    }, []);

    useEffect(() => {
        callGetCartItem();
    }, [callTermsFinished]);

    useEffect(() => {

        if (userPermissions && userPermissions.cartOnlyOption) {
            let rbOptionBook = document.getElementById(`rbTransferOption_${cartItemId}`);
            if (rbOptionBook)
                rbOptionBook.click();
        }

    }, [userPermissions, callTermsFinished])

    useEffect(() => {
        if (cartItem && cartItem.passengers && cartPaxesNames && cartPaxesNames.length > 0) {
            for (let i = 0; i < cartItem.passengers.length; i++) {
                let pax = cartItem.passengers[i];

                let e = { target: { value: pax.index }, Index: pax.index };

                let ddlPaxId = `ci_${cartItem.cartItemId}_option_1_pax_${pax.index}_ddlPax`;
                let ddlPax = document.getElementById(ddlPaxId);
                if (ddlPax) {
                    ddlPax.value = pax.index;
                    handleChangePax(cartItem, cartPaxesNames, handleBookParameters, 1, pax.index, ddlPaxId, e);
                }
            }
        }
    }, [cartPaxesNames]);


    async function callGetCartItem() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/GetCartItem/' + cartItemId, requestOption);
        const response = await fetchedRes.json();

        setCartItem(response);
        setIsLoading(false);
    }

    async function callUpdateQuotationItemStatus(quotationId, quotationItemId) {
        let inputData =
        {
            QuotationId: quotationId,
            QuotationItemId: quotationItemId,
            Status: 70,
            LogNote: "Status changed from CartQuotation due to BookingTerm error",
            QuotationSettings: { BaseUrl: "" }
        };

        const requestOption =
        {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/UpdateQuotationItemStatus', requestOption);
        const response = await fetchedRes.json();

        let responseQuotation = await registerSessionQuotation(quotationId, quotationItemId, true, cartItemId);
        if (responseQuotation) {
            setQuotationInfoSession(responseQuotation.quotationInfo);
            window.location.href = responseQuotation.url;
        }
    }

    function onClickChangeProduct(url, quotationId, quotationItemId) {
        if (quotationId !== null && quotationId !== undefined && quotationItemId !== null && quotationItemId !== undefined) {
            callUpdateQuotationItemStatus(quotationId, quotationItemId);
        }
        else {
            window.location.href = url;
        }
    }

    // Selection Structure - START
    const [callStructuresLoading, setCallStructuresLoading] = useState(false);
    const [showStructuresSelection, setShowStructuresSelection] = useState(false);
    const [callSaveFreeText, setCallSaveFreeText] = useState(false);
    const [savedFreeText, setSavedFreeText] = useState(false);
    const [showFreeText, setShowFreeText] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [structures, setStructures] = useState([]);

    const selectFreeTextOnChange = (checked) => {
        if (checked) {
            setShowFreeText(true);
        } else {
            setShowFreeText(false);
        }

        setShowStructuresSelection(false);
        setCallStructuresLoading(false);
    }
    const updateValue = (newValue) => {
        setCallStructuresLoading(true);
        setInputValue(newValue);
        setStructures([]);

        callGetStructuresList({ isLoading: true, isError: false, searchText: newValue });
    }
    async function callGetStructuresList(params) {
        setShowStructuresSelection(false);

        let rq = {
            CartItemId: cartItem.cartItemId,
            CultureCode: cultureName,
            StructureName: params.searchText
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(rq) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Transfer/GetStructuresList', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response.success && response.structures) {
                setStructures(response.structures);
                setShowStructuresSelection(true);
            }
        }

        setCallStructuresLoading(false);
    }
    async function saveSelectionMode(e, type, structureId, structureName) {
        if (e) e.preventDefault();

        setSavedFreeText(false);
        setCallSaveFreeText(false);

        let isFreeText = type === 1 ? true : false;

        let rq = {
            CartItemId: cartItem.cartItemId,
            SelectionType: isFreeText ? "FT" : "SID",
            Text: ""
        };

        if (!isFreeText) {
            rq.Text = structureId;
            setInputValue(structureName);
        } else {
            setCallSaveFreeText(true);
            let inputFreeText = document.getElementById(`freeText_${cartItem.cartItemId}`);
            let freeTextVal = inputFreeText.value;
            rq.Text = freeTextVal;
        }
        setShowStructuresSelection(false);

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(rq) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Transfer/SaveSelectionMode', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response && response.success) {
                console.log("Transfer SelectionMode saved successfully");

                if (isFreeText) {
                    setCallSaveFreeText(false);
                    setSavedFreeText(true);
                }
            }
        }
    }
    function renderSelectStructure() {
        if (!cartItem)
            return;

        return (
            <>
                <div className="row mt-4">
                    <div className="col-12">
                        <p>Cerca il tuo Hotel, se non lo trovi puoi inserire un testo libero e il Transfer sarà soggetto a riconferma da parte dei nostri operatori</p>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-12">
                        <input id={`cbFreeText_${cartItem.cartItemId}`} type="checkbox" name="cbFreeText" onChange={e => selectFreeTextOnChange(e.target.checked)} />
                        <label for={`cbFreeText_${cartItem.cartItemId}`} className="pl-1">Inserisci testo libero</label>
                    </div>
                </div>

                <div className="row">
                    <div className="col-3">
                        {!showFreeText
                            ? (
                                <>
                                    <div className="input-group bg-white rounded pr-1">
                                        <div className={"pl-1 pt-2"}>
                                            <DebounceInput
                                                id={`transferDebounce_${cartItem.cartItemId}`}
                                                className="form-control"
                                                minLength={3}
                                                debounceTimeout={1000}
                                                placeholder="Nome hotel"
                                                value={inputValue}
                                                onChange={event => updateValue(event.target.value)} />
                                        </div>
                                    </div>
                                </>
                            )
                            : (
                                <div className="input-group bg-white rounded pr-1">
                                    <div className={"pl-1 pt-2"}>
                                        <input id={`freeText_${cartItem.cartItemId}`} type="text" name="freeText" placeholder="Testo libero" />
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    {showFreeText &&
                        <React.Fragment>
                            <div className="col-2">
                                <div className={"pl-1 pt-2"}>
                                    <button className="btn btn-sm btn-success" onClick={e => saveSelectionMode(e, 1)}>{t('Button:Save')}</button>
                                </div>
                            </div>
                            {callSaveFreeText &&
                                <>
                                    <div className="col-7">
                                        <div className="spinner-border spinner-border-sm text-dark mt-3" role="status"></div>
                                        <span className="sr-only pl-2">Salvataggio...</span>
                                    </div>
                                </>
                            }
                            {!callSaveFreeText && savedFreeText &&
                                <>
                                    <div className="col-7">
                                        <div className="pt-3">
                                            <span className="text-success">Salvataggio avvenuto con successo</span>
                                        </div>
                                    </div>
                                </>
                            }
                        </React.Fragment>
                    }
                </div>

                <div className="row">
                    <div className="col-12">
                        {callStructuresLoading &&
                            <>
                                <div className="spinner-border spinner-border-sm text-dark mt-4" role="status"></div>
                                <span className="sr-only pl-2">Ricerca hotel, attendere...</span>
                            </>
                        }
                        {structures && structures.length > 0 && showStructuresSelection &&
                            <div className="input-group bg-white rounded pr-1">
                                <ul>
                                    {structures.map((structure, index) => {
                                        return (
                                            <li key={index} className="p-2">
                                                <a href className="cursor-pointer" onClick={e => saveSelectionMode(e, 0, structure.structureId, structure.structureName)}>{structure.structureName}</a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        }

                        {structures && structures.length === 0 && showStructuresSelection &&
                            <div className="input-group bg-white rounded mt-4 pr-1">
                                <span>Nessun hotel trovato</span>
                            </div>
                        }
                    </div>
                </div>
            </>
        );
    }
    // Selection Structure - END

    function onClickDeleteNotAvailable() {
        onOpenModalConfirmDelete(cartItemId);
    }


    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : (
                    <>
                        <div className={"card mt-3 p-3 " + productNotAvailableClass_Border(productBookParams)}>
                            <div className="card p-3">
                                <div className={productNotAvailableClass_Opacity(productBookParams)}>

                                    <div className="row">
                                        <div className="col-7">
                                            <M3IconProductType productType={cartItem.productType} externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                                            <span className="titlecard">{cartItem.name}</span>
                                        </div>
                                        <div className="col-2">
                                            <div class="w-100">
                                                <M3Pax adt={cartItem.adults} chd={cartItem.children} chdAges={cartItem.childrenAges} extraOptions={{ showInfant: false, iconClass: "icon-15" }} />
                                            </div>
                                        </div>
                                        <div className="col-3 text-right">
                                            {cartItem.status && cartItem.status === 'OnRequest' && <span className="text-warning h6 mb-0 small d-block"><var>{cartItem.status}</var></span>}
                                            {cartItem.supplier && <span className="text-info small d-block mt-2"><var>{cartItem.supplier.supplierName}</var></span>}
                                        </div>
                                    </div>

                                    {callTermsFinished &&
                                        <div className="d-inline float-end">
                                            <button className="btn btn-sm btn-danger" onClick={() => onOpenModalConfirmDelete(cartItemId)}>{t("Button:Delete")}</button>
                                        </div>
                                    }

                                    <p><label>{formatDateTime(cartItem.dateFrom, cultureName)} - {formatDateTime(cartItem.dateTo, cultureName)}</label></p>
                                </div>
                                <div className={productNotAvailableClass_Opacity(productBookParams)}>
                                    {
                                        cartItem.cancelPolicy && <span className={cartItem.cancelPolicy.isFree ? "penalty free" : "penalty"}>
                                            {cartItem.cancelPolicy.isFree ? t("CancelPolicy:FreeCancellation") + " " + formatDateTime(cartItem.cancelPolicy.expirationDate, { twoDigits: true }) : t("CancelPolicy:PenaltyCancellation")}
                                        </span>
                                    }

                                    {!callTermsFinished
                                        ? (<><Loading textMsg={t(`Cart:LoadingBookingTerms`)} isSmall={true} /></>)
                                        : (
                                            <>
                                                {productBookParams !== null && productBookParams !== undefined && productBookParams.IsAvailable
                                                    ? (
                                                        <>
                                                            {/*Passengers*/}
                                                            {cartItem.passengers.map(pax =>
                                                                <div key={pax.index} className="row">
                                                                    <div className="form-floating col-md-3">
                                                                        <select id={`ci_${cartItem.cartItemId}_option_1_pax_${pax.index}_ddlPax`} className="form-select border-0 border-bottom border-dark" aria-label="Fornitore" onChange={(e) => handleChangePax(cartItem, cartPaxesNames, handleBookParameters, 1, pax.index, `ci_${cartItem.cartItemId}_option_1_pax_${pax.index}_ddlPax`, e)}>
                                                                            <option value="-1">{t("Button:Select")}&nbsp; pax</option>
                                                                            {cartPaxesNames !== null && cartPaxesNames !== undefined && cartPaxesNames.length > 0
                                                                                ? (
                                                                                    cartPaxesNames.map(paxName =>
                                                                                        <option key={paxName.Index} value={paxName.Index}>{paxName.BpFirstName[0].Value} {paxName.BpLastName[0].Value}</option>
                                                                                    )
                                                                                )
                                                                                : (<></>)
                                                                            }
                                                                        </select>
                                                                        <label htmlFor={`ci_${cartItem.cartItemId}_option_1_pax_${pax.index}`}>
                                                                            <span className={BookParameterMandatoryCssClass.Mandatory}>{t("MyBook:Passenger")}&nbsp; {pax.paxType} {pax.index}:</span>
                                                                        </label>
                                                                    </div>

                                                                    {renderPaxBookParameters(cartItem, productBookParams, staticBookParams, handleBookParameters, 1, pax.index)}
                                                                </div>
                                                            )}

                                                            {/*Product Book Parameters*/}
                                                            {renderProductBookParameters(cartItem, productBookParams, handleBookParameters)}

                                                            {/*Select Structure*/}
                                                            {cartItem.regionId && renderSelectStructure()}
                                                        </>
                                                    )
                                                    : (<></>)
                                                }
                                            </>
                                        )
                                    }
                                </div>

                                {/*Product Not Available*/}
                                {productBookParams !== null && productBookParams !== undefined && !productBookParams.IsAvailable
                                    ? (
                                        <AvailableTransfer
                                            listUrl={productBookParams.TermsError_ListUrl}
                                            quotationId={productBookParams.QuotationId}
                                            quotationItemId={productBookParams.QuotationItemId}
                                            deleteFn={onClickDeleteNotAvailable}
                                            changeProductFn={onClickChangeProduct}
                                            mode="CART"
                                        />
                                    )
                                    : (<></>)
                                }
                            </div>

                            {/* --- DEBUG --- */}
                            {debug === "1"
                                ? (<><br /><br />{JSON.stringify(productBookParams)}</>)
                                : (<></>)
                            }
                            {/* --- DEBUG --- */}
                        </div>
                    </>
                )
            }
        </>
    )
}