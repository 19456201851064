import React from 'react';
import { replaceAllStyleElements } from '../../../js/PrintUtility';
import { PrintStepHeader } from './PrintStepHeader.js';

export const TourStaticPrint = ({ title, descriptions, cultureName }) => {

    return (
        <>
            <div className="border marginTopPdf" style={{ display: 'grid' }}>
                <table className="w-100">
                    <tbody>
                        <tr className="customBackground">
                            <td className="pl-2">
                                <span className="h6 float-start" style={{ color: "#780704" }}>{title }</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ padding: '5px', textAlign: "justify" }}>
                    <span style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(descriptions) }} />
                </div>
            </div>
        </>
    )
}