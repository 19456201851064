import React, { useState, useEffect } from 'react';
import { Loading } from '../Common/Loading';
import { M3Icon } from '../Common/M3Icon';
import { Passenger } from './Passenger';
import { PriceBar } from './PriceBar';
import { CancelReservationModal } from './OperationModals/CancelReservationModal';
import { PayWithCreditCardModal } from './OperationModals/PayWithCreditCardModal';
import { OperationButtons } from './OperationButtons';
import { QuotationOperationButtons } from '../QuotationTO/components/QuotationOperationButtons';
import { formatDateTime } from '../../js/Utils.js';
import configData from "../../appsettings.json";
import { imageOnError, imageOnLoad } from '../../js/CDNUtility';
import { renderWebsite } from '../../js/Constant';
import { Error } from '../Common/Error';
import { useTranslation } from 'react-i18next';
import { DMCExtras } from '../Product/Common/DMC/DMCExtras';
import { ServiceNote } from './ServiceNote';
import { UpdateActivityData } from '../MyBook/ProductData/components/UpdateActivityData';

export const ActivityDetail = ({ id, type, showNetPrices, showCost, customFunctions, forceRender, saveProductData }) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [activityDetail, setActivityDetail] = useState([]);

    const [modalId, setModalId] = useState("");
    const [resetValue, setResetValue] = useState(1);
    const [isEnabledUpdateStaticData, setIsEnabledUpdateStaticData] = useState(false);

    useEffect(() => {
        setResetValue(1);
        loadItem();

        setModalId("modalUpdateStaticDAta_" + id)
    }, []);

    useEffect(() => {
        if (forceRender && forceRender > 0) {
            loadItem();
            setResetValue(1);
        }
    }, [forceRender]);

    function loadItem() {
        setIsLoading(true);
        switch (type) {
            case 'MyBook':
            case 'QuotationTO_MyBook':
                const getActivityDetail = async (inputData) => {
                    const response = callGetActivityDetail(inputData);
                }
                getActivityDetail({ PNR: id, ProductType: configData.Settings.Products.Activity.IdTipoProdotto, Options: { WebsiteBaseUrl: configData.Settings.OldWebSite_BaseUrl } });
                break;
            case 'QuotationTO':
                const getQuotationItem = async (inputData) => {
                    const response = callGetQuotationItem(inputData);
                }
                getQuotationItem(id);
                break;
            default:
                setIsLoading(false);
        }
    }

    async function callGetActivityDetail(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetReservationDetail', requestOption);
        if (fetchedRes.ok) {

            const response = await fetchedRes.json();

            if (type === 'QuotationTO_MyBook' || type === 'MyBook')
                customFunctions.onCallSaveProductInfo(response, type, id);

            setActivityDetail(response);
            setIsError(false);
        } else {
            setErrorMsg(t("MyBook:PracticeNotAvailableMsg").replace("##ID##", id));
            setIsError(true);
        }

        setIsLoading(false);
    }

    async function callGetQuotationItem(id) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationitem/${id}`, requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            customFunctions.onCallSaveProductInfo(response, type, id);

            setActivityDetail(response);
            setIsError(false);

            if (response.allowedOperations && response.allowedOperations.EnabledUpdateStaticData)
                setIsEnabledUpdateStaticData(response.allowedOperations.EnabledUpdateStaticData.enabled && (type !== 'QuotationTOEndUser' && type !== 'QuotationEndUser'));
        } else {
            setErrorMsg(t("MyBook:QuotaionProductNotLoadMsg").replace("##ID##", id));
            setIsError(true);
        }

        setIsLoading(false);
    }

    function onClickOpenUpdateStaticData() {
        try {
            setResetValue((resetValue + 1));
        } catch (ex) { }
    }

    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : isError ? <Error textMsg={errorMsg} />
                    : (
                        <>
                            <div id={'aDetail_' + id}></div>

                            {/*Vertical Left Icon Bar*/}
                            {
                                type === 'MyBook'
                                    ? <div className="col-12 col-sm-10 col-md-6 col-lg-1 mb-4">
                                        <div className="timeline timeline-one timeline-mybook h-100">
                                            <div className="timeline-item h-100">
                                                <span className="icon icon-success"><i className="ph-person-simple-walk-bold h2"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                            }

                            {/*Activity*/}
                            <div className={type === 'MyBook' ? "col-12 col-sm-10 col-md-6 col-lg-11 mb-4" : ""}>
                                <div className={type === 'MyBook' ? "card border-light animate-up-5" : "card border-light mb-4 animate-up-5"}>
                                    <div className="row no-gutters">
                                        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                                            <div className="row no-gutters">
                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                    <img src={!activityDetail.thumbUrl ? imageOnLoad(configData.Settings.Products.Activity.IdTipoProdotto) : activityDetail.thumbUrl} alt="Thumb" className="card-img p-2 rounded-xl" onError={e => imageOnError(e, configData.Settings.Products.Activity.IdTipoProdotto)} />
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                    <div className="myBook-card-body">
                                                        <div className="mybook-activity">
                                                            <h5>
                                                                {
                                                                    (type === 'QuotationTO' || type === 'QuotationTO_MyBook')
                                                                    && (customFunctions && !customFunctions.isAgencyMode)
                                                                    && (activityDetail.quotationDetail && activityDetail.priceBar.isManualLoading)
                                                                    && <span className="material-icons-outlined mx-1" title="Prodotto manuale" style={{ fontSize: '16px' }}>front_hand</span>
                                                                }
                                                                {
                                                                    type === 'QuotationTO' && <span className="badge bg-gray-300 text-dark mr-2">{id}</span>
                                                                }
                                                                {activityDetail.name} <a className="ico info" style={{ display: 'none' }}><i className="fas fa-info-circle"></i></a>
                                                            </h5>
                                                            <p className="my-2">
                                                                <span><i className="ph-map-pin-line-bold"></i>{activityDetail.address}</span>
                                                            </p>
                                                            <p className="my-2">
                                                                {activityDetail.category !== null ? (<span><i className="ph-map-trifold-fill"></i>[CATEGORY]</span>) : (<></>)}
                                                            </p>
                                                        </div>
                                                        <div className="float-left">
                                                            {type === 'MyBook' && <OperationButtons pnr={id} productType={configData.Settings.Products.Activity.IdTipoProdotto} status={activityDetail.priceBar.status} allowedOps={activityDetail.allowedOperations} />}
                                                            {
                                                                type === 'QuotationTO' && <QuotationOperationButtons
                                                                    id={id}
                                                                    callOnChangeStatusQuotationItem={customFunctions.callOnChangeStatusQuotationItem}
                                                                    onCallRequoteQuotationItem={customFunctions.onCallRequoteQuotationItem}
                                                                    onCallUpdateQuotationItem={customFunctions.onCallUpdateQuotationItem}
                                                                    isCartQuotation={customFunctions.isCartQuotation}
                                                                    quotationMode={customFunctions.quotationMode}
                                                                    reservationDetail={activityDetail.reservationDetail}
                                                                    quotationDetail={activityDetail.quotationDetail} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mybook-hotel-room">
                                                <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                                    <h6>
                                                        Composizione:&nbsp;&nbsp;
                                                        {activityDetail.option.adults}  <M3Icon iconName="PaxAdl" />
                                                        {activityDetail.option.children} <M3Icon iconName="PaxChd" />
                                                        {
                                                            activityDetail.option.children && activityDetail.option.children > 0 && activityDetail.option.childrenAges && activityDetail.option.childrenAges.length > 0 && <>
                                                                ({t('SearchEngine:Age')}:
                                                                {
                                                                    activityDetail.option.childrenAges.map((age, index) => {
                                                                        return age + (index === (activityDetail.option.childrenAges.length - 1) ? "" : ",");
                                                                    })
                                                                }
                                                                )
                                                            </>
                                                        }
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="mybook-activity-option">
                                                <div className="row">
                                                    {
                                                        (type === 'MyBook' || type === 'QuotationTO_MyBook')
                                                            ? <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                                                <span className="mybook-activity-option-pax"><i className="ph-user-fill"></i><label>Ref.Pax:</label> {activityDetail.option.refPax}</span>
                                                            </div>
                                                            : <></>
                                                    }
                                                    <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                                        {
                                                            activityDetail.option.cancelPolicy && <span className={activityDetail.option.cancelPolicy.isFree ? "penalty free" : "penalty"}>
                                                                <i className="ph-money-light"></i>
                                                                {activityDetail.option.cancelPolicy.isFree ? t("CancelPolicy:FreeCancellation") + formatDateTime(activityDetail.option.cancelPolicy.expirationDate, { twoDigits: true }) + " " + renderWebsite.cancelPolicy.time : t("CancelPolicy:PenaltyCancellation")}
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                        <span><i className="ph-bed-light"></i><label>O{t("MyBook:SelectedOption")}:</label> {activityDetail.option.optionName}</span>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 right">
                                                        <h2 className="accordion-header " id="heading1c">
                                                            <a className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={'#pannelActivity' + id} aria-expanded="true" aria-controls={'pannelActivity' + id}>
                                                                <span className="icon-title h6 mb-0 font-weight-bold text-right w-100">{t("MyBook:DetailPassengerInfo")}</span>
                                                            </a>
                                                        </h2>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div id={'pannelActivity' + id} className="accordion-collapse collapse" role="region" aria-labelledby="heading1c">
                                                            <div className="accordion-body">
                                                                {/*Passengers*/}
                                                                {
                                                                    (type === 'MyBook' || type === 'QuotationTO_MyBook')
                                                                        ? <div className="pt-3">
                                                                            <div>{t("MyBook:Passengers")}:</div>
                                                                            {
                                                                                activityDetail.option.passengers.map(passenger =>
                                                                                    <Passenger key={passenger.index} passenger={passenger} />
                                                                                )
                                                                            }
                                                                        </div>
                                                                        : <></>
                                                                }

                                                                {/*Extras*/}
                                                                {
                                                                    activityDetail.option.extras && activityDetail.option.extras.length > 0 && <div className="row pt-3">
                                                                        <div className="mb-2"><data m3lab="Product.Activity.AddictionalOptions">{t('Product:Activity:AddictionalOptions')}:</data></div>
                                                                        {
                                                                            activityDetail.option.extras.map((extra) => {
                                                                                return <DMCExtras extra={extra} readOnly={true} />;
                                                                            })
                                                                        }
                                                                    </div>
                                                                }

                                                                {/*Notes*/}
                                                                {
                                                                    activityDetail.option.notes !== null && activityDetail.option.notes !== undefined && activityDetail.option.notes.length > 0
                                                                        ? (
                                                                            <div className="pt-3">
                                                                                Note:
                                                                                {activityDetail.option.notes.map((note, index) =>
                                                                                    <p key={index} dangerouslySetInnerHTML={{ __html: note }}></p>
                                                                                )}
                                                                            </div>
                                                                        )
                                                                        : (<></>)
                                                                }

                                                                {/* Descriptions*/}
                                                                {
                                                                    activityDetail.option.descriptions && activityDetail.option.descriptions.length > 0
                                                                        ? (
                                                                            activityDetail.option.descriptions.map((item, key) => {
                                                                                return <div key={key} className="pt-3 text-justify">
                                                                                    <b>{item.title}</b>
                                                                                    <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                                                                </div>
                                                                            })

                                                                        )
                                                                        : (<></>)
                                                                }

                                                                {
                                                                    <ServiceNote notes={activityDetail.notesService} noteTypeToShow={[13]} />
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*Price Bar*/}
                                        <PriceBar
                                            oPriceBar={activityDetail.priceBar}
                                            showNetPrices={showNetPrices}
                                            showCost={showCost}
                                            quotationDetail={activityDetail.quotationDetail}
                                            reservationDetail={activityDetail.reservationDetail}
                                            quotationMode={customFunctions.quotationMode}
                                            customView={{ notShowPaymentAvailable: (type === 'QuotationTO' || type === 'QuotationTO_MyBook') }}
                                            isEnabledUpdateStaticData={isEnabledUpdateStaticData}
                                            onClickOpenUpdateStaticData={onClickOpenUpdateStaticData} />
                                    </div>
                                </div>
                            </div>

                            {/*Modals*/}
                            {type === 'MyBook' && <CancelReservationModal pnr={id} productType={configData.Settings.Products.Activity.IdTipoProdotto} />}
                            {type === 'MyBook' && activityDetail.priceBar.paymentData !== null && <PayWithCreditCardModal pnr={id} productType={configData.Settings.Products.Activity.IdTipoProdotto} paymentData={activityDetail.priceBar.paymentData} />}
                            {isEnabledUpdateStaticData && <UpdateActivityData
                                modalId={modalId}
                                quotationItemId={id}
                                saveProductData={saveProductData}
                                resetValue={resetValue} />}
                        </>
                    )
            }
        </>
    );
}