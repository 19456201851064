import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { getCurrentUserFromJwtToken, formatPrice, formatDateTime, getStatusClass, getQuotationItemStatus, getQuotationItemStatusClass } from '../../js/Utils.js';
import { ProductAllPrice } from '../Product/Common/Pricing/ProductAllPrice';

export const PriceBar = ({ oPriceBar, showNetPrices, showCost, reservationDetail, quotationDetail, customView, quotationMode, isEnabledUpdateStaticData, onClickOpenUpdateStaticData }) => {
    const { t } = useTranslation();
    const [pricingB2C, setPricingB2C] = useState(false);
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    useEffect(() => {
        try {
            let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
            let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);
            if (currentUser.roles && currentUser.roles.includes("US_PricingB2C")) {
                setPricingB2C(true);
            } else {
                setPricingB2C(false);
            }
        } catch (ex) { }
    }, []);

    function isVisiblePrice() {

        if (quotationMode) {
            if (quotationMode === 'QuotationTOEndUser' && quotationDetail && quotationDetail.showPriceQuotationTOADV)
                return true;

            if (quotationMode === 'QuotationTOWorking' || quotationMode === 'QuotationWorking')
                return true;
        }
        else if (reservationDetail) {
            return reservationDetail.showPriceQuotationTOADV;
        }
        else {
            
            return true;
        }
        
        return false;
    }

    return (
        <>
            {
                oPriceBar !== undefined && oPriceBar !== null
                    ? (
                        <div className={`col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 mybook-priceBar status ${(quotationDetail ? "" : getStatusClass(oPriceBar.status))} bg`}>
                            <div className="d-table w-100 h-100">
                                <div className="d-table-row h-50">
                                    <div className="d-table-cell align-top">
                                        {oPriceBar.wsBookingId && <span className="text-muted font-small d-block"><label>N.Pre.:</label> {oPriceBar.wsBookingId}</span>}
                                        {isVisiblePrice() && <h3>{formatPrice((!pricingB2C ? oPriceBar.invoicePrice : oPriceBar.prezzoCliente), oPriceBar.currency, cultureName)}</h3>}
                                        {
                                            showNetPrices
                                                ? (
                                                    <div>
                                                        <ProductAllPrice showPriceBar={showNetPrices} showCost={showCost} pricing={oPriceBar.pricing} />
                                                    </div>
                                                )
                                                : (<></>)
                                        }
                                        <div className={`my-2 status ${(quotationDetail ? "" : getStatusClass(oPriceBar.status))}`}>
                                            {!quotationDetail && <span>{t(`statiPrenotazione:` + oPriceBar.status)}</span>}
                                            {quotationDetail && (quotationMode === 'QuotationTOWorking' || quotationMode === 'QuotationTOWorking') && <span className={getQuotationItemStatusClass(quotationDetail.status)}>{t(getQuotationItemStatus(quotationDetail.status))}</span>}
                                            {quotationDetail && quotationDetail.deletedDate && <div>{formatDateTime(quotationDetail.deletedDate, cultureName)}</div>}
                                        </div>
                                        {oPriceBar.supplierPNR && <div><span><label>N. PNR:</label> {oPriceBar.supplierPNR}</span></div>}
                                        <div>
                                            <p style={{ display: 'none' }}><span><label>Data book:</label> </span></p>
                                            <p><span><label>{t("MyBook:Date")} Ck-In:</label> {formatDateTime(oPriceBar.startDate, cultureName, { twoDigits: true })}</span></p>
                                            {oPriceBar.endDate && <p><span><label>{t("MyBook:Date")} Ck-Out:</label> {formatDateTime(oPriceBar.endDate, cultureName, { twoDigits: true })}</span></p>}
                                            {oPriceBar.endDate && <p><span><label>{t("MyBook:NNights")}:</label> {oPriceBar.numNights}</span></p>}
                                        </div>
                                        <div className="my-2"><h6>{oPriceBar.supplier}</h6></div>

                                        {isEnabledUpdateStaticData && <button className={`tp-btn-confirm btn-sm item-btn-bar`} onClick={onClickOpenUpdateStaticData}>Scheda</button>}
                                    </div>
                                </div>
                                {/*<div className="d-table-row h-50">*/}
                                {/*    <div className="d-table-cell align-bottom">*/}
                                {/*        <div className="Structure-btn-seleziona">*/}
                                {/*            {!(customView && customView.notShowPaymentAvailable) && oPriceBar.paymentData !== null && <a className="tp-btn-add mt-3 rounded py-2 text-decoration-none" data-bs-toggle="modal" data-bs-target={'#PayWithCreditCard_' + oPriceBar.wsBookingId}>Pay with Credit Card</a>}*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    )
                    : (<></>)
            }
        </>
    );
}
