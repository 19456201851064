import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import configData from "../../appsettings.json";
import { handleError, getWebsiteId } from "../../js/Utils";
import { Loading } from '../Common/Loading';
import { Error } from '../Common/Error';
import { WebContentItem } from './components/WebContentItem';
import { useTranslation } from 'react-i18next';


export const WebContent = ({ pageCodeParam, extraParams, noShowLoading }) => {
    let { pageCode } = useParams();
    const cookies = new Cookies();
    let cultureName = cookies.get("CultureName");

    const { t } = useTranslation();

    const [pageItems, setPageItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    // Modal
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [blockData, setBlockData] = useState(null);
    const [blockDataLines, setBlockDataLines] = useState(null);

    useEffect(() => {
        callGetWebPageContent();

        document.getElementById('openModalDestination').addEventListener('shown.bs.modal', function (e) {
            setIsLoadingModal(true);
            getBlockData();
        });

    }, []);

    function loadJs() {
        //const script = document.createElement("script");
        //script.src = "./test.js";
        //script.async = true;

        //document.body.appendChild(script);
    }

    function callGetWebPageContent() {
        setIsLoading(true);
        let myInputParam = {};

        let pageCodePR = pageCode;

        if (!pageCodePR)
            pageCodePR = pageCodeParam;

        if (!pageCodePR)
            pageCodePR = 'Home';

        if (extraParams && extraParams.length > 0) {
            for (let i = 0; i < extraParams.length; i++) {
                let paramBody = extraParams[i];

                switch (paramBody.key) {
                    case 'CAMPAGNAID': {
                        myInputParam.campagnaId = paramBody.value;
                        break;
                    }

                    case 'PAGE': {
                        pageCodePR = paramBody.value;
                        break;
                    }

                    default:
                        break;
                }
            }
        }

        if (cultureName === undefined)
            cultureName = "it-IT";

        const websiteId = getWebsiteId();

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(myInputParam)
        };
        fetch(`${configData.Settings.CommonApi_BaseUrl}webcontent/getpage/${pageCodePR}/${cultureName}/${websiteId}`, requestOption)
            .then((res) => handleError(res))
            .then((items) => {
                setPageItems(items);
                setIsError(false);
                setIsLoading(false);

                loadJs();
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
                setIsLoading(false);
            });

    }

    async function getBlockData() {
        let blockId = document.getElementById('webContent_blockId').innerHTML;
        if (cultureName === undefined)
            cultureName = "it-IT";

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}webcontent/getblock/${blockId}/${cultureName}`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setBlockData(response.block);

            let lines = [];
            for (let i = 1; i < 7; i++) {
                try {
                    let line = {};
                    line.description = response.block['row' + i];
                    line.destination = response.block['row' + i + '_Destination'];
                    line.icon = response.block['row' + i + '_Icon'];
                    line.iconalt = response.block['row' + i + '_IconAlt'];
                    line.link = response.block['row' + i + '_Link'];
                    line.media = response.block['row' + i + '_Media'];
                    line.price = response.block['row' + i + '_Price'];
                    line.priceb2c = response.block['row' + i + '_PriceB2C'];

                    const isEmpty = Object.values(line).every(x => x === null || x === '');
                    if (!isEmpty)
                        lines.push(line);

                } catch (ex) {
                    console.error(ex);
                }

            }

            setBlockDataLines(lines);
        }

        setIsLoadingModal(false);
    }


    return (
        <div className="banner-custom-bg">
            {
                pageItems && pageItems.length > 0 && pageItems.map((component, key) => {
                    return <WebContentItem key={key} item={component} />
                })
            }
            {
                isLoading && !noShowLoading && <div className="text-center"><Loading textMsg="Caricamento in corso..." /></div>
            }
            <div id="webContent_blockId" className="d-none"></div>

            <a id="lnkOpenModalDestination" className="d-none" data-bs-toggle="modal" data-bs-target="#openModalDestination"></a>
            <div className="modal fade" id="openModalDestination" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">
                                {blockData && blockData.title}
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {
                                isLoadingModal && <div className="text-center"><Loading textMsg="Caricamento in corso..." /></div>
                            }
                            {
                                !isLoadingModal && blockDataLines && blockDataLines.map((line, index) => {
                                    return <div key={index} className="rounded border p-1 m-2">
                                        <a className="text-decoration-none" href={line.link}>
                                            <span className="material-icons rounded bg-custom text-white icon-25 p-2">
                                                {line.icon}
                                            </span>
                                            <span className="h5">{line.destination}</span>
                                        </a>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
