import ReactDOMServer from "react-dom/server";
import { generateComponentInBody } from "../../../../js/PrintUtility";
import { PrintVoucherStructure } from "./components/PrintVoucherStructure";
import { PrintVoucherActivity } from "./components/PrintVoucherActivity";
import { PrintVoucherCar } from "./components/PrintVoucherCar";
import { PrintVoucherTransfer } from "./components/PrintVoucherTransfer";
import { PrintVoucherTour } from "./components/PrintVoucherTour";
import configData from "../../../../appsettings.json";
import { PrintVoucherBookingNote } from './components/PrintVoucherBookingNote';
import { PrintVoucherDynamicNote } from './components/PrintVoucherDynamicNote';

export function generateVoucherHTML(t, productDetailArr, visualizationType, cultureName, customBookingArr, customDynamicNoteArr) {
    let myArr = [];

    if (productDetailArr.length > 0) {
        productDetailArr.forEach(function (productDetail) {
            let productComponent;

            if (productDetail.productType == configData.Settings.Products.Structure.IdTipoProdotto) {
                productComponent = ReactDOMServer.renderToString(<PrintVoucherStructure
                    productDetail={productDetail}
                    cultureName={cultureName}
                    t={t}
                />);
            }
            else if (productDetail.productType == configData.Settings.Products.Activity.IdTipoProdotto) {
                productComponent = ReactDOMServer.renderToString(<PrintVoucherActivity
                    productDetail={productDetail}
                    cultureName={cultureName}
                    t={t}
                />);
            }
            else if (productDetail.productType == configData.Settings.Products.CarRental.IdTipoProdotto) {
                productComponent = ReactDOMServer.renderToString(<PrintVoucherCar
                    productDetail={productDetail}
                    cultureName={cultureName}
                    t={t}
                />);
            }
            else if (productDetail.productType == configData.Settings.Products.Transfer.IdTipoProdotto) {
                productComponent = ReactDOMServer.renderToString(<PrintVoucherTransfer
                    productDetail={productDetail}
                    cultureName={cultureName}
                    t={t}
                />);
            }
            else if (productDetail.productType == configData.Settings.Products.Tour.IdTipoProdotto) {
                productComponent = ReactDOMServer.renderToString(<PrintVoucherTour
                    productDetail={productDetail}
                    cultureName={cultureName}
                    t={t}
                />);
            }

            myArr.push({ html: generateComponentInBody(productComponent), break: false, wrap: true });

            let bookingNoteComponent = ReactDOMServer.renderToString(<PrintVoucherBookingNote
                notes={customBookingArr}
                t={t}
            />);

            myArr.push({ html: generateComponentInBody(bookingNoteComponent), break: false, wrap: true });


            let voucherNoteComponent = ReactDOMServer.renderToString(<PrintVoucherDynamicNote
                t={t}
                notes={customDynamicNoteArr}
            />);

            myArr.push({ html: generateComponentInBody(voucherNoteComponent), break: false, wrap: true });
        });
    }

    return myArr;
}