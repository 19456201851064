import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMealPlanLabelExtended, getDisplayPriceLabel, getDisplayPrice } from '../../../../js/Utils.js';
import Cookies from 'universal-cookie';
import { ProductAllPrice } from './ProductAllPrice';

export const ProductPriceBlock = ({ pricing, supplier, showPriceBar, showCost, isPackage, isPackageTO, isPromotion, otherParam, typePrice, isFromTour }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const [cultureName] = useState(cookies.get("CultureName"));

    return (
        <>
            {
                pricing &&
                <div className="d-table-row h-50 text-right float-end">
                    <div className="d-table-cell pt-1 pr-2">
                        {typePrice === 'NTPax' && <label className=""><data m3lab="General.NTPax"> {t(`General:NTPax`)}:</data></label>}
                        {typePrice === 'XPax' && <label className=""><data m3lab="General.XPax"> {t(`General:XPax`)}:</data></label>}
                        {typePrice === 'TotPrice' && <label className=""><data m3lab="General.TotPrice"> {t(`General:TotPrice`)}:</data></label>}
                            {typePrice === 'XCabin' && <label className=""><data m3lab="General.TotPrice"> {t(`General:XCabin`)}:</data></label>}
                        {!isFromTour && <span className="h3 ml-2"><var>{getDisplayPrice(pricing, cultureName, showCost, null)}</var></span>}
                        {!isFromTour && <div className=""><data m3lab="General.Commission">{t(getDisplayPriceLabel(showCost, pricing.isCommisionable))}</data></div>}
                        {
                            otherParam && otherParam.mealPlanId && <span className="text-dark h075 small d-block">
                                <var>{t(getMealPlanLabelExtended(otherParam.mealPlanId))}</var>
                            </span>
                        }
                        <ProductAllPrice showPriceBar={showPriceBar} showCost={showCost} pricing={pricing} />
                        {
                            supplier && <span className="text-warning small d-block"><var m3Role="FnShowProvider">{supplier.supplierName}</var></span>
                        }
                        {isPackage && <div className="d-inline-flex bg-warning text-white small w-auto px-2 py-1 mt-1 me-1 rounded"><var>Package</var></div>}
                        {isPackageTO && <div className="d-inline-flex bg-warning text-white small w-auto px-2 py-1 mt-1 me-1 rounded"><var>Package TO</var></div>}
                        {isPromotion && <div className="d-inline-flex bg-warning text-white small w-auto px-2 py-1 mt-1 me-1 rounded"><var>Promotion</var></div>}
                    </div>
                </div>
            }
        </>
    );
}