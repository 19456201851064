import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../../js/Utils.js';
import Cookies from 'universal-cookie';

export const ProductAllPrice = ({ showPriceBar, showCost, pricing }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const [cultureName] = useState(cookies.get("CultureName"));
    
    return (
        <>
            {
                showPriceBar && pricing && <div m3role="f_ShowPriceBar" className='d-flex d-sm-block gap-2'>
                    {
                        showCost && <span className="d-block small text-muted">
                            <data m3lab="PriceBar.Cost">{t(`PriceBar:Cost`)}: </data>
                            <var>{formatPrice(pricing.costoFornitore, pricing.valutaFornitore, cultureName)}</var>
                        </span>
                    }
                    <span className="d-block small text-muted">
                        <data m3lab="PriceBar.Net">{t(`PriceBar:Net`)}: </data>
                        <var>{formatPrice(pricing.displayNet, pricing.valutaCliente, cultureName)}</var>
                    </span>
                    {
                        pricing.isCommisionable && <span className="d-block small text-muted">
                            <data m3lab="PriceBar.Com">{t(`PriceBar:Com`)}: </data>
                            <var>{formatPrice(pricing.commission, pricing.valutaCliente, cultureName)}</var>
                        </span>
                    }
                    <span className="d-block small text-muted">
                        <data m3lab="PriceBar.Mkt">{t(`PriceBar:Mkt`)}: </data>
                        <var>{formatPrice(pricing.agencyMarkupVal, pricing.valutaCliente, cultureName)}</var>
                    </span>
                    <span className="d-block small text-muted">
                        <data m3lab="PriceBar.Fee">{t(`PriceBar:Fee`)}: </data>
                        <var>{formatPrice(pricing.agencyFee, pricing.valutaCliente, cultureName)}</var>
                    </span>
                </div>
            }
        </>
    );
}