import React, { useState, useEffect } from 'react';
import { TrainList } from './TrainList';

export const TrippieTrainProductResult = ({ productSearchParam, onAddTrain }) => {
    const [extraParam, setExtraParam] = useState(null);
    const [availabilityParam, setAvailabilityParam] = useState(null);
    const [forceClearIntervalList, setForceClearIntervalList] = useState([]);

    useEffect(() => {

        if (productSearchParam) {
            setExtraParam({
                carpetId: productSearchParam.carpetId,
                clientFlowId: productSearchParam.clientFlowId,
                productId: 0
            });

            setAvailabilityParam(null);
        }

    }, [productSearchParam])

    async function onClickSearch(ids, filters = null) {
        let param = {};

        param.clientFlowId = productSearchParam.clientFlowId;
        param.carpetId = productSearchParam.carpetId;
        param.itineraryId = ids.itineraryId;
        param.fareId = ids.fareId;
        param.cartItemId = productSearchParam.cartItemId;

        clearCarpetAllInterval();
        setAvailabilityParam(param);
    }


    function saveIntervalCarpetPing(intervalPing) {
        let upd = [...forceClearIntervalList];
        upd.push(intervalPing);
        setForceClearIntervalList(upd);
    }

    function clearCarpetAllInterval() {
        if (forceClearIntervalList) {
            for (let i = 0; i < forceClearIntervalList.length; i++) {
                try {
                    let intId = forceClearIntervalList[i];
                    clearInterval(intId);
                } catch (ex) { }

            }

            setForceClearIntervalList([]);
        }
    }

    return (
        <>
            {
                availabilityParam && <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item cursor-pointer" aria-current="page" onClick={_ => setAvailabilityParam(null)}>List</li>
                        <li class="breadcrumb-item active" aria-current="page">Fares</li>
                    </ol>
                </nav>
            }
            {
                productSearchParam.carpetId && !availabilityParam && <TrainList carpetId={productSearchParam.carpetId}
                    clientFlowId={productSearchParam.clientFlowId}
                    extraParam={extraParam}
                    step={productSearchParam.stepIndex}
                    saveIntervalCarpetPing={saveIntervalCarpetPing}
                    onClickSearch={onClickSearch} />
            }
        </>
    );
}