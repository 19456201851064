import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import configData from "../../appsettings.json";
import { paxType } from "../../js/Enums";
import { handleError, addDaysToDatePicker } from "../../js/Utils";
import { M3Icon } from '../Common/M3Icon';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { AddServiceNote } from './components/AddServiceNote';

export const CruiseManual = (props) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    const today = new Date().toISOString().split("T")[0];

    const cruiseItineraryDefault = {
        id: 0,
        date: '',
        departureTime: '',
        arrivalTime: '',
        port: ''
    }

    const cabinPaxPricingDefault = {
        id: 0,
        paxType: paxType[0].key,
        age: null,
        buyPriceAmount: null,
        buyPriceCurrency: 'EUR',
        taxesAmount: null,

        supplierCommissionAmount: null,
        supplierCommissionCurrency: 'EUR',

        serviceChargeAmount: null,
        sellPriceAmount: null,
        sellPriceCurrency: 'EUR',
    }

    const cruiseDetailDefault = {
        id: -1,
        productType: configData.Settings.Products.Cruise.IdTipoProdotto,
        city: {},
        cruiseName: '',
        shipName: '',
        duration: null,

        imageUrl: '',
        providerId: 'NOT',
        payBy: '',
        recordLocator: '',
        internalNote: '',

        itinerary: [{ ...cruiseItineraryDefault }],

        cabinName: '',
        cabinDescription: '',
        cabinThumbUrl: '',
        deckName: '',
        cancelPolicy: '',

        experienceId: '',
        experience: '',

        cabinPaxPricing: [{ ...cabinPaxPricingDefault }],

        serviceNote: null,
        serviceNoteDesc: null
    };

    const validateCruiseItineraryDefault = {
        isValidDate: false,
        isTouchedDate: false,

        isValidDepartureTime: false,
        isTouchedDepartureTime: false,

        isValidPort: false,
        isTouchedPort: false
    }

    const validateCabinPaxPricingDefault = {
        isValidBuyPriceAmount: false,
        isTouchedBuyPriceAmount: false,

        isValidSellPriceAmount: false,
        isTouchedSellPriceAmount: false,

        isValidSupplierCommissionAmount: false,
        isTouchedSupplierCommissionAmount: false,

        isValidServiceChargeAmount: false,
        isTouchedServiceChargeAmount: false,
    }

    const validateCruiseDefault = {
        isValidCruiseName: false,
        isTouchedCruiseName: false,

        isValidShipName: false,
        isTouchedShipName: false,

        isValidDuration: false,
        isTouchedDuration: false,

        isValidCity: false,
        isTouchedCity: false,

        isValidMacroArea: false,
        isTouchedMacroArea: false,

        isValidProviderId: false,
        isTouchedProviderId: false,

        isValidCabinName: false,
        isTouchedCabinName: false,
        isValidCabinDeckName: false,
        isTouchedCabinDeckName: false,
        isValidCancelPolicy: false,
        isTouchedCancelPolicy: false,
        isValidExperienceId: false,
        isTouchedExperienceId: false,

        isValidItinerary: [{ ...validateCruiseItineraryDefault }],
        isValidCabinPaxPricing: [{ ...validateCabinPaxPricingDefault }],

        isValidForm: false
    };

    const [currencies, setCurrencies] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [experiences, setExperiences] = useState([]);
    const [macroaree, setMacroaree] = useState([]);
    const [ports, setPorts] = useState([]);

    const [cruiseDetail, setCruiseDetail] = useState({ ...cruiseDetailDefault });
    const [validateInputData, setValidateInputData] = useState({ ...validateCruiseDefault });
    const [validateValueDefault, setValidateValueDefault] = useState({ maxCancelPolicy: '' });

    useEffect(() => {
        if (props.isOpen) {
            if (currencies.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}constant/getcurrency`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setCurrencies(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (suppliers.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}customer/getallsupplierforproduct/${configData.Settings.Products.Cruise.IdTipoProdotto}`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setSuppliers(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (experiences.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}cruise/GetExperiences`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setExperiences(item.experiences);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (macroaree.length < 1) {
                const inputData = { CultureCode: cultureName };
                const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };

                fetch(`${configData.Settings.CommonApi_BaseUrl}Cruise/GetMacroareeV2`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setMacroaree(item.macroaree);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (!props.item && ports.length < 1) {
                callDestinationPorts('NOT');
            } else if (props.item && ports.length < 1) {
                callDestinationPorts(props.item.macroarea);
            }

            if (!props.item) {
                setCruiseDetail({ ...cruiseDetailDefault });
                setValidateInputData({ ...validateCruiseDefault });
            }
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (!props.item) {
            setCruiseDetail({ ...cruiseDetailDefault });
            setValidateInputData({ ...validateCruiseDefault });
        } else if (props.item.productType === configData.Settings.Products.Cruise.IdTipoProdotto) {
            setCruiseDetail(props.item);

            var isValidCD = { ...validateCruiseDefault };
            Object.keys(isValidCD).forEach(key => {
                if (key === 'isValidItinerary') {
                    var myArray = [];
                    for (var i = 0; i < props.item.itinerary.length; i++) {
                        var item = { ...validateCruiseItineraryDefault };
                        Object.keys(item).forEach(r => item[r] = true);
                        myArray.push(item);
                    }

                    isValidCD[key] = myArray;
                } else if (key === 'isValidCabinPaxPricing') {
                    var myArray = [];
                    for (var i = 0; i < props.item.cabinPaxPricing.length; i++) {
                        var item = { ...validateCabinPaxPricingDefault };
                        Object.keys(item).forEach(r => item[r] = true);
                        myArray.push(item);
                    }

                    isValidCD[key] = myArray;
                } else {
                    isValidCD[key] = true;
                }
            });

            setValidateInputData(isValidCD);
        }
    }, [props.item]);


    function callDestinationPorts(macroarea) {

        const inputData = { CultureCode: cultureName, macroareaId: macroarea };
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };

        fetch(configData.Settings.CommonApi_BaseUrl + 'Cruise/GetPortsV2', requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                if (item.ports && item.ports.length > 0) {
                    setPorts(item.ports);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function onBlurEvent(event, propertyName) {
        let updateCruiseDetail = { ...cruiseDetail };

        let newValue = '';
        let labelValue = '';
        let isSameMacroareaValue = false;

        if (propertyName === 'providerId' || propertyName === 'macroarea' || propertyName === 'experienceId') {
            newValue = event.key;
            if (!newValue)
                newValue = event;

            try {
                if (propertyName === 'experienceId') {
                    labelValue = experiences.filter(x => x.value === newValue)[0].label;
                }

                if (propertyName === 'macroarea') {
                    labelValue = macroaree.filter(x => x.value === newValue)[0].label;
                    isSameMacroareaValue = updateCruiseDetail[propertyName] === newValue;
                }
            } catch (ex) {

            }
        }
        else if (propertyName === 'serviceNote' || propertyName === 'serviceNoteDesc') {
            newValue = event;
        }
        else {
            newValue = event.target.value;
        }


        updateCruiseDetail[propertyName] = newValue;

        if (propertyName === 'experienceId')
            updateCruiseDetail.experience = labelValue;
        if (propertyName === 'macroarea')
            updateCruiseDetail.macroareaName = labelValue;

        setCruiseDetail(updateCruiseDetail);

        checkValidateInputData(propertyName, newValue, { isSameMacroareaValue: isSameMacroareaValue });
    }

    function onBlurItineraryEvent(event, index, propertyName, extValue = null) {
        let updateItinerary = [...cruiseDetail.itinerary];
        let newValue = extValue;

        if (!extValue)
            newValue = event.target.value;

        updateItinerary[index][propertyName] = newValue;

        var updateCancelPolicy = false;
        var updateCruiseDetail = { ...cruiseDetail, itinerary: updateItinerary };
        if (propertyName === 'date' && index === 0) {
            let cpDate = addDaysToDatePicker(event.target.value, -1);
            updateCruiseDetail.cancelPolicy = cpDate;
            updateCancelPolicy = true;

            if (event.target.value) {
                setValidateValueDefault({ maxCancelPolicy: cpDate });
            }
            else {
                setValidateValueDefault({ maxCancelPolicy: '' });
            }
        }

        setCruiseDetail(updateCruiseDetail);
        checkValidateInputDataItinerary(newValue, index, propertyName, { cancelPolicy: updateCancelPolicy });
    }

    function onBlurCabinPaxPricingEvent(event, index, propertyName) {
        let updateItinerary = [...cruiseDetail.cabinPaxPricing];

        let newValue = '';
        if (propertyName === 'buyPriceCurrency' || propertyName === 'supplierCommissionCurrency' || propertyName === 'sellPriceCurrency' || propertyName === 'paxType') {
            newValue = event.key;
            if (!newValue)
                newValue = event;
        } else {
            newValue = event.target.value;
        }

        if (propertyName === 'paxType') {
            let defaultAge = null;
            switch (newValue) {
                case "1": {
                    defaultAge = "0";
                    break;
                }
            }

            updateItinerary[index]['age'] = defaultAge;
        }
        else if (propertyName === 'buyPriceCurrency' || propertyName === 'supplierCommissionCurrency' || propertyName === 'sellPriceCurrency') {
            for (let i = 0; i < updateItinerary.length; i++) {
                updateItinerary[i][propertyName] = newValue;
            }
        }

        updateItinerary[index][propertyName] = newValue;

        setCruiseDetail({ ...cruiseDetail, cabinPaxPricing: updateItinerary });
        checkValidateCabinPaxPricing(newValue, index, propertyName);
    }

    function checkValidateInputData(propertyName, value, extValue = null) {
        let isValidForm = false;
        let prevValidateInputData = { ...validateInputData };
        delete prevValidateInputData.isValidItinerary;
        delete prevValidateInputData.isValidCabinPaxPricing;
        delete prevValidateInputData.isValidForm;

        const isNotEmpty = (value !== '' && value !== undefined);
        const isSelectedOption = value !== 'NOT';

        switch (propertyName) {
            case 'cruiseName': {
                delete prevValidateInputData.isValidCruiseName;
                delete prevValidateInputData.isTouchedCruiseName;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item) && checkIsValidItinerary(-1) && checkIsValidCabinPaxPricing(-1);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidCruiseName: isNotEmpty, isTouchedCruiseName: true });
                break;
            }
            case 'macroarea': {
                delete prevValidateInputData.isValidMacroArea;
                delete prevValidateInputData.isTouchedMacroArea;

                let isValidItinerary = false;
                if (extValue) {
                    if (extValue.isSameMacroareaValue)
                        isValidItinerary = checkIsValidItinerary(-1);
                    else
                        isValidItinerary = extValue.isSameMacroareaValue;
                } else {
                    isValidItinerary = checkIsValidItinerary(-1);
                }

                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item) && isValidItinerary && checkIsValidCabinPaxPricing(-1);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidMacroArea: isSelectedOption, isTouchedMacroArea: true });

                callDestinationPorts(value);

                break;
            }
            case 'shipName': {
                delete prevValidateInputData.isValidShipName;
                delete prevValidateInputData.isTouchedShipName;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item) && checkIsValidItinerary(-1) && checkIsValidCabinPaxPricing(-1);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidShipName: isNotEmpty, isTouchedShipName: true });
                break;
            }
            case 'duration': {
                delete prevValidateInputData.isValidDuration;
                delete prevValidateInputData.isTouchedDuration;
                var isValidNumber = false;
                var duration = parseInt(value);
                if (duration)
                    isValidNumber = duration > 0;

                isValidForm = isValidNumber && isNotEmpty && Object.values(prevValidateInputData).every(item => item) && checkIsValidItinerary(-1) && checkIsValidCabinPaxPricing(-1);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidDuration: isNotEmpty && isValidNumber,
                    isTouchedDuration: true
                });
                break;
            }
            case 'providerId': {
                delete prevValidateInputData.isValidProviderId;
                delete prevValidateInputData.isTouchedProviderId;
                isValidForm = isSelectedOption && Object.values(prevValidateInputData).every(item => item) && checkIsValidItinerary(-1) && checkIsValidCabinPaxPricing(-1);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidProviderId: isSelectedOption, isTouchedProviderId: true });
                break;
            }
            case 'experienceId': {
                delete prevValidateInputData.isValidExperienceId;
                delete prevValidateInputData.isTouchedExperienceId;
                isValidForm = isSelectedOption && Object.values(prevValidateInputData).every(item => item) && checkIsValidItinerary(-1) && checkIsValidCabinPaxPricing(-1);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidExperienceId: isSelectedOption, isTouchedExperienceId: true });
                break;
            }
            case 'cabinName': {
                delete prevValidateInputData.isValidCabinName;
                delete prevValidateInputData.isTouchedCabinName;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item) && checkIsValidItinerary(-1) && checkIsValidCabinPaxPricing(-1);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidCabinName: isNotEmpty, isTouchedCabinName: true });
                break;
            }
            case 'deckName': {
                delete prevValidateInputData.isValidCabinDeckName;
                delete prevValidateInputData.isTouchedCabinDeckName;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item) && checkIsValidItinerary(-1) && checkIsValidCabinPaxPricing(-1);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidCabinDeckName: isNotEmpty, isTouchedCabinDeckName: true });
                break;
            }
            case 'cancelPolicy': {
                delete prevValidateInputData.isValidCancelPolicy;
                delete prevValidateInputData.isTouchedCancelPolicy;

                let isValidDate = isNotEmpty && (new Date(value) !== "Invalid Date") && new Date(value) >= new Date(today);

                isValidForm = isValidDate && Object.values(prevValidateInputData).every(item => item) && checkIsValidItinerary(-1) && checkIsValidCabinPaxPricing(-1);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidCancelPolicy: isValidDate,
                    isTouchedCancelPolicy: true
                });
                break;
            }
            default:
                break;
        }
    }

    function checkValidateInputDataItinerary(value, index, propertyName, customValidation) {
        let isValidForm = { ...validateInputData.isValidForm };
        let prevValidateInputData = [...validateInputData.isValidItinerary];

        const isNotEmpty = (value !== '' && value !== undefined);

        switch (propertyName) {
            case 'date': {
                delete prevValidateInputData[index].isValidDate;
                delete prevValidateInputData[index].isTouchedDate;
                if (customValidation.cancelPolicy) {
                    delete prevValidateInputData[index].isValidCancelPolicy;
                    delete prevValidateInputData[index].isTouchedCancelPolicy;
                }

                let isValidDate = isNotEmpty && (new Date(value) !== "Invalid Date") && new Date(value) >= new Date(today);
                if (index > 0) {
                    const prevDate = new Date(cruiseDetail.itinerary[index - 1].date);
                    isValidDate = isValidDate && new Date(value) > prevDate;
                }

                isValidForm = checkIsValidCruiseInfo(customValidation) && isValidDate && Object.values(prevValidateInputData[index]).every(item => item) && checkIsValidItinerary(index) && checkIsValidCabinPaxPricing(-1);

                prevValidateInputData[index].isValidDate = isValidDate;
                prevValidateInputData[index].isTouchedDate = true;
                let newValidateInputData = {
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidItinerary: prevValidateInputData
                };

                if (customValidation.cancelPolicy) {
                    newValidateInputData.isValidCancelPolicy = true;
                    newValidateInputData.isTouchedCancelPolicy = true;
                }

                setValidateInputData(newValidateInputData);
                break;
            }
            case 'departureTime': {
                delete prevValidateInputData[index].isValidDepartureTime;
                delete prevValidateInputData[index].isTouchedDepartureTime;

                isValidForm = checkIsValidCruiseInfo() && isNotEmpty && Object.values(prevValidateInputData[index]).every(item => item) && checkIsValidItinerary(index) && checkIsValidCabinPaxPricing(-1);

                prevValidateInputData[index].isValidDepartureTime = isNotEmpty;
                prevValidateInputData[index].isTouchedDepartureTime = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidItinerary: prevValidateInputData });
                break;
            }
            case 'port': {
                delete prevValidateInputData[index].isValidPort;
                delete prevValidateInputData[index].isTouchedPort;

                isValidForm = checkIsValidCruiseInfo() && isNotEmpty && Object.values(prevValidateInputData[index]).every(item => item) && checkIsValidItinerary(index) && checkIsValidCabinPaxPricing(-1);

                prevValidateInputData[index].isValidPort = isNotEmpty;
                prevValidateInputData[index].isTouchedPort = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidItinerary: prevValidateInputData });
                break;
            }
            default:
                break;
        }
    }

    function checkValidateCabinPaxPricing(value, index, propertyName) {
        let isValidForm = { ...validateInputData.isValidForm };
        let prevValidateInputData = [...validateInputData.isValidCabinPaxPricing];

        const isNotEmpty = (value !== '' && value !== undefined);
        switch (propertyName) {
            case 'buyPriceAmount': {
                delete prevValidateInputData[index].isValidBuyPriceAmount;
                delete prevValidateInputData[index].isTouchedBuyPriceAmount;

                isValidForm = checkIsValidCruiseInfo() && isNotEmpty && Object.values(prevValidateInputData[index]).every(item => item) && checkIsValidItinerary(-1);

                prevValidateInputData[index].isValidBuyPriceAmount = isNotEmpty;
                prevValidateInputData[index].isTouchedBuyPriceAmount = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidCabinPaxPricing: prevValidateInputData });
                break;
            }

            case 'sellPriceAmount': {
                delete prevValidateInputData[index].isValidSellPriceAmount;
                delete prevValidateInputData[index].isTouchedSellPriceAmount;

                isValidForm = checkIsValidCruiseInfo() && isNotEmpty && Object.values(prevValidateInputData[index]).every(item => item) && checkIsValidItinerary(-1);

                prevValidateInputData[index].isValidSellPriceAmount = isNotEmpty;
                prevValidateInputData[index].isTouchedSellPriceAmount = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidCabinPaxPricing: prevValidateInputData });
                break;
            }

            case 'supplierCommissionAmount': {
                delete prevValidateInputData[index].isValidSupplierCommissionAmount;
                delete prevValidateInputData[index].isTouchedSupplierCommissionAmount;

                isValidForm = checkIsValidCruiseInfo() && isNotEmpty && Object.values(prevValidateInputData[index]).every(item => item) && checkIsValidItinerary(-1);

                prevValidateInputData[index].isValidSupplierCommissionAmount = isNotEmpty;
                prevValidateInputData[index].isTouchedSupplierCommissionAmount = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidCabinPaxPricing: prevValidateInputData });
                break;
            }

            case 'serviceChargeAmount': {
                delete prevValidateInputData[index].isValidServiceChargeAmount;
                delete prevValidateInputData[index].isTouchedServiceChargeAmount;

                isValidForm = checkIsValidCruiseInfo() && isNotEmpty && Object.values(prevValidateInputData[index]).every(item => item) && checkIsValidItinerary(-1);

                prevValidateInputData[index].isValidServiceChargeAmount = isNotEmpty;
                prevValidateInputData[index].isTouchedServiceChargeAmount = true;

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidCabinPaxPricing: prevValidateInputData });
                break;
            }

            default:
                break;
        }
    }

    function onAddItineraryStep() {
        let updateItinerary = [...cruiseDetail.itinerary];

        var newItinerary = { ...cruiseItineraryDefault };

        newItinerary.id = updateItinerary[updateItinerary.length - 1].id + 1;

        updateItinerary.push(newItinerary);

        setCruiseDetail({ ...cruiseDetail, itinerary: updateItinerary });
        let updateValidItinerary = [...validateInputData.isValidItinerary];
        updateValidItinerary.push(validateCruiseItineraryDefault);
        setValidateInputData({ ...validateInputData, isValidForm: false, isValidItinerary: updateValidItinerary });
    }

    function onRemoveItineraryStep(removeIndex) {
        let updateItinerary = [...cruiseDetail.itinerary];

        updateItinerary.splice(removeIndex, 1);

        setCruiseDetail({ ...cruiseDetail, itinerary: updateItinerary });

        let updateValidItinerary = [...validateInputData.isValidItinerary];
        updateValidItinerary.splice(removeIndex, 1);

        setValidateInputData({
            ...validateInputData,
            isValidForm: checkIsValidCruiseInfo() && checkIsValidItinerary(removeIndex),
            isValidItinerary: updateValidItinerary
        });
    }

    function checkIsValidCruiseInfo(customValidation) {
        var isValidCP = validateInputData.isValidCancelPolicy;
        if (customValidation && customValidation.cancelPolicy) {
            isValidCP = true;
        }

        return validateInputData.isValidCruiseName && validateInputData.isValidShipName && validateInputData.isValidDuration && validateInputData.isValidProviderId && isValidCP;
    }

    function checkIsValidItinerary(exludeIndex) {
        if (!validateInputData.isValidItinerary)
            return false;

        var isValid = true;
        for (var i = 0; i < validateInputData.isValidItinerary.length; i++) {
            if (i !== exludeIndex) {
                isValid = isValid && Object.values(validateInputData.isValidItinerary[i]).every(x => x);
            }
        }

        return isValid;
    }

    function checkIsValidCabinPaxPricing(exludeIndex) {
        if (!validateInputData.isValidCabinPaxPricing)
            return false;

        var isValid = true;
        for (var i = 0; i < validateInputData.isValidCabinPaxPricing.length; i++) {
            if (i !== exludeIndex) {
                isValid = isValid && Object.values(validateInputData.isValidCabinPaxPricing[i]).every(x => x);
            }
        }

        return isValid;
    }

    function getItineraryMinDate(index) {
        if (index === 0)
            return today;

        var prevDate = cruiseDetail.itinerary[index - 1].date;

        if (!prevDate)
            return today;

        return prevDate;
    }

    function getItineraryMaxDate(index) {
        if (index === cruiseDetail.itinerary.length - 1)
            return '';

        var prevDate = cruiseDetail.itinerary[index + 1].date;

        if (!prevDate)
            return '';

        return prevDate;
    }

    function addCabinPaxPricing() {
        let updateCabinPaxPricing = [...cruiseDetail.cabinPaxPricing];

        var newCabinPricing = { ...cabinPaxPricingDefault };

        newCabinPricing.buyPriceCurrency = updateCabinPaxPricing[0].buyPriceCurrency;
        newCabinPricing.sellPriceCurrency = updateCabinPaxPricing[0].sellPriceCurrency;
        newCabinPricing.supplierCommissionCurrency = updateCabinPaxPricing[0].supplierCommissionCurrency;

        newCabinPricing.id = updateCabinPaxPricing.length;
        updateCabinPaxPricing.push(newCabinPricing);

        setCruiseDetail({ ...cruiseDetail, cabinPaxPricing: updateCabinPaxPricing });
        let updateValidCabinPaxPricing = [...validateInputData.isValidCabinPaxPricing];
        updateValidCabinPaxPricing.push(validateCabinPaxPricingDefault);
        setValidateInputData({ ...validateInputData, isValidForm: false, isValidCabinPaxPricing: updateValidCabinPaxPricing });
    }

    function removeCabinPaxPricing() {
        let updateCabinPaxPricing = [...cruiseDetail.cabinPaxPricing];

        updateCabinPaxPricing.splice(updateCabinPaxPricing.length - 1);

        setCruiseDetail({ ...cruiseDetail, cabinPaxPricing: updateCabinPaxPricing });

        let updateValidCabinPaxPricing = [...validateInputData.isValidCabinPaxPricing];
        updateValidCabinPaxPricing.splice(updateValidCabinPaxPricing.length - 1);
        setValidateInputData({
            ...validateInputData,
            isValidForm: checkIsValidCruiseInfo() && checkIsValidItinerary(-1) && checkIsValidCabinPaxPricing(validateInputData.isValidCabinPaxPricing.length - 1),
            isValidCabinPaxPricing: updateValidCabinPaxPricing
        });
    }

    function callOnSelectDestination(item) {
        var updateCruiseDetail = { ...cruiseDetail };
        updateCruiseDetail.city = { webId: item.Id + "", text: item.Text, geoId: item.GeoId + "" };

        setCruiseDetail(updateCruiseDetail);
        checkValidateInputData("city", item, null);
    }

    function callOnSelectPort(event, index) {
        let text = ports.filter(x => x.value === event.value)[0].label;
        let normPort = { webId: event.value, text: text, geoId: "" };
        onBlurItineraryEvent(null, index, 'port', normPort);
    }

    function saveCruiseManual() {
        props.callAddManualQuotationItem(cruiseDetail);
    }

    return (
        <div className="p-4">
            <div className="row">
                <h4>Crociere</h4>
                <div className={"form-floating col-md-3" + (!validateInputData.isValidCruiseName ? " insert-danger" : "")} >
                    <input type="text" className="form-control" value={cruiseDetail.cruiseName} id="floatingInput" placeholder="nome visualizzabile" onChange={(e) => { onBlurEvent(e, 'cruiseName'); }} onBlur={(e) => { onBlurEvent(e, 'name'); }} />
                    <label htmlFor="floatingInput" className="active">Nome Crociera *</label>
                    {
                        validateInputData.isTouchedCruiseName && !validateInputData.isValidCruiseName && <div className="text-danger">Il campo nome crociera è obbligatorio</div>
                    }
                </div>
                <div className={"form-floating col-md-3" + (!validateInputData.isValidShipName ? " insert-danger" : "")}>
                    <input type="text" className="form-control" value={cruiseDetail.shipName} id="floatingInput" placeholder="nome visualizzabile" onChange={(e) => { onBlurEvent(e, 'shipName'); }} onBlur={(e) => { onBlurEvent(e, 'shipName'); }} />
                    <label htmlFor="floatingInput" className="active">Nome Nave *</label>
                    {
                        validateInputData.isTouchedShipName && !validateInputData.isValidShipName && <div className="text-danger">Il campo nome nave è obbligatorio</div>
                    }
                </div>
                <div className={"form-floating col-md-3" + (!validateInputData.isValidDuration ? " insert-danger" : "")}>
                    <input type="number" className="form-control" value={cruiseDetail.duration === null ? '' : cruiseDetail.duration} id="floatingInput" placeholder="nome visualizzabile" onChange={(e) => { onBlurEvent(e, 'duration'); }} onBlur={(e) => { onBlurEvent(e, 'duration'); }} />
                    <label htmlFor="floatingInput" className="active">N. giorni *</label>
                    {
                        validateInputData.isTouchedDuration && !validateInputData.isValidDuration && <div className="text-danger">Il campo durata è obbligatorio</div>
                    }
                </div>
                <div className={"form-floating col-md-3 " + (!validateInputData.isValidMacroArea ? " insert-danger" : "")} >
                    <span className="p-float-label form-select-label-m3-react-prime">
                        {
                            macroaree && macroaree.length > 0 && <Dropdown value={cruiseDetail.macroarea + ""}
                                onChange={(e) => onBlurEvent(e.value, 'macroarea')}
                                inputId="dd-macroarea"
                                filter
                                className="w-100 form-select-m3-react-prime"
                                options={macroaree}
                                optionLabel="label" />
                        }
                        <label htmlFor="dd-macroarea">Macroarea * (Applica regole)</label>
                    </span>
                    {
                        validateInputData.isTouchedMacroArea && !validateInputData.isValidMacroArea && <div className="text-danger">Il campo macroarea è obbligatorio</div>
                    }
                </div>
            </div>
            <div className="row pt-2">
                <div className="col-md-3">
                    <div className={"form-floating" + (!validateInputData.isValidProviderId ? " insert-danger" : "")} >

                        <span className="p-float-label form-select-label-m3-react-prime">
                            {
                                suppliers && suppliers.length > 0 && <Dropdown value={cruiseDetail.providerId}
                                    onChange={(e) => onBlurEvent(e.value, 'providerId')}
                                    inputId="dd-providerId"
                                    filter
                                    className="w-100 form-select-m3-react-prime"
                                    options={suppliers}
                                    optionLabel="label" />
                            }
                            <label htmlFor="dd-providerId">Fornitore *</label>
                        </span>
                        {
                            validateInputData.isTouchedProviderId && !validateInputData.isValidProviderId && <div className="text-danger">Il campo fornitore è obbligatorio</div>
                        }
                    </div>
                </div>
                <div className="form-floating col-md-3">
                    <input type="text" className="form-control" value={cruiseDetail.payBy === null ? '' : cruiseDetail.payBy} id="floatingInput" placeholder="Fornitore per voucher" onChange={(e) => { onBlurEvent(e, 'payBy'); }} />
                    <label htmlFor="floatingInput" className="active">Pay by:</label>
                </div>
                <div className="form-floating col-md-3">
                    <input type="text" className="form-control" value={cruiseDetail.recordLocator === null ? '' : cruiseDetail.recordLocator} id="floatingInput" placeholder="IdRecordLocator" onChange={(e) => { onBlurEvent(e, 'recordLocator'); }} />
                    <label htmlFor="floatingInput" className="active">Cod prenotazione:</label>
                </div>
            </div>

            <div className="row pt-4">
                <label>Itinerario: il primo step è obbligatorio</label>
            </div>

            {
                cruiseDetail.itinerary.map((item, key) => {
                    return <div key={key} className="row pt-4">
                        <div className={"form-floating col-md-3" + (!validateInputData.isValidItinerary[key].isValidDate ? " insert-danger" : "")}>
                            <input type="date"
                                id="floatingInput"
                                placeholder="01/01/99"
                                className="form-control"
                                value={item.date}
                                min={getItineraryMinDate(key)}
                                max={getItineraryMaxDate(key)}
                                onChange={(e) => { onBlurItineraryEvent(e, key, 'date'); }}
                                onBlur={(e) => { onBlurItineraryEvent(e, key, 'date'); }}
                            />
                            <label htmlFor="floatingInput" className="active">Data *</label>
                            {
                                validateInputData.isValidItinerary[key].isTouchedDate && !validateInputData.isValidItinerary[key].isValidDate && <div className="text-danger">Il campo data non è valido</div>
                            }
                        </div>
                        <div className="form-floating col-md-2">
                            <input type="time"
                                id="floatingInput"
                                placeholder="01/01/99"
                                className={"form-control" + (validateInputData.isValidItinerary[key].isTouchedDepartureTime && !validateInputData.isValidItinerary[key].isValidDepartureTime ? " insert-danger" : "")}
                                value={item.departureTime}
                                onChange={(e) => { onBlurItineraryEvent(e, key, 'departureTime'); }}
                                onBlur={(e) => { onBlurItineraryEvent(e, key, 'departureTime'); }}
                            />
                            <label htmlFor="floatingInput" className="active">h Partenza *</label>
                            {
                                validateInputData.isValidItinerary[key].isTouchedDepartureTime && !validateInputData.isValidItinerary[key].isValidDepartureTime && <div className="text-danger">Il campo partenza è obbligatorio</div>
                            }
                        </div>
                        <div className="form-floating col-md-2">
                            <input type="time"
                                className="form-control"
                                id="floatingInput"
                                placeholder="01/01/99"
                                value={item.arrivalTime}
                                onChange={(e) => { onBlurItineraryEvent(e, key, 'arrivalTime') }}
                            />
                            <label htmlFor="floatingInput" className="active">h arrivo </label>
                        </div>
                        <div className={"form-floating col-md-2" + (!validateInputData.isValidItinerary[key].isValidPort ? " insert-danger" : "")}>

                            <span className="p-float-label form-select-label-m3-react-prime">
                                {
                                    ports && ports.length > 0 && <Dropdown value={item.port && item.port.webId ? item.port.webId : ''}
                                        onChange={(e) => callOnSelectPort(e, key)}
                                        inputId="dd-macroarea"
                                        filter
                                        className="w-100 form-select-m3-react-prime"
                                        options={ports}
                                        optionLabel="label" />
                                }
                                <label htmlFor="dd-macroarea">Porto *</label>
                            </span>
                            {
                                validateInputData.isValidItinerary[key].isTouchedPort && !validateInputData.isValidItinerary[key].isValidPort && <div className="text-danger">Il campo porto è obbligatorio</div>
                            }

                        </div>
                        <div className="form-floating col-md-2">
                            {
                                key === (cruiseDetail.itinerary.length - 1) && <button className="btn btn-sm tp-btn-add mt-4 mr-1" onClick={onAddItineraryStep}><M3Icon iconName="Add" externalClass="h085" /></button>
                            }
                            {
                                cruiseDetail.itinerary.length > 1 && <button className="btn btn-sm tp-btn-delete mt-4" onClick={e => onRemoveItineraryStep(key)}><M3Icon iconName="Remove" externalClass="h085" /></button>
                            }
                        </div>
                    </div>
                })
            }

            <div className="row pt-4">
                <div className="form-floating col-md-12">
                    <input type="text"
                        className="form-control"
                        value={cruiseDetail.imageUrl}
                        id="floatingInput"
                        placeholder="Url immagine"
                        onChange={(e) => { onBlurEvent(e, 'imageUrl'); }} />
                    <label htmlFor="floatingInput" className="active">Url Immagine</label>
                </div>
            </div>


            <div>
                <AddServiceNote
                    serviceNote={cruiseDetail.serviceNoteDesc}
                    roomIndex={-1}
                    noteTypeToAdd={12}
                    propertyName="serviceNoteDesc"
                    onBlurEvent={onBlurEvent} />
            </div>

            <div className="form-floating pt-2">
                <textarea className="form-control"
                    placeholder="Note Interne"
                    value={cruiseDetail.internalNote}
                    onChange={(e) => { onBlurEvent(e, 'internalNote'); }}
                    id="floatingTextarea2"
                    style={{ height: '100px' }} ></textarea>
                <label htmlFor="floatingTextarea2">Note Interne</label>
            </div>

            <div className="badge-gray-600 mt-4">Cabina</div>

            <div className="row pt-4">
                <div className={"form-floating col-md-3" + (!validateInputData.isValidCabinName ? " insert-danger" : "")}>
                    <input type="text"
                        className="form-control"
                        value={cruiseDetail.cabinName}
                        onChange={(e) => { onBlurEvent(e, 'cabinName'); }}
                        onBlur={(e) => { onBlurEvent(e, 'cabinName'); }}
                        id="floatingInput"
                        placeholder="123.45" />
                    <label htmlFor="floatingInput" className="active">Nome Cabina *</label>
                    {
                        validateInputData.isTouchedCabinName && !validateInputData.isValidCabinName && <div className="text-danger">Il campo nome cabina è obbligatorio</div>
                    }
                </div>
                <div className={"form-floating col-md-3" + (!validateInputData.isValidCabinDeckName ? " insert-danger" : "")}>
                    <input type="text"
                        className="form-control"
                        value={cruiseDetail.deckName}
                        onChange={(e) => { onBlurEvent(e, 'deckName'); }}
                        onBlur={(e) => { onBlurEvent(e, 'deckName'); }}
                        id="floatingInput"
                        placeholder="123.45" />
                    <label htmlFor="floatingInput" className="active">Ponte *</label>
                    {
                        validateInputData.isTouchedCabinDeckName && !validateInputData.isValidCabinDeckName && <div className="text-danger">Il campo ponte è obbligatorio</div>
                    }
                </div>
                <div className={"form-floating  col-md-3" + (!validateInputData.isValidExperienceId ? " insert-danger" : "")} >
                    <span className="p-float-label form-select-label-m3-react-prime">
                        {
                            experiences && experiences.length > 0 && <Dropdown value={cruiseDetail.experienceId}
                                onChange={(e) => onBlurEvent(e.value, 'experienceId')}
                                inputId="dd-providerId"
                                filter
                                className="w-100 form-select-m3-react-prime"
                                options={experiences}
                                optionLabel="label" />
                        }
                        <label htmlFor="dd-providerId">Tipo esperienza *</label>
                    </span>
                    {
                        validateInputData.isTouchedExperienceId && !validateInputData.isValidExperienceId && <div className="text-danger">Il campo tipo esperienza è obbligatorio</div>
                    }
                </div>
                <div className={"form-floating col-md-2" + (!validateInputData.isValidCancelPolicy ? " insert-danger" : "")}>
                    <input type="date"
                        className="form-control"
                        value={cruiseDetail.cancelPolicy}
                        max={validateValueDefault.maxCancelPolicy}
                        onChange={(e) => { onBlurEvent(e, 'cancelPolicy'); }}
                        onBlur={(e) => { onBlurEvent(e, 'cancelPolicy'); }}
                        id="floatingInput"
                        placeholder="123.45" />
                    <label htmlFor="floatingInput" className="active">Inizio penale *</label>
                    {
                        validateInputData.isTouchedCancelPolicy && !validateInputData.isValidCancelPolicy && <div className="text-danger">Il campo cancel policy è obbligatorio</div>
                    }
                </div>
            </div>

            <div className="form-floating pt-2">
                <textarea
                    className="form-control"
                    value={cruiseDetail.cabinDescription}
                    onChange={(e) => { onBlurEvent(e, 'cabinDescription'); }}
                    placeholder="Descrizione cabina"
                    id="floatingTextarea2"
                    style={{ height: '100px' }}></textarea>
                <label htmlFor="floatingTextarea2">Descrizione Facoltativa</label>
            </div>

            {
                cruiseDetail.cabinPaxPricing && cruiseDetail.cabinPaxPricing.map((item, key) => {
                    return <div key={key} className="row pt-4 mb-4">
                        <div className="form-floating col-md-3">
                            <div className="form-floating">
                                {
                                    paxType && paxType.length > 0 && <Dropdown value={item.paxType + ""}
                                        onChange={(e) => onBlurCabinPaxPricingEvent(e.value, key, 'paxType')}
                                        filter
                                        inputId="dd-paxType"
                                        className="w-100 form-select-m3-react-prime"
                                        options={paxType.map(d => { return { label: d.text, value: d.key }; })}
                                        optionLabel="label" />
                                }
                                <label htmlFor="dd-paxType">Tipo Passeggero</label>
                            </div>
                        </div>
                        <div className="form-floating col-md-1">
                            <input type="number"
                                className="form-control px-0"
                                id="floatingInput"
                                placeholder="123.45"
                                value={item.age === null ? '' : item.age}
                                onChange={(e) => onBlurCabinPaxPricingEvent(e, key, 'age')} />
                            <label htmlFor="floatingInput" className="active">Età </label>
                        </div>
                        <div className={"form-floating col-md-3" + (!validateInputData.isValidCabinPaxPricing[key].isValidBuyPriceAmount ? " insert-danger" : "")}>
                            <input type="number"
                                className="form-control"
                                value={item.buyPriceAmount === null ? '' : item.buyPriceAmount}
                                onChange={(e) => { onBlurCabinPaxPricingEvent(e, key, 'buyPriceAmount'); }}
                                onBlur={(e) => { onBlurCabinPaxPricingEvent(e, key, 'buyPriceAmount'); }}
                                id="floatingInput"
                                placeholder="123.45" />
                            <label htmlFor="floatingInput" className="active">Tariffa escluso tasse *</label>
                            {
                                validateInputData.isValidCabinPaxPricing[key].isTouchedBuyPriceAmount && !validateInputData.isValidCabinPaxPricing[key].isValidBuyPriceAmount && <div className="text-danger">Il campo costo è obbligatorio</div>
                            }
                        </div>

                        <div className={"form-floating col-md-2" + (!validateInputData.isValidCabinPaxPricing[key].isValidSupplierCommissionAmount ? " insert-danger" : "")}>
                            <input type="number"
                                className="form-control"
                                value={item.supplierCommissionAmount === null ? '' : item.supplierCommissionAmount}
                                onChange={(e) => { onBlurCabinPaxPricingEvent(e, key, 'supplierCommissionAmount'); }}
                                onBlur={(e) => { onBlurCabinPaxPricingEvent(e, key, 'supplierCommissionAmount'); }}
                                id="floatingInput"
                                placeholder="123.45" />
                            <label htmlFor="floatingInput" className="active">Commissione Fornitore *</label>
                            {
                                validateInputData.isValidCabinPaxPricing[key].isTouchedSupplierCommissionAmount && !validateInputData.isValidCabinPaxPricing[key].isValidSupplierCommissionAmount && <div className="text-danger">Il campo commissione fornitore è obbligatorio</div>
                            }
                        </div>

                        <div className="form-floating col-md-2 d-none">
                            <div className="form-floating">
                                {
                                    currencies && currencies.length > 0 && <Dropdown value={item.buyPriceCurrency}
                                        onChange={(e) => onBlurCabinPaxPricingEvent(e.value, key, 'buyPriceCurrency')}
                                        inputId="dd-buyPriceCurrency"
                                        filter
                                        className="w-100 form-select-m3-react-prime"
                                        options={currencies}
                                        optionLabel="label" />
                                }
                                <label htmlFor="dd-buyPriceCurrency">Valuta Acquisto</label>
                            </div>
                        </div>

                        <div className="form-floating col-md-2">
                            <input type="number"
                                className="form-control"
                                id="floatingInput"
                                placeholder="123.45"
                                value={item.taxesAmount === null ? '' : item.taxesAmount}
                                onChange={(e) => onBlurCabinPaxPricingEvent(e, key, 'taxesAmount')} />
                            <label htmlFor="floatingInput" className="active">Tasse</label>
                        </div>

                        <div className={"form-floating col-md-3" + (!validateInputData.isValidCabinPaxPricing[key].isValidServiceChargeAmount ? " insert-danger" : "")}>
                            <input type="number"
                                className="form-control"
                                value={item.serviceChargeAmount === null ? '' : item.serviceChargeAmount}
                                onChange={(e) => { onBlurCabinPaxPricingEvent(e, key, 'serviceChargeAmount'); }}
                                onBlur={(e) => { onBlurCabinPaxPricingEvent(e, key, 'serviceChargeAmount'); }}
                                id="floatingInput"
                                placeholder="123.45" />
                            <label htmlFor="floatingInput" className="active">QDS *</label>
                            {
                                validateInputData.isValidCabinPaxPricing[key].isTouchedServiceChargeAmount && !validateInputData.isValidCabinPaxPricing[key].isValidServiceChargeAmount && <div className="text-danger">Il campo QDS è obbligatorio</div>
                            }
                        </div>

                        <div className={"form-floating col-md-3" + (!validateInputData.isValidCabinPaxPricing[key].isValidSellPriceAmount ? " insert-danger" : "")}>
                            <input type="number"
                                className="form-control"
                                value={item.sellPriceAmount === null ? '' : item.sellPriceAmount}
                                onChange={(e) => { onBlurCabinPaxPricingEvent(e, key, 'sellPriceAmount'); }}
                                onBlur={(e) => { onBlurCabinPaxPricingEvent(e, key, 'sellPriceAmount'); }}
                                id="floatingInput"
                                placeholder="123.45" />
                            <label htmlFor="floatingInput" className="active">Prezzo Vendita escluso tasse *</label>
                            {
                                validateInputData.isValidCabinPaxPricing[key].isTouchedSellPriceAmount && !validateInputData.isValidCabinPaxPricing[key].isValidSellPriceAmount && <div className="text-danger">Il campo prezzo vendita è obbligatorio</div>
                            }
                        </div>

                        <div className="form-floating col-md-1 text-right">
                            {
                                key > 0 && key === (cruiseDetail.cabinPaxPricing.length - 1) && <button className="btn btn-sm tp-btn-delete mt-4" onClick={removeCabinPaxPricing}><M3Icon iconName="Remove" externalClass="h085" /></button>
                            }
                            {
                                key === (cruiseDetail.cabinPaxPricing.length - 1) && <button className="btn btn-sm tp-btn-add mt-4" onClick={addCabinPaxPricing}><M3Icon iconName="Add" externalClass="h085" /></button>
                            }
                        </div>
                    </div>
                })
            }

            <div className="mt-2 mb-2">L'età effettiva al momento della partenza deve corrispondere all'età dichiarata al momento dell'imbarco. Età bambini 0-12 compiti</div>

            <div>
                <AddServiceNote
                    serviceNote={cruiseDetail.serviceNote}
                    roomIndex={-1}
                    onBlurEvent={onBlurEvent} />
            </div>

            <div className=" float-end">
                {props.isError && <span className="text-danger">Errore in fase di inserimento, controllare i dati</span>}
                <button className="btn tp-btn-add btn-sm" disabled={!validateInputData.isValidForm} onClick={saveCruiseManual}>{t('Button:Save')}</button>
            </div>
        </div>
    );
}