import React, { useEffect, useState, useCallback } from 'react';
import configData from "../../../../appsettings.json";
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../../Common/M3Icon";

export const HotelPackagesFilter = ({ handleIsRef, handleIsNotRef, isReset }) => {
    const { t } = useTranslation();
    const [isClickRef, setIsClickRef] = useState(false);
    const [isClickNotRef, setIsClickNotRef] = useState(false);


    const handleInputClickIsRef = useCallback(event => {
        if (event.currentTarget.id === "packages") {
            let isClick = !isClickRef;
            handleIsRef(isClick ? isClick : null);
            setIsClickRef(isClick);
        }

    }, [handleIsRef]);

    const handleInputClickIsNotRef = useCallback(event => {
        if (event.currentTarget.id === "nopackages") {
            let isClick = !isClickNotRef;
            handleIsNotRef(isClick ? isClick : null);
            setIsClickNotRef(isClick);
        }

    }, [handleIsNotRef]);

    return (
        <div className="mt-4 bg-gray-200 p-1 rounded shadow">
            <h6 className="px-1 m-0">
                <data m3lab="Product.Structure.Filter.CancelpolicyFilter">{t(`Product:Structure:Filter:PackageFilter`)}:</data>
            </h6>

            <div className="row px-3">
                <div className="d-inline w-50 p-1">
                    <button id="packages" className="btn btn-sm tp-btn-login w-100 p-1" style={isReset || !isClickRef ? { opacity: "1" } : { opacity: "0.6" }  } onClick={e => handleInputClickIsRef(e) }>
                        <var>
                            <data m3lab="Product.Structure.Filter.Refundable">{t(`Product:Structure:Filter:YesPackage`)}</data>
                        </var>
                    </button>
                </div>
                <div className="d-inline w-50 p-1">
                    <button id="nopackages" className="btn btn-sm bg-warning w-100 small p-1" style={isReset || !isClickNotRef ? { opacity: "1" } : { opacity: "0.6" }} onClick={e => handleInputClickIsNotRef(e)}>
                        <var>
                            <data m3lab="Product.Structure.Filter.NotRefundable">{t(`Product:Structure:Filter:NoPackage`)}</data>
                        </var>
                    </button>
                </div>
            </div>
        </div>
    );
}