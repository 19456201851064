import React from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { getADTTotal, getCHDTotal } from '../../../js/PrintUtility';


export const PrintItineraryResume = ({ paxes, isConfirmPrint, isNotStep=true }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();

    return (
        <>
            <table className={"w-100 mt-5 " + (isNotStep ? " rounded-top stepHeader marginBottomPdf" : "")}>
                <tbody>
                    <tr>
                        <td className={"h3 w-40 " + (isNotStep ? "text-white" : "")}>
                            {t(`Template:IndoItinerryTitle`)}
                        </td>
                        <td className="text-right h6">
                            {paxes && paxes.length > 0 &&
                                <table className="w-100">
                                    <tbody>
                                        <tr className={isNotStep ? "text-white" : "text-gray-600"}>
                                            <td className="text-left">{t(`Template:Composition`)}:</td>
                                            <td className="pr-2">{t(`Template:Adults`)}</td>
                                            <td className="pr-2">{getADTTotal(paxes, isConfirmPrint)}</td>
                                            <td className="pr-2">{t(`Template:Children`)}</td>
                                            <td className="pr-2">{getCHDTotal(paxes, isConfirmPrint)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}