import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Loading } from '../Common/Loading';
import { M3Icon } from '../Common/M3Icon';
import { Passenger } from './Passenger';
import { PriceBar } from './PriceBar';
import { CancelReservationModal } from './OperationModals/CancelReservationModal';
import { PayWithCreditCardModal } from './OperationModals/PayWithCreditCardModal';
import { OperationButtons } from './OperationButtons';
import { QuotationOperationButtons } from '../QuotationTO/components/QuotationOperationButtons';
import { formatDateTime } from '../../js/Utils.js';
import configData from "../../appsettings.json";
import { imageOnError, imageOnLoad } from '../../js/CDNUtility';
import { renderWebsite } from '../../js/Constant';
import { Error } from '../Common/Error';
import { useTranslation } from 'react-i18next';
import { ServiceNote } from './ServiceNote';
import { UpdateTransferData } from '../MyBook/ProductData/components/UpdateTransferData';

export const TransferDetail = ({ id, type, showNetPrices, showCost, customFunctions, forceRender, saveProductData }) => {
    const { t } = useTranslation();

    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [transferDetail, setTransferDetail] = useState([]);

    const [modalId, setModalId] = useState("");
    const [resetValue, setResetValue] = useState(1);
    const [isEnabledUpdateStaticData, setIsEnabledUpdateStaticData] = useState(false);


    useEffect(() => {
        setResetValue(1);
        loadItem();

        setModalId("modalUpdateStaticDAta_" + id)
    }, []);

    useEffect(() => {
        if (forceRender && forceRender > 0) {
            loadItem();
            setResetValue(1);
        }
    }, [forceRender]);

    function loadItem() {
        switch (type) {
            case 'MyBook':
            case 'QuotationTO_MyBook':
                const getTransferDetail = async (inputData) => {
                    const response = callGetTransferDetail(inputData);
                }
                getTransferDetail({ PNR: id, ProductType: configData.Settings.Products.Transfer.IdTipoProdotto, Options: { WebsiteBaseUrl: configData.Settings.OldWebSite_BaseUrl } });
                break;
            case 'QuotationTO':
                const getQuotationItem = async (inputData) => {
                    const response = callGetQuotationItem(inputData);
                }
                getQuotationItem(id);
                break;
            default:
                setIsLoading(false);
        }
    }

    async function callGetTransferDetail(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };

        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'reservation/GetReservationDetail', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (type === 'QuotationTO_MyBook' || type === 'MyBook')
                customFunctions.onCallSaveProductInfo(response, type, id)

            setTransferDetail(response);
            setIsError(false);

        } else {
            setErrorMsg(t("MyBook:PracticeNotAvailableMsg").replace("##ID##", id));
            setIsError(true);
        }

        setIsLoading(false);
    }

    async function callGetQuotationItem(id) {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationitem/${id}`, requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            customFunctions.onCallSaveProductInfo(response, type, id);

            setTransferDetail(response);
            setIsError(false);

            if (response.allowedOperations && response.allowedOperations.EnabledUpdateStaticData)
                setIsEnabledUpdateStaticData(response.allowedOperations.EnabledUpdateStaticData.enabled && (type !== 'QuotationTOEndUser' && type !== 'QuotationEndUser'));
        } else {
            setErrorMsg(t("MyBook:QuotaionProductNotLoadMsg").replace("##ID##", id));
            setIsError(true);
        }

        setIsLoading(false);
    }

    function onClickOpenUpdateStaticData() {
        try {
            setResetValue((resetValue + 1));
        } catch (ex) { }
    }

    return (
        <>
            {isLoading
                ? (<Loading textMsg={t('MyBook:Loading')} />)
                : isError ? <Error textMsg={errorMsg} />
                    : (
                        <>
                            <div id={'aDetail_' + id}></div>

                            {/*Vertical Left Icon Bar*/}
                            {
                                type === 'MyBook'
                                    ? <div className="col-12 col-sm-10 col-md-6 col-lg-1 mb-4">
                                        <div className="timeline timeline-one timeline-mybook h-100">
                                            <div className="timeline-item h-100">
                                                <span className="icon icon-success"><i className="ph-bus-bold h2"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                            }

                            {/*Transfer*/}
                            <div className={type === 'MyBook' ? "col-12 col-sm-10 col-md-6 col-lg-11 mb-4" : ""}>
                                <div className={type === 'MyBook' ? "card border-light animate-up-5" : "card border-light mb-4 animate-up-5"}>
                                    <div className="row no-gutters">
                                        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                                            <div className="row  no-gutters ">
                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                    <img src={transferDetail.thumbUrl ? transferDetail.thumbUrl : imageOnLoad(configData.Settings.Products.Transfer.IdTipoProdotto)} alt="Thumb" className="card-img p-2 rounded-xl" onError={e => imageOnError(e, configData.Settings.Products.Transfer.IdTipoProdotto)} />
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                    <div className="myBook-card-body">
                                                        <div className="mybook-transfer">
                                                            <h5>
                                                                {
                                                                    (type === 'QuotationTO' || type === 'QuotationTO_MyBook')
                                                                    && (customFunctions && !customFunctions.isAgencyMode)
                                                                    && (transferDetail.quotationDetail && transferDetail.priceBar.isManualLoading)
                                                                    && <span className="material-icons-outlined mx-1" title="Prodotto manuale" style={{ fontSize: '16px' }}>front_hand</span>
                                                                }
                                                                {
                                                                    type === 'QuotationTO' && <span className="badge bg-gray-300 text-dark mr-2">{id}</span>
                                                                }
                                                                {transferDetail.name}
                                                            </h5>
                                                            <p className="m-2">
                                                                <span><label>{t("MyBook:Service")}:</label> {transferDetail.isRoundTrip ? t("Searchengine:Roundtrip") : t("Searchengine:OnlyOneWay")}</span>
                                                            </p>
                                                            {
                                                                (type === 'MyBook' || type === 'QuotationTO_MyBook')
                                                                    ? <p className="m-2">
                                                                        <span><label>Rif Pax:</label> {transferDetail.refPax}</span>
                                                                    </p>
                                                                    : <></>
                                                            }
                                                            <p>
                                                                {
                                                                    transferDetail.cancelPolicy && <span className={transferDetail.cancelPolicy.isFree ? "penalty free" : "penalty"}>
                                                                        <i className="ph-money-light"></i>
                                                                        {transferDetail.cancelPolicy.isFree ? t("CancelPolicy:FreeCancellation") + " " + formatDateTime(transferDetail.cancelPolicy.expirationDate, cultureName, { twoDigits: true }) + " " + renderWebsite.cancelPolicy.time : t("CancelPolicy:PenaltyCancellation")}
                                                                    </span>
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="float-left">
                                                            {type === 'MyBook' && <OperationButtons pnr={id} productType={configData.Settings.Products.Transfer.IdTipoProdotto} status={transferDetail.priceBar.status} allowedOps={transferDetail.allowedOperations} />}
                                                            {
                                                                type === 'QuotationTO' && <QuotationOperationButtons
                                                                    id={id}
                                                                    callOnChangeStatusQuotationItem={customFunctions.callOnChangeStatusQuotationItem}
                                                                    onCallRequoteQuotationItem={customFunctions.onCallRequoteQuotationItem}
                                                                    onCallUpdateQuotationItem={customFunctions.onCallUpdateQuotationItem}
                                                                    isCartQuotation={customFunctions.isCartQuotation}
                                                                    quotationMode={customFunctions.quotationMode}
                                                                    reservationDetail={transferDetail.reservationDetail}
                                                                    quotationDetail={transferDetail.quotationDetail} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mybook-hotel-room">
                                                <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                                    <h6>
                                                        {t("MyBook:Composition")}:&nbsp;&nbsp;
                                                        {transferDetail.adults} <M3Icon iconName="PaxAdl" />
                                                        {transferDetail.children} <M3Icon iconName="PaxChd" />


                                                        {
                                                            transferDetail.children && transferDetail.children > 0 && transferDetail.childrenAges && transferDetail.childrenAges.length > 0 && <>
                                                                ({t('SearchEngine:Age')}:
                                                                {
                                                                    transferDetail.childrenAges.map((age, index) => {
                                                                        return age + (index === (transferDetail.childrenAges.length - 1) ? "" : ",");
                                                                    })
                                                                }
                                                                )
                                                            </>
                                                        }
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="mybook-transfer-option">
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                                                        <span>{formatDateTime(transferDetail.outbound.date, cultureName, { withTime: true })}</span>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                                                        <span>{transferDetail.outbound.from}</span>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                                                        <span>{transferDetail.outbound.to}</span>
                                                    </div>
                                                </div>
                                                {
                                                    transferDetail.return !== undefined && transferDetail.return !== null
                                                        ? (
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                                                                    <span>{formatDateTime(transferDetail.return.date, cultureName, { withTime: true })}</span>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                                                                    <span>{transferDetail.return.from}</span>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                                                                    <span>{transferDetail.return.to}</span>
                                                                </div>
                                                            </div>
                                                        )
                                                        : (<></>)
                                                }
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 right">
                                                        <h2 className="accordion-header " id="heading1c">
                                                            <a className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={'#pannelTransfer' + id} aria-expanded="true" aria-controls={'pannelTransfer' + id}>
                                                                <span className="icon-title h6 mb-0 font-weight-bold text-right w-100">{t("MyBook:DetailPassengerInfo")}</span>
                                                            </a>
                                                        </h2>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div id={'pannelTransfer' + id} className="accordion-collapse collapse" role="region" aria-labelledby="heading1c">
                                                            <div className="accordion-body">
                                                                {/*Passengers*/}
                                                                {
                                                                    (type === 'MyBook' || type === 'QuotationTO_MyBook')
                                                                        ? <div className="pt-3">
                                                                            <div>{t("MyBook:Passengers")}:</div>
                                                                            {
                                                                                transferDetail.passengers.map(passenger =>
                                                                                    <Passenger key={passenger.index} passenger={passenger} />
                                                                                )
                                                                            }
                                                                        </div>
                                                                        : <></>
                                                                }
                                                                <div className="pt-3">
                                                                    <h6>{t("MyBook:Vehicle")}:</h6>
                                                                    <p className="my-1">
                                                                        <span><label>{t("MyBook:VehicleType")}</label> <span>{transferDetail.vehicle.type}</span></span>
                                                                        <span><label>{t("MyBook:MinCapacity")}:</label> <span>{transferDetail.vehicle.minCapacity ?? "N/A"}</span></span>
                                                                        <span><label>{t("MyBook:MaxCapacity")}:</label> <span>{transferDetail.vehicle.maxCapacity ?? "N/A"}</span></span>
                                                                        <span><label>{t("MyBook:MaxStops")}:</label> <span>{transferDetail.vehicle.maxStops ?? "N/A"}</span></span>
                                                                        <span><label>{t("MyBook:NumberBags")}:</label> <span>{transferDetail.vehicle.numberOfBags ?? "N/A"}</span></span>
                                                                    </p>

                                                                    {
                                                                        transferDetail.flightInfo !== undefined && transferDetail.flightInfo !== null
                                                                            ? (
                                                                                <>
                                                                                    <h6>{t("MyBook:FlightsDataArrival")}</h6>
                                                                                    <p className="my-1">
                                                                                        <span><label>{t("MyBook:Flight")}:</label> <span>{transferDetail.flightInfo.outboundFlightNumber}</span></span>
                                                                                        <span><label>{t("MyBook:StartHour")}:</label> <span>{transferDetail.flightInfo.outboundFlightDepartureTime}</span></span>
                                                                                        <span><label>{t("MyBook:EndHour")}:</label> <span>{transferDetail.flightInfo.outboundFlightArrivalTime}</span></span>
                                                                                    </p>
                                                                                    <h6>{t("MyBook:FlightsDataDeparture")}</h6>
                                                                                    <p className="my-1">
                                                                                        <span><label>{t("MyBook:Flight")}:</label> <span>{transferDetail.flightInfo.returnFlightNumber}</span></span>
                                                                                    </p>
                                                                                </>
                                                                            )
                                                                            : (<></>)
                                                                    }

                                                                    {
                                                                        transferDetail.note && <>
                                                                            <h6>Note</h6>
                                                                            <p className="my-1">
                                                                                {transferDetail.note}
                                                                            </p>
                                                                        </>
                                                                    }
                                                                    {
                                                                        <ServiceNote notes={transferDetail.notesService} noteTypeToShow={[12]} />
                                                                    }
                                                                    {
                                                                        <ServiceNote notes={transferDetail.notesService} noteTypeToShow={[13]} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/*Price Bar*/}
                                        <PriceBar
                                            oPriceBar={transferDetail.priceBar}
                                            showNetPrices={showNetPrices}
                                            showCost={showCost}
                                            reservationDetail={transferDetail.reservationDetail}
                                            quotationDetail={transferDetail.quotationDetail}
                                            quotationMode={customFunctions.quotationMode}
                                            customView={{ notShowPaymentAvailable: (type === 'QuotationTO' || type === 'QuotationTO_MyBook') }}
                                            isEnabledUpdateStaticData={isEnabledUpdateStaticData}
                                            onClickOpenUpdateStaticData={onClickOpenUpdateStaticData} />
                                    </div>
                                </div>
                            </div>

                            {/*Modals*/}
                            {type === 'MyBook' && <CancelReservationModal pnr={id} productType={configData.Settings.Products.Transfer.IdTipoProdotto} />}
                            {type === 'MyBook' && transferDetail.priceBar.paymentData !== null && <PayWithCreditCardModal pnr={id} productType={configData.Settings.Products.Transfer.IdTipoProdotto} paymentData={transferDetail.priceBar.paymentData} />}
                            {isEnabledUpdateStaticData && <UpdateTransferData
                                modalId={modalId}
                                quotationItemId={id}
                                saveProductData={saveProductData}
                                resetValue={resetValue} />}
                        </>
                    )
            }
        </>
    );
}