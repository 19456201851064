import React, { useEffect, useState } from 'react';
import configData from "../../appsettings.json";
import { handleError } from "../../js/Utils";
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';

export const GenericManual = (props) => {
    const { t } = useTranslation();
    const genericDetailDefault = {
        id: -1,
        productType: configData.Settings.Products.Generic.IdTipoProdotto,

        idTipoProdotto: 'NOT',
        idProdotto: 'NOT',

        providerId: 'NOT',

        manualName: '',
        detailProduct: '',
        internalNote: '',

        invoiceAmount: null,
        buyPriceAmount: null,
        buyPriceCurrency: 'EUR',
        buyPriceChange: null,

        isSelected: true,
        commissionable: false
    }

    const validateGenericDefault = {
        isValidIdTipoProdotto: false,
        isTouchedIdTipoProdotto: false,

        isValidIdProdotto: false,
        isTouchedIdProdotto: false,

        isTouchedBuyPriceAmount: false,
        isValidBuyPriceAmount: false,

        isTouchedInvoiceAmount: false,
        isValidInvoiceAmount: false,

        isValidForm: false
    }

    const [quotationAddictionalProductsManual, setQuotationAddictionalProductsManual] = useState([]);
    const [quotationTipoProdotto, setQuotationTipoProdotto] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [suppliers, setSuppliers] = useState([]);

    const [genericDetail, setGenericDetail] = useState({ ...genericDetailDefault });
    const [validateInputData, setValidateInputData] = useState({ ...validateGenericDefault });

    useEffect(() => {
        if (props.isOpen) {
            if (currencies.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}constant/getcurrency`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setCurrencies(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (suppliers.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}customer/getallsupplierforproduct/${configData.Settings.Products.Generic.IdTipoProdotto}`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setSuppliers(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (quotationAddictionalProductsManual.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getquotationaddictionalproductsmanual/${props.quotationId}`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {

                        setQuotationAddictionalProductsManual(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (quotationTipoProdotto.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/getrelatedproducttypes`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setQuotationTipoProdotto(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (!props.item) {
                setGenericDetail({ ...genericDetailDefault });
                setValidateInputData({ ...validateGenericDefault });
            }
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (!props.item) {
            setGenericDetail({ ...genericDetailDefault });
            setValidateInputData({ ...validateGenericDefault });
        } else if (props.item.productType === configData.Settings.Products.Generic.IdTipoProdotto) {
            setGenericDetail(props.item);

            let isValidAD = { ...validateGenericDefault };
            Object.keys(isValidAD).forEach(key => {
                isValidAD[key] = true;
            });

            setValidateInputData(isValidAD);
        }
    }, [props.item]);

    function onBlurEvent(event, propertyName) {

        let updateGenericDetail = { ...genericDetail };

        let newValue = '';
        if (propertyName === 'idTipoProdotto' || propertyName === 'idProdotto' || propertyName === 'buyPriceCurrency' || propertyName === 'providerId') {
            newValue = event;
            if (!newValue)
                newValue = event.key;
        } else {
            newValue = event.target.value;
        }

        if (propertyName === 'buyPriceChange' && !newValue) {
            newValue = null;
        }

        if (propertyName === 'buyPriceCurrency') {
            let exchangeRate = 1;
            if (currencies && currencies.length > 0) {
                let currency = currencies.find(element => element.value === newValue);
                if (currency)
                    exchangeRate = currency.rate;
            }

            updateGenericDetail['buyPriceChange'] = exchangeRate;
        }

        updateGenericDetail[propertyName] = newValue;
        setGenericDetail(updateGenericDetail);

        checkValidateInputData(propertyName, newValue);
    }

    function checkValidateInputData(propertyName, value) {
        let isValidForm = false;
        let prevValidateInputData = { ...validateInputData };
        delete prevValidateInputData.isValidForm;

        const isNotEmpty = (value !== '' && value !== undefined);
        const isSelectedOption = value !== 'NOT';

        switch (propertyName) {
            case 'idProdotto': {
                delete prevValidateInputData.isValidIdProdotto;
                delete prevValidateInputData.isTouchedIdProdotto;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidIdProdotto: isSelectedOption, isTouchedIdProdotto: true });
                break;
            }
            case 'idTipoProdotto': {
                delete prevValidateInputData.isValidIdTipoProdotto;
                delete prevValidateInputData.isTouchedIdTipoProdotto;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidIdTipoProdotto: isSelectedOption, isTouchedIdTipoProdotto: true });
                break;
            }
            case 'providerId': {
                delete prevValidateInputData.isValidProviderId;
                delete prevValidateInputData.isTouchedProviderId;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidProviderId: isNotEmpty, isTouchedProviderId: true });
                break;
            }
            case 'buyPriceAmount': {
                delete prevValidateInputData.isValidBuyPriceAmount;
                delete prevValidateInputData.isTouchedBuyPriceAmount;

                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidBuyPriceAmount: isNotEmpty, isTouchedBuyPriceAmount: true });
                break;
            }
            case 'invoiceAmount': {
                delete prevValidateInputData.isValidInvoiceAmount;
                delete prevValidateInputData.isTouchedInvoiceAmount;

                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidInvoiceAmount: isNotEmpty, isTouchedInvoiceAmount: true });
                break;
            }
            default:
                break;
        }
    }

    function saveGenericManual() {
        props.callAddManualQuotationItem(genericDetail);
    }

    function deleteGenericManual() {
        props.callDeleteManualQuotationItem(genericDetail);
    }

    return (
        <div className="p-4">
            <div className="row">
                <h4>Prodotto Correlato</h4>
                <div className="col-md-3">
                    <div className={"form-floating" + (!validateInputData.isValidProductSubType ? " insert-danger" : "")}>

                        <span className="p-float-label form-select-label-m3-react-prime">
                            {
                                quotationTipoProdotto && quotationTipoProdotto.length > 0 && <Dropdown value={genericDetail.idTipoProdotto}
                                    onChange={(e) => onBlurEvent(e.value, 'idTipoProdotto')}
                                    inputId="dd-tipoProdotto"
                                    filter
                                    className="w-100 form-select-m3-react-prime"
                                    options={quotationTipoProdotto.map(x => { return { label: x.name, value: x.idTipoProdotto }; })}
                                    optionLabel="label" />
                            }
                            <label htmlFor="dd-tipoProdotto">Tipo *{quotationTipoProdotto.idTipoProdotto}</label>
                        </span>
                        {
                            validateInputData.isTouchedIdTipoProdotto && !validateInputData.isValidIdTipoProdotto && <div className="text-danger">Il campo tipo è obbligatorio</div>
                        }
                    </div>
                </div>
                <div className="col-md-3">
                    <div className={"form-floating" + (!validateInputData.isValidProductSubType ? " insert-danger" : "")}>

                        <span className="p-float-label form-select-label-m3-react-prime">
                            {
                                quotationAddictionalProductsManual && quotationAddictionalProductsManual.length > 0 && <Dropdown value={genericDetail.idProdotto}
                                    onChange={(e) => onBlurEvent(e.value, 'idProdotto')}
                                    inputId="dd-idProdotto"
                                    filter
                                    className="w-100 form-select-m3-react-prime"
                                    options={quotationAddictionalProductsManual.map(x => { return { label: x.name, value: x.idProdotto } })}
                                    optionLabel="label" />
                            }
                            <label htmlFor="dd-idProdotto">Prodotto *</label>
                        </span>
                        {
                            validateInputData.isTouchedIdProdotto && !validateInputData.isValidIdProdotto && <div className="text-danger">Il campo prodotto è obbligatorio</div>
                        }
                    </div>
                </div>
                <div className="col-md-3">
                    <div className={"form-floating" + (!validateInputData.isValidProviderId ? " insert-danger" : "")}>
                        <span className="p-float-label form-select-label-m3-react-prime">
                            {
                                suppliers && suppliers.length > 0 && <Dropdown value={genericDetail.providerId}
                                    onChange={(e) => onBlurEvent(e.value, 'providerId')}
                                    inputId="dd-provider"
                                    filter
                                    className="w-100 form-select-m3-react-prime"
                                    options={suppliers}
                                    optionLabel="label" />
                            }
                            <label htmlFor="dd-provider">Fornitore *</label>
                        </span>
                        {
                            validateInputData.isTouchedProviderId && !validateInputData.isValidProviderId && <div className="text-danger">Il campo fornitore è obbligatorio</div>
                        }
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-floating">
                        <input type="text" className="form-control" value={genericDetail.manualName} id="floatingInput" placeholder="Nome" onChange={(e) => { onBlurEvent(e, 'manualName'); }} />
                        <label htmlFor="floatingInput" className="active">Nome</label>
                    </div>
                </div>
            </div>
            <div className="row pt-4">
                <div className="form-floating pt-2">
                    <textarea className="form-control"
                        placeholder="Descrizione"
                        value={genericDetail.detailProduct}
                        onChange={(e) => { onBlurEvent(e, 'detailProduct'); }}
                        id="floatingTextarea2"
                        style={{ height: '100px' }} ></textarea>
                    <label htmlFor="floatingTextarea2">Descrizione</label>
                </div>
                <div className="form-floating pt-2">
                    <textarea className="form-control"
                        placeholder="Note Interne"
                        value={genericDetail.internalNote}
                        onChange={(e) => { onBlurEvent(e, 'internalNote'); }}
                        id="floatingTextarea2"
                        style={{ height: '100px' }} ></textarea>
                    <label htmlFor="floatingTextarea2">Note Interne</label>
                </div>
            </div>
            <div className="row pt-4">
                <div class="badge-gray-600 mt-4">Opzione</div>
                <div className={"form-floating col-md-2" + (!validateInputData.isValidBuyPriceAmount ? " insert-danger" : "")}>
                    <input type="number" className="form-control" value={genericDetail.buyPriceAmount === null ? '' : genericDetail.buyPriceAmount} id="floatingInput" placeholder="123.45" onChange={(e) => { onBlurEvent(e, 'buyPriceAmount'); }} />
                    <label htmlFor="floatingInput" className="active">Costo *</label>
                    {
                        validateInputData.isTouchedBuyPriceAmount && !validateInputData.isValidBuyPriceAmount && <div className="error-input">Il campo costo è obbligatorio</div>
                    }
                </div>
                <div className="col-md-2">
                    <div className="form-floating">
                        <span className="p-float-label form-select-label-m3-react-prime">
                            {
                                currencies && currencies.length > 0 && <Dropdown value={genericDetail.buyPriceCurrency}
                                    onChange={(e) => onBlurEvent(e.value, 'buyPriceCurrency')}
                                    inputId="dd-buyPriceCurrency"
                                    filter
                                    className="w-100 form-select-m3-react-prime"
                                    options={currencies}
                                    optionLabel="label" />
                            }
                            <label htmlFor="dd-buyPriceCurrency">Valuta</label>
                        </span>
                    </div>
                </div>
                <div className="form-floating col-md-2">
                    <input type="number" className="form-control" id="floatingInput" placeholder="123.45" value={genericDetail.buyPriceChange === null ? '' : genericDetail.buyPriceChange} onChange={(e) => { onBlurEvent(e, 'buyPriceChange'); }} />
                    <label htmlFor="floatingInput" className="active">Tasso Cambio </label>
                </div>
                <div className={"form-floating col-md-2" + (!validateInputData.isValidInvoiceAmount ? " insert-danger" : "")}>
                    <input type="number" className="form-control" value={genericDetail.invoiceAmount === null ? '' : genericDetail.invoiceAmount} id="floatingInput" placeholder="123.45" onChange={(e) => { onBlurEvent(e, 'invoiceAmount'); }} />
                    <label htmlFor="floatingInput" className="active">Prezzo di Vendita *</label>
                    {
                        validateInputData.isTouchedInvoiceAmount && !validateInputData.isValidInvoiceAmount && <div className="error-input">Il campo costo è obbligatorio</div>
                    }
                </div>
            </div>
            <div className=" float-end">
                {props.isError && <span className="text-danger">Errore in fase di inserimento, controllare i dati</span>}
                <button className="btn tp-btn-delete btn-sm mr-2" onClick={deleteGenericManual}>Cancella</button>
                <button className="btn tp-btn-add btn-sm" disabled={!validateInputData.isValidForm} onClick={saveGenericManual}>{t('Button:Save')}</button>
            </div>
        </div>
    );
}