import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { Loading } from '../Common/Loading';
import { getAuthToken, getCurrentUserFromJwtToken, formatDateTime, formatPrice } from '../../js/Utils.js';
import { useInterval } from '../../js/useInterval.js';
import { CartScadenziario } from './CartScadenziario';
import configData from "../../appsettings.json";
import { M3IconProductType } from '../Common/M3IconProductType.js';
import { M3Icon } from '../Common/M3Icon';
import { ProductNavBar } from '../Product/SearchEngine/ProductNavBar';

export const CartConfirm = () => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");
    const { t } = useTranslation();


    let { orderNumber } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [username, setUsername] = useState();
    const [orderOutcome, setOrderOutcome] = useState([]);


    useEffect(() => {
        const getOrderOutcome = async () => {
            callGetOrderOutcome();
        }

        let currentUser = getCurrentUserFromJwtToken(getAuthToken());
        if (currentUser.name !== null && currentUser.name !== undefined)
            setUsername(currentUser.name.split(' ')[0]);

        if (cookies.get("orderNumber")) {
            cookies.remove("orderNumber", { path: "/" });
        }

        getOrderOutcome();
    }, []);


    async function callGetOrderOutcome() {
        const requestOption = { method: 'GET', credentials: 'include', headers: { 'Content-Type': 'application/json' } };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'ShoppingCart/GetOrderOutcome/' + orderNumber, requestOption);
        const response = await fetchedRes.json();

        setIsLoading(false);
        setOrderOutcome(response);
        return response;
    }


    const fnCondition = (response) => { return response.orderStatus === "P"; };
    useInterval(() => callGetOrderOutcome(), fnCondition, 3000);


    const renderStatus = (pratica) => {
        switch (pratica.status) {
            case "CONFIRMED":
                return (<><svg className="bi flex-shrink-0 me-2 status confirm" width="16" height="16" role="img" aria-label="Success:"><use xlinkHref="#check-circle-fill" /></svg><label>{t("MyBook:Confirmed")}</label></>);

            case "PRINTING":
                return (<><svg className="bi flex-shrink-0 me-2 status confirm" width="16" height="16" role="img" aria-label="Success:"><use xlinkHref="#check-circle-fill" /></svg><label>{t("statiPrenotazione:PRINTING")}</label></>);

            case "PRINTED":
                return (<><svg className="bi flex-shrink-0 me-2 status confirm" width="16" height="16" role="img" aria-label="Success:"><use xlinkHref="#check-circle-fill" /></svg><label>{t("MyBook:Emited")}</label></>);

            case "ONREQUEST":
                return (<><svg className="bi flex-shrink-0 me-2 status onrequest" width="16" height="16" role="img" aria-label="Danger:"><use xlinkHref="#exclamation-triangle-fill" /></svg><label>{t("MyBook:ONRequest")}</label></>);

            case "OPTION":
                return (<><svg className="bi flex-shrink-0 me-2 status onrequest" width="16" height="16" role="img" aria-label="Success:"><use xlinkHref="#check-circle-fill" /></svg><label>Opzionata</label></>);

            case "SOLDOUT":
            case "ERROR":
                return (<><svg className="bi flex-shrink-0 me-2 status manage" width="16" height="16" role="img" aria-label="Danger:"><use xlinkHref="#exclamation-triangle-fill" /></svg><label>{t("MyBook:ONManage")}</label></>);

            //case "MANAGE":
            default:
                return orderOutcome.orderStatus === "P"
                    ? (<><img src="https://cdn.travelplace.ch/common/images/general/ajax-loader.gif" alt="Loading..." /></>)
                    : (<><svg className="bi flex-shrink-0 me-2 status manage" width="16" height="16" role="img" aria-label="Danger:"><use xlinkHref="#exclamation-triangle-fill" /></svg><label>{t("MyBook:ONManage")}</label></>);
        }
    }

    const renderLegenda = (pratiche) => {
        let statusManage = (orderOutcome && orderOutcome.orderStatus !== "P") && pratiche.findIndex(x => x.status !== "CONFIRMED" && x.status !== "PRINTING" && x.status !== "PRINTED" && x.status !== "OPTION" && x.status !== "ONREQUEST") > -1;
        let statusOnRequest = pratiche.findIndex(x => x.status === "ONREQUEST") > -1;
        let statusSuspended = pratiche.findIndex(x => x.status === "SUSPENDED") > -1;
        let statusPrinting = pratiche.findIndex(x => x.status === "PRINTING") > -1;

        return (
            <>
                {statusManage
                    ? (
                        <div className="status bg-manage  rounded m-1 p-2" style={{ fontSize: '0.75rem' }}>
                            <b>{t("MyBook:ONManage")}:</b> {t("MyBook:ONManageMsg")}
                        </div>
                    )
                    : (<></>)
                }

                {statusOnRequest
                    ? (
                        <div className="status bg-onrequest rounded m-1 p-2" style={{ fontSize: '0.75rem' }}>
                            <b>{t("MyBook:ONRequest")}:</b> {t("MyBook:ONRequestMsg")}
                        </div>
                    )
                    : (<></>)
                }

                {statusSuspended
                    ? (
                        <div className="status bg-sospended rounded m-1 p-2 xx-small" style={{ fontSize: '0.75rem' }}>
                            <b>{t("MyBook:Sospended")}:</b> {t("MyBook:SospendedMsg")}
                        </div>
                    )
                    : (<></>)
                }

                {statusPrinting
                    ? (
                        <div className="status bg-primary rounded m-1 p-2 xx-small" style={{ fontSize: '0.75rem' }}>
                            <b>{t("MyBook:Printing")}:</b> {t("MyBook:PrintingMsg")}
                        </div>
                    )
                    : (<></>)
                }
            </>
        );
    }


    return (
        <>
            {configData.Settings.Website.EngineType === 'WITHNAVBAR' && <ProductNavBar />}

            {/*SVG Icons*/}
            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
                <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </symbol>
                <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                </symbol>
                <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                </symbol>
            </svg>

            {/*Body*/}
            <div className="container basket  pb-4">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <nav className="nav nav-fill mt-4">
                            <div className="nav-link basket step" href="#">{t("MyBook:Summary")}</div>
                            <a className="nav-link disabled" href="#"><div className="solid-line"></div></a>
                            <a className="nav-link disabled" href="#">{t("MyBook:Passengers")}</a>
                            <a className="nav-link disabled" href="#"><div className="solid-line"></div></a>
                            <a className="nav-link" href="#" tabIndex="-1" aria-disabled="true">{t("Button:Confirm")}</a>
                        </nav>

                        {/*Reservations*/}
                        <div className="basket-card  card mb-4 p-3">
                            <h4>{t("MyBook:ThankU")}&nbsp; {username}</h4>
                            <h6>{t("MyBook:CartConfirm")}&nbsp;  {orderNumber}</h6>
                            <div style={{ fontSize: '0.75rem' }}>
                                {t("MyBook:CartConfirmText")}&nbsp; <a href={'/mybook/orders/detail/' + orderNumber} target="_blank">"Ordini"</a>.
                            </div>

                            <div>
                                <div className="pt-4 px-3 basket">
                                    <M3Icon iconName="Signpost" externalClass="h150 text-white p-1 mr-1 bg-gray-400 rounded-circle" />
                                    <span className="basket titlecard">{t("MyBook:Travel")}</span>
                                </div>
                                <div className=" basketTable pt-0">
                                    <div className="border rounded border-gray-500 pt-1 pb-0">
                                        <div className="row basketTable titletable mx-0 rounded-top ">
                                            <div className="col-1 col-sm-4 col-md-12 col-lg-1 col-xl-1 text-center">{t("MyBook:Type")}</div>
                                            <div className="col-7 col-sm-4 col-md-12 col-lg-1 col-xl-5">{t("MyBook:Product")}</div>
                                            <div className="col-3 col-sm-4 col-md-12 col-lg-1 col-xl-2">{t("MyBook:State")}</div>
                                            <div className="col-4 col-sm-4 col-md-12 col-lg-1 col-xl-1">{t("MyBook:Start")}</div>
                                            <div className="col-4 col-sm-4 col-md-12 col-lg-1 col-xl-1">{t("MyBook:End")}</div>
                                            <div className="col-4 col-sm-4 col-md-12 col-lg-1 col-xl-2 text-right">€</div>
                                        </div>

                                        {isLoading
                                            ? (<Loading textMsg={t('MyBook:Loading')} />)
                                            : (
                                                <>
                                                    {orderOutcome !== null && orderOutcome !== undefined &&
                                                        orderOutcome.order !== null && orderOutcome.order !== undefined &&
                                                        orderOutcome.order.pratiche !== null && orderOutcome.order.pratiche !== undefined && orderOutcome.order.pratiche.length > 0
                                                        ? (
                                                            <>
                                                                {orderOutcome.order.pratiche.map(pratica =>
                                                                    <div key={pratica.idPratica} idpratica={pratica.idPratica} className="row border-bottom border-gray-300 border mx-1">
                                                                        <div className="col-2 col-sm-4 col-md-12 col-lg-1 col-xl-1 text-center">
                                                                            {<M3IconProductType productType={pratica.tipoPratica} />}
                                                                        </div>
                                                                        <div className="col-7 col-sm-4 col-md-12 col-lg-1 col-xl-5">
                                                                            {pratica.productName}
                                                                        </div>
                                                                        <div className="col-3 col-sm-4 col-md-12 col-lg-1 col-xl-2">
                                                                            {renderStatus(pratica)}
                                                                        </div>
                                                                        <div className="col-4 col-sm-4 col-md-12 col-lg-1 col-xl-1">
                                                                            {formatDateTime(pratica.startDate, cultureName, { noYear: true })}
                                                                        </div>
                                                                        <div className="col-4 col-sm-4 col-md-12 col-lg-1 col-xl-1">
                                                                            {formatDateTime(pratica.endDate, cultureName, { noYear: true })}
                                                                        </div>
                                                                        <div className="col-4 col-sm-4 col-md-12 col-lg-1 col-xl-2 text-right">
                                                                            <b>{formatPrice(pratica.prezzoCliente, pratica.currency, cultureName)}</b>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )
                                                        : (<></>)
                                                    }
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*Legenda*/}
                        {!isLoading &&
                            orderOutcome !== null && orderOutcome !== undefined &&
                            orderOutcome.order !== null && orderOutcome.order !== undefined &&
                            orderOutcome.order.pratiche !== null && orderOutcome.order.pratiche !== undefined && orderOutcome.order.pratiche.length > 0
                            ? (renderLegenda(orderOutcome.order.pratiche))
                            : (<></>)
                        }

                        {/*Scadenziario*/}
                        <CartScadenziario orderNumber={orderNumber} />

                        {/*Buttons*/}
                        <div className="my-3">
                            <button className="float-start  text-left btn btn-white  border-2 border-gray-600 rounded" onClick={() => window.open("mybook/orders", "_blank")}>
                                <M3Icon iconName="MyBook" externalClass="h100 text-white p-1 mr-1 bg-gray-400 rounded-circle" /> {t("MyBook:GoToMyBook")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
