import React from 'react';

export const Map = ({ longitude, latitude }) => {

    return (
        <>
            <div>
                {/*attenzione AIzaSyBHNwL7t9D9vlEEZD0EBDSogHqD6EJLZY0 chiave di mindcube */}
                <iframe className="map rounded" id="gmap_canvas" src={`https://www.google.com/maps/embed/v1/view?key=AIzaSyBHNwL7t9D9vlEEZD0EBDSogHqD6EJLZY0&center=${latitude},${longitude}&zoom=15`} ></iframe>
            </div>
        </>
    );
}
