import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { formatPrice, formatDateTime } from '../../../js/Utils.js';
import { useTranslation } from 'react-i18next';
import { M3Icon } from '../../Common/M3Icon';

export const CancelPolicyModal = ({ cancellationPolicy, autoOpen }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const { t } = useTranslation();

    const [idModal] = useState(getRandomInt());
    function getRandomInt() {
        return Math.floor(Math.random() * 1000000);
    }
    const modalId = "CancelPolicyDetail_" + idModal;

    const [penalties, setPenalties] = useState(null);
    useEffect(() => {

        if (cancellationPolicy && cancellationPolicy.penalties) {
            let updPenalties = cancellationPolicy.penalties.map(p => {

                let myPenalty = {
                    startDate: p.startDate
                };

                if (p.percent)
                    myPenalty.value = p.percent + " %";
                if (p.amount)
                    myPenalty.value = formatPrice(p.amount, p.currency, cultureName);

                return myPenalty;
            });

            setPenalties(updPenalties);
        }

    }, [cancellationPolicy]);

    useEffect(() => {

        if (autoOpen && penalties)
            document.getElementById("btn_" + modalId).click();

    }, [penalties]);

    function openModal(event) {
        event.stopPropagation();
        document.getElementById("btn_" + modalId).click();
    }

    return (
        <>
            {
                penalties && <>
                    {
                        !autoOpen && <span onClick={e => { openModal(e) }}>
                            <M3Icon iconName="Info" externalClass="icon-10 text-dark cursor-pointer" />
                        </span>
                    }
                    <a id={"btn_" + modalId} data-bs-toggle="modal" data-bs-target={"#" + modalId}></a>
                    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby={modalId + "_Label"} aria-hidden="true">
                        <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h6 className="modal-title" id={modalId + "_Label"}>
                                        <data m3lab="Template:CancelPolicyDetailPenalties">
                                            {t("Template:CancelPolicyDetailPenalties")}
                                        </data>
                                    </h6>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row mb-2 border-bottom border-light pb-1 text-dark">
                                        <div className="col-4">
                                            {t('MyBook:ExpirationDate')}
                                        </div>
                                        <div className="col-3">
                                            {t('MyBook:Value')}
                                        </div>
                                    </div>
                                    {
                                        penalties.map((penalty, key) => {
                                            return <div key={key} className="row mb-1 text-dark">
                                                <div className="col-4">
                                                    {formatDateTime(penalty.startDate, cultureName, { withTime: true })}
                                                </div>
                                                <div className="col-3">
                                                    {penalty.value}
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-gray-300" data-bs-dismiss="modal">
                                        <data m3lab="Button.Chiudi">
                                            {t("Button:Chiudi")}
                                        </data>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}