import React from 'react';
import configData from "../../appsettings.json";

export const Loading = ({ textMsg, isSmall }) => {
    return (
        <>
            {!isSmall &&
                <div className="loading text-center">
                    <img src={`${configData.Settings.CDN_Custom}/images/general/waiting-page.gif`} alt="Loading" />
                    <h6>{textMsg}</h6>
                </div>
            }

            {isSmall &&
                <div className="text-center">
                    <div className="spinner-border spinner-border-sm text-dark" role="status"></div>
                    <span className="sr-only">{textMsg}</span>
                </div>
            }
        </>
    );
}
