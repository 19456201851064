import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice, formatDateTime } from '../../../../js/Utils.js';
import { isValidGiftCard, isUsedGiftCard } from '../utils/giftCardUtil';

export const GiftCard = (props) => {
    const { t } = useTranslation();

    const isExpired = () => {
        if (!props.giftCard.expiresOn)
            return false;

        let today = new Date();
        today.setHours(0, 0, 0, 0);

        let isExpired = today.getTime() > new Date(props.giftCard.expiresOn).getTime();
        return isExpired;
    }

    const getExpiresOnDate = () => {
        if (!props.giftCard.expiresOn)
            return "--";

        let formattedDate = formatDateTime(props.giftCard.expiresOn, props.cultureName, { twoDigits: true });

        if (isExpired()) {
            formattedDate += " (expired)";
        }

        return formattedDate;
    }

    return (
        <>
            <li className="list-group-item border-bottom">
                <div className="row align-items-center">
                    <div className={`col-auto icon icon-shape icon-md rounded-circle mr-3 mb-4 mb-md-0 ${isValidGiftCard(props.giftCard) ? "icon-shape-primary" : "icon-shape-light"}`}><span className="fas fa-wallet"></span></div>
                    <div className="col ml-n2">
                        <h6 className="text-sm mb-0">{t(`giftCard:GiftCardTotal`)} {props.giftCard.code}</h6>
                        <p className="card-text small text-muted status print">{t("MyBook:GiftCreated")}  {formatDateTime(props.giftCard.created, props.cultureName)}</p>
                    </div>
                    <div className="col-md d-none d-md-block">
                        <p className="text-muted my-0"> {t("MyBook:NetValue")}:<b> {formatPrice(props.giftCard.amount, props.giftCard.currency, props.cultureName)} </b></p>
                        <p className={`status small ${!isExpired() ? "confirm" : "delete"}`}>{t("MyBook:ExpirationDate")}: {getExpiresOnDate()}</p>
                    </div>
                    {
                        isUsedGiftCard(props.giftCard) ?
                            <div className="col-md d-none d-md-block mb-1">
                                <p className="text-muted my-0">{t("MyBook:NOrder")}: {props.giftCard.usedOn_OrderNumber}</p>
                                <p className="status small penalty">{t("MyBook:UsedInDate")}: {formatDateTime(props.giftCard.usedOn, props.cultureName, { twoDigits: true })}</p>
                            </div>
                            : <></>
                    }
                </div>
            </li>
        </>
    );
}