import React, { useState } from 'react';

export const AvailableTour = ({ detailUrl, listUrl, quotationId, quotationItemId, deleteFn, changeProductFn, mode }) => {
    const [showBtnAlternativeRoom, setShowBtnAlternativeRoom] = useState(true);

    function onClickDelete() {
        deleteFn();
    }

    const onClickChangeProduct = (urlType) => {
        let url = urlType === "L" ? listUrl : detailUrl;
        if (quotationId && quotationItemId) {
            url = listUrl;
        }

        if (urlType === "D" && !quotationId) {
            setShowBtnAlternativeRoom(false);
            url = null;
        }

        changeProductFn(url, quotationId, quotationItemId);
    }

    const onClickChangeQuotationItem = () => {
        changeProductFn(quotationItemId);
    }

    return (
        <>
            {
                mode === 'QuotationTO' && <div className="allertError mt-4 p-2">
                    <p>Il prodotto non è più disponibile, seleziona una delle seguenti opzioni:</p>
                    <button className="btn btn-sm btn-soft d-inline-block" onClick={onClickChangeQuotationItem}>Camera alternativa</button>
                </div>
            }
            {
                mode === 'CART' &&
                <div className="allertError mt-4 p-2">
                    <p>Il prodotto non è più disponibile</p>
                    {/*{showBtnAlternativeRoom && <button className="btn btn-sm btn-soft d-inline-block" onClick={e => onClickChangeProduct("D")}>Camera alternativa</button>}*/}

                    {/*{(quotationId === null || quotationId === undefined) &&*/}
                    {/*    <>*/}
                    {/*        &nbsp;&nbsp;*/}
                    {/*        <button className="btn btn-sm btn-soft d-inline-block" onClick={e => onClickChangeProduct("L")}>Sostituisci Hotel</button>*/}
                    {/*    </>*/}
                    {/*}*/}
                    &nbsp;&nbsp;<button className="btn btn-sm btn-danger" onClick={onClickDelete}>Elimina</button>
                </div>
            }
        </>
    )
}
