import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { replaceAllStyleElements } from "../../../js/PrintUtility";
import configData from "../../../appsettings.json";
import { getWebsiteId } from '../../../js/Utils.js';

export const RegistrationEndUserForm = ({ handeIsValidForm, handleInputData, cultureCode, isCallDynamicNotesAgency = false, showPromoteCode, mandatoryPromoteCode }) => {
    const { t } = useTranslation();
    const [dynamicNotes, setDynamicNotes] = useState([]);
    const [selectedDynamicNotes, setSelectedDynamicNotes] = useState([]);
    const [selectedDynamicNotesToSend, setSelectedDynamicNotesToSend] = useState([]);

    const [inputData, setInputData] = useState({
        name: '',
        surname: '',
        email: '',
        password: '',
        confirmPassword: '',
        websiteId: getWebsiteId(),
        privacyPolicyDynamicNotes: selectedDynamicNotesToSend,
        cultureCode: cultureCode,
        promoteCode: "",
        profileId: configData.Settings.Login.ProfileId
    });

    const [validateInputData, setValidateInputData] = useState({
        isValidName: false,
        isTouchedName: false,

        isValidSurname: false,
        isTouchedSurname: false,

        isValidEmail: false,
        isTouchedEmail: false,

        isValidPassword: false,
        isTouchedPassword: false,

        isValidPrivacyPolicy: false,
        isTouchedPrivacyPolicy: false,

        isValidConfirmPassword: false,
        isTouchedConfirmPassword: false,

        isValidPromoteCode: !mandatoryPromoteCode,
        isTouchedPromoteCode: !mandatoryPromoteCode,

        isValidForm: false
    });

    useEffect(() => {
        if (validateInputData.isValidForm) {
            onHandleValidForm(false);
            onHandleInputData(inputData);
        }
        else {
            onHandleValidForm(true);
        }
    }, [validateInputData.isValidForm]);

    const [isLoading, setIsLoading] = useState(false);
    const [showTokenPanel, setShowTokenPanel] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const getDynamicNote = async (inputData) => {
            const response = callGetDynamicNote(inputData);
        }

        getDynamicNote({
            cultureCode: cultureCode,
            websiteId: getWebsiteId(),
            pageId: 16, // registrationEndUser
            productTypes: [],
            productCode: null,
            checkInFrom: null,
            checkInTo: null,
            webDestinationIds: null,
            supplierId: null,
        });

        if (isCallDynamicNotesAgency) {
            const getDynamicNote = async (inputData) => {
                const response = callGetDynamicNote(inputData);
            }

            getDynamicNote({
                cultureCode: cultureCode,
                websiteId: getWebsiteId(),
                pageId: 17, // registrationAgency
                productTypes: [],
                productCode: null,
                checkInFrom: null,
                checkInTo: null,
                webDestinationIds: null,
                supplierId: null,
            });
        }
    }, [cultureCode]);


    const onHandleValidForm = useCallback((isDisabled) => {
        handeIsValidForm(isDisabled);
    }, []);

    const onHandleInputData = useCallback((inputData) => {
        handleInputData(inputData);
    }, []);

    function onCheckDynamicNote(noteId, event) {
        let isChecked = event.target.checked;
        let isAllChecked = true;
        let isAllTouched = true;

        let selectedDynamicNotes_ = selectedDynamicNotesToSend;
        if (selectedDynamicNotes_.find(x => x === noteId)) {
            selectedDynamicNotes_ = selectedDynamicNotes_.filter(function (item) {
                return item !== noteId;
            });
            setSelectedDynamicNotesToSend(selectedDynamicNotes_);
        }
        else {
            selectedDynamicNotes_.push(noteId);
            setSelectedDynamicNotesToSend(selectedDynamicNotes_);
        }

        inputData.privacyPolicyDynamicNotes = selectedDynamicNotes_;

        // aggiorno
        let sNotes = [...selectedDynamicNotes];
        let selectedNoteIdx = sNotes.findIndex((x => x.noteId === noteId));
        sNotes[selectedNoteIdx].selected = isChecked;
        sNotes[selectedNoteIdx].touched = true;

        let tmpArr = [];
        selectedDynamicNotes.map(note => {
            tmpArr.push(note.noteId);
        });

        if (areEqual(tmpArr, selectedDynamicNotes_)) {
            isAllChecked = true;
            isAllTouched = true;
        }
        else {
            isAllChecked = false;
            isAllTouched = false;
        }

        // verifico che siano tutte checcate
        /*if (dynamicNotes && dynamicNotes.length > 0) {
            for (let i = 0; i < dynamicNotes.length; i++) {
                let myNote = dynamicNotes[i];

                if (myNote.noteId === noteId && !isChecked) {
                    isAllChecked = false;
                }
                else if (myNote.checkTypeId !== 1 && myNote.noteId !== noteId) {
                    let selectedNoteIdx = sNotes.findIndex((x => x.noteId === noteId));
                    if (selectedNoteIdx >= 0 && !sNotes[selectedNoteIdx].Selected) {
                        isAllChecked = false;
                    }
                }

                if (!isAllChecked)
                    break;

            }

            isAllTouched = Object.values(sNotes).every(item => item.touched);
        }*/

        // verifico che il form sia valido
        let prevValidateInputData = { ...validateInputData };
        delete prevValidateInputData.isValidForm;
        delete prevValidateInputData.isValidPrivacyPolicy;
        delete prevValidateInputData.isTouchedPrivacyPolicy;

        let isValidForm = isAllChecked && Object.values(prevValidateInputData).every(item => item);

        setValidateInputData({
            ...validateInputData,
            isValidForm: isValidForm,
            isValidPrivacyPolicy: isAllChecked,
            isTouchedPrivacyPolicy: isAllTouched
        });
    }

    function areEqual(array1, array2) {
        if (array1.length === array2.length) {
            array1.sort();
            array2.sort();
            return array1.every((element, index) => {
                if (element === array2[index]) {
                    return true;
                }

                return false;
            });
        }

        return false;
    }

    function onBlurEvent(event, propertyName) {
        let updateInputData = { ...inputData };

        let newValue = propertyName === 'privacyPolicy' ? event.target.checked
            : event.target.value;

        updateInputData[propertyName] = newValue;
        let isValidForm = false;
        let prevValidateInputData = { ...validateInputData };
        delete prevValidateInputData.isValidForm;
        const isNotEmpty = (newValue !== '' && newValue !== undefined);

        if (dynamicNotes && dynamicNotes.length === 0) {
            prevValidateInputData.isValidPrivacyPolicy = true;
            prevValidateInputData.isTouchedPrivacyPolicy = true;
        }

        switch (propertyName) {
            case 'name': {
                delete prevValidateInputData.isValidName;
                delete prevValidateInputData.isTouchedName;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidName: isNotEmpty,
                    isTouchedName: true
                });
                break;
            }

            case 'surname': {
                delete prevValidateInputData.isValidSurname;
                delete prevValidateInputData.isTouchedSurname;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidSurname: isNotEmpty,
                    isTouchedSurname: true
                });
                break;
            }

            case 'email': {
                delete prevValidateInputData.isValidEmail;
                delete prevValidateInputData.isTouchedEmail;
                let isValidEmailFormat = isNotEmpty;
                if (isNotEmpty) {
                    const isValidMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/.test(newValue);
                    isValidEmailFormat = isValidEmailFormat && isValidMail;
                }

                isValidForm = isValidEmailFormat && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidEmail: isValidEmailFormat,
                    isTouchedEmail: true
                });
                break;
            }

            case 'password': {
                delete prevValidateInputData.isValidPassword;
                delete prevValidateInputData.isTouchedPassword;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                let isValidPassword = isNotEmpty;
                let isValidConfirmPassword = isNotEmpty;

                if (isNotEmpty) {
                    let isStrongPassword = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(newValue);
                    isValidPassword = isValidPassword && isStrongPassword;

                    if (isValidPassword)
                        isValidConfirmPassword = newValue === updateInputData.password;
                }

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidPassword: isValidPassword,
                    isTouchedPassword: true,
                    isValidConfirmPassword: isValidConfirmPassword
                });
                break;
            }

            case 'confirmPassword': {
                delete prevValidateInputData.isValidConfirmPassword;
                delete prevValidateInputData.isTouchedConfirmPassword;

                const isValidConfirmPassword = isNotEmpty && newValue === updateInputData.password;
                isValidForm = isValidConfirmPassword && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidConfirmPassword: isValidConfirmPassword,
                    isTouchedConfirmPassword: true
                });
                break;
            }

            case 'promoteCode': {
                delete prevValidateInputData.isValidPromoteCode;
                delete prevValidateInputData.isTouchedPromoteCode;

                if (mandatoryPromoteCode) {
                    isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                    setValidateInputData({
                        ...validateInputData,
                        isValidForm: isValidForm,
                        isValidPromoteCode: isNotEmpty,
                        isTouchedPromoteCode: true
                    });
                    break;
                }

            }
        }

        setInputData(updateInputData);
    }

    var tmpDynamicNotes = [];
    async function callGetDynamicNote(inputData) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'dynamicnote/GetDynamicNotesForWebsite', requestOption);
        const response = await fetchedRes.json();

        if (response.success) {
            if (response.dynamicNotes != null && response.dynamicNotes.length > 0) {
                response.dynamicNotes.map(note => {
                    tmpDynamicNotes.push(note);
                });

                setDynamicNotes(tmpDynamicNotes);

                let dynamicNotesSelected = selectedDynamicNotes;
                if (response.dynamicNotes && response.dynamicNotes.length > 0) {

                    for (let i = 0; i < response.dynamicNotes.length; i++) {
                        let dn = response.dynamicNotes[i];
                        if (dn.checkTypeId === 3) { // mandatory
                            dynamicNotesSelected.push({
                                noteId: dn.noteId,
                                selected: false,
                                touched: false
                            });
                        }
                    }
                    setSelectedDynamicNotes(dynamicNotesSelected);
                }

                if (dynamicNotesSelected.length === 0) {
                    setValidateInputData({
                        ...validateInputData,
                        isValidPrivacyPolicy: false
                    });
                }
            }
        }
        else {
            setIsError(true);
        }
    }

    return (
        <>
            {
                !showTokenPanel && <div className="row mt-2">
                    <div className={"col-md-6 mb-3 text-left" + (!validateInputData.isValidName ? " insert-danger" : "")}>
                        {t("Login:Name")} *
                        <input type="text"
                            className="form-control"
                            //placeholder={t("Login:Name") + " *"}
                            aria-label="Nome"
                            onChange={e => onBlurEvent(e, 'name')}
                        />
                        {
                            validateInputData.isTouchedName && !validateInputData.isValidName && <div className="text-danger mt-1">{t('Login:FieldNameIsMandatory')}</div>
                        }
                    </div>
                    <div className={"col-md-6 mb-3 text-left" + (!validateInputData.isValidSurname ? " insert-danger" : "")}>
                        {t("Login:Surname")} *
                        <input type="text"
                            className="form-control"
                            //placeholder={t("Login:Surname") + " *"}
                            aria-label="Cognome"
                            onChange={e => onBlurEvent(e, 'surname')}
                        />
                        {
                            validateInputData.isTouchedSurname && !validateInputData.isValidSurname && <div className="text-danger mt-1">Il campo cognome è obbligatorio</div>
                        }
                    </div>
                    <div className={"col-md-12 mb-3 text-left" + (!validateInputData.isValidEmail ? " insert-danger" : "")}>
                        Email *
                        <input type="text"
                            className="form-control"
                            //placeholder="Email *"
                            aria-label="Email"
                            onChange={e => onBlurEvent(e, 'email')}
                        />
                        {
                            validateInputData.isTouchedEmail && !validateInputData.isValidEmail && <div className="text-danger mt-1">Il campo email è obbligatorio</div>
                        }
                    </div>
                    <div className={"col-md-6 mb-3 text-left" + (!validateInputData.isValidPassword ? " insert-danger" : "")}>
                        Password *
                        <input type="password"
                            className="form-control"
                            //placeholder="Password *"
                            aria-label="Password"
                            onChange={e => onBlurEvent(e, 'password')}
                        />
                    </div>
                    <div className={"col-md-6 mb-3 text-left" + (!validateInputData.isValidConfirmPassword ? " insert-danger" : "")}>
                        {t("Login:ConfirmPassword")} *
                        <input type="password"
                            className="form-control"
                            //placeholder={t("Login:ConfirmPassword") + " *"}
                            aria-label="Conferma Password"
                            onChange={e => onBlurEvent(e, 'confirmPassword')}
                        />
                    </div>
                    {
                        validateInputData.isTouchedPassword && !validateInputData.isValidPassword && <div className="col-md-12 mb-3 text-danger">
                            {t("Login:PasswordText")}
                        </div>
                    }
                    {
                        validateInputData.isTouchedConfirmPassword && !validateInputData.isValidConfirmPassword && <div className="col-md-12 mb-3 text-danger">
                            {t("Login:CoincidentPassword")} *
                        </div>
                    }
                    {showPromoteCode &&
                        <div className={"col-md-12 mb-3 text-left"}>
                            {t("Login:PromoteCode")}{mandatoryPromoteCode ? " *" : ""}
                            <input type="text"
                                className="form-control"
                                //placeholder="Email *"
                                aria-label="Email"
                                onChange={e => onBlurEvent(e, 'promoteCode')}
                            />
                            {
                                validateInputData.isTouchedPromoteCode && !validateInputData.isValidPromoteCode && <div className="col-md-12 mb-3 text-danger">
                                    {t("Login:PromoteCodeMandatory")} *
                                </div>
                            }
                        </div>
                    }

                    {dynamicNotes && dynamicNotes.length > 0 && dynamicNotes.map((note) =>
                        <div className="col-12 sol-sm-12 col-md-12 col-lg-12 mb-3 d-flex">
                            <div>
                                <input value={selectedDynamicNotes && selectedDynamicNotes.length > 0 ? selectedDynamicNotes.some(sdn => sdn.noteId === note.noteId && sdn.checked) : false}
                                    defaultValue="false" type="checkbox" className="mr-1" onClick={e => onCheckDynamicNote(note.noteId, e)} />
                            </div>
                            <span className="text-left" dangerouslySetInnerHTML={{ __html: replaceAllStyleElements(note.note) }} />
                            {note.checkTypeId === 3 ? " *" : ""}
                        </div>
                    )}

                </div>
            }

        </>
    );
}