import React from 'react';
import { useTranslation } from 'react-i18next';

export const CruiseInfoHighlights = ({ highlights }) => {
    const { t } = useTranslation();

    return (
        <>
            {
                highlights && highlights.length > 0 && <>
                    <div className="w-100 mt-2">
                        <div className="h6">{t('Product:Cruise:Highlights')}</div>
                        <ul className="list-group">
                            {
                                highlights.map((item, index) => {
                                    return <li key={index} className="list-group-item border-0">
                                        {
                                            item.imageUrl && <img className="mr-1" src={item.imageUrl} />
                                        }
                                        {
                                            item.icon && <span className="material-icons position-relative text-primary h085 mr-1" style={{ 'top': '3px' }}>{item.icon}</span>
                                        }
                                        {
                                            !item.imageUrl && !item.icon && <span className="material-icons position-relative h085 text-primary mr-1" style={{ 'top': '3px' }}>check</span>
                                        }
                                        <label>{item.name}</label>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </>
            }
        </>
    );
}