import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { M3SelectCustom } from '../../Common/M3SelectCustom.js';
import { InputSearchDestinationContainer } from '../../Common/Destination/InputSearchDestinationContainer';
import { InputCalendarContainer } from '../../Common/Calendar/InputCalendarContainer';
import { InputPaxesCompositionWithModalContainer } from '../../Common/Paxes/InputPaxesCompositionWithModalContainer';
import { generateClientFlowId, getCruiseCarpetId, getCruiseListLink, callGetLastItemsSearch, callGetGetProductRules } from '../../../js/ProductService.js';
import { countElementsInRange } from '../../../js/Utils';
import configData from "../../../appsettings.json";

export const CruiseSearchEngine = ({ searchParameters, typeSearch, extraParams, modeView, resetValues, isTrippie, onSearchTrippie }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    // original inputs list
    const [originalMacroaree, setOriginalMacroaree] = useState();
    const [originalPorts, setOriginalPorts] = useState();
    const [originalShips, setOriginalShips] = useState();
    const [originalCabins, setOriginalCabins] = useState();
    const [originalDuration, setOriginalDuration] = useState();
    const [originalPaxComposition, setOriginalPaxComposition] = useState();
    const [originalAvailableDates, setOriginalAvailableDates] = useState(null);

    const [lastSearchItems, setLastSearchItems] = useState([]);
    const [isStartSearch, setIsStartSearch] = useState(false);
    const [isValidComposition, setIsValidComposition] = useState(true);

    const [isValidForm, setIsValidForm] = useState({
        isValidDestination: false,
        isValidPort: false,
        isValidDates: false,
        isAllFieldValid: false
    });

    // select values
    const [selectMacroarea, setSelectMacroarea] = useState(null);
    const [selectPorts, setSelectPorts] = useState(null);
    const [selectPortsNames, setSelectPortsNames] = useState(null);
    const [selectPortsInput, setSelectPortsInput] = useState(null);
    const [selectShips, setSelectShips] = useState(null);
    const [selectDuration, setSelectDuration] = useState(0);
    const [selectCabins, setSelectCabins] = useState(null);
    const [selectCompositions, setSelectCompositions] = useState(null);
    const [selectDates, setSelectDates] = useState({});

    // visibilty panel
    const [showMacroaree, setShowMacroaree] = useState(false);
    const [showPorts, setShowPorts] = useState(false);
    const [showShips, setShowShips] = useState(false);
    const [showDuration, setShowDuration] = useState(false);
    const [showCabins, setShowCabins] = useState(false);
    const [showCompositions, setShowCompositions] = useState(false);
    const [showDates, setShowDates] = useState(false);

    const [textColorClass, setTextColorClass] = useState('text-white');


    useEffect(() => {
        const getLastSearchItems = async () => {
            const response = await callGetLastItemsSearch(31);
            setLastSearchItems(response);
        }
        getLastSearchItems();

        getSearchStaticData({ CultureCode: cultureName });

        const getRules = async () => {
            const response = await callGetGetProductRules(31);

            if (response) {
                try {
                    setIsValidComposition(response['ADDTOCART'].allowed);
                } catch (ex) {
                    console.error(ex);
                }
            }
        }
        getRules();

        const getMacroAree = async () => {
            const inputData = { CultureCode: cultureName };
            const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
            const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Cruise/GetMacroareeV2', requestOption);
            if (fetchedRes.ok) {
                const response = await fetchedRes.json();

                let items = [{ value: "-1", label: t('Product:Cruise:MacroareaPlaceholder'), isSelected: true }];

                if (response && response.success && response.macroaree && response.macroaree.length > 0) {
                    for (let i = 0; i < response.macroaree.length; i++) {
                        items.push(response.macroaree[i]);
                    }
                }

                setOriginalMacroaree(items);
            }
        }
        getMacroAree();

        if (modeView === 'MODAL')
            setTextColorClass('text-dark');
        else
            setTextColorClass('text-white');
    }, []);

    useEffect(() => {
        if (resetValues) {
            setShowMacroaree(false);
            setShowPorts(false);
            setShowShips(false);
            setShowDuration(false);
            setShowCabins(false);
            setShowCompositions(false);
            setShowDates(false);

            setValuesHowDefault();
        }

    }, [resetValues]);

    useEffect(() => {
        setValuesHowDefault();
    }, [searchParameters]);


    function setValuesHowDefault() {

        if (searchParameters) {
            //setIsDefaultSelected(true);

            setSelectMacroarea(searchParameters.destinationId);
            const getPortsSE = async () => {
                getPorts(searchParameters.destinationId);
            }

            if (searchParameters.destinationId)
                getPortsSE();

            let portCode = null
            if (searchParameters.portCodes && searchParameters.portCodes.length > 0) {
                portCode = searchParameters.portCodes[0];
                setSelectPorts(portCode);

                let portName = searchParameters.portNames && searchParameters.portNames.length > 0 ? searchParameters.portNames[0] : '';
                setSelectPortsInput({
                    Id: searchParameters.portCodes[0],
                    Text: portName,
                    Type: 'Port'
                });
                setSelectPortsNames(portName);
            }

            if (searchParameters.shipCodes && searchParameters.shipCodes.length > 0) {
                setSelectShips(searchParameters.shipCodes[0]);
            }

            if (searchParameters.cabinCategoryCodes && searchParameters.cabinCategoryCodes.length > 0) {
                setSelectCabins(searchParameters.cabinCategoryCodes[0]);
            }

            setSelectDuration(searchParameters.durationNights);

            let updateCompositions = [searchParameters.passengersComposition];
            setSelectCompositions(updateCompositions);

            setSelectDates({
                dateFrom: searchParameters.dateFrom.replace('T00:00:00', ''),
                dateTo: searchParameters.dateTo.replace('T00:00:00', ''),
            });

            let isValidFormUpdate = {
                isValidDestination: true,
                isValidDates: true,
                isAllFieldValid: true
            };

            setIsValidForm(isValidFormUpdate);
            updateSearchStaticData(searchParameters.destinationId, portCode, searchParameters.dateFrom.replace('T00:00:00', ''), searchParameters.dateTo.replace('T00:00:00', ''), false);
        }
    }

    async function getPorts(macroareaId) {
        const inputData = { CultureCode: cultureName, macroareaId: macroareaId };
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(inputData) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Cruise/GetPortsV2', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            let items = [{ value: "-1", label: t('Product:Cruise:PortPlaceholder'), isSelected: true }];

            if (response && response.success && response.ports && response.ports.length > 0) {

                for (let i = 0; i < response.ports.length; i++) {
                    items.push(response.ports[i]);
                }
            }

            setOriginalPorts(items);
        }
    }

    const getSearchStaticData = async (request, resetValues = true) => {
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(request) };
        const fetchedRes = await fetch(configData.Settings.CommonApi_BaseUrl + 'Cruise/GetStaticSearchData', requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            if (response && response.success) {

                let ships = [{ value: "-1", label: t('Product:Cruise:ShipPlaceholder'), isSelected: true }];
                if (response.ships && response.ships.length > 0)
                    ships = ships.concat(response.ships);

                if (resetValues)
                    setSelectShips(null);

                setOriginalShips(ships);

                let cabins = [{ value: "-1", label: t('Product:Cruise:CabinTypePlaceholder'), isSelected: true }];
                if (response.cabinCategoriesCodes && response.cabinCategoriesCodes.length > 0)
                    cabins = cabins.concat(response.cabinCategoriesCodes);

                if (resetValues)
                    setSelectCabins(null);

                setOriginalCabins(cabins);

                let durationNights = [{ value: "-1", label: t('MyBook:All'), isSelected: true }];
                if (response.durationNights && response.durationNights.length > 0)
                    durationNights = durationNights.concat(response.durationNights);

                if (resetValues)
                    setSelectDuration(null);

                setOriginalDuration(durationNights);

                let tmpAllowedPaxCompositions = [];
                for (let i = 0; i < response.allowedPaxCompositions.length; i++) {
                    response.allowedPaxCompositions[i].label = t(`SearchEngine:Cruise:PaxCompositions:${response.allowedPaxCompositions[i].label}`);
                    tmpAllowedPaxCompositions.push(response.allowedPaxCompositions[i]);
                }

                setOriginalPaxComposition(tmpAllowedPaxCompositions);

                if (response.availableDates && response.availableDates.length > 0) {
                    setOriginalAvailableDates(response.availableDates);

                    if (resetValues && selectDates.dateFrom && selectDates.dateTo) {
                        let containDateFrom = response.availableDates.some(x => x === (selectDates.dateFrom + "T00:00:00"));
                        let containDateTo = response.availableDates.some(x => x === (selectDates.dateTo + "T00:00:00"));

                        if (!containDateFrom || !containDateTo)
                            setSelectDates({});

                    }

                } else {
                    if (resetValues)
                        setSelectDates({});
                }
            }
        }
    }

    async function updateSearchStaticData(macroArea, portCode, dateFrom, dateTo, resetValues = true) {
        let request = {
            cultureCode: cultureName,
            macroareaId: macroArea,
            portCode: portCode,
            dateFrom: dateFrom,
            dateTo: dateTo
        };

        getSearchStaticData(request, resetValues);
    }


    // select function
    function onSelectMacroarea(item) {
        if (!item || item === '-1') {
            setSelectMacroarea(null);
            updateSearchStaticData(null, selectPorts, selectDates.dateFrom, selectDates.dateTo);
            checkIsValidForm(null, null, null, null);
        } else {
            setSelectMacroarea(item);
            getPorts(item);
            updateSearchStaticData(item, selectPorts, selectDates.dateFrom, selectDates.dateTo);
            checkIsValidForm(item, null, null, null);
        }

    }

    function onSelectPorts(item) {
        let portId = null;

        if (!item || item === '-1') {
            portId = null;
        } else {
            portId = item && item.Id ? item.Id : item;
        }

        if (item.Id) setSelectPortsInput(item);
        if (item.Text) setSelectPortsNames(item.Text);

        setSelectPorts(portId);
        updateSearchStaticData(selectMacroarea, portId, selectDates.dateFrom, selectDates.dateTo);
        checkIsValidForm(null, portId, null, null);
    }

    function onSelectShip(item) {
        if (item === '-1')
            setSelectShips(null);
        else
            setSelectShips(item);
    }

    function onSelectDuration(item) {
        if (item === '-1')
            setSelectDuration(null);
        else
            setSelectDuration(item);
    }

    function onSelectCabins(item) {
        if (item === '-1')
            setSelectCabins(null);
        else
            setSelectCabins(item);
    }

    function onSelectDates(values) {
        setShowDates(false);

        setSelectDates({
            dateFrom: values.dateFrom,
            dateTo: values.dateTo,
        });

        updateSearchStaticData(selectMacroarea, selectPorts, values.dateFrom, values.dateTo);
        checkIsValidForm(null, null, values, null);
    }

    function onSelectPaxComposition(item) {
        setSelectCompositions(item);
        checkIsValidForm(null, null, null, item);
    }

    // visibility panel
    function onOpenPanelMacroaree(visibility) {
        setShowMacroaree(true);
        setShowPorts(false);
        setShowShips(false);
        setShowDuration(false);
        setShowCabins(false);
        setShowCompositions(false);
        setShowDates(false);
    }

    function onOpenPanelShip(visibility) {
        setShowMacroaree(false);
        setShowPorts(false);
        setShowShips(true);
        setShowDuration(false);
        setShowCabins(false);
        setShowCompositions(false);
        setShowDates(false);
    }

    function onOpenPanelDuration(visibility) {
        setShowMacroaree(false);
        setShowPorts(false);
        setShowShips(false);
        setShowDuration(true);
        setShowCabins(false);
        setShowCompositions(false);
        setShowDates(false);
    }

    function onOpenPanelCabins(visibility) {
        setShowMacroaree(false);
        setShowPorts(false);
        setShowShips(false);
        setShowDuration(false);
        setShowCabins(true);
        setShowCompositions(false);
        setShowDates(false);
    }

    function onOpenPanelPorts(visibility) {
        setShowMacroaree(false);
        setShowPorts(true);
        setShowShips(false);
        setShowDuration(false);
        setShowCabins(false);
        setShowCompositions(false);
        setShowDates(false);
    }

    function onOpenPanelCalendar(visibility) {
        setShowMacroaree(false);
        setShowPorts(false);
        setShowShips(false);
        setShowDuration(false);
        setShowCabins(false);
        setShowCompositions(false);
        setShowDates(true);
    }

    function onOpenPanelComposition(visibility) {
        setShowMacroaree(false);
        setShowPorts(false);
        setShowShips(false);
        setShowDuration(false);
        setShowCabins(false);
        setShowCompositions(false);
        setShowDates(false);
        setShowCompositions(true);
    }

    // get defaults
    function getDefaultMacroaree() {
        return selectMacroarea;
    }

    function getDefaultPort() {
        return selectPorts;
    }

    function getDefaultShip() {
        return selectShips;
    }

    function getDefaultCabin() {
        return selectCabins;
    }

    function getDefaultDuration() {
        return selectDuration;
    }

    // event functions
    function checkIsValidForm(macroareaUpdate, portUpdate, datesUpdate, compositionUpdate) {
        let isValidDestination = false;
        let isValidDates = false;

        if ((macroareaUpdate !== null && macroareaUpdate !== undefined) || (selectMacroarea !== null && selectMacroarea !== undefined) ||
            (portUpdate !== null && portUpdate !== undefined) || (selectPorts !== null && selectPorts !== undefined)
        )
            isValidDestination = true;

        if ((datesUpdate !== null && datesUpdate !== undefined) || (selectDates && selectDates.dateFrom))
            isValidDates = true;

        let isValidFormUpdate = {
            isValidDestination: isValidDestination,
            isValidDates: isValidDates,
            isAllFieldValid: false
        };

        isValidFormUpdate.isAllFieldValid = isValidFormUpdate.isValidDestination && isValidFormUpdate.isValidDates;

        setIsValidForm(isValidFormUpdate);
    }


    async function onSearchButton() {
        setIsStartSearch(true);

        let newSearchParameters = {};

        let destinationDesc = "";
        try {
            destinationDesc = originalMacroaree.filter(x => x.value === selectMacroarea)[0].label
        } catch (ex) {

        }

        newSearchParameters = {
            destinationId: selectMacroarea,
            destinationDesc: destinationDesc,
            portCodes: selectPorts ? [selectPorts] : null,
            portNames: selectPortsNames ? [selectPortsNames] : [''],
            dateFrom: selectDates.dateFrom,
            dateTo: selectDates.dateTo,
            passengersComposition: selectCompositions[0],
            shipCodes: selectShips ? [selectShips] : null,
            cabinCategoryCodes: selectCabins ? [selectCabins] : null,
            durationNights: selectDuration ? selectDuration: 0
        };

        if (isTrippie) {
            let clientFlowId = generateClientFlowId();
            let carpetId = await getCruiseCarpetId(newSearchParameters, clientFlowId);

            onSearchTrippie(extraParams.stepIndex, clientFlowId, carpetId, newSearchParameters, configData.Settings.Products.Cruise.IdTipoProdotto);
            setIsStartSearch(false);
            return;
        }

        let clientFlowId = generateClientFlowId();
        let url = await getCruiseListLink(newSearchParameters, clientFlowId);
        window.open(url, '_self');
    }


    return (
        <>
            <div className="row mb-3">
                <div className="col-12 col-sm-3 my-1">
                    <M3SelectCustom
                        idModal="mdMacroaree"
                        type="Macroaree"
                        items={originalMacroaree}
                        modeView={modeView}
                        closeExternal={showMacroaree}
                        onSelectItems={onSelectMacroarea}
                        onOpenPanelItems={onOpenPanelMacroaree}
                        defaultSelected={getDefaultMacroaree()} />
                </div>
                <div className="col-12 col-sm-3 my-1">
                    {
                        selectMacroarea
                            ? (<>
                                <M3SelectCustom
                                    idModal="mdPorts"
                                    type="Ports"
                                    items={originalPorts}
                                    modeView={modeView}
                                    closeExternal={showPorts}
                                    onSelectItems={onSelectPorts}
                                    onOpenPanelItems={onOpenPanelPorts}
                                    defaultSelected={getDefaultPort()} />
                            </>)
                            : (<>
                                <InputSearchDestinationContainer
                                    defaultSelectDestination={selectPortsInput}
                                    productType={configData.Settings.Products.Cruise.IdTipoProdotto}
                                    lastSearchItems={lastSearchItems}
                                    onSelectSearchDestination={onSelectPorts}
                                    onOpenPanelDestination={onOpenPanelPorts}
                                    closeExternal={showPorts}
                                    modeView={modeView}
                                    extraOptions={{ iconName: "Port" }}
                                />
                            </>)
                    }
                </div>
                <div className="col-12 col-sm-3 my-1">
                    <InputCalendarContainer
                        dateFrom={selectDates ? selectDates.dateFrom : null}
                        dateTo={selectDates ? selectDates.dateTo : null}
                        extraOptions={{ multiDate: true, validDates: originalAvailableDates }}
                        onClickSelectDates={onSelectDates}
                        onOpenPanelCalendar={onOpenPanelCalendar}
                        closeExternal={showDates}
                        modeView={modeView}
                        productType={configData.Settings.Products.Cruise.IdTipoProdotto}
                        type="xl" />
                </div>
                <div className="col-12 col-sm-3 my-1">
                    <InputPaxesCompositionWithModalContainer
                        searchParameters={searchParameters}
                        productType={configData.Settings.Products.Cruise.IdTipoProdotto}
                        callOnSelectComposition={onSelectPaxComposition}
                        onOpenPanelPaxes={onOpenPanelComposition}
                        closeExternal={showCompositions}
                        modeView={"SEARCHENGINE"}
                        type="xl"
                        supportedComposition={originalPaxComposition}
                    />
                </div>
                <div className="col-12 col-sm-3 my-1">
                    <M3SelectCustom
                        idModal="mdShip"
                        type="Ships"
                        items={originalShips}
                        modeView={modeView}
                        closeExternal={showShips}
                        onSelectItems={onSelectShip}
                        onOpenPanelItems={onOpenPanelShip}
                        defaultSelected={getDefaultShip()} />
                </div>
                <div className="col-12 col-sm-3 my-1">
                    <M3SelectCustom
                        idModal="mdCabins"
                        type="Cabins"
                        items={originalCabins}
                        modeView={modeView}
                        closeExternal={showCabins}
                        onSelectItems={onSelectCabins}
                        onOpenPanelItems={onOpenPanelCabins}
                        defaultSelected={getDefaultCabin()} />
                </div>
                <div className="col-12 col-sm-3 my-1">
                    <M3SelectCustom
                        idModal="mdDuration"
                        type="Duration"
                        items={originalDuration}
                        modeView={modeView}
                        closeExternal={showDuration}
                        onSelectItems={onSelectDuration}
                        onOpenPanelItems={onOpenPanelDuration}
                        defaultSelected={getDefaultDuration()} />
                </div>
                <div className="col-12 col-sm-3 my-1">
                    <button
                        className="btn tp-btn-search w-100 py-3"
                        disabled={!isValidForm.isAllFieldValid && !isStartSearch}
                        onClick={_ => onSearchButton()}
                    >
                        {isStartSearch ? <><div className="spinner-border spinner-border-sm text-primary" role="status"><span className="sr-only"></span></div></> : <>{t('Button:Search')}</>}
                    </button>
                </div>

                {!isValidComposition && <div className={"col-12 col-sm-12 my-1 " + textColorClass}>
                        {t('SearchEngine:WarningCartComposition')}
                    </div>
                }
            </div>
        </>
    );
}
