import React from 'react';
import { useTranslation } from 'react-i18next';
import configData from "../../../../appsettings.json";
import { Gallery } from '../../Gallery';
import { ListSearchEngineCV } from '../../SearchEngine/compactView/ListSearchEngineCV';
import { ActivityDetailFavourite } from './ActivityDetailFavourite';
import { ActivityDetailHeader } from './ActivityDetailHeader';
import { ActivityDetailCategoryInfo } from './ActivityDetailCategoryInfo';
import { ActivityDetailDescriptions } from './ActivityDetailDescriptions';
import { ActivityDetailDescriptionsAccordion } from './ActivityDetailDescriptionsAccordion';
import { ActivityDetailInclusionExclusion } from './ActivityDetailInclusionExclusion';

export const ActivityStaticContainer = ({ activityId, activityDetail, modeView, extraParamSearch, searchParameters }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="card border-light bg-white p-1">
                {
                    modeView !== 'modal' && <div className="row px-2">
                        <ActivityDetailFavourite activityDetail={activityDetail} />
                    </div>
                }
                {
                    <div className="row p-3">
                        <Gallery gallery={activityDetail.images} alternativeText={activityDetail.name} modeView={modeView} productType={configData.Settings.Products.Activity.IdTipoProdotto} />
                    </div>
                }
                <div className="row px-1">
                    <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                        <ActivityDetailHeader activityDetail={activityDetail} />
                    </div>
                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 text-right">
                        {
                            modeView !== 'modal' && <>
                                <div>
                                    <var></var>
                                </div>
                                <button className="btn btn-sm btn-neutral p-1 m-0 d-none"><data m3lab="General.LookMap">{t('General:LookMap')}</data></button>
                            </>
                        }
                    </div>
                    <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                        <ActivityDetailDescriptions
                            activityDetail={activityDetail}
                            showOnlyGeneral="true" />
                    </div>
                    <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                        <ActivityDetailCategoryInfo
                            activityId={activityId}
                            activityDetail={activityDetail} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                        <div id="activityDetailDescriptionsAccordion">
                            <ActivityDetailDescriptionsAccordion activityDetail={activityDetail} gallery={activityDetail.images} />
                        </div>
                    </div>
                    <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                        {
                            modeView !== 'modal' && <>
                                <ListSearchEngineCV
                                    imageUrl="https://cdn.travelplace.ch/travelplace40/img/maps_list.jpg"
                                    searchParameters={searchParameters}
                                    productType={configData.Settings.Products.Activity.IdTipoProdotto}
                                    extraParams={extraParamSearch}
                                    typeSearch="AVL" />
                            </>
                        }
                        <ActivityDetailInclusionExclusion activityDetail={activityDetail} />
                    </div>
                </div>
            </div>
        </>
    );
}