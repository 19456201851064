import React, { useState, useEffect } from 'react';
import { Loading } from './Loading';
import { createRenderHtml } from '../../js/Utils.js';

export const Modal = (props) => {
    const [isLoading, setIsLoading] = useState(props.isLoading);
    const [loadingMsg, setLoadingMsg] = useState(props.loadingMsg);
    const [body, setBody] = useState(props.body);
    const [showBtnConfirm, setShowBtnConfirm] = useState(props.showBtnConfirm);
    const [showBtnClose, setShowBtnClose] = useState(props.showBtnClose);

    useEffect(() => {
        const callOnOpenFn = async (inputData) => {
            const response = OnOpenFn(inputData);
        }

        setBody(props.body);

        var thisModal = document.getElementById(props.id);
        thisModal.addEventListener('show.bs.modal', function (e) {
            if (props.fnOnOpen !== null && props.fnOnOpen !== undefined)
                callOnOpenFn(props.fnOnOpen);
        });
    }, []);

    async function OnOpenFn(fn) {
        const response = await fn();
        setBody(response.bodyHtml);
        if (!response.success) setShowBtnConfirm(false);
        setIsLoading(false);
    }

    async function OnConfirmFn() {
        setIsLoading(true);

        if (props.fnOnConfirm !== null && props.fnOnConfirm !== undefined) {
            const response = await props.fnOnConfirm();
            setBody(response.bodyHtml);
        }

        setShowBtnConfirm(false);
        setIsLoading(false);
    }

    const OnClose = () => {
        setBody(props.body);
        setShowBtnConfirm(props.showBtnConfirm);
        setShowBtnClose(props.showBtnClose);

        if (props.fnOnClose !== null && props.fnOnClose !== undefined) {
            props.fnOnClose();
        }
    }

    return (
        <div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby={props.id + 'Label'} aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {
                            isLoading
                                ? (<Loading textMsg={loadingMsg} />)
                                : (<div dangerouslySetInnerHTML={createRenderHtml(body)}></div>)
                        }
                    </div>
                    <div className={isLoading ? ('modal-footer d-none') : ('modal-footer')}>
                        {showBtnClose ? (<button type="button" className="btn bt-sm tp-btn-delete" data-bs-dismiss="modal" onClick={OnClose}>{props.lblClose}</button>) : (<></>)}
                        {showBtnConfirm ? (<button type="button" className="btn bt-sm tp-btn-confirm" onClick={OnConfirmFn}>{props.lblConfirm}</button>) : (<></>)}
                    </div>
                </div>
            </div>
        </div>
    );
}
