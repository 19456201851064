import React, { useEffect, useState } from 'react';
import configData from "../../../../appsettings.json";
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../../js/Utils.js';
import { M3Icon } from "../../../Common/M3Icon";
import { ProductActionMenu } from '../../Common/ProductActionMenu.js';
import { getDifferenceDayFlight } from '../../../../js/PrintUtility';

export const FlightLegsList = ({ item, airPlaneLogo, airPlaneName, airPlaneIATA, arrivalAirportName, arrivalAirportIATA, index, fares, modalOpenProductId, isViewDetail = false, isSegment = false, modalOpenFareDetail }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const [cultureName] = useState(cookies.get("CultureName"));

    useEffect(() => {
    }, [item]);
    
    function getSegmentsTooltip(stopsDurations) {
        let res = "";

        stopsDurations.forEach(function (stop) {
            res += t(`Product:Flight:Stopover`) + " " + stop.duration + " h - (" + stop.arrivalAirportIATA + ") " + stop.arrivalAirportName + "\n"
        });

        return res;
    }

    function onModalOpenProductId(productId) {
        modalOpenProductId(productId);
    }

    function onModalOpenFareDetail(productId) {
       modalOpenFareDetail(productId);
    }

    function getFareBaggages() {
        let res = t(`Product:Flight:NoBaggage`);
        let isFound = false;
        
        fares.forEach(function (fare) {
            fare.renderFaresDetails.forEach(function (renderFare) {
                renderFare.renderFareDetails.forEach(function (fareDetail) {
                    if (fareDetail.legIndex === index && fareDetail.baggage && !isFound) {
                        isFound = true;
                        res = t(`Product:Flight:Bagage1`);
                    }
                });
            });
        });

        return res;
    }



    return (
        <>
            {item && <>
                <div className="row h6 mb-0">
                    <div className="col-3 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                        <div className="d-inline">
                            <div className="d-table h-100">
                                <div className="d-table-row h-100">
                                    {!isSegment && <div className="d-table-cell align-middle">{formatDateTime(item.departureDate, cultureName, { noYear: true })}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                        <div className="row">
                            <div className="col-12 col-sm-0 col-md-0 col-lg-0 col-xl-0 p-0 m-0 d-block d-sm-none">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-end justify-content-flex-end align-items-center" >
                                        <img src={airPlaneLogo} className="w-100 p-2 w-10" alt="" />
                                        <label className="pl2">{airPlaneName}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12  d-block d-sm-none p-0 d-flex justify-content-end justify-content-flex-end align-items-center">
                                <div className="d-inline">
                                    {!isSegment && <><label className="mr-1">({item.departureAirportIATA})</label>{item.departureAirportName}</>}
                                </div>
                            </div>
                            <div className="col-12 d-none d-sm-block col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div className="d-inline">
                                    {!isSegment && <><label className="mr-1">({item.departureAirportIATA})</label>{item.departureAirportName}</>}
                                </div>
                            </div>
                            <div className="col-12  d-block d-sm-none p-0 d-flex justify-content-end justify-content-flex-end align-items-center ">
                                {!isSegment && <> <div className="d-inline"><label className="mr-1">({arrivalAirportIATA})</label>{arrivalAirportName}</div></>}
                            </div>
                            <div className="col-12  d-none d-sm-block  col-sm-6  col-md-6 col-lg-6 col-xl-6 ">
                                {!isSegment && <> <div className="d-inline"><label className="mr-1">({arrivalAirportIATA})</label>{arrivalAirportName}</div></>}
                            </div>
                        </div>
                    </div>

                    <div className="col-1 col-sm-0 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                        {!isViewDetail && index === 0 &&
                            <>
                                <div className="btn-group text-right float-end pr-2 d-none  d-sm-none d-md-block">
                                    <button className="btn p-0 m-0" type="button" id="menuItem1" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                        <data m3ico="List">
                                            <M3Icon iconName="MenuFunctions" />
                                        </data>
                                    </button>
                                    <ProductActionMenu
                                        productId={item.uniqueId}
                                        productType={configData.Settings.Products.Flight.IdTipoProdotto}
                                        handleModalOpenProductId={onModalOpenProductId}
                                        handleModalOpenFareDetail={onModalOpenFareDetail}
                                />
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-0 col-sm-1 col-md-1 col-lg-1 col-xl-1 p-0 m-0 d-none d-sm-block">
                        <img src={airPlaneLogo} className="w-100 p-2" title={airPlaneName} alt="" />
                        {((airPlaneIATA === "LH" || airPlaneIATA === "NH") && configData.Settings.Website.ShowFlightListAirPlaneIATA)
                            ? (<>
                                <img src={`${configData.Settings.CDN_Custom}/images/ico/lh_preferred_32.png`} alt="Preferred" title="Preferred" className="ml-1" style={{ width: '24px' }} />
                                <img src={`${configData.Settings.CDN_Custom}/images/ico/lh_green_32.png`} alt="Green" title="Green" style={{ width: '24px' }} />
                            </>)
                            : (<></>)
                        }
                    </div>
                    <div className="col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-2 px-1">
                        <label>
                            <data m3lab="Product.Flight.Takeoff">
                                {t(`Product:Flight:Takeoff`)}
                            </data>
                        </label>
                        <label className="ml-1">({item.departureAirportIATA})</label>
                        <div>
                            <label>
                                {item.departureTime}
                            </label>
                        </div>
                        <div>
                            <label>{airPlaneIATA + item.flightNumber}</label>
                        </div>
                    </div>
                    <div className="col-4 col-sm-7 col-md-7 col-lg-7 col-xl-7 text-center">
                        <label>
                            <data m3lab="Product.Flight.Duration">
                                {t(`Product:Flight:Duration`)}
                            </data>
                        </label>
                        <label className="ml-1">
                            {item.duration} h
                        </label>
                        <div className="w-100"></div>
                        {item.stops && item.stops.numStops > 0 ? // se da lista (senza segments)
                            <>
                                <div title={getSegmentsTooltip(item.stops.stopsDurations)}>
                                    <label>{item.stops.numStops}</label>
                                    <label className="ml-1">
                                        {item.stops.numStops > 1 ?
                                            <data m3lab="Product.Flight.Stopovers">
                                                {t(`Product:Flight:Stopovers`)}
                                            </data>
                                            :
                                            <data m3lab="Product.Flight.Stopover">
                                                {t(`Product:Flight:Stopover`)}
                                            </data>
                                        }
                                    </label>
                                    <label className="ml-1">
                                        ({item.stops && item.stops.totalDuration} h)
                                    </label>
                                    {
                                        item.stops.isConnectionLong && <>
                                            <br />
                                            <label class="bg-danger px-2 text-white h6">Connection long</label>
                                        </>
                                    }
                                </div>
                            </> : <label>
                                <data m3lab="Product.Flight.Direct">
                                    {t(`Product:Flight:Direct`)}
                                </data>
                            </label>
                        }
                        <div className="w-100 border-top"></div>
                        {fares &&
                            <label>{getFareBaggages()}</label>
                        }
                    </div>
                    <div className="col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2 pr-2 px-1 text-right">
                        <label>
                            <data m3lab="Product.Flight.Landing">
                                {t(`Product:Flight:Landing`)}
                            </data>
                        </label>
                        <label className="ml-1">({item.arrivalAirportIATA})</label>
                        <div>
                            <label>
                                {item.arrivalTime}
                            </label>
                        </div>
                        <div>
                            <label>{getDifferenceDayFlight(item.departureDate, item.arrivalDate)}</label>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
    );
}