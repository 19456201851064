import React, { useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import configData from "../../../appsettings.json";
import { Loading } from '../../Common/Loading';
import { formatPrice } from "../../../js/Utils";

export const TemplatePrintHeaderDescription = ({ title, description, onHandleIsPrint }) => {
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    return (
        <>
            {title &&
                <table>
                    <tbody>
                        <tr>
                            <td className="h3 w-100">
                                {title}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className="text-justify">{description}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        </>
    );
}