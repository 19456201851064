import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { StructureBoxStatic } from '../product/BoxStatic/StructureBoxStatic';
import { HotelModalStatic } from '../../Product/Hotel/components/HotelModalStatic';
import { ActivityBoxStatic } from '../product/BoxStatic/ActivityBoxStatic';
import { ActivityModalStatic } from '../../Product/Activity/components/ActivityModalStatic';
import { TourBoxStatic } from '../product/BoxStatic/TourBoxStatic';
import { TourModalStatic } from '../../Product/Tour/components/TourModalStatic';
import { TemplateInclusion } from '../TemplateUtils';

export const TemplateBoxStatic = ({ steps }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    const [modalOpenStructureId, setModalOpenStructureId] = useState(null);
    const [modalOpenActivityId, setModalOpenActivityId] = useState(null);
    const [modalOpenTourId, setModalOpenTourId] = useState(null);

    let aTemplateData = [];
    if (steps && steps.length > 0) {
        for (let i = 0; i < steps.length; i++) {
            if (steps[i].templateData && steps[i].templateData.length > 0) {
                for (let j = 0; j < steps[i].templateData.length; j++) {
                    //if (steps[i].templateData[j].inclusion === TemplateInclusion.Mandatory || steps[i].templateData[j].isDefault)
                    aTemplateData.push(steps[i].templateData[j]);
                }
            }
        }
    }

    return (
        <>
            {aTemplateData && aTemplateData.length > 0
                ? (
                    <>
                        <h3>{t('Template:ServicesDescription')}</h3>
                        {
                            aTemplateData.map((tData, idx) => {
                                return (
                                    <React.Fragment key={idx}>
                                        {tData.productType === 0 && <StructureBoxStatic structure={tData.product} codiceProdotto={tData.codiceProdotto} index={idx} modalOpenStructureId={setModalOpenStructureId} />}
                                        {tData.productType === 1 && <ActivityBoxStatic activity={tData.product} codiceProdotto={tData.codiceProdotto} index={idx} modalOpenActivityId={setModalOpenActivityId} />}
                                        {tData.productType === 10 && <TourBoxStatic tour={tData.product} codiceProdotto={tData.codiceProdotto} index={idx} modalOpenTourId={setModalOpenTourId} />}
                                    </React.Fragment>
                                )
                            })
                        }
                    </>
                )
                : (<></>)
            }

            {modalOpenStructureId && <HotelModalStatic structureId={modalOpenStructureId} modalCloseProductId={setModalOpenStructureId} isNotEngine={true} />}
            {modalOpenActivityId && <ActivityModalStatic activityId={modalOpenActivityId} modalCloseProductId={setModalOpenActivityId} isNotEngine={true} />}
            {modalOpenTourId && <TourModalStatic tourId={modalOpenTourId} modalCloseProductId={setModalOpenTourId} isNotEngine={true} />}
        </>
    );
}
