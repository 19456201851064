import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Loading } from '../Common/Loading';
import { Error } from '../Common/Error';
import { getCurrentUserFromJwtToken, getWebsiteId } from '../../js/Utils';
import configData from "../../appsettings.json";
import Cookies from 'universal-cookie';
import { PrintConfigButtons } from './components/PrintConfigButtons';
import { PrintHeader } from './components/PrintHeader';

export const DocViaggioV2 = () => {
    let { orderNumber } = useParams();
    let { cultureName } = useParams()
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const [isLoadingPrint, setIsLoadingPrint] = useState(false);
    const [isErrorPrint, setIsErrorPrint] = useState(false);

    const [websiteId, setWebsiteId] = useState(0);
    const [currentUser, setCurrentUser] = useState(null);

    const [printParam, setPrintParam] = useState({
        numeroOrdine: null,
        cultureCode: null,
        visualizationType: "H",
        websiteId: 0,
        authCode: "",
        idStakeholder: null,
        userId: null,

        idTypeSale: "1",
        B2BB2C: "2",
        ShowCover: true,
        ShowPaxes: true,
        ShowPricing: true,
        ShowProducts: true,
        ShowDynamicNote: true,
        ShowGroupingPricing: true,
        ShowStaticScheda: true
    });

    let params = new URLSearchParams(window.location.search);
    let authCode = params.get('ac');

    useEffect(() => {
        setIsLoading(true);

        try {
            let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
            let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);
            setWebsiteId(currentUser.websiteId);
            setCurrentUser(currentUser);
        } catch (ex) {
            setWebsiteId(getWebsiteId());
        }
        
    }, []);

    const getOrders = async (visualizationType) => {
        callGetOrders(visualizationType);
    }

    useEffect(() => {
        if (authCode || currentUser)
            getOrders("H");

    }, [currentUser, websiteId]);

    async function callGetOrders(visualizationType) {
        let inputData = { ...printParam };
        inputData.numeroOrdine = orderNumber;
        inputData.cultureCode = cultureName;
        inputData.websiteId = websiteId;
        inputData.visualizationType = visualizationType;
        inputData.authCode = authCode;
        inputData.userId = currentUser !== null ? currentUser.userId : null;
        inputData.idStakeholder = currentUser !== null ? currentUser.stakeholderId : null;

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json, application/pdf, text/html', // Specifica che accetti JSON, PDF e HTML
            },
            body: JSON.stringify(inputData)
        };


        try {

            const fetchedRes = await fetch(configData.Settings.Base_Url + 'api/documents/GetConfirmDocument', requestOption);

            // Analisi del contenuto per determinare se è HTML o PDF
            if (fetchedRes.ok) {
                if (visualizationType === 'H') {
                    let htmlContent = await fetchedRes.text();
                    document.getElementById('myDiv').innerHTML = htmlContent;
                }
                else if (visualizationType === 'P') {
                    let blob = await fetchedRes.blob();

                    // Crea un URL per il Blob
                    const url = window.URL.createObjectURL(blob);

                    // Crea un link per il download e cliccalo programmaticamente
                    const link = document.createElement('a');
                    link.href = url;
                    let documentTitle = "DocumentoDiViaggio";
                    if (printParam.B2BB2C)
                        documentTitle += "_Cliente";

                    link.setAttribute('download', documentTitle + '_' + orderNumber + '.pdf'); // Specifica il nome del file
                    document.body.appendChild(link);
                    link.click();

                    // Pulisci l'URL dopo il download
                    window.URL.revokeObjectURL(url);
                }
                else
                    throw new Error('Tipo di contenuto non supportato');

            } else {
                throw new Error('no data');
            }

            setIsLoading(false);
            setIsLoadingPrint(false);
        } catch (ex) {
            if (visualizationType === 'H')
                setIsError(true);
            if (visualizationType === 'P')
                setIsErrorPrint(true);

            setIsLoading(false);
            setIsLoadingPrint(false);
        }


    }

    function onPrint() {
        setIsLoadingPrint(true);
        getOrders("P");
    }

    function onClickPrintConfigButtons(items) {
        setPrintParam(items);
    }

    return (
        <>
            <section className="confirmBookingSection section bg-gray-300 section-lg pt-5">
                <div className="container bg-white">
                    {isLoading && <Loading textMsg={t('Loading:Order')} />}
                    {!isLoading && isError && <div className="row"><div className="col-12 text-center mb-4"><Error textMsg={t('Error:Order')} /></div></div>}
                    {
                        <>
                            {
                                !isLoading && !isError && <>
                                    <PrintHeader
                                        isLoadingPrint={isLoadingPrint}
                                        isErrorPrint={isErrorPrint}
                                        title={"Documento di Viaggio N." + { orderNumber }}
                                        showConfigButtons={false}
                                        onPrint={onPrint}
                                    />
                                    {
                                        !authCode && <PrintConfigButtons
                                            printParam={printParam}
                                            onClickPrintConfigButtons={onClickPrintConfigButtons} />
                                    }
                                </>
                            }
                            <div id="myDiv" className="bg-white py-2 mt-3" style={{ borderTop: '3px dotted #f4f6f8' }}></div>
                        </>
                    }
                </div>
            </section>
        </>
    );
}