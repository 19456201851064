import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { useAuth } from '../../Auth/AuthProvider';
import { searchCarpetParameter } from '../../../js/Constant';
import { getCurrentUserFromJwtToken, convertHourInMilliseconds, scrollTo, getWebsiteId } from '../../../js/Utils.js';
import { M3Icon } from "../../Common/M3Icon";
import { ProductHeader } from '../Common/ProductHeader';
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';
import { createTrainCarpetAvailability, createTrainCarpet } from '../../../js/ProductService';
import { ProductItemNotFound } from '../Common/ProductItemNotFound';
import { ProductFilter } from '../Common/Filter/ProductFilter';
import { ProductFilterByName } from '../Common/Filter/ProductFilterByName';
import { ProductFilterByPrice } from '../Common/Filter/ProductFilterByPrice';
import { ProductFilterMultiDestinationTime } from '../Common/Filter/ProductFilterMultiDestinationTime';
import { ListSearchEngineCV } from '../SearchEngine/compactView/ListSearchEngineCV';
import { ListSearchEngineCVMobile } from '../SearchEngine/compactView/ListSearchEngineCVMobile';
import { TrainItemList } from './components/TrainItemList';
import { TrainSelectedItinerary } from './components/TrainSelectedItinerary';
import { TrainItinerySeatMap } from './components/TrainItinerySeatMap';
import { TrainModal } from './components/TrainModal';
import { ProductNotAllowedAddToCart } from '../Common/ProductNotAllowedAddToCart';
import { ProductNavBar } from '../SearchEngine/ProductNavBar';

export const TrainList = () => {
    const cookies = new Cookies();
    const sessionToken = cookies.get("SessionToken");

    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    let params = new URLSearchParams(window.location.search);
    let carpetId = params.get('cId');
    let clientFlowId = params.get('cfId');
    let { getQuotationInfoSession } = useAuth();

    // carpet
    const [carpetStatus, setCarpetStatus] = useState(null);
    const [carpetDataStatus, setCarpetDataStatus] = useState(null);
    const [carpetTime, setCarpetTime] = useState(1);
    const [intervalCarpetPing, setIntervalCarpetPing] = useState([]);

    // page
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [items, setItems] = useState({});
    const [isLoadingFilter, setIsLoadingFilter] = useState(false);
    const [showPriceBar, setShowPriceBar] = useState(false);
    const [isLoadingListResult, setIsLoadingListResult] = useState(true);

    const [modalOpenTrainFare, setModalOpenTrainFare] = useState(null);
    const [templateInfo, setTemplateInfo] = useState(null);
    const [quotationInfo, setQuotationInfo] = useState(null);

    // roles
    const [showCost, setShowCost] = useState(false);
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);
    const [enableCanAddToCart, setEnableCanAddToCart] = useState(false);

    // filtri
    const [solutionType, setSolutionType] = useState(1);
    const [isResetSelectedItem, setIsResetSelectedItem] = useState(false);
    const [filterValues, setFilterValues] = useState(null);
    const [rangePriceInfo, setRangePriceInfo] = useState({
        min: "0",
        max: "5000",
        step: "50",
        currency: "EUR"
    });

    const [filterSelected, setFilterSelected] = useState({
        trainNumber: null,
        stops: [],
        providers: [],
        departureStations: [],
        arrivalStations: [],
        trainType: [],
        trainTimeInfo: null,
        rangePrice: null,
        orderBy: searchCarpetParameter.train.orderBy,
    });

    // paging
    const itemsPerPage = searchCarpetParameter.train.listMaxItem;
    const [pageCount, setPageCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(0);

    // train
    const [actualCarpetId, setActualCarpetId] = useState(carpetId);
    const [carpetAvailabilityId, setCarpetAvailabilityId] = useState(carpetId);

    const [outboundCarpetId, setOutboundCarpetId] = useState(carpetId);
    const [originalSearchParameters, setOriginalSearchParameters] = useState(null);
    const [selectedItineraryOutbound, setSelectedItineraryOutbound] = useState(null);
    const [selectedPaxesSeatOutbound, setSelectedPaxesSeatOutbound] = useState(null);

    const [returnCarpetId, setReturnCarpetId] = useState(null);
    const [returnSearchParameters, setReturnSearchParameters] = useState(null);
    const [selectedItineraryReturn, setSelectedItineraryReturn] = useState(null);
    const [selectedPaxesSeatReturn, setSelectedPaxesSeatReturn] = useState(null);

    // train seat
    const [enableSelectSeat, setEnableSelectSeat] = useState(true);
    const [showSelectSeat, setShowSelectSeat] = useState(false);
    const [isLoadingSelectSeat, setIsLoadingSelectSeat] = useState(false);
    const [haveSeatOutboundError, setHaveSeatOutboundError] = useState(false);
    const [seatMapOutbound, setSeatMapOutbound] = useState(null);

    const [haveSeatReturnError, setHaveSeatReturnError] = useState(false);
    const [seatMapReturn, setSeatMapReturn] = useState(null);

    const [isLoadingConfirmSeat, setIsLoadingConfirmSeat] = useState(false);
    const [isErrorConfirmSeat, setIsErrorConfirmSeat] = useState(false);


    const onChangeShowPriceBar = () => {
        setShowPriceBar(!showPriceBar);
    }

    useEffect(() => {
        setIsLoading(true);
        setIsError(false);
        console.log("LKOAD PPAGE");
        getCarpetStatus();

        try {

            let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
            let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);

            if (currentUser.roles) {
                if (!currentUser.roles.includes("FE_NonVisualizzaNetto"))
                    setEnableShowNetPrices(true);


                if (currentUser.roles.includes("FE_ShowCost"))
                    setShowCost(true);
            }

        } catch (ex) {
        }

    }, []);

    useEffect(() => {

        switch (carpetStatus) {
            case 'Processing':
            case 'Queued':
            case 'Completed':
                {
                    break;
                }
            case 'Error':
                {
                    setIsError(true);
                    setIsLoading(false);
                    break;
                }
        }

    }, [carpetStatus]);

    useEffect(() => {
        switch (carpetDataStatus) {
            case 'Completed':
                {
                    callGetNewData();
                    clearCarpetAllInterval();
                    break;
                }

            case 'NewData':
                {
                    callGetNewData();
                    break;
                }

            case 'Processing':
            case 'NoChanges':
                {

                    let interval = setInterval(() => {
                        getCarpetStatus();
                    }, searchCarpetParameter.flight.carpetPingMs);
                    addInterval(interval);

                    break;
                }
        }

    }, [carpetDataStatus]);

    useEffect(() => {
        if (getQuotationInfoSession && getQuotationInfoSession.quotationId)
            setQuotationInfo(getQuotationInfoSession);
        else
            setQuotationInfo(null);

    }, [getQuotationInfoSession]);

    useEffect(() => {
        getCarpetStatus();
    }, [actualCarpetId])

    // API carpet
    async function callGetNewData() {
        if (filterValues === null || carpetStatus === 'Completed')
            getTrainListFilters();

        getTrainList(filterSelected, 0);

        setIsLoading(false);
        setIsError(false);

        // conto quante chiamate sto facendo
        // ogni chiamata avviene dopo un tot di tempo, quindi in base al numero di chiamate so quanto è passato
        let callTicks = carpetTime * searchCarpetParameter.train.carpetPingMs;
        let finishTime = callTicks > searchCarpetParameter.train.carpetMaxTimeMs
        setCarpetTime(carpetTime + 1);

        if (carpetStatus !== 'Completed' && !finishTime) {
            setIsLoadingFilter(true);

            let interval = setInterval(() => {
                getCarpetStatus();
            }, searchCarpetParameter.activity.carpetPingMs);
            addInterval(interval);

        } else {
            setIsLoadingFilter(false);
        }

    }

    async function getTrainList(filterSelectedUpdate, pageNumber) {
        setIsLoadingFilter(true);

        let requestParam = {
            cultureCode: cultureName,
            carpetId: actualCarpetId,
            pageNumber: pageNumber,
            pageSize: searchCarpetParameter.train.listMaxItem,
            showCost: showCost,
            solutionType: solutionType,

            trainNumber: filterSelectedUpdate.trainNumber,
            stops: filterSelectedUpdate.stops,
            providers: filterSelectedUpdate.providers,
            departureStations: filterSelectedUpdate.departureStations,
            arrivalStations: filterSelectedUpdate.arrivalStations,
            trainType: filterSelectedUpdate.trainType,
            trainTimeInfo: filterSelectedUpdate.trainTimeInfo,
            rangePrice: filterSelectedUpdate.rangePrice,
            orderBy: filterSelectedUpdate.orderBy
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}train/gettrainlist`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setItems(response);
            renderPaginator(response.totalTrains);

            if (response.permissions)
                setEnableCanAddToCart(response.permissions['ADDTOCART']);
        }

        if (carpetDataStatus === 'Completed') {
            setIsLoadingFilter(false);
        }

        setIsLoadingListResult(false);
    }

    async function getTrainListFilters() {
        let requestParam = {
            cultureCode: cultureName,
            carpetId: actualCarpetId,
            solutionType: solutionType
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}train/gettrainlistfilters`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setFilterValues(response);

            if (response.trainSearchParameters && !originalSearchParameters)
                setOriginalSearchParameters(response.trainSearchParameters);

            if (carpetDataStatus === 'Completed' && response.rangePricesInfo) {
                setRangePriceInfo({
                    ...rangePriceInfo,
                    min: response.rangePricesInfo.min,
                    max: response.rangePricesInfo.max,
                    currency: response.rangePricesInfo.name
                });
            }
        }
    }

    async function getCarpetStatus() {
        console.log(`Call GetCarpetStatus [CarpetId = ${actualCarpetId}]`);
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}carpet/getcarpetstatus/${actualCarpetId}`, requestOption);

        if (!fetchedRes.ok) {
            setIsError(true);
        } else {
            const response = await fetchedRes.json();

            let dataStatus = response.dataStatus;
            let status = response.status;
            if (response.success) {
                setCarpetStatus(status);

                if (status !== 'Error') {
                    // messo per richiamare il getCarpetStatus, nel caso riceva piu volte lo status NoChanges.
                    // lo status Processing esiste solo lato FE
                    if (status !== carpetDataStatus)
                        setCarpetDataStatus(dataStatus);
                    else
                        setCarpetDataStatus('Processing');
                }


            } else {
                setIsError(true);
            }

            console.log(`Status ${status}`);
            console.log(`Data Status ${dataStatus}`);
        }

    }

    function addInterval(intervalId) {
        let arr = [...intervalCarpetPing];
        arr.push(intervalId);
        setIntervalCarpetPing(arr);
    }

    function clearCarpetAllInterval() {
        for (let i = 0; i < intervalCarpetPing.length; i++) {
            let intId = intervalCarpetPing[i];
            clearInterval(intId);
        }

        setIntervalCarpetPing([]);
    }

    // metodi filtro
    function callFilterValues(filterSelectedUpdate) {
        getTrainList(filterSelectedUpdate, 0);
    }

    function onChangeSortBy(event) {
        let orderByValue = event.target.selectedOptions[0].value;

        let filterSelectedUpdate = {
            ...filterSelected,
            orderBy: orderByValue
        };

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilter(selectedItems, type) {
        let filterSelectedUpdate = { ...filterSelected };
        switch (type) {
            case 'TrainSuppliers': {
                filterSelectedUpdate.providers = selectedItems;
                break;
            }
            case 'TrainStops': {
                filterSelectedUpdate.stops = selectedItems;
                break;
            }
            case 'TrainVehicle': {
                filterSelectedUpdate.trainType = selectedItems;
                break;
            }
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilterName(number) {
        let filterSelectedUpdate = {
            ...filterSelected,
            trainNumber: number.toUpperCase()
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilterPrice(rangePrice) {
        let filterSelectedUpdate = {
            ...filterSelected,
            rangePrice: rangePrice
        }

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onApplyFilterMultiDestinationTime(selectedItems, type) {
        let trainTimeInfo = { ...filterSelected.trainTimeInfo };

        if (type === 'TrainDestinationTime') {

            if (!trainTimeInfo)
                trainTimeInfo = {};

            let i = 0;

            trainTimeInfo.departureTimeFrom = selectedItems[i].departureTimeFrom;
            trainTimeInfo.departureTimeTo = selectedItems[i].departureTimeTo;
            trainTimeInfo.arrivalTimeFrom = selectedItems[i].arrivalTimeFrom;
            trainTimeInfo.arrivalTimeTo = selectedItems[i].arrivalTimeTo;

        }
        else if (type === 'TrainStops') {

            if (!trainTimeInfo)
                trainTimeInfo = {};

            if (!trainTimeInfo.departureTimeFrom)
                trainTimeInfo.departureTimeFrom = '2099-01-01 00:00:00';

            if (!trainTimeInfo.departureTimeTo)
                trainTimeInfo.departureTimeTo = '2099-01-01 23:59:59';

            if (!trainTimeInfo.arrivalTimeFrom)
                trainTimeInfo.arrivalTimeFrom = '2099-01-01 00:00:00';

            if (!trainTimeInfo.arrivalTimeTo)
                trainTimeInfo.arrivalTimeTo = '2099-01-01 23:59:59';

            // Convert hour ("22:00") to milliseconds values
            let i = 0;
            trainTimeInfo.stopsDurationFrom = convertHourInMilliseconds(selectedItems[i].minStopDuration);
            trainTimeInfo.stopsDurationTo = convertHourInMilliseconds(selectedItems[i].maxStopDuration);

        }

        let filterSelectedUpdate = {
            ...filterSelected,
            trainTimeInfo: trainTimeInfo
        };

        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    function onClearFilter() {
        let filterSelectedUpdate = {
            ...filterSelected,
            rangePrice: null,
            providers: [],
            baggages: [],
            stops: [],
            airportArrivals: [],
            airportDepartures: [],
            mktCarriers: []
        }

        setIsResetSelectedItem(!isResetSelectedItem);
        setFilterSelected(filterSelectedUpdate);
        callFilterValues(filterSelectedUpdate);
    }

    // metodi paginator
    const handlePageClick = async e => {
        setPageSelected(e.selected);

        let filterSelectedUpdate = {
            ...filterSelected,
            pageNumber: e.selected + 1
        }

        setFilterSelected(filterSelectedUpdate);
        getTrainList(filterSelected, e.selected);
    };

    function renderPaginator(totalItems) {
        setPageCount(Math.ceil(totalItems / itemsPerPage));
    }

    async function onClickSearch(legUniqueId, selectFares) {
        let selectedLeg = items.trains.filter(l => l.leg.uniqueId === legUniqueId)[0];
        selectedLeg.selectedFares = selectFares;
        setIsErrorConfirmSeat(false);

        setHaveSeatOutboundError(false);
        setSeatMapOutbound(null);
        setHaveSeatReturnError(false);
        setSeatMapReturn(null);

        if (selectedLeg.fares && selectedLeg.fares.tariffe && selectedLeg.fares.tariffe.length > 0) {
            let supplier = selectedLeg.fares.tariffe[0].supplier;
            if (supplier && supplier.supplierId === "-119") {
                setEnableSelectSeat(false);
            }
        }

        if (solutionType === 1) {

            let inputData = {
                carpetOperation: "AVL",
                clientflowId: clientFlowId,
                sessionToken: sessionToken,
                cultureCode: cultureName,
                trainSearchParameters: originalSearchParameters,
                outboundCarpetId: carpetId,
                outboundLegUniqueId: selectedLeg.leg.uniqueId,
                outboundFareComponentUniqueIds: selectedLeg.selectedFares.map(x => x.fareComponents.uniqueId)
            };

            let carpetIdAvailability = await createTrainCarpetAvailability(inputData);
            setCarpetAvailabilityId(carpetIdAvailability);
            setOutboundCarpetId(carpetId);
            setSelectedItineraryOutbound(selectedLeg);

            if (originalSearchParameters.returnLegCriteria) {
                setSolutionType(2);

                let tsr = { ...originalSearchParameters };

                tsr.directionData = 2;
                tsr.outboundFareArgs = selectedLeg.selectedFares[0].fareArgs;
                tsr.outBoundData = {
                    legUniqueId: inputData.outboundLegUniqueId,
                    carpetId: carpetIdAvailability,
                    fareComponentUniqueIds: inputData.outboundFareComponentUniqueIds
                };

                let inputDataReturnCarpet = {
                    carpetOperation: "SRC",
                    clientFlowId: clientFlowId,
                    sessionToken: sessionToken,
                    websiteId: getWebsiteId(),
                    trainSearchParameters: tsr,
                    productSubType: 110 // train
                };

                let carpetIdReturn = await createTrainCarpet(inputDataReturnCarpet);
                setActualCarpetId(carpetIdReturn);
                setReturnCarpetId(carpetIdReturn);

                setSelectedItineraryReturn(null);
                setReturnSearchParameters(tsr);
                setHaveSeatReturnError(false)

                setIsLoadingListResult(true);
                setShowSelectSeat(false);
            } else {
                setShowSelectSeat(true);
            }
        }

        if (solutionType === 2) {
            let inputData = {
                carpetOperation: "AVL",
                clientflowId: clientFlowId,
                sessionToken: sessionToken,
                cultureCode: cultureName,
                trainSearchParameters: returnSearchParameters,
                returnCarpetId: actualCarpetId,
                returnLegUniqueId: selectedLeg.leg.uniqueId,
                returnFareComponentUniqueIds: selectedLeg.selectedFares.map(x => x.fareComponents.uniqueId)
            };

            let returnCarpetIdAvailability = await createTrainCarpetAvailability(inputData, carpetAvailabilityId);

            setCarpetAvailabilityId(returnCarpetIdAvailability);

            setShowSelectSeat(true);
            setSelectedItineraryReturn(selectedLeg);
        }

        scrollTo(null, "startTrainSearchList");
    }

    function onOpenUpdateOutbound() {
        setSolutionType(1);
        if (actualCarpetId !== outboundCarpetId) {
            setActualCarpetId(outboundCarpetId);
            setIsLoadingListResult(true);
        }

        scrollTo(null, "startTrainList");
    }

    function onOpenUpdateReturn() {
        setSolutionType(2);

        if (actualCarpetId !== returnCarpetId) {
            setActualCarpetId(returnCarpetId);
            setIsLoadingListResult(true);
        }

        scrollTo(null, "startTrainList");
    }

    async function onClickGetSelectSeat() {
        await getSeatSelectionLayout(1);

        if (solutionType === 2)
            await getSeatSelectionLayout(2);

    }

    async function getSeatSelectionLayout(solutionTypeParam) {
        setIsLoadingSelectSeat(true);

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}train/getseatselectionlayout/${carpetAvailabilityId}/${solutionTypeParam}/${cultureName}`, requestOption);

        if (!fetchedRes.ok) {

            if (solutionTypeParam === 1) {
                setHaveSeatOutboundError(true);
                setSeatMapOutbound(null);
            }
            else {
                setHaveSeatReturnError(true);
                setSeatMapReturn(null);
            }

            console.error(fetchedRes);
        } else {
            const response = await fetchedRes.json();

            if (response.success) {
                if (solutionTypeParam === 1) {
                    setHaveSeatOutboundError(false);
                    setSeatMapOutbound(response.seapMaps);
                }
                else {
                    setHaveSeatReturnError(false);
                    setSeatMapReturn(response.seapMaps);
                }

            } else {

                if (solutionTypeParam === 1) {
                    setHaveSeatOutboundError(true);
                    setSeatMapOutbound(null);
                }
                else {
                    setHaveSeatReturnError(true);
                    setSeatMapReturn(null);
                }

            }

        }

        setIsLoadingSelectSeat(false);
    }

    function onSelectSeatOutbound(paxes) {
        debugger;
        setSelectedPaxesSeatOutbound(paxes);
    }

    function onSelectSeatReturn(paxes) {
        setSelectedPaxesSeatReturn(paxes);
    }

    async function onClickConfirmSelectSeat() {
        setIsLoadingConfirmSeat(true);
        let seatSelections = [];
        debugger;
        if (selectedPaxesSeatOutbound) {
            for (let i = 0; i < selectedPaxesSeatOutbound.length; i++) {
                seatSelections.push(selectedPaxesSeatOutbound[i]);
            }
        }


        if (selectedPaxesSeatReturn) {
            for (let i = 0; i < selectedPaxesSeatReturn.length; i++) {
                seatSelections.push(selectedPaxesSeatReturn[i]);
            }
        }

        let requestParam = {
            availabilityCarpetId: carpetAvailabilityId,
            seatSelections: seatSelections
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}train/setseatselection`, requestOption);
        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response.success) {
                setCarpetAvailabilityId(response.carpetId);
                setIsErrorConfirmSeat(false);
            } else {
                setIsErrorConfirmSeat(true);
            }
        } else {
            setIsErrorConfirmSeat(true);
        }

        setIsLoadingConfirmSeat(false);
    }


    async function onClickBook() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}train/addtocart/${carpetAvailabilityId}`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response.success) {
                if (quotationInfo && quotationInfo.quotationId) {
                    if (quotationInfo.isFromCart)
                        window.location.href = `cart-quotation`;
                    else
                        window.location.href = `quotationTO/${quotationInfo.quotationId}`;
                } else {
                    window.location.href = `cart`;
                }
            }

        } else {
            console.log(fetchedRes);
        }
    }

    return (
        <>
            <div className="bg-bluesoft-100">
                {
                    configData.Settings.Website.EngineType === 'WITHNAVBAR' && <ProductNavBar moduleSelected="TRAIN" />
                }
                {
                    configData.Settings.Website.ShowProductHeaderList && <div>
                        <ProductHeader text="" pageCode='Home_Gallery' />
                    </div>
                }
                <div className="container ">
                    <div id="startTrainSearchList" className="row mt-4">
                        {
                            selectedItineraryOutbound && <div className="d-none d-sm-block">
                                <div className="col-12  col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div class="h5">{t('Product:Train:SelectItinerary')}</div>
                                </div>
                                {
                                    <div className="px-0 mb-4">
                                        <TrainSelectedItinerary
                                            itinerary={selectedItineraryOutbound}
                                            type="SELECTSEAT"
                                            showPriceBar={showPriceBar}
                                            onModalOpenProductId={setModalOpenTrainFare}
                                            onClickUpdate={onOpenUpdateOutbound} />

                                        <TrainItinerySeatMap
                                            id="outbound"
                                            seatMap={seatMapOutbound}
                                            haveErrorSeat={haveSeatOutboundError}
                                            onSelectSeat={onSelectSeatOutbound}
                                        />
                                    </div>
                                }
                                {
                                    selectedItineraryReturn && <div className="px-0 mb-4">
                                        <TrainSelectedItinerary
                                            itinerary={selectedItineraryReturn}
                                            type="SELECTSEAT"
                                            showPriceBar={showPriceBar}
                                            onModalOpenProductId={setModalOpenTrainFare}
                                            onClickUpdate={onOpenUpdateReturn} />

                                        <TrainItinerySeatMap
                                            id="return"
                                            seatMap={seatMapReturn}
                                            haveErrorSeat={haveSeatReturnError}
                                            onSelectSeat={onSelectSeatReturn}
                                        />
                                    </div>
                                }
                                {
                                    showSelectSeat && <div className="card shadow shadow-hover mb-4 animate-up-3 px-1 py-2 text-right">
                                        <div className="row no-gutters">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                {
                                                    isLoadingSelectSeat && <>
                                                        {t('Product:Train:SelectSeatLoading')} <div className="spinner-border spinner-border-sm text-primary ml-2" role="status"></div>
                                                    </>
                                                }
                                                {
                                                    haveSeatOutboundError && haveSeatReturnError && <>
                                                        {t('Product:Train:SelectSeatError')}
                                                    </>
                                                }
                                                {
                                                    isLoadingConfirmSeat && <>
                                                        {t('Product:Train:ConfirmSelectSeatLoading')} <div className="spinner-border spinner-border-sm text-primary ml-2" role="status"></div>
                                                    </>
                                                }
                                                {
                                                    isErrorConfirmSeat && <>
                                                        {t('Product:Train:ConfirmSelectSeatError')}
                                                    </>
                                                }
                                                {
                                                    !isLoadingSelectSeat && !isLoadingConfirmSeat && <>
                                                        <div className="d-inline-block mr-2">
                                                            {
                                                                (enableSelectSeat && !haveSeatOutboundError && !haveSeatReturnError) && (templateInfo || quotationInfo || (enableCanAddToCart && enableCanAddToCart.allowed)) &&
                                                                <button className="btn btn-sm tp-btn-select" onClick={_ => onClickGetSelectSeat()}>
                                                                    <data m3lab="Button.Book">
                                                                        {t(`Product:Train:SelectSeat`)}
                                                                    </data>
                                                                </button>
                                                            }
                                                        </div>
                                                        <div className="d-inline-block mr-2">
                                                            {
                                                                (!haveSeatOutboundError || !haveSeatReturnError) && (seatMapOutbound || seatMapReturn) && (templateInfo || quotationInfo || (enableCanAddToCart && enableCanAddToCart.allowed)) &&
                                                                <button className="btn btn-sm tp-btn-select" onClick={_ => onClickConfirmSelectSeat()}>
                                                                    <data m3lab="Button.Book">
                                                                        {t(`Product:Train:ConfirmSeat`)}
                                                                    </data>
                                                                </button>
                                                            }
                                                        </div>
                                                        <div className="d-inline-block">
                                                            {
                                                                (templateInfo || quotationInfo || (enableCanAddToCart && enableCanAddToCart.allowed)) &&
                                                                <button className="btn btn-sm tp-btn-select" onClick={_ => onClickBook()}>
                                                                    <data m3lab="Button.Book">
                                                                        {!templateInfo && !quotationInfo && <span dangerouslySetInnerHTML={{ __html: t(`Button:AddToCart`) }}></span>}
                                                                        {templateInfo && t(`Button:Select`)}
                                                                        {quotationInfo && !quotationInfo.quotationItemIdToChange && t(`Button:AddQuotation`)}
                                                                        {quotationInfo && quotationInfo.quotationItemIdToChange && t(`Button:ReplaceQuotation`)}
                                                                    </data>
                                                                </button>
                                                            }
                                                            <ProductNotAllowedAddToCart enableCanAddToCart={enableCanAddToCart} />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }

                        {/* Loading */}
                        {isLoading && <div className="col-12"><Loading textMsg={t(`Product:Train:LoadingTrain`)} /></div>}

                        {/* Error */}
                        {!isLoading && isError && (!items || !items.trains || items.trains.length === 0) && <div className="col-12"><Error textMsg="Errore in fase di caricamento" /></div>}

                        {/* Items */}
                        {!isLoading && items &&
                            <>
                                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3"></div>

                                <div className="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6">
                                    <div className="d-table h-100">
                                        <div className="d-table-row ">
                                            <div className="d-table-cell align-bottom pb-3 h5">
                                                {
                                                    filterValues && originalSearchParameters && solutionType === 1 && <span className="mr-2">
                                                        <data m3lab="Product.Train.NTrain">
                                                            {t(`Product:Train:NTrain`)} {originalSearchParameters.outboundLegCriteria.departureLocation.stationDesc}
                                                            &nbsp;
                                                            {t(`Product:Train:NTrainTo`)} {originalSearchParameters.outboundLegCriteria.arrivalLocation.stationDesc}
                                                        </data>
                                                    </span>
                                                }
                                                {
                                                    filterValues && returnSearchParameters && solutionType === 2 && <data m3lab="Product.Train.NTrain">
                                                        {t(`Product:Train:NTrain`)} {returnSearchParameters.returnLegCriteria.departureLocation.stationDesc}
                                                        &nbsp;
                                                        {t(`Product:Train:NTrainTo`)} {returnSearchParameters.returnLegCriteria.arrivalLocation.stationDesc}
                                                    </data>
                                                }
                                                <span className="ml-2">
                                                    <var>
                                                        {items.totalTrains}
                                                    </var>
                                                    {
                                                        isLoadingFilter && <div className="spinner-border spinner-border-sm text-primary ml-2" role="status"></div>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-2 col-md-3 col-lg-3 col-xl-3 pt-1 text-right d-none d-sm-block">
                                    <div className="row">
                                        <div className="col-10 text-right pr-0">
                                            <span className="input-group mb-3 text-right">
                                                <select className="form-select border-0" aria-label="Disabled select example" value={filterSelected.orderBy} onChange={(e) => { onChangeSortBy(e); }}>
                                                    <option value="">
                                                        <data m3lab="General.Order.OrderBy">{t(`General:Order:OrderBy`)}</data>
                                                    </option>
                                                    {
                                                        searchCarpetParameter.train.orderByValues.map((item, key) => {
                                                            return <option key={key} value={item.value}>
                                                                <data m3lab={item.label} >{t(item.label)}</data>
                                                            </option>
                                                        })
                                                    }
                                                </select>
                                                <button className="btn btn-neutral m-0 p-0 pr-1 d-inline" type="button" id="button-addon2">
                                                    <data m3ico="SortBy icon-15 text-bluesoft">
                                                        <M3Icon iconName="SortBy" externalClass="icon-15 text-bluesoft p-1" hasLabel={false} />
                                                    </data>
                                                </button>
                                            </span>
                                        </div>
                                        <div className="col-2 pl-0">
                                            {
                                                enableShowNetPrices && <button className={(showPriceBar ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow"} m3rool="f_ShowPriceBar" onClick={(e) => { onChangeShowPriceBar() }}>
                                                    <data m3lab="Button.Net">
                                                        {t("Button:Net")}
                                                    </data>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* Colonna Filtri */}
                                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mt-1">

                                    {
                                        filterValues && filterValues.trainSearchParameters && <>
                                            <ListSearchEngineCV
                                                imageUrl="https://cdn.travelplace.ch/travelplace40/img/train_thum.gif"
                                                searchParameters={originalSearchParameters}
                                                productType={configData.Settings.Products.Train.IdTipoProdotto}
                                                typeSearch="SRC" />
                                            <ListSearchEngineCVMobile
                                                imageUrl="https://cdn.travelplace.ch/travelplace40/img/train_thum.gif"
                                                searchParameters={originalSearchParameters}
                                                productType={configData.Settings.Products.Train.IdTipoProdotto}
                                                typeSearch="SRC" />
                                        </>
                                    }

                                    {
                                        filterValues && <div className='d-none d-sm-block'>

                                            { /* Codice */}
                                            <ProductFilterByName
                                                items={filterValues.trainCodes}
                                                label={t("Product:Train:Filter:TrainCode")}
                                                type="TrainNumber"
                                                onApplyFilter={onApplyFilterName}
                                                resetSelectedItem={isResetSelectedItem} />

                                            <button className="btn btn-sm btn-block tp-btn-search animate-up-2 mt-4" onClick={(e) => onClearFilter(e)}>
                                                <data m3lab="Button.FilterRemove">{t('Button:FilterRemove')}</data>
                                            </button>

                                            { /* Price */}
                                            <ProductFilterByPrice
                                                rangePriceInfo={rangePriceInfo}
                                                onApplyFilter={onApplyFilterPrice}
                                                resetSelectedItem={isResetSelectedItem} />

                                            { /* Cambi */}
                                            <ProductFilter
                                                items={filterValues.stops}
                                                label="Product:Train:Filter:Stops"
                                                type="TrainStops"
                                                onApplyFilter={onApplyFilter}
                                                resetSelectedItem={isResetSelectedItem}
                                                subFilterOptions={{ subItems: filterValues.destinationNames, onApplyFilter: onApplyFilterMultiDestinationTime }} />

                                            { /* Orari Partenze arrivi */}
                                            <ProductFilterMultiDestinationTime
                                                destinationNames={filterValues.destinationNames}
                                                type="TrainDestinationTime"
                                                label="Product:Train:Filter:Hours"
                                                iconTitle="Time"
                                                onApplyFilter={onApplyFilterMultiDestinationTime}
                                                resetSelectedItem={isResetSelectedItem} />

                                            { /* Tipo Treno */}
                                            <ProductFilter
                                                items={filterValues.trainType}
                                                label="Product:Train:Filter:Vehicle"
                                                type="TrainVehicle"
                                                iconTitle="train"
                                                onApplyFilter={onApplyFilter}
                                                resetSelectedItem={isResetSelectedItem} />

                                            { /* Suppliers */}
                                            <ProductFilter
                                                items={filterValues.suppliers}
                                                label="Product:Train:Filter:Suppliers"
                                                type="TrainSuppliers"
                                                iconTitle="shop_two"
                                                onApplyFilter={onApplyFilter}
                                                resetSelectedItem={isResetSelectedItem} />
                                        </div>
                                    }
                                    <div className='d-flex justify-content-between d-sm-none gap-2'>
                                        <div className="d-flex gap-2">
                                            <div className="col-10 text-right pr-0 pl-0">
                                                <span className="input-group mb-3 text-right">
                                                    <select className="form-select border-0" aria-label="Disabled select example" value={filterSelected.orderBy} onChange={(e) => { onChangeSortBy(e); }}>
                                                        <option value="">
                                                            <data m3lab="General.Order.OrderBy">{t(`General:Order:OrderBy`)}</data>
                                                        </option>
                                                        {
                                                            searchCarpetParameter.train.orderByValues.map((item, key) => {
                                                                return <option key={key} value={item.value}>
                                                                    <data m3lab={item.label} >{t(item.label)}</data>
                                                                </option>
                                                            })
                                                        }
                                                    </select>
                                                    <button className="btn btn-neutral m-0 p-0 pr-1 d-inline" type="button" id="button-addon2">
                                                        <data m3ico="SortBy icon-15 text-bluesoft">
                                                            <M3Icon iconName="SortBy" externalClass="icon-15 text-bluesoft p-1" hasLabel={false} />
                                                        </data>
                                                    </button>
                                                </span>
                                            </div>
                                            <div className="col-2 pl-0">
                                                {
                                                    enableShowNetPrices && <button className={(showPriceBar ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow"} m3rool="f_ShowPriceBar" onClick={(e) => { onChangeShowPriceBar() }}>
                                                        <data m3lab="Button.Net">
                                                            {t("Button:Net")}
                                                        </data>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                        <button data-bs-toggle="modal" data-bs-target="#trainFilterModal" className="openFilterButton p-1 px-2 pt-2 rounded border-0 shadow tp-btn-search">
                                            {t(`General:OpenFilter`)}
                                        </button>
                                    </div>
                                </div>
                                {/* Mobile Detailed train */}
                                {
                                    selectedItineraryOutbound && <div className="d-block d-sm-none">
                                        <div className="col-12  col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div class="h5">{t('Product:Train:SelectItinerary')}</div>
                                        </div>
                                        {
                                            <div className="px-0 mb-4">
                                                <TrainSelectedItinerary
                                                    itinerary={selectedItineraryOutbound}
                                                    type="SELECTSEAT"
                                                    showPriceBar={showPriceBar}
                                                    onModalOpenProductId={setModalOpenTrainFare}
                                                    onClickUpdate={onOpenUpdateOutbound} />

                                                <TrainItinerySeatMap
                                                    id="outbound"
                                                    seatMap={seatMapOutbound}
                                                    haveErrorSeat={haveSeatOutboundError}
                                                    onSelectSeat={onSelectSeatOutbound}
                                                />
                                            </div>
                                        }
                                        {
                                            selectedItineraryReturn && <div className="px-0 mb-4">
                                                <TrainSelectedItinerary
                                                    itinerary={selectedItineraryReturn}
                                                    type="SELECTSEAT"
                                                    showPriceBar={showPriceBar}
                                                    onModalOpenProductId={setModalOpenTrainFare}
                                                    onClickUpdate={onOpenUpdateReturn} />

                                                <TrainItinerySeatMap
                                                    id="return"
                                                    seatMap={seatMapReturn}
                                                    haveErrorSeat={haveSeatReturnError}
                                                    onSelectSeat={onSelectSeatReturn}
                                                />
                                            </div>
                                        }
                                        {
                                            showSelectSeat && <div className="card shadow shadow-hover mb-4 animate-up-3 px-1 py-2 text-right">
                                                <div className="row no-gutters">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        {
                                                            isLoadingSelectSeat && <>
                                                                {t('Product:Train:SelectSeatLoading')} <div className="spinner-border spinner-border-sm text-primary ml-2" role="status"></div>
                                                            </>
                                                        }
                                                        {
                                                            haveSeatOutboundError && haveSeatReturnError && <>
                                                                {t('Product:Train:SelectSeatError')}
                                                            </>
                                                        }
                                                        {
                                                            isLoadingConfirmSeat && <>
                                                                {t('Product:Train:ConfirmSelectSeatLoading')} <div className="spinner-border spinner-border-sm text-primary ml-2" role="status"></div>
                                                            </>
                                                        }
                                                        {
                                                            isErrorConfirmSeat && <>
                                                                {t('Product:Train:ConfirmSelectSeatError')}
                                                            </>
                                                        }
                                                        {
                                                            !isLoadingSelectSeat && !isLoadingConfirmSeat && <>
                                                                <div className="d-inline-block mr-2">
                                                                    {
                                                                        (!haveSeatOutboundError && !haveSeatReturnError) && (templateInfo || quotationInfo || (enableCanAddToCart && enableCanAddToCart.allowed)) &&
                                                                        <button className="btn btn-sm tp-btn-select" onClick={_ => onClickGetSelectSeat()}>
                                                                            <data m3lab="Button.Book">
                                                                                {t(`Product:Train:SelectSeat`)}
                                                                            </data>
                                                                        </button>
                                                                    }
                                                                </div>
                                                                <div className="d-inline-block mr-2">
                                                                    {
                                                                        (!haveSeatOutboundError || !haveSeatReturnError) && (seatMapOutbound || seatMapReturn) && (templateInfo || quotationInfo || (enableCanAddToCart && enableCanAddToCart.allowed)) &&
                                                                        <button className="btn btn-sm tp-btn-select" onClick={_ => onClickConfirmSelectSeat()}>
                                                                            <data m3lab="Button.Book">
                                                                                {t(`Product:Train:ConfirmSeat`)}
                                                                            </data>
                                                                        </button>
                                                                    }
                                                                </div>
                                                                <div className="d-inline-block">
                                                                    {
                                                                        (templateInfo || quotationInfo || (enableCanAddToCart && enableCanAddToCart.allowed)) &&
                                                                        <button className="btn btn-sm tp-btn-select" onClick={_ => onClickBook()}>
                                                                            <data m3lab="Button.Book">
                                                                                {!templateInfo && !quotationInfo && <span dangerouslySetInnerHTML={{ __html: t(`Button:AddToCart`) }}></span>}
                                                                                {templateInfo && t(`Button:Select`)}
                                                                                {quotationInfo && !quotationInfo.quotationItemIdToChange && t(`Button:AddQuotation`)}
                                                                                {quotationInfo && quotationInfo.quotationItemIdToChange && t(`Button:ReplaceQuotation`)}
                                                                            </data>
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                { /* Colonna Items */}
                                <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 pt-1">
                                    {
                                        !isLoadingListResult && items && items.trains && items.trains.map((item, key) => {
                                            return <TrainItemList key={key}
                                                item={item}
                                                showPriceBar={showPriceBar}
                                                showCost={showCost}
                                                modalOpenProductId={setModalOpenTrainFare}
                                                onClickSearch={onClickSearch}
                                            />
                                        })
                                    }
                                    {
                                        isLoadingListResult && <div className="text-center"><Loading textMsg="Caricamento risultati in corso" /></div>
                                    }
                                    {
                                        !isLoadingListResult && (!items || (!items.trains || items.trains.length === 0)) && <ProductItemNotFound label={t("Product:Train:NotFound")} />
                                    }
                                </div>

                                {
                                    modalOpenTrainFare && <TrainModal trainLeg={modalOpenTrainFare}
                                        carpetId={actualCarpetId}
                                        modalCloseProductId={setModalOpenTrainFare} />
                                }

                                { /* Paginator */}
                                {
                                    items && items.trains && items.trains.length > 0 && <>
                                        <div className="col-12 col-sm-3 col-md-3 col-lg-39 col-xl-3 pt-1"></div>
                                        <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9 pt-1">
                                            <nav aria-label="Page navigation comments" className="mt-4">
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t('pagination:nextLabel')}
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel={t('pagination:previousLabel')}
                                                    renderOnZeroPageCount={null}
                                                    forcePage={pageSelected}

                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    marginPagesDisplayed={2}
                                                    containerClassName="pagination justify-content-center flex-wrap"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    activeClassName="active"
                                                />
                                            </nav>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="modal tp-search-input fade" id="trainFilterModal" tabIndex="-1" aria-labelledby="EngineModifyActivty" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen-xxl-down">
                    <div className="modal-content">
                        <div className="modal-header p-0">
                            <div className="h4 text-center w-100 mt-2">
                                {t('SearchEngine:UpdateSearch')}
                            </div>
                            <button type="button" className="btn-close mt-2 pr-4" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body bg-gray-300">
                            {

                                filterValues && <div>

                                    { /* Codice */}
                                    <ProductFilterByName
                                        items={filterValues.trainCodes}
                                        label={t("Product:Train:Filter:TrainCode")}
                                        type="TrainNumber"
                                        onApplyFilter={onApplyFilterName}
                                        resetSelectedItem={isResetSelectedItem} />

                                    <button className="btn btn-sm btn-block tp-btn-search animate-up-2 mt-4" onClick={(e) => onClearFilter(e)}>
                                        <data m3lab="Button.FilterRemove">{t('Button:FilterRemove')}</data>
                                    </button>

                                    { /* Price */}
                                    <ProductFilterByPrice
                                        rangePriceInfo={rangePriceInfo}
                                        onApplyFilter={onApplyFilterPrice}
                                        resetSelectedItem={isResetSelectedItem} />

                                    { /* Cambi */}
                                    <ProductFilter
                                        items={filterValues.stops}
                                        label="Product:Train:Filter:Stops"
                                        type="TrainStops"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem}
                                        subFilterOptions={{ subItems: filterValues.destinationNames, onApplyFilter: onApplyFilterMultiDestinationTime }} />

                                    { /* Orari Partenze arrivi */}
                                    <ProductFilterMultiDestinationTime
                                        destinationNames={filterValues.destinationNames}
                                        type="TrainDestinationTime"
                                        label="Product:Train:Filter:Hours"
                                        iconTitle="Time"
                                        onApplyFilter={onApplyFilterMultiDestinationTime}
                                        resetSelectedItem={isResetSelectedItem} />

                                    { /* Tipo Treno */}
                                    <ProductFilter
                                        items={filterValues.trainType}
                                        label="Product:Train:Filter:Vehicle"
                                        type="TrainVehicle"
                                        iconTitle="train"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem} />

                                    { /* Suppliers */}
                                    <ProductFilter
                                        items={filterValues.suppliers}
                                        label="Product:Train:Filter:Suppliers"
                                        type="TrainSuppliers"
                                        iconTitle="shop_two"
                                        onApplyFilter={onApplyFilter}
                                        resetSelectedItem={isResetSelectedItem} />
                                </div>

                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}