import React, { useEffect, useState, useCallback } from 'react';
import configData from "../../../../appsettings.json";
import Cookies from 'universal-cookie';
import { M3Icon } from "../../../Common/M3Icon";
import { useTranslation } from 'react-i18next';
import { formatDateTime, formatPrice } from '../../../../js/Utils.js';

export const FlightChooseFare = ({ fare, fareSelected, selectedFarePrice, handleClickFare }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);

    useEffect(() => {
        if (fare) 
            setIsLoadingPrint(false);
    }, [fare]);


    function getNameFareArr(name) {
        let arrTmp = [];
        arrTmp = name.split("/");

        if (arrTmp.length === 1)
            arrTmp.push("");

        return (
            <>
                {arrTmp.length === 2 &&
                    <>
                        {arrTmp[0]}
                        <label>{arrTmp[1]}</label>
                    </>
                }
                {arrTmp.length === 1 &&
                    arrTmp[0]
                }
            </>
        );
    }

    const onClickFare = useCallback(event => {
        handleClickFare(event);
        setIsLoadingPrint(true);
    }, [handleClickFare]);


    function getTooltips(tooltips) {
        let res = "";
        tooltips.map(tooltip => {
            res = res + tooltip + "\n";
        });

        return res;
    }

    function getTitle(fare) {
        return (
            <>
                {fare.titles.map((fareTitle, idx) =>
                    <>
                        <div title={fareTitle.tooltips.length > 0 && getTooltips(fareTitle.tooltips)}>
                            {getNameFareArr(fareTitle.name)}&nbsp;
                        </div>
                        {(idx < fare.titles.length - 1 ? " -" : "")}&nbsp;
                    </>
                )}
            </>
        );
    }

    function getBaggages(baggage) {
        let hasBag = false;

        if (baggage.baggagesOnlyQuantity.length > 0) {
            baggage.baggagesOnlyQuantity.map(quantity => {
                if (quantity !== 0) {
                    hasBag = true;
                }
            })
        }

        return (
            <>
                {hasBag && baggage.baggages.map((bag, idx) =>
                    <>
                        {bag} &nbsp;
                    </>
                )}
                {!hasBag && t(`Product:Flight:NoBaggage`)}
            </>
        );
    }
    return (
        <>
        {fare && 
            <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="bg-white rounded shadow my-1">
                    <div className="col-12 text-center bg-gray-300 rounded-top">
                    {fare.renderFaresDetailsToChoose &&
                        <div className="d-flex justify-content-center">
                            {getTitle(fare.renderFaresDetailsToChoose)}
                        </div>
                    }
                    </div>
                    <div className="p-2">
                            <div className="d-table h075 w-100">
                                {
                                    fare.supplier && <div className="d-table-row">
                                        <div className="d-table-cell">
                                            GDS
                                        </div>
                                        <div className="d-table-cell px-2 text-end">
                                            {fare.renderFaresDetailsToChoose.supplier}
                                        </div>
                                    </div>
                                }
                            <div className="d-table-row">
                                <div className="d-table-cell">
                                    <data m3lab="Product.Flight.Fare">
                                        {t(`Product:Flight:Fare`)}
                                    </data>
                                </div>
                                <div className="d-table-cell px-2 text-end">
                                    {fare.renderFaresDetailsToChoose.price && fare.renderFaresDetailsToChoose.taxes && formatPrice(fare.renderFaresDetailsToChoose.price.displayPrice - fare.renderFaresDetailsToChoose.taxes.displayPrice, fare.renderFaresDetailsToChoose.price.valutaCliente, cultureName)}
                                </div>
                            </div>
                            <div className="d-table-row">
                                <div className="d-table-cell">
                                    <data m3lab="Product.Flight.Taxes">
                                        {t(`Product:Flight:Taxes`)}
                                    </data>
                                </div>
                                <div className="d-table-cell px-2 text-end">
                                    {fare.renderFaresDetailsToChoose.taxes && formatPrice(fare.renderFaresDetailsToChoose.taxes.displayPrice, fare.renderFaresDetailsToChoose.taxes.valutaCliente, cultureName)}
                                </div>
                            </div>
                            {/*<div className="d-table-row">
                                <div className="d-table-cell">
                                    <span className="material-icons text-green icon-15">
                                        done
                                    </span>
                                    Bagaglio a mano
                                </div>
                                <div className="d-table-cell px-2 text-center">
                                    1 pc (20kg)
                                </div>
                            </div>*/}
                          
                            {/*fare.renderFaresDetailsToChoose && fare.renderFaresDetails.length > 0 && fare.renderFaresDetails[0].renderFareDetails && fare.renderFaresDetails[0].renderFareDetails.length > 0 && fare.renderFaresDetails[0].renderFareDetails[0].baggage ?
                                <data m3ico="Done text-danger icon-15">
                                    <M3Icon iconName="Done" externalClass="text-success icon-15" />
                                </data>
                            :
                                <data m3ico="Close text-danger icon-15">
                                        <M3Icon iconName="Close" externalClass="text-danger icon-15" />
                                </data>
                            <data m3lab="Product.Flight.HoldBaggage">
                                {t(`Product:Flight:Baggages`)}
                            </data>:
                            */}
                            <div className="d-table-row">
                                <div className="d-table-cell pt-2 ">
                                     <data m3lab="Product.Flight.HoldBaggage" style={{fontWeight: "600"}}>
                                        {t(`Product:Flight:Baggages`)}
                                    </data>:
                                </div>
                            </div>

                            {fare.renderFaresDetailsToChoose.baggages && fare.renderFaresDetailsToChoose.baggages.length > 0 && fare.renderFaresDetailsToChoose.baggages.map(bagagge =>
                            <>
                                <div className="d-table-row">
                                    <div className="d-table-cell">
                                        {bagagge.tratta} &nbsp;
                                    </div>
                                        <div className="d-table-cell pl-2 text-end">
                                        {bagagge.baggages.length > 0 &&
                                            getBaggages(bagagge)
                                        }
                                    </div>
                                </div>
                            </>
                            )}
                            {/*<div className="d-table-cell px-2">
                                {fare.renderFaresDetails && fare.renderFaresDetails.length > 0 && fare.renderFaresDetails[0].renderFareDetails && fare.renderFaresDetails[0].renderFareDetails.length > 0 && fare.renderFaresDetails[0].renderFareDetails[0].baggage }
                            </div>*/}
                            {/*<div className="d-table-row">
                            <div className="d-table-cell">
                                <span className="material-icons text-danger icon-15">
                                    close
                                </span>
                                Assegnazione posto
                            </div>
                            <div className="d-table-cell px-2 text-center">
                                =
                            </div>
                            </div>*/}
                            {/*<div className="d-table-row">
                            <div className="d-table-cell">
                                <span className="material-icons text-danger icon-15">
                                    close
                                </span>
                                Rimborsabile
                            </div>
                            <div className="d-table-cell px-2 text-center">
                                =
                            </div>
                        </div>*/}
                        </div>
                        <div className=" text-center">
                            <div className="h6 mt-2 mb-0">
                                {fareSelected === fare.uniqueId ?
                                    fare.price && formatPrice(fare.price.displayPrice, fare.price.valutaCliente, cultureName)
                                :
                                    selectedFarePrice && fare.price &&
                                       (Math.sign(fare.price.displayPrice - selectedFarePrice) === 1 ? "+ " : "") + formatPrice(fare.price.displayPrice - selectedFarePrice, fare.price.valutaCliente, cultureName)
                                }
                            </div>
                            {fareSelected === fare.uniqueId ?
                                <button className="btn btn-sm tp-btn-search selected">
                                    <data m3lab="Button.Selected">
                                        {t(`Button:Selected`)}
                                    </data>
                                </button>
                            :
                                <button className="btn btn-sm tp-btn-select" onClick={(e) => onClickFare(fare.uniqueId)}>
                                    {isLoadingPrint ?
                                        <>
                                            <div className="spinner-border spinner-border-sm text-white" role="status"></div>
                                        </>
                                    :
                                        <data m3lab="Button.Change">
                                            {t(`Button:Change`)}
                                        </data>
                                    }
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    );
}