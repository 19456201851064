import React from 'react';
import { M3Icon } from "../../../Common/M3Icon";


export const FlightFareRules = ({ fareRules }) => {

    return (
        <>
            {fareRules && fareRules.length > 0 && fareRules.map((fareRule, keyRules) =>
                <React.Fragment key={keyRules}>
                    <a className="text-decoration-none h075 pl-2 collapsed mt-1" data-bs-toggle="collapse" href={"#CondizioniFlight" + keyRules} role="button" aria-expanded="false" aria-controls={"CondizioniFlight" + keyRules}>
                        <label className="h6 mb-0 mt-1" key={keyRules}>
                            {
                                fareRule && fareRule.fareRules.length > 0 && <data m3ico="NavigateNext bg-blue text-white icon-15">
                                    <M3Icon iconName="NavigateNext" externalClass="bg-blue text-white icon-15" />
                                </data>
                            }
                            <span className="mx-1">{fareRule.flightInfo}</span>
                        </label>
                    </a>
                    {fareRule && fareRule.fareRules.length > 0 && fareRule.fareRules.map((rule, keyRule) =>
                        <li id={"CondizioniFlight" + keyRules} className="collapse list-group-item border-0 ml-3 pt-0 pb-0 " key={keyRule}>
                            <a className="text-decoration-none h075 pl-2 collapsed" data-bs-toggle="collapse" href={"#Condizioni" + keyRule} role="button" aria-expanded="false" aria-controls={"Condizioni" + keyRule}>
                                <h6 className="mb-0">{keyRule + 1}. {rule.title}</h6>
                            </a>
                            <div className="collapse" id={"Condizioni" + keyRule}>
                                <div className="card card-body">
                                    <div id={"Condizioni" + keyRule} className="accordion-collapse collapse" role="region" aria-labelledby="heading1c">
                                        <div className="accordion-body">
                                            {rule.text.map((text, keyText) =>
                                                <span key={keyText}>
                                                    {text} <br />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    )}
                </React.Fragment>
            )}
        </>
    );
}