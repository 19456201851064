import React from 'react';
import { Document, Page, View, Text } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import ReactDOMServer from "react-dom/server";
import { PrintVoucherHeader } from './PrintVoucherHeader';
import { PrintVoucherFooter } from './PrintVoucherFooter';
import { FooterPDFPrint } from '../../../../Custom/Print/FooterPDFPrint';
import { HeaderPDFPrint } from '../../../../Custom/Print/HeaderPDFPrint';

export const PrintPdfVoucher = ({ html, stylesheet, extraInfo, footerElements, headerElements }) => {
    const headerHtml = ReactDOMServer.renderToString(
        <HeaderPDFPrint headerElements={headerElements} extraInfo={extraInfo} />
    );
    const footerHtml = ReactDOMServer.renderToString(
        <FooterPDFPrint footerElements={footerElements} extraInfo={extraInfo} />
    );

    /* wrap = true  permette di accordare nella stessa pagina, wrap = false no,   break = true  obbliga l'elemento a saltare nella pagina successiva'  */
    return (
        <Document>
            <Page size="A4" wrap style={stylesheet.page}>
                <View fixed>
                    <Html stylesheet={stylesheet}>
                        {headerHtml}
                    </Html>
                </View>

                {
                    html.map((item, key) => {
                        return <View key={key} wrap={item.wrap} break={item.break} style={stylesheet.bodyPdf}>
                            <Html stylesheet={stylesheet}>
                                {item.html}
                            </Html>
                        </View>
                    })
                }

                <View fixed style={stylesheet.footer}>
                    <Html stylesheet={stylesheet}>
                        {footerHtml}
                    </Html>
                    <Text style={stylesheet.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </View>
            </Page>
        </Document>
    );
}