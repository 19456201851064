import React, { useState, useEffect } from 'react';
import configData from "../../../appsettings.json";
import { handleError, formatDateTime } from '../../../js/Utils.js';
import { SelectContact } from '../../MyBook/Rubrica/components/SelectContact.js';
import { Loading } from '../../Common/Loading.js';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PrintPdf } from '../../Common/PrintPdf';
import { printCssStyle } from './../../Custom/Print/PrintCss';
import { downloadDocumentPDF } from "../../../js/PrintUtility";
import { generateQuotationHTMLFromAgency } from "../service/QuotationPrintService";
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { M3Icon } from '../../Common/M3Icon';
import 'react-quill/dist/quill.snow.css';

export const QuotationBaseButton = ({ quotationId, authCode, pdfFileName, quotationMode, quotationHeader, quotationPaxes, quotationTableMarginItems, quotationRecapItems, quotationItemStepPrint, dynamicNotes, customerInfoQuotation, scadenziario, cultureName, callOnSendMailAgency, sendMailMsg, quotationAllowedOperation }) => {

    const { t } = useTranslation();
    useEffect(() => {

        if ((quotationHeader.authorizationToSend && quotationMode === 'QuotationTOWorking') || (quotationMode === 'QuotationWorking')) {
            let sendMail = document.getElementById('sendMail');
            sendMail.addEventListener('shown.bs.collapse', function () {
                callGetToSendMailAgency();
                setLoadingSendMail(false);
                setSendMailResult("");
            });

            sendMail.addEventListener('hidden.bs.collapse', function () {
                setSendMailInfo({});
                setIsLoadingGetSendMail(true);
                setLoadingSendMail(false);
                setSendMailResult("");
            });
        }

        if (quotationAllowedOperation) {
            setSendMailRubrica(quotationAllowedOperation.SendMailOnlyRubrica.enabled);
        }

    }, []);

    useEffect(() => {
        if (scadenziario)
            getScadenziarioPrint(scadenziario);
    }, [scadenziario])

    // Funzionalità di stampa
    const [isFinishPrintPDF, setIsFinishPrintPDF] = useState(0);
    const [isDownloadPDF, setIsDownloadPDF] = useState(true);
    const [myHtml, setMyHtml] = useState([]);
    const [scadenziarioPrint, setScadenziarioPrint] = useState([]);
    const [extraInfoPrint, setExtraInfoPrint] = useState({});
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);
    const [isLoadingPrintCliente, setIsLoadingPrintCliente] = useState(false);

    function dowloadPDFAfterRender(filename, url) {
        downloadDocumentPDF(filename, url);

        setIsFinishPrintPDF(isFinishPrintPDF + 1);
        setIsDownloadPDF(true);

        setIsLoadingPrint(false);
        setIsLoadingPrintCliente(false);
    }

    function callPrintPDF() {
        let myUrl = `${configData.Settings.Base_Url}Documents/quotation/` + quotationId;
        if (authCode)
            myUrl += "?ac=" + authCode
        window.location.href = myUrl;

        /*let myArr = generateQuotationHTMLFromAgency(
            quotationHeader,
            quotationHeader.quotationId,
            quotationTableMarginItems,
            quotationRecapItems,
            quotationItemStepPrint,
            customerInfoQuotation,
            quotationPaxes,
            dynamicNotes,
            scadenziarioPrint,
            cultureName
        );

        setIsDownloadPDF(false);
        setMyHtml(myArr);
        setExtraInfoPrint({ numeroOrdine: quotationHeader.numeroOrdine });*/
    }

    function callPrintPDFCliente() {
        let myUrl = `${configData.Settings.Base_Url}Documents/quotation/` + quotationId;
        if (authCode)
            myUrl += "?ac=" + authCode

        window.location.href = myUrl;
        /*
        let myArr = generateQuotationHTMLFromAgency(
            quotationHeader,
            quotationHeader.quotationId,
            quotationTableMarginItems,
            quotationRecapItems,
            quotationItemStepPrint,
            customerInfoQuotation,
            quotationPaxes,
            dynamicNotes,
            scadenziarioPrint,
            cultureName,
            true
        );

        setIsDownloadPDF(false);
        setMyHtml(myArr);
        setExtraInfoPrint({ numeroOrdine: quotationHeader.numeroOrdine, isPrintCliente: true });
        */
    }

    function onClickPrintPDF() {
        setIsLoadingPrint(true);
        callPrintPDF();
    }

    function onClickPrintPDFCliente() {
        setIsLoadingPrintCliente(true);
        callPrintPDFCliente();
    }

    function getScadenziarioPrint(scadenziario) {
        let resultObj = scadenziario.deadlineGroups.reduce(function (r, a) {
            let data = formatDateTime(a.deadlineDate, cultureName);
            r[data] = r[data] || [];
            r[data].push(a);

            return r;
        }, Object.create(null));

        var resultArr = Object.entries(resultObj);

        setScadenziarioPrint(resultArr);
    }

    // Funzionalità invia
    useEffect(() => {
        setSendMailResult(sendMailMsg);
        setLoadingSendMail(false);
    }, [sendMailMsg]);

    const [isLoadingGetSendMail, setIsLoadingGetSendMail] = useState(true);
    const [sendMailInfo, setSendMailInfo] = useState({});
    const [loadingSendMail, setLoadingSendMail] = useState(false);
    const [sendMailResult, setSendMailResult] = useState("");
    const [isValidSendMailInfo, setisValidSendMailInfo] = useState({
        toMail: true,
        fromMail: true,
        body: true
    });
    const [selectContacts, setSelectContacts] = useState([]);
    const [sendMailRubrica, setSendMailRubrica] = useState(false);
    const [canAutoLogin, setCanAutoLogin] = useState(false);
    const [defaultUserIdToSend, setDefaultUserIdToSend] = useState(null);
    const [defaultContactIdToSend, setDefaultContactIdToSend] = useState(null);

    function callGetToSendMailAgency() {
        setIsLoadingGetSendMail(true);

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(`${configData.Settings.CommonApi_BaseUrl}quotationTO/gettosendmailagency/${quotationHeader.quotationId}`, requestOption)
            .then((res) => handleError(res))
            .then((item) => {
                let mailInfos = item;
                if (sendMailRubrica) {
                    mailInfos.toMails = '';
                    let updateIsValidSendMailInfo = { ...isValidSendMailInfo };
                    updateIsValidSendMailInfo.toMail = false;

                    setisValidSendMailInfo(updateIsValidSendMailInfo);
                }

                if (mailInfos.defaultUserIdToSend)
                    setDefaultUserIdToSend(mailInfos.defaultUserIdToSend);

                if (mailInfos.defaultContactIdToSend)
                    setDefaultContactIdToSend(mailInfos.defaultContactIdToSend);

                delete mailInfos.defaultUserIdToSend;
                delete mailInfos.defaultContactIdToSend;

                setSendMailInfo(mailInfos);
                setIsLoadingGetSendMail(false);
                setCanAutoLogin(false);
            })
            .catch((err) => {
                console.error(err);
                setIsLoadingGetSendMail(false);
                setCanAutoLogin(false);
            });
    }

    function callSendMailAgency() {
        setLoadingSendMail(true);
        const sendToContact = selectContacts && selectContacts.length > 0;
        sendMailInfo.canAutoLogin = canAutoLogin && sendToContact;

        sendMailInfo.endUserId = null;
        if (sendToContact)
            sendMailInfo.endUserId = selectContacts[0].userId;

        callOnSendMailAgency(sendMailInfo);
    }

    function onBlurSendMailInfo(event, property, externalMode = false) {
        let myValue = "";
        if (externalMode)
            myValue = event;
        else
            myValue = property === 'body' ? event : event.target.value;

        let oldSendMailInfo = { ...sendMailInfo };
        let updateIsValidSendMailInfo = { ...isValidSendMailInfo };

        switch (property) {
            case 'fromMail': {
                let isValidMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(myValue);
                oldSendMailInfo.fromMail = myValue;
                updateIsValidSendMailInfo.fromMail = isValidMail;
                break;
            }
            case 'toMail': {
                let mails = myValue.split(';');
                let isValidAllMails = true;
                for (let i = 0; i < mails.length; i++) {
                    let isValidMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(mails[i]);
                    isValidAllMails = isValidAllMails && isValidMail;
                }

                oldSendMailInfo.toMail = myValue;
                updateIsValidSendMailInfo.toMail = isValidAllMails;
                break;
            }
            case 'body': {
                updateIsValidSendMailInfo.body = myValue ? true : false;
                oldSendMailInfo.body = myValue;
                break;
            }
        }

        setSendMailInfo(oldSendMailInfo);
        setisValidSendMailInfo(updateIsValidSendMailInfo);
    }

    function onCheckAutoLogin() {
        setCanAutoLogin(!canAutoLogin);
    }

    function onSelectContact(contact) {
        let myArray = [];
        if (contact) {
            const exists = selectContacts.some((x) => x.contactId === contact.contactId);

            if (!exists)
                myArray.push(contact);

            let toMails = '';

            if (myArray && myArray.length > 0) {
                myArray.forEach((x) => {
                    toMails += x.email + ";";
                });
            }

            toMails = toMails.slice(0, -1);

            onBlurSendMailInfo(toMails, 'toMail', true)
        }

        setSelectContacts(myArray);
    }

    return (
        <>
            <span>
                {
                    !isDownloadPDF && <PDFDownloadLink className="lnkPDFDownload" document={<PrintPdf html={myHtml} title={quotationHeader ? quotationHeader.name : ""} stylesheet={printCssStyle} extraInfo={extraInfoPrint} />} fileName={pdfFileName} >
                        {({ blob, url, loading, error }) => {
                            if (!loading && blob) {
                                dowloadPDFAfterRender(pdfFileName, URL.createObjectURL(blob));
                            }
                        }}
                    </PDFDownloadLink>
                }
            </span>

            <div className="row justify-content-md-center mb-4 button-inline-container">
                {
                    (quotationMode === 'QuotationTOWorking' || quotationMode === 'QuotationWorking') && <div className="col-2">
                        <button id="openSendMailQuotationAuto" className="btn mr-2 btn-tertiary w-100" data-bs-toggle="collapse" data-bs-target="#sendMail" aria-expanded="true" aria-controls="formHotel">
                            <M3Icon iconName="Send" externalClass="h075 mr-2" /> {t('Button:Send')}
                        </button>
                    </div>
                }
                {
                   <div className="col-2">
                        <button className="btn btn-outline-gray w-100" onClick={onClickPrintPDF} >
                            {!isLoadingPrint && <><M3Icon iconName="Print" externalClass="h075 mr-2" /> {t('Button:Print')}</>}
                            {
                                isLoadingPrint && <><div className="spinner-border spinner-border-sm text-dark h6 " role="status">
                                    <span className="sr-only"></span>
                                </div></>
                            }
                        </button>
                   </div>
                }
                {
                    quotationMode === 'QuotationTOWorking' && <div className="col-2">
                        <a href={`./quotationtoadv/${quotationHeader.quotationId}`} className="btn btn-outline-gray w-100">
                            <M3Icon iconName="Search" externalClass="h075 mr-2" /> {t("MyBook:Agency")}
                        </a>
                    </div>
                }
            </div>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    {
                        ((quotationHeader.authorizationToSend && quotationMode === 'QuotationTOWorking') || (quotationMode === 'QuotationWorking')) && <div id="sendMail" className="accordion-collapse collapse bg-white p-2 mb-4" role="region" aria-labelledby="heading1HM">
                            {isLoadingGetSendMail && <Loading />}
                            {(quotationMode === 'QuotationTOWorking' || quotationMode === 'QuotationWorking') && !isLoadingGetSendMail && <>
                                {
                                    sendMailInfo.defaultFromMail && <div className="form-group">
                                        {t("MyBook:MailWillSendAt")}: {sendMailInfo.defaultFromMail}. {t("MyBook:MailWillSendAtText")}
                                    </div>
                                }
                                <div className="form-group">
                                    <label htmlFor="name" className="active">{t("MyBook:SenderMail")}</label>
                                    <div className="input-group mb-4">
                                        <input
                                            className={"form-control " + !isValidSendMailInfo.fromMail ? " border-danger" : ""}
                                            id="name"
                                            type="text"
                                            value={sendMailInfo.fromMail}
                                            aria-label="contact name input"
                                            onChange={e => onBlurSendMailInfo(e, 'fromMail')} />
                                        {
                                            !isValidSendMailInfo.fromMail && <div className="text-danger">{t("MyBook:MailNotValid")}</div>
                                        }
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email" className="active">{t("MyBook:RecipientMail")}</label>
                                    <div className="input-group mb-4">
                                        <input
                                            className={"form-control " + !isValidSendMailInfo.toMail ? " border-danger" : ""}
                                            id="email"
                                            type="text"
                                            disabled={selectContacts && selectContacts.length > 0}
                                            value={sendMailInfo.toMail}
                                            aria-label="contact email input"
                                            onChange={e => onBlurSendMailInfo(e, 'toMail')} />
                                        {
                                            !isValidSendMailInfo.toMail && <div className="text-danger">{t("MyBook:InsertMailNotValid")}</div>
                                        }
                                    </div>
                                </div>
                                {
                                    sendMailRubrica && <div style={{ "marginBottom": "50px" }}>
                                        <SelectContact
                                            titleForm={t("MyBook:SelectContactTitle")}
                                            subTitleForm={t("MyBook:SelectContactSubTitle")}
                                            mode="QUOTATION"
                                            extraParams={{ canAutoLogin: canAutoLogin, onCheckAutoLogin: onCheckAutoLogin }}
                                            defaultUserIdToSend={defaultUserIdToSend}
                                            defaultContactIdToSend={defaultContactIdToSend}
                                            selectContacts={selectContacts}
                                            onSelectContact={onSelectContact} />
                                    </div>
                                }
                                <div className="form-group">
                                    <label htmlFor="message" className="active">{t("MyBook:InsertYourMessage")}</label>
                                    <ReactQuill
                                        id="reactQuillMail"
                                        className={!isValidSendMailInfo.toMail ? " border-danger" : ""}
                                        theme="snow"
                                        modules={{ clipboard: { matchVisual: true } }}
                                        value={sendMailInfo.body}
                                        onChange={(value) => {
                                            onBlurSendMailInfo(value, 'body');
                                        }}
                                        onBlur={(range, source, quill) => {
                                            onBlurSendMailInfo(quill.getHTML(), 'body');
                                        }}
                                    />
                                    {
                                        !isValidSendMailInfo.body && <div className="text-danger">{t("MyBook:InsertMailText")}</div>
                                    }
                                </div>
                                {
                                    !loadingSendMail && <button
                                        className="btn btn-block rounded btn-outline-tertiary"
                                        disabled={!(isValidSendMailInfo.toMail && isValidSendMailInfo.fromMail && isValidSendMailInfo.body)}
                                        onClick={callSendMailAgency}
                                    >
                                        {t("MyBook:SendEmail")}
                                    </button>
                                }
                                {
                                    loadingSendMail && <div className="text-center">
                                        <div className="spinner-border spinner-border-sm text-dark h6 " role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                }
                                {<h6 className="text-center mt-2 mb-2">{sendMailResult}</h6>}
                            </>
                            }
                        </div>
                    }
                </div>
            </div>
        </>
    );
}