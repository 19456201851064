import React, { useEffect, useState } from 'react';
import configData from "../../appsettings.json";
import { activityDuration } from "../../js/Enums";
import { manualRangeParameter } from "../../js/Constant";
import { handleError, addDaysToDatePicker } from "../../js/Utils";
import { InputSearchDestinationModal } from "../Common/Destination/InputSearchDestinationModal";
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { AddServiceNote } from './components/AddServiceNote';

export const ActivityManual = (props) => {
    const { t } = useTranslation();
    const today = new Date().toISOString().split("T")[0];

    const activityDetailDefault = {
        id: -1,
        productType: configData.Settings.Products.Activity.IdTipoProdotto,
        productSubType: 'NOT',
        name: '',
        address: '',
        city: {},
        providerId: 'NOT',

        imageUrl: '',
        payBy: '',
        reservationId: '',
        recordLocator: '',
        internalNote: '',

        checkIn: '',
        checkOut: '',
        cancelPolicy: '',

        serviceNote: null,
        serviceNoteDesc: null,

        optionName: '',
        durationTypeId: 'NOT',
        durationTypeName: '',
        buyPriceAmount: null,
        buyPriceCurrency: 'EUR',
        buyPriceChange: null,
        totalAdults: manualRangeParameter.activity.defaultAdults,
        totalChildren: manualRangeParameter.activity.defaultChildren,
        ages: []
    }
    const validateActivityDefault = {
        isValidName: false,
        isTouchedName: false,
        isValidAddress: false,
        isTouchedAddress: false,
        isValidCity: false,
        isTouchedCity: false,
        isValidProviderId: false,
        isTouchedProviderId: false,
        isValidCheckIn: false,
        isTouchedCheckIn: false,
        isValidCheckOut: true,
        isValidCancelPolicy: false,
        isTouchedCancelPolicy: false,

        isTouchedBuyPriceAmount: false,
        isValidBuyPriceAmount: false,

        isValidProductSubType: false,
        isTouchedProductSubType: false,
        isValidOptionName: false,
        isTouchedOptionName: false,

        isValidForm: false
    }

    const [currencies, setCurrencies] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [activityTypes, setActivityTypes] = useState([]);

    const [activityDetail, setActivityDetail] = useState({ ...activityDetailDefault });
    const [validateInputData, setValidateInputData] = useState({ ...validateActivityDefault });
    const [validateValueDefault, setValidateValueDefault] = useState({ maxCancelPolicy: '' });

    useEffect(() => {
        if (props.isOpen) {
            if (currencies.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}constant/getcurrency`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setCurrencies(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (suppliers.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}customer/getallsupplierforproduct/${configData.Settings.Products.Activity.IdTipoProdotto}`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setSuppliers(item);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (activityTypes.length < 1) {
                const requestOption = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                fetch(`${configData.Settings.CommonApi_BaseUrl}activity/getactivitymanualtypes`, requestOption)
                    .then((res) => handleError(res))
                    .then((item) => {
                        setActivityTypes(item.filter((x) => x.value !== 12));
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            if (!props.item) {
                setActivityDetail({ ...activityDetailDefault });
                setValidateInputData({ ...validateActivityDefault });
            }
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (!props.item) {
            setActivityDetail({ ...activityDetailDefault });
            setValidateInputData({ ...validateActivityDefault });
        } else if (props.item.productType === configData.Settings.Products.Activity.IdTipoProdotto) {
            setActivityDetail(props.item);

            let isValidAD = { ...validateActivityDefault };
            Object.keys(isValidAD).forEach(key => {
                isValidAD[key] = true;
            });

            setValidateInputData(isValidAD);
        }
    }, [props.item]);

    function onBlurEvent(event, propertyName) {
        let updateActivityDetail = { ...activityDetail };

        let newValue = '';
        if (propertyName === 'providerId' || propertyName === 'durationTypeId' || propertyName === 'buyPriceCurrency' || propertyName === 'productSubType') {
            newValue = event.key;
            if (!newValue)
                newValue = event;
        }
        else if (propertyName === 'serviceNote' || propertyName === 'serviceNoteDesc') {
            newValue = event;
        }
        else {
            newValue = event.target.value;
        }

        if (propertyName === 'totalAdults') {
            let numAdults = event.target.value ? parseInt(event.target.value) : 0;

            if (numAdults > manualRangeParameter.activity.maxAdults) {
                numAdults = manualRangeParameter.activity.maxAdults;
            } else if (numAdults < manualRangeParameter.activity.minAdults) {
                numAdults = manualRangeParameter.activity.minAdults;
            }

            newValue = numAdults;
        }

        if (propertyName === 'durationTypeId') {
            let adSelect = activityDuration.filter(x => (x.key + "") === newValue)[0];
            updateActivityDetail.durationTypeName = adSelect.text;
        }

        if (propertyName === 'buyPriceChange' && !newValue) {
            newValue = null;
        }

        if (propertyName === 'buyPriceCurrency') {
            let exchangeRate = 1;
            if (currencies && currencies.length > 0) {
                let currency = currencies.find(element => element.value === newValue);
                if (currency)
                    exchangeRate = currency.rate;
            }

            updateActivityDetail['buyPriceChange'] = exchangeRate;
        }

        updateActivityDetail[propertyName] = newValue;
        setActivityDetail(updateActivityDetail);

        checkValidateInputData(propertyName, newValue);
    }

    function checkValidateInputData(propertyName, value) {
        let isValidForm = false;
        let prevValidateInputData = { ...validateInputData };
        delete prevValidateInputData.isValidForm;

        const isNotEmpty = (value !== '' && value !== undefined);
        const isSelectedOption = value !== 'NOT';

        switch (propertyName) {
            case 'name': {
                delete prevValidateInputData.isValidName;
                delete prevValidateInputData.isTouchedName;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidName: isNotEmpty, isTouchedName: true });
                break;
            }
            case 'city': {
                delete prevValidateInputData.isValidCity;
                delete prevValidateInputData.isTouchedCity;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidCity: isNotEmpty, isTouchedCity: true });
                break;
            }
            case 'address': {
                delete prevValidateInputData.isValidAddress;
                delete prevValidateInputData.isTouchedAddress;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidAddress: isNotEmpty, isTouchedAddress: true });
                break;
            }
            case 'providerId': {
                delete prevValidateInputData.isValidProviderId;
                delete prevValidateInputData.isTouchedProviderId;
                isValidForm = isSelectedOption && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidProviderId: isSelectedOption, isTouchedProviderId: true });
                break;
            }
            case 'checkIn': {
                delete prevValidateInputData.isValidCheckIn;
                delete prevValidateInputData.isTouchedCheckIn;
                delete prevValidateInputData.isValidCancelPolicy;
                delete prevValidateInputData.isTouchedCancelPolicy;

                const isValidDate = (new Date(value) !== "Invalid Date") && new Date(value) >= new Date(today);
                isValidForm = isNotEmpty && isValidDate && Object.values(prevValidateInputData).every(item => item);

                let newValidateInputData = {
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidCheckIn: isNotEmpty && isValidDate,
                    isTouchedCheckIn: true,
                    isTouchedCancelPolicy: true,
                    isValidCancelPolicy: isNotEmpty,
                };

                setValidateInputData(newValidateInputData);
                break;
            }
            case 'cancelPolicy': {
                delete prevValidateInputData.isValidCancelPolicy;
                delete prevValidateInputData.isTouchedCancelPolicy;

                const isValidDate = (new Date(value) !== "Invalid Date") && new Date(value) >= new Date(today);

                const checkIn = new Date(activityDetail['checkIn']);
                const cancelPolicy = new Date(value);
                let isLessThanCheckIn = cancelPolicy <= checkIn;

                isValidForm = isNotEmpty && isValidDate && isLessThanCheckIn && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidCancelPolicy: isNotEmpty && isValidDate && isLessThanCheckIn,
                    isTouchedCancelPolicy: true
                });
                break;
            }
            case 'checkOut': {
                delete prevValidateInputData.isValidCheckOut;

                let isValidDate = (new Date(value) !== "Invalid Date") && new Date(value) >= new Date(today);
                if (!value) {
                    isValidForm = Object.values(prevValidateInputData).every(item => item);
                } else {

                    const checkIn = new Date(activityDetail['checkIn']);
                    const checkOut = new Date(value);

                    let isGreaterThanCheckIn = checkOut >= checkIn;
                    isValidDate = isValidDate && isGreaterThanCheckIn;

                    isValidForm = isValidDate && Object.values(prevValidateInputData).every(item => item);
                }

                setValidateInputData({
                    ...validateInputData,
                    isValidForm: isValidForm,
                    isValidCheckOut: !isNotEmpty || isValidDate
                });
                break;
            }
            case 'buyPriceAmount': {
                delete prevValidateInputData.isValidBuyPriceAmount;
                delete prevValidateInputData.isTouchedBuyPriceAmount;

                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidBuyPriceAmount: isNotEmpty, isTouchedBuyPriceAmount: true });
                break;
            }
            case 'productSubType': {
                delete prevValidateInputData.isValidProductSubType;
                delete prevValidateInputData.isTouchedProductSubType;
                isValidForm = isSelectedOption && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidProductSubType: isSelectedOption, isTouchedProductSubType: true });
                break;
            }
            case 'optionName': {
                delete prevValidateInputData.isValidOptionName;
                delete prevValidateInputData.isTouchedOptionName;
                isValidForm = isNotEmpty && Object.values(prevValidateInputData).every(item => item);

                setValidateInputData({ ...validateInputData, isValidForm: isValidForm, isValidOptionName: isNotEmpty, isTouchedOptionName: true });
                break;
            }
            default:
                break;
        }
    }

    function onChangeCheckIn(event) {
        let updateActivityDetail = { ...activityDetail };
        updateActivityDetail.checkIn = event.target.value;

        if (activityDetail.checkOut && new Date(activityDetail.checkOut) < new Date(event.target.value)) {
            updateActivityDetail.checkOut = '';
        }

        let cpDate = addDaysToDatePicker(event.target.value, -1);
        updateActivityDetail.cancelPolicy = cpDate;

        if (event.target.value) {
            setValidateValueDefault({ maxCancelPolicy: cpDate });
        }
        else {
            setValidateValueDefault({ maxCancelPolicy: '' });
        }

        setActivityDetail(updateActivityDetail);
        checkValidateInputData("checkIn", event.target.value);
    }

    function onChangeTotalChildren(event) {
        let numChildren = event.target.value ? parseInt(event.target.value) : 0;

        if (numChildren > manualRangeParameter.activity.maxChildren) {
            numChildren = manualRangeParameter.activity.maxChildren;
        } else if (numChildren < manualRangeParameter.activity.minChildren) {
            numChildren = manualRangeParameter.activity.minChildren;
        }

        let ages = [...activityDetail.ages];

        let diffChildren = ages.length - numChildren;
        if (diffChildren > 0) {
            ages.splice(numChildren);
        } else if (diffChildren < 0) {
            for (let i = 0; i < (diffChildren * -1); i++)
                ages.push(0);
        }

        setActivityDetail({ ...activityDetail, totalChildren: numChildren, ages: ages });
    }

    function onChangeAge(event, index) {
        let ages = [...activityDetail.ages];
        var age = event.target.value ? parseInt(event.target.value) : 0;

        if (age > manualRangeParameter.activity.maxChildAge) {
            age = manualRangeParameter.activity.maxChildAge;
        } else if (age < manualRangeParameter.activity.minChildAge) {
            age = manualRangeParameter.activity.minChildAge;
        }

        ages[index] = age;

        setActivityDetail({ ...activityDetail, ages: ages });
    }

    function callOnSelectDestination(item) {
        let updateActivityDetail = { ...activityDetail };
        updateActivityDetail.city = { webId: item.Id + "", text: item.Text, geoId: item.GeoId + "" };

        setActivityDetail(updateActivityDetail);
        checkValidateInputData("city", item);
    }

    function saveActivityManual() {
        props.callAddManualQuotationItem(activityDetail);
    }

    return (
        <div className="p-4">
            <div className="row">
                <h4>Activity</h4>
                <div className="col-md-3">
                    <div className={"form-floating" + (!validateInputData.isValidProductSubType ? " insert-danger" : "")}>

                        <span className="p-float-label form-select-label-m3-react-prime">
                            {
                                activityTypes && activityTypes.length > 0 && <Dropdown value={activityDetail.productSubType + ""}
                                    onChange={(e) => onBlurEvent(e.value, 'productSubType')}
                                    inputId="dd-productSubType"
                                    filter
                                    className="w-100 form-select-m3-react-prime"
                                    options={activityTypes}
                                    optionLabel="label" />
                            }
                            <label htmlFor="dd-productSubType">Tipo *</label>
                        </span>
                        {
                            validateInputData.isTouchedProductSubType && !validateInputData.isValidProductSubType && <div className="text-danger">Il campo tipo è obbligatorio</div>
                        }
                    </div>
                </div>
                <div className={"form-floating col-md-3" + (!validateInputData.isValidName ? " insert-danger" : "")}>
                    <input type="text" className="form-control" value={activityDetail.name} id="floatingInput" placeholder="nome visualizzabile esperienza" onChange={(e) => { onBlurEvent(e, 'name'); }} />
                    <label htmlFor="floatingInput" className="active">Nome esperienza *</label>
                    {
                        validateInputData.isTouchedName && !validateInputData.isValidName && <div className="text-danger">{t('Login:FieldNameIsMandatory')}</div>
                    }
                </div>
                <div className={"form-floating col-md-3" + (!validateInputData.isValidAddress ? " insert-danger" : "")}>
                    <input type="text" className="form-control" value={activityDetail.address} id="floatingInput" placeholder="indirizzo hotel visibile" onChange={(e) => { onBlurEvent(e, 'address'); }} />
                    <label htmlFor="floatingInput" className="active">Indirizzo * (visibile su web)</label>
                    {
                        validateInputData.isTouchedAddress && !validateInputData.isValidAddress && <div className="text-danger">{t('Login:FieldNameIsMandatory')}</div>
                    }
                </div>
                <div className={"form-floating col-md-3" + (!validateInputData.isValidCity ? " insert-danger" : "")}>
                    <input type="text"
                        className="form-control"
                        id="floatingInput"
                        value={activityDetail.city && activityDetail.city.text ? activityDetail.city.text : ''}
                        data-bs-toggle="modal"
                        data-bs-target="#AM_Manual_City"
                        placeholder="Città * (Applica regole)"
                        readOnly />
                    <label htmlFor="floatingInput" className="active">
                        Città * (Applica regole)
                    </label>

                    <InputSearchDestinationModal
                        modalId={"AM_Manual_City"}
                        productType={configData.Settings.Products.Activity.IdTipoProdotto}
                        callOnSelectDestination={callOnSelectDestination}
                        rules={{ enableDestination: true }} />
                    {
                        validateInputData.isTouchedCity && !validateInputData.isValidCity && <div className="text-danger">Il campo città è obbligatorio</div>
                    }
                </div>
            </div>
            <div className="row pt-2">
                <div className="col-md-3">
                    <div className={"form-floating" + (!validateInputData.isValidProviderId ? " insert-danger" : "")}>
                        <span className="p-float-label form-select-label-m3-react-prime">
                            {
                                suppliers && suppliers.length > 0 && <Dropdown value={activityDetail.providerId}
                                    onChange={(e) => onBlurEvent(e.value, 'providerId')}
                                    inputId="dd-provider"
                                    filter
                                    className="w-100 form-select-m3-react-prime"
                                    options={suppliers}
                                    optionLabel="label" />
                            }
                            <label htmlFor="dd-provider">Fornitore *</label>
                        </span>
                        {
                            validateInputData.isTouchedProviderId && !validateInputData.isValidProviderId && <div className="text-danger">Il campo fornitore è obbligatorio</div>
                        }
                    </div>
                </div>
                <div className="form-floating col-md-3">
                    <input type="text" className="form-control" value={activityDetail.payBy === null ? '' : activityDetail.payBy} id="floatingInput" placeholder="Fornitore per voucher" onChange={(e) => { onBlurEvent(e, 'payBy'); }} />
                    <label htmlFor="floatingInput" className="active">Pay by:</label>
                </div>
                <div className="form-floating col-md-3">
                    <input type="text" className="form-control" value={activityDetail.recordLocator === null ? '' : activityDetail.recordLocator} id="floatingInput" placeholder="IdRecordLocator" onChange={(e) => { onBlurEvent(e, 'recordLocator'); }} />
                    <label htmlFor="floatingInput" className="active">Cod prenotazione:</label>
                </div>
                <div className="form-floating col-md-3">
                    <input type="text" className="form-control" value={activityDetail.reservationId === null ? '' : activityDetail.reservationId} id="floatingInput" placeholder="IdReservation" onChange={(e) => { onBlurEvent(e, 'reservationId'); }} />
                    <label htmlFor="floatingInput" className="active">Cod prenotazione 2:</label>
                </div>

            </div>
            <div className="row pt-4">
                <div className={"form-floating col-md-3" + (!validateInputData.isValidCheckIn ? " insert-danger" : "")}>
                    <input type="date"
                        className="form-control"
                        value={activityDetail.checkIn}
                        id="txt_SM_CheckIn"
                        placeholder="01/01/99"
                        min={today}
                        onChange={onChangeCheckIn} />
                    <label htmlFor="floatingInput" className="active">Data Check-in *</label>
                    {
                        validateInputData.isTouchedCheckIn && !validateInputData.isValidCheckIn && <div className="text-danger">Il campo checkin non ha un valore valido</div>
                    }
                </div>
                <div className={"form-floating col-md-3" + (!validateInputData.isValidCheckOut ? " insert-danger" : "")}>
                    <input type="date"
                        className="form-control"
                        value={activityDetail.checkOut}
                        id="txt_SM_CheckOut"
                        placeholder="01/01/99"
                        min={activityDetail.checkIn}
                        onChange={(e) => { onBlurEvent(e, 'checkOut'); }} />

                    <label htmlFor="floatingInput" className="active">Data Check-out</label>

                    {
                        !validateInputData.isValidCheckOut && <div className="text-danger">Il campo checkout non ha un valore valido</div>
                    }
                </div>
                <div className={"form-floating col-md-3" + (!validateInputData.isValidCancelPolicy ? " insert-danger" : "")}>
                    <input type="date"
                        className="form-control"
                        value={activityDetail.cancelPolicy}
                        id="txt_SM_CancelPolicy"
                        placeholder="01/01/99"
                        max={validateValueDefault.maxCancelPolicy}
                        onChange={(e) => { onBlurEvent(e, 'cancelPolicy'); }} />
                    <label htmlFor="floatingInput" className="active">Inizio penale *</label>
                    {
                        validateInputData.isTouchedCancelPolicy && !validateInputData.isValidCancelPolicy && <div className="text-danger">Il campo cancel policy  non ha un valore valido</div>
                    }
                </div>
            </div>
            <div className="row pt-4">
                <div className="form-floating col-md-12">
                    <input type="text"
                        className="form-control"
                        value={activityDetail.imageUrl}
                        id="floatingInput"
                        placeholder="Url immagine"
                        onChange={(e) => { onBlurEvent(e, 'imageUrl'); }} />
                    <label htmlFor="floatingInput" className="active">Url Immagine</label>
                </div>
            </div>
            <div>
                <AddServiceNote
                    serviceNote={activityDetail.serviceNoteDesc}
                    roomIndex={-1}
                    noteTypeToAdd={12}
                    propertyName="serviceNoteDesc"
                    onBlurEvent={onBlurEvent} />
            </div>
            <div className="form-floating pt-2">
                <textarea className="form-control"
                    placeholder="Note Interne"
                    value={activityDetail.internalNote}
                    onChange={(e) => { onBlurEvent(e, 'internalNote'); }}
                    id="floatingTextarea2"
                    style={{ height: '100px' }} ></textarea>
                <label htmlFor="floatingTextarea2">Note Interne</label>
            </div>
            <div className="badge-gray-600 mt-4">Opzione</div>
            <div className="row pt-4">
                <div className={"form-floating col-md-4" + (!validateInputData.isValidOptionName ? " insert-danger" : "")}>
                    <input type="text" className="form-control" value={activityDetail.optionName} onChange={(e) => { onBlurEvent(e, 'optionName'); }} id="floatingInput" placeholder="typeService" />
                    <label htmlFor="floatingInput" className="active">Nome opzione *:</label>
                    {
                        validateInputData.isTouchedOptionName && !validateInputData.isValidOptionName && <div className="text-danger">{t('Login:FieldNameIsMandatory')}</div>
                    }
                </div>
                <div className="form-floating col-md-2">
                    <div className="form-floating">
                        <span className="p-float-label form-select-label-m3-react-prime">
                            {
                                activityDuration && activityDuration.length > 0 && <Dropdown value={activityDetail.durationTypeId}
                                    onChange={(e) => onBlurEvent(e.value, 'durationTypeId')}
                                    inputId="dd-durationTypeId"
                                    filter
                                    className="w-100 form-select-m3-react-prime"
                                    options={activityDuration.map(d => { return { label: d.text, value: d.key }; })}
                                    optionLabel="label" />
                            }
                            <label htmlFor="dd-durationTypeId">Durata</label>
                        </span>
                    </div>
                </div>
                <div className={"form-floating col-md-2" + (!validateInputData.isValidBuyPriceAmount ? " insert-danger" : "")}>
                    <input type="number" className="form-control" value={activityDetail.buyPriceAmount === null ? '' : activityDetail.buyPriceAmount} id="floatingInput" placeholder="123.45" onChange={(e) => { onBlurEvent(e, 'buyPriceAmount'); }} />
                    <label htmlFor="floatingInput" className="active">Costo *</label>
                    {
                        validateInputData.isTouchedBuyPriceAmount && !validateInputData.isValidBuyPriceAmount && <div className="error-input">Il campo costo è obbligatorio</div>
                    }
                </div>
                <div className="col-md-2">
                    <div className="form-floating">
                        <span className="p-float-label form-select-label-m3-react-prime">
                            {
                                currencies && currencies.length > 0 && <Dropdown value={activityDetail.buyPriceCurrency}
                                    onChange={(e) => onBlurEvent(e.value, 'buyPriceCurrency')}
                                    inputId="dd-buyPriceCurrency"
                                    filter
                                    className="w-100 form-select-m3-react-prime"
                                    options={currencies}
                                    optionLabel="label" />
                            }
                            <label htmlFor="dd-buyPriceCurrency">Valuta</label>
                        </span>
                    </div>
                </div>
                <div className="form-floating col-md-2">
                    <input type="number" className="form-control" id="floatingInput" placeholder="123.45" value={activityDetail.buyPriceChange === null ? '' : activityDetail.buyPriceChange} onChange={(e) => { onBlurEvent(e, 'buyPriceChange'); }} />
                    <label htmlFor="floatingInput" className="active">Tasso Cambio </label>
                </div>
            </div>
            <div className="row pt-2">
                <div className="form-floating col-2">
                    <input type="number" className="form-control" id="floatingInput" placeholder="adults" value={activityDetail.totalAdults} min={manualRangeParameter.activity.minRoom} max={manualRangeParameter.activity.maxAdults} onChange={(e) => { onBlurEvent(e, 'totalAdults'); }} />
                    <label htmlFor="floatingInput" className="active">N. Adulti </label>
                </div>
                <div className="form-floating col-2">
                    <input type="number" className="form-control" id="floatingInput" placeholder="children" value={activityDetail.totalChildren} min="0" max={manualRangeParameter.activity.maxChildren} onChange={(e) => { onChangeTotalChildren(e); }} />
                    <label htmlFor="floatingInput" className="active">N. Bambini</label>
                </div>
                {
                    activityDetail.ages.length > 0 && activityDetail.ages.map((item, key) => {
                        return <div key={key} className="form-floating col-2">
                            <input type="number" className="form-control" id="floatingInput" placeholder="children" min={manualRangeParameter.activity.minChildAge} max={manualRangeParameter.activity.maxChildAge} value={item} onChange={(e) => { onChangeAge(e, key); }} />
                            <label htmlFor="floatingInput" className="active">Età {key + 1}</label>
                        </div>
                    })
                }
                <div>L'età effettiva al momento della partenza deve corrispondere all'età dichiarata al momento della dell'entrata in struttura. Età ragazzi 17-0 compiuti</div>

                <div>
                    <AddServiceNote
                        serviceNote={activityDetail.serviceNote}
                        roomIndex={-1}
                        onBlurEvent={onBlurEvent} />
                </div>
            </div>
            <div className=" float-end">
                {props.isError && <span className="text-danger">Errore in fase di inserimento, controllare i dati</span>}
                <button className="btn tp-btn-add btn-sm" disabled={!validateInputData.isValidForm} onClick={saveActivityManual}>{t('Button:Save')}</button>
            </div>
        </div>
    );
}