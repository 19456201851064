import React from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { imageOnError } from '../../../js/CDNUtility';
import { formatDateTime, formatPrice } from '../../../js/Utils.js';

export const PrintTitle = ({ createdDate, isPrintCliente, title, titleCliente }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    return (
        <>
            <div>
                <table className="border-bottom w-100">
                    <tr>
                        <td className="h4 float-start">
                            {!isPrintCliente ?
                                <h4>{title}</h4>
                                :
                                <h4>{titleCliente}</h4>
                            }
                        </td>
                        <td className="h4 float-end">
                            {createdDate &&
                            <>
                                <label>{t(`Template:CreatedDate`)}&nbsp;</label> {formatDateTime(createdDate, cultureName)}
                            </>
                            }
                        </td>
                    </tr>
                </table>
            </div>
        </>
    );
}